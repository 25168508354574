import {CustomEnumFieldDto, CustomEnumValueDto, CustomFieldDto, CustomFieldType} from '|api/config-server';
import {CodebookFilterDefinition, ColumnDefinition, FilterType} from '@icz/angular-table';
import {isValidAtGivenDate} from '../../../core/services/data-mapping.utils';
import {IczOption} from '@icz/angular-form-elements';

export type CustomEnumValueOption = IczOption<Nullable<string>, CustomEnumValueDto>;

export function buildCodebookFieldOptions(field: CustomFieldDto, validityReferenceDate = new Date()): Array<CustomEnumValueOption> {
  const enumValues = [...((field as CustomEnumFieldDto).customEnumValues ?? [])] as CustomEnumValueDto[];

  enumValues.sort(
    (f1, f2) => (f1.name ?? f1.code) < (f2.name ?? f2.code) ? -1 : 1
  );
  enumValues.sort((f1, f2) => f1.valueOrder - f2.valueOrder);

  return enumValues.map(ev => ({
    value: ev.code,
    label: `${ev.code} ${ev.name ?? ''}`,
    isHidden: !isValidAtGivenDate(ev, validityReferenceDate),
    data: ev,
  }));
}

export function getCustomFieldFilterId(customField: CustomFieldDto) {
  const filterIdPrefix = `customFieldValues.cf${customField.id}`;

  if (customField.fieldType === CustomFieldType.DATE || customField.fieldType === CustomFieldType.DATE_TIME) {
    return `${filterIdPrefix}_datetime`;
  }
  else if (customField.fieldType === CustomFieldType.NUMERIC) {
    return `${filterIdPrefix}_numeric`;
  }
  else {
    return filterIdPrefix;
  }
}

export function getCustomFieldsColumnDefinitions(customFields: CustomFieldDto[]) {
  const indexedCustomFields = customFields.filter(a => a.indexed);
  const out: ColumnDefinition<string>[] = [];

  for (const customField of indexedCustomFields) {
    const columnDefinition: Partial<ColumnDefinition<string>> = {
      id: getCustomFieldFilterId(customField),
      label: customField.name,
      displayed: false,
      allowSettingInContextMenu: true,
    };

    if (customField.fieldType === CustomFieldType.TEXT) {
      columnDefinition.filterType = FilterType.TEXT;
    }
    else if (customField.fieldType === CustomFieldType.NUMERIC) {
      columnDefinition.filterType = FilterType.NUMBER;
    }
    else if (customField.fieldType === CustomFieldType.ENUM) {
      columnDefinition.filterType = FilterType.ENUM;
      columnDefinition.disableSort = true;
      (columnDefinition as CodebookFilterDefinition).list = buildCodebookFieldOptions(customField);
    }
    else if (customField.fieldType === CustomFieldType.DATE) {
      columnDefinition.filterType = FilterType.DATE;
    }
    else if (customField.fieldType === CustomFieldType.DATE_TIME) {
      columnDefinition.filterType = FilterType.DATETIME;
    }
    else if (customField.fieldType === CustomFieldType.BOOL) {
      columnDefinition.filterType = FilterType.BOOLEAN;
    }

    out.push(columnDefinition as ColumnDefinition);
  }

  return out;
}
