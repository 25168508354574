import {Component, DestroyRef, inject, Input} from '@angular/core';
import {CodebookService} from '../../../core/services/codebook.service';
import {IczFormArray, IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {EntityType} from '|api/commons';
import {
  CustomDateFieldDto,
  CustomDateTimeFieldDto,
  CustomFieldDto,
  CustomFieldType,
  CustomNumericFieldDto,
  CustomTextFieldDto
} from '|api/config-server';
import {IczOnChanges, IczSimpleChanges, parseBoolean} from '@icz/angular-essentials';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DOCUMENT_ENTITY_TYPES} from '../shared-document.utils';
import {isValidAtGivenDate} from '../../../core/services/data-mapping.utils';
// eslint-disable-next-line no-restricted-imports
import {ValidatorFn, Validators} from '@angular/forms';
import {add, Duration, sub} from 'date-fns';
import {CustomFieldValueDto} from '|api/document';
import {buildCodebookFieldOptions, CustomEnumValueOption} from '../document-table/custom-fields.utils';

export function makeCustomFieldValueForm() {
  return new IczFormGroup({
    customFieldId: new IczFormControl<Nullable<number>>(null),
    strValue: new IczFormControl<Nullable<string>>(null),
    dateTimeValue: new IczFormControl<Nullable<string>>(null),
    numericValue: new IczFormControl<Nullable<number>>(null),
    name: new IczFormControl<string>(''),
    indexed: new IczFormControl<Nullable<boolean>>(null),
  });
}

export function getCustomFieldsFormArray() {
  return new IczFormArray(makeCustomFieldValueForm, []);
}

export function prepareCustomFieldValuesForBackend(customFieldValues: CustomFieldValueDto[]): CustomFieldValueDto[] {
  return customFieldValues
    .filter(fv => !isNil(fv.strValue) || !isNil(fv.dateTimeValue) || !isNil(fv.numericValue))
    .map(fv => {
      if (typeof fv.strValue === 'boolean') {
        return {
          ...fv,
          value: String(fv.strValue),
        };
      }
      else {
        return fv;
      }
    });
}

type CustomFieldValueFormGroup = ReturnType<typeof makeCustomFieldValueForm>;

@Component({
  selector: 'icz-document-file-custom-fields-section',
  templateUrl: './document-file-custom-fields-section.component.html',
  styleUrl: './document-file-custom-fields-section.component.scss'
})
export class DocumentFileCustomFieldsSectionComponent implements IczOnChanges {

  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  @Input({ required: true })
  form!: IczFormGroup;
  @Input()
  arrayName = 'customFieldValues'; // control key; should correspond to an IczFormArray
  @Input({ required: true })
  entityType!: EntityType;
  @Input()
  documentTypeId: Nullable<number>;
  @Input()
  fileTypeId: Nullable<number>;
  @Input()
  validityReferenceDate: Nullable<Date> = new Date();

  private allCustomFields: Nullable<CustomFieldDto[]>;
  displayedCustomFields: CustomFieldDto[] = [];

  private customFieldOptions: Record<string, Array<CustomEnumValueOption>> = {};

  readonly CustomFieldType = CustomFieldType;

  get fieldValuesFormArray() {
    return this.form.get(this.arrayName) as IczFormArray;
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.documentTypeId || changes.fileTypeId || changes.entityType || changes.validityReferenceDate) {
      if (!this.allCustomFields) {
        this.codebookService.customFields().pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(customFields => {
          this.allCustomFields = customFields;
          this.computeAvailableFields();
        });
      }
      else {
        this.computeAvailableFields();
      }
    }
  }

  getFieldFormGroup(field: CustomFieldDto) {
    const fieldIndexInFormArray = this.fieldValuesFormArray.getRawValue().findIndex(
      (customFieldValue: CustomFieldValueDto) => customFieldValue.customFieldId === field.id
    );

    return this.fieldValuesFormArray.get(String(fieldIndexInFormArray))! as IczFormGroup;
  }

  getCodebookFieldOptions(field: CustomFieldDto) {
    return this.customFieldOptions[field.id!] ?? [];
  }

  computeAvailableFields() {
    // nullable validity reference date is considered invalid
    if (!this.validityReferenceDate) {
      return;
    }

    this.customFieldOptions = {};

    const availableFields = this.allCustomFields!
      .filter(f => f.entityTypes.includes(this.entityType))
      .filter(f => isValidAtGivenDate(f, this.validityReferenceDate!))
      .filter(f => (
        this.isFieldSuitableForCurrentDocumentType(f) ||
        this.isFieldSuitableForCurrentFileType(f)
      ));

    availableFields.sort(
      (f1, f2) => f1.name < f2.name ? -1 : 1
    );
    availableFields.sort((f1, f2) => f1.fieldOrder - f2.fieldOrder);

    this.displayedCustomFields = availableFields;

    const valuesBeforeChange = this.fieldValuesFormArray.getRawValue() as CustomFieldValueDto[];
    const disableStateBeforeChange = this.fieldValuesFormArray.disabled;

    this.fieldValuesFormArray.setSize(0);

    for (const displayedCustomField of this.displayedCustomFields) {
      const addedFormGroup = this.fieldValuesFormArray.incrementSize() as CustomFieldValueFormGroup;

      if (displayedCustomField.fieldType === CustomFieldType.ENUM) {
        this.customFieldOptions[String(displayedCustomField.id)] = buildCodebookFieldOptions(
          displayedCustomField,
          this.validityReferenceDate
        );
      }

      this.applyValidatorsToCustomField(displayedCustomField, addedFormGroup);

      addedFormGroup.get('customFieldId')!.setValue(displayedCustomField.id);
      addedFormGroup.get('name')!.setValue(displayedCustomField.name);
      addedFormGroup.get('indexed')!.setValue(displayedCustomField.indexed);

      let fieldValue: any;

      if (valuesBeforeChange.length) {
        const oldAssociatedValue = valuesBeforeChange.find(v => v.customFieldId === displayedCustomField.id);

        if (!oldAssociatedValue?.strValue && !oldAssociatedValue?.dateTimeValue && isNil(oldAssociatedValue?.numericValue)) {
          fieldValue = null;
        }
        else if (displayedCustomField.fieldType === CustomFieldType.BOOL) {
          fieldValue = parseBoolean(oldAssociatedValue?.strValue);
        }
        else if (displayedCustomField.fieldType === CustomFieldType.NUMERIC) {
          fieldValue = oldAssociatedValue?.numericValue;
        }
        else if (this.isFieldTemporal(displayedCustomField)) {
          fieldValue = oldAssociatedValue.dateTimeValue;
        }
        else {
          fieldValue = oldAssociatedValue.strValue;
        }
      }
      else {
        fieldValue = displayedCustomField.fieldType === CustomFieldType.BOOL ? false : null;
      }

      if (this.isFieldTemporal(displayedCustomField)) {
        addedFormGroup.get('dateTimeValue')!.setValue(fieldValue);
      }
      else if (displayedCustomField.fieldType === CustomFieldType.NUMERIC) {
        addedFormGroup.get('numericValue')!.setValue(fieldValue);
      }
      else {
        addedFormGroup.get('strValue')!.setValue(fieldValue);
      }

      if (disableStateBeforeChange) {
        addedFormGroup.disable();
      }
      else {
        addedFormGroup.enable();
      }
    }
  }

  private applyValidatorsToCustomField(displayedCustomField: CustomFieldDto, addedFormGroup: CustomFieldValueFormGroup) {
    const applicableValidators: ValidatorFn[] = [];

    if (displayedCustomField.createdRequired) {
      applicableValidators.push(IczValidators.required());
    }

    if (displayedCustomField.fieldType === CustomFieldType.TEXT) {
      const textCustomField = (displayedCustomField as CustomTextFieldDto);

      if (textCustomField.minLength) {
        applicableValidators.push(IczValidators.minLength(textCustomField.minLength));
      }
      if (textCustomField.maxLength) {
        applicableValidators.push(IczValidators.maxLength(textCustomField.maxLength));
      }
      if (textCustomField.textRegex) {
        applicableValidators.push(control => {
          const patternValidatorResult = Validators.pattern(textCustomField.textRegex)(control);

          if (patternValidatorResult) {
            return {
              value: {
                errorMessageTemplate: textCustomField.errorMessage ?? 'Zadaný text neodpovídá pravidlům pro obsah pole.',
                validatorResult: {
                  regex: false,
                }
              }
            };
          }
          else {
            return null;
          }
        });
      }
    }
    else if (displayedCustomField.fieldType === CustomFieldType.NUMERIC) {
      const numericCustomField = (displayedCustomField as CustomNumericFieldDto);

      if (numericCustomField.minValue) {
        applicableValidators.push(IczValidators.min(numericCustomField.minValue));
      }
      if (numericCustomField.maxValue) {
        applicableValidators.push(IczValidators.max(numericCustomField.maxValue));
      }
      if (numericCustomField.rounding) {
        applicableValidators.push(IczValidators.isRoundedToNumericOrder(numericCustomField.rounding));
      }
      if (numericCustomField.multiples) {
        applicableValidators.push(IczValidators.isMultipleOfValue(numericCustomField.multiples));
      }
      if (numericCustomField.mask) {
        applicableValidators.push(IczValidators.isValidByNumericMask(numericCustomField.mask));
      }
    }
    else if (displayedCustomField.fieldType === CustomFieldType.DATE || displayedCustomField.fieldType === CustomFieldType.DATE_TIME) {
      const temporalCustomField = (displayedCustomField as CustomDateFieldDto | CustomDateTimeFieldDto);

      if (temporalCustomField.pastDateRestrictionShift) {
        const pastValueRestriction = this.convertTimeOffsetToIsoDate(temporalCustomField.pastDateRestrictionShift).toISOString();

        applicableValidators.push(IczValidators.maxDateTime(
          pastValueRestriction,
          displayedCustomField.fieldType === CustomFieldType.DATE
        ));
      }
      if (temporalCustomField.futureDateRestrictionShift) {
        const futureValueRestriction = this.convertTimeOffsetToIsoDate(temporalCustomField.futureDateRestrictionShift).toISOString();

        applicableValidators.push(IczValidators.minDateTime(
          futureValueRestriction,
          displayedCustomField.fieldType === CustomFieldType.DATE
        ));
      }
    }
    // ENUM and BOOL do not have any specific validation.

    if (displayedCustomField.fieldType === CustomFieldType.DATE_TIME) {
      addedFormGroup.get('dateTimeValue')!.setValidators(applicableValidators);
    }
    else if (displayedCustomField.fieldType === CustomFieldType.NUMERIC) {
      addedFormGroup.get('numericValue')!.setValidators(applicableValidators);
    }
    else {
      addedFormGroup.get('strValue')!.setValidators(applicableValidators);
    }
  }

  private isFieldSuitableForCurrentDocumentType(field: CustomFieldDto) {
    return (
      DOCUMENT_ENTITY_TYPES.includes(this.entityType) &&
      (
        !field.documentTypes?.length ||
        (this.documentTypeId && (field.documentTypes ?? []).includes(this.documentTypeId))
      )
    );
  }

  private isFieldSuitableForCurrentFileType(field: CustomFieldDto) {
    return (
      this.entityType === EntityType.FILE &&
      (
        !field.fileTypes?.length ||
        (this.fileTypeId && (field.fileTypes ?? []).includes(this.fileTypeId))
      )
    );
  }

  private convertTimeOffsetToIsoDate(timeOffset: string) {
    const re = /^-?(\d+d)?(\d+h)?$/;
    const capturedValues = re.exec(timeOffset);

    if (capturedValues) {
      const isMinusMode = timeOffset.includes('-');
      const dayAmount = capturedValues[1]?.replace('d', '');
      const hourAmount = capturedValues[2]?.replace('h', '');

      const now = new Date();
      const duration: Duration = {
        days: dayAmount ? Number(dayAmount) : undefined,
        hours: hourAmount ? Number(hourAmount) : undefined,
      };

      return isMinusMode ? sub(now, duration) : add(now, duration);
    }
    else {
      throw new Error(`Time offset "${timeOffset}" is invalid.`);
    }
  }

  private isFieldTemporal(customField: CustomFieldDto) {
    return customField.fieldType === CustomFieldType.DATE || customField.fieldType === CustomFieldType.DATE_TIME;
  }

}
