/* tslint:disable */
/* eslint-disable */

/**
 * Takeover type of registry office object
 */
export enum RegistryOfficeTakeoverType {
  AUTOMATIC = 'AUTOMATIC',
  MANUAL = 'MANUAL',
  AUTOMATIC_FULL_CONTENT = 'AUTOMATIC_FULL_CONTENT'
}
