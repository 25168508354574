<form [formGroup]="signatureForm" class="h-full" autocomplete="new-password">
  @if (isVisualSignature && !isCertificateSelected && isManualSignatureActive) {
    <icz-alert
      class="mb-16"
      heading="Pro začátek manuálního umisťování podpisů je třeba vybrat nejprve platný certifikát"
      severity="warning"
      >
    </icz-alert>
  }
  <icz-tabs
    class="h-full"
    (tabClick)="handleTabClick($event)"
    [shouldNavigate]="false"
    [tabs]="allTabs"
    [waiting]="loadingService.isLoading(this, LoadingId.SIGN_DIGITAL_COMPONENTS) || loadingService.isLoading(this, LoadingId.IDT_RETRYING)"
    >
    <!--    SIGNATURE DEFINITION TAB-->
    <ng-template iczTab="definition">
      <div class="gap-32 row">
        <div class="col grow">
          <div class="row grow">
            <div class="col grow">
              <icz-section label="Zvolte úložiště certifikátu" [alwaysOpened]="true">
                <icz-radio-group>
                  <div class="gap-8 col">
                    <icz-radio-button formControlName="certificateSource" [value]="CertificateSourceEnum.LOCAL" label="Lokální úložiště"></icz-radio-button>
                    <icz-radio-button
                      testingFeature
                      formControlName="certificateSource"
                      [value]="CertificateSourceEnum.REMOTE"
                      label="Certifikát na serverovém kvalifikovaném prostředku (HSM)"
                    ></icz-radio-button>
                  </div>
                </icz-radio-group>
              </icz-section>

              @if (idtStatus === IdtConnectionState.RUNNING && isCertificateSourceSelected) {
                @if (isLocalCertificateSourceSelected) {
                  <icz-section label="Zvolení zdroje lokálního certifikátu" [alwaysOpened]="true">
                    <icz-form-autocomplete
                      label="Zdroj lokálního certifikátu"
                      formControlName="provider"
                      class="grow"
                      [options]="localSourceTypeOptions"
                      >
                    </icz-form-autocomplete>
                  </icz-section>
                  <icz-section label="Použití certifikátu" [alwaysOpened]="true">
                    <div class="col">
                      <icz-form-autocomplete
                        label="Certifikát"
                        formControlName="validCertificate"
                        class="certificate-selector grow"
                        [customOptionTemplate]="optionTemplate"
                        [customSelectedOptionTemplate]="optionTemplate"
                        [options]="certificateListOptions$ | async"
                        [hideDefaultTooltips]="true"
                      ></icz-form-autocomplete>
                      <ng-template #optionTemplate let-context>
                        <div class="col gap-8">
                          <div>{{parseNaming(context.option.data.subject)}}</div>
                          <div>{{parseNaming(context.option.data.issuer)}}</div>
                          <div>{{getCertificateAdditionalInfo(context.option.data)}}</div>
                        </div>
                      </ng-template>
                      @if (isFileCertificateSelected) {
                        <icz-form-field
                          type="password"
                          formControlName="password"
                          label="Heslo k certifikátu"
                        ></icz-form-field>
                      }
                    </div>
                  </icz-section>
                }
                <icz-section label="Důvod a místo podpisu" [alwaysOpened]="true">
                  <icz-form-field formControlName="reason" label="Důvod podpisu"></icz-form-field>
                  <icz-form-field formControlName="place" label="Místo podpisu"></icz-form-field>
                </icz-section>
                @if (loadingService.isLoading(this, LoadingId.IDT_INSTALLED)) {
                  <div class="justify-center mt-32 row">
                    <icz-spinner [diameter]="50"></icz-spinner>
                  </div>
                }
              }
            </div>
          </div>
        </div>

        @if (idtStatus === IdtConnectionState.RUNNING && isCertificateSourceSelected && isVisualSignatureAvailable) {
          <div class="col grow">
            @if (applicationConfigService.isVisualSignatureEnabled) {
              <icz-section
                label="Budete podpis umisťovat vizuálně?" [alwaysOpened]="true"
                >
                <icz-checkbox label="Vizuální umístění podpisu" formControlName="isVisualSignature"></icz-checkbox>
              </icz-section>
            }
            @if (isVisualSignature && numberOfWithoutVisualSignatureOption > 0) {
              <icz-alert
                [textContext]=""
                severity="warning"
                heading="fe.ui.alert.heading.SIGN_CONFIGURATION_NOT_ALL_VISUAL_SIGNABLE"
              ></icz-alert>
            }
            <div [hidden]="!isVisualSignature">
              <icz-section label="Jak budete umisťovat podpis?" [alwaysOpened]="true">
                <icz-radio-group>
                  <div class="gap-8 col">
                    <icz-radio-button formControlName="signatureMode" [value]="SignatureMode.AUTOMATIC" label="Automatické umístění podpisu"></icz-radio-button>
                    <icz-radio-button formControlName="signatureMode" [value]="SignatureMode.MANUAL" label="Ruční umístění podpisu"></icz-radio-button>
                  </div>
                </icz-radio-group>
                <icz-label class="mt-8" label="Podpis umístíte do dokumentů v následujícím kroku"></icz-label>
              </icz-section>
              @if (!isManualSignatureActive) {
                <div>
                  <icz-section label="Na kterou stranu?" [alwaysOpened]="true">
                    <icz-page-designator-form-fragment [form]="signatureForm" controlName="page"> </icz-page-designator-form-fragment>
                  </icz-section>
                  <icz-section label="Nastavení rohu podpisu" [alwaysOpened]="true">
                    <icz-checkbox
                      label="Vybrat roh podpisu z předvolených možností?"
                      formControlName="isPositionSelectedFromOptions"
                    ></icz-checkbox>
                  </icz-section>
                  @if (isPositionSelectedFromOptions) {
                    <icz-section label="V jakém rohu?" [alwaysOpened]="true">
                      <icz-page-corner-form-fragment [form]="signatureForm" controlName="position"> </icz-page-corner-form-fragment>
                    </icz-section>
                  }
                  @if (!isPositionSelectedFromOptions) {
                    <icz-section label="Vzdálenost od okrajů" [alwaysOpened]="true">
                      <icz-form-field formControlName="positionX" label="Vzdálenost v mm od levého okraje"></icz-form-field>
                      <icz-form-field formControlName="positionY" label="Vzdálenost v mm od horního okraje"></icz-form-field>
                    </icz-section>
                  }
                </div>
              }
            </div>
          </div>
        }

        @if (!isVisualSignatureAvailable) {
          <icz-section class="col grow" label="Důvod a místo podpisu" [alwaysOpened]="true">
            <icz-form-field formControlName="reason" label="Důvod podpisu"></icz-form-field>
            <icz-form-field formControlName="place" label="Místo podpisu"></icz-form-field>
          </icz-section>
        }
      </div>

      @if (idtStatus !== IdtConnectionState.RUNNING) {
        <icz-idt-malfunction-alert
          [idtStatus]="idtStatus"
          functionUnavailableText="Podpisové služby tudíž nejsou k dispozici."
        ></icz-idt-malfunction-alert>
      }

      @if (loadingService.isLoading(this, LoadingId.IDT_INSTALLED)) {
        <div class="row justify-center spinner">
          <icz-spinner [diameter]="50"></icz-spinner>
        </div>
      }
    </ng-template>

    @for (digitalComponent of signableWithVisualSignatureOption; track digitalComponent.id) {
      <ng-template [iczTab]="$any(digitalComponent.id)">
        <icz-digital-component-manual-signature-position
          [reasonPlaceFillAutomatic]="!isMultipleOfWithVisualSignatureOption"
          [certificateProvider]="providerTypeFormControl.value"
          [reason]="globalReason"
          [place]="globalPlace"
          [certificate]="currentCertificate"
          [digitalComponent]="digitalComponent"
        ></icz-digital-component-manual-signature-position>
      </ng-template>
    }
  </icz-tabs>

  @if (loadingService.isLoading(this, LoadingId.IDT_RETRYING)) {
    <icz-label class="grow text-center mt-16" label="fe.ui.alert.body.IDT_RETRYING"></icz-label>
  }
</form>

<icz-form-buttons>
  @if (idtStatus === IdtConnectionState.RUNNING) {
    <ng-container leftButtons>
      @if (isSignButtonWithoutVisualSignatureVisible) {
        <icz-button
          iczFormSubmit
          (onAction)="handleSign(true)"
          [disabled]="loadingService.isLoading(this, LoadingId.SIGN_DIGITAL_COMPONENTS)"
          primary
          label="Podepsat a orazítkovat"
        ></icz-button>
      }
      @if (
        applicationConfigService.signatureTimestampMandatorness === SignaturePlaceTimestamp.ALLOWED &&
        isSignButtonWithoutVisualSignatureVisible
        ) {
        <icz-button
          (onAction)="handleSign(false)"
          [disabled]="loadingService.isLoading(this, LoadingId.SIGN_DIGITAL_COMPONENTS)"
          iczFormSubmit
          label="Podepsat bez orazítkování"
        ></icz-button>
      }
      @if (isManualSignatureActive) {
        @if (isLastManualSignatureTab) {
          <icz-button
            iczFormSubmit
            (onAction)="handleSignWithManualSignaturePosition(true)"
            [disabled]="loadingService.isLoading(this, LoadingId.SIGN_DIGITAL_COMPONENTS)"
            primary
            [label]="manualSignatureButtonText"
          ></icz-button>
          @if (applicationConfigService.signatureTimestampMandatorness === SignaturePlaceTimestamp.ALLOWED) {
            <icz-button
              iczFormSubmit
              (onAction)="handleSignWithManualSignaturePosition(false)"
              [disabled]="loadingService.isLoading(this, LoadingId.SIGN_DIGITAL_COMPONENTS)"
              [label]="manualSignatureButtonTextWithoutTimestamp"
            ></icz-button>
          }
        } @else {
          <icz-button
            (onAction)="goToNextSignatureTab()"
            primary
            label="Přejít k umístění podpisu"
          ></icz-button>
        }
      }
    </ng-container>
  }

  <icz-button (onAction)="closeDialog()" rightButtons label="Storno"></icz-button>
</icz-form-buttons>
