import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  IczStatistic,
  periodOptions,
  StatisticConfig,
  StatisticFormatter, StatisticTrendCounterMode,
  TrendPeriod,
  TrendWidgetData
} from '../statistics-model';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {FileSizePipe, NumberToLocaleStringPipe} from '@icz/angular-essentials';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczOption} from '@icz/angular-form-elements';

interface FormattedTrendData {
  total: string;
  trend: string;
}


@Component({
  selector: 'icz-trend-counter',
  templateUrl: './trend-counter.component.html',
  styleUrls: ['./trend-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FileSizePipe,
  ]
})
export class TrendCounterComponent implements OnInit, IczOnChanges {

  private fileSizePipe = inject(FileSizePipe);
  private numberToLocaleStringPipe = new NumberToLocaleStringPipe();
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) trendData!: Nullable<TrendWidgetData>;
  @Input({required: true}) statisticsConfig: Nullable<StatisticConfig>;
  @Input() isTrendLoading = false;

  @Output() periodChanged = new EventEmitter<{ id: IczStatistic, period: TrendPeriod }>();
  @Output() goToDetailClicked = new EventEmitter<IczStatistic>();

  form = new IczFormGroup({
    period: new IczFormControl<TrendPeriod>(TrendPeriod.LAST_7_DAYS, []),
  });

  formattedTrendData: Nullable<FormattedTrendData> = null;

  trendPeriodOptions: IczOption[] = periodOptions;

  get showAsCount() {
    return this.statisticsConfig?.mode === StatisticTrendCounterMode.COUNT;
  }

  get showAsTable() {
    return this.statisticsConfig?.mode === StatisticTrendCounterMode.TABLE;
  }

  ngOnInit() {
    this.form.get('period')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(period => {
      this.periodChanged.emit({id: this.statisticsConfig!.id, period});
    });
  }

  onDetailClick(id: IczStatistic) {
    if (!this.statisticsConfig?.isDetailTestingFeature) {
      this.goToDetailClicked.emit(id);
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.statisticsConfig && changes.statisticsConfig.currentValue) {
      this.form.get('period')!.setValue(this.statisticsConfig!.period, {emitEvent: false});
    }

    if (this.trendData) {
      if (this.statisticsConfig?.formatter) {
        if (this.statisticsConfig?.formatter === StatisticFormatter.FILESIZE) {
          this.formattedTrendData = {
            trend: this.addSignToTrend(this.trendData.trend, this.fileSizePipe.transform(this.trendData.trend)),
            total: this.fileSizePipe.transform(this.trendData.total)
          };
        }
      }
      else {
        this.formattedTrendData = {
          trend: this.addSignToTrend(this.trendData.trend, this.numberToLocaleStringPipe.transform(this.trendData.trend)),
          total: String(this.trendData.total),
        };
      }
    }
  }

  addSignToTrend(trendNumber: number, trendString: string) {
    if (trendNumber >= 0) {
      return `+${trendString}`;
    } else {
      return `${trendString}`;
    }
  }

  downloadStatistic() {

  }
}
