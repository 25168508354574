import {Directive, inject} from '@angular/core';
import {AbstractHandoverFormComponent, DerivedOrgStructureElementOption} from './abstract-handover-form.component';
import {CheckUnsavedFormDialogService} from '../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../lib/form-group-checks';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczOption} from '@icz/angular-form-elements';
import {injectModalRef} from '@icz/angular-modal';
import {ToastService} from '../../../notifications/toast.service';
import {TranslateService} from '@ngx-translate/core';

export function getSelectedSingleselectOrgStructureElement(
  orgStructureElementsControl: IczFormControl,
  orgStructureOptions: IczOption[],
): DerivedOrgStructureElementOption[] {
  const orgStructureOptionValue = orgStructureElementsControl!.value;
  const foundOption = orgStructureOptions.find(o => o.value === orgStructureOptionValue);

  if (foundOption) {
    return [
      {
        ...foundOption,
        parent: Number(foundOption.parent!),
        value: foundOption.id!,
        data: {originalValue: foundOption.value}
      }
    ];
  }
  else {
    return [];
  }
}

@Directive()
export abstract class AbstractHandoverFormModalComponent extends AbstractHandoverFormComponent implements IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<unknown>>();
  protected toastService = inject(ToastService);
  protected translateService = inject(TranslateService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);

  formGroupsToCheck: string[] = [];

  get isSingleEntityFlow() {
    return this.circulationEntities.length === 1;
  }

  override ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    super.ngOnInit();
  }

  closeModal() {
    this.modalRef.close(null);
  }

}
