import {AddressCompleteDto, AddressForm, AddressFormat, CzechAddressDtoExtended,} from '../../model/addresses.model';
import {IczFormArray, IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {cloneDeep} from 'lodash';
import {
  CzechAddressDto,
  GenericLineAddressDto,
  HouseNumberType,
  PostalBoxAddressDto,
  SlovakAddressDto,
  SubjectAttributeSource, SubjectAttributeState
} from '|api/commons';

export function getCzSkFormatGroup() {
  return new IczFormGroup({
    city: new IczFormControl<Nullable<string>>(null, []),
    district: new IczFormControl<Nullable<string>>(null, []), // "Cast obce"
    pragueDistrict: new IczFormControl<Nullable<string>>(null, []), // "Mestska cast, double checked with analyst"
    houseNumber: new IczFormControl<Nullable<string>>(null, []), // "Číslo popisné"
    orientationNumber: new IczFormControl<Nullable<string>>(null, []), // "Číslo orientační"
    orientationNumberLastCharacter: new IczFormControl<Nullable<string>>(null, []), // "Číslo orientační" - symbol after slash
    registrationNumber: new IczFormControl<Nullable<string>>(null, []), // "Číslo evidenční", API doesnt have a field for it, instead this will set houseNumberType.REGISTRATION_NUMBER
    id: new IczFormControl<Nullable<string>>(null, []), // real world address ID from address registry
    postalCode: new IczFormControl<Nullable<string>>(null, []),
    postalOffice: new IczFormControl<Nullable<string>>(null, []),
    street: new IczFormControl<Nullable<string>>(null, []),
    houseNumberType: new IczFormControl<Nullable<HouseNumberType>>(HouseNumberType.STREET_NUMBER, []),
  });
}

export function createAddressFormGroup(address?: Nullable<AddressForm>, genericLines = 2): IczFormGroup {
  const form = new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(null, []),  // ID of existing subject attribute
    source: new IczFormControl<Nullable<SubjectAttributeSource>>(SubjectAttributeSource.INTERNAL, []),
    state: new IczFormControl<Nullable<SubjectAttributeState>>(SubjectAttributeState.CORRECT, []),
    validFrom: new IczFormControl<Nullable<string>>(new Date().toISOString(), []),
    value: new IczFormGroup({
      country: new IczFormControl<Nullable<string>>('CZE', []), // Abstract address dto: country
      _Class: new IczFormControl<Nullable<string>>(AddressFormat.CzechAddressDto, []), // Format of address (is unfortunately sometimes referred to as "type")
      [AddressFormat.CzechAddressDto]: getCzSkFormatGroup(),
      [AddressFormat.SlovakAddressDto]: getCzSkFormatGroup(),
      [AddressFormat.GenericLineAddressDto]: new IczFormGroup({
        addressLines: new IczFormArray(() => new IczFormGroup({line: new IczFormControl<Nullable<string>>(null, [])}), []),
      }),
      [AddressFormat.PostalBoxAddressDto]: new IczFormGroup({
        box: new IczFormControl<Nullable<string>>(null, []),
        postOffice: new IczFormControl<Nullable<string>>(null, []),
        postalCode: new IczFormControl<Nullable<string>>(null, []),
      }),
    }),
    previousValue: new IczFormControl<Nullable<string>>(null, []),
  });
  const existingLines = (address?.value[AddressFormat.GenericLineAddressDto] as GenericLineAddressDto)?.addressLines;
  if (existingLines) {
    (form.get('value')!.get(AddressFormat.GenericLineAddressDto)!.get('addressLines') as IczFormArray).setSize(existingLines.length);
  } else if (genericLines) {
    (form.get('value')!.get(AddressFormat.GenericLineAddressDto)!.get('addressLines') as IczFormArray).setSize(genericLines);
  }


  form.patchValue(address!);
  return form;
}

export function addressDtoToForm(address: Nullable<AddressCompleteDto>,
                                 id?: Nullable<number>,
                                 source?: SubjectAttributeSource,
                                 state?: SubjectAttributeState,
                                 validFrom?: string,
): Nullable<AddressForm> {
  if (!address) return null;

  const createExtendedAddress = (address: CzechAddressDto | SlovakAddressDto): CzechAddressDtoExtended => {
    const extendedAddress: CzechAddressDtoExtended = cloneDeep(address);
    if (address.houseNumberType === HouseNumberType.REGISTRATION_NUMBER) {
      extendedAddress.registrationNumber = extendedAddress.houseNumber;
      extendedAddress.houseNumber = '';
    }
    return extendedAddress;
  };

  const result: AddressForm = {
    value: {country: address.country, _Class: address._Class as AddressFormat},
    id: id ?? null,
    source: source ?? null,
    state: state ?? null,
    validFrom: validFrom ?? null,
  };
  if (address._Class === AddressFormat.CzechAddressDto) {
    result.value[AddressFormat.CzechAddressDto] = createExtendedAddress(address as CzechAddressDto);
  } else if (address._Class === AddressFormat.SlovakAddressDto) {
    result.value[AddressFormat.SlovakAddressDto] = createExtendedAddress(address as SlovakAddressDto);
  } else if (address._Class === AddressFormat.GenericLineAddressDto) {
    result.value[AddressFormat.GenericLineAddressDto] = {country: address.country, _Class: address._Class, addressLines: genericLineAddressToForm((address as GenericLineAddressDto).addressLines!)};
  } else if (address._Class === AddressFormat.PostalBoxAddressDto) {
    result.value[AddressFormat.PostalBoxAddressDto] = address as PostalBoxAddressDto;
  }

  return result;
}

function genericLineAddressToForm(addressLines: string[]) {
  return addressLines.map(line => ({line}));
}
