import {inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {CirculationActivityType, EntityType, ObjectClass} from '|api/commons';
import {
  AuthorizedEntityType,
  ComponentAuthorizedOperation,
  DocumentAuthorizedOperation,
  FileAuthorizedOperation
} from '|api/core';
import {InternalNotificationKey} from '|api/notification';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {
  CirculationToastService,
  CirculationToastType
} from '../../../../core/services/notifications/circulation-toast.service';
import {Button} from '../../../button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {ActivityToolbarDisablers} from './activity-toolbar-buttons.disablers';
import {entityTypeToEntityTypeClass} from './task-toolbar-buttons.service';
import {DialogService, IczModalService} from '@icz/angular-modal';
import {
  COMPONENT_ENTITY_TYPES,
  DOCUMENT_ENTITY_TYPES,
  getObjectIcon,
  getObjectLabel
} from '../../shared-document.utils';
import {ExtendedCirculationActivityDto} from '../../model/elastic-extended-entities.interface';
import {
  EsslComponentCirculationToastService
} from '../../../../core/services/notifications/essl-component-circulation-toast.service';
import {ActivityOperationValidators} from './activity-operation-validators';
import {
  BulkOperationValidationService,
  OperationValidator
} from '../../../../services/bulk-operation-validation.service';
import {GeneralAuthorizedOperation} from '../../permissions/permissions.utils';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {ApiCirculationActivityService} from '|api/flow';
import {RevokeTaskDialogComponent} from '../components/cancel-task-dialog/revoke-task-dialog.component';

const DOC_WITHOUT_REF_NUMBER = 'bez čísla jednacího';

export enum ActivityToolbarAction {
  ACTIVITY_REVOKED = 'ACTIVITY_REVOKED',
}

export enum ActivityToolbarView {
  ACTIVITIES_IN_PROGRESS = 'ACTIVITIES_IN_PROGRESS',

}

export interface ActivityToolbarContext {
  viewType: ActivityToolbarView;
  isUnitView?: boolean; // todo(rb) temporary parameter until task solving in unit views is implemented
}

@Injectable()
export class ActivityToolbarButtonsService extends ToolbarProvider<ExtendedCirculationActivityDto, ActivityToolbarAction, ActivityToolbarContext> {

  private modalService = inject(IczModalService);
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private circulationToastService = inject(CirculationToastService);
  private esslComponentCirculationToastService = inject(EsslComponentCirculationToastService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private apiCirculationActivityService = inject(ApiCirculationActivityService);

  getToolbarButtons(selection: Nullable<ExtendedCirculationActivityDto[]>, extraContext: ActivityToolbarContext): Observable<Button[]> {
    const singleEntity = selection?.[0];
    const isIssdActivity = singleEntity?.activityType === CirculationActivityType.DOCUMENT_ISSD_HANDOVER || singleEntity?.activityType === CirculationActivityType.DOCUMENT_ISSD_RETURN_OR_REJECT;

    const buttons: AuthorizedButton[] = [
      {
        label: 'Odvolat úkol',
        icon: 'deactivate',
        show: extraContext.viewType === ActivityToolbarView.ACTIVITIES_IN_PROGRESS && (!isIssdActivity),
        authorizedOperations: [
          DocumentAuthorizedOperation.DOCUMENT_REVOKE_TASK,
          FileAuthorizedOperation.FILE_REVOKE_TASK,
          ComponentAuthorizedOperation.COMPONENT_REVOKE_TASK,
        ],
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
          ActivityToolbarDisablers.isSomeActivityNotInProgress(selection),
          ActivityToolbarDisablers.isRejectedHandoverActivity(selection),
        ],
        action: button => this.onRevokeActivityClick(
          selection!,
          [
            ActivityOperationValidators.isActivityNotInProgress(),
            ActivityOperationValidators.isRejectedHandoverActivity(),
          ],
          button.authorizedOperations
        )
      },
    ];

    if (selection && selection.length === 1) {
      return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
        {
          [AuthorizedEntityType.DOCUMENT]: singleEntity?.documentId,
          [AuthorizedEntityType.FILE]: singleEntity?.fileId,
          [AuthorizedEntityType.COMPONENT]: singleEntity?.componentId,
        },
        of(buttons),
      );
    } else {
      return of(this.authorizedButtonService.evaluateButtonDefinition(buttons));
    }
  }

  onRevokeActivityClick(
    selectionData: ExtendedCirculationActivityDto[],
    validators: OperationValidator<ExtendedCirculationActivityDto>[],
    authorizedOperations?: GeneralAuthorizedOperation[]
  ) {
    const revokeActivities = (validatedObjects: ExtendedCirculationActivityDto[]) => {
      this.modalService.openComponentInModal<Nullable<string>, ExtendedCirculationActivityDto[]>({
        component: RevokeTaskDialogComponent,
        modalOptions: {
          width: 650,
          height: 500,
          titleTemplate: validatedObjects.length > 1 ? 'Hromadné odvolání úkolů ({{count}}) ' : 'Odvolání úkolu',
          titleTemplateContext: {
            count: validatedObjects.length
          }
        },
        data: validatedObjects,
      }).subscribe(result => {
        if (result) {
          if (validatedObjects.length === 1) {
            this.dispatchActivityRevokedToast(validatedObjects[0], result);
          }
          else {
            const templateData = {
              [InternalNotificationKey.COUNT]: String(validatedObjects.length),
            };
            this.circulationToastService.dispatchBulkTaskToast(templateData, CirculationToastType.FLOW_BULK_REVOKE);
          }

          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(ActivityToolbarAction.ACTIVITY_REVOKED);
        }
      });
    };

    const activityIds = selectionData.map(d => d.id);

    this.globalLoadingService.doLoading(this.apiCirculationActivityService.circulationActivityCheckRevokePreconditions({body: activityIds})).subscribe(preconditionResults => {
      const isBulkAction = activityIds.length > 1;

      if (isBulkAction) {
        validators.push(ActivityOperationValidators.isUnrevokableActivity(preconditionResults));

        this.bulkOperationValidationService.validateEsslObjects<ExtendedCirculationActivityDto>(
          {
            dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést odvolání úkolu. Hromadné odvolání úkolu bude provedeno pro vyhující objekty ({{successCount}}).',
            dialogWarningLabelContext: {},
            operationValidators: validators,
            esslObjects: selectionData.map(dd => this.circulationActivityToValidationObject(dd)),
            authorizedOperations,
          },
          this,
        ).subscribe(validatedObjects => {
          if (validatedObjects && validatedObjects.length > 0) {
            revokeActivities(validatedObjects);
          }
        });
      }
      else {
        const validatorResult = ActivityOperationValidators.isUnrevokableActivity(preconditionResults)(selectionData[0]);

        if (validatorResult === null) {
          revokeActivities(selectionData);
        }
        else {
          this.dialogService.showError(validatorResult!.validationMessage!);
        }
      }
    });
  }

  circulationEntityTypeToEntityId(object: ExtendedCirculationActivityDto): number {
    if (DOCUMENT_ENTITY_TYPES.includes(object.circulationEntityType)) {
      return object.documentId!;
    } else if (COMPONENT_ENTITY_TYPES.includes(object.circulationEntityType)) {
      return object.componentId!;
    } else {
      return object.fileId!;
    }
  }

  circulationEntityTypeToAuthorizedEntityType(circulationEntityType: EntityType): AuthorizedEntityType {
    if (DOCUMENT_ENTITY_TYPES.includes(circulationEntityType)) {
      return AuthorizedEntityType.DOCUMENT;
    } else if (COMPONENT_ENTITY_TYPES.includes(circulationEntityType)) {
      return AuthorizedEntityType.COMPONENT;
    } else {
      return AuthorizedEntityType.FILE;
    }
  }

  private circulationActivityToValidationObject(object: ExtendedCirculationActivityDto) {
    return {
      entityId: this.circulationEntityTypeToEntityId(object),
      authorizedEntityType: this.circulationEntityTypeToAuthorizedEntityType(object.circulationEntityType),
      entityIcon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      entityData: object,
      entityName: getObjectLabel(this.translateService, object),
    };
  }

  private dispatchActivityRevokedToast(activityData: ExtendedCirculationActivityDto, response: string) {
    if (COMPONENT_ENTITY_TYPES.includes(activityData.circulationEntityType)) {
      const templateData = {
        [InternalNotificationKey.ENTITY_TYPE]: activityData.circulationEntityType.toLowerCase(),
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: activityData.componentLabel!,
        [InternalNotificationKey.DOCUMENT_ID]: activityData.documentId!,
        [InternalNotificationKey.ESSL_COMPONENT_ID]: activityData.componentId!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: activityData.digitalComponentVersionId!,
        [InternalNotificationKey.ACTIVITY_ID]: activityData.id,
        [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${activityData.activityType}`),
      };

      this.esslComponentCirculationToastService.dispatchEsslComponentRevokeInitiator(templateData);
    }
    else {
      const templateData = {
        [InternalNotificationKey.ACTIVITY_ID]: activityData.id,
        [InternalNotificationKey.REASON]: response,
        [InternalNotificationKey.DOCUMENT_ID]: String(activityData.documentId),
        [InternalNotificationKey.DOCUMENT_SUBJECT]: activityData.subject,
        [InternalNotificationKey.REF_NUMBER]: activityData.refNumber ?? this.translateService.instant(DOC_WITHOUT_REF_NUMBER),
        [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${activityData.activityType}`),
        [InternalNotificationKey.ENTITY_TYPE_CLASS]: entityTypeToEntityTypeClass(activityData.circulationEntityType),
      };

      if (
        activityData.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
        activityData.activityType === CirculationActivityType.FILE_HANDOVER
      ) {
        this.circulationToastService.dispatchHandoverTaskRevokedInitiator(templateData);
      }
      else {
        this.circulationToastService.dispatchTaskRevokedInitiator(templateData);
      }
    }
  }

}
