<div
  class="wrapper"
  [class.icz-required]="isRequired$ | async"
  [class.icz-invalid]="isInvalid$ | async"
  [class.icz-important]="isImportant$ | async"
  >

  <ng-content></ng-content>

  @if (showValidationStatus) {
    <div class="validation-errors">
      @if (isInvalid$ | async) {
        <div [ngClass]="{'icz-validation-focusable-area': useFocusableArea}">
          @for (error of getErrors('control'); track error) {
            <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{error:error}"></ng-container>
          }
        </div>
      }
      @if (form) {
        <div>
          @if (isFormInvalid$ | async) {
            <div>
              @if (showFieldErrors) {
                <div>
                  @for (field of fieldsValidationErrors; track field) {
                    <div class="icz-error flex field-error">
                      <div>{{field.fieldName | translate }}:</div>
                      @for (error of getFieldErrors(field); track error) {
                        <div>
                          <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{error:error}"></ng-container>
                        </div>
                      }
                    </div>
                  }
                </div>
              }
              @else {
                @if (getErrors('form')?.length) {
                  @for (error of getErrors('form'); track error) {
                    <ng-container class="icz-error">
                      <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{error:error}"></ng-container>
                    </ng-container>
                  }
                }
              }
            </div>
          }
        </div>
      }
      @if (array) {
        <div>
          @if (isArrayInvalid$ | async) {
            <div>
              @for (error of getErrors('array'); track error) {
                <ng-container class="icz-error">
                  <ng-container [ngTemplateOutlet]="content" [ngTemplateOutletContext]="{error:error}"></ng-container>
                </ng-container>
              }
            </div>
          }
        </div>
      }
    </div>
  }
</div>


<ng-template #content let-error='error'>
  @if (wrapInAlert) {
    @if (!customAlertHeading) {
      <icz-alert severity="error" [heading]="error.errorMessageTemplate | translate | interpolate:error.validationErrors"
        [alertMode]="getAlertMode()"
        >
      </icz-alert>
    }
    @if (customAlertHeading) {
      <icz-alert severity="error" [heading]="customAlertHeading"
        [alertMode]="getAlertMode()"
        [label]="error.errorMessageTemplate | translate | interpolate:error.validationErrors">
      </icz-alert>
    }
  }
  @else {
    <div class="icz-error">
      {{ error.errorMessageTemplate | translate | interpolate:error.validationErrors }}
    </div>
  }

</ng-template>
