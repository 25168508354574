import {Directive, HostBinding, Input} from '@angular/core';

/**
 * A simple directive which turns directive host element contents text color
 * red when a deadline passed to @Input deadlineDate is in the past.
 */
@Directive({
  selector: '[iczPassedDeadlineWarning]',
  standalone: true,
})
export class PassedDeadlineWarningDirective {

  /**
   * Deadline date string.
   * Must be ISO 8601-formatted. Can also be a datetime string.
   */
  @Input({alias: 'iczPassedDeadlineWarning', required: true})
  deadlineDate!: string;

  @HostBinding('class.text-danger')
  protected get isDeadlinePassed(): boolean {
    return Number(new Date()) > Number(new Date(this.deadlineDate));
  }

}
