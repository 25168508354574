<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <icz-form-autocomplete label="Zvolte obálku spisové rozluky" formControlName="envelopeId" [options]="availableEnvelopes$ | async"></icz-form-autocomplete>
</form>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Předat"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
