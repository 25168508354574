import {Component, inject, OnInit} from '@angular/core';
import {filter, Observable, of, take} from 'rxjs';
import {CirculationActivityType, CirculationTaskResolution} from '|api/commons';
import {Router} from '@angular/router';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {CirculationSearchService} from '../../../services/circulation-search.service';
import {CurrentSessionService} from '../../../services/current-session.service';
import {AuthService} from '../../../core/authentication/auth.service';
import {OwnershipTransferRoutesEnum} from '../../../enums/ownership-transfer-routes.enum';
import {FilterOperator, getDefaultSearchParams} from '@icz/angular-table';
import {isLeader, isSecretariat} from '../../../core/model/user-roles.model';
import {findMenuItemByViewId, MainMenuCountsService} from '../../../core/services/main-menu-counts.service';
import {map} from 'rxjs/operators';
import {DocumentSearchService} from '../../../services/document-search.service';
import {MenuItemCountViewIds} from '../../../core/routing/menu-item-count-view-ids';
import {getStartOfTheDay} from '@icz/angular-form-elements';
import {getTasksAfterDeadlineFilter} from '../dashboard-officer/dashboard-officer.component';


@Component({
  selector: 'icz-dashboard-org-unit',
  templateUrl: './dashboard-org-unit.component.html',
  styleUrls: ['./dashboard-org-unit.component.scss'],
})
export class DashboardOrgUnitComponent implements OnInit {

  private circulationSearchService = inject(CirculationSearchService);
  private currentSessionService = inject(CurrentSessionService);
  private authService = inject(AuthService);
  private router = inject(Router);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private documentSearchService = inject(DocumentSearchService);

  toSignTaskCount$: Observable<number> = of(0);
  hasToSignTasksAfterDeadline$ = this.circulationSearchService.countTasksGlobally({...getDefaultSearchParams(), size: 0, filter: [...getTasksAfterDeadlineFilter(), {fieldName: 'activityType', operator: FilterOperator.equals, value: CirculationActivityType.COMPONENT_SIGNING}]}, {isUnitView: true}).pipe(
    map(taskCount => taskCount > 0),
  );
  toApproveTaskCount$: Observable<number> = of(0);
  hasToApproveTasksAfterDeadline$ = this.circulationSearchService.countTasksGlobally({...getDefaultSearchParams(), size: 0, filter: [...getTasksAfterDeadlineFilter(), {fieldName: 'activityType', operator: FilterOperator.equals, value: CirculationActivityType.COMPONENT_APPROVAL}]}, {isUnitView: true}).pipe(
    map(taskCount => taskCount > 0),
  );
  hasTasksAfterDeadline$ = this.circulationSearchService.countTasksGlobally({...getDefaultSearchParams(), size: 0, filter: getTasksAfterDeadlineFilter()}, {isUnitView: true}).pipe(
    map(taskCount => taskCount > 0),
  );
  unitTaskCount$: Observable<number> = of(0);
  unitExpiringDocumentsCount$: Observable<number> = of(0);
  unitExpiredDocumentsCount$: Observable<number> = of(0);
  forUnitDeliveryConfirmationConsignmentsCount$: Observable<number> = of(0);

  readonly toSignTaskRoute = createAbsoluteRoute(ApplicationRoute.UNIT, DocumentsRoute.SIGNATURE_BOOK, {view: 'toSign'});
  readonly toApproveTaskRoute = createAbsoluteRoute(ApplicationRoute.UNIT, DocumentsRoute.SIGNATURE_BOOK, {view: 'toApprove'});
  readonly ownershipTransferTaskRoute = createAbsoluteRoute(ApplicationRoute.ADMIN, OwnershipTransferRoutesEnum.OWNERSHIP_TRANSFER,OwnershipTransferRoutesEnum.OWNERSHIP_TRANSFER_CREATE);
  readonly unitDocumentTasksRoute = createAbsoluteRoute(ApplicationRoute.UNIT, DocumentsRoute.TASKS);
  readonly unitDocumentsExpiringRoute = createAbsoluteRoute(ApplicationRoute.UNIT, DocumentsRoute.EXPIRING);

  showCardsForLeader = false;
  showCardsForSecretariat = false;

  ngOnInit() {
    this.currentSessionService.currentUserFunctionalPosition$.pipe(
      filter(_ => this.authService.isAuthenticatedWithFunctionalPosition),
      take(1),
    ).subscribe(currentUserInfo => {
      if (currentUserInfo) {
        if (isLeader(currentUserInfo)) {
          this.loadCountsForLeader();
          this.showCardsForLeader = true;
        }
        if (isSecretariat(currentUserInfo)){
          this.showCardsForSecretariat = true;
          this.loadCountsForSecretariat();
        }
      }
    });
  }

  loadCountsForLeader() {
    this.loadUnitTasksCounter();
    const triggerDate = getStartOfTheDay();
    triggerDate.setDate(triggerDate.getDate() + 3);
    this.unitExpiringDocumentsCount$ = this.documentSearchService.countExpiringDocuments({...getDefaultSearchParams(), size: 0, filter: []}, triggerDate, {isUnitView: true});
    this.unitExpiredDocumentsCount$ = this.documentSearchService.countExpiredDocuments({...getDefaultSearchParams(), size: 0, filter: []}, {isUnitView: true});
    this.loadSigningTaskCounter();
    this.loadApproveTaskCounter();
  }

  loadCountsForSecretariat() {
    this.loadUnitTasksCounter();
    this.forUnitDeliveryConfirmationConsignmentsCount$ = this.mainMenuCountsService.menuItemsWithCounts$.pipe(
    map(menuItems => menuItems ? findMenuItemByViewId(menuItems, MenuItemCountViewIds.UNIT_DISPATCH_OFFICER_VIEW_RECORD_DELIVERY_RESULT)?.count ?? 0 : 0));
  }

  loadUnitTasksCounter() {
    this.unitTaskCount$ = this.mainMenuCountsService.menuItemsWithCounts$.pipe(map(menuItems => menuItems ? findMenuItemByViewId(menuItems, MenuItemCountViewIds.UNIT_TASKS)?.count ?? 0 : 0));
  }

  loadSigningTaskCounter() {
    const signingTaskSearchParams = {...getDefaultSearchParams(), size: 0};
    signingTaskSearchParams.filter = [
      {
        fieldName: 'activityType',
        operator: FilterOperator.equals,
        value: CirculationActivityType.COMPONENT_SIGNING
      },
      {
        fieldName: 'ownerFunctionalPositionId',
        operator: FilterOperator.equals,
        value: String(this.currentSessionService.currentUserFunctionalPosition?.id)
      },
      {
        fieldName: 'resolution',
        operator: FilterOperator.inSet,
        value: String([CirculationTaskResolution.IN_PROGRESS, CirculationTaskResolution.ASSIGNED])
      },
      {
        fieldName: 'isManagementTask',
        operator: FilterOperator.equals,
        value: 'false'
      },
    ];
    this.toSignTaskCount$ = this.circulationSearchService.countTasksGlobally(signingTaskSearchParams);
  }

  loadApproveTaskCounter() {
    const approveSearchParams = {...getDefaultSearchParams(), size: 0};
    approveSearchParams.filter = [
      {
        fieldName: 'activityType',
        operator: FilterOperator.equals,
        value: CirculationActivityType.COMPONENT_APPROVAL
      },
      {
        fieldName: 'ownerFunctionalPositionId',
        operator: FilterOperator.equals,
        value: String(this.currentSessionService.currentUserFunctionalPosition?.id)
      },
      {
        fieldName: 'resolution',
        operator: FilterOperator.inSet,
        value: String([CirculationTaskResolution.IN_PROGRESS, CirculationTaskResolution.ASSIGNED])
      },
      {
        fieldName: 'isManagementTask',
        operator: FilterOperator.equals,
        value: 'false'
      },
    ];

    this.toApproveTaskCount$ = this.circulationSearchService.countTasksGlobally(approveSearchParams);
  }

  goToPage(route: string) {
    this.router.navigateByUrl(route);
  }
}
