@if (form) {
  <div [waiting]="loadingIndicatorService.isLoading$(this) | async">
    <ng-container [formGroup]="form">
      @if (isPaperComponentsForm) {
        <ng-container formGroupName="paperComponents">
          @for (component of paperComponentsControl.controls; track component) {
            <div [formGroupName]="$index">
              <div class="row justify-end">
                @if ($index > 0 || config?.allowDeleteFirst) {
                  <icz-button background="transparent" size="small" svgIcon="delete" class="mt-4" (onAction)="deleteAnalogComponentDraft($index)"></icz-button>
                }
              </div>
              <div class="row">
                <icz-form-autocomplete formControlName="relationType" class="grow-5" label="Vztah k dokumentu"
                  [options]="esslComponentRelationOptions" (valueChange)="onRelationTypeChange($event, $index)"
                ></icz-form-autocomplete>
                @if (config?.showFieldOrigin) {
                  <icz-form-autocomplete label="Původ" formControlName="originType" class="grow-5"
                  [options]="analogOriginTypeOptions"></icz-form-autocomplete>
                }
                @if (!isComponentMainComponent($index)) {
                  <icz-form-field
                    type="integer"
                    formControlName="count" class="grow-5"
                    [label]="isAnalogCompositionSheets($index) ? 'Počet příloh' : 'Počet svazků'"
                  ></icz-form-field>
                }
                @if (isStandaloneCreate) {
                  <div class="row">
                    @if (!isComponentMainComponent($index)) {
                      <icz-form-autocomplete formControlName="compositionType" label="Skladba" class="grow-5"
                      [options]="analogCompositionTypeOptions"></icz-form-autocomplete>
                    }
                    @if (isAnalogCompositionSheets($index)) {
                      <icz-form-field label="Počet listů" type="integer"
                      formControlName="numberOfSheets" class="grow-5"></icz-form-field>
                    }
                  </div>
                }
              </div>
              @if (!isStandaloneCreate) {
                <div class="row">
                  @if (!isComponentMainComponent($index)) {
                    <icz-form-autocomplete formControlName="compositionType" label="Skladba" class="grow-5"
                    [options]="analogCompositionTypeOptions"></icz-form-autocomplete>
                  }
                  @if (isAnalogCompositionSheets($index)) {
                    <icz-form-field label="Počet listů" type="integer"
                    formControlName="numberOfSheets" class="grow-5"></icz-form-field>
                  }
                </div>
              }
              <div class="row">
                @if (isDetailedInput) {
                  <icz-form-field formControlName="label" class="grow-2" label="Název komponenty"
                  ></icz-form-field>
                }
                @if (isDetailedInput) {
                  <icz-form-autocomplete label="Ochranný prvek" formControlName="protectiveElementSet" [isMultiselect]="true" class="grow-1"
                  [options]="protectiveElementOptions"></icz-form-autocomplete>
                }
              </div>
              <div class="row mb-8 items-center">
                @if (isDetailedInput) {
                  <icz-form-field formControlName="description" class="grow-4" label="Popis"></icz-form-field>
                }
                <a class="icz-body-1 visible-clickable-area" iczLinkWithoutHref (click)="emitSelectFiles($index)">
                  <span>{{ 'Nahrát naskenovaný obsah' | translate }}</span>
                </a>
                @if (config?.showFieldIsFinal) {
                  <icz-checkbox formControlName="isFinal" class="grow-1" label="Komponenta je finální"></icz-checkbox>
                }
              </div>
              @if (component.value?.status !== ComponentUploadStatus.IDLE) {
                <div class="row mb-16">
                  <!--          todo (lp) reuse from digital component upload, there are different conditions for getIcon etc. and different formGroup structure tho -->
                  <div class="row justify-center">
                    @if (showSpinner($any(component)); as icon) {
                      <icz-spinner [diameter]="24"></icz-spinner>
                    }
                    @if (getIcon($any(component)); as icon) {
                      <icz-icon [svgIcon]="icon"></icz-icon>
                    }
                  </div>
                  <icz-label class="font-bold" dontTranslate [label]="component.value?.digitalComponentVersionDto?.fileName"></icz-label>
                  <icz-label class="filesize" dontTranslate [label]="component.value?.digitalComponentVersionDto?.fileSize | fileSize"></icz-label>
                  @if ($index > 0 || config?.allowDeleteFirst) {
                    <icz-button background="transparent" size="small" svgIcon="delete" class="mt-4" (onAction)="deleteDigitalComponentDraft($index)"></icz-button>
                  }
                </div>
              }
              <hr [hidden]="$last" class="mt-16" [ngClass]="{'mb-8': !isStandaloneCreate, 'mb-32': isStandaloneCreate}">
            </div>
          }
        </ng-container>
      }
      @if (isMediumComponentsForm || isPhysicalItemComponentsForm) {
        <ng-container [formGroupName]="isMediumComponentsForm ? 'mediumComponents' : 'physicalItemComponents'"
          >
          @for (component of mediumOrPhysicalItemsControl.controls; track component) {
            <div [formGroupName]="$index">
              <div class="row justify-end">
                @if ($index > 0 || config?.allowDeleteFirst) {
                  <icz-button background="transparent" size="small" svgIcon="delete" class="mt-4" (onAction)="deleteAnalogComponentDraft($index)"></icz-button>
                }
              </div>
              <div class="row">
                @if (isDetailedInput) {
                  <icz-form-field formControlName="label" class="grow-3" label="Název komponenty"></icz-form-field>
                }
                <icz-form-chip-input formControlName="type" class="grow-2" chipNamespace="icz-analog-component-create-form" label="Druh" [options]="analogComponentTypesOptions" [isMultiselect]="false"></icz-form-chip-input>
              </div>
              <div class="row mb-8 items-center">
                @if (isDetailedInput) {
                  <icz-form-field formControlName="description" class="grow-8" label="Popis"></icz-form-field>
                }
              </div>
              <div class="row">
                @if (config?.showFieldIsFinal) {
                  <icz-checkbox formControlName="isFinal" class="grow-1" label="Komponenta je finální"></icz-checkbox>
                }
              </div>
              @if (isStandaloneCreate && isMediumComponentsForm) {
                <icz-digital-components-upload-form [form]="form"
                  [config]="digitalComponentsConfig"
                  [selectFiles]="selectFilesForDigitalComponents"
                  [autofillName]="getAutofillName($index)"
                  class="grow wide ">
                </icz-digital-components-upload-form>
                <div class="row mt-16">
                  <icz-button data-cy="digitalComponentComponent" (onAction)="selectFilesForDigitalComponents.emit()"
                    label="Nahrát digitalní komponenty">
                  </icz-button>
                </div>
              }
              <hr [hidden]="$last" class="mt-16 mb-16">
            </div>
          }
        </ng-container>
      }
    </ng-container>
  </div>
}

<input type="file" #fileUpload (change)="setDigitalContentForComponent()"
  [multiple]="false" data-cy="doc-digital-component-file-input" [hidden]="true">
