<mat-accordion>
  <mat-expansion-panel class="tree-expansion-panel" [expanded]="isMenuExpanded" #expansionPanel>

    <mat-expansion-panel-header collapsedHeight="3rem" expandedHeight="3rem" (click)="menuPanelClicked()">
      <div class="justify-between row grow">
        <icz-button [svgIcon]="menuIcon" background="transparent" disableRipple [label]="menuLabel"></icz-button>

        @if (!isExpanded) {
          <icz-icon svgIcon="after" size="small" class="expansion-icon"></icz-icon>
        }
        @if (isExpanded) {
          <icz-icon svgIcon="expand_more" size="small" class="expansion-icon"></icz-icon>
        }
      </div>
    </mat-expansion-panel-header>

    <ng-content></ng-content>

    @if (isEmpty) {
      <div class="row">
        <div class="empty-label">{{ isEmptyText | translate }}</div>
      </div>
    }

  </mat-expansion-panel>
</mat-accordion>
