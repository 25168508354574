import {Component, inject, Input} from '@angular/core';
import {DeliveryServiceAdditionalInfoDto, DeliveryServiceCombinationDto} from '|api/codebook';
import {EpaPaService} from '../../../../epa-pa.service';

@Component({
  selector: 'icz-delivery-service-combination-info',
  templateUrl: './delivery-service-combination-info.component.html',
  styleUrls: ['./delivery-service-combination-info.component.scss'],
  providers: [
    EpaPaService
  ]
})
export class DeliveryServiceCombinationInfoComponent {

  protected epaPpaService = inject(EpaPaService);

  @Input({required: true})
  deliveryServiceCombination: Nullable<DeliveryServiceCombinationDto>;

  additionalServiceEpa(additionalService: DeliveryServiceAdditionalInfoDto): string {
    return String(additionalService.epa) === 'false' ? 'Nedostupný' : additionalService.epa!;
  }

}
