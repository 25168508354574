import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {InternalNotificationKey} from '|api/notification';
import {IdentifierGeneratorType} from '|api/records';
import {
  getToastHeaderTemplateTranslateKey,
  MessageType,
  SErrorParameters,
  SImplicitlyStringifiable,
  ToastService
} from '../../../components/notifications/toast.service';

// just add properties as needed
export const SAdminTemplateData = z.object({
  // let's use 'name' and fill with prop as needed, to avoid dozens of separate translate keys for different types of classifier
  [InternalNotificationKey.NAME]: z.optional(SImplicitlyStringifiable),
});
export type AdminTemplateData = z.infer<typeof SAdminTemplateData>;

export const SAdminErrorTemplateData = z.intersection(
  SAdminTemplateData,
  z.object({
    [InternalNotificationKey.ERROR_DESCRIPTION]: z.optional(SImplicitlyStringifiable),
    [InternalNotificationKey.ERROR_PARAMETERS]: z.optional(SErrorParameters),
  })
);
export type AdminErrorTemplateData = z.infer<typeof SAdminErrorTemplateData>;

export const SCrossReferenceTemplateData = z.intersection(
  SAdminTemplateData,
  z.object({
    [InternalNotificationKey.CROSS_REFERENCE_TEMPLATE_NAME]: SImplicitlyStringifiable,
  })
);
export type CrossReferenceTemplateData = z.infer<typeof SCrossReferenceTemplateData>;

export const SCrossReferenceErrorTemplateData = z.intersection(
  SCrossReferenceTemplateData,
  SAdminErrorTemplateData,
);
export type CrossReferenceErrorTemplateData = z.infer<typeof SCrossReferenceErrorTemplateData>;

export const SDocumentTypeImportSuccessTemplateData = z.object({
  [InternalNotificationKey.DOCUMENT_TYPE_CODE]: SImplicitlyStringifiable,
});
export type DocumentTypeImportSuccessTemplateData = z.infer<typeof SDocumentTypeImportSuccessTemplateData>;

export const SDocumentTypeImportErrorTemplateData = z.object({
  [InternalNotificationKey.DOCUMENT_TYPE_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type DocumentTypeImportErrorTemplateData = z.infer<typeof SDocumentTypeImportErrorTemplateData>;


export enum AdminToastType {
  FILE_CONFIGURATION_UPDATED = 'FILE_CONFIGURATION_UPDATED',
  FILE_CONFIGURATION_UPDATE_ERROR = 'FILE_CONFIGURATION_UPDATE_ERROR',
  GENERATOR_CREATED_SUCCESS = 'GENERATOR_CREATED_SUCCESS',
  GENERATOR_UPDATED_SUCCESS = 'GENERATOR_UPDATED_SUCCESS',
  SUBJECTS_DISPOSAL_UPDATED = 'SUBJECTS_DISPOSAL_UPDATED',
  SUBJECTS_DISPOSAL_UPDATE_ERROR = 'SUBJECTS_DISPOSAL_UPDATE_ERROR',
  DATA_BOX_CONFIGURATION_CREATED = 'DATA_BOX_CONFIGURATION_CREATED',
  DATA_BOX_CONFIGURATION_UPDATED = 'DATA_BOX_CONFIGURATION_UPDATED',
  DATA_BOX_CONFIGURATION_UPDATE_ERROR = 'DATA_BOX_CONFIGURATION_UPDATE_ERROR',
  PAPER_DISTRIBUTION_NODE_CREATED = 'PAPER_DISTRIBUTION_NODE_CREATED',
  PAPER_DISTRIBUTION_NODE_UPDATED = 'PAPER_DISTRIBUTION_NODE_UPDATED',
  PAPER_DISTRIBUTION_NODE_UPDATE_ERROR = 'PAPER_DISTRIBUTION_NODE_UPDATE_ERROR',
  EMAIL_UPLOAD_DISTRIBUTION_NODE_CREATED = 'EMAIL_UPLOAD_DISTRIBUTION_NODE_CREATED',
  EMAIL_UPLOAD_DISTRIBUTION_NODE_UPDATED = 'EMAIL_UPLOAD_DISTRIBUTION_NODE_UPDATED',
  EMAIL_UPLOAD_DISTRIBUTION_NODE_CREATE_ERROR = 'EMAIL_UPLOAD_DISTRIBUTION_NODE_UPDATE_ERROR',
  EMAIL_UPLOAD_DISTRIBUTION_NODE_UPDATE_ERROR = 'EMAIL_UPLOAD_DISTRIBUTION_NODE_UPDATE_ERROR',
  OFFICE_DESK_NODE_CREATED = 'OFFICE_DESK_NODE_CREATED',
  OFFICE_DESK_NODE_UPDATED = 'OFFICE_DESK_NODE_UPDATED',
  OFFICE_DESK_NODE_UPDATE_ERROR = 'OFFICE_DESK_NODE_UPDATE_ERROR',
  EMAIL_CONFIGURATION_UPDATED = 'EMAIL_CONFIGURATION_UPDATED',
  EMAIL_CONFIGURATION_UPDATE_ERROR = 'EMAIL_CONFIGURATION_UPDATE_ERROR',
  BASIC_SETTINGS_UPDATED = 'BASIC_SETTINGS_UPDATED',
  BASIC_SETTINGS_UPDATE_ERROR = 'BASIC_SETTINGS_UPDATE_ERROR',
  CIRCULATION_SETTINGS_UPDATED = 'CIRCULATION_SETTINGS_UPDATED',
  CIRCULATION_SETTINGS_UPDATE_ERROR = 'CIRCULATION_SETTINGS_UPDATE_ERROR',
  EMAIL_BOX_UPDATED = 'EMAIL_BOX_UPDATED',
  EMAIL_BOX_UPDATE_ERROR = 'EMAIL_BOX_UPDATE_ERROR',
  EMAIL_BOX_CREATED = 'EMAIL_BOX_CREATED',
  CROSS_REFERENCE_TEMPLATE_UPDATED = 'CROSS_REFERENCE_TEMPLATE_UPDATED',
  CROSS_REFERENCE_TEMPLATE_UPDATE_ERROR = 'CROSS_REFERENCE_TEMPLATE_UPDATE_ERROR',
  CROSS_REFERENCE_TEMPLATE_CREATED = 'CROSS_REFERENCE_TEMPLATE_CREATED',
  CROSS_REFERENCE_TEMPLATE_CREATE_ERROR = 'CROSS_REFERENCE_TEMPLATE_CREATE_ERROR',
  CROSS_REFERENCE_TEMPLATE_DELETED = 'CROSS_REFERENCE_TEMPLATE_DELETED',
  CROSS_REFERENCE_TEMPLATE_DELETE_ERROR = 'CROSS_REFERENCE_TEMPLATE_DELETE_ERROR',
  EXTERNAL_NOTIFICATION_TEMPLATE_UPDATED = 'EXTERNAL_NOTIFICATION_TEMPLATE_UPDATED',
  EXTERNAL_NOTIFICATION_REJECTION_REASON_TEMPLATE_UPDATED = 'EXTERNAL_NOTIFICATION_REJECTION_REASON_TEMPLATE_UPDATED',
  FOOTER_TEMPLATE_CREATED = 'FOOTER_TEMPLATE_CREATED',
  FOOTER_TEMPLATE_UPDATED = 'FOOTER_TEMPLATE_UPDATED',
  INFORMATION_TEMPLATE_CREATED = 'INFORMATION_TEMPLATE_CREATED',
  INFORMATION_TEMPLATE_UPDATED = 'INFORMATION_TEMPLATE_UPDATED',
  LABEL_TEMPLATE_DELETE_ERROR = 'LABEL_TEMPLATE_DELETE_ERROR',
  LABEL_TEMPLATE_CONFIG_UPDATED = 'LABEL_TEMPLATE_CONFIG_UPDATED',
  LABEL_TEMPLATE_CONFIG_UPDATE_ERROR = 'LABEL_TEMPLATE_CONFIG_UPDATE_ERROR',
  EMAIL_ALLOWED_FORMAT_CREATED = 'EMAIL_ALLOWED_FORMAT_CREATED',
  EMAIL_ALLOWED_FORMAT_CREATE_ERROR = 'EMAIL_ALLOWED_FORMAT_CREATE_ERROR',
  DOCUMENT_DELIVERY_CONFIGURATION_UPDATED = 'DOCUMENT_DELIVERY_CONFIGURATION_UPDATED',
  DOCUMENT_DELIVERY_CONFIGURATION_UPDATE_ERROR = 'DOCUMENT_DELIVERY_CONFIGURATION_UPDATE_ERROR',
  EPDZ_CHANNELS_UPDATED = 'EPDZ_CHANNELS_UPDATED',
  EPDZ_CHANNELS_UPDATE_ERROR = 'EPDZ_CHANNELS_UPDATE_ERROR',
  FUNCTIONAL_POSITION_GROUP_CREATED = 'FUNCTIONAL_POSITION_GROUP_CREATED',
  FUNCTIONAL_POSITION_GROUP_UPDATED = 'FUNCTIONAL_POSITION_GROUP_UPDATED',
  FUNCTIONAL_POSITION_GROUP_DELETED = 'FUNCTIONAL_POSITION_GROUP_DELETED',
  SIGNATURE_SETTINGS_UPDATED = 'SIGNATURE_SETTINGS_UPDATED',
  SIGNATURE_SETTINGS_UPDATE_ERROR = 'SIGNATURE_SETTINGS_UPDATE_ERROR',
  DELIVERY_RESULT_CREATED = 'DELIVERY_RESULT_CREATED',
  DELIVERY_RESULT_UPDATED = 'DELIVERY_RESULT_UPDATED',
  DELIVERY_RESULT_DELETED = 'DELIVERY_RESULT_DELETED',
  TECHNICAL_ATTACHMENTS_UPDATED = 'TECHNICAL_ATTACHMENTS_UPDATED',
  TECHNICAL_ATTACHMENTS_UPDATE_ERROR = 'TECHNICAL_ATTACHMENTS_UPDATE_ERROR',
  DATABOX_ATTACHMENTS_UPDATED = 'DATABOX_ATTACHMENTS_UPDATED',
  DATABOX_ATTACHMENTS_UPDATE_ERROR = 'DATABOX_ATTACHMENTS_UPDATE_ERROR',
  ADMIN_SETTINGS_UPDATED = 'ADMIN_SETTINGS_UPDATED',
  ADMIN_SETTINGS_UPDATE_ERROR = 'ADMIN_SETTINGS_UPDATE_ERROR',
  CLASSIFIER_CREATE_SUCCESS = 'CLASSIFIER_CREATE_SUCCESS',
  CLASSIFIER_UPDATE_SUCCESS = 'CLASSIFIER_UPDATE_SUCCESS',
  CLASSIFIER_UPDATE_ERROR = 'CLASSIFIER_UPDATE_ERROR',
  FILING_UPDATED_SUCCESS = 'FILING_UPDATED_SUCCESS',
  FILING_CREATED_SUCCESS = 'FILING_CREATED_SUCCESS',
  SEPARATE_FILING_CREATED_SUCCESS = 'SEPARATE_FILING_CREATED_SUCCESS',
  SEPARATE_FILING_UPDATED_SUCCESS = 'SEPARATE_FILING_UPDATED_SUCCESS',
  OWN_CONSIGNMENT_CONFIGURATION_UPDATED_SUCCESS = 'OWN_CONSIGNMENT_CONFIGURATION_UPDATED_SUCCESS',
  DISPOSAL_SCHEDULE_CREATED_SUCCESS = 'DISPOSAL_SCHEDULE_CREATED_SUCCESS',
  DISPOSAL_SCHEDULE_UPDATED_SUCCESS = 'DISPOSAL_SCHEDULE_UPDATED_SUCCESS',
  SUBSTITUTION_CREATE_SUCCESS = 'SUBSTITUTION_CREATE_SUCCESS',
  SUBSTITUTION_CREATE_ERROR = 'SUBSTITUTION_CREATE_ERROR',
  SUBSTITUTION_UPDATE_SUCCESS = 'SUBSTITUTION_UPDATE_SUCCESS',
  SUBSTITUTION_UPDATE_ERROR = 'SUBSTITUTION_UPDATE_ERROR',
  SUBSTITUTION_DELETE_SUCCESS = 'SUBSTITUTION_DELETE_SUCCESS',
  SUBSTITUTION_DELETE_ERROR = 'SUBSTITUTION_DELETE_ERROR',
  ORG_UNIT_SHARING_CREATE_SUCCESS = 'ORG_UNIT_SHARING_CREATE_SUCCESS',
  ORG_UNIT_SHARING_CREATE_ERROR = 'ORG_UNIT_SHARING_CREATE_ERROR',
  ORG_UNIT_SHARING_UPDATE_SUCCESS = 'ORG_UNIT_SHARING_UPDATE_SUCCESS',
  ORG_UNIT_SHARING_UPDATE_ERROR = 'ORG_UNIT_SHARING_UPDATE_ERROR',
  ORG_UNIT_SHARING_DELETE_SUCCESS = 'ORG_UNIT_SHARING_DELETE_SUCCESS',
  CODEBOOK_ITEM_CREATED = 'CODEBOOK_ITEM_CREATED',
  CODEBOOK_ITEM_CREATE_ERROR = 'CODEBOOK_ITEM_CREATE_ERROR',
  CODEBOOK_ITEM_UPDATED = 'CODEBOOK_ITEM_UPDATED',
  CODEBOOK_ITEM_UPDATE_ERROR = 'CODEBOOK_ITEM_UPDATE_ERROR',
  CODEBOOK_ITEM_DELETED = 'CODEBOOK_ITEM_DELETED',
  CODEBOOK_ITEM_DELETE_ERROR = 'CODEBOOK_ITEM_DELETE_ERROR',
  OWNERSHIP_TRANSFER_SCHEDULE_SUCCESS = 'OWNERSHIP_TRANSFER_SCHEDULE_SUCCESS',
  OWNERSHIP_TRANSFER_SCHEDULE_ERROR = 'OWNERSHIP_TRANSFER_SCHEDULE_ERROR',
  OWNERSHIP_TRANSFER_UNSCHEDULE_SUCCESS = 'OWNERSHIP_TRANSFER_UNSCHEDULE_SUCCESS',
  OWNERSHIP_TRANSFER_UNSCHEDULE_ERROR = 'OWNERSHIP_TRANSFER_UNSCHEDULE_ERROR',
  OWNERSHIP_TRANSFER_RESCHEDULE_SUCCESS = 'OWNERSHIP_TRANSFER_RESCHEDULE_SUCCESS',
  OWNERSHIP_TRANSFER_RESCHEDULE_ERROR = 'OWNERSHIP_TRANSFER_RESCHEDULE_ERROR',
  ENVELOPE_TEMPLATE_CREATED_SUCCESS = 'ENVELOPE_TEMPLATE_CREATED_SUCCESS',
  ENVELOPE_TEMPLATE_CREATED_ERROR = 'ENVELOPE_TEMPLATE_CREATED_ERROR',
  ENVELOPE_TEMPLATE_UPDATED_SUCCESS = 'ENVELOPE_TEMPLATE_UPDATED_SUCCESS',
  ENVELOPE_TEMPLATE_UPDATED_ERROR = 'ENVELOPE_TEMPLATE_UPDATED_ERROR',
  ENVELOPE_TEMPLATE_EXPORTED_SUCCESS = 'ENVELOPE_TEMPLATE_EXPORTED_SUCCESS',
  ENVELOPE_TEMPLATE_EXPORTED_ERROR = 'ENVELOPE_TEMPLATE_EXPORTED_ERROR',
  ENVELOPE_TEMPLATE_IMPORTED_SUCCESS = 'ENVELOPE_TEMPLATE_IMPORTED_SUCCESS',
  ENVELOPE_TEMPLATE_IMPORTED_ERROR = 'ENVELOPE_TEMPLATE_IMPORTED_ERROR',
  ENVELOPE_TEMPLATE_DELETED_SUCCESS = 'ENVELOPE_TEMPLATE_DELETED_SUCCESS',
  ENVELOPE_TEMPLATE_DELETED_ERROR = 'ENVELOPE_TEMPLATE_DELETED_ERROR',
  SHEET_LABEL_TEMPLATE_CREATED_SUCCESS = 'SHEET_LABEL_TEMPLATE_CREATED_SUCCESS',
  SHEET_LABEL_TEMPLATE_CREATED_ERROR = 'SHEET_LABEL_TEMPLATE_CREATED_ERROR',
  SHEET_LABEL_TEMPLATE_UPDATED_SUCCESS = 'SHEET_LABEL_TEMPLATE_UPDATED_SUCCESS',
  SHEET_LABEL_TEMPLATE_UPDATED_ERROR = 'SHEET_LABEL_TEMPLATE_UPDATED_ERROR',
  SHEET_LABEL_TEMPLATE_EXPORTED_SUCCESS = 'SHEET_LABEL_TEMPLATE_EXPORTED_SUCCESS',
  SHEET_LABEL_TEMPLATE_EXPORTED_ERROR = 'SHEET_LABEL_TEMPLATE_EXPORTED_ERROR',
  SHEET_LABEL_TEMPLATE_IMPORTED_SUCCESS = 'SHEET_LABEL_TEMPLATE_IMPORTED_SUCCESS',
  SHEET_LABEL_TEMPLATE_IMPORTED_ERROR = 'SHEET_LABEL_TEMPLATE_IMPORTED_ERROR',
  SHEET_LABEL_TEMPLATE_DELETED_SUCCESS = 'SHEET_LABEL_TEMPLATE_DELETED_SUCCESS',
  SHEET_LABEL_TEMPLATE_DELETED_ERROR = 'SHEET_LABEL_TEMPLATE_DELETED_ERROR',
  AUTHENTICATION_SETTINGS_UPDATED = 'AUTHENTICATION_SETTINGS_UPDATED',
  AUTHENTICATION_SETTINGS_UPDATE_ERROR = 'AUTHENTICATION_SETTINGS_UPDATE_ERROR',
  EXTERNAL_APPLICATION_SETTINGS_CREATED = 'EXTERNAL_APPLICATION_SETTINGS_CREATED',
  EXTERNAL_APPLICATION_SETTINGS_UPDATED = 'EXTERNAL_APPLICATION_SETTINGS_UPDATED',
  EXTERNAL_APPLICATION_SETTINGS_DELETED = 'EXTERNAL_APPLICATION_SETTINGS_DELETED',
  IMPORT_CONFIGURATION_CREATED = 'IMPORT_CONFIGURATION_CREATED',
  IMPORT_CONFIGURATION_UPDATED = 'IMPORT_CONFIGURATION_UPDATED',
  IMPORT_CONFIGURATION_DELETED = 'IMPORT_CONFIGURATION_DELETED',
  ORG_STRUCTURE_SOURCE_SETTINGS_UPDATED = 'ORG_STRUCTURE_SOURCE_SETTINGS_UPDATED',
  ORG_STRUCTURE_SOURCE_SETTINGS_UPDATE_ERROR = 'ORG_STRUCTURE_SOURCE_SETTINGS_UPDATE_ERROR',
  ORG_STRUCTURE_SOURCE_MANUAL_IMPORT_STARTED = 'ORG_STRUCTURE_SOURCE_MANUAL_IMPORT_STARTED',
  STORAGE_UNIT_SETTINGS_UPDATED = 'STORAGE_UNIT_SETTINGS_UPDATED',
  STORAGE_UNIT_SETTINGS_ERROR = 'STORAGE_UNIT_SETTINGS_ERROR',
  EXECUTION_DATE_CLAUSE_SETTINGS_UPDATED = 'EXECUTION_DATE_CLAUSE_SETTINGS_UPDATED',
  EXECUTION_DATE_CLAUSE_SETTINGS_ERROR = 'EXECUTION_DATE_CLAUSE_SETTINGS_ERROR',
  ISZR_AGENDA_CREATED_SUCCESS = 'ISZR_AGENDA_CREATED_SUCCESS',
  ISZR_AGENDA_UPDATED_SUCCESS = 'ISZR_AGENDA_UPDATED_SUCCESS',
  ISZR_AGENDA_DELETED_SUCCESS = 'ISZR_AGENDA_DELETED_SUCCESS',
  ISZR_INTEGRATION_SETTINGS_UPDATED = 'ISZR_INTEGRATION_SETTINGS_UPDATED',
  ISZR_INTEGRATION_SETTINGS_ERROR = 'ISZR_INTEGRATION_SETTINGS_ERROR',
  ISZR_INTEGRATION_SETTINGS_CERTIFICATE_ADDED = 'ISZR_INTEGRATION_SETTINGS_CERTIFICATE_ADDED',
  ISZR_INTEGRATION_SETTINGS_CERTIFICATE_DELETED = 'ISZR_INTEGRATION_SETTINGS_CERTIFICATE_DELETED',
  ERMS_EVENT_RETRY_SUCCESS = 'ERMS_EVENT_RETRY_SUCCESS',
  ERMS_EVENT_RETRY_ERROR = 'ERMS_EVENT_RETRY_ERROR',
  ERMS_EVENT_MARK_AS_FIXED_SUCCESS = 'ERMS_EVENT_MARK_AS_FIXED_SUCCESS',
  ERMS_EVENT_MARK_AS_FIXED_ERROR = 'ERMS_EVENT_MARK_AS_FIXED_ERROR',
  ERMS_BATCH_RESEND_SUCCESS = 'ERMS_BATCH_RESEND_SUCCESS',
  ERMS_BATCH_RESEND_ERROR = 'ERMS_BATCH_RESEND_ERROR',
  CZECH_POINT_SETTINGS_UPDATED = 'CZECH_POINT_SETTINGS_UPDATED',
  CZECH_POINT_SETTINGS_ERROR = 'CZECH_POINT_SETTINGS_ERROR',
  OBJECT_RENDITION_SETTINGS_UPDATED = 'OBJECT_RENDITION_SETTINGS_UPDATED',
  OBJECT_RENDITION_SETTINGS_UPDATE_ERROR = 'OBJECT_RENDITION_SETTINGS_UPDATE_ERROR',
  DIGITAL_COMPONENT_TEMPLATE_CREATED_SUCCESS = 'DIGITAL_COMPONENT_TEMPLATE_CREATED_SUCCESS',
  DIGITAL_COMPONENT_TEMPLATE_CREATED_ERROR = 'DIGITAL_COMPONENT_TEMPLATE_CREATED_ERROR',
  DIGITAL_COMPONENT_TEMPLATE_UPDATED_SUCCESS = 'DIGITAL_COMPONENT_TEMPLATE_UPDATED_SUCCESS',
  DIGITAL_COMPONENT_TEMPLATE_UPDATED_ERROR = 'DIGITAL_COMPONENT_TEMPLATE_UPDATED_ERROR',
  DIGITAL_COMPONENT_TEMPLATE_DELETED_SUCCESS = 'DIGITAL_COMPONENT_TEMPLATE_DELETED_SUCCESS',
  DIGITAL_COMPONENT_TEMPLATE_DELETED_ERROR = 'DIGITAL_COMPONENT_TEMPLATE_DELETED_ERROR',
  DOCUMENT_TYPES_IMPORT_SUCCESS = 'DOCUMENT_TYPES_IMPORT_SUCCESS',
  DOCUMENT_TYPES_IMPORT_ERROR = 'DOCUMENT_TYPES_IMPORT_ERROR',
  DOCUMENT_TYPES_EXPORT_SUCCESS = 'DOCUMENT_TYPES_EXPORT_SUCCESS',
  EMAIL_NOTIFICATION_RULE_CREATED = 'EMAIL_NOTIFICATION_RULE_CREATED',
  EMAIL_NOTIFICATION_RULE_UPDATED = 'EMAIL_NOTIFICATION_RULE_UPDATED',
  EMAIL_NOTIFICATION_RULE_DELETED = 'EMAIL_NOTIFICATION_RULE_DELETED',
  EMAIL_NOTIFICATION_RULE_COPIED = 'EMAIL_NOTIFICATION_RULE_COPIED',
  EMAIL_NOTIFICATION_RULE_CREATE_ERROR = 'EMAIL_NOTIFICATION_RULE_CREATE_ERROR',
  EMAIL_NOTIFICATION_RULE_UPDATE_ERROR = 'EMAIL_NOTIFICATION_RULE_UPDATE_ERROR',
  EMAIL_NOTIFICATION_RULE_DELETE_ERROR = 'EMAIL_NOTIFICATION_RULE_DELETE_ERROR',
  EMAIL_NOTIFICATION_USER_UPDATED = 'EMAIL_NOTIFICATION_USER_UPDATED',
  EMAIL_NOTIFICATION_USER_UPDATE_ERROR = 'EMAIL_NOTIFICATION_USER_UPDATE_ERROR',
  EMAIL_NOTIFICATION_USER_ACTIVATED = 'EMAIL_NOTIFICATION_USER_ACTIVATED',
  EMAIL_NOTIFICATION_USER_DEACTIVATED = 'EMAIL_NOTIFICATION_USER_DEACTIVATED',
  EMAIL_NOTIFICATION_USER_ACTIVATE_ERROR = 'EMAIL_NOTIFICATION_USER_ACTIVATE_ERROR',
  EMAIL_NOTIFICATION_USER_DEACTIVATE_ERROR = 'EMAIL_NOTIFICATION_USER_DEACTIVATE_ERROR',
  NOMENCLATURE_SYNCHRONIZATION_STARTED = 'NOMENCLATURE_SYNCHRONIZATION_STARTED',
  NOMENCLATURE_SYNCHRONIZATION_ERROR = 'NOMENCLATURE_SYNCHRONIZATION_ERROR',
  NOMENCLATURE_SYNCHRONIZATION_FINISHED = 'NOMENCLATURE_SYNCHRONIZATION_FINISHED',
  NOMENCLATURE_SYNCHRONIZATION_FAILED = 'NOMENCLATURE_SYNCHRONIZATION_FAILED',
  MANUAL_RENDITION_STARTED = 'MANUAL_RENDITION_STARTED',
  MANUAL_RENDITION_START_FAILED = 'MANUAL_RENDITION_START_FAILED',
  EMAIL_ALLOWED_FORMAT_UPDATED = 'EMAIL_ALLOWED_FORMAT_UPDATED',
  EMAIL_ALLOWED_FORMAT_UPDATE_ERROR = 'EMAIL_ALLOWED_FORMAT_UPDATE_ERROR',
  CUSTOM_ATTRIBUTE_DEFINITION_CREATED = 'CUSTOM_ATTRIBUTE_DEFINITION_CREATED',
  CUSTOM_ATTRIBUTE_DEFINITION_UPDATED = 'CUSTOM_ATTRIBUTE_DEFINITION_UPDATED',
  AGENDA_TRANSFER_SETTINGS_UPDATED = 'AGENDA_TRANSFER_SETTINGS_UPDATED',
  AGENDA_TRANSFER_SETTINGS_ERROR = 'AGENDA_TRANSFER_SETTINGS_ERROR',
  CUSTOM_FIELD_DEFINITION_CREATED = 'CUSTOM_FIELD_DEFINITION_CREATED',
  CUSTOM_FIELD_DEFINITION_UPDATED = 'CUSTOM_FIELD_DEFINITION_UPDATED',
  STORAGE_UNIT_FILLER_SETTINGS_UPDATE_SUCCESS = 'STORAGE_UNIT_FILLER_SETTINGS_UPDATE_SUCCESS',
  STORAGE_UNIT_FILLER_SETTINGS_UPDATE_ERROR = 'STORAGE_UNIT_FILLER_SETTINGS_UPDATE_ERROR',
  AGENDA_TRANSFER_TESTING_EXPORT_RUN = 'AGENDA_TRANSFER_TESTING_EXPORT_RUN',
  AGENDA_TRANSFER_FINAL_EXPORT_RUN = 'AGENDA_TRANSFER_FINAL_EXPORT_RUN',
}

@Injectable({
  providedIn: 'root'
})
export class AdminToastService {

  private toastService = inject(ToastService);

  private readonly generatorTypeTranslationPrefix = 'en.identifierGeneratorType.';

  dispatchAdminInfoToast(adminToastType: AdminToastType, templateData: AdminTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, adminToastType, templateData, SAdminTemplateData);
  }

  dispatchAdminErrorToast(adminToastType: string, templateData: AdminErrorTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, adminToastType, templateData, SAdminErrorTemplateData);
  }

  dispatchCrossReferenceInfoToast(adminToastType: AdminToastType, templateData: CrossReferenceTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, adminToastType, templateData, SCrossReferenceTemplateData);
  }

  dispatchCrossReferenceErrorToast(adminToastType: string, templateData: CrossReferenceErrorTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, adminToastType, templateData, SCrossReferenceErrorTemplateData);
  }

  dispatchGeneratorSuccessToast(generatorType: IdentifierGeneratorType, adminToastType: AdminToastType) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(adminToastType),
          templateData: {GENERATOR_TYPE: this.generatorTypeTranslationPrefix + generatorType},
          templateDataSchema: z.object({GENERATOR_TYPE: z.string()}),
        },
      },
    });
  }

}
