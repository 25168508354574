import {inject, Injectable} from '@angular/core';
import {combineLatest, forkJoin, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  CirculationTaskType,
  DigitalComponentVersionTypeFlag,
  DocumentForm,
  DocumentState,
  EntityType,
  FileState,
  NodeType,
  RetentionTriggerTypeCode,
  StorageUnitAllowableObjectClass,
} from '|api/commons';
import {DocumentAllowableObjectClass, FileAllowableObjectClass} from '|api/document';
import {PermissionSetLevel} from '|api/core';
import {BasicRegistersService} from '../../../basic-registers.service';
import {getComponentIcon, getUserFullName} from '../../../../../model';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {OrganizationalStructureService} from '../../../../../core/services/organizational-structure.service';
import {
  DisposalScheduleOption,
  disposalSchedulesToOptions,
  EntityClassFilterOption,
  enumToOptions,
  namedDtosToOptions,
  namedDtosToOptionsWithId,
  namedDtoToOption,
} from '../../../../../core/services/data-mapping.utils';
import {isActiveClassificationScheme, isArchiveClassificationScheme} from '../../../../../utils/classification-schemes';
import {IczOption} from '@icz/angular-form-elements';
import {removeDuplicates} from '@icz/angular-essentials';

interface ClassificationSchemeFilterOptionData {
  isActive: boolean;
}

export type ClassificationSchemeFilterOption = IczOption<number, ClassificationSchemeFilterOptionData>;

@Injectable({
  providedIn: 'root'
})
export class DocumentFiltersDataService {

  private codebookService = inject(CodebookService);
  private basicRegistersService = inject(BasicRegistersService);
  private organizationalStructureService = inject(OrganizationalStructureService);

  objectClassOptions: IczOption[] = [
    {
      value: DocumentAllowableObjectClass.RECEIVED_DOCUMENT,
      label: 'Doručený dokument',
      icon: 'doruceny_dokument',
    },
    {
      value: DocumentAllowableObjectClass.RECEIVED_DOCUMENT_DIGITAL,
      label: 'Doručený dokument v elektronické podobě',
      icon: 'doruceny_dokument_digi',
    },
    {
      value: DocumentAllowableObjectClass.OWN_DOCUMENT,
      label: 'Vlastní dokument',
      icon: 'vlastni_dokument',
    },
    {
      value: DocumentAllowableObjectClass.OWN_DOCUMENT_DIGITAL,
      label: 'Vlastní dokument v elektronické podobě',
      icon: 'vlastni_dokument_digi',
    },
    {
      value: FileAllowableObjectClass.FILE,
      label: 'Spis',
      icon: 'spis',
    },
    {
      value: FileAllowableObjectClass.FILE_DIGITAL,
      label: 'Spis v elektronické podobě',
      icon: 'spis_digi',
    },
    {
      value: StorageUnitAllowableObjectClass.STORAGE_UNIT,
      label: 'Ukládací jednotka',
      icon: 'storage_unit',
    },
    {
      value: StorageUnitAllowableObjectClass.STORAGE_UNIT_DIGITAL,
      label: 'Ukládací jednotka v elektronické podobě',
      icon: 'storage_unit_digital',
    },
  ];

  entityStateOptions: IczOption[] = removeDuplicates([
    ...enumToOptions('documentState', DocumentState),
    ...enumToOptions('fileState', FileState),
  ], o => o.value);

  documentFormOptions: IczOption[] = enumToOptions('documentForm', DocumentForm);

  permissionSetLevelOptions: IczOption[] = [
    ...enumToOptions('permissionSetLevel', PermissionSetLevel),
  ];

  taskTypeOptions: IczOption[] = enumToOptions(
    'circulationTaskType',
    CirculationTaskType
  ).filter(o => !(o.value as string)!.includes('MANAGEMENT'));

  digitalComponentTypeOptions: IczOption[] = enumToOptions(
    'digitalComponentVersionTypeFlag',
    DigitalComponentVersionTypeFlag
  ).map(o => ({
    ...o,
    icon: getComponentIcon(EntityType.DIGITAL_COMPONENT, o.value as DigitalComponentVersionTypeFlag), //todo(lp) add analog formats as well
  }));

  functionalPositionTreeOptions = this.organizationalStructureService.functionalPositionTreeOptions();

  organizationalUnitTreeOptions = this.organizationalStructureService.organizationalUnitTreeOptions();

  retentionTriggerTypesOptions: IczOption[] = enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode);

  disposalScheduleOptions(): Observable<DisposalScheduleOption[]> {
    return this.codebookService.disposalSchedules().pipe(
      disposalSchedulesToOptions,
    );
  }

  externalRetentionTriggers(): Observable<IczOption[]> {
    return this.codebookService.externalRetentionTriggers().pipe(
      map(triggers => triggers.map(rt => ({
        value: rt.id!,
        label: `${rt.code} ${rt.description}`,
      })))
    );
  }

  securityCategoryOptions(): Observable<IczOption[]> {
    return this.codebookService.securityCategories().pipe(
      map(categories => categories.map(c => ({
        value: c.id!,
        label: `${c.code} ${c.name}`,
        parent: c.parentId,
      }))),
    );
  }

  documentTypeOptions(): Observable<IczOption[]> {
    return this.codebookService.documentTypes().pipe(
      map(entries => entries.map(e => ({
        value: e.id!,
        parent: e.parentId,
        label: `${e.code} ${e.name}`,
      }))),
    );
  }

  fileTypeOptions(): Observable<IczOption[]> {
    return this.codebookService.fileTypes().pipe(
      map(entries => entries.map(e => ({
        value: e.id!,
        parent: e.parentId,
        label: `${e.code} ${e.name}`,
      }))),
    );
  }

  userOptions(): Observable<IczOption[]> {
    return this.codebookService.users().pipe(
      map(users => users.map(u => ({
        value: u.id,
        label: getUserFullName(u),
      }))),
    );
  }

  entityClassOptionsTree(): Observable<EntityClassFilterOption[]> {
    return combineLatest([
      this.codebookService.classificationSchemes(),
      this.codebookService.entityClasses(),
    ]).pipe(
      map(([classificationSchemes, entityClasses]) => {
        const availableClassificationSchemes = classificationSchemes
          .filter(cs => isActiveClassificationScheme(cs) || isArchiveClassificationScheme(cs));
        const classificationSchemeOptions: EntityClassFilterOption[] = availableClassificationSchemes
          .map(cs => ({
            value: `cs_${cs.id}`,
            label: `${cs.code} ${cs.name}`,
            originId: 'cs',
            id: cs.id,
            isGroup: true,
            autoExpand: Boolean(isActiveClassificationScheme(cs)),
            data: {
              classificationSchemeId: cs.id,
              classificationSchemeName: cs.name,
              fqcCode: null,
            }
          }));

        const availableClassificationSchemeIds = availableClassificationSchemes.map(cs => cs.id);

        const entityClassOptions = entityClasses.filter(ec => {
          return availableClassificationSchemeIds.includes(ec.classificationSchemeId!);
        }).map(ec => {
          return ({
            value: ec.id!,
            label: `${ec.fqcCode} ${ec.name}`,
            originId: 'ec',
            id: ec.id!,
            parent: ec.parentId ?? `cs_${ec.classificationSchemeId!}`,
            data: {
              classificationSchemeId: ec.classificationSchemeId!,
              classificationSchemeName: classificationSchemes.find(cs => cs.id === ec.classificationSchemeId)!.name,
              fqcCode: ec.fqcCode!,
            }
          });
        }).toSorted((x: EntityClassFilterOption, y: EntityClassFilterOption) => {
          if (x.data!.fqcCode === y.data!.fqcCode) {
            return 0;
          }
          else {
            return x.data!.fqcCode! < y.data!.fqcCode! ? -1 : 1;
          }
        });

        return [...classificationSchemeOptions, ...entityClassOptions];
      }),
    );
  }

  classificationSchemeOptions(): Observable<ClassificationSchemeFilterOption[]> {
    return this.codebookService.classificationSchemes().pipe(
      map(classificationSchemes => classificationSchemes.filter(cs => isActiveClassificationScheme(cs) || isArchiveClassificationScheme(cs))),
      map(classificationSchemes => classificationSchemes.map(cs => ({
        ...namedDtoToOption(true)(cs),
        data: {
          isActive: Boolean(isActiveClassificationScheme(cs)),
        }
      }))),
    );
  }

  keywordOptions(): Observable<IczOption[]> {
    return this.codebookService.keywords().pipe(
      map(keywords => keywords.map(
        kw => ({value: kw.id, label: kw.code})
      )),
    );
  }

  basicRegisterOptions() {
    return this.basicRegistersService.getBasicRegisters().pipe(
      namedDtosToOptions,
    );
  }

  deliveryTypeOptions() {
    return this.codebookService.deliveryTypes().pipe(
      map(deliveryTypes => deliveryTypes.filter(dt => dt.nodeType === NodeType.LEAF)),
      namedDtosToOptionsWithId,
    );
  }

  deliveryServiceOptions() {
    return this.codebookService.deliveryServiceCombinations().pipe(
      namedDtosToOptions,
    );
  }

  distributorOptions() {
    return this.codebookService.distributors().pipe(
      map(distributors => distributors.map(d => ({
        value: d.id,
        label: d.description!,
      }))),
    );
  }

  formatOptionTree(): Observable<IczOption[]> {
    return forkJoin([
      this.codebookService.formatGroups(),
      this.codebookService.formats(),
    ]).pipe(
      map(([formatGroups, formats]) => {
        const formatOptionTree: IczOption<string>[] = formatGroups.map(fg => ({
          value: `group_${fg.id}`,
          label: fg.name!,
          id: fg.id!,
          isGroup: true,
        }));

        formatOptionTree.push(...(formats ?? []).map(f => ({
          value: f.puid,
          label: `${f.description} (${f.puid})`,
          parent: f.group?.id ? `group_${f.group.id}` : undefined,
        } as IczOption<string>)));

        return formatOptionTree;
      }),
    );
  }

  issdAppsOptions(): Observable<IczOption[]> {
    return this.codebookService.issdApplications().pipe(
      map(issdApps => issdApps.map(issdApp => ({value: issdApp.id, label: issdApp.name})))
    );
  }

  registryOfficeOptions(): Observable<IczOption[]> {
    return this.codebookService.registryOffices().pipe(
      namedDtosToOptions,
    );
  }

}
