/* tslint:disable */
/* eslint-disable */

/**
 * Failed precondition
 */
export enum CirculationActivityPreconditionFailureType {
  FILE_IN_INVALID_STATE = 'FILE_IN_INVALID_STATE',
  DOCUMENT_IS_NOT_IN_PROGRESS = 'DOCUMENT_IS_NOT_IN_PROGRESS',
  COMPONENT_ALREADY_FINAL = 'COMPONENT_ALREADY_FINAL',
  CURRENT_FP_IS_NOT_OWNER = 'CURRENT_FP_IS_NOT_OWNER',
  CURRENT_FP_IS_NOT_HOLDER = 'CURRENT_FP_IS_NOT_HOLDER',
  CURRENT_FP_IS_NEITHER_OWNER_NOR_HOLDER = 'CURRENT_FP_IS_NEITHER_OWNER_NOR_HOLDER',
  INCOMPATIBLE_FLOW_CURRENTLY_RUNNING = 'INCOMPATIBLE_FLOW_CURRENTLY_RUNNING'
}
