import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { UserInitialsColor } from '@icz/angular-essentials';


@Component({
  selector: 'icz-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationCardComponent {
  @Input()
  isSystemMessage = false;
  @Input()
  originatorInitials = '';

  readonly UserInitialsColor = UserInitialsColor;
}
