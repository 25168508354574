import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {sub} from 'date-fns';
import {debounceTime, filter} from 'rxjs/operators';
import {DateFilterSelectionMode} from '../date-filter/date-filter.models';
import {AutoFocusDirective, ButtonComponent, IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {
  FormFieldComponent,
  getTodayMidnight,
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidators,
  locateOptionByValue
} from '@icz/angular-form-elements';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';

/**
 * @internal
 */
export enum DatePickerShortcut {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  CURRENT_WEEK = 'CURRENT_WEEK',
  CURRENT_MONTH = 'CURRENT_MONTH',
  CURRENT_YEAR = 'CURRENT_YEAR',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_X_DAYS = 'LAST_X_DAYS',
}

/**
 * @internal
 */
export type DatePickerShortcutValue = Date | DateRange<Date> | string;

/**
 * @internal
 */
export type ShortcutClickedEvent = {
  shortcutType: Nullable<DatePickerShortcut>;
  shortcutValue: Nullable<DatePickerShortcutValue>;
};

/**
 * @internal
 */
export type XDaysAgoParameters = {
  days: number;
};

/**
 * @internal
 */
export type DatePickerShortcutParameters = XDaysAgoParameters;

/**
 * @internal
 */
function isDatepickerShortcutParametrized(shortcut: DatePickerShortcut) {
  return shortcut === DatePickerShortcut.LAST_X_DAYS;
}

/**
 * @internal
 */
export const RELATIVE_INTERVAL_SHORTCUTS: IczOption<DatePickerShortcut, Nullable<string>>[] = [
  {
    label: 'Dnes',
    value: DatePickerShortcut.TODAY,
    data: 'PCD',
  },
  {
    label: 'Minulý týden',
    value: DatePickerShortcut.LAST_WEEK,
    data: 'PLW',
  },
  {
    label: 'Včera',
    value: DatePickerShortcut.YESTERDAY,
    data: 'PLD',
  },
  {
    label: 'Minulý měsíc',
    value: DatePickerShortcut.LAST_MONTH,
    data: 'PLM',
  },
  {
    label: 'Tento týden',
    value: DatePickerShortcut.CURRENT_WEEK,
    data: 'PCW',
  },
  {
    label: 'Minulý rok',
    value: DatePickerShortcut.LAST_YEAR,
    data: 'PLY',
  },
  {
    label: 'Tento měsíc',
    value: DatePickerShortcut.CURRENT_MONTH,
    data: 'PCM',
  },
  {
    label: 'Posledních 7 dní',
    value: DatePickerShortcut.LAST_7_DAYS,
    data: 'P7D',
  },
  {
    label: 'Tento rok',
    value: DatePickerShortcut.CURRENT_YEAR,
    data: 'PCY',
  },
  {
    label: 'Posledních X dní',
    value: DatePickerShortcut.LAST_X_DAYS,
    data: null, // not used because this duration string is parametrized thus requiring manual parsing and dynamic string construction
  },
];

/**
 * @internal
 */
@Component({
  selector: 'icz-date-picker-shortcuts',
  templateUrl: './date-picker-shortcuts.component.html',
  styleUrls: ['./date-picker-shortcuts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormFieldComponent,
    ButtonComponent,
    AutoFocusDirective,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class DatePickerShortcutsComponent implements OnInit, IczOnChanges {

  private destroyRef = inject(DestroyRef);

  @Input({required: true}) selectionMode = DateFilterSelectionMode.DATE;

  @Input({required: true}) activeIntervalShortcut: Nullable<DatePickerShortcut>;

  @Input() selectedDaysAgo: Nullable<number>;

  @Input() isColumnView = false;

  @Output() shortcutClicked = new EventEmitter<ShortcutClickedEvent>();

  protected daysAgoFromTodayForm = new IczFormGroup({
    days: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger(), IczValidators.min(1), IczValidators.max(365 * 20 /* 20yrs */)]),
  });

  protected readonly DatePickerShortcut = DatePickerShortcut;
  protected readonly DateFilterSelectionMode = DateFilterSelectionMode;

  protected get shortcutListOptions(): IczOption<DatePickerShortcut>[] {
    if (this.selectionMode === DateFilterSelectionMode.DATE) {
      return this.dateShortcutsOptions;
    }
    else if (this.selectionMode === DateFilterSelectionMode.ABSOLUTE_INTERVAL) {
      return this.absoluteIntervalShortcutsOptions;
    }
    else if (this.selectionMode === DateFilterSelectionMode.RELATIVE_INTERVAL) {
      return this.relativeIntervalShortcutsOptions;
    }
    else {
      return [];
    }
  }

  private dateShortcutsOptions: IczOption<DatePickerShortcut>[] = [
    {
      label: 'Dnes',
      value: DatePickerShortcut.TODAY,
    },
    {
      label: 'Včera',
      value: DatePickerShortcut.YESTERDAY,
    },
  ];

  private absoluteIntervalShortcutsOptions: IczOption<DatePickerShortcut>[] = [
    {
      label: 'Poslední měsíc',
      value: DatePickerShortcut.LAST_MONTH,
    },
    {
      label: 'Posledních 7 dní',
      value: DatePickerShortcut.LAST_7_DAYS,
    },
    {
      label: 'Posledních X dní',
      value: DatePickerShortcut.LAST_X_DAYS,
    },
  ];

  private relativeIntervalShortcutsOptions = RELATIVE_INTERVAL_SHORTCUTS;

  currentShortcutParams: Partial<{days: Nullable<number>}> = {days: null};

  ngOnInit() {
    this.daysAgoFromTodayForm.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      filter(_ => this.activeIntervalShortcut === DatePickerShortcut.LAST_X_DAYS),
      filter(_ => this.daysAgoFromTodayForm.valid),
      debounceTime(300),
    ).subscribe(shortcutParams => {
      this.currentShortcutParams = shortcutParams;
    });
  }

  applySelectedDays() {
    this.shortcutClicked.emit({
      shortcutType: this.activeIntervalShortcut!,
      shortcutValue: this.getShortcutTransformedDate(
        this.activeIntervalShortcut!,
        {
          days: this.currentShortcutParams.days!,
        }
      ),
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.selectionMode || (changes.selectedDaysAgo && !this.selectedDaysAgo)) {
      this.daysAgoFromTodayForm.reset();
    }
    if (changes.selectedDaysAgo && this.selectedDaysAgo) {
      this.daysAgoFromTodayForm.get('days')!.setValue(this.selectedDaysAgo);
    }
  }

  protected onShortcutClick(shortcut: DatePickerShortcut) {
    if (isDatepickerShortcutParametrized(shortcut)) {
      this.activeIntervalShortcut = shortcut;
    }
    else {
      const date = this.getShortcutTransformedDate(shortcut);

      this.activeIntervalShortcut = null;
      this.shortcutClicked.emit({
        shortcutType: shortcut,
        shortcutValue: date,
      });
    }
  }

  private getShortcutTransformedDate(shortcut: DatePickerShortcut, shortcutParameters?: DatePickerShortcutParameters): Nullable<DatePickerShortcutValue> {
    const todayMidnight = getTodayMidnight();

    switch (shortcut) {
      case DatePickerShortcut.TODAY:
        if (this.selectionMode === DateFilterSelectionMode.RELATIVE_INTERVAL) {
          return this.getIczDurationStringByShortcutType(shortcut);
        }
        else {
          return new Date();
        }
      case DatePickerShortcut.YESTERDAY:
        if (this.selectionMode === DateFilterSelectionMode.RELATIVE_INTERVAL) {
          return this.getIczDurationStringByShortcutType(shortcut);
        }
        else {
          return sub(new Date(), {days: 1});
        }
      case DatePickerShortcut.LAST_WEEK:
        return this.getIczDurationStringByShortcutType(shortcut); // RELATIVE_INTERVAL only
      case DatePickerShortcut.LAST_MONTH:
        if (this.selectionMode === DateFilterSelectionMode.RELATIVE_INTERVAL) {
          return this.getIczDurationStringByShortcutType(shortcut);
        }
        else {
          return new DateRange(sub(todayMidnight, {months: 1}), todayMidnight);
        }
      case DatePickerShortcut.LAST_YEAR:
        return this.getIczDurationStringByShortcutType(shortcut); // RELATIVE_INTERVAL only
      case DatePickerShortcut.LAST_7_DAYS:
        if (this.selectionMode === DateFilterSelectionMode.RELATIVE_INTERVAL) {
          return this.getIczDurationStringByShortcutType(shortcut);
        }
        else {
          return new DateRange(sub(todayMidnight, {days: 6}), todayMidnight);
        }
      case DatePickerShortcut.CURRENT_MONTH:
        return this.getIczDurationStringByShortcutType(shortcut); // RELATIVE_INTERVAL only
      case DatePickerShortcut.CURRENT_WEEK:
        return this.getIczDurationStringByShortcutType(shortcut); // RELATIVE_INTERVAL only
      case DatePickerShortcut.CURRENT_YEAR:
        return this.getIczDurationStringByShortcutType(shortcut); // RELATIVE_INTERVAL only
      case DatePickerShortcut.LAST_X_DAYS:
        const numberOfDays = Number((shortcutParameters as XDaysAgoParameters).days);

        if (numberOfDays) {
          if (this.selectionMode === DateFilterSelectionMode.RELATIVE_INTERVAL) {
            return `P${numberOfDays}D`;
          }
          else {
            return new DateRange(
              sub(todayMidnight, {days: numberOfDays - 1}),
              todayMidnight
            );
          }
        }
        else {
          return null;
        }
      default:
        return null;
    }
  }

  private getIczDurationStringByShortcutType(shortcut: DatePickerShortcut): string {
    return locateOptionByValue(this.relativeIntervalShortcutsOptions, shortcut)!.data!;
  }

}
