import {Directive, inject, Input, OnDestroy, TemplateRef} from '@angular/core';
import {Subject} from 'rxjs';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';

export interface WizardStepDataChanges {
  id: string;
  changes: IczSimpleChanges<WizardStepDirective>;
}

@Directive({
  selector: '[iczWizardStep]',
})
export class WizardStepDirective implements IczOnChanges, OnDestroy {

  content = inject(TemplateRef);

  @Input({alias: 'iczWizardStep', required: true}) id!: string;
  @Input({required: true}) label!: string;
  @Input() submitLabel!: string;
  @Input() customSubmitHandler!: () => void;
  @Input() isValid!: boolean;
  @Input() stepHidden = false;

  inputsChanged$ = new Subject<WizardStepDataChanges>();

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    this.inputsChanged$.next({
      id: this.id,
      changes,
    });
  }

  ngOnDestroy(): void {
    this.inputsChanged$.complete();
  }
}
