import {applyPathParams, CORE_MICROSERVICE_ROOT} from '../api';
import {ApiElasticsearchService} from '|api/elastic';
import {SearchApiService} from './search-api.service';
import {MainMenuCountsService} from '../core/services/main-menu-counts.service';
import {inject} from '@angular/core';
import {SearchParams} from '@icz/angular-table';

export interface UnitViewSearchConfig {
  isUnitView: boolean;
  orgUnitId?: Nullable<number>;
}

export class SearchApiWithElasticService extends SearchApiService {

  private mainMenuCountsService = inject(MainMenuCountsService, {optional: true});

  protected getElasticViewUri(viewName: string, searchParams: Partial<SearchParams>, countMode = false) {
    let searchUri: string;

    if (countMode) {
      searchUri = applyPathParams(ApiElasticsearchService.ElasticsearchLoadViewAndReturnCountPath, {viewName});
    }
    else {
      searchUri = applyPathParams(ApiElasticsearchService.ElasticsearchLoadViewPath, {viewName});
    }

    if (searchParams?.fulltextSearchTerm?.trim()) {
      searchUri += `?text=${encodeURIComponent(searchParams?.fulltextSearchTerm ?? '')}`;
    }

    return CORE_MICROSERVICE_ROOT + searchUri;
  }

  protected getElasticViewByOrgUnitUri(orgUnitViewName: string, orgUnitId: Nullable<number>, searchParams: Partial<SearchParams>, countMode = false) {
    let searchUri: string;

    if (countMode) {
      if (orgUnitId || orgUnitId === 0) {
        searchUri = applyPathParams(ApiElasticsearchService.ElasticsearchLoadViewAndReturnCountForOrgUnitPath, {orgUnitViewName, orgUnitId});
      }
      else {
        searchUri = applyPathParams(ApiElasticsearchService.ElasticsearchLoadViewAndReturnCountPath, {viewName: orgUnitViewName});
      }
    }
    else {
      if (orgUnitId || orgUnitId === 0) {
        searchUri = applyPathParams(ApiElasticsearchService.ElasticsearchLoadViewForOrgUnitPath, {orgUnitViewName, orgUnitId});
      }
      else {
        searchUri = applyPathParams(ApiElasticsearchService.ElasticsearchLoadViewPath, {viewName: orgUnitViewName});
      }
    }

    if (searchParams?.fulltextSearchTerm?.trim()) {
      searchUri += `?text=${encodeURIComponent(searchParams?.fulltextSearchTerm ?? '')}`;
    }

    return CORE_MICROSERVICE_ROOT + searchUri;
  }
}
