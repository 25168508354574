import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {SubjectDuplicateResolveDialogData, SubjectDuplicateResolveDialogResult} from '../../model/subjects.model';
import {SubjectTableColumnSet} from '../subjects-table/subjects-table/subjects-table.component';
import {IczInMemoryDatasource} from '@icz/angular-table';
import {SubjectsTableAction, SubjectsTableActionCommand} from '../subjects-table/subjects-table-actions';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {SubjectAttributeType} from '../../model/subject-attribute-type';

@Component({
  selector: 'icz-subject-duplicate-resolve-dialog',
  templateUrl: './subject-duplicate-resolve-dialog.component.html',
  styleUrls: ['./subject-duplicate-resolve-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectDuplicateResolveDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<SubjectDuplicateResolveDialogResult>();
  protected modalData = injectModalData<SubjectDuplicateResolveDialogData>();

  get tableTitle() {
    return this.modalData.isUpdate ? 'Podobné subjekty s aktualizovaným subjektem' : 'Podobné subjekty s nově zakládaným subjektem';
  }

  get headingText() {
    return this.modalData.isUpdate ? 'Aktualizovaný subjekt' : 'Nově zakládaný subjekt';
  }

  get forceLabel() {
    return this.modalData.isUpdate ? 'Uložit' : 'Založit a přidat';
  }

  forceCreateOrUpdate() {
    this.modalRef.close(new SubjectDuplicateResolveDialogResult(true, null));
  }

  subjectSelected(command: SubjectsTableActionCommand) {
    if (command.action !== SubjectsTableAction.LINK_SUBJECT) return;
    this.modalRef.close(new SubjectDuplicateResolveDialogResult(false, command.subject));
  }

  ngOnInit(): void {
    this.dataSource.setDataFactory(() => this.modalData.suspectedDuplicates);
  }

  cancel() {
    this.modalRef.close();
  }

  dataSource = new IczInMemoryDatasource(() => []);

  protected readonly SubjectAttributeType = SubjectAttributeType;
  protected readonly SubjectTableColumnSet = SubjectTableColumnSet;

}
