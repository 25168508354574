import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {MenuItem, MenuItemActionButton} from '../side-menu/side-menu.component';
import {DetachingService, IczOnChanges, IczSimpleChanges, NumberToLocaleStringPipe} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {Breadcrumb, BreadcrumbsService} from '../breadcrumbs/breadcrumbs.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {OffsetHighlight} from '../../highlights/highlight-text.directive';


@Component({
  selector: 'icz-side-menu-items',
  templateUrl: './side-menu-items.component.html',
  styleUrls: ['./side-menu-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideMenuItemsComponent implements OnInit, IczOnChanges {

  private router = inject(Router);
  private detachingService = inject(DetachingService);
  private translateService = inject(TranslateService);
  private cd = inject(ChangeDetectorRef);
  private breadcrumbs = inject(BreadcrumbsService);
  private destroyRef = inject(DestroyRef);

  private numberToLocaleStringPipe = new NumberToLocaleStringPipe();

  @Input({required: true})
  menuData: Nullable<MenuItem[]>;
  @Input()
  showSearch = false;
  @Input()
  showBackToApp = false;

  _menuData: MenuItem[] = [];

  expandedMenuItemsDictionary!: { [id: string]: boolean };
  isSearchActive = false;
  filteredRoutesSet = new Set<string>();
  searchedIndexes: Record<string, OffsetHighlight> = {};

  searchForm = new IczFormGroup({
    searchItem: new IczFormControl<Nullable<string>>(null)
  });

  breadcrumbsValue: Breadcrumb[] = [];
  innerApplications: string[] = [ApplicationRoute.ADMIN, ApplicationRoute.CONFIG, ApplicationRoute.DEMO];

  ngOnInit(): void {
    this.detachingService.registerDetach('columnResize', this.cd);
    this.detachingService.registerDetach('menuClosed', this.cd);

    this.setExpandedMenuItems();

    this.searchForm.get('searchItem')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(val => {
      this.searchedIndexes = {};
      if (val) {
        this.filteredRoutesSet.clear();
        const clearedValue = val.trim();
        const stringNorm = clearedValue.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
        this.findAllRoutesBySearch(this.menuData!, stringNorm, []);
        this._menuData = this.filterMenuBySearch(this.menuData!);
        this.isSearchActive = true;
      } else {
        this.isSearchActive = false;
        this._menuData = this.menuData!;
      }
    });

    this.breadcrumbs.breadcrumbs$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(crumbs => {
      this.breadcrumbsValue = crumbs;
      this.setExpandedMenuItems();
      this.cd.detectChanges();
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.menuData && changes.menuData.currentValue) {
      this._menuData = changes.menuData.currentValue ?? [];
      this.setExpandedMenuItems();
    }
  }

  backToApp() {
    this.router.navigateByUrl(ApplicationRoute.ROOT);
  }

  getMenuItemLabel(menuItem: MenuItem) {
    let translatedLabel = this.translateService.instant(menuItem.name);

    if (!isNil(menuItem.count)) {
      translatedLabel += ` (${this.numberToLocaleStringPipe.transform(menuItem.count)})`;
    }

    return translatedLabel;
  }

  getMenuItemUrl(menuItem: MenuItem): string {
    if (menuItem.id.includes('?')) {
      return menuItem.id.split('?')[0];
    }
    else {
      return menuItem.id;
    }
  }

  isLinkActive(routeId: string) {
    if (routeId !== ApplicationRoute.ROOT) {
      const currentUrl = decodeURIComponent(this.router.url);
      if (this.innerApplications.some(ia => currentUrl.startsWith(`/${ia}`))) {
        const splitRouteId = routeId.split('/');
        const splitCurrentUrl = currentUrl.split('/');
        return splitCurrentUrl.pop()! === splitRouteId.pop()!;
      } else if (this.breadcrumbsValue.length && this.breadcrumbsValue[0].uri) {
        return this.breadcrumbsValue[0].uri.includes(routeId);
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  getMenuItemQueryParams(menuItem: MenuItem): Record<string, string> {
    if (menuItem.id.includes('?')) {
      const queryString = menuItem.id.split('?')[1];
      const params = new URLSearchParams(queryString);

      const out: Record<string, string> = {};

      params.forEach((value, key) => {
        out[key] = value;
      });

      return out;
    }
    else {
      return {};
    }
  }

  private setExpandedMenuItems() {
    this.expandedMenuItemsDictionary = {};
    const currentUrl = decodeURIComponent(this.router.url);

    if (this.innerApplications.some(ia => currentUrl.startsWith(`/${ia}`))) {
      this.setExpandedMenuItem(this.menuData!, currentUrl);
    } else {
      if (this.breadcrumbsValue.length && this.breadcrumbsValue[0].uri) {
        this.setExpandedMenuItem(this.menuData!, decodeURIComponent(this.breadcrumbsValue[0].uri));
      }
    }
  }

  private setExpandedMenuItem(menuData: MenuItem[], currentUrl: string) {
    menuData.filter(menu => Boolean(menu.children)).forEach(menu => {
      if (Boolean(menu.children)) this.setExpandedMenuItem(menu.children!, currentUrl);

      const childrenRoutes: string[] = this.findAllRoutesOfParent(menu.id, this.menuData!, []);
      this.expandedMenuItemsDictionary[menu.id] = childrenRoutes.some(route => currentUrl.includes(route));
    });
  }

  private findAllRoutesBySearch(menuData: MenuItem[], searchQuery: string, route: string[]) {
    menuData.forEach(item => {
      if (isNil(item.children) && this.isSearchedLeaf(item, searchQuery)) {
        route.concat([item.id]).forEach(routePart => this.filteredRoutesSet.add(routePart));
      }
      if (item.children && item.children.length) {
        this.findAllRoutesBySearch(item.children, searchQuery, route.concat([item.id]));
      }
    });
  }

  private filterMenuBySearch(menuItems: Nullable<MenuItem[]>): MenuItem[] {
    if (menuItems) {
      const filteredMenu: MenuItem[] = [];
      menuItems.forEach(item => {
        if (this.filteredRoutesSet.has(item.id)) {
          filteredMenu.push(this.createMenuItem(item, this.filterMenuBySearch(item.children)));
        }
      });
      return filteredMenu;
    } else {
      return [];
    }
  }

  private createMenuItem(originalMenuItem: MenuItem, children: MenuItem[]) {
    if (children.length) {
      return {...originalMenuItem, children};
    } else {
      return {...originalMenuItem};
    }
  }

  private isSearchedLeaf(item: MenuItem, searchQuery: string) {
    const localizedNodeName = this.translateService.instant(item.name);
    const normalizedLeafName = localizedNodeName.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
    if (normalizedLeafName.includes(searchQuery)) {
      this.searchedIndexes[item.name] = ({offset: normalizedLeafName.indexOf(searchQuery), length: searchQuery.length});
      return true;
    } else {
      return false;
    }
  }

  getHighlightOffset(buttonName: string) {
    return [this.searchedIndexes[buttonName]];
  }

  private findAllRoutesOfParent(parentId: string, menuList: MenuItem[], listToReturn: string[]) {
    const foundParent = this.findParent(parentId, menuList);

    if (foundParent) {
      const children = foundParent.children!.filter(el => el.id !== '.');

      children.forEach(child => {
        listToReturn.push(child.id);
        if (child.children) {
          listToReturn = listToReturn.concat(this.findAllRoutesOfParent(child.id, children, listToReturn));
        }
      });
      return listToReturn;
    }
    return [];
  }

  private findParent(parentId: string, menuList: MenuItem[]) {
    let foundParent = menuList.find(el => el.id === parentId);

    if (foundParent) {
      return foundParent;
    }
    menuList.some(element => {
      if (!element.children) return false;

      foundParent = this.findParent(parentId, element.children);
      return foundParent;
    });
    return foundParent;
  }

  handleActionButtonClick($event: Event, actionButton: MenuItemActionButton) {
    $event.preventDefault();
    $event.stopPropagation();
    actionButton.handler();
  }

}
