import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {EntityType} from '|api/commons';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {CirculationSearchService} from '../../../../../services/circulation-search.service';
import {isDocumentEntity} from '../../../shared-document.utils';
import {ExtendedCirculationTaskDto} from '../../../model/elastic-extended-entities.interface';
import {IczOption} from '@icz/angular-form-elements';
import {FilterOperator, FilterParam} from '@icz/angular-table';

@Component({
  selector: 'icz-issd-activity-state-icon',
  templateUrl: './issd-entity-activity-icon.component.html',
  styleUrls: ['./issd-entity-activity-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssdEntityActivityIconComponent {

  protected loadingService = inject(LoadingIndicatorService);
  private circulationSearchService = inject(CirculationSearchService);
  private cd = inject(ChangeDetectorRef);

  @Input({required: true})
  entityId: Nullable<number>;
  @Input({required: true})
  entityType: Nullable<EntityType>;
  @Input()
  issdAppsOptions: IczOption[] = [];

  isTasksPopupOpen = false;

  circulationTask: Nullable<ExtendedCirculationTaskDto>;
  activityHeading = '';
  taskState = '';

  taskIconClicked() {
    this.isTasksPopupOpen = true;
    const filterParams: FilterParam[] = [];

    if (isDocumentEntity(this.entityType)) {
      filterParams.push({
        fieldName: 'documentId',
        operator: FilterOperator.equals,
        value: String(this.entityId),
      });
    } else {
      filterParams.push({
        fieldName: 'fileId',
        operator: FilterOperator.equals,
        value: String(this.entityId),
      });
    }

    this.loadingService.doLoading(this.circulationSearchService.findTasksGlobally({
      page: 0,
      size: 1,
      sort: [],
      filter: filterParams,
    }), this).subscribe(page => {
      if (page.content.length === 1) {
        this.circulationTask = page.content[0];
        this.activityHeading = 'en.circulationActivityType.' + this.circulationTask!.activityType;
        this.taskState = 'en.circulationTaskState.' + this.circulationTask!.taskState;
        this.cd.detectChanges();
      } else {
        if (isDocumentEntity(this.entityType)) {
          this.activityHeading = 'Dokument je zpracováván v externí aplikaci';
        } else {
          this.activityHeading = 'Spis je zpracováván v externí aplikaci';
        }
      }
    });
  }

}
