import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {convertValueToDateObject} from './date.pipe';

/**
 * Converts timestamps in milliseconds from interval <0, 86400000) to wall-time
 * string based on locale (i.e. 12:56, includes also meridian and 12h format for western locales).
 *
 * @param value - unix timestamp in milliseconds since Epoch
 *              - must be in <1970-01-01T00:00:00.000; 1970-01-01T23:59:59.999) = <0, 86400000)
 */
export function iczFormatTime(
  currentLocale: string,
  value: Nullable<number>,
  ignoreTimezoneOffset?: boolean,
): string {
  if (!Number.isNaN(Number(value))) {
    const parsedValue = Number(value);

    if (parsedValue < 0 || parsedValue >= 86400000) {
      throw new Error(`LocalizedTimePipe value "${parsedValue}" out of bounds <0, 86400000).`);
    }
  }

  const convertedValue = convertValueToDateObject(value, ignoreTimezoneOffset);

  if (currentLocale === 'cs' || currentLocale === 'sk') {
    return stripSecondsFromCzechDateTime(convertedValue?.toLocaleTimeString('cs-CZ') ?? '');
  }
  else {
    return stripSecondsFromEnglishDateTime(convertedValue?.toLocaleTimeString('en-US') ?? '');
  }
}

/**
 * @internal
 */
export function stripSecondsFromCzechDateTime(stringWithTime: string): string {
  return stringWithTime.replace(/:\d\d$/g, '');
}

/**
 * @internal
 */
export function stripSecondsFromEnglishDateTime(stringWithTime: string): string {
  return stringWithTime.replace(',', '').replace(/:\d\d (AM|PM)$/g, ' $1');
}

/**
 * Strips time part of date.
 * @param date date object
 * @param currentLocale locale to apply
 * @param ignoreTimezoneOffset ignore timezone offset
 * @return time part of date formated in hh:mm
 */
export function getTimePartFromDate(date: Date, currentLocale: string, ignoreTimezoneOffset?: boolean) {
  const convertedValue = convertValueToDateObject(date.getTime(), ignoreTimezoneOffset);
  if (currentLocale === 'cs' || currentLocale === 'sk') {
    return (convertedValue?.toLocaleTimeString('cs-CZ', {hour: '2-digit', minute: '2-digit'}) ?? '');
  }
  else {
    return (convertedValue?.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'}) ?? '');
  }
}

/**
 * Converts timestamps in milliseconds from interval <0, 86400000) to wall-time
 * string based on locale (i.e. 12:56, includes also meridian and 12h format for western locales).
 */
@Pipe({
  name: 'localizedTime',
  pure: true,
  standalone: true,
})
export class LocalizedTimePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  /**
   * @param value - unix timestamp in milliseconds since Epoch
   *              - must be in <1970-01-01T00:00:00.000; 1970-01-01T23:59:59.999) = <0, 86400000)
   */
  transform(value: Nullable<number>): string {
    if (!value) { // Branch used merely for performance optimization
      return '';
    }

    return iczFormatTime(this.translateService.currentLang, value);
  }

}
