import {DOCUMENT} from '@angular/common';
import {ChangeDetectorRef, Component, DestroyRef, EventEmitter, inject, Input, Output} from '@angular/core';
import {
  RelatedObjectType,
  SubjectObjectRelationCreateDto,
  SubjectObjectRelationType,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto,
  SubjectRecordFindMode,
} from '|api/commons';
import {IczModalRef} from '@icz/angular-modal';
import {SubjectLoaderIds, SubjectsService} from '../../../../../services/subjects.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {extendDefaultTableConfig, IczInMemoryDatasource,} from '@icz/angular-table';
import {constructSubjectName, SubjectAndAddress, SubjectRecordSource, SubjectRecordWithSourceDto} from '../../../model/subjects.model';
import {SubjectsTableAction, SubjectsTableActionCommand} from '../../subjects-table/subjects-table-actions';
import {SubjectToolbarContext} from '../../subjects-toolbar/subjects-toolbar.component';
import {SubjectTableColumnSet} from '../../subjects-table/subjects-table/subjects-table.component';
import {DocumentDetailService} from '../../../../../services/document-detail.service';
import {SubjectToastService, SubjectToastType} from '../../../../../core/services/notifications/subject-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {TranslateService} from '@ngx-translate/core';
import {ObjectDetailPart} from '../../../../../services/abstract-object-detail.service';
import {load} from '../../../../../lib/object-counts';
import {switchMap} from 'rxjs/operators';
import {CreateNewConsignmentDialogType} from '../../../own-consignment-table/model/own-consignment-model';
import {ApiRelatedObjectService} from '|api/subject-register';
import {isOwnDocumentObject} from '../../../shared-document.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  CreateOrUpdateSubjectAction,
  SubjectCreateOrUpdateWithDuplicateResolveService
} from '../../subject-create-or-update-with-duplicate-resolve.service';
import {Observable} from 'rxjs';
import {getSearchSubjectFormGroup} from '../../subject-search-form/subject-search-form.model';
import {esslErrorDtoToToastParameters} from '../../../../notifications/toast.service';
import {DocumentOrProfileDtoWithAuthorization, FileOrProfileDtoWithAuthorization} from '../../../model/dto-with-permissions.interface';
import {SubjectPropagateToFileService} from '../../subject-propagate-to-file.service';
import {DocumentDto} from '|api/document';


@Component({
  selector: 'icz-subject-search-with-results',
  templateUrl: './subject-search-with-results.component.html',
  styleUrls: ['./subject-search-with-results.component.scss'],
})
export class SubjectSearchWithResultsComponent {

  private destroyRef = inject(DestroyRef);
  private subjectsService = inject(SubjectsService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private subjectToastService = inject(SubjectToastService);
  private translateService = inject(TranslateService);
  private subjectPropagateToFileService = inject(SubjectPropagateToFileService);
  private subjectCreateOrUpdateWithDuplicateResolveService = inject(SubjectCreateOrUpdateWithDuplicateResolveService);
  private apiRelatedObjectService = inject(ApiRelatedObjectService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private documentDetailService = inject(DocumentDetailService, {optional: true});
  private document = inject(DOCUMENT);

  @Input({required: true}) resultTableColumnSet!: SubjectTableColumnSet;
  @Input({required: true}) tableViewId!: string;
  @Input() objectId!: number;
  @Input() fullEntity: Nullable<DocumentOrProfileDtoWithAuthorization | FileOrProfileDtoWithAuthorization>;
  @Input() modalRef!: IczModalRef<Nullable<SubjectRecordDto | SubjectRecordCreateOrUpdateDto>>; // todo 10540 move input to parent
  @Input() showInlineSearchButton = false;
  @Input() showFooter = false;
  @Input() showResultTableToolbar = true;
  @Input() showSearchAppendix = false;
  @Input() allowCreateOnNotFound = true;
  @Input() allowShowLinkToStepCreate = false;
  @Input() allowInternalSearchOnly = false;
  @Input() searchOnlyIdentified: Nullable<boolean> = null;
  @Input() createNewConsignmentDialogType!: CreateNewConsignmentDialogType;
  @Input() relationType: Nullable<SubjectObjectRelationType>;
  @Input() relatedObjectType: Nullable<RelatedObjectType>;
  @Input() subjectToolbarContext = SubjectToolbarContext.OVERVIEW;
  @Output() advanceToCreate = new EventEmitter<Record<string, any>>();
  @Output() tableActionExecuted = new EventEmitter<SubjectsTableActionCommand>();
  @Output() subjectAddressChanged = new EventEmitter<SubjectAndAddress>();
  @Output() selectedRowsChanged = new EventEmitter<SubjectRecordDto[]>();
  @Output() searchResultDone = new EventEmitter<number>();

  form = getSearchSubjectFormGroup();
  // table properties
  tableConfig = extendDefaultTableConfig({
    rowHeight: 70,
    toolbarConfig: {showToolbar: false}
  });

  readonly SubjectLoaderIds = SubjectLoaderIds;
  readonly SubjectTableColumnSet = SubjectTableColumnSet;
  readonly SubjectToolbarContext = SubjectToolbarContext;
  readonly SubjectRecordFindMode = SubjectRecordFindMode;

  searched = false;
  foundSubjects: SubjectRecordWithSourceDto[] = [];
  dataSource = new IczInMemoryDatasource(() => []);

  selectedRows: SubjectRecordWithSourceDto[] = [];

  get expanded() {
    return !this.searched;
  }

  get showLinkToStepCreate() {
    return this.allowShowLinkToStepCreate && this.searched && this.foundSubjects.length;
  }

  get isFormEmpty() {
    return this.subjectsService.isSearchFormEmpty(this.form);
  }

  onRowSelect(rows: SubjectRecordWithSourceDto[]) {
    this.selectedRows = rows;
    this.selectedRowsChanged.emit(rows);
  }

  goToCreateSubjectStep() {
    this.advanceToCreate.emit(this.form.getRawValue());
  }

  setSubjectsForTable(subjects: SubjectRecordWithSourceDto[], isReloadMode = false): void {
    if (!isReloadMode) {
      this.searched = true;
    }

    this.foundSubjects = subjects;

    if (subjects?.length) {
      this.dataSource.setDataFactory(() => subjects);
      this.searchResultDone.emit(subjects?.length);
    } else {
      this.dataSource.setDataFactory(() => []);
      this.searchResultDone.emit(0);
    }
  }

  searchSubjectsAndDecideFindMode(isReloadMode: boolean) {
    const findMode = this.allowInternalSearchOnly ? SubjectRecordFindMode.INTERNAL : SubjectRecordFindMode.INTERNAL_ISDS;
    this.searchSubjectsByKeys(isReloadMode, findMode);
  }

  searchSubjectsByKeys(isReloadMode = false, findMode?: SubjectRecordFindMode): void {
    findMode = this.allowInternalSearchOnly ? SubjectRecordFindMode.INTERNAL : findMode;

    if (this.form.valid) {
      this.loadingIndicatorService.startLoading(this, SubjectLoaderIds.SEARCHING);

      if (!isReloadMode) {
        this.searched = false;
      }

      this.dataSource.setDataFactory(() => []);

      const handleResult = (subjects: SubjectRecordWithSourceDto[]) => {
        this.setSubjectsForTable(subjects);
        this.loadingIndicatorService.endLoading(this, SubjectLoaderIds.SEARCHING);

        const innerTab = this.document.querySelectorAll('icz-modal-dialog icz-tabs div.content-container');
        if (innerTab && innerTab[0]) {
          innerTab[0].scrollTop = innerTab[0].scrollHeight;
        }
      };

      this.subjectsService.searchSubjects(this.form, this.searchOnlyIdentified, findMode).subscribe({
        next: (subjects: Array<SubjectRecordWithSourceDto>) => {
          handleResult(subjects);
        },
        error: err => {
          handleResult([]);
        },
        complete: () => {
        }
      });
    }
  }

  searchSubjectsInternalOnly() {
    this.searchSubjectsByKeys(false, SubjectRecordFindMode.INTERNAL);
  }

  actionExecuted(command: SubjectsTableActionCommand) {
    switch (command.action) {
      case SubjectsTableAction.LINK_SUBJECT:

        if (command.subject.subjectSource === SubjectRecordSource.ISDS_SEARCH) {
            this.loadingIndicatorService.doLoading(
              this.subjectsService.findUsingIsdsFind(command.subject).pipe(
                switchMap(subjectFromIsds => {return this.linkSubject$(subjectFromIsds!);}),
                takeUntilDestroyed(this.destroyRef)),
              this,
              SubjectLoaderIds.LINKING
            ).subscribe(
              {
                next: res => {
                  this.onCreateSuccess(res!);
                },
                error: err => {
                  this.onCreateTechnicalError(command.subject, err);
                }
              }
            );
        }
        else {
          this.linkSubject(command.subject);
          this.changeDetectorRef.detectChanges();
        }
        break;
      default:
        this.tableActionExecuted.emit(command);
        break;
    }
  }

  private onCreateSuccess(subject: SubjectRecordDto | SubjectRecordCreateOrUpdateDto) {
    const name = constructSubjectName(subject);

    this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_LINK_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
    this.documentDetailService?.reloadObject({
      [ObjectDetailPart.OBJECT_DATA]: load(),
    });
    this.modalRef.close(subject);
  }

  private onCreateTechnicalError(subject: SubjectRecordCreateOrUpdateDto, error: any) {
    const name = constructSubjectName(subject);

    this.subjectToastService.dispatchSubjectErrorToast(
      SubjectToastType.SUBJECT_LINK_ERROR, {
        [InternalNotificationKey.SUBJECT_NAME]: name,
        ...esslErrorDtoToToastParameters(this.translateService, error.error),
      });
  }

  linkSubject$(subjectToLink: SubjectRecordDto | SubjectRecordCreateOrUpdateDto): Observable<Nullable<SubjectRecordDto | SubjectRecordCreateOrUpdateDto>> {
    const representing = isOwnDocumentObject(this.documentDetailService?.object);
    const relation: SubjectObjectRelationCreateDto = {
      relatedObjectId: this.objectId,
      representing,
      relationType: this.relationType!,
      relatedObjectType: this.relatedObjectType!,
    };
    let req$: Nullable<Observable<Nullable<SubjectRecordDto | SubjectRecordCreateOrUpdateDto>>>;
    if (subjectToLink.id) {
      req$ = this.apiRelatedObjectService.relatedObjectLinkObjectsToSubject({
        subjectId: subjectToLink.id!,
        body: [relation],
      });
    } else {
      (subjectToLink as SubjectRecordCreateOrUpdateDto).objectRelations = [relation];
      req$ = this.subjectCreateOrUpdateWithDuplicateResolveService.createOrUpdateSubjectWithDuplicateResolve(
        CreateOrUpdateSubjectAction.CREATE_OR_UPDATE_SUBJECT,
        {subject: subjectToLink, createRelation: relation},
        this.documentDetailService?.objectId);
    }

    return req$.pipe(switchMap(subject => this.subjectPropagateToFileService.checkAndAddRelationToFile('addRelationToFile', subject, this.fullEntity!.id, (this.fullEntity as DocumentDto).fileId)));
  }

  linkSubject(subjectToLink: SubjectRecordDto | SubjectRecordCreateOrUpdateDto) {
    this.loadingIndicatorService.doLoading(
      this.linkSubject$(subjectToLink),
      this,
      SubjectLoaderIds.LINKING).subscribe(
      {
        next: createAttemptResult => {
          if (!createAttemptResult) {
            return; // no result means closing the duplicate dialog without any action
          }
          else {
            this.onCreateSuccess(createAttemptResult);
          }
        },
        error: err => {
          this.onCreateTechnicalError(subjectToLink, err);
        }
      }
    );
  }

  resetSearch() {
    this.dataSource.setDataFactory(() => []);
    this.searched = false;
  }

}
