import {DestroyRef, Directive, ElementRef, EventEmitter, inject} from '@angular/core';
import {isFilterWithOptions, NonemptyFilterItem} from './filter.types';
import {FilterValue} from './table.models';
import {IczFormControl, IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {FilterOperator, isNoValueOperator} from './table.utils';

/**
 * Abstract class used for creation of custom filters.
 * Table filter dynamics work around mutation of its _item property and after the mutation
 *   is done, the item is sent to the client using the setFilterValue Subject.
 *
 * @deprecated - we are looking into ways to refactor table filters to immutable data communication
 *               thus we do not guarrantee stability of this interface and its behavior.
 */
export abstract class AbstractTableFilter {

  /**
   * Item setter can be overriden in subclasses to implement various behaviors,
   *   such as sanitization, validation with corrections or additional filter state changes.
   */
  set item(newValue: NonemptyFilterItem) { this._item = newValue; }
  /**
   * Item getter can be overridden to implement additional transformations made to the item.
   */
  get item(): NonemptyFilterItem { return this._item; }

  /**
   * Original filter item
   */
  protected _item!: NonemptyFilterItem;

  /**
   * Emits when the inner code of the filter imperatively requests its window to close.
   */
  closePopup = new EventEmitter<void>();
  /**
   * Emits when any effective aspect of the filter item changes (operator / value / subvalues).
   *   Triggers filtering operation in the datasource which will send a request to a backend service.
   */
  setFilterValue = new EventEmitter<FilterValue>();
  /**
   * Used in conjunction with asynchronously fetched column lists.
   * @see FilterWithList.list$
   */
  isLoading = false;
  /**
   * Should contain filter operators available in the context of a given fitler.
   */
  filterOperators: Array<IczOption<FilterOperator>> = [];
  /**
   * Form containing filterOperator field and value field.
   * - subclasses should change filterOperator and value accordingly after the
   *     user makes modifications to their respective UI counterparts.
   */
  form!: IczFormGroup;

  /**
   * Should be set to true if the user opens filter operators list and to false if the user closes the list.
   */
  protected isOperatorSelectorOpen = false;

  /**
   * Should be bound to filter body - filter body and operators list should be mutually exclusively visible.
   */
  protected get canDisplayFilterBody(): boolean {
    return !this.isOperatorSelectorOpen && !isNoValueOperator(this.currentOperator);
  }

  /**
   * Gets currently selected filtering operator.
   */
  protected get currentOperator(): FilterOperator {
    return this.form.get('filterOperator')!.value;
  }

  /**
   * Requests to close the filtering widget.
   */
  protected closeFilterPopup() {
    this.closePopup.emit();
  }

  /**
   * Inits the form and sets its operator and value to initial values.
   *   Should be called from ngOnInit of your filter.
   */
  protected initForm() {
    this.form = new IczFormGroup({
      filterOperator: new IczFormControl<Nullable<FilterOperator>>(this.initialFilterOperator),
      value: new IczFormControl<Nullable<string | string[]>>(this.initialFilterValue),
    });
  }

  /**
   * Should be called after the user manually confirms value of the filter in GUI (using Enter or a primary button).
   */
  protected abstract applyFilter(): void;

  /**
   * Should contain logic which emits data to the client using AbstractTableFilter.setFilterValue.
   */
  protected abstract emitFilterValue(): void;

  /**
   * Should be called after the user changes filtering operator; can contain additional logic
   *   to change value or other aspects on operator change.
   */
  protected abstract applyFilterOperator(): void;

  /**
   * Retrieves default filtering operator either from default filter operator configuration or from previously chosen operator.
   */
  protected get initialFilterOperator(): FilterOperator {
    return this.item.filterOption?.value
      ? this.item.filterOption.value
      : this.filterOperators[0]?.value;
  }

  /**
   * Retrieves internal form value, usually from value selected by the user in the past.
   */
  protected get initialFilterValue(): Nullable<string | string[]> {
    if (!this.item?.value) {
      return null;
    }
    else {
      return this.item.value;
    }
  }

  /**
   * Determines if the filter is with options.
   * @deprecated - result of an antipattern in our internal filters inheritance hierarchy.
   */
  protected get isFilterWithOptions(): boolean {
    return isFilterWithOptions(this.item.filterType);
  }

  /**
   * Used when the filter and/or its operator are used in a mode which should not emit a non-null value.
   *  (operator isEmpty, etc.)
   */
  protected emitNoValueFilterValue(filterOperator: FilterOperator) {
    this.setFilterValue.emit({
      value: undefined,
      viewValue: undefined,
      subValues: undefined,
      label: null,
      closeAfter: true,
      filterOperator,
    });
  }

}

/**
 * Used in built-in table filters to reduce boilerplate code.
 * @internal
 */
@Directive()
export abstract class IczTableFilterWithInternalForm extends AbstractTableFilter {

  protected destroyRef = inject(DestroyRef);
  protected el = inject(ElementRef);

  protected initOperatorAndValueChangeHandlers() {
    this.form.get('value')!.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.emitFilterValue();
    });

    this.form.get('filterOperator')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.applyFilterOperator();
    });
  }

  protected applyFilterOperator(): void {
    const filterOperator = this.form.get('filterOperator')!.value;
    const valueControl = this.form.get('value');
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (isNoValueOperator(filterOperator)) {
      valueControl!.setValue(null, {emitEvent: false});
      valueControl!.disable();

      this.setFilterValue.emit({
        value: undefined,
        viewValue: undefined,
        subValues: undefined,
        label: null,
        closeAfter: true,
        filterOperator,
      });

      this.closeFilterPopup();
    }
    else if (valueControl!.value) {
      this.emitFilterValue();
    }
    else {
      valueControl!.enable();
      setTimeout(() => this.el.nativeElement.querySelector('.filter-body icz-form-field input')?.focus());
    }
  }

}

/**
 * Used in built-in table filters to reduce boilerplate code.
 * @internal
 */
@Directive()
export abstract class IczTableFilterWithList extends IczTableFilterWithInternalForm {

  protected get hasNoValueFilterOperator(): boolean {
    return isNoValueOperator(this.currentOperator);
  }

  protected emitFilterValue(): void {
    const value = this.form.get('value')!.value;

    if (!this.isFilterWithOptions && value === null) return;

    const filterOperator = this.form.get('filterOperator')!.value;
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (this.hasNoValueFilterOperator) {
      this.setFilterValue.emit({
        value: undefined,
        label: null,
        filterOperator,
        closeAfter: true,
      });
    }
    else {
      this.setFilterValue.emit({
        value,
        list: this.item.list,
        label: null,
        filterOperator,
      });
    }
  }

  protected override applyFilterOperator() {
    const filterOperator = this.form.get('filterOperator')!.value;
    const valueControl = this.form.get('value');
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (isNoValueOperator(filterOperator)) {
      valueControl!.setValue(null, {emitEvent: false});
      valueControl!.disable();

      this.setFilterValue.emit({
        value: undefined,
        viewValue: undefined,
        subValues: undefined,
        label: null,
        closeAfter: true,
        filterOperator,
      });

      this.closeFilterPopup();
    }
    else if (valueControl!.value) {
      this.emitFilterValue();
    }
    else {
      valueControl!.enable();
      setTimeout(() => this.el.nativeElement.querySelector('.filter-body icz-form-field input')?.focus());
    }
  }

}
