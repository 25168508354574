<div class="row" [formGroup]="form" (keydown.enter)="applyFilter()">

  <div class="col" [waiting]="isLoading">
    <div class="filter-specification-area">

      <icz-form-inline-select
        formControlName="filterOperator"
        [options]="filterOperators"
        (openStatusChanged)="isOperatorSelectorOpen = $event">
      </icz-form-inline-select>

      <div class="filter-body"
        [style.height.px]="canDisplayFilterBody ? null : 1"
        [style.opacity]="canDisplayFilterBody ? null : 0"> <!-- will make smoother UX without text jumping when re-selecting filter operator -->

        @if (item.isTree) {
          <icz-form-tree-selector
            [isMultiselect]="true"
            [options]="options"
            [asPopover]="false"
            [clearable]="false"
            [originId]="item.originId"
            [containerHeight]="tableHeight"
            [placeholder]="item.searchFieldPlaceholder ?? ''"
            [selectionStrategy]="item.treeSelectionStrategy ?? TreeItemSelectionStrategy.SIMPLE"
            formControlName="value"
            >
            <ng-container
              additionalContentBeforeOptions
              *ngTemplateOutlet="subfilters"
            ></ng-container>
          </icz-form-tree-selector>
        }
        @else {
          <icz-form-autocomplete
            class="block mt-12 mb-8"
            [options]="options"
            [asPopover]="false"
            [clearable]="false"
            [isMultiselect]="true"
            [originId]="item.originId"
            [customOptionTemplate]="item.customListItemTemplate"
            [placeholder]="item.searchFieldPlaceholder ?? ''"
            formControlName="value"
            >
            <ng-container
              additionalContentBeforeOptions
              *ngTemplateOutlet="subfilters"
            ></ng-container>
          </icz-form-autocomplete>
        }

      </div>

      <icz-button class="mx-24 my-12" [disabled]="!hasNoValueFilterOperator && !chosenOptions.length"
        primary label="Použít" (onAction)="applyFilter()">
      </icz-button>

    </div>
  </div>
  @if (!hasNoValueFilterOperator) {
    <div class="col">
      <div class="filter-values-area">
        <div class="icz-body-1 selected-headline">
          {{ 'Vybráno' | translate }}:
        </div>
        @if (!filterValueControl.value?.length) {
          <div class="icz-body-1 text-center">
            <em>{{ 'Žádné položky' | translate }}</em>
          </div>
        }
        <cdk-virtual-scroll-viewport
          class="selected-items-chips"
          itemSize="44" minBufferPx="200" maxBufferPx="400" appendOnly
          >
          <icz-chip *cdkVirtualFor="let option of chosenOptions"
            [iczTooltip]="option.label"
            [iczTooltipShowDelay]="TRUNCATED_TEXT_TOOLTIP_DELAY"
            class="codebook-chip-size"
            [clickable]="false">
            <ng-container content>
              @if (!item.customSelectedChipTemplate) {
                <span class="selected-item-chip-text">{{ option.label | translate }}</span>
              }
              @if (item.customSelectedChipTemplate) {
                <ng-container
                  [ngTemplateOutlet]="item.customSelectedChipTemplate"
                  [ngTemplateOutletContext]="{$implicit: {option: option}}">
                </ng-container>
              }
            </ng-container>
            <icz-button suffix svgIcon="close" background="transparent" size="small"
              disableRipple (onAction)="removeSelectedItem(option)">
            </icz-button>
          </icz-chip>
        </cdk-virtual-scroll-viewport>
      </div>
    </div>
  }

</div>

<ng-template #subfilters>
  @if (item.subfilters?.length && subfilterForm) {
    <div
      [class.grid]="item.subfilters!.length >= 2"
      class="grid-cols-2 gap-8"
      [formGroup]="subfilterForm"
      >
      @for (subfilter of item.subfilters; track subfilter.id) {
        <icz-form-filter
          [formControlName]="subfilter.id!"
          [filterItem]="subfilter"
        ></icz-form-filter>
      }
    </div>
  }
  <!-- todo(rb) uncomment after SearchAPI endpoints converted to POST method -->
  <!--<icz-checkbox
  class="select-all-headline"
  label="Vybrat vše" [value]="isEverythingSelected"
  (valueChange)="selectAllValueChanged($event)"
></icz-checkbox>-->
</ng-template>
