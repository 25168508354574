import {
  DataBoxDto,
  PostalBoxAddressDto,
  RelatedObjectType,
  SubjectAttributeAddressDto,
  SubjectAttributeDataBoxDto,
  SubjectAttributeGenderDto,
  SubjectAttributeLocalDateDto,
  SubjectAttributePostalBoxDto,
  SubjectAttributesDto,
  SubjectAttributeStringDto,
  SubjectObjectRelationType,
  SubjectRecordClassification,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto
} from '|api/commons';
import {IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {AddressCompleteDto,} from './addresses.model';
import {map} from 'rxjs/operators';
import {CzemItemDto, LegalFormDto} from '|api/codebook';
import {isValidNow} from '../../../core/services/data-mapping.utils';
import {isEqual} from 'lodash';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {SubjectAttributeType} from './subject-attribute-type';
import {SharedBusinessValidators} from '../shared-business-validators';
import {
  DocumentOrProfileDtoWithAuthorization,
  FileOrProfileDtoWithAuthorization
} from './dto-with-permissions.interface';

export const UNNAMED_SUBJECT_TEXT = 'Subjekt bez názvu, proveďte ověření/ztotožnění';

export type SubjectAsSender = Nullable<SubjectRecordCreateOrUpdateDto | number>;

export const attributesWithMultiValues = [
  SubjectAttributeType.ADDITIONAL_ADDRESS,
  SubjectAttributeType.DATA_BOX,
  SubjectAttributeType.EMAIL,
  SubjectAttributeType.POSTAL_BOX,
  SubjectAttributeType.PHONE_FAX,
] as const;

export type SubjectAttributeWithMultipleValues = typeof attributesWithMultiValues[number];

export enum AttributeComponentType {
  FORM_FIELD = 'FORM_FIELD',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  DATEPICKER = 'DATEPICKER',
}

export const ATTRIBUTES_WITH_MULTI_VALUES = [
  SubjectAttributeType.ADDITIONAL_ADDRESS,
  SubjectAttributeType.DATA_BOX,
  SubjectAttributeType.EMAIL,
  SubjectAttributeType.POSTAL_BOX,
  SubjectAttributeType.PHONE_FAX,
];

export const SUBJECT_ADDRESS_AS_SENDER_ATTRIBUTE_TYPES = [
  SubjectAttributeType.MAILING_ADDRESS,
  SubjectAttributeType.RESIDENTIAL_ADDRESS,
  SubjectAttributeType.ADDITIONAL_ADDRESS,
];

export interface SubjectAndAddress {
  subject: SubjectRecordDto;
  address: Nullable<AddressCompleteDto>;
}

export enum SubjectRecordSource {
  INTERNAL = 'INTERNAL',
  ISDS_FIND = 'ISDS_FIND',
  ISDS_SEARCH = 'ISDS_SEARCH',
  ISZR = 'ISZR',
}

export interface IszrEnrichResult {
  enrichedSubject: SubjectRecordDto,
  diff: Record<string, string>
}

export class SubjectDuplicateResolveDialogResult {
  forceCreateOrUpdate: boolean;
  selectedSubject: Nullable<SubjectRecordCreateOrUpdateDto>;

  constructor(
    forceCreateOrUpdate: boolean,
    selectedSubject?: Nullable<SubjectRecordDto>,
    ) {
    this.forceCreateOrUpdate = forceCreateOrUpdate;
    this.selectedSubject = selectedSubject;
  }
}

export interface SubjectCreateRelationPropagateToFile {
  propagateRelationToFile: boolean;
}

export interface AddSubjectDialogData {
  relatedObjectType: RelatedObjectType;
  fullEntity: DocumentOrProfileDtoWithAuthorization | FileOrProfileDtoWithAuthorization;
}

export interface SubjectDuplicateResolveDialogData {
  isUpdate: boolean;
  subjectToBeCreateOrUpdated: SubjectRecordCreateOrUpdateDto;
  suspectedDuplicates: SubjectRecordWithSourceDto[];
  subjectReplacementConfig?: SubjectReplacementConfig;
}

// DataboxTypeFromIsdsSearchMethod is not exactly 1:1 to API generated DataboxType
export enum DataboxTypeFromIsdsSearchMethod {
  OVM_FO = 'OVM_FO',
  FO = 'FO',
  OVM_PFO = 'OVM_PFO',
  PFO = 'PFO',
  PFO_ADVOK = 'PFO_ADVOK',
  PFO_DANPOR = 'PFO_DANPOR',
  PFO_INSSPR = 'PFO_INSSPR',
  PFO_AUDITOR = 'PFO_AUDITOR',
  PFO_ZNALEC = 'PFO_ZNALEC',
  PFO_TLUMOCNIK = 'PFO_TLUMOCNIK',
  PFO_REQ = 'PFO_REQ',
  PFO_ARCH = 'PFO_ARCH',
  PFO_AIAT = 'PFO_AIAT',
  PFO_AZI = 'PFO_AZI',
  OVM = 'OVM',
  OVM_NOTAR = 'OVM_NOTAR',
  OVM_EXEKUT = 'OVM_EXEKUT',
  OVM_REQ = 'OVM_REQ',
  OVM_PO = 'OVM_PO',
  PO = 'PO',
  PO_ZAK = 'PO_ZAK',
  PO_REQ = 'PO_REQ',
}

export function constructSubjectName(subject: SubjectRecordDto | SubjectRecordCreateOrUpdateDto, includeClassification = false): string {
  let result = '';
  const classification = subject.classification;
  if (includeClassification) result += `${subject.classification} - `;
  if (classification === SubjectRecordClassification.FO) {
    if (subject.attributes[SubjectAttributeType.FIRST_NAME]?.value) {
      result += `${subject.attributes[SubjectAttributeType.FIRST_NAME].value} `;
    }
    result += subject.attributes[SubjectAttributeType.SURNAME]?.value ?? UNNAMED_SUBJECT_TEXT;
  }
  else {
    result += subject.attributes[SubjectAttributeType.BUSINESS_NAME]?.value ??
      subject.attributes[SubjectAttributeType.SURNAME]?.value ??
      UNNAMED_SUBJECT_TEXT;
  }
  return result;
}


export interface SubjectWithSource {
  subjectSource: SubjectRecordSource;
}

export interface SubjectRecordWithSourceDto extends SubjectRecordDto, SubjectWithSource {}

export interface SubjectPhysicalAddresses {
  mailing?: Nullable<AddressCompleteDto>;
  residential?: Nullable<AddressCompleteDto>;
  additional?: Nullable<AddressCompleteDto[]>;
  postalBoxes?: Nullable<PostalBoxAddressDto[]>;
}

export interface AddSubjectWizardData {
  relationType: SubjectObjectRelationType,
  relatedObjectType: RelatedObjectType,
  searchForm: Nullable<Record<string, any>>,
}

export enum StepCreateFormAction {
  SEARCH = 'SEARCH',
  CREATE = 'CREATE'
}

export interface StepCreateFormResult {
  subject: Nullable<SubjectRecordCreateOrUpdateDto>;
  action: StepCreateFormAction;
}

export interface SingleAddressCreateResult {
  addressForm: IczFormGroup;
  type: SubjectAttributeType;
  valid: boolean;
}

export type SingularSubjectAttributeDto = (
  SubjectAttributeAddressDto |
  SubjectAttributeDataBoxDto |
  SubjectAttributeGenderDto |
  SubjectAttributeLocalDateDto |
  SubjectAttributePostalBoxDto |
  SubjectAttributeStringDto
);

export interface SubjectReplacementConfig {
  allowSubjectReplacement: boolean;
  disallowedReplacementReason: string;
}

export type SubjectAttributeDto = SingularSubjectAttributeDto | SingularSubjectAttributeDto[];

export function isDataBoxDto(value: any): value is DataBoxDto | SubjectAttributeDataBoxDto {
  return value && typeof(value) === 'object' && 'id' in value && 'type' in value;
}

export function transformSubjectRecordCreateOrUpdateDtoToSubjectRecordDto(value: Nullable<SubjectRecordCreateOrUpdateDto | SubjectRecordDto>): Nullable<SubjectRecordDto> {
  if (!value) return null;
  if (!isNil((value as SubjectRecordDto).identifiable)) return value as SubjectRecordDto;
  else return {...value, identifiable: SubjectTemplateUtils.isIdentifiableByAttributes(value as SubjectRecordDto)} as SubjectRecordDto;
}

export function anonymousSenderSet(anonymousSender: boolean, form: IczFormGroup) {
  if (anonymousSender) {
    form.get('senderAddress')?.clearValidators();
    form.get('senderAddress')?.setValue(null);
    form.get('senderAddress')?.updateValueAndValidity({emitEvent: true, onlySelf: true});
    form.get('senderAddressValid')?.setValue(true);
    form.get('senderAddressValid')?.updateValueAndValidity({emitEvent: true, onlySelf: true});

    form.get('senderDefinition')?.clearValidators();
    form.get('senderDefinition')?.setValue(null);
    form.get('senderDefinition')?.updateValueAndValidity({emitEvent: true, onlySelf: true});
  } else {
    form.get('senderAddress')?.addValidators([]);
    form.get('senderAddress')?.updateValueAndValidity({emitEvent: true, onlySelf: true});
    form.get('senderAddressValid')?.setValue(null);
    form.get('senderAddressValid')?.updateValueAndValidity({emitEvent: true, onlySelf: true});

    form.get('senderDefinition')?.addValidators([SharedBusinessValidators.isValidSenderSubject()]);
    form.get('senderDefinition')?.updateValueAndValidity({emitEvent: true, onlySelf: true});
  }
}

export enum InvalidLegalFormHandling {
  FILTER = 'FILTER',
  DISABLE = 'DISABLE',
}

export function mapLegalForms(invalidLegalFormHandling?: InvalidLegalFormHandling) {
  return map(
    entries => (entries as LegalFormDto[]).filter(
      entry => {
        if (invalidLegalFormHandling === InvalidLegalFormHandling.FILTER) {
          return isValidNow(entry);
        }
        else {
          return true;
        }
      }
    ).map(
      entry => ({
        value: String(entry.code),
        label: [entry.code, entry.name].join(' - '),
        disabled: invalidLegalFormHandling === InvalidLegalFormHandling.DISABLE ? !isValidNow(entry) : false,
      } as IczOption<string>)
    )
  );
}

export function mapCountries() {
  return map(
      entries => (entries as CzemItemDto[]).map(
          option => ({
            value: option.alpha3Code,
            label: option.czechFullName,
          } as IczOption<string>)
      ).sort((a, b) => {
        if (a.label < b.label) return -1;
        else return 1;
      })
  );
}

export function subjectGetAttribute(subject: SubjectRecordDto, attr: keyof SubjectAttributesDto): Nullable<SubjectAttributeDto> {
  return subject.attributes[attr];
}

export function isSubjectAttributeNonEmpty(subjectAttribute: Nullable<SubjectAttributeDto>) {
  return Array.isArray(subjectAttribute) ? subjectAttribute.length > 0 : !isNil(subjectAttribute);
}

export function subjectHasAttribute(subject: SubjectRecordDto, attr: keyof SubjectAttributesDto): boolean {
  return isSubjectAttributeNonEmpty(subjectGetAttribute(subject, attr));
}

export function areSubjectAttributeValuesEqual(a1: Nullable<SubjectAttributeDto>, a2: Nullable<SubjectAttributeDto>) {
  if (isNil(a1) !== isNil(a2)) {
    return false;
  }
  else if (isNil(a1) && isNil(a2)) {
    return true;
  }
  else if (Array.isArray(a1) !== Array.isArray(a2)) {
    return false;
  }
  else if (Array.isArray(a1) && Array.isArray(a2)) {
    if (a1.length !== a2.length) {
      return false;
    }
    else {
      const attrValuesSorted1 = a1.map(a => a.value).sort();
      const attrValuesSorted2 = a2.map(a => a.value).sort();

      return isEqual(attrValuesSorted1, attrValuesSorted2); // todo(rb) verify correctness for non-string attributes
    }
  }
  else {
    return isEqual((a1 as SingularSubjectAttributeDto).value, (a2 as SingularSubjectAttributeDto).value);
  }
}

export function areNSubjectAttributeValuesEqual(...attrs: Nullable<SubjectAttributeDto>[]) {
  let out = true;

  for (let i = 0; i < attrs.length - 1; ++i) {
    out &&= areSubjectAttributeValuesEqual(attrs[i], attrs[i + 1]);
  }

  return out;
}
