/* tslint:disable */
/* eslint-disable */

/**
 * State of export for agenda transfer.
 */
export enum AgendaTransferExportState {
  OK = 'OK',
  ERROR = 'ERROR',
  PROCESSING = 'PROCESSING'
}
