import {ButtonDisablerFn} from '../../../button-collection/button-collection.component';
import {ExtendedCirculationActivityDto} from '../../model/elastic-extended-entities.interface';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {ActivityOperationValidators} from './activity-operation-validators';

export class ActivityToolbarDisablers {

  static isSomeActivityNotInProgress(selection: Nullable<ExtendedCirculationActivityDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, ActivityOperationValidators.isActivityNotInProgress());
    };
  }

  static isRejectedHandoverActivity(selection: Nullable<ExtendedCirculationActivityDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, ActivityOperationValidators.isRejectedHandoverActivity());
    };
  }

}
