import {getEsslComponentTemplate, NotificationModelMappers} from '../notification-message-body/abstract-notification-message-content';
import {
  InternalNotificationBulkComponentActionSubjectCode,
  InternalNotificationBulkDocumentActionSubjectCode,
  InternalNotificationBulkFileActionSubjectCode,
  InternalNotificationConsignmentSubjectCode,
  InternalNotificationSubjectCode
} from '../../../core/services/notifications/internal-notification.enum';
import {
  EsslComponentToastType,
  SComponentBulkOperationStartedData,
  SComponnentAnonymizationTemplateData,
  SConversionErrorTemplateData,
  SDigitalComponentTemplateData
} from '../../../core/services/notifications/essl-component-toast.service';
import {
  CirculationToastType,
  SCirculationHandoverTaskAssignedInitiatorData,
  SCirculationHandoverTaskPassedInitiatorData,
  SCirculationHandoverTaskRejectedInitiatorData,
  SCirculationHandoverTaskRevokedTargetParticipantData,
  SCirculationHandoverTaskSuccessInitiatorData,
  SCirculationTaskAssignedInitiatorData,
  SCirculationTaskPassedInitiatorData,
  SCirculationTaskRejectedInitiatorData,
  SCirculationTaskRevokedInitiatorData,
  SCirculationTaskSuccessInitiatorData
} from '../../../core/services/notifications/circulation-toast.service';
import {
  EsslComponentCirculationToastType,
  SCirculatedEsslComponentData,
  SEsslComponentApprovalApprovedInitiatorData,
  SEsslComponentApprovalPassInitiatorData,
  SEsslComponentApprovalRejectInitiatorData,
  SEsslComponentApprovalRevokeTargetParticipantData,
  SEsslComponentSignRejectInitiatorData,
  SEsslComponentSignRevokeTargetParticipantData,
  SEsslComponentSignSuccessInitiatorData
} from '../../../core/services/notifications/essl-component-circulation-toast.service';
import {
  DocumentToastType,
  SDocumentBulkOperationCountData,
  SDocumentTemplateData
} from '../../../core/services/notifications/document-toast.service';
import {RenditionToastType} from '../../../core/services/notifications/rendition-toast.service';
import {
  ConsignmentToastType,
  SConsignmentErrorToastData,
  SConsignmentToastData
} from '../../shared-business-components/consignment-dialog/consignments-toast.service';
import {FileCirculationToastType} from '../../../core/services/notifications/file-circulation-toast.service';
import {
  SStorageUnitBulkAactionTemplateData,
  SStorageUnitEntityTemplateData, SStorageUnitFillerTemplateData,
  SStorageUnitTemplateData,
  StorageUnitToastType
} from '../../../core/services/notifications/storage-unit-toast.service';
import {
  EpdzErrorToastType,
  SEpdzErrorErrorTemplateData,
  SEpdzErrorTemplateData,
  SEpdzGeneralErrorErrorTemplateData
} from '../../../core/services/notifications/epdz-error-toast.service';
import {FileToastType, SFileTemplateData} from '../../../core/services/notifications/file-toast.service';
import {
  RegistryOfficeToastType,
  SRegistryOfficeTransferErrorTemplateData,
  SRegistryOfficeTransferIncidentErrorTemplateData,
  SRegistryOfficeTransferIncidentTemplateData,
  SRegistryOfficeTransferTemplateData
} from '../../../core/services/notifications/registry-office-toast.service';
import {AdminToastType, SAdminErrorTemplateData, SAdminTemplateData} from '../../../core/services/notifications/admin-toast.service';

export const MESSAGE_HEADER_MAPPERS: NotificationModelMappers<InternalNotificationSubjectCode |
  InternalNotificationConsignmentSubjectCode |
  InternalNotificationBulkDocumentActionSubjectCode |
  InternalNotificationBulkComponentActionSubjectCode |
  InternalNotificationBulkFileActionSubjectCode
> = {
  [InternalNotificationSubjectCode.CONVERSION_WITH_CLAUSE_SUCCESS]: {
    template: EsslComponentToastType.CONVERSION_WITH_CLAUSE_SUCCESS,
    schema: SDigitalComponentTemplateData,
  },
  [InternalNotificationSubjectCode.CONVERSION_WITH_CLAUSE_ERROR]: {
    template: EsslComponentToastType.CONVERSION_WITH_CLAUSE_ERROR,
    schema: SConversionErrorTemplateData,
  },
  [InternalNotificationSubjectCode.CONVERSION_SUCCESS]: {
    template: EsslComponentToastType.CONVERSION_SUCCESS,
    schema: SDigitalComponentTemplateData,
  },
  [InternalNotificationSubjectCode.CONVERSION_ERROR]: {
    template: EsslComponentToastType.CONVERSION_ERROR,
    schema: SConversionErrorTemplateData,
  },
  [InternalNotificationSubjectCode.FLOW_TASK_ASSIGNED]: {
    template: CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR,
    schema: SCirculationTaskAssignedInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_TASK_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_INITIATOR,
    schema: SCirculationTaskSuccessInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_TASK_REJECTED]: {
    template: CirculationToastType.FLOW_TASK_REJECTED_INITIATOR,
    schema: SCirculationTaskRejectedInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_TASK_REVOKED]: {
    template: CirculationToastType.FLOW_TASK_REVOKED_INITIATOR,
    schema: SCirculationTaskRevokedInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_TASK_PASSED]: {
    template: CirculationToastType.FLOW_TASK_PASSED_INITIATOR,
    schema: SCirculationTaskPassedInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_HANDOVER_TASK_ASSIGNED]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR,
    schema: SCirculationHandoverTaskAssignedInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_HANDOVER_TASK_SUCCESS]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_SUCCESS_INITIATOR,
    schema: SCirculationHandoverTaskSuccessInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_HANDOVER_TASK_REJECTED]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_REJECTED_INITIATOR,
    schema: SCirculationHandoverTaskRejectedInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_HANDOVER_TASK_REVOKED]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT,
    schema: SCirculationHandoverTaskRevokedTargetParticipantData,
  },
  [InternalNotificationSubjectCode.FLOW_HANDOVER_TASK_PASSED]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_PASSED_INITIATOR,
    schema: SCirculationHandoverTaskPassedInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_APPROVAL_TASK_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_INITIATOR),
    schema: SEsslComponentApprovalApprovedInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_APPROVAL_TASK_REVOKED]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_REVOKE_TARGET_PARTICIPANT),
    schema: SEsslComponentApprovalRevokeTargetParticipantData,
  },
  // todo it became missing on BE [InternalNotificationSubjectCode.FLOW_COMPONENT_APPROVAL_TASK_DISAPPROVED]: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_INITIATOR,
  [InternalNotificationSubjectCode.FLOW_COMPONENT_APPROVAL_TASK_REJECTED]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_REJECTED_INITIATOR),
    schema: SEsslComponentApprovalRejectInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_APPROVAL_TASK_PASSED]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_PASSED_INITIATOR),
    schema: SEsslComponentApprovalPassInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_SIGNING_TASK_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_INITIATOR),
    schema: SEsslComponentSignSuccessInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_SIGNING_TASK_REVOKED]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_TASK_REVOKED_INITIATOR),
    schema: SEsslComponentSignRevokeTargetParticipantData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_SIGNING_TASK_REJECTED]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_REJECT_INITIATOR),
    schema: SEsslComponentSignRejectInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_SIGNING_TASK_SIGNED_INITIATOR]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_INITIATOR),
    schema: SCirculatedEsslComponentData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_SIGNING_TASK_REVOKED_TARGET_PARTICIPANT]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_REVOKE_TARGET_PARTICIPANT),
    schema: SEsslComponentSignRejectInitiatorData,
  },
  [InternalNotificationSubjectCode.FLOW_COMPONENT_SIGNING_TASK_REJECTED_INITIATOR]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_REJECT_INITIATOR),
    schema: SEsslComponentSignRejectInitiatorData,
  },
/*  [InternalNotificationSubjectCode.DOCUMENT_SETTLEMENT_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_SETTLED,
    schema: SDocumentTemplateData,
  },
  [InternalNotificationSubjectCode.FILE_SETTLEMENT_SUCCESS]: {
    template: FileToastType.FILE_SETTLED,
    schema: SFileTemplateData,
  },*/
  [InternalNotificationSubjectCode.FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED]: {
    template: RenditionToastType.FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED,
    schema: null,
  },
  [InternalNotificationSubjectCode.FILING_REGISTER_RENDITION_FINISHED]: {
    template: RenditionToastType.FILING_REGISTER_RENDITION_FINISHED,
    schema: null,
  },
  [InternalNotificationSubjectCode.OFFICE_DESK_POST_SUCCESS]: {
    template: ConsignmentToastType.OFFICE_DESK_POST_SUCCESS,
    schema: SConsignmentToastData,
  },
  [InternalNotificationSubjectCode.OFFICE_DESK_POST_ERROR]: {
    template: ConsignmentToastType.OFFICE_DESK_POST_ERROR,
    schema: SConsignmentErrorToastData,
  },
  [InternalNotificationSubjectCode.OFFICE_DESK_UNPOST_SUCCESS]: {
    template: ConsignmentToastType.OFFICE_DESK_UNPOST_SUCCESS,
    schema: SConsignmentToastData,
  },
  [InternalNotificationSubjectCode.OFFICE_DESK_UNPOST_ERROR]: {
    template: ConsignmentToastType.OFFICE_DESK_UNPOST_ERROR,
    schema: SConsignmentErrorToastData,
  },
  [InternalNotificationSubjectCode.EXECUTION_CLAUSE_DATE_CREATION_FINISHED]: {
    template: EsslComponentToastType.EXECUTION_CLAUSE_CREATION_SUCCESS,
    schema: SDigitalComponentTemplateData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_INSERT_DOCUMENTS_INTO_FILE_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_INSERTED_TO_FILE,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.DOCUMENT_SETTLEMENT_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_SETTLED,
    schema: SDocumentTemplateData,
  },
  [InternalNotificationSubjectCode.DOCUMENT_SETTLEMENT_ERROR]: {
    template: DocumentToastType.DOCUMENT_SETTLE_ERROR,
    schema: SDocumentTemplateData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_WITHDRAW_SETTLE_DOCUMENT_SUCCESS]: {
    template: DocumentToastType.WITHDRAW_DOCUMENT_SETTLEMENT_SUCCESS,
    schema: SDocumentTemplateData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_WITHDRAW_SETTLE_DOCUMENT_ERROR]: {
    template: DocumentToastType.WITHDRAW_DOCUMENT_SETTLEMENT_ERROR,
    schema: SDocumentTemplateData,
  },
  [InternalNotificationSubjectCode.FILE_SETTLEMENT_SUCCESS]: {
    template: FileToastType.FILE_SETTLED,
    schema: SFileTemplateData,
  },
  [InternalNotificationSubjectCode.FILE_SETTLEMENT_ERROR]: {
    template: FileToastType.FILE_SETTLE_ERROR,
    schema: SFileTemplateData,
  },
  [InternalNotificationBulkFileActionSubjectCode.BULK_WITHDRAW_SETTLE_FILE_SUCCESS]: {
    template: FileToastType.FILE_WITHDRAW_SETTLEMENT,
    schema: SFileTemplateData,
  },
  [InternalNotificationBulkFileActionSubjectCode.BULK_WITHDRAW_SETTLE_FILE_ERROR]: {
    template: FileToastType.FILE_WITHDRAW_SETTLEMENT_ERROR,
    schema: SFileTemplateData,
  },
  [InternalNotificationBulkFileActionSubjectCode.BULK_CLOSE_FILE_SUCCESS]: {
    template: FileToastType.FILE_CLOSED,
    schema: SFileTemplateData,
  },
  [InternalNotificationBulkFileActionSubjectCode.BULK_CLOSE_FILE_ERROR]: {
    template: FileToastType.FILE_CLOSE_ERROR,
    schema: SFileTemplateData,
  },
  [InternalNotificationBulkFileActionSubjectCode.BULK_REOPEN_FILE_SUCCESS]: {
    template: FileToastType.FILE_REOPENED_SUCCESS,
    schema: SFileTemplateData,
  },
  [InternalNotificationBulkFileActionSubjectCode.BULK_REOPEN_FILE_ERROR]: {
    template: FileToastType.FILE_REOPENED_ERROR,
    schema: SFileTemplateData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_INSERT_DOCUMENTS_INTO_FILE_ERROR]: {
    template: DocumentToastType.DOCUMENT_INSERTED_TO_FILE_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_REMOVE_DOCUMENTS_FROM_FILE_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_REMOVED_FROM_FILE,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_REMOVE_DOCUMENTS_FROM_FILE_ERROR]: {
    template: DocumentToastType.DOCUMENT_REMOVED_FROM_FILE_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_MOVED_TO_ANOTHER_FILE,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_ERROR]: {
    template: DocumentToastType.DOCUMENT_MOVED_TO_ANOTHER_FILE_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_REGISTER_DOCUMENT_SUCCESS]: {
    template: DocumentToastType.DOCUMENT_REGISTERED,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationBulkDocumentActionSubjectCode.BULK_REGISTER_DOCUMENT_ERROR]: {
    template: DocumentToastType.DOCUMENT_REGISTERED_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.CONVERSION_BULK_SUCCESS]: {
    template: EsslComponentToastType.CONVERSION_SUCCESS,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.CONVERSION_BULK_ERROR]: {
    template: EsslComponentToastType.CONVERSION_TO_OUTPUT_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.EXTRACTION_BULK_SUCCESS]: {
    template: EsslComponentToastType.COMPONENT_BULK_CONTAINER_EXTRACT_SUCCESS,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.EXTRACTION_BULK_ERROR]: {
    template: EsslComponentToastType.COMPONENT_BULK_CONTAINER_EXTRACT_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.CHANGE_WITH_CLAUSE_BULK_SUCCESS]: {
    template: EsslComponentToastType.CONVERSION_WITH_CLAUSE_SUCCESS,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.CHANGE_WITH_CLAUSE_BULK_ERROR]: {
    template: EsslComponentToastType.CONVERSION_TO_OUTPUT_WITH_CLAUSE_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.BULK_COMPONENT_LOCK_SUCCESS]: {
    template: EsslComponentToastType.COMPONENT_LOCKED,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.BULK_COMPONENT_LOCK_ERROR]: {
    template: EsslComponentToastType.COMPONENT_LOCKED_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.BULK_COMPONENT_UNLOCK_SUCCESS]: {
    template: EsslComponentToastType.COMPONENT_UNLOCKED,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.BULK_COMPONENT_UNLOCK_ERROR]: {
    template: EsslComponentToastType.COMPONENT_UNLOCKED_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.BULK_COMPONENT_DELETE_SUCCESS]: {
    template: EsslComponentToastType.COMPONENT_DELETION_SUCCESS,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationBulkComponentActionSubjectCode.BULK_COMPONENT_DELETE_ERROR]: {
    template: EsslComponentToastType.COMPONENT_DELETION_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_HANDOVER_FOR_SIGN_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR),
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_HAND_FOR_APPROVAL_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR),
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_ANALOG_SIGN_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT),
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_DIGITAL_SIGN_SUCCESS]: {
    template: getEsslComponentTemplate(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT),
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HANDOVER_SUCCESS]: {
    template: CirculationToastType.FLOW_BULK_HANDOVER_TASK_ASSIGNED_INITIATOR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_FILE_HANDOVER_SUCCESS]: {
    template: FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_HANDOVER_TAKEOVER_SUCCESS]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_HANDOVER_FILE_TAKEOVER_SUCCESS]: {
    template: CirculationToastType.FLOW_FILE_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HAND_FOR_APPROVAL_SUCCESS]: {
    template: CirculationToastType.FLOW_BULK_HAND_FOR_APPROVAL_DOCUMENT_INITIATOR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HAND_FOR_STATEMENET_SUCCESS]: {
    template: CirculationToastType.FLOW_BULK_HAND_FOR_STATEMENT_INITIATOR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_SUCCESS]: {
    template: CirculationToastType.FLOW_BULK_HAND_FOR_ACKNOWLEDGMENT_INITIATOR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_ACKNOWLEDGE_TARGET_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_APPROVE_BY_TARGET_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_STATEMENT_BY_TARGET_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_DISAPPROVE_BY_TARGET_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_APPROVE_BY_TARGET_SUCCESS]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_TARGET_PARTICIPANT,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_DISAPPROVE_BY_TARGET_SUCCESS]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_TARGET_PARTICIPANT,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_TAKEOVER_AND_PASS_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_FILE_TAKEOVER_AND_PASS_SUCCESS]: {
    template: FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_TASK_REJECT_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_REJECTED_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_TASK_REVOKE_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_TASK_PASS_TO_NEXT_SUCCESS]: {
    template: CirculationToastType.FLOW_TASK_PASSED_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_COMOPNENT_HANDOVER_FOR_SIGN_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_HAND_FOR_APPROVAL_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_ANALOG_SIGN_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_DIGITAL_SIGN_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_SIGNING_TARGET_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HANDOVER_ERROR]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_FILE_HANDOVER_ERROR]: {
    template: FileCirculationToastType.FILE_CIRCULATION_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HANDOVER_TAKEOVER_ERROR]: {
    template: CirculationToastType.FLOW_HANDOVER_TASK_TARGET_PARTICIPANT_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HAND_FOR_STATEMENET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HAND_FOR_APPROVAL_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_ACKNOWLEDGE_TARGET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_APPROVE_BY_TARGET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_STATEMENT_BY_TARGET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_DISAPPROVE_BY_TARGET_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_APPROVE_BY_TARGET_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_BY_TARGET_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_COMPONENT_DISAPPROVE_BY_TARGET_ERROR]: {
    template: EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_DISAPPROVAL_BY_TARGET_ERROR,
    schema: SComponentBulkOperationStartedData,
  },
  [InternalNotificationSubjectCode.BULK_DOCUMENT_TAKEOVER_AND_PASS_ERROR]: {
    template: CirculationToastType.FLOW_TASK_ERROR_TARGET_PARTICIPANT,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_FILE_TAKEOVER_AND_PASS_ERROR]: {
    template: FileCirculationToastType.FILE_TASK_CIRCULATION_BY_TARGET_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_TASK_REJECT_ERROR]: {
    template: CirculationToastType.FLOW_TASK_REJECTED_TARGET_PARTICIPANT_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_TASK_REVOKE_ERROR]: {
    template: CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_TASK_PASS_TO_NEXT_ERROR]: {
    template: CirculationToastType.FLOW_TASK_PASSED_TARGET_PARTICIPANT_ERROR,
    schema: SDocumentBulkOperationCountData,
  },
  [InternalNotificationSubjectCode.BULK_STORAGE_UNIT_LOCK_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_BLOCK_SUCCESS,
    schema: SStorageUnitBulkAactionTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_STORAGE_UNIT_LOCK_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_BLOCK_FAILED,
    schema: SStorageUnitBulkAactionTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_STORAGE_UNIT_UNLOCK_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_UNBLOCK_SUCCESS,
    schema: SStorageUnitBulkAactionTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_STORAGE_UNIT_UNLOCK_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_UNBLOCK_FAILED,
    schema: SStorageUnitBulkAactionTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_STORAGE_UNIT_DELETE_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_DELETE_SUCCESS,
    schema: SStorageUnitBulkAactionTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_STORAGE_UNIT_DELETE_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_DELETE_FAILED,
    schema: SStorageUnitBulkAactionTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_INSERT_TO_STORAGE_UNIT_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_INSERT_SUCCESS,
    schema: SStorageUnitEntityTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_INSERT_TO_STORAGE_UNIT_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_INSERT_ERROR,
    schema: SStorageUnitTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_WITHDRAW_FROM_STORAGE_UNIT_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_WITHDRAW_SUCCESS,
    schema: SStorageUnitEntityTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_WITHDRAW_FROM_STORAGE_UNIT_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_WITHDRAW_ERROR,
    schema: SStorageUnitTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_MOVE_BETWEEN_STORAGE_UNITS_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_MOVE_SUCCESS,
    schema: SStorageUnitEntityTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_MOVE_BETWEEN_STORAGE_UNITS_ERROR]: {
    template: StorageUnitToastType.STORAGE_UNIT_MOVE_ERROR,
    schema: SStorageUnitTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_EPDZ_ERROR_PROCESSING_SUCCESS]: {
    template: EpdzErrorToastType.EPDZ_ERROR_PROCESSING_SUCCESS,
    schema: SEpdzErrorTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_EPDZ_ERROR_PROCESSING_ERROR]: {
    template: EpdzErrorToastType.EPDZ_ERROR_PROCESSING_ERROR,
    schema: SEpdzErrorErrorTemplateData,
  },
  [InternalNotificationSubjectCode.EPDZ_ERROR_GENERAL]: {
    template: EpdzErrorToastType.EPDZ_MESSAGE_PROCESS_ERROR,
    schema: SEpdzGeneralErrorErrorTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_SUCCESS]: {
    template: RegistryOfficeToastType.RO_INCIDENT_RESOLVE_SUCCESS,
    schema: SRegistryOfficeTransferIncidentTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_ERROR]: {
    template: RegistryOfficeToastType.RO_INCIDENT_RESOLVE_ERROR,
    schema: SRegistryOfficeTransferIncidentErrorTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_CANCEL_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_CANCEL_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_CANCEL_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_CANCEL_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_RETRANSFER_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_RETRANSFER_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_RETRANSFER_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_RETRANSFER_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_RETURN_TO_OFFICE_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_RETURN_TO_OFFICE_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_CREATED_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_CREATED_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_TAKEN_OVER_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_TAKEN_OVER_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_TAKEN_OVER_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_TAKEN_OVER_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_REJECT_SUCCESS]: {
    template: RegistryOfficeToastType.RO_TRANSFER_REJECTED_SUCCESS,
    schema: SRegistryOfficeTransferTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_REJECT_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_REJECTED_ERROR,
    schema: SRegistryOfficeTransferErrorTemplateData,
  },
  [InternalNotificationSubjectCode.BULK_RO_TRANSFER_CREATED_ERROR]: {
    template: RegistryOfficeToastType.RO_TRANSFER_CREATED_ERROR,
    schema: SRegistryOfficeTransferTemplateData,
  },
  [InternalNotificationSubjectCode.NOMENCLATURE_SYNCHRONIZATION_UPLOAD_SUCCESS]: {
    template: AdminToastType.NOMENCLATURE_SYNCHRONIZATION_FINISHED,
    schema: SAdminTemplateData,
  },
  [InternalNotificationSubjectCode.NOMENCLATURE_SYNCHRONIZATION_UPLOAD_ERROR]: {
    template: AdminToastType.NOMENCLATURE_SYNCHRONIZATION_FAILED,
    schema: SAdminErrorTemplateData,
  },
  [InternalNotificationSubjectCode.NOMENCLATURE_SYNCHRONIZATION_UPDATE_SUCCESS]: {
    template: AdminToastType.NOMENCLATURE_SYNCHRONIZATION_FINISHED,
    schema: SAdminTemplateData,
  },
  [InternalNotificationSubjectCode.NOMENCLATURE_SYNCHRONIZATION_UPDATE_ERROR]: {
    template: AdminToastType.NOMENCLATURE_SYNCHRONIZATION_FAILED,
    schema: SAdminErrorTemplateData,
  },
  [InternalNotificationSubjectCode.COMPONENT_ANONYMIZATION_COMPLETED]: {
    template: EsslComponentToastType.COMPONENT_ANONYMIZATION_COMPLETED,
    schema: SComponnentAnonymizationTemplateData,
  },
  [InternalNotificationSubjectCode.COMPONENT_ANONYMIZATION_CANCELED]: {
    template: EsslComponentToastType.COMPONENT_ANONYMIZATION_CANCELED,
    schema: SComponnentAnonymizationTemplateData,
  },
  [InternalNotificationSubjectCode.AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_SUCCESS,
    schema: SDigitalComponentTemplateData,
  },
  [InternalNotificationSubjectCode.AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_ERROR]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_ERROR,
    schema: SConversionErrorTemplateData,
  },
  [InternalNotificationSubjectCode.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS]: {
    template: EsslComponentToastType.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS,
    schema: SDigitalComponentTemplateData,
  },
  [InternalNotificationSubjectCode.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR]: {
    template: EsslComponentToastType.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR,
    schema: SConversionErrorTemplateData,
  },
  [InternalNotificationSubjectCode.AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_SUCCESS,
    schema: SDigitalComponentTemplateData,
  },
  [InternalNotificationSubjectCode.FORM_FILLER_CLAUSE_SUCCESS]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_SUCCESS,
    schema: SDigitalComponentTemplateData,
  },
  [InternalNotificationSubjectCode.AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_ERROR]: {
    template: EsslComponentToastType.AUTHORIZED_CONVERSION_ERROR,
    schema: SConversionErrorTemplateData,
  },
  [InternalNotificationSubjectCode.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS]: {
    template: EsslComponentToastType.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS,
    schema: SDigitalComponentTemplateData,
  },
  [InternalNotificationSubjectCode.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR]: {
    template: EsslComponentToastType.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR,
    schema: SConversionErrorTemplateData,
  },
  [InternalNotificationSubjectCode.STORAGE_UNIT_FILLER_SUCCESS]: {
    template: StorageUnitToastType.STORAGE_UNIT_FILLER_SUCCESS,
    schema: SStorageUnitFillerTemplateData,
  },
};
