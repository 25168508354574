import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {IconCounterBadgeColor} from '../dashboard-icon-counter/dashboard-icon-counter.component';
import {map} from 'rxjs/operators';
import {AcknowledgementStatus, ApiInternalNotificationService} from '|api/notification';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {FilterOperator} from '@icz/angular-table';


@Component({
  selector: 'icz-dashboard-unread-notifications-counter',
  templateUrl: './dashboard-unread-notifications-counter.component.html',
  styleUrls: ['./dashboard-unread-notifications-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardUnreadNotificationsCounterComponent {

  protected loadingService = inject(LoadingIndicatorService);
  private apiInternalNotificationService = inject(ApiInternalNotificationService);

  readonly documentsNotificationsRoute = createAbsoluteRoute(
    ApplicationRoute.DOCUMENTS,
    DocumentsRoute.NOTIFICATIONS,
    {
      filter: `${FilterOperator.equals}('"acknowledgementStatus"','["${AcknowledgementStatus.UNREAD}"]')`
    }
  );

  unreadNotificationCount$ = this.loadingService.doLoading(
    this.apiInternalNotificationService.internalNotificationCount().pipe(
      map(counts => counts.count ?? 0)
    ),
    this
  );

  readonly IconCounterBadgeColor = IconCounterBadgeColor;

}
