import {Params} from '@angular/router';
import {isObjectLike} from 'lodash';
import {Observable} from 'rxjs';

export function constructBulkModalTitle(title: string): string {
  return `${title} ({{count}})`;
}

export function firstLetterUppercase(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function isWindowsOs() {
  return (navigator.platform.indexOf('Win') !== -1);
}

export function isChrome() {
  return (navigator as any).userAgentData?.brands?.some((b: {brand: string}) => b.brand === 'Google Chrome');
}

export function isMsEdge() {
  return (navigator as any).userAgentData?.brands?.some((b: {brand: string}) => b.brand === 'Microsoft Edge');
}

export function isFirefox() {
  return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
}

export function getPreferredBrowserLanguage() {
  if (navigator.languages !== undefined) {
    // 0th item of navigator.languages is the most preferred of supplied locales...
    return navigator.languages[0];
  }
  else { // IE Fallback
    return navigator.language;
  }
}

export const isEnumValue = <T>(e: T) => (token: any): token is T[keyof T] =>
  Object.values(e as Record<string, unknown>).includes(token as T[keyof T]);

// eslint-disable-next-line @typescript-eslint/ban-types -- accepts generic object
export function stripUnwantedProps<T extends object, U>(object: T,
                                                        unwanted: Array<keyof T>,
                                                        config: { removeNull: boolean,
                                                          removeEmptyString: boolean } = {
                                                          removeNull: false,
                                                          removeEmptyString: false
                                                        }): U {
  unwanted.forEach(prop => {
    if (object.hasOwnProperty(prop)) delete object[prop];
  });
  for (const [key, value] of Object.entries(object)) {
    if (config.removeEmptyString && value === '') delete object[key as keyof T];
    if (config.removeNull && value === null) delete object[key as keyof T];
  }
  return object as unknown as U;
}

export function replaceLast(haystack: string, needle: string, replacement: string): string {
  if (!haystack.includes(needle)) {
    return haystack;
  }
  else {
    const pcs = haystack.split(needle);
    const lastPc = pcs.pop();
    return pcs.join(needle) + replacement + lastPc;
  }
}

export function arrayRemove<T>(arr: T[], itemToRemove: T) {
  const index = arr.indexOf(itemToRemove);

  if (index > -1) {
    arr.splice(index, 1);
  }
}

export function serializeParamsToQueryString(params: Params, omitQuery?: boolean): string {
  if (Object.keys(params).length === 0) return '';

  let out = omitQuery ? '' : '?';

  const paramEntries = Object.entries(params);

  for (let i = 0; i < paramEntries.length; ++i) {
    const [k, v] = paramEntries[i];

    if (params.hasOwnProperty(k)) {
      out += `${k}=${v}`;

      if (i < paramEntries.length - 1) out += '&';
    }
  }

  return out;
}

export function validateJsonPassesReferenceObject<T>(selectedFile: T, referenceObj: T) {
  let valid = true;

  function checkValueIsDefined(val: any) {
    if (isObjectLike(val)) {
      for (const nestedKey in val) {
        if (val[nestedKey as any] === undefined) valid = false;
      }
    }
    else {
      if (val === undefined) valid = false;
    }
  }

  for (const key in referenceObj) {
    if ((referenceObj as Record<any, any>).hasOwnProperty(key)) {
      const val: any = selectedFile[key as keyof T];
      if (Array.isArray(val) && (referenceObj[key] as Array<any>).length > 0) {
        if ((val as Array<any>).length === 0) {
          valid = false;
        }
        val.forEach(arrayVal => {
          checkValueIsDefined(arrayVal);
        });
      }
      else {
        checkValueIsDefined(val);
      }
    }
  }
  return valid;
}

export function arrayFindLast<T>(arr: T[], findPredicate: (arrItem: T) => boolean): Nullable<T> {
  return [...arr].reverse().find(findPredicate);
}

export function pushOrCreateArray<T>(arr: Nullable<T[]>, value: any) {
  if (arr) {
    arr.push(value);
  } else {
    arr = [value];
  }
  return arr;
}

/**
 * Creates time in hh:mm format, that is suitable for time picker component.
 * @param date
 */
export function formatAsTimePickerTime(date: Date): string {
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${hours}:${minutes}`;
}

/**
 * Creates date in yyyy-mm-dd format, that is suitable for date picker component.
 * @param date
 */
export function formatAsDatePickerDate(date: Date): string {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * Combines date in yyyy-mm-dd format and time in hh:mm format to Date object
 * @param dateString
 * @param timeString
 */
export function combineDateTime (dateString: string, timeString: string): Date {
  const [year, month, day] = dateString.split('-').map(Number);
  const [hours, minutes] = timeString.split(':').map(Number);
  return new Date(year, month - 1, day, hours, minutes);
}

export function fileToBase64(file: File): Observable<string> {
  return new Observable(subscriber => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
      subscriber.next(reader.result as string);
      subscriber.complete();
    };
    reader.onerror = function (error) {
      subscriber.error(error);
    };
  });
}

export function stripQueryStringFromUrl(url: string) {
  return url.replace(/\?.+$/, '');
}
