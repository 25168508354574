import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {getUiKeyForSubjectCreateAttribute} from '../../../services/subjects.service';
import {isDataBoxDto, SubjectAttributeDto, } from '../model/subjects.model';
import {iczFormatDate} from '@icz/angular-essentials';
import {ApplicationLanguage} from '../../../core/services/environment.models';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {DataBoxDto, SubjectAttributesDto, SubjectAttributeStringDto, SubjectRecordDto} from '|api/commons';
import {
  addressToAddressWithType,
  isAddressDto,
  isCompleteAddressDto,
  isPOBoxWithAttributeId
} from '../model/addresses.model';
import {SubjectAttributeType} from '../model/subject-attribute-type';

const ATTRIBUTE_ORDER: SubjectAttributeType[] = [
  // names first
  SubjectAttributeType.DEGREE_BEFORE,
  SubjectAttributeType.FIRST_NAME,
  SubjectAttributeType.SURNAME,
  SubjectAttributeType.DEGREE_AFTER,
  SubjectAttributeType.BUSINESS_NAME,
  SubjectAttributeType.LEGAL_FORM,
  // secondary identifiers second
  SubjectAttributeType.BIRTH_DATE,
  SubjectAttributeType.CID,
  SubjectAttributeType.COMPANY_ID, // lp: Really, is Company ID useful?
  SubjectAttributeType.TAX_ID,
  SubjectAttributeType.VAT_ID,
  // addresses third
  SubjectAttributeType.RESIDENTIAL_ADDRESS,
  SubjectAttributeType.MAILING_ADDRESS,
  SubjectAttributeType.ADDITIONAL_ADDRESS,
];

@Pipe({
  name: 'subjectRecord',
  pure: true,
})
export class SubjectRecordPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  transform(subject: Nullable<SubjectRecordDto>): string {
    if (!subject) {
      return '';
    }

    const orderedSubjectInfo: Array<{attributeKey: string, attributeValue: string}> = [];

    for (const attributeType of ATTRIBUTE_ORDER) {
      for (const attributeKey in subject.attributes) {
        if (
          subject.attributes.hasOwnProperty(attributeKey) &&
          attributeKey === attributeType
        ) {
          const attributeValue = this.formatSubjectAttribute(attributeKey as SubjectAttributeType, subject!.attributes![attributeKey as keyof SubjectAttributesDto]!, subject);
          if (attributeValue) {
            orderedSubjectInfo.push({
              attributeKey,
              attributeValue,
            });
          }
        }
      }
    }

    return orderedSubjectInfo
      .map(attribute => `${this.translateService.instant(getUiKeyForSubjectCreateAttribute(attribute.attributeKey as SubjectAttributeType))}:
       ${attribute.attributeValue}`)
      .join('; ');
  }

  private formatSubjectAttribute(key: SubjectAttributeType, value: SubjectAttributeDto, subject: SubjectRecordDto): string {
    if (key === SubjectAttributeType.BIRTH_DATE) {
      return iczFormatDate(
        this.translateService.currentLang as ApplicationLanguage,
        (value as SubjectAttributeStringDto)?.value
      );
    }
    else if (Array.isArray(value)) {
      return value.map(subvalue => this.formatSubjectAttribute(key, subvalue, subject)).join(',');
    }
    else if (value && typeof(value) === 'object') {
      if (isDataBoxDto(value.value)) {
        return SubjectTemplateUtils.getDataboxString(value.value as DataBoxDto);
      }
      else if (isCompleteAddressDto(value.value)) {
        return SubjectTemplateUtils.getAddressString(addressToAddressWithType(value.value), subject.classification, true);
      }
      else if (isAddressDto(value.value)) {
        return SubjectTemplateUtils.getAddressString(addressToAddressWithType(value.value), subject.classification, true);
      }
      else if (isPOBoxWithAttributeId(value.value)) {
        return SubjectTemplateUtils.getPostalBoxString(value.value);
      }
      else {
        return value.value;
      }
    }

    return value ?? '';
  }

}
