import {NgModule} from '@angular/core';
import {AutoFocusDirective} from './auto-focus.directive';
import {FileSizePipe} from './file-size.pipe';
import {FormButtonsComponent} from './form-buttons/form-buttons.component';
import {InterpolatePipe} from './interpolate.pipe';
import {IconComponent} from './icon/icon.component';
import {LabelComponent} from './label/label.component';
import {PopoverComponent} from './popover/popover.component';
import {StaticProgressIndicatorComponent} from './static-progress-indicator/static-progress-indicator.component';
import {TagComponent} from './tag/tag.component';
import {TabDirective} from './tabs/tab.directive';
import {AlertComponent} from './alert/alert.component';
import {AnnotatedLabelComponent} from './annotated-label/annotated-label.component';
import {ChipComponent} from './chip/chip.component';
import {CardComponent} from './card/card.component';
import {HeadingComponent} from './heading/heading.component';
import {LinksToDirective} from './links-to.directive';
import {UserInitialsComponent} from './user-initials/user-initials.component';
import {TranslateEnumValuesListPipe} from './translate-enum-values-list.pipe';
import {GetNodeInjectorDirective} from './get-node-injector.directive';
import {PassedDeadlineWarningDirective} from './passed-deadline-warning.directive';
import {LinkWithoutHrefDirective} from './link-without-href.directive';
import {ExpandableParagraphComponent} from './expandable-paragraph/expandable-paragraph.component';
import {LocalizedBooleanPipe} from './localized-boolean.pipe';
import {SectionExpanderComponent} from './section-expander/section-expander.component';
import {SpinnerComponent} from './spinner/spinner.component';
import {SectionComponent} from './section/section.component';
import {ResizableSidebarComponent} from './resizable-sidebar/resizable-sidebar.component';
import {TooltipDirective} from './tooltip.directive';
import {ButtonComponent} from './button/button.component';
import {LocalizedDatePipe} from './date.pipe';
import {InaccessibleDirective} from './inaccessible.directive';
import {TabsComponent} from './tabs/tabs.component';
import {ToggleButtonComponent} from './toggle-button/toggle-button.component';
import {LocalizedDatetimePipe} from './datetime.pipe';
import {LocalizedTimePipe} from './time.pipe';
import {NewspaperLoaderComponent} from './newspaper-loader/newspaper-loader.component';
import {CopyToClipboardButtonComponent} from './copy-to-clipboard-button/copy-to-clipboard-button.component';
import {PopoverMenuComponent} from './popover-menu/popover-menu.component';
import {NumberToLocaleStringPipe} from './number-to-locale-string.pipe';

/**
 * @internal
 */
const ESSENTIALS = [
  ButtonComponent,
  IconComponent,
  LabelComponent,
  PopoverComponent,
  SpinnerComponent,
  StaticProgressIndicatorComponent,
  TagComponent,
  ToggleButtonComponent,
  AutoFocusDirective,
  InaccessibleDirective,
  InterpolatePipe,
  TooltipDirective,
  TabDirective,
  TabsComponent,
  LocalizedDatePipe,
  LocalizedDatetimePipe,
  FormButtonsComponent,
  AlertComponent,
  SectionComponent,
  PassedDeadlineWarningDirective,
  ChipComponent,
  CardComponent,
  LinksToDirective,
  UserInitialsComponent,
  HeadingComponent,
  GetNodeInjectorDirective,
  ResizableSidebarComponent,
  FileSizePipe,
  NumberToLocaleStringPipe,
  ExpandableParagraphComponent,
  LocalizedTimePipe,
  AnnotatedLabelComponent,
  TranslateEnumValuesListPipe,
  LocalizedBooleanPipe,
  SectionExpanderComponent,
  LinkWithoutHrefDirective,
  NewspaperLoaderComponent,
  CopyToClipboardButtonComponent,
  PopoverMenuComponent,
];

/**
 * The components, directives and pipes in this library are fully standalone. Import this NgModule
 * if you prefer using NgModules instead of standalone imports in your codebase.
 */
@NgModule({
  imports: ESSENTIALS,
  exports: ESSENTIALS,
})
export class EssentialsModule {}
