/* tslint:disable */
/* eslint-disable */
export enum AuthorizationBusinessReason {
  UNSPECIFIED = 'UNSPECIFIED',
  PREVIOUS_HOLDER = 'PREVIOUS_HOLDER',
  PREVIOUS_HOLDER_SECURITY_CATEGORY = 'PREVIOUS_HOLDER_SECURITY_CATEGORY',
  PREVIOUS_OWNER = 'PREVIOUS_OWNER',
  PREVIOUS_OWNER_SECURITY_CATEGORY = 'PREVIOUS_OWNER_SECURITY_CATEGORY',
  MAILROOM_OFFICE = 'MAILROOM_OFFICE',
  MAILROOM_OFFICE_SECURITY_CATEGORY = 'MAILROOM_OFFICE_SECURITY_CATEGORY',
  ACKNOWLEDGEMENT = 'ACKNOWLEDGEMENT',
  ACKNOWLEDGEMENT_SECURITY_CATEGORY = 'ACKNOWLEDGEMENT_SECURITY_CATEGORY',
  PUBLICATION = 'PUBLICATION',
  PUBLICATION_SECURITY_CATEGORY = 'PUBLICATION_SECURITY_CATEGORY',
  FOLDER = 'FOLDER',
  FOLDER_SECURITY_CATEGORY = 'FOLDER_SECURITY_CATEGORY',
  GROUPING_FOLDER = 'GROUPING_FOLDER',
  GROUPING_FOLDER_SECURITY_CATEGORY = 'GROUPING_FOLDER_SECURITY_CATEGORY',
  CLASSIFICATION_SHARING_DOCUMENT_TYPE = 'CLASSIFICATION_SHARING_DOCUMENT_TYPE',
  CLASSIFICATION_SHARING_DOCUMENT_TYPE_SECURITY_CATEGORY = 'CLASSIFICATION_SHARING_DOCUMENT_TYPE_SECURITY_CATEGORY',
  CLASSIFICATION_SHARING_ENTITY_TYPE = 'CLASSIFICATION_SHARING_ENTITY_TYPE',
  CLASSIFICATION_SHARING_ENTITY_TYPE_SECURITY_CATEGORY = 'CLASSIFICATION_SHARING_ENTITY_TYPE_SECURITY_CATEGORY',
  SET_BY_USER = 'SET_BY_USER',
  SET_BY_USER_SECURITY_CATEGORY = 'SET_BY_USER_SECURITY_CATEGORY',
  ORG_UNIT_SHARING = 'ORG_UNIT_SHARING',
  ORG_UNIT_SHARING_SECURITY_CATEGORY = 'ORG_UNIT_SHARING_SECURITY_CATEGORY',
  CONSIGNMENT_MAILROOM = 'CONSIGNMENT_MAILROOM',
  CONSIGNMENT_MAILROOM_SECURITY_CATEGORY = 'CONSIGNMENT_MAILROOM_SECURITY_CATEGORY',
  CONSIGNMENT_SECRETARIAT = 'CONSIGNMENT_SECRETARIAT',
  CONSIGNMENT_SECRETARIAT_SECURITY_CATEGORY = 'CONSIGNMENT_SECRETARIAT_SECURITY_CATEGORY',
  CONSIGNMENT_DOCUMENT_OWNER = 'CONSIGNMENT_DOCUMENT_OWNER',
  CONSIGNMENT_DOCUMENT_OWNER_SECURITY_CATEGORY = 'CONSIGNMENT_DOCUMENT_OWNER_SECURITY_CATEGORY',
  CONSIGNMENT_DISPATCH_OFFICE = 'CONSIGNMENT_DISPATCH_OFFICE',
  CONSIGNMENT_DISPATCH_OFFICE_SECURITY_CATEGORY = 'CONSIGNMENT_DISPATCH_OFFICE_SECURITY_CATEGORY',
  SECRETARIAT_TASK_OWNER = 'SECRETARIAT_TASK_OWNER',
  SECRETARIAT_TASK_OWNER_SECURITY_CATEGORY = 'SECRETARIAT_TASK_OWNER_SECURITY_CATEGORY',
  SECRETARIAT_ENTITY_OWNER = 'SECRETARIAT_ENTITY_OWNER',
  SECRETARIAT_ENTITY_OWNER_SECURITY_CATEGORY = 'SECRETARIAT_ENTITY_OWNER_SECURITY_CATEGORY',
  REGISTRY = 'REGISTRY',
  REGISTRY_SECURITY_CATEGORY = 'REGISTRY_SECURITY_CATEGORY',
  INSERTED_INTO_STORAGE_UNIT = 'INSERTED_INTO_STORAGE_UNIT',
  INSERTED_INTO_FILE = 'INSERTED_INTO_FILE',
  INSERTED_INTO_FILE_SECURITY_CATEGORY = 'INSERTED_INTO_FILE_SECURITY_CATEGORY',
  SHARED_FOLDER = 'SHARED_FOLDER',
  SHARED_FOLDER_SECURITY_CATEGORY = 'SHARED_FOLDER_SECURITY_CATEGORY',
  CURRENT_HOLDER = 'CURRENT_HOLDER',
  CURRENT_HOLDER_SECURITY_CATEGORY = 'CURRENT_HOLDER_SECURITY_CATEGORY',
  CURRENT_OWNER = 'CURRENT_OWNER',
  CURRENT_OWNER_SECURITY_CATEGORY = 'CURRENT_OWNER_SECURITY_CATEGORY',
  ADMINISTRATOR = 'ADMINISTRATOR',
  ADMINISTRATOR_SECURITY_CATEGORY = 'ADMINISTRATOR_SECURITY_CATEGORY',
  DEACTIVATED = 'DEACTIVATED',
  DEACTIVATED_SECURITY_CATEGORY = 'DEACTIVATED_SECURITY_CATEGORY',
  DESTROYED = 'DESTROYED',
  DESTROYED_SECURITY_CATEGORY = 'DESTROYED_SECURITY_CATEGORY',
  FP_TRANSFER_OWNERSHIP_ACTIVE = 'FP_TRANSFER_OWNERSHIP_ACTIVE',
  SYSTEM_AUTH_PRESENT = 'SYSTEM_AUTH_PRESENT',
  NON_EXCLUSIVE_ACCESS = 'NON_EXCLUSIVE_ACCESS',
  REGISTRY_TASK = 'REGISTRY_TASK',
  ISSD_IMPORT = 'ISSD_IMPORT',
  AGENDA_TRANSFER = 'AGENDA_TRANSFER',
  AGENDA_TRANSFER_SECURITY_CATEGORY = 'AGENDA_TRANSFER_SECURITY_CATEGORY'
}
