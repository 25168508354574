import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {
  SubjectTableColumnSet
} from '../../../../shared-business-components/subjects/subjects-table/subjects-table/subjects-table.component';
import {IczFormGroup} from '@icz/angular-form-elements';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  SubjectSearchWithResultsComponent
} from '../../../../shared-business-components/subjects/add-subject/subject-search-with-results/subject-search-with-results.component';
import {SubjectRecordDto, SubjectRecordFindMode} from '|api/commons';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {SubjectToolbarContext} from '../../../../shared-business-components/subjects/subjects-toolbar/subjects-toolbar.component';
import {IczOption} from '@icz/angular-form-elements';
import {FilterOperator} from '@icz/angular-table';

export interface SubjectCustomFilterDialogData {
  filterOperators: Array<IczOption<FilterOperator>>;
  form: IczFormGroup;
}

export interface SubjectCustomFilterDialogResult {
  form: Nullable<IczFormGroup>;
  selectedSubjects: SubjectRecordDto[];
}

@Component({
  selector: 'icz-subject-custom-filter-dialog',
  templateUrl: './subject-custom-filter-dialog.component.html',
  styleUrls: ['./subject-custom-filter-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class SubjectCustomFilterDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<SubjectCustomFilterDialogResult>();
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected data = injectModalData<SubjectCustomFilterDialogData>();

  @ViewChild(SubjectSearchWithResultsComponent)
  subjectSearch!: SubjectSearchWithResultsComponent;

  formGroupsToCheck!: string[];
  form!: IczFormGroup;

  readonly SubjectTableColumnSet = SubjectTableColumnSet;
  readonly SubjectRecordFindMode = SubjectRecordFindMode;

  selectedSubjects: SubjectRecordDto[] = [];

  foundSubjectsCount = 0;

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.form = this.data.form;
  }

  selectedSubjectsChanged(selected: SubjectRecordDto[]) {
    this.selectedSubjects = selected;
    const ids = selected.map(s => s.id!);
    this.form.get('value')!.setValue(ids);
  }

  applyFilter(): void {
    this.modalRef.close({
      form: this.form,
      selectedSubjects: this.selectedSubjects,
    });
  }

  close() {
    this.modalRef.close({form: null, selectedSubjects: []});
  }

  onSearchDone(resultCount: number) {
    this.foundSubjectsCount = resultCount;
  }

  resetFoundCounter() {
    this.foundSubjectsCount = 0;
    this.subjectSearch.resetSearch();
  }

	protected readonly SubjectToolbarContext = SubjectToolbarContext;
}
