<div
  class="flex"
  cdkOverlayOrigin
  #tasksPopupOrigin="cdkOverlayOrigin"
  (click.stop)="taskIconClicked()">

  <icz-icon
    svgIcon="task_green"
    size="small"
  ></icz-icon>

  <icz-popover [overlayOrigin]="tasksPopupOrigin" [isOpen]="isTasksPopupOpen" (onClose)="isTasksPopupOpen = false;">
    <div class="p-16">
      <div [waiting]="loadingService.isLoading$(this) | async">
        @if (circulationTask) {
          <div>
            <icz-heading [label]="activityHeading" [level]="'3'"></icz-heading>
            <div class="row mb-8">
              <icz-icon
                svgIcon="task_green"
                size="small"
              ></icz-icon>
              <icz-tag [label]="taskState" color="green"></icz-tag>
            </div>
            <div class="icz-body-1 mb-8">
              <span class="font-bold">{{ 'Externí aplikace' | translate }}:</span>
              {{ circulationTask.issdId | findInList:issdAppsOptions }}
            </div>
            <div class="icz-body-1 mb-8">
              <span class="font-bold">{{ 'Převzato' | translate }}:</span>
              {{ circulationTask.settlementDate | localizedDatetime }}
            </div>
            <div class="icz-body-1 mb-8">
              <span class="font-bold">{{ 'Předáno od' | translate }}:</span>
              {{ circulationTask.ownerUserId | findUserById | async }} ({{ circulationTask.ownerFunctionalPositionId | findFunctionalPositionById:true | async }})
            </div>
            <div class="icz-body-1 mb-8">
              <span class="font-bold">{{ 'Předáno dne' | translate }}:</span>
              {{ circulationTask.assignmentDate | localizedDatetime }}
            </div>
          </div>
        } @else {
          <icz-heading [label]="activityHeading" [level]="'3'"></icz-heading>
        }
      </div>
    </div>
  </icz-popover>
</div>
