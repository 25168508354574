<icz-validation-errors-list [control]="control">
  <div class="row" [formGroup]="_formGroup">
    <icz-date-picker
      formControlName="date" class="grow-2"
      [label]="label"
      outputFormat="date"
      [fieldDisabled]="fieldDisabled"
      [showValidationStatus]="false"
      [placeholder]="placeholder"
    ></icz-date-picker>
    <icz-time-picker
      formControlName="time" class="grow-1" label=" "
      [showValidationStatus]="false"
      [fieldDisabled]="fieldDisabled"
    ></icz-time-picker>
  </div>
</icz-validation-errors-list>
