import {DataSource} from '@angular/cdk/collections';
import {FlatTreeControl} from '@angular/cdk/tree';
import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TRUNCATED_TEXT_TOOLTIP_DELAY} from '@icz/angular-essentials';
import {TreeNode} from '../table-treeview/table-treeview.datasource';


@Component({
  selector: 'icz-table-tree-menu',
  templateUrl: './table-tree-menu.component.html',
  styleUrls: ['./table-tree-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableTreeMenuComponent<N extends TreeNode = TreeNode> {
  @Input({required: true})
  treeControl!: FlatTreeControl<N>;
  @Input({required: true})
  dataSource!: DataSource<N>;
  @Input({required: true})
  selectedMenuItem: Nullable<N>;
  @Input({required: true})
  showItemMenu!: boolean;
  @Input()
  maxWidth = 320;
  @Output()
  treeItemSelected = new EventEmitter<N>();

  readonly INDENT_SIZE = 10; // px
  readonly RESERVED_RIGHT_SPACE = 40; // px
  readonly TRUNCATED_TEXT_TOOLTIP_DELAY = TRUNCATED_TEXT_TOOLTIP_DELAY;
  readonly HORIZONTAL_PADDINGS_COMPENSATION = 24;
  popoverOpen = false;
  openedPopover: Nullable<N>;

  getTreeViewItemIcon(node: N) {
    if (node.leaf) {
      return 'dot';
    }
    else {
      if (node.expandable && this.treeControl.isExpanded(node)) {
        return 'expand_more';
      }
      else {
        return 'after';
      }
    }
  }

  getNodeElementWidth(node: N) {
    const rightEmptySpace = (node.level * this.INDENT_SIZE) + this.RESERVED_RIGHT_SPACE;
    return `calc(100% - ${rightEmptySpace}px)`;
  }

  onTreeMenuClick(node: N) {
    this.openedPopover = node;
  }

  isPopoverOpened(node: N) {
    return this.openedPopover === node;
  }

  closePopover() {
    return this.openedPopover = null;
  }
}
