import {
  AfterContentInit,
  Component,
  ContentChildren,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  QueryList
} from '@angular/core';
import {cloneDeep} from 'lodash';
import {merge} from 'rxjs';
import {filter} from 'rxjs/operators';
import {WizardStepDirective} from './wizard-step.directive';
import {IczOnChanges, IczSimpleChanges, TabItem} from '@icz/angular-essentials';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczModalRef} from '@icz/angular-modal';

export interface WizardStepChange {
  previousStep: WizardStepDirective;
  currentStep: WizardStepDirective;
}


@Component({
  selector: 'icz-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
})
export class WizardComponent implements IczOnChanges, AfterContentInit {

  private destroyRef = inject(DestroyRef);
  private modalRef = inject(IczModalRef, {optional: true});

  @HostBinding('class.in-modal')
  get isInModalContext() {
    return this.modalRef !== null;
  }

  @HostBinding('class.with-submit-bar')
  @Input()
  showSubmitBar = true;
  @Input() disableTransitionToPreviousSteps = false;
  @Input() alwaysEnableAllSteps = false;
  @Input() disableNextStepFormChecks = false;
  @Input() readonlyWizard = false;
  @Input() hideNextButton = false;
  @Input() hideTabset = false;

  @ContentChildren(WizardStepDirective)
  steps!: QueryList<WizardStepDirective>;

  @Output() finished = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();
  @Output() stepChanged = new EventEmitter<WizardStepChange>();

  tabs: TabItem[] = [];
  stepIndex = 0;

  get activeTab() {
    return this.stepIndex !== -1 ? this.tabs[this.stepIndex] : null;
  }

  get currentStep(): WizardStepDirective {
    return this.getStepByIndex(this.stepIndex)!;
  }

  get submitLabel() {
    return this.currentStep?.submitLabel ?? 'Další';
  }

  get submitDisabled() {
    if (this.stepIndex === this.steps.length - 1) {
      let result = false;
      this.steps.toArray().some(s => {
        if (s.stepHidden === false && s.isValid === false) result = true;
        return result;
      });
      return result;
    }
    const step = this.steps.find((_, i) => (i === this.stepIndex));
    return (!step || step.isValid === false);
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.alwaysEnableAllSteps) {
      this.recomputeTabDisableStates();
    }
  }

  ngAfterContentInit() {
    this.initializeTabs();

    // Wizard tabs did not pick up changes in WizardStepDirective#label
    // which caused weird behavior and change detection errors. This manual
    // change listener fixes that issue.
    merge(...this.steps.map(s => s.inputsChanged$)).pipe(
      filter(wizardStepChanges => {
        return Boolean(wizardStepChanges.changes.label || wizardStepChanges.changes.stepHidden);
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(wizardStepChanges => {
      const affectedTab = this.tabs.find(t => t.id === wizardStepChanges.id);

      if (affectedTab) {
        const labelChanges = wizardStepChanges.changes.label;
        const stepDisabledChanges = wizardStepChanges.changes.stepHidden;

        if (labelChanges) {
          affectedTab.label = labelChanges.currentValue;
        }
        if (stepDisabledChanges) {
          affectedTab.isHidden = stepDisabledChanges.currentValue;
        }

        this.tabs = cloneDeep(this.tabs);
      }
    });
  }

  getStepByIndex(index: number): Nullable<WizardStepDirective> {
    return this.steps?.get(index);
  }

  setStepByTab(tab: TabItem) {
    this.setStepIndex(this.tabs.indexOf(tab));
  }

  setStepById(stepId: string|number) {
    this.setStepIndex(this.tabs.findIndex(t => t.id === stepId));
  }

  nextStep() {
    if (this.currentStep.customSubmitHandler) {
      this.currentStep.customSubmitHandler();
    }
    else {
      this.basicNextStep();
    }
  }

  basicNextStep() {
    if (this.stepIndex === this.steps.length - 1) {
      this.finished.emit();
    }
    else {
      this.setStepIndex(this.stepIndex + 1);
    }
  }

  previousStep() {
    if (this.stepIndex > 0) {
      this.setStepIndex(this.stepIndex - 1);
    }
  }

  close() {
    this.canceled.emit();
  }

  private setStepIndex(newStepIndexValue: number) {
    if (newStepIndexValue === -1) {
      return;
    }

    const previousStep = this.currentStep;
    this.stepIndex = newStepIndexValue;

    this.recomputeTabDisableStates();

    this.stepChanged.emit({
      previousStep,
      currentStep: this.currentStep,
    });
  }

  private recomputeTabDisableStates() {
    for (let i = 0; i < this.tabs.length; ++i) {
      if (this.disableTransitionToPreviousSteps) {
        this.tabs[i].disabled = this.alwaysEnableAllSteps ? false : i !== this.stepIndex;
      }
      else {
        this.tabs[i].disabled = this.alwaysEnableAllSteps ? false : (i > this.stepIndex);
      }
    }
  }

  private initializeTabs() {
    let firstNonDisabledStepIndex: Nullable<number>;

    for (let i = 0; i < this.steps.length; ++i) {
      const step = this.getStepByIndex(i)!;

      if (!step.stepHidden) {
        firstNonDisabledStepIndex = i;
        break;
      }
    }

    if (!isNil(firstNonDisabledStepIndex)) {
      this.stepIndex = firstNonDisabledStepIndex;
    }

    for (let i = 0; i < this.steps.length; ++i) {
      const step = this.getStepByIndex(i)!;

      this.tabs.push({
        id: step.id,
        label: step.label ?? step.id,
        disabled: this.alwaysEnableAllSteps ? false : i > this.stepIndex,
        isHidden: step.stepHidden,
      });
    }
  }

}
