import {MatIcon, MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {ICONS as ESPIS_ICONS} from './icons';
import {ICONS as ESSENTIALS_ICONS} from '@icz/angular-essentials';
import {ICONS as FORM_ELEMENTS_ICONS} from '@icz/angular-form-elements';
import {ICONS as MODAL_ICONS} from '@icz/angular-modal';
import {ICONS as TABLE_ICONS} from '@icz/angular-table';

export const ICONS = {
  ...ESSENTIALS_ICONS,
  ...FORM_ELEMENTS_ICONS,
  ...MODAL_ICONS,
  ...TABLE_ICONS,
  ...ESPIS_ICONS,
};

export function initIconRegistry(
  iconRegistry: MatIconRegistry,
  domSanitizer: DomSanitizer,
) {
  /**
   * This patch fixes behavior related to icon glitching when router URL contains \" character sequences in Chrome.
   * The original method addressed an issue from 2018 which no longer manifestates in today's (2024) Safari neither Chrome.
   * see https://github.com/angular/components/issues/9276 .
   */
  MatIcon.prototype['_prependPathToReferences'] = () => {};

  Object.entries(ICONS).forEach(([name, svg]) => {
    if (svg) {
      const html = domSanitizer.bypassSecurityTrustHtml(svg);
      iconRegistry.addSvgIconLiteral(name, html);
    } else {
      const url = domSanitizer.bypassSecurityTrustResourceUrl(`/assets/icons/${name}.svg`);
      iconRegistry.addSvgIcon(name, url);
    }
  });
}
