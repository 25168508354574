import {inject, Injectable} from '@angular/core';
import {CzechAddressDto, HouseNumberType} from '|api/commons';
import {SubjectToastService, SubjectToastType} from '../../../../core/services/notifications/subject-toast.service';
import {
  FilterType,
  IczInMemoryDatasource,
  PopupSelectorTableDialogComponent,
  PopupSelectorTableDialogData,
  TableColumnsData
} from '@icz/angular-table';
import {AddressForm, AddressFormat} from '../../model/addresses.model';
import {IczModalService} from '@icz/angular-modal';
import {hashed, LoadingIndicatorService} from '@icz/angular-essentials';
import {map, Observable, of, switchMap} from 'rxjs';
import {ApiRuianAddressesService} from '|api/elastic';
import {formatCzechAddress} from '../../../address-pipe/address.pipe';

interface AddressIdentificationTableItem {
  id: string;
  address: CzechAddressDto;
  addressText: string;
  ruianCode: string;
}

export enum AddressIdentificationStatus {
  IDENTIFIED = 'IDENTIFIED',
  NOT_IDENTIFIED = 'NOT_IDENTIFIED',
  UNKNOWN = 'UNKNOWN',
}

export interface AddressIdentificationResult {
  status: AddressIdentificationStatus;
  address?: Nullable<CzechAddressDto>;
}

@Injectable({
  providedIn: 'root'
})
export class AddressIdentificationService {

  private loadingService = inject(LoadingIndicatorService);
  private modalService = inject(IczModalService);
  private subjectToastService = inject(SubjectToastService);
  private apiRuianAddressesService = inject(ApiRuianAddressesService);

  identifyAddress(addressFormValue: AddressForm): Observable<AddressIdentificationResult> {
    if (addressFormValue.value._Class === AddressFormat.CzechAddressDto) {
      const address = addressFormValue.value[AddressFormat.CzechAddressDto]!;

      const addressValidationDto = {
        _Class: AddressFormat.CzechAddressDto,
        city: address.city || null,
        country: address.country || null,
        district: address.district || null,
        houseNumber: address.houseNumberType === HouseNumberType.STREET_NUMBER ? address.houseNumber || null : null,
        orientationNumber: address.orientationNumber || null,
        orientationNumberLastCharacter: address.orientationNumberLastCharacter || null,
        postalCode: address.postalCode || null,
        postalOffice: address.postalOffice || null,
        registrationNumber: address.houseNumberType === HouseNumberType.REGISTRATION_NUMBER ? address.houseNumber : null,
        ruianId: address.id || null,
        street: address.street || null,
      };

      return this.loadingService.doLoading(
        this.apiRuianAddressesService.ruianAddressesIdentifyAddress({
          body: addressValidationDto,
        }),
        this
      ).pipe(
        switchMap(foundAddresses => {
          if (foundAddresses.length === 0) {
            this.subjectToastService.dispatchSimpleSubjectInfoToast(SubjectToastType.SUBJECT_ADDRESS_IDENTIFICATION_SUCCESS);

            return of({
              status: AddressIdentificationStatus.NOT_IDENTIFIED,
            });
          }
          else if (foundAddresses.length === 1) {
            this.subjectToastService.dispatchSimpleSubjectInfoToast(SubjectToastType.SUBJECT_ADDRESS_IDENTIFICATION_SUCCESS);

            return of({
              status: AddressIdentificationStatus.IDENTIFIED,
              address: foundAddresses[0],
            });
          }
          else {
            const columnsData = new TableColumnsData<'addressText'|'ruianCode'>([
              {
                id: 'addressText',
                label: 'Adresa',
                filterType: FilterType.NONE,
              },
              {
                id: 'ruianCode',
                label: 'Kód adresního místa',
                filterType: FilterType.NONE,
                fixedWidth: 200,
              }
            ]);

            return this.modalService.openComponentInModal<AddressIdentificationTableItem, PopupSelectorTableDialogData<string>>({
              component: PopupSelectorTableDialogComponent,
              modalOptions: {
                width: 1000,
                height: 600,
                titleTemplate: 'Vícero adres vyhovuje vyhledávacímu dotazu ({{count}})',
                titleTemplateContext: {
                  count: String(foundAddresses.length),
                },
                disableAutoMargin: true,
              },
              data: {
                tableId: 'address-validation-selector',
                tableTitle: '',
                tableTitleTag: '',
                schema: columnsData,
                tableConfig: {
                  defaultFilterColumns: [],
                },
                dataSource: new IczInMemoryDatasource(() => foundAddresses.map(address => ({
                  id: hashed(address),
                  address,
                  addressText: formatCzechAddress(address),
                  ruianCode: address.id,
                } as AddressIdentificationTableItem))),
              },
            }).pipe(
              map((resultValue: AddressIdentificationTableItem) => {
                if (resultValue) {
                  this.subjectToastService.dispatchSimpleSubjectInfoToast(SubjectToastType.SUBJECT_ADDRESS_IDENTIFICATION_SUCCESS);

                  return {
                    status: AddressIdentificationStatus.IDENTIFIED,
                    address: resultValue.address,
                  };
                }
                else {
                  return {
                    status: AddressIdentificationStatus.UNKNOWN,
                  };
                }
              }),
            );
          }
        }),
      );
    }
    else {
      return of({
        status: AddressIdentificationStatus.UNKNOWN,
      });
    }
  }

}
