import {DOCUMENT} from '@angular/common';
import {inject, Injectable, Injector, Type} from '@angular/core';
import {Observable} from 'rxjs';
import {take, tap} from 'rxjs/operators';
import {ComponentModalComponent} from './component-modal/component-modal.component';
import {IczModalRef} from './icz-modal-ref.injectable';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {AnyComponent, InterpolationContext} from '@icz/angular-essentials';
import {extendDefaultModalConfig} from './modals.utils';
import {FIRE_AND_FORGET_HEALTHCHECK_PROVIDER} from './modals.providers';

/**
 * Modal definition
 */
export interface IczModalDefinition<D> {
  /**
   * Component to place into the modal
   */
  component: Type<AnyComponent>;
  /**
   * Behavior options for the modal
   */
  modalOptions: IczModalOptions;
  /**
   * Modal data. Can be consumed in the component by dep. injection using utility function
   * injectModalData<T>() or by using @Inject(ICZ_MODAL_DATA) with constructor dependency injection.
   */
  data?: D;
}

/**
 * Behavior options for the modal
 */
export interface IczModalOptions {
  /**
   * a string with mustache-like expressions. might not need be defined if useCustomHeader is true
   */
  titleTemplate?: string;
  /**
   * context for title template if it contains mustache-like expressions
   */
  titleTemplateContext?: InterpolationContext;
  /**
   * Width: if number, in pixels. If string, only 'vw' unit with numeric dimension is supported.
   */
  width: number | string;
  /**
   * Height: if number, in pixels. If null, then height will be dynamically adjusted according to content height.
   * If string, only 'vh' unit with numeric dimension is supported.
   */
  // eslint-disable-next-line @typescript-eslint/ban-types -- null is reserved value
  height: number | string | null;
  /**
   * will remove header from the modal
   */
  useCustomHeader?: boolean;
  /**
   * if true, there will be "X" button, backdrop click listener and esc keypress listener which will close the modal
   */
  isClosable?: boolean;
  /**
   * will disable auto-positioning of inner content. handy for special modal content components
   */
  disableAutoMargin?: boolean;
  /**
   * will stretch modal component to full height, used when icz-form-buttons are not present inside modal component
   */
  disableButtonBar?: boolean;
  /**
   * in non-standard situations, an injector can be passed to the modal in order to bypass default injector hierarchy
   */
  injector?: Injector;
}

/**
 * A simple wrapper around MatDialog, auto-places a component of choice into ModalDialogComponent,
 * thus saving devs from boilerplate HTML+CSS and always subscribing to DialogRef#afterClosed()
 *
 * If you need any more advanced use-case, don't hesitate to use your trusty old MatDialog.
 */
@Injectable({
  providedIn: 'root',
})
export class IczModalService {

  private fireAndForgetHealthcheck = inject(FIRE_AND_FORGET_HEALTHCHECK_PROVIDER);
  private dialogService = inject(MatDialog);
  private document = inject(DOCUMENT);

  private openModals: IczModalRef<any>[] = [];

  /**
   * Default method for opening modal windows.
   * @param modalDef Modal definition.
   * @returns an observable of result data. Result can be pushed to this observable using IczModalRef.close(Result).
   *   There is a guarantee that the observable will emit at most one value so you do not need to unsubscribe.
   */
  openComponentInModal<TResult, TData>(modalDef: IczModalDefinition<TData>) {
    // When designing this service, I took current MatDialog#open usages in our project into account.
    // Those calls were always accompanied by subscribing to afterClosed thus this method will
    // return Observable<R> instead of DialogRef...
    const modalRef = this.openComponentInModalWithInstance(modalDef) as IczModalRef<TResult>;

    return (modalRef.afterClosed() as Observable<TResult>).pipe(
      take(1), // technical code: ensures that we really don't need to unsubscribe in components
      tap(_ => {
        // After closing a nested modal, focus should be returned
        // to another modal container otherwise Esc keypress will not work.
        const underlyingModalEls: NodeListOf<HTMLElement> = this.document.querySelectorAll('.mat-dialog-container');

        if (underlyingModalEls.length) {
          // CDK places child modal elements as next descendants of the
          // previous one (important in case of modal nesting).
          const lastModalEl = underlyingModalEls[underlyingModalEls.length - 1];
          lastModalEl.focus();
        }
      })
    );
  }

  /**
   * For advanced use-cases only. Similar behavior to openComponentInModal.
   * See documentation of IczModalRef for mode info regarding the advanced use-cases.
   */
  openComponentInModalWithInstance<TResult, TData>(modalDef: IczModalDefinition<TData>): IczModalRef<TResult> {
    this.fireAndForgetHealthcheck.checkBackend();

    modalDef = this.applyDefaultModalOptionsValues(modalDef);
    const config = this.getModalConfig<TData>(modalDef);

    const modalRef: IczModalRef<TResult> = this.dialogService.open<ComponentModalComponent<TData>, IczModalDefinition<TData>, TResult>(
      ComponentModalComponent,
      config
    ) as unknown as IczModalRef<TResult>;

    modalRef.forceClose = modalRef.close;

    modalRef.afterClosed().pipe(
      take(1), // technical code: ensures that we really don't need to unsubscribe
    ).subscribe(_ => {
      const index = this.openModals.indexOf(modalRef);

      if (index > -1) {
        this.openModals.splice(index, 1);
      }
    });
    this.openModals.push(modalRef);

    return modalRef;
  }

  /**
   * Closes all modals. If there are any checks in the modals which block their closing, they will be skipped.
   */
  closeAllModals() {
    for (const modalRef of this.openModals) {
      modalRef.forceClose(null);
    }
  }

  /**
   * A predicate which checks if there are currently open some modals with the given component.
   */
  hasOpenModalOfType(modalComponentClass: Type<AnyComponent>) {
    return this.openModals.findIndex(
      om => (om.componentInstance as ComponentModalComponent<unknown>).component instanceof modalComponentClass
    ) !== -1;
  }

  /**
   * A predicate which checks if there is a modal with the given component and is currently at the top of modal windows stack.
   */
  isModalComponentCurrentlyActive(modalComponent: ComponentModalComponent<unknown>) {
    if (this.openModals.length) {
      // eslint-disable-next-line
      return (this.openModals.at(-1) as unknown as MatDialogRef<unknown>) === modalComponent.dialogRef;
    }
    else {
      return false;
    }
  }

  private getModalConfig<D>(modalDef: IczModalDefinition<D>) {
    const maxPadding = 50; // px

    const heightLimit = this.document.body.offsetHeight;
    const widthLimit = this.document.body.offsetWidth;

    const finalHeight = this.getEffectiveModalDimensions(modalDef.modalOptions.height, heightLimit, maxPadding);
    const finalWidth = this.getEffectiveModalDimensions(modalDef.modalOptions.width, widthLimit, maxPadding);

    return extendDefaultModalConfig({
      width: modalDef.modalOptions.width ? finalWidth : undefined,
      height: modalDef.modalOptions.height ? finalHeight : undefined,
      injector: modalDef.modalOptions.injector,
      data: modalDef,
    });
  }

  private getEffectiveModalDimensions(dimension: Nullable<string|number>, limit: number, maxPadding: number) {
    if (typeof dimension === 'number' || dimension === null) {
      return dimension && dimension > limit ? `${limit - maxPadding}px` : `${dimension}px`;
    }
    else if (typeof dimension === 'string' && (dimension.endsWith('vh') || dimension.endsWith('vw'))) {
      const isVh = dimension.endsWith('vh');
      let viewportDimension = parseInt(dimension.replaceAll('vh', '').replaceAll('vw', ''), 10);
      viewportDimension = viewportDimension < 0 ? 0 : viewportDimension > 90 ? 90 : viewportDimension;
      return `${String(viewportDimension)}${isVh ? 'vh' : 'vw'}`;
    }
    else {
      throw new Error('Unsupported unit for modal width/height provided.');
    }
  }

  private applyDefaultModalOptionsValues<D>(modalDef: IczModalDefinition<D>) {
    modalDef = {...modalDef};

    if (modalDef.modalOptions.isClosable === undefined) {
      modalDef.modalOptions.isClosable = true;
    }

    return modalDef;
  }
}
