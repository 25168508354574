import {Component, DestroyRef, ElementRef, inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {PDFDocumentProxy} from 'ng2-pdf-viewer';
import {fromEvent, Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, take} from 'rxjs/operators';
import {DigitalComponentCompleteDto} from '|api/component';
import {
  CardInformation,
  ElementTranslate,
  InProgressSignature,
  SignMarkTimestampService
} from '../../../../services/sign-mark-timestamp.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {RemoteBinaryFileDownloadService} from '../../../../services/remote-binary-file-download.service';
import {Idt2Certificate, Idt2CertificateProvider} from '../../../../core/services/idt/idt.typedefs';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {getLatestDigitalComponentVersion} from '../../shared-document.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

function getTranslateMatrixOfElement(elementRef: ElementRef): ElementTranslate {
  const values = elementRef.nativeElement.style.transform.split(/\w+\(|\);?/);
  const transform = values[1].split(/,\s?/g).map((numStr: string) => parseInt(numStr, 10));

  return {
    translateX: transform[0],
    translateY: transform[1],
  };
}

export function getClientRectOfElement(selector: string): Nullable<DOMRect> {
  const elem = document.querySelector(selector);
  if (!elem) return null;
  else return elem.getBoundingClientRect();
}

interface ElementInformation {
  positionX: number; // in pixels
  positionY: number; // in pixels
  width: number;
  height: number;
}

/*
  DigitalComponentManualSignaturePositionComponent
  --------------------------------------------------
  This component is responsible for displaying and placing the signature to digitalComponent.
  1. Library ng2-pdf-viewer is used to view digitalComponent - maybe can be replaced in future because of library size
  2. Basic digitalComponent viewer in browser cannot be used because we need 1 solution same for every browser
  3. Basic digitalComponent viewer in browser is used only in digitalComponent-viewer component to only display digitalComponent
*/


@Component({
  selector: 'icz-digital-component-manual-signature-position',
  templateUrl: './digital-component-manual-signature-position.component.html',
  styleUrls: ['./digital-component-manual-signature-position.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DigitalComponentManualSignaturePositionComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private signMarkTimestampService = inject(SignMarkTimestampService);
  private destroyRef = inject(DestroyRef);

  @ViewChild('card', {read: ElementRef}) card!: ElementRef;

  @Input({required: true}) digitalComponent!: DigitalComponentCompleteDto;

  @Input() certificate: Nullable<Idt2Certificate>;

  @Input() certificateProvider!: Idt2CertificateProvider;

  // Reason and place can be filled automatically if selected in signature configuration
  // Otherwise it should be filled manually for each digitalComponent - depends also on backend configuration if reason and place are required
  @Input() reasonPlaceFillAutomatic!: boolean;

  @Input() reason!: string;

  @Input() place!: string;

  // get isFileProvider() {
  //   return this.certificateProvider === Idt2CertificateProvider.FILE;
  // }

  translate: {x: Nullable<number>; y: Nullable<number>} = {x: null, y: null};

  // Uncomment when debug of manual placement is needed (it will)
  // DEBUG = {x: 0, y: 0, convertedX: 0, convertedY: 0};

  previewScrolled = {x: 0, y: 0};

  currentPageNumber = 1;

  zoom = 1;

  hasCardValidPosition = false;

  hasCardBeenDropped = false;

  pdfLoadedAndRendered = false;

  signatureReasonPlaceForm = new IczFormGroup({
    reason: new IczFormControl<Nullable<string>>(null, []),
    place: new IczFormControl<Nullable<string>>(null, []),
    pageNumber: new IczFormControl<Nullable<number>>(null, []),
  });

  pdf: Nullable<PDFDocumentProxy> = null;

  pdfSource$!: Observable<Uint8Array>;

  private digitalComponentsWithManualSignatureInProgress$ = this.signMarkTimestampService.digitalComponentsWithManualSignatureInProgress$;

  private static getCardElementCoordinates(el: Element): ElementInformation {
    const {x, y, width, height} = el.getBoundingClientRect();
    return {positionX: x, positionY: y, width, height};
  }

  private static isCardInPage(cardEl: Element, pageEl: Nullable<Element>): boolean {
    if (!cardEl || !pageEl) return false;
    const cardInfo = DigitalComponentManualSignaturePositionComponent.getCardElementCoordinates(cardEl);
    const pageInfo = DigitalComponentManualSignaturePositionComponent.getCardElementCoordinates(pageEl);
    const isInX = cardInfo.positionX >= pageInfo.positionX && cardInfo.positionX + cardInfo.width < pageInfo.positionX + pageInfo.width;
    const isInY = cardInfo.positionY >= pageInfo.positionY && cardInfo.positionY + cardInfo.height < pageInfo.positionY + pageInfo.height;
    return isInX && isInY;
  }

  get formReason() {
    return this.signatureReasonPlaceForm.get('reason')!.value;
  }

  get formPlace() {
    return this.signatureReasonPlaceForm.get('place')!.value;
  }

  get numberOfPages() {
    return this.pdf?.numPages ?? 0;
  }

  get reasonFormControl() {
    return this.signatureReasonPlaceForm.get('reason');
  }

  get placeFormControl() {
    return this.signatureReasonPlaceForm.get('place');
  }

  get certIssuer() {
    return this.certificate ? this.getItemFromCertificate(this.certificate.issuer, 'CN=') : '';
  }

  get certSubject() {
    return this.certificate ? this.getItemFromCertificate(this.certificate.subject, 'CN=') : '';
  }

  get certOrg() {
    return this.certificate ? this.getItemFromCertificate(this.certificate.subject, 'O=') : '';
  }

  private getItemFromCertificate(value: string, code: string ) {
    if(value) {
      const csv = value.split(',');
      const name = csv.find(v => v.includes(code));
      return name ? name.split(code)[1] : '';
    } else {
      return '';
    }
  }

  // DigitalComponent is loaded from backend and need to be converted to Uint8Array
  // for ng2-pdf-viewer library to be properly displayed in template
  private getPdfSource() {
    if (!this.digitalComponent?.id) return;
    this.pdfSource$ = this.loadingService.doLoading(
      this.remoteBinaryFileDownloadService
        .fetchDigitalComponentVersion(getLatestDigitalComponentVersion(this.digitalComponent)!.id!)
        .pipe(map(binaryWithCharset => new Uint8Array(binaryWithCharset.buffer))),
      this
    );
  }

  afterPdfLoad(pdf: PDFDocumentProxy) {
    this.pdf = pdf;

    // timeout, because rendered is a bit later than pdf loaded
    setTimeout(() => {
      const pdfViewerElement = document.querySelector('.ng2-pdf-viewer-container');
      if (!pdfViewerElement) return;

      pdfViewerElement.scrollLeft = this.previewScrolled.x;
      pdfViewerElement.scrollTop = this.previewScrolled.y;
      this.pdfLoadedAndRendered = true;
      this.hasCardValidPosition = this.getCardInformation()!.hasValidPosition;

      fromEvent(pdfViewerElement, 'scroll')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe(() => {
          this.hasCardValidPosition = this.getCardInformation()!.hasValidPosition;
          if (this.hasCardBeenDropped) this.setDigitalComponentToManualSignaturePositionList();
        });
    }, 100);
  }

  handleDragDrop() {
    this.hasCardValidPosition = this.getCardInformation()!.hasValidPosition;
    this.hasCardBeenDropped = true;
    this.setDigitalComponentToManualSignaturePositionList();
  }

  private checkIfDigitalComponentIsInListWithProgress() {
    this.digitalComponentsWithManualSignatureInProgress$.pipe(take(1)).subscribe(list => {
      this.updateReasonAndPlaceForm(list);
      this.updateCardCoordinates(list);
    });
  }

  private checkForFormChange() {
    this.signatureReasonPlaceForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(250), distinctUntilChanged())
      .subscribe(() => this.setDigitalComponentToManualSignaturePositionList());
  }

  // If user selects automatic reason and place fill
  // then form fields should be disabled and automatically send to backend based on what user configured
  private disableReasonAndPlaceFormIfFillIsAutomatic() {
    if (this.reasonPlaceFillAutomatic) {
      this.reasonFormControl?.disable();
      this.placeFormControl?.disable();
    }
  }

  private updateReasonAndPlaceForm(list: InProgressSignature[]) {
    const digitalComponentInList = list.find(({digitalComponent}) => digitalComponent.id === this.digitalComponent.id);
    if (digitalComponentInList) {
      const {reason, place} = digitalComponentInList;
      this.signatureReasonPlaceForm.patchValue({place, reason});
      this.currentPageNumber = digitalComponentInList.pageNumber;
      this.previewScrolled.x = digitalComponentInList.scrolledX;
      this.previewScrolled.y = digitalComponentInList.scrolledY;
      if (this.reason && this.reasonPlaceFillAutomatic) {
        this.signatureReasonPlaceForm.patchValue({reason: this.reason});
      }
      if (this.place && this.reasonPlaceFillAutomatic) {
        this.signatureReasonPlaceForm.patchValue({place: this.place});
      }
    } else {
      const reason = this.reason;
      const place = this.place;
      this.signatureReasonPlaceForm.patchValue({place, reason});
    }
  }

  private updateCardCoordinates(list: InProgressSignature[]) {
    const digitalComponentInList = list.find(({digitalComponent}) => digitalComponent.id === this.digitalComponent.id);
    if (digitalComponentInList) {
      const {displayedPositionX, displayedPositionY} = digitalComponentInList;
      if (displayedPositionX === 0 && displayedPositionY === 0) return; // If both translates are 0, the card hasn't actually been moved, do nothing
      this.hasCardBeenDropped = true;
      this.translate.x = displayedPositionX;
      this.translate.y = displayedPositionY;
    }
  }

  private getCardPositionInformation(card: Element) {
    // Defined page variables
    let pageBefore: Nullable<Element>;
    const selectedPage = document.querySelector(`[data-page-number="${this.currentPageNumber}"]`);
    let pageAfter: Nullable<Element>;

    // Get before, current, after page elements
    if (this.currentPageNumber === 1) {
      pageAfter = document.querySelector(`[data-page-number="${this.currentPageNumber + 1}"]`);
    } else if (this.currentPageNumber === this.numberOfPages) {
      pageBefore = document.querySelector(`[data-page-number="${this.currentPageNumber - 1}"]`);
    } else {
      pageBefore = document.querySelector(`[data-page-number="${this.currentPageNumber - 1}"]`);
      pageAfter = document.querySelector(`[data-page-number="${this.currentPageNumber + 1}"]`);
    }
    // Find out where exactly is card placed
    const isCardInBeforePage = pageBefore ? DigitalComponentManualSignaturePositionComponent.isCardInPage(card, pageBefore) : false;
    const isCardInSelectedPage = DigitalComponentManualSignaturePositionComponent.isCardInPage(card, selectedPage);
    const isCardInAfterPage = pageAfter ? DigitalComponentManualSignaturePositionComponent.isCardInPage(card, pageAfter) : false;

    // Check if card has valid position
    const hasValidPosition = isCardInBeforePage || isCardInSelectedPage || isCardInAfterPage;

    return {hasValidPosition, isCardInBeforePage, isCardInAfterPage};
  }

  /*
    Library, which is used to view digitalComponent, displays more than one page in view
    So we need to check, if card is in page, which is currently displayed in center of view or in previous or next page
  */
  private getPageWhereCardIsPlaced(hasValidPosition: boolean, isCardInBeforePage: boolean, isCardInAfterPage: boolean): Nullable<number> {
    if (!hasValidPosition) {
      return null;
    }
    // Get page number, where is card placed
    let page = this.currentPageNumber;
    if (isCardInBeforePage) {
      page = page - 1;
    }
    if (isCardInAfterPage) {
      page = page + 1;
    }
    return page;
  }

  // There could be situation, when there is visible more than 1 page
  // PDF Viewer set active page to most centered page in view
  // But user can drop card to visible top or bottom page too
  // So this checks if user dropped card to some of these three pages - before, active, after
  // Also checks if whole card is really in page and for example not in whitespace between pages
  getCardInformation(): Nullable<CardInformation> {
    const cardElement = document.querySelector('.signature-card');
    const pdfViewerElement = document.querySelector('.ng2-pdf-viewer-container');
    const renderedPageElement = document.querySelector('div.page');
    if (!cardElement || !pdfViewerElement || !renderedPageElement) return null;

    const {hasValidPosition, isCardInBeforePage, isCardInAfterPage} = this.getCardPositionInformation(cardElement);

    // Get coordinates of card in pixels
    const {positionX, positionY} = DigitalComponentManualSignaturePositionComponent.getCardElementCoordinates(cardElement);
    let pageWhereCardIsPlaced = this.getPageWhereCardIsPlaced(hasValidPosition, isCardInBeforePage, isCardInAfterPage)!;
    if (pageWhereCardIsPlaced < 1) pageWhereCardIsPlaced = 1;
    if (pageWhereCardIsPlaced > this.numberOfPages) pageWhereCardIsPlaced = this.numberOfPages;
    this.signatureReasonPlaceForm.get('pageNumber')!.setValue(pageWhereCardIsPlaced);

    return {
      pageNumber: pageWhereCardIsPlaced!,
      scrolledX: pdfViewerElement.scrollLeft,
      scrolledY: pdfViewerElement.scrollTop,
      pageHeight: renderedPageElement.clientHeight,
      hasValidPosition,
      positionX,
      positionY,
    };
  }

  /*
    This method is used to add digitalComponent to in progress list, which is then used in tabs of signature configuration
    It is also used to check, if certain digitalComponent is already in progress and has valid position, info or has even beed dropped and can be signed
  */
  private setDigitalComponentToManualSignaturePositionList() {
    const cardInformation = this.getCardInformation();
    if (!cardInformation) return;

    if (this.hasCardValidPosition) {
      this.signatureReasonPlaceForm.markFormGroupTouched();
      this.signatureReasonPlaceForm.updateValueAndValidity();
    }

    const sideMargin = 16;
    const configColumnInTabSelector = '.manual-signature-config-col';
    const configColumnInTabWidth = getClientRectOfElement(configColumnInTabSelector)!.width;
    const translateMatrix = getTranslateMatrixOfElement(this.card);
    const translateX = translateMatrix.translateX;
    const translateY = translateMatrix.translateY;

    this.signMarkTimestampService.addDigitalComponentToManualSignatureInProgressList({
      digitalComponent: this.digitalComponent,
      ...cardInformation,
      reason: this.formReason!,
      place: this.formPlace!,
      translateX: translateX - configColumnInTabWidth - 2 * sideMargin,
      translateY: translateY + 38 + (2 * 16), // lp: (38 + (2 * 16)) is the so called challenge-height, can be looked up in SCSS file of this component
      showValidity: this.hasCardBeenDropped,
      isReadyForSign: this.signatureReasonPlaceForm.valid && this.hasCardValidPosition,
      displayedPositionX: translateX,
      displayedPositionY: translateY,
    });

    /* Uncomment when debug of manual placement is needed (it will)
    const pdfViewerElement = document.querySelector('.ng2-pdf-viewer-container');
    this.DEBUG.x = translateX - getClientRectOfElement('.manual-signature-config-col')!.width - 2 * 16;
    this.DEBUG.y = translateY;
    this.DEBUG.convertedX = this.DEBUG.x + pdfViewerElement!.scrollLeft;
    this.DEBUG.convertedY = (this.DEBUG.y + pdfViewerElement!.scrollTop);
    */
  }

  ngOnInit() {
    this.getPdfSource();
    this.checkIfDigitalComponentIsInListWithProgress();
    this.checkForFormChange();
    this.disableReasonAndPlaceFormIfFillIsAutomatic();
  }

}
