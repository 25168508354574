import {GenericOwnElasticConsignmentWithConsignee} from '../model/own-consignment-model';
import {IczTableDataSource} from '@icz/angular-table';
import {OwnConsignmentsSearchService} from '../../../../services/own-consignments-search.service';


export class DocumentDetailOwnConsignmentsDatasource extends IczTableDataSource<GenericOwnElasticConsignmentWithConsignee> {
  constructor(
    searchService: OwnConsignmentsSearchService,
    documentId: number,
  ) {
    super(searchParams => searchService.findOwnDocumentOwnConsignments(documentId, searchParams));
  }
}
