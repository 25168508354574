import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {OrganizationalStructureEntityDto, OrganizationalStructureEntityType} from '|api/commons';
import {AuthorizedEntityType, DocumentPermission, FilePermission,} from '|api/core';
import {
  ManualSharingCreateDto,
  ManualSharingWithFineGrainedPermissionsCreateDto,
  PermissionService
} from '../services/permission.service';
import {
  GeneralAuthorizationResult,
  GeneralAuthorizedOperation,
  getInsufficientPermissionsTooltip,
  isAuthorizedOperationGranted
} from '../permissions.utils';
import {PermissionSelectorValue} from '../permission-selector-popup/permission-selector-popup.component';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  OrganizationalStructureOption,
  OrganizationalStructureService
} from '../../../../core/services/organizational-structure.service';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {OrganizationStructureType} from '../../functional-position-selector/org-structure-selector.component';
import {locateOptionByValue} from '@icz/angular-form-elements';

/*
  Permission add toolbar
  1. Is used to add or disable permissions to a user for certain entity
  2. It is composed from permission-selector and org-structure-selector
  3. User can select multiple org-structure entities and permissions for them
*/

@Component({
  selector: 'icz-permission-add-toolbar',
  templateUrl: './permission-add-toolbar.component.html',
  styleUrls: ['./permission-add-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionAddToolbarComponent implements OnInit, IczOnChanges {

  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private permissionService = inject(PermissionService);
  private loadingIndicatorService = inject(LoadingIndicatorService);
  private orgStructureService = inject(OrganizationalStructureService);

  @Input({required: true}) entityType!: AuthorizedEntityType;
  @Input({required: true}) entityId!: number;
  @Input() disablePermissionToolbar = false;
  @Input() disableOrgUnitPermissions = false;
  @Input({required: true}) manageSharingAuthorizedOperation!: GeneralAuthorizedOperation;
  @Input({required: true}) authorizationResult!: GeneralAuthorizationResult;
  @Output() permissionAdded = new EventEmitter<void>();

  form = new IczFormGroup({
    orgStructureElementIds: new IczFormControl<Nullable<Array<number>>>(null),
    permissionOptions: new IczFormControl<Nullable<PermissionSelectorValue>>(null),
  });

  orgStructureOptions: OrganizationalStructureOption[] = [];

  private get arePermissionSelected() {
    return (
      Boolean(this.permissionOptions?.value?.permissionSetLevel) || Boolean(this.permissionOptions?.value?.permissions?.length)
    );
  }

  get canAddPermission() {
    return isAuthorizedOperationGranted(this.authorizationResult, this.manageSharingAuthorizedOperation);
  }

  get isSubmitDisabled() {
    return !this.arePermissionSelected || !this.form.value.orgStructureElementIds || this.form.value.orgStructureElementIds.length === 0 || !this.arePermissionsControlsUsable;
  }

  get arePermissionsControlsUsable() {
    return this.canAddPermission && !this.disablePermissionToolbar;
  }

  get permissionOptions() {
    return this.form.get('permissionOptions');
  }

  get isLoading$() {
    return this.loadingIndicatorService.isLoading$(this.permissionService);
  }

  get disabledSubmitTooltip() {
    if (this.isSubmitDisabled) {
      if (!this.canAddPermission) {
        const tooltipData = getInsufficientPermissionsTooltip(
          this.authorizationResult,
          this.manageSharingAuthorizedOperation,
          this.translateService,
        );

        if (tooltipData?.tooltip) {
          return this.translateParser.interpolate(
            tooltipData.tooltip,
            tooltipData?.tooltipContext ?? {}
          );
        }
        else {
          return null;
        }
      }
      else {
        return 'fe.ui.tooltip.permissions.DISABLED_ADD_PERMISSION_SUBMIT';
      }
    }
    return null;
  }

  readonly OrganizationStructureType = OrganizationStructureType;

  private loadOrgStructureOptions() {
    this.loadingIndicatorService
      .doLoading(this.orgStructureService.orgStructureOptions(), this)
      .subscribe(options => (this.orgStructureOptions = options));
  }

  private getOrganizationalStructureEntityTypeFromOriginId(originId: string) {
    if (originId === 'ou') {
      return OrganizationalStructureEntityType.ORGANIZATIONAL_UNIT;
    }
    {
      return OrganizationalStructureEntityType.FUNCTIONAL_POSITION;
    }
  }

  private getManualSharingCreateDto(): ManualSharingCreateDto {
    const {orgStructureElementIds, permissionOptions} = this.form.value;

    if (orgStructureElementIds && permissionOptions) {
      const {revoking, permissions, permissionSetLevel, detailedPermissions} = permissionOptions;
      const permissionsFromSelector = permissions;

      const sharedWith: OrganizationalStructureEntityDto[] = orgStructureElementIds.map(
        (orgStructureElementId: number) => {
          const {id, originId} = locateOptionByValue(this.orgStructureOptions, orgStructureElementId)!;
          return {
            organizationalStructureEntityId: id!,
            organizationalStructureEntityType: this.getOrganizationalStructureEntityTypeFromOriginId(originId!)!,
          };
        }
      );

      const createDto: ManualSharingCreateDto = {
        revoking,
        documentId: this.entityId,
        fileId: this.entityId,
        storageUnitId: this.entityId,
        sharedFolderId: this.entityId,
        permissions: [],
        sharedWith,
      };
      if (detailedPermissions) {
        let permissions: (DocumentPermission | FilePermission)[] = [];
        if (this.entityType === AuthorizedEntityType.DOCUMENT) {
          permissions = permissionsFromSelector as DocumentPermission[];
        } else {
          permissions = permissionsFromSelector as FilePermission[];
        }

        delete createDto.permissionSetLevel;
        return {
          ...createDto,
          permissions
        } as ManualSharingCreateDto;
      } else {
        delete (createDto as ManualSharingWithFineGrainedPermissionsCreateDto).permissions;

        return {
          ...createDto,
          permissionSetLevel: permissionSetLevel!
        } as ManualSharingCreateDto;
      }
    }
    else {
      throw new Error('Permissions not defined');
    }
  }

  addPermission() {
    const permission = this.getManualSharingCreateDto();
    this.permissionService.addPermission(this.entityType, permission).subscribe(() => {
      this.permissionAdded.emit();
      this.form.reset();
    });
  }

  ngOnInit() {
    this.loadOrgStructureOptions();
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.disablePermissionToolbar && changes.disablePermissionToolbar.currentValue) {
      if (this.disablePermissionToolbar) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
  }
}
