import {DestroyRef, inject, Injectable} from '@angular/core';
import {InternalNotificationKey} from '|api/notification';
import {Button} from '../button-collection/button-collection.component';
import {z, ZodType} from 'zod';
import {WebSocketNotificationParameters} from './web-socket-notifications.service';
import {ApplicationConfigService} from '../../core/services/config/application-config.service';
import {BehaviorSubject} from 'rxjs';
import {UserSettingsService} from '../../services/user-settings.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {EsslErrorResponseDto} from '../../core/services/communication-error-dialog.service';
import {interpolateBackendErrorMessage} from '../shared-business-components/interpolate-backend-error.pipe';

export function getBulkToastHeaderTemplateTranslateKey<T>(toastType: T) {
  return `fe.ui.toast.bulk.header.${toastType}`;
}

export function getToastHeaderTemplateTranslateKey<T>(toastType: T) {
  return `fe.ui.toast.header.${toastType}`;
}

export function getToastBodyTemplateTranslateKey<T>(toastType: T) {
  return `fe.ui.toast.body.${toastType}`;
}

export const SImplicitlyStringifiable = z.union([z.string(), z.number(), z.boolean(), z.null()]);
export type ImplicitlyStringifiable = z.infer<typeof SImplicitlyStringifiable>;

export const SErrorParameters = z.array(z.string());

export const CLOSE_THIS_TOAST = '_close';

export type ToastTemplateData = Partial<Record<InternalNotificationKey, ImplicitlyStringifiable|Array<ImplicitlyStringifiable>>>;

export enum MessageType {
  INFO = 'INFO', // green
  WARNING = 'WARNING', // blue
  ERROR = 'ERROR', // red
  DEV_WARNING = 'DEV_WARNING', // rainbow
}

type TemplateDefinitionWithoutData = {
  template: string; // markdown string with mustache-like expressions
};

type TemplateDefinitionWithData<D> = TemplateDefinitionWithoutData & {
  templateData: D; // {templateVariable -> templateValue}
  templateDataSchema: ZodType;
};

export type TemplateDefinition<D> = TemplateDefinitionWithoutData | TemplateDefinitionWithData<D>;

export function isTemplateDefinitionWithData<T>(td: Nullable<TemplateDefinition<T>>): td is TemplateDefinitionWithData<T> {
  return !isNil((td as TemplateDefinitionWithData<T>)?.templateData);
}

export function esslErrorDtoToToastParameters(translateService: TranslateService, error: EsslErrorResponseDto) {
  return {
    [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(translateService, error),
    [InternalNotificationKey.ERROR_PARAMETERS]: error.innerErrorParameters ?? [],
  };
}

export interface ToastMessage<H = any, B = any> {
  type: MessageType;
  timestamp: Nullable<Date>;
  data: Nullable<{
    header: TemplateDefinition<H>;
    body?: TemplateDefinition<B> | Array<TemplateDefinition<B>>;
    buttons?: Button[];
  }>;
  duration: number;
  isUnclosable: boolean;
  isPermanent: boolean;
  isBackendNotification: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {

  private destroyRef = inject(DestroyRef);
  private userSettingsService = inject(UserSettingsService);
  private applicationConfigService = inject(ApplicationConfigService);

  private readonly newDefaultMessage: ToastMessage = {
    data: null,
    isUnclosable: false,
    timestamp: null,
    duration: 10_000,
    type: MessageType.INFO,
    isPermanent: false,
    isBackendNotification: false,
  };
  messages$ = new BehaviorSubject<ToastMessage[]>([]);

  connectionInfo!: ToastMessage;

  disableInfoToasts = false;

  constructor() {
    this.userSettingsService.disableInfoToasts$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(disableInfoToasts => {
      this.disableInfoToasts = disableInfoToasts;
    });
  }

  reinitialize() {
    this.messages$.next([]);
  }

  dispatchToast(newMessage: Partial<ToastMessage>) {
    if (newMessage.type === MessageType.INFO && this.disableInfoToasts) {
      return;
    }

    const header = newMessage.data?.header;
    let isDataValidAccordingToSchema = true;

    if (isTemplateDefinitionWithData(header)) {
      const headerValidationResult = header.templateDataSchema?.safeParse(header.templateData).success ?? true;
      let bodyValidationResult = true;

      const body = newMessage.data?.body;

      if (body) {
        if (Array.isArray(body)) {
          bodyValidationResult = body.map(
            b => isTemplateDefinitionWithData(b) ?
              b.templateDataSchema?.safeParse(b.templateData).success ?? true :
              true
          ).reduce(
            (prev, curr) => prev && curr,
            true
          );
        }
        else if (isTemplateDefinitionWithData(body)) {
          bodyValidationResult = body.templateDataSchema?.safeParse(body.templateData).success ?? true;
        }
      }

      isDataValidAccordingToSchema = headerValidationResult && bodyValidationResult;
    }

    const coalescedMessage: ToastMessage = {
      ...this.newDefaultMessage,
      ...newMessage,
      timestamp: new Date(),
      type: (!isDataValidAccordingToSchema && this.applicationConfigService.isErrorReportingEnabled) ?
        MessageType.DEV_WARNING :
        newMessage.type!,
    };

    this.messages$.next([
      ...this.messages$.value,
      coalescedMessage,
    ]);
  }

  // overloads enforcing usage consistency
  dispatchSimpleToast(messageType: MessageType, templateType: string): void;
  dispatchSimpleToast(messageType: MessageType, templateType: string, templateData: ToastTemplateData, templateDataSchema: ZodType): void;

  dispatchSimpleToast(
    messageType: MessageType,
    templateType: string,
    templateData?: ToastTemplateData,
    templateDataSchema?: ZodType,
  ): void {
    this.dispatchToast({
      type: messageType,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(templateType),
          templateData,
          templateDataSchema,
        },
      },
    });
  }

  /**
   * Method used for displaying possibly bulk toasts, with possible fallback to single toast mode when bulk size is 1.
   * @param preserveBodyMode:
   * - true: used for toasts whose single version had both header and body
   * - false: used for toasts whose single version was a simple toast with header only
   */
  dispatchBulkToast(
    messageType: MessageType,
    templateType: string,
    templateData: WebSocketNotificationParameters[],
    singleTemplateDataSchema: ZodType,
    preserveBodyMode = false
  ) {
    if (!templateData.length) {
      return;
    }
    else if (templateData.length === 1) {
      if (preserveBodyMode) {
        this.dispatchToast({
          type: messageType,
          data: {
            header: {
              template: getToastHeaderTemplateTranslateKey(templateType),
              templateData: templateData[0],
              templateDataSchema: singleTemplateDataSchema,
            },
            body: {
              template: getToastBodyTemplateTranslateKey(templateType),
              templateData: templateData[0],
              templateDataSchema: singleTemplateDataSchema,
            },
          },
        });
      }
      else {
        this.dispatchToast({
          type: messageType,
          data: {
            header: {
              template: getToastHeaderTemplateTranslateKey(templateType),
              templateData: templateData[0],
              templateDataSchema: singleTemplateDataSchema,
            },
          },
        });
      }
    }
    else {
      this.dispatchToast({
        type: messageType,
        data: {
          header: {
            template: getBulkToastHeaderTemplateTranslateKey(templateType),
            templateData: {
              [InternalNotificationKey.COUNT]: templateData.length,
            },
            templateDataSchema: z.object({[InternalNotificationKey.COUNT]: z.number()})
          },
          body: templateData.map(td => ({
            template: preserveBodyMode ? getToastBodyTemplateTranslateKey(templateType) : getToastHeaderTemplateTranslateKey(templateType),
            templateData: td,
            templateDataSchema: singleTemplateDataSchema,
          })),
        }
      });
    }
  }

  dismissToast(index: number) {
    this.messages$.value.splice(index, 1);
    this.messages$.next([...this.messages$.value]);
  }

}
