import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/**
 * A mass translation utility pipe which accepts a list of enum values as strings,
 *   enum translation prefix and produces a comma separated list of translated
 *   enum values as a result.
 *
 * It is assumed that translation keys for enum values are in ngx-translate dictionary in form "en.<enum name>.<enum value>".
 */
@Pipe({
  name: 'translateEnumValuesList',
  pure: false, // output might change based on language change
  standalone: true,
})
export class TranslateEnumValuesListPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  /**
   * Mass translates multiple values originating from a single enum.
   */
  transform(value: string[], enumName: string): string {
    return value
            .map(v => `en.${enumName}.${v}`)
            .map(v => this.translateService.instant(v))
            .join(', ');
  }

}
