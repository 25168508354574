import {ChangeDetectorRef, EventEmitter, inject, Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, of, Subject, switchMap} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {AnalogComponentOrigin} from '|api/commons';
import {
  AnalogComponentToDocumentReferenceCreateDto,
  ApiDigitalComponentVersionService,
  ApiMediumComponentService,
  ApiPaperComponentService,
  ApiPhysicalItemComponentService,
  MediumComponentCreateDto,
  PaperComponentCreateDto,
  PhysicalItemComponentCreateDto
} from '|api/component';
import {EsslAnalogComponentCreateDto, EsslAnalogComponentDto} from '../../../../services/essl-component-search.service';
import {IczFormArray, IczFormGroup} from '@icz/angular-form-elements';
import {
  AnalogComponentCreateFormComponent,
  EsslAnalogComponentCreateDtoWithDigitalContent,
  EsslAnalogComponentType,
  EsslAnalogComponentTypeAsFields
} from '../../analog-component-create-form/analog-component-create-form.component';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {ComponentUploadStatus} from '../essl-components-utils';

interface AnalogComponentDtoWithClientFormData {
  analogComponentDto: AnalogComponentToDocumentReferenceCreateDto;
  digitalComponentVersionId: Nullable<number>;
}

@Injectable({
  providedIn: 'root',
})
export class AnalogComponentSubmitDataService {

  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private apiPaperComponentService = inject(ApiPaperComponentService);
  private apiMediumComponentService = inject(ApiMediumComponentService);
  private apiPhysicalItemComponentService = inject(ApiPhysicalItemComponentService);

  fileBlob: Nullable<File> = null;
  isUploading = false;
  analogComponentsToLink$ = new Subject<AnalogComponentToDocumentReferenceCreateDto[]>();
  analogComponentsFinishedLinkingToDigitalRendition$ = new BehaviorSubject(false);

  mapEsslAnalogComponentDtoToAnalogComponentDtoTiedWithDigitalRendition(analogComponentDto: EsslAnalogComponentDto, digitalComponentVersionId: Nullable<number>): AnalogComponentDtoWithClientFormData {
    return {
      analogComponentDto: {
        analogComponentId: analogComponentDto.id!,
        auditInfo: analogComponentDto.auditInfo,
        availability: analogComponentDto.availability,
        description: analogComponentDto.description,
        isFinal: analogComponentDto.isFinal,
        label: analogComponentDto.label,
        note: analogComponentDto.note,
        relationType: analogComponentDto.relationType,
      }, digitalComponentVersionId
    };
  }

  startUpload(formControlsBasedOnDialogType: IczFormArray,
              indexOfDigitalContent: number,
              changeDetectorRef: ChangeDetectorRef,
  ) {
    const controlByIndex = formControlsBasedOnDialogType.controls[indexOfDigitalContent];
    controlByIndex.get('status')!.setValue(ComponentUploadStatus.UPLOADING);
    controlByIndex.get('originType')!.setValue(AnalogComponentOrigin.ANALOG_DIGITIZATION);
    controlByIndex.markAsDirty();
    this.isUploading = true;

    const body = {
      file: this.fileBlob!,
    };

    this.apiDigitalComponentVersionService.digitalComponentVersionUploadNewFile({body}).subscribe({
      next: result => {
        controlByIndex.get('digitalComponentVersionDto')!.setValue(result);
        controlByIndex.get('status')!.setValue(ComponentUploadStatus.SUCCESS);
        changeDetectorRef.detectChanges();
        this.isUploading = false;
      },
      error: () => {
        controlByIndex.get('digitalComponentVersionDto')!.setValue(null);
        controlByIndex.get('status')!.setValue(ComponentUploadStatus.ERROR);
        changeDetectorRef.detectChanges();
        this.isUploading = false;
      }
    });
  }

  submit(form: IczFormGroup,
         component: AnalogComponentCreateFormComponent,
         analogComponentType: EsslAnalogComponentType,
         loadingIndicatorService: LoadingIndicatorService,
         formControlsBasedOnDialogType: IczFormArray,
         submitted: EventEmitter<AnalogComponentToDocumentReferenceCreateDto[]>,
         ) {
    if (this.isUploading) {
      return;
    }
    const paperComponents = (form.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormArray)?.value;
    const mediumComponents = (form.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormArray)?.value;
    const physicalItemComponents = (form.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormArray)?.value;
    const isPaperComponentsForm = analogComponentType === EsslAnalogComponentType.PAPER_COMPONENT;
    const isMediumComponentsForm = analogComponentType === EsslAnalogComponentType.MEDIUM_COMPONENT;

    const createReqs: Observable<Nullable<AnalogComponentDtoWithClientFormData>>[] = [];

    let components: Nullable<EsslAnalogComponentCreateDto[]>;
    let createRequest: (body: any) => Observable<EsslAnalogComponentDto>;

    if (isPaperComponentsForm) {
      components = paperComponents;
      createRequest = (body: PaperComponentCreateDto) => this.apiPaperComponentService.paperComponentCreate({body});
    } else if (isMediumComponentsForm) {
      components = mediumComponents;
      createRequest = (body: MediumComponentCreateDto) => this.apiMediumComponentService.mediumComponentCreate({body});
    } else {
      components = physicalItemComponents;
      createRequest = (body: PhysicalItemComponentCreateDto) => this.apiPhysicalItemComponentService.physicalItemComponentCreate({body});
    }

    loadingIndicatorService.startLoading(component);

    components!.forEach((component, index) => {
      const digitalComponentVersionId = (component as EsslAnalogComponentCreateDtoWithDigitalContent).digitalComponentVersionDto?.id;
      const componentAsFormElement = {...component as EsslAnalogComponentCreateDtoWithDigitalContent};
      const componentHasDigitalRendition = Boolean(componentAsFormElement.digitalComponentVersionDto);
      delete componentAsFormElement.digitalComponentVersionDto;
      delete componentAsFormElement.status;

      createReqs.push(
        createRequest(componentAsFormElement).pipe(
          tap(_ => {
            if (componentHasDigitalRendition) formControlsBasedOnDialogType.controls[index].get('status')?.setValue(ComponentUploadStatus.SUCCESS);
          }),
          map((analogComponentDto: EsslAnalogComponentDto) => {
            return this.mapEsslAnalogComponentDtoToAnalogComponentDtoTiedWithDigitalRendition(analogComponentDto, digitalComponentVersionId);
          }),
          catchError(_ => {
            if (componentHasDigitalRendition) formControlsBasedOnDialogType.controls[index].get('status')?.setValue(ComponentUploadStatus.ERROR);
            loadingIndicatorService.endLoading(component);
            return of(null);
          }))
      );
    });

    forkJoin(createReqs).pipe(
      switchMap((analogComponentDtosWithClientFormData: (Nullable<AnalogComponentDtoWithClientFormData>[])) => {
        this.analogComponentsToLink$.next(analogComponentDtosWithClientFormData.map(a => a!.analogComponentDto));

        const analogsToBeLinkedWithDigitalRendition = analogComponentDtosWithClientFormData.filter(ac => Boolean(ac!.digitalComponentVersionId));

        if (analogsToBeLinkedWithDigitalRendition.length) {
          return this.apiPaperComponentService.paperComponentLinkPaperComponentToDigitalRendition({
            body: {
              analogComponentToDigitalRenditionReferenceList: analogsToBeLinkedWithDigitalRendition.map(ac => {
                return {analogComponentId: ac!.analogComponentDto.analogComponentId, digitalRenditionId: ac!.digitalComponentVersionId!};
              })
            }
          }).pipe(
            map(_ => true), // paperComponentLinkPaperComponentToDigitalRendition returns void obs, so we map success as TRUE
            catchError(_ => {
              loadingIndicatorService.endLoading(component);
              return of(false); // paperComponentLinkPaperComponentToDigitalRendition returns void obs, so we map error as FALSE
            })
          );
        } else {
          return of(true);
        }
      }))
      .subscribe((linkingToDigitalRenditionResult: boolean) => {
        loadingIndicatorService.endLoading(component);
        this.analogComponentsFinishedLinkingToDigitalRendition$.next(linkingToDigitalRenditionResult);
      });

    const submittedSub = combineLatest([this.analogComponentsToLink$, this.analogComponentsFinishedLinkingToDigitalRendition$])
      .subscribe(([analogComponentsToLink, analogComponentsFinishedLinkingToDigitalRendition]) => {
        if (analogComponentsFinishedLinkingToDigitalRendition) {
          submitted.next(analogComponentsToLink);
          submittedSub.unsubscribe();
          this.analogComponentsFinishedLinkingToDigitalRendition$.next(false);
        }
      });
  }

}
