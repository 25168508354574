import {Observable} from 'rxjs';
import {DocumentDto, FileDto, StorageUnitDto} from '|api/document';
import {
  TransferableObject
} from '../components/shared-business-components/document-toolbar/services/transferable-object-toolbar-buttons.service';
import {SearchRecordSourceRegistryOfficeTransferActivityDto} from '|api/elastic';

/**
 * Necessary abstract class for avoiding cyclic dependency between shared and other modules,
 * dependency direction being reversed by providing services implementing this shared abstract
 */
export abstract class AbstractDocumentDialogsManagerService {
  abstract openDeactivationDialog(documentData: DocumentDto): Observable<Nullable<boolean>>;
  abstract openStorageUnitInsertDialog(selectedObjects: (DocumentDto | FileDto)[], moveFromStorageUnitId?: number): Observable<boolean>;
  abstract openCurrentStorageUnitInsertDialog(selectedStorageUnit: StorageUnitDto): Observable<boolean>;
  abstract openTransferToRegistryOfficeDialog(selectedObjects: TransferableObject[]): Observable<boolean>;
  abstract openTransferTakeoverByRegistryOfficeDialog(selectedObjects: SearchRecordSourceRegistryOfficeTransferActivityDto[]): Observable<boolean>;
  abstract openSuspendSettlementDialog(selectedObjects: (DocumentDto | FileDto)[]): Observable<boolean>;
  abstract openResumeSettlementDialog(selectedObjects: (DocumentDto | FileDto)[]): Observable<boolean>;
  abstract openRetentionYearEditDialog(selectedObjects: (DocumentDto | FileDto)[], editCheckYear: boolean): Observable<boolean>;
}
