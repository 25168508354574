import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {ApiDigitalComponentVersionService, DigitalComponentVersionValidatedDto} from '|api/component';
import {AbstractControl} from '@angular/forms';
import {ApiEmailUploadNodesOauthService} from '|api/config-server';
import {ApiReceivedDigitalConsignmentService, EmailConsignmentCreateDto} from '|api/sad';
import {
  IczFormArray,
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidatorFn,
  IczValidators,
  locateOptionByValue,
  ValidationErrorMessage
} from '@icz/angular-form-elements';
import {ComponentUploadStatus} from '../essl-components/essl-components-utils';
import {CheckUnsavedFormDialogService} from '../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../lib/form-group-checks';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {DialogService, IczModalRef} from '@icz/angular-modal';
import {DocumentToastService, DocumentToastType} from '../../../core/services/notifications/document-toast.service';
import {EsslErrorCodeExceptionDto} from '|api/commons';
import {fileUploadFormArray} from '../file-upload-list/file-upload-list.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export class EmailUploadValidators {
  @ValidationErrorMessage('Je nutné vybrat aspoň jeden soubor s e-mailovou zprávou.')
  static filesSelected(): IczValidatorFn {
    return (control: AbstractControl) => {
      if ((control as IczFormArray).length > 0) {
        return null;
      }
      else {
        return {
          fileSelected: false,
        };
      }
    };
  }
}

@Component({
  selector: 'icz-create-email-consignment-dialog',
  templateUrl: './create-email-consignment-dialog.component.html',
  styleUrls: ['./create-email-consignment-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEmailConsignmentDialogComponent implements IFormGroupCheckable, OnInit {
  loadingService = inject(LoadingIndicatorService);
  modalRef = inject(IczModalRef<Nullable<boolean>>);
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private apiEmailUploadNodesOauthService = inject(ApiEmailUploadNodesOauthService);
  private apiReceivedDigitalConsignmentService = inject(ApiReceivedDigitalConsignmentService);
  private documentToastService = inject(DocumentToastService);
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private destroyRef = inject(DestroyRef);
  private dialogService = inject(DialogService);

  readonly maxSizeInfoText = 'Maximální velikost nahrávaných zpráv může být {{ maximumSize }}.';
  form = new IczFormGroup({
    distributionNodeId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  emailMessageFilesForm =  new IczFormGroup({
    messageFiles: fileUploadFormArray([
      EmailUploadValidators.filesSelected(),
    ]),
  });

  formGroupsToCheck!: string[];
  distributionNodesOptions: IczOption[] = [];
  selectedMaximumEmailSize: Nullable<string>;
  currentDistributionNodeId: Nullable<number>;

  get messageFilesControl() {
      return this.emailMessageFilesForm.get('messageFiles') as IczFormArray;
  }

  get distributionNodeIdControl() {
    return this.form.get('distributionNodeId')! as IczFormControl;
  }

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.loadingService.doLoading(
      this.apiEmailUploadNodesOauthService.emailUploadNodesOauthGetAll(),
      this
    ).subscribe(emailNodes => {
      this.distributionNodesOptions = emailNodes.map(n => ({value: n.id, label: n.nodeName, data: {fileSize: n.maximumMessageSize}}));
      if (this.distributionNodesOptions.length > 0) {
        this.distributionNodeIdControl.setValue(this.distributionNodesOptions[0].value);
        this.currentDistributionNodeId = Number(this.distributionNodesOptions[0].value);
      }
    });

    this.distributionNodeIdControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(val => {
      if (val) {
        let selectedNode = locateOptionByValue(this.distributionNodesOptions, val);
        if (selectedNode) {
          this.selectedMaximumEmailSize = (selectedNode.data as any).fileSize;
          if (this.messageFilesControl.length > 0 && !isNil(this.currentDistributionNodeId)) {
            this.dialogService.openQuestionDialogWithAnswer({
              title: 'Změna distribučního uzlu',
              question: 'Opravdu chcete změnit distribuční uzel?',
              description: 'Po změně uzlu je potřeba znovu nahrát soubory z počítače.',
              leftButtonTitle: 'Změnit uzel',
              rightButtonTitle: 'Storno',
            }).subscribe(value => {
              if (value) {
                while (this.messageFilesControl.length !== 0) {
                  this.messageFilesControl.removeAt(0);
                }
                this.currentDistributionNodeId = val;
              } else {
                this.distributionNodeIdControl.setValue(this.currentDistributionNodeId, {emitEvent: false});
                selectedNode = locateOptionByValue(this.distributionNodesOptions, this.currentDistributionNodeId);
                if (selectedNode) {
                  this.selectedMaximumEmailSize = (selectedNode.data as any).fileSize;
                }
              }
            });
          }
        }
      }
    });
  }

  submit() {
    this.messageFilesControl.markAsTouched();
    this.messageFilesControl.updateValueAndValidity();
    if (this.messageFilesControl.valid) {
      const createDto = {
        emailUploadNodeId: this.form.getRawValue().distributionNodeId,
        emailMessageIds: this.messageFilesControl.getRawValue().map(fileGroupValue => fileGroupValue.digitalComponentVersionId as number)
      };

      this.loadingService.doLoading(
        this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentCreateEmailConsignment(
          {
            body: createDto as EmailConsignmentCreateDto
          }
        ),
        this
      ).subscribe({
        next: _ => {
          this.documentToastService.dispatchSimpleInfoToast(DocumentToastType.EMAIL_CONSIGNMENT_CREATED);
          this.modalRef.close(true);
        },
        error: _ => {
          this.documentToastService.dispatchSimpleErrorToast(DocumentToastType.EMAIL_CONSIGNMENT_CREATE_ERROR);
        }
      });
    }
  }
  cancel() {
    this.modalRef.close(null);
  }

  isAllUploaded() {
    let isAllUploaded = true;
    if (this.messageFilesControl.controls.length > 0) {
      this.messageFilesControl.controls.forEach(control => {
        const fileGroupValue = (control as IczFormGroup).getRawValue();
        const invalidStates = [
          ComponentUploadStatus.IDLE,
          ComponentUploadStatus.ERROR,
          ComponentUploadStatus.INVALID_EXTENSION,
          ComponentUploadStatus.UPLOADING
        ];
        if (invalidStates.includes(fileGroupValue.status)) {
          isAllUploaded = false;
        }
      });
    }
    return isAllUploaded;
  }

  filesChanged() {
    this.messageFilesControl.controls.forEach(control => {
      const uploadBlob = control.get('file')!.value;
      const statusControl = control.get('status');
      const blockUploadStatus = [
        ComponentUploadStatus.UPLOADING,
        ComponentUploadStatus.ERROR,
        ComponentUploadStatus.INVALID_EXTENSION,
        ComponentUploadStatus.SUCCESS,
      ];
      if (blockUploadStatus.includes(statusControl!.value)) return;

      statusControl!.setValue(ComponentUploadStatus.UPLOADING);
      const uploadExceptionsCtrl = control.get('uploadExceptions')!;
      this.apiDigitalComponentVersionService.digitalComponentVersionUploadNewEmailFile({
        distributionNodeId: this.form.get('distributionNodeId')!.value!,
        body: {
          file: uploadBlob,
        }
      }).subscribe({
        next: (resultWithValidation: DigitalComponentVersionValidatedDto) => {
          control.get('digitalComponentVersionId')!.setValue(resultWithValidation.id);

          if (resultWithValidation.validationFailures && resultWithValidation.validationFailures.length) {
            statusControl!.setValue(ComponentUploadStatus.ERROR);
            const uploadExceptions = (uploadExceptionsCtrl.value as Nullable<EsslErrorCodeExceptionDto[]>) ?? [];
            uploadExceptionsCtrl.setValue([...uploadExceptions, ...resultWithValidation.validationFailures]);
          } else {
            statusControl!.setValue(ComponentUploadStatus.SUCCESS);
          }
        },
        error: err => {
          statusControl!.setValue(ComponentUploadStatus.ERROR);
          uploadExceptionsCtrl.setValue(err.error);
        }
      });
    });
  }
}
