import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {DigitalComponentOrigin, EsslComponentRelation} from '|api/commons';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {IczValidators} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {forkJoin} from 'rxjs';
import {LocalBinaryFileDownloadService} from '../../../../../services/local-binary-file-download.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ApiDigitalComponentTemplateService,
  ApiDigitalComponentVersionService,
  DigitalComponentVersionCompleteDto
} from '|api/component';
import {
  BinaryWithCharset,
  RemoteBinaryFileDownloadService
} from '../../../../../services/remote-binary-file-download.service';
import {injectModalRef} from '@icz/angular-modal';
import {locateOptionByValue, IczOption} from '@icz/angular-form-elements';

export interface DigitalComponentFromTemplateDialogResult {
  templateId: number;
  label: string;
  description: Nullable<string>;
  relationType: EsslComponentRelation;
  originType: DigitalComponentOrigin;
  isFinal: boolean;
  fileName: string;
  fileSize: number;
}

@Component({
  selector: 'icz-digital-component-from-template-dialog',
  templateUrl: './digital-component-from-template-dialog.component.html',
  styleUrls: ['./digital-component-from-template-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class DigitalComponentFromTemplateDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<DigitalComponentFromTemplateDialogResult>>();
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private destroyRef = inject(DestroyRef);
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private apiDigitalComponentTemplateService = inject(ApiDigitalComponentTemplateService);

  form = new IczFormGroup({
    templateId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    label: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    relationType: new IczFormControl<Nullable<string>>(EsslComponentRelation.ENCLOSURE, [IczValidators.required()]),
    originType: new IczFormControl<Nullable<string>>(DigitalComponentOrigin.DIGITAL_BORN, [IczValidators.required()]),
    description: new IczFormControl<Nullable<string>>(null),
    isFinal: new IczFormControl<boolean>(false),
    fileName: new IczFormControl<Nullable<string>>(null),
    fileSize: new IczFormControl<Nullable<number>>(null),
  });

  digitalComponentTemplates: IczOption<number, {digitalVersionId: number, description: string} >[] = [];
  originTypeOptions = enumToOptions('digitalComponentOrigin', DigitalComponentOrigin);
  relationTypeOptions = enumToOptions('esslComponentRelation', EsslComponentRelation);

  formGroupsToCheck!: string[];

  templateVersionCompleteDto: Nullable<DigitalComponentVersionCompleteDto>;
  digitalComponentVersionFileContents: Nullable<BinaryWithCharset>;

  get componentTemplateControl() {
    return this.form.get('templateId')! as IczFormControl;
  }

  get hasFileContent() {
    return !isNil(this.digitalComponentVersionFileContents) && !isNil(this.templateVersionCompleteDto);
  }

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.loadingService.doLoading(
      this.apiDigitalComponentTemplateService.digitalComponentTemplateFindAll(),
      this
    ).subscribe(templates => {
      this.digitalComponentTemplates = templates.map(t => ({value: t.id, label: t.name, data: {digitalVersionId: t.digitalVersionId, description: t.description ?? ''}}));
    });


    this.componentTemplateControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) {
        const selectedTemplate = locateOptionByValue(this.digitalComponentTemplates, value)!;
        this.form.get('label')!.setValue(selectedTemplate.label);
        this.form.get('description')!.setValue(selectedTemplate.data?.description);
        this.loadingService.doLoading(
          forkJoin([
            this.apiDigitalComponentVersionService.digitalComponentVersionGetById({
              digitalComponentVersionId: selectedTemplate.data?.digitalVersionId!,
            }),
            this.remoteBinaryFileDownloadService.fetchDigitalComponentVersion(selectedTemplate.data?.digitalVersionId!)
          ]),
          this
        ).subscribe(([componentMetadata, componentBinary]) => {
          this.templateVersionCompleteDto = componentMetadata;
          this.form.get('fileName')!.setValue(componentMetadata.fileName);
          this.form.get('fileSize')!.setValue(componentMetadata.fileSize);
          this.digitalComponentVersionFileContents = componentBinary;
        });
      }
    });
  }

  submit() {
    this.modalRef.close(this.form.value as DigitalComponentFromTemplateDialogResult);
  }

  cancel() {
    this.modalRef.close(null);
  }

  download() {
    const downloadUrl = this.localBinaryFileDownloadService.constructDownloadUrl(this.digitalComponentVersionFileContents!.buffer, this.templateVersionCompleteDto!.fileName!, 'application/octet-stream');
    this.localBinaryFileDownloadService.downloadBinaryFileFromUrl(
      downloadUrl,
      this.templateVersionCompleteDto!.fileName!
    );
  }

}
