/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AgendaTransferExportDto } from '../models/agenda-transfer-export-dto';
import { AgendaTransferExportErrorDto } from '../models/agenda-transfer-export-error-dto';
import { agendaTransferExportFindAAgendaTransferExportErrorDtoById } from '../fn/agenda-transfer-export/agenda-transfer-export-find-a-agenda-transfer-export-error-dto-by-id';
import { AgendaTransferExportFindAAgendaTransferExportErrorDtoById$Params } from '../fn/agenda-transfer-export/agenda-transfer-export-find-a-agenda-transfer-export-error-dto-by-id';
import { agendaTransferExportFindAgendaTransferExportDtoById } from '../fn/agenda-transfer-export/agenda-transfer-export-find-agenda-transfer-export-dto-by-id';
import { AgendaTransferExportFindAgendaTransferExportDtoById$Params } from '../fn/agenda-transfer-export/agenda-transfer-export-find-agenda-transfer-export-dto-by-id';
import { agendaTransferExportSearchAgendaTransferExportErrorDto } from '../fn/agenda-transfer-export/agenda-transfer-export-search-agenda-transfer-export-error-dto';
import { AgendaTransferExportSearchAgendaTransferExportErrorDto$Params } from '../fn/agenda-transfer-export/agenda-transfer-export-search-agenda-transfer-export-error-dto';
import { agendaTransferExportSearchForAgendaTransferExportDtos } from '../fn/agenda-transfer-export/agenda-transfer-export-search-for-agenda-transfer-export-dtos';
import { AgendaTransferExportSearchForAgendaTransferExportDtos$Params } from '../fn/agenda-transfer-export/agenda-transfer-export-search-for-agenda-transfer-export-dtos';
import { PageAgendaTransferExportDto } from '../models/page-agenda-transfer-export-dto';
import { PageAgendaTransferExportErrorDto } from '../models/page-agenda-transfer-export-error-dto';


/**
 * Controller for managing agenda transfer export.
 */
@Injectable({ providedIn: 'root' })
export class ApiAgendaTransferExportService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `agendaTransferExportFindAgendaTransferExportDtoById()` */
  static readonly AgendaTransferExportFindAgendaTransferExportDtoByIdPath = '/oauth/agenda-transfer-export/{id}';

  /**
   * Find agenda transfer export record by it's id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferExportFindAgendaTransferExportDtoById()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportFindAgendaTransferExportDtoById$Response(params: AgendaTransferExportFindAgendaTransferExportDtoById$Params, context?: HttpContext): Observable<StrictHttpResponse<AgendaTransferExportDto>> {
    return agendaTransferExportFindAgendaTransferExportDtoById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find agenda transfer export record by it's id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferExportFindAgendaTransferExportDtoById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportFindAgendaTransferExportDtoById(params: AgendaTransferExportFindAgendaTransferExportDtoById$Params, context?: HttpContext): Observable<AgendaTransferExportDto> {
    return this.agendaTransferExportFindAgendaTransferExportDtoById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgendaTransferExportDto>): AgendaTransferExportDto => r.body)
    );
  }

  /** Path part for operation `agendaTransferExportSearchForAgendaTransferExportDtos()` */
  static readonly AgendaTransferExportSearchForAgendaTransferExportDtosPath = '/oauth/agenda-transfer-export/search-api';

  /**
   * Find all agenda transfer export records
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferExportSearchForAgendaTransferExportDtos()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportSearchForAgendaTransferExportDtos$Response(params?: AgendaTransferExportSearchForAgendaTransferExportDtos$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageAgendaTransferExportDto>> {
    return agendaTransferExportSearchForAgendaTransferExportDtos(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all agenda transfer export records
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferExportSearchForAgendaTransferExportDtos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportSearchForAgendaTransferExportDtos(params?: AgendaTransferExportSearchForAgendaTransferExportDtos$Params|null|undefined, context?: HttpContext): Observable<PageAgendaTransferExportDto> {
    return this.agendaTransferExportSearchForAgendaTransferExportDtos$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageAgendaTransferExportDto>): PageAgendaTransferExportDto => r.body)
    );
  }

  /** Path part for operation `agendaTransferExportFindAAgendaTransferExportErrorDtoById()` */
  static readonly AgendaTransferExportFindAAgendaTransferExportErrorDtoByIdPath = '/oauth/agenda-transfer-export-error/{id}';

  /**
   * Find agenda transfer export record by it's id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferExportFindAAgendaTransferExportErrorDtoById()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportFindAAgendaTransferExportErrorDtoById$Response(params: AgendaTransferExportFindAAgendaTransferExportErrorDtoById$Params, context?: HttpContext): Observable<StrictHttpResponse<AgendaTransferExportErrorDto>> {
    return agendaTransferExportFindAAgendaTransferExportErrorDtoById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find agenda transfer export record by it's id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferExportFindAAgendaTransferExportErrorDtoById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportFindAAgendaTransferExportErrorDtoById(params: AgendaTransferExportFindAAgendaTransferExportErrorDtoById$Params, context?: HttpContext): Observable<AgendaTransferExportErrorDto> {
    return this.agendaTransferExportFindAAgendaTransferExportErrorDtoById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgendaTransferExportErrorDto>): AgendaTransferExportErrorDto => r.body)
    );
  }

  /** Path part for operation `agendaTransferExportSearchAgendaTransferExportErrorDto()` */
  static readonly AgendaTransferExportSearchAgendaTransferExportErrorDtoPath = '/oauth/agenda-transfer-export-error/search-api';

  /**
   * Find all agenda transfer export records
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferExportSearchAgendaTransferExportErrorDto()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportSearchAgendaTransferExportErrorDto$Response(params?: AgendaTransferExportSearchAgendaTransferExportErrorDto$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageAgendaTransferExportErrorDto>> {
    return agendaTransferExportSearchAgendaTransferExportErrorDto(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all agenda transfer export records
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferExportSearchAgendaTransferExportErrorDto$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportSearchAgendaTransferExportErrorDto(params?: AgendaTransferExportSearchAgendaTransferExportErrorDto$Params|null|undefined, context?: HttpContext): Observable<PageAgendaTransferExportErrorDto> {
    return this.agendaTransferExportSearchAgendaTransferExportErrorDto$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageAgendaTransferExportErrorDto>): PageAgendaTransferExportErrorDto => r.body)
    );
  }

}
