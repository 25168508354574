import {Injectable} from '@angular/core';
import {SearchApiService} from '../../../services/search-api.service';
import {SearchParams} from '@icz/angular-table';
import {Observable} from 'rxjs';
import {CORE_MICROSERVICE_ROOT} from '../../../api';
import {ApiStatisticsService, DimensionsRequestDto, PageOfStatisticDimensionsRowsDto} from '|api/elastic';

@Injectable({
  providedIn: 'root'
})
export class StatisticsSearchService extends SearchApiService {
  findStatisticsData(searchParams: Partial<SearchParams>, reqBody: DimensionsRequestDto): Observable<PageOfStatisticDimensionsRowsDto> {
    return this.searchApiStatistics<PageOfStatisticDimensionsRowsDto>(searchParams, CORE_MICROSERVICE_ROOT + ApiStatisticsService.StatisticsGetDimensionsAggregationPath, reqBody);
  }
}
