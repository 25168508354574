import {inject, Injectable} from '@angular/core';
import {
  MessageType,
  SErrorParameters,
  SImplicitlyStringifiable,
  ToastService
} from '../../../components/notifications/toast.service';
import {z} from 'zod';
import {InternalNotificationKey} from '|api/notification';

export const SRegistryOfficeSimpleErrorTemplateData = z.object({
  [InternalNotificationKey.ERROR_DESCRIPTION]: z.optional(SImplicitlyStringifiable),
  [InternalNotificationKey.ERROR_PARAMETERS]: z.optional(SErrorParameters),
});
export type RegistryOfficeSimpleErrorTemplateData = z.infer<typeof SRegistryOfficeSimpleErrorTemplateData>;

export const SRegistryOfficeTransferProblemTemplateData = z.object({
  [InternalNotificationKey.CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.NAME]: SImplicitlyStringifiable,
});
export type RegistryOfficeTransferProblemTemplateData = z.infer<typeof SRegistryOfficeTransferProblemTemplateData>;

export const SRegistryOfficeTransferIncidentTemplateData = z.object({
  [InternalNotificationKey.NAME]: SImplicitlyStringifiable,
});
export type RegistryOfficeTransferIncidentTemplateData = z.infer<typeof SRegistryOfficeTransferIncidentTemplateData>;

export const SRegistryOfficeTransferIncidentErrorTemplateData = z.object({
  [InternalNotificationKey.NAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type RegistryOfficeTransferIncidentErrorTemplateData = z.infer<typeof SRegistryOfficeTransferIncidentErrorTemplateData>;

export const SRegistryOfficeTransferTemplateData = z.object({
  [InternalNotificationKey.NAME]: SImplicitlyStringifiable,
});
export type RegistryOfficeTransferTemplateData = z.infer<typeof SRegistryOfficeTransferTemplateData>;

export const SRegistryOfficeTransferErrorTemplateData = z.object({
  [InternalNotificationKey.NAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type RegistryOfficeTransferErrorTemplateData = z.infer<typeof SRegistryOfficeTransferErrorTemplateData>;

export const SRegistryOfficeProblemIncidentsResolutionStartedTemplateData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type RegistryOfficeProblemIncidentsResolutionStartedTemplateData = z.infer<typeof SRegistryOfficeProblemIncidentsResolutionStartedTemplateData>;


export enum RegistryOfficeToastType {
  RO_TRANSFER_CREATE_ERROR = 'RO_TRANSFER_CREATE_ERROR',
  RO_TRANSFER_TAKEOVER_ERROR = 'RO_TRANSFER_TAKEOVER_ERROR',
  RO_TRANSFER_REJECT_ERROR = 'RO_TRANSFER_REJECT_ERROR',
  RO_PROBLEM_UPDATE_SUCCESS = 'RO_PROBLEM_UPDATE_SUCCESS',
  RO_PROBLEM_RESOLVE_ALL_INCIDENTS_SUCCESS = 'RO_PROBLEM_RESOLVE_ALL_INCIDENTS_SUCCESS',
  RO_INCIDENT_UPDATE_SUCCESS = 'RO_INCIDENT_UPDATE_SUCCESS',
  RO_INCIDENT_RESOLVE_SUCCESS = 'RO_INCIDENT_RESOLVE_SUCCESS',
  RO_INCIDENT_RESOLVE_ERROR = 'RO_INCIDENT_RESOLVE_ERROR',
  RO_TRANSFER_CANCEL_SUCCESS = 'RO_TRANSFER_CANCEL_SUCCESS',
  RO_TRANSFER_CANCEL_ERROR = 'RO_TRANSFER_CANCEL_ERROR',
  RO_TRANSFER_RETRANSFER_SUCCESS = 'RO_TRANSFER_RETRANSFER_SUCCESS',
  RO_TRANSFER_RETRANSFER_ERROR = 'RO_TRANSFER_RETRANSFER_ERROR',
  RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS = 'RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS',
  RO_TRANSFER_RETURN_TO_OFFICE_ERROR = 'RO_TRANSFER_RETURN_TO_OFFICE_ERROR',
  RO_TRANSFER_CREATED_SUCCESS = 'RO_TRANSFER_CREATED_SUCCESS',
  RO_TRANSFER_TAKEN_OVER_SUCCESS = 'RO_TRANSFER_TAKEN_OVER_SUCCESS',
  RO_TRANSFER_REJECTED_SUCCESS = 'RO_TRANSFER_REJECTED_SUCCESS',
  RO_TRANSFER_CREATED_ERROR = 'RO_TRANSFER_CREATED_ERROR',
  RO_TRANSFER_TAKEN_OVER_ERROR = 'RO_TRANSFER_TAKEN_OVER_ERROR',
  RO_TRANSFER_REJECTED_ERROR = 'RO_TRANSFER_REJECTED_ERROR',
  RO_INCIDENT_RESOLVE_STARTED = 'RO_INCIDENT_RESOLVE_STARTED',
}

@Injectable({
  providedIn: 'root',
})
export class RegistryOfficeToastService {

  private toastService = inject(ToastService);

  dispatchSimpleInfoToast(storageToastType: RegistryOfficeToastType) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, storageToastType);
  }

  dispatchRegistryOfficeProblemIncidentsResolutionStarted(templateData: RegistryOfficeProblemIncidentsResolutionStartedTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, RegistryOfficeToastType.RO_INCIDENT_RESOLVE_STARTED, templateData, SRegistryOfficeProblemIncidentsResolutionStartedTemplateData);
  }

  dispatchErrorToast(storageToastType: RegistryOfficeToastType, templateData: RegistryOfficeSimpleErrorTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, storageToastType, templateData, SRegistryOfficeSimpleErrorTemplateData);
  }

  dispatchAllProblemIncidentsResolved(templateData: RegistryOfficeTransferProblemTemplateData) {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      RegistryOfficeToastType.RO_PROBLEM_RESOLVE_ALL_INCIDENTS_SUCCESS,
      templateData,
      SRegistryOfficeTransferProblemTemplateData
    );
  }

  dispatchProblemUpdated(templateData: RegistryOfficeTransferProblemTemplateData) {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      RegistryOfficeToastType.RO_PROBLEM_UPDATE_SUCCESS,
      templateData,
      SRegistryOfficeTransferProblemTemplateData
    );
  }

  dispatchIncidentUpdated(templateData: RegistryOfficeTransferIncidentTemplateData) {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      RegistryOfficeToastType.RO_INCIDENT_UPDATE_SUCCESS,
      templateData,
      SRegistryOfficeTransferIncidentTemplateData
    );
  }

}
