import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {AddressFormat, AddressWithTypeDto} from '../../model/addresses.model';
import {
  ConsignmentAddressDialogComponent,
  ConsignmentAddressDialogData,
  ConsignmentAddressDialogResult
} from '../../consignment-dialog/consignment-dialog/paper-consignment-specification/consignment-address-dialog/consignment-address-dialog.component';
import {IczModalService} from '@icz/angular-modal';
import {CzechAddressDto, SubjectRecordClassification} from '|api/commons';
import {getAddressTypeLabelForLegalLike, getAddressTypeLabelForNaturalPerson} from '../address-type-label.pipe';

export interface AddressEditInfo {
  originalAddress: AddressWithTypeDto;
  editedAddress: AddressWithTypeDto;
  valueCorrectionMode: boolean;
}

@Component({
  selector: 'icz-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressDetailsComponent {

  private modalService = inject(IczModalService);

  @Input() addressWithType: Nullable<AddressWithTypeDto>;

  @Input() isAddressLabelBold = false;

  @Input() wrapInCard = false;

  @Input() requireAddressType = false;

  @Input() isAddressValid = true;

  @Input() withTooltip = true;

  @Input() withSingleLineAddress = false;

  @Input() allowAddressEdit = false;

  @Input() nonpersistentSubjectMode = false;

  @Input() showVerificationStatus = true;

  @Input() subjectClassification = SubjectRecordClassification.FO;

  @Output() addressEdited = new EventEmitter<AddressEditInfo>();

  get addressTypeLabel(): Nullable<string> {
    if (this.isAddressValid) {
      if (this.subjectClassification === SubjectRecordClassification.FO) {
        return getAddressTypeLabelForNaturalPerson(this.addressWithType?.type);
      } else {
        return getAddressTypeLabelForLegalLike(this.addressWithType?.type);
      }
    }
    else {
      return 'Neaktuální adresa';
    }
  }

  get knownAddressTypeLabel(): boolean {
    return Boolean(this.addressWithType?.type) && Boolean(this.addressTypeLabel);
  }

  get addressCurrentlyValid() {
    return !this.requireAddressType || this.knownAddressTypeLabel;
  }

  get isAddressVerified() {
    return this.isAddressFromRuian || this.isAddressFromIszr;
  }

  get isAddressFromRuian() {
    return (
      this.addressWithType?.format === AddressFormat.CzechAddressDto &&
      !isNil((this.addressWithType.address as CzechAddressDto).id)
    );
  }

  get isAddressFromIszr() {
    return false; // todo(rb) unmock the check after ISZR connection implemented
  }

  editAddress() {
    this.modalService.openComponentInModal<ConsignmentAddressDialogResult, ConsignmentAddressDialogData>({
      component: ConsignmentAddressDialogComponent,
      modalOptions: {
        width: 500,
        height: 720,
        titleTemplate: 'Úprava adresy',
      },
      data: {
        addressToEdit: this.addressWithType,
        nonpersistentSubjectMode: this.nonpersistentSubjectMode,
      },
    }).subscribe(result => {
      if (result) {
        this.addressEdited.emit({
          originalAddress: this.addressWithType!,
          editedAddress: result.address,
          valueCorrectionMode: result.valueCorrectionMode!,
        });
      }
    });
  }

}
