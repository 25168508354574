import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CirculationTaskState} from '|api/commons';
import {ExtendedCirculationTaskDto} from '../../../model/elastic-extended-entities.interface';


@Component({
  selector: 'icz-issd-task-rejected-notes',
  templateUrl: './issd-task-rejected-notes.component.html',
  styleUrls: ['./issd-task-rejected-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IssdTaskRejectedNotesComponent {

  @Input({required: true})
  task!: ExtendedCirculationTaskDto;

  isNotesPopupOpen = false;

  getNotesFieldText() {
    if (this.task.taskState === CirculationTaskState.REJECTED_BY_ISSD) {
      return 'Důvod odmítnutí';
    } else if (this.task.taskState === CirculationTaskState.RETURNED_BY_ISSD)  {
      return 'Důvod vrácení';
    } else {
      return 'Poznámky';
    }
  }
}
