@if (subject) {
  <div class="row items-center p-4">
    <icz-subject-initials [subject]="subject" class="mr-4"></icz-subject-initials>
    @if (withSplitBorder) {
      <hr class="mx-8">
    }
    <icz-subject-identification-status-icon [subject]="subject" class="mr-4"></icz-subject-identification-status-icon>
    @if (subject | subjectName; as subjectName) {
      <div class="col">
        <div class="row">
          @if (isIszrVerified && showIdentifiedAsTag) {
            <icz-tag svgIcon="verified_subject" color="green" label="Ztotožněný">
            </icz-tag>
          }
          @if (isSubjectRepresenting) {
            <icz-tag color="blue" label="Reprezentující"></icz-tag>
          }
        </div>
        <span class="subject-name" [iczTooltip]="subjectName">{{ subjectName }}</span>
      </div>
    }
    <ng-content select="[afterSubjectName]"></ng-content>
  </div>
}
