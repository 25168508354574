import {ChangeDetectorRef, Directive, ElementRef, HostListener, inject, Input, OnInit} from '@angular/core';
import {ControlContainer} from '@angular/forms';
import {findIndex, findLastIndex, includes} from 'lodash';
import {maskDigitValidators, neverValidator} from './digit_validators';
import {
  BACK_SPACE,
  DELETE,
  isCharacterSurrogatePair,
  LEFT_ARROW,
  overWriteCharAtPosition,
  RIGHT_ARROW,
  SPECIAL_CHARACTERS,
  TAB
} from './utils';

/**
 * @internal
 */
export function validateMask(mask: string, isIntegerMaskMode = false): boolean {
  let decimalCount = 0;

  // eslint-disable-next-line @typescript-eslint/prefer-for-of -- I need that here
  for (let i = 0; i < mask.length; i++) {
    const char = mask[i];

    if (char >= '0' && char <= '9') {
      continue;
    }
    else if (char === '.') {
      ++decimalCount;

      if (isIntegerMaskMode) {
        return false;
      }
      if (!isIntegerMaskMode && decimalCount > 1) {
        return false;
      }
    }
    else {
      return false;
    }
  }

  return true;
}

/**
 * Formats the number by supplied numeric mask (i.e. subset of iczInputMask syntax related to numbers).
 * @internal
 */
export function formatNumberByNumericMask(mask: string, num: number, isIntegerMaskMode = false): string {
  if (!validateMask(mask)) {
    throw new Error(`Supplied mask "${mask} is not a valid numeric mask".`);
  }

  const numStr = num.toString();

  const maskParts = mask.split('.');
  const integerMask = maskParts[0];
  const fractionalMask = maskParts[1] ?? '';

  const numParts = numStr.split('.');
  let integerPart = numParts[0];
  let fractionalPart = numParts[1] ?? '';

  if (integerPart.length < integerMask.length) {
    integerPart = integerPart.padStart(integerMask.length, '0');
  }
  else if (integerPart.length > integerMask.length) {
    throw new Error(`The integer part of number ${num} is too large for mask ${mask}.`);
  }

  if (fractionalPart.length < fractionalMask.length) {
    fractionalPart = fractionalPart.padEnd(fractionalMask.length, '0');
  }
  else if (fractionalPart.length > fractionalMask.length) {
    throw new Error('The fractional part of number ${num} is too large for mask ${mask}.');
  }

  let formattedNumber = integerPart;

  if (fractionalMask.length > 0) {
    formattedNumber += '.' + fractionalPart;
  }

  for (let i = 0; i < integerMask.length; i++) {
    const maskDigit = integerMask[i];
    if (maskDigit !== '0' && parseInt(integerPart[i]) > parseInt(maskDigit)) {
      throw new Error(`Integer part of number ${num} exceeds the mask limit of mask ${mask} at position ${i}`);
    }
  }

  for (let i = 0; i < fractionalMask.length; i++) {
    const maskDigit = fractionalMask[i];
    if (maskDigit !== '0' && parseInt(fractionalPart[i]) > parseInt(maskDigit)) {
      throw new Error(`Fractional part of number ${num} exceeds the mask limit of mask ${mask} at position ${i}`);
    }
  }

  return formattedNumber;
}

/**
 * A directive which performs simple character-level validations on form fields at keypress time:
 * - the validation is defined by a simple mask, each character in the mask defined a character slot,
 * - each character slot corresponds to a single character validation at runtime,
 * - if the validation does not pass at runtime, the directive prevents keydown events from happening thus blocking writing to a given character slot.
 *
 * Known issues:
 * - We could not prevent the user inputting characters to input field with this directive
 * using Win keycode shortcuts (Alt+92, ...) or MacOS character map (Ctrl+Cmd+Space) because it
 * generates an input event which coincidentally collides with inner workings of .setValue()
 * method of reactive forms setting the value of the form field.
 *
 * => it is strongly recommended to create a companion reactive validator for the field
 * when using this directive to assure that the inputs are 100% correct.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[iczInputMask]',
  standalone: true,
})
export class InputMaskDirective implements OnInit {

  private cd = inject(ChangeDetectorRef);
  private el = inject(ElementRef);
  private controlContainer = inject(ControlContainer, {optional: true});

  /**
   * to be used in conjunction with reactive forms
   * @internal
   */
  @Input()
  iczInputMaskControlName = '';
  /**
   * Character-level validation mask. See InputMaskDirective docs for more comprehensive info.
   * @see InputMaskDirective
   */
  @Input({required: true})
  iczInputMask = '';

  private input!: HTMLInputElement;
  private fullFieldSelected = false;
  private isClickDisabled = false;
  private previousValue = '';

  /**
   * @internal
   */
  ngOnInit() {
    this.input = this.el.nativeElement;

    if (this.input.value === '') {
      this.buildPlaceHolder();
      this.previousValue = this.input.value;
    }
  }

  @HostListener('focus')
  protected setUpCursorPosition() {
    this.isClickDisabled = true;

    // Chrome fires click event AFTER getting focus (as opposed to Firefox which does it before)
    // which will move the cursor back to its original position. This code will prevent
    // catching the click event while programmatically moving the cursor.
    setTimeout(() => {
      const firstEmptyCharacterIndex = this.input.value.indexOf('_');

      if (firstEmptyCharacterIndex !== -1) {
        this.moveCursorToPositionIndex(firstEmptyCharacterIndex);
      }

      this.isClickDisabled = false;
    }, 0);
  }

  @HostListener('blur')
  protected handleControlBlur() {
    if (this.controlContainer && this.iczInputMaskControlName) {
      const reactiveControl = this.controlContainer.control!.get(this.iczInputMaskControlName)!;
      reactiveControl.setValue(reactiveControl.value);
      reactiveControl.markAsDirty();
      this.cd.markForCheck();
    }
  }

  @HostListener('paste', ['$event'])
  protected blockPaste($event: KeyboardEvent) {
    $event.preventDefault();
  }

  @HostListener('select', ['$event'])
  protected onSelect() {
    this.fullFieldSelected = this.input.selectionStart === 0 && this.input.selectionEnd === this.input.value.length;
  }

  @HostListener('click', ['$event'])
  protected onClick($event: Event) {
    if (this.isClickDisabled) {
      $event.preventDefault();
    }
  }

  @HostListener('input', ['$event'])
  protected onInput($event: InputEvent) {
    const insertedChars = $event.data as string;
    const newCursorPos = this.input.selectionStart as number;
    this.input.value = this.previousValue;

    if (!isCharacterSurrogatePair(insertedChars)) {
      let cursorPos = newCursorPos - insertedChars.length;
      this.moveCursorToPositionIndex(cursorPos);

      for (const char of insertedChars) {
        this.validateAndWriteCharacter(cursorPos, char);
        ++cursorPos;
      }
    }
  }

  @HostListener('keydown', ['$event', '$event.keyCode'])
  protected onKeyDown($event: KeyboardEvent, keyCode: number) {
    if (!this.iczInputMask) return;
    if ($event.metaKey || $event.ctrlKey) return;

    if (keyCode !== TAB) $event.preventDefault();

    const key = $event.key;
    const cursorPos = this.input.selectionStart as number;

    if (this.fullFieldSelected) {
      this.buildPlaceHolder();

      const firstPlaceholderPos = findIndex(this.input.value, char => char === '_');
      this.moveCursorToPositionIndex(firstPlaceholderPos);
    }

    switch (keyCode) {
      case LEFT_ARROW:
        this.handleLeftArrow(cursorPos);
        return;

      case RIGHT_ARROW:
        this.handleRightArrow(cursorPos);
        return;

      case BACK_SPACE:
        this.handleBackspace(cursorPos);
        return;

      case DELETE:
        this.handleDelete(cursorPos);
        return;
    }

    this.validateAndWriteCharacter(cursorPos, key);
  }

  private handleDelete(cursorPos: number) {
    this.overWriteCharAtPosition(cursorPos, '_');
    this.input.setSelectionRange(cursorPos, cursorPos);
  }

  private handleBackspace(cursorPos: number) {
    const previousPos = this.calculatePreviousCursorPos(cursorPos);

    if (previousPos >= 0) {
      this.overWriteCharAtPosition(previousPos, '_');
      this.input.setSelectionRange(previousPos, previousPos);
    }
  }

  private calculatePreviousCursorPos(cursorPos: number) {
    const valueBeforeCursor = this.input.value.slice(0, cursorPos);

    return findLastIndex(valueBeforeCursor, char => !includes(SPECIAL_CHARACTERS, char));
  }

  private handleLeftArrow(cursorPos: number) {
    const previousPos = this.calculatePreviousCursorPos(cursorPos);

    // TODO shoud it be called twice?
    this.input.setSelectionRange(previousPos, previousPos);

    if (previousPos >= 0) {
      this.input.setSelectionRange(previousPos, previousPos);
    }
  }

  private handleRightArrow(cursorPos: number) {
    const valueAfterCursor = this.input.value.slice(cursorPos + 1);
    const nextPos = findIndex(valueAfterCursor,
      char => !includes(SPECIAL_CHARACTERS, char));

    if (nextPos >= 0) {
      const newCursorPos = cursorPos + nextPos + 1;
      this.input.setSelectionRange(newCursorPos, newCursorPos);
    }
  }

  private buildPlaceHolder() {
    const chars = this.iczInputMask.split('');
    const placeholder = chars.reduce((result, char) => {
      return `${result}${includes(SPECIAL_CHARACTERS, char) ? char : '_'}`;
    }, '');

    this.input.value = placeholder;
    this.previousValue = placeholder;
  }

  private validateAndWriteCharacter(cursorPos: number, char: string) {
    const maskDigit = this.iczInputMask.charAt(cursorPos);
    const digitValidator = maskDigitValidators[maskDigit] || neverValidator;

    if (digitValidator(char)) {
      this.overWriteCharAtPosition(cursorPos, char);
      this.writeInputValueToReactiveControl();
      this.previousValue = this.input.value;
      this.handleRightArrow(cursorPos);
    }
  }

  private overWriteCharAtPosition(cursorPos: number, key: string) {
    overWriteCharAtPosition(this.input, cursorPos, key);
    this.writeInputValueToReactiveControl();
  }

  private writeInputValueToReactiveControl() {
    if (this.controlContainer && this.iczInputMaskControlName) {
      this.controlContainer.control!
        .get(this.iczInputMaskControlName)!
        .setValue(this.input.value);
      this.cd.markForCheck();
    }
  }

  private moveCursorToPositionIndex(positionIndex: number) {
    const elem = this.input;

    // IE fix - cast to any is needed because createTextRange not in DOM standard
    if ((elem as any).createTextRange) {
      const range = (elem as any).createTextRange();
      range.move('character', positionIndex);
      range.select();
    }
    else {
      if (elem.selectionStart !== undefined) {
        elem.focus();
        elem.setSelectionRange(positionIndex, positionIndex);
      }
      else {
        elem.focus();
      }
    }
  }

}
