/* eslint-disable @typescript-eslint/quotes */

/**
 * Icons aggregated from apps/espis/src/assets/icons/.
 *
 * Used as a method of resource inlining to decrease HTTP GET overhead
 * for fetching lots of small icons at application startup.
 */
export const ICONS = {
  "activate": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"18\" fill=\"none\" viewBox=\"0 0 16 18\">\r\n    <path fill=\"#6C6F75\" d=\"M8.69 17.95c3.612-.405 6.505-3.291 6.917-6.902.54-4.721-3.129-8.74-7.729-8.792V.138c0-.117-.147-.18-.246-.106L3.27 3.234c-.074.055-.074.162 0 .217l4.36 3.203c.1.073.247.007.247-.107V4.433c3.232.051 5.82 2.802 5.6 6.089-.187 2.828-2.493 5.122-5.32 5.302-2.997.191-5.545-1.949-6.001-4.78-.085-.526-.544-.908-1.074-.908-.658 0-1.173.585-1.07 1.235.64 4.034 4.365 7.06 8.678 6.579z\"/>\r\n</svg>\r\n",
  "add_key": null,
  "add_new": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18zm0-2A7 7 0 1 0 9 2a7 7 0 0 0 0 14zM8 6v2H6a1 1 0 0 0 0 2h2v2a1 1 0 1 0 2 0v-2h2a1 1 0 1 0 0-2h-2V6a1 1 0 0 0-2 0z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "add_new_white": null,
  "add_user": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"15\" fill=\"none\" viewBox=\"0 0 20 15\">\r\n    <path fill=\"#fff\" d=\"M12.727 7.273c2.01 0 3.637-1.628 3.637-3.637C16.364 1.627 14.736 0 12.727 0c-2.009 0-3.636 1.627-3.636 3.636 0 2.01 1.627 3.637 3.636 3.637zM4.545 5.455V2.727H2.727v2.728H0v1.818h2.727V10h1.818V7.273h2.728V5.455H4.545zm8.182 3.636c-2.427 0-7.272 1.218-7.272 3.636v1.819H20v-1.819c0-2.418-4.845-3.636-7.273-3.636z\"/>\r\n</svg>\r\n",
  "advanced_filter": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"12\" fill=\"none\" viewBox=\"0 0 18 12\">\r\n    <path fill=\"#6C6F75\" d=\"M8 12h2c.55 0 1-.45 1-1s-.45-1-1-1H8c-.55 0-1 .45-1 1s.45 1 1 1zM0 1c0 .55.45 1 1 1h16c.55 0 1-.45 1-1s-.45-1-1-1H1C.45 0 0 .45 0 1zm4 6h10c.55 0 1-.45 1-1s-.45-1-1-1H4c-.55 0-1 .45-1 1s.45 1 1 1z\"/>\r\n</svg>\r\n",
  "alldone": "<svg width=\"60\" height=\"60\" viewBox=\"0 0 60 60\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M40.9205 20.411L27.2835 34.0481L21.2225 27.9872C20.3857 27.1503 19.0289 27.1503 18.1921 27.9872C17.3552 28.824 17.3552 30.1808 18.1921 31.0176L27.2835 40.109L43.951 23.4415C44.7878 22.6046 44.7878 21.2479 43.951 20.411C43.1141 19.5742 41.7574 19.5742 40.9205 20.411Z\" fill=\"#4CAF50\"/>\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M30 60C13.4315 60 0 46.5685 0 30C0 13.4315 13.4315 0 30 0C46.5685 0 60 13.4315 60 30C60 46.5685 46.5685 60 30 60ZM29.9999 55.7143C44.2015 55.7143 55.7142 44.2016 55.7142 30C55.7142 15.7984 44.2015 4.28571 29.9999 4.28571C15.7983 4.28571 4.28564 15.7984 4.28564 30C4.28564 44.2016 15.7983 55.7143 29.9999 55.7143Z\" fill=\"#4CAF50\"/>\r\n</svg>\r\n",
  "all_documents": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M0 5l9-5 9 5-9 5-9-5zm9 2.712L13.882 5 9 2.288 4.118 5 9 7.712z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#fff\" d=\"M1.8 8L0 9l9 5 9-5-1.8-1L9 12 1.8 8z\"/>\r\n    <path fill=\"#fff\" d=\"M1.8 12L0 13l9 5 9-5-1.8-1L9 16l-7.2-4z\"/>\r\n</svg>\r\n",
  "arrow_big": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M2 10l6-5v4h7V5h2v10h-2v-4H8v4l-6-5z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "arrow_left": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\r\n<svg\r\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\r\n   xmlns:cc=\"http://creativecommons.org/ns#\"\r\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\r\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\r\n   xmlns=\"http://www.w3.org/2000/svg\"\r\n   id=\"svg12\"\r\n   version=\"1.1\"\r\n   viewBox=\"0 0 11 13\"\r\n   fill=\"none\"\r\n   height=\"13\"\r\n   width=\"11\">\r\n  <metadata\r\n     id=\"metadata18\">\r\n    <rdf:RDF>\r\n      <cc:Work\r\n         rdf:about=\"\">\r\n        <dc:format>image/svg+xml</dc:format>\r\n        <dc:type\r\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\r\n        <dc:title></dc:title>\r\n      </cc:Work>\r\n    </rdf:RDF>\r\n  </metadata>\r\n  <defs\r\n     id=\"defs16\" />\r\n  <path\r\n     style=\"fill:#6c6f75;fill-opacity:1\"\r\n     id=\"path10\"\r\n     d=\"M 11,5.75 H 2.873 L 4,4.625 V 2.5 l -4,4 4,4 V 8.38 L 2.873,7.25 H 11 Z\"\r\n     fill=\"#2d3039\" />\r\n</svg>\r\n",
  "arrow_right": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\r\n<svg\r\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\r\n   xmlns:cc=\"http://creativecommons.org/ns#\"\r\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\r\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\r\n   xmlns=\"http://www.w3.org/2000/svg\"\r\n   width=\"11\"\r\n   height=\"13\"\r\n   fill=\"none\"\r\n   viewBox=\"0 0 11 13\"\r\n   version=\"1.1\"\r\n   id=\"svg12\">\r\n  <metadata\r\n     id=\"metadata18\">\r\n    <rdf:RDF>\r\n      <cc:Work\r\n         rdf:about=\"\">\r\n        <dc:format>image/svg+xml</dc:format>\r\n        <dc:type\r\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\r\n        <dc:title></dc:title>\r\n      </cc:Work>\r\n    </rdf:RDF>\r\n  </metadata>\r\n  <defs\r\n     id=\"defs16\" />\r\n  <path\r\n     fill=\"#2d3039\"\r\n     d=\"M 0,5.75 H 8.127 L 7,4.625 V 2.5 l 4,4 -4,4 V 8.38 L 8.127,7.25 H 0 Z\"\r\n     id=\"path10\"\r\n     style=\"fill:#6c6f75;fill-opacity:1\" />\r\n</svg>\r\n",
  "attachment": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"7\" height=\"16\" fill=\"none\" viewBox=\"0 0 7 16\">\r\n    <path fill=\"#6C6F75\" d=\"M3.072 0C2.262 0 1.498.306.93.874L.923.88A3.018 3.018 0 0 0 .05 3.033c0 .328.271.599.599.6a.592.592 0 0 0 .592-.594 1.82 1.82 0 0 1 1.836-1.847c1.013 0 1.837.84 1.838 1.871l.005 9.892c0 1.026-.836 1.86-1.865 1.859a1.867 1.867 0 0 1-1.866-1.86l-.004-6.626c0-.417.47-.697.91-.696h.006c.147 0 .355.031.501.18.118.12.18.299.18.517l.003 4.996a.594.594 0 0 0 1.186 0L3.969 6.33c0-.527-.185-1.006-.52-1.348-.34-.345-.818-.535-1.348-.535h-.006C.939 4.445-.001 5.289 0 6.327l.004 6.626c0 1.68 1.37 3.046 3.053 3.047.842 0 1.606-.341 2.158-.894.551-.551.89-1.313.89-2.152L6.1 3.061C6.098 1.376 4.739.001 3.072 0z\"/>\r\n</svg>\r\n",
  "attachment_audio": null,
  "attachment_doc": null,
  "attachment_docx": null,
  "attachment_fo": null,
  "attachment_img": null,
  "attachment_odp": null,
  "attachment_ods": null,
  "attachment_odt": null,
  "attachment_paper": null,
  "attachment_pdf": null,
  "attachment_pdfa": null,
  "attachment_ppt": null,
  "attachment_pptx": null,
  "attachment_rest": null,
  "attachment_xls": null,
  "attachment_xlsx": null,
  "attachment_xml": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"21\" height=\"26\" fill=\"none\" viewBox=\"0 0 21 26\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M1.453 0h13.081l5.813 5.814V15h.003v9c0 .035 0 .07-.003.105v.442c0 .802-.65 1.453-1.453 1.453H1.454C.65 26 0 25.35 0 24.547V1.453C0 .65.651 0 1.453 0zm17.441 15H1.454V1.453H13.08v4.36c0 .803.65 1.454 1.453 1.454h4.36V15zm-.601-9.186l-3.759-3.76v3.76h3.759z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#fff\" d=\"M5.913 19.152l.697-1.418h1.175L6.593 19.85 7.817 22H6.631l-.718-1.444L5.195 22H4.012l1.221-2.15-1.189-2.116h1.172l.697 1.418zM9.505 17.734l.952 2.968.95-2.968h1.353V22h-1.031v-.996l.1-2.04L10.796 22h-.68l-1.034-3.038.1 2.042V22H8.154v-4.266h1.35zM14.444 21.209h1.79V22h-2.818v-4.266h1.028v3.475z\"/>\r\n</svg>\r\n",
  "attachment_zfo": null,
  "autofit_all_columns": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"none\" viewBox=\"0 0 16 16\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M7 0H5v16h2V0zM3 5L0 8l3 3V5zm13 3l-3 3V5l3 3zm-5-8H9v16h2V0z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "backtoapp": "<svg width=\"15\" height=\"15\" viewBox=\"0 0 15 15\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M5.90833 10.4917L7.08333 11.6667L11.25 7.5L7.08333 3.33333L5.90833 4.50833L8.05833 6.66667H0V8.33333H8.05833L5.90833 10.4917ZM13.3333 0H1.66667C0.741667 0 0 0.75 0 1.66667V5H1.66667V1.66667H13.3333V13.3333H1.66667V10H0V13.3333C0 14.25 0.741667 15 1.66667 15H13.3333C14.25 15 15 14.25 15 13.3333V1.66667C15 0.75 14.25 0 13.3333 0Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "back_to_step_before": "<svg width=\"20\" height=\"14\" viewBox=\"0 0 20 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M1.54731 4.55289C0.546639 5.55765 -0.00286749 6.89201 4.57764e-05 8.31009L0.000217438 8.39338C0.00313075 9.81149 0.558093 11.1436 1.5629 12.1442C2.56772 13.1449 3.90202 13.6944 5.32011 13.6915L11.7048 13.6784C12.3589 13.677 12.8881 13.1457 12.8867 12.4916C12.8854 11.8375 12.3541 11.3083 11.6999 11.3097L5.31524 11.3228C3.69397 11.3261 2.37225 10.0098 2.36892 8.38854L2.36875 8.30525C2.36542 6.68395 3.68172 5.36226 5.30299 5.35893L15.937 5.33708L14.9985 6.2908C14.5397 6.75703 14.5457 7.50693 15.012 7.96568C15.4782 8.42448 16.228 8.41843 16.6868 7.9522L19.6513 4.93965C19.8789 4.70836 19.9921 4.40732 19.9915 4.10656C19.9908 3.80109 19.8727 3.49595 19.6378 3.26479L16.6657 0.340191C16.1995 -0.11863 15.4496 -0.112554 14.9908 0.353671C14.5321 0.819896 14.5381 1.56975 15.0043 2.02848L15.9594 2.96832L5.29813 2.99022C3.88004 2.99311 2.54798 3.5481 1.54731 4.55289Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "barcode": null,
  "bigger": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"10\" fill=\"none\" viewBox=\"0 0 14 10\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M.21.706A1.06 1.06 0 0 1 1.563.06l10.39 3.685-.004.009 1.445.538c.829.309.8 1.491-.043 1.76l-1.69.538L1.386 9.913a1.06 1.06 0 1 1-.652-2.016l8.673-2.805L.854 2.06A1.06 1.06 0 0 1 .21.706z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "bigger_or_equal": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"12\" fill=\"none\" viewBox=\"0 0 14 12\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M1.51.06a1.024 1.024 0 0 0-.685 1.929l8.261 2.93L.71 7.626a1.024 1.024 0 1 0 .63 1.948l9.923-3.209 1.634-.52c.814-.26.841-1.402.04-1.7l-1.395-.52.003-.008L1.51.059zM.246 10.975C.247 11.542.705 12 1.27 12h11.26a1.024 1.024 0 0 0 0-2.047H1.27c-.565 0-1.023.458-1.023 1.023z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "big_file": "<svg width=\"26\" height=\"26\" viewBox=\"0 0 26 26\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M5.63108 5.47331H6.63108V4.47331V2.22222C6.63108 2.09949 6.73057 2 6.8533 2H17.1457C17.2684 2 17.3679 2.09949 17.3679 2.22222V7.947V8.947H18.3679H22V20.6195C22 20.7422 21.9005 20.8417 21.7778 20.8417H2.22222C2.09949 20.8417 2 20.7422 2 20.6195V5.69553C2 5.5728 2.09949 5.47331 2.22222 5.47331H5.63108ZM17.1762 9.26279H17.1639L17.1517 9.26309L17.1458 9.26316H17.1457L13.1397 9.26283L10.6773 6.281L10.3773 5.91775H9.90619H3.44444H2.44444V6.91775V19.3973V20.3973H3.44444H20.5556H21.5556V19.3973V10.2628V9.26279H20.5556H17.1762ZM16.0521 8.947H17.0521V7.947V3.31582V2.31582H16.0521H7.94682H6.94682V3.31582V4.47331V5.47331H7.94682H9.95667L9.98439 5.51179L10.4175 5.56556C10.473 5.57245 10.5233 5.59865 10.5612 5.64456L12.8809 8.45359C12.8878 8.4619 12.8937 8.47014 12.8988 8.47826L13.1927 8.947H13.746H16.0521Z\" fill=\"#C4C4C4\" stroke=\"#6C6F75\" stroke-width=\"2\"/>\r\n</svg>\r\n",
  "big_file_digital": null,
  "big_file_digital__color": null,
  "big_file__color": "<svg width=\"26\" height=\"26\" viewBox=\"0 0 26 26\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M5.63108 5.47331H6.63108V4.47331V2.22222C6.63108 2.09949 6.73057 2 6.8533 2H17.1457C17.2684 2 17.3679 2.09949 17.3679 2.22222V7.947V8.947H18.3679H22V20.6195C22 20.7422 21.9005 20.8417 21.7778 20.8417H2.22222C2.09949 20.8417 2 20.7422 2 20.6195V5.69553C2 5.5728 2.09949 5.47331 2.22222 5.47331H5.63108ZM17.1762 9.26279H17.164L17.1517 9.26309L17.1458 9.26316H17.1457L13.1397 9.26283L10.6773 6.281L10.3773 5.91775H9.90619H3.44444H2.44444V6.91775V19.3973V20.3973H3.44444H20.5556H21.5556V19.3973V10.2628V9.26279H20.5556H17.1762ZM16.0521 8.947H17.0521V7.947V3.31582V2.31582H16.0521H7.94682H6.94682V3.31582V4.47331V5.47331H7.94682H9.95667L9.98439 5.51179L10.4175 5.56556C10.473 5.57245 10.5233 5.59865 10.5612 5.64456L12.8809 8.45359C12.8878 8.4619 12.8937 8.47014 12.8988 8.47826L13.1927 8.947H13.746H16.0521Z\" fill=\"#C4C4C4\" stroke=\"#FFAC33\" stroke-width=\"2\"/>\r\n</svg>\r\n",
  "big_mail": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"35\" height=\"23\" fill=\"none\" viewBox=\"0 0 35 23\">\r\n    <path fill=\"#D4E0FC\" fill-opacity=\".66\" d=\"M0.761 0.761H34.239000000000004V21.051H0.761z\"/>\r\n    <path fill=\"#fff\" d=\"M20.392 11.381c-1.098.909-2.678.934-3.804.062L2.79.761H33.225L20.39 11.38z\"/>\r\n    <path fill=\"#A4B6CF\" fill-opacity=\".75\" d=\"M.76 20.32h33.48c0 .56-.455 1.014-1.015 1.014H1.775c-.56 0-1.014-.454-1.014-1.014z\"/>\r\n    <path stroke=\"#B6C6DB\" stroke-width=\"2\" d=\"M3.804 1.775l11.824 11.386c1.615 1.556 4.185 1.506 5.74-.11L32.21 1.775\"/>\r\n    <path stroke=\"#AAACAF\" stroke-width=\"1.5\" d=\"M1.775.76l14.236 11.154c1.513 1.186 3.65 1.147 5.12-.093L34.239.761\"/>\r\n    <path stroke=\"#AAACAF\" d=\"M22.424 10.547L33.583 21.706M13.432 10.252L1.117 22.426\"/>\r\n    <path fill-rule=\"evenodd\" stroke=\"#AAACAF\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M4.48.76H.76v21.305H34.24V.761H3.551h.93z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "big_own_document": "<svg width=\"26\" height=\"26\" viewBox=\"0 0 26 26\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M8.5335 16.3667H16.9335V18.4667H8.5335V16.3667ZM8.5335 12.1667H16.9335V14.2667H8.5335V12.1667ZM14.8335 1.66669H6.4335C5.2785 1.66669 4.3335 2.61169 4.3335 3.76669V20.5667C4.3335 21.7217 5.268 22.6667 6.423 22.6667H19.0335C20.1885 22.6667 21.1335 21.7217 21.1335 20.5667V7.96669L14.8335 1.66669ZM19.0335 20.5667H6.4335V3.76669H13.7835V9.01669H19.0335V20.5667Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "big_own_document_digital": "<svg width=\"26\" height=\"26\" viewBox=\"0 0 26 26\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M8.5335 16.3667H16.9335V18.4667H8.5335V16.3667ZM8.5335 12.1667H16.9335V14.2667H8.5335V12.1667ZM14.8335 1.66669H6.4335C5.2785 1.66669 4.3335 2.61169 4.3335 3.76669V20.5667C4.3335 21.7217 5.268 22.6667 6.423 22.6667H19.0335C20.1885 22.6667 21.1335 21.7217 21.1335 20.5667V7.96669L14.8335 1.66669ZM19.0335 20.5667H6.4335V3.76669H13.7835V9.01669H19.0335V20.5667Z\" fill=\"#6C6F75\"/>\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.5 26C23.9853 26 26 23.9853 26 21.5C26 19.0147 23.9853 17 21.5 17C19.0147 17 17 19.0147 17 21.5C17 23.9853 19.0147 26 21.5 26Z\" fill=\"#004EF5\"/>\r\n</svg>\r\n",
  "big_received_document": null,
  "big_received_document_digital": null,
  "big_received_document_digital__color": null,
  "big_received_document__color": null,
  "change_password": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#6C6F75\" d=\"M18.321 10c0-.464.376-.84.84-.84.463 0 .839.376.839.84 0 2.671-1.04 5.182-2.93 7.071C15.183 18.96 12.67 20 10 20c-2.671 0-5.182-1.04-7.071-2.929C1.04 15.182 0 12.671 0 10c0-2.671 1.04-5.182 2.929-7.071C4.818 1.04 7.329 0 10 0c2.24 0 4.387.748 6.126 2.097V.84c0-.464.375-.84.84-.84.463 0 .839.376.839.84v3.232c0 .464-.376.84-.84.84h-3.232c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84h1.108C13.442 2.231 11.755 1.68 10 1.68 5.412 1.68 1.679 5.412 1.679 10c0 4.588 3.733 8.321 8.321 8.321 4.588 0 8.321-3.733 8.321-8.321z\"/>\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M6.978 7.629c0-1.666 1.355-3.022 3.022-3.022 1.666 0 3.022 1.356 3.022 3.022v1.176h.378c.464 0 .84.375.84.839v4.45c0 .463-.376.839-.84.839H6.6c-.464 0-.84-.376-.84-.84v-4.45c0-.463.376-.839.84-.839h.378V7.63zM10 6.286c-.74 0-1.343.603-1.343 1.343v1.176h2.686V7.629c0-.74-.602-1.343-1.343-1.343zm-2.56 6.968h5.12v-2.77H7.44v2.77z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "closed": "<svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n    <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 18A9 9 0 1 1 9 0a9 9 0 0 1 0 18zm0-2A7 7 0 1 0 9 2a7 7 0 0 0 0 14zM6.172 7.586 7.586 9l-1.414 1.414a1 1 0 1 0 1.414 1.414L9 10.414l1.414 1.414a1 1 0 1 0 1.415-1.414L10.413 9l1.415-1.414a1 1 0 0 0-1.415-1.414L9 7.586 7.586 6.172a1 1 0 1 0-1.414 1.414z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "code_selection": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"20\" fill=\"none\" viewBox=\"0 0 18 20\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M8 20h2V0H8v20zm-4-4h2V4H4v12zm-2-4H0V8h2v4zm10 4h2V4h-2v12zm4-4V8h2v4h-2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "comments": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17\" height=\"17\" fill=\"none\" viewBox=\"0 0 17 17\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M4 17l6-4h6a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h3v4zm11-6H9.394L6 13.263V11H2V2h13v9z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "complete_subject": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"none\" viewBox=\"0 0 16 16\">\r\n    <path fill=\"#757575\" d=\"M8 8c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM16 14c0-2.66-5.33-4-8-4s-8 1.34-8 4v2h16v-2z\"/>\r\n</svg>\r\n",
  "component": null,
  "confidential": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"18\" fill=\"none\" viewBox=\"0 0 16 18\">\r\n    <path fill=\"#E82C17\" d=\"M4.667 7.833L7 10.167 11.667 5.5l1.166 1.167L7 12.5 3.5 9l1.167-1.167z\"/>\r\n    <path fill=\"#E82C17\" fill-rule=\"evenodd\" d=\"M7.949 18c-.064 0-.127-.012-.188-.035-1.904-.724-3.499-1.907-4.74-3.514-.984-1.276-1.746-2.818-2.264-4.583C-.12 6.885.003 4.315.009 4.207c.01-.19.12-.36.29-.444L7.714.056c.149-.075.323-.075.472 0l7.413 3.706c.17.085.281.255.291.445.006.108.128 2.677-.748 5.661-.518 1.765-1.28 3.307-2.264 4.583-1.241 1.607-2.836 2.79-4.74 3.514-.06.023-.124.035-.188.035zm0-2.298c-.08 0-.16-.018-.233-.055-2.348-1.16-3.997-3.267-4.901-6.26-.452-1.497-.616-2.908-.673-3.828-.013-.211.101-.41.29-.504l5.282-2.64c.148-.075.323-.075.471 0l5.281 2.64c.19.094.304.293.291.504-.057.92-.22 2.33-.673 3.828-.904 2.993-2.553 5.1-4.9 6.26-.074.037-.155.055-.235.055z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "config_error": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"26\" height=\"24\" fill=\"none\" viewBox=\"0 0 26 24\">\r\n    <path fill=\"#E82C17\" fill-rule=\"evenodd\" d=\"M25.743 20.165L14.663.961c-.168-.292-.411-.535-.703-.704-.92-.53-2.094-.215-2.624.704L.257 20.165c-.168.292-.257.623-.257.96 0 1.06.86 1.92 1.92 1.92h22.16c.336 0 .667-.088.959-.257.919-.53 1.234-1.704.704-2.623zm-14.184-2.881c0 .795.645 1.44 1.44 1.44.796 0 1.441-.645 1.441-1.44 0-.796-.645-1.44-1.44-1.44-.796 0-1.44.644-1.44 1.44zm2.69-8.354c0-.69-.56-1.25-1.25-1.25-.058 0-.117.005-.176.013-.683.098-1.158.73-1.06 1.414l.689 4.82c.039.273.272.476.548.476.275 0 .509-.203.548-.476l.689-4.82c.008-.059.012-.118.012-.177z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "config_warning": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"26\" height=\"24\" fill=\"none\" viewBox=\"0 0 26 24\">\r\n    <path fill=\"#FFAC33\" fill-rule=\"evenodd\" d=\"M25.743 20.165L14.663.961c-.168-.292-.411-.535-.703-.704-.92-.53-2.094-.215-2.624.704L.257 20.165c-.168.292-.257.623-.257.96 0 1.06.86 1.92 1.92 1.92h22.16c.336 0 .667-.088.959-.257.919-.53 1.234-1.704.704-2.623zm-14.184-2.881c0 .795.645 1.44 1.44 1.44.796 0 1.441-.645 1.441-1.44 0-.796-.645-1.44-1.44-1.44-.796 0-1.44.644-1.44 1.44zm2.69-8.354c0-.69-.56-1.25-1.25-1.25-.058 0-.117.005-.176.013-.683.098-1.158.73-1.06 1.414l.689 4.82c.039.273.272.476.548.476.275 0 .509-.203.548-.476l.689-4.82c.008-.059.012-.118.012-.177z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "contains": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12\" height=\"14\" fill=\"none\" viewBox=\"0 0 12 14\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M0 0h4a1 1 0 0 1 0 2H2v10h2a1 1 0 1 1 0 2H0V0zm12 2v12H8a1 1 0 1 1 0-2h2V2H8a1 1 0 0 1 0-2h4v2zm-6 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "conversion": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#6C6F75\" d=\"M14 13.62c.619 0 1.12-.501 1.12-1.12 0-.619-.501-1.12-1.12-1.12-.619 0-1.12.501-1.12 1.12 0 .619.501 1.12 1.12 1.12zM14 14.5c-.73 0-2.19.36-2.24 1.08.5.71 1.32 1.17 2.24 1.17.92 0 1.74-.46 2.24-1.17-.05-.72-1.51-1.08-2.24-1.08z\"/>\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M15 3.27v4.82c2.84.48 5 2.94 5 5.91 0 3.31-2.69 6-6 6-2.03 0-3.82-1.01-4.9-2.55-.52.23-1.05.42-1.6.55C3.2 16.97 0 12.72 0 8.18V3.27L7.5 0 15 3.27zM8.23 15.62C8.08 15.11 8 14.56 8 14c0-2.97 2.16-5.43 5-5.91V4.58l-5.5-2.4L2 4.58v3.6c0 3.5 2.33 6.74 5.5 7.74.25-.08.49-.19.73-.3zM10 14c0 2.21 1.79 4 4 4s4-1.79 4-4-1.79-4-4-4-4 1.79-4 4z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "conversion_menu": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"none\" viewBox=\"0 0 16 16\">\r\n    <path fill=\"#6C6F75\" d=\"M0 5.648c0-.29.115-.567.32-.772.204-.205.482-.32.771-.32h10.975L9.36 1.85c-.199-.206-.309-.482-.306-.768.002-.286.117-.56.32-.762.202-.203.476-.317.762-.32.286-.002.562.108.767.306l4.57 4.57c.152.153.256.347.298.559.042.211.02.43-.062.63-.083.2-.223.37-.402.49-.18.12-.39.184-.606.184H1.09c-.29 0-.567-.115-.771-.32C.115 6.215 0 5.937 0 5.648zM14.7 9H1.092c-.216 0-.427.064-.606.184-.18.12-.32.29-.402.49-.083.2-.104.419-.062.63.042.212.146.406.299.559l4.569 4.57c.205.198.481.308.767.306.286-.003.56-.117.763-.32.202-.202.317-.476.32-.762.002-.286-.108-.562-.307-.768l-2.706-2.706H14.7c.29 0 .567-.115.771-.32.205-.205.32-.482.32-.772 0-.29-.115-.567-.32-.771-.204-.205-.482-.32-.771-.32z\"/>\r\n</svg>\r\n",
  "copy_white": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\r\n<svg\r\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\r\n   xmlns:cc=\"http://creativecommons.org/ns#\"\r\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\r\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\r\n   xmlns=\"http://www.w3.org/2000/svg\"\r\n   width=\"18\"\r\n   height=\"18\"\r\n   fill=\"none\"\r\n   viewBox=\"0 0 18 18\"\r\n   version=\"1.1\"\r\n   id=\"svg12\">\r\n  <metadata\r\n     id=\"metadata18\">\r\n    <rdf:RDF>\r\n      <cc:Work\r\n         rdf:about=\"\">\r\n        <dc:format>image/svg+xml</dc:format>\r\n        <dc:type\r\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\r\n        <dc:title></dc:title>\r\n      </cc:Work>\r\n    </rdf:RDF>\r\n  </metadata>\r\n  <defs\r\n     id=\"defs16\" />\r\n  <path\r\n     fill=\"#6C6F75\"\r\n     fill-rule=\"evenodd\"\r\n     d=\"M1 0h12c.552 0 1 .448 1 1v3h3c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1v-3H1c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1zm3 12V5c0-.552.448-1 1-1h7V2H2v10h2zm2-6v10h10V6H6z\"\r\n     clip-rule=\"evenodd\"\r\n     id=\"path10\"\r\n     style=\"fill:#ffffff\" />\r\n</svg>\r\n",
  "corner_right_up": "<?xml version=\"1.0\" encoding=\"UTF-8\"?>\r\n<svg xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" width=\"18pt\" height=\"18pt\" viewBox=\"0 0 18 18\" version=\"1.1\">\r\n\r\n<path style=\" stroke:none;fill-rule:nonzero;\" fill=\"#6C6F75\" d=\"M 3 15.75 L 8.25 15.75 C 9.285156 15.75 10.222656 15.328125 10.902344 14.652344 C 11.578125 13.976562 12 13.035156 12 12 L 12 4.808594 L 14.46875 7.28125 C 14.761719 7.574219 15.238281 7.574219 15.53125 7.28125 C 15.824219 6.988281 15.824219 6.511719 15.53125 6.21875 L 11.78125 2.46875 C 11.710938 2.402344 11.628906 2.34375 11.539062 2.308594 C 11.265625 2.195312 10.941406 2.25 10.71875 2.46875 L 6.96875 6.21875 C 6.675781 6.511719 6.675781 6.988281 6.96875 7.28125 C 7.261719 7.574219 7.738281 7.574219 8.03125 7.28125 L 10.5 4.8125 L 10.5 12 C 10.5 12.621094 10.25 13.183594 9.839844 13.589844 C 9.433594 14 8.871094 14.25 8.25 14.25 L 3 14.25 C 2.585938 14.25 2.25 14.585938 2.25 15 C 2.25 15.414062 2.585938 15.75 3 15.75 Z M 3 15.75 \"/>\r\n\r\n</svg>\r\n",
  "cross": null,
  "crossed": null,
  "current": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"16\" fill=\"none\" viewBox=\"0 0 20 16\">\r\n    <path fill=\"#6C6F75\" d=\"M18.182.455C17.172.136 16.064 0 15 0c-1.773 0-3.682.364-5 1.364C8.682.364 6.773 0 5 0 3.227 0 1.318.364 0 1.364v13.318c0 .227.227.454.455.454.09 0 .136-.045.227-.045 1.227-.591 3-1 4.318-1 1.773 0 3.682.364 5 1.364 1.227-.773 3.454-1.364 5-1.364 1.5 0 3.046.273 4.318.955.091.045.136.045.227.045.228 0 .455-.227.455-.455V1.364c-.546-.41-1.136-.682-1.818-.91zm0 12.272c-1-.318-2.091-.454-3.182-.454-1.546 0-3.773.59-5 1.363V3.182c1.227-.773 3.454-1.364 5-1.364 1.09 0 2.182.137 3.182.455v10.454z\"/>\r\n    <path fill=\"#6C6F75\" d=\"M15 5.455c.8 0 1.573.081 2.273.236V4.309c-.718-.136-1.491-.218-2.273-.218-1.546 0-2.945.264-4.09.754v1.51c1.026-.582 2.454-.9 4.09-.9zM10.909 7.264v1.509c1.027-.582 2.454-.9 4.09-.9.8 0 1.574.082 2.274.236V6.727c-.718-.136-1.491-.218-2.273-.218-1.546 0-2.945.273-4.091.755zM15 8.937c-1.546 0-2.945.263-4.091.754v1.51c1.027-.583 2.454-.9 4.09-.9.8 0 1.574.081 2.274.236V9.155c-.718-.146-1.491-.218-2.273-.218z\"/>\r\n</svg>\r\n",
  "dashboard": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"none\" viewBox=\"0 0 16 16\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M16 0H9v8h7V0zm-2 6h-3V2h3v4zM7 8H0v8h7V8zm-5 2v4h3v-4H2zM9 10h7v6H9v-6zm5 2h-3v2h3v-2zM0 0h7v6H0V0zm5 2H2v2h3V2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "datova_zprava": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"12\" fill=\"none\" viewBox=\"0 0 20 12\">\r\n    <path fill=\"#FFAC33\" d=\"M19.794 0c.063 0 .119.036.164.105.03.033.042.07.042.107v11.353c0 .113-.114.202-.254.202h-2.148c-.144 0-.26-.09-.26-.202V4.55l-7.157 7.159c-.091.09-.272.09-.36 0L8.3 10.19c-.046-.048-.074-.112-.074-.18 0-.07.028-.134.073-.181l7.17-7.17h-3.325c-.112 0-.202-.115-.202-.254V.26c0-.143.09-.259.202-.259L19.795 0zM.204.001h10.033c.112 0 .204.116.204.256v2.149c0 .14-.092.255-.204.255H4.53l4.257 4.26c.08.079.062.223-.036.321L7.235 8.76c-.101.101-.246.12-.325.04L2.659 4.551v7.016c0 .111-.112.203-.255.203H.255c-.14 0-.255-.092-.255-.203V.214C0 .176.016.14.046.102c.044-.066.101-.1.158-.1z\"/>\r\n</svg>\r\n",
  "datova_zprava_k_rukam": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"14\" fill=\"none\" viewBox=\"0 0 20 14\">\r\n    <path fill=\"#FFAC33\" d=\"M9.489 1.855h-9.3c-.053 0-.106.032-.146.093-.028.035-.043.069-.043.104v10.523c0 .104.107.188.237.188h1.991c.133 0 .237-.085.237-.188V6.072l3.94 3.938c.073.073.208.056.301-.037l1.406-1.406c.092-.091.108-.225.034-.299L4.2 4.32H9.49c.104 0 .189-.107.189-.236V2.092c0-.13-.085-.237-.19-.237z\"/>\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M10.436 3.272c0 1.806 1.465 3.271 3.272 3.271 1.807 0 3.271-1.465 3.271-3.272C16.98 1.466 15.515 0 13.708 0s-3.272 1.465-3.272 3.272zm9.447 8.7c-.592-1.661-2.139-3.794-6.175-3.794-4.037 0-5.583 2.133-6.176 3.795-.367 1.031.513 1.93 1.608 1.93h9.136c1.094 0 1.975-.9 1.607-1.93z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "deactivate": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17\" height=\"17\" fill=\"none\" viewBox=\"0 0 17 17\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M8.1 16.2C3.626 16.2 0 12.573 0 8.1 0 3.626 3.626 0 8.1 0c4.473 0 8.1 3.626 8.1 8.1 0 4.473-3.627 8.1-8.1 8.1zm0-1.8c3.48 0 6.3-2.82 6.3-6.3 0-1.415-.467-2.721-1.254-3.773l-8.819 8.819C5.379 13.933 6.685 14.4 8.1 14.4zm-4.956-2.41C2.302 10.918 1.8 9.567 1.8 8.1c0-3.48 2.82-6.3 6.3-6.3 1.415 0 2.721.467 3.773 1.254l-8.819 8.819.09.117z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "delete_user": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"16\" fill=\"none\" viewBox=\"0 0 20 16\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M11.429 3.81c0-2.105-1.705-3.81-3.81-3.81s-3.81 1.705-3.81 3.81c0 2.104 1.705 3.809 3.81 3.809s3.81-1.705 3.81-3.81zm-1.905 0c0 1.047-.857 1.904-1.905 1.904S5.714 4.857 5.714 3.81c0-1.048.857-1.905 1.905-1.905s1.905.857 1.905 1.905zM0 13.333v1.905h15.238v-1.905c0-2.533-5.076-3.81-7.619-3.81S0 10.8 0 13.334zm1.905 0c.19-.676 3.143-1.904 5.714-1.904 2.562 0 5.495 1.219 5.714 1.904H1.905z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#6C6F75\" d=\"M14.286 5.714H20V7.62h-5.714V5.714z\"/>\r\n</svg>\r\n",
  "destroyed": null,
  "dictionary": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"17\" fill=\"none\" viewBox=\"0 0 19 17\">\r\n    <path fill=\"#EDEAFF\" d=\"M10.09 11.11L7.93 8.976l.026-.026c1.479-1.648 2.533-3.544 3.154-5.55h2.49V1.7H7.65V0h-1.7v1.7H0v1.692h9.495c-.57 1.64-1.471 3.196-2.695 4.555-.79-.875-1.445-1.835-1.963-2.847h-1.7c.62 1.386 1.47 2.695 2.533 3.876l-4.327 4.267L2.55 14.45 6.8 10.2l2.644 2.644.645-1.734zm4.785-4.31h-1.7L9.35 17h1.7l.952-2.55h4.037L17 17h1.7L14.875 6.8zm-2.227 5.95l1.377-3.68 1.377 3.68h-2.754z\"/>\r\n</svg>\r\n",
  "dil_typoveho_spisu": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"14\" fill=\"none\" viewBox=\"0 0 18 14\">\r\n    <path fill=\"#757575\" fill-rule=\"evenodd\" d=\"M7.378 0H1C.448 0 0 .448 0 1v11.534c0 .552.448 1 1 1h15.143c.552 0 1-.448 1-1V2.707H9.924c-.027-.048-.06-.094-.096-.138L8.081.453c-.165-.2-.392-.32-.63-.353L7.377 0zm-.36 2H2v9.534h13.143V4.51h-6.12v-.084L7.019 2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "does_not_contain": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12\" height=\"14\" fill=\"none\" viewBox=\"0 0 12 14\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M2 0H0v14h4a1 1 0 1 0 0-2H2V2h2a1 1 0 0 0 0-2H2zm10 12V0H8a1 1 0 0 0 0 2h2v10H8a1 1 0 1 0 0 2h4v-2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "doruceny_dokument": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17\" height=\"16\" fill=\"none\" viewBox=\"0 0 17 16\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M14 3.91l2.15 1.663c.09.07.162.146.216.227.39.325.634.787.634 1.3v7.12c0 .983-.895 1.78-2 1.78H2c-1.105 0-2-.797-2-1.78V7.1c0-.551.275-1.08.715-1.417.046-.054.1-.105.162-.153L3 3.91V0h11v3.91zm-1.65-2.577h-7.7v6.605l3.782 3.369 3.918-3.43V1.333zM14 6.4l.433-.38-.346-.273L14 5.679v.72zM3 5.67l-.08.06-.388.307.441.393h-.017L3 6.47v-.8zm-1 6.7V7.587l2.685 2.391L2 12.37zm.215 1.85h12.488l-.773-.686h.037l-2.881-2.556-1.96 1.716c-.398.349-1.003.346-1.398-.006l-1.915-1.705-2.838 2.528h.038l-.798.71zM15 7.55v4.895L12.224 9.98 15 7.551zm-.169-.97l.003.004-.001-.001-.002-.003z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "doruceny_dokument_digi": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"19\" fill=\"none\" viewBox=\"0 0 19 19\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M14 3.91l2.15 1.663c.09.07.162.146.216.227.39.325.634.787.634 1.3v7.12c0 .983-.895 1.78-2 1.78H2c-1.105 0-2-.797-2-1.78V7.1c0-.551.275-1.08.715-1.417.046-.054.1-.105.162-.153L3 3.91V0h11v3.91zm-1.65-2.577h-7.7v6.605l3.782 3.369 3.918-3.43V1.333zM14 6.4l.433-.38-.346-.273L14 5.679v.72zM3 5.67l-.08.06-.388.307.441.393h-.017L3 6.47v-.8zm-1 6.7V7.587l2.685 2.391L2 12.37zm.215 1.85h12.488l-.773-.686h.037l-2.881-2.556-1.96 1.716c-.398.349-1.003.346-1.398-.006l-1.915-1.705-2.838 2.528h.038l-.798.71zM15 7.55v4.895L12.224 9.98 15 7.551zm-.169-.97l.003.004-.001-.001-.002-.003z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#004EF5\" fill-rule=\"evenodd\" d=\"M15.5 19c1.933 0 3.5-1.567 3.5-3.5S17.433 12 15.5 12 12 13.567 12 15.5s1.567 3.5 3.5 3.5z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "doruceny_dokument_digi__color": null,
  "doruceny_dokument__color": "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 4.91014L17.1504 6.57279C17.2403 6.64225 17.3116 6.71886 17.3658 6.79973C17.7558 7.12456 18 7.58671 18 8.09951V15.2199C18 16.203 17.1046 17 16 17H3C1.89543 17 1 16.203 1 15.2199V8.09951C1 7.54852 1.2755 7.0209 1.71536 6.6832C1.76068 6.62936 1.81445 6.57804 1.8771 6.53022L4 4.90951V1H15V4.91014ZM13.35 2.33333H5.65V8.93775L9.43242 12.3065L13.35 8.87661V2.33333ZM15 7.39926L15.4333 7.01992L15.087 6.74586L15 6.6786V7.39926ZM4 6.66843L3.92085 6.72886L3.53222 7.03635L3.97342 7.4293L3.95622 7.42922L4 7.46821V6.66843ZM3 13.3697L3 8.58611L5.68506 10.9775L3 13.3697ZM3.21541 15.2199H15.7034L14.9296 14.5332L14.9668 14.5333L12.0859 11.9771L10.1258 13.6932C9.72768 14.0418 9.12269 14.0391 8.72752 13.6872L6.81279 11.9819L3.97547 14.5097L4.01271 14.5096L3.21541 15.2199ZM16 8.55032V13.4446L13.2236 10.9811L16 8.55032ZM15.8313 7.58037L15.8337 7.58353L15.833 7.58251L15.8313 7.58037Z\" fill=\"#2A68DE\"/>\r\n</svg>\r\n",
  "dot": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#6C6F75\" d=\"M11 10.5c0 .828-.672 1.5-1.5 1.5S8 11.328 8 10.5C8 9.67 8.672 9 9.5 9s1.5.671 1.5 1.5z\"/>\r\n</svg>\r\n",
  "download": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"17\" fill=\"none\" viewBox=\"0 0 14 17\">\r\n    <path fill=\"#6C6F75\" d=\"M8 2v6h1.17L7 10.17 4.83 8H6V2h2zm2-2H4v6H0l7 7 7-7h-4V0zm4 15H0v2h14v-2z\"/>\r\n</svg>\r\n",
  "drag_and_drop": "<svg width=\"11\" height=\"16\" viewBox=\"0 0 11 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M0 14C0 15.1 0.9 16 2 16C3.1 16 4 15.1 4 14C4 12.9 3.1 12 2 12C0.9 12 0 12.9 0 14ZM0 2C0 3.1 0.9 4 2 4C3.1 4 4 3.1 4 2C4 0.9 3.1 0 2 0C0.9 0 0 0.9 0 2ZM0 8C0 9.1 0.9 10 2 10C3.1 10 4 9.1 4 8C4 6.9 3.1 6 2 6C0.9 6 0 6.9 0 8Z\" fill=\"#6C6F75\"/>\r\n<path d=\"M7 14C7 15.1 7.9 16 9 16C10.1 16 11 15.1 11 14C11 12.9 10.1 12 9 12C7.9 12 7 12.9 7 14ZM7 2C7 3.1 7.9 4 9 4C10.1 4 11 3.1 11 2C11 0.9 10.1 0 9 0C7.9 0 7 0.9 7 2ZM7 8C7 9.1 7.9 10 9 10C10.1 10 11 9.1 11 8C11 6.9 10.1 6 9 6C7.9 6 7 6.9 7 8Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "dynamicka_slozka": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"17\" fill=\"none\" viewBox=\"0 0 20 17\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M0 0h17v14H0V0zm8 2v2h7V2H8zM6 6v2H2V6h4zm9 2H8V6h7v2zM2 2h4v2H2V2zm6 10h7v-2H8v2zm-2-2v2H2v-2h4zm14-7h-1.889v12H3v2h17V3z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "edit": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15\" height=\"15\" fill=\"none\" viewBox=\"0 0 15 15\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M11.524.596A2.029 2.029 0 0 1 14.4.6c.795.795.796 2.084.005 2.876L4.32 13.56 0 15l1.44-4.32L11.524.596zM3.6 8.52l2.88 2.88-1.44 1.44-2.88-2.88L3.6 8.52zm7.2-7.2l2.88 2.88-1.44 1.44-2.88-2.88 1.44-1.44z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "edit_subject": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M10.773 3.803c0 2.097-1.706 3.803-3.803 3.803S3.167 5.9 3.167 3.803 4.873 0 6.97 0s3.803 1.706 3.803 3.803zm-1.818 0c0-1.094-.89-1.985-1.985-1.985-1.095 0-1.985.89-1.985 1.985 0 1.095.89 1.985 1.985 1.985 1.094 0 1.985-.89 1.985-1.985zM16.986 8.145l2.748 2.748c.355.355.355.93 0 1.285l-7.556 7.556c-.17.17-.402.266-.643.266H8.788c-.502 0-.91-.407-.91-.91v-1.787H.91c-.502 0-.909-.407-.909-.909 0-3.843 3.127-6.97 6.97-6.97 1.99 0 3.853.84 5.164 2.288L15.7 8.145c.17-.17.401-.266.642-.266s.473.096.643.266zM10.846 13c-.973-1.111-2.375-1.758-3.876-1.758-2.53 0-4.64 1.834-5.072 4.243h6.463L10.845 13zm-1.149 5.182h1.462l6.646-6.647-1.462-1.462-3.646 3.647-.017.016-2.983 2.984v1.462z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "email": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"15\" fill=\"none\" viewBox=\"0 0 19 15\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M11.1 11a5.022 5.022 0 0 0 0 2H10v-2h1.1zM18 6h-2v1.1c.739.15 1.419.463 2 .9V6z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M1 0a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h9.5v-2H2V3.435c.377.385.876.891 1.428 1.442 1.19 1.188 2.659 2.615 3.699 3.49.069.058.141.114.217.165.583.407 1.292.505 1.895.456.613-.05 1.256-.26 1.76-.638.037-.028.072-.058.105-.09L16 3.408V6.5h2V1a1 1 0 0 0-1-1H1zm13.58 2H3.393c.377.386.884.9 1.447 1.462 1.194 1.191 2.61 2.564 3.576 3.376l.007.006a.426.426 0 0 0 .063.047c.086.06.286.129.59.104.273-.022.518-.11.678-.214L14.579 2z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#004EF5\" fill-rule=\"evenodd\" d=\"M15.5 15a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "empty": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"none\" viewBox=\"0 0 16 16\">\r\n    <path fill=\"#EBECEE\" fill-opacity=\".01\" stroke=\"#6C6F75\" stroke-width=\"2\" d=\"M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z\"/>\r\n</svg>\r\n",
  "empty_big": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"160\" height=\"160\" fill=\"none\" viewBox=\"0 0 160 160\">\r\n    <path fill=\"#ECF4FF\" fill-rule=\"evenodd\" d=\"M80 160c44.183 0 80-35.817 80-80S124.183 0 80 0 0 35.817 0 80s35.817 80 80 80z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#6C6F75\" d=\"M124.662 89.486L105.232 72H54.768l-19.43 17.486-2.676-2.972L53.232 68h53.536l20.57 18.514-2.676 2.972z\"/>\r\n    <path fill=\"#6C6F75\" d=\"M122 121H38c-3.309 0-6-2.691-6-6V86h32v2c0 4.963 4.037 9 9 9h14c4.963 0 9-4.037 9-9v-2h32v29c0 3.309-2.691 6-6 6zM36 90v25c0 1.103.897 2 2 2h84c1.103 0 2-.897 2-2V90H99.847c-.965 6.223-6.359 11-12.847 11H73c-6.488 0-11.882-4.777-12.847-11H36zM67.687 51.928l-7-11-3.374 2.147 7 11 3.374-2.147zM103.688 43.072l-3.375-2.147-6.999 11 3.375 2.147 6.999-11zM82 39h-4v13h4V39z\"/>\r\n</svg>\r\n",
  "ending": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"9\" height=\"10\" fill=\"none\" viewBox=\"0 0 9 10\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M0 9a1 1 0 1 0 2 0V1a1 1 0 0 0-2 0v8zm6.5 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "environment_edu": null,
  "environment_prod": null,
  "environment_test": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#4CAF50\" d=\"M12.027 0H7.973c0 4.396-3.577 7.973-7.973 7.973v4.054c4.396 0 7.973 3.577 7.973 7.973h4.054c0-4.396 3.577-7.973 7.973-7.973V7.973c-4.396 0-7.973-3.577-7.973-7.973zM10 13.325C9.12 12.01 7.989 10.88 6.675 10 7.99 9.12 9.12 7.989 10 6.675 10.88 7.99 12.011 9.12 13.325 10c-1.314.88-2.445 2.011-3.325 3.325z\"/>\r\n</svg>\r\n",
  "equal": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"6\" fill=\"none\" viewBox=\"0 0 10 6\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M1 0a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H1zm0 4a1 1 0 0 0 0 2h8a1 1 0 1 0 0-2H1z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "equals": "",
  "export": null,
  "extract": null,
  "favorite_off": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#AAACAF\" fill-rule=\"evenodd\" d=\"M15.548 16.622l-1.754-4.996 4.096-2.703a1 1 0 0 0-.551-1.835h-4.92l-1.8-5.404a1 1 0 0 0-1.898 0L6.92 7.088H2a1 1 0 0 0-.55 1.835l4.095 2.703-1.754 4.996a1 1 0 0 0 1.502 1.161L9.67 14.84l4.376 2.943a1 1 0 0 0 1.502-1.16zM10.522 7.72a2 2 0 0 0 1.897 1.368h1.59l-1.316.868a2 2 0 0 0-.786 2.332l.757 2.155-1.878-1.262a2 2 0 0 0-2.232 0l-1.879 1.262.757-2.155a2 2 0 0 0-.786-2.332l-1.315-.868H6.92A2 2 0 0 0 8.817 7.72l.853-2.557.852 2.557z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "favorite_on": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#FFAC33\" d=\"M13.794 11.626l1.754 4.996a1 1 0 0 1-1.502 1.161L9.67 14.84l-4.377 2.943a1 1 0 0 1-1.502-1.16l1.754-4.998-4.096-2.702A1 1 0 0 1 2 7.088h4.92l1.8-5.404a1 1 0 0 1 1.898 0l1.801 5.404h4.92a1 1 0 0 1 .55 1.835l-4.095 2.703z\"/>\r\n</svg>\r\n",
  "flag": "<svg width=\"12\" height=\"16\" viewBox=\"0 0 12 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M7.52 1.77778L7.2 0H0V15.1111H1.6V8.88889H6.08L6.4 10.6667H12V1.77778H7.52Z\" fill=\"#E82C17\"/>\r\n</svg>\r\n",
  "flag_cz": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\r\n    <path fill=\"#F0F0F0\" d=\"M9 18c4.971 0 9-4.03 9-9s-4.029-9-9-9C4.03 0 0 4.03 0 9s4.03 9 9 9z\"/>\r\n    <path fill=\"#D80027\" d=\"M8.217 9s-5.576 6.365-5.581 6.364C4.265 16.993 6.515 18 9 18c4.97 0 9-4.03 9-9H8.217z\"/>\r\n    <path fill=\"#0052B4\" d=\"M2.636 2.636c-3.515 3.515-3.515 9.213 0 12.728L9 9 2.636 2.636z\"/>\r\n</svg>\r\n",
  "flag_en": null,
  "flag_outlined": "<svg width=\"14\" height=\"16\" viewBox=\"0 0 14 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M8.4 1.86667L7.46667 0H0V15.8667H1.86667V9.33333H6.53333L7.46667 11.2H14V1.86667H8.4ZM12.1333 9.33333H8.4L7.46667 7.46667H1.86667V1.86667H6.53333L7.46667 3.73333H12.1333V9.33333Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "flag_sk": null,
  "funkcni_misto": null,
  "future": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"20\" fill=\"none\" viewBox=\"0 0 19 20\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M8.45 0c-.57 0-1.033.463-1.033 1.034 0 .57.463 1.034 1.034 1.034h5.169c.57 0 1.034-.463 1.034-1.034S14.19 0 13.62 0H8.45zM2.654 14.068L0 15.166l5.157 2.139 2.139-5.157-2.916 1.206c-.412-1.384-.369-2.912.227-4.35 1.379-3.323 5.19-4.899 8.512-3.52 3.323 1.378 4.9 5.189 3.52 8.511-1.256 3.031-4.538 4.609-7.626 3.816l-.719 1.733c4.045 1.177 8.413-.854 10.065-4.836 1.772-4.272-.255-9.171-4.527-10.943C9.56 1.992 4.66 4.019 2.888 8.29c-.793 1.912-.825 3.95-.235 5.777zm8.946-2.974l3.017 3.017c.363.364.363.953 0 1.316-.363.364-.952.364-1.316 0l-3.562-3.562V7.758c0-.514.416-.93.93-.93s.93.416.93.93v3.336z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "hamburger": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17\" height=\"14\" fill=\"none\" viewBox=\"0 0 17 14\">\r\n    <path fill=\"#fff\" d=\"M.944 14h15.112c.519 0 .944-.525.944-1.167 0-.641-.425-1.166-.944-1.166H.944c-.519 0-.944.525-.944 1.166C0 13.475.425 14 .944 14zm0-5.833h15.112c.519 0 .944-.525.944-1.167s-.425-1.167-.944-1.167H.944C.425 5.833 0 6.358 0 7s.425 1.167.944 1.167zm-.944-7c0 .641.425 1.166.944 1.166h15.112c.519 0 .944-.525.944-1.166C17 .525 16.575 0 16.056 0H.944C.425 0 0 .525 0 1.167z\"/>\r\n</svg>\r\n",
  "hamburger_menu_dark": null,
  "handover_to_distribution": null,
  "help": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10zm0-2c.37 0 .733-.025 1.089-.073l-.787-2.936a5.043 5.043 0 0 1-.604 0l-.787 2.936c.356.048.72.073 1.089.073zm-3.02-.59l.786-2.936a5.021 5.021 0 0 1-2.24-2.24l-2.936.787a8.027 8.027 0 0 0 4.39 4.389zm-4.907-6.321l2.936-.787a5.085 5.085 0 0 1 0-.604L2.073 8.91a8.072 8.072 0 0 0 0 2.178zm.517-4.11l2.936.787a5.021 5.021 0 0 1 2.24-2.24L6.979 2.59A8.027 8.027 0 0 0 2.59 6.98zm6.321-4.906l.787 2.936a5.082 5.082 0 0 1 .604 0l.787-2.936a8.07 8.07 0 0 0-2.178 0zm3.323 12.401l.787 2.936a8.027 8.027 0 0 0 4.389-4.39l-2.936-.786a5.02 5.02 0 0 1-2.24 2.24zm5.693-3.385l-2.936-.787a5.043 5.043 0 0 0 0-.604l2.936-.787a8.06 8.06 0 0 1 0 2.178zM13.02 2.59a8.027 8.027 0 0 1 4.389 4.39l-2.936.786a5.021 5.021 0 0 0-2.24-2.24l.787-2.936zM13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "hierarchy": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"18\" fill=\"none\" viewBox=\"0 0 20 18\">\r\n    <path fill=\"#757575\" fill-rule=\"evenodd\" d=\"M12 0H8c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h1v2H2v4H1c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1H4v-2h5v2H8c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1h-1v-2h5v2h-1c-.552 0-1 .448-1 1v4c0 .552.448 1 1 1h4c.552 0 1-.448 1-1v-4c0-.552-.448-1-1-1h-1V8h-7V6h1c.552 0 1-.448 1-1V1c0-.552-.448-1-1-1zm-1 4H9V2h2v2zM2 16v-2h2v2H2zm7-2v2h2v-2H9zm7 0v2h2v-2h-2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "history": null,
  "icon_lock": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"18\" fill=\"none\" viewBox=\"0 0 14 18\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M2 6V5C1.953 2.234 4.148.049 7 0c2.66-.045 4.934 2.065 5 5v1c1.104 0 2 .896 2 2v8c0 1.105-.896 2-2 2H2c-1.105 0-2-.895-2-2V8c0-1.104.895-2 2-2zm8 0V5C9.957 3.16 8.593 1.894 7 2c-1.715-.052-3.032 1.26-3 3v1h6zM2 16V8h10v8H2zm5-6.5c.552 0 1 .448 1 1v3c0 .552-.448 1-1 1-.553 0-1-.448-1-1v-3c0-.552.447-1 1-1z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "icon_unlocked": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"20\" fill=\"none\" viewBox=\"0 0 14 20\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M3.754 8H12c1.105 0 2 .895 2 2v8c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2v-8c0-.996.728-1.822 1.68-1.975l-.468-1.633C.45 3.737 1.986.969 4.64.207c2.654-.76 5.423.774 6.184 3.428l.276.962c.152.53-.155 1.084-.685 1.237-.531.152-1.085-.155-1.237-.686l-.276-.961c-.457-1.593-2.118-2.514-3.71-2.057-1.593.457-2.514 2.118-2.058 3.71L3.754 8zM2 10v8h10v-8H2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "import": null,
  "incomplete_subject": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15\" height=\"17\" fill=\"none\" viewBox=\"0 0 15 17\">\r\n    <path fill=\"#757575\" d=\"M8 8c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zM7 12.5c0 1.763 1.304 3.222 3 3.464V16H0v-2c0-2.66 5.33-4 8-4h.05C7.402 10.635 7 11.52 7 12.5zM11.84 12.626c-.107.273-.16.648-.16 1.123h1.396c.003-.127.014-.243.032-.347.017-.098.04-.186.07-.263.035-.091.085-.18.15-.268.05-.07.112-.138.183-.206l.561-.522c.238-.232.41-.458.518-.68.107-.22.161-.466.161-.736 0-.619-.2-1.096-.6-1.431-.398-.335-.96-.503-1.685-.503-.73 0-1.296.182-1.7.547-.403.361-.608.867-.615 1.518h1.65c.007-.244.07-.437.191-.58.12-.144.278-.216.474-.216.423 0 .635.246.635.738 0 .202-.064.387-.19.556-.124.167-.307.35-.548.552-.24.202-.415.441-.522.718zM11.738 14.643c-.172.153-.258.35-.258.59 0 .241.086.44.258.596.176.153.394.23.655.23.26 0 .476-.077.649-.23.176-.156.264-.355.264-.596 0-.24-.088-.437-.264-.59-.172-.157-.389-.235-.65-.235-.26 0-.478.078-.654.235z\"/>\r\n</svg>\r\n",
  "info_config": null,
  "info_small": "<svg width=\"26\" height=\"26\" viewBox=\"0 0 26 26\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13ZM13.1428 10.2734C12.3144 10.2734 11.6428 9.57258 11.6428 8.70811C11.6428 7.84364 12.3144 7.14285 13.1428 7.14285C13.9712 7.14285 14.6428 7.84364 14.6428 8.70811C14.6428 9.57258 13.9712 10.2734 13.1428 10.2734ZM13.1428 11.8385C13.9712 11.8385 14.6428 12.5393 14.6428 13.4037V17.5776C14.6428 18.4421 13.9712 19.1429 13.1428 19.1429C12.3144 19.1429 11.6428 18.4421 11.6428 17.5776V13.4037C11.6428 12.5393 12.3144 11.8385 13.1428 11.8385Z\" fill=\"#2A68DE\"/>\r\n</svg>\r\n",
  "inline_error": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"3\" height=\"12\" fill=\"none\" viewBox=\"0 0 3 12\">\r\n    <path fill=\"#E82C17\" fill-rule=\"evenodd\" d=\"M3 1.5C3 .672 2.328 0 1.5 0S0 .672 0 1.5v4C0 6.328.672 7 1.5 7S3 6.328 3 5.5v-4zM0 10c0 .828.672 1.5 1.5 1.5S3 10.828 3 10s-.672-1.5-1.5-1.5S0 9.172 0 10z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "inline_error_grey": "<svg width=\"4\" height=\"14\" viewBox=\"0 0 4 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1.82609 14C0.817567 14 0 13.1824 0 12.1739C0 11.1654 0.817567 10.3478 1.82609 10.3478C2.83461 10.3478 3.65217 11.1654 3.65217 12.1739C3.65217 13.1824 2.83461 14 1.82609 14ZM1.82599 0C2.70067 0 3.40974 0.709072 3.40974 1.58376C3.40974 1.6587 3.40443 1.73355 3.39383 1.80773L2.52077 7.91916C2.47137 8.26492 2.17525 8.52174 1.82599 8.52174C1.47672 8.52174 1.1806 8.26492 1.13121 7.91916L0.258148 1.80773C0.134449 0.94184 0.736117 0.139617 1.60201 0.0159176C1.6762 0.00531922 1.75105 0 1.82599 0Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "internal": null,
  "jednani": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#fff\" d=\"M19.99 2c0-1.1-.89-2-1.99-2H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 2v13.17L16.83 14H2V2h16zM4 10h12v2H4v-2zm0-3h12v2H4V7zm0-3h12v2H4V4z\"/>\r\n</svg>\r\n",
  "kontejner": null,
  "last_page": null,
  "logo_big_bgdark": null,
  "logo_big_bglight": null,
  "logo_big_bgpurple": null,
  "logo_small_bgdark": null,
  "logo_small_bglight": null,
  "logo_small_bgpurple": null,
  "log_out": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"18\" fill=\"none\" viewBox=\"0 0 19 18\">\r\n    <path fill=\"#6C6F75\" d=\"M12.897.9v3.12h-1.8V2.25c0-.255-.195-.45-.45-.45H3.48l4.034 2.07c.285.15.48.464.48.794v7.633h2.655c.254 0 .45-.195.45-.45V8.278h1.799v4.919c0 .495-.405.9-.9.9H7.978v3.449c0 .345-.36.555-.66.405L.48 14.41c-.285-.15-.48-.464-.48-.794V.9C0 .405.405 0 .9 0h11.097c.495 0 .9.405.9.9z\"/>\r\n    <path fill=\"#6C6F75\" d=\"M18.13 5.459l-2.924-2.925c-.374-.375-.96-.39-1.32-.03-.36.345-.314.96.03 1.305.016 0 1.32 1.305 1.32 1.305h-5.113c-.15 0-.315.045-.45.12-.54.3-.645 1.095-.225 1.544.165.195.42.3.675.3h5.113l-1.32 1.305c-.344.36-.39.96-.03 1.32.375.375.946.36 1.32-.015l2.925-2.925c.375-.375.375-.93 0-1.304z\"/>\r\n</svg>\r\n",
  "maxim": null,
  "minim": null,
  "more": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"4\" fill=\"none\" viewBox=\"0 0 16 4\">\r\n    <path fill=\"#6C6F75\" d=\"M2 0C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zM8 0C6.9 0 6 .9 6 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z\"/>\r\n</svg>\r\n",
  "move_document": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#6C6F75\" d=\"M16 11.001h-.68l-2 2h1.91l1.77 2H3l1.78-2h2.05l-2-2H4l-3 3v4c0 1.1.89 2 1.99 2H17a2 2 0 0 0 2-2v-4l-3-3zm1 7H3v-1h14v1zm-7.66-4.98c.39.39 1.02.39 1.41 0l6.36-6.36a.996.996 0 0 0 0-1.41L12.16.301a.975.975 0 0 0-1.4-.01l-6.37 6.37a.996.996 0 0 0 0 1.41l4.95 4.95zm2.12-10.61L15 5.951l-4.95 4.95-3.54-3.54 4.95-4.95z\"/>\r\n</svg>\r\n",
  "multi_consig": null,
  "my_documents": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"16\" fill=\"none\" viewBox=\"0 0 18 16\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M9 0C7.136 0 5.57 1.275 5.126 3H1c-.552 0-1 .448-1 1v11c0 .552.448 1 1 1h16c.552 0 1-.448 1-1V4c0-.552-.448-1-1-1h-4.126C12.43 1.275 10.864 0 9 0zm1.732 3C10.387 2.402 9.74 2 9 2s-1.387.402-1.732 1h3.464zM7 8c0-.552.448-1 1-1h2c.552 0 1 .448 1 1h5V5H2v3h5zm0 2H2v4h14v-4h-5c0 .552-.448 1-1 1H8c-.552 0-1-.448-1-1z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "new_tab": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M0 17V5c0-.552.448-1 1-1h3V1c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1h-3v3c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1zm12-3H5c-.552 0-1-.448-1-1V6H2v10h10v-2zm-6-2h10V2H6v10z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#6C6F75\" d=\"M12.59 4.492l.918.918L8.918 10 8 9.082l4.59-4.59z\"/>\r\n    <path fill=\"#6C6F75\" d=\"M10.105 4H14v3.895L10.105 4z\"/>\r\n</svg>\r\n",
  "nosic": null,
  "not_empty": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"none\" viewBox=\"0 0 16 16\">\r\n    <path fill=\"#EBECEE\" fill-opacity=\".01\" stroke=\"#6C6F75\" stroke-width=\"2\" d=\"M15 8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z\"/>\r\n    <circle cx=\"8\" cy=\"8\" r=\"4\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "not_opened_package": null,
  "opened_mail": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17\" height=\"17\" fill=\"none\" viewBox=\"0 0 17 17\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M15.125 5.47l1.025.794c.09.07.162.146.216.227.39.325.634.787.634 1.3v7.12c0 .983-.895 1.78-2 1.78H2c-1.105 0-2-.797-2-1.78v-7.12c0-.551.275-1.079.715-1.417.046-.053.1-.105.162-.153l1.186-.905 2.422-2.24L7.66.16c.382-.24.89-.213 1.245.086l1.96 1.652.015-.014 1.153.975-.013.012 2.75 2.316v-.017l.355.3zM2.66 6.842l.023.02v.002l.285.257h-.012L3 7.16v-.011l2.242 2.018.005-.006 3.185 2.837 3.275-2.867.008.008 2.407-2.156.024-.02.135-.21-.155-.131.001-.001-.293-.25h.012l-.045-.038v.01l-2.296-1.955-.005.006-3.262-2.748-3.195 2.956-.008-.009-2.354 2.229-.021.02zM2 8.277v4.784l2.685-2.392L2 8.277zm12.703 6.634H2.215l.798-.71h-.038l2.838-2.528 1.915 1.705c.395.352 1 .355 1.398.006l1.96-1.716 2.88 2.557h-.036l.773.686zM15 13.136V8.24l-2.776 2.431L15 13.136zm-.166-5.861l-.003-.003.002.002z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "outdated": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"16\" fill=\"none\" viewBox=\"0 0 20 16\">\r\n    <path fill=\"#6C6F75\" d=\"M13 12h4v2h-4v-2zm0-8h7v2h-7V4zm0 4h6v2h-6V8zM1 14c0 1.1.9 2 2 2h6c1.1 0 2-.9 2-2V4H1v10zm2-8h6v8H3V6zm5-6H4L3 1H0v2h12V1H9L8 0z\"/>\r\n</svg>\r\n",
  "overeny_subjekt": "<svg width=\"18\" height=\"20\" viewBox=\"0 0 18 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M4 4.93555C4 7.14554 5.78998 8.93555 8 8.93555C10.21 8.93555 12 7.14554 12 4.93555C12 2.72556 10.21 0.935547 8 0.935547C5.78998 0.935547 4 2.72556 4 4.93555Z\" fill=\"#757575\"/>\r\n<path d=\"M8 10.9355C5.33002 10.9355 0 12.2755 0 14.9355V16.9355H10.126C10.0438 16.6159 10 16.2809 10 15.9355C10 14.0028 11.3707 12.3903 13.193 12.017C11.4907 11.297 9.3866 10.9355 8 10.9355Z\" fill=\"#757575\"/>\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.5 19.9355C16.433 19.9355 18 18.3685 18 16.4355C18 14.5026 16.433 12.9355 14.5 12.9355C12.567 12.9355 11 14.5026 11 16.4355C11 18.3685 12.567 19.9355 14.5 19.9355Z\" fill=\"#004EF5\"/>\r\n</svg>\r\n",
  "package": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"19\" fill=\"none\" viewBox=\"0 0 20 19\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M15.82 4H18c1.11 0 2 .89 2 2v11c0 1.11-.89 2-2 2H2c-1.11 0-2-.89-2-2L.01 6C.01 4.89.89 4 2 4h2.18C4.07 3.69 4 3.35 4 3c0-1.66 1.34-3 3-3 1.05 0 1.96.54 2.5 1.34l.5.68.5-.67A2.996 2.996 0 0 1 16 3c0 .35-.07.69-.18 1zM14 3c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM8 3c0-.55-.45-1-1-1s-1 .45-1 1 .45 1 1 1 1-.45 1-1zM2 17h16V6h-5.08L15 8.83 13.38 10 10 5.4 6.62 10 5 8.83 7.08 6H2v11z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "page_arrow": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"6\" height=\"10\" fill=\"none\" viewBox=\"0 0 6 10\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M1.2 9.6L6 4.8 1.2 0 0 1.2l3.6 3.6L0 8.4l1.2 1.2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "pdfa_kontejner": null,
  "podatelna": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"17\" fill=\"none\" viewBox=\"0 0 20 17\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M9 0v5H7l3 4 3-4h-2V0H9zM4.488 1H7v2H4.488l-1.8 6H6.25L7 11h6l.75-2h3.562l-1.8-6H13V1h2.512a2 2 0 0 1 1.916 1.425L19.4 9h.6v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9h.6l1.972-6.575A2 2 0 0 1 4.488 1zM2 15v-4h2.864l.75 2h8.772l.75-2H18v4H2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "portal": "<svg width=\"13\" height=\"13\" viewBox=\"0 0 13 13\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 0C0.895431 0 0 0.89543 0 2V8C0 9.10457 0.89543 10 2 10H5.79999V11.5H2.5C2.08579 11.5 1.75 11.8358 1.75 12.25C1.75 12.6642 2.08579 13 2.5 13H10.5C10.9142 13 11.25 12.6642 11.25 12.25C11.25 11.8358 10.9142 11.5 10.5 11.5H7.29999V10H11C12.1046 10 13 9.10457 13 8V2C13 0.895431 12.1046 0 11 0H2ZM11 2H2L2 8H11V2Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "postal_filing_sheet": null,
  "prehled": "<svg width=\"18\" height=\"14\" viewBox=\"0 0 18 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M2 4C3.10457 4 4 3.10457 4 2C4 0.89543 3.10457 0 2 0C0.89543 0 0 0.89543 0 2C0 3.10457 0.89543 4 2 4Z\" fill=\"#757575\"/>\r\n<path d=\"M2 9C3.10457 9 4 8.10457 4 7C4 5.89543 3.10457 5 2 5C0.89543 5 0 5.89543 0 7C0 8.10457 0.89543 9 2 9Z\" fill=\"#757575\"/>\r\n<path d=\"M4 12C4 13.1046 3.10457 14 2 14C0.89543 14 0 13.1046 0 12C0 10.8954 0.89543 10 2 10C3.10457 10 4 10.8954 4 12Z\" fill=\"#757575\"/>\r\n<path d=\"M7 11C6.44772 11 6 11.4477 6 12C6 12.5523 6.44771 13 7 13H17C17.5523 13 18 12.5523 18 12C18 11.4477 17.5523 11 17 11H7Z\" fill=\"#757575\"/>\r\n<path d=\"M7 6C6.44772 6 6 6.44772 6 7C6 7.55228 6.44771 8 7 8H17C17.5523 8 18 7.55228 18 7C18 6.44772 17.5523 6 17 6H7Z\" fill=\"#757575\"/>\r\n<path d=\"M7 1C6.44772 1 6 1.44772 6 2C6 2.55228 6.44771 3 7 3H17C17.5523 3 18 2.55228 18 2C18 1.44772 17.5523 1 17 1H7Z\" fill=\"#757575\"/>\r\n</svg>\r\n",
  "printer": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"18\" fill=\"none\" viewBox=\"0 0 20 18\">\r\n    <path fill=\"#6C6F75\" d=\"M17 5h-1V0H4v5H3C1.34 5 0 6.34 0 8v6h4v4h12v-4h4V8c0-1.66-1.34-3-3-3zM6 2h8v3H6V2zm8 12v2H6v-4h8v2zm2-2v-2H4v2H2V8c0-.55.45-1 1-1h14c.55 0 1 .45 1 1v4h-2z\"/>\r\n    <path fill=\"#6C6F75\" d=\"M16 9.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2z\"/>\r\n</svg>\r\n",
  "progress": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"20\" fill=\"none\" viewBox=\"0 0 24 20\">\r\n    <path fill=\"#fff\" d=\"M2.93 13.593l-1.64.88c.507.868 1.148 1.653 1.905 2.336l1.288-1.312c-.618-.557-1.14-1.197-1.553-1.904zM5.82 16.479l-.984 1.534c.886.522 1.846.909 2.853 1.15l.454-1.748c-.82-.196-1.6-.51-2.322-.936zM11.35 17.57c-.387.052-.783.079-1.178.079h-.024c-.113 0-.226-.002-.338-.007l-.075 1.8c.136.005.275.008.413.008h.023c.483 0 .97-.033 1.444-.098l-.265-1.783zM14.133 16.682c-.369.193-.756.36-1.15.497l.639 1.694c.485-.168.96-.374 1.413-.611l-.902-1.58zM16.42 14.914c-.276.305-.578.592-.899.853l1.217 1.373c.393-.32.764-.672 1.102-1.046l-1.42-1.18zM22.671 8.885l-2.375 2.277V9.725c0-2.598-1.056-5.04-2.973-6.877C15.407 1.012 12.858 0 10.148 0s-5.26 1.012-7.176 2.848C1.056 4.685 0 7.128 0 9.725c0 1 .158 1.985.469 2.93l1.792-.542c-.253-.77-.382-1.573-.382-2.388 0-4.37 3.71-7.924 8.269-7.924s8.268 3.555 8.268 7.924v1.422l-2.39-2.285-1.327 1.275 4.667 4.462L24 10.16l-1.329-1.274z\"/>\r\n</svg>\r\n",
  "question_mark": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"15\" fill=\"none\" viewBox=\"0 0 10 15\">\r\n    <path fill=\"#6C6F75\" d=\"M3.044 10.373c0-.94.115-1.69.343-2.248.229-.558.645-1.106 1.25-1.643.612-.545 1.018-.985 1.22-1.32.201-.343.302-.703.302-1.08 0-1.135-.524-1.703-1.572-1.703-.498 0-.897.155-1.2.464-.296.302-.45.722-.464 1.26H0c.013-1.284.427-2.288 1.24-3.014C2.06.363 3.175 0 4.587 0c1.424 0 2.53.346 3.316 1.038.787.686 1.18 1.657 1.18 2.914 0 .57-.128 1.112-.383 1.623-.256.504-.703 1.065-1.341 1.683l-.817.776c-.51.49-.803 1.065-.877 1.724l-.04.615h-2.58zm-.292 3.095c0-.45.151-.82.454-1.11.309-.295.702-.443 1.18-.443.476 0 .866.148 1.168.444.31.289.464.659.464 1.109 0 .443-.151.81-.453 1.098-.296.29-.69.434-1.18.434s-.887-.145-1.19-.434c-.295-.288-.443-.655-.443-1.098z\"/>\r\n</svg>\r\n",
  "question_mark_orange": null,
  "refuse": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\r\n    <path fill=\"#6C6F75\" d=\"M9.154 5.012C9.104 5.004 9.052 5 9 5c-.137 0-.268.028-.387.078.176-.032.357-.054.54-.066zM8.613 12.922c.119.05.25.078.387.078.052 0 .103-.004.153-.012-.183-.012-.364-.034-.54-.066z\"/>\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M9 18c-4.97 0-9-4.03-9-9s4.03-9 9-9 9 4.03 9 9-4.03 9-9 9zm5.607-4.808C15.482 12.024 16 10.572 16 9c0-3.866-3.134-7-7-7-1.572 0-3.023.518-4.192 1.393l9.799 9.799zm-1.415 1.414C12.024 15.482 10.572 16 9 16c-3.866 0-7-3.134-7-7 0-1.572.518-3.024 1.394-4.192l9.798 9.798z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "register": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"15\" fill=\"none\" viewBox=\"0 0 18 15\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M17.007 3.704l-2.71-1.566.782-1.355c.432-.749 1.39-1.005 2.138-.573.749.432 1.005 1.39.573 2.138l-.783 1.356zM5.212.135h7.831l-.554.96-.65 1.127H7.303v5.217H2.087v5.218H6.9l-.126 2.086H0V5.36L5.212.135zm7.332 14.608h2.064v-2.71l-1.257 2.177-.807.533zm-9.589-9.39h2.262V3.084L2.955 5.352zm8.835 7.387l4.174-7.23-2.711-1.564-4.174 7.229-.21 3.494 2.921-1.929z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "registratury": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M16.364 15.79h2.727c.502 0 .909-.472.909-1.053V3.157c0-.58-.407-1.052-.91-1.052h-7.25L11.162.573C11.007.221 10.694 0 10.353 0H4.545c-.502 0-.909.471-.909 1.053V4.21H.91C.407 4.21 0 4.68 0 5.263v13.684C0 19.53.407 20 .91 20h14.544c.503 0 .91-.471.91-1.053V15.79zm0-2.106V7.368c0-.58-.407-1.052-.91-1.052h-7.25l-.678-1.532c-.155-.352-.468-.573-.81-.573H5.456V2.105h4.342l.931 2.106h7.454v9.473h-1.818zM1.818 6.316v11.579h12.728V8.42H7.092L6.16 6.316H1.818zm1.818 8.42c0-.58.407-1.052.91-1.052h7.272c.502 0 .91.472.91 1.053s-.408 1.053-.91 1.053H4.545c-.502 0-.909-.472-.909-1.053zm3.637-4.21H4.545c-.502 0-.909.472-.909 1.053s.407 1.053.91 1.053h2.727c.502 0 .909-.472.909-1.053s-.407-1.053-.91-1.053z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "remove_user": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"21\" height=\"20\" fill=\"none\" viewBox=\"0 0 21 20\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M7.066 7.711c2.126 0 3.856-1.73 3.856-3.855C10.922 1.73 9.192 0 7.066 0S3.21 1.73 3.21 3.856 4.94 7.71 7.066 7.71zm0-5.868c1.11 0 2.012.903 2.012 2.013 0 1.11-.902 2.012-2.012 2.012-1.11 0-2.012-.903-2.012-2.012 0-1.11.902-2.013 2.012-2.013z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#6C6F75\" d=\"M12 12.5c-.552 0-1 .448-1 1s.448 1 1 1h3c.552 0 1-.448 1-1s-.448-1-1-1h-3z\"/>\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M13.702 7.097c-2.12 0-4.003 1.027-5.18 2.61-.477-.1-.964-.152-1.456-.152C3.17 9.555 0 12.725 0 16.62c0 .509.413.921.922.921h7.717C9.82 19.038 11.65 20 13.702 20c3.557 0 6.452-2.894 6.452-6.452 0-3.557-2.895-6.451-6.452-6.451zM1.925 15.699c.436-2.442 2.576-4.301 5.141-4.301.182 0 .363.01.543.029-.232.664-.359 1.378-.359 2.121 0 .754.13 1.478.37 2.15H1.924zm11.777 2.458c-2.541 0-4.608-2.068-4.608-4.609 0-2.54 2.067-4.608 4.608-4.608 2.541 0 4.608 2.067 4.608 4.608 0 2.541-2.067 4.609-4.608 4.609z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "ringbell": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M9 0a2.25 2.25 0 0 0-2.25 2.25v.107c-2.622.83-4.5 3.067-4.5 5.697v5.033H1.007a1.007 1.007 0 1 0 0 2.014h15.986a1.007 1.007 0 1 0 0-2.014H15.75V8.054c0-2.63-1.878-4.868-4.5-5.697V2.25A2.25 2.25 0 0 0 9 0zm4.5 13.087V8.054c0-2.224-2.015-4.027-4.5-4.027S4.5 5.83 4.5 8.054v5.033h9zM11.16 16.108H7.3a1.988 1.988 0 0 0 3.86 0z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "rozluka": null,
  "scale_columns": "<svg width=\"18\" height=\"16\" viewBox=\"0 0 18 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3 0H15C16.6569 0 18 1.34315 18 3V13C18 14.6569 16.6569 16 15 16H3C1.34315 16 0 14.6569 0 13V3C0 1.34315 1.34315 0 3 0ZM3 2C2.44772 2 2 2.44772 2 3V13C2 13.5523 2.44772 14 3 14H15C15.5523 14 16 13.5523 16 13V3C16 2.44772 15.5523 2 15 2H3ZM6 4H4V12H6V4ZM8 4H10V12H8V4ZM14 4H12V12H14V4Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "seal": null,
  "sekretariat": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"18\" fill=\"none\" viewBox=\"0 0 20 18\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M12 0H8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1v2H2v4H1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H4v-2h5v2H8a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-1v-2h5v2h-1a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-1V8h-7V6h1a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm-1 4H9V2h2v2zM2 16v-2h2v2H2zm7-2v2h2v-2H9zm7 0v2h2v-2h-2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "send_back": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#6C6F75\" d=\"M18.464 6.561c1 1.005 1.55 2.34 1.547 3.758v.083c-.003 1.418-.558 2.75-1.563 3.75-1.005 1.001-2.339 1.55-3.757 1.548l-6.385-.013c-.654-.002-1.183-.533-1.182-1.187.002-.654.533-1.183 1.187-1.182l6.385.013c1.621.004 2.943-1.313 2.946-2.934v-.083c.004-1.622-1.313-2.943-2.934-2.947L4.074 7.346l.939.953c.458.466.452 1.216-.014 1.675-.466.459-1.216.453-1.675-.013L.36 6.948c-.228-.231-.341-.532-.34-.833 0-.305.118-.61.353-.842L3.345 2.35c.467-.46 1.216-.453 1.675.013.459.466.453 1.216-.013 1.675l-.955.94 10.66.022c1.419.003 2.751.558 3.752 1.562z\"/>\r\n</svg>\r\n",
  "sent": "<svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M5.70208 7.90416L8.10625 10.3083L12.9146 5.5L14.1167 6.70208L8.10625 12.7125L4.5 9.10625L5.70208 7.90416Z\" fill=\"#6C6F75\"/>\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 13.9706 13.9706 18 9 18ZM8.99998 16.0001C12.866 16.0001 16 12.8661 16 9.00006C16 5.13407 12.866 2.00006 8.99998 2.00006C5.13398 2.00006 1.99997 5.13407 1.99997 9.00006C1.99997 12.8661 5.13398 16.0001 8.99998 16.0001Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "settings": null,
  "share": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\r\n<svg\r\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\r\n   xmlns:cc=\"http://creativecommons.org/ns#\"\r\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\r\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\r\n   xmlns=\"http://www.w3.org/2000/svg\"\r\n   id=\"svg835\"\r\n   version=\"1.1\"\r\n   fill=\"none\"\r\n   height=\"14\"\r\n   width=\"13\">\r\n  <metadata\r\n     id=\"metadata841\">\r\n    <rdf:RDF>\r\n      <cc:Work\r\n         rdf:about=\"\">\r\n        <dc:format>image/svg+xml</dc:format>\r\n        <dc:type\r\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\r\n        <dc:title></dc:title>\r\n      </cc:Work>\r\n    </rdf:RDF>\r\n  </metadata>\r\n  <defs\r\n     id=\"defs839\" />\r\n  <path\r\n     style=\"stroke-width:0.860802\"\r\n     id=\"path833\"\r\n     fill=\"#6c6f75\"\r\n     d=\"M 12.968932,6.9961263 7.5906372,1.1004887 V 4.6177285 H 6.4492128 A 6.417284,6.417284 0 0 0 0.03106804,11.035874 v 1.863637 L 0.53894161,12.343433 A 9.1107356,9.1107356 0 0 1 7.268697,9.3736633 h 0.322801 v 3.5180997 l 5.376574,-5.8964975 z\" />\r\n</svg>\r\n",
  "signature": null,
  "skartacni_navrh": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"17\" fill=\"none\" viewBox=\"0 0 20 17\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M18.182 9.09h.909c.241 0 .472.097.643.267.17.17.266.402.266.643s-.096.472-.266.643c-.17.17-.402.266-.643.266H.909c-.241 0-.472-.096-.643-.266C.096 10.473 0 10.24 0 10s.096-.472.266-.643c.17-.17.402-.266.643-.266h.91V1.818c0-.482.19-.944.532-1.285C2.69.192 3.154 0 3.636 0h8.237c.102.017.2.05.29.1h.082c.098.042.187.1.264.173l5.455 5.454c.072.077.13.167.172.264v.082c.026.076.041.156.046.236v2.782zm-3.1-3.635L12.727 3.1v2.355h2.355zM3.636 1.818v7.273h12.728V7.273h-3.637c-.482 0-.944-.192-1.285-.533-.341-.34-.533-.803-.533-1.285V1.818H3.636zm0 10.91H1.818v3.636h1.818v-3.637zm1.819 0h1.818v3.636H5.455v-3.637zm5.454 0H9.091v3.636h1.818v-3.637zm1.818 0h1.818v3.636h-1.818v-3.637zm3.637 0h1.818v3.636h-1.818v-3.637z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "smaller": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"10\" fill=\"none\" viewBox=\"0 0 14 10\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M13.79 9.26a1.06 1.06 0 0 1-1.353.644L2.047 6.219l.003-.009-1.444-.538c-.83-.308-.801-1.49.042-1.76l1.691-.538L12.613.052a1.06 1.06 0 1 1 .652 2.016L4.592 4.873l8.553 3.033c.552.196.84.802.645 1.353z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "smaller_or_equal": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"12\" fill=\"none\" viewBox=\"0 0 14 12\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M13.357 10.973c0-.568-.46-1.028-1.028-1.028H1.027a1.027 1.027 0 1 0 0 2.055H12.33c.568 0 1.028-.46 1.028-1.027zM12.09 9.602a1.027 1.027 0 1 0 .686-1.937l-8.292-2.94 8.409-2.72A1.027 1.027 0 1 0 12.261.05L2.3 3.27l-1.64.523C-.157 4.053-.184 5.2.62 5.5l1.4.522-.003.009L12.09 9.602z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "smlouvy": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#fff\" d=\"M4.059 5H14.94C15.528 5 16 4.554 16 4s-.472-1-1.059-1H4.06C3.472 3 3 3.446 3 4c0 .549.477 1 1.059 1z\"/>\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M19.167 0H.833C.37 0 0 .371 0 .833v15.963c0 .461.371.833.833.833h8.175v1.555c0 .673.776 1.057 1.31.645l1.743-1.34 1.743 1.34c.535.412 1.31.028 1.31-.645v-1.555h4.053c.462 0 .833-.372.833-.833V.833C20 .37 19.629 0 19.167 0zm-.836 15.963h-3.217v-3.147c.617-.698.988-1.604.988-2.596 0-2.187-1.812-3.963-4.04-3.963-.878 0-1.69.276-2.353.743h-5.65C3.472 7 3 7.446 3 8c0 .549.477 1 1.059 1h4.158c-.128.385-.197.795-.197 1.22 0 .992.372 1.898.988 2.596v3.147H1.665V1.665h16.666v14.298zm-6.27.412l-1.375 1.058V13.95c.428.151.894.237 1.375.237.482 0 .947-.082 1.376-.237v3.482l-1.376-1.058zm0-3.853c-1.31 0-2.375-1.032-2.375-2.302 0-1.269 1.065-2.302 2.375-2.302s2.376 1.033 2.376 2.302c0 1.27-1.066 2.302-2.376 2.302z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "sorting": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"8\" height=\"11\" fill=\"none\" viewBox=\"0 0 8 11\">\r\n    <path fill=\"#2D3039\" d=\"M3.25 0v8.127L2.125 7H0l4 4 4-4H5.88L4.75 8.127V0h-1.5z\"/>\r\n</svg>\r\n",
  "spanner": null,
  "spis": null,
  "spisovna": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M16 0H2L1 2h16l-1-2zM1 3h16l1 2H0l1-2zM0 6h18v12H0V6zm2 10h14V8H2v8zm5-5a1 1 0 1 0-2 0v2a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-2a1 1 0 1 0-2 0v1H7v-1z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "spis_digi": null,
  "spis_digi__color": null,
  "spis__color": null,
  "stamp": null,
  "starting_with": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"9\" height=\"10\" fill=\"none\" viewBox=\"0 0 9 10\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M7 9a1 1 0 1 0 2 0V1a1 1 0 1 0-2 0v8zm-4.5 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "storage_unit": "<svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M16 0H2C0.9 0 0 0.9 0 2V9C0 10.1 0.9 11 2 11H16C17.1 11 18 10.1 18 9V2C18 0.9 17.1 0 16 0ZM2 7H5.13C5.34 7.78 5.8 8.47 6.4 9H2V7ZM16 9H11.6C12.2 8.47 12.66 7.78 12.87 7H16V9ZM16 5H11V6C11 7.07 10.07 8 9 8C7.93 8 7 7.07 7 6V5H2V2H16V5ZM11 12V13C11 13.47 10.81 13.9 10.52 14.25C10.15 14.7 9.6 15 9 15C8.4 15 7.85 14.7 7.48 14.25C7.19 13.9 7 13.47 7 13V12H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V12H11ZM2 14H5.13C5.15 14.09 5.19 14.17 5.22 14.25C5.46 14.93 5.87 15.53 6.4 16H2V14ZM16 16H11.6C12.14 15.53 12.55 14.93 12.78 14.25C12.81 14.17 12.85 14.09 12.87 14H16V16Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "storage_unit_add": null,
  "storage_unit_big": null,
  "storage_unit_big__color": null,
  "storage_unit_digital": "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M16 0H2C0.9 0 0 0.9 0 2V9C0 10.1 0.9 11 2 11H16C17.1 11 18 10.1 18 9V2C18 0.9 17.1 0 16 0ZM2 7H5.13C5.34 7.78 5.8 8.47 6.4 9H2V7ZM16 9H11.6C12.2 8.47 12.66 7.78 12.87 7H16V9ZM16 5H11V6C11 7.07 10.07 8 9 8C7.93 8 7 7.07 7 6V5H2V2H16V5ZM11 12V13C11 13.47 10.81 13.9 10.52 14.25C10.15 14.7 9.6 15 9 15C8.4 15 7.85 14.7 7.48 14.25C7.19 13.9 7 13.47 7 13V12H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V12H11ZM2 14H5.13C5.15 14.09 5.19 14.17 5.22 14.25C5.46 14.93 5.87 15.53 6.4 16H2V14ZM16 16H11.6C12.14 15.53 12.55 14.93 12.78 14.25C12.81 14.17 12.85 14.09 12.87 14H16V16Z\" fill=\"#6C6F75\"/>\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.5 20C18.433 20 20 18.433 20 16.5C20 14.567 18.433 13 16.5 13C14.567 13 13 14.567 13 16.5C13 18.433 14.567 20 16.5 20Z\" fill=\"#004EF5\"/>\r\n</svg>\r\n",
  "storage_unit_digital_big": null,
  "storage_unit_digital_big__color": null,
  "storage_unit_digital__color": "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M16 0H2C0.9 0 0 0.9 0 2V9C0 10.1 0.9 11 2 11H16C17.1 11 18 10.1 18 9V2C18 0.9 17.1 0 16 0ZM2 7H5.13C5.34 7.78 5.8 8.47 6.4 9H2V7ZM16 9H11.6C12.2 8.47 12.66 7.78 12.87 7H16V9ZM16 5H11V6C11 7.07 10.07 8 9 8C7.93 8 7 7.07 7 6V5H2V2H16V5ZM11 12V13C11 13.47 10.81 13.9 10.52 14.25C10.15 14.7 9.6 15 9 15C8.4 15 7.85 14.7 7.48 14.25C7.19 13.9 7 13.47 7 13V12H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V12H11ZM2 14H5.13C5.15 14.09 5.19 14.17 5.22 14.25C5.46 14.93 5.87 15.53 6.4 16H2V14ZM16 16H11.6C12.14 15.53 12.55 14.93 12.78 14.25C12.81 14.17 12.85 14.09 12.87 14H16V16Z\" fill=\"#8952A3\"/>\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M16.5 20C18.433 20 20 18.433 20 16.5C20 14.567 18.433 13 16.5 13C14.567 13 13 14.567 13 16.5C13 18.433 14.567 20 16.5 20Z\" fill=\"#004EF5\"/>\r\n</svg>\r\n",
  "storage_unit_remove": "<svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n    <path d=\"M8.215.299 5.295 3.4c-.374.398-.388 1.017-.028 1.398.346.38.96.332 1.305-.034 0-.016 1.303-1.4 1.303-1.4l.007 5.417c0 .159.045.334.12.476.301.572 1.096.682 1.545.236a.965.965 0 0 0 .3-.715l-.008-5.416 1.307 1.396c.36.365.96.411 1.32.03a1.01 1.01 0 0 0-.018-1.398L9.52.297C9.145-.1 8.59-.1 8.215.299z\" fill=\"#6C6F75\"/>\r\n    <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2 6.35h4.5v2.118H2v3.177h5v1.06c0 1.133.93 2.117 2 2.117 1.07 0 2-.984 2-2.118v-1.059h5V8.468h-5V6.349h5c1.1 0 2 .954 2 2.119v7.414C18 17.047 17.1 18 16 18H2c-1.1 0-2-.953-2-2.118V8.468c0-1.165.9-2.119 2-2.119zm0 7.413h3.13c.21.827.67 1.557 1.27 2.119H2v-2.119zm14 2.119h-4.4a4.408 4.408 0 0 0 1.27-2.119H16v2.119z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "storage_unit__color": "<svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M16 0H2C0.9 0 0 0.9 0 2V9C0 10.1 0.9 11 2 11H16C17.1 11 18 10.1 18 9V2C18 0.9 17.1 0 16 0ZM2 7H5.13C5.34 7.78 5.8 8.47 6.4 9H2V7ZM16 9H11.6C12.2 8.47 12.66 7.78 12.87 7H16V9ZM16 5H11V6C11 7.07 10.07 8 9 8C7.93 8 7 7.07 7 6V5H2V2H16V5ZM11 12V13C11 13.47 10.81 13.9 10.52 14.25C10.15 14.7 9.6 15 9 15C8.4 15 7.85 14.7 7.48 14.25C7.19 13.9 7 13.47 7 13V12H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V12H11ZM2 14H5.13C5.15 14.09 5.19 14.17 5.22 14.25C5.46 14.93 5.87 15.53 6.4 16H2V14ZM16 16H11.6C12.14 15.53 12.55 14.93 12.78 14.25C12.81 14.17 12.85 14.09 12.87 14H16V16Z\" fill=\"#8952A3\"/>\r\n</svg>\r\n",
  "substitute": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"14\" fill=\"none\" viewBox=\"0 0 20 14\">\r\n    <path fill=\"#6C6F75\" d=\"M7 8.75c-2.34 0-7 1.17-7 3.5V14h14v-1.75c0-2.33-4.66-3.5-7-3.5zM2.34 12c.84-.58 2.87-1.25 4.66-1.25s3.82.67 4.66 1.25H2.34zM7 7c1.93 0 3.5-1.57 3.5-3.5S8.93 0 7 0 3.5 1.57 3.5 3.5 5.07 7 7 7zm0-5c.83 0 1.5.67 1.5 1.5S7.83 5 7 5s-1.5-.67-1.5-1.5S6.17 2 7 2zm7.04 6.81c1.16.84 1.96 1.96 1.96 3.44V14h4v-1.75c0-2.02-3.5-3.17-5.96-3.44zM13 7c1.93 0 3.5-1.57 3.5-3.5S14.93 0 13 0c-.54 0-1.04.13-1.5.35.63.89 1 1.98 1 3.15s-.37 2.26-1 3.15c.46.22.96.35 1.5.35z\"/>\r\n</svg>",
  "success": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"26\" height=\"26\" fill=\"none\" viewBox=\"0 0 26 26\">\r\n    <path fill=\"#4CAF50\" fill-rule=\"evenodd\" d=\"M13 26C5.82 26 0 20.18 0 13S5.82 0 13 0s13 5.82 13 13-5.82 13-13 13zm0-1.857c6.154 0 11.143-4.989 11.143-11.143 0-6.154-4.989-11.143-11.143-11.143C6.846 1.857 1.857 6.846 1.857 13c0 6.154 4.989 11.143 11.143 11.143zm4.732-15.298l-5.91 5.91-2.626-2.627a.928.928 0 1 0-1.313 1.313l3.94 3.94 7.223-7.223a.929.929 0 0 0-1.314-1.313z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "system_config": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#E2DFF6\" d=\"M18.842 15.825l-7.567-7.567c.775-1.95.375-4.25-1.2-5.833C8.158.508 5.175.333 3.05 1.883l3.2 3.209-1.183 1.175-3.192-3.192C.325 5.192.5 8.183 2.417 10.092c1.55 1.55 3.808 1.958 5.741 1.233l7.592 7.592c.325.325.85.325 1.175 0L18.842 17c.333-.317.333-.842 0-1.175zm-2.5 1.333L8.458 9.275c-.508.375-1.075.6-1.666.683-1.134.167-2.325-.175-3.192-1.041-.792-.784-1.158-1.834-1.1-2.867l2.575 2.575 3.533-3.533-2.575-2.575c1.034-.059 2.075.308 2.867 1.091.9.9 1.242 2.142 1.033 3.3-.1.592-.35 1.142-.733 1.634l7.875 7.875-.733.741z\"/>\r\n</svg>\r\n",
  "take_over": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"20\" fill=\"none\" viewBox=\"0 0 19 20\">\r\n    <path fill=\"#6C6F75\" d=\"M15.94 1.212c-.432 0-.84.101-1.204.28C14.284.609 13.364 0 12.304 0c-.871 0-1.648.41-2.148 1.049-.428-.28-.94-.443-1.488-.443C7.164.606 5.94 1.83 5.94 3.333v8.215l-1.992-1.014c-.376-.188-.798-.288-1.22-.288C1.222 10.246 0 11.47 0 12.974c0 1.04.578 1.974 1.508 2.44l7.788 3.894c.887.453 1.886.692 2.888.692 1.702 0 3.318-.661 4.551-1.862 1.247-1.214 1.933-2.817 1.933-4.515V3.94c0-1.503-1.224-2.727-2.727-2.727zm.91 12.411c0 2.471-2.137 4.559-4.666 4.559-.728 0-1.422-.167-2.065-.495l-.007-.003-7.79-3.896c-.31-.155-.504-.468-.504-.814 0-.502.408-.91.91-.91.142 0 .28.033.4.094l3.31 1.683c.281.143.617.13.887-.036.27-.165.434-.458.434-.775V3.333c0-.5.407-.909.909-.909.501 0 .909.408.909.91v4.848c0 .502.407.909.909.909s.909-.407.909-.91V2.728c0-.501.408-.909.91-.909.5 0 .908.408.908.91V8.181c0 .502.407.909.91.909.501 0 .908-.407.908-.91V3.94c0-.5.408-.909.91-.909.5 0 .909.408.909.91v9.683z\"/>\r\n</svg>\r\n",
  "task": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\r\n<svg\r\n   width=\"14\"\r\n   height=\"14\"\r\n   viewBox=\"0 0 14 14\"\r\n   fill=\"none\"\r\n   version=\"1.1\"\r\n   id=\"svg4\"\r\n   xmlns=\"http://www.w3.org/2000/svg\"\r\n   xmlns:svg=\"http://www.w3.org/2000/svg\">\r\n  <defs\r\n     id=\"defs8\" />\r\n  <path\r\n     fill-rule=\"evenodd\"\r\n     clip-rule=\"evenodd\"\r\n     d=\"M 9.005,14 H 0 V 0 H 14 V 8.993 Z M 12.47769,7.4409449 12.440945,1.5223097 1.503937,1.5774278 1.4671916,12.459318 7.3490814,12.440945 7.4409449,7.4776903 Z M 11.866163,8.9632546 9,9 v 2.613945 z\"\r\n     fill=\"#6c6f75\"\r\n     id=\"path2\" />\r\n</svg>\r\n",
  "tasks": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\r\n    <path fill=\"#fff\" d=\"M8 4h6v2H8V4zm0 4h6v2H8V8zm0 4h6v2H8v-2zM4 4h2v2H4V4zm0 4h2v2H4V8zm0 4h2v2H4v-2zM17.1 0H.9C.4 0 0 .4 0 .9v16.2c0 .4.4.9.9.9h16.2c.4 0 .9-.5.9-.9V.9c0-.5-.5-.9-.9-.9zM16 16H2V2h14v14z\"/>\r\n</svg>\r\n",
  "task_faded": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\r\n<svg\r\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\r\n   xmlns:cc=\"http://creativecommons.org/ns#\"\r\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\r\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\r\n   xmlns=\"http://www.w3.org/2000/svg\"\r\n   id=\"svg12\"\r\n   version=\"1.1\"\r\n   viewBox=\"0 0 15 15\"\r\n   fill=\"none\"\r\n   height=\"15\"\r\n   width=\"15\">\r\n  <metadata\r\n     id=\"metadata18\">\r\n    <rdf:RDF>\r\n      <cc:Work\r\n         rdf:about=\"\">\r\n        <dc:format>image/svg+xml</dc:format>\r\n        <dc:type\r\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\r\n        <dc:title></dc:title>\r\n      </cc:Work>\r\n    </rdf:RDF>\r\n  </metadata>\r\n  <defs\r\n     id=\"defs16\" />\r\n  <path\r\n     style=\"fill:#d0d9de;fill-opacity:1\"\r\n     id=\"path10\"\r\n     clip-rule=\"evenodd\"\r\n     d=\"M0 0v15h8.684V8.684H15V0H0zm10.263 15L15 10.263h-4.737V15z\"\r\n     fill-rule=\"evenodd\"\r\n     fill=\"#FDB813\" />\r\n</svg>\r\n",
  "task_green": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\r\n<svg\r\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\r\n   xmlns:cc=\"http://creativecommons.org/ns#\"\r\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\r\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\r\n   xmlns=\"http://www.w3.org/2000/svg\"\r\n   id=\"svg12\"\r\n   version=\"1.1\"\r\n   viewBox=\"0 0 15 15\"\r\n   fill=\"none\"\r\n   height=\"15\"\r\n   width=\"15\">\r\n  <metadata\r\n     id=\"metadata18\">\r\n    <rdf:RDF>\r\n      <cc:Work\r\n         rdf:about=\"\">\r\n        <dc:format>image/svg+xml</dc:format>\r\n        <dc:type\r\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\r\n        <dc:title></dc:title>\r\n      </cc:Work>\r\n    </rdf:RDF>\r\n  </metadata>\r\n  <defs\r\n     id=\"defs16\" />\r\n  <path\r\n     style=\"fill:#40a33f;fill-opacity:1\"\r\n     id=\"path10\"\r\n     clip-rule=\"evenodd\"\r\n     d=\"M0 0v15h8.684V8.684H15V0H0zm10.263 15L15 10.263h-4.737V15z\"\r\n     fill-rule=\"evenodd\"\r\n     fill=\"#FDB813\" />\r\n</svg>\r\n",
  "task_grey": null,
  "task_orange": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15\" height=\"15\" fill=\"none\" viewBox=\"0 0 15 15\">\r\n    <path fill=\"#FDB813\" fill-rule=\"evenodd\" d=\"M0 0v15h8.684V8.684H15V0H0zm10.263 15L15 10.263h-4.737V15z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "task_red": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\r\n<svg\r\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\r\n   xmlns:cc=\"http://creativecommons.org/ns#\"\r\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\r\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\r\n   xmlns=\"http://www.w3.org/2000/svg\"\r\n   id=\"svg12\"\r\n   version=\"1.1\"\r\n   viewBox=\"0 0 15 15\"\r\n   fill=\"none\"\r\n   height=\"15\"\r\n   width=\"15\">\r\n  <metadata\r\n     id=\"metadata18\">\r\n    <rdf:RDF>\r\n      <cc:Work\r\n         rdf:about=\"\">\r\n        <dc:format>image/svg+xml</dc:format>\r\n        <dc:type\r\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\r\n        <dc:title></dc:title>\r\n      </cc:Work>\r\n    </rdf:RDF>\r\n  </metadata>\r\n  <defs\r\n     id=\"defs16\" />\r\n  <path\r\n     style=\"fill:#e01314;fill-opacity:1\"\r\n     id=\"path10\"\r\n     clip-rule=\"evenodd\"\r\n     d=\"M0 0v15h8.684V8.684H15V0H0zm10.263 15L15 10.263h-4.737V15z\"\r\n     fill-rule=\"evenodd\"\r\n     fill=\"#FDB813\" />\r\n</svg>\r\n",
  "trend_down": "<svg width=\"16\" height=\"16\" viewBox=\"0 0 16 16\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M13.8682 0.686646L3.35827 11.0745L3.55443 8.35678L1.17249 5.94685L0.484619 15.5967L10.1418 15.0216L7.76442 12.6162L5.04003 12.7761L15.55 2.38816L13.8682 0.686646Z\" fill=\"#E82C17\"/>\r\n</svg>\r\n",
  "trend_up": "<svg width=\"16\" height=\"15\" viewBox=\"0 0 16 15\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M1.68181 14.91L12.1918 4.52213L11.9956 7.2399L14.3776 9.64983L15.0654 -1.71661e-05L5.40821 0.575079L7.78562 2.98043L10.51 2.82062L5.62668e-05 13.2085L1.68181 14.91Z\" fill=\"#4CAF50\"/>\r\n</svg>\r\n",
  "typovy_spis": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"17\" fill=\"none\" viewBox=\"0 0 20 17\">\r\n    <path fill=\"#757575\" fill-rule=\"evenodd\" d=\"M10.235 0H3.857c-.552 0-1 .448-1 1v1.857H1c-.552 0-1 .448-1 1v11.534c0 .552.448 1 1 1h15.143c.552 0 1-.448 1-1v-1.857H19c.552 0 1-.448 1-1V2.707h-7.219c-.027-.048-.06-.094-.096-.138L10.94.453c-.166-.2-.393-.32-.632-.353l-.072-.1zM5.5 4.857V4.85h-3v.007H2v9.534h13.143v-.315h.005l.027-4h-.032V7.368h-6.12v-.084L7.019 4.857H5.5zm11.643 6.677H18V4.51h-6.12v-.084L9.876 2H4.857v.857h2.521l.072.1c.239.033.466.153.631.353l1.747 2.116c.037.044.069.09.096.138h7.219v5.97z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "typovy_spis_digi": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"18\" fill=\"none\" viewBox=\"0 0 20 18\">\r\n    <path fill=\"#757575\" fill-rule=\"evenodd\" d=\"M10.235 0H3.857c-.552 0-1 .448-1 1v1.857H1c-.552 0-1 .448-1 1v11.534c0 .552.448 1 1 1h15.143c.552 0 1-.448 1-1v-1.857H19c.552 0 1-.448 1-1V2.707h-7.219c-.027-.048-.06-.094-.096-.138L10.94.453c-.166-.2-.393-.32-.632-.353l-.072-.1zM5.5 4.857V4.85h-3v.007H2v9.534h13.143v-.315h.005l.027-4h-.032V7.368h-6.12v-.084L7.019 4.857H5.5zm11.643 6.677H18V4.51h-6.12v-.084L9.876 2H4.857v.857h2.521l.072.1c.239.033.466.153.631.353l1.747 2.116c.037.044.069.09.096.138h7.219v5.97z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#004EF5\" fill-rule=\"evenodd\" d=\"M14.5 18c1.933 0 3.5-1.567 3.5-3.5S16.433 11 14.5 11 11 12.567 11 14.5s1.567 3.5 3.5 3.5z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "unequal": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"13\" fill=\"none\" viewBox=\"0 0 10 13\">\r\n    <path fill=\"#6C6F75\" d=\"M1 3.285a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H1zM1 7.285a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2H1z\"/>\r\n    <path fill=\"#6C6F75\" d=\"M1.861 12.277a1 1 0 0 1-.353-1.37L7.648.493A1 1 0 0 1 9.37 1.508L3.23 11.923a1 1 0 0 1-1.369.354z\"/>\r\n</svg>\r\n",
  "uredni_deska": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\r\n    <path fill=\"#fff\" d=\"M11 1.5c0 .828-.672 1.5-1.5 1.5S8 2.328 8 1.5 8.672 0 9.5 0 11 .672 11 1.5zM5 9c-.552 0-1 .448-1 1s.448 1 1 1h10c.552 0 1-.448 1-1s-.448-1-1-1H5zM5 13c-.552 0-1 .448-1 1s.448 1 1 1h10c.552 0 1-.448 1-1s-.448-1-1-1H5z\"/>\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M0 5c0-.552.448-1 1-1h18c.552 0 1 .448 1 1v14c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1V5zm2 13V6h16v12H2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "user_configuration": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"15\" fill=\"none\" viewBox=\"0 0 18 15\">\r\n    <path fill=\"#EDEAFF\" d=\"M6 7.5c-2.1 0-3.75-1.65-3.75-3.75S3.9 0 6 0s3.75 1.65 3.75 3.75S8.1 7.5 6 7.5zm0-6c-1.275 0-2.25.975-2.25 2.25S4.725 6 6 6c1.274 0 2.25-.975 2.25-2.25S7.273 1.5 6 1.5zm5.324 13.499H.75c-.45 0-.75-.3-.75-.75 0-3.3 2.7-6 6-6h.074c3.3 0 6 2.625 6 5.85v.15c0 .45-.375.75-.75.75zm-9.674-1.5h8.85c-.376-2.175-2.25-3.75-4.426-3.75-2.25 0-4.124 1.575-4.424 3.75zm14.099-9.75c0-2.1-1.65-3.749-3.75-3.749-.6 0-1.275.15-1.8.525-.375.225-.45.675-.3 1.05.225.375.675.45 1.05.3 1.05-.6 2.475-.225 3.075.825.6 1.05.225 2.475-.825 3.074-.675.375-1.5.375-2.25 0-.375-.224-.825-.075-1.05.3-.225.375-.075.825.3 1.05.525.3 1.2.45 1.8.45 2.1-.075 3.75-1.725 3.75-3.824zm2.25 10.5c.074-3.225-2.55-5.925-5.775-6h-.15c-.225 0-.45 0-.675.075-.45.075-.75.45-.675.825.075.45.45.75.825.675h.525c2.175 0 4.125 1.575 4.425 3.675h-3c-.45 0-.75.3-.75.75s.3.75.75.75h3.75c.45 0 .75-.3.75-.75z\"/>\r\n</svg>\r\n",
  "user_settings": null,
  "vedouci": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"20\" fill=\"none\" viewBox=\"0 0 14 20\">\r\n    <path fill=\"#fff\" d=\"M7 6.89c1.077 0 1.95-.873 1.95-1.95S8.077 2.99 7 2.99s-1.95.873-1.95 1.95S5.923 6.89 7 6.89zM10.89 10.35C10.89 9.05 8.3 8.4 7 8.4c-1.3 0-3.89.65-3.89 1.95V11h7.78v-.65z\"/>\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M13 0H1C.45 0 0 .45 0 1v12c0 .55.45 1 1 1h12c.55 0 1-.45 1-1V1c0-.55-.45-1-1-1zm-1 12H2V2h10v10z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#fff\" d=\"M0 16c0-.552.448-1 1-1h12c.552 0 1 .448 1 1s-.448 1-1 1H1c-.552 0-1-.448-1-1zM1 18c-.552 0-1 .448-1 1s.448 1 1 1h12c.552 0 1-.448 1-1s-.448-1-1-1H1z\"/>\r\n</svg>\r\n",
  "verified_subject": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"19\" height=\"17\" fill=\"none\" viewBox=\"0 0 19 17\">\r\n    <path fill=\"#757575\" fill-rule=\"evenodd\" d=\"M6 13l3-2.94c-.39-.04-.68-.06-1-.06-2.67 0-8 1.34-8 4v2h9l-3-3zm2-5c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4z\" clip-rule=\"evenodd\"/>\r\n    <path fill=\"#4CAF50\" fill-rule=\"evenodd\" d=\"M12.47 16.5L9 13l1.4-1.41 2.07 2.08L17.6 8.5 19 9.91l-6.53 6.59z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "vlastni_dokument": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"18\" fill=\"none\" viewBox=\"0 0 14 18\">\r\n    <path fill=\"#6C6F75\" d=\"M3.667 12.333h6.666V14H3.667v-1.667zm0-3.333h6.666v1.667H3.667V9zm5-8.333H2c-.917 0-1.667.75-1.667 1.666v13.334c0 .916.742 1.666 1.659 1.666H12c.917 0 1.667-.75 1.667-1.666v-10l-5-5zm3.333 15H2V2.333h5.833V6.5H12v9.167z\"/>\r\n</svg>\r\n",
  "vlastni_dokument_digi": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"15\" height=\"19\" fill=\"none\" viewBox=\"0 0 15 19\">\r\n    <path fill=\"#6C6F75\" d=\"M3.333 11.667H10v1.666H3.333v-1.666zm0-3.334H10V10H3.333V8.333zm5-8.333H1.667C.75 0 0 .75 0 1.667V15c0 .917.742 1.667 1.658 1.667h10.009c.916 0 1.666-.75 1.666-1.667V5l-5-5zm3.334 15h-10V1.667H7.5v4.166h4.167V15z\"/>\r\n    <path fill=\"#004EF5\" fill-rule=\"evenodd\" d=\"M11.5 19c1.933 0 3.5-1.567 3.5-3.5S13.433 12 11.5 12 8 13.567 8 15.5 9.567 19 11.5 19z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "vypraveni": null,
  "vypravna": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"17\" fill=\"none\" viewBox=\"0 0 20 17\">\r\n    <path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M13 4h-2v5H9V4H7l3-4 3 4zm.75 5h3.562l-1.8-6h-.762l-1.5-2h2.262a2 2 0 0 1 1.916 1.425L19.4 9h.6v6a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9h.6l1.972-6.575A2 2 0 0 1 4.488 1H6.75l-1.5 2h-.762l-1.8 6H6.25L7 11h6l.75-2zM2 11v4h16v-4h-2.864l-.75 2H5.614l-.75-2H2z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "vyradit": "<svg width=\"14\" height=\"14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n    <path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1 0h12a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V1a1 1 0 0 1 1-1zm11 2H2v10h10V2zM4.172 5.586 5.586 7 4.172 8.414a1 1 0 1 0 1.414 1.414L7 8.414l1.414 1.414A1 1 0 1 0 9.83 8.414L8.414 7 9.83 5.586a1 1 0 0 0-1.415-1.414L7 5.586 5.586 4.172a1 1 0 1 0-1.414 1.414z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "vyradit_vse": "<svg width=\"18\" height=\"18\" viewBox=\"0 0 18 18\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 13V1C18 0.447754 17.5523 0 17 0H5C4.44772 0 4 0.447754 4 1V4H1C0.447716 4 0 4.44775 0 5V17C0 17.5522 0.447716 18 1 18H13C13.5523 18 14 17.5522 14 17V14H17C17.5523 14 18 13.5522 18 13ZM13 4H6V2H16V12H14V5C14 4.44775 13.5523 4 13 4ZM2 6H12V16H2V6ZM4.1717 9.58582L5.58593 11L4.17171 12.4142C3.78118 12.8048 3.78118 13.4379 4.17171 13.8285C4.56224 14.219 5.1954 14.219 5.58593 13.8285L7.00015 12.4142L8.41438 13.8285C8.8049 14.219 9.43807 14.219 9.8286 13.8285C10.2191 13.438 10.2191 12.8048 9.82859 12.4142L8.41437 11L9.82859 9.58582C10.2191 9.19531 10.2191 8.56213 9.82858 8.17163C9.43806 7.78101 8.80489 7.78101 8.41437 8.17163L7.00014 9.58582L5.58592 8.17151C5.1954 7.78101 4.56223 7.78101 4.1717 8.17151C3.78117 8.56213 3.78117 9.19531 4.1717 9.58582Z\" fill=\"#6C6F75\"/>\r\n</svg>\r\n",
  "work_in_progress": null,
  "zaevidovat": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"15\" fill=\"none\" viewBox=\"0 0 18 15\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M17.007 3.704l-2.71-1.566.782-1.355c.432-.749 1.39-1.005 2.138-.573.749.432 1.005 1.39.573 2.138l-.783 1.356zM5.212.135h7.831l-.554.96-.65 1.127H7.303v5.217H2.087v5.218H6.9l-.126 2.086H0V5.36L5.212.135zm7.332 14.608h2.064v-2.71l-1.257 2.177-.807.533zm-9.589-9.39h2.262V3.084L2.955 5.352zm8.835 7.387l4.174-7.23-2.711-1.564-4.174 7.229-.21 3.494 2.921-1.929z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "zasilka": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"12\" fill=\"none\" viewBox=\"0 0 16 12\">\r\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M1.406.09C.59.343 0 1.102 0 2v8c0 1.105.895 2 2 2h12c1.105 0 2-.895 2-2V2c0-1.105-.895-2-2-2H2c-.135 0-.266.013-.393.039L1.57 0c-.058.023-.114.053-.165.089zm11.343 1.76H3.284l4.692 5.045L12.75 1.85zM1.85 2.51l2.725 2.93L1.85 8.381V2.51zm.426 7.64h11.652l-.679-.697h.07l-2.887-2.97-1.83 1.934c-.36.38-.906.378-1.263-.006l-1.74-1.87-2.697 2.912h.02l-.646.697zM14.15 8.155V2.554l-2.686 2.839 2.686 2.762z\" clip-rule=\"evenodd\"/>\r\n</svg>\r\n",
  "ztotoznit": "<svg width=\"20\" height=\"20\" viewBox=\"0 0 20 20\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\r\n<path d=\"M3.98137 8.54427C3.66919 8.27761 3.63229 7.80837 3.89896 7.49619C4.16562 7.18401 4.63487 7.14712 4.94704 7.41378L6.64278 8.8623L10.0227 4.90557C10.2893 4.5934 10.7586 4.5565 11.0707 4.82317C11.3829 5.08983 11.4198 5.55908 11.1532 5.87125L6.8076 10.9585L3.98137 8.54427Z\" fill=\"#616161\"/>\r\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7.5 15C9.21605 15 10.7975 14.4237 12.0613 13.454C12.0944 13.5012 12.132 13.5462 12.1742 13.5884L17.6568 19.0711C18.0474 19.4616 18.6805 19.4616 19.0711 19.0711C19.4616 18.6806 19.4616 18.0474 19.0711 17.6569L13.5884 12.1742C13.5462 12.132 13.5012 12.0944 13.454 12.0613C14.4237 10.7975 15 9.21606 15 7.5C15 3.35786 11.6421 0 7.5 0C3.35786 0 0 3.35786 0 7.5C0 11.6421 3.35786 15 7.5 15ZM7.5 13C10.5376 13 13 10.5376 13 7.5C13 4.46243 10.5376 2 7.5 2C4.46243 2 2 4.46243 2 7.5C2 10.5376 4.46243 13 7.5 13Z\" fill=\"#616161\"/>\r\n</svg>\r\n"
};
