import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

/**
 * A method decorator which will add error message based on Mustache
 * template to an Angular reactive forms validator
 *
 * @param errorMessageTemplate - template for the validation message.
 * May contain references to Angular validator error object members which will be rendered.
 *
 * Example:
 * - when reusing angular Validators.min, it adds an object of form
 * {min: MINVALUE, actual: CONTROLVALUE} to AbstractControl#errors - thus the template can contain
 * either {{min}} or {{actual}} template expressions that will get rendered to the final error message:
 * @ValidationErrorMessage("You've entered {{actual}} but it should be at least {{min}}.")
 */
export function ValidationErrorMessage(errorMessageTemplate: string): IczValidatorFnDecorator {
  return (
    targetObject: {[k: string]: any},
    propertyName: string,
    propertyDescriptor: TypedPropertyDescriptor<IczValidatorFnFactory>
  ) => {
    const untransformedValidator = targetObject[propertyName];

    propertyDescriptor.value = (...factoryFunctionArgs) => {
      return transformValidatorResult(
        propertyName,
        errorMessageTemplate,
        untransformedValidator(...factoryFunctionArgs),
      );
    };
  };
}

/**
 * Validation error transfer object.
 */
export interface IczValidatorResult {
  /**
   * Error message template, can contain simple mustache-like interpolation expression.
   */
  errorMessageTemplate: string;
  /**
   * @internal
   */
  validationErrors?: ValidationErrors;
}

/**
 * @internal
 */
export interface IczFieldValidationResults {
  fieldName: string;
  errors: IczValidationErrors;
}

/**
 * @internal
 */
type IczValidatorFnFactory = (...factoryFunctionArgs: any) => IczValidatorFn;

/**
 * @internal
 */
type IczValidatorFnDecorator = (
  // eslint-disable-next-line @typescript-eslint/ban-types -- generic object instance
  targetObject: object,
  propertyName: string,
  propertyDescriptor: TypedPropertyDescriptor<IczValidatorFnFactory>
) => void;

/**
 * @internal
 */
export type IczValidationErrors = {[k: string]: IczValidatorResult};

/**
 * @internal
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- initial type definition
export type IczValidatorFn = ValidatorFn | ((control: AbstractControl) => IczValidationErrors | null);

/**
 * A function that will convert standard ValidationErrors object from
 * Angular Forms to our IczValidationErrors object which is able to
 * render a supplied template provided validation error details.
 * To be used only by the @ValidationErrorMessage decorator.
 *
 * @param validatorPropertyName is a name of the validator corresponding to static property,
 *        such as `required` for a validator `IczValidators.required`
 * @param errorMessageTemplate is the template to be interpolated into error message
 * @param originalValidator is Angular ValidatorFn we want to add the validation message to
 */
function transformValidatorResult(
  validatorPropertyName: string,
  errorMessageTemplate: string,
  originalValidator: ValidatorFn
): IczValidatorFn {
  return control => {
    const validatorResult = originalValidator(control);

    if (!validatorResult) return null;

    let validationErrors = {};

    // will flatten all the validation error objects into a single
    // object which will be available for template interpolation
    for (const key in validatorResult) {
      if (validatorResult.hasOwnProperty(key)) {
        validationErrors = {
          ...validationErrors,
          ...validatorResult[key],
        };
      }
    }

    const out = {
      errorMessageTemplate,
      validationErrors,
    };

    return {
      [validatorPropertyName]: out
    };
  };
}
