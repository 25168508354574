import {DestroyRef, inject, Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, map, Subject, Subscription} from 'rxjs';
import {
  FilterItemValue,
  FilterType,
  isListFilterDefinition,
  isListFilterItem,
  isNonemptyFilterDefinition,
  isNonemptyFilterItem, isPageSelectorFilterDefinition,
  NonemptyFilterItem
} from '../filter.types';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {DialogService} from '@icz/angular-modal';
import {
  addValueToFilterItem,
  EMPTY_FILTER_TREE,
  FilterItemValueTree,
  filterItemValueTreeToFilterItemTree
} from '../filter-trees.utils';
import {TableColumnsData} from '../table-columns-data';
import {startWith} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {getOperatorTranslationKey} from '../table.utils';

/**
 * Service which controls table filter toolbar behavior.
 */
@Injectable()
export class TableToolbarService implements OnDestroy {

  private destroyRef = inject(DestroyRef);
  private dialogService = inject(DialogService);

  /**
   * @internal
   */
  autoOpen = false;

  /**
   * @internal
   */
  reloadFilters$ = new Subject<void>();

  /**
   * @internal
   */
  allColumnFilters$ = new BehaviorSubject<NonemptyFilterItem[]>([]);

  /**
   * @internal
   */
  enabledFilters$ = new BehaviorSubject<Nullable<string[]>>(null);

  /**
   * @internal
   */
  filters$ = new BehaviorSubject<NonemptyFilterItem[]>([]);

  /**
   * @internal
   */
  allColumnFilterOptions$ = this.allColumnFilters$.pipe(
    map(allFilters => {
      return allFilters
        .filter(u => isNonemptyFilterItem(u))
        .filter(u => !u.isDistinctive)
        .map(u => ({value: (u.customFilterId ? u.customFilterId : u.id)!, label: u.columnLabel}));
    }),
  );

  /**
   * @internal
   */
  activeFilterValues$ = new BehaviorSubject<FilterItemValueTree>(EMPTY_FILTER_TREE);

  /**
   * @internal
   */
  visibleFilters$ = this.filters$.pipe(
    map(filters => filters.filter(f => !f.isDistinctive)),
    map(filters => {
      if (this.enabledFilters$.value) {
        return filters.filter(f => this.enabledFilters$.value!.includes(f.id));
      }
      else {
        return filters;
      }
    }),
  );

  /**
   * @internal
   */
  activeFilters$ = combineLatest([
    this.activeFilterValues$,
    this.allColumnFilters$,
  ]).pipe(
    map(([activeFilterValues, allColumnFilters]) => {
      return filterItemValueTreeToFilterItemTree(activeFilterValues, allColumnFilters);
    })
  );

  /**
   * @internal
   */
  distinctiveFilters$ = this.filters$.pipe(
    map(allFilters => allFilters.filter(f => f.isDistinctive))
  );

  /**
   * @internal
   */
  toolbarForm = new IczFormGroup({
    searchTerm: new IczFormControl<Nullable<string>>(null),
  });

  private defaultToolbarItems: string[] = [];
  private columnDefinitionsChangeSubscription: Nullable<Subscription>;

  /**
   * @internal
   */
  ngOnDestroy() {
    this.columnDefinitionsChangeSubscription?.unsubscribe();
  }

  /**
   * @internal
   */
  init(defaultToolbarItems: string[], columnsData: TableColumnsData<string>) {
    this.defaultToolbarItems = defaultToolbarItems;

    this.toolbarForm.reset(
      {
        searchTerm: null,
      },
      {
        emitEvent: false,
      }
    );

    this.columnDefinitionsChangeSubscription?.unsubscribe();

    this.columnDefinitionsChangeSubscription = columnsData.columnDefinitionsChange$.pipe(
      startWith(undefined),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      const columnDefinitions = columnsData.columnDefinitions;

      this.allColumnFilters$.next(columnDefinitions.filter(c => c.filterType !== FilterType.NONE).map(c => {
        return ({
          id: c.id,
          filterType: c.filterType,
          value: '',
          label: undefined,
          columnLabel: !c.label!.replace(/ /g, '') ? c.filterLabel! : c.label!,
          list: isListFilterDefinition(c) ? c.list : undefined,
          filterDataSource: isPageSelectorFilterDefinition(c) ? c.filterDataSource : null,
          ...(isNonemptyFilterDefinition(c) ? c.filterConfig : {}),
        } as NonemptyFilterItem);
      }));
    });

    const distinctiveFilterIds = this.allColumnFilters$.value.filter(c => c.isDistinctive).map(c => c.id);

    const itemsInToolbar = [
      ...distinctiveFilterIds,
      ...this.defaultToolbarItems,
    ];

    this.filters$.next(this.allColumnFilters$.value.filter(c => itemsInToolbar.includes(c.customFilterId ? c.customFilterId : c.id)));

    this.activeFilterValues$.next(EMPTY_FILTER_TREE);
  }

  /**
   * Adds filters corresponding to filter tree to the toolbar and applies search.
   */
  addTreeItems(deserializedQuery: FilterItemValueTree) {
    this.activeFilterValues$.next(deserializedQuery);
  }

  /**
   * Adds a single filter to the toolbar and applies search.
   */
  addItemValue(itemValue: FilterItemValue, reload = false) {
    const filterItem = this.allColumnFilters$.value.find(u => u.customFilterId ? u.customFilterId === itemValue.id : u.id === itemValue.id);

    if (filterItem) {
      const presetFilter = this.filters$.value.find(f => f.id === itemValue.id);

      if (presetFilter) {
        presetFilter.value = itemValue.value;
        presetFilter.subValues = itemValue.subValues;
        presetFilter.filterOption = {value: itemValue.operator!, label: getOperatorTranslationKey(itemValue.operator)};

        if (isListFilterItem(presetFilter)) {
          presetFilter.originId = itemValue.originId;
        }
      }
      else {
        this.addFilterItem(addValueToFilterItem(filterItem, itemValue));
      }

      if (reload) {
        this.reloadFilters();
      }
    }
    else {
      // Neznámé vyhledávací kritérium "{{criterionName}}". Vyhledávání podle tohoto kritéria nebude provedeno.
      this.dialogService.showError(
        'fe.ui.unknownFilteringCriterion',
          undefined,
        {criterionName: itemValue.id}
      );
    }
  }

  /**
   * @internal
   */
  addFilterItem(item: NonemptyFilterItem, reload = false) {
    this.filters$.next([...this.filters$.value, item]);

    if (reload) this.reloadFilters();
  }

  /**
   * Removes a single filter from the toolbar and applies search.
   */
  removeFilterItem(item: NonemptyFilterItem, reload = false) {
    this.filters$.next(this.filters$.value.filter(f => f !== item));
    if (reload) this.reloadFilters();
  }

  /**
   * Removed all filters from the toolbar and applies search.
   */
  clearAllFilters(reload = false) {
    this.filters$.next([]);
    this.activeFilterValues$.next(EMPTY_FILTER_TREE);
    if (reload) this.reloadFilters();
  }

  /**
   * @internal
   */
  reloadFilters() {
    this.filters$.next(this.filters$.value);
    this.reloadFilters$.next();
  }

}
