/* eslint-disable no-bitwise */
import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, EMPTY, Observable, Subject, timeout} from 'rxjs';
import * as semver from 'semver/preload';
import {
  getVersionRes,
  Idt2ErrorMessage,
  Idt2MethodName,
  Idt2RequestMessage,
  Idt2ResponseMessage,
  Idt2Target
} from './idt.typedefs';
import {DebugLoggingService} from '../debug-logging.service';
import {DialogService} from '@icz/angular-modal';
import {ENVIRONMENT} from '../environment.service';
import {isFirefox} from '../../../lib/utils';
import {ConfigPropValueService} from '../../../services/config-prop-value.service';
import {ApplicationConfigService} from '../config/application-config.service';

export enum IdtConnectionState {
  UNINITIATED = 'UNINITIATED',
  INCOMPATIBLE_VERSION = 'INCOMPATIBLE_VERSION',
  INCOMPATIBLE_BROWSER = 'INCOMPATIBLE_BROWSER',
  NOT_INSTALLED = 'NOT_INSTALLED',
  INITIATING = 'INITIATING',
  RUNNING = 'RUNNING',
}

export enum IdtErrorCode {
  ANONYMIZER_NOT_INSTALLED = 137,
  ANONYMIZER_OPEN_ERROR = 138
}

type CorrelationId = string;

export class IdtCommunicationError extends Error {

  constructor(
    message: string,
    public errorData: Idt2RequestMessage | Idt2ErrorMessage,
  ) {
    super(message);

    Object.setPrototypeOf(this, IdtCommunicationError.prototype);
  }

}

@Injectable({
  providedIn: 'root'
})
export class IdtLinkService {

  private dialogService = inject(DialogService);
  private debugLoggingService = inject(DebugLoggingService);
  private configPropValueService = inject(ConfigPropValueService);
  private applicationConfiguration = inject(ApplicationConfigService);
  private environment = inject(ENVIRONMENT);

  private messagePool = new Map<CorrelationId, Subject<Idt2ResponseMessage>>();
  // a map storing messaging timeout handler IDs for each distinctive request
  // todo(rb) uncomment after IDT vendor fixes inability to bulk sign attachments with windows cryptoAPI
  // private timeoutPool = new Map<CorrelationId, number>();

  private isTimeoutDialogOpen = false;

  private readonly _idtConnectionStatus$ = new BehaviorSubject<IdtConnectionState>(IdtConnectionState.UNINITIATED);
  idtConnectionStatus$ = this._idtConnectionStatus$.asObservable();

  private incomingMessageHandler = (incomingMessage: any) => {
    if (!incomingMessage.data) return;

    const {
      messageId,
      source,
      target,
      methodName,
      result,
    } = incomingMessage.data as Idt2ResponseMessage | Idt2ErrorMessage;

    const sourceMatches = source === Idt2Target.nativeApp;
    const targetMatches = target === Idt2Target.webApp;

    // if it is not IDT2 message, ignore it
    if (!sourceMatches || !targetMatches) return;

    this.debugLoggingService.logIdtMessageInbound({
      // @ts-ignore
      method: methodName,
      success: result,
      data: incomingMessage.data.data,
    });

    // dispatch message processing
    if (this.messagePool.has(messageId)) {
      const response$ = this.messagePool.get(messageId)!;
      // todo(rb) uncomment after IDT vendor fixes inability to bulk sign attachments with windows cryptoAPI
      // clearTimeout(this.timeoutPool.get(messageId)!);

      if (!result) {
        if (incomingMessage.data.code === IdtErrorCode.ANONYMIZER_NOT_INSTALLED) {
          this.dialogService.showError('Anonymizér není nainstalován.');
        } else if (incomingMessage.data.code === IdtErrorCode.ANONYMIZER_NOT_INSTALLED) {
          this.dialogService.showError('Otevření souboru pro anonymizaci selhalo.');
        } else {
          response$.error(
            new IdtCommunicationError(
              `IDT call to ${methodName} failed: ${incomingMessage.data.message}`,
              incomingMessage.data,
            )
          );
        }
      }
      else {
        response$.next(incomingMessage.data.data);
      }

      response$.complete();

      this.messagePool.delete(messageId);
      // todo(rb) uncomment after IDT vendor fixes inability to bulk sign attachments with windows cryptoAPI
      // this.timeoutPool.delete(messageId);
    }
    else {
      console.warn(`No outbound message associated to ${messageId}. `);
    }
  };

  initialize() {
    const isChromeUserAgent = navigator.userAgent.indexOf('Chrome') > -1;
    const isSafari = (window as any).safari;

    if (isChromeUserAgent || isSafari || isFirefox()) {
      window.addEventListener('message', this.incomingMessageHandler, false);
      this._idtConnectionStatus$.next(IdtConnectionState.INITIATING);

      const requiredIdtVersion = this.applicationConfiguration.defaultIdtVersion;
      this.request({
        methodName: Idt2MethodName.getVersion,
      }).pipe(
        timeout(30000),
      ).subscribe({
        next: result => {
          if (semver.satisfies((result as unknown as getVersionRes['data']).version, `>=${requiredIdtVersion}`)) {
            this._idtConnectionStatus$.next(IdtConnectionState.RUNNING);
          }
          else {
            this._idtConnectionStatus$.next(IdtConnectionState.INCOMPATIBLE_VERSION);
          }
        },
        error: _ => {
          this._idtConnectionStatus$.next(IdtConnectionState.NOT_INSTALLED);
        },
      });
    }
    else {
      this._idtConnectionStatus$.next(IdtConnectionState.INCOMPATIBLE_BROWSER);
      console.warn(`IDT2 is not supported outside Chrome, Firefox, Microsoft Edge and Safari.`);
    }
  }

  // Idt2ErrorMessage gets sent in error emissions
  request(message: Partial<Idt2RequestMessage>): Observable<Idt2ResponseMessage> {
    if (
      this._idtConnectionStatus$.value === IdtConnectionState.INITIATING ||
      this._idtConnectionStatus$.value === IdtConnectionState.RUNNING ||
      this._idtConnectionStatus$.value === IdtConnectionState.INCOMPATIBLE_VERSION
    ) {
      const correlationId = this.generateCorrelationId();
      const messageResponse$ = new Subject<Idt2ResponseMessage>();

      // todo(rb) uncomment after IDT vendor fixes inability to bulk sign attachments with windows cryptoAPI
      /*const timeoutHandlerId = setTimeout(() => {
        if (this._idtConnectionStatus$.value === IdtConnectionState.RUNNING && !this.isTimeoutDialogOpen) {
          this.isTimeoutDialogOpen = true;

          this.dialogService.showError(
            '',
            {},
            [],
            'IDT neodpovídá',
          ).subscribe(_ => {
            this.isTimeoutDialogOpen = false;
          });
        }

        this.messagePool.get(correlationId)!.error(
          new IdtCommunicationError(
            `IDT communication timeouted in ${IDT_MESSAGING_TIMEOUT} ms.`,
            message as unknown as Idt2RequestMessage
          )
        );
        this.messagePool.delete(correlationId);
        this.timeoutPool.delete(correlationId);
      }, IDT_MESSAGING_TIMEOUT);

      // @ts-ignore
      this.timeoutPool.set(correlationId, timeoutHandlerId);*/

      this.messagePool.set(correlationId, messageResponse$);

      this.sendMessage(message, correlationId);

      return messageResponse$;
    }
    else {
      return EMPTY;
    }
  }

  private sendMessage(message: Partial<Idt2RequestMessage>, correlationId: CorrelationId) {
    window.postMessage({
      ...message,
      messageId: correlationId,
      source: 'idt2-web',
      target: 'idt2-cli',
    });

    this.debugLoggingService.logIdtMessageOutbound({
      method: message.methodName!,
      // @ts-ignore
      data: message.data ?? null,
    });
  }

  private generateCorrelationId(): CorrelationId {
    const randomHex = (c: any) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16);
    // @ts-ignore
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11)
      .replace(/[018]/g,randomHex);
  }

}
