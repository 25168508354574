<div class="h-full col grow" [formGroup]="form">
  <div class="top-toolbar row">
    <div class="row items-center">
      <ng-content></ng-content>
      {{ tableTitle }}
    </div>
    <div class="grow"></div>
    <div class="row items-center">
      @if (statisticConfig?.allowLineChart) {
        <icz-form-filter formControlName="firstPeriod"

                         [filterItem]="firstPeriodFilter"></icz-form-filter>
        <icz-form-filter formControlName="secondPeriod"
                         [filterItem]="secondPeriodFilter"></icz-form-filter>
      }
      <icz-toggle-button
        class="my-8"
        label="Graf"
        [toggled]="showChart"
        (onAction)="showChart = !showChart"
      ></icz-toggle-button>
    </div>
  </div>
  <div class="graph-area w-full">
    @if (showChart && statisticConfig?.allowLineChart) {
      <icz-line-chart [config]="lineChartConfig" [waiting]="loadingIndicatorService.isLoading$(this, 'lineChart') | async" class="w-full"></icz-line-chart>
    }
    @if (showChart && statisticConfig?.allowBarChart) {
      <icz-bar-chart [config]="barChartConfig" [waiting]="loadingIndicatorService.isLoading$(this, 'barChart') | async" class="w-full"></icz-bar-chart>
    }
    @if (showDonutChart) {
      <icz-donut-chart [config]="statisticConfig.donutConfig!"></icz-donut-chart>
    }
  </div>
  @if (columnsData && tableConfig && statisticConfig && showTableAndGraph) {
    <div class="col grow">
      <icz-table
        class="col grow"
        [waiting]="loadingIndicatorService.isLoading$(this, 'statistic-table') | async"
        [id]="tableId"
        [dataSource]="dataSource"
        [columnsData]="columnsData"
        [config]="tableConfig"
        (pageLoadStarted)="pageLoadStarted($event)"
        [disableUrlParameters]="true"
        (initialized)="afterTableInitialized($event)"
      >
        <icz-label noDataLabel [label]="dimensionsSelected ? 'Žádná data' : 'Žádná data, přidejte aspoň jednu dimenzi'"></icz-label>
        <icz-label notFoundLabel label="Žádná data"></icz-label>
        <!-- todo(mh) will be added in next iteration -->
        <!--<div class="row-group" tools>
          <icz-button background="white" (onAction)="exportToCSV()" label="Export do CSV"></icz-button>
        </div>-->
        <icz-dimensions-toolbar beforeFilters
                                [initialDimensions]="selectedDimensions"
                                [allowedDimensions]="allowedDimensions"
                                (dimensionsChange)="dimensionsChanged($event, true)"
        ></icz-dimensions-toolbar>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'count'; let column = column">
          {{ row.count | numberToLocaleString }}
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'size'; let column = column">
          {{ row.size | fileSize}}
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.ENTITY_CLASS; let column = column">
          <ng-container
            [ngTemplateOutlet]="asyncListRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.SECURITY_CATEGORY; let column = column">
          <ng-container
            [ngTemplateOutlet]="asyncListRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.DISPOSAL_SCHEDULE; let column = column">
          <ng-container
            [ngTemplateOutlet]="asyncListRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.DOCUMENT_TYPE; let column = column">
          <ng-container
            [ngTemplateOutlet]="asyncListRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.FILE_TYPE; let column = column">
          <ng-container
            [ngTemplateOutlet]="asyncListRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.OBJECT_CLASS; let column = column">
          <ng-container
            [ngTemplateOutlet]="listRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.ENTITY_STATE; let column = column">
          <ng-container
            [ngTemplateOutlet]="listRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.OWNER_FP; let column = column">
          @if (getFpOrOrgAsNumber(row.dimensions[StatisticsDimension.OWNER_FP]) | findFunctionalPositionById | async; as owner) {
            <span [iczTooltip]="owner">
            {{ owner }}
          </span>
          }
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.OWNER_ORG_UNIT; let column = column">
          @if (getFpOrOrgAsNumber(row.dimensions[StatisticsDimension.OWNER_ORG_UNIT]) | findOrganizationalUnitById | async; as ownerOU) {
            <span [iczTooltip]="ownerOU">
            {{ ownerOU }}
          </span>
          }
        </ng-container>


        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.STORAGE_UNIT; let column = column">
          @if (row.dimensions[StatisticsDimension.STORAGE_UNIT]) {
            {{ row.dimensions[StatisticsDimension.STORAGE_UNIT] }}
          } @else {
            {{ getNullDimensionValue(StatisticsDimension.STORAGE_UNIT) | translate }}
          }
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.REGISTRY_OFFICE; let column = column">
          <ng-container
            [ngTemplateOutlet]="asyncListRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>

        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.HANDED_OVER_BY_FP; let column = column">
          @if (getFpOrOrgAsNumber(row.dimensions[StatisticsDimension.HANDED_OVER_BY_FP]) | findFunctionalPositionById | async; as owner) {
            <span [iczTooltip]="owner">
            {{ owner }}
          </span>
          }
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.HANDED_OVER_BY_ORG_UNIT; let column = column">
          @if (getFpOrOrgAsNumber(row.dimensions[StatisticsDimension.HANDED_OVER_BY_ORG_UNIT]) | findOrganizationalUnitById | async; as ownerOU) {
            <span [iczTooltip]="ownerOU">
            {{ ownerOU }}
          </span>
          }
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.PUID; let column = column">
          {{ row.dimensions[StatisticsDimension.PUID] }}
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.BUSINESS_RULE; let column = column">
          {{ row.dimensions[StatisticsDimension.BUSINESS_RULE] | translate }}
        </ng-container>
        <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: StatisticsDimension.ENTITY_CLASS_TYPE; let column = column">
          <ng-container
            [ngTemplateOutlet]="listRow"
            [ngTemplateOutletContext]="{$implicit: {column: column, row: row}}">
          </ng-container>
        </ng-container>
      </icz-table>
    </div>

    <ng-template #asyncListRow let-context>
      @if (context.row.dimensions[context.column.id]) {
        {{ context.row.dimensions[context.column.id] | findInList:(context.column.list$! | async)! | translate }}
      } @else {
        {{ getNullDimensionValue(context.column.id) | translate}}
      }
    </ng-template>
    <ng-template #listRow let-context>
      {{ context.row.dimensions[context.column.id] | findInList:(context.column.list)! | translate }}
    </ng-template>
  }
</div>


