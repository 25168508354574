/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { MarkForAgendaTransferDto } from '../../models/mark-for-agenda-transfer-dto';

export interface AgendaTransferMarkForTransfer$Params {
      body: MarkForAgendaTransferDto
}

export function agendaTransferMarkForTransfer(http: HttpClient, rootUrl: string, params: AgendaTransferMarkForTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, agendaTransferMarkForTransfer.PATH, 'put');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

agendaTransferMarkForTransfer.PATH = '/document/agenda-transfer/mark-for-transfer';