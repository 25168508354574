import {DestroyRef, Directive, inject} from '@angular/core';
import {BOOLEAN_OPTIONS, IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {StorageUnitFillerClassificationAttribute} from '|api/commons';
import {startWith} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CodebookService} from '../../../../../core/services/codebook.service';

@Directive()
export abstract class AbstractStorageUnitFillerClassKeysComponment {
  constructor() {}

  protected destroyRef = inject(DestroyRef);
  protected codebookService = inject(CodebookService);
  protected form!: IczFormGroup;

  protected classificationAttributeOptions: IczOption[] = enumToOptions('storageUnitFillerClassificationAttribute', StorageUnitFillerClassificationAttribute);
  protected booleanOptions = BOOLEAN_OPTIONS;
  protected abstract registryOfficeOptions: IczOption[];

  protected syncEnforcedClassificationWithUserKeys() {
    const disposalScheduleOpt = this.classificationAttributeOptions.find(o => o.value === StorageUnitFillerClassificationAttribute.DISPOSAL_SCHEDULE)!;
    const disposalYearOpt = this.classificationAttributeOptions.find(o => o.value === StorageUnitFillerClassificationAttribute.DISPOSAL_YEAR)!;
    const entityClassOpt = this.classificationAttributeOptions.find(o => o.value === StorageUnitFillerClassificationAttribute.ENTITY_CLASS)!;
    const disposalYearCtrl = this.form.get('enforceStrictDisposalYear')!;
    const entityClassCtrl = this.form.get('enforceStrictEntityClass')!;
    const classificationCtrl = this.form.get('classificationAttributes')!;

    disposalScheduleOpt!.disabled = true;

    disposalYearCtrl.valueChanges.pipe(
      startWith(disposalYearCtrl.value),
      takeUntilDestroyed(this.destroyRef))
      .subscribe(enforce => {
        const classKeys = classificationCtrl.value ?? [];

        if (enforce) {
          disposalYearOpt.disabled = true;
          if (classKeys && !classKeys?.includes(StorageUnitFillerClassificationAttribute.DISPOSAL_YEAR)) {
            classificationCtrl.setValue([...classKeys as StorageUnitFillerClassificationAttribute[], StorageUnitFillerClassificationAttribute.DISPOSAL_YEAR]);
          }
        }
        else {
          disposalYearOpt.disabled = false;
          if (classKeys && classKeys?.includes(StorageUnitFillerClassificationAttribute.DISPOSAL_YEAR)) {
            classificationCtrl.setValue(classKeys.filter((key: StorageUnitFillerClassificationAttribute) => key !== StorageUnitFillerClassificationAttribute.DISPOSAL_YEAR));
          }
        }
      });
    entityClassCtrl.valueChanges.pipe(
      startWith(entityClassCtrl.value),
      takeUntilDestroyed(this.destroyRef))
      .subscribe(enforce => {
        const classKeys = classificationCtrl.value ?? [];

        if (enforce) {
          entityClassOpt.disabled = true;
          if (classKeys && !classKeys?.includes(StorageUnitFillerClassificationAttribute.ENTITY_CLASS)) {
            classificationCtrl.setValue([...classKeys as StorageUnitFillerClassificationAttribute[], StorageUnitFillerClassificationAttribute.ENTITY_CLASS]);
          }
        }
        else {
          entityClassOpt.disabled = false;
          if (classKeys && classKeys?.includes(StorageUnitFillerClassificationAttribute.ENTITY_CLASS)) {
            classificationCtrl.setValue(classKeys.filter((key: StorageUnitFillerClassificationAttribute) => key !== StorageUnitFillerClassificationAttribute.ENTITY_CLASS));
          }
        }
      });
  }

}
