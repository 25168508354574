import {inject} from '@angular/core';
import {UrlSerializer} from '@angular/router';
import {filter, map, take} from 'rxjs/operators';
import {ApplicationRoute} from '../../enums/shared-routes.enum';
import {PersonalSettingsRoute} from '../../enums/personal-settings-routes.enum';
import {BasicSettingsRoute} from '../../enums/basic-settings-routes.enum';
import {SubjectsManagementRoute} from '../../enums/subjects-management-routes.enum';
import {ClassificationSchemesRoute} from '../../enums/classification-schemes-routes.enum';
import {isAdmin, isClassificationSchemesAdmin, isOfficer, isSubjectsAdmin} from '../model/user-roles.model';
import {CurrentSessionService} from '../../services/current-session.service';

export function guardApplicationSettingsLandingPage() {
  return () => {
    const currentSessionService = inject(CurrentSessionService);
    const urlSerializer = inject(UrlSerializer);

    return currentSessionService.currentUserFunctionalPosition$.pipe(filter(Boolean), take(1), map(currentUserInfo => {
      if (isAdmin(currentUserInfo!)) {
        return urlSerializer.parse(`${ApplicationRoute.ADMIN}/${BasicSettingsRoute.BASIC_SETTINGS}`);
      } else if (isSubjectsAdmin(currentUserInfo)){
        return urlSerializer.parse( `${ApplicationRoute.ADMIN}/${SubjectsManagementRoute.SUBJECTS_MANAGEMENT}/${SubjectsManagementRoute.SUBJECTS_OVERVIEW}`);
      } else if (isClassificationSchemesAdmin(currentUserInfo)){
        return urlSerializer.parse( `${ApplicationRoute.ADMIN}/${ClassificationSchemesRoute.CLASSIFICATION_SCHEMES}`);
      } else if (isOfficer()){
        return urlSerializer.parse(`${ApplicationRoute.ADMIN}/${PersonalSettingsRoute.PERSONAL_SETTINGS}/${PersonalSettingsRoute.PERSONAL_SUBSTITUTIONS}`);
      } else {
        throw new Error('ApplicationSettingsLandingPageGuard prevented access to page');
      }
    }));
  };
}
