import {ChangeDetectionStrategy, Component} from '@angular/core';
import {range} from 'lodash';
import {TimeSelectorComponent} from '../time-selector/time-selector.component';

/**
 * @internal
 */
@Component({
  selector: 'icz-time-selector-grid',
  templateUrl: './time-selector-grid.component.html',
  styleUrls: ['./time-selector-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class TimeSelectorGridComponent extends TimeSelectorComponent {

  protected readonly availableHours = range(0, 24);
  protected readonly availableMinutes = range(0, 60, 5);

  protected zeroPadMinutes(number: number): string {
    return String(number).padStart(2, '0');
  }

  protected override minutesChanged($event: number) {
    this.minutes = $event;

    if (!isNil(this.hours)) {
      this.emitCurrentValue();
    }
  }

  protected override hoursChanged($event: number) {
    this.hours = $event;

    if (!isNil(this.minutes)) {
      this.emitCurrentValue();
    }
  }

}
