<icz-section label="Informace o dokumentu">
  <icz-entity-info [entity]="document"></icz-entity-info>
</icz-section>
<div class="gap-24 row grow" [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="gap-8 col grow">
    <icz-section label="Jak chcete vyřídit">
      <icz-form-autocomplete label="Způsob vyřízení"
        formControlName="documentSettlementType"
        [options]="documentSettlementTypeOptions">
      </icz-form-autocomplete>
      <div class="row grow">
        <icz-date-picker
          label="Datum vyřízení"
          formControlName="settlementDate"
          [selectableDates]="settlementDateValidator"
          outputFormat="dateTime"
          class="grow">
        </icz-date-picker>
        <icz-form-autocomplete label="Forma dokumentu" formControlName="documentForm"
          [options]="documentFormOptions" class="grow">
        </icz-form-autocomplete>
      </div>
      @if (isSettlementByRecord) {
        <icz-form-field label="Obsah vyřízení (jak bylo vyřízeno)" formControlName="content"></icz-form-field>
        <icz-form-field label="Důvod" formControlName="reason"></icz-form-field>
      }
    </icz-section>

    <icz-section label="Komentáře" testingFeature>
      <icz-checkbox formControlName="deleteComments"
        label="Smazat uživatelské komentáře"
      secondaryLabel="{{ userCommentNotice | translate | interpolate:userCommentNoticeContext }}"></icz-checkbox>
    </icz-section>

    <icz-section label="Počet ukládaných komponent">
      <icz-form-field [value]="selectedEsslComponentsCount" label="Počet ukládaných komponent" [fieldDisabled]="true"></icz-form-field>
    </icz-section>

  </div>

  <div class="gap-8 col grow">
    <icz-section label="Zatřídění">
      @if (isDocumentInFile) {
        <icz-form-help-field class="grow"
          helpText="Dokument je založen ve spisu">
        </icz-form-help-field>
      }

      <icz-document-type-selector
        [form]="form"
        controlName="documentTypeId"
        [mode]="VisibleClassifiersMode.VALID_AT_GIVEN_DATE"
        [date]="settlementDate"
      ></icz-document-type-selector>
      <icz-entity-class-selector
        [form]="form"
        controlName="entityClassId"
        [fieldDisabled]="isDocumentInFile"
        [showEntityClassesValidAt]="settlementDate"
      ></icz-entity-class-selector>
      <div class="row grow">
        <icz-form-field label="Spisový plán" formControlName="classificationSchemeName" class="grow"></icz-form-field>
      </div>
      <div class="row grow">
        <icz-date-picker label="Platnost spisového plánu od"
          formControlName="classificationSchemeValidFrom"
          placeholder="Neomezeno" class="grow">
        </icz-date-picker>
        <icz-date-picker label="Platnost spisového plánu do"
          formControlName="classificationSchemeValidTo"
          placeholder="Neomezeno" class="grow">
        </icz-date-picker>
      </div>

    </icz-section>
    <icz-section label="Skartační režim">
      <icz-form-autocomplete
        [clearable]="false"
        [options]="disposalScheduleOptions"
        class="grow"
        formControlName="disposalScheduleId"
        label="Skartační režim"
      ></icz-form-autocomplete>
      <icz-form-autocomplete [options]="disposalOperationCodeOptions" class="grow-2"
                             formControlName="disposalOperationCode" label="Skartační operace"></icz-form-autocomplete>
      <icz-form-field class="grow-1" formControlName="retentionPeriod" label="Skartační lhůta"></icz-form-field>
      <icz-form-autocomplete
        [clearable]="false"
        [options]="retentionTriggerTypesOptions"
        class="grow"
        formControlName="retentionTriggerTypeCode"
        label="Spouštěcí událost"
      ></icz-form-autocomplete>
      @if (disposalSchedulePrepare && isExternalRetentionTriggerTypeWithPeriod()) {
        <icz-ext-retention-trigger-selector
          [form]="form"
          [availableExternalRetentionTriggerIds]="disposalSchedulePrepare.externalRetentionTriggerIds ?? []"
        ></icz-ext-retention-trigger-selector>
      }
      <div class="row">
        <icz-form-field class="grow" label="Rok spouštěcí události"  formControlName="yearOfRetentionPeriodStart" type="integer"></icz-form-field>
        @if (isRetentionTriggerTypeWithPeriod()) {
          <icz-form-field class="grow" label="Rok kontroly spouštěcí události"  formControlName="triggerEventCheckYear" type="integer"></icz-form-field>
        } @else {
          <div class="grow"></div>
        }
      </div>
    </icz-section>

  </div>
</div>
