import {TranslateService} from '@ngx-translate/core';
import {pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  AnalogComponentTypeDto, ClassificationSchemeDto,
  DeliveryTypeDto,
  DisposalScheduleDto,
  DistributionClassDto,
  EntityClassDto
} from '|api/codebook';
import {AnalogComponentForm, NodeType} from '|api/commons';

import {IczOption} from '@icz/angular-form-elements';
import {numberArrayToOptions} from '@icz/angular-table';

export type DisposalScheduleOption = IczOption<number, DisposalScheduleDto>;

interface DeliveryTypeOptionData {
  distributionClass: DistributionClassDto;
}

interface EntityClassFilterOptionData {
  classificationSchemeId: Nullable<number>;
  classificationSchemeName: Nullable<string>;
  fqcCode: Nullable<string>;
}

export type EntityClassFilterOption = IczOption<string | number, EntityClassFilterOptionData>;

export type DeliveryTypeOption = IczOption<number, DeliveryTypeOptionData>;
export type Enum<E> = Record<keyof E, string | number>;
type EnumArray<E> = Array<[keyof E, string | number]>;

export function enumToArray<E>(values: Enum<E>): EnumArray<E> {
  return Object.entries(values) as EnumArray<E>;
}

export function enumValuesToArray<E>(values: Enum<E>): Array<string> {
  return Object.values(values);
}

export function arrayToOptions<T = string | number>(array: T[], name?: string): IczOption[] {
  return (array.map(value => ({
    value,
    label: name ? `en.${name}.${value}` : String(value),
  })) as unknown) as IczOption[];
}

export function enumArrayToOptions<E>(name: string, values: EnumArray<E>) {
  return values.map(([_, value]) => ({
    value,
    label: name ? `en.${name}.${value}` : value.toString(),
  }));
}

/**
 * The preferred method to map static (and usually generated) ENUMs to autocomplete options in component controllers.
 * @param name - the translate key middle part (prefix being 'en' and suffix being the actual value) to find in dictionaries
 * @param values - the enum values
 */
export function enumToOptions<E>(name: string, values: Enum<E>): IczOption[] {
  return enumArrayToOptions(name, enumToArray(values));
}

export function numberRangeToOptions(from: number, to: number, incrementBy: number = 1) {
  const numbers: number[] = [];

  for (let i = from; i <= to; i += incrementBy) {
    numbers.push(i);
  }

  return numberArrayToOptions(numbers);
}

export interface NamedDTO {
  code?: Nullable<string>;
  id?: Nullable<number>;
  name: string;
}

export interface TreeNamedDTO extends NamedDTO {
  parentId?: Nullable<number>;
}

/**
 * The preferred method to map codebooks, lists etc. to autocomplete options in component controllers
 * @param withCode - whether to display the option with 'code' together with 'name'.
 */
export function namedDtoToOption(withCode = false): (entry: NamedDTO) => IczOption<number> {
  return (entry: NamedDTO) => ({
    value: entry.id!,
    label: (withCode && entry.code) ? `${entry.code} ${entry.name}` : entry.name,
    disableTranslate: true
  });
}

export function nameDtoToOptionWithId(withCode = false): (entry: NamedDTO) => IczOption {
  return (entry: NamedDTO) => ({
    value: entry.id!,
    label: withCode ? `${entry.code} - ${entry.name}` : entry.name,
    id: entry.id!
  });
}

export function treeNamedDtoToOption(withCode = false) {
  return (entry: TreeNamedDTO) => ({
    ...namedDtoToOption(withCode)(entry),
    parent: entry.parentId,
  });
}

export const disposalScheduleToOption = (entry: DisposalScheduleDto): DisposalScheduleOption => {
  return {
    ...namedDtoToOption(true)(entry),
    data: entry,
  };
};

export const deliveryTypeToOption = (entry: DeliveryTypeDto): DeliveryTypeOption => {
  return {
    value: entry.id!,
    label: entry.name!,
    data: {
      distributionClass: entry.distributionClass,
    }
  };
};

export function analogComponentTypesToOptions(types: AnalogComponentTypeDto[],
                                              componentForm: AnalogComponentForm,
                                              translateService: TranslateService ) {
  let optionsPerForm: AnalogComponentTypeDto[] = [];
  if (componentForm === AnalogComponentForm.MEDIUM) {
    optionsPerForm = types.filter(op => op.analogComponentForm === AnalogComponentForm.MEDIUM);
  }
  else if (componentForm === AnalogComponentForm.ITEM) {
    optionsPerForm = types.filter(op => op.analogComponentForm === AnalogComponentForm.ITEM);
  }
  const translate = (code: string) => { return translateService.instant(`en.analogComponentType.${code}`);};
  return optionsPerForm.map(type => ({value: translate(type.code), label: translate(type.code)}));
}

export const namedDtosToOptions = pipe(
  map<NamedDTO[], IczOption[]>(list => (!list ? [] : list.map(namedDtoToOption())))
);

export const namedDtosToOptionsWithCode = pipe(
  map<NamedDTO[], IczOption[]>(list => (!list ? [] : list.map(namedDtoToOption(true))))
);

export const namedDtosToOptionsWithId = pipe(
  map<NamedDTO[], IczOption[]>(list => (!list ? [] : list.map(nameDtoToOptionWithId())))
);

export const disposalSchedulesToOptions = pipe(
  map<DisposalScheduleDto[], DisposalScheduleOption[]>(list => (!list ? [] : list.map(disposalScheduleToOption)))
);

export const disposalSchedulesWithValidDate = pipe(
  map<DisposalScheduleDto[], DisposalScheduleDto[]>(schedules => schedules.filter(ds => isNil(ds.validTo) || (!isNil(ds.validTo) && (new Date(ds.validTo!) >= new Date()))))
);

// todo(mh) add type.definition.outsideFilingOffice === true when implemented on BE
export const deliveryTypesToOption = pipe(
  map<DeliveryTypeDto[], DeliveryTypeOption[]>(typesList =>
    typesList
    .filter(type => type.forDelivery === true && type.nodeType === NodeType.LEAF)
    .map(deliveryTypeToOption)
  )
);

export interface ObjectWithValidity {
  validFrom?: Nullable<string>;
  validTo?: Nullable<string>;
}

export function isValidAtGivenDate(c: ObjectWithValidity, date: Date) {
  const validityLowerBound = c.validFrom ? Number(new Date(c.validFrom)) : -Infinity;
  const validityUpperBound = c.validTo ? Number(new Date(c.validTo)) : Infinity;
  const dateTimestamp = Number(date);

  return dateTimestamp >= validityLowerBound && dateTimestamp <= validityUpperBound;
}

export function isValidNow(c: ObjectWithValidity) {
  return isValidAtGivenDate(c, new Date());
}

export function isValidInTheFuture(c: ObjectWithValidity) {
  if (!c.validFrom && !c.validTo) {
    return true; // unconstrained validity
  }
  else {
    const validityLowerBound = Number(new Date(c.validFrom!));
    const dateTimestamp = Number(new Date());

    return validityLowerBound > dateTimestamp;
  }
}

export function entityClassToTreeMapping(entityClasses: EntityClassDto[], classificationSchemes: ClassificationSchemeDto[]) {
  return entityClasses.map(ec => {
    return ({
      value: ec.id!,
      label: `${ec.fqcCode} ${ec.name}`,
      parent: ec.parentId ?? `cs_${ec.classificationSchemeId!}`,
      data: {
        classificationSchemeId: ec.classificationSchemeId!,
        classificationSchemeName: classificationSchemes.find(cs => cs.id === ec.classificationSchemeId)!.name,
        fqcCode: ec.fqcCode!,
      }
    });
  }).sort((x: EntityClassFilterOption, y: EntityClassFilterOption) => {
    if (x.data!.fqcCode === y.data!.fqcCode) {
      return 0;
    }
    else {
      return x.data!.fqcCode! < y.data!.fqcCode! ? -1 : 1;
    }
  });
}

export function classificationSchemesToTreeMapping(classificationSchemes: ClassificationSchemeDto[]) {
  return classificationSchemes.map(cs => ({
    value: `cs_${cs.id}`,
    label: `${cs.code} ${cs.name}`,
    isGroup: true,
    data: {
      classificationSchemeId: cs.id,
      classificationSchemeName: cs.name,
      fqcCode: null,
    }
  }));
}
