import {Pipe, PipeTransform} from '@angular/core';

/**
 * Formats a numeric value such that its format corresponds to user's locale.
 */
@Pipe({
  name: 'numberToLocaleString',
  pure: false, // has to check for language change so is impure
  standalone: true,
})
export class NumberToLocaleStringPipe implements PipeTransform {

  /**
   * Formats a numeric value such that its format corresponds to user's locale.
   */
  transform(numberValue: Nullable<number>): string {
    if (isNil(numberValue)) {
      return '';
    }
    else {
      return numberValue.toLocaleString();
    }
  }

}
