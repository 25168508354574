import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DocumentAllowableObjectClass} from '|api/sad';
import {ObjectClass, StorageUnitAllowableObjectClass} from '|api/commons';
import {getObjectIcon} from '../shared-document.utils';
import {FileAllowableObjectClass} from '|api/document';
import {IconSize} from '@icz/angular-essentials';

@Component({
  selector: 'icz-object-class-icon',
  templateUrl: './object-class-icon.component.html',
  styleUrls: ['./object-class-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjectClassIconComponent {
  @Input({required: true})
  objectClass!: ObjectClass | DocumentAllowableObjectClass | FileAllowableObjectClass | StorageUnitAllowableObjectClass;
  @Input()
  iconSize: IconSize = 'small';

  getObjectIcon = getObjectIcon;
}
