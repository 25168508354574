/* tslint:disable */
/* eslint-disable */

/**
 * Storage unit filler classification attribute
 */
export enum StorageUnitFillerClassificationAttribute {
  DISPOSAL_SCHEDULE = 'DISPOSAL_SCHEDULE',
  ENTITY_CLASS = 'ENTITY_CLASS',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  DISPOSAL_YEAR = 'DISPOSAL_YEAR',
  ORGANIZATIONAL_UNIT = 'ORGANIZATIONAL_UNIT',
  SECURITY_CATEGORY = 'SECURITY_CATEGORY',
  FORM = 'FORM',
  PHYSICAL_LOCATION = 'PHYSICAL_LOCATION'
}
