import {ColumnDefinition, isListColumnDefinition} from './table.models';
import {Subject} from 'rxjs';
import {IczOption} from '@icz/angular-form-elements';

/**
 * Class used for defining table schema and to carry out simple runtime operations related to table columns.
 */
export class TableColumnsData<T extends string = never> {

  /**
   * A shallow copy of column definitions, can be used to restore table column visibility back to default.
   */
  initialDefinitions: ColumnDefinition<T>[] = [];
  /**
   * Column definitions which are actively used and mutated by consumer components.
   */
  columnDefinitions: ColumnDefinition<T>[] = [];
  /**
   * IDs of columns which are displayed.
   */
  displayedColumns!: string[];
  /**
   * Holds states of list$ loadings for individual columns.
   * @see FilterWithList.list$
   */
  listLoadingStates: Record<string, boolean> = {};
  /**
   * Notifies consumers about list$ loading state changes.
   * @see FilterWithList.list$
   */
  listLoadingStatesChange$ = new Subject<Record<string, boolean>>();
  /**
   * Notifies consumers about deep object mutations made to individual column definitions.
   * Used mainly for handling changes after object mutations.
   */
  columnDefinitionsChange$ = new Subject<void>();

  constructor(definitions: ColumnDefinition<T>[]) {
    this.initialDefinitions = definitions.map(columnDef => ({
      ...columnDef,
      displayed: columnDef.displayed ?? true,
    }));
    this.reset();
    this.setDisplayedColumns();
  }

  /**
   * Sets column list after loading list$ successfully.
   * @see FilterWithList.list
   * @see FilterWithList.list$
   */
  setColumnList(columnId: T, list: IczOption[]) {
    const columnDefinition = this.getColumnById(columnId);

    if (isListColumnDefinition(columnDefinition)) {
      columnDefinition.list = list;
      this.columnDefinitionsChange$.next();
    }
  }

  /**
   * Sets column loading state. Used mainly in conjunction with list$.
   * @see FilterWithList.list$
   */
  setColumnLoading(columnId: T, loading: boolean) {
    this.listLoadingStates[columnId] = loading;
    this.listLoadingStatesChange$.next({...this.listLoadingStates});
  }

  /**
   * Gets column definition for a specific column.
   */
  getColumnById(columnId: T): Nullable<ColumnDefinition<T>> {
    return this.columnDefinitions.find(
      entry => entry.id === columnId || entry.filterConfig?.customFilterId === columnId
    );
  }

  private setDisplayedColumns() {
    this.displayedColumns = this.columnDefinitions
      .filter(definition => definition.displayed)
      .map(definition => definition.id);
  }

  private reset() {
    this.columnDefinitions = [...(this.initialDefinitions ?? [])]
      .map(definition => ({
        ...definition,
        label: definition.label || definition.id,
        displayed: isNil(definition.displayed) || definition.displayed === true,
      }));
  }

}
