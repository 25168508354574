import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {IczFormControl} from '@icz/angular-form-elements';
import {SettlementType} from '|api/commons';
import {DisposalScheduleComputationDto, DocumentDto} from '|api/document';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {VisibleClassifiersMode} from '../../../../abstract-classifier-selector.component';
import {DisposalTipDetailLevel} from '../../../../shared-business-components.model';
import {IczOption} from '@icz/angular-form-elements';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AbstractDocumentSettleFormComponent} from '../abstract-document-settle-form.component';
import {formatDisposalSchedule} from '../../../../shared-document.utils';

@Component({
	selector: 'icz-document-settle-general-setting',
	templateUrl: './document-settle-general-setting.component.html',
	styleUrls: ['./document-settle-general-setting.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentSettleGeneralSettingComponent extends AbstractDocumentSettleFormComponent implements OnInit, IczOnChanges {

	protected loadingService = inject(LoadingIndicatorService);

  @Input({required: true}) minSettlementDate!: Date;

  @Input({required: true}) documents!: DocumentDto[];
  @Input({required: true}) globalSettlementTypeOptions!: IczOption[];
  @Input() disposalSchedulePrepare: Nullable<DisposalScheduleComputationDto>;

	readonly userCommentNotice = 'Dokumenty obsahují {{userCommentCount}} uživatelské komentáře';

	document: Nullable<DocumentDto>;

	maxSettlementDate = new Date();

	private isDateValid(date: Date) {
		if (!(date && this.minSettlementDate)) return false;
    return !(date < this.minSettlementDate || date > this.maxSettlementDate);
	}
	documentFormOptions: IczOption[] = [];
  disposalScheduleOptions: IczOption[] = [];

	settlementDate!: Date;

	userCommentNoticeContext = {userCommentCount: 4};

	settlementDateValidator = {
		validationFn: (date: Date) => this.isDateValid(date),
		invalidDateMessage : { errorMessageTemplate: 'Zadejte datum mezi dneškem a nejpozdějším datem založení ze všech dokumentů'}
	};

	get documentSettlementCtrl() {
		return this.form.get('documentSettlementType') as IczFormControl;
	}

	get isResolutionByRecord() {
		return this.documentSettlementCtrl?.value === SettlementType.BY_RECORD;
	}

	readonly VisibleClassifiersMode = VisibleClassifiersMode;
	readonly DisposalTipDetailLevel = DisposalTipDetailLevel;

  override ngOnInit() {
    super.ngOnInit();
    this.form.get('entityClassId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) {
        const selectedEntityClass = this.entityClassDtos.find(ec => ec.id === value);
        const selectedClassificationScheme = this.classificationSchemes.find(scheme => scheme.id === selectedEntityClass!.classificationSchemeId);
        if (selectedClassificationScheme) {
          this.form.get('classificationSchemeName')!.setValue(selectedClassificationScheme.name);
          this.form.get('classificationSchemeValidFrom')!.setValue(selectedClassificationScheme.validFrom);
          this.form.get('classificationSchemeValidTo')!.setValue(selectedClassificationScheme.validTo);
        }
      }
      this.disposalScheduleSourceChange.emit();
    });
    this.form.get('documentTypeId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.disposalScheduleSourceChange.emit();
    });

    this.form.get('disposalScheduleId')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      const selectedDs = this.availableDisposalSchedules.find(ds => ds.disposalScheduleId === value)!;
      this.form.get('retentionTriggerTypeCode')!.setValue(selectedDs?.retentionTriggerTypeCode);
      this.form.get('disposalOperationCode')!.setValue(selectedDs.disposalOperationCode);
      this.form.get('retentionPeriod')!.setValue(selectedDs.retentionPeriod);
      this.form.get('triggerEventCheckYear')!.setValue((new Date()).getFullYear() + (selectedDs.defaultTriggerEventCheckYear ?? 0));
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.disposalSchedulePrepare && changes.disposalSchedulePrepare.currentValue) {
      if (changes.disposalSchedulePrepare.currentValue.availableDisposalSchedules) {
        this.availableDisposalSchedules = changes.disposalSchedulePrepare.currentValue.availableDisposalSchedules;
        this.disposalScheduleOptions = changes.disposalSchedulePrepare.currentValue.availableDisposalSchedules.map(ds => ({value: ds.disposalScheduleId, label: formatDisposalSchedule(ds)}));
        if (changes.disposalSchedulePrepare.currentValue.recommendedDisposalScheduleId) {
          this.form.get('disposalScheduleId')!.setValue(changes.disposalSchedulePrepare.currentValue.recommendedDisposalScheduleId);
        }
        if (changes.disposalSchedulePrepare.currentValue.defaultYearOfRetentionPeriodStart) {
          this.form.get('yearOfRetentionPeriodStart')!.setValue(changes.disposalSchedulePrepare.currentValue.defaultYearOfRetentionPeriodStart);
        }
        this.form.get('externalRetentionTriggerIds')!.setValue(changes.disposalSchedulePrepare.currentValue.externalRetentionTriggerIds);
      }
    }
  }

}
