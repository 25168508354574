import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FilterItem} from '../../filter.types';
import {sub} from 'date-fns';
import {LocalizedDatePipe} from '@icz/angular-essentials';
import {FilterValueFormatter} from '../../table.providers';
import {
  DatePickerShortcut,
  RELATIVE_INTERVAL_SHORTCUTS
} from './basic-filters/date-picker-shortcuts/date-picker-shortcuts.component';
import {isValidIczDurationString} from '../../table.utils';
import {TranslateService} from '@ngx-translate/core';

/**
 * @internal
 */
@Injectable()
export class DateFilterValueFormatterService implements FilterValueFormatter {

  private localizedDatePipe = inject(LocalizedDatePipe);
  private translateService = inject(TranslateService);

  format(filterItem: FilterItem): Observable<string> {
    if (isValidIczDurationString(filterItem.value)) {
      const associatedShortcut = RELATIVE_INTERVAL_SHORTCUTS.find(s => s.data === filterItem.value);

      if (associatedShortcut) {
        return of(this.translateService.instant(associatedShortcut.label));
      }
      else {
        const xDaysAgoRe = /^P(\d+)D$/;

        if (xDaysAgoRe.test(filterItem.value as string)) {
          const days = Number(xDaysAgoRe.exec(filterItem.value as string)![1]);
          const intervalLabel = RELATIVE_INTERVAL_SHORTCUTS.find(s => s.value === DatePickerShortcut.LAST_X_DAYS)!.label;

          return of(this.translateService.instant(intervalLabel).replace('X', String(days)));
        }
        else {
          return of('Flexibilní interval');
        }
      }
    }
    else if (filterItem.subValues?.length === 2) {
      const intervalBounds = [...filterItem.subValues];
      intervalBounds.sort(
        // ISO 8601 strings are lexicographically comparable thus no need to convert them to unix timestamps
        (b1, b2) => b1.value! > b2.value! ? 1 : -1
      );

      const lowerBoundValue = intervalBounds[0].value;
      const upperBoundValue = sub(new Date(intervalBounds[1].value!), {days: 1}); // needed for correctly formatting upper bound

      return of(`${this.localizedDatePipe.transform(lowerBoundValue)} - ${this.localizedDatePipe.transform(upperBoundValue)}`);
    }
    else {
      return of(this.localizedDatePipe.transform(filterItem.value as Nullable<string>));
    }
  }

}
