<div class="row" [class.buttons-collapsed]="responsivityService.isSmallDesktop$ | async">
  <div #saveFilterOrigin="cdkOverlayOrigin" cdkOverlayOrigin>
    <icz-button noBorder svgIcon="save_filter" originalIconColor
      [background]="isSaveFilterPopupOpen ? '#fff' : 'transparent'"
      label="Uložit filtr" [disabled]="areFiltersEmpty"
      [isCollapsed]="responsivityService.isMidDesktop$ | async" size="large"
      [iczTooltip]="areFiltersEmpty ? 'Nebyla zadána žádná filtrovací kritéria k uložení.' : ''"
      [iczTooltipShowDelay]="DISABLED_ELEMENT_TOOLTIP_DELAY"
      (onAction)="isSaveFilterPopupOpen = true; useDefaultFilterName()"
    ></icz-button>

    <icz-popover [overlayOrigin]="saveFilterOrigin" [isOpen]="isSaveFilterPopupOpen" (onClose)="isSaveFilterPopupOpen = false;">
      <div class="save-filter-header" popoverHeader>
        {{'Uložit filtr' | translate}}
        <icz-button svgIcon="close" size="small" background="transparent" disableRipple
          (onAction)="this.closeSaveFilterDialog()">
        </icz-button>
      </div>

      @if (isSaveFilterPopupOpen) {
        <div
          iczForm [formGroup]="form"
          class="save-filter"
          (keydown.enter)="saveFilter()"
          [waiting]="loadingService.isLoading$(this) | async"
        >
          <icz-form-field iczAutoFocus
            label="Název filtru"
            formControlName="filterName"
            [autoSizeMax]="5"
          ></icz-form-field>
          <div class="row gap-16 items-center">
            <icz-button
              iczFormSubmit class="save-filter-button" label="Uložit filtr"
              primary [disabled]="!isFilterSaveDialogValid"
              [iczTooltip]="saveButtonTooltip" (onAction)="saveFilter()"
            ></icz-button>
            <icz-checkbox
              formControlName="setNewFilterAsDefault"
              class="mt-4" label="Nastavit jako výchozí"
            ></icz-checkbox>
          </div>
        </div>
      }
    </icz-popover>
  </div>
  <div #savedFiltersOrigin="cdkOverlayOrigin" cdkOverlayOrigin>
    @if (savedFilterOptions.length) {
      <icz-button
        noBorder svgIcon="saved_filter" size="large" class="mr-8"
        [background]="isSavedFiltersPopupOpen ? '#fff' : 'transparent'"
        label="Moje uložené" [isCollapsed]="responsivityService.isMidDesktop$ | async"
        (onAction)="isSavedFiltersPopupOpen = true;"
      ></icz-button>
    }

    <icz-popover [overlayOrigin]="savedFiltersOrigin" [isOpen]="isSavedFiltersPopupOpen" (onClose)="isSavedFiltersPopupOpen = false;">
      <div class="save-filter-header">
        {{'Uložené filtry' | translate}}
        <icz-button svgIcon="close" size="small" background="transparent"
          (onAction)="this.closeSavedFiltersDialog()">
        </icz-button>
      </div>

      <div [formGroup]="form" class="save-filter">
        <icz-options-list [options]="savedFilterOptions"
          [listItemTemplate]="optionTemplate"
          (selectionChanged)="restoreSavedFilter($event)">
        </icz-options-list>
        <ng-template #optionTemplate let-context>
          <div class="row grow items-center">
            <div class="col grow">
              <span class="option-text truncate">
                {{context.option.label}}
              </span>
            </div>
            <icz-checkbox label="výchozí filtr" (valueChange)="defaultFilterChanged($event, context.option.value)" [value]="getDefaultValue(context.option.value)"></icz-checkbox>
            <icz-button svgIcon="delete" background="transparent" size="small"
              (onAction)="$event.stopPropagation(); savedFilterDeleted(context.option.value)">
            </icz-button>
          </div>
        </ng-template>
      </div>
    </icz-popover>
  </div>
</div>
