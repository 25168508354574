import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {TooltipDirective} from '@icz/angular-essentials';
import {
  FormAutocompleteChipComponent
} from '../form-autocomplete/form-autocomplete-chip/form-autocomplete-chip.component';
import {FindInListPipe} from '../pipes/find-in-list.pipe';
import {IczOption} from '../form-elements.model';

/**
 * A special field which looks like a disabled field but is not connected to
 * reactive form models. Accepts its value only using data binding.
 *
 * Used in cases where there is lots of always disabled fields and we want to rapidly render lots of fields at once to optimize performance.
 */
@Component({
  selector: 'icz-readonly-field',
  templateUrl: './readonly-field.component.html',
  styleUrls: ['./readonly-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class': 'icz-form-field'
  },
  standalone: true,
  imports: [
    TranslateModule,
    TooltipDirective,
    FormAutocompleteChipComponent,
    FindInListPipe,
  ]
})
export class ReadonlyFieldComponent {

  /**
   * Field placeholder if empty.
   */
  @Input()
  placeholder = '';
  /**
   * Field label.
   */
  @Input()
  label = '';
  /**
   * Fiela value. If the value is an array type, option lookup against options will be performed.
   */
  @Input({required: true})
  value: Nullable<string | number | string[] | number[]> = null;
  /**
   * Options for lookup if the value is an array.
   */
  @Input()
  options: Nullable<IczOption[]> = [];
  /**
   * Makes the input white instead of light gray.
   */
  @Input()
  withWhiteBackground = false;

  protected get isValueArray() {
    return Array.isArray(this.value);
  }

}
