import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {AuthService, LogoutType} from '../../../core/authentication/auth.service';
import {CurrentSessionService} from '../../../services/current-session.service';
import {getUserFullName, getUserInitials} from '../../../model';
import {filter, map, switchMap} from 'rxjs/operators';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {Router} from '@angular/router';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {RouteRestorationService} from '../../../services/route-restoration.service';
import {IczModalService} from '@icz/angular-modal';
import {AppAboutDialogComponent} from '../app-about-dialog/app-about-dialog.component';
import {of} from 'rxjs';
import {OrganizationalStructureService,} from '../../../core/services/organizational-structure.service';

export function getSubstitutedFpHolderObs(
  currentSessionService: CurrentSessionService,
  orgStructureService: OrganizationalStructureService
) {
  return currentSessionService.currentUserFunctionalPosition$.pipe(
    filter(Boolean),
    switchMap(fp => {
      if (fp.substituted) {
        return orgStructureService.organizationalStructure().pipe(
          map(orgStruct => {
            return orgStruct.getRepresentingUserByFp(fp);
          })
        );
      } else {
        return of(null);
      }
    }),
  );
}

@Component({
  selector: 'icz-user-settings-popup',
  templateUrl: './user-settings-popup.component.html',
  styleUrls: ['./user-settings-popup.component.scss'],
})
export class UserSettingsPopupComponent implements OnInit {

  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private authService = inject(AuthService);
  private currentSessionService = inject(CurrentSessionService);
  private routeRestorationService = inject(RouteRestorationService);
  private modalService = inject(IczModalService);
  private orgStructureService = inject(OrganizationalStructureService);

  readonly getUserInitials = getUserInitials;
  readonly getUserFullName = getUserFullName;

  userInitials$ = this.currentSessionService.currentUser$.pipe(
    filter(Boolean),
    map(user => getUserInitials(user)),
  );
  userFullName$ = this.currentSessionService.currentUser$.pipe(
    filter(Boolean),
    map(user => getUserFullName(user)),
  );
  functionalPositionName$ = this.currentSessionService.currentUserFunctionalPosition$.pipe(
    filter(Boolean),
    map(functionalPosition => functionalPosition.name),
  );
  organizationName$ = this.currentSessionService.currentOrganization$.pipe(
    filter(Boolean),
    map(organization => organization.name),
  );

  functionalPositionSubstituted$ = this.currentSessionService.currentUserFunctionalPosition$.pipe(
    filter(Boolean),
    map(fp => fp.substituted),
  );

  substitutedFPHolder$ = getSubstitutedFpHolderObs(this.currentSessionService, this.orgStructureService);

  showSelectPlace = true;

  ngOnInit() {
    this.currentSessionService.getAvailableFunctionalPlacesWithSubstitutions().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(places => {
      this.showSelectPlace = places.length > 1;
    });
  }

  // TODO get the real sponsors
  get hasSponsors() {
    return false;
  }

  logout() {
    this.authService.logOut(LogoutType.USER_INITIATED);
  }

  selectPlace() {
    this.routeRestorationService.storeUrlBeforeLogin(this.router.url);
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.PLACE_SELECT));
  }

  aboutAppClick() {
    this.modalService.openComponentInModal<void, void>({
      component: AppAboutDialogComponent,
      modalOptions: {
        width: 500,
        height: 350,
        titleTemplate: 'O aplikaci',
        disableButtonBar: true
      }
    });
  }

}
