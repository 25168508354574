import {inject, Injectable} from '@angular/core';
import {cloneDeep} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import {
  dehydrateFilterItemValue,
  dehydrateFilterItemValueTree,
  FilterItemValueTree,
  isFilterTree
} from '../filter-trees.utils';
import {FilterItemValue} from '../filter.types';

import {FilterOperator} from '../table.utils';
import {AllSavedFilters, SAVED_FILTERS_PERSISTOR} from '../table.providers';

/**
 * @internal
 */
export const FULLTEXT_SEARCH_TERM_FILTER_ID = '__FullTextSearchTerm';

/**
 * @internal
 */
@Injectable({
  providedIn: 'root'
})
export class SavedFiltersService {

  private savedFiltersPersistor = inject(SAVED_FILTERS_PERSISTOR);

  private _savedFilters$ = new BehaviorSubject<AllSavedFilters>({});
  savedFilters$ = this._savedFilters$.asObservable();

  get savedFilters(): AllSavedFilters {
    return this._savedFilters$.value;
  }

  constructor() {
    this.initialize();
  }

  saveFilter(serializationScope: string, filterName: string, filterItems: Nullable<FilterItemValueTree>, fulltextSearchTerm: Nullable<string>) {
    if (this.savedFilters.hasOwnProperty(filterName)) {
      throw new Error(`Saved filters already contain a filter named "${filterName}" in scope "${serializationScope}".`);
    }

    const savedFiltersClone = cloneDeep(this.savedFilters);

    if (!savedFiltersClone[serializationScope]) {
      savedFiltersClone[serializationScope] = {};
    }

    savedFiltersClone[serializationScope][filterName] = {isDefault: false, ...filterItems!};

    const filterValues = savedFiltersClone[serializationScope][filterName].values;

    for (let i = 0; i < filterValues.length; ++i) {
      const isTree = isFilterTree<FilterItemValueTree>(filterValues[i]);

      if (isTree) {
        filterValues[i] = dehydrateFilterItemValueTree(filterValues[i] as FilterItemValueTree);
      }
      else {
        filterValues[i] = dehydrateFilterItemValue(filterValues[i] as FilterItemValue);
      }
    }

    if (fulltextSearchTerm) {
      filterValues.push(new FilterItemValue(
        FULLTEXT_SEARCH_TERM_FILTER_ID,
        FilterOperator.contains,
        fulltextSearchTerm,
      ));
    }

    this._savedFilters$.next(savedFiltersClone);
    this.serializeAvailableSavedFilters();
  }

  updateFilterDefaultState(serializationScope: string, value: boolean, filterName: string) {
    if (!this.savedFilters.hasOwnProperty(serializationScope)) {
      throw new Error(`Saved filters does not contain a filters in scope "${serializationScope}".`);
    }

    const savedFiltersClone = cloneDeep(this.savedFilters);
    const savedScopedFiltersClone = savedFiltersClone[serializationScope];

    Object.keys(savedScopedFiltersClone).forEach(k => {
      const filterValue = savedScopedFiltersClone[k];
      if (k === filterName) {
        filterValue.isDefault = value;
      } else {
        filterValue.isDefault = false;
      }
    });

    this._savedFilters$.next(savedFiltersClone);
    this.serializeAvailableSavedFilters();
  }

  deleteFilter(serializationScope: string, filterName: string) {
    const savedFiltersClone = cloneDeep(this.savedFilters);
    delete savedFiltersClone[serializationScope][filterName];

    this._savedFilters$.next(savedFiltersClone);
    this.serializeAvailableSavedFilters();
  }

  initialize() {
    this._savedFilters$.next(this.savedFiltersPersistor.getSavedFilters() ?? {});
  }

  private serializeAvailableSavedFilters() {
    this.savedFiltersPersistor.saveSavedFilters(this.savedFilters);
  }

}
