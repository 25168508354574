import {ChartElementColor} from './chart-utils';
import {Statistic, StatisticsDimension} from '|api/elastic';
import {IczOption} from '@icz/angular-form-elements';


export interface GenericChartDataset {
  label: string,
  data: Array<number>,
  borderWidth?: number,
  barPercentage?: number,
  backgroundColor?: Array<ChartElementColor>,
  borderColor?: Array<ChartElementColor>,
  hoverOffset?: number,
}

export interface GenericChartConfig {
  labels: Array<string>,
  datasets: Array<GenericChartDataset>
}

export interface LineChartPoint {
  x: string;
  y: number;
}

export interface LineChartDataset {
  label: string,
  data: Array<number>,
  fullDataSet: Array<LineChartPoint>,
  fill?: boolean,
  borderColor?: ChartElementColor,
  backgroundColor?: ChartElementColor,
  tension?: number
}

export interface LineChartConfig {
  xAxisRange: [string, string], // [ISODate, ISODate]
  datasets: Array<LineChartDataset>,
}

export enum TrendPeriod {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
}

export enum IczStatistic {
  NEW_DOCUMENT_COUNT = 'NEW_DOCUMENT_COUNT',
  DOCUMENT_COUNT = 'DOCUMENT_COUNT',
  NEW_FILE_COUNT = 'NEW_FILE_COUNT',
  FILE_COUNT = 'FILE_COUNT',
  FILE_IN_STORING_UNIT_COUNT = 'FILE_IN_STORING_UNIT_COUNT',
  NEW_COMPONENT_COUNT = 'NEW_COMPONENT_COUNT',
  COMPONENT_COUNT = 'COMPONENT_COUNT',
  DOCUMENT_SIZE = 'DOCUMENT_SIZE',
  FILE_SIZE = 'FILE_SIZE',
  FILE_BY_PERMISSION_COUNT = 'FILE_BY_PERMISSION_COUNT',
  DOCUMENT_BY_PERMISSION_COUNT = 'DOCUMENT_BY_PERMISSION_COUNT',
  NEW_ENTITY_CLASSES_CURRENT_CS = 'NEW_ENTITY_CLASSES_CURRENT_CS',
  FILES_TRANSFERRED_TO_REGISTRY_OFFICE = 'FILES_TRANSFERRED_TO_REGISTRY_OFFICE',
}

export enum StatisticFormatter {
  FILESIZE = 'FILESIZE',
}

export enum StatisticTrendCounterMode {
  LINK = 'LINK',
  COUNT = 'COUNT',
  GRAPH = 'GRAPH',
  TABLE = 'TABLE'
}

export interface AggregatedCounterData {
  label: string;
  value: number;
}

export interface TrendWidgetData {
  total: number;
  trend: number;
  aggregatedData?: AggregatedCounterData[];
}

export interface TrendCounterDefinition {
  id: IczStatistic;
  label: string;
  isTimeSpecific: boolean;
  tooltip: string;
  statisticKey: Statistic;
  mode?: StatisticTrendCounterMode;
  isDetailTestingFeature?: boolean;
  order: number;
  columnIndex: number;
  defaultDimension: StatisticsDimension;
}

export interface TrendCounterStoredSettings {
  id: IczStatistic;
  period: TrendPeriod;
  displayed: boolean;
  order: number;
  columnIndex: number;
}

export interface StatisticConfig {
  id: IczStatistic;
  period: TrendPeriod;
  statisticKey: Statistic;
  label: string;
  displayed: boolean;
  order: number;
  dimensions: Array<StatisticsDimension>;
  defaultDimension: StatisticsDimension;
  isTimeSpecific: boolean;
  tooltip?: string;
  allowBarChart: boolean;
  allowLineChart: boolean;
  allowDonutChart: boolean;
  barConfig?: GenericChartConfig;
  donutConfig?: GenericChartConfig;
  lineConfig?: LineChartConfig;
  formatter?: StatisticFormatter;
  mode?: StatisticTrendCounterMode;
  isDetailTestingFeature?: boolean;
}

export const periodOptions: IczOption[]  = [
  {value: TrendPeriod.LAST_7_DAYS, label: 'Za posledních 7 dní'},
  {value: TrendPeriod.LAST_30_DAYS, label: 'Za posledních 30 dní'},
];

const documentAndFileDimensions = [
  StatisticsDimension.OBJECT_CLASS,
  StatisticsDimension.ENTITY_STATE,
  StatisticsDimension.ENTITY_CLASS,
  StatisticsDimension.DISPOSAL_SCHEDULE,
  StatisticsDimension.OWNER_FP,
  StatisticsDimension.OWNER_ORG_UNIT,
  StatisticsDimension.SECURITY_CATEGORY,
  StatisticsDimension.STORAGE_UNIT,
  StatisticsDimension.BUSINESS_RULE
];

const documentDimensions = [
    ...documentAndFileDimensions,
  StatisticsDimension.DOCUMENT_TYPE,
];

const fileDimensions = [
  ...documentAndFileDimensions,
  StatisticsDimension.FILE_TYPE,
];

const componentDimensions = [
  StatisticsDimension.PUID,
  // todo add StatisticsDimension.SIZE,
];

export const statisticToAllowedDimensionsMapping: Record<IczStatistic, StatisticsDimension[]> = {
  [IczStatistic.NEW_DOCUMENT_COUNT]: documentDimensions,
  [IczStatistic.DOCUMENT_COUNT]: documentDimensions,
  [IczStatistic.NEW_FILE_COUNT]: fileDimensions,
  [IczStatistic.FILE_COUNT]: fileDimensions,
  [IczStatistic.FILE_IN_STORING_UNIT_COUNT]: fileDimensions,
  [IczStatistic.NEW_COMPONENT_COUNT]: componentDimensions,
  [IczStatistic.COMPONENT_COUNT]: componentDimensions,
  [IczStatistic.DOCUMENT_SIZE]: documentDimensions,
  [IczStatistic.FILE_SIZE]: fileDimensions,
  [IczStatistic.DOCUMENT_BY_PERMISSION_COUNT]: documentDimensions,
  [IczStatistic.FILE_BY_PERMISSION_COUNT]: fileDimensions,
  [IczStatistic.FILES_TRANSFERRED_TO_REGISTRY_OFFICE]: [
    StatisticsDimension.ENTITY_STATE,
    StatisticsDimension.HANDED_OVER_BY_FP,
    StatisticsDimension.HANDED_OVER_BY_ORG_UNIT,
    StatisticsDimension.REGISTRY_OFFICE,
  ],
  [IczStatistic.NEW_ENTITY_CLASSES_CURRENT_CS]: [
    StatisticsDimension.ENTITY_CLASS_TYPE
  ],
};

export const barChartStatistics = [
  IczStatistic.DOCUMENT_COUNT,
  IczStatistic.FILE_COUNT,
  IczStatistic.COMPONENT_COUNT,
  IczStatistic.DOCUMENT_SIZE,
  IczStatistic.FILE_SIZE,
  IczStatistic.FILE_IN_STORING_UNIT_COUNT,
  IczStatistic.DOCUMENT_BY_PERMISSION_COUNT,
  IczStatistic.FILE_BY_PERMISSION_COUNT,
];


