<div [formGroup]="specificFieldsForm" [waiting]="(loadingService.isLoading$(this) | async) || distributionNodesLoading">
  <div class="row gap-48">
    <icz-section class="grow" label="Obsah datové zprávy">
      <icz-form-field formControlName="subject" label="Předmět zprávy"></icz-form-field>
      <icz-form-field formControlName="body" label="Tělo zprávy" [autoSizeMin]="10" [autoSizeMax]="10"></icz-form-field>
    </icz-section>
    <div class="grow">
      <icz-section label="Údaje adresáta" class="block mb-16">
        @if (consignee) {
          <span class="icz-body-1">{{ consignee | subjectRecord }}</span>
        }
      </icz-section>

      @if (deliveryFilingType === ConsignmentDeliveryFillingType.DELIVERY_FILLING_OFFICE) {
        <icz-applicable-outcome-distribution-node-selector
          [form]="specificFieldsForm"
          controlName="dispatchOfficeDistributionNodeId"
          [deliveryTypeId]="deliveryTypeId"
          (loadingStatusChanged)="distributionNodesLoading = $event"
        ></icz-applicable-outcome-distribution-node-selector>
      }

      <icz-section label="Způsob vypravení">
        <icz-form-autocomplete formControlName="consigneeEmail" label="E-mailová adresa" [options]="emailAddressOptions"></icz-form-autocomplete>
        <icz-button
          [hidden]="consignment && consignment.status !== OwnConsignmentStatus.TO_HANDOVER"
          label="Přidat e-mailovou adresu"
          (onAction)="addSubjectEmailAddress()"
        ></icz-button>
      </icz-section>

      <icz-section label="Poznámka">
        <icz-form-field
          formControlName="note"
          label="Poznámka k zásilce"
          [autoSizeMax]="5"
        ></icz-form-field>
      </icz-section>
    </div>
  </div>
</div>
