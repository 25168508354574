import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {EntityType} from '|api/commons';
import {ApiComponentService, ApiDigitalComponentService} from '|api/component';
import {
  ApiAuthorizationService,
  AuthorizedEntityType,
  ComponentAuthorizationResult,
  ComponentAuthorizedOperation,
} from '|api/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  EsslComponentViewerDialogComponent,
} from '../components/shared-business-components/essl-component-viewer/essl-component-viewer-dialog.component';
import {getLastDigitalComponentVersionId} from '../components/shared-business-components/shared-document.utils';
import {EsslComponentDto} from './essl-component-search.service';
import {IczModalService} from '@icz/angular-modal';

export interface EsslComponentViewerModalData {
  esslComponent: EsslComponentDto;
  authorizationResult: ComponentAuthorizationResult;
  // either versionId or versionNumber should be set
  //  to successfully resolve the detail and its data
  versionId?: Nullable<number>;
  versionNumber?: number;
  showParentDocumentInfo?: boolean;
  isReadonly?: boolean;
  hideComponentContent?: boolean;
  consignmentId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class EsslComponentModalService {

  private modalService = inject(IczModalService);
  private loadingService = inject(LoadingIndicatorService);
  private apiComponentService = inject(ApiComponentService);
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private apiAuthorizationService = inject(ApiAuthorizationService);

  openEsslComponentDetailWithPermissionFetch(
    component: EsslComponentDto,
    componentVersionId: Nullable<number>,
    documentId: number,
    isReadonly: boolean,
    showParentDocumentInfo?: boolean,
    hideComponentContent?: boolean,
    consignmentId?: number,
  ) {
    return this.loadingService.doLoading(
      this.apiAuthorizationService.authorizationAuthorizeComponentOperations({
        body: {
          authorizedEntityId: component.id!,
          authorizedEntityType: AuthorizedEntityType.COMPONENT,
          operationsToAuthorize: [
            ComponentAuthorizedOperation.COMPONENT_MODIFY_PROFILE,
            ComponentAuthorizedOperation.COMPONENT_SHOW_CONTENT,
          ],
        },
      }),
      this,
      component.id!,
    ).pipe(
      switchMap(authorizationResult =>
        this.openEsslComponentDetail({
          esslComponent: component,
          versionId: componentVersionId,
          authorizationResult,
          isReadonly,
          showParentDocumentInfo,
          hideComponentContent,
          consignmentId,
        })
      )
    );
  }

  openEsslComponentDetailWithFullFetch(
    componentId: number,
    componentVersionId: Nullable<number>,
    documentId: number,
    isReadonly: boolean,
    showParentDocumentInfo?: boolean,
  ) {
    let component$: Observable<EsslComponentDto>;

    if (componentVersionId) {
      component$ = this.apiDigitalComponentService.digitalComponentFindById({
        id: componentId,
      });
    }
    else {
      component$ = this.apiComponentService.componentGetById({
        id: componentId,
      }).pipe(
        switchMap(component => {
          if (component.entityType === EntityType.DIGITAL_COMPONENT) {
            return this.apiDigitalComponentService.digitalComponentFindById({
              id: componentId,
            }).pipe(
              tap(digitalComponent => {
                componentVersionId = getLastDigitalComponentVersionId(digitalComponent);
              }),
            );
          }
          else {
            return of(component);
          }
        })
      ) as Observable<EsslComponentDto>;
    }

    return this.loadingService.doLoading(
      component$,
      this,
      componentId,
    ).pipe(
      switchMap(esslComponent => this.openEsslComponentDetailWithPermissionFetch(
        esslComponent as EsslComponentDto,
        componentVersionId,
        documentId,
        isReadonly,
        showParentDocumentInfo
      )),
    );
  }

  /**
   * @returns Observable signifying whether component metadata got changed inside the modal
   */
  private openEsslComponentDetail(viewerData: EsslComponentViewerModalData): Observable<boolean> {
    return this.modalService.openComponentInModal<boolean, EsslComponentViewerModalData>({
      component: EsslComponentViewerDialogComponent,
      modalOptions: {
        width: 1200,
        height: '90vh',
        useCustomHeader: true,
        disableAutoMargin: true,
      },
      data: viewerData,
    });
  }

}
