import {NgModule} from '@angular/core';
import {ButtonCollectionComponent} from './button-collection.component';
import {EssentialsModule} from '@icz/angular-essentials';
import {LibraryImportModule} from '../.module-library-import/library-import.module';

@NgModule({
  declarations: [
    ButtonCollectionComponent,
  ],
  exports: [
    ButtonCollectionComponent,
  ],
  imports: [
    EssentialsModule,
    LibraryImportModule,
  ]
})
export class ButtonCollectionModule {}
