import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {hashed} from '../essentials.utils';
import {MatExpansionPanel, MatExpansionPanelHeader} from '@angular/material/expansion';
import {TranslateModule} from '@ngx-translate/core';
import {SECTION_SETTINGS_PERSISTOR} from '../essentials.providers';
import {IczOnChanges, IczSimpleChanges} from '../icz-on-changes';
import {IconComponent, IconSize} from '../icon/icon.component';

/**
 * Expandable element which is used for grouping complex screens into logically
 *   separate parts. Section content is passed to the component using ng-content.
 */
@Component({
  selector: 'icz-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatExpansionPanel, MatExpansionPanelHeader, TranslateModule, IconComponent],
})
export class SectionComponent implements IczOnChanges {

  private persistor = inject(SECTION_SETTINGS_PERSISTOR);
  private elRef = inject(ElementRef);

  @ViewChild(MatExpansionPanel)
  private expansionPanel!: MatExpansionPanel;

  /**
   * Section label, shoule describe characteristics of section contents.
   */
  @Input({required: true})
  label!: string;
  /**
   * Optional icon displayed to the left of section label.
   */
  @Input()
  svgIcon: Nullable<string>;
  /**
   * Icon size.
   */
  @Input()
  svgIconSize: Nullable<IconSize> = 'default';
  /**
   * If TRUE, the section will display expansion hint "Expand section"/
   * "Collapse section" in the right part of its header.
   */
  @Input()
  showExpansionHint = false;
  /**
   * Initial section expansion state. Changing this property
   * can also programmatically open/close the section.
   */
  @Input()
  expanded = true;
  /**
   * Section height limit.
   * Accepts a string with CSS height units (eg. '250px') or 'auto'.
   * If 'auto', section content won't display y scrollbar, else it will.
   */
  @Input()
  maxContentHeight: Nullable<string> = 'auto';
  /**
   * Section header height. Default is usually fine unless you want
   * to render sections which are visually more significant.
   */
  @Input()
  headerHeight = 40; // px, default ~ $form-vertical-modulus from SCSS

  private _sectionId: Nullable<string>;

  /**
   * If TRUE, the section will store its state using SectionSettingsPersistor.
   * Sometimes it is undesirable to store the state, for example in forms where
   * section visibility is scripted under certain conditions.
   *
   * @see SectionSettingsPersistor
   */
  @Input()
  storeClosedState = true;

  /**
   * If TRUE, the section will have white background, shadow and bold header text.
   */
  @HostBinding('class.boxed')
  @Input()
  set boxed(value: boolean | '') { this._boxed = value === '' ? true : value; }
  get boxed() { return this._boxed; }
  private _boxed = false;

  /**
   * If TRUE, no padding will be applied to section contents.
   */
  @HostBinding('class.no-padding')
  @Input()
  set noPadding(value: boolean | '') { this._noPadding = value === '' ? true : value; }
  get noPadding() { return this._noPadding; }
  private _noPadding = false;

  /**
   * If TRUE, no padding will be applied to section contents.
   */
  @HostBinding('class.no-header-padding')
  @Input()
  set noHeaderPadding(value: boolean | '') { this._noHeaderPadding = value === '' ? true : value; }
  get noHeaderPadding() { return this._noHeaderPadding; }
  private _noHeaderPadding = false;

  /**
   * If TRUE section header will be disabled to clicking and the section will be merely visual.
   * @param value
   */
  @Input()
  set alwaysOpened(value: boolean | '') { this._alwaysOpened = value === '' ? true : value; }
  get alwaysOpened() { return this._alwaysOpened; }
  private _alwaysOpened = false;

  /**
   * Emits new section state right after the section has been opened/closed.
   */
  @Output()
  expansionStateChanged = new EventEmitter<boolean>();

  private _openCalled = false;
  private _closeCalled = false;

  /**
   * Programmatically opens the section.
   */
  open() {
    this.expansionPanel.open();
    this.onOpened();
    this.sectionHeaderClicked();
  }

  /**
   * Programmatically closes the section.
   */
  close() {
    this.expansionPanel.close();
    this.onClosed();
    this.sectionHeaderClicked();
  }

  protected onOpened() {
    this._openCalled = true;
    this.expanded = true;
    this.expansionStateChanged.emit(this.expanded);

    setTimeout(() => {
      this._openCalled = false;
    });
  }

  protected onClosed() {
    this._closeCalled = true;
    this.expanded = false;
    this.expansionStateChanged.emit(this.expanded);

    setTimeout(() => {
      this._closeCalled = false;
    });
  }

  protected sectionHeaderClicked() {
    if (this._closeCalled) {
      this._closeCalled = false;
      if (this._sectionId) {
        this.persistor.setSectionClosedState(this._sectionId, true);
      }
    }

    if (this._openCalled) {
      this._openCalled = false;
      if (this._sectionId) {
        this.persistor.setSectionClosedState(this._sectionId, false);
      }
    }
  }

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.label && changes.label.currentValue && this.storeClosedState) {
      const path: string[] = [changes.label.currentValue];
      this.createPathId(this.elRef.nativeElement, path);
      this._sectionId = hashed({sectionId: path.join('_')});
      const closedState = this.persistor.getSectionClosedState(this._sectionId);
      if (!isNil(closedState)) {
        this.expanded = !closedState;
      }
    }
  }

  private createPathId(el: any, path: string[]) {
    if ( (el.nodeName as string).startsWith('ICZ') ) {
      path.push(el.nodeName);
    }

    if (el.parentElement !== null && el.parentElement.nodeName !== 'BODY') {
      this.createPathId(el.parentElement, path);
    }
  }

}
