import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EditSubjectDialogComponent} from './edit-subject/edit-subject-dialog.component';
import {SubjectTechnicalInfoComponent} from './edit-subject/subject-technical-info/subject-technical-info.component';
import {SelectSubjectDialogComponent} from './select-subject-dialog/select-subject-dialog.component';
import {
  SubjectCreateAddressesComponent
} from './subject-create-form/subject-create-addresses/subject-create-addresses.component';
import {
  SubjectCreateContactsComponent
} from './subject-create-form/subject-create-contacts/subject-create-contacts.component';
import {SubjectCreateFormComponent} from './subject-create-form/subject-create-form.component';
import {
  SubjectUsageReportConfigurationComponent
} from './subject-usage-report-configuration/subject-usage-report-configuration.component';
import {SubjectRecordPipe} from './subject-record.pipe';
import {AddressTypeLabelPipe} from './address-type-label.pipe';
import {AddSubjectDialogComponent} from './add-subject/add-subject-dialog.component';
import {
  AddSubjectStepRelationComponent
} from './add-subject/add-subject-step-relation/add-subject-step-relation.component';
import {AddSubjectStepCreateComponent} from './add-subject/add-subject-step-create/add-subject-step-create.component';
import {SubjectDetailCardComponent} from './subject-detail-card/subject-detail-card.component';
import {
  SubjectMultipleValuesAttributeComponent
} from './subject-multiple-values-attribute/subject-multiple-values-attribute.component';
import {SubjectNamePipe} from './subject-name.pipe';
import {EssentialsModule} from '@icz/angular-essentials';
import {FormElementsModule} from '@icz/angular-form-elements';
import {ModalsModule} from '@icz/angular-modal';
import {TableModule} from '@icz/angular-table';
import {
  SubjectSearchWithResultsComponent
} from './add-subject/subject-search-with-results/subject-search-with-results.component';
import {SubjectSearchFormComponent} from './subject-search-form/subject-search-form.component';
import {SubjectsTableComponent} from './subjects-table/subjects-table/subjects-table.component';
import {SubjectsToolbarComponent} from './subjects-toolbar/subjects-toolbar.component';
import {SubjectSearchAppendixComponent} from './subject-search-appendix/subject-search-appendix.component';
import {
  SubjectIdentificationStatusIconComponent
} from './subject-identification-status/subject-identification-status-icon.component';
import {SubjectAddressSelectorComponent} from './subject-address-selector/subject-address-selector.component';
import {SubjectAddressListComponent} from './subject-address-list/subject-address-list.component';
import {
  SubjectsTableGenericActionsComponent
} from './subjects-table/subjects-table-generic-actions/subjects-table-generic-actions.component';
import {
  SubjectsTableConsignmentActionsComponent
} from './subjects-table/subjects-table-consignment-actions/subjects-table-consignment-actions.component';
import {AddressComponent} from './address/address.component';
import {AddressFulltextSelectorComponent} from './address-fulltext-selector/address-fulltext-selector.component';
import {SubjectUsageReportComponent} from './subject-usage-report/subject-usage-report.component';
import {IszrQueryDialogComponent} from './iszr-query-dialog/iszr-query-dialog.component';
import {
  SubjectIdentifierInputFormFragmentComponent
} from './subject-identifier-input-form-fragment/subject-identifier-input-form-fragment.component';
import {TransactionsModule} from '../transactions/transactions.module';
import {SubjectSingleAttributeComponent} from './subject-single-attribute/subject-single-attribute.component';
import {
  SubjectDuplicateResolveDialogComponent
} from './subject-duplicate-resolve-dialog/subject-duplicate-resolve-dialog.component';
import {SubjectHeaderInfoComponent} from './subject-header-info/subject-header-info.component';
import {SubjectTableSidebarComponent} from './subjects-table/subject-table-sidebar/subject-table-sidebar.component';
import {SubjectInitialsComponent} from './subject-initials/subject-initials.component';
import {SubjectDetailRowInfoComponent} from './subject-detail-row-info/subject-detail-row-info.component';
import {PortraitComponent} from './portrait/portrait.component';
import {AttributeValuesPipe} from './attribute-values.pipe';
import {ButtonCollectionModule} from '../../button-collection/button-collection.module';
import {LibraryImportModule} from '../../.module-library-import/library-import.module';
import {AddressDetailsComponent} from './address-details/address-details.component';
import {DataBoxDetailsComponent} from './databox-details/data-box-details.component';
import {DataboxWithTypeComponent} from './databox-with-type/databox-with-type.component';
import {IczFormDirectivesModule} from '../../icz-form-directives/icz-form-directives.module';
import {CzechOrSlovakAddressComponent} from './address/czech-or-slovak-address/czech-or-slovak-address.component';
import {AddressPipeModule} from '../../address-pipe/address-pipe.module';
import {WizardModule} from '../../wizard/wizard.module';
import {DialogsModule} from '../../dialogs/dialogs.module';
import {
  SubjectCustomFilterDialogComponent
} from '../custom-filters/subject-custom-filter/subject-custom-filter-dialog/subject-custom-filter-dialog.component';
import {SubjectCustomFilterComponent} from '../custom-filters/subject-custom-filter/subject-custom-filter.component';
import {AddressCustomFilterComponent} from '../custom-filters/address-custom-filter/address-custom-filter.component';
import { SubjectSelectionDialogComponent } from './subject-selection-dialog/subject-selection-dialog.component';

@NgModule({
  declarations: [
    SubjectSearchWithResultsComponent,
    SubjectSearchFormComponent,
    SubjectsTableComponent,
    SubjectTableSidebarComponent,
    SubjectsToolbarComponent,
    SubjectSearchAppendixComponent,
    SubjectIdentificationStatusIconComponent,
    SubjectAddressSelectorComponent,
    SubjectAddressListComponent,
    SubjectsTableGenericActionsComponent,
    SubjectsTableConsignmentActionsComponent,
    SubjectCustomFilterDialogComponent,
    SubjectCustomFilterComponent,
    AddressCustomFilterComponent,
    AddressTypeLabelPipe,
    AddSubjectDialogComponent,
    AddSubjectStepRelationComponent,
    AddSubjectStepCreateComponent,
    EditSubjectDialogComponent,
    SelectSubjectDialogComponent,
    SubjectDetailCardComponent,
    SubjectCreateFormComponent,
    SubjectCreateContactsComponent,
    SubjectCreateAddressesComponent,
    SubjectTechnicalInfoComponent,
    SubjectUsageReportConfigurationComponent,
    SubjectMultipleValuesAttributeComponent,
    SubjectRecordPipe,
    SubjectNamePipe,
    SubjectUsageReportComponent,
    AddressComponent,
    AddressFulltextSelectorComponent,
    SubjectHeaderInfoComponent,
    SubjectInitialsComponent,
    SubjectSingleAttributeComponent,
    SubjectDuplicateResolveDialogComponent,
    SubjectSingleAttributeComponent,
    IszrQueryDialogComponent,
    SubjectIdentifierInputFormFragmentComponent,
    SubjectDetailRowInfoComponent,
    PortraitComponent,
    AttributeValuesPipe,
    AddressDetailsComponent,
    DataBoxDetailsComponent,
    DataboxWithTypeComponent,
    CzechOrSlovakAddressComponent,
  SubjectSelectionDialogComponent,],
  exports: [
    SubjectSearchWithResultsComponent,
    SubjectSearchFormComponent,
    SubjectsTableComponent,
    SubjectTableSidebarComponent,
    SubjectsToolbarComponent,
    SubjectSearchAppendixComponent,
    SubjectIdentificationStatusIconComponent,
    SubjectAddressSelectorComponent,
    SubjectAddressListComponent,
    SubjectsTableGenericActionsComponent,
    SubjectsTableConsignmentActionsComponent,
    SubjectCustomFilterDialogComponent,
    SubjectCustomFilterComponent,
    AddressCustomFilterComponent,
    AddressTypeLabelPipe,
    AddSubjectDialogComponent,
    AddSubjectStepRelationComponent,
    AddSubjectStepCreateComponent,
    SubjectDetailCardComponent,
    SelectSubjectDialogComponent,
    EditSubjectDialogComponent,
    SubjectMultipleValuesAttributeComponent,
    SubjectRecordPipe,
    SubjectNamePipe,
    AddressComponent,
    SubjectUsageReportComponent,
    AddressFulltextSelectorComponent,
    SubjectCreateFormComponent,
    SubjectSingleAttributeComponent,
    SubjectHeaderInfoComponent,
    SubjectInitialsComponent,
    SubjectDuplicateResolveDialogComponent,
    SubjectDetailRowInfoComponent,
    PortraitComponent,
    AttributeValuesPipe,
    AddressDetailsComponent,
    DataBoxDetailsComponent,
    DataboxWithTypeComponent,
    CzechOrSlovakAddressComponent,
  ],
  imports: [
    CommonModule,
    EssentialsModule,
    FormElementsModule,
    ModalsModule,
    DialogsModule,
    TableModule,
    TransactionsModule,
    ButtonCollectionModule,
    LibraryImportModule,
    IczFormDirectivesModule,
    AddressPipeModule,
    WizardModule,
  ],
})
export class SubjectsModule {
}
