import {inject, Pipe, PipeTransform} from '@angular/core';
import {InterpolationContext} from './essentials.utils';
import {get} from 'lodash';
import {TranslateService} from '@ngx-translate/core';

/**
 * An alternative interpolation routines which works with Spring errors serialization where interpolation
 * placeholders are written as {{0}} ... {{N}} and interpolation cotext is a zero-indexed array
 * and each interpolation placeholder references a value at a specific index in the array.
 */
export function interpolateWithArrayParams(translateService: TranslateService, template: string, parameters: string[]) {
  const replaceRegex = /{{1,2}([a-z0-9]+)}{1,2}/i;
  const replaceNumberRegex = /{{1,2}([0-9]+)}{1,2}/i;

  template = translateService.instant(template);

  // parsing based on existence of {{[0-9]+}} string in error message, if this message contains such a parameter than it number from this parameter is used to index error code parameters
  while (true) {
    const matchedParam = template.match(replaceNumberRegex);
    if (matchedParam && parameters[Number(matchedParam[1])]) {
      template = template.replace(matchedParam[0], translateService.instant(parameters[Number(matchedParam[1])]));
    }
    else {
      break;
    }
  }

  parameters.forEach(param => template = template.replace(replaceRegex, translateService.instant(param)));

  return template;
}

/**
 * A pipe which interpolates mustache-like expressions with the supplied contexts.
 * The mustache-like expressions support mere interpolation marks, i.e. {{STUFF}}
 * which gets replaced by the value of context.STUFF object property.
 * Note that if context.STUFF is null or undefined, the interpolation marks will stay in place.
 * To render nothing in their place, supply empty string to context.STUFF.
 */
@Pipe({
  name: 'interpolate',
  pure: true,
  standalone: true,
})
export class InterpolatePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  /**
   * Interpolates interpolationTemplate with interpolationContext context.
   * @see InterpolatePipe
   */
  transform(interpolationTemplate: string, interpolationContext: Nullable<InterpolationContext>): string {
    const templateMatcher = /{{\s?([^{}\s]*)\s?}}/g;

    if (!interpolationTemplate || !interpolationContext) {
      return interpolationTemplate ?? '';
    }
    return interpolationTemplate.replace(templateMatcher, (interpolationMark, interpolationMarkContents) => {
      if (interpolationMarkContents.includes('>>')) {
        const interpolationMarkParts = interpolationMarkContents.split('>>');
        const innerInterpolationContextKey = interpolationMarkParts[0];
        const innerInterpolationTemplateKey = interpolationMarkParts[1];

        let resolvedInterpolationContext: string[];

        try {
          resolvedInterpolationContext = JSON.parse(get(interpolationContext, innerInterpolationContextKey));
        }
        catch {
          resolvedInterpolationContext = [];
        }

        const resolvedInterpolationTemplate: string = get(interpolationContext, innerInterpolationTemplateKey);

        if (resolvedInterpolationTemplate && resolvedInterpolationContext) {
          return interpolateWithArrayParams(this.translateService, resolvedInterpolationTemplate, resolvedInterpolationContext);
        }
        else {
          return interpolationMark;
        }
      }
      else {
        const resolvedValue = get(interpolationContext, interpolationMarkContents);
        return isNil(resolvedValue) ? interpolationMark : resolvedValue;
      }
    });
  }

}
