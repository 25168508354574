import {OwnConsignmentStatus} from '|api/commons';
import {IczTableDataSource} from '@icz/angular-table';
import {GenericOwnElasticConsignmentWithConsignee} from '../model/own-consignment-model';
import {OwnConsignmentsSearchService} from '../../../../services/own-consignments-search.service';

export class OwnConsignmentsOfficeDeskOwnerDatasource extends IczTableDataSource<GenericOwnElasticConsignmentWithConsignee> {
  constructor(
    searchService: OwnConsignmentsSearchService,
    states: Array<OwnConsignmentStatus>,
  ) {
    super(searchParams => searchService.findOfficeDeskConsignmentsForForOwner(searchParams, states));
  }
}
