import {Directive, inject, Input, TemplateRef} from '@angular/core';

/**
 * A directive used for capturing tab contents template.
 * To be used in contents of TabsComponent tag.
 */
@Directive({
  selector: '[iczTab]',
  standalone: true,
})
export class TabDirective {

  content = inject(TemplateRef);

  /**
   * TabItem ID to associate the tab contents to.
   *
   * @see TabItem
   * @see TabsComponent
   */
  @Input({alias: 'iczTab', required: true})
  id!: string;

}
