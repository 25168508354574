/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { HibernateSearchScheduleMassIndexerResponse } from '../models/hibernate-search-schedule-mass-indexer-response';
import { PageSubjectRecordWithRelationsDto } from '../models/page-subject-record-with-relations-dto';
import { SubjectRecordDto } from '|api/commons';
import { subjectRecordElasticElasticFindSubjectsByRelations } from '../fn/subject-record-elastic/subject-record-elastic-elastic-find-subjects-by-relations';
import { SubjectRecordElasticElasticFindSubjectsByRelations$Params } from '../fn/subject-record-elastic/subject-record-elastic-elastic-find-subjects-by-relations';
import { subjectRecordElasticGet } from '../fn/subject-record-elastic/subject-record-elastic-get';
import { SubjectRecordElasticGet$Params } from '../fn/subject-record-elastic/subject-record-elastic-get';
import { subjectRecordElasticGetRelatedSubjectCount } from '../fn/subject-record-elastic/subject-record-elastic-get-related-subject-count';
import { SubjectRecordElasticGetRelatedSubjectCount$Params } from '../fn/subject-record-elastic/subject-record-elastic-get-related-subject-count';
import { subjectRecordElasticGetRepresentingSubject } from '../fn/subject-record-elastic/subject-record-elastic-get-representing-subject';
import { SubjectRecordElasticGetRepresentingSubject$Params } from '../fn/subject-record-elastic/subject-record-elastic-get-representing-subject';
import { subjectRecordElasticGetRepresentingSubjects } from '../fn/subject-record-elastic/subject-record-elastic-get-representing-subjects';
import { SubjectRecordElasticGetRepresentingSubjects$Params } from '../fn/subject-record-elastic/subject-record-elastic-get-representing-subjects';
import { subjectRecordElasticScheduleMassIndexing } from '../fn/subject-record-elastic/subject-record-elastic-schedule-mass-indexing';
import { SubjectRecordElasticScheduleMassIndexing$Params } from '../fn/subject-record-elastic/subject-record-elastic-schedule-mass-indexing';
import { subjectRecordElasticSearchByRelatedObject } from '../fn/subject-record-elastic/subject-record-elastic-search-by-related-object';
import { SubjectRecordElasticSearchByRelatedObject$Params } from '../fn/subject-record-elastic/subject-record-elastic-search-by-related-object';
import { subjectRecordElasticStopAndDiscardAllMassIndexingJobs } from '../fn/subject-record-elastic/subject-record-elastic-stop-and-discard-all-mass-indexing-jobs';
import { SubjectRecordElasticStopAndDiscardAllMassIndexingJobs$Params } from '../fn/subject-record-elastic/subject-record-elastic-stop-and-discard-all-mass-indexing-jobs';
import { subjectRecordElasticStopAndDiscardMassIndexingJob } from '../fn/subject-record-elastic/subject-record-elastic-stop-and-discard-mass-indexing-job';
import { SubjectRecordElasticStopAndDiscardMassIndexingJob$Params } from '../fn/subject-record-elastic/subject-record-elastic-stop-and-discard-mass-indexing-job';
import { SubjectRecordWithRelationsDto } from '|api/commons';


/**
 * Controller for Subject Record from elastic
 */
@Injectable({ providedIn: 'root' })
export class ApiSubjectRecordElasticService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `subjectRecordElasticStopAndDiscardAllMassIndexingJobs()` */
  static readonly SubjectRecordElasticStopAndDiscardAllMassIndexingJobsPath = '/subject-record/elastic/mass-indexer/stop-and-discard-all-jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticStopAndDiscardAllMassIndexingJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordElasticStopAndDiscardAllMassIndexingJobs$Response(params?: SubjectRecordElasticStopAndDiscardAllMassIndexingJobs$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectRecordElasticStopAndDiscardAllMassIndexingJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticStopAndDiscardAllMassIndexingJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordElasticStopAndDiscardAllMassIndexingJobs(params?: SubjectRecordElasticStopAndDiscardAllMassIndexingJobs$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.subjectRecordElasticStopAndDiscardAllMassIndexingJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subjectRecordElasticScheduleMassIndexing()` */
  static readonly SubjectRecordElasticScheduleMassIndexingPath = '/subject-record/elastic/mass-indexer/schedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticScheduleMassIndexing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticScheduleMassIndexing$Response(params: SubjectRecordElasticScheduleMassIndexing$Params, context?: HttpContext): Observable<StrictHttpResponse<HibernateSearchScheduleMassIndexerResponse>> {
    return subjectRecordElasticScheduleMassIndexing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticScheduleMassIndexing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticScheduleMassIndexing(params: SubjectRecordElasticScheduleMassIndexing$Params, context?: HttpContext): Observable<HibernateSearchScheduleMassIndexerResponse> {
    return this.subjectRecordElasticScheduleMassIndexing$Response(params, context).pipe(
      map((r: StrictHttpResponse<HibernateSearchScheduleMassIndexerResponse>): HibernateSearchScheduleMassIndexerResponse => r.body)
    );
  }

  /** Path part for operation `subjectRecordElasticStopAndDiscardMassIndexingJob()` */
  static readonly SubjectRecordElasticStopAndDiscardMassIndexingJobPath = '/subject-record/elastic/mass-indexer/job/{key}/stop-and-discard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticStopAndDiscardMassIndexingJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordElasticStopAndDiscardMassIndexingJob$Response(params: SubjectRecordElasticStopAndDiscardMassIndexingJob$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return subjectRecordElasticStopAndDiscardMassIndexingJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticStopAndDiscardMassIndexingJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordElasticStopAndDiscardMassIndexingJob(params: SubjectRecordElasticStopAndDiscardMassIndexingJob$Params, context?: HttpContext): Observable<void> {
    return this.subjectRecordElasticStopAndDiscardMassIndexingJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subjectRecordElasticGetRepresentingSubjects()` */
  static readonly SubjectRecordElasticGetRepresentingSubjectsPath = '/subject-record/elastic/get-representing-subjects';

  /**
   * Finds representing subject by input list relatedObjectDto.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticGetRepresentingSubjects()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticGetRepresentingSubjects$Response(params: SubjectRecordElasticGetRepresentingSubjects$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubjectRecordWithRelationsDto>>> {
    return subjectRecordElasticGetRepresentingSubjects(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds representing subject by input list relatedObjectDto.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticGetRepresentingSubjects$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticGetRepresentingSubjects(params: SubjectRecordElasticGetRepresentingSubjects$Params, context?: HttpContext): Observable<Array<SubjectRecordWithRelationsDto>> {
    return this.subjectRecordElasticGetRepresentingSubjects$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubjectRecordWithRelationsDto>>): Array<SubjectRecordWithRelationsDto> => r.body)
    );
  }

  /** Path part for operation `subjectRecordElasticGetRepresentingSubject()` */
  static readonly SubjectRecordElasticGetRepresentingSubjectPath = '/subject-record/elastic/get-representing-subject';

  /**
   * Finds representing subject by input relatedObjectDto.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticGetRepresentingSubject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticGetRepresentingSubject$Response(params: SubjectRecordElasticGetRepresentingSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<SubjectRecordDto>> {
    return subjectRecordElasticGetRepresentingSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds representing subject by input relatedObjectDto.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticGetRepresentingSubject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticGetRepresentingSubject(params: SubjectRecordElasticGetRepresentingSubject$Params, context?: HttpContext): Observable<SubjectRecordDto> {
    return this.subjectRecordElasticGetRepresentingSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubjectRecordDto>): SubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `subjectRecordElasticGetRelatedSubjectCount()` */
  static readonly SubjectRecordElasticGetRelatedSubjectCountPath = '/subject-record/elastic/get-related-subject-count';

  /**
   * Gets count of related subjects for given related object.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticGetRelatedSubjectCount()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticGetRelatedSubjectCount$Response(params: SubjectRecordElasticGetRelatedSubjectCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return subjectRecordElasticGetRelatedSubjectCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets count of related subjects for given related object.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticGetRelatedSubjectCount$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticGetRelatedSubjectCount(params: SubjectRecordElasticGetRelatedSubjectCount$Params, context?: HttpContext): Observable<number> {
    return this.subjectRecordElasticGetRelatedSubjectCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `subjectRecordElasticElasticFindSubjectsByRelations()` */
  static readonly SubjectRecordElasticElasticFindSubjectsByRelationsPath = '/subject-record/elastic/find-subjects-by-relations';

  /**
   * Finds related subject records by related object.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticElasticFindSubjectsByRelations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticElasticFindSubjectsByRelations$Response(params: SubjectRecordElasticElasticFindSubjectsByRelations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubjectRecordWithRelationsDto>>> {
    return subjectRecordElasticElasticFindSubjectsByRelations(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds related subject records by related object.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticElasticFindSubjectsByRelations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subjectRecordElasticElasticFindSubjectsByRelations(params: SubjectRecordElasticElasticFindSubjectsByRelations$Params, context?: HttpContext): Observable<Array<SubjectRecordWithRelationsDto>> {
    return this.subjectRecordElasticElasticFindSubjectsByRelations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubjectRecordWithRelationsDto>>): Array<SubjectRecordWithRelationsDto> => r.body)
    );
  }

  /** Path part for operation `subjectRecordElasticGet()` */
  static readonly SubjectRecordElasticGetPath = '/subject-record/elastic/{subjectId}';

  /**
   * Returns subject by its id.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordElasticGet$Response(params: SubjectRecordElasticGet$Params, context?: HttpContext): Observable<StrictHttpResponse<SubjectRecordDto>> {
    return subjectRecordElasticGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns subject by its id.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordElasticGet(params: SubjectRecordElasticGet$Params, context?: HttpContext): Observable<SubjectRecordDto> {
    return this.subjectRecordElasticGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubjectRecordDto>): SubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `subjectRecordElasticSearchByRelatedObject()` */
  static readonly SubjectRecordElasticSearchByRelatedObjectPath = '/subject-record/elastic/find-subjects-by-relation/{relatedObjectType}/{relatedObjectId}/search-api';

  /**
   * Finds page of subjects based on relation.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subjectRecordElasticSearchByRelatedObject()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordElasticSearchByRelatedObject$Response(params: SubjectRecordElasticSearchByRelatedObject$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSubjectRecordWithRelationsDto>> {
    return subjectRecordElasticSearchByRelatedObject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds page of subjects based on relation.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subjectRecordElasticSearchByRelatedObject$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  subjectRecordElasticSearchByRelatedObject(params: SubjectRecordElasticSearchByRelatedObject$Params, context?: HttpContext): Observable<PageSubjectRecordWithRelationsDto> {
    return this.subjectRecordElasticSearchByRelatedObject$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSubjectRecordWithRelationsDto>): PageSubjectRecordWithRelationsDto => r.body)
    );
  }

}
