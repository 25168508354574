import {AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ArcElement, Chart, ChartData, DoughnutController, Title, Tooltip} from 'chart.js';
import {GenericChartConfig} from '../statistics-model';
import {CHART_COLOR_PALETTE} from '../chart-utils';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';


@Component({
  selector: 'icz-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DonutChartComponent implements OnInit, AfterViewInit, IczOnChanges {

  @Input({required: true}) config!: GenericChartConfig;

  @ViewChild('chart') private chartRef!: ElementRef;
  private chart!: Chart<any>;

  ngOnInit() {
    Chart.register(DoughnutController, ArcElement, Title, Tooltip);
  }

  configToDonutConfig(config: GenericChartConfig): ChartData<any> {
    const result: ChartData<any> = {...config};
    result.datasets.forEach(d => {
      if (!d.cutout) d.cutout = '75%';
      if (!d.borderWidth) d.borderWidth = 1;
      if (!d.backgroundColor) {
        // setting a couple default colors, donut chart with 1 color isn't very useful
        d.backgroundColor = CHART_COLOR_PALETTE;
      }
      if (!d.borderColor) {
        d.borderColor = CHART_COLOR_PALETTE;
      }
    });

    return result;
  }

  setNewChart() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'doughnut',
      data: this.configToDonutConfig(this.config),
      options: {
        responsive: true,
      },
    });
  }

  ngAfterViewInit(): void {
    if (this.config && !this.chart) {
      this.setNewChart();
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.config.currentValue) {
      if (this.chart) {
        this.chart.data = changes.config.currentValue;
        this.chart.update();
      }
      else if (this.chartRef?.nativeElement) {
        this.setNewChart();
      }
    }
  }

}
