<div class="row" [formGroup]="form" (keydown.enter)="applyFilter()">

  <div class="col grow">
    <div class="filter-specification-area">

      <icz-form-inline-select
        formControlName="filterOperator"
        [options]="filterOperators"
        (openStatusChanged)="isOperatorSelectorOpen = $event">
      </icz-form-inline-select>

      <div class="filter-body"
        [waiting]="isLoading"
        [style.height.px]="canDisplayFilterBody ? null : 1"
        [style.opacity]="canDisplayFilterBody ? null : 0"> <!-- will make smoother UX without text jumping when re-selecting filter operator -->

        @if (useCustomChipsFilter) {
          <icz-form-chip-input
            class="block mt-12 mb-8"
            [options]="item.list"
            [asPopover]="false"
            [clearable]="false"
            [isMultiselect]="true"
            [manualValueCommit]="false"
            [originId]="item.originId"
            [chipNamespace]="item.chipNamespace"
            [customOptionTemplate]="item.customListItemTemplate"
            formControlName="value"
          ></icz-form-chip-input>
        }
        @if (simpleListFilter) {
          <icz-form-autocomplete
            class="block mt-12 mb-8"
            [options]="item.list"
            [asPopover]="false"
            [clearable]="false"
            [isMultiselect]="true"
            [originId]="item.originId"
            [customOptionTemplate]="item.customListItemTemplate"
            formControlName="value"
          ></icz-form-autocomplete>
        }
        @if (backendListFilter) {
          <icz-form-autocomplete
            formControlName="value"
            [options]="[]"
            [optionsDefinitionFactory]="makeSearchTermOptionsDefinition"
            [asPopover]="false"
            [isMultiselect]="true"
            [minSearchTermLength]="item.minSearchTermLength ?? 3"
          ></icz-form-autocomplete>
        }

      </div>

    </div>
  </div>

</div>
