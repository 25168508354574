import {Component, TemplateRef, ViewChild} from '@angular/core';
import {AbstractTemplateCollection} from '@icz/angular-essentials';
import {IAutocompleteListItemContext, IImplicitTemplateContext} from '@icz/angular-form-elements';

@Component({
  templateUrl: './shared-template-collection.component.html',
})
export class SharedTemplateCollectionComponent extends AbstractTemplateCollection {

  // each new template should have prefix/suffix "template".
  @ViewChild('entityClassChipTemplate')
  entityClassChipTemplate!: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>;

  @ViewChild('agendaActivityRoleTemplate')
  agendaActivityRoleTemplate!: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>;

}
