import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {SubjectRecordDto} from '|api/commons';
import {SubjectAddressOptionData} from '../subject-address-selector/subject-address-selector.component';
import {AddressCompleteDto, AddressWithTypeDto} from '../../model/addresses.model';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';
import {CodebookService} from '../../../../core/services/codebook.service';
import {CzemItemDto} from '|api/codebook';
import {hashed} from '@icz/angular-essentials';
import {IczOption} from '@icz/angular-form-elements';

@Component({
  selector: 'icz-subject-address-list',
  templateUrl: './subject-address-list.component.html',
  styleUrls: ['./subject-address-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectAddressListComponent implements OnInit {

  private codebookService = inject(CodebookService);

  @Input({required: true}) subject!: SubjectRecordDto;
  @Input({required: true}) value!: Nullable<AddressCompleteDto>;

  options: IczOption<string, SubjectAddressOptionData>[] = [];
  showPopover = false;

  countriesOptions!: CzemItemDto[];

  get hasMultipleAddresses() {
    return this.options?.length > 1;
  }

  addressClick() {
    if (this.hasMultipleAddresses) this.showPopover = true;
  }

  private buildOptionsFromInputs() {
    if (this.subject) {
      this.options = this.getBusinessSortedAddresses(this.subject).map(addressWithType => ({
        value: hashed(addressWithType.address),
        label: SubjectTemplateUtils.getAddressString(addressWithType, this.subject.classification, false, this.countriesOptions),
        data: {
          addressWithType,
        }
      }));
    }
  }

  ngOnInit() {
    this.codebookService.countries().subscribe(
      countries => {
        this.countriesOptions = countries;
        this.buildOptionsFromInputs();
      }
    );

    this.buildOptionsFromInputs();
  }

  getBusinessSortedAddresses(subject: SubjectRecordDto): AddressWithTypeDto[] {
    return SubjectTemplateUtils.getBusinessSortedAddresses(subject);
  }

}
