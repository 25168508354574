import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {PrimitiveValueFormField} from '../common/abstract-form-field';
import {
  IconComponent,
  IczOnChanges,
  IczSimpleChanges,
  PopoverComponent,
  TooltipDirective
} from '@icz/angular-essentials';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {formatNumberByNumericMask, InputMaskDirective} from '../input-mask/input-mask.directive';
import {ValidationErrorsListComponent} from '../validators/validation-errors-list/validation-errors-list.component';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

import {isNilOrEmptyString} from '../form-elements.utils';

/**
 * Implement number inputs using [type]="text" and validator IczValidators.isInteger/IczValidators.isDecimal!
 */
export type FormFieldType = 'text'|'integer'|'decimal'|'password';

@Component({
  selector: 'icz-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    'class': 'icz-form-field'
  },
  standalone: true,
  imports: [
    IconComponent,
    CdkTextareaAutosize,
    TranslateModule,
    InputMaskDirective,
    PopoverComponent,
    ValidationErrorsListComponent,
    TooltipDirective,
    CdkOverlayOrigin,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => FormFieldComponent),
  }],
})
export class FormFieldComponent extends PrimitiveValueFormField<string | number> implements OnInit, IczOnChanges {

  private translateService = inject(TranslateService);

  @ViewChild('inputElement', {read: ElementRef, static: false})
  protected inputElement!: ElementRef;

  @ViewChild('autosize', {read: CdkTextareaAutosize, static: false})
  protected autosize!: CdkTextareaAutosize;

  /**
   * Field value. Both null and emptystring values are considered null-like.
   */
  @Input()
  override set value(newValue: Nullable<string | number>) {
    if (!isNilOrEmptyString(newValue) && this.type === 'integer') {
      const sanitizedNewValue = (typeof newValue) === 'string' ? parseInt(newValue as string) : newValue as number;

      if (this.iczInputMask) {
        this._value = formatNumberByNumericMask(this.iczInputMask, sanitizedNewValue);
      }
      else {
        this._value = String(newValue);
      }
    }
    else if (!isNilOrEmptyString(newValue) && this.type === 'decimal') {
      const currentLanguage = this.translateService.currentLang;
      const sanitizedNewValue = (typeof newValue) === 'string' ? parseFloat(newValue as string) : newValue as number;
      let stringifiedNumber: string;

      if (this.iczInputMask) {
        stringifiedNumber = formatNumberByNumericMask(this.iczInputMask, sanitizedNewValue);
      }
      else if (this.decimalPlaceCount && Number.isInteger(sanitizedNewValue * (10 ** this.decimalPlaceCount))) {
        stringifiedNumber = sanitizedNewValue.toFixed(this.decimalPlaceCount);
      }
      else {
        stringifiedNumber = String(newValue);
      }

      if (currentLanguage === 'cs' || currentLanguage === 'sk') {
        this._value = stringifiedNumber.replace('.', ',');
      }
      else {
        this._value = stringifiedNumber;
      }
    }
    else {
      this._value = newValue;
    }
  }
  override get value(): Nullable<string | number> {
    return this.convertInternalValueToOutputValue(this._value as string);
  }

  /**
   * Input type, similar to plain HTML <input> type attribute but adjusted to out needs.
   * @see FormFieldType
   */
  @Input()
  type: FormFieldType = 'text';
  /**
   * Allowed number of decimal places. Effective only if FormFieldComponent.type is "decimal".
   */
  @Input()
  decimalPlaceCount: Nullable<number>;
  /**
   * Minimal count of text content rows simultaneously visible to the user.
   * Should not be greater than autoSizeMax.
   */
  @Input()
  autoSizeMin = 1;
  /**
   * Maximal count of text content rows simultaneously visible to the user. Used in "inflatable text area" use-cases.
   * If autoSizeMax is greater than 1, the form field will start accepting multiline inputs.
   */
  @Input()
  autoSizeMax = 1;
  /**
   * Input mask.
   * @see InputMaskDirective
   */
  @Input()
  iczInputMask = '';
  /**
   * If true, displays a trash icon used for clearing field value.
   */
  @Input()
  clearable = false;
  /**
   * Tabindex, works in the same fashion as HTML tabindex property.
   */
  @Input()
  tabIndex!: number;
  /**
   * Used for disabling rendering of validator error messages if its value is FALSE.
   * Usually needed only in very specific use-cases for wrapping form fields in more complex form controls, such as money inputs.
   */
  @Input()
  showValidationStatus = true;
  /**
   * If true, will display only placeholder in unfocused state and only prefix in focused state even if the field itself is empty.
   * Effective if both @Input placeholder and ng-content prefix are supplied to the component.
   */
  @Input()
  switchPrefixAndPlaceholder = false;
  /**
   * Used for explicitly disabling password suggestions in scenarios where it is not
   * desirable (dialogs with password without username input, etc).
   * Effective if if FormFieldComponent.type is "password"
   */
  @Input()
  passwordSuggestions = false;
  /**
   * If true, will signal to host browser that this field is used for inputting usernames.
   * As od July 2024, this feature is used mainly by Firefox in its internal autofill heuristics.
   */
  @Input()
  isUsername = false;
  /**
   * HTML name of the field. Usually not required to fill but might be significant
   * in cases where we want to enable autofill, especially in Chrome where it is used by its internal heuristics.
   */
  @Input()
  htmlName: Nullable<string> = null;
  /**
   * @internal
   */
  @Input()
  hideInputElement = false;
  /**
   * @internal
   */
  @Input()
  hasClickableInput = false;
  /**
   * A special visually distinguished label of the field to the right of the ordinary label.
   */
  @Input()
  rightLabel: Nullable<string>;
  /**
   * Tooltip of the right label.
   * Applicable only if rightLabel is non-null.
   */
  @Input()
  rightLabelTooltip: Nullable<string>;
  /**
   * A flag determining if clicking the right label will open a popover.
   * Applicable only if rightLabel is non-null.
   */
  @Input()
  showRightLabelPopupOnClick = false;

  /**
   * Fires when the field gets focused.
   */
  @Output()
  focus = new EventEmitter<void>();
  /**
   * Fires when the field is clicked.
   */
  @Output()
  inputClick = new EventEmitter<Event>();

  protected isFieldFocused = false;
  protected showRightLabelPopup = false;

  /**
   * @internal
   */
  ngOnInit(): void {
    setTimeout(() => {
      if (this.autosize) {
        this.autosize.resizeToFitContent(true);
      }
    }, 0);
  }

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes?.type?.currentValue && !changes?.type.previousValue) {
      this.originalType = changes.type.currentValue;
      this._isPasswordField = this.originalType === 'password';
    }

    if (changes.type || changes.autoSizeMax) {
      if (this.autoSizeMax > 1 && this.type !== 'text') {
        throw new Error(`Only form fields of type=text support AutoSize larger than 1.`);
      }
    }
  }

  /**
   * Programatically focuses the field without the need for DOM queries and manipulation.
   */
  focusField() {
    this.inputElement.nativeElement.focus();
  }

  /**
   * Clears the field value.
   */
  clear() {
    this._value = null;
    this.valueChange.emit('');
  }

  protected originalType: Nullable<FormFieldType>;

  protected get shouldShowPrefix() {
    if (this.switchPrefixAndPlaceholder) {
      return (this.isFieldFocused || !isNilOrEmptyString(this.value));
    } else {
      return true;
    }
  }

  protected get shouldHidePlaceholder() {
    return this.switchPrefixAndPlaceholder && this.shouldShowPrefix;
  }

  protected get autocompleteValue() {
    if (this.type === 'password') {
      if (this.passwordSuggestions) {
        return 'on';
      } else {
        return 'new-password';
      }
    } else if (this.isUsername) {
      return 'username';
    } else {
      return 'off';
    }
  }

  protected get passwordRevealButtonIcon() {
    return this.type === 'password' ? 'eye' : 'eye_hidden';
  }

  protected get htmlType() {
    if (this.type === 'integer' || this.type === 'decimal') {
      return 'text';
    }
    else {
      return this.type;
    }
  }

  protected get isDecimalTypeWithFixedDecimals() {
    return this.type === 'decimal' && (!isNil(this.decimalPlaceCount) || this.iczInputMask?.includes('.'));
  }

  protected toggleRevealPassword() {
    this.type = (
      this.type === 'password' ?
        'text' :
        'password'
    );
  }

  protected onFocus() {
    this.isFieldFocused = true;
    this.focus.emit();
  }

  protected onBlur($event: Event) {
    if (this.isDecimalTypeWithFixedDecimals) {
      this.emitOutputValue($event);
    }

    this.isFieldFocused = false;
    this.blur.emit();
  }

  protected _valueChanged($event: Event) {
    if (!this.isDecimalTypeWithFixedDecimals) {
      this.emitOutputValue($event);
    }
  }

  protected onInputClick($event: Event) {
    if (this.hasClickableInput) {
      this.inputClick.emit($event);
    }
  }

  protected checkInputCharacter($event: KeyboardEvent) {
    if (
      $event.metaKey ||
      $event.ctrlKey ||
      $event.key === 'Backspace' ||
      $event.key === 'Delete' ||
      $event.key === 'Tab' ||
      $event.key === 'ArrowLeft' ||
      $event.key === 'ArrowRight' ||
      $event.key === 'Enter' ||
      $event.key === 'Shift'
    ) return;

    if (this.type === 'integer') {
      const allowedCharacters = /[-0-9]/;

      if (!allowedCharacters.test($event.key)) {
        $event.preventDefault();
      }
    }
    else if (this.type === 'decimal') {
      const allowedCharacters = /[-0-9,.]/;

      if (!allowedCharacters.test($event.key)) {
        $event.preventDefault();
      }
    }
  }

  private convertInternalValueToOutputValue(internalValue: Nullable<string>): Nullable<string | number> {
    if (isNilOrEmptyString(internalValue)) {
      return null;
    }
    else {
      if (this.type === 'integer') {
        // eslint-disable-next-line eqeqeq -- coercive behavior is on point here - `string|number == string` where string|number might contain nonconversible values
        if (internalValue != `${parseInt(internalValue, 10)}`) {
          return null;
        }
        else {
          return parseInt(internalValue, 10);
        }
      }
      else if (this.type === 'decimal') {
        if (/^[+-]?(?=[,.]?\d)\d*([,.]\d{0,9})?$/.test(internalValue)) {
          return parseFloat(internalValue.replace(',', '.'));
        }
        else {
          return null;
        }
      }
      else {
        return internalValue;
      }
    }
  }

  private emitOutputValue($event: Event) {
    const fieldValue = ($event.target as HTMLInputElement).value;
    const outputValue = this.convertInternalValueToOutputValue(fieldValue);

    this.value = outputValue;
    this.valueChange.emit(outputValue);
  }

}
