<icz-card [waiting]="loadingService.isLoading$(this) | async" class="block">
  <div class="pt-24">
    <div class="text-center">
      <icz-button class="block pointer-events-none pb-16"
        svgIcon="substitute"
        originalIconColor
        rounded noBorder
      [background]="isSubstitutionsForUser ? SUBSTITUTION_FOR_USER_ICON_COLOR : SUBSTITUTION_FOR_FP_ICON_COLOR"> </icz-button>
      <span class="icz-body-1 uppercase">
        {{ (isSubstitutionsForUser ? 'Funkční místa, která zastupujete' : 'Vaše funkční místo je zastupované') | translate }}
      </span>
    </div>
    <div class="card-divider">
      @if (substitutions.length > 0) {
        <div>
          @for (substitution of substitutions; track substitution.id) {
            <div class="mx-24 my-8">
              <div class="mb-4 font-bold">
                @if (isSubstitutionsForUser) {
                  {{substitution.functionalPositionId | findFunctionalPositionById | async}}
                }
                @if (!isSubstitutionsForUser) {
                  {{substitution.userId | findUserById | async}}
                }
              </div>
              <div>{{ formatSubstitutionDate(substitution) }}</div>
            </div>
          }
        </div>
      }
      @else {
        <div class="mx-24 my-8">
          <span>{{ 'Žádné zástupy' | translate }}</span>
        </div>
      }
    </div>
    <icz-button size="xlarge" label="Přehled zástupů" (onAction)="goToSubstitutionsSettings()" background="transparent"></icz-button>
  </div>
</icz-card>
