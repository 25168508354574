import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  HostListener,
  inject,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GenericChartConfig, IczStatistic, LineChartConfig, StatisticConfig, TrendPeriod} from '../statistics-model';
import {
  CodebookFilterDefinition,
  ColumnDefinition,
  extendDefaultTableConfig,
  FilterItemValue,
  FilterOperator,
  FilterParam,
  FilterType,
  FormFilterItem,
  getDefaultSearchParams,
  iczParseDurationString,
  isValidIczDurationString,
  SearchParams,
  TableColumnsData,
  TableComponent
} from '@icz/angular-table';
import {
  DocumentFiltersDataService
} from '../../shared-business-components/document-table/components/document-filters/document-filters-data.service';
import {
  formatAsLocalIsoDate,
  getTodayMidnight,
  IczFormControl,
  IczFormGroup,
  IczOption,
  isDateRange,
  locateOptionByValue,
  TreeItemSelectionStrategy
} from '@icz/angular-form-elements';
import {
  FileSizeUnit,
  IczOnChanges,
  IczSimpleChanges,
  LoadingIndicatorService,
  removeDuplicates,
  TemplatePoolService,
  transformFileSize
} from '@icz/angular-essentials';
import {ApiStatisticsService, PointDto, Statistic, StatisticsDimension} from '|api/elastic';
import {DocumentState, EntityClassType, FileState} from '|api/commons';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {enumToOptions, nameDtoToOptionWithId} from '../../../core/services/data-mapping.utils';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {
  getDisposalScheduleFilterConfig,
  getEntityClassFilterConfig,
  setupEntityClassFilterSubfilters
} from '../../shared-business-components/document-table/components/document-filters/document-filter-selectors';
import {isEqual, toNumber} from 'lodash';
import {DateRange} from '@angular/material/datepicker';
import {ChartElementColor} from '../chart-utils';
import {StatisticsSearchService} from './statistics-search.service';
import {StorageUnitSearchService} from '../../../services/storage-unit-search.service';
import {LocalStorageKey, UserSettingsService} from '../../../services/user-settings.service';
import {DashboardLayoutService} from '../../../services/dashboard-layout.service';
import {StorageUnitsDatasource} from '../../shared-business-components/storage-units.datasource';
import {
  getDocumentsColumnsData,
  getStaticDocumentsColumnsForView
} from '../../shared-business-components/document-table/documents-table.columnsets';
import {StorageUnitView} from '../../shared-business-components/document-toolbar/services/toolbar-common.utils';
import {StatisticsTableDatasource} from './statistics-table.datasource';
import {of} from 'rxjs';
import {CodebookService} from '../../../core/services/codebook.service';

type StatisticsTableColumn =  StatisticsDimension | 'count' | 'previousCount' | 'dateRange' |
  'createdAt' | 'settlementDate' | 'resolutionDate' | 'closureDate' | 'component.isFinal' | 'component.createdAt'|
  'entityClass.createdAt' | 'size' | 'inRegistryOffice';

@Component({
  selector: 'icz-statistics-table',
  templateUrl: './statistics-table.component.html',
  styleUrls: ['./statistics-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticsTableComponent implements IczOnChanges, OnInit, OnDestroy {

  loadingIndicatorService = inject(LoadingIndicatorService);
  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private translateService = inject(TranslateService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private apiStatisticsService = inject(ApiStatisticsService);
  private templatePool = inject(TemplatePoolService);
  private statisticsSearchService = inject(StatisticsSearchService);
  private storageUnitSearchService = inject(StorageUnitSearchService);
  private userSettingsService = inject(UserSettingsService);
  protected dashboardLayoutService = inject(DashboardLayoutService);
  private codebookService = inject(CodebookService);


  @Input({required: true}) tableTitle!: string;
  @Input({required: true}) allowedDimensions!: StatisticsDimension[];
  @Input({required: true}) statisticConfig!: StatisticConfig;
  @Input({required: true}) period!: TrendPeriod;
  @HostListener('window:beforeunload')
  protected beforeUnloadWindow() {
    this.tableComponent.setDisplayedColumns(true);
  }

  selectedDimensions: StatisticsDimension[] = [];
  initialDataLoaded = false;
  private showPreviousCountColumn = false;
  private tableComponent!: TableComponent<StatisticsTableColumn>;

  watchedPeriodFromDate!: Date;
  watchedPeriodToDate!: Date;

  firstPeriodFilter = {
    label: this.translateService.instant('Období'),
    isDistinctive: true,
    filterType: FilterType.DATE_STATISTICS,
  } as Partial<FormFilterItem>;

  secondPeriodFilter = {
    label: this.translateService.instant('Porovnat s obdobím'),
    filterType: FilterType.DATE_STATISTICS,
  } as Partial<FormFilterItem>;

  tableConfig = extendDefaultTableConfig({
    hoverableRows: false,
    rowHeight: 40,
    toolbarConfig: {
      showFilter: false,
      showTools: false,
      autoOpenFilter: true,
      showReload: false,
      showColumnSelector: false,
      showSavedFilters: false
    },
    defaultFilterColumns: [StatisticsDimension.ENTITY_CLASS],
  });

  readonly StatisticsDimension = StatisticsDimension;

  lineChartConfig: LineChartConfig = {
    xAxisRange: [new Date().toISOString(), new Date().toISOString()],
    datasets: []
  };

  barChartConfig: GenericChartConfig = {
    labels: [],
    datasets: [{
      label: 'Počet',
      data: [],
      barPercentage: 0.3,
      borderWidth: 1
    }]
  };
  appliedFilters: FilterParam[] = [];

  entityStateOptions: IczOption[] = removeDuplicates([
    ...enumToOptions('documentState', DocumentState),
    ...enumToOptions('fileState', FileState),
  ], o => o.value);

  documentStatistics = [
    Statistic.DOCUMENTS_SIZE_COUNT,
    Statistic.DOCUMENTS_TOTAL_COUNT,
    Statistic.DOCUMENTS_RANGE_COUNT,
    Statistic.DOCUMENTS_BY_ACCESS_ENTITLEMENT,
  ];

  fileStatistics = [
    Statistic.FILES_SIZE_COUNT,
    Statistic.FILES_TOTAL_COUNT,
    Statistic.FILES_RANGE_COUNT,
    Statistic.FILES_BY_ACCESS_ENTITLEMENT,
    Statistic.FILES_IN_STORAGE_UNIT_TOTAL_COUNT
  ];

  componentStatistics = [
    Statistic.DIGITAL_COMPONENTS_RANGE_COUNT,
    Statistic.DIGITAL_COMPONENTS_BY_PUID,
  ];

  storingUnitStatistics = [
    Statistic.FILES_TRANSFERRED_TO_REGISTRY_OFFICE_RANGE_COUNT,
  ];

  selectedDate: Nullable<Date | DateRange<Date>> = null;

  showChart = true;

  get showDonutChart(): boolean {
    return this.statisticConfig && this.statisticConfig.allowDonutChart && this.form.get('showDonutChart')!.value!;
  };

  get dimensionsSelected() {
    return this.selectedDimensions.length > 0;
  }

  form = new IczFormGroup({
    showDonutChart: new IczFormControl<Nullable<boolean>>(false),
    period: new IczFormControl<Nullable<TrendPeriod>>(null),
    firstPeriod: new IczFormControl<Nullable<FilterItemValue<any>>>(null),
    secondPeriod: new IczFormControl<Nullable<FilterItemValue<string>>>(null),
  });

  readonly allColumnIds: StatisticsDimension[] = [
    StatisticsDimension.ENTITY_CLASS, StatisticsDimension.SECURITY_CATEGORY, StatisticsDimension.ENTITY_STATE
  ];

  columnsData!: TableColumnsData<StatisticsTableColumn>;

  dataSource = new StatisticsTableDatasource(() => []);

  showTableAndGraph = true;
  currentSearchParams: SearchParams = {...getDefaultSearchParams(), size: 1000};

  afterTableInitialized(tableInstance: TableComponent<StatisticsTableColumn>) {
    this.tableComponent = tableInstance;
  }

  pageLoadStarted(searchParams: SearchParams) {
    this.currentSearchParams = searchParams;
    this.currentSearchParams.filter.forEach(f => {
      switch (f.fieldName) {
        case StatisticsDimension.ENTITY_CLASS:
          f.fieldName = 'entityClassId';
          break;
        case StatisticsDimension.DISPOSAL_SCHEDULE:
          f.fieldName = 'disposalScheduleId';
          break;
        case StatisticsDimension.ENTITY_STATE:
          f.fieldName = 'entityState';
          break;
        case StatisticsDimension.OBJECT_CLASS:
          f.fieldName = 'objectClass';
          break;
        case StatisticsDimension.OWNER_FP:
          f.fieldName = 'ownerFunctionalPositionId';
          break;
        case StatisticsDimension.OWNER_ORG_UNIT:
          f.fieldName = 'ownerOrgUnitId';
          break;
        case StatisticsDimension.SECURITY_CATEGORY:
          f.fieldName = 'securityCategoryId';
          break;
        case StatisticsDimension.STORAGE_UNIT:
          f.fieldName = 'storageUnitId';
          break;
        case StatisticsDimension.HANDED_OVER_BY_FP:
          f.fieldName = 'handedOverByFunctionalPositionId';
          break;
        case StatisticsDimension.HANDED_OVER_BY_ORG_UNIT:
          f.fieldName = 'handedOverByFunctionalPositionOfOrgUnitId';
          break;
        case StatisticsDimension.REGISTRY_OFFICE:
          f.fieldName = 'registryOfficeId';
          break;
        case StatisticsDimension.DOCUMENT_TYPE:
          f.fieldName = 'documentTypeId';
          break;
        case StatisticsDimension.FILE_TYPE:
          f.fieldName = 'fileTypeId';
          break;
      }
    });
    if (isEqual(this.appliedFilters, this.currentSearchParams.filter)) {
      setTimeout(() => {
        // this must be done in timeout, to allow filters datasource reload to run first and then run load page with clearing searchParams filters
        this.dataSource?.loadPage({...this.currentSearchParams, filter: []});
      });
    } else {
      this.appliedFilters = searchParams.filter;
      setTimeout(() => {
        // this must be done in timeout, to allow filters datasource reload to run first and then run load page with clearing searchParams filters
        this.loadTableData(this.watchedPeriodFromDate.toISOString(), this.watchedPeriodToDate.toISOString(), this.currentSearchParams);
      });
    }
  }

  get tableId() {
    return 'statistic-table-' + this.statisticConfig.id;
  }

  getFpOrOrgAsNumber(value: Nullable<string>) {
    return value ? toNumber(value) : -1;
  }

  getNullDimensionValue(columnId: string) {
    switch (columnId) {
      case StatisticsDimension.ENTITY_CLASS:
        return this.translateService.instant('Bez věcné skupiny');
      case StatisticsDimension.SECURITY_CATEGORY:
        return this.translateService.instant('Bez bezpečnostní kategorie');
      case StatisticsDimension.DISPOSAL_SCHEDULE:
        return this.translateService.instant('Bez skartačního režimu');
      case StatisticsDimension.DOCUMENT_TYPE:
        return this.translateService.instant('Bez typu dokumentu');
      case StatisticsDimension.FILE_TYPE:
        return this.translateService.instant('Bez typu spisu');
      case StatisticsDimension.STORAGE_UNIT:
        return this.translateService.instant('Není uloženo');
      case StatisticsDimension.REGISTRY_OFFICE:
        return this.translateService.instant('Bez typu spisu');
      // todo(mh) more dimensions will be added in the future
      default:
        return '';
    }
  }

  transformYaxisDataset(data: PointDto[]) {
    if (this.statisticConfig.id === IczStatistic.NEW_COMPONENT_COUNT) {
      return data.map(point => transformFileSize(Number(point.y), FileSizeUnit.KB, false));
    } else {
      return data.map(point => Number(point.y));
    }
  }

  transformXaxisDataset(data: PointDto[]) {
    if (this.statisticConfig.id === IczStatistic.DOCUMENT_COUNT || this.statisticConfig.id === IczStatistic.FILE_COUNT) {
      return data.map(point => this.translateService.instant(locateOptionByValue(this.documentFiltersDataService.objectClassOptions, point.x)!.label));
    } else if (this.statisticConfig.id === IczStatistic.FILE_BY_PERMISSION_COUNT || this.statisticConfig.id === IczStatistic.DOCUMENT_BY_PERMISSION_COUNT) {
      return data.map(point => this.translateService.instant(point.x));
    } else {
      return data.map(point => point.x);
    }
  }

  loadLineChartData(dateMin: string, dateMax: string) {
    this.loadingIndicatorService.doLoading(
      this.apiStatisticsService.statisticsGetXyAxesAggregation({
        body: {
          statistic: this.statisticConfig.statisticKey,
          watchedPeriodFromDate: dateMin,
          watchedPeriodToDate: dateMax,
          includePrecedingPeriod: false
        }
      }),
      this, 'lineChart'
    ).subscribe(lineChartData => {
      if (this.lineChartConfig.datasets.length > 0) {
        this.lineChartConfig.xAxisRange = [dateMin, dateMax];
        this.lineChartConfig.datasets[0].data = this.transformYaxisDataset(lineChartData.watchedPeriodPoints);
        this.lineChartConfig.datasets[0].fullDataSet = lineChartData.watchedPeriodPoints.map(point => ({x: point.x, y: point.y}));
        this.lineChartConfig = {...this.lineChartConfig};
      } else {
        this.lineChartConfig = {
          xAxisRange: [dateMin, dateMax],
          datasets: [
            {
              label: this.translateService.instant('Období'),
              borderColor: ChartElementColor.BLUE,
              fullDataSet: lineChartData.watchedPeriodPoints.map(point => ({x: point.x, y: point.y})),
              fill: true,
              data: this.transformYaxisDataset(lineChartData.watchedPeriodPoints),
            }
          ]
        };
      }
    });
  }

  loadBarChartData() {
    this.loadingIndicatorService.doLoading(
      this.apiStatisticsService.statisticsGetXyAxesAggregation({
        body: {
          statistic: this.statisticConfig.statisticKey,
          includePrecedingPeriod: false
        }
      }),
      this, 'barChart'
    ).subscribe(barChartData => {
      if (this.barChartConfig.datasets.length > 0) {
        this.barChartConfig.labels = this.transformXaxisDataset(barChartData.watchedPeriodPoints);
        this.barChartConfig.datasets[0].data = this.transformYaxisDataset(barChartData.watchedPeriodPoints);
        this.barChartConfig = {...this.barChartConfig};
      } else {
        this.barChartConfig = {
          labels: this.transformXaxisDataset(barChartData.watchedPeriodPoints),
          datasets: [
            {
              label: this.translateService.instant('Počet'),
              barPercentage: 0.3,
              borderWidth: 1,
              data: this.transformYaxisDataset(barChartData.watchedPeriodPoints),
            }
          ]
        };
      }
    });
  }

  loadSecondLineChartData(dateMin: string, dateMax: string) {
    this.loadingIndicatorService.doLoading(
      this.apiStatisticsService.statisticsGetXyAxesAggregation({
        body: {
          statistic: this.statisticConfig.statisticKey,
          watchedPeriodFromDate: dateMin,
          watchedPeriodToDate: dateMax,
          includePrecedingPeriod: false
        }
      }),
      this, 'statistic-table'
    ).subscribe(lineChartData => {
      if (this.lineChartConfig.datasets[1]) {
        this.lineChartConfig.datasets[1].data = this.transformYaxisDataset(lineChartData.watchedPeriodPoints);
        this.lineChartConfig.datasets[1].fullDataSet = lineChartData.watchedPeriodPoints.map(point => ({x: point.x, y: point.y}));
      } else {
        this.lineChartConfig.datasets.push({
          label: this.translateService.instant('Porovnat s obdobím'),
          borderColor: ChartElementColor.GREY,
          fullDataSet: lineChartData.watchedPeriodPoints.map(point => ({x: point.x, y: point.y})),
          fill: false,
          data: this.transformYaxisDataset(lineChartData.watchedPeriodPoints)
        });
      }

      if (this.lineChartConfig.datasets[0].data.length < this.lineChartConfig.datasets[1].data.length) {
        const offset = this.lineChartConfig.datasets[1].data.length - this.lineChartConfig.datasets[0].data.length;
        const adjustedDateMax = new Date();
        adjustedDateMax.setDate(this.watchedPeriodToDate.getDate() + offset);
        this.lineChartConfig.xAxisRange = [this.watchedPeriodFromDate.toISOString(), adjustedDateMax.toISOString()];
      }
      this.lineChartConfig = {...this.lineChartConfig};
    });
  }

  loadTableData(dateMin: string, dateMax: string, searchParams: SearchParams) {
    searchParams.size = 1000;
    this.loadingIndicatorService.doLoading(
      this.statisticsSearchService.findStatisticsData(searchParams, {
        dimensions: this.selectedDimensions,
        statistic: this.statisticConfig.statisticKey,
        watchedPeriodFromDate: this.statisticConfig.isTimeSpecific ? dateMin : undefined,
        watchedPeriodToDate: this.statisticConfig.isTimeSpecific ? dateMax : undefined,
      }),
      this, 'lineChart'
    ).pipe(switchMap(
      data => {
        if (this.selectedDimensions.includes(StatisticsDimension.STORAGE_UNIT) && data.rows.length > 0) {
          const storageUnitsToFind = removeDuplicates(data.rows.map(r => r.dimensions[StatisticsDimension.STORAGE_UNIT])).filter(id => !isNil(id));
          if (storageUnitsToFind.length > 0) {
            return this.storageUnitSearchService.findAllStorageUnits({...getDefaultSearchParams(), size: 1000, filter: [
                {
                  fieldName: 'id',
                  operator: FilterOperator.inSet,
                  value: String(removeDuplicates(data.rows.map(r => r.dimensions[StatisticsDimension.STORAGE_UNIT])).filter(id => !isNil(id))),
                }
              ]}).pipe(
              map(units => {
                data.rows.forEach(r => {
                  if (!isNil(r.dimensions[StatisticsDimension.STORAGE_UNIT])) {
                    const unit = units.content.find(unit => unit.id === r.dimensions[StatisticsDimension.STORAGE_UNIT]);
                    if (unit) {
                      r.dimensions[StatisticsDimension.STORAGE_UNIT] = unit.name;
                    }
                  }
                });
                return data;
              })
            );
          } else {
            return of(data);
          }
        } else {
          return of(data);
        }
      }
    )).subscribe(statisticsTableData => {
      this.dataSource.setDataFactory(() => statisticsTableData!.rows);
    });
  }

  exportToCSV() {
    // connect to export to CSV
  }

  private setColumns() {
    const entityClassOptionsTree$ = this.documentFiltersDataService.entityClassOptionsTree().pipe(shareReplay(1));
    const classificationSchemeOptions$ = this.documentFiltersDataService.classificationSchemeOptions().pipe(shareReplay(1));
    const securityCategoryOptions$ = this.documentFiltersDataService.securityCategoryOptions().pipe(shareReplay(1));
    const disposalScheduleOptions$ = this.documentFiltersDataService.disposalScheduleOptions().pipe(shareReplay(1));
    const functionalPositionTreeOptions$ = this.documentFiltersDataService.functionalPositionTreeOptions.pipe(shareReplay(1));
    const organizationalUnitTreeOptions$ = this.documentFiltersDataService.organizationalUnitTreeOptions.pipe(shareReplay(1));
    const documentTypeOptions$ = this.documentFiltersDataService.documentTypeOptions().pipe(shareReplay(1));
    const fileTypeOptions$ = this.documentFiltersDataService.fileTypeOptions().pipe(shareReplay(1));
    const entityClassColumnDef = {
      id: StatisticsDimension.ENTITY_CLASS,
      label: 'Věcná skupina',
      filterType: FilterType.CODEBOOK,
      list$: entityClassOptionsTree$,
      filterConfig: getEntityClassFilterConfig(this.templatePool),
      disableSort: true,
      displayed: this.selectedDimensions.includes(StatisticsDimension.ENTITY_CLASS)
    };
    setupEntityClassFilterSubfilters(
      (entityClassColumnDef as unknown as CodebookFilterDefinition).filterConfig!,
      classificationSchemeOptions$,
    );

    const documentAndFileColumns: ColumnDefinition<StatisticsTableColumn>[] = [
      entityClassColumnDef,
      {
        id: StatisticsDimension.OBJECT_CLASS,
        label: 'Druh objektu',
        filterType: FilterType.ENUM,
        list: this.documentFiltersDataService.objectClassOptions,
        minWidth: 200,
        disableSort: true,
        displayed: this.selectedDimensions.includes(StatisticsDimension.OBJECT_CLASS)
      },
      {
        id: StatisticsDimension.ENTITY_STATE,
        label: 'Stav objektu',
        filterType: FilterType.ENUM,
        minWidth: 200,
        list: this.documentFiltersDataService.entityStateOptions,
        disableSort: true,
        displayed: this.selectedDimensions.includes(StatisticsDimension.ENTITY_STATE)
      },
      {
        id: StatisticsDimension.DISPOSAL_SCHEDULE,
        label: 'Skartační režim',
        minWidth: 200,
        filterType: FilterType.CODEBOOK,
        list$: disposalScheduleOptions$,
        disableSort: true,
        filterConfig: getDisposalScheduleFilterConfig(),
        displayed: this.selectedDimensions.includes(StatisticsDimension.DISPOSAL_SCHEDULE)
      },
      {
        id: StatisticsDimension.OWNER_FP,
        label: 'Zpracovatel',
        minWidth: 200,
        filterType: FilterType.CODEBOOK,
        list$: functionalPositionTreeOptions$,
        disableSort: true,
        filterConfig: {
          originId: 'fp',
          isTree: true,
          treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID
        },
        displayed: this.selectedDimensions.includes(StatisticsDimension.OWNER_FP)
      },
      {
        id: StatisticsDimension.OWNER_ORG_UNIT,
        label: 'OJ zpracovatele',
        minWidth: 200,
        filterType: FilterType.CODEBOOK,
        list$: organizationalUnitTreeOptions$,
        disableSort: true,
        filterConfig: {
          originId: 'ou',
          isTree: true,
          treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID
        },
        displayed: this.selectedDimensions.includes(StatisticsDimension.OWNER_ORG_UNIT)
      },
      {
        id: StatisticsDimension.SECURITY_CATEGORY,
        label: 'Bezpečnostní kategorie',
        minWidth: 200,
        filterType: FilterType.CODEBOOK,
        list$: securityCategoryOptions$,
        disableSort: true,
        filterConfig: {
          isTree: true
        },
        displayed: this.selectedDimensions.includes(StatisticsDimension.SECURITY_CATEGORY)
      },
      {
        id: StatisticsDimension.STORAGE_UNIT,
        label: 'Ukládací jednotka',
        minWidth: 200,
        filterType: FilterType.PAGE_SELECTOR,
        filterDataSource: new StorageUnitsDatasource(this.storageUnitSearchService,
          {
            isUnitView: false,
            orgUnitId: null
          }),
        disableSort: true,
        filterConfig: {
          filterTableId: 'statisticsStorageUnitFilterTable',
          modelLabelKey: 'name',
          modelValueKey: 'id',
          defaultFilterColumns: [],
          tableFilterTitle: 'Vyberte ukládací jednotku',
          tableSchema: new TableColumnsData<string>(getDocumentsColumnsData(
            StorageUnitView.STORAGE_UNITS_TABLE,
            this.documentFiltersDataService,
            this.templatePool,
            false,
            getStaticDocumentsColumnsForView(StorageUnitView.STORAGE_UNITS_TABLE)
          ))
        },
        displayed: this.selectedDimensions.includes(StatisticsDimension.STORAGE_UNIT)
      },
      {
        id: StatisticsDimension.BUSINESS_RULE,
        label: 'Business důvod',
        filterType: FilterType.NONE,
        minWidth: 200,
        disableSort: true,
        displayed: this.selectedDimensions.includes(StatisticsDimension.BUSINESS_RULE)
      },
      {id: 'count', label: 'Počet objektů', filterType: FilterType.NONE, minWidth: 100, disableSort: true},
      {id: 'inRegistryOffice', label: 'Uloženo ve spisovně', filterType: FilterType.BOOLEAN, minWidth: 100, disableSort: true, displayed: false},
      {id: 'size', label: 'Velikost', filterType: FilterType.NONE, disableSort: true, minWidth: 100, displayed: (this.statisticConfig.statisticKey === Statistic.DOCUMENTS_SIZE_COUNT || this.statisticConfig.statisticKey === Statistic.FILES_SIZE_COUNT)},
      {id: 'createdAt', label: 'Datum vzniku', filterType: FilterType.DATE, disableSort: true, displayed: false, filterConfig: {customFilterId: 'auditInfo.createdAt'}},
      {id: 'settlementDate', label: 'Datum vyřízení', filterType: FilterType.DATE, disableSort: true, displayed: false, filterConfig: {customFilterId: 'settlement.settlementDate'}},
      {id: 'resolutionDate', label: 'Termín vyřízení', filterType: FilterType.DATE, disableSort: true, displayed: false},
    ];

    if (this.documentStatistics.includes(this.statisticConfig.statisticKey)) {
      this.columnsData = new TableColumnsData<StatisticsTableColumn>([
        {
          id: StatisticsDimension.DOCUMENT_TYPE,
          label: 'Typ dokumentu',
          minWidth: 200,
          filterType: FilterType.CODEBOOK,
          list$: documentTypeOptions$,
          disableSort: true,
          filterConfig: {
            isTree: true
          },
          displayed: this.selectedDimensions.includes(StatisticsDimension.DOCUMENT_TYPE)
        },
        ...documentAndFileColumns,
      ]);
    } else if (this.fileStatistics.includes(this.statisticConfig.statisticKey)) {
      this.columnsData = new TableColumnsData<StatisticsTableColumn>([
        {
          id: StatisticsDimension.FILE_TYPE,
          label: 'Typ spisu',
          minWidth: 200,
          filterType: FilterType.CODEBOOK,
          list$: fileTypeOptions$,
          disableSort: true,
          filterConfig: {
            isTree: true
          },
          displayed: this.selectedDimensions.includes(StatisticsDimension.FILE_TYPE)
        },
        {id: 'closureDate', label: 'Datum uzavření', filterType: FilterType.DATE, disableSort: true, displayed: false},
        ...documentAndFileColumns,
      ]);
    } else if (this.componentStatistics.includes(this.statisticConfig.statisticKey)) {
      this.columnsData = new TableColumnsData<StatisticsTableColumn>([
        {
          id: StatisticsDimension.PUID,
          label: 'Formát',
          filterType: FilterType.NONE,
          disableSort: true,
          minWidth: 100,
          displayed: this.selectedDimensions.includes(StatisticsDimension.PUID)
        },
        // todo(mh) will be added
        /*{
          id: StatisticsDimension.SIZE,
          label: 'Velikost',
          filterType: FilterType.NONE,
          disableSort: true,
          filterConfig: {
            customFilterId: 'fileSize'
          },
          displayed: this.selectedDimensions.includes(StatisticsDimension.SIZE)
        },*/
        {id: 'component.createdAt', label: 'Datum vzniku', filterType: FilterType.DATE, disableSort: true, displayed: false, filterConfig: {customFilterId: 'components.auditInfo.createdAt'}},
        {id: 'component.isFinal', label: 'Finální', filterType: FilterType.BOOLEAN, disableSort: true, displayed: false, filterConfig: {customFilterId: 'components.isFinal'}},
        {id: 'count', label: 'Počet objektů', minWidth: 100, filterType: FilterType.NONE, disableSort: true},
        {id: 'size', label: 'Velikost', minWidth: 100, filterType: FilterType.NONE, disableSort: true},
      ]);
    } else if (this.storingUnitStatistics.includes(this.statisticConfig.statisticKey)) {
      this.columnsData = new TableColumnsData<StatisticsTableColumn>([
        {
          id: StatisticsDimension.ENTITY_STATE,
          label: 'Stav objektu',
          minWidth: 200,
          filterType: FilterType.ENUM,
          list: this.documentFiltersDataService.entityStateOptions,
          disableSort: true,
          displayed: this.selectedDimensions.includes(StatisticsDimension.ENTITY_STATE)
        },
        {
          id: StatisticsDimension.HANDED_OVER_BY_FP,
          label: 'Předávající',
          minWidth: 200,
          filterType: FilterType.CODEBOOK,
          list$: functionalPositionTreeOptions$,
          disableSort: true,
          filterConfig: {
            originId: 'fp',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID
          },
          displayed: this.selectedDimensions.includes(StatisticsDimension.HANDED_OVER_BY_FP)
        },
        {
          id: StatisticsDimension.HANDED_OVER_BY_ORG_UNIT,
          label: 'OJ předávajícího',
          minWidth: 200,
          filterType: FilterType.CODEBOOK,
          list$: organizationalUnitTreeOptions$,
          disableSort: true,
          filterConfig: {
            originId: 'ou',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID
          },
          displayed: this.selectedDimensions.includes(StatisticsDimension.HANDED_OVER_BY_ORG_UNIT)
        },
        {
          id: StatisticsDimension.REGISTRY_OFFICE,
          label: 'Spisovna',
          minWidth: 200,
          filterType: FilterType.CODEBOOK,
          list$: this.codebookService.registryOffices().pipe(
            map(office => office.map(nameDtoToOptionWithId(false))),
          ),
          disableSort: true,
          displayed: this.selectedDimensions.includes(StatisticsDimension.REGISTRY_OFFICE)
        },
        {id: 'count', label: 'Počet objektů', minWidth: 100, filterType: FilterType.NONE, disableSort: true},
      ]);
    } else if (this.statisticConfig.statisticKey === Statistic.ENTITY_CLASSES_OF_VALID_CLASSIFICATION_SCHEME_RANGE_COUNT) {
      this.columnsData = new TableColumnsData<StatisticsTableColumn>([
        {
          id: StatisticsDimension.ENTITY_CLASS_TYPE,
          label: 'Typ věcné skupiny',
          filterType: FilterType.ENUM,
          list: enumToOptions('entityClassType', EntityClassType),
          minWidth: 200,
          disableSort: true,
          displayed: this.selectedDimensions.includes(StatisticsDimension.ENTITY_CLASS_TYPE)
        },
        {id: 'count', label: 'Počet objektů', minWidth: 100, filterType: FilterType.NONE, disableSort: true},
      ]);
    } else {
      this.columnsData = new TableColumnsData<StatisticsTableColumn>([]);
    }
    this.changeDetectorRef.detectChanges();
  }

  dimensionsChanged(dimensions: StatisticsDimension[], resetDatasource: boolean) {
    let diff: StatisticsDimension[];
    if (this.selectedDimensions.length > dimensions.length) {
      // dimension was removed
      diff = this.selectedDimensions.filter(dim => !dimensions.includes(dim));
    } else {
      // dimension was added
      diff = dimensions.filter(dim => !this.selectedDimensions.includes(dim));
    }
    this.selectedDimensions = [...dimensions];
    let storedDimensions = this.userSettingsService.getParsedValue(LocalStorageKey.USER_STATISTIC_TABLE_DIMENSIONS) as Record<string, StatisticsDimension[]>;
    if (storedDimensions) {
      storedDimensions[this.tableId] = this.selectedDimensions;
    } else {
      storedDimensions = {
        [this.tableId]: this.selectedDimensions
      };
    }
    this.userSettingsService.setParsedValue(LocalStorageKey.USER_STATISTIC_TABLE_DIMENSIONS, storedDimensions);

    if (diff[0]) {
      this.tableComponent.toggleColumn(diff[0]);
    }

    if (dimensions.length > 0) {
      this.loadTableData(this.watchedPeriodFromDate.toISOString(), this.watchedPeriodToDate.toISOString(), this.currentSearchParams);
    } else {
      this.dataSource.setDataFactory(() => []);
    }
  }

  initialDataLoad() {
    if (this.statisticConfig && this.period && !this.initialDataLoaded) {
      this.selectedDate = new Date();
      this.initialDataLoaded = true;
      const storedDimensions = this.userSettingsService.getParsedValue(LocalStorageKey.USER_STATISTIC_TABLE_DIMENSIONS) as Record<string, StatisticsDimension[]>;
      if (storedDimensions && Array.isArray(storedDimensions[this.tableId]) && storedDimensions[this.tableId].length > 0) {
        this.selectedDimensions = storedDimensions[this.tableId];
      } else {
        this.selectedDimensions = [this.statisticConfig.defaultDimension];
      }

      this.watchedPeriodFromDate = new Date();
      this.watchedPeriodToDate = new Date();
      const intervalToDate = new Date();
      intervalToDate.setDate(intervalToDate.getDate() + 1);
      const fromDate = new Date();
      if (this.period === TrendPeriod.LAST_7_DAYS) {
        this.watchedPeriodFromDate.setDate(fromDate.getDate() - 6);
      }
      else if (this.period === TrendPeriod.LAST_30_DAYS) {
        this.watchedPeriodFromDate.setDate(fromDate.getDate() - 29);
      }

      this.form.get('firstPeriod')!.setValue(FilterItemValue.fromFilterItem({
        id: 'firstPeriod',
        value: 'interval',
        subValues: [
          {
            operator: FilterOperator.greaterOrEqual,
            value: formatAsLocalIsoDate(this.watchedPeriodFromDate),
            and: true
          },
          {
            operator: FilterOperator.less,
            value: formatAsLocalIsoDate(intervalToDate),
            and: true
          }
        ]
      } as FormFilterItem));

      this.setColumns();
      this.loadChartAndTable();
    }
  }

  loadChartAndTable() {
    if (this.statisticConfig.allowLineChart) {
      this.loadLineChartData(this.watchedPeriodFromDate.toISOString(), this.watchedPeriodToDate.toISOString());
    } else if (this.statisticConfig.allowBarChart) {
      this.loadBarChartData();
    }
    this.loadTableData(this.watchedPeriodFromDate.toISOString(), this.watchedPeriodToDate.toISOString(), this.currentSearchParams);
  }

  ngOnInit() {
    this.form.get('firstPeriod')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(period => {
      if (period && period.value) {
        if (period.value === 'interval') {
          this.watchedPeriodFromDate = new Date(String(period.subValues![0].value));
          const intervalToDate = new Date(String(period.subValues![1].value));
          intervalToDate.setDate(intervalToDate.getDate() - 1);
          this.watchedPeriodToDate = intervalToDate;
        } else if (isValidIczDurationString(period.value)) {
          const parsedValue = iczParseDurationString(period.value as string, getTodayMidnight(), this.translateService.currentLang);
          if (isDateRange(parsedValue)) {
            this.watchedPeriodFromDate = parsedValue.start!;
            this.watchedPeriodToDate = parsedValue.end!;
          } else {
            this.watchedPeriodFromDate = parsedValue;
            this.watchedPeriodToDate = parsedValue;
          }
        } else {
          this.watchedPeriodFromDate = new Date(String(period.value));
          this.watchedPeriodToDate = new Date(String(period.value));
        }
        this.loadChartAndTable();
        this.showTableAndGraph = true;
      } else {
        this.showTableAndGraph = false;
      }
    });

    this.form.get('secondPeriod')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(period => {
      let secondWatchedPeriodFromDate;
      let secondWatchedPeriodToDate;
      if (period && period.value) {
        if (period.value === 'interval') {
          secondWatchedPeriodFromDate = new Date(String(period.subValues![0].value)).toISOString();
          const intervalToDate = new Date(String(period.subValues![1].value));
          intervalToDate.setDate(intervalToDate.getDate() - 1);
          secondWatchedPeriodToDate = intervalToDate.toISOString();
        } else if (isValidIczDurationString(period.value)) {
          const parsedValue = iczParseDurationString(period.value as string, getTodayMidnight(), this.translateService.currentLang);
          if (isDateRange(parsedValue)) {
            secondWatchedPeriodFromDate = parsedValue.start!.toISOString();
            secondWatchedPeriodToDate = parsedValue.end!.toISOString();
          } else {
            secondWatchedPeriodFromDate = parsedValue.toISOString();
            secondWatchedPeriodToDate = parsedValue.toISOString();
          }
        } else {
          secondWatchedPeriodFromDate = new Date(String(period.value)).toISOString();
          secondWatchedPeriodToDate = new Date(String(period.value)).toISOString();
        }
        this.loadSecondLineChartData(secondWatchedPeriodFromDate, secondWatchedPeriodToDate);
      } else {
        if (this.lineChartConfig.datasets.length === 2) {
          this.lineChartConfig.datasets.splice(1, 1);
        }
        this.lineChartConfig = {...this.lineChartConfig};
      }
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    this.initialDataLoad();
  }

  ngOnDestroy() {
    if (this.tableComponent) {
      this.tableComponent.setDisplayedColumns(true);
    }
    setTimeout(() => {
      this.dashboardLayoutService.showDashboardHeader$.next(true);
    });
  }
}
