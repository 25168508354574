import {DestroyRef, inject, Injectable} from '@angular/core';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {ApiStorageUnitService, DocumentDto, FileDto, StorageUnitDto} from '|api/document';
import {Button} from '../../../button-collection/button-collection.component';
import {map, Observable, of} from 'rxjs';
import {
  BulkOperationValidationService,
  OperationValidator
} from '../../../../services/bulk-operation-validation.service';
import {GeneralAuthorizedOperation} from '../../permissions/permissions.utils';
import {
  StorageUnitToastService,
  StorageUnitToastType
} from '../../../../core/services/notifications/storage-unit-toast.service';
import {getObjectIcon, getObjectLabel, getSuperclassEntityType, isDocumentEntity} from '../../shared-document.utils';
import {AuthorizedEntityType, StorageUnitAuthorizedOperation} from '|api/core';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {TranslateService} from '@ngx-translate/core';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {StorageUnitToolbarDisablers} from './storage-unit-toolbar.disablers';
import {AbstractDocumentDialogsManagerService} from '../../../../services/abstract-document-dialogs-manager.service';
import {ApplicationConfigService} from '../../../../core/services/config/application-config.service';
import {InternalNotificationMessageCode} from '../../../../core/services/notifications/internal-notification.enum';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {ObjectClass} from '|api/commons';
import {RegistryOfficeTransferDisablers} from './registry-office-transfer.disablers';

export enum StorableObjectToolbarAction {
  OBJECT_ADDED_TO_STORAGE_UNIT = 'OBJECT_ADDED_TO_STORAGE_UNIT',
  OBJECT_REMOVED_FROM_STORAGE_UNIT = 'OBJECT_REMOVED_FROM_STORAGE_UNIT',
  OBJECT_MOVED_BETWEEN_STORAGE_UNITS = 'OBJECT_MOVED_BETWEEN_STORAGE_UNITS',
}

export enum StorableObjectView {
  DOCUMENT_DETAIL = 'DOCUMENT_DETAIL',
  FILE_DETAIL = 'FILE_DETAIL',
  SETTLED_OBJECTS_LISTING = 'SETTLED_OBJECTS_LISTING',
  STORAGE_UNIT_CONTENTS = 'STORAGE_UNIT_CONTENTS',
}

export type StorableObject = DocumentDto | FileDto;

@Injectable()
export class StorableObjectToolbarButtonsService extends ToolbarProvider<DocumentDto | FileDto, StorableObjectToolbarAction, StorableObjectView> {

  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private globalLoading = inject(GlobalLoadingIndicatorService);
  private storageUnitToastService = inject(StorageUnitToastService);
  private documentDialogService = inject(AbstractDocumentDialogsManagerService);
  private apiStorageUnitService = inject(ApiStorageUnitService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private applicationConfigService = inject(ApplicationConfigService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private destroyRef = inject(DestroyRef);
  private translateService = inject(TranslateService);

  constructor() {
    super();

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_INSERT_TO_STORAGE_UNIT_SUCCESS).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
      this.announceActionCompleted(StorableObjectToolbarAction.OBJECT_ADDED_TO_STORAGE_UNIT);
    });

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_WITHDRAW_FROM_STORAGE_UNIT_SUCCESS).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
      this.announceActionCompleted(StorableObjectToolbarAction.OBJECT_REMOVED_FROM_STORAGE_UNIT);
    });

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_MOVE_BETWEEN_STORAGE_UNITS_SUCCESS).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.announceActionCompleted(StorableObjectToolbarAction.OBJECT_MOVED_BETWEEN_STORAGE_UNITS);
    });
  }

  override getToolbarButtons(
    selection: Nullable<(DocumentDto | FileDto)[]>,
    viewType: StorableObjectView,
    storageUnit?: StorageUnitDto,
  ): Observable<Button[]> {
    let buttons$: Observable<AuthorizedButton[]>;

    if (viewType === StorableObjectView.SETTLED_OBJECTS_LISTING || viewType === StorableObjectView.DOCUMENT_DETAIL || viewType === StorableObjectView.FILE_DETAIL) {
      const isObjectInRegistryOffice = Boolean(selection?.[0]?.registryOfficeTransfer?.registryOfficeTransferState);
      const isObjectInStorageUnit = !isNil(selection?.[0]?.storageUnitId);

      if (isObjectInRegistryOffice) {
        buttons$ = of([]);
      }
      else {
        buttons$ = of([
          {
            label: 'Ukládání',
            icon: 'storage_unit_add',
            submenuItems: [
              isObjectInStorageUnit ? this.getRemoveButton(selection) : this.getInsertButton(selection, viewType),
            ]
          }
        ] as AuthorizedButton[]);
      }
    }
    else if (viewType === StorableObjectView.STORAGE_UNIT_CONTENTS) {
      buttons$ = of([
        this.getRemoveFromCurrentStorageUnitButton(selection, viewType, storageUnit!),
        this.getMoveFromCurrentStorageUnitButton(selection, viewType, storageUnit!),
      ]);
    }
    else {
      buttons$ = of([]);
    }

    if (selection?.length === 1) {
      return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
        {
          [AuthorizedEntityType.STORAGE_UNIT]: selection[0]?.storageUnitId,
        },
        buttons$
      );
    }
    else {
      return buttons$.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  private getInsertButton(selection: Nullable<Array<StorableObject>>, viewType: StorableObjectView): AuthorizedButton {
    return {
      label: 'Vložit do ukládací jednotky',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        StorageUnitToolbarDisablers.isSeparateDocumentStoringUnavailable(selection, this.applicationConfigService.storeDocumentsSeparatelyUntil),
      ],
      action: () => this.onAddToStorageUnitClick(
        selection!,
        [
          StorageUnitToolbarDisablers.isSeparateDocumentStoringUnavailable(selection, this.applicationConfigService.storeDocumentsSeparatelyUntil),
        ],
        []
      ),
    };
  }

  private getRemoveFromCurrentStorageUnitButton(selection: Nullable<Array<StorableObject>>, viewType: StorableObjectView, storageUnit: StorageUnitDto): AuthorizedButton {
    return {
      label: 'Vyjmout z ukládací jednotky',
      authorizedOperations: [StorageUnitAuthorizedOperation.STORAGE_UNIT_ENTITY_WITHDRAW],
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        StorageUnitToolbarDisablers.isStorageUnitBlocked([storageUnit!]),
        RegistryOfficeTransferDisablers.isEntityInRegistryOffice([storageUnit!]),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
      ],
      action: () => this.onWithdrawFromStorageUnitClick(
        selection!
      ),
    };
  }

  private getRemoveButton(selection: Nullable<Array<DocumentDto | FileDto>>): AuthorizedButton {
    return {
      label: 'Vyjmout z ukládací jednotky',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
      ],
      action: () => this.onWithdrawFromStorageUnitClick(
        selection!
      ),
    };
  }

  private getMoveFromCurrentStorageUnitButton(selection: Nullable<Array<StorableObject>>, viewType: StorableObjectView, storageUnit: StorageUnitDto): AuthorizedButton {
    return {
      label: 'Přesun z jednotky',
      icon: 'handover_to_distribution',
      authorizedOperations: [StorageUnitAuthorizedOperation.STORAGE_UNIT_ENTITY_MOVE],
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        StorageUnitToolbarDisablers.isStorageUnitBlocked([storageUnit!]),
        RegistryOfficeTransferDisablers.isEntityInRegistryOffice([storageUnit!]),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
      ],
      action: () => this.onMoveToStorageUnitClick(
        selection!,
        storageUnit,
      ),
    };
  }

  private onAddToStorageUnitClick(selectionData: StorableObject[], validators?: OperationValidator<StorableObject>[], authorizedOperations?: GeneralAuthorizedOperation[]) {
    this.bulkOperationValidationService.validateEsslObjects<StorableObject>(
      {
        dialogWarningLabel: 'Některé objekty ({{errorCount}}) není možné vložit do ukládaci jednotky. Operace vkládaní do ukládací jednotky bude provedena pro vyhovující objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: validators ?? [],
        esslObjects: selectionData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openStorageUnitInsertDialog(validatedObjects);
      }
    });
  }

  private onMoveToStorageUnitClick(selectionData: StorableObject[], storageUnit: StorageUnitDto) {
    this.documentDialogService.openStorageUnitInsertDialog(selectionData, storageUnit.id).subscribe(result => {
      if (result) {
        this.storageUnitToastService.dispatchSimpleWarningToast(StorageUnitToastType.STORAGE_UNIT_MOVE_STARTED);
      }
    });
  }

  private onWithdrawFromStorageUnitClick(selectionData: StorableObject[]) {
    this.globalLoading.doLoading(
      this.apiStorageUnitService.storageUnitBulkWithdrawEntities({
        id: selectionData[0].storageUnitId!,
        body: selectionData.map(sd => ({id: sd.id!, entityType: getSuperclassEntityType(sd.entityType!)}))
      })
    ).subscribe({
      next: _ => {
        this.storageUnitToastService.dispatchSimpleWarningToast(StorageUnitToastType.STORAGE_UNIT_WITHDRAW_STARTED);
      },
      error: _ => {
        this.storageUnitToastService.dispatchSimpleErrorToast(StorageUnitToastType.STORAGE_UNIT_WITHDRAW_ERROR);
      }
    });
  }

  private onAddToCurrentStorageUnitClick(storageUnit: StorageUnitDto) {
    this.documentDialogService.openCurrentStorageUnitInsertDialog(storageUnit).subscribe(result => {
      if (result) {
        this.storageUnitToastService.dispatchSimpleWarningToast(StorageUnitToastType.STORAGE_UNIT_INSERT_STARTED);
      }
    });
  }

  esslObjectToValidationObject(object: StorableObject) {
    return {
      entityId: object.id,
      authorizedEntityType: isDocumentEntity(object.entityType) ? AuthorizedEntityType.DOCUMENT : AuthorizedEntityType.FILE,
      entityIcon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      entityData: object,
      entityName: getObjectLabel(this.translateService, object),
    };
  }

}
