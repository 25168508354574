import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT} from '../api';
import {
  ApiComponentService,
  DigitalComponentCompleteDto,
  MediumComponentCreateDto,
  MediumComponentDto,
  PaperComponentCreateDto,
  PaperComponentDto,
  PhysicalItemComponentCreateDto,
  PhysicalItemComponentDto
} from '|api/component';
import {ApiConsignmentComponentService} from '|api/sad';
import {SearchApiService} from './search-api.service';
import {FilterOperator, FilterParam, Page, SearchParams} from '@icz/angular-table';
import {CirculationActivityDto} from '|api/flow';
import {CirculationSearchService} from './circulation-search.service';
import {map} from 'rxjs/operators';
import {CirculationActivityState} from '|api/commons';

export type EsslAnalogComponentDto = PaperComponentDto | MediumComponentDto | PhysicalItemComponentDto;
export type EsslAnalogComponentCreateDto = PaperComponentCreateDto | MediumComponentCreateDto | PhysicalItemComponentCreateDto;
export type EsslComponentDto = DigitalComponentCompleteDto | EsslAnalogComponentDto;
export type EsslComponentDtoWithActivity = EsslComponentDto & {circulationActivity?: Nullable<CirculationActivityDto>};

@Injectable({
  providedIn: 'root',
})
export class EsslComponentSearchService extends SearchApiService {

  private circulationSearchService = inject(CirculationSearchService);

  prepareSearchParamsforFind(
    searchParams: SearchParams,
    documentId?: number,
    additionalFilters?: FilterParam[],
  ): SearchParams {
    const hasFulltextFilterDefined = Boolean(searchParams.fulltextSearchTerm?.trim());

    if (additionalFilters?.length) {
      for (const additionalFilter of additionalFilters) {
        if (!searchParams.filter.find(f => f.fieldName === additionalFilter.fieldName)) {
          searchParams.filter.push(additionalFilter);
        }
      }
    }
    if (documentId) {
      searchParams.filter.push({operator: FilterOperator.equals, fieldName: 'documentId', value: String(documentId)});
    }
    if (hasFulltextFilterDefined) {
      if (!searchParams.filter.find(f => f.fieldName === 'label')) {
        searchParams.filter.push({
          operator: FilterOperator.contains,
          fieldName: 'label',
          value: searchParams.fulltextSearchTerm,
          isCaseInsensitive: true,
          or: true,
        });
      }
      if (!searchParams.filter.find(f => f.fieldName === 'description')) {
        searchParams.filter.push({
          operator: FilterOperator.contains,
          fieldName: 'description',
          value: searchParams.fulltextSearchTerm,
          isCaseInsensitive: true,
          or: true,
        });
      }
    }

    return searchParams;
  }

  findEsslComponents(
    searchParams: SearchParams,
    documentId?: number,
    additionalFilters?: FilterParam[],
  ): Observable<Page<EsslComponentDto>> {
    return this.searchApi<Page<EsslComponentDto>>(this.prepareSearchParamsforFind(searchParams, documentId, additionalFilters), CORE_MICROSERVICE_ROOT + ApiComponentService.ComponentSearchPath);
  }

  findEsslComponentsWithActivities(
    searchParams: SearchParams,
    documentId: number,
    additionalFilters?: FilterParam[],
  ): Observable<Page<EsslComponentDtoWithActivity>> {
    return this.searchApi<Page<EsslComponentDto>>(this.prepareSearchParamsforFind(searchParams, documentId, additionalFilters), CORE_MICROSERVICE_ROOT + ApiComponentService.ComponentSearchPath)
      .pipe(
        switchMap(componentsPage => {
          const componentIds = componentsPage.content.map(c => c.id!);
          const circulationSearchParams: SearchParams = {
            filter: [
              {
                fieldName: 'componentId',
                operator: FilterOperator.inSet,
                value: componentIds.toString(),
              },
              {
                fieldName: 'activityState',
                operator: FilterOperator.equals,
                value: CirculationActivityState.IN_PROGRESS,
              },
            ],
            size: searchParams.size,
            sort: [],
            page: 0,
          };
          return this.circulationSearchService.findActivitiesForDocument(circulationSearchParams, documentId).pipe(map(tasksPage => {
            const componentsWithTasks: EsslComponentDtoWithActivity[] = [];
            componentsPage.content.forEach(esslComponent => {
              const task = tasksPage.content.find(t => t.componentId === esslComponent.id);
              if (task) {
                componentsWithTasks.push({...esslComponent, circulationActivity: task});
              } else {
                componentsWithTasks.push({...esslComponent, circulationActivity: null});
              }
            });
            return {...componentsPage, content: componentsWithTasks};
          }));
        })
      );
  }

  findEsslComponentsForDocuments(searchParams: SearchParams, documentIds: number[]) {
    if (documentIds?.length) {
      searchParams.filter.push({operator: FilterOperator.inSet, fieldName: 'documentId', value: String(documentIds)});
    }

    return this.searchApi<Page<EsslComponentDto>>(searchParams, CORE_MICROSERVICE_ROOT + ApiComponentService.ComponentSearchPath);
  }

  findEsslComponentsForConsignment(
    searchParams: SearchParams,
    consignmetId: number,
    documentId?: number,
    additionalFilters?: FilterParam[],
  ) {
    return this.searchApi<Page<EsslComponentDto>>(this.prepareSearchParamsforFind(searchParams, documentId, additionalFilters), CORE_MICROSERVICE_ROOT + applyPathParams(ApiConsignmentComponentService.ConsignmentComponentFindOwnConsignmentComponentsPath, {id: consignmetId}));
  }

}
