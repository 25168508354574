import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal
} from '@angular/core';
import {CodebookService} from '../../../core/services/codebook.service';
import {DisposalScheduleDto, DocumentTypeDto, EntityClassDto} from '|api/codebook';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {RetentionTriggerTypeCode} from '|api/commons';
import {
  getExternalRetentionTriggersOptions,
  isExternalRetentionTriggerType,
  isRetentionTriggerTypeWithPeriod,
  RetentionTriggerWidgetMode
} from '../shared-document.utils';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {distinctUntilChanged, startWith} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export enum RetentionTriggerTypeSource {
  ENTITY_CLASS = 'ENTITY_CLASS',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
}

@Component({
  selector: 'icz-retention-trigger-type-widget',
  templateUrl: './retention-trigger-type-widget.component.html',
  styleUrls: ['./retention-trigger-type-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetentionTriggerTypeWidgetComponent implements OnInit, IczOnChanges {
  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);
  private cd = inject(ChangeDetectorRef);

  @Input()
  form!: IczFormGroup;
  @Input()
  disposalScheduleId: Nullable<number>;
  @Input()
  externalRetentionTriggerIds: Nullable<number[]>;
  @Input()
  showExternalRetentionYear = false;
  @Input()
  retentionTriggerWidgetMode: Nullable<BehaviorSubject<RetentionTriggerWidgetMode>>;

  documentTypes: DocumentTypeDto[] = [];
  entityClasses: EntityClassDto[] = [];
  disposalSchedules: DisposalScheduleDto[] = [];
  retentionTriggerTypesOptions: IczOption[] = [];
  retentionTriggerTypeCode: Nullable<RetentionTriggerTypeCode>;

  triggerEventCheckYearControlSub: Nullable<Subscription>;
  yearOfRetentionPeriodStartControlSub!: Nullable<Subscription>;

  disposalScheduleIdChanged = new Subject<void>();
  disposalScheduleIdChangedSub: Nullable<Subscription>;

  isRetentionTriggerTypeWithPeriod = signal(false);
  isExternalRetentionTriggerTypeWithPeriod = signal(false);

  externalRetentionTriggerOptions$ = getExternalRetentionTriggersOptions(this.codebookService);

  get yearOfRetentionPeriodStartControl() {
    return this.form.get('yearOfRetentionPeriodStart')! as IczFormControl;
  }

  get triggerEventCheckYearControl() {
    return this.form.get('triggerEventCheckYear')! as IczFormControl;
  }

  get externalRetentionTriggerIdsValue(): Nullable<number | number[]> {
    if (this.externalRetentionTriggerIds) {
      if (this.externalRetentionTriggerIds.length === 1) {
        return this.externalRetentionTriggerIds[0];
      } else {
        return this.externalRetentionTriggerIds;
      }
    } else {
      return null;
    }
  }

  ngOnInit() {
    this.codebookService.disposalSchedules().subscribe(disposalSchedules => {
      this.disposalSchedules = disposalSchedules;
      this.retentionTriggerTypesOptions = enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode);
      this.resolveRetentionTriggerType();
    });
    if (this.retentionTriggerWidgetMode) {
      this.retentionTriggerWidgetMode.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(mode => {
        this.clearRetentionTriggerYearValidators();
        if (mode === RetentionTriggerWidgetMode.ENABLED) {
          this.triggerEventCheckYearControl.enable();
          this.yearOfRetentionPeriodStartControl.enable();
        } else if (mode === RetentionTriggerWidgetMode.DISABLED) {
          this.triggerEventCheckYearControl.disable();
          this.yearOfRetentionPeriodStartControl.disable();
        } else if (mode === RetentionTriggerWidgetMode.ENABLED_START_YEAR) {
          this.triggerEventCheckYearControl.disable();
          this.yearOfRetentionPeriodStartControl.enable();
        } else {
          this.setRetentionTriggerYearValidators();
        }
      });
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.disposalScheduleId) {
      this.resolveRetentionTriggerType();
    }
  }

  setRetentionTriggerYearValidators() {
    this.disposalScheduleIdChangedSub?.unsubscribe();
    this.disposalScheduleIdChangedSub = this.disposalScheduleIdChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      if (this.isRetentionTriggerTypeWithPeriod()) {
        this.setupFormValidation();
      } else {
        this.clearRetentionTriggerYearValidators();
      }
    });
    this.disposalScheduleIdChanged.next();
  }

  clearRetentionTriggerYearValidators() {
    this.yearOfRetentionPeriodStartControl.clearValidators();
    this.triggerEventCheckYearControl.clearValidators();
    this.triggerEventCheckYearControlSub?.unsubscribe();
    this.yearOfRetentionPeriodStartControlSub?.unsubscribe();
    this.triggerEventCheckYearControlSub = null;
    this.yearOfRetentionPeriodStartControlSub = null;
  }

  setupFormValidation() {
    const triggerEventCheckYearValidators = [IczValidators.required(), IczValidators.min(new Date().getFullYear())];

    if (!this.triggerEventCheckYearControlSub) {
      this.triggerEventCheckYearControlSub = this.triggerEventCheckYearControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), startWith(this.triggerEventCheckYearControl.value), distinctUntilChanged()).subscribe(value => {
        if (value) {
          this.triggerEventCheckYearControl.setValidators(triggerEventCheckYearValidators);
          this.yearOfRetentionPeriodStartControl.clearValidators();
          this.yearOfRetentionPeriodStartControl.disable();
          this.yearOfRetentionPeriodStartControl.patchValue(null);
        } else {
          this.yearOfRetentionPeriodStartControl.setValidators([IczValidators.required()]);
          this.yearOfRetentionPeriodStartControl.enable();
        }
        this.yearOfRetentionPeriodStartControl.updateValueAndValidity();
      });
    }

    if (!this.yearOfRetentionPeriodStartControlSub) {
      this.yearOfRetentionPeriodStartControlSub = this.yearOfRetentionPeriodStartControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), startWith(this.yearOfRetentionPeriodStartControl.value), distinctUntilChanged()).subscribe(value => {
        if (value) {
          this.yearOfRetentionPeriodStartControl.setValidators([IczValidators.required()]);
          this.triggerEventCheckYearControl.clearValidators();
          this.triggerEventCheckYearControl.disable();
          this.triggerEventCheckYearControl.patchValue(null);
        } else {
          this.triggerEventCheckYearControl.setValidators(triggerEventCheckYearValidators);
          this.triggerEventCheckYearControl.enable();
        }
        this.triggerEventCheckYearControl.updateValueAndValidity();
      });
    }
  }

  resolveRetentionTriggerType() {
    if (this.disposalSchedules.length > 0) {
      if (this.disposalScheduleId) {
        const selectedDS = this.disposalSchedules.find(ds => ds.id === this.disposalScheduleId)!;
        this.retentionTriggerTypeCode = selectedDS.retentionTriggerTypeCode;
        this.isRetentionTriggerTypeWithPeriod.set(isRetentionTriggerTypeWithPeriod(selectedDS.retentionTriggerTypeCode));
        this.isExternalRetentionTriggerTypeWithPeriod.set(isExternalRetentionTriggerType(selectedDS.retentionTriggerTypeCode));
        this.disposalScheduleIdChanged.next();
      } else {
        this.retentionTriggerTypeCode = null;
        this.isRetentionTriggerTypeWithPeriod.set(false);
        this.isExternalRetentionTriggerTypeWithPeriod.set(false);
        this.disposalScheduleIdChanged.next();
      }
      this.cd.detectChanges();
    }
  }
}
