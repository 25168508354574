<div
  class="object-icons"
  [class.visible-clickable-area]="isDocumentObject(documentsTableEntity) && showComponentsWithDigitalContentIndicator"
  [iczTooltip]="isEsslObjectEntity(documentsTableEntity) ? (documentsTableEntity!.objectClass | objectClassName | translate) : null"
  cdkOverlayOrigin
  #digitalComponentsPopupOrigin="cdkOverlayOrigin"
  (click)="entityTypeClick($event, entity)"
  >
  @if (isOriginalObjectIconVisible) {
    <icz-object-class-icon [objectClass]="entity.objectClass"></icz-object-class-icon>
    @if (showComponentsWithDigitalContentIndicator) {
      <icz-icon svgIcon="attachment" size="small"></icz-icon>
    }
    <icz-popover
      [isOpen]="isDigitalComponentsPopupOpen"
      [overlayOrigin]="digitalComponentsPopupOrigin"
      (onClose)="isDigitalComponentsPopupOpen = false"
      >
      @if (canUserDisplayComponents) {
        @if ((loadingService.isLoading$(this) | async) === false) {
          <div class="gap-8 pt-8 col">
            <div class="gap-8 overflow-hidden col digital-components-list">
              @for (component of documentEsslComponents$ | async; track component.id) {
                <icz-essl-component-link
                  [documentEsslComponent]="component"
                  class="overflow-hidden grow shrink"
                ></icz-essl-component-link>
              }
            </div>
            <div class="items-center justify-center row digital-components-footer" popoverFooter>
              @if (notAllComponentsVisible) {
                <a iczLinkWithoutHref (click)="allComponentsViewClick()">{{ 'Všechny komponenty' | translate }} ({{ totalComponentCount }})</a>
              }
            </div>
          </div>
        }
        @else {
          <icz-spinner
            class="digital-components-loading" [diameter]="50"
          ></icz-spinner>
        }
      }
      @else {
        <icz-document-file-insufficient-permissions-alert
          class="grow"
          [isShortAlert]="true"
        ></icz-document-file-insufficient-permissions-alert>
      }
    </icz-popover>
  }
</div>
