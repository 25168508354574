import {Component, inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {EsslErrorCodeExceptionDto} from '|api/commons';
import {DigitalComponentCompleteDto} from '|api/component';
import {ApiSignatureService, DocumentCheckBeforeMarkResponse} from '|api/document';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {DigitalComponentWithResult} from '../../models/signature-dialog.model';
import {getLastDigitalComponentVersionId, getLatestDigitalComponentVersion} from '../../../shared-document.utils';
import {injectModalData, injectModalRef} from '@icz/angular-modal';

export interface PreMarkExtendValidationDialogData {
  documentId: number;
  isBulkOperation: boolean;
  isMark: boolean;
  digitalComponents: DigitalComponentCompleteDto[];
}

@Component({
  selector: 'icz-digital-component-pre-mark-validation-dialog',
  templateUrl: './digital-component-pre-mark-extend-validation-dialog.component.html',
  styleUrls: ['./digital-component-pre-mark-extend-validation-dialog.component.scss'],
})
export class DigitalComponentPreMarkExtendValidationDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<DigitalComponentCompleteDto[]>>();
  private apiSignatureService = inject(ApiSignatureService);
  protected data = injectModalData<PreMarkExtendValidationDialogData>();

  formGroupsToCheck!: string[];

  get canContinue() {
    return this.numberOfErrors < this.data.digitalComponents.length;
  }

  parentDocumentError: Nullable<EsslErrorCodeExceptionDto>;
  numberOfErrors = 0;
  headingContext: Nullable<{numberOfErrors: number}>;
  validationResult:  DigitalComponentWithResult[] = [];
  validationErrorComponents:  DigitalComponentWithResult[] = [];
  invalidComponentsIds : number[] = [];
  validComponents: DigitalComponentCompleteDto[] = [];

  ngOnInit(): void {
    let validationRequest$: Observable<DocumentCheckBeforeMarkResponse>;

    if (this.data.isMark) {
      validationRequest$ = this.apiSignatureService.signatureCheckBeforeMark({
        documentId: this.data.documentId,
        body: {
          digitalComponentVersionIds: this.prepareDigitalComponentLatestVersions()
        }
      });
    } else {
      validationRequest$ = this.apiSignatureService.signatureCheckBeforeExtend({
        documentId: this.data.documentId,
        body: {
          digitalComponentVersionIds: this.prepareDigitalComponentLatestVersions()
        }
      });
    }

    validationRequest$.subscribe(result => {
      if (result.violation) {
        this.parentDocumentError = result.violation;
      }

      this.validationResult = result.digitalComponentVersionsResults.map(validationResult => {
        const digitalComponent = this.data.digitalComponents.find(digitalComponent => getLastDigitalComponentVersionId(digitalComponent) === validationResult.digitalComponentVersionId)!;
        const fileName = getLatestDigitalComponentVersion(digitalComponent)!.fileName;

        if (validationResult.violation) {
          this.invalidComponentsIds.push(digitalComponent.id!);
          return {
            ...digitalComponent,
            errorCode: validationResult.violation.code,
            errorParameters: validationResult.violation.parameters,
            fileName,
          };
        } else {
          return {
            ...digitalComponent,
            fileName,
          };
        }
      });
      this.validationErrorComponents = this.validationResult.filter(res => res.errorCode);
      this.numberOfErrors = this.validationErrorComponents.length;

      this.headingContext = {
        numberOfErrors: this.validationErrorComponents.length
      };

      if (!this.numberOfErrors) {
        this.modalRef.close(this.data.digitalComponents);
      } else {
        this.validComponents = this.data.digitalComponents.filter(dc => !this.invalidComponentsIds.includes(dc.id!));
      }
    });
  }

  prepareDigitalComponentLatestVersions() {
    return this.data.digitalComponents.map(digitalComponent => getLastDigitalComponentVersionId(digitalComponent)!);
  }

  continue() {
    if (this.canContinue) {
      this.modalRef.close(this.validComponents);
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

}
