/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CustomBoolFieldDto } from '../../models/custom-bool-field-dto';
import { CustomDateFieldDto } from '../../models/custom-date-field-dto';
import { CustomDateTimeFieldDto } from '../../models/custom-date-time-field-dto';
import { CustomEnumFieldDto } from '../../models/custom-enum-field-dto';
import { CustomFieldDto } from '../../models/custom-field-dto';
import { CustomNumericFieldDto } from '../../models/custom-numeric-field-dto';
import { CustomTextFieldDto } from '../../models/custom-text-field-dto';

export interface CustomFieldOauthFindById$Params {
  id: number;
}

export function customFieldOauthFindById(http: HttpClient, rootUrl: string, params: CustomFieldOauthFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>> {
  const rb = new RequestBuilder(rootUrl, customFieldOauthFindById.PATH, 'get');
  if (params) {
    rb.path('id', params.id, {});
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>;
    })
  );
}

customFieldOauthFindById.PATH = '/oauth/custom-field/find-by-id/{id}';