@if (wrapInCard) {
  <icz-card class="in-card" [class.hidden]="!hasFileSelected">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </icz-card>
}
@else {
  <ng-container *ngTemplateOutlet="content"></ng-container>
}

<ng-template #content>
  @if (showFileDragAndDrop) {
    <div class="file-drag-area"
         (dragstart)="onFileDragStart($event)"
         (dragover)="onFileDragover($event)"
         (dragleave)="onFileDragLeave($event)"
         (drop)="onFileDrop($event)"
         [ngClass]="{'on-file-drag': isFileDrag}">
      {{ dndAreaHelpText }}
      @if (multipleFilesDroppedInSingleUploadMode) {
        <icz-alert class="m-12" severity="warning" [label]="multipleFilesDroppedInSingleUploadModeHelpText"></icz-alert>
      }
      <ng-container *ngTemplateOutlet="fileUploadButton"></ng-container>
      <div class="px-8 pt-16">
        <ng-container *ngTemplateOutlet="fileList"></ng-container>
      </div>
    </div>
  }
  @else {
    <div>
      <ng-container *ngTemplateOutlet="fileList"></ng-container>
    </div>
  }
  <ng-template #fileList>
    @for (item of files.controls; track item) {
      <div class="col gap-16 digital-component"
           [waiting]="loadingIndicatorService.isLoading(this, $index)"
      >
        @if (item.get('file')!.value; as uploadListItem) {
          <div class="gap-16">
            <div class="row">
              @if (showSpinner(item) || loadingIndicatorService.isLoading(this, $index)) {
                <icz-spinner [diameter]="24"></icz-spinner>
              }
              <ng-content select="[beforeFileName]"></ng-content>
              @if (withFileIcon && showFileIcon(item)) {
                <icz-icon [svgIcon]="getFileIcon(uploadListItem)"></icz-icon>
              }
              @if (showErrorIcon(item)) {
                <icz-icon svgIcon="error"></icz-icon>
              }
              <icz-label class="font-bold overflow-hidden" dontTranslate [label]="getFileName(uploadListItem)" [iczTooltip]="getFileName(uploadListItem)"></icz-label>
              @if (showFileSize) {
                <icz-label class="filesize" dontTranslate [label]="getFileSize(uploadListItem) | fileSize"></icz-label>
              }
              <ng-content select="[afterFileName]"></ng-content>
              @if (!item.get('file')!.disabled) {
                <icz-button background="transparent" size="small" svgIcon="delete" (onAction)="deleteDigitalComponent($index)"></icz-button>
              }
            </div>
            @for (uploadException of getFileUploadExceptions($index); track uploadException) {
              <div>
                <div class="icz-error file-upload-error mt-4">{{ formatUploadException(uploadException) }}</div>
              </div>
            }
          </div>
        }
        <!-- additional content for file card customization -->
        <ng-content select="[additionalContent]"></ng-content>
      </div>
    }
  </ng-template>
</ng-template>

@if (!showFileDragAndDrop) {
  <ng-container *ngTemplateOutlet="fileUploadButton"></ng-container>
}
<ng-template #fileUploadButton>
  @if (showFileUploadButton) {
    <div class="row" [class.mt-16]="showFileDragAndDrop" [class.justify-center]="showFileDragAndDrop">
      @if (!hasFileSelected || isMultiUpload) {
        <icz-button
          class="inline-block"
          (onAction)="openUploadDialog()"
          [label]="fileUploadButtonLabel"
        ></icz-button>
      }
    </div>
  }
</ng-template>

<input type="file"
  #fileUpload
  (change)="setFiles()"
  [multiple]="isMultiUpload"
  [accept]="fileInputAccepted"
  data-cy="doc-digital-component-file-input"
  class="hidden">
