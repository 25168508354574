import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {delay} from 'rxjs/operators';
import {OwnConsignmentWorkflowService} from '../../own-consignment-table/own-consignment-workflow.service';
import {ConsignmentToolbarAction, ConsignmentToolbarButtonsService} from './consignment-toolbar-buttons.service';
import {
  GenericOwnElasticConsignmentWithConsignee,
  OwnConsignmentOfficeDeskTableView,
  OwnConsignmentTableView
} from '../../own-consignment-table/model/own-consignment-model';
import {IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {DocumentDetailCountType, DocumentDetailService} from '../../../../services/document-detail.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {increment, load} from '../../../../lib/object-counts';
import {ConsignmentDialogService} from '../../consignment-dialog/consignment-dialog.service';
import {ELASTIC_RELOAD_DELAY} from '../../document-toolbar/services/toolbar-common.utils';
import {AbstractToolbarButtonsComponent} from '../../abstract-toolbar-buttons.component';
import {DocumentOrProfileDtoWithAuthorization} from '../../model/dto-with-permissions.interface';


@Component({
  selector: 'icz-consignment-toolbar-buttons',
  templateUrl: './consignment-toolbar-buttons.component.html',
  styleUrls: ['./consignment-toolbar-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ConsignmentDialogService,
    ConsignmentToolbarButtonsService,
    OwnConsignmentWorkflowService,
  ]
})
export class ConsignmentToolbarButtonsComponent extends AbstractToolbarButtonsComponent<GenericOwnElasticConsignmentWithConsignee, OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView> implements OnInit {

  private consignmentToolbarButtonsService = inject(ConsignmentToolbarButtonsService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  protected loadingService = inject(LoadingIndicatorService);
  private documentDetailService = inject(DocumentDetailService, {optional: true});

  @Input({required: true})
  isUnitView!: boolean;
  @Input({required: true})
  dispatchOfficeId!: Nullable<number>;
  @Input({required: true})
  canCreateBulkPostingForm!: boolean;
  @Input()
  entity!: Nullable<DocumentOrProfileDtoWithAuthorization>;
  @Input()
  excludedConsignmentIdsForBpf?: Array<number>;
  @Input()
  disableAllButtons = false;
  @Input()
  collapseButtons = false;
  @Input()
  dispatchOfficeDistributionNodeIds: number[] = [];
  @Input()
  guidedBPFGenerationEnabled = false; // important to start with false

  ngOnInit() {
    this.consignmentToolbarButtonsService.actionCompleted$.pipe(
      delay(ELASTIC_RELOAD_DELAY),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(action => {
      this.operationCompleted.emit();

      if (this.documentDetailService) {
        switch (action) {
          case ConsignmentToolbarAction.CONSIGNMENT_CREATED:
            this.documentDetailService.reloadObject({
              [DocumentDetailCountType.CONSIGNMENTS]: increment(),
              [DocumentDetailCountType.SUBJECTS]: load(),
            });
            break;
        }
      }
    });
  }

  override ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.guidedBPFGenerationEnabled || changes.entity || changes.selectedRows || changes.dispatchOfficeId || changes.canCreateBulkPostingForm || changes.excludedConsignmentIdsForBpf) {
      this.generateToolbarContents(this.selectedRows);
    }
  }

  protected generateToolbarContents(rows: Nullable<(GenericOwnElasticConsignmentWithConsignee)[]>) {
    this.loadingService.doLoading(this.consignmentToolbarButtonsService.getToolbarButtons(rows, {
      tableView: this.view,
      dispatchOfficeId: this.dispatchOfficeId,
      isUnitView: this.isUnitView,
      canCreateBulkPostingForm: this.canCreateBulkPostingForm,
      excludedConsignmentIdsForBpf: this.excludedConsignmentIdsForBpf,
      guidedBPFGenerationEnabled: this.guidedBPFGenerationEnabled,
      dispatchOfficeDistributionNodeIds: this.dispatchOfficeDistributionNodeIds,
    }), this).subscribe(buttons => {
      this.toolbarButtons = buttons;
      this.cd.detectChanges();
    });
  }

}
