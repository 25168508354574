import {ApiRenditionService, DocumentDto, FileDto} from '|api/document';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormGroup} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {LocalBinaryFileDownloadService} from '../../../../../services/local-binary-file-download.service';
import {Observable} from 'rxjs';
import {DialogService, injectModalRef} from '@icz/angular-modal';
import {inject} from '@angular/core';
import {httpResponseToFile} from '../../../../../services/remote-binary-file-download.service';
import {HttpResponse} from '@angular/common/http';

export abstract class AbstractPrintObjectInfoDialogComponent<TObject extends DocumentDto | FileDto> implements IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected loadingService = inject(LoadingIndicatorService);
  protected apiRenditionService = inject(ApiRenditionService);
  protected localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  protected dialogService = inject(DialogService);

  protected abstract objects: TObject[];

  formGroupsToCheck!: string[];

  abstract form: IczFormGroup;

  protected abstract getPrintRequest(formValue: Record<string, boolean>): Observable<HttpResponse<ArrayBuffer>>;

  submit() {
    const formValue = this.form.getRawValue() as Record<string, boolean>;
    const selectedPrintReportsCount = Object.values(formValue).filter(Boolean).length;

    if (!selectedPrintReportsCount) {
      this.dialogService.showError('Nebyla vybrána žádná tisková sestava. Vyberte alespoň jednu tiskovou sestavu a zkuste spustit tisk znovu.');
    }
    else {
      this.loadingService.doLoading(
        this.getPrintRequest(formValue),
        this
      ).pipe(
        httpResponseToFile(),
      ).subscribe(response => {
        this.localBinaryFileDownloadService.downloadFile(response);
        this.modalRef.close(true);
      });
    }
  }

  close() {
    this.modalRef.close(false);
  }

}
