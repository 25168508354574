import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {map} from 'rxjs';
import {EnvelopeTemplateDto, SheetLabelTemplateDto} from '|api/sad';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {RemoteBinaryFileDownloadService} from '../../../services/remote-binary-file-download.service';

const DEFAULT_CANVAS_ASPECT_RATIO = '217/155'; // envelopes with stripes will most likely be used

@Component({
  selector: 'icz-envelope-or-label-preview-canvas',
  templateUrl: './envelope-or-label-preview-canvas.component.html',
  styleUrls: ['./envelope-or-label-preview-canvas.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvelopeOrLabelPreviewCanvasComponent implements IczOnChanges {

  loadingService = inject(LoadingIndicatorService);
  private cd = inject(ChangeDetectorRef);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);

  @Input({required: true})
  template: Nullable<EnvelopeTemplateDto | SheetLabelTemplateDto>;
  @Input({required: true})
  previewImageDataUrl: Nullable<string>;
  @Input({required: true})
  isPreviewLoading: Nullable<boolean>;
  @Input()
  infoText: Nullable<string>;

  backgroundImageDataUrl: Nullable<string>;

  previewAspectRatio!: string;

  get isBackgroundLoaded() {
    if (this.template && this.isUsingSheetLabelTemplate(this.template)) {
      return true; // sheet labels never have a background
    }
    else {
      if ((this.template as Nullable<EnvelopeTemplateDto>)?.digitalVersionId) {
        return !isNil(this.backgroundImageDataUrl);
      }
      else {
        return true;
      }
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.template) {
      if (this.template) {
        if (this.isUsingEnvelopeTemplate(this.template)) {
          if (this.template?.digitalVersionId) {
            this.loadingService.doLoading(
              this.remoteBinaryFileDownloadService.fetchDigitalComponentVersion(this.template.digitalVersionId).pipe(
                map(binaryWithCharset => binaryWithCharset.buffer)
              ),
              this
            ).subscribe(envelopeBackground => {
              const backgroundFile = new File([envelopeBackground], 'unknown');

              const reader = new FileReader();

              reader.addEventListener('load', () => {
                this.backgroundImageDataUrl = String(reader.result);
                this.cd.detectChanges();
              }, false);

              reader.readAsDataURL(backgroundFile);
            });
          }
          else {
            this.backgroundImageDataUrl = null;
          }

          this.previewAspectRatio = `${this.template.widthMm}/${this.template.heightMm}`;
        }
        else if (this.isUsingSheetLabelTemplate(this.template)) {
          this.previewAspectRatio = `${this.template.labelWidthMm}/${this.template.labelHeightMm}`;
        }

        this.cd.detectChanges();
      }
      else {
        this.previewAspectRatio = DEFAULT_CANVAS_ASPECT_RATIO;
      }
    }
  }

  private isUsingEnvelopeTemplate(template: EnvelopeTemplateDto | SheetLabelTemplateDto): template is EnvelopeTemplateDto {
    return 'widthMm' in template && 'heightMm' in template;
  }

  private isUsingSheetLabelTemplate(template: EnvelopeTemplateDto | SheetLabelTemplateDto): template is SheetLabelTemplateDto {
    return 'labelSheetWidthMm' in template && 'labelSheetHeightMm' in template;
  }

}
