import {Component, EventEmitter, HostBinding, HostListener, Input, Output} from '@angular/core';
import {TabItem} from '../tabs.component.model';
import {IconComponent} from '../../icon/icon.component';
import {SpinnerComponent} from '../../spinner/spinner.component';
import {LabelComponent} from '../../label/label.component';
import {TooltipDirective} from '../../tooltip.directive';
import {NumberToLocaleStringPipe} from '../../number-to-locale-string.pipe';

/**
 * @internal
 */
@Component({
  selector: 'icz-tab-item',
  templateUrl: './tab-item.component.html',
  styleUrls: ['./tab-item.component.scss'],
  standalone: true,
  imports: [IconComponent, SpinnerComponent, LabelComponent, TooltipDirective, NumberToLocaleStringPipe],
})
export class TabItemComponent<T extends string | number = string | number> {

  @Input({required: true})
  tab: Nullable<TabItem<T>>;

  @HostBinding('class.active')
  @Input()
  isActive = false;

  @Output()
  onClick = new EventEmitter();

  protected get isLoading() {
    return this.tab?.showTabValidity && this.tab?.loading;
  }

  protected get isValid() {
    return this.tab?.showTabValidity && !this.isLoading && this.tab?.valid;
  }

  protected get isInvalid() {
    return this.tab?.showTabValidity && !this.isLoading && !this.tab?.valid;
  }

  @HostBinding('class.disabled')
  protected get isDisabled() {
    return Boolean(this.tab?.disabled);
  }

  @HostListener('click', ['$event'])
  protected handleTabClick() {
    this.onClick.emit();
  }
}
