import {sub} from 'date-fns';
import {getTodayMidnight, getTomorrowMidnight} from '../../form-elements.utils';
import {ValidDateFnWithMessage} from '../../calendar/calendar.component';

/**
 * Collection of built-in date validators.
 * @see ValidDateFnWithMessage
 */
export class IczDateValidators {
  static presentOrFutureDateValidator: ValidDateFnWithMessage = {
    validationFn: (d: Date) => {
      return d >= getTodayMidnight();
    },
    invalidDateMessage : { errorMessageTemplate: 'Datum nesmí být v minulosti'}
  };

  static futureDateValidator: ValidDateFnWithMessage = {
    validationFn: (d: Date) => {
      return d > getTodayMidnight();
    },
    invalidDateMessage : { errorMessageTemplate: 'Datum musí být den následující po aktuálním datu.'}
  };

  static pastOrPresentDateValidator: ValidDateFnWithMessage = {
    validationFn: (d: Date) => {
      return d <= getTodayMidnight();
    },
    invalidDateMessage : { errorMessageTemplate: 'Datum nesmí být v budoucnosti'}
  };

  static yesterdayOrFutureDateValidator: ValidDateFnWithMessage = {
    validationFn: (d: Date) => d.getTime() >= Date.now() - (24 * 60 * 60 * 1000),
    invalidDateMessage : { errorMessageTemplate: 'Můžete zadat včerejší nebo pozdější datum'}
  };

  static presentOrFutureWithouWeekendValidator: ValidDateFnWithMessage = {
    validationFn: (d: Date) => {
      return d >= getTodayMidnight() && d.getDay() !== 6 && d.getDay() !== 0; // 6 = saturday, 0 = sunday
    },
    invalidDateMessage : { errorMessageTemplate: 'Zadejte budoucí datum, které není o víkendu'}
  };

  static birthDateValidator: ValidDateFnWithMessage = {
    validationFn: (d: Date) => {
      const lowerBound = sub(new Date(), {years: 200, days: 1});
      return d >= lowerBound && d < getTomorrowMidnight();
    },
    invalidDateMessage: { errorMessageTemplate: 'Neplatné datum narození'}
  };
}
