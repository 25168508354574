import {inject, Injectable} from '@angular/core';
import {
  FILTER_PARAM_NAME,
  FOCUSED_ROW_PARAM_NAME,
  FULLTEXT_SEARCH_TERM_PARAM_NAME,
  PAGE_PARAM_NAME,
  SearchParamsSerializationService,
  SORT_TERM_PARAM_NAME
} from './search-params-serialization.service';
import {
  EsslTableQueryParametersSerializationMode,
  FilterItemTree,
  filterItemTreeToFilterItemValueTree,
  FilterItemValue,
  isFilterTreeEmpty,
  isSimpleQueryFilterTree,
  MIN_SEARCH_TERM_LENGTH,
  TablePage,
  TableQueryParamsDeserializationResult,
  TableQueryParamsSerializer,
  TableToolbarService
} from '@icz/angular-table';
import {Params} from '@angular/router';
import {serializeParamsToQueryString} from '../lib/utils';
import {HistoryService} from './history.service';
import {Sort, SortDirection} from '@angular/material/sort';
import {DialogService} from '@icz/angular-modal';

@Injectable()
export class EsslTableQueryParametersSerializerService implements TableQueryParamsSerializer {

  private dialogService = inject(DialogService);
  private historyService = inject(HistoryService);
  private searchParamsSerializationService = inject(SearchParamsSerializationService);

  serialize(
    mode: EsslTableQueryParametersSerializationMode,
    searchTerm: string,
    activeFilters: FilterItemTree,
    currentSort: Nullable<Sort>,
    tablePage: Nullable<TablePage>,
    focusedRow: Nullable<string|number>,
  ) {
    let queryString = '';

    const sortParams = this.searchParamsSerializationService.createParamsFromSort(currentSort);
    const pageParams = this.searchParamsSerializationService.createParamsFromPage(tablePage);

    if (mode === EsslTableQueryParametersSerializationMode.SORT_PARAM_ONLY) {
      this.historyService.patchHistoryBitWithCustomParams(SORT_TERM_PARAM_NAME, sortParams[SORT_TERM_PARAM_NAME]);
    }
    else if (mode === EsslTableQueryParametersSerializationMode.PAGE_PARAM_ONLY && tablePage) {
      this.historyService.patchHistoryBitWithCustomParams(PAGE_PARAM_NAME, pageParams[PAGE_PARAM_NAME]);
    }
    else if (mode === EsslTableQueryParametersSerializationMode.FOCUSED_ROW_ONLY && focusedRow) {
      this.historyService.patchHistoryBitWithCustomParams(FOCUSED_ROW_PARAM_NAME, String(focusedRow));
    }
    else {
      const queryParams: Params = {
        ...sortParams,
      };

      if (searchTerm && searchTerm !== ' ') {
        queryString = `${FULLTEXT_SEARCH_TERM_PARAM_NAME}=${encodeURIComponent(searchTerm)}`;
      }

      const filterValues = filterItemTreeToFilterItemValueTree(activeFilters);

      if (!isFilterTreeEmpty(filterValues)) {
        queryParams[FILTER_PARAM_NAME] = this.searchParamsSerializationService.serializeFilterValuesToString(filterValues);
      }

      const filterQueryString = serializeParamsToQueryString(queryParams, true);

      if (queryString === '') {
        queryString = filterQueryString;
      }
      else {
        if (filterQueryString) {
          queryString = `${queryString}&${filterQueryString}`;
        }
      }

      this.historyService.patchHistoryBitWithSearchParams(queryString);
    }
  }

  deserialize(tableToolbarService: TableToolbarService, queryParams: Params) {
    const result: TableQueryParamsDeserializationResult = {
      searchTerm: null,
      hasSomeFilters: false,
      sort: null,
      page: null,
      focusedRowKey: null,
    };

    if (FILTER_PARAM_NAME in queryParams) {
      try {
        const deserializedQuery = this.searchParamsSerializationService.deserializeFilterValuesFromString(queryParams[FILTER_PARAM_NAME]);

        if (deserializedQuery) {
          if (!isFilterTreeEmpty(deserializedQuery)) {
            tableToolbarService.clearAllFilters();

            if (isSimpleQueryFilterTree(deserializedQuery)) {
              for (const paramValue of (deserializedQuery.values as FilterItemValue[])) {
                tableToolbarService!.addItemValue(paramValue);
              }
            }
            else {
              tableToolbarService.addTreeItems(deserializedQuery);
            }

            result.hasSomeFilters = true;
          }
        }
      }
      catch {
        this.dialogService.showError('Došlo k chybě při čtení vyhledávacího výrazu z adresy v prohlížeči. Vyhledávání nebude provedeno.');
      }
    }

    if (FULLTEXT_SEARCH_TERM_PARAM_NAME in queryParams) {
      const searchTermFromUrl = queryParams[FULLTEXT_SEARCH_TERM_PARAM_NAME] as string;
      if (searchTermFromUrl.length < MIN_SEARCH_TERM_LENGTH) {
        this.dialogService.showError('Pro vyhledávání musíte zadat alespoň dva znaky.');
      } else {
        result.searchTerm = searchTermFromUrl;
        result.hasSomeFilters = true;
      }
    }

    if (SORT_TERM_PARAM_NAME in queryParams) {
      const sortTermFromUrl = queryParams[SORT_TERM_PARAM_NAME] as string;
      const sortTermFromUrlSplit = sortTermFromUrl.split(',');
      if (sortTermFromUrlSplit.length !== 2) {
        this.dialogService.showError('Parametr pro řazení načtený z adresy URL má nesprávný tvar.');
      } else {
        if (sortTermFromUrlSplit[1] === 'asc' || sortTermFromUrlSplit[1] === 'desc') {
          result.sort = {
            active: sortTermFromUrlSplit[0],
            direction: sortTermFromUrlSplit[1] as SortDirection,
          };
        }
      }
    }

    if (PAGE_PARAM_NAME in queryParams) {
      const pageTermFromUrl = queryParams[PAGE_PARAM_NAME] as string;
      const pageTermFromUrlSplit = pageTermFromUrl.split(',');
      if (pageTermFromUrlSplit.length !== 2) {
        this.dialogService.showError('Parametr pro číslo stránky načtený z adresy URL má nesprávný tvar.');
      } else {
        const page = Number(pageTermFromUrlSplit[0]);
        const size = Number(pageTermFromUrlSplit[1]);
        if (isNaN(size) || isNaN(page)) {
          this.dialogService.showError('Parametr pro číslo stránky nebo velikost stránky načtenou z adresy URL není číslo.');
        } else if (size <= 0 || size > 2000 || page < 0) {
          this.dialogService.showError('Parametr pro číslo stránky nebo velikost stránky překračuje limity.');
        } else {
          result.page = {
            page: Number(pageTermFromUrlSplit[0]),
            size: Number(pageTermFromUrlSplit[1]),
          };
        }
      }
    }

    if (FOCUSED_ROW_PARAM_NAME in queryParams) {
      result.focusedRowKey = queryParams[FOCUSED_ROW_PARAM_NAME];
    }

    return result;
  }

}
