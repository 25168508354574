/* tslint:disable */
/* eslint-disable */

/**
 * Type of custom field
 */
export enum CustomFieldType {
  TEXT = 'TEXT',
  NUMERIC = 'NUMERIC',
  ENUM = 'ENUM',
  DATE = 'DATE',
  DATE_TIME = 'DATE_TIME',
  BOOL = 'BOOL'
}
