/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { documentFileSubjectsCheckPresence } from '../fn/document-file-subjects/document-file-subjects-check-presence';
import { DocumentFileSubjectsCheckPresence$Params } from '../fn/document-file-subjects/document-file-subjects-check-presence';
import { DocumentFileSubjectsCheckResponseDto } from '../models/document-file-subjects-check-response-dto';


/**
 * API for management of subjects related to file and its documents.
 */
@Injectable({ providedIn: 'root' })
export class ApiDocumentFileSubjectsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `documentFileSubjectsCheckPresence()` */
  static readonly DocumentFileSubjectsCheckPresencePath = '/document/subjects/check-presence';

  /**
   * Checks the presence of subject and document/file relations.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `documentFileSubjectsCheckPresence()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentFileSubjectsCheckPresence$Response(params: DocumentFileSubjectsCheckPresence$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentFileSubjectsCheckResponseDto>> {
    return documentFileSubjectsCheckPresence(this.http, this.rootUrl, params, context);
  }

  /**
   * Checks the presence of subject and document/file relations.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `documentFileSubjectsCheckPresence$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  documentFileSubjectsCheckPresence(params: DocumentFileSubjectsCheckPresence$Params, context?: HttpContext): Observable<DocumentFileSubjectsCheckResponseDto> {
    return this.documentFileSubjectsCheckPresence$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentFileSubjectsCheckResponseDto>): DocumentFileSubjectsCheckResponseDto => r.body)
    );
  }

}
