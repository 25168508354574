import {Component, inject, OnInit} from '@angular/core';
import {DocumentDto, RenditionOutputType} from '|api/document';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {AbstractPrintObjectInfoDialogComponent} from './abstract-print-object-info-dialog.component';
import {injectModalData} from '@icz/angular-modal';


@Component({
  selector: 'icz-print-document-info-dialog',
  templateUrl: './print-document-info-dialog.component.html',
  styleUrls: ['./print-document-info-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class PrintDocumentInfoDialogComponent extends AbstractPrintObjectInfoDialogComponent<DocumentDto> implements OnInit {

  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected objects = injectModalData<DocumentDto[]>();

  form = new IczFormGroup({
    metadata: new IczFormControl(true),
    transactionLog: new IczFormControl(true),
  });

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  protected getPrintRequest(formValue: typeof this.form['value']) {
    return this.apiRenditionService.renditionCreateBulkDocumentPrintRendition$Response({
      body: {
        documentIds: this.objects.map(d => d.id),
        metadata: formValue.metadata,
        transactionLog: formValue.transactionLog,
        renditionOutputType: RenditionOutputType.PDF,
      }
    });
  }

}
