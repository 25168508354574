import {Injectable} from '@angular/core';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {applyPathParams, CORE_MICROSERVICE_ROOT} from '../api';
import {
  ApiReceivedDigitalConsignmentService,
  ApiReceivedInternalMessageService,
  ReceivedEpdzMessageDto,
  ReceivedEpdzMessageProcessingStatus,
  ReceivedInternalDigitalMessageDto,
  ReceivedInternalMessageBaseDto,
} from '|api/sad';
import {SearchApiService} from './search-api.service';
import {EpdzMessageType} from '|api/commons';
import {FilterOperator, FilterParamTree, FilterTreeOperator, Page, SearchParams} from '@icz/angular-table';

const nextMessage = function<T extends (ReceivedInternalMessageBaseDto | ReceivedEpdzMessageDto)> (currentMessageId: number) {
  return pipe(map<Page<T>, Nullable<T>>(page => {
    let currentMesasgeIndex = page.content.findIndex(message => message.id === currentMessageId);
    if (currentMesasgeIndex > -1) {
      return page.content[++currentMesasgeIndex] ?? null;
    } else {
      return null;
    }
  }));
};

@Injectable({
  providedIn: 'root'
})
export class FilingOfficeSearchService extends SearchApiService {

  getComplexFilterForNextMessage(currentMessageId: number): FilterParamTree {
    return {
      operator: FilterTreeOperator.OR,
      values: [
        {
          fieldName: 'lockedBy',
          operator: FilterOperator.empty,
          value: '',
        },
        {
          fieldName: 'id',
          operator: FilterOperator.equals,
          value: String(currentMessageId),
        }
      ]
    };
  }

  getNextInternalMessageInFilterQueue(currentMessageId: number, currentSearchParams: SearchParams): Observable<Nullable<ReceivedInternalMessageBaseDto>> {
    currentSearchParams.size++;
    currentSearchParams.complexFilter = this.getComplexFilterForNextMessage(currentMessageId);
    return this.findInternalMessages(currentSearchParams).pipe(nextMessage<ReceivedInternalMessageBaseDto>(currentMessageId));
  }

  getNextEmailMessageInFilterQueue(currentMessageId: number, messageProcessingStatus: ReceivedEpdzMessageProcessingStatus, currentSearchParams: SearchParams, distributionNodeId: Nullable<number>): Observable<Nullable<ReceivedEpdzMessageDto>> {
    currentSearchParams.size++;
    currentSearchParams.complexFilter = this.getComplexFilterForNextMessage(currentMessageId);
    return this.findEmailMessages(currentSearchParams, messageProcessingStatus, distributionNodeId).pipe(nextMessage<ReceivedEpdzMessageDto>(currentMessageId));
  }

  getNextDataboxMessageInFilterQueue(currentMessageId: number, currentSearchParams: SearchParams, distributionNodeId: Nullable<number>): Observable<Nullable<ReceivedEpdzMessageDto>> {
    currentSearchParams.size++;
    currentSearchParams.complexFilter = this.getComplexFilterForNextMessage(currentMessageId);
    return this.findDataboxMessages(currentSearchParams, distributionNodeId).pipe(nextMessage<ReceivedEpdzMessageDto>(currentMessageId));
  }

  findEmailMessages(
    searchParams: Partial<SearchParams>,
    messageProcessingStatus: ReceivedEpdzMessageProcessingStatus,
    distributionNodeId?: Nullable<number>
  ): Observable<Page<ReceivedEpdzMessageDto>> {
    searchParams.filter ??= [];
    searchParams.sort ??= [];

    searchParams.filter.push({
      fieldName: 'messageType',
      operator: FilterOperator.inSet,
      value: String([EpdzMessageType.EMAIL]),
    });

    return this.findDigitalMessages(searchParams, messageProcessingStatus, distributionNodeId);
  }

  findDataboxMessages(
    searchParams: Partial<SearchParams>,
    distributionNodeId?: Nullable<number>
  ): Observable<Page<ReceivedEpdzMessageDto>> {
    searchParams.filter ??= [];
    searchParams.sort ??= [];

    if (!searchParams.filter.find(f => f.fieldName === 'messageType')) {
      searchParams.filter.push({
        fieldName: 'messageType',
        operator: FilterOperator.inSet,
        value: String([EpdzMessageType.DATA_BOX, EpdzMessageType.DATA_BOX_PERSONAL_DELIVERY]),
      });
    }

    return this.findDigitalMessages(searchParams, ReceivedEpdzMessageProcessingStatus.UNREAD, distributionNodeId);
  }

  findInternalMessages(searchParams: Partial<SearchParams>): Observable<Page<ReceivedInternalMessageBaseDto>> {
    return this.searchApi<Page<ReceivedInternalDigitalMessageDto>>(
      searchParams,
      CORE_MICROSERVICE_ROOT +
      ApiReceivedInternalMessageService.ReceivedInternalMessageSearchPath
    );
  }

  findEpdzErrors(
    searchParams: Partial<SearchParams>,
    distributionNodeId: number,
  ) {
    return this.searchApi<Page<ReceivedEpdzMessageDto>>(
      searchParams,
      CORE_MICROSERVICE_ROOT + applyPathParams(ApiReceivedDigitalConsignmentService.ReceivedDigitalConsignmentFindEpdzErrorDistributionNodePath, {distributionNodeId})
    );
  }

  private findDigitalMessages(
    searchParams: Partial<SearchParams>,
    messageProcessingStatus: ReceivedEpdzMessageProcessingStatus,
    distributionNodeId?: Nullable<number>
  ) {
    searchParams.filter ??= [];

    searchParams.filter.push({
      fieldName: 'processingStatus',
      operator: FilterOperator.equals,
      value: messageProcessingStatus,
    });

    if (isNil(distributionNodeId)) {
      return this.searchApi<Page<ReceivedEpdzMessageDto>>(
        searchParams,
        CORE_MICROSERVICE_ROOT +
        ApiReceivedDigitalConsignmentService.ReceivedDigitalConsignmentFindEpdzMessage_1Path
      );
    } else {
      return this.searchApi<Page<ReceivedEpdzMessageDto>>(
        searchParams,
        CORE_MICROSERVICE_ROOT + applyPathParams(ApiReceivedDigitalConsignmentService.ReceivedDigitalConsignmentFindEpdzMessagePath, {distributionNodeId})
      );
    }
  }

}
