/* tslint:disable */
/* eslint-disable */

/**
 * Parameter key
 */
export enum InternalNotificationKey {
  REF_NUMBER = 'REF_NUMBER',
  DOCUMENT_ID = 'DOCUMENT_ID',
  DOCUMENT_SUBJECT = 'DOCUMENT_SUBJECT',
  ID_SOURCE = 'ID_SOURCE',
  IDENTIFIER = 'IDENTIFIER',
  ESSL_COMPONENT_ID = 'ESSL_COMPONENT_ID',
  ESSL_COMPONENT_LABEL = 'ESSL_COMPONENT_LABEL',
  DIGITAL_COMPONENT_ID = 'DIGITAL_COMPONENT_ID',
  DIGITAL_COMPONENT_VERSION_ID = 'DIGITAL_COMPONENT_VERSION_ID',
  DIGITAL_COMPONENT_FILE_NAME = 'DIGITAL_COMPONENT_FILE_NAME',
  FAILED_DIGITAL_COMPONENTS = 'FAILED_DIGITAL_COMPONENTS',
  FILE_ID = 'FILE_ID',
  FILE_SUBJECT = 'FILE_SUBJECT',
  INITIATOR_USERNAME = 'INITIATOR_USERNAME',
  INITIATOR_FP_CODE = 'INITIATOR_FP_CODE',
  TARGET_PARTICIPANT_USERNAME = 'TARGET_PARTICIPANT_USERNAME',
  TARGET_PARTICIPANT_FP_CODE = 'TARGET_PARTICIPANT_FP_CODE',
  ACTIVITY_TYPE_KEY = 'ACTIVITY_TYPE_KEY',
  REASON = 'REASON',
  TASK_ID = 'TASK_ID',
  ACTIVITY_ID = 'ACTIVITY_ID',
  ERROR_DESCRIPTION = 'ERROR_DESCRIPTION',
  SUBJECT_NAME = 'SUBJECT_NAME',
  CONSIGNMENT_ID = 'CONSIGNMENT_ID',
  CONSIGNMENT_NAME = 'CONSIGNMENT_NAME',
  CONSIGNMENT_UID = 'CONSIGNMENT_UID',
  CLASSIFICATION_SCHEME_ID = 'CLASSIFICATION_SCHEME_ID',
  PROCESS_ID = 'PROCESS_ID',
  PROCESS_CLASS = 'PROCESS_CLASS',
  PROCESS_NAME = 'PROCESS_NAME',
  PROCESS_OBJECT_ID = 'PROCESS_OBJECT_ID',
  PROCESS_OBJECT_NAME = 'PROCESS_OBJECT_NAME',
  PROCESS_START_TIME = 'PROCESS_START_TIME',
  RECORDS_PROCESSED = 'RECORDS_PROCESSED',
  NUMBER_OF_INFO_INCIDENTS = 'NUMBER_OF_INFO_INCIDENTS',
  NUMBER_OF_WARNING_INCIDENTS = 'NUMBER_OF_WARNING_INCIDENTS',
  NUMBER_OF_ERROR_INCIDENTS = 'NUMBER_OF_ERROR_INCIDENTS',
  NUMBER_OF_FATAL_INCIDENTS = 'NUMBER_OF_FATAL_INCIDENTS',
  STORAGE_UNIT_ID = 'STORAGE_UNIT_ID',
  STORAGE_UNIT_NUMBER = 'STORAGE_UNIT_NUMBER',
  EPDZ_ERROR_MESSAGE = 'EPDZ_ERROR_MESSAGE',
  EPDZ_FILE_NAME = 'EPDZ_FILE_NAME',
  ID = 'ID',
  CODE = 'CODE',
  NAME = 'NAME',
  COUNT = 'COUNT',
  ENTITY_TYPE = 'ENTITY_TYPE',
  ENTITY_TYPE_CLASS = 'ENTITY_TYPE_CLASS',
  CROSS_REFERENCE_TEMPLATE_NAME = 'CROSS_REFERENCE_TEMPLATE_NAME',
  SUBJECT = 'SUBJECT',
  DOCUMENT_TYPE_CODE = 'DOCUMENT_TYPE_CODE',
  ACTION_TYPE = 'ACTION_TYPE',
  ERROR_PARAMETERS = 'ERROR_PARAMETERS',
  FP_CODE = 'FP_CODE',
  USERNAME = 'USERNAME'
}
