import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CodebookFilterItem, EnumFilterItem, FilterItem} from '../../filter.types';
import {locateOptionByValue} from '@icz/angular-form-elements';
import {TranslateService} from '@ngx-translate/core';
import {FilterValueFormatter} from '../../table.providers';

/**
 * @internal
 */
@Injectable()
export class CodebookEnumFilterValueFormatterService implements FilterValueFormatter {

  private translateService = inject(TranslateService);

  format(filterItem: EnumFilterItem|CodebookFilterItem): Observable<string> {
    if (!filterItem?.list) {
      console.warn(`FilterItem with filterType IN {list, tree} requires to have FilterItem#list ` +
        `specified in order to render human-friendly labels. Displaying list of raw values as fallback.`);

      return of((filterItem.value as string[])!.join(', '));
    }
    else {
      return of(
        this.getHumanFriendlyListLabels(filterItem, filterItem.originId)
          .map(l => this.translateService.instant(l))
          .join(', ')
      );
    }
  }

  private getHumanFriendlyListLabels(item: FilterItem, originId: Nullable<string>): string[] {
    item.value ??= [];

    const value: Array<string|number> = Array.isArray(item.value) ? item.value as string[] : [item.value as string];

    return value.map(value => {
      const option = locateOptionByValue(item.list ?? [], value, originId);

      return option?.label ?? '???';
    });
  }

}
