import {OperationValidator} from '../../../../services/bulk-operation-validation.service';
import {StorageUnitFillerDto} from '|api/document';

export class StorageUnitFillerToolbarValidators {
  static isStorageUnitRunning(): OperationValidator<Nullable<StorageUnitFillerDto>> {
    return (storageUnitFiller: Nullable<StorageUnitFillerDto>) => {
      return (storageUnitFiller && storageUnitFiller.storageUnitFillerState === 'RUNNING') ? {validationMessage: 'Dávka plnění ukládacích jednotek právě probíhá.' } : null;
    };
  }

  static isStorageUnitFinished(): OperationValidator<Nullable<StorageUnitFillerDto>> {
    return (storageUnitFiller: Nullable<StorageUnitFillerDto>) => {
      return (storageUnitFiller && storageUnitFiller.storageUnitFillerState === 'FINISHED') ? {validationMessage: 'Dávka plnění ukládacích jednotek již doběhla.' } : null;
    };
  }

}
