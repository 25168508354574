<div [waiting]="loadingService.isLoading$(this) | async">
  <form [formGroup]="form">
    <icz-section label="Jaké parametry se mají aktualizovat?">
      <icz-radio-group class="grow">
        <div class="col grow">
          <icz-radio-button class="grow" formControlName="onlyEmpty"
                            label="Aktualizovat všechny parametry v obsahu komponenty"
                            secondaryLabel="Dojde k aktualizaci (přepsání) všech parametrů v obsahu komponenty"
                            [value]="false"
          ></icz-radio-button>
          <icz-radio-button class="grow" formControlName="onlyEmpty"
                            label="Aktualizovat pouze prázdné parametry v obsahu komponenty"
                            secondaryLabel="Dojde k aktualizaci (doplnění) pouze nevyplněných parametrů v obsahu komponenty"
                            [value]="true"
                            [fieldDisabled]="allParametersFilled"
          ></icz-radio-button>
        </div>
      </icz-radio-group>
    </icz-section>
    <icz-section label="Hodnoty parametrů v obsahu komponanenty">
      @for (parameter of componentMetadata; track parameter) {
        <div>
          <div class="row">
            <div [ngClass]="{'param-key-red': parameter.empty, 'param-key-green': !parameter.empty}">{{ parameter.placeHolderName }}</div>
            <div> - {{ getParameterValueAsText(parameter) }}</div>
          </div>
        </div>
      }
    </icz-section>
  </form>
</div>

<icz-form-buttons>
  <icz-button leftButtons  [disabled]="loadingService.isLoading(this)" (onAction)="submit()" primary label="Aktualizovat parametry"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="cancel()" label="Storno"></icz-button>
</icz-form-buttons>
