import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  DestroyRef,
  DoCheck,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {clone} from 'lodash';
import {of, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {
  ButtonComponent,
  IczOnChanges,
  IczSimpleChanges,
  InaccessibleDirective,
  PopoverComponent
} from '@icz/angular-essentials';
import {
  CheckboxComponent,
  CHIP_SELECTOR_STATE_PERSISTOR,
  FormChipInputComponent,
  getOptionsByValuesList,
  IczOption
} from '@icz/angular-form-elements';
import {AbstractTableFilter} from '../../abstract-table-filter.component';
import {
  FilterItem,
  FilterSubValue,
  FilterType,
  isEnumFilterItem,
  isFilterWithOptions,
  isListFilterItem,
  NonemptyFilterItem
} from '../../filter.types';
import {
  addressToolbarOperators,
  booleanOperator,
  dateStatisticsToolbarOperators,
  dateToolbarOperators,
  DeferredColumnList,
  empowermentToolbarOperators,
  fileSizeToolbarOperators,
  FilterValue,
  listToolbarOperators,
  numberToolbarOperators,
  subjectRecordToolbarOperators,
  textToolbarOperators
} from '../../table.models';
import {TableToolbarService} from '../table-toolbar.service';
import {FilterTagComponent} from '../filter-tag/filter-tag.component';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {AsyncPipe, NgClass} from '@angular/common';
import {FilterOperator, getOperatorTranslationKey, isNoValueOperator} from '../../table.utils';
import {FilterNameService} from '../../filter-name.service';
import {DEFAULT_FILTERS, FILTER_PLUGINS} from '../../table.providers';

/**
 * @internal
 */
@Component({
  selector: 'icz-filter-item',
  templateUrl: './filter-item.component.html',
  styleUrls: ['./filter-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FilterNameService,
  ],
  standalone: true,
  imports: [
    FilterTagComponent,
    InaccessibleDirective,
    CdkOverlayOrigin,
    ButtonComponent,
    PopoverComponent,
    CheckboxComponent,
    AsyncPipe,
    NgClass,
  ],
})
export class FilterItemComponent implements OnInit, AfterViewInit, IczOnChanges, DoCheck {

  private defaultFilters = inject(DEFAULT_FILTERS);
  private filterPlugins = inject(FILTER_PLUGINS, {optional: true});
  private translateService = inject(TranslateService);
  private cd = inject(ChangeDetectorRef);
  private filterNameService = inject(FilterNameService);
  private chipSelectorStatePersistor = inject(CHIP_SELECTOR_STATE_PERSISTOR);
  private destroyRef = inject(DestroyRef);
  private tableToolbarService = inject(TableToolbarService, {optional: true});

  @Input({required: true})
  set item(item: Nullable<NonemptyFilterItem>) {
    this._item = item;
  }
  get item(): Nullable<NonemptyFilterItem> {
    return this._item;
  }
  private _item: Nullable<NonemptyFilterItem>;

  @Input({required: true}) autoOpen = true;
  @Input({required: true}) isStatic = false;
  @Input() isDisabled = false;
  @Input() useAsFormElement = false;
  @Input() isLoading = false;
  @Input() set deferredFilterList(listInfo: DeferredColumnList) {
    if (this.item) {
      this.item.list = listInfo.list;

      if (this.component) {
        this.component.instance.isLoading = listInfo.isListLoading;
        this.component.changeDetectorRef.detectChanges();
      }
    }
  }

  @Output() valueChanged = new EventEmitter<NonemptyFilterItem>();
  @Output() cancelClicked = new EventEmitter<boolean>();

  private notChosenLabel: string = '';

  protected get filterLabel() {
    return this.item ? this.getFilterItemLabel() : '';
  }

  protected filterValue = of('');

  private get hasNoValueOperator() {
    return isNoValueOperator(this.item?.filterOption?.value);
  }

  private get isFilterWithOptions() {
    return isFilterWithOptions(this.item?.filterType);
  }

  protected get shouldRenderChips() {
    return this.isFilterWithOptions && (
        this.item?.filterOption?.value === FilterOperator.inSet ||
        this.item?.filterOption?.value === FilterOperator.equals ||
        this.item?.filterOption?.value === FilterOperator.notEquals
      );
  }

  protected get isModalFilterItem() {
    return this.item?.filterType === FilterType.SUBJECT_RECORD || this.item?.filterType === FilterType.PAGE_SELECTOR;
  }

  private get filterItemList(): IczOption[] {
    if (isListFilterItem(this.item)) {
      if (isEnumFilterItem(this.item) && this.item?.useCustomChips && this.item?.chipNamespace) {
        return FormChipInputComponent.getOptionsWithCustomChips(
          this.item?.list ?? [],
          null,
          this.item?.chipNamespace,
          this.chipSelectorStatePersistor,
        ) as IczOption<any, any>[];
      }
      else {
        return this.item?.list ?? [];
      }
    }
    else {
      return [];
    }
  }

  protected get selectedOptions() {
    return getOptionsByValuesList(
      this.filterItemList,
      (this.item?.value ?? []) as string[],
      isListFilterItem(this.item!) ? this.item?.originId : undefined
    );
  }

  protected get isBooleanFilter() {
    return this.item?.filterType === FilterType.BOOLEAN;
  }

  protected get shouldDisplayExpansionHint() {
    return this.useAsFormElement && !this.isDisabled && this.isFilterWithOptions;
  }

  @ViewChild('customFilter', {static: false, read: ViewContainerRef})
  protected customFilter!: ViewContainerRef;

  protected isOpen = false;
  protected component: Nullable<ComponentRef<AbstractTableFilter>>;
  private filterTypes: IczOption<FilterOperator>[] = [];
  private reload$ = new Subject<boolean>();

  private _oldItem: Nullable<NonemptyFilterItem>;
  private _oldValue: Nullable<string | string[]>;
  private _oldSubValues: Nullable<FilterSubValue[]>;

  private combinedFilterPlugins = {
    ...this.defaultFilters,
    ...(this.filterPlugins ?? {}),
  };

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.item && changes.item.currentValue) {
      const item: FilterItem = changes.item.currentValue;

      this.filterTypes = this.getFilterOperatorsByType(item.filterType);

      if (item && !item.filterOption) {
        item.filterOption = this.getDefaultFilterOperatorOption(item);
      }

      this.item = item;
    }
  }

  ngDoCheck() {
    if (this.item !== this._oldItem || this.item?.value !== this._oldValue || this.item?.subValues !== this._oldSubValues) {
      this.filterValue = this.getFilterValue();

      this._oldItem = this.item;
      this._oldValue = this.item?.value;
      this._oldSubValues = this.item?.subValues;
    }
  }

  ngOnInit() {
    this.tableToolbarService?.reloadFilters$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(_ => this.cd.detectChanges());

    if (!this.useAsFormElement) {
      this.notChosenLabel = 'Vše';

      this.reload$.pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(closeAfter => {
        this.tableToolbarService?.reloadFilters();
        if (closeAfter) this.closePopup();
        this.cd.detectChanges();
      });
    }
  }

  ngAfterViewInit(): void {
    if (this.autoOpen) this.openPopup();
    else this.isOpen = false;
    this.cd.detectChanges();
  }

  protected cancel($event?: Event) {
    $event?.stopPropagation();
    let isCancelWithEmptyValue = false;
    const emptyFilterValue: FilterValue = {
      value: null,
      label: null,
      filterOperator: null,
      list: this.item?.list,
    };

    if (this.item) {
      if (!isNil(this.item.value) && this.item.value !== '') {
        this.setFilterValue(emptyFilterValue);
        this.resetFilterOperator();
      }
      else if (this.item.filterOption!.value === FilterOperator.empty || this.item.filterOption!.value === FilterOperator.notEmpty) {
        this.resetFilterOperator();
        this.setFilterValue(emptyFilterValue);
      }
      else {
        if (!this.useAsFormElement) {
          this.tableToolbarService?.removeFilterItem(this.item);
        }
        else {
          this.resetFilterOperator();
        }
        isCancelWithEmptyValue = true;
      }
    }

    this.cancelClicked.next(isCancelWithEmptyValue);
  }

  protected openPopup($event?: Event) {
    $event?.stopPropagation();

    if (!this.isDisabled) {
      if (this.isBooleanFilter) return;

      this.isOpen = true;
      this.component = undefined;
      setTimeout(() => this.createFilterContent(), 0);
    }
  }

  private createFilterContent() {
    if (this.item) {
      const componentConstructor = this.combinedFilterPlugins[this.item.filterType]!.filterComponent;

      if (componentConstructor) {
        this.component = this.customFilter.createComponent(componentConstructor) as ComponentRef<AbstractTableFilter>;
        this.component.instance.item = this.item;
        this.component.instance.filterOperators = this.filterTypes;
        this.component.instance.isLoading = this.isLoading;

        this.component.instance.setFilterValue.pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe((res: FilterValue) => {
          this.setFilterValue(res);
        });

        this.component.instance.closePopup.pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(() => this.closePopup());

        this.component.changeDetectorRef.detectChanges();
      }
    }
  }

  private setFilterValue(filterValue: FilterValue) {
    if (this.item) {
      const filterOperator = filterValue.filterOperator as FilterOperator;
      this.item.value = filterValue.value;
      this.item.subValues = filterValue.subValues;
      this.item.label = filterValue.label ? filterValue.label : filterValue.value as string;
      this.item.filterOption = {value: filterOperator, label: getOperatorTranslationKey(filterOperator)};
      this.item.list = filterValue.list;
      this.valueChanged.emit(clone(this.item));

      if (!this.useAsFormElement) {
        this.reload$.next(Boolean(filterValue.closeAfter));
      }

      if (filterValue.closeAfter) {
        this.closePopup();
      }
    }
  }

  protected closePopup() {
    this.isOpen = false;
    this.component = null;
  }

  protected booleanFilterChanged($event: any) {
    this.setFilterValue({filterOperator: FilterOperator.equals, label: $event.toString(), value: $event.toString()});
  }

  private getFilterItemLabel() {
    let out = this.item?.columnLabel ? this.translateService.instant(this.item.columnLabel) : '';

    if (!this.useAsFormElement && !this.item?.filterOption) throw new Error('Filter operators for this filter type not defined!');

    if (this.item && !this.useAsFormElement && !isNoValueOperator(this.item.filterOption!.value) && !this.item.value) {
      out += ': ' + this.translateService.instant(this.notChosenLabel);
    }

    return out;
  }

  private getDefaultFilterOperatorOption(c: FilterItem): IczOption<FilterOperator> {
    const availableOperators = this.getFilterOperatorsByType(c.filterType);

    if (c.filterType === FilterType.TEXT) {
      return availableOperators.find(o => o.value === FilterOperator.contains)!;
    }
    else if (c.filterType === FilterType.ENUM || c.filterType === FilterType.CODEBOOK || c.filterType === FilterType.PAGE_SELECTOR) {
      return availableOperators.find(o => o.value === FilterOperator.equals)!;
    }
    else if (availableOperators) {
      return availableOperators[0];
    }
    else {
      // failsafe defaults
      return {label: getOperatorTranslationKey(FilterOperator.equals), value: FilterOperator.equals, icon: 'equal'};
    }
  }

  private getFilterOperatorsByType(toolbarType: FilterType): Array<IczOption<FilterOperator>> {
    switch (toolbarType) {
      case FilterType.ENUM:
      case FilterType.CODEBOOK:
      case FilterType.PAGE_SELECTOR:
        return listToolbarOperators;
      case FilterType.DATE:
      case FilterType.DATETIME:
        return dateToolbarOperators;
      case FilterType.DATE_STATISTICS:
        return dateStatisticsToolbarOperators;
      case FilterType.NUMBER:
        return numberToolbarOperators;
      case FilterType.TEXT:
        return textToolbarOperators;
      case FilterType.BOOLEAN:
        return booleanOperator;
      case FilterType.FILE_SIZE:
        return fileSizeToolbarOperators;
      case FilterType.EMPOWERMENT:
        return empowermentToolbarOperators;
      case FilterType.SUBJECT_RECORD:
        return subjectRecordToolbarOperators;
      case FilterType.ADDRESS:
        return addressToolbarOperators;
      default:
        return [];
    }
  }

  private getFilterValue() {
    return this.item?.value ?
      this.filterNameService.getFilterItemValue(this.item) :
      of('');
  }

  private resetFilterOperator() {
    this.item!.filterOption = this.getDefaultFilterOperatorOption(this.item!);
  }

  protected shouldDisplayCalendarHint(item: Nullable<FilterItem>) {
    return this.useAsFormElement && !this.isDisabled && item?.filterType === FilterType.DATE;
  }

  protected shouldDisplayCancelHint(item: Nullable<FilterItem>) {
    return !this.isDisabled && (!this.isStatic || item?.value || this.hasNoValueOperator);
  }
}
