import {NgModule} from '@angular/core';
import {EssentialsModule} from '@icz/angular-essentials';
import {LibraryImportModule} from '../.module-library-import/library-import.module';
import {WizardComponent} from './wizard.component';
import {WizardStepDirective} from './wizard-step.directive';
import {FormElementsModule} from '@icz/angular-form-elements';
import {IczFormDirectivesModule} from '../icz-form-directives/icz-form-directives.module';

@NgModule({
  declarations: [
    WizardComponent,
    WizardStepDirective,
  ],
  exports: [
    WizardComponent,
    WizardStepDirective,
  ],
  imports: [
    EssentialsModule,
    LibraryImportModule,
    FormElementsModule,
    IczFormDirectivesModule,
  ]
})
export class WizardModule {}
