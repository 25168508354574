import {Component, inject, OnInit} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {OwnInternalPaperConsignmentElasticDto, OwnPaperConsignmentElasticDto} from '|api/sad';
import {injectModalData, injectModalRef} from '@icz/angular-modal';

@Component({
  selector: 'icz-consignment-change-delivery-service-dialog',
  templateUrl: './consignment-change-delivery-service-dialog.component.html',
  styleUrls: ['./consignment-change-delivery-service-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class ConsignmentChangeDeliveryServiceDialogComponent implements OnInit {

  protected modalRef = injectModalRef<Nullable<number>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected consignment = injectModalData<OwnPaperConsignmentElasticDto | OwnInternalPaperConsignmentElasticDto>();

  areDeliveryServicesLoading = false;

  form = new IczFormGroup({
    deliveryServiceCombinationId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.form.patchValue({
      deliveryServiceCombinationId: this.consignment.deliveryServiceCombinationId,
    });
  }

  submit() {
    const newDeliveryServiceCombinationId = this.form.value.deliveryServiceCombinationId;

    if (newDeliveryServiceCombinationId !== this.consignment.deliveryServiceCombinationId) {
      this.modalRef.close(newDeliveryServiceCombinationId);
    }
    else {
      this.modalRef.close(null);
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

}
