import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Injectable,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import {MatPaginator, MatPaginatorIntl, PageEvent} from '@angular/material/paginator';
import {TranslateModule, TranslateParser, TranslateService} from '@ngx-translate/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczOption, locateOptionByValue, OptionsListComponent} from '@icz/angular-form-elements';
import {ButtonComponent, InterpolatePipe, LabelComponent, PopoverComponent} from '@icz/angular-essentials';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {numberArrayToOptions} from '../table.utils';

/**
 * @internal
 */
@Injectable()
export class CustomPaginator extends MatPaginatorIntl {

  private translate = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private destroyRef = inject(DestroyRef);

  private rangeLabelIntl!: string;

  constructor() {
    super();

    this.translate.onLangChange.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.getTranslations();
    });

    this.getTranslations();
  }

  private getTranslations() {
    this.translate.get([
      'Zobrazeno 0 záznamů',
      'Zobrazeno x záznamů',
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(translation => {
      this.itemsPerPageLabel = translation['Zobrazeno 0 záznamů'];
      this.rangeLabelIntl = translation['Zobrazeno x záznamů'];
      this.changes.next();
    });
  }

  override getRangeLabel = (page: number, pageSize: number, total: number): string => {
    if (total === 0 || pageSize === 0) {
      return this.itemsPerPageLabel;
    }

    total = Math.max(total, 0);
    const start = page * pageSize;
    const end = start < total ?
      Math.min(start + pageSize, total) :
      start + pageSize;

    return this.translateParser.interpolate(this.rangeLabelIntl, {start: start + 1, end, total}) ?? '';
  };

}

/**
 * @internal
 */
const PAGINATOR_PAGE_BUTTONS_COUNT = 4;

/**
 * @internal
 */
@Component({
  selector: 'icz-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    MatPaginator,
    LabelComponent,
    TranslateModule,
    InterpolatePipe,
    PopoverComponent,
    OptionsListComponent,
    CdkOverlayOrigin,
  ],
})
export class TablePaginatorComponent implements AfterViewInit {

  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @ViewChild(MatPaginator, {static: true})
  paginator!: MatPaginator;

  @Input({required: true})
  selectedRowsCount = 0;
  @Input({required: true})
  pageRowCount = 0;

  @Output()
  pageChanged = new EventEmitter<PageEvent>();
  @Output()
  rowCountChanged = new EventEmitter<number>();

  protected availablePages: number[] = [];

  protected selectedItemsCountLabel = 'Vybráno záznamů: {{count}}';

  protected isRowCountSelectorOpen = false;

  protected rowCountOptions: IczOption[] = [
    {
      value: 0, // reserved for AUTO
      label: 'Automaticky',
    },
    ...numberArrayToOptions([20, 50, 100, 200]),
  ];

  protected get selectedRowCount(): IczOption[] {
    return [locateOptionByValue(this.rowCountOptions, this.pageRowCount)!];
  }

  protected get isCollectionPaginable() {
    return this.paginator.length > this.paginator.pageSize;
  }

  protected get currentPageNumber() {
    return this.paginator.pageIndex + 1;
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(page => {
      this.pageChanged.emit(page);
    });
  }

  setPage(pageNumber: number) {
    if (this.paginator.pageIndex !== pageNumber) {
      this.paginator.pageIndex = pageNumber;
      this.paginator.page.next({
        pageIndex: pageNumber,
        pageSize: this.paginator.pageSize,
        length: this.paginator.length,
      });
    }
  }

  setAvailablePagesOptions() {
    const totalPages = this.paginator.getNumberOfPages();
    const effectivePageButtonsCount = Math.min(PAGINATOR_PAGE_BUTTONS_COUNT, totalPages);
    let lowerPagesCount: number;

    if (this.currentPageNumber === 1) {
      lowerPagesCount = 0;
    }
    else if (this.currentPageNumber >= totalPages) {
      lowerPagesCount = effectivePageButtonsCount - 1;
    }
    else if (this.currentPageNumber === totalPages - 1) {
      lowerPagesCount = effectivePageButtonsCount - 2;
    }
    else {
      lowerPagesCount = Math.min(this.paginator.pageIndex, 2);
    }

    this.availablePages = [];

    let i = this.currentPageNumber - lowerPagesCount;

    while (this.availablePages.length < effectivePageButtonsCount) {
      this.availablePages.push(i);
      ++i;
    }

    this.cd.detectChanges();
  }

  protected changeRowCount(selectedOption: IczOption[]) {
    // selectedOption is always single-item list in this scenario
    this.rowCountChanged.emit(selectedOption[0]!.value as number);
    this.isRowCountSelectorOpen = false;
  }

  protected toggleRowCountSelector() {
    this.isRowCountSelectorOpen = !this.isRowCountSelectorOpen;
  }

}
