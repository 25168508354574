import {ChangeDetectorRef, Directive, inject, Input, OnInit} from '@angular/core';
import {
  isValidAtGivenDate,
  isValidInTheFuture,
  isValidNow,
  ObjectWithValidity
} from '../../core/services/data-mapping.utils';
import {Observable} from 'rxjs';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {map} from 'rxjs/operators';
import {IczFormGroup} from '@icz/angular-form-elements';
import {IczOption} from '@icz/angular-form-elements';

export enum VisibleClassifiersMode {
  CURRENTLY_VALID = 'CURRENTLY_VALID',
  CURRENTLY_VALID_AND_FUTURE_VALID = 'CURRENTLY_VALID_AND_FUTURE_VALID',
  VALID_AT_GIVEN_DATE = 'VALID_AT_GIVEN_DATE',
}

@Directive()
export abstract class AbstractClassifierSelectorComponent<T extends ObjectWithValidity> implements OnInit, IczOnChanges {

  private cd = inject(ChangeDetectorRef);

  @Input()
  form!: IczFormGroup;
  @Input()
  controlName!: string;
  @Input()
  mode = VisibleClassifiersMode.CURRENTLY_VALID;
  @Input()
  date: Nullable<Date>;

  abstract classifierSource$: Observable<T[]>;
  abstract optionMapper: (classifier: T) => IczOption<number>;
  prefilteringPredicate: (classifier: T) => boolean = _ => true;

  classifierOptions: IczOption[] = [];

  get isSelectedClassifierExpired() {
    let controlValue: Nullable<number | number[]>;

    if (this.form && this.controlName) {
      controlValue = this.form.get(this.controlName)!.value;
    }

    if (Array.isArray(controlValue)) {
      return controlValue.some(classifierId => this.classifierOptions.find(o => o.value === classifierId)?.isHidden);
    }
    else if (controlValue) {
      return this.classifierOptions.find(o => o.value === controlValue)?.isHidden;
    }
    else {
      return false;
    }
  }

  ngOnInit() {
    this.prepareClassifierOptions();
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.mode || changes.date) {
      this.prepareClassifierOptions();
    }
  }

  protected prepareClassifierOptions() {
    const isEntryConsideredValid = (e: ObjectWithValidity) => {
      if (this.mode === VisibleClassifiersMode.CURRENTLY_VALID || this.mode === VisibleClassifiersMode.VALID_AT_GIVEN_DATE) {
        let referenceDate = new Date();

        if (this.mode === VisibleClassifiersMode.VALID_AT_GIVEN_DATE && this.date) {
          referenceDate = this.date!;
        }

        return isValidAtGivenDate(e, referenceDate);
      }
      else if (this.mode === VisibleClassifiersMode.CURRENTLY_VALID_AND_FUTURE_VALID) {
        return isValidNow(e) || isValidInTheFuture(e);
      }
      else {
        return true;
      }
    };

    this.classifierSource$.pipe(
      map(entries => entries.filter(this.prefilteringPredicate)),
      map(entries => entries.map(e => {
        const option = this.optionMapper(e);

        if (!isEntryConsideredValid(e)) {
          option.isHidden = true;
        }

        return option;
      })),
    ).subscribe(classifierOptions => {
      this.classifierOptions = classifierOptions;
      this.cd.detectChanges();
    });
  }

}
