import {ContentChild, Directive, ElementRef, forwardRef, inject, Input, OnInit} from '@angular/core';

/**
 * A directive which focuses a host input field or a button at its initialization time.
 */
@Directive({
  selector: '[iczAutoFocus]',
  exportAs: 'iczAutoFocus',
  standalone: true,
})
export class AutoFocusDirective implements OnInit {

  private el = inject(ElementRef);

  /**
   * Sets autofocus mode:
   * - true - autofocus at ngOnInit time,
   * - deffered - autofocus after a certain delay after ngOnInit time,
   * - '' - alias for deffered
   * - false - disable autofocus - used mainly when we need to do conditional autofocus
   *
   * Note that iczAutoFocus might not work reliably if it is in a context with multiple autofocus
   *  directives or if it is affected by another directive with focus shifting (focus traps etc.)
   */
  @Input({required: true})
  iczAutoFocus!: true | false | 'deffered'| '';

  @ContentChild(forwardRef(() => AutoFocusDirective))
  protected slave!: AutoFocusDirective;

  /**
   * @internal
   */
  ngOnInit() {
    if (this.iczAutoFocus === false) return;
    setTimeout(() => {
      this.focus();
    });
  }

  /**
   * @internal
   */
  focus() {
    const isFocusable = ((el: {focus: () => void}) => (el && typeof el.focus === 'function') ? el : null);

    // inputs and textareas have priority over buttons
    const focusableDescendants: HTMLElement[] = [
      this.el.nativeElement.querySelector('input'),
      this.el.nativeElement.querySelector('textarea'),
      this.el.nativeElement.querySelector('button')
    ].filter(Boolean);

    const focusable = isFocusable(this.slave) || // slave AutoFocusDirective exist
      // this.slave(this.el.nativeElement) // focus is implemented
      isFocusable(focusableDescendants[0]); // first focusable descendant

    if (focusable) {
      if (this.iczAutoFocus === 'deffered' || this.iczAutoFocus === '') {
        // delay 150ms works almost surely compared do 0ms
        setTimeout(() => { focusable.focus(); }, 150);
      }
      else {
        focusable.focus();
      }
    }
  }

}
