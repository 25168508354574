import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IconComponent} from '../icon/icon.component';
import {TranslateModule} from '@ngx-translate/core';

/**
 * A simple blue text with a static loading icon, used for marking long-running
 * asynchronous tasks in progress in a certain context (most usually tables).
 */
@Component({
  selector: 'icz-static-progress-indicator',
  templateUrl: './static-progress-indicator.component.html',
  styleUrls: ['./static-progress-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, TranslateModule],
})
export class StaticProgressIndicatorComponent {

  /**
   * Human friendly task type label.
   */
  @Input()
  label: string = '';

}
