import {ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {merge, Observable, of, throwError} from 'rxjs';
import {catchError, delay, map, switchMap, take} from 'rxjs/operators';
import {CirculationTaskAction, DocumentState} from '|api/commons';
import {DocumentDto, FileDto, RegistryOfficeTransferDto, StorageUnitDto, StorageUnitFillerDto} from '|api/document';
import {ApiCirculationTaskService, CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {DocumentToolbarAction, DocumentToolbarButtonsService} from '../../services/document-toolbar-buttons.service';
import {ActivityToolbarAction, ActivityToolbarButtonsService, ActivityToolbarView} from '../../services/activity-toolbar-buttons.service';
import {FileToolbarButtonsService} from '../../services/file-toolbar-buttons.service';
import {TaskToolbarAction, TaskToolbarButtonsService} from '../../services/task-toolbar-buttons.service';
import {Button} from '../../../../button-collection/button-collection.component';
import {ToolbarDataService} from '../../../toolbar-data.service';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {
  CirculationView,
  DocumentView,
  ELASTIC_RELOAD_DELAY,
  IN_PROGRESS_ACTIVITY_DOCUMENT_VIEWS,
  RegistryOfficeTransferView,
  StorageUnitView
} from '../../services/toolbar-common.utils';
import {doneTaskResolutions, inProgressTaskResolutions} from '../../../circulation-table/my-tasks.datasource';
import {doneActivityStates, inProgressActivityStates} from '../../../circulation-table/my-activities.datasource';
import {
  DocumentDtoWithAuthorization,
  DocumentOrProfileDtoWithAuthorization,
  FileDtoWithAuthorization
} from '../../../model/dto-with-permissions.interface';
import {
  ExtendedCirculationActivityDto,
  ExtendedCirculationTaskDto,
  ExtendedRegistryOfficeTransferActivityDto
} from '../../../model/elastic-extended-entities.interface';
import {FileDetailCountType, FileDetailService} from '../../../../../services/file-detail.service';
import {DocumentDetailCountType, DocumentDetailService} from '../../../../../services/document-detail.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ObjectDetailPart} from '../../../../../services/abstract-object-detail.service';
import {increment, load} from '../../../../../lib/object-counts';
import {ReceivedDocumentDtoWithInitialHandoverActivity} from '../../../../../services/document-search.service';
import {StorageUnitToolbarButtonsService} from '../../../../../services/storage-unit-toolbar-buttons.service';
import {StorageUnitDetailCountType, StorageUnitDetailService} from '../../../../../services/storage-unit-detail.service';
import {
  StorableObjectToolbarAction,
  StorableObjectToolbarButtonsService,
  StorableObjectView
} from '../../services/storable-object-toolbar-buttons.service';
import {
  TransferableObjectToolbarAction,
  TransferableObjectToolbarButtonsService,
  TransferableObjectView
} from '../../services/transferable-object-toolbar-buttons.service';
import {CurrentSessionService} from '../../../../../services/current-session.service';
import {arrayRemove} from '../../../../../lib/utils';
import {AbstractToolbarButtonsComponent} from '../../../abstract-toolbar-buttons.component';
import {SearchRecordSourceRegistryOfficeTransferActivityDto} from '|api/elastic';
import {Router} from '@angular/router';
import {DocumentAndFileToolbarAction, DocumentAndFileToolbarButtonsService} from '../../services/document-and-file-toolbar-buttons.service';
import {DocumentOrFileDto} from '../../../shared-document.utils';
import {SharedFolderToolbarAction, SharedFolderToolbarButtonsService} from '../../services/shared-folder-toolbar-buttons.service';
import {SharedFolderViewService} from '../../../../../services/shared-folder-view.service';
import {
  FolderShareableObjectToolbarAction,
  FolderShareableToolbarButtonsService
} from '../../services/folder-shareable-object-toolbar-buttons.service';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../../core/services/main-menu-counts.service';
import {StorageUnitFillerToolbarButtonsService} from '../../../../../services/storage-unit-filler-toolbar-buttons.service';

type DocumentToolbarButtonsView = DocumentView | CirculationView | StorageUnitView | RegistryOfficeTransferView;

interface DocumentWithAssociatedTask {
  document: ReceivedDocumentDtoWithInitialHandoverActivity;
  task: CirculationTaskDto;
}

@Component({
  selector: 'icz-document-toolbar-buttons',
  templateUrl: './document-toolbar-buttons.component.html',
  styleUrls: ['./document-toolbar-buttons.component.scss'],
  providers: [
    DocumentToolbarButtonsService,
    FileToolbarButtonsService,
    TaskToolbarButtonsService,
    ActivityToolbarButtonsService,
    StorageUnitToolbarButtonsService,
    StorageUnitFillerToolbarButtonsService,
    StorableObjectToolbarButtonsService,
    TransferableObjectToolbarButtonsService,
    DocumentAndFileToolbarButtonsService,
    SharedFolderToolbarButtonsService,
    FolderShareableToolbarButtonsService,
  ],
})
export class DocumentToolbarButtonsComponent extends AbstractToolbarButtonsComponent<DocumentOrProfileDtoWithAuthorization | FileDto, DocumentToolbarButtonsView> implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private documentToolbarButtonsService = inject(DocumentToolbarButtonsService);
  private folderShareableToolbarButtonsService = inject(FolderShareableToolbarButtonsService);
  private fileToolbarButtonsService = inject(FileToolbarButtonsService);
  private taskToolbarButtonsService = inject(TaskToolbarButtonsService);
  private activityToolbarButtonsService = inject(ActivityToolbarButtonsService);
  private toolbarDataService = inject(ToolbarDataService);
  private apiCirculationTaskService = inject(ApiCirculationTaskService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private currentSessionService = inject(CurrentSessionService);
  private storageUnitToolbarButtonsService = inject(StorageUnitToolbarButtonsService);
  private storageUnitFillerToolbarButtonsService = inject(StorageUnitFillerToolbarButtonsService);
  private storableObjectToolbarButtonsService = inject(StorableObjectToolbarButtonsService);
  private transferableObjectToolbarButtonsService = inject(TransferableObjectToolbarButtonsService);
  private documentAndFileToolbarButtonsService = inject(DocumentAndFileToolbarButtonsService);
  private sharedFolderToolbarButtonsService = inject(SharedFolderToolbarButtonsService);
  private router = inject(Router);
  protected mainMenuCountsService = inject(MainMenuCountsService);
  protected documentDetailService = inject(DocumentDetailService, {optional: true});
  protected fileDetailService = inject(FileDetailService, {optional: true});
  protected storageUnitDetailService = inject(StorageUnitDetailService, {optional: true});
  protected sharedFolderViewService = inject(SharedFolderViewService, {optional: true});

  @Input()
  refActivity: Nullable<CirculationActivityDto>;
  @Input()
  refTask: Nullable<CirculationTaskDto>;
  @Input()
  isRegistryOfficeTransferActivityView!: boolean;
  @Input()
  runningCirculationActivity: Nullable<CirculationActivityDto>;
  @Input()
  isUnitView!: boolean;
  @Input()
  storageUnitFillerId: Nullable<number>;

  get isToolbarLoading() {
    return this.loadingService.isLoading(this) || this.loadingService.isLoading(this.taskToolbarButtonsService);
  }

  get isOnDocumentDetail() {
    return this.view === DocumentView.DOCUMENT_DETAIL;
  }

  get isOnDocumentDetailInProgressTaskView() {
    return this.isOnDocumentDetail && this.refTask && inProgressTaskResolutions.includes(this.refTask.resolution);
  }

  get isOnDocumentDetailInProgressTransferActivityView() {
    return this.isOnDocumentDetail && this.isRegistryOfficeTransferActivityView;
  }

  get isOnDocumentDetailDoneTaskView() {
    return this.isOnDocumentDetail && this.refTask && doneTaskResolutions.includes(this.refTask.resolution);
  }

  get isOnDocumentDetailInProgressActivityView() {
    return this.isOnDocumentDetail && this.refActivity && inProgressActivityStates.includes(this.refActivity.activityState);
  }

  get isOnDocumentDetailDoneActivityView() {
    return this.isOnDocumentDetail && this.refActivity && doneActivityStates.includes(this.refActivity.activityState);
  }

  ngOnInit() {
    merge(
      this.documentToolbarButtonsService.actionCompleted$,
      this.fileToolbarButtonsService.actionCompleted$,
      this.taskToolbarButtonsService.actionCompleted$,
      this.activityToolbarButtonsService.actionCompleted$,
      this.storableObjectToolbarButtonsService.actionCompleted$,
      this.storageUnitToolbarButtonsService.actionCompleted$,
      this.storageUnitFillerToolbarButtonsService.actionCompleted$,
      this.transferableObjectToolbarButtonsService.actionCompleted$,
      this.documentAndFileToolbarButtonsService.actionCompleted$,
      this.folderShareableToolbarButtonsService.actionCompleted$,
      this.sharedFolderToolbarButtonsService.actionCompleted$,
    ).pipe(
      delay(ELASTIC_RELOAD_DELAY),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(action => {
      this.operationCompleted.emit();

      if (this.isOnDocumentDetail) {
        this.router.navigate([this.router.url.split('?')[0]]);
      }

      if (this.documentDetailService || this.storageUnitDetailService || this.sharedFolderViewService) {
        switch (action) {
          case TaskToolbarAction.TASK_REVOKED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case TaskToolbarAction.TASK_REJECTED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case TaskToolbarAction.TASK_TAKEN_OVER:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case TaskToolbarAction.TASK_PASSED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
            });
            // reload nothing
            break;
          case TaskToolbarAction.TASK_TAKEN_OVER_AND_PASSED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
              [DocumentDetailCountType.ACTIVITIES]: increment(),
            });
            break;
          case TaskToolbarAction.TASK_TAKEN_OVER_FROM_EXTERNAL_APP:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_REGISTERED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_REREGISTERED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_DEACTIVATED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_REACTIVATED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_HANDED_OVER_TO_EXTERNAL_APP:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
              [FileDetailCountType.ACTIVITIES]: increment(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_SETTLED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_SETTLE_STARTED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_ADDED_TO_FILE:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_REMOVED_FROM_FILE:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_MOVED_TO_ANOTHER_FILE:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_SETTLEMENT_WITHDRAWN:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.DOCUMENT_CIRCULATION_STARTED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
              [FileDetailCountType.ACTIVITIES]: increment(),
            });
            break;
          case ActivityToolbarAction.ACTIVITY_REVOKED:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case StorableObjectToolbarAction.OBJECT_ADDED_TO_STORAGE_UNIT:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            this.storageUnitDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [StorageUnitDetailCountType.CONTENT]: load(),
            });
            break;
          case StorableObjectToolbarAction.OBJECT_REMOVED_FROM_STORAGE_UNIT:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            this.storageUnitDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [StorageUnitDetailCountType.CONTENT]: load(),
            });
            break;
          case StorableObjectToolbarAction.OBJECT_MOVED_BETWEEN_STORAGE_UNITS:
            this.storageUnitDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [StorageUnitDetailCountType.CONTENT]: load(),
            });
            break;
          case TransferableObjectToolbarAction.TRANSFER_HANDOVER:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case TransferableObjectToolbarAction.TRANSFER_TAKEOVER:
          case TransferableObjectToolbarAction.TRANSFER_REJECT:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentAndFileToolbarAction.MOVED_TO_AGENDA_TRANSFER:
          case DocumentAndFileToolbarAction.REMOVED_FROM_AGENDA_TRANSFER:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case SharedFolderToolbarAction.SHARED_FOLDER_CREATED:
          case SharedFolderToolbarAction.SHARED_FOLDER_UPDATED:
            this.sharedFolderViewService?.reloadAndExpandCurrentlyExpandedNode();
            break;
          case SharedFolderToolbarAction.SHARED_FOLDER_DELETED:
            this.sharedFolderViewService?.reloadAndExpandRoot();
            break;
          case SharedFolderToolbarAction.ENTITY_INSERTED_TO_SHARED_FOLDER:
          case SharedFolderToolbarAction.ENTITY_REMOVED_FROM_SHARED_FOLDER:
          case SharedFolderToolbarAction.ENTITY_MOVED_BETWEEN_SHARED_FOLDERS:
            this.operationCompleted.emit();
            break;
          case FolderShareableObjectToolbarAction.OBJECT_INSERTED_INTO_FOLDER:
          case FolderShareableObjectToolbarAction.OBJECT_REMOVED_FROM_FOLDER:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
              [ObjectDetailPart.OBJECT_PERMISSIONS]: load(),
            });
            break;
          case DocumentToolbarAction.ENTITY_EXCLUDED_FROM_FILLER:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
            });
            break;
          case TransferableObjectToolbarAction.TRANSFER_CANCEL:
          case TransferableObjectToolbarAction.TRANSFER_RETRANSFER:
          case TransferableObjectToolbarAction.TRANSFER_RETURN_TO_OFFICE:
            this.documentDetailService?.reloadObject({
              [ObjectDetailPart.OBJECT_DATA]: load(),
            });
            this.mainMenuCountsService.updateMainMenuCounters([
              CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS,
            ]);
            this.operationCompleted.emit();
            break;
          default:
            this.documentDetailService?.reloadObject();
            this.storageUnitDetailService?.reloadObject();
        }
      }

      this.generateToolbarContents(this.selectedRows ?? []);
    });
  }

  override ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.selectedRows ||
      changes.view ||
      changes.refActivity ||
      changes.refTask ||
      changes.runningCirculationActivity ||
      changes.isRegistryOfficeTransferActivityView ||
      changes.storageUnitFillerId
    ) {
      this.generateToolbarContents(this.selectedRows ?? []);
    }
  }

  protected generateToolbarContents(rows: Nullable<Array<DocumentOrProfileDtoWithAuthorization | FileDto | CirculationTaskDto | CirculationActivityDto | StorageUnitDto>>) {
    let buttons$: Observable<Button[]> = of([]);

    if (this.view === DocumentView.GLOBAL_SEARCH) {
      buttons$ = of([]);
    }
    else if (this.view === DocumentView.SETTLED_DOCUMENTS) {
      buttons$ = this.toolbarDataService.mergeToolbars(
        this.storageUnitFillerToolbarButtonsService.getToolbarButtons(rows as unknown as StorageUnitFillerDto[], {view: this.view, isUnitView: this.isUnitView}),
        this.storableObjectToolbarButtonsService.getToolbarButtons(rows as Array<DocumentDto | FileDto>, StorableObjectView.SETTLED_OBJECTS_LISTING),
        this.transferableObjectToolbarButtonsService.getToolbarButtons(rows as Array<DocumentDto | FileDto>, TransferableObjectView.SETTLED_OBJECTS_LISTING),
        this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view}),
        this.fileToolbarButtonsService.getToolbarButtons(rows as FileDtoWithAuthorization[], this.view),
      );
    } else if (this.view === DocumentView.SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR) {
      buttons$ =  this.documentAndFileToolbarButtonsService.getToolbarButtons(rows as DocumentOrFileDto[], this.view);
    }
    else if (
      this.view === CirculationView.TASKS_IN_PROGRESS ||
      this.view === CirculationView.UNIT_TASKS_IN_PROGRESS ||
      this.isOnDocumentDetailInProgressTaskView
    ) {
      if (rows?.length) {
        const taskIds: number[] = this.isOnDocumentDetailInProgressTaskView ? [this.refTask!.id!] : rows.map(r => r.id!);
        const taskToolbarEntities = (
          this.isOnDocumentDetailInProgressTaskView ?
            [this.documentAndTaskToExtendedTaskDto(rows[0] as DocumentOrProfileDtoWithAuthorization, this.refTask!)] :
            rows
        ) as ExtendedCirculationTaskDto[];

        const availableActions$ = this.apiCirculationTaskService.circulationTaskResolveAvailableActions({
          body: taskIds,
        });

        buttons$ = this.loadingService.doLoading(availableActions$, this).pipe(
          switchMap(availableActions => {
            return this.taskToolbarButtonsService.getToolbarButtons(
              taskToolbarEntities,
              {
                availableTaskActions: availableActions.map(aa => ({
                  taskId: aa.taskId!,
                  availableActions: aa.taskActions!,
                  circulationTask: taskToolbarEntities.find(e => e.id === aa.taskId)!
                })),
                isUnitView: this.isUnitView,
              }
            );
          })
        );
      } else {
        buttons$ = of([]);
      }
    }
    else if (IN_PROGRESS_ACTIVITY_DOCUMENT_VIEWS.includes(this.view as CirculationView) || this.isOnDocumentDetailInProgressActivityView) {
      const activityViewMap: Partial<Record<DocumentView | CirculationView, ActivityToolbarView>> = {
        [CirculationView.DOCUMENT_ACTIVITIES]: ActivityToolbarView.ACTIVITIES_IN_PROGRESS,
        [CirculationView.ACTIVITIES_IN_PROGRESS]: ActivityToolbarView.ACTIVITIES_IN_PROGRESS,
        [DocumentView.DOCUMENT_DETAIL]: ActivityToolbarView.ACTIVITIES_IN_PROGRESS,
      };

      const activityToolbarEntities = (
        this.isOnDocumentDetailInProgressActivityView ?
          [this.documentAndActivityToExtendedActivityDto(rows![0] as DocumentOrProfileDtoWithAuthorization, this.refActivity!)] :
          rows
      ) as ExtendedCirculationActivityDto[];

      buttons$ = this.toolbarDataService.mergeToolbars(
        this.activityToolbarButtonsService.getToolbarButtons(
          activityToolbarEntities,
          {
            viewType: activityViewMap[this.view as CirculationView]!,
            isUnitView: this.isUnitView,
          }
        ),
        of([]),
      );
    }
    else if (this.view === CirculationView.COMPONENT_TASKS_TO_SIGN) {
      buttons$ = this.taskToolbarButtonsService.getToolbarButtons(
        rows as ExtendedCirculationTaskDto[],
        {
          availableTaskActions: rows ? rows.map(row => ({
            taskId: row.id,
            availableActions: [
              CirculationTaskAction.SIGN_BY_TARGET,
              CirculationTaskAction.REJECT_BY_TARGET,
            ],
            circulationTask: row as ExtendedCirculationTaskDto
          })) : [],
          isUnitView: this.isUnitView,
        }
      );
    }
    else if (this.view === CirculationView.COMPONENT_TASKS_TO_APPROVE) {
      buttons$ = this.taskToolbarButtonsService.getToolbarButtons(
        rows as ExtendedCirculationTaskDto[],
        {
          availableTaskActions: rows ? rows.map(row => ({
            taskId: row.id,
            availableActions: [
              CirculationTaskAction.APPROVE_DOCUMENT_BY_TARGET,
              CirculationTaskAction.DISAPPROVE_DOCUMENT_BY_TARGET,
              CirculationTaskAction.REJECT_BY_TARGET,
              CirculationTaskAction.PASS_BY_TARGET,
            ],
            circulationTask: row as ExtendedCirculationTaskDto
          })) : [],
          isUnitView: this.isUnitView,
        }
      );
    }
    else if (this.view === CirculationView.COMPONENT_TASKS_DONE) {
      buttons$ = of([]);
    }
    else if (this.view === DocumentView.AGENDA_TRANSFER_OBJECTS_SELECT || this.view === DocumentView.AGENDA_TRANSFER_READY_TO_TRANSFER || this.view === DocumentView.AGENDA_TRANSFER_CONFIRMED) {
      buttons$ =  this.documentAndFileToolbarButtonsService.getToolbarButtons(rows as DocumentOrFileDto[], this.view);
    }
    else if (this.view === CirculationView.TASKS_DONE || this.view === CirculationView.UNIT_TASKS_DONE || this.isOnDocumentDetailDoneTaskView || this.isOnDocumentDetailDoneActivityView) {
      buttons$ = of([]);
    }
    else if (this.view === DocumentView.FILING_OFFICE_RECEIVED || this.view === DocumentView.FILING_OFFICE_HANDED_OVER) {
      buttons$ =  this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view});
    }
    else if (this.view === DocumentView.FILING_OFFICE_REJECTED) {
      if (rows?.length) {
        const taskToolbarEntities = rows as ReceivedDocumentDtoWithInitialHandoverActivity[];
        const currentUsersSelectedTasksWithDocuments = this.getTasksWithDocumentsFromSelection(taskToolbarEntities);
        const availableActions$ = this.apiCirculationTaskService.circulationTaskResolveAvailableActions({
          body: currentUsersSelectedTasksWithDocuments.map(t => t.task.id),
        });

        buttons$ = this.loadingService.doLoading(
          currentUsersSelectedTasksWithDocuments.length ? availableActions$ : of([]),
          this
        ).pipe(
          switchMap(availableActions => {
            return this.toolbarDataService.mergeToolbars(
              this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view}),
              this.taskToolbarButtonsService.getToolbarButtons(
                currentUsersSelectedTasksWithDocuments.map(t => this.documentAndTaskToExtendedTaskDto(t.document, t.task)),
                {
                  availableTaskActions: availableActions.map(aa => {
                    const documentWithTaskForAvailableActions = currentUsersSelectedTasksWithDocuments.find(t => t.task.id === aa.taskId)!;
                    return {
                      taskId: aa.taskId!,
                      availableActions: aa.taskActions!,
                      circulationTask: this.documentAndTaskToExtendedTaskDto(documentWithTaskForAvailableActions.document, documentWithTaskForAvailableActions.task),
                    };
                  }),
                  isUnitView: this.isUnitView,
                  view: DocumentView.FILING_OFFICE_REJECTED,
                }
              ),
            );
          }),
          map(buttons => {
            const moreMenuButton = buttons.find(b => b.label === 'Více');

            if (moreMenuButton) {
              const buttonsCopy = [...buttons];
              arrayRemove(buttonsCopy, moreMenuButton);

              return [
                ...buttonsCopy,
                moreMenuButton,
              ];
            }
            else {
              return buttons;
            }
          }),
        );
      } else {
        buttons$ = of([]);
      }
    }
    else if (this.view === DocumentView.FILE_CONTENTS) {
      buttons$ = this.toolbarDataService.mergeToolbars(
        this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view, fileDetailService: this.fileDetailService!}),
        this.documentAndFileToolbarButtonsService.getToolbarButtons(rows as DocumentOrFileDto[], this.view),
      );
    }
    else if (this.view === StorageUnitView.STORAGE_UNITS_TABLE) {
      buttons$ = this.toolbarDataService.mergeToolbars(
        this.storageUnitToolbarButtonsService.getToolbarButtons(rows as StorageUnitDto[], this.view as StorageUnitView),
        this.transferableObjectToolbarButtonsService.getToolbarButtons(rows as Array<StorageUnitDto>, TransferableObjectView.STORAGE_UNITS_TABLE),
      );
    }
    else if (this.view === StorageUnitView.STORAGE_UNIT_FILLERS_TABLE) {
      buttons$ = this.toolbarDataService.mergeToolbars(
        this.storageUnitFillerToolbarButtonsService.getToolbarButtons(rows as unknown as StorageUnitFillerDto[], {view: this.view, isUnitView: this.isUnitView}),
      );
    }
    else if (
      this.view === RegistryOfficeTransferView.REGISTRY_OFFICE_TO_RESOLVE_ISSUES ||
      this.view === RegistryOfficeTransferView.OFFICER_TO_RESOLVE_ISSUES ||
      this.view === RegistryOfficeTransferView.REGISTRY_OFFICE_TO_TAKE_OVER ||
      this.isOnDocumentDetailInProgressTransferActivityView
    ) {
      const document = rows![0] as DocumentDtoWithAuthorization;
      const registryOfficeTransferActivityEntities = (
        this.isOnDocumentDetailInProgressTransferActivityView ?
          [this.documentAndTransferToExtendedTransferDto(document, document.registryOfficeTransfer!)] :
          rows
      ) as SearchRecordSourceRegistryOfficeTransferActivityDto[];
      buttons$ = this.transferableObjectToolbarButtonsService.getToolbarButtons(registryOfficeTransferActivityEntities, this.isOnDocumentDetailInProgressTransferActivityView ? TransferableObjectView.ENTITY_TRANSFER_IN_PROGRESS : this.view as RegistryOfficeTransferView);
    } else if (this.view === DocumentView.STORAGE_UNIT_CONTENTS) {
      buttons$ = this.toolbarDataService.mergeToolbars(
        this.storableObjectToolbarButtonsService.getToolbarButtons(rows as Array<DocumentDto | FileDto>, StorableObjectView.STORAGE_UNIT_CONTENTS, this.storageUnitDetailService!.object),
        this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view}),
      );
    }
    else if (this.view === DocumentView.DOCUMENT_DETAIL && rows?.[0] && (rows as DocumentDtoWithAuthorization[])[0].fileId) {
      buttons$ = this.documentDetailService!.getFileForDocument(rows[0] as DocumentDto)!.pipe(
        take(1),
        catchError(error => {
          if (error.status === 401) {
            return of(undefined);
          } else {
            return throwError(() => error);
          }
        }),
        switchMap(file => this.toolbarDataService.mergeToolbars(
          this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view as DocumentView, file}),
          this.documentAndFileToolbarButtonsService.getToolbarButtons(rows as DocumentOrFileDto[], this.view as DocumentView),
          !this.isOnDocumentDetail ? this.fileToolbarButtonsService.getToolbarButtons(rows as FileDtoWithAuthorization[]) : of([]),
        )),
      );
    }
    else if (this.view === CirculationView.ISSD_TASKS_REJECTED) {
      buttons$ = this.taskToolbarButtonsService.getToolbarButtons(
        rows as ExtendedCirculationTaskDto[],
        {
          availableTaskActions: rows ? rows.map(row => ({
            taskId: row.id,
            availableActions: [
              CirculationTaskAction.TAKEOVER_BY_INITIATOR
            ],
            circulationTask: row as ExtendedCirculationTaskDto
          })) : [],
          view: this.view
        }
      );
    }
    else if (this.view === CirculationView.ISSD_TASKS || this.view === DocumentView.ISSD_DOCUMENTS || this.view === RegistryOfficeTransferView.HANDED_OVER_AND_STORED) {
      buttons$ = of([]);
    }
    else if (this.view === DocumentView.SHARED_FOLDER) {
      buttons$ = this.sharedFolderToolbarButtonsService.getToolbarButtons(rows as DocumentOrFileDto[]);
    }
    else {
      if (this.isOnDocumentDetail) {
        if (this.documentDetailService!.object.documentState === DocumentState.SETTLED) {
          buttons$ = this.toolbarDataService.mergeToolbars(
            this.storableObjectToolbarButtonsService.getToolbarButtons(rows as Array<DocumentDto | FileDto>, StorableObjectView.DOCUMENT_DETAIL),
            this.transferableObjectToolbarButtonsService.getToolbarButtons(rows as Array<DocumentDto | FileDto>, TransferableObjectView.DOCUMENT_DETAIL),
            this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view as DocumentView}),
            this.folderShareableToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[]),
          );
        } else {
          buttons$ = this.toolbarDataService.mergeToolbars(
            this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view as DocumentView, runningActivity: this.runningCirculationActivity}),
            this.documentAndFileToolbarButtonsService.getToolbarButtons(rows as DocumentOrFileDto[], this.view as DocumentView),
            this.folderShareableToolbarButtonsService.getToolbarButtons(rows as DocumentOrFileDto[]),
          );
        }
      } else {
        buttons$ = this.toolbarDataService.mergeToolbars(
          this.documentToolbarButtonsService.getToolbarButtons(rows as DocumentDtoWithAuthorization[], {viewType: this.view as DocumentView}),
          this.fileToolbarButtonsService.getCreateFileContentToolbarButtons(rows as FileDtoWithAuthorization[], this.view as DocumentView),
          this.fileToolbarButtonsService.getToolbarButtons(rows as FileDtoWithAuthorization[], this.view as DocumentView),
          this.documentAndFileToolbarButtonsService.getToolbarButtons(rows as DocumentOrFileDto[], this.view as DocumentView),
        );
      }
    }

    this.loadingService.doLoading(
      buttons$,
      this
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(buttons => {
      this.toolbarButtons = buttons;
      setTimeout(() => {
        this.cd.detectChanges();
      }, 0);
    });
  }

  private getTasksWithDocumentsFromSelection(taskToolbarEntities: ReceivedDocumentDtoWithInitialHandoverActivity[]): DocumentWithAssociatedTask[] {
    const currentUsersSelectedTasksWithDocuments: DocumentWithAssociatedTask[] = [];

    for (const selectedEntity of taskToolbarEntities) {
      const currentUsersTaskOfSelectedDocument = (selectedEntity.handoverActivities ?? []).flatMap(a => a.tasks ?? []).find(
        t => (
          !t.isManagementTask &&
          t.ownerFunctionalPositionId === this.currentSessionService.currentUserFunctionalPosition!.id
        )
      );

      if (currentUsersTaskOfSelectedDocument) {
        currentUsersSelectedTasksWithDocuments.push({
          document: selectedEntity,
          task: currentUsersTaskOfSelectedDocument,
        });
      }
    }

    return currentUsersSelectedTasksWithDocuments;
  }

  private documentAndTaskToExtendedTaskDto(document: DocumentOrProfileDtoWithAuthorization, task: CirculationTaskDto) {
    return {
      ...document,
      ...task
    } as unknown as ExtendedCirculationTaskDto;
  }

  private documentAndActivityToExtendedActivityDto(document: DocumentOrProfileDtoWithAuthorization, activity: CirculationActivityDto) {
    return {
      ...document,
      ...activity
    } as unknown as ExtendedCirculationActivityDto;
  }

  private documentAndTransferToExtendedTransferDto(document: DocumentOrProfileDtoWithAuthorization, transfer: RegistryOfficeTransferDto) {
    return {
      ...document,
      ...transfer
    } as unknown as ExtendedRegistryOfficeTransferActivityDto;
  }

}
