import {DestroyRef, Directive, EventEmitter, inject, Input, OnInit, Output, signal} from '@angular/core';
import {combineLatest} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {
  ClassificationSchemeDto,
  DisposalScheduleDto,
  DocumentTypeDto,
  EntityClassDto,
} from '|api/codebook';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {DisposalOperationCode, RetentionTriggerTypeCode} from '|api/commons';
import {isExternalRetentionTriggerType, isRetentionTriggerTypeWithPeriod} from '../../../shared-document.utils';
import {SimplifiedDisposalScheduleDto} from '|api/document';

@Directive()
export abstract class AbstractDocumentSettleFormComponent implements OnInit {
  private codebookService = inject(CodebookService);
  protected destroyRef = inject(DestroyRef);

  @Input({required: true}) form!: IczFormGroup;
  @Output() disposalScheduleSourceChange = new EventEmitter<void>();

  disposalSchedules: DisposalScheduleDto[] = [];
  retentionTriggerTypesOptions: IczOption[] = enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode);
  disposalOperationCodeOptions = enumToOptions('disposalOperationCode', DisposalOperationCode);
  classificationSchemes: ClassificationSchemeDto[] = [];

  isRetentionTriggerTypeWithPeriod = signal(false);
  isExternalRetentionTriggerTypeWithPeriod = signal(false);

  entityClassDtos!: EntityClassDto[];
  documentTypes!: DocumentTypeDto[];
  allExternalRetentionTriggers: IczOption[] = [];
  documentCreateDate: Nullable<string>;
  availableDisposalSchedules:  SimplifiedDisposalScheduleDto[] = [];

  get entityClassId() {
    return this.form ? this.form.get('entityClassId')!.value : null;
  }

  get documentTypeId() {
    return this.form ? this.form.get('documentTypeId')!.value : null;
  }
  get retentionTriggerYearCtrl() {
    return this.form.get('yearOfRetentionPeriodStart') as IczFormControl;
  }

  get retentionTriggerCheckYearCtrl() {
    return this.form.get('triggerEventCheckYear') as IczFormControl;
  }

  codebooksLoaded() {}

  ngOnInit () {
    combineLatest([
      this.codebookService.documentTypes(),
      this.codebookService.entityClasses(),
      this.codebookService.disposalSchedules(),
      this.codebookService.externalRetentionTriggers(),
      this.codebookService.classificationSchemes(),
    ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([documentTypes, entityClasses, disposalSchedules, externalRetentionTriggers, classificationSchemes]) => {
      this.documentTypes = documentTypes;
      this.entityClassDtos = entityClasses;
      this.disposalSchedules = disposalSchedules;
      this.classificationSchemes = classificationSchemes;
      this.allExternalRetentionTriggers = externalRetentionTriggers.map(rt => ({
        value: rt.id,
        label: `${rt.code} ${rt.description}`,
      }));
      this.codebooksLoaded();
    });

    this.form.get('retentionTriggerTypeCode')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((selectedRTT: Nullable<RetentionTriggerTypeCode>) => {
      if (selectedRTT) {
        this.isRetentionTriggerTypeWithPeriod.set(isRetentionTriggerTypeWithPeriod(selectedRTT));
        this.isExternalRetentionTriggerTypeWithPeriod.set(isExternalRetentionTriggerType(selectedRTT));
        if (isRetentionTriggerTypeWithPeriod(selectedRTT)) {
          this.retentionTriggerYearCtrl.enable();
          this.retentionTriggerCheckYearCtrl.enable();
          this.retentionTriggerYearCtrl.setValidators([IczValidators.required()]);
          this.retentionTriggerCheckYearCtrl.setValidators([IczValidators.required(), IczValidators.min((new Date()).getFullYear()), IczValidators.isInteger()]);
        } else {
          this.retentionTriggerYearCtrl.disable();
          this.retentionTriggerCheckYearCtrl.disable();
          this.retentionTriggerYearCtrl.clearValidators();
          this.retentionTriggerCheckYearCtrl.clearValidators();
        }
      } else {
        this.isRetentionTriggerTypeWithPeriod.set(false);
        this.isExternalRetentionTriggerTypeWithPeriod.set(false);
        this.retentionTriggerYearCtrl.disable();
        this.retentionTriggerCheckYearCtrl.disable();
        this.retentionTriggerYearCtrl.clearValidators();
        this.retentionTriggerCheckYearCtrl.clearValidators();
      }

      this.retentionTriggerCheckYearCtrl.updateValueAndValidity({emitEvent: false});
      (this.retentionTriggerCheckYearCtrl.statusChanges as EventEmitter<any>).emit(this.retentionTriggerCheckYearCtrl.status);
      this.retentionTriggerYearCtrl.updateValueAndValidity({emitEvent: false});
      (this.retentionTriggerYearCtrl.statusChanges as EventEmitter<any>).emit(this.retentionTriggerYearCtrl.status);
    });

    this.retentionTriggerYearCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) {
        this.retentionTriggerCheckYearCtrl.clearValidators();
        this.retentionTriggerCheckYearCtrl.disable();
        this.retentionTriggerCheckYearCtrl.setValue(null);
      } else {
        this.retentionTriggerCheckYearCtrl.setValidators([IczValidators.required()]);
        this.retentionTriggerCheckYearCtrl.enable();
      }
      this.retentionTriggerCheckYearCtrl.updateValueAndValidity({emitEvent: false});
      (this.retentionTriggerCheckYearCtrl.statusChanges as EventEmitter<any>).emit(this.retentionTriggerCheckYearCtrl.status);
    });

    this.retentionTriggerCheckYearCtrl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) {
        this.retentionTriggerYearCtrl.clearValidators();
      } else {
        this.retentionTriggerYearCtrl.setValidators([IczValidators.required()]);
      }
      this.retentionTriggerYearCtrl.updateValueAndValidity({emitEvent: false});
      (this.retentionTriggerYearCtrl.statusChanges as EventEmitter<any>).emit(this.retentionTriggerYearCtrl.status);
    });
  }
}
