import {inject, Injectable, InjectionToken} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';

/**
 * Injection token to inject browser global namespace.
 */
export const WINDOW = new InjectionToken('Browser Window Object', {
  factory: () => window,
  providedIn: 'root',
});

/**
 * Responsivity breakpoint boundaries.
 * ICZ Angular components currently support resolutions from 1280x800.
 * The applications are optimized for resolution 1920x1080.
 */
export enum ResponsivityBreakpoint {
  TABLET = 1270, // <0x0, 1280x800>
  DESKTOP_SM = 1430, // <1280x800, 1440x900>
  DESKTOP_MD = 1670, // <1440x900, 1680x1050>
  DESKTOP_LG = Infinity, // <1680x1050, +Inf>
}

/**
 * A service used for detection of current responsive device class.
 * Also provides a few related utilities.
 */
@Injectable({
  providedIn: 'root'
})
export class ResponsivityService {

  private window = inject(WINDOW);

  // keep it in sync with ResponsivityBreakpoint enum and respect ascending breakpoints order pls
  private breakpointsOrder = [
    0,
    ResponsivityBreakpoint.TABLET,
    ResponsivityBreakpoint.DESKTOP_SM,
    ResponsivityBreakpoint.DESKTOP_MD,
    ResponsivityBreakpoint.DESKTOP_LG,
  ];

  private windowResized$ = fromEvent(this.window, 'resize').pipe(
    startWith(null),
    map(_ => this.window.innerWidth),
    debounceTime(10),
  );

  /**
   * Emits TRUE if the device is considered a tablet by its browser viewport dimensins.
   */
  isTablet$ = this.createObsFromBreakpoint(ResponsivityBreakpoint.TABLET);
  /**
   * Emits TRUE if the device is considered a small laptop (13") by its browser viewport dimensins.
   */
  isSmallDesktop$ = this.createObsFromBreakpoint(ResponsivityBreakpoint.DESKTOP_SM);
  /**
   * Emits TRUE if the device is considered a big laptop (15") by its browser viewport dimensins.
   */
  isMidDesktop$ = this.createObsFromBreakpoint(ResponsivityBreakpoint.DESKTOP_MD);
  /**
   * Emits TRUE if the device is considered a desktop (>=19") by its browser viewport dimensins.
   */
  isLargeDesktop$ = this.createObsFromBreakpoint(ResponsivityBreakpoint.DESKTOP_LG);

  private activeBreakpoint$ = this.windowResized$.pipe(
    map(viewportWidth => {
      for (const breakpointSize of this.breakpointsOrder) {
        if (viewportWidth < breakpointSize) {
          return breakpointSize;
        }
      }

      return ResponsivityBreakpoint.DESKTOP_LG;
    }),
    distinctUntilChanged(),
  );

  /**
   * Emits breakpoints which are currently not visible.
   * It is assumed that the applications will have its default breakpoint = DESKTOP_LG
   * and if device class is smaller, will conditionally hide content meant for bigger devices.
   */
  invisibleBreakpointsList$ = this.activeBreakpoint$.pipe(
    map(activeBreakpoint => this.breakpointsOrder.filter(b => b >= activeBreakpoint)),
    distinctUntilChanged(),
  );

  private createObsFromBreakpoint(breakpoint: number) {
    return this.windowResized$.pipe(
      map(_ => this.window.innerWidth < breakpoint),
      distinctUntilChanged(),
    );
  }

}
