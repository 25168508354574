import {Pipe, PipeTransform} from '@angular/core';
import {InterpolatePipe} from '@icz/angular-essentials';
import {InterpolationContext} from '@icz/angular-essentials';

/**
 * Because our project aims to depend on as few libraries as possible
 * (due to long lifespan and lots of security issues accumulating in the libraries over time),
 * here is implemented a simple Mustache syntax evaluator which implements this subset of Mustache:
 * https://github.com/janl/mustache.js#sections
 *
 * !! currently LIMITED to template sections which are NOT nested because our use-case does not require them.
 */
@Pipe({
  name: 'processTemplateStructures',
  pure: true,
})
export class ProcessTemplateStructuresPipe implements PipeTransform {

  transform(value: string, context: InterpolationContext): string {
    const blockBeginRe = /{{ *#([A-Za-z0-9]+) *}}/g;
    const blockBeginResult = blockBeginRe.exec(value);

    if (blockBeginResult) {
      const blockedVar = blockBeginResult[1];
      const blockBeginDesignatorMatch = blockBeginResult[0];

      const blockEndRe = new RegExp(`{{ */${blockedVar} *}}`, 'g');
      const blockEndResult = blockEndRe.exec(value);

      if (blockEndResult) {
        const blockEndDesignatorMatch = blockEndResult[0];
        const blockContentRe = new RegExp(`${blockBeginDesignatorMatch}(.+)${blockEndDesignatorMatch}`, 'gs');

        if (!context[blockedVar]) {
          value = value.replace(blockContentRe, '');
        }
        else {
          const blockContent = blockContentRe.exec(value);

          if (blockContent) {
            const interpolator = new InterpolatePipe();
            const blockContentCode = blockContent[1];

            if (Array.isArray(context[blockedVar])) {
              let expandedTemplate = '';

              for (const item of context[blockedVar]) {
                if (typeof(item) === 'object') { // item !== null is already sanitized
                  expandedTemplate += interpolator.transform(
                    blockContentCode,
                    {...context, ...item}, // the inner body of the loop might also contain references to global context
                  );
                }
                else {
                  // or it could be implemented using Mustache syntax: {{.}} which expands primitive values
                  throw new Error(`Context variable "${blockedVar}" is not an iterable of objects.`);
                }
              }

              value = value.replace(blockContentRe, expandedTemplate);
            }
            else { // scalar value
              value = value.replace(
                blockContentRe,
                interpolator.transform(blockContentCode, context),
              );
            }
          }
        }
      }
      else {
        throw new Error(`Matching block end for expression "${blockBeginDesignatorMatch}" not found.`);
      }
    }

    return value;
  }

}
