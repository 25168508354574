/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { elasticsearchCountDocumentsAndFilesInStorageUnit } from '../fn/elasticsearch/elasticsearch-count-documents-and-files-in-storage-unit';
import { ElasticsearchCountDocumentsAndFilesInStorageUnit$Params } from '../fn/elasticsearch/elasticsearch-count-documents-and-files-in-storage-unit';
import { elasticsearchCountDocumentsInFile } from '../fn/elasticsearch/elasticsearch-count-documents-in-file';
import { ElasticsearchCountDocumentsInFile$Params } from '../fn/elasticsearch/elasticsearch-count-documents-in-file';
import { elasticsearchCountRegistryOfficeTransferIncidentsForEntity } from '../fn/elasticsearch/elasticsearch-count-registry-office-transfer-incidents-for-entity';
import { ElasticsearchCountRegistryOfficeTransferIncidentsForEntity$Params } from '../fn/elasticsearch/elasticsearch-count-registry-office-transfer-incidents-for-entity';
import { elasticsearchElasticUpdateByIssdId } from '../fn/elasticsearch/elasticsearch-elastic-update-by-issd-id';
import { ElasticsearchElasticUpdateByIssdId$Params } from '../fn/elasticsearch/elasticsearch-elastic-update-by-issd-id';
import { elasticsearchElasticUpdateKeywordId } from '../fn/elasticsearch/elasticsearch-elastic-update-keyword-id';
import { ElasticsearchElasticUpdateKeywordId$Params } from '../fn/elasticsearch/elasticsearch-elastic-update-keyword-id';
import { elasticsearchFind } from '../fn/elasticsearch/elasticsearch-find';
import { ElasticsearchFind$Params } from '../fn/elasticsearch/elasticsearch-find';
import { elasticsearchFindDocumentActivities } from '../fn/elasticsearch/elasticsearch-find-document-activities';
import { ElasticsearchFindDocumentActivities$Params } from '../fn/elasticsearch/elasticsearch-find-document-activities';
import { elasticsearchFindDocumentsAndFilesInStorageUnit } from '../fn/elasticsearch/elasticsearch-find-documents-and-files-in-storage-unit';
import { ElasticsearchFindDocumentsAndFilesInStorageUnit$Params } from '../fn/elasticsearch/elasticsearch-find-documents-and-files-in-storage-unit';
import { elasticsearchFindDocumentsInFile } from '../fn/elasticsearch/elasticsearch-find-documents-in-file';
import { ElasticsearchFindDocumentsInFile$Params } from '../fn/elasticsearch/elasticsearch-find-documents-in-file';
import { elasticsearchFindFileActivities } from '../fn/elasticsearch/elasticsearch-find-file-activities';
import { ElasticsearchFindFileActivities$Params } from '../fn/elasticsearch/elasticsearch-find-file-activities';
import { elasticsearchFindInEntities } from '../fn/elasticsearch/elasticsearch-find-in-entities';
import { ElasticsearchFindInEntities$Params } from '../fn/elasticsearch/elasticsearch-find-in-entities';
import { elasticsearchFindRegistryOfficeTransferIncidentsForEntities } from '../fn/elasticsearch/elasticsearch-find-registry-office-transfer-incidents-for-entities';
import { ElasticsearchFindRegistryOfficeTransferIncidentsForEntities$Params } from '../fn/elasticsearch/elasticsearch-find-registry-office-transfer-incidents-for-entities';
import { elasticsearchFindRegistryOfficeTransferIncidentsForEntity } from '../fn/elasticsearch/elasticsearch-find-registry-office-transfer-incidents-for-entity';
import { ElasticsearchFindRegistryOfficeTransferIncidentsForEntity$Params } from '../fn/elasticsearch/elasticsearch-find-registry-office-transfer-incidents-for-entity';
import { elasticsearchFindRegistryOfficeTransferIncidentsForProblem } from '../fn/elasticsearch/elasticsearch-find-registry-office-transfer-incidents-for-problem';
import { ElasticsearchFindRegistryOfficeTransferIncidentsForProblem$Params } from '../fn/elasticsearch/elasticsearch-find-registry-office-transfer-incidents-for-problem';
import { elasticsearchFindStorageUnits } from '../fn/elasticsearch/elasticsearch-find-storage-units';
import { ElasticsearchFindStorageUnits$Params } from '../fn/elasticsearch/elasticsearch-find-storage-units';
import { elasticsearchGetCountForViews } from '../fn/elasticsearch/elasticsearch-get-count-for-views';
import { elasticsearchGetCountForViews_1 } from '../fn/elasticsearch/elasticsearch-get-count-for-views-1';
import { ElasticsearchGetCountForViews_1$Params } from '../fn/elasticsearch/elasticsearch-get-count-for-views-1';
import { ElasticsearchGetCountForViews$Params } from '../fn/elasticsearch/elasticsearch-get-count-for-views';
import { elasticsearchGetDocumentActivityCount } from '../fn/elasticsearch/elasticsearch-get-document-activity-count';
import { ElasticsearchGetDocumentActivityCount$Params } from '../fn/elasticsearch/elasticsearch-get-document-activity-count';
import { elasticsearchGetFileActivityCount } from '../fn/elasticsearch/elasticsearch-get-file-activity-count';
import { ElasticsearchGetFileActivityCount$Params } from '../fn/elasticsearch/elasticsearch-get-file-activity-count';
import { elasticsearchGetSubjectUsage } from '../fn/elasticsearch/elasticsearch-get-subject-usage';
import { ElasticsearchGetSubjectUsage$Params } from '../fn/elasticsearch/elasticsearch-get-subject-usage';
import { elasticsearchLoadEntitiesForStorageUnitInsertView } from '../fn/elasticsearch/elasticsearch-load-entities-for-storage-unit-insert-view';
import { ElasticsearchLoadEntitiesForStorageUnitInsertView$Params } from '../fn/elasticsearch/elasticsearch-load-entities-for-storage-unit-insert-view';
import { elasticsearchLoadView } from '../fn/elasticsearch/elasticsearch-load-view';
import { ElasticsearchLoadView$Params } from '../fn/elasticsearch/elasticsearch-load-view';
import { elasticsearchLoadViewAndReturnCount } from '../fn/elasticsearch/elasticsearch-load-view-and-return-count';
import { ElasticsearchLoadViewAndReturnCount$Params } from '../fn/elasticsearch/elasticsearch-load-view-and-return-count';
import { elasticsearchLoadViewAndReturnCountForOrgUnit } from '../fn/elasticsearch/elasticsearch-load-view-and-return-count-for-org-unit';
import { ElasticsearchLoadViewAndReturnCountForOrgUnit$Params } from '../fn/elasticsearch/elasticsearch-load-view-and-return-count-for-org-unit';
import { elasticsearchLoadViewForOrgUnit } from '../fn/elasticsearch/elasticsearch-load-view-for-org-unit';
import { ElasticsearchLoadViewForOrgUnit$Params } from '../fn/elasticsearch/elasticsearch-load-view-for-org-unit';
import { elasticsearchLoadViewWithParams } from '../fn/elasticsearch/elasticsearch-load-view-with-params';
import { ElasticsearchLoadViewWithParams$Params } from '../fn/elasticsearch/elasticsearch-load-view-with-params';
import { elasticsearchScheduleMassIndexing } from '../fn/elasticsearch/elasticsearch-schedule-mass-indexing';
import { ElasticsearchScheduleMassIndexing$Params } from '../fn/elasticsearch/elasticsearch-schedule-mass-indexing';
import { elasticsearchStopAndDiscardAllMassIndexingJobs } from '../fn/elasticsearch/elasticsearch-stop-and-discard-all-mass-indexing-jobs';
import { ElasticsearchStopAndDiscardAllMassIndexingJobs$Params } from '../fn/elasticsearch/elasticsearch-stop-and-discard-all-mass-indexing-jobs';
import { elasticsearchStopAndDiscardMassIndexingJob } from '../fn/elasticsearch/elasticsearch-stop-and-discard-mass-indexing-job';
import { ElasticsearchStopAndDiscardMassIndexingJob$Params } from '../fn/elasticsearch/elasticsearch-stop-and-discard-mass-indexing-job';
import { HibernateSearchScheduleMassIndexerResponse } from '../models/hibernate-search-schedule-mass-indexer-response';
import { PageSearchRecordDto } from '../models/page-search-record-dto';
import { PageSubjectRecordUsageDto } from '../models/page-subject-record-usage-dto';


/**
 * Controller for management of elasticsearch
 */
@Injectable({ providedIn: 'root' })
export class ApiElasticsearchService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `elasticsearchGetCountForViews()` */
  static readonly ElasticsearchGetCountForViewsPath = '/elastic/view/count';

  /**
   * Get count for all views
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchGetCountForViews()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchGetCountForViews$Response(params?: ElasticsearchGetCountForViews$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: number;
}>> {
    return elasticsearchGetCountForViews(this.http, this.rootUrl, params, context);
  }

  /**
   * Get count for all views
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchGetCountForViews$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchGetCountForViews(params?: ElasticsearchGetCountForViews$Params|null|undefined, context?: HttpContext): Observable<{
[key: string]: number;
}> {
    return this.elasticsearchGetCountForViews$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: number;
}>): {
[key: string]: number;
} => r.body)
    );
  }

  /** Path part for operation `elasticsearchGetCountForViews_1()` */
  static readonly ElasticsearchGetCountForViews_1Path = '/elastic/view/count';

  /**
   * Get count for demanded views
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchGetCountForViews_1()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  elasticsearchGetCountForViews_1$Response(params: ElasticsearchGetCountForViews_1$Params, context?: HttpContext): Observable<StrictHttpResponse<{
[key: string]: number;
}>> {
    return elasticsearchGetCountForViews_1(this.http, this.rootUrl, params, context);
  }

  /**
   * Get count for demanded views
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchGetCountForViews_1$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  elasticsearchGetCountForViews_1(params: ElasticsearchGetCountForViews_1$Params, context?: HttpContext): Observable<{
[key: string]: number;
}> {
    return this.elasticsearchGetCountForViews_1$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
[key: string]: number;
}>): {
[key: string]: number;
} => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindRegistryOfficeTransferIncidentsForEntities()` */
  static readonly ElasticsearchFindRegistryOfficeTransferIncidentsForEntitiesPath = '/elastic/registry-office-transfer/incidents/search-api';

  /**
   * Finds entities incidents of registry office transfer based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindRegistryOfficeTransferIncidentsForEntities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  elasticsearchFindRegistryOfficeTransferIncidentsForEntities$Response(params: ElasticsearchFindRegistryOfficeTransferIncidentsForEntities$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindRegistryOfficeTransferIncidentsForEntities(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds entities incidents of registry office transfer based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindRegistryOfficeTransferIncidentsForEntities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  elasticsearchFindRegistryOfficeTransferIncidentsForEntities(params: ElasticsearchFindRegistryOfficeTransferIncidentsForEntities$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindRegistryOfficeTransferIncidentsForEntities$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchStopAndDiscardAllMassIndexingJobs()` */
  static readonly ElasticsearchStopAndDiscardAllMassIndexingJobsPath = '/elastic/mass-indexer/stop-and-discard-all-jobs';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchStopAndDiscardAllMassIndexingJobs()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchStopAndDiscardAllMassIndexingJobs$Response(params?: ElasticsearchStopAndDiscardAllMassIndexingJobs$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return elasticsearchStopAndDiscardAllMassIndexingJobs(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchStopAndDiscardAllMassIndexingJobs$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchStopAndDiscardAllMassIndexingJobs(params?: ElasticsearchStopAndDiscardAllMassIndexingJobs$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.elasticsearchStopAndDiscardAllMassIndexingJobs$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `elasticsearchScheduleMassIndexing()` */
  static readonly ElasticsearchScheduleMassIndexingPath = '/elastic/mass-indexer/schedule';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchScheduleMassIndexing()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  elasticsearchScheduleMassIndexing$Response(params: ElasticsearchScheduleMassIndexing$Params, context?: HttpContext): Observable<StrictHttpResponse<HibernateSearchScheduleMassIndexerResponse>> {
    return elasticsearchScheduleMassIndexing(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchScheduleMassIndexing$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  elasticsearchScheduleMassIndexing(params: ElasticsearchScheduleMassIndexing$Params, context?: HttpContext): Observable<HibernateSearchScheduleMassIndexerResponse> {
    return this.elasticsearchScheduleMassIndexing$Response(params, context).pipe(
      map((r: StrictHttpResponse<HibernateSearchScheduleMassIndexerResponse>): HibernateSearchScheduleMassIndexerResponse => r.body)
    );
  }

  /** Path part for operation `elasticsearchStopAndDiscardMassIndexingJob()` */
  static readonly ElasticsearchStopAndDiscardMassIndexingJobPath = '/elastic/mass-indexer/job/{key}/stop-and-discard';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchStopAndDiscardMassIndexingJob()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchStopAndDiscardMassIndexingJob$Response(params: ElasticsearchStopAndDiscardMassIndexingJob$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return elasticsearchStopAndDiscardMassIndexingJob(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchStopAndDiscardMassIndexingJob$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchStopAndDiscardMassIndexingJob(params: ElasticsearchStopAndDiscardMassIndexingJob$Params, context?: HttpContext): Observable<void> {
    return this.elasticsearchStopAndDiscardMassIndexingJob$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `elasticsearchLoadViewWithParams()` */
  static readonly ElasticsearchLoadViewWithParamsPath = '/elastic/view/{viewName}/{viewParameterName}/{viewParameterValue}/search-api';

  /**
   * Load view based on name and criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchLoadViewWithParams()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadViewWithParams$Response(params: ElasticsearchLoadViewWithParams$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchLoadViewWithParams(this.http, this.rootUrl, params, context);
  }

  /**
   * Load view based on name and criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchLoadViewWithParams$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadViewWithParams(params: ElasticsearchLoadViewWithParams$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchLoadViewWithParams$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchLoadView()` */
  static readonly ElasticsearchLoadViewPath = '/elastic/view/{viewName}/search-api';

  /**
   * Load view based on name and criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchLoadView()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadView$Response(params: ElasticsearchLoadView$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchLoadView(this.http, this.rootUrl, params, context);
  }

  /**
   * Load view based on name and criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchLoadView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadView(params: ElasticsearchLoadView$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchLoadView$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchLoadViewAndReturnCount()` */
  static readonly ElasticsearchLoadViewAndReturnCountPath = '/elastic/view/{viewName}/search-api/count';

  /**
   * Loads view and returns count based on name and criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchLoadViewAndReturnCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadViewAndReturnCount$Response(params: ElasticsearchLoadViewAndReturnCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return elasticsearchLoadViewAndReturnCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Loads view and returns count based on name and criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchLoadViewAndReturnCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadViewAndReturnCount(params: ElasticsearchLoadViewAndReturnCount$Params, context?: HttpContext): Observable<number> {
    return this.elasticsearchLoadViewAndReturnCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `elasticsearchLoadEntitiesForStorageUnitInsertView()` */
  static readonly ElasticsearchLoadEntitiesForStorageUnitInsertViewPath = '/elastic/view/{viewName}/{storageUnitId}/search-api';

  /**
   * Load view based on name and criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchLoadEntitiesForStorageUnitInsertView()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadEntitiesForStorageUnitInsertView$Response(params: ElasticsearchLoadEntitiesForStorageUnitInsertView$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchLoadEntitiesForStorageUnitInsertView(this.http, this.rootUrl, params, context);
  }

  /**
   * Load view based on name and criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchLoadEntitiesForStorageUnitInsertView$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadEntitiesForStorageUnitInsertView(params: ElasticsearchLoadEntitiesForStorageUnitInsertView$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchLoadEntitiesForStorageUnitInsertView$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchLoadViewForOrgUnit()` */
  static readonly ElasticsearchLoadViewForOrgUnitPath = '/elastic/view/{orgUnitViewName}/org-unit/{orgUnitId}/search-api';

  /**
   * Load view based on name and criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchLoadViewForOrgUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadViewForOrgUnit$Response(params: ElasticsearchLoadViewForOrgUnit$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchLoadViewForOrgUnit(this.http, this.rootUrl, params, context);
  }

  /**
   * Load view based on name and criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchLoadViewForOrgUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadViewForOrgUnit(params: ElasticsearchLoadViewForOrgUnit$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchLoadViewForOrgUnit$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchLoadViewAndReturnCountForOrgUnit()` */
  static readonly ElasticsearchLoadViewAndReturnCountForOrgUnitPath = '/elastic/view/{orgUnitViewName}/org-unit/{orgUnitId}/search-api/count';

  /**
   * Loads view and returns count based on name and criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchLoadViewAndReturnCountForOrgUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadViewAndReturnCountForOrgUnit$Response(params: ElasticsearchLoadViewAndReturnCountForOrgUnit$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return elasticsearchLoadViewAndReturnCountForOrgUnit(this.http, this.rootUrl, params, context);
  }

  /**
   * Loads view and returns count based on name and criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchLoadViewAndReturnCountForOrgUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchLoadViewAndReturnCountForOrgUnit(params: ElasticsearchLoadViewAndReturnCountForOrgUnit$Params, context?: HttpContext): Observable<number> {
    return this.elasticsearchLoadViewAndReturnCountForOrgUnit$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `elasticsearchGetSubjectUsage()` */
  static readonly ElasticsearchGetSubjectUsagePath = '/elastic/subject-record/{subjectId}/usage';

  /**
   * Find search records based on criteria in request, and the current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchGetSubjectUsage()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchGetSubjectUsage$Response(params: ElasticsearchGetSubjectUsage$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSubjectRecordUsageDto>> {
    return elasticsearchGetSubjectUsage(this.http, this.rootUrl, params, context);
  }

  /**
   * Find search records based on criteria in request, and the current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchGetSubjectUsage$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchGetSubjectUsage(params: ElasticsearchGetSubjectUsage$Params, context?: HttpContext): Observable<PageSubjectRecordUsageDto> {
    return this.elasticsearchGetSubjectUsage$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSubjectRecordUsageDto>): PageSubjectRecordUsageDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindStorageUnits()` */
  static readonly ElasticsearchFindStorageUnitsPath = '/elastic/storage-units/search-api';

  /**
   * Find all storage unit search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindStorageUnits()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindStorageUnits$Response(params?: ElasticsearchFindStorageUnits$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindStorageUnits(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all storage unit search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindStorageUnits$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindStorageUnits(params?: ElasticsearchFindStorageUnits$Params|null|undefined, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindStorageUnits$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindDocumentsAndFilesInStorageUnit()` */
  static readonly ElasticsearchFindDocumentsAndFilesInStorageUnitPath = '/elastic/storage-unit-entities/{storageUnitId}/search-api';

  /**
   * Find a given storage unit documents and files search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindDocumentsAndFilesInStorageUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindDocumentsAndFilesInStorageUnit$Response(params: ElasticsearchFindDocumentsAndFilesInStorageUnit$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindDocumentsAndFilesInStorageUnit(this.http, this.rootUrl, params, context);
  }

  /**
   * Find a given storage unit documents and files search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindDocumentsAndFilesInStorageUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindDocumentsAndFilesInStorageUnit(params: ElasticsearchFindDocumentsAndFilesInStorageUnit$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindDocumentsAndFilesInStorageUnit$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchCountDocumentsAndFilesInStorageUnit()` */
  static readonly ElasticsearchCountDocumentsAndFilesInStorageUnitPath = '/elastic/storage-unit-entities/{storageUnitId}/search-api/count';

  /**
   * Find a given storage unit documents and files search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchCountDocumentsAndFilesInStorageUnit()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchCountDocumentsAndFilesInStorageUnit$Response(params: ElasticsearchCountDocumentsAndFilesInStorageUnit$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return elasticsearchCountDocumentsAndFilesInStorageUnit(this.http, this.rootUrl, params, context);
  }

  /**
   * Find a given storage unit documents and files search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchCountDocumentsAndFilesInStorageUnit$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchCountDocumentsAndFilesInStorageUnit(params: ElasticsearchCountDocumentsAndFilesInStorageUnit$Params, context?: HttpContext): Observable<number> {
    return this.elasticsearchCountDocumentsAndFilesInStorageUnit$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `elasticsearchFind()` */
  static readonly ElasticsearchFindPath = '/elastic/search-api';

  /**
   * Find search records based on criteria in request, and the current user.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFind$Response(params?: ElasticsearchFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find search records based on criteria in request, and the current user.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFind(params?: ElasticsearchFind$Params|null|undefined, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindRegistryOfficeTransferIncidentsForEntity()` */
  static readonly ElasticsearchFindRegistryOfficeTransferIncidentsForEntityPath = '/elastic/registry-office-transfer/{entityType}/{entityId}/incidents/search-api';

  /**
   * Finds entity incidents of registry office transfer based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindRegistryOfficeTransferIncidentsForEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindRegistryOfficeTransferIncidentsForEntity$Response(params: ElasticsearchFindRegistryOfficeTransferIncidentsForEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindRegistryOfficeTransferIncidentsForEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds entity incidents of registry office transfer based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindRegistryOfficeTransferIncidentsForEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindRegistryOfficeTransferIncidentsForEntity(params: ElasticsearchFindRegistryOfficeTransferIncidentsForEntity$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindRegistryOfficeTransferIncidentsForEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchCountRegistryOfficeTransferIncidentsForEntity()` */
  static readonly ElasticsearchCountRegistryOfficeTransferIncidentsForEntityPath = '/elastic/registry-office-transfer/{entityType}/{entityId}/incidents/search-api/count';

  /**
   * Gets count of entity incidents of registry office transfer based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchCountRegistryOfficeTransferIncidentsForEntity()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchCountRegistryOfficeTransferIncidentsForEntity$Response(params: ElasticsearchCountRegistryOfficeTransferIncidentsForEntity$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return elasticsearchCountRegistryOfficeTransferIncidentsForEntity(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets count of entity incidents of registry office transfer based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchCountRegistryOfficeTransferIncidentsForEntity$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchCountRegistryOfficeTransferIncidentsForEntity(params: ElasticsearchCountRegistryOfficeTransferIncidentsForEntity$Params, context?: HttpContext): Observable<number> {
    return this.elasticsearchCountRegistryOfficeTransferIncidentsForEntity$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindRegistryOfficeTransferIncidentsForProblem()` */
  static readonly ElasticsearchFindRegistryOfficeTransferIncidentsForProblemPath = '/elastic/registry-office-transfer/problems/{id}/incidents/search-api';

  /**
   * Finds incidents of registry office transfer for problem based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindRegistryOfficeTransferIncidentsForProblem()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindRegistryOfficeTransferIncidentsForProblem$Response(params: ElasticsearchFindRegistryOfficeTransferIncidentsForProblem$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindRegistryOfficeTransferIncidentsForProblem(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds incidents of registry office transfer for problem based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindRegistryOfficeTransferIncidentsForProblem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindRegistryOfficeTransferIncidentsForProblem(params: ElasticsearchFindRegistryOfficeTransferIncidentsForProblem$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindRegistryOfficeTransferIncidentsForProblem$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchElasticUpdateKeywordId()` */
  static readonly ElasticsearchElasticUpdateKeywordIdPath = '/elastic/keywords-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchElasticUpdateKeywordId()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchElasticUpdateKeywordId$Response(params?: ElasticsearchElasticUpdateKeywordId$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return elasticsearchElasticUpdateKeywordId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchElasticUpdateKeywordId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchElasticUpdateKeywordId(params?: ElasticsearchElasticUpdateKeywordId$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.elasticsearchElasticUpdateKeywordId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `elasticsearchElasticUpdateByIssdId()` */
  static readonly ElasticsearchElasticUpdateByIssdIdPath = '/elastic/issd-update';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchElasticUpdateByIssdId()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchElasticUpdateByIssdId$Response(params?: ElasticsearchElasticUpdateByIssdId$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return elasticsearchElasticUpdateByIssdId(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchElasticUpdateByIssdId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchElasticUpdateByIssdId(params?: ElasticsearchElasticUpdateByIssdId$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.elasticsearchElasticUpdateByIssdId$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindInEntities()` */
  static readonly ElasticsearchFindInEntitiesPath = '/elastic/find-in-entities';

  /**
   * Search by text in elasticsearch index and field.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindInEntities()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindInEntities$Response(params: ElasticsearchFindInEntities$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindInEntities(this.http, this.rootUrl, params, context);
  }

  /**
   * Search by text in elasticsearch index and field.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindInEntities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindInEntities(params: ElasticsearchFindInEntities$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindInEntities$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindDocumentsInFile()` */
  static readonly ElasticsearchFindDocumentsInFilePath = '/elastic/file-documents/{fileId}/search-api';

  /**
   * Find a given file's document search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindDocumentsInFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindDocumentsInFile$Response(params: ElasticsearchFindDocumentsInFile$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindDocumentsInFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Find a given file's document search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindDocumentsInFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindDocumentsInFile(params: ElasticsearchFindDocumentsInFile$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindDocumentsInFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchCountDocumentsInFile()` */
  static readonly ElasticsearchCountDocumentsInFilePath = '/elastic/file-documents/{fileId}/search-api/count';

  /**
   * Find a given file's document search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchCountDocumentsInFile()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchCountDocumentsInFile$Response(params: ElasticsearchCountDocumentsInFile$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return elasticsearchCountDocumentsInFile(this.http, this.rootUrl, params, context);
  }

  /**
   * Find a given file's document search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchCountDocumentsInFile$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchCountDocumentsInFile(params: ElasticsearchCountDocumentsInFile$Params, context?: HttpContext): Observable<number> {
    return this.elasticsearchCountDocumentsInFile$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindFileActivities()` */
  static readonly ElasticsearchFindFileActivitiesPath = '/elastic/file-activities/{fileId}/search-api';

  /**
   * Find a given file's activity search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindFileActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindFileActivities$Response(params: ElasticsearchFindFileActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindFileActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * Find a given file's activity search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindFileActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindFileActivities(params: ElasticsearchFindFileActivities$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindFileActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchGetFileActivityCount()` */
  static readonly ElasticsearchGetFileActivityCountPath = '/elastic/file-activities/{fileId}/search-api/count';

  /**
   * Get total count of a given file's activity search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchGetFileActivityCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchGetFileActivityCount$Response(params: ElasticsearchGetFileActivityCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return elasticsearchGetFileActivityCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Get total count of a given file's activity search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchGetFileActivityCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchGetFileActivityCount(params: ElasticsearchGetFileActivityCount$Params, context?: HttpContext): Observable<number> {
    return this.elasticsearchGetFileActivityCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `elasticsearchFindDocumentActivities()` */
  static readonly ElasticsearchFindDocumentActivitiesPath = '/elastic/document-activities/{documentId}/search-api';

  /**
   * Find a given document's activity search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchFindDocumentActivities()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindDocumentActivities$Response(params: ElasticsearchFindDocumentActivities$Params, context?: HttpContext): Observable<StrictHttpResponse<PageSearchRecordDto>> {
    return elasticsearchFindDocumentActivities(this.http, this.rootUrl, params, context);
  }

  /**
   * Find a given document's activity search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchFindDocumentActivities$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchFindDocumentActivities(params: ElasticsearchFindDocumentActivities$Params, context?: HttpContext): Observable<PageSearchRecordDto> {
    return this.elasticsearchFindDocumentActivities$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSearchRecordDto>): PageSearchRecordDto => r.body)
    );
  }

  /** Path part for operation `elasticsearchGetDocumentActivityCount()` */
  static readonly ElasticsearchGetDocumentActivityCountPath = '/elastic/document-activities/{documentId}/search-api/count';

  /**
   * Get total count of a given document's activity search records based on criteria in request.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `elasticsearchGetDocumentActivityCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchGetDocumentActivityCount$Response(params: ElasticsearchGetDocumentActivityCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return elasticsearchGetDocumentActivityCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Get total count of a given document's activity search records based on criteria in request.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `elasticsearchGetDocumentActivityCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  elasticsearchGetDocumentActivityCount(params: ElasticsearchGetDocumentActivityCount$Params, context?: HttpContext): Observable<number> {
    return this.elasticsearchGetDocumentActivityCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
