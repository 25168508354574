import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {AutoFocusDirective, FileSizeUnit, transformFileSize} from '@icz/angular-essentials';
import {
  FormAutocompleteComponent,
  FormFieldComponent,
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidators
} from '@icz/angular-form-elements';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ReactiveFormsModule} from '@angular/forms';

/**
 * @internal
 */
export interface FileSizeChangedEvent {
  fileSize: number;
  unit: FileSizeUnit;
}

/**
 * @internal
 */
@Component({
  selector: 'icz-file-size-filter',
  templateUrl: './file-size-filter.component.html',
  styleUrls: ['./file-size-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormFieldComponent,
    FormAutocompleteComponent,
    ReactiveFormsModule,
    AutoFocusDirective,
  ],
})
export class FileSizeFilterComponent implements OnInit {

  private destroyRef = inject(DestroyRef);

  @Output() fileSizeChanged = new EventEmitter<FileSizeChangedEvent>();

  protected form = new IczFormGroup({
    fileSize: new IczFormControl<Nullable<number>>(null, [IczValidators.min(0), IczValidators.isDecimal()]),
    unit: new IczFormControl<Nullable<FileSizeUnit>>(FileSizeUnit.KB),
  });

  protected sizeOptions: IczOption[] = [
    {label: 'B', value: FileSizeUnit.B},
    {label: 'kB', value: FileSizeUnit.KB},
    {label: 'MB', value: FileSizeUnit.MB},
    {label: 'GB', value: FileSizeUnit.GB},
  ];

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => this.formValueChanged(value));
  }

  private formValueChanged(value: {fileSize?: Nullable<number>, unit?: Nullable<FileSizeUnit>}) {
    const {fileSize, unit} = value;
    const fileSizeTransformed = transformFileSize(fileSize!, unit!, true);
    if (this.form.valid) {
      this.fileSizeChanged.emit({
        fileSize: fileSizeTransformed,
        unit: unit!,
      });
    }
  }

}
