<icz-section label="Informace o doručení" [formGroup]="form">

  <icz-form-autocomplete
    label="Způsob doručení" [options]="deliveryTypeOptions$ | async"
    formControlName="deliveryTypeId"
  ></icz-form-autocomplete>

  <icz-form-autocomplete label="Druh zásilky" [options]="basicServiceWithDistributorOptions"
    formControlName="basicServiceId"
  ></icz-form-autocomplete>

  @if (isCreateFilingOfficeInternal) {
    <icz-form-field label="Identifikátor" formControlName="uid"></icz-form-field>
  }

  @if (isDistributionClassPostOfficeOrPersonal) {
    @if (isConsignmentRecommended) {
      <icz-form-field label="Podací číslo" formControlName="postingNumber"></icz-form-field>
      @if (isDistributionClassPostOffice) {
        <icz-form-field label="Podací pošta" formControlName="senderPostOffice"></icz-form-field>
      }
    }
  }

  <div class="row">
    @if (isCreateFilingOfficePaper) {
      <icz-date-picker class="grow" label="Datum odeslání" formControlName="dispatchDate"></icz-date-picker>
    }
    <div class="row grow-2">
      <icz-date-picker label="Datum doručení" class="grow-2 mr-8" formControlName="deliveryDate" [selectableDates]="isValidDeliveryDate">
        <icz-icon suffix size="small" iczTooltip="ui.document.datetime.tooltip" svgIcon="info"></icz-icon>
      </icz-date-picker>
      <icz-time-picker label="Čas doručení" formControlName="deliveryTime" class="grow"></icz-time-picker>
    </div>
  </div>

  @if (showExternalId) {
    <icz-form-field
      formControlName="externalId" label="Identifikátor zásilky">
    </icz-form-field>
  }
</icz-section>
