import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

import {InterpolationContext} from '../essentials.utils';
import {TranslateModule} from '@ngx-translate/core';
import {InterpolatePipe} from '../interpolate.pipe';
import {NgTemplateOutlet} from '@angular/common';

/**
 * Heading level, corresponding to <h1>/<h2>/<h3> elements.
 */
type HeadingLevel = '1'|'2'|'3';

/**
 * Heading component with our skinning and ability to perform mustache expression interpolation.
 */
@Component({
  selector: 'icz-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, InterpolatePipe, NgTemplateOutlet],
})
export class HeadingComponent {

  @HostBinding('class.h1')
  protected get isLevel1() { return this.level === '1'; }
  @HostBinding('class.h2')
  protected get isLevel2() { return this.level === '2'; }
  @HostBinding('class.h3')
  protected get isLevel3() { return this.level === '3'; }

  /**
   * Heading level.
   * @see HeadingLevel
   */
  @Input()
  level: HeadingLevel = '1';
  /**
   * Heading label. Can contain mustache-like expressions.
   */
  @Input({required: true})
  label: string = '';
  /**
   * Heading label context used for mustache-like expressions interpolation.
   */
  @Input()
  labelContext: Nullable<InterpolationContext>;

}
