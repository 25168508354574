import {FunctionalPositionDto} from '|api/auth-server';
import {DocumentState} from '|api/commons';
import {DigitalComponentCompleteDto, DigitalComponentDto, PaperComponentDto} from '|api/component';
import {DocumentDto} from '|api/document';
import {ButtonDisablerFn, Guard} from '../../../button-collection/button-collection.component';
import {
  getLatestDigitalComponentVersion,
  isReceivedDocumentObject,
  receivedConsignmentTypeDisallowsAdditionalAnalogComponents,
  receivedConsignmentTypeDisallowsAdditionalDigitalComponents
} from '../../shared-document.utils';
import {EsslComponentDto} from '../../../../services/essl-component-search.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {CommonToolbarDisablers} from '../../document-toolbar/services/toolbar-common.disablers';
import {EsslComponentOperationValidators} from './essl-component-operation-validators';
import {DeliveryTypeDto} from '|api/codebook';

export class EsslComponentToolbarDisablers {

  static isParentDocumentDeactivated(document: DocumentDto): ButtonDisablerFn {
    return () => {
      return document?.documentState === DocumentState.DEACTIVATED ? {tooltip: 'Dokument je zrušený'} : null;
    };
  }

  static isParentDocumentReceived(document: DocumentDto): ButtonDisablerFn {
    return () => {
      return isReceivedDocumentObject(document) ? {tooltip: 'K doručeným dokumentům nelze přidávat nové komponenty'} : null;
    };
  }

  static receivedConsignmentTypeDisallowsAdditionalAnalogComponents(document: DocumentDto, deliveryType: DeliveryTypeDto): ButtonDisablerFn {
    return () => {
      return receivedConsignmentTypeDisallowsAdditionalAnalogComponents(document, deliveryType) ? {tooltip: 'K doručeným dokumentům doručeným tímto způsobem doručení nelze dodatečně přidávat analogové komponenty.'} : null;
    };
  }

  static receivedConsignmentTypeDisallowsAdditionalDigitalComponents(document: DocumentDto, deliveryType: DeliveryTypeDto): ButtonDisablerFn {
    return () => {
      return receivedConsignmentTypeDisallowsAdditionalDigitalComponents(document, deliveryType) ? {tooltip: 'K doručeným dokumentům doručeným tímto způsobem doručení nelze dodatečně přidávat digitální komponenty.'} : null;
    };
  }

  static isParentDocumentSettled(document: DocumentDto): ButtonDisablerFn {
    return () => {
      return document.documentState === DocumentState.SETTLED ? {tooltip: 'Dokument je vyřízený'} : null;
    };
  }

  @Guard()
  static someComponentsAreNotDigital(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsNotDigital());
    };
  }

  static someComponentsAreInConversion(selection: DigitalComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsInConversion());
    };
  }

  @Guard()
  static someComponentsAreNotPaper(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsNotPaper());
    };
  }

  static someComponentsDontHaveDigitalRendition(selection: PaperComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentDoesntHaveDigitalRendition());
    };
  }

  static someComponentsDontHaveAuthConversion(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.someComponentsDontHaveAuthConversion());
    };
  }


  static someComponentsAreNotInAllowedFormatForAuthConversionLE(selection: PaperComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentsIsNotInAllowedFormatForAuthConversionLE());
    };
  }

  static someComponentsAreLocked(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsLocked());
    };
  }

  static someComponentsAreNotInAllowedFormat(selection: DigitalComponentDto[], allowedFormats: string[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsNotInAllowedFormat(allowedFormats));
    };
  }

  @Guard()
  static someComponentsAreNotLocked(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsNotLocked());
    };
  }

  static someComponentsAreFinal(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsFinal());
    };
  }

  static someComponentsAreNotFinal(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsNotFinal());
    };
  }

  static componentsAreNotPdf(selection: DigitalComponentCompleteDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsNotPdf());
    };
  }

  static anotherAsyncOperationIsRunning(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.anotherAsyncOperationIsRunning());
    };
  }

  static someComponentsAreNotFromTemplate(selection: DigitalComponentCompleteDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsNotFromTemplate());
    };
  }

  static isNotLastComponentVersionInViewer(selection: EsslComponentDto[], viewerDigitalComponentVersionId: Nullable<number>): ButtonDisablerFn {
    return () => {
      if (selection.length === 1) {
        const isLastDigitalComponentVersion = (
          !viewerDigitalComponentVersionId ||
          getLatestDigitalComponentVersion((selection as unknown as DigitalComponentCompleteDto[])[0])?.id === viewerDigitalComponentVersionId
        );

        return !isLastDigitalComponentVersion ? {tooltip: 'Není otevřena poslední verze komponenty'} : null;
      }
      else {
        // irrelevant for multiple or zero selected components as the viewer will always display only one component
        return null;
      }
    };
  }

  static isUnlockPreconditionNotMet(
    selection: EsslComponentDto[],
    currentUserFP: FunctionalPositionDto,
    organizationalStructureService: OrganizationalStructureService,
    document: DocumentDto,
  ): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.isUnlockPreconditionNotMet(currentUserFP, organizationalStructureService, document));
    };
  }

  static someComponentsAreNotContainers(selection: DigitalComponentCompleteDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsNotContainer());
    };
  }

  static someComponentsAreAlreadyExtracted(selection: DigitalComponentCompleteDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentIsAlreadyExtracted());
    };
  }

  static someComponentsHaveAuthConversion(selection: EsslComponentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, EsslComponentOperationValidators.componentHasAuthorizedConversion());
    };
  }

}
