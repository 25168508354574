import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  TemplateRef
} from '@angular/core';
import {ChipComponent, TooltipDirective, TRUNCATED_TEXT_TOOLTIP_DELAY} from '@icz/angular-essentials';

import {IczOption} from '@icz/angular-form-elements';
import {LowerCasePipe, NgTemplateOutlet} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FilterOperator, getOperatorTranslationKey, isNoValueOperator} from '../../table.utils';

/**
 * @internal
 */
@Component({
  selector: 'icz-filter-tag',
  templateUrl: './filter-tag.component.html',
  styleUrls: ['./filter-tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ChipComponent,
    NgTemplateOutlet,
    TranslateModule,
    TooltipDirective,
    LowerCasePipe,
  ],
})
export class FilterTagComponent {
  @Input({required: true})
  filterName: string = '';
  @Input()
  filterValue: Nullable<string> = '';
  @Input()
  operator: Nullable<FilterOperator>;
  @Input()
  customTemplate: Nullable<TemplateRef<any>>;
  @Input()
  selectedOptions: Nullable<IczOption[]>;
  @HostBinding('class.is-disabled')
  @Input()
  isDisabled = false;

  @Output()
  clicked = new EventEmitter<void>();

  protected readonly TRUNCATED_TEXT_TOOLTIP_DELAY = TRUNCATED_TEXT_TOOLTIP_DELAY;

  @HostBinding('class.is-active')
  protected get isActive() {
    return this.operator && (this.filterValue || isNoValueOperator(this.operator));
  }

  @HostBinding('tabindex')
  protected tabIndex = 0;

  @HostListener('keydown', ['$event'])
  protected handleFocus($event: KeyboardEvent): void {
    if ($event.key === 'Enter' || $event.key === ' ') {
      this.clicked.emit();
    }
  }

  protected getTranslationKey(operator: Nullable<FilterOperator>) {
    return getOperatorTranslationKey(operator);
  }

}
