/* eslint-disable @angular-eslint/no-input-rename */
import {Directive, ElementRef, inject, Input} from '@angular/core';
import {AbstractHighlightDirective} from './abstract-highlight.directive';
import {
  DatePickerComponent,
  FormAutocompleteComponent,
  FormFieldComponent,
  FormTreeSelectorComponent,
  PrimitiveValueFormField
} from '@icz/angular-form-elements';
import {FormPopupTableSelectorComponent} from '@icz/angular-table';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';

@Directive({
  selector: '[iczHighlightFormField]',
})
export class HighlightFormFieldDirective extends AbstractHighlightDirective implements IczOnChanges {

  private el = inject(ElementRef);
  private formAutocompleteComponent = inject(FormAutocompleteComponent, {optional: true});
  private formFieldComponent = inject(FormFieldComponent, {optional: true});
  private formTreeSelectorComponent = inject(FormTreeSelectorComponent, {optional: true});
  private formPopupTableSelector = inject(FormPopupTableSelectorComponent, {optional: true});
  private datepickerComponent = inject(DatePickerComponent, {optional: true});

  @Input({alias: 'iczHighlightFormField.terms', required: true})
  terms: string[] = []; // terms to highlight in field value. typically based on user input

  @Input('iczHighlightFormField.if')
  activateHighlighting: boolean = true; // could be disabled based on some external condition

  private formField: Nullable<PrimitiveValueFormField<any>> = this.formAutocompleteComponent || this.formFieldComponent ||
    this.formTreeSelectorComponent || this.formPopupTableSelector || this.datepickerComponent;

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (this.activateHighlighting) {
      if (changes.terms) {
        const nonemptyTerms = this.terms.filter(t => t);

        if (nonemptyTerms.length && this.valueMatchesAnyTerm(nonemptyTerms)) {
          this.highlightFieldContent();
        }
        else {
          this.unhighlightFieldContent();
        }
      }
    }
    else {
      this.unhighlightFieldContent();
    }
  }

  private highlightFieldContent() {
    this.getHighlightAreaElement()?.classList.add('highlight');
  }

  private unhighlightFieldContent() {
    this.getHighlightAreaElement()?.classList.remove('highlight');
  }

  private getHighlightAreaElement() {
    const el = this.el.nativeElement;

    if (el.tagName === 'ICZ-FORM-FIELD') {
      return this.el.nativeElement.querySelector('.input-wrapper');
    }
    else {
      return this.el.nativeElement.querySelector('icz-form-field .input-wrapper');
    }
  }

  private valueMatchesAnyTerm(terms: string[]): boolean {
    return this.getRegexForTerms(terms).test(this.formField?.control.value);
  }

}
