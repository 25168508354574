import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LabelComponent, TagComponent} from '@icz/angular-essentials';
import {extendDefaultTableConfig, TableComponent} from '../../table.component';
import {IczTableDataSource} from '../../table.datasource';
import {PopupSelectorTableDialogData} from '../popup-selector-table-dialog.component';
import {TableColumnsData} from '../../table-columns-data';
import {TableConfig} from '../../table.models';

export interface PopupSelectorTableData extends PopupSelectorTableDialogData<string> {

}

/**
 * @internal
 */
@Component({
  selector: 'icz-popup-selector-table',
  templateUrl: './popup-selector-table.component.html',
  styleUrls: ['./popup-selector-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TableComponent,
    TagComponent,
    LabelComponent,
  ],
})
export class PopupSelectorTableComponent implements OnInit {
  @Input({required: true})
  selectorData!: PopupSelectorTableData;
  @Output()
  selectedRowsChanged: EventEmitter<any[]> = new EventEmitter();
  @Output()
  rowClicked: EventEmitter<any> = new EventEmitter();

  protected tableConfig!:  TableConfig<string>;

  ngOnInit() {
    this.tableConfig = extendDefaultTableConfig<string>({
      ...this.selectorData.tableConfig,
      autoPageSizeConfig: {tableHeight: 600},
      toolbarConfig: {
        ...(this.selectorData?.tableConfig?.toolbarConfig ?? {}),
        showFilter: false,
        showReload: false,
        autoOpenFilter: true,
        showToolbar: this.selectorData.tableTitle !== '',
      },
    });

    this.tableId = this.selectorData.tableId;
    this.tableTitle = this.selectorData.tableTitle;
    this.tableTitleTag = this.selectorData.tableTitleTag;
    this.columnsData = this.selectorData.schema;
    this.dataSource = this.selectorData.dataSource;
  }

  protected tableId!: string;
  protected tableTitle!: string;
  protected tableTitleTag!: string;
  protected columnsData!: TableColumnsData<string>;

  protected dataSource!: IczTableDataSource<unknown>;
}
