<div class="wrapper" >
  <div class="row">
    @for (dim of selectedDimensions; track dim) {
      <icz-chip>
        <ng-container content>
          <div class="row>"><span> {{'Dimenze' | translate}} {{': '}} </span>
          <icz-label [label]="'en.dimension.' + dim" class="bold"></icz-label>
        </div>
      </ng-container>
      <ng-container suffix>
        <icz-button (onAction)="removeDimension(dim)"
          svgIcon="close" background="transparent" disableRipple size="small">
        </icz-button>
      </ng-container>
    </icz-chip>
  }
  <icz-button label="Přidat dimenzi" (onAction)="showPopover = true" class="add-dimension" background="white"
  cdkOverlayOrigin #overlayOrigin="cdkOverlayOrigin"></icz-button>
</div>

<icz-popover [isOpen]="showPopover"
  [overlayOrigin]="overlayOrigin"
  (onClose)="showPopover = false">
  @for (opt of allowedDimensionsOptions; track opt.value) {
    <icz-option-item
      [option]="opt"
      [listItemTemplate]="optionTmpl"
    ></icz-option-item>
  }

  <ng-template let-context #optionTmpl>
    <span (click)="addDimension(context.option?.value)"> {{context.option?.label | translate}} </span>
  </ng-template>
</icz-popover>
</div>
