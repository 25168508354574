import {inject, Injectable} from '@angular/core';
import {
  GenericOwnConsignment,
  GenericOwnElasticConsignment
} from '../own-consignment-table/model/own-consignment-model';
import {Observable, of} from 'rxjs';
import {ConsignmentType} from '|api/commons';
import {ApiOwnConsignmentService} from '|api/sad';

@Injectable({providedIn: 'root'})
export class OwnConsignmentService {

  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);

  getFullOwnConsignmentDto(consignment: GenericOwnElasticConsignment): Observable<Nullable<GenericOwnConsignment>> {
    switch (consignment.consignmentType) {
      case ConsignmentType.OWN_PAPER_ADDRESS:
      case ConsignmentType.OWN_PAPER_ON_PLACE:
        return this.apiOwnConsignmentService.ownConsignmentGetOwnPaperConsignment({id: consignment.id});
      case ConsignmentType.OWN_PAPER_MULTI_ADDRESS:
        return this.apiOwnConsignmentService.ownConsignmentGetOwnPaperMultiConsignment({id: consignment.id});
      case ConsignmentType.OWN_DIGITAL_DATABOX:
      case ConsignmentType.OWN_DIGITAL_EMAIL:
        return this.apiOwnConsignmentService.ownConsignmentGetOwnDigitalConsignment({id: consignment.id});
      case ConsignmentType.OWN_DIGITAL_INTERNAL:
        return this.apiOwnConsignmentService.ownConsignmentGetOwnInternalDigitalConsignment({id: consignment.id});
      case ConsignmentType.OWN_PAPER_INTERNAL:
        return this.apiOwnConsignmentService.ownConsignmentGetOwnInternalPaperConsignment({id: consignment.id});
      case ConsignmentType.OFFICE_DESK:
        return this.apiOwnConsignmentService.ownConsignmentFindByIdOfficeDesk({id: consignment.id});
      case ConsignmentType.TELEFAX:
        return this.apiOwnConsignmentService.ownConsignmentGetTelefaxConsignment({id: consignment.id});
      case ConsignmentType.PORTAL:
        return this.apiOwnConsignmentService.ownConsignmentGetPortalConsignment({id: consignment.id});
      default:
        return of(null);
    }
  }

}
