import {IczTableDataSource} from '@icz/angular-table';
import {DocumentDto, FileDto} from '|api/document';
import {DocumentSearchService, UnitViewSearchConfig} from '|shared';
import {ObjectClass} from '|api/commons';

export class SettledDocumentsDataSource extends IczTableDataSource<DocumentDto | FileDto> {
  constructor(documentSearchService: DocumentSearchService, unitView: UnitViewSearchConfig, objectClass: ObjectClass[], findStored: boolean, findWithRetentionCheckYear: boolean) {
    super(searchParams => documentSearchService.findSettledDocuments(searchParams, unitView, objectClass, findStored, findWithRetentionCheckYear));
  }
}
