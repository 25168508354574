<icz-section label="Stav zásilky">
  @if (!consignmentValidationResult || !consignmentValidationResult.alreadyRan) {
    <div>
      <icz-label class="secondary" label="Pro plné zvalidování zásilky vyplňte prosím všechna povinná pole v Popisu vypravení a vyberte alespoň jednu komponentu."></icz-label>
    </div>
  }
  @else {
    @if (consignmentValidationResult!.valid) {
      <div>
        <icz-label class="secondary" label="Validní zásilka."></icz-label>
        @if (consignmentValidationResult!.errors!.length) {
          <div>
            <icz-label class="secondary" label="Varování:"></icz-label>
            @for (error of consignmentValidationResult!.errors; track error.code) {
              <div>
                <icz-label [label]="error.code | interpolateBackendError:error.parameters" class="bold"></icz-label>
              </div>
            }
          </div>
        }
      </div>
    }
    @else {
      <icz-label class="secondary" label="Zásilka není validní z důvodů:"></icz-label>
      @for (error of consignmentValidationResult!.errors; track error.code) {
        <div class="font-bold">
          @if (isErrorTechnical(error)) {
            {{ 'Došlo k neočekávané technické chybě. Kontaktujte podporu, případně zopakujte aktivitu za chvíli. Kód chyby:' | translate }}
          }
          {{ error.code | interpolateBackendError:error.parameters }}
        </div>
      }
    }
  }
</icz-section>
