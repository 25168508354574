import {DisposalOperationCode, RetentionTriggerTypeCode} from '|api/commons';
import {CodebookFilterConfig, EnumFilterItem, FilterItemValue, FilterOperator, FilterType} from '@icz/angular-table';
import {TemplatePoolService} from '@icz/angular-essentials';
import {
  SharedTemplateCollectionComponent
} from '../../../shared-template-collection/shared-template-collection.component';
import {ClassificationSchemeFilterOption} from './document-filters-data.service';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {map, Observable} from 'rxjs';
import {shareReplay} from 'rxjs/operators';
import {TreeItemSelectionStrategy} from '@icz/angular-form-elements';

export function getDisposalScheduleFilterConfig(): Partial<CodebookFilterConfig> {
  return {
    subfilters: [
      {
        id: 'retentionPeriod',
        label: 'Skartační lhůta',
        filterType: FilterType.NUMBER,
      },
      {
        id: 'disposalOperationCode',
        label: 'Skartační operace',
        filterType: FilterType.ENUM,
        list: enumToOptions('disposalOperationCode', DisposalOperationCode),
      },
      {
        id: 'retentionTriggerTypeCode',
        label: 'Spouštěcí událost',
        filterType: FilterType.ENUM,
        list: enumToOptions('retentionTriggerTypeCode', RetentionTriggerTypeCode),
      }
    ]
  };
}

export function getEntityClassFilterConfig(templatePool: TemplatePoolService<SharedTemplateCollectionComponent>): Partial<CodebookFilterConfig> {
  return {
    isTree: true,
    originId: 'ec',
    treeSelectionStrategy: TreeItemSelectionStrategy.BULK,
    searchFieldPlaceholder: 'Hledat v názvu nebo kódu věcné skupiny',
    customSelectedChipTemplate: templatePool.templates.entityClassChipTemplate,
    subfilters: [
      {
        id: 'classificationSchemeId',
        filterType: FilterType.ENUM,
        label: 'Spisový plán',
      }
    ]
  };
}

export function setupEntityClassFilterSubfilters(
  entityClassFilterItem: CodebookFilterConfig,
  classificationSchemeOptions$: Observable<ClassificationSchemeFilterOption[]>
) {
  const classificationSchemeOptionsShared$ = classificationSchemeOptions$.pipe(
    shareReplay(1),
  );

  (entityClassFilterItem.subfilters!.find(
    sf => sf.id === 'classificationSchemeId'
  ) as EnumFilterItem).list$ = classificationSchemeOptionsShared$;
  entityClassFilterItem.subfilterInitialValues$ = {
    classificationSchemeId: classificationSchemeOptionsShared$.pipe(
      map(classificationSchemeOptions => {
        const currentClassificationSchemeId = classificationSchemeOptions.find(cs => cs.data!.isActive)?.value;
        return new FilterItemValue(
          'classificationSchemeId',
          FilterOperator.equals,
          [String(currentClassificationSchemeId)],
          undefined,
          undefined,
          classificationSchemeOptions,
        );
      }),
    ),
  };
}
