import {Injectable, InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';

/**
 * Interface of a logger which logs user interactions.
 * Can be used for telemetry or diagnostics.
 */
export interface UserInteractionLogger {

  /**
   * Logs a user interaction, fire-and-forget style.
   * UI interactions are currently described by simple strings (arg1.description).
   */
  logUserInteraction(message: {description: string}): void;

}

/**
 * DI token used for providing user interaction logger.
 */
export const USER_INTERACTION_LOGGER = new InjectionToken<UserInteractionLogger>('User Interaction Logger');

/**
 * A UserInteractionLogger that does not log anywhere.
 */
@Injectable()
export class NoopUserInteractionLoggerService implements UserInteractionLogger {
  logUserInteraction(message: {description: string}) {}
};

/**
 * Interface used for persisting icz-section component open/close state.
 * Please note that that there might be thousands of sections in the application
 *   so we recommend you to persist only state of sections which are explicitly closed by the user.
 */
export interface SectionSettingsPersistor {

  /**
   * Returns whether a section has been closed by the user.
   * @param sectionId is a hash derived from section name and section's path in the DOM from <html> element.
   * @returns:
   * - boolean - TRUE if the section has been closed by the user; false otherwise.
   * - null - keep default open/close state of a section.
   */
  getSectionClosedState(sectionId: string): Nullable<boolean>;
  /**
   * Sets whether a section has been closed by the user.
   * @see SectionSettingsPersistor.getSectionClosedState
   */
  setSectionClosedState(sectionId: string, isClosed: boolean): void;

}

/**
 * DI token used for providing section open/close state persistence class.
 */
export const SECTION_SETTINGS_PERSISTOR = new InjectionToken<SectionSettingsPersistor>('Section Settings Persistor');

/**
 * A section open/close state persistor that does not persist section state.
 */
@Injectable()
export class NoopSectionSettingsPersistorService implements SectionSettingsPersistor {
  getSectionClosedState() {
    return null;
  }
  setSectionClosedState() {}
};

/**
 * An interface used to translate SVG icon IDs and other possible context to icon registry key.
 * ad icon registry: see https://v7.material.angular.io/components/icon/api#MatIconRegistry
 */
export interface IconRegistryKeyProvider {

  /**
   * Accepts svgIcon$ observable containing svgIcon name (from icz-icon) and produces
   * a mapped observable which is translated to MatIconRegistry icon key.
   * Identity mapping, i.e. using DefaultIconRegistryKeyProviderService, is usually fine.
   * Custom mapping can be implemented to implement an iconset for dark mode, etc.
   */
  getIconRegistryKey$(svgIcon$: Observable<Nullable<string>>): Observable<Nullable<string>>;

}

/**
 * DI token used for providing an implementation of icon registry key.
 * Note that, if you do not plan to implement icon themes or dark mode,
 * straight providing DefaultIconRegistryKeyProviderService should be just fine.
 */
export const ICON_REGISTRY_KEY_PROVIDER = new InjectionToken<IconRegistryKeyProvider>('Icon Registry Key Provider');

/**
 * Default IconRegistryKeyProvider which accepts svgIcon and uses it to query icon registry.
 */
@Injectable()
export class DefaultIconRegistryKeyProviderService implements IconRegistryKeyProvider {
  getIconRegistryKey$(svgIcon$: Observable<Nullable<string>>): Observable<Nullable<string>> {
    return svgIcon$;
  }
}
