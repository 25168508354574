import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {
  IczStatistic,
  TrendCounterStoredSettings
} from '../../statistics-model';
import {CheckUnsavedFormDialogService} from '../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {IczModalRef} from '@icz/angular-modal';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {LocalStorageKey, UserSettingsService} from '../../../../services/user-settings.service';
import {enumValuesToArray} from '../../../../core/services/data-mapping.utils';


@Component({
  selector: 'icz-statistic-toggle-dashboard',
  templateUrl: './statistic-dashboard-toggle.component.html',
  styleUrls: ['./statistic-dashboard-toggle.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticDashboardToggleComponent implements OnInit, IFormGroupCheckable {

  protected modalRef = inject(IczModalRef);
  loadingIndicatorService = inject(LoadingIndicatorService);
  private userSettingsService = inject(UserSettingsService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);

  form = new IczFormGroup({
    [IczStatistic.NEW_DOCUMENT_COUNT]: new IczFormControl<boolean>(true, []),
    [IczStatistic.NEW_FILE_COUNT]: new IczFormControl<boolean>(true, []),
    [IczStatistic.DOCUMENT_COUNT]: new IczFormControl<boolean>(true, []),
    [IczStatistic.FILE_COUNT]: new IczFormControl<boolean>(true, []),
    [IczStatistic.NEW_COMPONENT_COUNT]: new IczFormControl<boolean>(true, []),
    [IczStatistic.FILE_SIZE]: new IczFormControl<boolean>(true, []),
    [IczStatistic.DOCUMENT_SIZE]: new IczFormControl<boolean>(true, []),
    [IczStatistic.FILE_BY_PERMISSION_COUNT]: new IczFormControl<boolean>(true, []),
    [IczStatistic.FILE_IN_STORING_UNIT_COUNT]: new IczFormControl<boolean>(true, []),
    [IczStatistic.NEW_ENTITY_CLASSES_CURRENT_CS]: new IczFormControl<boolean>(true, []),
    [IczStatistic.DOCUMENT_BY_PERMISSION_COUNT]: new IczFormControl<boolean>(true, []),
    [IczStatistic.FILES_TRANSFERRED_TO_REGISTRY_OFFICE]: new IczFormControl<boolean>(true, []),
    [IczStatistic.COMPONENT_COUNT]: new IczFormControl<boolean>(true, []),
  });

  sectionSettings: Partial<Record<IczStatistic, TrendCounterStoredSettings>> = {};

  readonly IczStatistic = IczStatistic;
  formGroupsToCheck!: string[];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.sectionSettings = this.userSettingsService.getParsedValue(LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS) as Partial<Record<IczStatistic, TrendCounterStoredSettings>>;

    if (this.sectionSettings) {
      const statistics = enumValuesToArray(IczStatistic);
      statistics.forEach(statistic => {
        const statisticName = statistic as IczStatistic;
        if (this.sectionSettings[statisticName]) {
          this.form.get(statisticName)!.setValue(this.sectionSettings[statisticName]!.displayed);
        }
      });
    }
  }

  submit() {
    const formValue = this.form.getRawValue();

    const sectionSettings = this.sectionSettings;
    const statistics = enumValuesToArray(IczStatistic);
    statistics.forEach(statistic => {
      const statisticName = statistic as IczStatistic;
      sectionSettings[statisticName]!.displayed = formValue[statisticName];
    });

    this.userSettingsService.setParsedValue(LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS, sectionSettings);

    this.form.reset();
    this.close();
  }

  close() {
    this.modalRef.close();
  }
}
