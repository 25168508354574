import {DestroyRef, inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, of} from 'rxjs';
import {filter, finalize, map} from 'rxjs/operators';
import {OwnConsignmentStatus, SubjectRecordDto} from '|api/commons';
import {
  ApiAuthorizationService,
  AuthorizedEntityType,
  DocumentAuthorizedOperation,
  FileAuthorizedOperation,
  OwnConsignmentAuthorizedOperation,
} from '|api/core';
import {ApiFileService, FileDto, OwnDocumentDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {
  ApiOwnConsignmentService,
  OwnDigitalConsignmentElasticDto,
  OwnInternalPaperConsignmentElasticDto,
  OwnPaperConsignmentElasticDto,
} from '|api/sad';
import {
  GenericOwnElasticConsignmentWithConsignee,
  isOwnInternalPaperElasticConsignment,
  isOwnMultiPaperElasticConsignment,
  isOwnPaperElasticConsignment,
  ManualDeliveryResultRecordAction,
  ManualDeliveryResultRecordMode,
  OwnConsignmentOfficeDeskTableView,
  OwnConsignmentTableView,
  OwnConsignmentWorkflowActionOpts,
} from '../../own-consignment-table/model/own-consignment-model';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {ConsignmentsToastService, ConsignmentToastData} from '../../consignment-dialog/consignments-toast.service';
import {
  getDocumentIdFromConsignment,
  OwnConsignmentWorkflowService,
} from '../../own-consignment-table/own-consignment-workflow.service';
import {AuthorizedButton, AuthorizedButtonService,} from '../../authorized-button.service';
import {Button} from '../../../button-collection/button-collection.component';
import {ConsignmentToolbarButtonsDisablers} from './consignment-toolbar-buttons-disablers';
import {CommonToolbarDisablers} from '../../document-toolbar/services/toolbar-common.disablers';
import {
  ConsignmentDialogType,
  ConsignmentDocumentData,
} from '../../consignment-dialog/consignment-dialog/abstract-consignment-dialog-data';
import {CodebookService} from '../../../../core/services/codebook.service';
import {IczModalService} from '@icz/angular-modal';
import {DocumentDetailService} from '../../../../services/document-detail.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  TakeoverByBarcodeDialogComponent,
  TakeoverByBarcodeDialogData,
} from '../../takeover-by-barcode-dialog/takeover-by-barcode-dialog.component';
import {TakeoverByBarcodeWorkflowAction,} from '../../takeover-by-barcode-dialog/takeover-by-barcode-dialog.service';
import {enumValuesToArray} from 'libs/shared/src/lib/core/services/data-mapping.utils';
import {
  DeliveryResultDto,
  DeliveryServiceAdditionalDto,
  DeliveryServiceCombinationDto,
  DeliveryTypeDto
} from '|api/codebook';
import {
  BulkOperationValidationService,
  EsslObjectForValidation,
  OperationValidator,
} from '../../../../services/bulk-operation-validation.service';
import {ConsignmentOperationValidators} from './consignment-operation-validators';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {GlobalLoadingIndicatorService, ResponsivityBreakpoint} from '@icz/angular-essentials';
import {dispatchDialogTitles} from '../../dispatch-dialogs/dispatch-dialogs.model';
import {OwnConsignmentWorkflowAction} from '../../consignment-dialog/consignment-dialog/consignment.model';
import {constructBulkModalTitle} from '../../../../lib/utils';
import {ToolbarDataService} from '../../toolbar-data.service';
import {ENVIRONMENT} from '../../../../core/services/environment.service';
import {
  DevCreateConsignmentDialogComponent,
  DevCreateConsignmentDialogComponentResult,
} from './dev-create-consignment-dialog/dev-create-consignment-dialog.component';
import {
  InternalNotificationConsignmentMessageCode,
} from '../../../../core/services/notifications/internal-notification.enum';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {getConsignmentIcon} from '../../shared-document.utils';
import {GeneralAuthorizedOperation} from '../../permissions/permissions.utils';
import {
  ManualDeliveryResultByBarcodeDialogComponent,
  ManualDeliveryResultByBarcodeDialogData
} from '../../own-consignment-table/components/manual-delivery-result-by-barcode/manual-delivery-result-by-barcode.component';
import {
  ManualDeliveryResultDialogService
} from '../../consignment-dialog/consignment-dialog/manual-delivery-result-dialog.service';
import {ConsignmentDialogService} from '../../consignment-dialog/consignment-dialog.service';
import {
  BulkPostingFormDialogService
} from '../../dispatch-dialogs/components/bulk-posting-form/bulk-posting-form-dialog.service';
import {
  EnvelopePrintDialogComponent,
  EnvelopePrintDialogData
} from '../../envelope-print-dialog/envelope-print-dialog.component';
import {
  SheetLabelPrintDialogComponent,
  SheetLabelPrintDialogData
} from '../../sheet-label-print-dialog/sheet-label-print-dialog.component';

type EnvelopeSheetLabelPrintableConsignment = OwnPaperConsignmentElasticDto | OwnInternalPaperConsignmentElasticDto;

export interface OwnConsignmentToolbarContext {
  tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView;
  dispatchOfficeId: Nullable<number>;
  isUnitView: boolean;
  guidedBPFGenerationEnabled: boolean;
  dispatchOfficeDistributionNodeIds: number[];
  canCreateBulkPostingForm?: boolean;
  excludedConsignmentIdsForBpf?: number[];
}

export enum ConsignmentToolbarAction {
  CONSIGNMENT_CREATED = 'CONSIGNMENT_CREATED',
  CONSIGNMENT_UPDATED = 'CONSIGNMENT_UPDATED',
  BULK_POSTING_FORM_CREATED = 'BULK_POSTING_FORM_CREATED',
  MANUAL_DELIVERY_RESULT_WRITTEN = 'MANUAL_DELIVERY_RESULT_WRITTEN',
  PROOF_OF_DELIVERY_ADDED = 'PROOF_OF_DELIVERY_ADDED',
  WORKFLOW_ACTION_COMPLETED = 'WORKFLOW_ACTION_COMPLETED'
}

@Injectable()
export class ConsignmentToolbarButtonsService extends ToolbarProvider<GenericOwnElasticConsignmentWithConsignee, ConsignmentToolbarAction, OwnConsignmentToolbarContext> {

  private globalLoading = inject(GlobalLoadingIndicatorService);
  private consignmentDialogService = inject(ConsignmentDialogService);
  private manualDeliveryResultDialogService = inject(ManualDeliveryResultDialogService);
  private bulkPostingFormDialogService = inject(BulkPostingFormDialogService);
  private consignmentsToastService = inject(ConsignmentsToastService);
  private ownConsignmentWorkflowService = inject(OwnConsignmentWorkflowService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private codebookService = inject(CodebookService);
  private modalService = inject(IczModalService);
  private apiAuthorizationService = inject(ApiAuthorizationService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private translateService = inject(TranslateService);
  private apiFileService = inject(ApiFileService);
  private destroyRef = inject(DestroyRef);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private toolbarDataService = inject(ToolbarDataService);
  private webSocketNotificationsService = inject(WebSocketNotificationsService);
  private documentDetailService = inject(DocumentDetailService, {optional: true});
  private environment = inject(ENVIRONMENT);

  document: Nullable<OwnDocumentDto>;
  parentFileRefNumber: Nullable<string>;

  constructor() {
    super();

    //only listen to success notification to avoid double refresh
    this.webSocketNotificationsService.getMessagesListener$(Object.values(InternalNotificationConsignmentMessageCode).filter(value => value.endsWith('success')))
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]);
        this.announceActionCompleted(ConsignmentToolbarAction.WORKFLOW_ACTION_COMPLETED);
      },
    );

    if (this.documentDetailService) {
      this.documentDetailService.object$.pipe(
        filter(Boolean),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(document => {
        this.document = document as unknown as OwnDocumentDto;
        if (this.document.fileId) {
          this.apiAuthorizationService.authorizationAuthorizeFileOperations({
            body: {
              authorizedEntityId: this.document.fileId,
              authorizedEntityType: AuthorizedEntityType.FILE,
              operationsToAuthorize: [FileAuthorizedOperation.FILE_SHOW_PROFILE],
            },
          }).subscribe(result => {
            if (result) {
              const authorizedOperation = result.authorizedOperations.find(op => op.operation === FileAuthorizedOperation.FILE_SHOW_PROFILE);
              if (authorizedOperation && authorizedOperation.userAuthorized) {
                this.apiFileService.fileFindById({id: this.document!.fileId!}).subscribe((file: FileDto) => {
                  this.parentFileRefNumber = file.refNumber;
                });
              }
            }
          });
        }
      });
    }
  }

  private ownConsignmentToValidationObject(c: GenericOwnElasticConsignmentWithConsignee): EsslObjectForValidation<GenericOwnElasticConsignmentWithConsignee> {
    return {
      entityId: c.id,
      entityIcon: getConsignmentIcon(c.consignmentType!)!,
      authorizedEntityType: AuthorizedEntityType.OWN_CONSIGNMENT,
      entityData: c,
      entityName: `${c.uid!.uid} - ${c.refNumber}: ${c.document?.subject}`,
    };
  }

  getToolbarButtons(selection: Nullable<(GenericOwnElasticConsignmentWithConsignee)[]>, context: OwnConsignmentToolbarContext): Observable<Button[]> {
    let buttons$: Observable<AuthorizedButton[]> = of([]);

    if (context.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS) {
      buttons$ = combineLatest([
        this.codebookService.deliveryResults(),
        this.codebookService.deliveryTypes(),
        this.codebookService.deliveryServiceAdditionals(),
        this.codebookService.deliveryServiceCombinations(),
      ]).pipe(
        map(([deliveryResults, deliveryTypes, additionalServices, deliveryServiceCombinations]) => {
          return [
            this.getCreateButtons(),
            this.getEditButton(selection, context),
            this.getHandoverButton(selection, context.tableView, deliveryTypes),
            this.getWithdrawForCorrectionButton(selection, context),
            // this.getDispatchOutsideFilingOfficeButton(selection, context.tableView),
            /*{
              label: 'Výměna dokumentů',
              isTestingFeature: true,
            },
            {
              label: 'Hromadná zásilka',
              isTestingFeature: true,
            },*/
            ...this.getDeliveryConfirmationButtons(selection, deliveryTypes, deliveryResults, additionalServices, deliveryServiceCombinations, context),
            this.getConsignmentMoreButtons(selection, context),
          ];
        }),
      );
    } else {
      if (context.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_CREATE_OFFICE_DESK_CONSIGNMENTS) {
        buttons$ = this.codebookService.deliveryTypes().pipe(
          map(deliveryTypes => ([
            {
              label: 'Vyvěšení na úřední desku',
              authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_OWN_CONSIGNMENT],
              icon: 'uredni_deska',
              buttonDisablers: [ConsignmentToolbarButtonsDisablers.isDocumentWithoutRefNumber(this.document!)],
              action: _ => this.createOfficeDeskConsignment(),
            },
            this.getEditButton(selection, context),
            this.getHandoverButton(selection, context.tableView, deliveryTypes),
            this.getDeleteButton(selection, context)
          ]))
        );
      } else if (
        context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER ||
        context.tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER
      ) {
        buttons$ = of([
          this.getTakeoverButton(selection),
          this.getTakeoverByBarcodeMenu(context),
          this.getTakeoverAndPrepareForDistributionButton(selection, context.tableView),
          this.getRejectButton(selection, context.tableView),
          this.getConsignmentMoreButtons(selection, context),
        ]);
      } else if (
        context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_DISPATCH
      ) {
        buttons$ = of([
          this.getPrepareForDistributionButton(selection),
          this.getPrepareForDistributionByBarcodeButton(context),
          this.getRejectButton(selection, context.tableView),
          this.getConsignmentMoreButtons(selection, context),
        ]);
      } else if (
        context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION
      ) {
        buttons$ = of([
          this.getDispatchButton(selection),
          this.getWithdrawFromDistributionButton(selection, context),
          this.getGuidedBPFCreateButton(context),
          this.getRejectButton(selection, context.tableView),
          this.getConsignmentMoreButtons(selection, context),
        ]);
      } else if (
        context.tableView === OwnConsignmentTableView.OFFICER_VIEW_ALL ||
        context.tableView === OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS ||
        context.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS
      ) {
        buttons$ = combineLatest([
          this.codebookService.deliveryResults(),
          this.codebookService.deliveryTypes(),
          this.codebookService.deliveryServiceAdditionals(),
          this.codebookService.deliveryServiceCombinations(),
        ]).pipe(
          map(([deliveryResults, deliveryTypes, additionalServices, deliveryServiceCombinations]) =>
            {
              const buttons: AuthorizedButton[] = [
                // todo(rb) uncomment after feature ready
                /*{
                  label: 'Hromadná zásilka',
                  show: context.tableView !== OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS,
                  isTestingFeature: true,
                },*/
                this.getHandoverButton(selection, context.tableView, deliveryTypes),
                this.getDispatchOutsideFilingOfficeButton(selection, deliveryTypes),
              ];

              if (!context.isUnitView) {
                buttons.push(
                  this.getCancelButton(selection, context),
                  this.getDeleteButton(selection, context),
                );
              }

              buttons.push(
                ...this.getDeliveryConfirmationButtons(selection, deliveryTypes, deliveryResults, additionalServices, deliveryServiceCombinations, context),
                this.getConsignmentMoreButtons(selection, context),
              );

              return buttons;
            }),
        );
      } else if (
        context.tableView === OwnConsignmentTableView.OFFICER_VIEW_REJECTED ||
        context.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED
      ) {
        buttons$ = this.codebookService.deliveryTypes().pipe(
          map(deliveryTypes => ([
            this.getHandoverButton(selection, context.tableView, deliveryTypes),
            this.getWithdrawForCorrectionButton(selection, context),
          ])),
          map(buttons => {
            if (!context.isUnitView) {
              buttons.push(this.getCancelButton(selection, context));
            }
            buttons.push(this.getConsignmentMoreButtons(selection, context));
            return buttons;
          })
        );
      } else if (
        context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT ||
        context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED ||
        context.tableView === OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT
      ) {
        buttons$ = combineLatest([
          this.codebookService.deliveryResults(),
          this.codebookService.deliveryTypes(),
          this.codebookService.deliveryServiceAdditionals(),
          this.codebookService.deliveryServiceCombinations(),
        ]).pipe(
          map(([deliveryResults, deliveryTypes, additionalServices, deliveryServiceCombinations]) => ([
            ...this.getDeliveryConfirmationButtons(selection, deliveryTypes, deliveryResults, additionalServices, deliveryServiceCombinations, context),
          ]))
        );
      } else if (
        context.tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_POST
      ) {
        buttons$ = of([
          this.getPostButton(selection),
          this.getRejectButton(selection, context.tableView),
        ]);
      } else if (
        context.tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_POSTED
      ) {
        buttons$ = of([
          this.getUnpostButton(selection),
        ]);
      } else if (
        context.tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_UNPOSTED
      ) {
        buttons$ = of([]); // There's nothing to manually do with UNPOSTED consignments
      } else if (
        context.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL_POSTED
      ) {
        buttons$ = of([]); // Officer can view all POSTED consignments, but has no actions
      } else if (
        context.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL
      ) {
        buttons$ = of([]); // Officer can view all consignments here, but has no actions
      }
    }

    if (selection?.length === 0 || selection?.length === 1) {
      return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
        {
          [AuthorizedEntityType.DOCUMENT]: this.document?.id,
          [AuthorizedEntityType.OWN_CONSIGNMENT]: selection?.[0]?.id,
        },
        buttons$,
      );
    }
    else {
      return buttons$.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  private devCreatePaperConsignment() {
    this.modalService
      .openComponentInModal({
        component: DevCreateConsignmentDialogComponent,
        modalOptions: {
          titleTemplate: 'Dev create paper consignment',
          width: 750,
          height: 500,
        },
        data: {
          documentId: this.document!.id,
        }
      })
      .subscribe(result => {
        const resultAsserted = result as DevCreateConsignmentDialogComponentResult;
        if (result as DevCreateConsignmentDialogComponentResult) {
          for (let i = 0; i < resultAsserted.count; i++) {
            const consignee = {...resultAsserted.paperConsig.consigneeDefinition as SubjectRecordDto};
            const copy = {...resultAsserted.paperConsig};
            copy.consigneeId = consignee.id!;
            this.apiOwnConsignmentService.ownConsignmentCreateOwnPaperConsignment({body: copy}).subscribe();
          }
        }
      });
  }

  private createSingleConsignment(dialogType: ConsignmentDialogType = ConsignmentDialogType.CREATE_CONSIGNMENT) {
    this.consignmentDialogService.openConsignmentDialog(
      {
        id: this.document!.id,
        refNumber: this.document!.refNumber,
        subject: this.document!.subject,
        empowerment: this.document!.empowerment,
        parentFileRefNumber: this.parentFileRefNumber,
        parentFileId: this.document!.fileId,
      },
      dialogType,
    ).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]);
        this.announceActionCompleted(ConsignmentToolbarAction.CONSIGNMENT_CREATED);
      }
    });
  }

  private createOfficeDeskConsignment() {
    this.createSingleConsignment(ConsignmentDialogType.CREATE_OFFICE_DESK_CONSIGNMENT);
  }

  private createInternalConsignment() {
    this.createSingleConsignment(ConsignmentDialogType.CREATE_INTERNAL_CONSIGNMENT);
  }

  private edit(selection: GenericOwnElasticConsignmentWithConsignee[]) {
    const openDialog = (documentData: ConsignmentDocumentData) => this.consignmentDialogService.openConsignmentDialog(documentData, ConsignmentDialogType.EDIT_CONSIGNMENT, selection[0]).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]);
      }
      this.announceActionCompleted(ConsignmentToolbarAction.CONSIGNMENT_UPDATED);
    });

    const documentId = getDocumentIdFromConsignment(selection[0]);
    openDialog({
      id: documentId!,
    });
  }

  private workflowAction(action: OwnConsignmentWorkflowAction,
                         selection: GenericOwnElasticConsignmentWithConsignee[],
                         opts: OwnConsignmentWorkflowActionOpts = {validate: true, saveFirst: false}): void {
    // Currently only HANDOVER action shows no modal window immediately, hence uses table's non-blocking loading
    if (action === OwnConsignmentWorkflowAction.HANDOVER || action === OwnConsignmentWorkflowAction.TAKEOVER) {
      this.globalLoading.startLoading();
    }

    this.ownConsignmentWorkflowService.workflowAction[action as OwnConsignmentWorkflowAction](selection, opts)
      .pipe(
        finalize(() => this.globalLoading.endLoading()),
      )
      .subscribe(
        {
          next: _ => {
            this.announceActionCompleted(ConsignmentToolbarAction.WORKFLOW_ACTION_COMPLETED);

            const isBulk = selection.length > 1;
            if (isBulk) {
              if (action === OwnConsignmentWorkflowAction.HANDOVER ||
                action === OwnConsignmentWorkflowAction.TAKEOVER) {
                const toastData: ConsignmentToastData = {
                  [InternalNotificationKey.COUNT]: selection.length,
                  // Discovered bug: since HANDOVER/TAKEOVER have no modals, they don't correct COUNT of validated objects,
                  // so the toast displays total count of errored objects as well
                };

                this.consignmentsToastService.dispatchConsignmentBulkActionAccepted(toastData);
              }
            } else {
              const toastData: ConsignmentToastData = {
                [InternalNotificationKey.CONSIGNMENT_ID]: selection[0].id,
                [InternalNotificationKey.CONSIGNMENT_UID]: selection[0].uid!.uid!,
              };

              if (action === OwnConsignmentWorkflowAction.HANDOVER) {
                this.consignmentsToastService.dispatchConsignmentHandedOver(toastData);
              } else if (action === OwnConsignmentWorkflowAction.TAKEOVER) {
                this.consignmentsToastService.dispatchConsignmentTakenOver(toastData);
              }
            }
          },
          error: _ => {
            const isBulk = selection.length > 1;
            if (isBulk) {
              if (action === OwnConsignmentWorkflowAction.HANDOVER ||
                action === OwnConsignmentWorkflowAction.TAKEOVER) {
                const toastData: ConsignmentToastData = {
                  [InternalNotificationKey.COUNT]: selection.length,
                };

                this.consignmentsToastService.dispatchConsignmentBulkActionAcceptError(toastData);
              }
            }
          },
        });
  }

  private openBulkPostingFormCreateDialog(excludedConsignmentIdsForBpf?: number[]) {
    this.bulkPostingFormDialogService.openBulkPostingFormDialog({
      isCreateMode: true,
      excludedConsignmentIds: excludedConsignmentIdsForBpf,
    }).subscribe(result => {
        if (result) {
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.BULK_POSTING_FORMS_COUNTS]);
          this.announceActionCompleted(ConsignmentToolbarAction.BULK_POSTING_FORM_CREATED);
        }
      },
    );
  }

  private writeManualDeliveryResult(selection: (OwnPaperConsignmentElasticDto | OwnDigitalConsignmentElasticDto)[]) {
    this.manualDeliveryResultDialogService.openManualDeliveryResultDialog({
      action: ManualDeliveryResultRecordAction.RECORD_DELIVERY_RESULT,
      consignment: selection[0],
      mode: ManualDeliveryResultRecordMode.AFTER_CREATE,
    }).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]);
        this.announceActionCompleted(ConsignmentToolbarAction.MANUAL_DELIVERY_RESULT_WRITTEN);
        const toastData: ConsignmentToastData = {
          [InternalNotificationKey.CONSIGNMENT_ID]: selection[0]!.id,
          [InternalNotificationKey.CONSIGNMENT_UID]: selection[0]!.uid!.uid!,
        };
        if (selection[0]!.documentId) {
          toastData[InternalNotificationKey.DOCUMENT_ID] = selection[0]!.documentId;
        }

        this.consignmentsToastService.dispatchProofOfDeliveryRecorded(toastData);
      }
    });
  }

  private addProofOfDelivery(selection: (OwnPaperConsignmentElasticDto | OwnDigitalConsignmentElasticDto)[]) {
    this.manualDeliveryResultDialogService.openManualDeliveryResultDialog({
      action: ManualDeliveryResultRecordAction.ADD_PROOF_OF_DELIVERY,
      consignment: selection[0],
      mode: ManualDeliveryResultRecordMode.AFTER_CREATE,
    }).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]);
        this.announceActionCompleted(ConsignmentToolbarAction.PROOF_OF_DELIVERY_ADDED);
        const toastData: ConsignmentToastData = {
          [InternalNotificationKey.CONSIGNMENT_ID]: selection[0]!.id,
          [InternalNotificationKey.CONSIGNMENT_UID]: selection[0]!.uid!.uid!,
        };
        if (selection[0]!.documentId) {
          toastData[InternalNotificationKey.DOCUMENT_ID] = selection[0]!.documentId;
        }

        this.consignmentsToastService.dispatchProofOfDeliveryAdded(toastData);
      }
    });
  }

  private printEnvelopes(selection: GenericOwnElasticConsignmentWithConsignee[]) {
    const openEnvelopePrintDialog = (selection: EnvelopeSheetLabelPrintableConsignment[]) => {
      const isBulkAction = selection.length > 1;

      this.modalService.openComponentInModal<boolean, EnvelopePrintDialogData>({
        component: EnvelopePrintDialogComponent,
        modalOptions: {
          width: 1100,
          height: 700,
          titleTemplate: isBulkAction ? 'Tisk obálek ({{count}})' : 'Tisk obálky',
          titleTemplateContext: {
            count: selection.length,
          },
          disableAutoMargin: isBulkAction,
        },
        data: {
          consignments: selection,
        },
      }).subscribe();
    };

    if (selection.length > 1) {
      this.bulkOperationValidationService.validateEsslObjects<GenericOwnElasticConsignmentWithConsignee>(
        {
          dialogWarningLabel: 'Pro některé ze zvolených zásilek ({{errorCount}}) nelze vytisknout obálku. Tisk obálek bude proveden pouze pro vyhovující zásilky ({{successCount}}).',
          dialogWarningLabelContext: {},
          operationValidators: [
            ConsignmentOperationValidators.isNotSupportedForEnvelopePrinting(),
          ],
          esslObjects: selection!.map(this.ownConsignmentToValidationObject),
          authorizedOperations: [],
        },
        this,
      ).subscribe(validated => {
        openEnvelopePrintDialog(validated as EnvelopeSheetLabelPrintableConsignment[]);
      });
    }
    else {
      openEnvelopePrintDialog(selection as EnvelopeSheetLabelPrintableConsignment[]);
    }
  }

  private printSheetLabels(selection: GenericOwnElasticConsignmentWithConsignee[]) {
    const openSheetLabelPrintDialog = (selection: EnvelopeSheetLabelPrintableConsignment[]) => {
      const isBulkAction = selection.length > 1;

      this.modalService.openComponentInModal<boolean, SheetLabelPrintDialogData>({
        component: SheetLabelPrintDialogComponent,
        modalOptions: {
          width: 1100,
          height: 700,
          titleTemplate: isBulkAction ? 'Tisk etiket ({{count}})' : 'Tisk etikety',
          titleTemplateContext: {
            count: selection.length,
          },
          disableAutoMargin: isBulkAction,
        },
        data: {
          consignments: selection,
        },
      }).subscribe();
    };

    if (selection.length > 1) {
      this.bulkOperationValidationService.validateEsslObjects<GenericOwnElasticConsignmentWithConsignee>(
        {
          dialogWarningLabel: 'Pro některé ze zvolených zásilek ({{errorCount}}) nelze vytisknout etiketu. Tisk etiket bude proveden pouze pro vyhovující zásilky ({{successCount}}).',
          dialogWarningLabelContext: {},
          operationValidators: [
            ConsignmentOperationValidators.isNotSupportedForLabelPrinting(),
          ],
          esslObjects: selection!.map(this.ownConsignmentToValidationObject),
          authorizedOperations: [],
        },
        this,
      ).subscribe(validated => {
        openSheetLabelPrintDialog(validated as EnvelopeSheetLabelPrintableConsignment[]);
      });
    }
    else {
      openSheetLabelPrintDialog(selection as EnvelopeSheetLabelPrintableConsignment[]);
    }
  }

  openTakeoverByBarcodeDialog(dialogMode: TakeoverByBarcodeWorkflowAction, dispatchOfficeDistributionNodeIds: number[]) {
    let dialogTitle = '';

    if (dialogMode === OwnConsignmentWorkflowAction.TAKEOVER) {
      dialogTitle = 'Převzetí čtečkou kódů';
    } else if (dialogMode === OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION) {
      dialogTitle = 'Převzetí a předání do distribuce čtečkou kódů';
    } else if (dialogMode === OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION) {
      dialogTitle = 'Předání do distribuce čtečkou kódů';
    }

    this.modalService.openComponentInModal<Nullable<number>, TakeoverByBarcodeDialogData>({
      component: TakeoverByBarcodeDialogComponent,
      modalOptions: {
        width: 1100,
        height: 700,
        titleTemplate: dialogTitle,
      },
      data: {
        dialogMode,
        dispatchOfficeDistributionNodeIds,
      },
    }).subscribe(consignmentsToProcessCount => {
      if (consignmentsToProcessCount) {
        this.consignmentsToastService.dispatchConsignmentBulkActionAccepted({
          [InternalNotificationKey.COUNT]: consignmentsToProcessCount
        });
        this.announceActionCompleted(ConsignmentToolbarAction.WORKFLOW_ACTION_COMPLETED);
      }
    });
  }

  openManualDeliveryResultByBarcodeDialog(dispatchOfficeDistributionNodeIds: number[]) {
    this.modalService.openComponentInModal<number, ManualDeliveryResultByBarcodeDialogData>({
      component: ManualDeliveryResultByBarcodeDialogComponent,
      modalOptions: {
        width: 1100,
        height: 700,
        titleTemplate: 'Zapsat doručení čtečkou kódů',
        disableAutoMargin: true
      },
      data: {
        dispatchOfficeDistributionNodeIds,
      },
    }).subscribe(consignmentsToProcessCount => {
      if (consignmentsToProcessCount) {
        this.consignmentsToastService.dispatchConsignmentBulkActionAccepted({
          [InternalNotificationKey.COUNT]: consignmentsToProcessCount
        });
        this.announceActionCompleted(ConsignmentToolbarAction.WORKFLOW_ACTION_COMPLETED);
      }
    });
  }


  private validateAndRunWorkflowBulk(
    workflowAction: OwnConsignmentWorkflowAction,
    dialogWarningLabel: string,
    operationValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[],
    esslObjects: Nullable<GenericOwnElasticConsignmentWithConsignee[]>,
    authorizedOperations: GeneralAuthorizedOperation[],
  ) {
    this.bulkOperationValidationService.validateEsslObjects<GenericOwnElasticConsignmentWithConsignee>(
      {
        dialogTitle: constructBulkModalTitle(dispatchDialogTitles[workflowAction]),
        dialogTitleContext: {count: esslObjects!.length},
        dialogWarningLabel,
        dialogWarningLabelContext: {},
        operationValidators,
        esslObjects: esslObjects!.map(this.ownConsignmentToValidationObject),
        authorizedOperations,
      },
      this,
    ).subscribe(validated => {
      if (!validated?.length) return;
      this.workflowAction(workflowAction, validated!);
    });
  }

  private getCreateButtons(): AuthorizedButton {
    return {
      label: 'Vypravení',
      icon: 'vypraveni',
      show: !isNil(this.document),
      authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_OWN_CONSIGNMENT],
      submenuItems: [
        {
          label: 'Vypravení na jednoho adresáta',
          icon: 'funkcni_misto',
          buttonDisablers: [ConsignmentToolbarButtonsDisablers.isDocumentWithoutRefNumber(this.document!)],
          action: _ => this.createSingleConsignment(),
        },
        {
          label: 'Hromadné vypravení',
          icon: 'substitute',
          isTestingFeature: true,
        },
        {
          label: 'Interní vypravení',
          icon: 'internal',
          buttonDisablers: [ConsignmentToolbarButtonsDisablers.isDocumentWithoutRefNumber(this.document!)],
          action: _ => this.createInternalConsignment(),
        },
        {
          label: 'Vyvěšení na úřední desku',
          icon: 'uredni_deska',
          buttonDisablers: [ConsignmentToolbarButtonsDisablers.isDocumentWithoutRefNumber(this.document!)],
          action: _ => this.createOfficeDeskConsignment(),
        },
        {
          label: 'Dev listinná zásilka',
          show: false,
          icon: 'funkcni_misto',
          buttonDisablers: [ConsignmentToolbarButtonsDisablers.isDocumentWithoutRefNumber(this.document!)],
          action: _ => this.devCreatePaperConsignment(),
        },
      ],
    };
  }

  private getEditButton(selection: Nullable<(GenericOwnElasticConsignmentWithConsignee)[]>,
                        context: OwnConsignmentToolbarContext): AuthorizedButton {
    return {
      label: 'Upravit',
      icon: 'edit',
      buttonDisablers: [CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection)],
      action: _ => this.edit(selection!),
    };
  }

  private getHandoverButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>,
                            tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView,
                            deliveryTypes: DeliveryTypeDto[]): AuthorizedButton {
    let buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];
    let label = '';

    if (
      tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS ||
      tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_CREATE_OFFICE_DESK_CONSIGNMENTS ||
      tableView === OwnConsignmentTableView.OFFICER_VIEW_ALL ||
      tableView === OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS ||
      tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS
    ) {
      buttonValidators = [
        ConsignmentOperationValidators.isConsignmentInvalid(),
        ConsignmentOperationValidators.isPersonalConsignment(),
        ConsignmentOperationValidators.isPortalConsignment(),
        ConsignmentOperationValidators.isEmailOutsideFilingOffice(deliveryTypes),
        ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.TO_HANDOVER, this.translateService),
      ];
      label = 'Předat výpravně';
    } else if (
      tableView === OwnConsignmentTableView.OFFICER_VIEW_REJECTED ||
      tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED
    ) {
      buttonValidators = [
        ConsignmentOperationValidators.isConsignmentInvalid(),
        ConsignmentOperationValidators.isPersonalConsignment(),
        ConsignmentOperationValidators.isPortalConsignment(),
        ConsignmentOperationValidators.isDigitalConsignment(),
        ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.REJECTED, this.translateService),
      ];
      label = 'Znovupředat výpravně';
    }

    return {
      label,
      icon: 'handover_to_distribution',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_HANDOVER],
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.HANDOVER,
          'Některé zásilky ({{errorCount}}) nebylo možné předat výpravně. Předání výpravně bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getTakeoverButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isDigitalConsignment(),
      ConsignmentOperationValidators.isPortalConsignment(),
      ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.TO_TAKEOVER, this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    return {
      label: 'Převzít',
      icon: 'take_over',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_TAKEOVER],
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.TAKEOVER,
          'Některé zásilky ({{errorCount}}) nebylo možné převzít. Převzetí bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getDispatchOutsideFilingOfficeButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>, deliveryTypes: DeliveryTypeDto[]): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isPaperConsignment(),
      ConsignmentOperationValidators.isPortalConsignment(),
      ConsignmentOperationValidators.isNotEmailOutsideFilingOffice(deliveryTypes),
      ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.TO_HANDOVER, this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    return {
      label: 'Potvrdit vypravení mimo EPO',
      icon: 'email',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_DISPATCH],
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE,
          'Některé zásilky ({{errorCount}}) nebylo možné potvrdit. Potvrzení bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getTakeoverByBarcodeMenu(context: OwnConsignmentToolbarContext): AuthorizedButton {
    const visibleTilBreakpoint = context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER ? ResponsivityBreakpoint.DESKTOP_MD : undefined;

    return {
      label: 'Převzít čtečkou kódů',
      icon: 'barcode',
      visibleTilBreakpoint,
      submenuItems: [
        {
          label: 'Převzít čtečkou kódů',
          visibleTilBreakpoint,
          action: _ => this.openTakeoverByBarcodeDialog(OwnConsignmentWorkflowAction.TAKEOVER, context.dispatchOfficeDistributionNodeIds),
        },
        {
          label: 'Převzít a předat do distribuce čtečkou kódů',
          visibleTilBreakpoint,
          action: _ => this.openTakeoverByBarcodeDialog(OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION, context.dispatchOfficeDistributionNodeIds),
        },
      ],
    };
  }

  // likely not needed, but there may be a CHR for it still
  // private getDispatchOutsideFilingOfficeButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>,
  //                                              tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView,): AuthorizedButton {
  //   {
  //     label: 'Vypravit mimo EPO',
  //     icon: 'not_through_EPO.svg',
  //     disable: !isCurrentUserOfficer,
  //     // authorizedOperation: OwnConsignmentAuthorizedOperation.HANDOVER, // not sure about this, but DISPATCH permission is only meant for dispatch office
  //     buttonDisablers: [
  //       CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
  //       ConsignmentToolbarButtonsDisablers.notInAllowedStatus(selection!, OwnConsignmentStatus.TO_HANDOVER, this.translateService),
  //       ConsignmentToolbarButtonsDisablers.isNotPersonalConsignment(selection!),
  //       ConsignmentToolbarButtonsDisablers.isNotEmailOutsideFilingOffice(selection![0]),
  //     ],
  //     action: _ => this.workflowAction(OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE, selection!),
  //   },
  // }

  private getPrepareForDistributionByBarcodeButton(context: OwnConsignmentToolbarContext): AuthorizedButton {
    return {
      label: 'Předat do distribuce čtečkou kódů',
      icon: 'barcode',
      visibleTilBreakpoint: context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_DISPATCH ? ResponsivityBreakpoint.DESKTOP_MD : undefined,
      action: _ => this.openTakeoverByBarcodeDialog(OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION, context.dispatchOfficeDistributionNodeIds),
    };
  }

  private getPrepareForDistributionButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isNotPaperOrMultiPaperOrInternalPaperConsignment(),
      ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.TAKEN_OVER, this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    return {
      label: 'Předat do distribuce',
      icon: 'handover_to_distribution',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_DISTRIBUTE],
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION,
          'Některé zásilky ({{errorCount}}) nebylo možné předat do distribuce. Předání do distribuce bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getTakeoverAndPrepareForDistributionButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>,
                                                     tableView?: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.notInOneOfAllowedStatus([OwnConsignmentStatus.TAKEN_OVER, OwnConsignmentStatus.TO_TAKEOVER], this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    if (selection && selection.length > 0) {
      if (isOwnPaperElasticConsignment(selection![0]) || isOwnMultiPaperElasticConsignment(selection![0]) || isOwnInternalPaperElasticConsignment(selection![0])) {
        buttonValidators.push(
          ConsignmentOperationValidators.isNotPaperOrMultiPaperOrInternalPaperConsignment(),
        );
      } else {
        buttonValidators.push(
          ConsignmentOperationValidators.isNotDigitalConsignment(),
          ConsignmentOperationValidators.isPortalConsignment(),
        );
      }
    }

    return {
      label: 'Převzít a předat do distribuce',
      icon: 'handover_to_distribution',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_DISTRIBUTE],
      show: tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER,
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION,
          'Některé zásilky ({{errorCount}}) nebylo možné převzít a předat do distribuce. Převzetí a předání do distribuce bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getDispatchButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isNotPaperConsignment(),
      ConsignmentOperationValidators.notInOneOfAllowedStatus([OwnConsignmentStatus.IN_DISTRIBUTION], this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    return {
      label: 'Potvrdit vypravení',
      icon: 'sent',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_MARK_AS_DISPATCHED],
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.DISPATCH,
          'Některé zásilky ({{errorCount}}) nebylo možné potvrdit. Potvrzení bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getGuidedBPFCreateButton(context: OwnConsignmentToolbarContext): AuthorizedButton {
    return {
      label: 'Průvodce generování PPA',
      icon: 'postal_filing_sheet',
      buttonDisablers: [],
      show: context.guidedBPFGenerationEnabled,
      action: _ => this.openBulkPostingFormCreateDialog(context.excludedConsignmentIdsForBpf),
    };
  }

  private getWithdrawFromDistributionButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>, context: OwnConsignmentToolbarContext): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isNotPaperOrInternalAnalogConsignment(),
      ConsignmentOperationValidators.isInternalConsignmentInDistribution(),
      ConsignmentOperationValidators.notInOneOfAllowedStatus([OwnConsignmentStatus.IN_DISTRIBUTION], this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    return {
      label: 'Stáhnout z distribuce',
      icon: 'send_back',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION],
      visibleTilBreakpoint: context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION ? ResponsivityBreakpoint.DESKTOP_LG : undefined,
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.WITHDRAW_FROM_DISTRIBUTION,
          'Některé zásilky ({{errorCount}}) nebylo možné stáhnout z distribuce. Stažení z distribuce bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getWithdrawForCorrectionButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>, context: OwnConsignmentToolbarContext): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isDigitalConsignment(),
      ConsignmentOperationValidators.isPortalConsignment(),
      ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.REJECTED, this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    return {
      label: 'Stáhnout k nápravě',
      icon: 'send_back',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_WITHDRAW],
      visibleTilBreakpoint: context.tableView === OwnConsignmentTableView.OFFICER_VIEW_REJECTED && context.isUnitView ? ResponsivityBreakpoint.DESKTOP_MD : undefined,
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.WITHDRAW_FOR_CORRECTION,
          'Některé zásilky ({{errorCount}}) nebylo možné stáhnout k nápravě. Stažení k nápravě bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getCancelButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>, context: OwnConsignmentToolbarContext): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isPortalConsignment(),
      ConsignmentOperationValidators.notInOneOfAllowedStatus([OwnConsignmentStatus.REJECTED], this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    const visibleTilBreakpoint = (
      (
        context.tableView === OwnConsignmentTableView.OFFICER_VIEW_ALL ||
        context.tableView === OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS ||
        context.tableView === OwnConsignmentTableView.OFFICER_VIEW_REJECTED
      ) && context.isUnitView ?
        ResponsivityBreakpoint.DESKTOP_MD :
        undefined
    );

    return {
      label: 'Stornovat',
      icon: context.isUnitView ? undefined : 'closed',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_CANCEL],
      visibleTilBreakpoint,
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.CANCEL,
          'Některé zásilky ({{errorCount}}) nebylo možné stornovat. Stornování bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getDeleteButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>, context: OwnConsignmentToolbarContext): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.isPortalConsignment(),
      ConsignmentOperationValidators.notInOneOfAllowedStatus([OwnConsignmentStatus.TO_HANDOVER, OwnConsignmentStatus.TO_TAKEOVER], this.translateService),
      ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
    ];

    return {
      label: 'Smazat',
      icon: context.isUnitView || context.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS ? undefined : 'delete',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_DELETE],
      visibleTilBreakpoint: context.tableView === OwnConsignmentTableView.OFFICER_VIEW_ALL || context.tableView === OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS ? ResponsivityBreakpoint.DESKTOP_MD : undefined,
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.DELETE,
          'Některé zásilky ({{errorCount}}) nebylo možné smazat. Smazání bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getRejectButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>,
                          tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView,): AuthorizedButton {
    let buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [];

    if (tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_POST) {
      buttonValidators = [
        ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.TO_BE_POSTED, this.translateService),
      ];
    } else {
      buttonValidators = [
        ConsignmentOperationValidators.isPersonalConsignment(),
        ConsignmentOperationValidators.isPortalConsignment(),
        ConsignmentOperationValidators.isInternalConsignmentInDistribution(),
        ConsignmentOperationValidators.notInOneOfAllowedStatus([OwnConsignmentStatus.TAKEN_OVER, OwnConsignmentStatus.TO_TAKEOVER, OwnConsignmentStatus.IN_DISTRIBUTION], this.translateService),
        ConsignmentOperationValidators.isPartOfOwnMultiPaperConsignment(),
      ];
    }

    return {
      label: 'Vrátit',
      icon: 'send_back',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_REJECT],
      visibleTilBreakpoint: (
        tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER ||
        tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION ?
          ResponsivityBreakpoint.DESKTOP_LG :
          (
            tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_DISPATCH ?
              ResponsivityBreakpoint.DESKTOP_MD :
              undefined
          )
      ),
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: button => {
        this.validateAndRunWorkflowBulk(
          OwnConsignmentWorkflowAction.REJECT,
          'Některé zásilky ({{errorCount}}) nebylo možné vrátit. Vrácení bude provedeno jen s vyhovujícími zásilkami ({{successCount}}).',
          buttonValidators,
          selection,
          button.authorizedOperations!,
        );
      },
    };
  }

  private getPostButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.TO_BE_POSTED, this.translateService),
    ];

    return {
      label: 'Vyvěsit',
      icon: 'uredni_deska',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_OFFICE_DESK_MANUAL_POST],
      buttonDisablers: [
        CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: _ => this.workflowAction(OwnConsignmentWorkflowAction.POST, selection!),
    };
  }

  private getUnpostButton(selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>): AuthorizedButton {
    const buttonValidators: OperationValidator<GenericOwnElasticConsignmentWithConsignee>[] = [
      ConsignmentOperationValidators.notInAllowedStatus(OwnConsignmentStatus.POSTED, this.translateService),
    ];

    return {
      label: 'Svěsit',
      icon: 'deactivate',
      authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_OFFICE_DESK_MANUAL_POST],
      buttonDisablers: [
        CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
        ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, buttonValidators),
      ],
      action: _ => this.workflowAction(OwnConsignmentWorkflowAction.UNPOST, selection!),
    };
  }

  private getConsignmentMoreButtons(
    selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>,
    context: OwnConsignmentToolbarContext,
  ): AuthorizedButton {
    const isOfficeDeskView = enumValuesToArray(OwnConsignmentOfficeDeskTableView).includes(context.tableView);

    let submenuButtons: AuthorizedButton[] = [
      ...(
        context.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS ?
          [this.getDeleteButton(selection, context)] :
          []
      ),
      {
        label: 'Tisk adresní etikety',
        authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_PRINT_LABEL],
        buttonDisablers: [
          ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, [
            ConsignmentOperationValidators.isNotSupportedForLabelPrinting(),
          ]),
          CommonToolbarDisablers.isNoItemSelected(selection),
        ],
        action: _ => this.printSheetLabels(selection!),
      },
      {
        label: 'Tisk poštovní obálky',
        authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_PRINT_ENVELOPE],
        show: !isOfficeDeskView,
        buttonDisablers: [
          ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, [
            ConsignmentOperationValidators.isNotSupportedForEnvelopePrinting(),
          ]),
          CommonToolbarDisablers.isNoItemSelected(selection),
        ],
        action: _ => this.printEnvelopes(selection!),
      },
      // todo(rb) implement operation Tisk with authorizedOperation = DocumentAuthorizedOperation.PRINT_OWN_CONSIGNMENT_LIST
    ];

    if (context.isUnitView) {
      submenuButtons = [this.getCancelButton(selection, context), this.getDeleteButton(selection, context), ...submenuButtons];
    }

    return {
      label: 'Více',
      icon: 'more',
      submenuItems: submenuButtons,
    };
  }

  private getDeliveryConfirmationButtons(
    selection: Nullable<GenericOwnElasticConsignmentWithConsignee[]>,
    deliveryTypes: DeliveryTypeDto[],
    deliveryResults: DeliveryResultDto[],
    additionalServices: DeliveryServiceAdditionalDto[],
    deliveryServiceCombinations: DeliveryServiceCombinationDto[],
    context: OwnConsignmentToolbarContext,
  ): AuthorizedButton[] {
    const visibleTilBreakpoint = (
      (context.tableView === OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS ||
        context.tableView === OwnConsignmentTableView.OFFICER_VIEW_ALL) && context.isUnitView ?
        ResponsivityBreakpoint.DESKTOP_MD :
        undefined
    );

    return [
      ...(
        context.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS ?
          [] :
          [this.getDeliveryConfirmationByBarcodeButton(context)]
      ),
      {
        label: 'Zapsat doručení',
        icon: 'sent',
        show: context.tableView !== OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS,
        visibleTilBreakpoint,
        submenuItems: [
          {
            label: 'Zapsat doručení',
            authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_CONFIRM_DELIVERY],
            visibleTilBreakpoint,
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, [
                ConsignmentOperationValidators.isPortalConsignment(),
              ]),
              ConsignmentToolbarButtonsDisablers.isDeliveryResultRecordUnsupported(selection![0], deliveryTypes, deliveryResults, additionalServices, deliveryServiceCombinations),
            ],
            action: _ => this.writeManualDeliveryResult(selection!),
          },
          ...(
            context.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS ?
              [this.getDeliveryConfirmationByBarcodeButton(context)] :
              []
          ),
          {
            label: 'Připojit doručenku',
            show: (
              context.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS ||
              context.tableView === OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT ||
              context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT ||
              context.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED
            ),
            authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_CONFIRM_DELIVERY],
            visibleTilBreakpoint,
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              ...ConsignmentToolbarButtonsDisablers.generateDisablers(selection!, [
                ConsignmentOperationValidators.isPortalConsignment(),
                ConsignmentOperationValidators.consignmentHasNoProofOfDelivery(),
              ]),
            ],
            action: _ => this.addProofOfDelivery(selection!),
          },
        ],
      },
    ];
  }

  private getDeliveryConfirmationByBarcodeButton(context: OwnConsignmentToolbarContext): AuthorizedButton {
    return {
      label: 'Zapsat doručení čtečkou kódů',
      icon: context.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS ? undefined : 'barcode',
      show: context.tableView !== OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS,
      action: _ => this.openManualDeliveryResultByBarcodeDialog(context.dispatchOfficeDistributionNodeIds),
    };
  }

}
