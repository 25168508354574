import {Component, inject, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {merge, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {IczFormArray, IczFormControl, IczFormGroup} from '../../icz-form-controls';
import {isImportant, isRequired} from '../icz-validators/icz-validators';
import {
  IczFieldValidationResults,
  IczValidationErrors,
  IczValidatorResult,
} from '../icz-validators/validator-decorators';
import {IczFormDirective} from '../../form-container/icz-form.directive';
import {AlertComponent, InterpolatePipe} from '@icz/angular-essentials';
import {TranslateModule} from '@ngx-translate/core';
import {AsyncPipe, NgClass, NgTemplateOutlet} from '@angular/common';

/**
 * A predicate which checks if the user manipulated the control and it became invalid due to his actions.
 */
function isInvalid(control: Nullable<AbstractControl>): boolean {
  return (control?.touched && control?.invalid) ?? false;
}

/**
 * A special wrapper to be placed around a custom form control/form group/form array which applies validity/required field
 * colors to it. It also displays validation messages which correspond to failed reactive form validations below the form fields.
 *
 * Please note that out built-in controls already have this behavior.
 */
@Component({
  selector: 'icz-validation-errors-list',
  templateUrl: './validation-errors-list.component.html',
  styleUrls: ['./validation-errors-list.component.scss'],
  standalone: true,
  imports: [
    AlertComponent,
    TranslateModule,
    InterpolatePipe,
    NgTemplateOutlet,
    AsyncPipe,
    NgClass,
  ]
})
export class ValidationErrorsListComponent implements OnInit {

  private iczForm = inject(IczFormDirective, {optional: true, skipSelf: true});

  /**
   * Form control to perform chesks against if applying this component to a single control.
   */
  @Input()
  set control(newControl: Nullable<IczFormControl>) {
    if (newControl && this._control !== newControl) {
      this._control = newControl;

      if (this._control.statusChanges && this._control.touchChanged && this._control.dirtinessChanged) {
        const controlChange$ = merge(
          this._control.statusChanges,
          this._control.touchChanged,
          this._control.dirtinessChanged,
        ).pipe(
          // required in order to emit "isRequired$" after component render
          startWith(null),
          map(_ => undefined),
        );
        this.isInvalid$ = controlChange$.pipe(
          map(_ => isInvalid(this._control)),
        );
        this.isRequired$ = controlChange$.pipe(
          map(_ => !isInvalid(this._control) && isRequired(this._control)),
        );
        this.isImportant$ = controlChange$.pipe(
          map(_ => !isInvalid(this._control) && !isRequired(this._control) && isImportant(this._control)),
        );
      }
    }
  }
  get control(): IczFormControl {
    return this._control;
  }

  /**
   * Form group to perform chesks against if applying this component to a group of fields.
   */
  @Input()
  set form(newForm: Nullable<IczFormGroup>) {
    if (newForm && this._form !== newForm) {
      this._form = newForm;

      if (this._form.statusChanges && this._form.touchChanged && this._form.dirtinessChanged) {
        this.isFormInvalid$ = merge(
          this._form.statusChanges,
          this._form.touchChanged,
          this._form.dirtinessChanged,
        ).pipe(
          map(_ => this.form?.touched && (this.form?.errors !== null || this.form?.invalid)),
        );
      }
    }
  }
  get form(): IczFormGroup {
    return this._form as IczFormGroup;
  }

  /**
   * Form array to perform chesks against if applying this component to a group of fields.
   */
  @Input()
  set array(newArray: Nullable<IczFormArray>) {
    if (newArray && this._array !== newArray) {
      this._array = newArray;

      if (this._array.statusChanges && this._array.touchChanged && this._array.dirtinessChanged) {
        this.isArrayInvalid$ = merge(
          this._array.statusChanges,
          this._array.touchChanged,
          this._array.dirtinessChanged,
        ).pipe(
          map(_ => this.array?.touched === true && this.array?.errors !== null),
        );
      }
    }
  }
  get array(): IczFormArray {
    return this._array;
  }

  /**
   * Manual toggle to force disable field error message rendering.
   */
  @Input()
  showValidationStatus: boolean = true;

  /**
   * If true, will turn on rendering of errors manually supplied into fieldsValidationErrors input.
   * @deprecated - we will try to get this information directly from form control errors after refactoring our reactive validators.
   */
  @Input()
  showFieldErrors: boolean = false;

  /**
   * Used for supplying external errors for rendering. Handy when we want to distinguish which controls have which errors,
   * @deprecated - we will try to get this information directly from form control errors after refactoring our reactive validators.
   */
  @Input()
  fieldsValidationErrors: IczFieldValidationResults[] = [];

  /**
   * Renders the errors inside an icz-alert.
   * @depracated - we would like to move this behavior to another component.
   */
  @Input()
  wrapInAlert = false;

  /**
   * Makes the icz-alert displayed using wrapInAlert input more visually subtle, handy for rendering small error messages.
   * @depracated - we would like to move this behavior to another component.
   */
  @Input()
  compactAlert = false;

  /**
   * Makes the icz-alert displayed using wrapInAlert input more visually subtle, handy for rendering small error messages.
   * @depracated - we would like to move this behavior to another component.
   */
  @Input()
  customAlertHeading: Nullable<string>;

  /**
   * Marks validation errors list contents as focusable. Used in conjunction with iczFormSubmit -
   * when the user presses Submit and the control/group/array of this component is invalid,
   * the area of this component's content slot is autofocused.
   */
  @Input()
  useFocusableArea = false;

  private _control!: IczFormControl;
  private _array!: IczFormArray;
  private _form!: IczFormGroup;
  private errorCountLimit = Infinity;

  protected isInvalid$!: Observable<boolean>;
  protected isFormInvalid$!: Observable<boolean>;
  protected isArrayInvalid$!: Observable<boolean>;
  protected isRequired$!: Observable<boolean>;
  protected isImportant$!: Observable<boolean>;

  /**
   * @internal
   */
  ngOnInit() {
    if (this.iczForm) {
      this.errorCountLimit = 1;
    }
  }

  protected getErrors(type: 'control' | 'form' | 'array'): IczValidatorResult[] {
    const out: IczValidatorResult[] = [];

    if (this[type] && this[type]!.errors) {
      const errors: Nullable<IczValidationErrors> = this[type]!.errors!;
      const keys = Object.keys(errors);

      for (let i = 0; i < Math.min(keys.length, this.errorCountLimit); ++i) {
        out.push(errors[keys[i]]);
      }
    }

    return out;
  }

  protected getFieldErrors(fieldErrors: IczFieldValidationResults): IczValidatorResult[] {
    const out: IczValidatorResult[] = [];

    if(fieldErrors.errors !== null){
      Object.entries(fieldErrors.errors!).forEach(([_, value]) => {
        out.push(value);
      });
    }

    return out;
  }

  protected getAlertMode() {
    return this.compactAlert ? 'compact' : 'full';
  }

}
