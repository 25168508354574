/* eslint-disable @typescript-eslint/quotes */

/**
 * Icons aggregated from libs/icz-angular-essentials/assets/icons/.
 *
 * Used as a method of resource inlining to decrease HTTP GET overhead
 * for fetching lots of small icons at application startup.
 */
export const ICONS = {
  "checked": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"13\" fill=\"none\" viewBox=\"0 0 16 13\">\n    <path fill=\"#4CAF50\" fill-rule=\"evenodd\" d=\"M.44 4.682c-.586.586-.586 1.536 0 2.121l5.303 5.304 9.546-9.546c.585-.586.585-1.536 0-2.122-.586-.585-1.536-.585-2.122 0L5.743 7.864 2.56 4.682c-.586-.586-1.536-.586-2.122 0z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "close": "<svg width=\"14\" height=\"14\" viewBox=\"0 0 14 14\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path d=\"M1.70711 0.292893C1.31658 -0.0976311 0.683418 -0.0976311 0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683418 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711C14.0976 1.31658 14.0976 0.683418 13.7071 0.292894C13.3166 -0.0976303 12.6834 -0.0976299 12.2929 0.292894L7 5.58579L1.70711 0.292893Z\" fill=\"#6C6F75\"/>\n</svg>\n",
  "collapse": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\n<svg\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\n   xmlns:cc=\"http://creativecommons.org/ns#\"\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\n   xmlns=\"http://www.w3.org/2000/svg\"\n   id=\"svg835\"\n   version=\"1.1\"\n   viewBox=\"0 0 20 20\"\n   fill=\"none\"\n   height=\"20\"\n   width=\"20\">\n  <metadata\n     id=\"metadata841\">\n    <rdf:RDF>\n      <cc:Work\n         rdf:about=\"\">\n        <dc:format>image/svg+xml</dc:format>\n        <dc:type\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\n        <dc:title></dc:title>\n      </cc:Work>\n    </rdf:RDF>\n  </metadata>\n  <defs\n     id=\"defs839\" />\n  <path\n     id=\"path833\"\n     clip-rule=\"evenodd\"\n     d=\"M 9.364,6.4017118 3.707,12.058712 a 1,-1 0 0 0 1.414,1.414 l 4.243,-4.2430002 4.243,4.2430002 a 1,-1 0 1 0 1.414,-1.414 z\"\n     fill-rule=\"evenodd\"\n     fill=\"#6c6f75\" />\n</svg>\n",
  "config_success": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"26\" height=\"26\" fill=\"none\" viewBox=\"0 0 26 26\">\n    <path fill=\"#4CAF50\" fill-rule=\"evenodd\" d=\"M13 26c7.18 0 13-5.82 13-13S20.18 0 13 0 0 5.82 0 13s5.82 13 13 13zM5.44 13.803c-.586-.586-.586-1.535 0-2.121.585-.586 1.535-.586 2.12 0l3.183 3.182 7.424-7.425c.586-.585 1.536-.585 2.122 0 .585.586.585 1.536 0 2.122l-9.546 9.546-5.304-5.304z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "copy": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\n<svg\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\n   xmlns:cc=\"http://creativecommons.org/ns#\"\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\n   xmlns=\"http://www.w3.org/2000/svg\"\n   id=\"svg16\"\n   version=\"1.1\"\n   viewBox=\"0 0 18 18\"\n   fill=\"none\"\n   height=\"18\"\n   width=\"18\">\n  <metadata\n     id=\"metadata22\">\n    <rdf:RDF>\n      <cc:Work\n         rdf:about=\"\">\n        <dc:format>image/svg+xml</dc:format>\n        <dc:type\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\n        <dc:title></dc:title>\n      </cc:Work>\n    </rdf:RDF>\n  </metadata>\n  <defs\n     id=\"defs20\" />\n  <path\n     id=\"path10\"\n     clip-rule=\"evenodd\"\n     d=\"M0 17V5c0-.552.448-1 1-1h3V1c0-.552.448-1 1-1h12c.552 0 1 .448 1 1v12c0 .552-.448 1-1 1h-3v3c0 .552-.448 1-1 1H1c-.552 0-1-.448-1-1zm12-3H5c-.552 0-1-.448-1-1V6H2v10h10v-2zm-6-2h10V2H6v10z\"\n     fill-rule=\"evenodd\"\n     fill=\"#6C6F75\" />\n</svg>\n",
  "done": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"10\" height=\"8\" fill=\"none\" viewBox=\"0 0 10 8\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M3.606 4.808L1.202 2.404 0 3.606l3.606 3.606 6.01-6.01L8.416 0 3.606 4.808z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "error": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"26\" height=\"24\" fill=\"none\" viewBox=\"0 0 26 24\">\n    <path fill=\"#E82C17\" fill-rule=\"evenodd\" d=\"M25.743 20.165L14.663.961c-.168-.292-.411-.535-.703-.704-.92-.53-2.094-.215-2.624.704L.257 20.165c-.168.292-.257.623-.257.96 0 1.06.86 1.92 1.92 1.92h22.16c.336 0 .667-.088.959-.257.919-.53 1.234-1.704.704-2.623zM13 1.921L1.92 21.125h22.16L13 1.92zm0 16.803c-.796 0-1.44-.645-1.44-1.44 0-.796.644-1.44 1.44-1.44.795 0 1.44.644 1.44 1.44 0 .795-.645 1.44-1.44 1.44zm0-11.043c.69 0 1.25.559 1.25 1.249 0 .06-.005.118-.013.177l-.69 4.82c-.038.273-.272.476-.547.476-.276 0-.51-.203-.548-.476l-.689-4.82c-.098-.683.377-1.316 1.06-1.414.059-.008.118-.012.177-.012z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "expand_more": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M9.364 13.778L3.707 8.121a1 1 0 0 1 1.414-1.414l4.243 4.243 4.243-4.243a1 1 0 1 1 1.414 1.414l-5.657 5.657z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "in_progress": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"20\" fill=\"none\" viewBox=\"0 0 24 20\">\n    <path fill=\"#fff\" d=\"M2.93 13.593l-1.64.88c.507.868 1.148 1.653 1.905 2.336l1.288-1.312c-.618-.557-1.14-1.197-1.553-1.904zM5.82 16.479l-.984 1.534c.886.522 1.846.909 2.853 1.15l.454-1.748c-.82-.196-1.6-.51-2.322-.936zM11.35 17.57c-.387.052-.783.079-1.178.079h-.024c-.113 0-.226-.002-.338-.007l-.075 1.8c.136.005.275.008.413.008h.023c.483 0 .97-.033 1.444-.098l-.265-1.783zM14.133 16.682c-.369.193-.756.36-1.15.497l.639 1.694c.485-.168.96-.374 1.413-.611l-.902-1.58zM16.42 14.914c-.276.305-.578.592-.899.853l1.217 1.373c.393-.32.764-.672 1.102-1.046l-1.42-1.18zM22.671 8.885l-2.375 2.277V9.725c0-2.598-1.056-5.04-2.973-6.877C15.407 1.012 12.858 0 10.148 0s-5.26 1.012-7.176 2.848C1.056 4.685 0 7.128 0 9.725c0 1 .158 1.985.469 2.93l1.792-.542c-.253-.77-.382-1.573-.382-2.388 0-4.37 3.71-7.924 8.269-7.924s8.268 3.555 8.268 7.924v1.422l-2.39-2.285-1.327 1.275 4.667 4.462L24 10.16l-1.329-1.274z\"/>\n</svg>\n",
  "info": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"14\" fill=\"none\" viewBox=\"0 0 14 14\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M0 7a7 7 0 1 0 14 0A7 7 0 0 0 0 7zm12.6 0A5.6 5.6 0 1 1 1.4 7a5.6 5.6 0 0 1 11.2 0zM7 6.3c.58 0 1.05.47 1.05 1.05v2.8a1.05 1.05 0 1 1-2.1 0v-2.8c0-.58.47-1.05 1.05-1.05zM5.95 4.2a1.05 1.05 0 1 0 2.1 0 1.05 1.05 0 0 0-2.1 0z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "info_blue": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"26\" height=\"26\" fill=\"none\" viewBox=\"0 0 26 26\">\n    <path fill=\"#2A68DE\" d=\"M11.5 8.565c0 .865.672 1.566 1.5 1.566s1.5-.701 1.5-1.566C14.5 7.701 13.828 7 13 7s-1.5.7-1.5 1.565zM14.5 13.26c0-.864-.672-1.564-1.5-1.564s-1.5.7-1.5 1.565v4.174c0 .864.672 1.565 1.5 1.565s1.5-.7 1.5-1.565V13.26z\"/>\n    <path fill=\"#2A68DE\" fill-rule=\"evenodd\" d=\"M13 26C5.82 26 0 20.18 0 13S5.82 0 13 0s13 5.82 13 13-5.82 13-13 13zm0-1.857c6.154 0 11.143-4.989 11.143-11.143 0-6.154-4.989-11.143-11.143-11.143C6.846 1.857 1.857 6.846 1.857 13c0 6.154 4.989 11.143 11.143 11.143z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "more-vertical": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"4\" height=\"16\" fill=\"none\" viewBox=\"0 0 4 16\">\n    <circle cx=\"2\" cy=\"2\" r=\"2\" fill=\"#6C6F75\"/>\n    <circle cx=\"2\" cy=\"8\" r=\"2\" fill=\"#6C6F75\"/>\n    <circle cx=\"2\" cy=\"14\" r=\"2\" fill=\"#6C6F75\"/>\n</svg>\n",
  "system_user": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M9 0C4.037 0 0 4.037 0 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9zm0 16.575c-1.984 0-3.791-.766-5.143-2.018C5.294 12.917 7.1 12.022 9 12.022s3.707.895 5.143 2.535c-1.352 1.252-3.16 2.018-5.143 2.018zM1.425 9c0 1.678.548 3.23 1.476 4.488 1.526-1.688 3.415-2.683 5.436-2.862-1.727-.315-3.042-1.84-3.042-3.668C5.295 4.902 6.957 3.23 9 3.23s3.704 1.672 3.704 3.728c0 1.828-1.314 3.353-3.041 3.668 2.02.179 3.91 1.174 5.436 2.862.928-1.257 1.476-2.81 1.476-4.488 0-4.177-3.398-7.575-7.575-7.575S1.425 4.823 1.425 9zM6.72 6.958C6.72 8.228 7.743 9.26 9 9.26s2.28-1.033 2.28-2.303S10.257 4.654 9 4.654 6.72 5.688 6.72 6.958z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "user_blue": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\n<svg\n   xmlns:dc=\"http://purl.org/dc/elements/1.1/\"\n   xmlns:cc=\"http://creativecommons.org/ns#\"\n   xmlns:rdf=\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"\n   xmlns:svg=\"http://www.w3.org/2000/svg\"\n   xmlns=\"http://www.w3.org/2000/svg\"\n   id=\"svg12\"\n   version=\"1.1\"\n   viewBox=\"0 0 15 15\"\n   fill=\"none\"\n   height=\"15\"\n   width=\"15\">\n  <metadata\n     id=\"metadata18\">\n    <rdf:RDF>\n      <cc:Work\n         rdf:about=\"\">\n        <dc:format>image/svg+xml</dc:format>\n        <dc:type\n           rdf:resource=\"http://purl.org/dc/dcmitype/StillImage\" />\n        <dc:title></dc:title>\n      </cc:Work>\n    </rdf:RDF>\n  </metadata>\n  <defs\n     id=\"defs16\" />\n  <path\n     style=\"fill:#004ef5;fill-opacity:1\"\n     id=\"path10\"\n     d=\"m 7.4995,7.5 c 2.01,0 3.637,-1.628 3.637,-3.637 0,-2.009 -1.628,-3.636 -3.637,-3.636 -2.009,0 -3.636,1.627 -3.636,3.636 0,2.01 1.627,3.637 3.636,3.637 z m 0,1.818 c -2.427,0 -7.272,1.218 -7.272,3.636 v 1.819 h 14.545 v -1.819 c 0,-2.418 -4.845,-3.636 -7.273,-3.636 z\"\n     fill=\"#fff\" />\n</svg>\n",
  "warning": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"18\" fill=\"none\" viewBox=\"0 0 20 18\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M19.802 15.512L11.28.739c-.13-.225-.317-.411-.542-.541-.707-.408-1.61-.165-2.018.541L.198 15.512c-.13.224-.198.479-.198.738 0 .816.661 1.477 1.477 1.477h17.045c.26 0 .514-.068.739-.197.706-.408.949-1.312.541-2.018zM10 1.478L1.477 16.25h17.045L10 1.478zm0 12.925c-.612 0-1.108-.496-1.108-1.108 0-.612.496-1.108 1.108-1.108.612 0 1.108.496 1.108 1.108 0 .612-.496 1.108-1.108 1.108zm0-8.495c.53 0 .96.43.96.961 0 .046-.002.091-.009.136l-.53 3.708c-.03.21-.21.366-.421.366-.212 0-.392-.156-.422-.366l-.53-3.708c-.075-.525.29-1.012.816-1.087.045-.007.09-.01.136-.01z\" clip-rule=\"evenodd\"/>\n</svg>\n"
};
