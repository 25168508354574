<form iczForm [formGroup]="form">
  <div
    class="row items-center justify-between toolbar"
    cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
  >
    <icz-form-autocomplete
      class="mt-16"
      cdkDragHandle
      cdkDragHandleDisabled
      formControlName="filterOperator"
      [clearable]="false"
      [options]="filterOperators"
    ></icz-form-autocomplete>
    <icz-button svgIcon="close" (onAction)="close.emit()" background="transparent" disableRipple size="small"></icz-button>
  </div>
</form>
