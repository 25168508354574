import {Component, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {
  ComponentWithContext,
  IAutocompleteListItemContext,
  IPopoverContext,
} from '../../form-autocomplete/form-autocomplete.model';
import {TreeItemSelectionStrategy, TreeViewComponent, TreeViewSelection} from '../../tree-view/tree-view.component';
import {TranslateModule} from '@ngx-translate/core';
import {FormFieldComponent} from '../../form-field/form-field.component';
import {IczOption} from '../../form-elements.model';
import {AutoFocusDirective} from '@icz/angular-essentials';

/**
 * @internal
 */
@Component({
  selector: 'icz-form-autocomplete-tree-list',
  templateUrl: './form-autocomplete-tree-list.component.html',
  styleUrls: ['./form-autocomplete-tree-list.component.scss'],
  standalone: true,
  imports: [
    TreeViewComponent,
    TranslateModule,
    FormFieldComponent,
    AutoFocusDirective,
  ]
})
export class FormAutocompleteTreeListComponent implements ComponentWithContext<IPopoverContext> {

  @Input({required: true})
  options: IczOption[] = [];
  @Input({required: true})
  selectedOptions: IczOption[] = [];
  @Input({required: true})
  isMultiselect: Nullable<boolean> = false;
  @Input({required: true})
  searchTermInputPrefix = '';
  @Input({required: true})
  searchTermInputPlaceholder = '';
  @Input({required: true})
  selectionStrategy = TreeItemSelectionStrategy.SIMPLE;
  @Input({required: true})
  listItemTemplate!: TemplateRef<IAutocompleteListItemContext>;
  @Input()
  containerHeight: Nullable<number>;
  @Output()
  selectionChanged = new EventEmitter<IczOption[]>();
  @Output()
  searchTermChanged = new EventEmitter<string>();

  protected _searchTerm: string = '';

  protected searchItems($event: any) {
    // this method won't emit to searchTermChanged because TreeViewComponent already
    // includes a sophisticated mechanism for full-text search in n-ary tree.
    this._searchTerm = $event;
  }

  protected treeItemSelected(selection: TreeViewSelection) {
    this.selectionChanged.emit(
      this.options.filter(o => selection.selectedNodes.findIndex(s => s.value === o.value) !== -1)
    );
  }

  protected readonly TreeItemSelectionStrategy = TreeItemSelectionStrategy;

}
