import {Component, DestroyRef, ElementRef, inject, OnInit} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {add, sub} from 'date-fns';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {FileSizeChangedEvent, FileSizeFilterComponent} from './file-size-filter/file-size-filter.component';
import {AbstractTableFilter} from '../../../abstract-table-filter.component';
import {BasicFilterItem, FilterType} from '../../../filter.types';
import {isNumberRange, NumberFilterComponent, NumberRange} from './number-filter/number-filter.component';
import {AutoFocusDirective, getFileSizeUnitLabel, transformFileSize} from '@icz/angular-essentials';
import {DateFilterComponent, DateFilterValue, isDateOperatorIntervalable} from './date-filter/date-filter.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  formatAsLocalIsoDate,
  FormFieldComponent,
  FormInlineSelectComponent,
  isDateRange,
  isValidDate
} from '@icz/angular-form-elements';
import {NgClass} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {FilterOperator, isNoValueOperator, isValidIczDurationString} from '../../../table.utils';

/**
 * @internal
 */
export const SUBVALUES_SHAPE_INDICATOR = 'interval';

/**
 * @internal
 */
@Component({
  selector: 'icz-basic-filters',
  templateUrl: './basic-filters.component.html',
  styleUrls: ['./basic-filters.component.scss'],
  standalone: true,
  imports: [
    FormInlineSelectComponent,
    NgClass,
    FormFieldComponent,
    NumberFilterComponent,
    DateFilterComponent,
    FileSizeFilterComponent,
    AutoFocusDirective,
    ReactiveFormsModule,
  ],
})
export class BasicFiltersComponent extends AbstractTableFilter implements OnInit {

  private destroyRef = inject(DestroyRef);
  private el = inject(ElementRef);

  // Override
  override set item(newItem: BasicFilterItem) {
    this._item = newItem;

    if (newItem.filterType === FilterType.DATE ||
      newItem.filterType === FilterType.DATETIME ||
      newItem.filterType === FilterType.DATE_STATISTICS
    ) {
      if (isValidIczDurationString(newItem.value)) {
        this.selectedDate = newItem.value as string;
      }
      else if (newItem.subValues?.length === 2 && newItem.subValues[0].value && newItem.subValues[1].value) {
        this.selectedDate = new DateRange(
          new Date(newItem.subValues[0].value),
          sub(new Date(newItem.subValues[1].value), {days: 1}),
        );
      }
      else if (newItem.value) {
        this.selectedDate = new Date(newItem.value as string);
      }
    }
    else if (newItem.filterType === FilterType.NUMBER) {
      if (newItem.subValues?.length === 2 && newItem.subValues[0].value && newItem.subValues[1].value) {
        this.selectedNumber = {
          from: Number(newItem.subValues[0].value),
          to: Number(newItem.subValues[1].value),
        };
      }
      else if (newItem.value) {
        this.selectedNumber = Number(newItem.value as string);
      }
    }
  }
  override get item(): BasicFilterItem {
    return this._item as BasicFilterItem;
  }

  protected selectedDate: Nullable<DateFilterValue> = null;
  protected selectedNumber: Nullable<number | NumberRange> = null;

  private get hasNoValueFilterOperator(): boolean {
    return isNoValueOperator(this.currentOperator);
  }

  readonly FilterType = FilterType;

  ngOnInit() {
    this.initForm();

    this.form.get('value')!.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.emitFilterValue();
    });

    this.form.get('filterOperator')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.applyFilterOperator();
    });
  }

  selectedDateChanged($event: Nullable<DateFilterValue>): void {
    if (isDateRange($event)) {
      if ($event.start && $event.end) {
        const filterOperator: FilterOperator = this.form.get('filterOperator')!.value;

        if (filterOperator === FilterOperator.equals || filterOperator === FilterOperator.notEquals) {
          let intervalOperatorsTuple!: [FilterOperator, FilterOperator];
          let isIntersection!: boolean;

          if (filterOperator === FilterOperator.equals) {
            intervalOperatorsTuple = [FilterOperator.greaterOrEqual, FilterOperator.less];
            isIntersection = true;
          }
          else if (filterOperator === FilterOperator.notEquals) {
            intervalOperatorsTuple = [FilterOperator.less, FilterOperator.greaterOrEqual];
            isIntersection = false;
          }

          this.form.get('value')!.setValue(SUBVALUES_SHAPE_INDICATOR, {emitEvent: false});
          this.setFilterValue.emit({
            value: SUBVALUES_SHAPE_INDICATOR,
            subValues: [
              {operator: intervalOperatorsTuple[0], value: formatAsLocalIsoDate($event.start), and: isIntersection},
              {operator: intervalOperatorsTuple[1], value: formatAsLocalIsoDate(add($event.end, {days: 1})), and: isIntersection},
            ],
            label: null,
            filterOperator,
            closeAfter: true,
          });
        }
        // else nothing
      }
    }
    else {
      if (isValidDate($event)) {
        this.item.viewValue = null;
        this.item.subValues = null;
        this.selectedDate = $event;
        this.form.get('value')!.setValue(this.selectedDate?.toISOString());

        // workaround - debounceTime in value changes listener does not work for date filter type
        if (this.item.filterType === FilterType.DATE || this.item.filterType === FilterType.DATE_STATISTICS) {
          this.emitFilterValue();
          this.applyFilter();
        }
      }
      else if (isValidIczDurationString($event)) {
        const filterOperator: FilterOperator = this.form.get('filterOperator')!.value;

        if (filterOperator === FilterOperator.equals || filterOperator === FilterOperator.notEquals) {
          this.item.viewValue = null;
          this.item.subValues = null;
          this.item.value = $event;
          this.selectedDate = $event;
          this.form.get('value')!.setValue($event);

          if (this.item.filterType === FilterType.DATE || this.item.filterType === FilterType.DATE_STATISTICS) {
            this.emitFilterValue();
            this.applyFilter();
          }
        }
      }
      else {
        this.item.viewValue = null;
        this.item.subValues = null;
        this.selectedDate = null;
        this.form.get('value')!.setValue(null);
      }
    }
  }

  protected selectedNumberChanged($event: Nullable<number | NumberRange>): void {
    this.selectedNumber = $event;

    if (isNumberRange($event)) {
      if ($event.from && $event.to) {
        const filterOperator: FilterOperator = this.form.get('filterOperator')!.value;

        if (filterOperator === FilterOperator.equals || filterOperator === FilterOperator.notEquals) {
          let intervalOperatorsTuple!: [FilterOperator, FilterOperator];
          let isIntersection!: boolean;

          if (filterOperator === FilterOperator.equals) {
            intervalOperatorsTuple = [FilterOperator.greaterOrEqual, FilterOperator.lessOrEqual];
            isIntersection = true;
          }
          else if (filterOperator === FilterOperator.notEquals) {
            intervalOperatorsTuple = [FilterOperator.less, FilterOperator.greater];
            isIntersection = false;
          }

          this.form.get('value')!.setValue(SUBVALUES_SHAPE_INDICATOR, {emitEvent: false});
          this.setFilterValue.emit({
            value: SUBVALUES_SHAPE_INDICATOR,
            subValues: [
              {operator: intervalOperatorsTuple[0], value: String($event.from), and: isIntersection},
              {operator: intervalOperatorsTuple[1], value: String($event.to), and: isIntersection},
            ],
            label: null,
            filterOperator,
            closeAfter: false,
          });

          this.closePopup.emit();
        }
        // else nothing
      }
    }
    else {
      this.item.viewValue = null;
      this.item.subValues = null;
      this.form.get('value')!.setValue(this.selectedNumber);
    }
  }

  protected selectedFileSizeChanged($event: FileSizeChangedEvent) {
    this.form.get('value')!.setValue($event);
  }

  protected applyFilter(): void {
    this.applyFilterOperator();

    if (!this.hasNoValueFilterOperator) {
      this.emitFilterValue();
    }

    this.closeFilterPopup();
  }

  protected enterKeyPressed() {
    if (this.item.filterType !== FilterType.DATE && this.item.filterType !== FilterType.DATE_STATISTICS) {
      this.applyFilter();
    }
  }

  protected emitFilterValue(): void {
    const value = this.form.get('value')!.value;

    const filterOperator = this.form.get('filterOperator')!.value;
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (!this.isFilterWithOptions && value === null) {
      this.setFilterValue.emit({
        value,
        list: this.item.list,
        label: null,
        filterOperator,
      });
      return;
    }

    if (this.hasNoValueFilterOperator) {
      this.setFilterValue.emit({
        value: undefined,
        label: null,
        filterOperator,
        closeAfter: true,
      });
    }
    else if(this.item.filterType === FilterType.FILE_SIZE) {
      const {fileSize, unit} = value as FileSizeChangedEvent;
      const viewValue = `${transformFileSize(fileSize, unit, false)} ${getFileSizeUnitLabel(unit)}`;
      if (value) {
        this.setFilterValue.emit({
          value: fileSize.toString(),
          label: null,
          filterOperator,
          closeAfter: false,
          viewValue
        });
      }
    }
    else if (this.item.filterType === FilterType.DATE || this.item.filterType === FilterType.DATE_STATISTICS || this.item.filterType === FilterType.DATETIME) {
      if (value) {
        this.setFilterValue.emit({
          value: isValidIczDurationString(value) ? value : formatAsLocalIsoDate(value),
          label: null,
          filterOperator,
          closeAfter: true,
        });
      }
      else {
        this.setFilterValue.emit({
          value: null,
          label: null,
          filterOperator,
          closeAfter: false,
        });
      }
    }
    else {
      this.setFilterValue.emit({
        value,
        list: this.item.list,
        label: null,
        filterOperator,
      });
    }
  }

  protected applyFilterOperator() {
    const filterOperator = this.form.get('filterOperator')!.value;
    const valueControl = this.form.get('value');
    const previousOperator = this.item.filterOption!.value!;
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (isNoValueOperator(filterOperator)) {
      valueControl!.setValue(null, {emitEvent: false});
      valueControl!.disable();
      this.emitNoValueFilterValue(filterOperator);
      this.closeFilterPopup();
    }
    else if (valueControl!.value) {
      if (this.item.filterType === FilterType.DATE || this.item.filterType === FilterType.DATETIME || this.item.filterType === FilterType.DATE_STATISTICS) {
        if (this.item.subValues && isDateOperatorIntervalable(previousOperator) && !isDateOperatorIntervalable(filterOperator)) {
          valueControl!.setValue(null, {emitEvent: false});
          valueControl!.disable();
          this.item.value = null;
          this.item.viewValue = null;
          this.item.subValues = null;

          this.setFilterValue.emit({
            value: undefined,
            viewValue: undefined,
            subValues: undefined,
            label: null,
            closeAfter: false,
            filterOperator,
          });
        }
        else {
          if (this.item.subValues?.length === 2) {
            const subValues = this.item.subValues;

            if (previousOperator === FilterOperator.equals && filterOperator === FilterOperator.notEquals) {
              subValues[0].and = false;
              subValues[0].operator = FilterOperator.less;
              subValues[1].and = false;
              subValues[1].operator = FilterOperator.greaterOrEqual;
            }
            else if (previousOperator === FilterOperator.notEquals && filterOperator === FilterOperator.equals) {
              subValues[0].and = true;
              subValues[0].operator = FilterOperator.greaterOrEqual;
              subValues[1].and = true;
              subValues[1].operator = FilterOperator.less;
            }
          }

          this.setFilterValue.emit({
            value: this.item.value,
            subValues: this.item.subValues,
            label: this.item.label,
            filterOperator,
            closeAfter: false,
            viewValue: this.item.viewValue!,
          });
        }
      }
      else {
        this.emitFilterValue();
      }
    }
    else {
      valueControl!.enable();
      setTimeout(() => this.el.nativeElement.querySelector('.filter-body icz-form-field input')?.focus());
    }
  }

}
