import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, share, switchMap, tap} from 'rxjs/operators';
import {ReceivedEpdzMessageDefectDto} from '|api/sad';
import {getComponentIconByFilenameSuffix} from '../../../model';
import {
  BinaryWithCharset,
  RemoteBinaryFileDownloadService
} from '../../../services/remote-binary-file-download.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {EpdzAttachmentViewerParams} from './epdz-attachment-viewer.service';
import {LocalBinaryFileDownloadService} from '../../../services/local-binary-file-download.service';
import {getDigitalMessageDefectSummaryTranslationKey} from '../../../enums/digital-message-issue.enum';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {injectModalData, injectModalRef} from '@icz/angular-modal';

interface FileWithMetadataAndContent {
  fileName: string;
  mimeType: string;
  fileContents: BinaryWithCharset;
}

@Component({
  selector: 'icz-epdz-attachment-viewer',
  templateUrl: './epdz-attachment-viewer.component.html',
  styleUrls: ['./epdz-attachment-viewer.component.scss'],
})
export class EpdzAttachmentViewerComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private modalRef = injectModalRef<Nil>();
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private params = injectModalData<EpdzAttachmentViewerParams>();

  @ViewChild(CdkDrag, {static: false})
  cdkDrag!: CdkDrag;

  get currentAttachmentIndex() {
    return this._currentAttachmentIndex$.value;
  }
  set currentAttachmentIndex(newValue: number) {
    if (this._currentAttachmentIndex$.value !== newValue) {
      this._currentAttachmentIndex$.next(newValue);
    }
  }

  cachedFileContents = new Map<number, Nullable<BinaryWithCharset>>();

  currentFileContents: Nullable<BinaryWithCharset>;

  get isPrevButtonDisabled() {
    return this.currentAttachmentIndex <= 0;
  }

  get isNextButtonDisabled() {
    return !this.params.attachments || this.currentAttachmentIndex >= this.params.attachments.length - 1;
  }

  get currentFileComponentId() {
    return this.params.attachments?.[this.currentAttachmentIndex]?.epdzComponentId;
  }

  get hasMoreAttachments() {
    return this.params.attachments && this.params.attachments.length > 1;
  }

  get currentAttachmentDefects(): ReceivedEpdzMessageDefectDto[] {
    if (this.currentFileComponentId) {
      return (this.params.message.defects ?? []).filter(
        d => (d.epdzComponentIds ?? []).includes(this.currentFileComponentId!)
      );
    }
    else {
      return [];
    }
  }

  get isMaximized() {
    return this.modalRef.isMaximized;
  }

  private _currentAttachmentIndex$ = new BehaviorSubject<number>(0);

  currentFile$: Observable<FileWithMetadataAndContent> = this._currentAttachmentIndex$.pipe(
    switchMap(currentAttachmentIndex => this.getFileContentsByAttachmentIndex(currentAttachmentIndex).pipe(
      map(fileContents => ({
        fileName: this.params.attachments?.[this.currentAttachmentIndex]?.name ?? '',
        mimeType: this.params.attachments?.[this.currentAttachmentIndex]?.mimeType ?? '',
        fileContents,
      }))
    )),
    share(),
  );

  currentFileIcon$ = this.currentFile$.pipe(
    map(currentFile => getComponentIconByFilenameSuffix(currentFile.fileName)),
  );

  currentFileName$ = this.currentFile$.pipe(
    map(currentFile => currentFile.fileName),
  );

  ngOnInit(): void {
    if (this.params.initialAttachmentIndex) {
      this.currentAttachmentIndex = this.params.initialAttachmentIndex;
    }
  }

  downloadCurrentFile() {
    this.getFileContentsByAttachmentIndex(this.currentAttachmentIndex).subscribe(fileContents => {
      const currentAttachment = this.params.attachments[this.currentAttachmentIndex]!;
      this.localBinaryFileDownloadService.downloadBlob(fileContents.buffer, currentAttachment.name!, currentAttachment.mimeType);
    });
  }

  printCurrentFile() {
    alert('todo implement');
  }

  toggleMaximize() {
    this.cdkDrag.reset();
    this.modalRef.toggleMaximize();
  }

  close() {
    this.modalRef.close(null);
  }

  goToPrevFile() {
    --this.currentAttachmentIndex;
  }

  goToNextFile() {
    ++this.currentAttachmentIndex;
  }

  getCurrentAttachmentIssueSummary(currentAttachmentDefect: ReceivedEpdzMessageDefectDto): string {
    return getDigitalMessageDefectSummaryTranslationKey(currentAttachmentDefect.defectType);
  }

  private getFileContentsByAttachmentIndex(attachmentIndex: number): Observable<BinaryWithCharset> {
    if (this.cachedFileContents.has(attachmentIndex)) {
      return of(this.cachedFileContents.get(attachmentIndex)!);
    }
    else {
      return this.loadingService.doLoading(
        this.remoteBinaryFileDownloadService.fetchEpdzAttachment({
          messageId: this.params.message.id!,
          attachmentId: this.params.attachments[this.currentAttachmentIndex]!.id!,
        }),
        this
      ).pipe(
        tap(binaryWithCharset => {
          if (!this.cachedFileContents.has(attachmentIndex)) {
            this.cachedFileContents.set(attachmentIndex, binaryWithCharset);
          }
        }),
      );
    }
  }

}
