import {NgModule, StaticProvider} from '@angular/core';
import {SavedFiltersComponent} from './table-toolbar/saved-filters/saved-filters.component';
import {TableSavedFiltersComponent} from './table-toolbar/table-saved-filters/table-saved-filters.component';
import {TablePaginatorComponent} from './table-paginator/table-paginator.component';
import {TablePreviewComponent} from './table-preview/table-preview.component';
import {FilterItemComponent} from './table-toolbar/filter-item/filter-item.component';
import {TableToolbarComponent} from './table-toolbar/table-toolbar.component';
import {ColumnTemplateDirective} from './column-template.directive';
import {TableComponent} from './table.component';
import {BasicFiltersComponent} from './table-toolbar/filter-item/basic-filters/basic-filters.component';
import {EnumFilterComponent} from './table-toolbar/filter-item/enum-filter/enum-filter.component';
import {CodebookFilterComponent} from './table-toolbar/filter-item/codebook-filter/codebook-filter.component';
import {PopupSelectorTableDialogComponent} from './popup-selector-table-dialog/popup-selector-table-dialog.component';
import {FilterTagComponent} from './table-toolbar/filter-tag/filter-tag.component';
import {FormFilterComponent} from './table-toolbar/form-filter/form-filter.component';
import {FormPopupTableSelectorComponent} from './form-popup-table-selector/form-popup-table-selector.component';
import {TreeTableComponent} from './tree-table/tree-table.component';
import {DEFAULT_FILTERS} from './table.providers';
import {FilterType} from './filter.types';
import {DateFilterValueFormatterService} from './table-toolbar/filter-item/date-filter-value-formatter.service';
import {LocalizedDatePipe, NumberToLocaleStringPipe} from '@icz/angular-essentials';
import {NumberFilterValueFormatterService} from './table-toolbar/filter-item/number-filter-value-formatter.service';
import {
  CodebookEnumFilterValueFormatterService
} from './table-toolbar/filter-item/codebook-enum-filter-value-formatter.service';
import {BooleanFilterValueFormatterService} from './table-toolbar/filter-item/boolean-filter-value-formatter.service';
import {
  PageSelectorFilterComponent
} from './table-toolbar/filter-item/page-selector-filter/page-selector-filter.component';
import {PageSelectorFilterFormatterService} from './table-toolbar/filter-item/page-selector-filter-formatter.service';

/**
 * @internal
 */
const TABLE_ELEMENTS = [
  TablePaginatorComponent,
  TablePreviewComponent,
  FilterItemComponent,
  TableToolbarComponent,
  ColumnTemplateDirective,
  TableComponent,
  BasicFiltersComponent,
  EnumFilterComponent,
  CodebookFilterComponent,
  PageSelectorFilterComponent,
  PopupSelectorTableDialogComponent,
  FilterTagComponent,
  FormFilterComponent,
  TableSavedFiltersComponent,
  SavedFiltersComponent,
  FormPopupTableSelectorComponent,
  TreeTableComponent,
];

/**
 * Provides default configuration of table filtering plugins
 */
export function withDefaultTableFilters(): StaticProvider {
  return {
    provide: DEFAULT_FILTERS,
    useValue: {
      [FilterType.TEXT]: {
        filterComponent: BasicFiltersComponent,
        filterValueFormatter: null, // use default toString
      },
      [FilterType.DATE]: {
        filterComponent: BasicFiltersComponent,
        filterValueFormatter: DateFilterValueFormatterService,
        filterValueFormatterDeps: [LocalizedDatePipe],
      },
      [FilterType.DATE_STATISTICS]: {
        filterComponent: BasicFiltersComponent,
        filterValueFormatter: DateFilterValueFormatterService,
        filterValueFormatterDeps: [LocalizedDatePipe],
      },
      [FilterType.DATETIME]: {
        filterComponent: BasicFiltersComponent,
        filterValueFormatter: DateFilterValueFormatterService,
        filterValueFormatterDeps: [LocalizedDatePipe],
      },
      [FilterType.NUMBER]: {
        filterComponent: BasicFiltersComponent,
        filterValueFormatter: NumberFilterValueFormatterService,
        filterValueFormatterDeps: [NumberToLocaleStringPipe],
      },
      [FilterType.ENUM]: {
        filterComponent: EnumFilterComponent,
        filterValueFormatter: CodebookEnumFilterValueFormatterService,
      },
      [FilterType.CODEBOOK]: {
        filterComponent: CodebookFilterComponent,
        filterValueFormatter: CodebookEnumFilterValueFormatterService,
      },
      [FilterType.PAGE_SELECTOR]: {
        filterComponent: PageSelectorFilterComponent,
        filterValueFormatter: PageSelectorFilterFormatterService,
      },
      [FilterType.BOOLEAN]: {
        filterComponent: BasicFiltersComponent,
        filterValueFormatter: BooleanFilterValueFormatterService,
      },
      [FilterType.FILE_SIZE]: {
        filterComponent: BasicFiltersComponent,
        filterValueFormatter: null, // use default toString
      },
      [FilterType.NONE]: null,
    },
  };
}

/**
 * The components, directives and pipes in this library are fully standalone. Import this NgModule
 * if you prefer using NgModules instead of standalone imports in your codebase.
 */
@NgModule({
  imports: TABLE_ELEMENTS,
  exports: TABLE_ELEMENTS,
  providers: [
    withDefaultTableFilters(),
  ]
})
export class TableModule {}
