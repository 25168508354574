/* eslint-disable @typescript-eslint/quotes */
import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, concatMap, map, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {
  CirculationActivityState,
  EntityType,
  RegistryOfficeTransferIncidentState,
  RelatedObjectDto,
  RelatedObjectType
} from '|api/commons';
import {
  ApiAuthorizationService,
  ApiEntitySharingService,
  AuthorizedEntityType,
  DocumentAuthorizedOperation
} from '|api/core';
import {
  ApiCrossReferenceService,
  ApiDocumentService,
  ApiFileService,
  ApiStorageUnitService,
  DocumentDto,
  FileDto
} from '|api/document';
import {ApiElasticsearchService} from '|api/elastic';
import {
  DocumentDtoWithAuthorization,
  DocumentOrProfileDtoWithAuthorization
} from '../components/shared-business-components/model/dto-with-permissions.interface';
import {
  getInsufficientPermissionsTooltip,
  isAuthorizedOperationGranted
} from '../components/shared-business-components/permissions/permissions.utils';
import {FilterOperator, getDefaultSearchParams} from '@icz/angular-table';
import {
  ExtendedCirculationActivityDto
} from '../components/shared-business-components/model/elastic-extended-entities.interface';
import {SKIP_ERROR_DIALOG} from '../core/error-handling/http-errors';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DocumentFileDetailService, ObjectDetailLoadType} from './abstract-object-detail.service';
import {TabItemWithPriority, TabPriority} from '@icz/angular-essentials';
import {ObjectCounts} from '../lib/object-counts';
import {DocumentDetailRoute} from '../enums/documents-routes.enum';
import {isOwnDocumentObject, isUnopenedDocument} from '../components/shared-business-components/shared-document.utils';
import {ApiOwnConsignmentService} from '|api/sad';
import {ComponentSearchService} from './component-search.service';
import {HistoryService} from './history.service';
import {TranslateService} from '@ngx-translate/core';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {IncidentDto} from '|api/incident-log';
import {IczOption} from '@icz/angular-form-elements';
import {createAbsoluteRoute} from '../core/routing/routing.helpers';
import {ApplicationRoute} from '../enums/shared-routes.enum';

interface DocumentTypeOptionData {
  disposalScheduleId?: Nullable<number>;
  entityClassId?: Nullable<number>;
  statutoryPeriod?: Nullable<number>;
}

export type DocumentTypeOption = IczOption<Nullable<number>, DocumentTypeOptionData>;

interface EntityClassOptionData {
  disposalScheduleId?: Nullable<number>;
  classificationSchemeId?: Nullable<number>;
}

export type EntityClassOption = IczOption<number, EntityClassOptionData>;


export enum DocumentDetailCountType {
  CONSIGNMENTS = 'consignments',
  COMPONENTS = 'components',
  SUBJECTS = 'subjects',
  RELATED = 'related',
  ACTIVITIES = 'activities',
  SHARE = 'share',
  INCIDENTS = 'incidents',
}

@Injectable()
export class DocumentDetailService extends DocumentFileDetailService<DocumentOrProfileDtoWithAuthorization, DocumentDetailCountType> {

  private componentSearchService = inject(ComponentSearchService);
  private apiDocumentService = inject(ApiDocumentService);
  private apiEntitySharingService = inject(ApiEntitySharingService);
  private apiFileService = inject(ApiFileService);
  private apiStorageUnitService = inject(ApiStorageUnitService);
  private apiAuthorizationService = inject(ApiAuthorizationService);
  private apiCrossReferenceService = inject(ApiCrossReferenceService);
  private apiElasticsearchService = inject(ApiElasticsearchService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private historyService = inject(HistoryService);
  private translateService = inject(TranslateService);

  protected activitiesAuthorizedOperation = DocumentAuthorizedOperation.DOCUMENT_SHOW_ACTIVITIES;
  protected objectReferenceIdFieldName = 'documentId' as const;

  override counts = new ObjectCounts<DocumentDetailCountType>({
    [DocumentDetailCountType.CONSIGNMENTS]: documentId => this.object$.pipe(
      take(1),
      switchMap(_ => this.apiOwnConsignmentService.ownConsignmentCountOwnConsignmentsForDocument({
        id: documentId
      }).pipe(
        map(response => response.count),
      )),
    ),
    [DocumentDetailCountType.COMPONENTS]: documentId => this.object$.pipe(
      take(1),
      switchMap(_ => this.componentSearchService.countDocumentComponents(documentId)),
    ),
    [DocumentDetailCountType.SUBJECTS]: documentId => this.object$.pipe(
      take(1),
      switchMap(_ => {
        const related: RelatedObjectDto = {
          relatedObjectId: documentId,
          relatedObjectType: RelatedObjectType.DOCUMENT
        };

        return this.apiSubjectRecordNgElasticService.subjectRecordElasticGetRelatedSubjectCount({body: related});
      })
    ),
    [DocumentDetailCountType.RELATED]: documentId => this.object$.pipe(
      take(1),
      switchMap(_ => {
        return this.apiCrossReferenceService.crossReferenceGetCount({entityId: documentId, entityType: EntityType.DOCUMENT});
      })
    ),
    [DocumentDetailCountType.ACTIVITIES]: documentId => this.object$.pipe(
      take(1),
      switchMap(_ => {
        return this.apiElasticsearchService.elasticsearchGetDocumentActivityCount({documentId});
      }),
    ),
    [DocumentDetailCountType.SHARE]: documentId => this.object$.pipe(
      take(1),
      switchMap(_ => this.apiEntitySharingService.entitySharingCountSharingStatusForDocument({id: documentId})),
    ),
    [DocumentDetailCountType.INCIDENTS]: documentId => this.object$.pipe(
      take(1),
      switchMap(_ => this.apiElasticsearchService.elasticsearchCountRegistryOfficeTransferIncidentsForEntity({
        entityType: EntityType.DOCUMENT,
        entityId: documentId,
        urlPredicates: {'state': RegistryOfficeTransferIncidentState.IN_PROGRESS} as Partial<Record<keyof IncidentDto, string>>,
      })),
    ),
  });

  override object$: Observable<Nullable<DocumentOrProfileDtoWithAuthorization>> = this.loadTypeWithAuthorization$.pipe(
    concatMap(loadTypeWithAuthorization => {
      const loadType = loadTypeWithAuthorization.loadType;
      const authorization = loadTypeWithAuthorization.authorization;
      let document$: Nullable<Observable<DocumentOrProfileDtoWithAuthorization>>;

      if (loadType === ObjectDetailLoadType.FULL_LOAD || loadType === ObjectDetailLoadType.RELOAD_OBJECT) {
        if (isAuthorizedOperationGranted(authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_PROFILE)) {
          document$ = this.apiDocumentService.documentFindById({
            id: authorization!.authorizedEntityId,
          });
        }
        else {
          document$ = this.apiDocumentService.documentReadDocumentProfile({
            documentId: authorization!.authorizedEntityId,
          }).pipe(
            catchError(_ => {
              this.historyService.routeBack(createAbsoluteRoute(ApplicationRoute.DOCUMENTS));
              return of(this.object);
            }),
          );
        }
      }
      else {
        document$ = of(this.object as DocumentOrProfileDtoWithAuthorization);
      }

      return this.loadingService.doLoading(document$, this).pipe(
        map(document => ({...document, authorization})),
      );
    }),
    shareReplay(1)
  );

  override objectRepresentingSubject$ = this.object$.pipe(
    switchMap(d => {
      const related: RelatedObjectDto = {relatedObjectId: d!.id, relatedObjectType: RelatedObjectType.DOCUMENT};
      return this.apiSubjectRecordNgElasticService.subjectRecordElasticGetRepresentingSubject({body: related});
    }),
    shareReplay(1)
  );

  override tabs$: Observable<TabItemWithPriority[]> = combineLatest([
    this.object$,
    this.counts.countsChanged$,
  ]).pipe(
    map(([object, counts]) => {
      const canUserShowOwnConsignments = isAuthorizedOperationGranted(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_OWN_CONSIGNMENTS);
      const canUserShowComponents = isAuthorizedOperationGranted(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_COMPONENTS);
      const canUserShowSubjects = isAuthorizedOperationGranted(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_SUBJECTS);
      const canUserShowRelatedObjects = isAuthorizedOperationGranted(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_RELATED_OBJECTS);
      const canUserShowHistory = isAuthorizedOperationGranted(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_HISTORY);
      const canUserShowActivities = isAuthorizedOperationGranted(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_ACTIVITIES);
      const canUserShowSharing = isAuthorizedOperationGranted(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_SHARING);

      return [
        {
          id: DocumentDetailRoute.PREVIEW,
          label: 'Přehled',
          showCount: false,
          priority: TabPriority.HIGHEST,
        },
        {
          id: DocumentDetailRoute.DELIVERY,
          label: 'Doručení',
          isHidden: isOwnDocumentObject(object),
          priority: TabPriority.HIGHEST,
        },
        {
          id: DocumentDetailRoute.OWN_CONSIGNMENTS,
          label: 'Vypravení',
          isHidden: !isOwnDocumentObject(object),
          showCount: canUserShowOwnConsignments,
          count: counts[DocumentDetailCountType.CONSIGNMENTS],
          priority: TabPriority.HIGHEST,
          disabled: !canUserShowOwnConsignments,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_OWN_CONSIGNMENTS, this.translateService)?.tooltip
        },
        {
          id: DocumentDetailRoute.OFFICE_DESK_CONSIGNMENTS,
          label: 'Vyvěšení',
          isHidden: isOwnDocumentObject(object) || isUnopenedDocument(object),
          showCount: canUserShowOwnConsignments,
          count: counts[DocumentDetailCountType.CONSIGNMENTS],
          priority: TabPriority.HIGHEST,
          disabled: !canUserShowOwnConsignments,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_OWN_CONSIGNMENTS, this.translateService)?.tooltip
        },
        {
          id: DocumentDetailRoute.COMPONENTS,
          label: 'Komponenty',
          priority: TabPriority.HIGHEST,
          isHidden: isUnopenedDocument(object),
          showCount: canUserShowComponents,
          count: counts[DocumentDetailCountType.COMPONENTS],
          disabled: !canUserShowComponents,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_COMPONENTS, this.translateService)?.tooltip
        },
        {
          id: DocumentDetailRoute.SUBJECTS,
          label: 'Subjekty',
          priority: TabPriority.HIGHEST,
          showCount: canUserShowSubjects,
          count: counts[DocumentDetailCountType.SUBJECTS],
          disabled: !canUserShowSubjects,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_SUBJECTS, this.translateService)?.tooltip
        },
        {
          id: DocumentDetailRoute.RELATED_OBJECTS,
          label: 'Související',
          priority: TabPriority.MEDIUM,
          isHidden: isUnopenedDocument(object),
          showCount: canUserShowRelatedObjects,
          count: counts[DocumentDetailCountType.RELATED],
          disabled: !canUserShowRelatedObjects,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_RELATED_OBJECTS, this.translateService)?.tooltip
        },
        {
          id: DocumentDetailRoute.HISTORY,
          label: 'Historie',
          priority: TabPriority.MEDIUM,
          disabled: !canUserShowHistory,

          tooltip: getInsufficientPermissionsTooltip(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_HISTORY, this.translateService)?.tooltip
        },
        {
          id: DocumentDetailRoute.ACTIVITIES,
          label: 'Úkony',
          priority: TabPriority.HIGHEST,
          showCount: canUserShowActivities,
          count: counts[DocumentDetailCountType.ACTIVITIES],
          disabled: !canUserShowActivities,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_ACTIVITIES, this.translateService)?.tooltip
        },
        {
          id: DocumentDetailRoute.SHARE,
          label: 'Sdíleno',
          priority: TabPriority.HIGHEST,
          showCount: canUserShowSharing,
          count: counts[DocumentDetailCountType.SHARE],
          disabled: !canUserShowSharing,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, DocumentAuthorizedOperation.DOCUMENT_SHOW_SHARING, this.translateService)?.tooltip
        },
        {
          id: DocumentDetailRoute.INCIDENTS,
          label: 'Chyby při předání',
          priority: TabPriority.MEDIUM,
          isHidden: !(object as DocumentDtoWithAuthorization)!.registryOfficeTransfer?.registryOfficeTransferState,
          icon: counts[DocumentDetailRoute.INCIDENTS] ? 'error' : null,
          showCount: true,
          count: counts[DocumentDetailRoute.INCIDENTS],
        },
      ];
    })
  );

  fileForDocument: Nullable<FileDto>;

  constructor() {
    super();

    super.initialize();

    this.parentFileRunningActivity$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(activity => this.parentRunningActivity = activity[0]);
  }

  parentFileRunningActivity$ = this.object$.pipe(
    switchMap( document => {
      const fileId = (document as DocumentDto).fileId;
      if (fileId) {
        const searchParams = getDefaultSearchParams();
        searchParams.size = 1;
        searchParams.filter = [
          {
            fieldName: 'fileId',
            operator: FilterOperator.equals,
            value: String(fileId),
          },
          {
            fieldName: 'circulationEntityType',
            operator: FilterOperator.equals,
            value: EntityType.FILE,
          },
          {
            fieldName: 'activityState',
            operator: FilterOperator.equals,
            value: CirculationActivityState.IN_PROGRESS,
          },
        ];
        return this.circulationSearchService.findActivitiesGlobally(searchParams).pipe(
          map(page => page.content)
        );
      } else {
        return of([]);
      }
    })
  );

  parentRunningActivity!: ExtendedCirculationActivityDto;

  getFileForDocument(document: DocumentDto) {
    if (!document.fileId) return null;

    return this.apiFileService.fileFindById({id: document.fileId}, SKIP_ERROR_DIALOG).pipe(
      tap(file => this.fileForDocument = file),
    );
  }

  getStorageUnit(document: DocumentDto) {
    if (!document.storageUnitId) return null;

    return this.apiStorageUnitService.storageUnitFindById({id: document.storageUnitId}, SKIP_ERROR_DIALOG);
  }

  protected loadAuthorizationForTabsAndPreview(objectId: number) {
    return this.apiAuthorizationService.authorizationAuthorizeDocumentOperations({
      body: {
        authorizedEntityType: AuthorizedEntityType.DOCUMENT,
        authorizedEntityId: objectId,
        operationsToAuthorize: [
          DocumentAuthorizedOperation.DOCUMENT_SHOW_OWN_CONSIGNMENTS,
          DocumentAuthorizedOperation.DOCUMENT_SHOW_COMPONENTS,
          DocumentAuthorizedOperation.DOCUMENT_SHOW_SUBJECTS,
          DocumentAuthorizedOperation.DOCUMENT_SHOW_RELATED_OBJECTS,
          DocumentAuthorizedOperation.DOCUMENT_SHOW_PROFILE,
          DocumentAuthorizedOperation.DOCUMENT_MODIFY_PROFILE,
          DocumentAuthorizedOperation.DOCUMENT_SHOW_HISTORY,
          DocumentAuthorizedOperation.DOCUMENT_SHOW_ACTIVITIES,
          DocumentAuthorizedOperation.DOCUMENT_SHOW_SHARING,
        ]
      }
    });
  }

}
