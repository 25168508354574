import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

import {InterpolationContext} from '../essentials.utils';
import {TranslateModule} from '@ngx-translate/core';
import {InterpolatePipe} from '../interpolate.pipe';

/**
 * A simple component representing a plain text element with
 *   built-in translation and interpolation functionality.
 */
@Component({
  selector: 'icz-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, InterpolatePipe],
})
export class LabelComponent {

  /**
   * Text contents of the label. Can contain mustache-like expression.
   */
  @Input({required: true})
  label: Nullable<string>;
  /**
   * Text interpolation context.
   *
   * Interpolation is applied after label translation, i.e. translate labels including
   * mustache-like expression if you want to support i18n.
   */
  @Input()
  labelContext: Nullable<InterpolationContext>;
  /**
   * If TRUE, label contents will wrap when needed.
   */
  @Input()
  hasNormalWhitespace = false;

  /**
   * If TRUE, label contents will not get translated.
   */
  @Input() set dontTranslate(value: boolean | '') { this._dontTranslate = value === '' ? true : value; }
  get dontTranslate() { return this._dontTranslate; }
  private _dontTranslate = false;

}
