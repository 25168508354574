import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormArray, IczFormGroup, IczValidatorFn, ValidationErrorMessage} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {injectModalRef} from '@icz/angular-modal';
import {fileUploadFormArray} from '../../../file-upload-list/file-upload-list.component';
import {AbstractControl} from '@angular/forms';
import {ComponentUploadStatus} from '../../../essl-components/essl-components-utils';
import {ApiDigitalComponentVersionService, DigitalComponentVersionCompleteDto} from '|api/component';
import {ApiAgendaTransferService} from '|api/document';
import {esslErrorDtoToToastParameters} from '../../../../notifications/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {
  DocumentToastService,
  DocumentToastType
} from '../../../../../core/services/notifications/document-toast.service';

export class ConfirmationFileUploadValidators {
  @ValidationErrorMessage('Je nutné vybrat aspoň jeden soubor s potvrzením.')
  static filesSelected(): IczValidatorFn {
    return (control: AbstractControl) => {
      if ((control as IczFormArray).length > 0) {
        return null;
      }
      else {
        return {
          fileSelected: false,
        };
      }
    };
  }
}

@Component({
  selector: 'icz-confirm-agenda-transfer-dialog',
  templateUrl: './confirm-agenda-transfer-dialog.component.html',
  styleUrls: ['./confirm-agenda-transfer-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmAgendaTransferDialogComponent implements OnInit, IFormGroupCheckable {
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  protected apiAgendaTransferService = inject(ApiAgendaTransferService);
  private translateService = inject(TranslateService);
  private documentToastService = inject(DocumentToastService);

  formGroupsToCheck!: string[];
  form = new IczFormGroup({
    confirmFiles: fileUploadFormArray([
      ConfirmationFileUploadValidators.filesSelected(),
    ]),
  });

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  get filesControl() {
    return this.form.get('confirmFiles') as IczFormArray;
  }

  filesChanged() {
    this.filesControl.controls.forEach(control => {
      const uploadBlob = control.get('file')!.value;
      const statusControl = control.get('status');
      const blockUploadStatus = [
        ComponentUploadStatus.UPLOADING,
        ComponentUploadStatus.ERROR,
        ComponentUploadStatus.INVALID_EXTENSION,
        ComponentUploadStatus.SUCCESS,
      ];
      if (blockUploadStatus.includes(statusControl!.value)) return;

      statusControl!.setValue(ComponentUploadStatus.UPLOADING);
      const uploadExceptionsCtrl = control.get('uploadExceptions')!;
      this.apiDigitalComponentVersionService.digitalComponentVersionUploadNewFile({
        body: {
          file: uploadBlob,
        }
      }).subscribe({
        next: (componentVersion: DigitalComponentVersionCompleteDto) => {
          control.get('digitalComponentVersionId')!.setValue(componentVersion.id);
          statusControl!.setValue(ComponentUploadStatus.SUCCESS);
        },
        error: err => {
          statusControl!.setValue(ComponentUploadStatus.ERROR);
          uploadExceptionsCtrl.setValue(err.error);
        }
      });
    });
  }

  isAllUploaded() {
    let isAllUploaded = true;
    if (this.filesControl.controls.length > 0) {
      this.filesControl.controls.forEach(control => {
        const fileGroupValue = (control as IczFormGroup).getRawValue();
        const invalidStates = [
          ComponentUploadStatus.IDLE,
          ComponentUploadStatus.ERROR,
          ComponentUploadStatus.INVALID_EXTENSION,
          ComponentUploadStatus.UPLOADING
        ];
        if (invalidStates.includes(fileGroupValue.status)) {
          isAllUploaded = false;
        }
      });
    }
    return isAllUploaded;
  }

  submit() {
   const fileForm = (this.form.get('confirmFiles')! as IczFormArray).controls[0];
    this.loadingService.doLoading(
      this.apiAgendaTransferService.agendaTransferConfirmTransfer({
        digitalComponentVersionId: fileForm.get('digitalComponentVersionId')!.value,
      }),
      this
    ).subscribe({
      next: _ => {
        this.documentToastService.dispatchSimpleInfoToast(DocumentToastType.AGENDA_TRANSFER_CONFIRMED);
        this.cancel();
      },
      error: err => {
        const errorData = esslErrorDtoToToastParameters(this.translateService, err.error);
        this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.AGENDA_TRANSFER_CONFIRM_ERROR, errorData);
      }
    });
  }

  cancel() {
    this.modalRef.close(null);
  }
}
