import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {CirculationActivityType, CirculationTaskState} from '|api/commons';
import {ApiCirculationTaskService, CirculationTaskDto} from '|api/flow';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {ExtendedCirculationActivityDto} from '../../../model/elastic-extended-entities.interface';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


interface TaskActivityNote {
  taskState: CirculationTaskState;
  functionalPositionId: number;
  userId: number;
  message: string;
}


@Component({
  selector: 'icz-activity-notes',
  templateUrl: './activity-notes.component.html',
  styleUrls: ['./activity-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityNotesComponent {

  protected loadingService = inject(LoadingIndicatorService);
  protected apiCirculationTaskService = inject(ApiCirculationTaskService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  activity!: ExtendedCirculationActivityDto;

  currentActivityNotes: Nullable<TaskActivityNote[]> = null;

  isIssdCirculationActivity = false;

  isNotesPopupOpen = false;

  getUserAcronym(userFullName: string) {
    if (!userFullName) return '';
    else return userFullName.split(/\s/).reduce((response, word) => `${response}${word.slice(0, 1)}`, '');
  }

  fetchActivityNotes(row: ExtendedCirculationActivityDto) {
    let task$: Observable<CirculationTaskDto[]>;

    if (this.isRejectedWithNotes(row)) {
      const rejectedTask$ = (row.rejectedTaskIds ?? []).map(
        taskId => this.apiCirculationTaskService.circulationTaskFindById({id: taskId})
      );

      task$ = combineLatest(rejectedTask$);
    }
    else {
      task$ = this.apiCirculationTaskService.circulationTaskFindByActivityId({
        activityId: row.id,
      });
    }

    this.loadingService.doLoading(
      task$,
      this.apiCirculationTaskService,
      row.id,
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(activityTasks => {
      const nonManagementActivityTasks = activityTasks.filter(t => !t.taskType.includes('MANAGEMENT'));
      this.currentActivityNotes = [];

      for (const task of nonManagementActivityTasks) {
        if (task.taskState === CirculationTaskState.REJECTED_BY_TARGET) {
          this.currentActivityNotes.push({
            taskState: task.taskState,
            functionalPositionId: task.rejectionFunctionalPositionId!,
            userId: task.rejectionUserId!,
            message: task.rejectionReason!,
          });
        }
        else if (
          row.activityType === CirculationActivityType.DOCUMENT_STATEMENT &&
          task.taskState.includes('DONE')
        ) {
          this.currentActivityNotes.push({
            taskState: task.taskState,
            functionalPositionId: task.settlementFunctionalPositionId!,
            userId: task.settlementUserId!,
            message: task.settlementNote!,
          });
        } else if (
          row.activityType === CirculationActivityType.DOCUMENT_ISSD_HANDOVER || row.activityType === CirculationActivityType.FILE_ISSD_HANDOVER
        ) {
          this.isIssdCirculationActivity = true;
          this.currentActivityNotes.push({
            taskState: task.taskState,
            functionalPositionId: task.ownerFunctionalPositionId!,
            userId: row.initUserId!,
            message: task.instruction!,
          });
        }
      }

      this.isNotesPopupOpen = true;
    });
  }

  activityNotesClosed() {
    this.isNotesPopupOpen = false;
    this.currentActivityNotes = null;
  }

  isNotesFieldVisible(row: ExtendedCirculationActivityDto) {
    return this.isRejectedWithNotes(row) || this.hasStatements(row);
  }

  getNotesFieldText(row: ExtendedCirculationActivityDto) {
    if (this.isRejectedWithNotes(row)) {
      return 'Důvody odmítnutí';
    }
    else if (this.hasStatements(row)) {
      return 'Poznámky k vyjádření';
    }
    else {
      return 'Poznámky';
    }
  }

  private isRejectedWithNotes(row: ExtendedCirculationActivityDto) {
    return (row?.rejectedTaskIds ?? []).length !== 0;
  }

  private hasStatements(row: ExtendedCirculationActivityDto) {
    return (
      row.activityType === CirculationActivityType.DOCUMENT_STATEMENT ||
      row.activityType === CirculationActivityType.FILE_ISSD_HANDOVER ||
      row.activityType === CirculationActivityType.DOCUMENT_ISSD_HANDOVER) && row.hasSettlementNotes;
  }
}
