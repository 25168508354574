import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {SubjectRecordClassification} from '|api/commons';
import {SubjectsService,} from '../../../../services/subjects.service';
import {IczDateValidators, IczFormGroup} from '@icz/angular-form-elements';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SubjectAttributeType} from '../../model/subject-attribute-type';
import {getSearchSubjectFormGroup, SubjectSearchFormData} from './subject-search-form.model';
import {SearchOnlyAttribute, SubjectIdentifierType} from '../subject-search.model';

@Component({
  selector: 'icz-subject-search-form',
  templateUrl: './subject-search-form.component.html',
  styleUrls: ['./subject-search-form.component.scss'],
  providers: [SubjectsService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectSearchFormComponent implements OnInit {

  private changeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private subjectsService = inject(SubjectsService);

  @Input({required: true}) form = getSearchSubjectFormGroup();
  @Input() singleColumnDisplay = false;
  @Input() showSearchAppendix = false;
  @Input() showAddressInvalidAlert = false;
  @Input() wrapInSections = true;
  @Output() searchByKeys =  new EventEmitter<void>();
  @Output() blockingAction = new EventEmitter<boolean>();
  @Input() set expanded(expanded) {
    this._expanded = expanded;
    this.changeDetectorRef.detectChanges();
  }

  get expanded() {
    return this._expanded;
  }

  searchCriteriaValue: SubjectSearchFormData = {};

  private _expanded = true;

  readonly birthDateValidator = IczDateValidators.birthDateValidator;
  readonly SubjectAttributeType = SubjectAttributeType;
  readonly SearchOnlyAttribute = SearchOnlyAttribute;

  get classification() {
    return this.form?.get('classification')!.value as SubjectRecordClassification;
  }

  get addressForm(): IczFormGroup {
    return this.form.get(SearchOnlyAttribute.ADDRESS) as IczFormGroup;
  }

  emitSearch() {
    this.searchByKeys.emit();
  }

  get isFormEmpty() {
    return this.subjectsService.isSearchFormEmpty(this.form);
  }

  onInputFieldKeydown($event: KeyboardEvent): void {
    const keyPressed = $event.key;

    if (keyPressed === 'Enter') {
      $event.stopPropagation();
      this.emitSearchIfFormNonEmpty();
    }
  }

  ngOnInit() {
    this.form.get('identityType')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(selectedQuickKey => {
      Object.values(SubjectIdentifierType).forEach(quickKey => {
        if (selectedQuickKey !== quickKey) {
          this.form.get(quickKey as unknown as SubjectAttributeType)!.setValue(null, {emitEvent: false});
        }
      });
    });
    this.form.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.searchCriteriaValue = this.form.getRawValue();
    });
  }

  emitSearchIfFormNonEmpty() {
    if (!this.isFormEmpty) this.emitSearch();
  }

}
