import {DestroyRef, inject, Injectable} from '@angular/core';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {
  ApiRegistryOfficeTransferService,
  DocumentDto,
  FileDto,
  RegistryOfficeTransferIncidentDto,
  StorageUnitDto
} from '|api/document';
import {map, Observable, of} from 'rxjs';
import {Button} from '../../../button-collection/button-collection.component';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {
  BulkOperationValidationService,
  OperationValidator
} from '../../../../services/bulk-operation-validation.service';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {AbstractDocumentDialogsManagerService} from '../../../../services/abstract-document-dialogs-manager.service';
import {TranslateService} from '@ngx-translate/core';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {ApplicationConfigService} from '../../../../core/services/config/application-config.service';
import {GeneralAuthorizedOperation} from '../../permissions/permissions.utils';
import {
  getObjectIcon,
  getObjectLabel,
  getStorageUnitLabel,
  isDocumentEntity,
  isFileEntity,
  isStorageUnitObject
} from '../../shared-document.utils';
import {AuthorizedEntityType} from '|api/core';
import {RegistryOfficeTransferView} from './toolbar-common.utils';
import {
  ExtendedRegistryOfficeTransferActivityDto,
  isTransferedElasticObjectFragment
} from '../../model/elastic-extended-entities.interface';
import {CommonToolbarValidators} from './toolbar-common.validators';
import {RegistryOfficeTransferValidators} from './registry-office-transfer.validators';
import {RegistryOfficeTransferDisablers} from './registry-office-transfer.disablers';
import {
  WorkflowTextResponseDialogComponent,
  WorkflowTextResponseDialogParams,
  WorkflowTextResponseDialogResult
} from '../components/workflow-text-response-dialog/workflow-text-response-dialog.component';
import {DialogService, IczModalService} from '@icz/angular-modal';
import {SKIP_ERROR_DIALOG} from '../../../../core/error-handling/http-errors';
import {tap} from 'rxjs/operators';
import {
  RegistryOfficeToastService,
  RegistryOfficeToastType
} from '../../../../core/services/notifications/registry-office-toast.service';
import {ApiElasticsearchService, SearchRecordSourceRegistryOfficeTransferActivityDto} from '|api/elastic';
import {EntityType, ObjectClass, RegistryOfficeTransferIncidentState, RegistryOfficeTransferState} from '|api/commons';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from '../../../../core/services/notifications/internal-notification.enum';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  RegistryOfficeIncidentResolutionDialogComponent,
  RegistryOfficeIncidentResolutionDialogData
} from '../../registry-office-transfer-incidents-table/registry-office-transfer-incident-toolbar-buttons/registry-office-incident-resolution-dialog/registry-office-incident-resolution-dialog.component';
import {castStream} from '../../../../lib/rxjs';
import {esslErrorDtoToToastParameters} from '../../../notifications/toast.service';

export enum TransferableObjectView {
  DOCUMENT_DETAIL = 'DOCUMENT_DETAIL',
  FILE_DETAIL = 'FILE_DETAIL',
  ENTITY_TRANSFER_IN_PROGRESS = 'ENTITY_TRANSFER_IN_PROGRESS',
  STORAGE_UNIT_DETAIL = 'STORAGE_UNIT_DETAIL',
  SETTLED_OBJECTS_LISTING = 'SETTLED_OBJECTS_LISTING',
  STORAGE_UNITS_TABLE = 'STORAGE_UNITS_TABLE',
}

export enum TransferableObjectToolbarAction {
  TRANSFER_HANDOVER = 'TRANSFER_HANDOVER',
  TRANSFER_TAKEOVER = 'TRANSFER_TAKEOVER',
  TRANSFER_REJECT = 'TRANSFER_REJECT',
  TRANSFER_CANCEL = 'TRANSFER_CANCEL',
  TRANSFER_RETRANSFER = 'TRANSFER_RETRANSFER',
  TRANSFER_RETURN_TO_OFFICE = 'TRANSFER_RETURN_TO_OFFICE',
}

enum RetransferMode {
  RETRANSFER_TO_REGISTRY_OFFICE = 'RETRANSFER_TO_REGISTRY_OFFICE',
  RETRANSFER_TO_DESA = 'RETRANSFER_TO_DESA',
}

export type TransferableObject = DocumentDto | FileDto | StorageUnitDto;

@Injectable()
export class TransferableObjectToolbarButtonsService extends ToolbarProvider<TransferableObject | ExtendedRegistryOfficeTransferActivityDto | SearchRecordSourceRegistryOfficeTransferActivityDto, TransferableObjectToolbarAction, TransferableObjectView | RegistryOfficeTransferView> {

  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private globalLoading = inject(GlobalLoadingIndicatorService);
  private documentDialogService = inject(AbstractDocumentDialogsManagerService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private translateService = inject(TranslateService);
  private applicationConfigService = inject(ApplicationConfigService);
  private apiRegistryOfficeTransferService = inject(ApiRegistryOfficeTransferService);
  private apiElasticsearchService = inject(ApiElasticsearchService);
  private modalService = inject(IczModalService);
  private destroyRef = inject(DestroyRef);
  private dialogService = inject(DialogService);
  private registryOfficeToastService = inject(RegistryOfficeToastService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private wsNotificationService = inject(WebSocketNotificationsService);

  constructor() {
    super();

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_RO_TRANSFER_CREATED_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TransferableObjectToolbarAction.TRANSFER_HANDOVER);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_RO_TRANSFER_TAKEN_OVER_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TransferableObjectToolbarAction.TRANSFER_TAKEOVER);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_RO_TRANSFER_REJECT_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TransferableObjectToolbarAction.TRANSFER_REJECT);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_RO_TRANSFER_CANCEL_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TransferableObjectToolbarAction.TRANSFER_CANCEL);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_RO_TRANSFER_RETRANSFER_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TransferableObjectToolbarAction.TRANSFER_RETRANSFER);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TransferableObjectToolbarAction.TRANSFER_RETURN_TO_OFFICE);
      }
    );
  }

  override getToolbarButtons(
    selection: Nullable<(TransferableObject | SearchRecordSourceRegistryOfficeTransferActivityDto | ExtendedRegistryOfficeTransferActivityDto)[]>,
    viewType: TransferableObjectView | RegistryOfficeTransferView,
  ): Observable<Button[]> {
    let buttons$: Observable<AuthorizedButton[]>;

    if (viewType === RegistryOfficeTransferView.ENTITY_TRANSFER_IN_PROGRESS) {
      const detailEntity = selection![0] as SearchRecordSourceRegistryOfficeTransferActivityDto;

      if (detailEntity.registryOfficeTransferState === RegistryOfficeTransferState.HANDED_OVER) {
        viewType = RegistryOfficeTransferView.REGISTRY_OFFICE_TO_TAKE_OVER;
      }
      else if (detailEntity.registryOfficeTransferState === RegistryOfficeTransferState.TO_RESOLVE_ISSUES_BY_REGISTRY_OFFICE) {
        viewType = RegistryOfficeTransferView.REGISTRY_OFFICE_TO_RESOLVE_ISSUES;
      }
      else if (
        detailEntity.registryOfficeTransferState === RegistryOfficeTransferState.TO_RESOLVE_ISSUES_BY_OWNER ||
        detailEntity.registryOfficeTransferState === RegistryOfficeTransferState.TO_RESOLVE_ISSUES
      ) {
        viewType = RegistryOfficeTransferView.OFFICER_TO_RESOLVE_ISSUES;
      }
    }

    if (viewType === RegistryOfficeTransferView.REGISTRY_OFFICE_TO_TAKE_OVER) {
      buttons$ = of([
        this.getTakeOverButton(selection as SearchRecordSourceRegistryOfficeTransferActivityDto[]),
        this.getRejectButton(selection as SearchRecordSourceRegistryOfficeTransferActivityDto[]),
      ] as AuthorizedButton[]);
    }
    else if (viewType === RegistryOfficeTransferView.OFFICER_TO_RESOLVE_ISSUES) {
      buttons$ = of([
        this.getRetransferButton(selection as SearchRecordSourceRegistryOfficeTransferActivityDto[], RetransferMode.RETRANSFER_TO_REGISTRY_OFFICE),
        this.getCancelTransferButton(selection as SearchRecordSourceRegistryOfficeTransferActivityDto[]),
      ] as AuthorizedButton[]);
    }
    else if (viewType === RegistryOfficeTransferView.REGISTRY_OFFICE_TO_RESOLVE_ISSUES) {
      buttons$ = of([
        this.getRetransferButton(selection as SearchRecordSourceRegistryOfficeTransferActivityDto[], RetransferMode.RETRANSFER_TO_DESA),
        this.getReturnToOfficerButton(selection as SearchRecordSourceRegistryOfficeTransferActivityDto[]),
      ] as AuthorizedButton[]);
    }
    else {
      buttons$ = of([
        {
          label: 'Ukládání',
          icon: 'storage_unit_add',
          submenuItems: [
            this.getTransferToRegistryOfficeButton(selection as TransferableObject[]),
          ]
        }
      ] as AuthorizedButton[]);
    }

    return buttons$.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
  }

  private getTakeOverButton(selection: Array<SearchRecordSourceRegistryOfficeTransferActivityDto>): AuthorizedButton {
    return {
      label: 'Převzít',
      icon: 'take_over',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
      ],
      action: () => this.onTakeoverByRegistryOfficeClick(selection),
    };
  }

  private getRejectButton(selection: Array<SearchRecordSourceRegistryOfficeTransferActivityDto>): AuthorizedButton {
    return {
      label: 'Odmítnout',
      icon: 'send_back',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
      ],
      action: () => this.onRejectByRegistryOfficeClick(selection),
    };
  }

  private getRetransferButton(selection: Array<SearchRecordSourceRegistryOfficeTransferActivityDto>, retransferMode: RetransferMode): AuthorizedButton {
    return {
      label: 'Předat spisovně',
      icon: 'back_to_step_before',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
      ],
      action: () => this.onRetransferClick(selection, retransferMode),
    };
  }

  private getCancelTransferButton(selection: Array<SearchRecordSourceRegistryOfficeTransferActivityDto>): AuthorizedButton {
    return {
      label: 'Stornovat ukládání',
      icon: 'closed',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        RegistryOfficeTransferDisablers.isTransferActivityReturnedFromDesa(selection),
      ],
      action: () => this.onCancelTransferClick(selection),
    };
  }

  private getReturnToOfficerButton(selection: Array<SearchRecordSourceRegistryOfficeTransferActivityDto>): AuthorizedButton {
    return {
      label: 'Vrátit referentovi',
      icon: 'send_back',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
      ],
      action: () => this.onReturnToOfficerClick(selection),
    };
  }

  private getTransferToRegistryOfficeButton(selection: Array<TransferableObject>): AuthorizedButton {
    return {
      label: 'Předat spisovně',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
        RegistryOfficeTransferDisablers.isEntityInRegistryOffice(selection),
        RegistryOfficeTransferDisablers.isEntityIncomplete(selection),
        RegistryOfficeTransferDisablers.isEntityAnalogOrHybrid(selection),
        RegistryOfficeTransferDisablers.isEntityInIncorrectState(selection),
        RegistryOfficeTransferDisablers.isEntityIncorrectType(selection),
        RegistryOfficeTransferDisablers.isSeparateDocumentTransferringUnavailable(selection, this.applicationConfigService.storeDocumentsSeparatelyUntil),
      ],
      action: button => this.onTransferToRegistryOfficeClick(
        selection,
        [
          CommonToolbarValidators.isEsslObjectLocked(),
          RegistryOfficeTransferValidators.isEntityInRegistryOffice(),
          RegistryOfficeTransferValidators.isEntityIncomplete(),
          RegistryOfficeTransferValidators.isEntityAnalogOrHybrid(),
          RegistryOfficeTransferValidators.isEntityInIncorrectState(),
          RegistryOfficeTransferValidators.isEntityIncorrectType(),
          RegistryOfficeTransferValidators.isSeparateDocumentTransferringUnavailable(this.applicationConfigService.storeDocumentsSeparatelyUntil)
        ],
        button.authorizedOperations!
      ),
    };
  }

  private onTransferToRegistryOfficeClick(selectionData: TransferableObject[], validators: OperationValidator<TransferableObject>[], authorizedOperations: GeneralAuthorizedOperation[]) {
    this.bulkOperationValidationService.validateEsslObjects<TransferableObject>(
      {
        dialogWarningLabel: 'Některé objekty ({{errorCount}}) není možné předat spisovně. Operace předání do spisovny bude provedena pro vyhovující objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: validators ?? [],
        esslObjects: selectionData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openTransferToRegistryOfficeDialog(validatedObjects).subscribe();
      }
    });
  }

  private onTakeoverByRegistryOfficeClick(selectionData: ExtendedRegistryOfficeTransferActivityDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<ExtendedRegistryOfficeTransferActivityDto>(
      {
        dialogWarningLabel: 'Některé objekty ({{errorCount}}) není možné převzít spisovnou. Operace převzetí do spisovny bude provedena pro vyhovující objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [],
        esslObjects: selectionData.map(dd => this.esslTransferActivityToValidationObject(dd)),
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openTransferTakeoverByRegistryOfficeDialog(validatedObjects).subscribe();
      }
    });
  }

  private onRejectByRegistryOfficeClick(selectionData: ExtendedRegistryOfficeTransferActivityDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<ExtendedRegistryOfficeTransferActivityDto>(
      {
        dialogWarningLabel: 'Některé objekty ({{errorCount}}) není možné odmítnout spisovnou. Operace odmítnutí převzetí do spisovny bude provedena pro vyhovující objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [],
        esslObjects: selectionData.map(dd => this.esslTransferActivityToValidationObject(dd)),
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.modalService.openComponentInModal<WorkflowTextResponseDialogResult, WorkflowTextResponseDialogParams>({
          component: WorkflowTextResponseDialogComponent,
          modalOptions: {
            width: 650,
            height: 500,
            titleTemplate: validatedObjects.length > 1 ? 'Hromadné odmítnutí převzetí do spisovny ({{count}})' : 'Odmítnutí převzetí {{entityName}} do spisovny',
            titleTemplateContext: {
              count: String(validatedObjects.length),
              entityName: this.getTransferableObjectName(validatedObjects[0]),
            }
          },
          data: {
            infoAlertTemplate: 'Převzetí do spisovny bude odmítnuto.',
            infoAlertTemplateContext: {},
            responseFieldLabel: 'Popis chyby',
            responseFieldDefaultValue: '',
            primaryButtonLabel: 'Odmítnout převzetí',
            responseHandlerFn: response => this.apiRegistryOfficeTransferService.registryOfficeTransferTransferReject(
              {
                body: {
                  transferActivityIds: selectionData.map(t => t.id!),
                  incidentName: response,
                },
              },
              SKIP_ERROR_DIALOG
            ).pipe(
              tap({
                error: err => {
                  this.registryOfficeToastService.dispatchErrorToast(
                    RegistryOfficeToastType.RO_TRANSFER_REJECT_ERROR,
                    esslErrorDtoToToastParameters(this.translateService, err.error),
                  );
                }
              }),
            )
          },
        });
      }
    });
  }

  private onRetransferClick(selectionData: ExtendedRegistryOfficeTransferActivityDto[], retransferMode: RetransferMode) {
    const doRetransferToRegistryOffice = () => {
      this.bulkOperationValidationService.validateEsslObjects<ExtendedRegistryOfficeTransferActivityDto>(
        {
          dialogWarningLabel: 'Některé objekty ({{errorCount}}) není možné předat spisovně. Operace předání do spisovny bude provedena pro vyhovující objekty ({{successCount}}).',
          dialogWarningLabelContext: {},
          operationValidators: [],
          esslObjects: selectionData.map(dd => this.esslTransferActivityToValidationObject(dd)),
        },
        this,
      ).subscribe(validatedObjects => {
        if (validatedObjects && validatedObjects.length > 0) {
          let request$!: Observable<void>;

          if (retransferMode === RetransferMode.RETRANSFER_TO_REGISTRY_OFFICE) {
            request$ = this.apiRegistryOfficeTransferService.registryOfficeTransferReTransferToRegistryOffice({
              body: {
                transferActivityIds: validatedObjects.map(transferActivity => transferActivity.id),
              }
            });
          }
          else if (retransferMode === RetransferMode.RETRANSFER_TO_DESA) {
            request$ = this.apiRegistryOfficeTransferService.registryOfficeTransferReTransferToDesa({
              body: {
                transferActivityIds: validatedObjects.map(transferActivity => transferActivity.id),
              }
            });
          }

          this.globalLoading.doLoading(
            request$,
          ).subscribe();
        }
      });
    };

    const documentIds = selectionData.map(transferActivity => transferActivity.documentId!).filter(Boolean);
    const fileIds = selectionData.map(transferActivity => transferActivity.fileId!).filter(Boolean);
    const storageUnitIds = selectionData.map(transferActivity => transferActivity.storageUnitId!).filter(Boolean);

    this.globalLoading.doLoading(
      this.apiElasticsearchService.elasticsearchFindRegistryOfficeTransferIncidentsForEntities({
        body: {
          [EntityType.DOCUMENT]: documentIds.length ? documentIds : undefined,
          [EntityType.FILE]: fileIds.length ? fileIds : undefined,
          [EntityType.STORAGE_UNIT]: storageUnitIds.length ? storageUnitIds : undefined,
        } as Record<string, number[]>,
        urlPredicates: {
          state: RegistryOfficeTransferIncidentState.IN_PROGRESS,
        },
      }).pipe(
        map(incidentsPage => (incidentsPage.content ?? []).map(c => c.source)),
        castStream<RegistryOfficeTransferIncidentDto[]>(),
      )
    ).subscribe(relatedIncidents => {
      if (relatedIncidents.length) {
        this.dialogService.openQuestionDialogWithAnswer({
          title: 'Předat do spisovny',
          question: 'Byly nalezeny nevyřešené chyby při předání ({{count}}).',
          questionContext: {
            count: relatedIncidents.length,
          },
          description: 'Přejete si tyto chyby nyní hromadně označit za vyřešené?',
          leftButtonTitle: 'Ano',
          rightButtonTitle: 'Ne',
        }).subscribe(bulkresolveIncidentsChosen => {
          if (bulkresolveIncidentsChosen) {
            const isBulkAction = relatedIncidents.length > 1;

            this.modalService.openComponentInModal<RegistryOfficeTransferIncidentDto[], RegistryOfficeIncidentResolutionDialogData>({
              component: RegistryOfficeIncidentResolutionDialogComponent,
              modalOptions: {
                width: 600,
                height: 450,
                titleTemplate: isBulkAction ? 'Hromadné řešení chyb ({{count}})' : 'Řešení chyby {{errorId}} {{name}}',
                titleTemplateContext: {
                  count: String(relatedIncidents.length),
                  errorId: relatedIncidents[0]!.errorId,
                  name: relatedIncidents[0]!.name,
                },
              },
              data: {
                incidents: relatedIncidents,
                syncMode: true,
              },
            }).subscribe(result => {
              if (result) {
                doRetransferToRegistryOffice();
              }
            });
          }
          else {
            doRetransferToRegistryOffice();
          }
        });
      }
      else {
        doRetransferToRegistryOffice();
      }
    });
  }

  private onCancelTransferClick(selectionData: ExtendedRegistryOfficeTransferActivityDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<ExtendedRegistryOfficeTransferActivityDto>(
      {
        dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možné stornovat ukládání. Operace stornování ukládání bude provedena pro vyhovující objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          RegistryOfficeTransferValidators.isTransferActivityReturnedFromDesa(),
        ],
        esslObjects: selectionData.map(dd => this.esslTransferActivityToValidationObject(dd)),
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        const isBulkAction = validatedObjects.length > 1;

        this.modalService.openComponentInModal<WorkflowTextResponseDialogResult, WorkflowTextResponseDialogParams>({
          component: WorkflowTextResponseDialogComponent,
          modalOptions: {
            width: 650,
            height: 500,
            titleTemplate: isBulkAction ? 'Stornovat ukládání ({{count}})' : 'Stornovat ukládání',
            titleTemplateContext: {
              count: validatedObjects.length,
            }
          },
          data: {
            responseFieldLabel: 'Důvod stornování',
            primaryButtonLabel: 'Stornovat ukládání',
            responseHandlerFn: response => this.globalLoading.doLoading(
              this.apiRegistryOfficeTransferService.registryOfficeTransferCancelTransfer({
                body: {
                  reason: response,
                  transferActivityIds: validatedObjects.map(transferActivity => transferActivity.id),
                }
              }),
            ),
          },
        }).subscribe();
      }
    });
  }

  private onReturnToOfficerClick(selectionData: ExtendedRegistryOfficeTransferActivityDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<ExtendedRegistryOfficeTransferActivityDto>(
      {
        dialogWarningLabel: 'Některé objekty ({{errorCount}}) není možné vrátit referentovi. Operace vrácení referentovi bude provedena pro vyhovující objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [],
        esslObjects: selectionData.map(dd => this.esslTransferActivityToValidationObject(dd)),
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.globalLoading.doLoading(
          this.apiRegistryOfficeTransferService.registryOfficeTransferReturnToOffice({
            body: {
              transferActivityIds: validatedObjects.map(transferActivity => transferActivity.id),
            }
          }),
        ).subscribe();
      }
    });
  }

  esslObjectToValidationObject(object: TransferableObject) {
    let entityName = '';
    let validationObject;
    let authorizedEntityType;

    if (isDocumentEntity(object.entityType)) {
      authorizedEntityType = AuthorizedEntityType.DOCUMENT;
      validationObject = object as DocumentDto;
      entityName = getObjectLabel(this.translateService, validationObject);
    } else if (isFileEntity(object.entityType)) {
      authorizedEntityType = AuthorizedEntityType.FILE;
      validationObject = object as FileDto;
      entityName = getObjectLabel(this.translateService, validationObject);
    } else {
      authorizedEntityType = AuthorizedEntityType.FILE;
      validationObject = object as StorageUnitDto;
      entityName = getStorageUnitLabel(validationObject);
    }

    return {
      entityId: object.id,
      authorizedEntityType,
      entityIcon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      entityData: object,
      entityName,
    };
  }

  esslTransferActivityToValidationObject(activity: ExtendedRegistryOfficeTransferActivityDto) {
    let entityId: number;
    let authorizedEntityType;

    if (activity.documentId) {
      entityId = activity.documentId!;
      authorizedEntityType = AuthorizedEntityType.DOCUMENT;
    } else if (activity.fileId) {
      entityId = activity.fileId!;
      authorizedEntityType = AuthorizedEntityType.FILE;
    } else {
      entityId = activity.storageUnitId!;
      authorizedEntityType = AuthorizedEntityType.STORAGE_UNIT;
    }

    return {
      entityId,
      authorizedEntityType,
      entityIcon: getObjectIcon(activity.objectClass as unknown as ObjectClass)!,
      entityData: activity,
      entityName: this.getTransferableObjectName(activity),
    };
  }

  private getTransferableObjectName(activity: ExtendedRegistryOfficeTransferActivityDto) {
    if (isTransferedElasticObjectFragment(activity)) {
      return activity.entityName!;
    }
    else if (isStorageUnitObject(activity)) {
      return activity.name;
    }
    else {
      return activity.subject;
    }
  }

}
