import {DestroyRef, inject, Injectable, Type} from '@angular/core';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {
  CirculationActivityType,
  CirculationTaskAction,
  CirculationTaskType,
  EntityType,
  ObjectClass
} from '|api/commons';
import {ApiDigitalComponentService, DigitalComponentCompleteDto} from '|api/component';
import {
  AuthorizedEntityType,
  ComponentAuthorizedOperation,
  DocumentAuthorizedOperation,
  FileAuthorizedOperation,
} from '|api/core';
import {
  ApiCirculationActivityService,
  ApiCirculationTaskAcknowledgementService,
  ApiCirculationTaskApprovalService,
  ApiCirculationTaskBulkActionsService,
  ApiCirculationTaskComponentSigningService,
  ApiCirculationTaskHandoverService,
  ApiCirculationTaskHandoverToOwnHandsService,
  ApiCirculationTaskService,
  ApiCirculationTaskStatementService,
  CirculationActivityIssdBaseDto,
  CirculationTaskDto,
} from '|api/flow';
import {InternalNotificationKey} from '|api/notification';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {
  CirculationHandoverTaskToExternalAppData,
  CirculationToastService,
  CirculationToastType,
} from '../../../../core/services/notifications/circulation-toast.service';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {
  EsslComponentCirculationToastService,
  SEsslActivityErrorData,
} from '../../../../core/services/notifications/essl-component-circulation-toast.service';
import {Button} from '../../../button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {SKIP_ERROR_DIALOG} from '../../../../core/error-handling/http-errors';
import {RevokeTaskDialogComponent} from '../components/cancel-task-dialog/revoke-task-dialog.component';
import {FileHandoverDialogComponent} from '../../file-toolbar/file-handover-dialog/file-handover-dialog.component';
import {HandoverDialogComponent} from '../components/handover-dialog/handover-dialog.component';
import {
  PassHandoverToNextHandlerDialogComponent,
} from '../components/pass-handover-to-next-handler-dialog/pass-handover-to-next-handler-dialog.component';
import {
  GenericPassToNextHandlerDialogComponent,
} from '../components/generic-pass-to-next-handler-dialog/generic-pass-to-next-handler-dialog.component';
import {
  WorkflowTextResponseDialogComponent,
  WorkflowTextResponseDialogParams,
  WorkflowTextResponseDialogResult,
} from '../components/workflow-text-response-dialog/workflow-text-response-dialog.component';
import {AbstractHandoverFormModalComponent} from '../components/abstract-handover-form-modal.component';
import {getUserFullNameById$} from '../../find-user-by-id.pipe';
import {CirculationView, DocumentView, openGeneralCirculationModal} from './toolbar-common.utils';
import {
  COMPONENT_ENTITY_TYPES,
  DOCUMENT_ENTITY_TYPES,
  getObjectIcon,
  getObjectLabel,
  isDocumentEntity,
  isFileEntity,
} from '../../shared-document.utils';
import {DialogService, IczModalService} from '@icz/angular-modal';
import {CodebookService} from '../../../../core/services/codebook.service';
import {
  ExtendedCirculationActivityDto,
  ExtendedCirculationTaskDto,
} from '../../model/elastic-extended-entities.interface';
import {
  DigitalComponentSignatureDialogService,
} from '../../essl-components/services/digital-component-signature-dialog.service';
import {handleCirculationErrorAsToast} from '../../../../utils/document-file-circulation.utils';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  BulkOperationValidationService,
  OperationValidator,
} from '../../../../services/bulk-operation-validation.service';
import {GeneralAuthorizedOperation} from '../../permissions/permissions.utils';
import {TaskToolbarButtonsDisablers} from './task-toolbar-buttons.disablers';
import {TaskOperationValidators} from './task-operation-validators';
import {
  AvailableCirculationActionsDialogData,
} from '../components/available-actions-dialog/available-circulation-actions-dialog-model';
import {
  AvailableCirculationActionsDialogComponent,
} from '../components/available-actions-dialog/available-circulation-actions-dialog.component';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {MessageType, ToastService} from '../../../notifications/toast.service';
import {InternalNotificationMessageCode} from '../../../../core/services/notifications/internal-notification.enum';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {Router} from '@angular/router';
import {createAbsoluteRoute} from '../../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../../enums/documents-routes.enum';
import {CirculationSearchService} from '../../../../services/circulation-search.service';
import {FilterOperator} from '@icz/angular-table';
import {SignatureFlowType} from '../../essl-components/models/signature-dialog.model';

interface ApproveDialogParams {
  nextSolverInfoAlertTemplate: string;
  lastSolverInfoAlertTemplate: string;
  bulkInfoAlert?: string;
  dialogTitle: string;
  approvalNoteFieldLabel: string;
  approvalNoteFieldDefaultValue: string;
  approvalButtonLabel: string;
  isComponentApprove?: boolean;
}

interface DisapproveDialogParams {
  dialogTitle: string;
  infoAlertTemplate: string;
  disapprovalNoteFieldLabel: string;
  disapprovalButtonLabel: string;
  isComponentDisapprove?: boolean;
}

enum ExtraActionOnTaskComplete {
  REGISTER_CONTENT_OFFICER = 'REGISTER_CONTENT_OFFICER',
  REGISTER_CONTENT_FILING_OFFICE = 'REGISTER_CONTENT_FILING_OFFICE',
}

interface UserInfo {
  userFullName: string;
  functionalPositionName: string;
}

export interface AvailableTaskActions {
  taskId: number;
  availableActions: (CirculationTaskAction)[];
  circulationTask: ExtendedCirculationTaskDto;
}

export interface TaskToolbarContext {
  availableTaskActions: Array<AvailableTaskActions>;
  isUnitView?: boolean; // todo(rb) temporary parameter until task solving in unit views is implemented
  view?: CirculationView | DocumentView;
}

export function entityTypeToEntityTypeClass(circulationEntityType: EntityType): string {
  if (DOCUMENT_ENTITY_TYPES.includes(circulationEntityType)) {
    return EntityType.DOCUMENT.toLowerCase();
  }
  else if (COMPONENT_ENTITY_TYPES.includes(circulationEntityType)) {
    return EntityType.COMPONENT.toLowerCase();
  }
  else {
    return circulationEntityType.toLowerCase();
  }
}

const DOC_WITHOUT_REF_NUMBER = 'bez čísla jednacího';


export enum TaskToolbarAction {
  TASK_SIGNED = 'TASK_SIGNED',
  TASK_APPROVED = 'TASK_APPROVED',
  TASK_DISAPPROVED = 'TASK_DISAPPROVED',
  TASK_REVOKED = 'TASK_REVOKED',
  TASK_REJECTED = 'TASK_REJECTED',
  TASK_TAKEN_OVER = 'TASK_TAKEN_OVER',
  TASK_STATEMENT_MADE = 'TASK_STATEMENT_MADE',
  TASK_ACKNOWLEDGED = 'TASK_ACKNOWLEDGED',
  TASK_PASSED = 'TASK_PASSED',
  TASK_TAKEN_OVER_AND_PASSED = 'TASK_TAKEN_OVER_AND_PASSED',
  TASK_TAKEN_OVER_FROM_EXTERNAL_APP = 'TASK_TAKEN_OVER_FROM_EXTERNAL_APP',
}

@Injectable()
export class TaskToolbarButtonsService extends ToolbarProvider<ExtendedCirculationTaskDto, TaskToolbarAction, TaskToolbarContext> {

  private modalService = inject(IczModalService);
  private translateParser = inject(TranslateParser);
  private codebookService = inject(CodebookService);
  private translateService = inject(TranslateService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private circulationToastService = inject(CirculationToastService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private circulationSearchService = inject(CirculationSearchService);
  private apiCirculationTaskService = inject(ApiCirculationTaskService);
  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private apiCirculationTaskApprovalService = inject(ApiCirculationTaskApprovalService);
  private apiCirculationTaskStatementService = inject(ApiCirculationTaskStatementService);
  private apiCirculationTaskHandoverService = inject(ApiCirculationTaskHandoverService);
  private apiCirculationTaskHandoverToOwnHandsService = inject(ApiCirculationTaskHandoverToOwnHandsService);
  private esslComponentCirculationToastService = inject(EsslComponentCirculationToastService);
  private apiCirculationTaskAcknowledgementService = inject(ApiCirculationTaskAcknowledgementService);
  private apiCirculationTaskSigningService = inject(ApiCirculationTaskComponentSigningService);
  private apiCirculationTaskBulkActionsService = inject(ApiCirculationTaskBulkActionsService);
  private digitalComponentSignatureDialogService = inject(DigitalComponentSignatureDialogService);
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private dialogService = inject(DialogService);
  private destroyRef = inject(DestroyRef);
  private toastService = inject(ToastService);
  private router = inject(Router);
  private wsNotificationService = inject(WebSocketNotificationsService);

  constructor() {
    super();

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_COMPONENT_ANALOG_SIGN_SUCCESS, InternalNotificationMessageCode.BULK_COMPONENT_DIGITAL_SIGN_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_SIGNED);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_COMPONENT_APPROVE_BY_TARGET_SUCCESS, InternalNotificationMessageCode.BULK_DOCUMENT_APPROVE_BY_TARGET_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_APPROVED);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_TASK_PASS_TO_NEXT_SUCCESS, InternalNotificationMessageCode.BULK_DOCUMENT_TAKEOVER_AND_PASS_SUCCESS, InternalNotificationMessageCode.BULK_FILE_TAKEOVER_AND_PASS_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_PASSED);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_DOCUMENT_ACKNOWLEDGE_TARGET_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_ACKNOWLEDGED);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_COMPONENT_DISAPPROVE_BY_TARGET_SUCCESS, InternalNotificationMessageCode.BULK_DOCUMENT_DISAPPROVE_BY_TARGET_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_DISAPPROVED);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_TASK_REVOKE_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_REVOKED);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_TASK_REJECT_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_REJECTED);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_HANDOVER_TAKEOVER_SUCCESS, InternalNotificationMessageCode.BULK_HANDOVER_FILE_TAKEOVER_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_TAKEN_OVER);
      }
    );

    this.wsNotificationService.getMessagesListener$([InternalNotificationMessageCode.BULK_DOCUMENT_STATEMENT_BY_TARGET_SUCCESS])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_STATEMENT_MADE);
      }
    );
  }

  getToolbarButtons(selection: ExtendedCirculationTaskDto[], context: TaskToolbarContext): Observable<Button[]> {
    const singleEntity = selection[0];

    let availableActions: (CirculationTaskAction)[] = [];
    // create a union of all available actions for each selected task
    context.availableTaskActions.forEach(task => {
      availableActions = [...new Set([...availableActions, ...task.availableActions])];
    });

    let buttons: AuthorizedButton[];

    if (context.view === CirculationView.ISSD_TASKS_REJECTED) {
      buttons = [
        {
          label: 'Převzít',
          icon: 'take_over',
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: () => this.onExternalApplicationTakeoverClick(selection),
        },
      ];
    } else if (context.view === DocumentView.FILING_OFFICE_REJECTED) {
      buttons = [
        {
          label: 'Převzít a zaevidovat obsah',
          icon: 'doruceny_dokument',
          show: selection?.[0]?.taskType === CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_RETURNED_BY_INITIATOR && (availableActions.includes(CirculationTaskAction.TAKEOVER_BY_TARGET) ||
            availableActions.includes(CirculationTaskAction.TAKEOVER_BY_INITIATOR)),
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_TAKEOVER,
            FileAuthorizedOperation.FILE_TAKEOVER,
          ],
          buttonDisablers: [CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.TAKEOVER_BY_TARGET, CirculationTaskAction.TAKEOVER_BY_INITIATOR],
            ExtraActionOnTaskComplete.REGISTER_CONTENT_FILING_OFFICE
          ),
        },
        {
          label: 'Zpětvzít a předat dál dokument',
          show: availableActions.includes(CirculationTaskAction.TAKEOVER_AND_PASS_BY_INITIATOR),
          icon: 'take_over',
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_TAKEOVER_AND_DELEGATE,
            FileAuthorizedOperation.FILE_TAKEOVER_AND_DELEGATE
          ],
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
            TaskToolbarButtonsDisablers.selectedItemIsNotDocumentCirculation(selection)
          ],
          action: button => this.onCirculationOperationClick(
            'Zpětvzít a předat dál dokument - {{refNumber}}',
            'Hromadně zpětvzít a předat ({{count}}) dokumentů dál',
            HandoverDialogComponent,
            CirculationActivityType.DOCUMENT_HANDOVER,
            'Pro některé objekty ({{errorCount}}) není možno provést zpětvzetí a předání. Operace bude provedena pro vyhovující obejkty ({{successCount}}).',
            selection,
            [
              TaskOperationValidators.selectedItemIsNotDocumentCirculation(),
            ],
            button.authorizedOperations!,
            context.availableTaskActions,
            CirculationTaskAction.TAKEOVER_AND_PASS_BY_INITIATOR
          ),
        },
      ];
    } else {
      buttons = [
        {
          label: 'Převzít',
          icon: 'take_over',
          show: availableActions.includes(CirculationTaskAction.TAKEOVER_BY_TARGET) ||
            availableActions.includes(CirculationTaskAction.TAKEOVER_BY_INITIATOR),
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_TAKEOVER,
            FileAuthorizedOperation.FILE_TAKEOVER,
          ],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.TAKEOVER_BY_TARGET, CirculationTaskAction.TAKEOVER_BY_INITIATOR]
          ),
        },
        {
          label: 'Vyjádřit se',
          icon: 'checked',
          show: availableActions.includes(CirculationTaskAction.STATEMENT_DONE_BY_TARGET),
          authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_MAKE_STATEMENT],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.STATEMENT_DONE_BY_TARGET]
          ),
        },
        {
          label: 'Vzít na vědomí',
          icon: 'checked',
          show: availableActions.includes(CirculationTaskAction.ACKNOWLEDGE_BY_TARGET),
          authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ACKNOWLEDGE],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.ACKNOWLEDGE_BY_TARGET]
          ),
        },
        {
          label: 'Schválit',
          icon: 'checked',
          useOriginalIconColor: true,
          show: availableActions.includes(CirculationTaskAction.APPROVE_DOCUMENT_BY_TARGET),
          authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_APPROVE_OR_DISAPPROVE],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.APPROVE_DOCUMENT_BY_TARGET]
          ),
        },
        {
          label: 'Podepsat',
          icon: 'signature',
          show: availableActions.includes(CirculationTaskAction.SIGN_BY_TARGET),
          authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_SIGN],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.bulkSignComponent(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.SIGN_BY_TARGET]
          ),
        },
        {
          label: 'Neschválit',
          icon: 'crossed',
          useOriginalIconColor: true,
          show: availableActions.includes(CirculationTaskAction.DISAPPROVE_DOCUMENT_BY_TARGET),
          authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_APPROVE_OR_DISAPPROVE],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.DISAPPROVE_DOCUMENT_BY_TARGET]
          ),
        },
        {
          label: 'Schválit komponentu',
          icon: 'checked',
          useOriginalIconColor: true,
          show: availableActions.includes(CirculationTaskAction.APPROVE_COMPONENT_BY_TARGET),
          authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_APPROVE_OR_DISAPPROVE],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.APPROVE_COMPONENT_BY_TARGET]
          ),
        },
        {
          label: 'Neschválit komponentu',
          icon: 'crossed',
          useOriginalIconColor: true,
          show: availableActions.includes(CirculationTaskAction.DISAPPROVE_COMPONENT_BY_TARGET),
          authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_APPROVE_OR_DISAPPROVE],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.DISAPPROVE_COMPONENT_BY_TARGET]
          ),
        },
        {
          label: 'Převzít a zaevidovat obsah',
          icon: 'doruceny_dokument',
          show: selection?.[0]?.activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS && (availableActions.includes(CirculationTaskAction.TAKEOVER_BY_TARGET) ||
               availableActions.includes(CirculationTaskAction.TAKEOVER_BY_INITIATOR)),
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_TAKEOVER,
            FileAuthorizedOperation.FILE_TAKEOVER,
          ],
          buttonDisablers: [CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.TAKEOVER_BY_TARGET, CirculationTaskAction.TAKEOVER_BY_INITIATOR],
            ExtraActionOnTaskComplete.REGISTER_CONTENT_OFFICER
          ),
        },
        {
          label: 'Vrátit k evidenci',
          icon: 'send_back',
          show: availableActions.includes(CirculationTaskAction.RETURN_BY_TARGET),
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_REJECT_TASK,
            FileAuthorizedOperation.FILE_REJECT_TASK,
            ComponentAuthorizedOperation.COMPONENT_REJECT_TASK,
          ],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.RETURN_BY_TARGET]
          ),
        },
        {
          label: 'Odmítnout úkol',
          icon: 'send_back',
          show: availableActions.includes(CirculationTaskAction.REJECT_BY_TARGET),
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_REJECT_TASK,
            FileAuthorizedOperation.FILE_REJECT_TASK,
            ComponentAuthorizedOperation.COMPONENT_REJECT_TASK,
          ],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onCirculationTaskClick(
            selection,
            button.authorizedOperations!,
            context.availableTaskActions,
            [CirculationTaskAction.REJECT_BY_TARGET]
          ),
        },
        {
          label: 'Odvolat úkol',
          icon: 'deactivate',
          show: availableActions.includes(CirculationTaskAction.REVOKE_BY_INITIATOR),
          authorizedOperations: [
            DocumentAuthorizedOperation.DOCUMENT_REVOKE_TASK,
            FileAuthorizedOperation.FILE_REVOKE_TASK,
            ComponentAuthorizedOperation.COMPONENT_REVOKE_TASK,
          ],
          buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
          action: button => this.onRevokeTaskClick(
            selection,
            [],
            button.authorizedOperations!,
            context.availableTaskActions
          ),
        },
        {
          label: 'Více',
          icon: 'more',
          show: (
            availableActions.includes(CirculationTaskAction.PASS_BY_TARGET) ||
            availableActions.includes(CirculationTaskAction.TAKEOVER_AND_PASS_BY_TARGET) ||
            availableActions.includes(CirculationTaskAction.TAKEOVER_AND_PASS_BY_INITIATOR)
          ),
          submenuItems: [
            {
              label: 'Předat dál',
              show: availableActions.includes(CirculationTaskAction.PASS_BY_TARGET),
              authorizedOperations: [
                DocumentAuthorizedOperation.DOCUMENT_DELEGATE_TASK,
                FileAuthorizedOperation.FILE_DELEGATE_TASK,
              ],
              buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
              action: button => this.onCirculationTaskClick(
                selection,
                button.authorizedOperations!,
                context.availableTaskActions,
                [CirculationTaskAction.PASS_BY_TARGET]
              ),
            },
            {
              label: 'Převzít a předat dál dokument',
              show: availableActions.includes(CirculationTaskAction.TAKEOVER_AND_PASS_BY_TARGET),
              authorizedOperations: [
                DocumentAuthorizedOperation.DOCUMENT_TAKEOVER_AND_DELEGATE,
                FileAuthorizedOperation.FILE_TAKEOVER_AND_DELEGATE
              ],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                TaskToolbarButtonsDisablers.selectedItemIsNotDocumentCirculation(selection)
              ],
              action: button => this.onCirculationOperationClick(
                'Převzít a předat dál dokument - {{refNumber}}',
                'Hromadne převzít a předat ({{count}}) dokumentů dál',
                HandoverDialogComponent,
                CirculationActivityType.DOCUMENT_HANDOVER,
                'Pro některé objekty ({{errorCount}}) není možno provést převzetí a předání. Operace bude provedena pro vyhovující obejkty ({{successCount}}).',
                selection, [
                  TaskOperationValidators.selectedItemIsNotDocumentCirculation(),
                ],
                button.authorizedOperations!,
                context.availableTaskActions,
                CirculationTaskAction.TAKEOVER_AND_PASS_BY_TARGET
              ),
            },
            {
              label: 'Zpětvzít a předat dál dokument',
              show: availableActions.includes(CirculationTaskAction.TAKEOVER_AND_PASS_BY_INITIATOR),
              authorizedOperations: [
                DocumentAuthorizedOperation.DOCUMENT_TAKEOVER_AND_DELEGATE,
                FileAuthorizedOperation.FILE_TAKEOVER_AND_DELEGATE
              ],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                TaskToolbarButtonsDisablers.selectedItemIsNotDocumentCirculation(selection)
              ],
              action: button => this.onCirculationOperationClick(
                'Zpětvzít a předat dál dokument - {{refNumber}}',
                'Hromadně zpětvzít a předat ({{count}}) dokumentů dál',
                HandoverDialogComponent,
                CirculationActivityType.DOCUMENT_HANDOVER,
                'Pro některé objekty ({{errorCount}}) není možno provést zpětvzetí a předání. Operace bude provedena pro vyhovující obejkty ({{successCount}}).',
                selection,
                [
                  TaskOperationValidators.selectedItemIsNotDocumentCirculation(),
                ],
                button.authorizedOperations!,
                context.availableTaskActions,
                CirculationTaskAction.TAKEOVER_AND_PASS_BY_INITIATOR
              ),
            },
            {
              label: 'Převzít a předat dál spis',
              show: availableActions.includes(CirculationTaskAction.TAKEOVER_AND_PASS_BY_TARGET),
              authorizedOperations: [
                DocumentAuthorizedOperation.DOCUMENT_TAKEOVER_AND_DELEGATE,
                FileAuthorizedOperation.FILE_TAKEOVER_AND_DELEGATE
              ],
              buttonDisablers: [
                CommonToolbarDisablers.isNoItemSelected(selection),
                TaskToolbarButtonsDisablers.selectedItemIsNotFileCirculation(selection)
              ],
              action: button => this.onCirculationOperationClick(
                'Převzít a předat dál spis - {{refNumber}}',
                'Hromadne převzít a předat ({{count}}) spisů dál',
                FileHandoverDialogComponent,
                CirculationActivityType.FILE_HANDOVER,
                'Pro některé objekty ({{errorCount}}) není možno provést převzetí a předání. Operace bude provedena pro vyhovující obejkty ({{successCount}}).',
                selection,
                [
                  TaskOperationValidators.selectedItemIsNotFileCirculation(),
                ],
                button.authorizedOperations!,
                context.availableTaskActions,
                CirculationTaskAction.TAKEOVER_AND_PASS_BY_TARGET
              ),
            },
          ]
        }
      ];
    }

    if (selection.length === 1) {
      return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
        {
          [AuthorizedEntityType.DOCUMENT]: singleEntity?.documentId,
          [AuthorizedEntityType.FILE]: singleEntity?.fileId,
          [AuthorizedEntityType.COMPONENT]: singleEntity?.componentId,
        },
        of(buttons),
      );
    } else {
      return of(buttons).pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  approveDocument(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.genericBulkApprove(
        taskData,
        {
          nextSolverInfoAlertTemplate: '',
          lastSolverInfoAlertTemplate: '',
          bulkInfoAlert: 'Dokumenty budou schváleny a předány k dalšímu schválení nebo zadavateli úkolu',
          dialogTitle: 'Schválení ({{count}}) dokumentů',
          approvalNoteFieldLabel: 'Poznámka ke schválení dokumentu',
          approvalNoteFieldDefaultValue: 'Bez připomínek',
          approvalButtonLabel: 'Schválit',
        });
    } else {
      this.genericApprove(
        taskData[0],
        {
          nextSolverInfoAlertTemplate: 'Dokument bude předán k dalšímu schválení - {{userFullName}} ({{functionalPositionName}})',
          lastSolverInfoAlertTemplate: 'Dokument bude předán zadavateli úkolu - {{userFullName}} ({{functionalPositionName}})',
          dialogTitle: 'Schválení dokumentu {{refNumber}}',
          approvalNoteFieldLabel: 'Poznámka ke schválení dokumentu',
          approvalNoteFieldDefaultValue: 'Bez připomínek',
          approvalButtonLabel: 'Schválit',
        }
      );
    }
  }

  bulkSignComponent(taskData: ExtendedCirculationTaskDto[], authorizedOperations: GeneralAuthorizedOperation[], availableTasks: AvailableTaskActions[], selectedCirculationAction: (CirculationTaskAction)[]) {
    const operationValidators: OperationValidator<ExtendedCirculationTaskDto>[] = [];

    if (taskData[0].circulationEntityType === EntityType.DIGITAL_COMPONENT) {
      operationValidators.push(TaskOperationValidators.selectedSignItemIsNotDigitalComponent());
    }
    else {
      operationValidators.push(TaskOperationValidators.selectedSignItemIsNotAnalogComponent());
    }

    this.bulkOperationValidationService.validateEsslObjects<ExtendedCirculationTaskDto>(
      {
        dialogWarningLabel: 'Nekteré objekty ({{errorCount}}) není možné podepsat. Operace podepisování bude provedena s vyhovujícími objekty ({{successCount}}). ',
        dialogWarningLabelContext: {},
        operationValidators,
        esslObjects: taskData.map(dd => this.circulationTaskToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        const filterdTasks = this.filterTasksByAvailableActions(selectedCirculationAction, availableTasks);
        if (filterdTasks.unavailableTasks.length) {
          this.openUnavailableActionsDialog(filterdTasks, 'Podepsání komponenty').subscribe(result => {
            if (result.length) this.signComponents(filterdTasks.availableTasks);
          });
        } else {
          this.signComponents(filterdTasks.availableTasks);
        }
      }
    });
  }

  signComponents(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData[0].circulationEntityType === EntityType.DIGITAL_COMPONENT) {
      if (taskData.length === 1) {
        this.globalLoadingService.doLoading(
          this.apiDigitalComponentService.digitalComponentFindById({
            id: taskData[0].componentId!,
          })
        ).subscribe(digitalComponentData => {
          this.digitalComponentSignatureDialogService.openSignatureDialogFlow(
            SignatureFlowType.COMPONENT_TO_SIGN_TASK,
            [digitalComponentData],
            (documentId, signRequest) => this.apiCirculationTaskSigningService.circulationTaskComponentSigningSignDigitalComponent({
              id: taskData[0].id,
              body: signRequest,
            }),
            response => response.signResponse,
          ).subscribe(documentSignResponse => {
            if (documentSignResponse) {
              this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
              this.announceActionCompleted(TaskToolbarAction.TASK_SIGNED);
            }
          });
        });
      } else {
        this.globalLoadingService.doLoading(
          this.apiDigitalComponentService.digitalComponentFindByIds({
            body: taskData.map(task => task.componentId!),
          })
        ).subscribe((digitalComponentsData: DigitalComponentCompleteDto[]) => {
          this.digitalComponentSignatureDialogService.openSignatureDialogFlow(
            SignatureFlowType.COMPONENT_TO_SIGN_TASK,
            digitalComponentsData,
            (documentId, signRequest) => this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsSignDigital({
              body: signRequest,
            }).pipe(map(_ => ({bulkComponentSignStarted: true}))),
            response => response,
          ).subscribe(documentSignResponse => {
            if (documentSignResponse) {
              this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
              this.announceActionCompleted(TaskToolbarAction.TASK_SIGNED);
            }
          });
        });
      }
    }
    else {
      if (taskData.length === 1) {
        this.globalLoadingService.doLoading(
          this.apiCirculationTaskSigningService.circulationTaskComponentSigningSignAnalogComponent({
            id: taskData[0].id,
          })
        ).subscribe(documentSignResponse => {
          if (documentSignResponse) {
            this.esslComponentCirculationToastService.dispatchEsslComponentSignSuccessTargetParticipant({
              [InternalNotificationKey.ENTITY_TYPE]: taskData[0].circulationEntityType,
              [InternalNotificationKey.ESSL_COMPONENT_LABEL]: taskData[0].componentLabel!,
              [InternalNotificationKey.DOCUMENT_ID]: taskData[0].documentId!,
              [InternalNotificationKey.ESSL_COMPONENT_ID]: taskData[0].componentId!,
              [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: taskData[0].digitalComponentVersionId!,
              [InternalNotificationKey.ACTIVITY_ID]: taskData[0].activityId,
            });
            this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
            this.announceActionCompleted(TaskToolbarAction.TASK_SIGNED);
          }
        });
      } else {
        this.globalLoadingService.doLoading(
          this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsSignAnalog({
            body: taskData.map(task => task.id)
          })
        ).subscribe(_ => {
          const templateData = {
            [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationTaskType.${CirculationTaskType.SIGNING_BY_TARGET}`,
            [InternalNotificationKey.COUNT]: String(taskData.length),
          };
          this.circulationToastService.dispatchBulkTaskToast(templateData, CirculationToastType.FLOW_BULK_ANALOG_COMPONENT_SIGN_STARTED);
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(TaskToolbarAction.TASK_SIGNED);
        });
      }
    }
  }

  disapproveDocument(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.genericBulkDisapprove(
        taskData,
        {
          infoAlertTemplate: 'Dokumenty budou předány zadavateli úkolů',
          dialogTitle: 'Neschválit ({{count}}) dokumentů',
          disapprovalNoteFieldLabel: 'Důvod neschválení dokumentů',
          disapprovalButtonLabel: 'Neschválit',
        });
    } else {
      this.genericDisapprove(
        taskData[0],
        {
          dialogTitle: 'Neschválit {{refNumber}}',
          infoAlertTemplate: 'Dokument bude předán zadavateli úkolu - {{userFullName}} ({{functionalPositionName}})',
          disapprovalNoteFieldLabel: 'Důvod neschválení dokumentu',
          disapprovalButtonLabel: 'Neschválit',
        }
      );
    }

  }

  approveComponent(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.genericBulkApprove(
        taskData,
        {
          nextSolverInfoAlertTemplate: '',
          lastSolverInfoAlertTemplate: '',
          bulkInfoAlert: 'Komponenty budou schváleny a předány k dalšímu schválení nebo zadavateli úkolu',
          dialogTitle: 'Schválení ({{count}}) komponent',
          approvalNoteFieldLabel: 'Poznámka ke schválení komponent',
          approvalNoteFieldDefaultValue: 'Bez připomínek',
          approvalButtonLabel: 'Schválit',
          isComponentApprove: true
        });
    } else {
      this.genericApprove(
        taskData[0],
        {
          nextSolverInfoAlertTemplate: 'Komponenta bude předána k dalšímu schválení - {{userFullName}} ({{functionalPositionName}})',
          lastSolverInfoAlertTemplate: 'Komponenta bude předána zadavateli úkolu - {{userFullName}} ({{functionalPositionName}})',
          dialogTitle: 'Schválení komponenty {{componentLabel}}',
          approvalNoteFieldLabel: 'Poznámka ke schválení komponenty',
          approvalNoteFieldDefaultValue: 'Bez připomínek',
          approvalButtonLabel: 'Schválit',
        }
      );
    }
  }

  disapproveComponent(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.genericBulkDisapprove(
        taskData,
        {
          dialogTitle: 'Neschválení ({{count}}) komponent',
          infoAlertTemplate: 'Komponenty budou předány zadavateli úkolů',
          disapprovalNoteFieldLabel: 'Důvod neschválení komponent',
          disapprovalButtonLabel: 'Neschválit',
          isComponentDisapprove: true,
        });
    } else {
      this.genericDisapprove(
        taskData[0],
        {
          dialogTitle: 'Neschválit {{componentLabel}}',
          infoAlertTemplate: 'Komponenta bude předána zadavateli úkolu - {{userFullName}} ({{functionalPositionName}})',
          disapprovalNoteFieldLabel: 'Důvod neschválení komponenty',
          disapprovalButtonLabel: 'Neschválit',
        }
      );
    }
  }

  circulationEntityTypeToAuthorizedEntityType(circulationEntityType: EntityType): AuthorizedEntityType {
    if (DOCUMENT_ENTITY_TYPES.includes(circulationEntityType)) {
      return AuthorizedEntityType.DOCUMENT;
    } else if (COMPONENT_ENTITY_TYPES.includes(circulationEntityType)) {
      return AuthorizedEntityType.COMPONENT;
    } else {
      return AuthorizedEntityType.FILE;
    }
  }

  circulationEntityTypeToEntityId(object: ExtendedCirculationTaskDto | ExtendedCirculationActivityDto): number {
    if (DOCUMENT_ENTITY_TYPES.includes(object.circulationEntityType)) {
      return object.documentId!;
    } else if (COMPONENT_ENTITY_TYPES.includes(object.circulationEntityType)) {
      return object.componentId!;
    } else {
      return object.fileId!;
    }
  }

  circulationTaskToValidationObject(object: ExtendedCirculationTaskDto) {
    return {
      entityId: this.circulationEntityTypeToEntityId(object),
      authorizedEntityType: this.circulationEntityTypeToAuthorizedEntityType(object.circulationEntityType),
      entityIcon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      entityData: object,
      entityName: getObjectLabel(this.translateService, object),
    };
  }

  private circulationActivityToValidationObject(object: ExtendedCirculationActivityDto) {
    return {
      entityId: this.circulationEntityTypeToEntityId(object),
      authorizedEntityType: this.circulationEntityTypeToAuthorizedEntityType(object.circulationEntityType),
      entityData: object,
      entityName: object.subject
    };
  }

  private genericBulkApprove(taskData: ExtendedCirculationTaskDto[], params: ApproveDialogParams) {
    this.openWorkflowTextResponseDialog(
      params.dialogTitle,
      TaskToolbarAction.TASK_APPROVED,
      {
        infoAlertTemplate: params.bulkInfoAlert,
        infoAlertTemplateContext: {},
        responseFieldLabel: params.approvalNoteFieldLabel,
        responseFieldDefaultValue: params.approvalNoteFieldDefaultValue,
        primaryButtonLabel: params.approvalButtonLabel,
        responseHandlerFn: response => this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsApprove(
          {
            body: taskData.map(t => ({
              taskId: t.id,
              settlementNote: response
            })),
          },
          SKIP_ERROR_DIALOG
        ).pipe(
          tap({
            next: _ => {
              const templateData = {
                [InternalNotificationKey.COUNT]: String(taskData.length),
              };
              this.circulationToastService.dispatchBulkTaskToast(templateData, params.isComponentApprove ? CirculationToastType.FLOW_BULK_COMPONENT_APPROVE : CirculationToastType.FLOW_BULK_DOCUMENT_APPROVE);
              this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
              this.announceActionCompleted(TaskToolbarAction.TASK_APPROVED);
            },
            error: _ => this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_BULK_TASK_BY_TARGET_ERROR, [{[InternalNotificationKey.ACTIVITY_TYPE_KEY]: params.isComponentApprove ? CirculationActivityType.COMPONENT_APPROVAL : CirculationActivityType.DOCUMENT_APPROVAL}], SEsslActivityErrorData)
          }),
        )
      },
      taskData,
    );
  }

  private genericApprove(taskData: ExtendedCirculationTaskDto, params: ApproveDialogParams) {
    this.globalLoadingService.doLoading(
      combineLatest([
        this.apiCirculationTaskService.circulationTaskFindById({id: taskData.id}),
        this.apiCirculationActivityService.circulationActivityFindById({id: taskData.activityId}),
      ])
    ).pipe(
      switchMap(([completeTask, activity]) => {
        const totalStepCount = activity.targetParticipants.length;
        const currentStepNumber = completeTask.participant!.taskAssignmentOrder!;

        let infoAlertTemplate: string;

        if (currentStepNumber && currentStepNumber < totalStepCount) { // non-last step
          const nextStep = activity.targetParticipants.find(p => p.taskAssignmentOrder === currentStepNumber + 1);

          infoAlertTemplate = params.nextSolverInfoAlertTemplate;

          if (nextStep) {
            return this.codebookService.functionalPositions().pipe(
              map(functionalPositions => functionalPositions.find(
                fp => fp.id === nextStep.targetParticipantId)!
              ),
              map(functionalPosition => ({
                userFullName: functionalPosition.holderName ?? '',
                functionalPositionName: functionalPosition.name,
              })),
              map(userInfo => ({userInfo, completeTask, activity, infoAlertTemplate}))
            );
          }
          else {
            return of({
              userInfo: {
                userFullName: '',
                functionalPositionName: '',
              },
              completeTask,
              activity,
              infoAlertTemplate,
            });
          }
        }
        else { // last step
          infoAlertTemplate = params.lastSolverInfoAlertTemplate;

          return this.getInitUserInfo$(completeTask.rootActivityId).pipe(
            map(userInfo => ({userInfo, completeTask, activity, infoAlertTemplate}))
          );
        }
      }),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(({userInfo, completeTask, activity, infoAlertTemplate}) => {
      this.openWorkflowTextResponseDialog(
        params.dialogTitle,
        TaskToolbarAction.TASK_APPROVED,
        {
          infoAlertTemplate,
          infoAlertTemplateContext: {
            userFullName: userInfo.userFullName,
            functionalPositionName: userInfo.functionalPositionName
          },
          responseFieldLabel: params.approvalNoteFieldLabel,
          responseFieldDefaultValue: params.approvalNoteFieldDefaultValue,
          primaryButtonLabel: params.approvalButtonLabel,
          responseHandlerFn: response => this.globalLoadingService.doLoading(this.apiCirculationTaskApprovalService.circulationTaskApprovalApprove(
            {
              id: taskData.id,
              body: {
                settlementNote: response,
              },
            },
            SKIP_ERROR_DIALOG
          )).pipe(
            tap({
              next: resultTask => {
                this.dispatchTaskSuccessToast(taskData, resultTask.id);
                this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
                this.announceActionCompleted(TaskToolbarAction.TASK_APPROVED);
              },
              error: errorResponse => handleCirculationErrorAsToast(
                taskData,
                errorResponse,
                this.toastService,
                this.translateService,
                undefined,
                CirculationActivityType.DOCUMENT_APPROVAL
              ),
            }),
          )
        },
        [taskData],
      );
    });
  }

  private genericBulkDisapprove(taskData: ExtendedCirculationTaskDto[], params: DisapproveDialogParams) {
    this.openWorkflowTextResponseDialog(
      params.dialogTitle,
      TaskToolbarAction.TASK_DISAPPROVED,
      {
        infoAlertTemplate: params.infoAlertTemplate,
        infoAlertTemplateContext: {},
        responseFieldLabel: params.disapprovalNoteFieldLabel,
        primaryButtonLabel: params.disapprovalButtonLabel,
        responseHandlerFn: response => this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsDisapprove(
          {
            body: taskData.map(t => ({
              taskId: t.id,
              settlementNote: response
            })),
          },
          SKIP_ERROR_DIALOG
        ).pipe(
          tap({
            next: _ => {
              const templateData = {
                [InternalNotificationKey.COUNT]: String(taskData.length),
              };
              this.circulationToastService.dispatchBulkTaskToast(templateData, params.isComponentDisapprove ? CirculationToastType.FLOW_BULK_COMPONENT_DISAPPROVE : CirculationToastType.FLOW_BULK_DISAPPROVE);
              this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
              this.announceActionCompleted(TaskToolbarAction.TASK_DISAPPROVED);
            },
            error: _ => this.toastService.dispatchBulkToast(MessageType.ERROR, params.isComponentDisapprove ? CirculationToastType.FLOW_BULK_COMPONENT_DISAPPROVE_ERROR : CirculationToastType.FLOW_BULK_DISAPPROVE_ERROR, [{[InternalNotificationKey.ACTIVITY_TYPE_KEY]: CirculationToastType.FLOW_BULK_DISAPPROVE}], SEsslActivityErrorData)
          }),
        )
      },
      taskData,
    );
  }

  private genericDisapprove(taskData: ExtendedCirculationTaskDto, params: DisapproveDialogParams) {
    this.globalLoadingService.doLoading(
      this.getInitUserInfo$(taskData.rootActivityId),
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(userInfo => {
      this.openWorkflowTextResponseDialog(
        params.dialogTitle,
        TaskToolbarAction.TASK_DISAPPROVED,
        {
          infoAlertTemplate: params.infoAlertTemplate,
          infoAlertTemplateContext: {
            userFullName: userInfo.userFullName,
            functionalPositionName: userInfo.functionalPositionName,
          },
          responseFieldLabel: params.disapprovalNoteFieldLabel,
          primaryButtonLabel: params.disapprovalButtonLabel,
          responseHandlerFn: response => this.globalLoadingService.doLoading(this.apiCirculationTaskApprovalService.circulationTaskApprovalDisapprove(
            {
              id: taskData.id,
              body: {
                settlementNote: response,
              },
            },
            SKIP_ERROR_DIALOG
          )).pipe(
            tap({
              next: resultTask => {
                this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
                this.announceActionCompleted(TaskToolbarAction.TASK_DISAPPROVED);
                this.dispatchTaskSuccessToast(taskData, resultTask.id);
              },
              error: errorResponse => handleCirculationErrorAsToast(
                taskData,
                errorResponse,
                this.toastService,
                this.translateService,
                undefined,
                CirculationActivityType.DOCUMENT_APPROVAL,
              ),
            }),
          )
        },
        [taskData],
      );
    });
  }

  onRevokeTaskClick(
    selectionData: ExtendedCirculationTaskDto[], // todo why is that here
    validators: OperationValidator<ExtendedCirculationTaskDto>[],
    authorizedOperations: GeneralAuthorizedOperation[],
    allSelectedTasks: AvailableTaskActions[]
  ) {
    const revokeActivitiesByTasks = (revokableActivities: ExtendedCirculationActivityDto[], filteredTasks: ExtendedCirculationTaskDto[]) => {
      this.modalService.openComponentInModal<Nullable<string>, ExtendedCirculationActivityDto[]>({
        component: RevokeTaskDialogComponent,
        modalOptions: {
          width: 650,
          height: 500,
          titleTemplate: revokableActivities.length > 1 ? 'Hromadné odvolání úkolů ({{count}})' : 'Odvolání úkolu',
          titleTemplateContext: {
            count: String(revokableActivities.length),
          }
        },
        data: revokableActivities,
      }).subscribe(result => {
        if (result) {
          if (revokableActivities.length === 1) {
            const activityData = filteredTasks[0];
            const templateData = {
              [InternalNotificationKey.ACTIVITY_ID]: activityData.id,
              [InternalNotificationKey.REASON]: result,
              [InternalNotificationKey.DOCUMENT_ID]: String(activityData.documentId),
              [InternalNotificationKey.DOCUMENT_SUBJECT]: activityData.subject,
              [InternalNotificationKey.REF_NUMBER]: activityData.refNumber ?? this.translateService.instant(DOC_WITHOUT_REF_NUMBER),
              [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${activityData.activityType}`,
              [InternalNotificationKey.ENTITY_TYPE_CLASS]: entityTypeToEntityTypeClass(activityData.circulationEntityType),
            };

            if (
              activityData.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
              activityData.activityType === CirculationActivityType.FILE_HANDOVER
            ) {
              this.circulationToastService.dispatchHandoverTaskRevokedInitiator(templateData);
            }
            else {
              this.circulationToastService.dispatchTaskRevokedInitiator(templateData);
            }
          }
          else {
            const templateData = {
              [InternalNotificationKey.COUNT]: String(revokableActivities.length),
            };
            this.circulationToastService.dispatchBulkTaskToast(templateData, CirculationToastType.FLOW_BULK_REVOKE);
          }
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(TaskToolbarAction.TASK_REVOKED);
        }
      });
    };

    const validateAvailableTasks = (filteredTasks: ExtendedCirculationTaskDto[]) => {
      const activityIds = filteredTasks.map(d => d.activityId);

      this.globalLoadingService.doLoading(
        forkJoin([
          this.apiCirculationActivityService.circulationActivityCheckRevokePreconditions({body: activityIds}),
          this.circulationSearchService.findActivitiesGlobally({
            page: 0,
            size: 2000,
            filter: [
              {
                fieldName: 'id',
                operator: FilterOperator.inSet,
                value: String(activityIds),
              }
            ],
            sort: [],
          }).pipe(map(page => page.content))
        ])
      ).subscribe(([preconditionResults, activities]) => {
        const revokableActivities = preconditionResults.filter(r => r.revokeAllowed)
          .map(r => activities.find(a => a.id === r.activityId)!);

        if (activities.length > 1) {
          validators.push(TaskOperationValidators.isUnrevokableActivityByTask(preconditionResults));

          this.bulkOperationValidationService.validateEsslObjects<ExtendedCirculationTaskDto>(
            {
              dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést odvolání úkolu. Hromadné odvolání úkolu bude provedeno pro vyhující objekty ({{successCount}}).',
              dialogWarningLabelContext: {},
              operationValidators: validators ?? [],
              esslObjects: filteredTasks.map(task => this.circulationTaskToValidationObject(task)),
              authorizedOperations,
            },
            this,
          ).subscribe(_ => {
            if (revokableActivities && revokableActivities.length > 0) {
              revokeActivitiesByTasks(revokableActivities, filteredTasks);
            }
          });
        }
        else {
          const validatorResult = TaskOperationValidators.isUnrevokableActivityByTask(preconditionResults)(filteredTasks[0]);

          if (validatorResult === null) {
            revokeActivitiesByTasks(revokableActivities, filteredTasks);
          }
          else {
            this.dialogService.showError(validatorResult!.validationMessage!);
          }
        }
      });
    };

    const filteredTasks = this.filterTasksByAvailableActions([CirculationTaskAction.REVOKE_BY_INITIATOR], allSelectedTasks);
    if (filteredTasks.unavailableTasks.length) {
      this.openUnavailableActionsDialog(filteredTasks, 'Odvolání úkolu').subscribe(result => {
        if (result.length) validateAvailableTasks(filteredTasks.availableTasks);
      });
    } else {
      validateAvailableTasks(filteredTasks.availableTasks);
    }
  }

  rejectTask(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.openWorkflowTextResponseDialog(
        'Hromadné odmítnutí ({{count}}) úkolů',
        TaskToolbarAction.TASK_REJECTED,
        {
          responseFieldLabel: 'Důvod odmítnutí úkolů',
          primaryButtonLabel: 'Odmítnout úkoly',
          responseHandlerFn: response => this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsReject(
            {
              body: taskData.map(task => ({
                taskId: task.id,
                reason: response
              })),
            },
            SKIP_ERROR_DIALOG
          ).pipe(
            tap({
              next: _ => {
                const templateData = {
                  [InternalNotificationKey.COUNT]: String(taskData.length),
                };
                this.circulationToastService.dispatchBulkTaskToast(templateData, CirculationToastType.FLOW_BULK_REJECT);
                this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
                this.announceActionCompleted(TaskToolbarAction.TASK_REJECTED);
              },
              error: errorResponse => handleCirculationErrorAsToast(
                taskData[0],
                errorResponse,
                this.toastService,
                this.translateService,
                undefined,
                CirculationActivityType.DOCUMENT_STATEMENT
              ),
            }),
          )
        },
        taskData,
      );
    } else {
      const isApprovalActivity = [
        CirculationTaskType.DOCUMENT_APPROVAL_BY_TARGET,
        CirculationTaskType.DOCUMENT_APPROVAL_ACTIVITY_MANAGEMENT_BY_INITIATOR,
        CirculationTaskType.COMPONENT_APPROVAL_BY_TARGET,
        CirculationTaskType.COMPONENT_APPROVAL_ACTIVITY_MANAGEMENT_BY_INITIATOR,
      ].includes(taskData[0].taskType);
      if (isApprovalActivity) {
        this.rejectApproval(taskData[0]);
      } else {
        this.onRejectTaskClick(taskData[0]);
      }
    }
  }

  // Vrátit k evidenci do podatelny
  returnForRegisterContentTask(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsReturnEntity({body: taskData.map(t => {return {taskId: t.id, reason: ''};})
      }).subscribe({
        next: _ => {
          const templateData = {
            [InternalNotificationKey.COUNT]: String(taskData.length),
          };
          this.circulationToastService.dispatchBulkTaskToast(templateData, CirculationToastType.FLOW_BULK_HANDOVER_UNOPENED_RETURN_FOR_REGISTER_CONTENT);
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(TaskToolbarAction.TASK_PASSED); // Common TASK_PASSED should be fine for RETURN_FOR_REGISTER_CONTENT as it is a subtype of PASSING I think
        },
        error: errorResponse => handleCirculationErrorAsToast(
          taskData[0],
          errorResponse,
          this.toastService,
          this.translateService,
          undefined,
          CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS
        ),
      });
    } else {
      this.apiCirculationTaskHandoverToOwnHandsService.circulationTaskHandoverToOwnHandsReturnByTarget({id: taskData[0]!.id, body: {reason: ''}}).subscribe({
        next: newTask => {
          const templateData = {
            [InternalNotificationKey.DOCUMENT_ID]: String(taskData[0].documentId),
            [InternalNotificationKey.DOCUMENT_SUBJECT]: taskData[0].subject,
            [InternalNotificationKey.TASK_ID]: newTask.id,
            [InternalNotificationKey.REF_NUMBER]: taskData[0].refNumber ?? this.translateService.instant(DOC_WITHOUT_REF_NUMBER),
            [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${taskData[0].activityType}`),
            [InternalNotificationKey.ENTITY_TYPE_CLASS]: entityTypeToEntityTypeClass(taskData[0].circulationEntityType),
          };
          this.circulationToastService.dispatchTaskReturnedForRegisterContentTargetParticipant(templateData);
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(TaskToolbarAction.TASK_PASSED); // Common TASK_PASSED should be fine for RETURN_FOR_REGISTER_CONTENT as it is a subtype of PASSING I think
        },
        error: errorResponse => handleCirculationErrorAsToast(
          taskData[0],
          errorResponse,
          this.toastService,
          this.translateService,
          undefined,
          CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS
        ),
      });
    }
  }

  rejectApproval(taskData: ExtendedCirculationTaskDto) {
    const isComponentCirculation = COMPONENT_ENTITY_TYPES.includes(taskData.circulationEntityType);

    this.getInitUserInfo$(taskData.rootActivityId).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(userInfo => {
      this.openWorkflowTextResponseDialog(
        isComponentCirculation ? 'Odmítnout schválení {{componentLabel}}' : 'Odmítnout schválení {{refNumber}}',
        TaskToolbarAction.TASK_REJECTED,
        {
          infoAlertTemplate: (
            isComponentCirculation ?
              'Komponenta bude předána zadavateli úkolu - {{userFullName}} ({{functionalPositionName}})' :
              'Dokument bude předán zadavateli úkolu - {{userFullName}} ({{functionalPositionName}})'
          ),
          infoAlertTemplateContext: {
            userFullName: userInfo.userFullName,
            functionalPositionName: userInfo.functionalPositionName,
          },
          responseFieldLabel: 'Důvod odmítnutí',
          primaryButtonLabel: 'Odmítnout úkol',
          responseHandlerFn: response => this.globalLoadingService.doLoading(this.apiCirculationTaskApprovalService.circulationTaskApprovalReject(
            {
              id: taskData.id,
              body: {
                reason: response,
              },
            },
            SKIP_ERROR_DIALOG
          )).pipe(
            tap({
              next: resultTask => {
                this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
                this.announceActionCompleted(TaskToolbarAction.TASK_REJECTED);
                this.dispatchTaskRejectedToast(taskData, response, resultTask.id);
              },
              error: errorResponse => handleCirculationErrorAsToast(
                taskData,
                errorResponse,
                this.toastService,
                this.translateService,
                undefined,
                CirculationActivityType.DOCUMENT_APPROVAL
              ),
            }),
          )
        },
        [taskData],
      );
    });
  }

  onRejectTaskClick(taskData: ExtendedCirculationTaskDto) {
    let modalTitleTemplate = 'Odmítnutí úkolu';

    if (taskData.circulationEntityType === EntityType.OWN_DOCUMENT || taskData.circulationEntityType === EntityType.RECEIVED_DOCUMENT) {
      modalTitleTemplate = 'Odmítnutí úkolu dokumentu {{refNumber}}';
    }
    else if (taskData.circulationEntityType === EntityType.FILE) {
      modalTitleTemplate = 'Odmítnutí úkolu spisu {{refNumber}}';
    }
    else if (COMPONENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      modalTitleTemplate = 'Odmítnutí úkolu komponenty {{componentLabel}}';
    }

    this.openWorkflowTextResponseDialog(
      modalTitleTemplate,
      TaskToolbarAction.TASK_REJECTED,
      {
        responseFieldLabel: 'Důvod odmítnutí úkolu',
        primaryButtonLabel: 'Odmítnout úkol',
        responseHandlerFn: response => {
          const rejectionCallParams = {
            id: taskData.id,
            body: {
              reason: response,
            },
          };

          let request$: Observable<Nullable<CirculationTaskDto>>;
          let activityType: CirculationActivityType;

          if (taskData.taskType === CirculationTaskType.STATEMENT_FROM_TARGET) {
            activityType = CirculationActivityType.DOCUMENT_STATEMENT;
            request$ = this.apiCirculationTaskStatementService.circulationTaskStatementReject(
              rejectionCallParams,
              SKIP_ERROR_DIALOG
            );
          }
          else if (
            taskData.taskType === CirculationTaskType.TAKEOVER_BY_TARGET ||
            taskData.taskType === CirculationTaskType.TAKEOVER_DUPLICATE_BY_TARGET
          ) {
            if (
              taskData.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
              taskData.activityType === CirculationActivityType.FILE_HANDOVER
            ) {
              activityType = taskData.activityType;
              request$ = this.apiCirculationTaskHandoverService.circulationTaskHandoverReject(
                rejectionCallParams,
                SKIP_ERROR_DIALOG
              );
            }
            else if (taskData.activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS) {
              activityType = taskData.activityType;
              request$ = this.apiCirculationTaskHandoverToOwnHandsService.circulationTaskHandoverToOwnHandsReject(
                rejectionCallParams,
                SKIP_ERROR_DIALOG
              );
            }
          }
          else if (taskData.taskType === CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_BY_TARGET) {
            activityType = taskData.activityType;
            request$ = this.apiCirculationTaskHandoverToOwnHandsService.circulationTaskHandoverToOwnHandsReject(
              rejectionCallParams,
              SKIP_ERROR_DIALOG
            );
          }
          else if (taskData.taskType === CirculationTaskType.SIGNING_BY_TARGET) {
            activityType = CirculationActivityType.COMPONENT_SIGNING;
            request$ = this.apiCirculationTaskSigningService.circulationTaskComponentSigningReject(
              rejectionCallParams,
              SKIP_ERROR_DIALOG
            );
          }
          else {
            activityType = CirculationActivityType.DOCUMENT_HANDOVER; // sensible default
            request$ = of(null);
          }

          return this.globalLoadingService.doLoading(request$!).pipe(
            tap({
              next: resultTask => {
                if (resultTask) {
                  this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
                  this.announceActionCompleted(TaskToolbarAction.TASK_REJECTED);
                  this.dispatchTaskRejectedToast(taskData, response, resultTask.id);
                }
              },
              error: errorResponse => handleCirculationErrorAsToast(
                taskData,
                errorResponse,
                this.toastService,
                this.translateService,
                undefined,
                activityType
              ),
            }),
          );
        }
      },
      [taskData],
    );
  }

  private onTakeOver(tasks: ExtendedCirculationTaskDto[], extraActionOnComplete?: ExtraActionOnTaskComplete) {
    const handoverService = this.apiCirculationTaskHandoverService;
    const handoverToOwnHandsService = this.apiCirculationTaskHandoverToOwnHandsService;
    let req$: Observable<Nullable<CirculationTaskDto> | void>;
    if (tasks.length === 1) {
      const params = {
        id: tasks[0].id,
      };

      if (tasks[0].taskType === CirculationTaskType.TAKEOVER_REJECTED_BY_INITIATOR) {
        if (
          tasks[0].activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
          tasks[0].activityType === CirculationActivityType.FILE_HANDOVER
        ) {
          req$ = handoverService.circulationTaskHandoverTakeoverRejected(params, SKIP_ERROR_DIALOG);
        }
        else if (tasks[0].activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS) {
          req$ = handoverToOwnHandsService.circulationTaskHandoverToOwnHandsTakeoverByInitiator(params, SKIP_ERROR_DIALOG);
        }
      }
      else if (tasks[0].taskType === CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_RETURNED_BY_INITIATOR ||
        tasks[0].taskType === CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_REJECTED_BY_INITIATOR) {
        req$ = handoverToOwnHandsService.circulationTaskHandoverToOwnHandsTakeoverByInitiator(params, SKIP_ERROR_DIALOG);
      }
      else {
        if (
          tasks[0].activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
          tasks[0].activityType === CirculationActivityType.FILE_HANDOVER
        ) {
          req$ = handoverService.circulationTaskHandoverTakeover(params, SKIP_ERROR_DIALOG);
        }
        else if (tasks[0].activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS) {
          req$ = handoverToOwnHandsService.circulationTaskHandoverToOwnHandsTakeover(params, SKIP_ERROR_DIALOG);
        }
      }
    } else {
      // bulk api is common for any takeover regardless of CirculationActivityType
      const bulkRequestData = tasks.map(task => task.id);
        req$ = this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsTakeover({body: bulkRequestData});
    }

    this.globalLoadingService.doLoading(
      req$!
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe({
      next: resultTask => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_TAKEN_OVER);
        if (resultTask && tasks.length === 1) {
          if (
            tasks[0].taskType === CirculationTaskType.TAKEOVER_BY_TARGET ||
            tasks[0].taskType === CirculationTaskType.TAKEOVER_DUPLICATE_BY_TARGET
          ) {
            this.dispatchTaskSuccessToast(tasks[0], resultTask!.id);
          }
          if (extraActionOnComplete === ExtraActionOnTaskComplete.REGISTER_CONTENT_OFFICER) {
            this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, tasks[0].documentId!, DocumentsRoute.REGISTER_CONTENT_OFFICER));
          } else if (extraActionOnComplete === ExtraActionOnTaskComplete.REGISTER_CONTENT_FILING_OFFICE) {
            this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.DOCUMENTS, tasks[0].documentId!, DocumentsRoute.REGISTER_CONTENT_FILING_OFFICE));
          }
        } else {
          const templateData = {
            [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationTaskType.${CirculationTaskType.TAKEOVER_BY_TARGET}`,
            [InternalNotificationKey.COUNT]: String(tasks.length),
          };
          this.circulationToastService.dispatchBulkHandoverTakeoverTaskToast(templateData);
        }
      },
      error: errorResponse => handleCirculationErrorAsToast(
        tasks[0],
        errorResponse,
        this.toastService,
        this.translateService,
        undefined,
        tasks[0].activityType
      ),
    });
  }

  private runTaskAction(taskData: ExtendedCirculationTaskDto[], selectedCirculationAction: (CirculationTaskAction)[], extraActionOnComplete?: ExtraActionOnTaskComplete) {

    if (selectedCirculationAction.includes(CirculationTaskAction.TAKEOVER_BY_TARGET)) {
      this.onTakeOver(taskData, extraActionOnComplete);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.STATEMENT_DONE_BY_TARGET)) {
      this.onApproveWithStatementClick(taskData);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.ACKNOWLEDGE_BY_TARGET)) {
      this.onAcknowledgeClick(taskData);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.APPROVE_DOCUMENT_BY_TARGET)) {
      this.approveDocument(taskData);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.APPROVE_COMPONENT_BY_TARGET)) {
      this.approveComponent(taskData);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.DISAPPROVE_DOCUMENT_BY_TARGET)) {
      this.disapproveDocument(taskData);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.DISAPPROVE_COMPONENT_BY_TARGET)) {
      this.disapproveComponent(taskData);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.REJECT_BY_TARGET)) {
      this.rejectTask(taskData);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.RETURN_BY_TARGET)) {
      this.returnForRegisterContentTask(taskData);
    } else if (selectedCirculationAction.includes(CirculationTaskAction.PASS_BY_TARGET)) {
      this.onPassToNextHandlerClick(taskData);
    }
  }

  private onCirculationTaskClick(taskData: ExtendedCirculationTaskDto[],
                                 authorizedOperations: GeneralAuthorizedOperation[],
                                 availableTasks: AvailableTaskActions[],
                                 selectedCirculationActions: CirculationTaskAction[],
                                 extraActionOnComplete?: ExtraActionOnTaskComplete,
  ) {
    const operationToTitleMapping: Partial<Record<CirculationTaskAction, string>> = {
      [CirculationTaskAction.TAKEOVER_BY_TARGET]: 'Převzít',
      [CirculationTaskAction.TAKEOVER_BY_INITIATOR]: 'Převzít',
      [CirculationTaskAction.STATEMENT_DONE_BY_TARGET]: 'Vyjádřit se',
      [CirculationTaskAction.ACKNOWLEDGE_BY_TARGET]: 'Vzít na vědomí',
      [CirculationTaskAction.APPROVE_DOCUMENT_BY_TARGET]: 'Schválit dokument',
      [CirculationTaskAction.APPROVE_COMPONENT_BY_TARGET]: 'Schválit komponentu',
      [CirculationTaskAction.DISAPPROVE_DOCUMENT_BY_TARGET]: 'Neschválit dokument',
      [CirculationTaskAction.DISAPPROVE_COMPONENT_BY_TARGET]: 'Neschválit komponentu',
      [CirculationTaskAction.REJECT_BY_TARGET]: 'Odmítnout úkol',
      [CirculationTaskAction.PASS_BY_TARGET]: 'Předat dál',
      [CirculationTaskAction.RETURN_BY_TARGET]: 'Vrátit k evidenci',
    };

    this.bulkOperationValidationService.validateEsslObjects<ExtendedCirculationTaskDto>(
      {
        dialogWarningLabel: 'Nekteré úkoly ({{errorCount}}) není možné vykonat. Hromadná operace bude provedena pro vyhující úkoly ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [],
        esslObjects: taskData.map(dd => this.circulationTaskToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        const filteredTasks = this.filterTasksByAvailableActions(selectedCirculationActions, availableTasks);

        if (filteredTasks.unavailableTasks.length) {
          this.openUnavailableActionsDialog(filteredTasks, operationToTitleMapping[selectedCirculationActions[0]]!).subscribe(result => {
            if (result.length) this.runTaskAction(filteredTasks.availableTasks, selectedCirculationActions, extraActionOnComplete);
          });
        }
        else {
          this.runTaskAction(filteredTasks.availableTasks, selectedCirculationActions, extraActionOnComplete);
        }
      }
    });
  }

  private onCirculationOperationClick(
    titleTemplate: string,
    titleTemplateBulk: string,
    modalComponent: Type<AbstractHandoverFormModalComponent>,
    circulationActivityType: CirculationActivityType,
    dialogWarningLabel: string,
    selectionData: ExtendedCirculationTaskDto[],
    validators: OperationValidator<ExtendedCirculationTaskDto>[],
    authorizedOperations: GeneralAuthorizedOperation[],
    allSelectedTasks: AvailableTaskActions[],
    selectedCirculationAction: CirculationTaskAction
  ) {
    const validateOperation = (tasks: ExtendedCirculationTaskDto[]) => {
      this.bulkOperationValidationService.validateEsslObjects<ExtendedCirculationTaskDto>(
        {
          dialogWarningLabel,
          dialogWarningLabelContext: {},
          operationValidators: validators ?? [],
          esslObjects: tasks.map(dd => this.circulationTaskToValidationObject(dd)),
          authorizedOperations,
        },
        this,
      ).subscribe(validatedObjects => {
        if (validatedObjects && validatedObjects.length > 0) {
          const openModal = (tasks: ExtendedCirculationTaskDto[]) => {
            this.openCirculationModal(
              titleTemplate,
              titleTemplateBulk,
              modalComponent,
              tasks,
              TaskToolbarAction.TASK_TAKEN_OVER_AND_PASSED
            ).subscribe(result => {
              if (result) {
                if (tasks.length === 1) {
                  const templateData = this.getCirculationSuccessToastTemplateDataForDocumentAndFile(tasks[0], result);
                  this.circulationToastService.dispatchHandoverTaskSuccessTargetParticipant(templateData);
                } else {
                  this.circulationToastService.dispatchBulkHandoverTakeoverTaskToast({
                    [InternalNotificationKey.ACTIVITY_TYPE_KEY]: CirculationActivityType.DOCUMENT_HANDOVER,
                    [InternalNotificationKey.COUNT]: tasks.length,
                  });
                }
                this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
              }
            });
          };
          const filteredTasks = this.filterTasksByAvailableActions([selectedCirculationAction], allSelectedTasks);

          if (filteredTasks.unavailableTasks.length) {
            this.openUnavailableActionsDialog(filteredTasks, 'Převzít a předat dál').subscribe(result => {
              if (result.length) openModal(filteredTasks.availableTasks);
            });
          }
          else {
            openModal(filteredTasks.availableTasks);
          }
        }
      });
    };

    if (circulationActivityType === CirculationActivityType.FILE_HANDOVER) {
      const fileIds = selectionData.filter(task => isFileEntity(task.circulationEntityType)).map(task => task.fileId!);
      this.globalLoadingService.doLoading(this.apiCirculationActivityService.circulationActivityExecuteFileHandoverPreconditionsCheck({body: fileIds})).subscribe(preconditionResult => {
        validators.push(TaskOperationValidators.selectedFileHandoverPreconditionCheckFailed(preconditionResult, this.translateService));
        validateOperation(selectionData);
      });
    } else {
      validateOperation(selectionData);
    }
  }

  onTakeoverAndRegisterContent(selection: ExtendedCirculationTaskDto[], authorizedOperations: GeneralAuthorizedOperation[], availableTasks: AvailableTaskActions[]) {
    this.onCirculationTaskClick(selection, authorizedOperations, availableTasks, [CirculationTaskAction.TAKEOVER_BY_TARGET, CirculationTaskAction.TAKEOVER_BY_INITIATOR]);
  }

  onApproveWithStatementClick(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.openWorkflowTextResponseDialog(
        'Předání ({{count}}) dokumentů s vyjádřením',
        TaskToolbarAction.TASK_STATEMENT_MADE,
        {
          infoAlertTemplate: 'Dokumenty budou předány zadavatelum úkolú',
          infoAlertTemplateContext: {},
          responseFieldLabel: 'Poznámka k vyjádření',
          primaryButtonLabel: 'Dát k vyjádření',
          responseHandlerFn: response => this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsGiveStatement(
            {
              body: taskData.map(task => ({
                taskId: task.id!,
                settlementNote: response
              })),
            },
            SKIP_ERROR_DIALOG
          ).pipe(
            tap({
              next: _ => {
                const templateData = {
                  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationTaskType.${CirculationTaskType.STATEMENT_FROM_TARGET}`,
                  [InternalNotificationKey.COUNT]: String(taskData.length),
                };
                this.circulationToastService.dispatchBulkTaskToast(templateData, CirculationToastType.FLOW_BULK_STATEMENT_BY_TARGET);
                this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
                this.announceActionCompleted(TaskToolbarAction.TASK_STATEMENT_MADE);
              },
              error: _ => this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_BULK_STATEMENT_BY_TARGET_ERROR, [{[InternalNotificationKey.ACTIVITY_TYPE_KEY]: CirculationActivityType.DOCUMENT_STATEMENT}], SEsslActivityErrorData)
            }),
          )
        },
        taskData
      );
    } else {
      this.getInitUserInfo$(taskData[0].rootActivityId).pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(userInfo => {
        this.openWorkflowTextResponseDialog(
          'Předání dokumentu s vyjádřením {{refNumber}}',
          TaskToolbarAction.TASK_STATEMENT_MADE,
          {
            infoAlertTemplate: 'Dokument bude předán zadavateli úkolu - {{userFullName}} ({{functionalPositionName}})',
            infoAlertTemplateContext: {
              userFullName: userInfo.userFullName,
              functionalPositionName: userInfo.functionalPositionName,
            },
            responseFieldLabel: 'Poznámka k vyjádření',
            primaryButtonLabel: 'Dát k vyjádření',
            responseHandlerFn: response => this.globalLoadingService.doLoading(this.apiCirculationTaskStatementService.circulationTaskStatementStatement(
              {
                id: taskData[0].id,
                body: {
                  settlementNote: response,
                },
              },
              SKIP_ERROR_DIALOG
            )).pipe(
              tap({
                next: resultTask => {
                  this.dispatchTaskSuccessToast(taskData[0], resultTask.id);
                  this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
                  this.announceActionCompleted(TaskToolbarAction.TASK_STATEMENT_MADE);
                },
                error: errorResponse => handleCirculationErrorAsToast(
                  taskData[0],
                  errorResponse,
                  this.toastService,
                  this.translateService,
                  undefined,
                  CirculationActivityType.DOCUMENT_STATEMENT
                ),
              }),
            )
          },
          taskData,
        );
      });
    }
  }

  onAcknowledgeClick(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.globalLoadingService.doLoading(
        this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsAcknowledge(
          {
            body: taskData.map(task => task.id!),
          },
          SKIP_ERROR_DIALOG
        )
      ).subscribe({
        next: _ => {
          const templateData = {
            [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationTaskType.${CirculationTaskType.ACKNOWLEDGEMENT_BY_TARGET}`,
            [InternalNotificationKey.COUNT]: String(taskData.length),
          };
          this.circulationToastService.dispatchBulkTaskToast(templateData, CirculationToastType.FLOW_BULK_ACKNOWLEDGEMENT_BY_TARGET);
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(TaskToolbarAction.TASK_ACKNOWLEDGED);
        },
        error: _ => this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_BULK_ACKNOWLEDGEMENT_BY_TARGET_ERROR, [{[InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationTaskType.${CirculationTaskType.ACKNOWLEDGEMENT_BY_TARGET}`}], SEsslActivityErrorData)
      });
    } else {
      this.globalLoadingService.doLoading(
        this.apiCirculationTaskAcknowledgementService.circulationTaskAcknowledgementAcknowledge(
          {
            id: taskData[0].id,
          },
          SKIP_ERROR_DIALOG
        )
      ).subscribe({
        next: resultTask => {
          this.dispatchTaskSuccessToast(taskData[0], resultTask.id);
          this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
          this.announceActionCompleted(TaskToolbarAction.TASK_ACKNOWLEDGED);
        },
        error: errorResponse => handleCirculationErrorAsToast(
          taskData[0],
          errorResponse,
          this.toastService,
          this.translateService,
          undefined,
          CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT
        ),
      });
    }

  }

  onPassToNextHandlerClick(taskData: ExtendedCirculationTaskDto[]) {
    if (taskData.length > 1) {
      this.onBulkPassToNextHandler(taskData);
    } else {
      if (taskData[0].taskType === CirculationTaskType.TAKEOVER_BY_TARGET) {
        if (taskData[0].circulationEntityType === EntityType.FILE) {
          this.onPassObjectToNextHandlerClick('Předat spis dál - {{refNumber}}', taskData[0]);
        }
        else {
          this.onPassObjectToNextHandlerClick('Předat dokument dál - {{refNumber}}', taskData[0]);
        }
      }
      else if (taskData[0].taskType === CirculationTaskType.HANDOVER_TO_OWN_HANDS_TAKEOVER_BY_TARGET) {
        this.onPassObjectToNextHandlerClick('Předat dokument dál - {{refNumber}}', taskData[0]);
      }
      else {
        this.onPassGenericActivityToNextHandlerClick(taskData[0]);
      }
    }
  }

  onBulkPassToNextHandler(taskData: ExtendedCirculationTaskDto[]) {
    this.openCirculationModal(
      '',
      'Hromadně předat dál ({{count}})',
      PassHandoverToNextHandlerDialogComponent,
      taskData,
      TaskToolbarAction.TASK_PASSED
    ).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        if (taskData.length === 1) {
          this.dispatchTaskPassedToast(taskData[0], result);
        } else {
          this.circulationToastService.dispatchBulkPassToNextTaskToast({
            [InternalNotificationKey.COUNT]: taskData.length,
          });
        }
      }
    });
  }

  private onPassObjectToNextHandlerClick(modalTitleTemplate: string, taskData: ExtendedCirculationTaskDto) {
    this.openCirculationModal(
      modalTitleTemplate,
      '',
      PassHandoverToNextHandlerDialogComponent,
      [taskData],
      TaskToolbarAction.TASK_PASSED
    ).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.dispatchTaskPassedToast(taskData, result);
      }
    });
  }

  onPassGenericActivityToNextHandlerClick(taskData: ExtendedCirculationTaskDto) {
    const isComponentCirculation = COMPONENT_ENTITY_TYPES.includes(taskData.circulationEntityType);
    const translatedActivityType = this.translateService.instant(`en.circulationActivityType.${taskData.activityType}`).toLowerCase();

    this.openCirculationModal(
      this.translateParser.interpolate(
        (
          isComponentCirculation ?
            'Předat dál {{translatedActivityType}} - {{componentName}}' :
            'Předat dál {{translatedActivityType}} - {{refNumber}}'
        ),
        {translatedActivityType}
      ) ?? 'Předat dál',
      '',
      GenericPassToNextHandlerDialogComponent,
      [taskData],
      TaskToolbarAction.TASK_PASSED
    ).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.dispatchTaskPassedToast(taskData, result);
      }
    });
  }

  private openWorkflowTextResponseDialog(
    titleTemplate: string,
    action: TaskToolbarAction,
    dialogParams: WorkflowTextResponseDialogParams,
    taskData: ExtendedCirculationTaskDto[],
  ) {
    let titleTemplateContext: Record<string, string>;
    if (taskData.length > 1) {
      titleTemplateContext = {
        count: String(taskData.length)
      };
    } else {
      titleTemplateContext = {
      ...taskData[0],
          refNumber: taskData[0].refNumber ?? DOC_WITHOUT_REF_NUMBER,
      } as unknown as Record<string, string>;
    }

    return this.modalService.openComponentInModal<WorkflowTextResponseDialogResult, WorkflowTextResponseDialogParams>({
      component: WorkflowTextResponseDialogComponent,
      modalOptions: {
        width: 650,
        height: 500,
        titleTemplate,
        titleTemplateContext,
      },
      data: dialogParams,
    }).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(action);
      }
    });
  }

  private openCirculationModal(
    titleTemplate: string,
    titleTemplateBulk: string,
    modalComponent: Type<AbstractHandoverFormModalComponent>,
    taskData: Nullable<ExtendedCirculationTaskDto>[],
    successAction: string
  ) {
    return openGeneralCirculationModal(
      // @ts-ignore -- circumvent private property error
      this,
      titleTemplate,
      titleTemplateBulk,
      modalComponent,
      taskData,
      successAction
    );
  }

  private getInitUserInfo$(activityId: number): Observable<UserInfo> {
    return this.apiCirculationActivityService.circulationActivityFindById({id: activityId}).pipe(
      switchMap(taskActivity => combineLatest([
        getUserFullNameById$(this.codebookService, taskActivity.initUserId),
        this.codebookService.functionalPositions().pipe(
          map(functionalPositions => functionalPositions.find(
            fp => fp.id === taskActivity.initFunctionalPositionId)!.name
          ),
        )
      ])),
      map(([userFullName, functionalPositionName]) => ({userFullName, functionalPositionName})),
    );
  }

  private dispatchTaskRejectedToast(taskData: ExtendedCirculationTaskDto, response: string, newTaskId: number) {
    if (taskData.circulationEntityType === EntityType.FILE || DOCUMENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      const templateData = {
        [InternalNotificationKey.REASON]: response,
        [InternalNotificationKey.DOCUMENT_ID]: String(taskData.documentId),
        [InternalNotificationKey.DOCUMENT_SUBJECT]: taskData.subject,
        [InternalNotificationKey.TASK_ID]: newTaskId,
        [InternalNotificationKey.REF_NUMBER]: taskData.refNumber ?? this.translateService.instant(DOC_WITHOUT_REF_NUMBER),
        [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${taskData.activityType}`),
        [InternalNotificationKey.ENTITY_TYPE_CLASS]: entityTypeToEntityTypeClass(taskData.circulationEntityType),
      };

      if (
        taskData.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
        taskData.activityType === CirculationActivityType.FILE_HANDOVER
      ) {
        this.circulationToastService.dispatchHandoverTaskRejectedTargetParticipant(templateData);
      }
      else {
        this.circulationToastService.dispatchTaskRejectedTargetParticipant(templateData);
      }
    }
    else if (COMPONENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      this.esslComponentCirculationToastService.dispatchEsslComponentTaskRejectTargetParticipant(
        this.getCirculationSuccessToastTemplateDataForComponent(taskData)
      );
    }
  }

  private getCirculationSuccessToastTemplateDataForComponent(taskData: ExtendedCirculationTaskDto) {
    return {
      [InternalNotificationKey.ENTITY_TYPE]: taskData.circulationEntityType.toLowerCase(),
      [InternalNotificationKey.ESSL_COMPONENT_LABEL]: taskData.componentLabel!,
      [InternalNotificationKey.DOCUMENT_ID]: taskData.documentId!,
      [InternalNotificationKey.ESSL_COMPONENT_ID]: taskData.componentId!,
      [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: taskData.digitalComponentVersionId!,
      [InternalNotificationKey.ACTIVITY_ID]: taskData.activityId,
      [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${taskData.activityType}`),
    };
  }

  private getCirculationSuccessToastTemplateDataForDocumentAndFile(taskData: ExtendedCirculationTaskDto, newTaskId: number) {
    return {
      [InternalNotificationKey.DOCUMENT_ID]: String(isFileEntity(taskData.circulationEntityType) ? taskData.fileId : taskData.documentId),
      [InternalNotificationKey.DOCUMENT_SUBJECT]: taskData.subject,
      [InternalNotificationKey.TASK_ID]: newTaskId,
      [InternalNotificationKey.REF_NUMBER]: taskData.refNumber ?? this.translateService.instant(DOC_WITHOUT_REF_NUMBER),
      [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${taskData.activityType}`),
      [InternalNotificationKey.ENTITY_TYPE_CLASS]: entityTypeToEntityTypeClass(taskData.circulationEntityType),
    };
  }

  private dispatchTaskRevokedToast(taskData: ExtendedCirculationTaskDto, response: string) {
    if (taskData.circulationEntityType === EntityType.FILE || DOCUMENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      const templateData = {
        [InternalNotificationKey.REASON]: response,
        [InternalNotificationKey.DOCUMENT_ID]: String(taskData.documentId),
        [InternalNotificationKey.DOCUMENT_SUBJECT]: taskData.subject,
        [InternalNotificationKey.ACTIVITY_ID]: taskData.activityId,
        [InternalNotificationKey.REF_NUMBER]: taskData.refNumber ?? this.translateService.instant(DOC_WITHOUT_REF_NUMBER),
        [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${taskData.activityType}`),
        [InternalNotificationKey.ENTITY_TYPE_CLASS]: entityTypeToEntityTypeClass(taskData.circulationEntityType),
      };

      if (
        taskData.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
        taskData.activityType === CirculationActivityType.FILE_HANDOVER
      ) {
        this.circulationToastService.dispatchHandoverTaskRevokedInitiator(templateData);
      } else {
        this.circulationToastService.dispatchTaskRevokedInitiator(templateData);
      }
    }
    else if (COMPONENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      this.esslComponentCirculationToastService.dispatchEsslComponentRevokeInitiator(this.getCirculationSuccessToastTemplateDataForComponent(taskData));
    }
  }

  private dispatchTaskSuccessToast(taskData: ExtendedCirculationTaskDto, newTaskId: number) {
    if (taskData.circulationEntityType === EntityType.FILE || DOCUMENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      const templateData = this.getCirculationSuccessToastTemplateDataForDocumentAndFile(taskData, newTaskId);

      if (
        taskData.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
        taskData.activityType === CirculationActivityType.FILE_HANDOVER
      ) {
        this.circulationToastService.dispatchHandoverTaskSuccessTargetParticipant(templateData);
      }
      else {
        this.circulationToastService.dispatchTaskSuccessTargetParticipant(templateData);
      }
    }
    else if (COMPONENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      const templateData = {
        [InternalNotificationKey.ENTITY_TYPE]: taskData.circulationEntityType,
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: taskData.componentLabel!,
        [InternalNotificationKey.DOCUMENT_ID]: taskData.documentId!,
        [InternalNotificationKey.ESSL_COMPONENT_ID]: taskData.componentId!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: taskData.digitalComponentVersionId!,
        [InternalNotificationKey.ACTIVITY_ID]: taskData.activityId,
        [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${taskData.activityType}`),
      };

      this.esslComponentCirculationToastService.dispatchTaskSuccessTargetParticipant(templateData);
    }
  }

  private dispatchTaskPassedToast(taskData: ExtendedCirculationTaskDto, newTaskId: number) {
    if (taskData.circulationEntityType === EntityType.FILE || DOCUMENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      const templateData = this.getCirculationSuccessToastTemplateDataForDocumentAndFile(taskData, newTaskId);

      if (
        taskData.activityType === CirculationActivityType.DOCUMENT_HANDOVER ||
        taskData.activityType === CirculationActivityType.FILE_HANDOVER
      ) {
        this.circulationToastService.dispatchHandoverTaskPassedTargetParticipant(templateData);
      }
      else {
        this.circulationToastService.dispatchTaskPassedTargetParticipant(templateData);
      }
    }
    else if (COMPONENT_ENTITY_TYPES.includes(taskData.circulationEntityType)) {
      this.esslComponentCirculationToastService.dispatchEsslComponentTaskPassedTargetParticipant(
        this.getCirculationSuccessToastTemplateDataForComponent(taskData)
      );
    }
  }

  onExternalApplicationTakeoverClick(selection: ExtendedCirculationTaskDto[]) {
    const requestData: CirculationActivityIssdBaseDto[] = [];
    selection.forEach(s => {
      if (s.documentId) {
        requestData.push({
          circulationEntityType: EntityType.DOCUMENT,
          documentId: s.documentId,
        });
      } else {
        requestData.push({
          circulationEntityType: EntityType.FILE,
          fileId: s.fileId,
        });
      }
    });
    this.apiCirculationActivityService.circulationActivityFinishIssdActivity(
      {
        body: requestData
      }
    ).subscribe(_ => {
      // todo(mh) BE sometimes sends response before transaction is completly finished and following refresh fetches "old" data to table
      setTimeout(() => {
        if (selection.length > 1) {
          this.circulationToastService.dispatchBulkTakeoverReturnedFromExternalApp({
            [InternalNotificationKey.COUNT]: String(selection.length)
          });
        } else {
          let templateData: CirculationHandoverTaskToExternalAppData;
          if (isDocumentEntity(requestData[0].circulationEntityType)) {
            templateData = {
              [InternalNotificationKey.REF_NUMBER]: String(selection[0].refNumber),
              [InternalNotificationKey.ENTITY_TYPE]: EntityType.DOCUMENT,
              [InternalNotificationKey.ID]: String(selection[0].documentId),
              [InternalNotificationKey.SUBJECT]: String(selection[0].subject),
            };
          } else {
            templateData = {
              [InternalNotificationKey.REF_NUMBER]: String(selection[0].refNumber),
              [InternalNotificationKey.ENTITY_TYPE]: EntityType.FILE,
              [InternalNotificationKey.ID]: String(selection[0].fileId),
              [InternalNotificationKey.SUBJECT]: String(selection[0].subject),
            };
          }
          this.circulationToastService.dispatchTakeoverReturnedFromExternalApp(templateData);
        }
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(TaskToolbarAction.TASK_TAKEN_OVER_FROM_EXTERNAL_APP);
      }, 500);
    });
  }


  filterTasksByAvailableActions(selectedCirculationAction: (CirculationTaskAction)[], circulationTasksWithActions: AvailableTaskActions[]) {
    const filteredActions: AvailableCirculationActionsDialogData = {
      availableTasks: [],
      unavailableTasks:  [],
      continueButtonText: 'Pokračovat s ({{count}})',
    };
    circulationTasksWithActions.forEach(task => {
      if (task.availableActions.some(action => selectedCirculationAction.includes(action))) {
        filteredActions.availableTasks.push(task.circulationTask);
      } else {
        if (task.circulationTask.circulationEntityType === EntityType.FILE || DOCUMENT_ENTITY_TYPES.includes(task.circulationTask.circulationEntityType)) {
          filteredActions.unavailableTasks.push(`${task.circulationTask.refNumber ?? this.translateService.instant(DOC_WITHOUT_REF_NUMBER)} - ${task.circulationTask.subject}`);
        } else if (COMPONENT_ENTITY_TYPES.includes(task.circulationTask.circulationEntityType)) {
          filteredActions.unavailableTasks.push(`${task.circulationTask.componentLabel}`);
        }
      }
    });

    return filteredActions;
  }

  openUnavailableActionsDialog(actions: AvailableCirculationActionsDialogData, title: string) {
    return this.modalService.openComponentInModal<ExtendedCirculationTaskDto[], AvailableCirculationActionsDialogData>({
      component: AvailableCirculationActionsDialogComponent,
      modalOptions: {
        width: 650,
        height: 500,
        titleTemplate: title,
      },
      data: actions,
    }).pipe(
      map(result => result ?? []),
    );
  }
}
