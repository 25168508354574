import {Component, OnInit} from '@angular/core';
import {of, pipe} from 'rxjs';
import {debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {IczTableFilterWithList} from '../../../abstract-table-filter.component';
import {EnumFilterItem, isListFilterItem} from '../../../filter.types';
import {
  FormAutocompleteComponent,
  FormChipInputComponent,
  FormInlineSelectComponent,
  IczOption,
  makeDefaultOptionsDefinition,
  OptionsDefinitionFactory
} from '@icz/angular-form-elements';
import {ReactiveFormsModule} from '@angular/forms';
import {InaccessibleDirective} from '@icz/angular-essentials';

/**
 * @internal
 */
@Component({
  selector: 'icz-enum-filter',
  templateUrl: './enum-filter.component.html',
  styleUrls: ['./enum-filter.component.scss'],
  standalone: true,
  imports: [
    FormInlineSelectComponent,
    FormChipInputComponent,
    FormAutocompleteComponent,
    ReactiveFormsModule,
    InaccessibleDirective,
  ],
})
export class EnumFilterComponent extends IczTableFilterWithList implements OnInit {

  override set item(newItem: EnumFilterItem) {
    this._item = newItem;
  }
  override get item(): EnumFilterItem {
    return this._item as EnumFilterItem;
  }

  protected get useCustomChipsFilter() {
    return isListFilterItem(this.item) && this.item.useCustomChips && this.item.chipNamespace && !this.item.findListCb;
  }

  protected get simpleListFilter() {
    return isListFilterItem(this.item) && !(this.item.useCustomChips && this.item.chipNamespace) && !this.item.findListCb;
  }

  protected get backendListFilter() {
    return isListFilterItem(this.item) && this.item.findListCb;
  }

  ngOnInit() {
    this.initForm();
    this.initOperatorAndValueChangeHandlers();
  }

  protected makeSearchTermOptionsDefinition: OptionsDefinitionFactory = (options$, strForSearch) => {
    const defaultDefinition = makeDefaultOptionsDefinition(options$, strForSearch);

    defaultDefinition.searchtermToOptionsOperator = pipe(
      debounceTime(300),
      switchMap(searchTerm => {
        if (isListFilterItem(this.item) && searchTerm.length >= (this.item.minSearchTermLength ?? 3)) {
          return this.item.findListCb!(searchTerm);
        } else {
          return of([]);
        }
      }),
      map(items => items.map(item => ({value: item.id, label: item.name})) as IczOption[]),
      tap(options => {
        this.item.list = options;
      })
    );

    return defaultDefinition;
  };

  protected applyFilter(): void {
    this.applyFilterOperator();

    if (!this.hasNoValueFilterOperator) {
      this.emitFilterValue();
    }

    this.closeFilterPopup();
  }

}
