import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IconComponent} from '../icon/icon.component';

/**
 * A simple label with Up/Down chevron icon which changes based on
 * passed expansion state to @Input expanded.
 */
@Component({
  selector: 'icz-section-expander',
  templateUrl: './section-expander.component.html',
  styleUrls: ['./section-expander.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, IconComponent],
})
export class SectionExpanderComponent {

  /**
   * Expander label.
   */
  @Input({required: true})
  label!: string;
  /**
   * Expansion state.
   */
  @Input()
  expanded = false;
  /**
   * Emits expansion state after the expander has been toggled by the user.
   */
  @Output()
  expandedChange = new EventEmitter<boolean>();

  protected toggleExpansionState() {
    this.expanded = !this.expanded;
    this.expandedChange.emit(this.expanded);
  }

}
