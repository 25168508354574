<div class="h-full col grow" [waiting]="loadingIndicatorService.isLoading$(this) | async">
  @if (dataSource) {
    <icz-table
      id="permission-settings-table"
      [dataSource]="dataSource"
      [columnsData]="columnsData"
      [config]="config"
    >
      <icz-label tableTitle [label]="tableTitle"></icz-label>
      <icz-label noDataLabel label="Žádná oprávnění nebyla nastavena"></icz-label>
      <icz-label notFoundLabel label="Žádná oprávnění nebyla nalezena"></icz-label>
      <icz-permission-add-toolbar
        underToolbar
        class="px-24 py-16"
        [entityId]="entityId"
        [entityType]="entityType"
        [disablePermissionToolbar]="isPermissionToolbarDisabled"
        [disableOrgUnitPermissions]="disableOrgUnitPermissions"
        (permissionAdded)="onPermissionAdded()"
        [manageSharingAuthorizedOperation]="manageSharingAuthorizedOperation"
        [authorizationResult]="authorizationResult"
      ></icz-permission-add-toolbar>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'sharedWith';">
        <div class="row gap-0 items-center" [iczTooltip]="getSharedWithTooltip(row) | async">
          @for (share of getFirstVisibleOrgStructureEntites(row); track share) {
            <div class="row gap-0">
              @if (share.organizationalStructureEntityType === OrganizationalStructureEntityType.FUNCTIONAL_POSITION) {
                <icz-icon size="small" svgIcon="complete_subject" class="mr-4"></icz-icon>
                <icz-label [label]="share.organizationalStructureEntityId | findFunctionalPositionById:true | async"></icz-label>
              }
              @if (share.organizationalStructureEntityType === OrganizationalStructureEntityType.ORGANIZATIONAL_UNIT) {
                <icz-icon size="small" svgIcon="hierarchy" class="mr-4 h-16"></icz-icon>
                <icz-label [label]="share.organizationalStructureEntityId | findOrganizationalUnitById | async"></icz-label>
              }
              @if (share.organizationalStructureEntityType === OrganizationalStructureEntityType.USER) {
                <icz-icon size="small" svgIcon="complete_subject" class="mr-4"></icz-icon>
                <icz-label [label]="share.organizationalStructureEntityId | findUserById | async"></icz-label>
              }
              @if (!$last) {
                ,&nbsp;
              }
            </div>
          }
          @if (row.sharedWith.length > VISIBLE_ORG_STRUCTURE_ENTITIES_COUNT) {
            <div>
              &nbsp;{{ getMoreFormattedText(row.sharedWith.length) }}
            </div>
          }
        </div>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'revoking'">
        <div class="items-center row gap-12">
          <icz-icon size="small" [svgIcon]="row.revoking ? 'cross' : 'checked'"></icz-icon>
          <icz-label [label]="row.inverted ? 'Zakázat' : 'Povolit'"></icz-label>
        </div>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'origin'">
        @if (row.documentId || row.fileId || row.storageUnitId) {
          <span>{{'en.authorizationBusinessReason.' + AuthorizationBusinessReason.SET_BY_USER | translate}}</span>
        }
        @else {
          <span>{{'en.authorizationBusinessReason.' + AuthorizationBusinessReason.ORG_UNIT_SHARING | translate}}</span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'permissions'">
        <div class="permission-cell">
          <icz-permission-selector
            [fieldDisabled]="!canUserManageSharing"
            [showInvertedStatus]="false"
            [value]="permissionRowToPermissionOptions(row)"
            [entityType]="entityType"
            (popupClosed)="onPermissionSelectorPopupClosed(row, $event)"
          ></icz-permission-selector>
        </div>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'rowActions'">
        @if (canUserManageSharing) {
          <icz-button
            (onAction)="deletePermission(row.id)"
            svgIcon="delete"
            size="large"
            class="delete-permission"
          ></icz-button>
        }
      </ng-container>
    </icz-table>
  }
</div>
