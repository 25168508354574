import {DestroyRef, Directive, inject, Input} from '@angular/core';
import {PrimitiveValueFormField} from './abstract-form-field';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczValidatorFn} from '../validators/icz-validators/validator-decorators';
import {IczFormControl, IczFormGroup} from '../icz-form-controls';
import {distinctUntilChanged} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ValidationErrors} from '@angular/forms';

/**
 * A type of form control which is able to either accept user's format of
 * a value (i.e. a DD.MM.YYYY date) or pick the same kind of data from a graphical popover.
 * @internal
 */
@Directive()
export abstract class AbstractPicker extends PrimitiveValueFormField implements IczOnChanges {

  /**
   * @see ValidationErrorsListComponent.showValidationStatus
   */
  @Input()
  showValidationStatus = true;

  protected abstract valueValidator: IczValidatorFn;

  protected destroyRef = inject(DestroyRef);

  protected readonly PICKER_ALWAYS_INVALID: IczValidatorFn = () => ({invalid: true});

  protected abstract _formGroup: IczFormGroup;

  protected _isOpen = false;
  protected _realValue: Nullable<string> = null;

  protected get valueInput() {
    return this._formGroup.get('valueInput') as IczFormControl;
  }

  /**
   * @internal
   */
  ngOnInit() {
    this.valueInput.statusChanges.pipe(
      // needed to stop recursion between statusChanges and unsetControlErrors
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(status => {
      if (status === 'INVALID') {
        this.markOuterControlAsAlwaysInvalid();
      } else if (status === 'VALID') {
        this.unsetControlErrors();
      }
    });
  }

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (!this.formControlName && changes.fieldDisabled) {
      if (this.fieldDisabled) {
        this.valueInput.disable();
      } else {
        this.valueInput.enable();
      }
    }
  }

  protected onInputFieldKeydown($event: KeyboardEvent): void {
    const keyPressed = $event.key;

    if (keyPressed === 'Enter') {
      $event.stopPropagation();
      this.enterPressed();
    } else if (keyPressed === 'ArrowDown') {
      this.openPopover();
    } else if (keyPressed === 'Escape') {
      if (this._isOpen) {
        $event.stopPropagation();
        this.closePopover();
      }
    }
  }

  private enterPressed(): void {
    if (this._isOpen) {
      this.closePopover();
    } else {
      this.openPopover();
    }
  }

  protected openPopover(): void {
    if (!this.fieldDisabled) {
      this._isOpen = true;
    }
  }

  protected closePopover(): void {
    this._isOpen = false;
  }

  protected valueInputBlurred(): void {
    if (!this.valueInput.errors) {
      this.blur.emit();
    }
  }

  // eslint-disable-next-line @typescript-eslint/ban-types -- precise null is required in AbstractControl#setErrors signature
  protected setControlErrors(errors: ValidationErrors | null) {
    this.valueInput.setErrors(errors);
    this.markOuterControlAsAlwaysInvalid();
  }

  protected unsetControlErrors() {
    this.valueInput.setErrors(null);
    this.valueInput.updateValueAndValidity({emitEvent: false});
    this.control.removeValidators(this.PICKER_ALWAYS_INVALID);
    this.control.setErrors(null);
    this.control.updateValueAndValidity();
  }

  protected markOuterControlAsAlwaysInvalid() {
    // this.control.setErrors is not enough as iczFormSubmit recomputes form validity
    // state by explicitly running validators of each field. Using an always-invalid kind
    // of validator ensures that the field stays invalid even after that recomputation.
    this.control.addValidators(this.PICKER_ALWAYS_INVALID);
  }

}
