import {ChangeDetectionStrategy, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {
  ApiClassificationSchemeService,
  ApiDisposalScheduleService,
  ClassificationSchemeDto,
  DisposalScheduleDto,
  DocumentTypeDto,
  EntityClassDto
} from '|api/codebook';
import {
  DocumentForm,
  DocumentState,
  EntityClassType,
  FileForm,
  FileHandlingType,
  FileState,
  RelatedObjectDto,
  RelatedObjectType, RetentionTriggerTypeCode,
  SubjectRecordDto
} from '|api/commons';
import {
  ApiDigitalComponentService,
  ApiMediumComponentService,
  ApiPaperComponentService,
  ApiPhysicalItemComponentService,
  DigitalComponentCompleteDto,
  MediumComponentDto,
  PageDigitalComponentCompleteDto,
  PaperComponentDto,
  PhysicalItemComponentDto
} from '|api/component';
import {OwnDocumentDto, ReceivedDocumentDto} from '|api/document';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {ElasticDocumentOrFile} from '../../model/document-file-sidebar.model';
import {enumToOptions, namedDtosToOptions, namedDtoToOption} from '../../../../core/services/data-mapping.utils';
import {CodebookService} from '../../../../core/services/codebook.service';
import {BasicRegistersService} from '../../basic-registers.service';
import {CodebookSearchService} from '../../../../services/codebook-search.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {DocumentTypeOption, EntityClassOption,} from '../../../../services/document-detail.service';
import {isDocumentObject, isFileObject} from '../../shared-document.utils';
import {findKeywordsByIDsToOptions} from '../../keyword-selector/keywords-selector.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {map} from 'rxjs/operators';
import {DisposalTipDetailLevel, WITHOUT_REF_NUMBER} from '../../shared-business-components.model';
import {ApiRelatedObjectService, ApiSubjectRecordElasticService} from '|api/subject-register';
import {IczOption, locateOptionByValue} from '@icz/angular-form-elements';

export function entityClassesToOptions() {
  return map(
    entries => (entries as EntityClassDto[]).filter(
      entry => (entry.type === EntityClassType.DOCUMENT_FILE)
    ).map<EntityClassOption>(
      entry => ({
        value: entry.id!,
        parent: entry.parentId!,
        label: [entry.fqcCode, entry.name].join(' - '),
        data: {
          disposalScheduleId: entry.disposalScheduleId,
          classificationSchemeId: entry.classificationSchemeId,
        }
      })
    )
  );
}

export function documentTypesToOptions() {
  return map((entries: DocumentTypeDto[]) => entries.filter(
    entry => (entry.nodeType === 'LEAF')
  ).map<DocumentTypeOption>(entry => ({
    value: entry.id!,
    parent: entry.parentId!,
    label: `${entry.code} ${entry.name}`,
    data: {
      disposalScheduleId: entry.disposalScheduleId,
      entityClassId: entry.entityClassId,
      statutoryPeriod: entry.statutoryPeriod,
    },
  })));
}

export function sortDigitalComponentsByVersions() {
  return map((entries: PageDigitalComponentCompleteDto) => entries!.content!.map(entry => ({
    ...entry,
    digitalComponentVersions: entry.digitalComponentVersions!.sort((v1, v2) => (v2.version! - v1.version!)),
  })))!;
}

@Component({
  selector: 'icz-document-file-sidebar-tab-overview',
  templateUrl: './document-file-sidebar-tab-overview.component.html',
  styleUrls: ['./document-file-sidebar-tab-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentFileSidebarTabOverviewComponent implements OnInit, IczOnChanges {

  private codebookService = inject(CodebookService);
  private basicRegistersService = inject(BasicRegistersService);
  private searchService = inject(CodebookSearchService);
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private apiPaperComponentService = inject(ApiPaperComponentService);
  private apiMediumComponentService = inject(ApiMediumComponentService);
  private apiPhysicalItemComponentService = inject(ApiPhysicalItemComponentService);
  private apiDisposalScheduleService = inject(ApiDisposalScheduleService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private apiClassificationSchemeService = inject(ApiClassificationSchemeService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private apiRelatedObjectNgService = inject(ApiRelatedObjectService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) object: Nullable<ElasticDocumentOrFile>;

  representingSubject: Nullable<SubjectRecordDto>;
  disposalSchedules!: DisposalScheduleDto[];
  functionalPositionsOptions$ = this.organizationalStructureService.functionalPositionsOptions();
  isObjectDeactivated = false;

  documentStateOptions = enumToOptions('documentState', DocumentState);
  fileStateOptions = enumToOptions('fileState', FileState);
  fileHandlingTypeOptions = enumToOptions('fileHandlingType', FileHandlingType);

  securityCategoryOptions$ = this.codebookService.securityCategories().pipe(
    namedDtosToOptions,
  );
  documentFormOptions: IczOption[] = enumToOptions('documentForm', DocumentForm);
  fileFormOptions: IczOption[] = enumToOptions('fileForm', FileForm);
  digitalComponents$!: Observable<DigitalComponentCompleteDto[]>;
  paperComponents$!: Observable<PaperComponentDto[]>;
  mediumComponents$!: Observable<MediumComponentDto[]>;
  physicalItemComponents$!: Observable<PhysicalItemComponentDto[]>;
  keywords$!: Observable<IczOption[]>;
  currentDisposalSchedule: Nullable<DisposalScheduleDto>;
  currentClassificationScheme: Nullable<ClassificationSchemeDto>;

  entityClassOptions: EntityClassOption[] = [];
  documentTypeOptions: DocumentTypeOption[] = [];
  fileTypeOptions: IczOption[] = [];
  basicRegisterOptions: IczOption[] = [];

  get retentionTriggerTypeCode(): Nullable<RetentionTriggerTypeCode> {
    if (this.object) {
      const entityClass = locateOptionByValue(this.entityClassOptions, this.object!.entityClassId);
      const disposalScheduleId = entityClass?.data?.disposalScheduleId;

      if (disposalScheduleId) {
        return this.disposalSchedules.find(
          ds => ds.id === disposalScheduleId
        )?.retentionTriggerTypeCode;
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }

  readonly DocumentForm = DocumentForm;
  readonly FileForm = FileForm;
  readonly FileState = FileState;
  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;
  readonly isDocumentObject = isDocumentObject;
  readonly isFileObject = isFileObject;
  readonly WITHOUT_REF_NUMBER = WITHOUT_REF_NUMBER;

  ngOnInit() {
    // TODO: only entityClasses for current classification Scheme
    this.codebookService.entityClasses().pipe(
      entityClassesToOptions()
    ).subscribe(options => {
      this.entityClassOptions = options;
    });

    this.codebookService.disposalSchedules().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(disposalSchedules => {
      this.disposalSchedules = disposalSchedules;
    });

    this.codebookService.documentTypes().pipe(
      documentTypesToOptions(),
    ).subscribe(options => this.documentTypeOptions = options);

    this.codebookService.fileTypes().subscribe(
      fileTypes => this.fileTypeOptions = fileTypes.map(namedDtoToOption())
    );

    this.basicRegistersService.getBasicRegisters().pipe(
      namedDtosToOptions,
    ).subscribe(options => this.basicRegisterOptions = options);
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.object?.currentValue) {
      const entityClass = locateOptionByValue(this.entityClassOptions, this.object!.entityClassId);

      if (isDocumentObject(this.object)) {
        this.digitalComponents$ = this.apiDigitalComponentService.digitalComponentFindDigitalComponents({
          documentId: this.object.id,
        }).pipe(
          sortDigitalComponentsByVersions(),
        );
        this.paperComponents$ = this.apiPaperComponentService.paperComponentGetDocumentPaperComponents({
          documentId: this.object.id,
        });
        this.mediumComponents$ = this.apiMediumComponentService.mediumComponentGetDocumentMediumComponents({
          documentId: this.object.id,
        });
        this.physicalItemComponents$ = this.apiPhysicalItemComponentService.physicalItemComponentGetDocumentPhysicalItemComponents({
          documentId: this.object.id,
        });

        this.isObjectDeactivated = this.object.documentState === DocumentState.DEACTIVATED;

        const documentType = locateOptionByValue(this.documentTypeOptions, (this.object as OwnDocumentDto | ReceivedDocumentDto).documentTypeId);

        if (documentType?.data?.disposalScheduleId || entityClass?.data?.disposalScheduleId) {
          this.apiDisposalScheduleService.disposalScheduleFindById({
            id: (documentType?.data?.disposalScheduleId ?? entityClass?.data?.disposalScheduleId) as number,
          }).subscribe(disposalSchedule => {
            this.currentDisposalSchedule = disposalSchedule;
          });
        }
        else {
          this.currentDisposalSchedule = null;
        }
      }
      else if (isFileObject(this.object)) {
        if (entityClass?.data?.disposalScheduleId) {
          this.apiDisposalScheduleService.disposalScheduleFindById({
            id: entityClass?.data?.disposalScheduleId,
          }).subscribe(disposalSchedule => {
            this.currentDisposalSchedule = disposalSchedule;
          });
        }
        else {
          this.currentDisposalSchedule = null;
        }

        this.isObjectDeactivated = this.object.fileState === FileState.DEACTIVATED;
      }

      if (entityClass?.data?.classificationSchemeId) {
        this.apiClassificationSchemeService.classificationSchemeFindById({id: entityClass.data.classificationSchemeId})
          .subscribe(classificationScheme => this.currentClassificationScheme = classificationScheme);
      }

      if (this.object!.keywordIds?.length) {
        this.keywords$ = findKeywordsByIDsToOptions(this.object!.keywordIds, this.searchService);
      }

      const related: RelatedObjectDto = {
        relatedObjectId: this.object!.id!,
        relatedObjectType: RelatedObjectType.DOCUMENT,
      };
      this.apiSubjectRecordNgElasticService.subjectRecordElasticGetRepresentingSubject({body: related}).subscribe(subject => {
        this.representingSubject = subject;
      });
    }
  }

}
