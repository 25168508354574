<icz-table-preview [opened]="opened" (closed)="closed.emit()" [withCustomPreviewHeader]="true">
  <div previewHeader>
    <div class="preview-header">
      <icz-label class="grow" [label]="object?.objectClass | objectClassName | translate" justifyContent="flex-start"></icz-label>
      <div class="grow"></div>
      <icz-button class="doc-detail-btn ml-16 mr-24" size="large" (onAction)="openDetail()" svgIcon="reference_list"
        [label]="isFileObject ? 'Detail spisu' : 'Detail dokumentu'">
      </icz-button>
      <icz-button svgIcon="close" background="transparent" (onAction)="closed.emit()"></icz-button>
    </div>
    <div class="preview-sections">
      @for (section of sections; track section.id) {
        <div
          [testingFeature]="!isSectionImplemented(section)"
          [testingFeatureLabel]="'N. Impl'"
          class="h-full"
          [class.cursor-default]="section.disabled"
          [class.opacity-50]="section.disabled"
          [iczTooltip]="getSidebarTabTooltip(section)"
          (click)="activateSection(section)">
          <div class="px-4">
            {{section.id | translate}} @if (section.showCount) {
            <span>({{section.count | numberToLocaleString }})</span>
          }
        </div>
        @if (activeSection === section.id) {
          <div class="active-line"></div>
        }
      </div>
    }
  </div>
</div>

@if (hasDocumentForFullPreview) {
  <div class="m-16">
    @if (activeSection === DocumentFileSidebarSections.OVERVIEW) {
      <icz-document-file-sidebar-tab-overview
        [object]="$any(object)"
      ></icz-document-file-sidebar-tab-overview>
    }
    @if (activeSection === DocumentFileSidebarSections.SUBJECTS) {
      <icz-document-file-sidebar-tab-subjects
        [object]="$any(object)"
      ></icz-document-file-sidebar-tab-subjects>
    }
    @if (activeSection === DocumentFileSidebarSections.RELATED) {
      <icz-document-file-sidebar-tab-related
        [object]="$any(object)"
      ></icz-document-file-sidebar-tab-related>
    }
    @if (activeSection === DocumentFileSidebarSections.DELIVERY) {
      <icz-document-file-sidebar-tab-delivery
        [object]="$any(object)"
      ></icz-document-file-sidebar-tab-delivery>
    }
  </div>
}
</icz-table-preview>
