/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { crossReferenceCreate } from '../fn/cross-reference/cross-reference-create';
import { CrossReferenceCreate$Params } from '../fn/cross-reference/cross-reference-create';
import { crossReferenceCreateStrongCrossReference } from '../fn/cross-reference/cross-reference-create-strong-cross-reference';
import { CrossReferenceCreateStrongCrossReference$Params } from '../fn/cross-reference/cross-reference-create-strong-cross-reference';
import { crossReferenceDelete } from '../fn/cross-reference/cross-reference-delete';
import { CrossReferenceDelete$Params } from '../fn/cross-reference/cross-reference-delete';
import { CrossReferenceDto } from '../models/cross-reference-dto';
import { crossReferenceFindAll } from '../fn/cross-reference/cross-reference-find-all';
import { CrossReferenceFindAll$Params } from '../fn/cross-reference/cross-reference-find-all';
import { crossReferenceGetCount } from '../fn/cross-reference/cross-reference-get-count';
import { CrossReferenceGetCount$Params } from '../fn/cross-reference/cross-reference-get-count';
import { crossReferenceGetStrongCrossReference } from '../fn/cross-reference/cross-reference-get-strong-cross-reference';
import { CrossReferenceGetStrongCrossReference$Params } from '../fn/cross-reference/cross-reference-get-strong-cross-reference';
import { crossReferencePrepareStrongCrossReference } from '../fn/cross-reference/cross-reference-prepare-strong-cross-reference';
import { CrossReferencePrepareStrongCrossReference$Params } from '../fn/cross-reference/cross-reference-prepare-strong-cross-reference';
import { crossReferenceRetrieveDocumentSettlementCrossReferences } from '../fn/cross-reference/cross-reference-retrieve-document-settlement-cross-references';
import { CrossReferenceRetrieveDocumentSettlementCrossReferences$Params } from '../fn/cross-reference/cross-reference-retrieve-document-settlement-cross-references';
import { crossReferenceSearch } from '../fn/cross-reference/cross-reference-search';
import { CrossReferenceSearch$Params } from '../fn/cross-reference/cross-reference-search';
import { CrossReferenceWithDetailDto } from '../models/cross-reference-with-detail-dto';
import { PageCrossReferenceWithDetailDto } from '../models/page-cross-reference-with-detail-dto';
import { StrongCrossReferenceDto } from '../models/strong-cross-reference-dto';
import { StrongCrossReferencePrepareResultDto } from '../models/strong-cross-reference-prepare-result-dto';
import { StrongCrossReferenceResultDto } from '../models/strong-cross-reference-result-dto';


/**
 * API for management of cross references
 */
@Injectable({ providedIn: 'root' })
export class ApiCrossReferenceService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `crossReferenceCreate()` */
  static readonly CrossReferenceCreatePath = '/document/cross-reference/{templateId}';

  /**
   * Creates weak cross reference between two entities. This is will be user-created cross reference.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceCreate$Response(params: CrossReferenceCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CrossReferenceDto>>> {
    return crossReferenceCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates weak cross reference between two entities. This is will be user-created cross reference.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceCreate(params: CrossReferenceCreate$Params, context?: HttpContext): Observable<Array<CrossReferenceDto>> {
    return this.crossReferenceCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CrossReferenceDto>>): Array<CrossReferenceDto> => r.body)
    );
  }

  /** Path part for operation `crossReferencePrepareStrongCrossReference()` */
  static readonly CrossReferencePrepareStrongCrossReferencePath = '/document/cross-reference/prepare-strong-cross-reference';

  /**
   * Prepares strong cross reference and returns set of newly referenced files
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferencePrepareStrongCrossReference()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferencePrepareStrongCrossReference$Response(params: CrossReferencePrepareStrongCrossReference$Params, context?: HttpContext): Observable<StrictHttpResponse<StrongCrossReferencePrepareResultDto>> {
    return crossReferencePrepareStrongCrossReference(this.http, this.rootUrl, params, context);
  }

  /**
   * Prepares strong cross reference and returns set of newly referenced files
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferencePrepareStrongCrossReference$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferencePrepareStrongCrossReference(params: CrossReferencePrepareStrongCrossReference$Params, context?: HttpContext): Observable<StrongCrossReferencePrepareResultDto> {
    return this.crossReferencePrepareStrongCrossReference$Response(params, context).pipe(
      map((r: StrictHttpResponse<StrongCrossReferencePrepareResultDto>): StrongCrossReferencePrepareResultDto => r.body)
    );
  }

  /** Path part for operation `crossReferenceRetrieveDocumentSettlementCrossReferences()` */
  static readonly CrossReferenceRetrieveDocumentSettlementCrossReferencesPath = '/document/cross-reference/find-settlement-by-response';

  /**
   * Finds cross references for given document id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceRetrieveDocumentSettlementCrossReferences()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceRetrieveDocumentSettlementCrossReferences$Response(params: CrossReferenceRetrieveDocumentSettlementCrossReferences$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CrossReferenceWithDetailDto>>> {
    return crossReferenceRetrieveDocumentSettlementCrossReferences(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds cross references for given document id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceRetrieveDocumentSettlementCrossReferences$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceRetrieveDocumentSettlementCrossReferences(params: CrossReferenceRetrieveDocumentSettlementCrossReferences$Params, context?: HttpContext): Observable<Array<CrossReferenceWithDetailDto>> {
    return this.crossReferenceRetrieveDocumentSettlementCrossReferences$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CrossReferenceWithDetailDto>>): Array<CrossReferenceWithDetailDto> => r.body)
    );
  }

  /** Path part for operation `crossReferenceCreateStrongCrossReference()` */
  static readonly CrossReferenceCreateStrongCrossReferencePath = '/document/cross-reference/create-strong-cross-reference';

  /**
   * Creates strong cross reference
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceCreateStrongCrossReference()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceCreateStrongCrossReference$Response(params: CrossReferenceCreateStrongCrossReference$Params, context?: HttpContext): Observable<StrictHttpResponse<StrongCrossReferenceResultDto>> {
    return crossReferenceCreateStrongCrossReference(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates strong cross reference
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceCreateStrongCrossReference$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceCreateStrongCrossReference(params: CrossReferenceCreateStrongCrossReference$Params, context?: HttpContext): Observable<StrongCrossReferenceResultDto> {
    return this.crossReferenceCreateStrongCrossReference$Response(params, context).pipe(
      map((r: StrictHttpResponse<StrongCrossReferenceResultDto>): StrongCrossReferenceResultDto => r.body)
    );
  }

  /** Path part for operation `crossReferenceFindAll()` */
  static readonly CrossReferenceFindAllPath = '/document/cross-reference/{entityType}/{entityId}';

  /**
   * Finds all cross references for given entity type and id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceFindAll$Response(params: CrossReferenceFindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<CrossReferenceWithDetailDto>>> {
    return crossReferenceFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all cross references for given entity type and id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceFindAll(params: CrossReferenceFindAll$Params, context?: HttpContext): Observable<Array<CrossReferenceWithDetailDto>> {
    return this.crossReferenceFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CrossReferenceWithDetailDto>>): Array<CrossReferenceWithDetailDto> => r.body)
    );
  }

  /** Path part for operation `crossReferenceSearch()` */
  static readonly CrossReferenceSearchPath = '/document/cross-reference/{entityType}/{entityId}/search-api';

  /**
   * Finds cross reference based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceSearch$Response(params: CrossReferenceSearch$Params, context?: HttpContext): Observable<StrictHttpResponse<PageCrossReferenceWithDetailDto>> {
    return crossReferenceSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds cross reference based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceSearch(params: CrossReferenceSearch$Params, context?: HttpContext): Observable<PageCrossReferenceWithDetailDto> {
    return this.crossReferenceSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageCrossReferenceWithDetailDto>): PageCrossReferenceWithDetailDto => r.body)
    );
  }

  /** Path part for operation `crossReferenceGetCount()` */
  static readonly CrossReferenceGetCountPath = '/document/cross-reference/{entityType}/{entityId}/get-count';

  /**
   * Get count of valid cross references for given entity type with specialization not equal FILE_DOCUMENT
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceGetCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceGetCount$Response(params: CrossReferenceGetCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return crossReferenceGetCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Get count of valid cross references for given entity type with specialization not equal FILE_DOCUMENT
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceGetCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceGetCount(params: CrossReferenceGetCount$Params, context?: HttpContext): Observable<number> {
    return this.crossReferenceGetCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `crossReferenceGetStrongCrossReference()` */
  static readonly CrossReferenceGetStrongCrossReferencePath = '/document/cross-reference/strong-cross-reference/{fileId}';

  /**
   * Gets collection of files connected to each other by strong cross reference. If result is empty collection that file with id {fileId} is not part of strong cross reference connection
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceGetStrongCrossReference()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceGetStrongCrossReference$Response(params: CrossReferenceGetStrongCrossReference$Params, context?: HttpContext): Observable<StrictHttpResponse<StrongCrossReferenceDto>> {
    return crossReferenceGetStrongCrossReference(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets collection of files connected to each other by strong cross reference. If result is empty collection that file with id {fileId} is not part of strong cross reference connection
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceGetStrongCrossReference$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  crossReferenceGetStrongCrossReference(params: CrossReferenceGetStrongCrossReference$Params, context?: HttpContext): Observable<StrongCrossReferenceDto> {
    return this.crossReferenceGetStrongCrossReference$Response(params, context).pipe(
      map((r: StrictHttpResponse<StrongCrossReferenceDto>): StrongCrossReferenceDto => r.body)
    );
  }

  /** Path part for operation `crossReferenceDelete()` */
  static readonly CrossReferenceDeletePath = '/document/cross-reference';

  /**
   * Delete cross reference templates by list of ids.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `crossReferenceDelete()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceDelete$Response(params: CrossReferenceDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return crossReferenceDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete cross reference templates by list of ids.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `crossReferenceDelete$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  crossReferenceDelete(params: CrossReferenceDelete$Params, context?: HttpContext): Observable<void> {
    return this.crossReferenceDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
