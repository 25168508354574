/** @internal */
export const TAB = 9;
/** @internal */
export const LEFT_ARROW = 37;
/** @internal */
export const RIGHT_ARROW = 39;
/** @internal */
export const BACK_SPACE = 8;
/** @internal */
export const DELETE = 46;

/** @internal */
export const SPECIAL_CHARACTERS = [' ', '/', '(', ')', '+', '\/', '-', '@', '.', ':'];

/** @internal */
export function overWriteCharAtPosition(input: HTMLInputElement, cursorPos: number, key: string) {
  const currentValue = input.value;

  input.value = currentValue.slice(0, cursorPos) +
    key + currentValue.slice(cursorPos + 1);
}

/**
 * Checks if a given character is a surrogate pair
 * (typically emojis with skin color etc.)
 */
export function isCharacterSurrogatePair(char: string) {
  return /[\uD800-\uDFFF]/.test(char);
}
