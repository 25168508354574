import {StorageUnitFillerDto} from '|api/document';
import {ButtonDisablerFn} from '../../../button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {StorageUnitFillerToolbarValidators} from './storage-unit-filler-toolbar.validators';

export class StorageUnitFillerToolbarDisablers {
  static isStorageUnitRunning(selection: Nullable<StorageUnitFillerDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, StorageUnitFillerToolbarValidators.isStorageUnitRunning());
    };
  }

  static isStorageUnitFinished(selection: Nullable<StorageUnitFillerDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, StorageUnitFillerToolbarValidators.isStorageUnitFinished());
    };
  }
}
