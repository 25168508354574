import {StorageUnitDto} from '|api/document';
import {ButtonDisablerFn} from '../../../button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {StorageUnitToolbarValidators} from './storage-unit-toolbar.validators';
import {EsslObject} from '../../model/entity.model';

export class StorageUnitToolbarDisablers {
  static isStorageUnitBlocked(selection: Nullable<StorageUnitDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, StorageUnitToolbarValidators.isStorageUnitBlocked());
    };
  }

  static isStorageUnitNotBlocked(selection: Nullable<StorageUnitDto[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, StorageUnitToolbarValidators.isStorageUnitNotBlocked());
    };
  }

  static isSeparateDocumentStoringUnavailable(selection: Nullable<EsslObject[]>, storeDocumentsSeparatelyUntil: Date): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, StorageUnitToolbarValidators.isSeparateDocumentStoringUnavailable(storeDocumentsSeparatelyUntil));
    };
  }
}
