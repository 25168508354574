import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';
import {SubjectRecordDto} from '|api/commons';
import {LocalizedDatetimePipe} from '@icz/angular-essentials';
import {transformSubjectRecordCreateOrUpdateDtoToSubjectRecordDto} from '../../model/subjects.model';
import {IconSize} from '@icz/angular-essentials';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'icz-subject-identification-status-icon',
  templateUrl: './subject-identification-status-icon.component.html',
  styleUrls: ['./subject-identification-status-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectIdentificationStatusIconComponent {

  @Input({
    transform: transformSubjectRecordCreateOrUpdateDtoToSubjectRecordDto,
    required: true
  }) subject!: SubjectRecordDto;
  @Input() showExtraVerificationInfo = false;
  @Input() size: IconSize = 'default';

  private translateService = inject(TranslateService);
  localizedDatetimePipe = new LocalizedDatetimePipe();

  get hasIsdsVerifiedDate() {
    return Boolean(this.subject?.isdsVerified);
  }

  get isIsdsVerified() {
    return SubjectTemplateUtils.isIsdsVerified(this.subject);
  }

  get isIszrVerified() {
    return SubjectTemplateUtils.isIszrVerified(this.subject);
  }

  get iszrVerifiedTooltip() {
    return `${this.translateService.instant('Ztotožněno')} ${this.localizedDatetimePipe.transform(this.subject.iszrMetadata?.identificationDate)}`;
  }

  get isdsVerifiedTooltip() {
    return `${this.translateService.instant('Subjekt byl ověřený vůči databázi datových schránek ISDS')} ${this.localizedDatetimePipe.transform(this.subject.isdsVerified)}`;
  }

  get isIdentifiable() {
    return SubjectTemplateUtils.isIdentifiable(this.subject);
  }

}
