<div class="grow" [formGroup]="form">
  <icz-alert class="mb-16" severity="info" heading="Komponenta obsahuje podpisy. Převodem do výstupního formátu tyto podpisy ztratíte."></icz-alert>
  <icz-radio-group>
    <icz-radio-button
      formControlName="shouldConvert" [value]="false" class="mb-16"
      label="Komponentu nepřevádět do výstupního formátu. Zachováte původní podpisy komponenty, ale nebude ji možné je podepsat vizuálně."
    ></icz-radio-button>
    <icz-radio-button
    formControlName="shouldConvert" [value]="true"
    label="Komponentu převést do výstupního formátu - ztratíte původní podpisy"
  ></icz-radio-button>
  </icz-radio-group>
</div>

<icz-form-buttons>
  <icz-button iczFormSubmit leftButtons (onAction)="confirm()" primary label="Pokračovat"></icz-button>
  <icz-button rightButtons (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
