import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {BinaryWithCharset} from '../../../services/remote-binary-file-download.service';
import {LocalBinaryFileDownloadService} from '../../../services/local-binary-file-download.service';


export enum ViewerType {
  IFRAME = 'IFRAME',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  HTML = 'HTML',
  RTF = 'RTF',
  TXT = 'TXT',
  UNKNOWN = 'UNKNOWN',
}

@Component({
  selector: 'icz-file-contents-viewer',
  templateUrl: './file-contents-viewer.component.html',
  styleUrls: ['./file-contents-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileContentsViewerComponent implements IczOnChanges {

  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private sanitizer = inject(DomSanitizer);

  @Input({required: true})
  fileContents: Nullable<BinaryWithCharset>;

  @Input({required: true})
  fileName!: string;
  @Input({required: true})
  mimeType: Nullable<string>;
  @Output()
  downloadRequested = new EventEmitter<void>();

  url: Nullable<SafeUrl>;
  html: Nullable<string>;

  viewerType = ViewerType.UNKNOWN;

  readonly ViewerType = ViewerType;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.fileName && this.fileName) {
      this.viewerType = this.getViewerTypeByFileSuffix();
    }

    if (changes.fileContents) {
      if (!this.fileContents) {
        this.url = null;
        this.html = null;
      }
      else {
        if (this.viewerType === ViewerType.HTML || this.viewerType === ViewerType.TXT) {
          const decoder = new TextDecoder(this.fileContents.charset);
          let decodedContents = decoder.decode(this.fileContents.buffer);

          if (this.mimeType!.includes('text/plain') && this.viewerType === ViewerType.HTML) {
            decodedContents = decodedContents.replaceAll('\n', '<br>');
          }

          this.url = null;
          this.html = decodedContents;
        } else if (this.viewerType !== ViewerType.RTF) {
          this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.localBinaryFileDownloadService.constructDownloadUrl(
            this.fileContents.buffer, this.fileName, this.mimeType!));
          this.html = null;
        } else {
          // RTF contents will get passed to icz-rtf-outlet and processed there
          this.url = null;
          this.html = null;
        }
      }
    }
  }

  private getViewerTypeByFileSuffix(): ViewerType {
    const filenameParts = this.fileName.split('.');
    const fileSuffix = filenameParts[filenameParts.length - 1].toLowerCase();

    switch (fileSuffix) {
      case 'pdf':
      case 'pdfa':
        return ViewerType.IFRAME;

      case 'bmp':
      case 'gif':
      case 'jfif':
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'ppeg':
        return ViewerType.IMAGE;

      case 'wav':
      case 'mp3':
      case 'ogg':
      case 'flac':
        return ViewerType.AUDIO;

      case 'mp4':
        return ViewerType.VIDEO;

      case 'html':
        return ViewerType.HTML;

      case 'txt':
      case 'xml':
      case 'xsl':
        return ViewerType.TXT;

      case 'rtf':
        return ViewerType.RTF;

      default:
        return ViewerType.UNKNOWN;
    }
  }
}
