import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {VersionInfoService} from '../../../core/services/version-info.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';


@Component({
  selector: 'icz-version-info',
  templateUrl: './version-info.component.html',
  styleUrls: ['./version-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VersionInfoComponent implements OnInit {

  versionInfoService = inject(VersionInfoService);
  protected loadingService = inject(LoadingIndicatorService);

  versionInfoText!: string;

  ngOnInit() {
    this.loadingService.doLoading(
      this.versionInfoService.getVersionInfo(),
      this
    ).subscribe(versionInfo => {
      this.versionInfoText = versionInfo;
    });
  }

}
