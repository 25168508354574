import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {TimeSelectorValue} from './time-selector/time-selector.component';
import {IczFormControl, IczFormGroup} from '../icz-form-controls';
import {IczValidators} from '../validators/icz-validators/icz-validators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AbstractPicker} from '../common/abstract-picker';
import {TimeSelectorGridComponent} from './time-selector-grid/time-selector-grid.component';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {IconComponent, PopoverComponent} from '@icz/angular-essentials';
import {FormFieldComponent} from '../form-field/form-field.component';
import {ValidationErrorsListComponent} from '../validators/validation-errors-list/validation-errors-list.component';
import {ReactiveFormsModule} from '@angular/forms';
import {isValidTime, parseTimeFromSimpleTimeString} from '../form-elements.model';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

/**
 * A form field which allows the user to either input his own time using text
 * in HH:MM format or by picking it from a popover with a grid containing hour and minute values (min. five-minute resolution).
 */
@Component({
  selector: 'icz-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TimeSelectorGridComponent,
    CdkOverlayOrigin,
    PopoverComponent,
    IconComponent,
    FormFieldComponent,
    ValidationErrorsListComponent,
    ReactiveFormsModule,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => TimePickerComponent),
  }],
})
export class TimePickerComponent extends AbstractPicker implements OnInit {

  protected valueValidator = IczValidators.isSimpleTime();

  /**
   * Time value in HH:MM format.
   */
  @Input()
  override set value(newValue: Nullable<string>) {
    if (this._formGroup) {
      this.valueInput.setValue(newValue, {emitEvent: false});
      this._realValue = newValue;
    }
  }
  override get value(): Nullable<string> {
    return this._realValue;
  }

  /**
   * @internal
   */
  override ngOnInit() {
    super.ngOnInit();

    this.valueInput.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      const isValidAccordingToValidator = value && IczValidators.isSimpleTime()(new IczFormControl<Nullable<string>>(value)) === null;

      if (isValidAccordingToValidator) {
        const isLogicallyValid = value ? isValidTime(parseTimeFromSimpleTimeString(value)) : null;

        if (isLogicallyValid) {
          this._valueChanged(value);
        }
      } else {
        this._realValue = null;
        this.valueChange.emit(this._realValue);
      }
    });
  }

  protected _formGroup = new IczFormGroup({
    valueInput: new IczFormControl<Nullable<string>>(null, [this.valueValidator]),
  });

  protected onTimePick(timeSelectorValue: TimeSelectorValue): void {
    this.valueInput.reset(undefined);

    const newValue = `${String(timeSelectorValue.hours).padStart(2, '0')}:${String(timeSelectorValue.minutes).padStart(2, '0')}`;

    this._valueChanged(newValue);
    this.closePopover();
  }

  protected _valueChanged(newValue: Nullable<string>): void {
    if (!newValue) {
      return;
    }

    if (this.parseLocalTimeFormat(newValue)) {
      this.value = newValue;
      this.valueChange.emit(newValue);
      this.blur.emit();
    }
  }

  private parseLocalTimeFormat(timeSource: string): Nullable<string> {
    const time = parseTimeFromSimpleTimeString(timeSource);

    if (isValidTime(time)) {
      this.unsetControlErrors();
      return timeSource;
    }
    else {
      this.setControlErrors({
        invalidDateInput: {
          errorMessageTemplate: 'Zadaný čas je neplatný'
        },
      });
      return null;
    }
  }

}
