/* tslint:disable */
/* eslint-disable */

/**
 * Statistic type
 */
export enum Statistic {
  DOCUMENTS_SIZE_COUNT = 'DOCUMENTS_SIZE_COUNT',
  FILES_SIZE_COUNT = 'FILES_SIZE_COUNT',
  DOCUMENTS_TOTAL_COUNT = 'DOCUMENTS_TOTAL_COUNT',
  FILES_TOTAL_COUNT = 'FILES_TOTAL_COUNT',
  FILES_IN_STORAGE_UNIT_TOTAL_COUNT = 'FILES_IN_STORAGE_UNIT_TOTAL_COUNT',
  DOCUMENTS_RANGE_COUNT = 'DOCUMENTS_RANGE_COUNT',
  FILES_RANGE_COUNT = 'FILES_RANGE_COUNT',
  ENTITY_CLASSES_OF_VALID_CLASSIFICATION_SCHEME_RANGE_COUNT = 'ENTITY_CLASSES_OF_VALID_CLASSIFICATION_SCHEME_RANGE_COUNT',
  FILES_TRANSFERRED_TO_REGISTRY_OFFICE_RANGE_COUNT = 'FILES_TRANSFERRED_TO_REGISTRY_OFFICE_RANGE_COUNT',
  DIGITAL_COMPONENTS_RANGE_COUNT = 'DIGITAL_COMPONENTS_RANGE_COUNT',
  DIGITAL_COMPONENTS_BY_PUID = 'DIGITAL_COMPONENTS_BY_PUID',
  DOCUMENTS_BY_ACCESS_ENTITLEMENT = 'DOCUMENTS_BY_ACCESS_ENTITLEMENT',
  FILES_BY_ACCESS_ENTITLEMENT = 'FILES_BY_ACCESS_ENTITLEMENT'
}
