import {Component, inject} from '@angular/core';
import {CirculationActivityFlowType, CirculationActivityType} from '|api/commons';
import {DocumentDto} from '|api/document';
import {
  ApiCirculationActivityBulkActionsService,
  ApiCirculationActivityService,
  CirculationActivityCreateDto
} from '|api/flow';
import {AbstractHandoverFormModalComponent} from '../abstract-handover-form-modal.component';
import {MessageType} from '../../../../notifications/toast.service';
import {IczFormControl, IczFormGroup, IczValidators, TextLength} from '@icz/angular-form-elements';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {SKIP_ERROR_DIALOG} from '../../../../../core/error-handling/http-errors';
import {handleCirculationErrorAsToast} from '../../../../../utils/document-file-circulation.utils';
import {CirculationToastType} from '../../../../../core/services/notifications/circulation-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {
  SEsslActivityErrorData
} from '../../../../../core/services/notifications/essl-component-circulation-toast.service';
import {injectModalData} from '@icz/angular-modal';


export function getAcknowledgementSpecificationForm() {
  return new IczFormGroup({
    orgStructureElementIds: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    targetTaskInstruction: new IczFormControl<Nullable<string>>(null, [], [], TextLength.LONG),
    deadline: new IczFormControl<Nullable<string>>(null),
    printHandoverProtocol: new IczFormControl<Nullable<boolean>>(false),
  });
}

@Component({
  selector: 'icz-hand-for-acknowledgement-dialog',
  templateUrl: './hand-for-acknowledgement-dialog.component.html',
  styleUrls: ['./hand-for-acknowledgement-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class HandForAcknowledgementDialogComponent extends AbstractHandoverFormModalComponent {

  protected override circulationEntities = injectModalData<DocumentDto[]>();
  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private apiCirculationActivityBulkActionsService = inject(ApiCirculationActivityBulkActionsService);

  override formGroupsToCheck!: string[];

  protected override selectedFlowType = CirculationActivityFlowType.PARALLEL;

  override form = getAcknowledgementSpecificationForm();

  handForAcknowledgement() {
    const formValue = this.form.value;
    const activityCreateDtos: CirculationActivityCreateDto[] = this.circulationEntities.map(entity => ({
      circulationEntityType: entity.entityType!,
      deadline: formValue.deadline!,
      flowType: this.selectedFlowType,
      targetTaskInstruction: formValue.targetTaskInstruction!,
      documentId: entity.id,
      functionalPositions: this.selectedFunctionalPositions,
      organizationalUnits: this.selectedOrganizationalUnits,
    }));

    if (this.isSingleEntityFlow) {
      this.loadingService.doLoading(
        this.apiCirculationActivityService.circulationActivityCreateAcknowledgement(
          {
            body: activityCreateDtos[0],
          },
          SKIP_ERROR_DIALOG
        ),
        this,
      ).subscribe({
        next: createdActivity => this.modalRef.close(createdActivity.id),
        error: errorResponse => handleCirculationErrorAsToast(
          this.circulationEntities[0],
          errorResponse,
          this.toastService,
          this.translateService,
          this.modalRef,
          CirculationActivityType.DOCUMENT_APPROVAL,
        ),
      });
    } else {
      this.loadingService.doLoading(
      this.apiCirculationActivityBulkActionsService.circulationActivityBulkActionsAcknowledgement(
          {
            body: activityCreateDtos,
          },
          SKIP_ERROR_DIALOG
        ),
        this,
      ).subscribe({
        next: _ => this.modalRef.close(true),
        error: _ => {
          this.modalRef.close(false);
          this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_BULK_ACKNOWLEDGEMENT_ERROR, [{[InternalNotificationKey.ACTIVITY_TYPE_KEY]: CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT}], SEsslActivityErrorData);
        }
      });
    }

  }

}
