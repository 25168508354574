import {inject, Injectable} from '@angular/core';
import {SubjectsService} from '../../../services/subjects.service';
import {SKIP_ERROR_DIALOG} from '../../../core/error-handling/http-errors';
import {
  ApiOwnConsignmentService,
  ApiReceivedDigitalConsignmentService,
  ApiReceivedPaperConsignmentService,
  OwnDigitalConsignmentCreateDto,
  OwnDigitalConsignmentDto,
  OwnDigitalConsignmentUpdateDto,
  OwnInternalDigitalConsignmentCreateDto,
  OwnInternalDigitalConsignmentDto,
  OwnInternalDigitalConsignmentUpdateDto,
  OwnInternalPaperConsignmentCreateDto,
  OwnInternalPaperConsignmentDto,
  OwnInternalPaperConsignmentUpdateDto,
  OwnOfficeDeskConsignmentCreateDto,
  OwnOfficeDeskConsignmentDto,
  OwnOfficeDeskConsignmentUpdateDto,
  OwnPaperConsignmentCreateDto,
  OwnPaperConsignmentDto,
  OwnPaperConsignmentUpdateDto,
  ReceivedDigitalConsignmentCreateDto,
  ReceivedDigitalConsignmentDto,
  ReceivedDigitalConsignmentUpdateDto,
  ReceivedPaperConsignmentCreateDto,
  ReceivedPaperConsignmentDto,
  ReceivedPaperConsignmentUpdateDto,
  ReceivedSingleDigitalConsignmentCreateDto,
  ReceivedSinglePaperConsignmentCreateDto
} from '|api/sad';
import {Observable, of, switchMap, throwError} from 'rxjs';
import {
  SubjectCreateRelationPropagateToFile,
  SubjectDuplicateResolveDialogData,
  SubjectDuplicateResolveDialogResult,
  SubjectReplacementConfig
} from '../model/subjects.model';
import {SubjectRecordCreateOrUpdateDto} from '|api/commons';
import {catchError} from 'rxjs/operators';
import {
  SubjectDuplicateResolveDialogComponent
} from '../subjects/subject-duplicate-resolve-dialog/subject-duplicate-resolve-dialog.component';
import {IczModalService} from '@icz/angular-modal';

enum CreateOrUpdateConsignmentAction {
  RECEIVED_CREATE_PAPER_OFFICER = 'RECEIVED_CREATE_PAPER_OFFICER',
  RECEIVED_UPDATE_PAPER = 'RECEIVED_UPDATE_PAPER',
  RECEIVED_CREATE_PAPER_FILING_OFFICE = 'RECEIVED_CREATE_PAPER_FILING_OFFICE',
  RECEIVED_CREATE_DIGITAL = 'RECEIVED_CREATE_DIGITAL',
  RECEIVED_CREATE_DIGITAL_FILING_OFFICE = 'RECEIVED_CREATE_DIGITAL_FILING_OFFICE',
  RECEIVED_UPDATE_DIGITAL = 'RECEIVED_UPDATE_DIGITAL',
  OWN_CREATE_PAPER = 'OWN_CREATE_PAPER',
  OWN_UPDATE_PAPER = 'OWN_UPDATE_PAPER',
  OWN_CREATE_DIGITAL = 'OWN_CREATE_DIGITAL',
  OWN_UPDATE_DIGITAL = 'OWN_UPDATE_DIGITAL',
  OWN_CREATE_OFFICE_DESK = 'OWN_CREATE_OFFICE_DESK',
  OWN_UPDATE_OFFICE_DESK = 'OWN_UPDATE_OFFICE_DESK',
  OWN_CREATE_INTERNAL_PAPER = 'OWN_CREATE_INTERNAL_PAPER',
  OWN_UPDATE_INTERNAL_PAPER = 'OWN_UPDATE_INTERNAL_PAPER',
  OWN_CREATE_INTERNAL_DIGITAL = 'OWN_CREATE_INTERNAL_DIGITAL',
  OWN_UPDATE_INTERNAL_DIGITAL = 'OWN_UPDATE_INTERNAL_DIGITAL',
}

const receivedConsignmentActions = [
  CreateOrUpdateConsignmentAction.RECEIVED_CREATE_PAPER_OFFICER,
  CreateOrUpdateConsignmentAction.RECEIVED_UPDATE_PAPER,
  CreateOrUpdateConsignmentAction.RECEIVED_CREATE_PAPER_FILING_OFFICE,
  CreateOrUpdateConsignmentAction.RECEIVED_CREATE_DIGITAL,
  CreateOrUpdateConsignmentAction.RECEIVED_CREATE_DIGITAL_FILING_OFFICE,
  CreateOrUpdateConsignmentAction.RECEIVED_UPDATE_DIGITAL,
];

type CreateOrUpdateConsignmentDto =
  ReceivedSinglePaperConsignmentCreateDto |
  ReceivedPaperConsignmentCreateDto |
  ReceivedSingleDigitalConsignmentCreateDto |
  ReceivedPaperConsignmentUpdateDto |
  ReceivedDigitalConsignmentUpdateDto |
  OwnPaperConsignmentCreateDto |
  OwnPaperConsignmentUpdateDto |
  OwnDigitalConsignmentCreateDto |
  OwnDigitalConsignmentUpdateDto |
  OwnOfficeDeskConsignmentCreateDto |
  OwnOfficeDeskConsignmentUpdateDto |
  OwnInternalPaperConsignmentCreateDto |
  OwnInternalPaperConsignmentUpdateDto |
  OwnInternalDigitalConsignmentCreateDto |
  OwnInternalDigitalConsignmentUpdateDto
  ;

@Injectable({
  providedIn: 'root',
})
export class ConsignmentWithDuplicateResolveService {

  private subjectsService = inject(SubjectsService);
  private apiReceivedPaperConsignmentService = inject(ApiReceivedPaperConsignmentService);
  private apiReceivedDigitalConsignmentService = inject(ApiReceivedDigitalConsignmentService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private iczModalService = inject(IczModalService);

  createOrUpdateWithDuplicateResolve(req$: Observable<any>,
                                     subject: SubjectRecordCreateOrUpdateDto,
                                     subjectReplacementConfig?: SubjectReplacementConfig,
                                     ): Observable<any | SubjectDuplicateResolveDialogResult | SubjectCreateRelationPropagateToFile> {
    return req$.pipe(
      catchError((err: any) => {
        let errorObject;
        if (typeof err.error === 'string') {
          errorObject = JSON.parse(err.error);
        } else {
          errorObject = err.error;
        }
        if (errorObject.errorCode === 'be.error.subjectRegister.subject.ambivalent') {
          return this.iczModalService.openComponentInModal<SubjectDuplicateResolveDialogResult, SubjectDuplicateResolveDialogData>({
            component: SubjectDuplicateResolveDialogComponent,
            modalOptions: {
              width: 900,
              height: '80vh',
              titleTemplate: 'Podezření na duplicitu',
            },
            data: {
              isUpdate: true,
              subjectToBeCreateOrUpdated: subject,
              suspectedDuplicates: this.subjectsService.mapAmbivalentSubjectDtoToSubjects({
                internalResults: errorObject.internalResults,
                isdsFindResults: errorObject.isdsFindResults,
                isdsSearchResults: errorObject.isdsSearchResults,
              }),
              subjectReplacementConfig: subjectReplacementConfig!,
            },
          });
        }
        else {
          return throwError(() => err);
        }
      }));
  }

  private getOwnConsignmentCreateOwnPaperConsignment(body: OwnPaperConsignmentCreateDto): Observable<OwnPaperConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentCreateOwnPaperConsignment({body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentUpdateOwnPaperConsignment(id: number, body: OwnPaperConsignmentUpdateDto): Observable<OwnPaperConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentUpdateOwnPaperConsignment({id, body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentCreateOwnDigitalConsignment(body: OwnDigitalConsignmentCreateDto): Observable<OwnDigitalConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentCreateOwnDigitalConsignment({body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentUpdateOwnDigitalConsignment(id: number, body: OwnDigitalConsignmentUpdateDto): Observable<OwnDigitalConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentUpdateOwnDigitalConsignment({id, body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentCreateOwnOfficeDesk(body: OwnOfficeDeskConsignmentCreateDto): Observable<OwnOfficeDeskConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentCreateOwnOfficeDesk({body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentUpdateOwnOfficeDeskConsignment(id: number, body: OwnOfficeDeskConsignmentUpdateDto): Observable<OwnOfficeDeskConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentUpdateOwnOfficeDeskConsignment({id, body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentCreateOwnInternalPaperConsignment(body: OwnInternalPaperConsignmentCreateDto): Observable<OwnInternalPaperConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentCreateOwnInternalPaperConsignment({body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentCreateOwnInternalDigitalConsignment(body: OwnInternalDigitalConsignmentCreateDto): Observable<OwnInternalDigitalConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentCreateOwnInternalDigitalConsignment({body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentUpdateOwnInternalPaperConsignment(id: number, body: OwnInternalPaperConsignmentUpdateDto): Observable<OwnInternalPaperConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentUpdateOwnInternalPaperConsignment({id, body}, SKIP_ERROR_DIALOG);
  }

  private getOwnConsignmentUpdateOwnInternalDigitalConsignment(id: number, body: OwnInternalDigitalConsignmentUpdateDto): Observable<OwnInternalDigitalConsignmentDto> {
    return this.apiOwnConsignmentService.ownConsignmentUpdateOwnInternalDigitalConsignment({id, body}, SKIP_ERROR_DIALOG);
  }

  private getReceivedPaperConsignmentProcessReceivedOfficer(body: ReceivedSinglePaperConsignmentCreateDto): Observable<ReceivedPaperConsignmentDto> {
    return this.apiReceivedPaperConsignmentService.receivedPaperConsignmentProcessReceivedOfficer({body}, SKIP_ERROR_DIALOG);
  }

  private getReceivedPaperConsignmentProcessReceivedFilingOffice(body: ReceivedPaperConsignmentCreateDto): Observable<ReceivedPaperConsignmentDto> {
    return this.apiReceivedPaperConsignmentService.receivedPaperConsignmentProcessReceivedFilingOffice({body}, SKIP_ERROR_DIALOG);
  }

  private getReceivedDigitalConsignmentProcessReceivedSingle(body: ReceivedSingleDigitalConsignmentCreateDto): Observable<ReceivedDigitalConsignmentDto> {
    return this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentProcessReceivedSingle({body}, SKIP_ERROR_DIALOG);
  }

  private getReceivedDigitalConsignmentProcessReceivedFilingOffice(body: ReceivedDigitalConsignmentCreateDto): Observable<ReceivedDigitalConsignmentDto> {
    return this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentProcessReceivedFilingOffice({body}, SKIP_ERROR_DIALOG);
  }

  private getReceivedPaperConsignmentUpdate(id: number, body: ReceivedPaperConsignmentUpdateDto): Observable<ReceivedPaperConsignmentDto> {
    return this.apiReceivedPaperConsignmentService.receivedPaperConsignmentUpdate({id, body}, SKIP_ERROR_DIALOG);
  }

  private getReceivedDigitalConsignmentUpdate(id: number, body: ReceivedDigitalConsignmentUpdateDto): Observable<ReceivedDigitalConsignmentDto> {
    return this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentUpdate({id, body}, SKIP_ERROR_DIALOG);
  }

  private consignmentEnumToReq$(action: CreateOrUpdateConsignmentAction, callParams: { id: Nullable<number>, body: any }): Observable<any> {
    switch (action) {
      case CreateOrUpdateConsignmentAction.RECEIVED_CREATE_PAPER_OFFICER:
        return this.getReceivedPaperConsignmentProcessReceivedOfficer(callParams.body);
      case CreateOrUpdateConsignmentAction.RECEIVED_CREATE_PAPER_FILING_OFFICE:
        return this.getReceivedPaperConsignmentProcessReceivedFilingOffice(callParams.body);
      case CreateOrUpdateConsignmentAction.RECEIVED_CREATE_DIGITAL:
        return this.getReceivedDigitalConsignmentProcessReceivedSingle(callParams.body);
      case CreateOrUpdateConsignmentAction.RECEIVED_CREATE_DIGITAL_FILING_OFFICE:
        return this.getReceivedDigitalConsignmentProcessReceivedFilingOffice(callParams.body);
      case CreateOrUpdateConsignmentAction.RECEIVED_UPDATE_PAPER:
        return this.getReceivedPaperConsignmentUpdate(callParams.id!, callParams.body);
      case CreateOrUpdateConsignmentAction.RECEIVED_UPDATE_DIGITAL:
        return this.getReceivedDigitalConsignmentUpdate(callParams.id!, callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_CREATE_PAPER:
        return this.getOwnConsignmentCreateOwnPaperConsignment(callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_CREATE_DIGITAL:
        return this.getOwnConsignmentCreateOwnDigitalConsignment(callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_CREATE_INTERNAL_PAPER:
        return this.getOwnConsignmentCreateOwnInternalPaperConsignment(callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_CREATE_INTERNAL_DIGITAL:
        return this.getOwnConsignmentCreateOwnInternalDigitalConsignment(callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_CREATE_OFFICE_DESK:
        return this.getOwnConsignmentCreateOwnOfficeDesk(callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_UPDATE_PAPER:
        return this.getOwnConsignmentUpdateOwnPaperConsignment(callParams.id!, callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_UPDATE_DIGITAL:
        return this.getOwnConsignmentUpdateOwnDigitalConsignment(callParams.id!, callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_UPDATE_INTERNAL_PAPER:
        return this.getOwnConsignmentUpdateOwnInternalPaperConsignment(callParams.id!, callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_UPDATE_INTERNAL_DIGITAL:
        return this.getOwnConsignmentUpdateOwnInternalDigitalConsignment(callParams.id!, callParams.body);
      case CreateOrUpdateConsignmentAction.OWN_UPDATE_OFFICE_DESK:
        return this.getOwnConsignmentUpdateOwnOfficeDeskConsignment(callParams.id!, callParams.body);
    }
  }

  private createOrUpdateReceivedConsignment(action: CreateOrUpdateConsignmentAction, initialCallParams: {
    id: Nullable<number>,
    body: CreateOrUpdateConsignmentDto
  }): Observable<any> {
    let subjectIdField = 'consigneeId' as keyof CreateOrUpdateConsignmentDto;
    let subjectDefinitionField = 'consigneeDefinition' as keyof CreateOrUpdateConsignmentDto;

    if (receivedConsignmentActions.includes(action)) {
      subjectIdField = 'senderId' as keyof CreateOrUpdateConsignmentDto;
      subjectDefinitionField = 'senderDefinition' as keyof CreateOrUpdateConsignmentDto;
    }

    // if existing subjectId is used, AmbivalentSubject exception can't be thrown, so just return api method
    if (initialCallParams.body[subjectIdField as keyof CreateOrUpdateConsignmentDto]) {
      return this.consignmentEnumToReq$(action, initialCallParams);
    }

    // if subjectID is not used, subjectDefinition must be provided and AmbivalentSubject exception may be returned
    return this.createOrUpdateWithDuplicateResolve(
      this.consignmentEnumToReq$(action, initialCallParams),
      (initialCallParams.body[subjectDefinitionField] as unknown as SubjectRecordCreateOrUpdateDto)!,)
      .pipe(switchMap(createOrUpdateAttemptResult => {
          if (!createOrUpdateAttemptResult) {
            return of(null); // no result means closing the duplicate dialog without any action
          }
          if (createOrUpdateAttemptResult instanceof SubjectDuplicateResolveDialogResult) {
            if (createOrUpdateAttemptResult.forceCreateOrUpdate) {
              const callParamsWithForceMode = {...initialCallParams};
              (callParamsWithForceMode.body[subjectDefinitionField] as unknown as SubjectRecordCreateOrUpdateDto)!.forceMode = true;

              return this.consignmentEnumToReq$(action, callParamsWithForceMode);
            }
            else {
              const callParamsWithSelectedSubject = {...initialCallParams};
              if (createOrUpdateAttemptResult.selectedSubject!.id) {
                (callParamsWithSelectedSubject.body[subjectIdField] as number) = createOrUpdateAttemptResult.selectedSubject!.id!;
                (callParamsWithSelectedSubject.body[subjectDefinitionField] as unknown as Nullable<SubjectRecordCreateOrUpdateDto>) = null;
                return this.consignmentEnumToReq$(action, callParamsWithSelectedSubject);
              }
              else {
                (callParamsWithSelectedSubject.body[subjectIdField] as Nullable<number>) = null;
                (callParamsWithSelectedSubject.body[subjectDefinitionField] as unknown as Nullable<SubjectRecordCreateOrUpdateDto>) = createOrUpdateAttemptResult.selectedSubject;
                return this.createOrUpdateReceivedConsignment(action, callParamsWithSelectedSubject);
              }
            }
          }
          else {
            return of(createOrUpdateAttemptResult);
          }
        },
      ));
  }

  receivedPaperConsignmentProcessReceivedOfficer(body: ReceivedSinglePaperConsignmentCreateDto): Observable<ReceivedPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.RECEIVED_CREATE_PAPER_OFFICER, {id: null, body});
  }

  receivedPaperConsignmentProcessReceivedFilingOffice(body: ReceivedPaperConsignmentCreateDto): Observable<ReceivedPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.RECEIVED_CREATE_PAPER_FILING_OFFICE, {id: null, body});
  }

  receivedDigitalConsignmentProcessReceivedSingle(body: ReceivedSingleDigitalConsignmentCreateDto): Observable<ReceivedDigitalConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.RECEIVED_CREATE_DIGITAL, {id: null, body});
  }

  receivedDigitalConsignmentProcessReceivedFilingOffice(body: ReceivedDigitalConsignmentCreateDto): Observable<ReceivedDigitalConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.RECEIVED_CREATE_DIGITAL_FILING_OFFICE, {id: null, body});
  }

  receivedPaperConsignmentUpdate(id: number, body: ReceivedPaperConsignmentUpdateDto): Observable<ReceivedPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.RECEIVED_UPDATE_PAPER, {id, body});
  }

  receivedDigitalConsignmentUpdate(id: number, body: ReceivedDigitalConsignmentUpdateDto): Observable<ReceivedDigitalConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.RECEIVED_UPDATE_DIGITAL, {id, body});
  }

  ownConsignmentCreateOwnPaperConsignment(body: OwnPaperConsignmentCreateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_CREATE_PAPER, {id: null, body});
  }

  ownConsignmentUpdateOwnPaperConsignment(id: number, body: OwnPaperConsignmentUpdateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_UPDATE_PAPER, {id, body});
  }

  ownConsignmentCreateOwnDigitalConsignment(body: OwnDigitalConsignmentCreateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_CREATE_DIGITAL, {id: null, body});
  }

  ownConsignmentUpdateOwnDigitalConsignment(id: number, body: OwnDigitalConsignmentUpdateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_UPDATE_DIGITAL, {id, body});
  }

  ownConsignmentCreateOwnOfficeDesk(body: OwnOfficeDeskConsignmentCreateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_CREATE_OFFICE_DESK, {id: null, body});
  }

  ownConsignmentUpdateOwnOfficeDeskConsignment(id: number, body: OwnOfficeDeskConsignmentUpdateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_UPDATE_OFFICE_DESK, {id, body});
  }

  ownConsignmentCreateOwnInternalPaperConsignment(body: OwnInternalPaperConsignmentCreateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_CREATE_INTERNAL_PAPER, {id: null, body});
  }

  ownConsignmentCreateOwnInternalDigitalConsignment(body: OwnInternalDigitalConsignmentCreateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_CREATE_INTERNAL_DIGITAL, {id: null, body});
  }

  ownConsignmentUpdateOwnInternalPaperConsignment(id: number, body: OwnInternalPaperConsignmentUpdateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_UPDATE_INTERNAL_PAPER, {id, body});
  }

  ownConsignmentUpdateOwnInternalDigitalConsignment(id: number, body: OwnInternalDigitalConsignmentUpdateDto): Observable<OwnPaperConsignmentDto> {
    return this.createOrUpdateReceivedConsignment(CreateOrUpdateConsignmentAction.OWN_UPDATE_INTERNAL_DIGITAL, {id, body});
  }

}
