export enum FileConfigKey {
  // FILE_HANDLING_TYPE_MODE = 'file.defaultFileHandlingType', // todo semantics are undocumented => unable to connect
}

export enum CirculationConfigKey {
  PASS_ON_LIMIT = 'circulation.passOnLimit',
  FILE_HANDOVER_ENABLED = 'circulation.fileHandoverEnabled',
  ALLOW_HAND_FOR_RESOLUTION = 'circulation.documentHandoverEnabled',
  ALLOW_HAND_FOR_APPROVAL = 'circulation.documentApprovalEnabled',
  ALLOW_HAND_FOR_STATEMENT = 'circulation.documentStatementEnabled',
  ALLOW_HAND_FOR_ACKNOWLEDGEMENT = 'circulation.documentAcknowledgementEnabled',
  COMPONENT_SIGNING_ENABLED = 'circulation.componentSigningEnabled',
  COMPONENT_APPROVAL_ENABLED = 'circulation.componentApprovalEnabled',
}

export enum AuthenticationConfigKey {
  USER_AUTHENTICATION_TYPE = 'userAuthentication.type',
}

export enum ObjectRenditionConfigKey {
  FILE_ENVELOPE_REPORT_TYPE = 'fileRendition.fileEnvelopeReportType',
  HEADER_BARCODE_TYPE = 'fileRendition.headerBarcodeType',
}

export enum LdapAuthenticationConfigKey {
  LDAP_URL = 'ldap.url',
  LDAP_SERVICE_ACCOUNT_LOGIN = 'ldap.serviceAccountLogin',
  LDAP_SERVICE_ACCOUNT_PASSWORD = 'ldap.serviceAccountPassword',
  LDAP_BASE_PATH = 'ldap.basePath',
  LDAP_USERS = 'ldap.users',
  LDAP_ESSL_LOGIN = 'ldap.esslLogin',
}

export enum OrgStructureSettingsConfigKey {
  ORGANIZATIONAL_STRUCTURE_SOURCE = 'organizationalStructure.source',
  EOS_URL = 'eos.url',
  EOS_ORG_STRUCTURE_EPR = 'eos.orgStructureEpr',
  EOS_ATTRS_EPR = 'eos.attrsEpr',
  EOS_APPL_EPR = 'eos.applEpr',
  EOS_USERNAME = 'eos.user',
  EOS_PASSWORD = 'eos.passwd',
  EOS_APPL_CODE = 'eos.applCode',
  EOS_ROOT_OU = 'eos.rootou',
  ORGADMIN_URL = 'orgAdmin.url',
  ORGADMIN_ROOT_OU = 'orgAdmin.rootou',
  ORGADMIN_USER = 'orgAdmin.user',
  ORGADMIN_PASSWD = 'orgAdmin.passwd ',
  IDM_CRON = 'idm.cron',
  IDM_TYPE = 'idm.type',
}

export enum GeneratorsConfigKey {
  UID_ID_SOURCE = 'uid.idSource',
  REF_NUMBER_ORIGINATOR = 'refnumber.originator'
}

export enum MiscConfigKey {
  FE_ERROR_REPORTING_ENABLED = 'fe.errorReporting.enabled',
  DEFAULT_CURRENCY = 'currency.default',
  DEFAULT_LANGUAGE = 'tbd8',
  FILING_OFFICE_RECEIVE_MESSAGE_TIMER = 'epdzmessage.lockHeartbeat',
  ELASTIC_MAX_SEARCH_RESULT_WINDOW = 'elastic.maxSearchResultWindow',
}

export enum SubjectDisposalConfigKeys {
  DISPOSAL_FO_ENABLED = 'subject-register.disposal.fo.enabled',
  DISPOSAL_PFO_ENABLED = 'subject-register.disposal.pfo.enabled',
  DISPOSAL_PO_ENABLED = 'subject-register.disposal.po.enabled',
  DISPOSAL_PERIOD = 'subject-register.disposal.period',
}

export enum EmailConfigurationKeys {
  NOTIFICATION_BLOCK_INTERVAL = 'epdz.global.restrictions.emlBlockNotifInterval',
  NOTIFICATION_ALLOW_INTERVAL = 'epdz.global.revocation.revocationPeriod',
  ALLOWED_NOTIFICATION_COUNT = 'epdz.global.restrictions.emlNotificationInterval',
  MANAGER_EMAIL_ADDRESS = 'epdz.global.administration.email',
}

export enum DocumentDeliveryConfigurationKeys {
  CONSIGNMENT_EXTERNAL_ID_ENABLED = 'consignment.externalIdEnabled',
  RECEIVED_DOCUMENT_EXTERNAL_ID_ENABLED = 'received-document.externalIdEnabled',
  RECEIVED_DOCUMENT_DEFAULT_REGISTRATION = 'received-document.defaultDocumentRegistration',
  RECEIVED_DOCUMENT_REGISTRATION_REQUIRED = 'received-document.registrationRequired',
  RECEIVED_DOCUMENT_DELIVERY_WITHOUT_FORWARDING = 'receivedDocument.deliveryWithoutForwarding',
  OWN_DOCUMENT_EXTERNAL_ID_ENABLED = 'own-document.externalIdEnabled',
  OWN_DOCUMENT_DEFAULT_REGISTRATION = 'own-document.defaultDocumentRegistration',
  OWN_DOCUMENT_REGISTRATION_REQUIRED = 'own-document.registrationRequired',
}

export enum LabelTemplatesConfigurationKeys {
  AUTOMATIC_PRINTING = 'label-template.automatic-printing',
  PRINT_CONSIGNMENT = 'label-template.print-consignment',
  PRINT_COMPONENT = 'label-template.print-component',
  RECEIVED_DOCUMENT_DEFAULT_TEMPLATE = 'label-template.received-document-default-template'
}

export enum SignaturesConfigurationKeys {
  VISIBLE_SIGNATURE_ENABLED = 'signatures.visible-signature.enabled',
  EXTERNAL_SIGNATURE_SERVICES = 'signatures.external-services-list',
  EXTERNAL_SEALING_SERVICES = 'signatures.external-sealing-list',
  EXTERNAL_VERIFICATION_SERVICES = 'signatures.external-verifying-list',
  SIGNATURE_ALGORITHM = 'signatures.algorithm',
  DEFAULT_SIGNATURE_CERT_LOCATION = 'signatures.default-cert-location',
  TIMESTAMPING_MANDATORNESS = 'signatures.timestamp',
}

export enum FileConfigKeys {
  ALLOWED_FILE_HANDLING_TYPES = 'file.allowedFileHandlingTypes',
  DEFAULT_FILE_REFERENCE_CREATION_TYPE =  'file.defaultFileReferenceCreationType',
  FILE_SCR_SIZE_LIMIT =  'file-strong-cross-reference.set-limit',
  FILE_SCR_LEADING_FILE_TYPE =  'file-strong-cross-reference.leading-file-type',
}

export enum EnvironmentConfigKeys {
  DEFAULT_ICON_COLOR_SCHEME = 'environment.defaultIconColorScheme',
  REQUIRED_IDT_VERSION = 'environment.requiredIdtVersion',
}

export enum StorageUnitsConfigKeys {
  FORCE_REQUIRED_DISPOSAL_SCHEDULE = 'storage-unit.forceRequiredDisposalScheduleConsistency',
  REQUIRE_ENTITY_CLASS_CONSISTENCY = 'storage-unit.requireEntityClassConsistency',
  REQUIRE_DISPOSAL_YEAR_CONSISTENCY = 'storage-unit.requireDisposalYearConsistency',
  STORAGE_UNIT_MAX_SIZE = 'storage-unit.maxSize',
  STORE_DOCUMENTS_SEPARATELY_UNTIL = 'document.storeDocumentsSeparatelyUntil',
}

export enum IszrIntegrationConfigKeys {
  ISZR_PUBLIC_AUTHORITY_IDENTIFICATION = 'iszr.publicAuthorityIdentification',
  ISZR_AGENDA_SYSTEM_CODE = 'iszr.agendaSystemCode',
  ISZR_CHANGES_NOTIFICATION_ENDPOINT = 'iszr.changesNotificationEndpoint',
  REX2_URL = 'rex2.url',
  REX2_USERNAME = 'rex2.username',
  REX2_PASSWORD = 'rex2.password',
}

export enum ExecutionDateClauseConfigKeys {
  SHOW_LEGAL_PRESCRIPTION = 'execution-date-clause.showLegalPrescription',
  USAGE_OF_TIMESTAMP = 'execution-date-clause.usageOfTimestamp',
  KEEP_SIGNATURE_AS_IMAGE = 'execution-date-clause.keepSignatureAsImage',
}

export enum EmailNotificationConfigKeys {
  EMAIL_NOTIFICATION_TIME = 'emailNotification.scheduledNotificationSendTime',
  EMAIL_NOTIFICATION_DISTRIBUTION_NODE = 'emailNotification.sendEmailNodeId',
  EMAIL_NOTIFICATION_CONTENT_TYPE = 'emailNotification.contentType',
  EMAIL_NOTIFICATION_TIMER_CRON = 'scheduler.emailNotificationsProcessCron',
  EMAIL_NOTIFICATION_APPLICATION_URL = 'base.application.url',
}

export enum TransactionLogRenditionConfigurationKeys {
  JOB_TRIGGER_TIME = 'scheduler.transactionLogRenditionCron',
  TARGET_FILE = 'transaction-protocol.rendition.target-file',
  FUNCTIONAL_POSITION = 'transaction-protocol.rendition.functional-position',
  SUBJECT = 'transaction-protocol.rendition.subject',
  DOCUMENT_TYPE = 'transaction-protocol.rendition.document-type',
  TRANSACTION_LIMIT = 'transaction-protocol.rendition.transaction-limit',
  FILE_NAME = 'transaction-protocol.rendition.file-name',
  FILE_TYPE = 'transaction-protocol.rendition.file-type',
  AUTHENTICATION = 'transaction-protocol.rendition.authentication',
  USE_RENDITION_FILE = 'transaction-protocol.rendition.use-target-file',
}

export enum ElectronicRegistryOfficeConfigKeys {
  ERO_TYPE = 'registry.settings.type',
  ERO_DESA_CODE = 'registry.desa.code',
  ERO_DESA_NAME = 'registry.desa.name',
  ERO_DESA_URL = 'registry.desa.application.url',
  ERO_DESA_ADMIN_NOMENCLATURES_CRON = 'registry.desa.admin.nomenclaturesCheckCron',
  ERO_DESA_WS_ADMIN_URL = 'registry.desa.ws.admin.url',
  ERO_DESA_WS_ADMIN_USERNAME = 'registry.desa.ws.admin.username',
  ERO_DESA_WS_ADMIN_PASSWORD = 'registry.desa.ws.admin.password',
  ERO_DESA_WS_ADMIN_LOGIN = 'registry.desa.ws.admin.userLogin',
  ERO_DESA_REST_SIP_URL = 'registry.desa.rest.sip.submission.url',
  ERO_DESA_REST_SIP_USERNAME = 'registry.desa.rest.sip.submission.username',
  ERO_DESA_REST_SIP_PASSWORD = 'registry.desa.rest.sip.submission.password',
  ERO_DESA_REST_SIP_LOGIN = 'registry.desa.rest.sip.submission.userLogin',
  ERO_DESA_WS_SIP_URL = 'registry.desa.ws.sip.submission.url',
  ERO_DESA_WS_SIP_USERNAME = 'registry.desa.ws.sip.submission.username',
  ERO_DESA_WS_SIP_PASSWORD = 'registry.desa.ws.sip.submission.password',
  ERO_DESA_WS_SIP_LOGIN = 'registry.desa.ws.sip.submission.userLogin',
  ERO_DESA_SIP_SUBMISSION_CHECK_CRON = 'registry.desa.sip.submission.ingestCheckCron',
  ERO_DESA_SIP_SUBMISSION_CHECK_DAYS = 'registry.desa.sip.submission.ingestCheckDays',
}

export enum AgendaTransferConfigKeys {
  AGENDA_TRANSFER_IS_ACTIVE = 'agenda-transfer.enabled',
}
