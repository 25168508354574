import {ClassificationSchemeDto} from '|api/codebook';
import {formatAsLocalIsoDate, getTodayMidnight} from '@icz/angular-form-elements';

export function isInWorkClassificationScheme(scheme: Nullable<ClassificationSchemeDto>) {
  if (!scheme) return false;
  else return !scheme.validFrom && !scheme.validTo;
}

export function isFutureClassificationScheme(scheme: Nullable<ClassificationSchemeDto>) {
  if (!scheme) return false;
  else return scheme.validFrom && scheme.validFrom > formatAsLocalIsoDate(getTodayMidnight());
}

export function isActiveClassificationScheme(scheme: Nullable<ClassificationSchemeDto>) {
  if (!scheme) return false;
  else {
    return (
      (scheme.validFrom && scheme.validFrom <= formatAsLocalIsoDate(getTodayMidnight())) &&
      (
        !scheme.validTo ||
        (scheme.validTo && scheme.validTo >= formatAsLocalIsoDate(getTodayMidnight()))
      )
    );
  }
}

export function isArchiveClassificationScheme(scheme: Nullable<ClassificationSchemeDto>) {
  if (!scheme) return false;
  else return scheme.validFrom && scheme.validTo && scheme.validTo < formatAsLocalIsoDate(getTodayMidnight());
}
