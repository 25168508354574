@if (taskInfo) {
  <icz-task-state-tag class="inline-block mb-8" [taskState]="taskInfo.taskState" [parentActivity]="parentActivity"></icz-task-state-tag>
  <div class="icz-body-1">
    @if (taskInfo.orderNumber) {
      {{taskInfo.orderNumber}}.
    }
    @if (taskInfo.taskInfoType === TaskInfoType.ASSIGNED && taskInfo.ownerUserId) {
      <span class="icz-body-strong">{{ taskInfo.ownerUserId | findUserById | async }}</span>
      ({{ taskInfo.ownerFunctionalPositionId | findFunctionalPositionById:true | async }})
    }
    @else {
      {{ taskInfo.ownerFunctionalPositionId | findFunctionalPositionById | async }}
    }
  </div>
  @if (taskInfo.taskInfoType === TaskInfoType.ASSIGNED) {
    @if (taskInfo.assignmentDate) {
      <div class="icz-body-1">
        {{ 'Úkol přidělen' | translate }}: {{ taskInfo.assignmentDate | localizedDatetime }}
      </div>
    }
    @if (taskInfo.settlementDate) {
      <div class="icz-body-1">
        {{ activityTransitionName | translate }}: {{ taskInfo.settlementDate | localizedDatetime }}
      </div>
    }
    @if (taskInfo.settlementNote) {
      <div class="icz-body-1">
        <span class="icz-body-strong">{{ activityDescriptionName | translate }}</span>:
        <icz-expandable-paragraph [contents]="taskInfo.settlementNote"></icz-expandable-paragraph>
      </div>
    }
    @if (taskInfo.rejectionReason) {
      <div class="icz-body-1">
        <span class="icz-body-strong">{{ 'Důvod odmítnutí' | translate }}</span>:
        <icz-expandable-paragraph [contents]="taskInfo.rejectionReason"></icz-expandable-paragraph>
      </div>
    }
    @if (taskInfo.revokeReason) {
      <div class="icz-body-1">
        <span class="icz-body-strong">{{ 'Důvod odvolání' | translate }}</span>:
        <icz-expandable-paragraph [contents]="taskInfo.revokeReason"></icz-expandable-paragraph>
      </div>
    }
  }
}
