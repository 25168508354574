/* eslint-disable @typescript-eslint/ban-types,no-restricted-imports */
import {DOCUMENT} from '@angular/common';
import {
  AfterViewInit,
  Component,
  DestroyRef,
  ElementRef,
  HostBinding,
  inject,
  InjectionToken,
  Injector,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {fromEvent} from 'rxjs';
import {AnyComponent, GetNodeInjectorDirective, LoadingIndicatorService} from '@icz/angular-essentials';
import {IczModalDefinition} from '../icz-modal.service';
import {IczModalRef, iczModalRefFactory} from '../icz-modal-ref.injectable';
import {MODAL_BACKDROP_CLASS} from '../modals.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ModalDialogComponent} from '../modal-dialog/modal-dialog.component';
import {IczFormDirective} from '@icz/angular-form-elements';

/**
 * Dialog data for ModalizedComponentService-popupated modals, works similarly to MAT_DIALOG_DATA.
 * Can be used with @Inject() when utilizing constructor dependency injection.
 * If using inject-syntax dependency injection, injectModalData<T>() can be used as syntax sugar.
 */
export const ICZ_MODAL_DATA = new InjectionToken('ICZ_MODAL_DATA');

/**
 * @internal
 */
@Component({
  selector: 'icz-component-modal',
  templateUrl: './component-modal.component.html',
  styleUrls: ['./component-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    ModalDialogComponent,
    GetNodeInjectorDirective,
    IczFormDirective,
  ],
})
export class ComponentModalComponent<D> implements AfterViewInit {

  dialogRef = inject<MatDialogRef<ComponentModalComponent<D>>>(MatDialogRef);
  dialogData = inject<IczModalDefinition<D>>(MAT_DIALOG_DATA);
  private el = inject(ElementRef);
  private loadingService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);
  private document = inject(DOCUMENT);

  @ViewChild('componentPlaceholder', {read: ViewContainerRef})
  protected componentPlaceholder!: ViewContainerRef;
  @ViewChild('nodeInjectorRef')
  protected nodeInjectorRef!: GetNodeInjectorDirective;

  @HostBinding('class.auto-margin')
  protected get hasAutoMargin(): boolean {
    return !this.dialogData.modalOptions.disableAutoMargin;
  }

  @HostBinding('class.without-button-bar')
  protected get isWithoutButtonBar(): boolean {
    return this.dialogData.modalOptions.disableButtonBar!;
  }

  protected get isClosable() {
    return this.dialogData.modalOptions.isClosable ?? false;
  }

  component!: AnyComponent;

  ngAfterViewInit(): void {
    if (!this.dialogData.component) return;

    setTimeout(() => { // this timeout prevents dirty change detection state errors
      const componentInjector = Injector.create({
        // content children of icz-modal-dialog must also see what is in its NodeInjector
        parent: this.nodeInjectorRef.injector,
        providers: [
          // ICZ_MODAL_DATA will unwrap data property from MAT_DIALOG_DATA,
          // component-provided providers will work fine with that
          {
            provide: ICZ_MODAL_DATA,
            useFactory: (matDialogData: IczModalDefinition<D>) => matDialogData.data,
            deps: [MAT_DIALOG_DATA],
          },
          // IczModalRef will decorate MatDialogRef with our custom functionalities
          {
            provide: IczModalRef,
            useFactory: iczModalRefFactory,
            deps: [MatDialogRef],
          }
        ],
      });

      const modalRef = componentInjector.get(IczModalRef);

      // can safely cast to string bcs IczModalService.getModalConfig always produces string width/height
      modalRef.originalWidth = this.dialogData.modalOptions.width as string;
      modalRef.originalHeight = this.dialogData.modalOptions.height as string;

      const componentRef = this.componentPlaceholder.createComponent(this.dialogData.component, {
        injector: componentInjector,
      });
      this.component = componentRef.instance;

      const originalCloseFunction = this.dialogRef.close.bind(this.dialogRef);
      this.dialogRef.close = (isDone: any) => {
        setTimeout(() => {
          if (!this.loadingService.isLoading(this.component)) {
            originalCloseFunction(isDone);
          }
        });
      };

      if (this.isClosable) {
        const closeModal = () => this.dialogRef.close(null);

        const modalContextElements = this.document.querySelectorAll(`.${MODAL_BACKDROP_CLASS}`);
        const modalContainerElement = this.el.nativeElement.closest('.mat-mdc-dialog-container');

        if (modalContextElements.length && modalContainerElement) {
          // We must take the last element with BACKDROP_CLASS in order to account for nested modals.
          const modalContextElement = modalContextElements[modalContextElements.length - 1];

          fromEvent<KeyboardEvent>(modalContainerElement, 'keydown').pipe(
            takeUntilDestroyed(this.destroyRef),
          ).subscribe((event: KeyboardEvent) => {
            if (event.key === 'Escape') {
              closeModal();
            }
          });

          fromEvent(modalContextElement, 'click').pipe(
            takeUntilDestroyed(this.destroyRef),
          ).subscribe((event: Event) => {
            closeModal();
          });
        }
      }
    }, 0);
  }

}
