/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CustomBoolFieldDto } from '../models/custom-bool-field-dto';
import { CustomDateFieldDto } from '../models/custom-date-field-dto';
import { CustomDateTimeFieldDto } from '../models/custom-date-time-field-dto';
import { CustomEnumFieldDto } from '../models/custom-enum-field-dto';
import { CustomFieldDto } from '../models/custom-field-dto';
import { customFieldOauthCreate } from '../fn/custom-field-oauth/custom-field-oauth-create';
import { CustomFieldOauthCreate$Params } from '../fn/custom-field-oauth/custom-field-oauth-create';
import { customFieldOauthFindAll } from '../fn/custom-field-oauth/custom-field-oauth-find-all';
import { CustomFieldOauthFindAll$Params } from '../fn/custom-field-oauth/custom-field-oauth-find-all';
import { customFieldOauthFindById } from '../fn/custom-field-oauth/custom-field-oauth-find-by-id';
import { CustomFieldOauthFindById$Params } from '../fn/custom-field-oauth/custom-field-oauth-find-by-id';
import { customFieldOauthSearch } from '../fn/custom-field-oauth/custom-field-oauth-search';
import { CustomFieldOauthSearch$Params } from '../fn/custom-field-oauth/custom-field-oauth-search';
import { customFieldOauthUpdate } from '../fn/custom-field-oauth/custom-field-oauth-update';
import { CustomFieldOauthUpdate$Params } from '../fn/custom-field-oauth/custom-field-oauth-update';
import { CustomNumericFieldDto } from '../models/custom-numeric-field-dto';
import { CustomTextFieldDto } from '../models/custom-text-field-dto';
import { PageCustomFieldDto } from '../models/page-custom-field-dto';


/**
 * API for management of custom fields.
 */
@Injectable({ providedIn: 'root' })
export class ApiCustomFieldOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `customFieldOauthUpdate()` */
  static readonly CustomFieldOauthUpdatePath = '/oauth/custom-field/update/{id}';

  /**
   * Updates a custom field entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldOauthUpdate$Response(params: CustomFieldOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>> {
    return customFieldOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates a custom field entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldOauthUpdate(params: CustomFieldOauthUpdate$Params, context?: HttpContext): Observable<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)> {
    return this.customFieldOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>): (CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto) => r.body)
    );
  }

  /** Path part for operation `customFieldOauthCreate()` */
  static readonly CustomFieldOauthCreatePath = '/oauth/custom-field/create';

  /**
   * Creates a custom field entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldOauthCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldOauthCreate$Response(params: CustomFieldOauthCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>> {
    return customFieldOauthCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a custom field entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldOauthCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  customFieldOauthCreate(params: CustomFieldOauthCreate$Params, context?: HttpContext): Observable<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)> {
    return this.customFieldOauthCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>): (CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto) => r.body)
    );
  }

  /** Path part for operation `customFieldOauthSearch()` */
  static readonly CustomFieldOauthSearchPath = '/oauth/custom-field/search-api';

  /**
   * Find all custom fields based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldOauthSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldOauthSearch$Response(params?: CustomFieldOauthSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageCustomFieldDto>> {
    return customFieldOauthSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all custom fields based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldOauthSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldOauthSearch(params?: CustomFieldOauthSearch$Params|null|undefined, context?: HttpContext): Observable<PageCustomFieldDto> {
    return this.customFieldOauthSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageCustomFieldDto>): PageCustomFieldDto => r.body)
    );
  }

  /** Path part for operation `customFieldOauthFindById()` */
  static readonly CustomFieldOauthFindByIdPath = '/oauth/custom-field/find-by-id/{id}';

  /**
   * Find custom field by ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldOauthFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldOauthFindById$Response(params: CustomFieldOauthFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>> {
    return customFieldOauthFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find custom field by ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldOauthFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldOauthFindById(params: CustomFieldOauthFindById$Params, context?: HttpContext): Observable<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)> {
    return this.customFieldOauthFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>): (CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto) => r.body)
    );
  }

  /** Path part for operation `customFieldOauthFindAll()` */
  static readonly CustomFieldOauthFindAllPath = '/oauth/custom-field/find-all';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `customFieldOauthFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldOauthFindAll$Response(params?: CustomFieldOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>>> {
    return customFieldOauthFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `customFieldOauthFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  customFieldOauthFindAll(params?: CustomFieldOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>> {
    return this.customFieldOauthFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>>): Array<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)> => r.body)
    );
  }

}
