import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {Observable, pipe} from 'rxjs';
import {map} from 'rxjs/operators';
import {FormOptionsDefinition, makeDefaultOptionsDefinition} from '../form-autocomplete/form-autocomplete.model';
import {AbstractSelectField} from '../common/abstract-select-field';
import {
  ComposableFormAutocompleteComponent
} from '../form-autocomplete/composable-form-autocomplete/composable-form-autocomplete.component';
import {
  FormAutocompleteListComponent,
  IAutocompleteNoResultsContext
} from '../form-autocomplete/form-autocomplete-list/form-autocomplete-list.component';
import {
  AutoFocusDirective,
  ButtonComponent,
  IczOnChanges,
  IczSimpleChanges,
  InterpolatePipe,
  TooltipDirective
} from '@icz/angular-essentials';
import {TranslateModule} from '@ngx-translate/core';
import {
  FormAutocompleteListTextItemComponent
} from '../form-autocomplete/form-autocomplete-list-text-item/form-autocomplete-list-text-item.component';
import {NgTemplateOutlet} from '@angular/common';
import {
  FormAutocompleteChipComponent
} from '../form-autocomplete/form-autocomplete-chip/form-autocomplete-chip.component';
import {ValidationErrorsListComponent} from '../validators/validation-errors-list/validation-errors-list.component';
import {IczOption, locateOptionByValue} from '../form-elements.model';
import {CHIP_SELECTOR_STATE_PERSISTOR, ChipSelectorStatePersistor, ChipUsageRates} from '../form-elements.providers';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

/**
 * @internal
 */
interface ChipInputOptionData {
  _usageRate: number;
}

/**
 * @internal
 */
type ChipInputOption = IczOption<Nullable<string | number>, ChipInputOptionData>;

/**
 * Chip input can select only options with string or numeric values.
 */
type ChipInputValue = Array<string | number> | string | number;

/**
 * @internal
 */
const usageRateComparator = (o1: ChipInputOption, o2: ChipInputOption) => o2.data!._usageRate - o1.data!._usageRate;

/**
 * Decorates default options definition such that the options
 * will get sorted by usage rate from local storage
 */
export function makeUsageSortedOptionsDefinition(
  options$: Observable<IczOption[]>,
  strForSearch: (s: IczOption) => string,
): FormOptionsDefinition {
  const defaultDefinition = makeDefaultOptionsDefinition(options$, strForSearch);

  defaultDefinition.searchtermToOptionsOperator = pipe(
    defaultDefinition.searchtermToOptionsOperator,
    map(options => [...(options as ChipInputOption[]).sort(usageRateComparator)]),
  );

  return defaultDefinition;
}

/**
 * A kind of multiselect autocomplete which allows the user to add his own values to a set of
 * available chips in a specific business domain. There can also be some chips predefined using
 * the options property. Options in the list of options will be sorted by usage rates.
 */
@Component({
  selector: 'icz-form-chip-input',
  templateUrl: './form-chip-input.component.html',
  styleUrls: ['./form-chip-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    InterpolatePipe,
    ButtonComponent,
    FormAutocompleteListTextItemComponent,
    NgTemplateOutlet,
    ComposableFormAutocompleteComponent,
    FormAutocompleteChipComponent,
    FormAutocompleteListComponent,
    TooltipDirective,
    ValidationErrorsListComponent,
    AutoFocusDirective,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => FormChipInputComponent),
  }],
})
export class FormChipInputComponent extends AbstractSelectField<string | number> implements OnInit, IczOnChanges {

  private chipSelectorStatePersistor = inject(CHIP_SELECTOR_STATE_PERSISTOR);
  private cd = inject(ChangeDetectorRef);

  @ViewChild('basisComponent')
  protected basisComponent!: ComposableFormAutocompleteComponent;

  protected override _visibleOptions!: ChipInputOption[];

  /**
   * Selector value.
   */
  @Input()
  override set value(newValues: Nullable<ChipInputValue>) {
    super.value = newValues;

    this.initOptions((this._rawOptions ?? []) as ChipInputOption[]);
  }
  override get value(): Nullable<ChipInputValue> {
    return super.value;
  }

  /**
   * Chip namespace to be chosen from. Each chip namespace should correspond to a single business set of chips.
   * The user will be able to add his own chips to the given namespace.
   */
  @Input({required: true})
  chipNamespace: Nullable<string>;
  /**
   * This field will always be a multiselect.
   * @deprecated - do not flip it to FALSE.
   */
  @Input()
  override isMultiselect = true;
  /**
   * If true, the inner selection interface of the selector will contain an "Apply" button
   * which will be used for manually update field model value after the user made their decision.
   */
  @Input()
  manualValueCommit = true;
  /**
   * A function which will extract a string from an option to be serached in using fulltext search.
   */
  @Input()
  searchIndexer: Nullable<(o: IczOption) => string>;

  protected readonly usageSortedOptionsDefinitionFactory = makeUsageSortedOptionsDefinition;

  protected createNewChipText = '"{{searchTerm}}" (vytvořit nové)';

  private _rawOptions: Nullable<IczOption[]>;
  private _lastRealValue: Nullable<ChipInputValue> = null;

  /**
   * @internal
   */
  ngOnInit(): void {
    if (isNil(this.chipNamespace)) {
      console.warn('Each instance of icz-form-chip-input must have property "chipNamespace" set.');
    }
  }

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.options || changes.value || changes.chipNamespace) {
      if (this.options !== this._rawOptions) {
        this._rawOptions = this.options;
        this.initOptions(this._visibleOptions);
      }
    }
  }

  protected addChip(context: IAutocompleteNoResultsContext) {
    if (this.chipNamespace) {
      const chipName = context.searchTerm;

      this._visibleOptions.push({
        label: chipName,
        value: chipName,
        data: {
          _usageRate: 0,
        }
      });
      this.chipSelectorStatePersistor.addCustomChip(this.chipNamespace, chipName);

      context.searchTermChanged.emit(context.searchTerm);
    }
  }

  protected applyChanges() {
    super.emitFieldValue();
    this.recomputeUsageRates();
    this._lastRealValue = this._value as Nullable<Array<string | number>>;
    this.basisComponent.closeOptions(true);
  }

  protected valueCleared() {
    super.emitFieldValue();
    this._lastRealValue = null;
  }

  // Will return selected chip state to previous effective value
  // after closing the selector without applying any changes.
  protected optionsListClosed(isSynthetic: boolean) {
    if (!isSynthetic && this.isMultiselect && this.manualValueCommit) {
      this._value = this._lastRealValue;
      this.__value = this.searchForMultiselectOptions(this._lastRealValue as Nullable<Array<string|number>>);
    }
  }

  protected override emitFieldValue() {
    if (!(this.manualValueCommit && this.isMultiselect)) {
      super.emitFieldValue();
    }
  }

  private recomputeUsageRates() {
    let oldSelectedOptions: Set<string|number>;
    let newSelectedOptions: Set<string|number>;

    if (this.isMultiselect) {
      oldSelectedOptions = new Set(this._lastRealValue as Array<string|number> ?? []);
      newSelectedOptions = new Set(this._value as Nullable<Array<string | number>> ?? []);
    }
    else {
      oldSelectedOptions = new Set(this._lastRealValue ? [this._lastRealValue as string|number] : []);
      newSelectedOptions = new Set(this._value ? [this._value as string|number] : []);
    }

    const additions = new Set([...newSelectedOptions].filter(x => !oldSelectedOptions.has(x!)));

    for (const additionValue of additions) {
      const additionOption = this._visibleOptions.find(o => o.value === additionValue)!;

      ++additionOption.data!._usageRate;

      this.chipSelectorStatePersistor.setChipUsageRate(
        this.chipNamespace!,
        String(additionOption.value),
        additionOption.data!._usageRate,
      );
    }
  }

  private initOptions(options: ChipInputOption[]) {
    this.options = FormChipInputComponent.getOptionsWithCustomChips(
      options,
      this._value as Nullable<string>,
      this.chipNamespace!,
      this.chipSelectorStatePersistor,
    );
    this.cd.detectChanges();
  }

  /**
   * @internal
   */
  static getOptionsWithCustomChips(
    originalOptions: IczOption<Nullable<string | number>>[],
    currentValue: Nullable<string | string[]>,
    chipNamespace: string,
    persistor: ChipSelectorStatePersistor,
  ) {
    const customOptions = (chipNamespace ? persistor.getCustomChips(chipNamespace) : []).map(
      chipName => ({label: chipName, value: chipName} as IczOption)
    );

    const availableOptions: IczOption[] = [
      ...originalOptions,
      ...customOptions,
    ];
    const optionsFromArbitraryValues: IczOption[] = [];

    if (Array.isArray(currentValue)) {
      for (const singleValue of currentValue) {
        if (!locateOptionByValue(availableOptions, singleValue)) {
          optionsFromArbitraryValues.push({
            label: singleValue,
            value: singleValue,
          });
        }
      }
    }
    else if (!isNil(currentValue) && !locateOptionByValue(availableOptions, currentValue)) {
      optionsFromArbitraryValues.push({
        label: currentValue,
        value: currentValue,
      });
    }

    const chipUsageRates: ChipUsageRates = chipNamespace ? persistor.getChipUsageRates(chipNamespace) : {};

    return ([
      ...optionsFromArbitraryValues,
      ...availableOptions,
    ] as ChipInputOption[]).map(o => {
      if (!o.hasOwnProperty('data')) {
        o.data = {
          _usageRate: 0,
        };
      }
      o.data!._usageRate = chipUsageRates[String(o.value)] ?? 0;
      return o;
    });
  }
}
