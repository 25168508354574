import {ChangeDetectionStrategy, Component, OnInit, Input} from '@angular/core';
import {SubjectRecordClassification} from '|api/commons';
import {IczFormGroup} from '@icz/angular-form-elements';
import {SubjectTemplateUtils} from '../../../../../utils/subject-template-utils';
import {isAddressPartiallyFilled} from '../../../../../services/subjects.service';
import {SubjectAttributeType} from '../../../model/subject-attribute-type';

@Component({
  selector: 'icz-subject-create-addresses',
  templateUrl: './subject-create-addresses.component.html',
  styleUrls: ['./subject-create-addresses.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectCreateAddressesComponent implements OnInit {

  @Input() isUpdateMode!: boolean;
  @Input() isReadonly = false;
  @Input({required: true}) form!: IczFormGroup;
  @Input() classification = SubjectRecordClassification.FO;

  readonly SubjectAttributeType = SubjectAttributeType;
  initExpandedMailingAddress = true;
  initExpandedResidentialAddress = true;

  get isNaturalPerson(): boolean {
    return SubjectTemplateUtils.isNaturalPerson(this.classification);
  }

  get isBusinessIndividual(): boolean {
    return SubjectTemplateUtils.isBusinessIndividual(this.classification);
  }

  get isNaturalPersonOrBI() {
    return this.isNaturalPerson || this.isBusinessIndividual;
  }

  get mailingAddressForm(): IczFormGroup {
    return SubjectTemplateUtils.mailingAddressForm(this.form);
  }

  get residentialAddressForm(): IczFormGroup {
    return SubjectTemplateUtils.residentialAddressForm(this.form);
  }

  ngOnInit() {
    if (this.isUpdateMode) {
      this.initExpandedMailingAddress = isAddressPartiallyFilled(this.mailingAddressForm);
      this.initExpandedResidentialAddress = isAddressPartiallyFilled(this.residentialAddressForm);
    } else {
      this.initExpandedMailingAddress = true;
      this.initExpandedResidentialAddress = true;
    }
  }

}
