import {Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {DistributionClass, EntityType} from '|api/commons';
import {DataBoxNodeDto, EmailNodeDto} from '|api/config-server';
import {OfficeDeskNodeDto} from '|api/sad';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczFormGroup} from '@icz/angular-form-elements';
import {ConsignmentDeliveryFilingType} from '../consignment.model';
import {ConsignmentComponentsDatasource} from './consignment-components.datasource';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {EsslComponentDto, EsslComponentSearchService} from '../../../../../services/essl-component-search.service';
import {EsslComponentsTableColumnSet} from '../../../essl-components-table/essl-components-table.component';
import {ConsignmentValidationDialogResult} from '../consignment-validation/consignment-validation.component';
import {FilterOperator, FilterParam} from '@icz/angular-table';

@Component({
  selector: 'icz-consignment-component-selection',
  templateUrl: './consignment-component-selection.component.html',
  styleUrls: ['./consignment-component-selection.component.scss'],
})
export class ConsignmentComponentSelectionComponent implements OnInit, IczOnChanges {

  private codebookService = inject(CodebookService);
  private searchService = inject(EsslComponentSearchService);

  @Input({required: true})
  specificFieldsForm!: IczFormGroup;
  @Input({required: true})
  documentId!: number;
  @Input({required: true})
  preselectedComponents!: Nullable<EsslComponentDto[]>;
  @Input({required: true})
  distributionClassForDispatch: Nullable<DistributionClass>;
  @Input()
  disabled = false;
  @Input({required: true})
  dispatchOfficeDistributionNodeId!: Nullable<number>;
  @Input({required: true})
  deliveryFilingType: Nullable<ConsignmentDeliveryFilingType>;
  @Input()
  canUserWriteContent = true;
  @Input()
  canUserDisplayComponentsContent = true;
  @Input({required: true})
  consignmentValidationResult: Nullable<ConsignmentValidationDialogResult>;
  @Input({required: true})
  consignmentId: Nullable<number>;
  @Output()
  componentsSelected = new EventEmitter<EsslComponentDto[]>();

  get sizeLimitExceededAlert() {
    if (this.distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD) {
      return 'Celková velikost příloh překročila povolený limit ({{componentsSizeLimit}}) pro úřední desku.';
    }
    else if (this.distributionClassForDispatch === DistributionClass.EMAIL_BOX) {
      return 'Celková velikost příloh překročila povolený limit ({{componentsSizeLimit}}) pro e-mail.';
    }
    else if (this.distributionClassForDispatch === DistributionClass.ISDS_BOX) {
      return 'Celková velikost příloh překročila povolený limit ({{componentsSizeLimit}}) pro datové schránky.';
    }
    else return '';
  }

  mainComponentConstraintAlert = 'Hlavní komponenta musí být opatřena platným podpisem, pečetí a časovým razítkem.';

  additionalComponentSelectorFilters: FilterParam[] = [];

  componentsSizeLimit!: Nullable<string>;
  componentsSizeLimitLoaded = false;
  componentSizeLimitExceeded = false;

  consignmentComponentDatasource: Nullable<ConsignmentComponentsDatasource>;

  readonly EsslComponentsTableColumnSet = EsslComponentsTableColumnSet;
  readonly DistributionClass = DistributionClass;

  get mainComponentConstraintUnmet(): boolean {
    // todo(rb) need to get specified how to select main consignment component
    //  => should be some kind of stuff which selects a single component from selected components list
    return false;
  }

  get componentSizeMayBeLimited() {
    return (
      this.distributionClassForDispatch === DistributionClass.EMAIL_BOX && this.deliveryFilingType === ConsignmentDeliveryFilingType.DELIVERY_FILLING_OFFICE) ||
      this.distributionClassForDispatch === DistributionClass.ISDS_BOX ||
      this.distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD;
  }

  get tableTitle() {
    return this.distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD ? 'Zvolte komponenty k vyvěšení' : 'Zvolte komponenty k vypravení';
  }

  ngOnInit() {
    if (this.componentSizeMayBeLimited) {
      let deliveryTypeNodes$: Nullable<Observable<Array<DataBoxNodeDto | EmailNodeDto | OfficeDeskNodeDto>>>;

      if (this.dispatchOfficeDistributionNodeId) {
        if (this.distributionClassForDispatch === DistributionClass.ISDS_BOX) {
          deliveryTypeNodes$ = this.codebookService.databoxNodes();
        }
        else if (this.distributionClassForDispatch === DistributionClass.EMAIL_BOX) {
          deliveryTypeNodes$ = this.codebookService.emailNodes();
        }
        else if (this.distributionClassForDispatch === DistributionClass.OFFICIAL_BOARD) {
          deliveryTypeNodes$ = this.codebookService.officeDeskNodes();
        }
      }

      if (deliveryTypeNodes$) {
        deliveryTypeNodes$.subscribe(nodes => {
          const activeNode = nodes.find(o => o.id === this.dispatchOfficeDistributionNodeId);
          this.componentsSizeLimit = activeNode?.maximumMessageSize;
          this.componentsSizeLimitLoaded = true;
        });
      }
      else {
        this.componentsSizeLimitLoaded = true;
        this.componentsSizeLimit = null;
      }

      this.additionalComponentSelectorFilters = [
        {
          fieldName: 'entityType',
          operator: FilterOperator.inSet,
          value: String([EntityType.DIGITAL_COMPONENT, EntityType.PAPER_COMPONENT]),
        },
        {
          fieldName: 'fileSize',
          operator: FilterOperator.notEmpty,
          value: undefined,
        }
      ];

      if (this.consignmentId) {
        this.consignmentComponentDatasource = new ConsignmentComponentsDatasource(this.searchService, this.consignmentId, this.documentId, this.additionalComponentSelectorFilters);
      }
    }
  }

  handleComponentsSelected(selectedComponents: EsslComponentDto[]) {
    this.componentsSelected.emit(selectedComponents);
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.documentId && changes.documentId.currentValue && changes.consignmentId && changes.consignmentId.currentValue) {
      this.consignmentComponentDatasource = new ConsignmentComponentsDatasource(this.searchService, changes.consignmentId.currentValue, changes.documentId.currentValue, this.additionalComponentSelectorFilters);
    }

    if (changes.consignmentValidationResult) {
      if (!this.consignmentValidationResult || this.consignmentValidationResult.valid) {
        this.componentSizeLimitExceeded = false;
      }
      else if (!this.consignmentValidationResult.loading) {
        this.componentSizeLimitExceeded = !isNil(this.consignmentValidationResult.errors?.find(
          e => e.code === 'be.error.consignment.own.digital.componentsTotalSizeLimitExceeded'
        ));
      }
    }
  }

}
