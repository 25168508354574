import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';

/**
 * A chip with left-aligned content slot and right-aligned suffix slot,
 *   mainly used for implementing badges or deletable chips with "X" buttons.
 */
@Component({
  selector: 'icz-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class ChipComponent {

  @HostListener('click', ['$event'])
  protected hostClicked(_: MouseEvent) {
    if (this._clickable) {
      this.clicked.emit();
    }
  }

  /**
   * If true, will emit to @Output clicked on chip click.
   * deprecated - used in scenarios where this component is abused as a combo box. We might delete this in the future.
   */
  @Input()
  clickable = true;
  /**
   * If true, will render the chip as visually disabled and disable emitting to @Output clicked.
   */
  @Input()
  @HostBinding('class.disabled')
  isDisabled = false;
  /**
   * @deprecated - used in scenarios where this component is abused as a combo box. We might delete this in the future.
   */
  @Output()
  clicked = new EventEmitter<void>();

  @HostBinding('class.clickable')
  protected get _clickable(): boolean { return this.clickable && !this.isDisabled; }

}
