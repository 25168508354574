import {NgModule} from '@angular/core';
import {OptionItemComponent} from './form-autocomplete/options-list/option-item/option-item.component';
import {OptionsListComponent} from './form-autocomplete/options-list/options-list.component';
import {FormChipInputComponent} from './form-chip-input/form-chip-input.component';
import {ItemSorterComponent} from './item-sorter/item-sorter.component';
import {ReadonlyFieldComponent} from './readonly-field/readonly-field.component';
import {ValidationErrorsListComponent} from './validators/validation-errors-list/validation-errors-list.component';
import {RadioButtonComponent} from './radio-button/radio-button.component';
import {RadioButtonValueAccessorDirective} from './radio-button/radio-button.value-accessor';
import {FormTreeSelectorComponent} from './form-tree-selector/form-tree-selector.component';
import {
  FormAutocompleteTreeListComponent
} from './form-tree-selector/form-autocomplete-tree-list/form-autocomplete-tree-list.component';
import {FormAutocompleteComponent} from './form-autocomplete/form-autocomplete.component';
import {
  ComposableFormAutocompleteComponent
} from './form-autocomplete/composable-form-autocomplete/composable-form-autocomplete.component';
import {
  FormAutocompleteChipComponent
} from './form-autocomplete/form-autocomplete-chip/form-autocomplete-chip.component';
import {
  FormAutocompleteListComponent
} from './form-autocomplete/form-autocomplete-list/form-autocomplete-list.component';
import {
  FormAutocompleteListTextItemComponent
} from './form-autocomplete/form-autocomplete-list-text-item/form-autocomplete-list-text-item.component';
import {InputMaskDirective} from './input-mask/input-mask.directive';
import {ButtonToggleGroupComponent} from './button-toggle-group/button-toggle-group.component';
import {CheckboxComponent} from './checkbox/checkbox.component';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {TreeViewComponent} from './tree-view/tree-view.component';
import {FormHelpFieldComponent} from './form-help-field/form-help-field.component';
import {FormInlineSelectComponent} from './form-inline-select/form-inline-select.component';
import {TimePickerComponent} from './time-picker/time-picker.component';
import {
  NumberCounterFieldComponent
} from './time-picker/time-selector/number-counter-field/number-counter-field.component';
import {TimeSelectorComponent} from './time-picker/time-selector/time-selector.component';
import {FormFieldComponent} from './form-field/form-field.component';
import {IczRadioGroupDirective} from './radio-button/radio-group.directive';
import {FindInListPipe} from './pipes/find-in-list.pipe';
import {CalendarComponent} from './calendar/calendar.component';
import {CalendarRangeComponent} from './calendar-range/calendar-range.component';
import {IczMatCalendarDirective} from './calendar/icz-mat-calendar.directive';
import {IczFormContainerDirective, IczFormDirective} from './form-container/icz-form.directive';
import {DatetimePickerComponent} from './datetime-picker/datetime-picker.component';

/**
 * @internal
 */
const FORM_ELEMENTS = [
  ValidationErrorsListComponent,
  RadioButtonComponent,
  RadioButtonValueAccessorDirective,
  FormTreeSelectorComponent,
  FormAutocompleteTreeListComponent,
  FormFieldComponent,
  FormAutocompleteComponent,
  ComposableFormAutocompleteComponent,
  FormAutocompleteChipComponent,
  FormAutocompleteListComponent,
  FormAutocompleteListTextItemComponent,
  InputMaskDirective,
  ButtonToggleGroupComponent,
  CheckboxComponent,
  DatePickerComponent,
  TreeViewComponent,
  OptionItemComponent,
  FormHelpFieldComponent,
  OptionsListComponent,
  FormInlineSelectComponent,
  ReadonlyFieldComponent,
  ItemSorterComponent,
  FormChipInputComponent,
  TimePickerComponent,
  NumberCounterFieldComponent,
  TimeSelectorComponent,
  IczRadioGroupDirective,
  FindInListPipe,
  IczMatCalendarDirective,
  CalendarComponent,
  CalendarRangeComponent,
  IczFormDirective,
  IczFormContainerDirective,
  DatetimePickerComponent,
];

/**
 * The components, directives and pipes in this library are fully standalone. Import this NgModule
 * if you prefer using NgModules instead of standalone imports in your codebase.
 */
@NgModule({
	imports: FORM_ELEMENTS,
	exports: FORM_ELEMENTS,
})
export class FormElementsModule {
}
