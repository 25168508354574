<icz-card (click)="onDetailClick(statisticsConfig!.id)" [ngClass]="{'cursor-pointer': !statisticsConfig?.isDetailTestingFeature}">
  <div [ngClass]="{'counter-size-small': showAsCount, 'counter-size-big': showAsTable}" class="col">
    @if (!formattedTrendData) {
      <div class="flex justify-center">
        <icz-spinner [diameter]="24"></icz-spinner>
      </div>
    }
    <div class="row counter-header px-8 pt-8">
      <div>
        <icz-icon svgIcon="drag_and_drop" class="show-drag-and-drop drag-and-drop"></icz-icon>
      </div>
      <div class="row py-4">
        @if (statisticsConfig?.tooltip) {
          <icz-icon svgIcon="info" [iczTooltip]="statisticsConfig?.tooltip!"></icz-icon>
        }
        @if (statisticsConfig?.isDetailTestingFeature) {
          <icz-icon svgIcon="work_in_progress" iczTooltip="Podrobný náhled statistiky se připravuje"></icz-icon>
        }
        <!-- todo(mh) will be added when csv download will be implemented on BE -->
        <!--<icz-button svgIcon="download" [noBorder]="true" (onAction)="downloadStatistic()"></icz-button>-->
      </div>
    </div>
    @if (trendData && formattedTrendData) {
      <div class="col conuter-vertical-spacing grow">
        <div class="row grow">
          <div class="col grow-8">
            <div>
              <div class="row grow justify-center">
                <icz-label [label]="statisticsConfig!.label" bold [hasNormalWhitespace]="true"></icz-label>
              </div>
              @if (isTrendLoading) {
                <div class="flex grow trend-numbers justify-center mt-16">
                  <icz-label label="Statistika se připravuje" class="icz-body-1 secondary-label"></icz-label>
                </div>
              } @else {
                <div class="flex grow trend-numbers justify-center mt-16">
                  <span class="mr-4 font-bold">{{formattedTrendData.total}}</span>
                  @if (statisticsConfig!.isTimeSpecific) {
                    <span class="flex font-bold" [ngClass]="{'trend-positive': trendData.trend > 0, 'trend-negative': trendData.trend < 0}">
                    ({{formattedTrendData.trend}})
                      @if (trendData.trend > 0) {
                        <icz-icon svgIcon="trend_up"></icz-icon>
                      }
                      @if (trendData.trend < 0) {
                        <icz-icon svgIcon="trend_down"></icz-icon>
                      }
                  </span>
                  }
                </div>
              }
              @if (statisticsConfig!.isTimeSpecific) {
                <div class="row grow justify-center mt-8" [formGroup]="form">
                  <icz-form-autocomplete formControlName="period" [options]="trendPeriodOptions" [clearable]="false"></icz-form-autocomplete>
                </div>
              }
            </div>
          </div>
        </div>
        @if (trendData.aggregatedData && showAsTable) {
          <div class="w-full aggregated-table">
            <div class="table-header">
              <div class="row px-12 py-4">
                <icz-label class="grow-2 pr-8 aggregation-table-header" label="Agregace"></icz-label>
                <icz-label class="grow aggregation-table-header" label="Počet"></icz-label>
              </div>
            </div>
            @for (row of trendData.aggregatedData; track row) {
              @if ($index < 5) {
                <div class="px-12 row py-4">
                  <div class="grow-2 pr-8 row-label-size">
                    <icz-label class="icz-body-1" [label]="row.label" [iczTooltip]="row.label"></icz-label>
                  </div>
                  <div class="grow"><span class="icz-body-1">{{row.value}}</span></div>
                </div>
                <div class="row-separator"></div>
              }
            } @empty {
              <div class="aggregated-table-empty"><span class="icz-body-1">{{ 'Žádná data' | translate }}</span></div>
            }
          </div>
        }
      </div>
    }
  </div>
</icz-card>
