<icz-validation-errors-list [showValidationStatus]="showValidationStatus" [control]="control">

  @if ((label | translate); as translatedLabel) {
    <div class="row label-row justify-between"
        >
      <div
        class="input-label grow-1"
        [class.focused]="isFieldFocused"
        [iczTooltip]="translatedLabel"
      >
        {{ translatedLabel }}
      </div>
      @if ((rightLabel! | translate); as translatedRightLabel) {
        <div (click)="showRightLabelPopupOnClick ? showRightLabelPopup = !showRightLabelPopup : 0"
             [iczTooltip]="rightLabelTooltip!"
             cdkOverlayOrigin
             class="right-label"
             #labelRow="cdkOverlayOrigin">
          {{ translatedRightLabel }}
        </div>
        <icz-popover [overlayOrigin]="labelRow" [isOpen]="showRightLabelPopup"
                     (onClose)="showRightLabelPopup = false">
          <div class="p-16">
            <ng-content select="[rightLabelPopup]"></ng-content>
          </div>
        </icz-popover>
      }
    </div>
    }

  <div
    class="input-wrapper"
    [class.focused]="isFieldFocused"
    [class.disabled]="fieldDisabled"
    [class.flexible-height]="autoSizeMax > 1"
    [class.with-textarea]="autoSizeMax > 1"
    (click)="onInputClick($event)"
    >
    @if (shouldShowPrefix) {
      <div class="prefix">
        <ng-content select="[prefix]"></ng-content>
      </div>
    }

    <div class="infix" [class.hide-input-element]="hideInputElement">
      @if (autoSizeMax <= 1) {
        @if (!iczInputMask) {
          <input
            [value]="_value"
            (keydown)="checkInputCharacter($event)"
            (input)="_valueChanged($event)"
            (focus)="onFocus()"
            (blur)="onBlur($event)"
            [disabled]="fieldDisabled"
            [tabIndex]="fieldDisabled ? -1 : tabIndex"
            [type]="htmlType"
            #inputElement
            [placeholder]="shouldHidePlaceholder ? '' : ((placeholder | translate) || '')"
            [name]="htmlName!"
            [attr.autocomplete]="autocompleteValue">
        }
        <!-- duplicated on purpose - will not apply unnecessary event
        listeners to the input when input mask is not needed -->
        @if (iczInputMask) {
          <input
            [value]="_value"
            (keydown)="checkInputCharacter($event)"
            (input)="_valueChanged($event)"
            (focus)="onFocus()"
            (blur)="onBlur($event)"
            [disabled]="fieldDisabled"
            [tabIndex]="fieldDisabled ? -1 : tabIndex"
            [type]="htmlType"
            #inputElement
            [placeholder]="shouldHidePlaceholder ? '' : ((placeholder | translate) || '')"
            [iczInputMask]="iczInputMask"
            [iczInputMaskControlName]="formControlName"
            [name]="htmlName!"
            [attr.autocomplete]="autocompleteValue">
        }
      }

      @if (autoSizeMax > 1) {
        <textarea
          [value]="_value"
          (keydown)="checkInputCharacter($event)"
          (input)="_valueChanged($event)"
          (focus)="onFocus()"
          (blur)="onBlur($event)"
          (keydown.enter.stop)="0"
          [disabled]="fieldDisabled"
          [tabIndex]="tabIndex"
          #inputElement
          [placeholder]="shouldHidePlaceholder ? '' : ((placeholder | translate) || '')"
          [name]="htmlName!"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          [cdkAutosizeMinRows]="autoSizeMin"
          [cdkAutosizeMaxRows]="autoSizeMax"
        ></textarea>
      }

      <ng-content></ng-content>
    </div>

    <div class="suffix">
      @if (originalType === 'password') {
        <icz-icon
          size="small"
          class="cursor-pointer"
          [svgIcon]="passwordRevealButtonIcon"
          (click)="toggleRevealPassword()" background>
        </icz-icon>
      }
      @if (!fieldDisabled && clearable && _value) {
        <icz-icon
          class="clear-selection"
          size="small"
          svgIcon="delete"
          background
          (click)="clear()"
          tabIndex="-1">
        </icz-icon>
      }
      <ng-content select="[suffix]"></ng-content>
    </div>
  </div>

</icz-validation-errors-list>
