import {
  CzechAddressDto,
  DataBoxDto,
  GenericLineAddressDto,
  OvmType,
  PostalBoxAddressDto,
  SlovakAddressDto,
  SubjectObjectRelationType,
  SubjectRecordClassification,
  SubjectRecordDto
} from '|api/commons';
import {IczFormArray, IczFormGroup} from '@icz/angular-form-elements';
import {
  getAddressTypeLabelForLegalLike,
  getAddressTypeLabelForNaturalPerson
} from '../components/shared-business-components/subjects/address-type-label.pipe';
import {
  AddressCompleteDto,
  AddressFormat,
  AddressWithTypeDto,
} from '../components/shared-business-components/model/addresses.model';
import {
  subjectHasAttribute,
  SubjectReplacementConfig,
} from '../components/shared-business-components/model/subjects.model';
import {
  EditSubjectDialogComponent,
  EditSubjectDialogData,
  EditSubjectDialogMode
} from '../components/shared-business-components/subjects/edit-subject/edit-subject-dialog.component';
import {IczModalService} from '@icz/angular-modal';
import {Injector} from '@angular/core';
import {CzemItemDto} from '|api/codebook';
import {SubjectAttributeType} from '../components/shared-business-components/model/subject-attribute-type';

export class SubjectTemplateUtils {

  static openEditSubjectModal(iczModalService: IczModalService,
                              subject: SubjectRecordDto,
                              mode = EditSubjectDialogMode.FULL,
                              relationType?: Nullable<SubjectObjectRelationType>,
                              representing?: Nullable<boolean>,
                              subjectReplacementConfig?: SubjectReplacementConfig,
                              injector?: Injector) {
    return iczModalService.openComponentInModal<SubjectRecordDto, EditSubjectDialogData>({
      component: EditSubjectDialogComponent,
      modalOptions: {
        width: 1200,
        height: '90vh',
        titleTemplate: 'Úprava subjektu',
        useCustomHeader: true,
        disableAutoMargin: true,
        injector,
      },
      data: {
        subject,
        mode,
        relationType,
        representing,
        subjectReplacementConfig,
      },
    });
  }

  static classification(form: IczFormGroup): SubjectRecordClassification {
    return form?.get('classification')!.value;
  }

  static ovmType(form: IczFormGroup): Nullable<OvmType> {
    return form?.get('ovmType')!.value;
  }

  static isNaturalPerson(value: SubjectRecordClassification): boolean {
    return value === SubjectRecordClassification.FO;
  }

  static isLegalPerson(value: SubjectRecordClassification): boolean {
    return value === SubjectRecordClassification.PO;
  }

  static isBusinessIndividual(value: SubjectRecordClassification): boolean {
    return value === SubjectRecordClassification.PFO;
  }

  static isIdentifiable(subject: SubjectRecordDto): boolean {
    return Boolean(subject?.identifiable);
  }

  static isIdentifiableByAttributes(subject: SubjectRecordDto): boolean {
    function subjectHasSomeKindOfAddress(subject: SubjectRecordDto) {
      return (
        subjectHasAttribute(subject, SubjectAttributeType.RESIDENTIAL_ADDRESS) ||
        subjectHasAttribute(subject, SubjectAttributeType.MAILING_ADDRESS) ||
        subjectHasAttribute(subject, SubjectAttributeType.ADDITIONAL_ADDRESS)
      );
    }

    if (subject.classification === SubjectRecordClassification.FO) {
      return (
        (
          subjectHasAttribute(subject, SubjectAttributeType.FIRST_NAME) &&
          subjectHasAttribute(subject, SubjectAttributeType.SURNAME) &&
          subjectHasAttribute(subject, SubjectAttributeType.BIRTH_DATE) &&
          subjectHasSomeKindOfAddress(subject)
        ) ||
        subjectHasAttribute(subject, SubjectAttributeType.DATA_BOX) ||
        subjectHasAttribute(subject, SubjectAttributeType.IDENTITY_CARD_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.DRIVING_LICENCE_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.PASSPORT_ID)
      );
    }
    else if (subject.classification === SubjectRecordClassification.PFO) {
      return (
        (
          subjectHasAttribute(subject, SubjectAttributeType.FIRST_NAME) &&
          subjectHasAttribute(subject, SubjectAttributeType.SURNAME) &&
          subjectHasSomeKindOfAddress(subject) &&
          subjectHasAttribute(subject, SubjectAttributeType.CID)
        ) ||
        (
          subjectHasSomeKindOfAddress(subject) &&
          subjectHasAttribute(subject, SubjectAttributeType.BUSINESS_NAME)
        ) ||
        subjectHasAttribute(subject, SubjectAttributeType.DATA_BOX) ||
        (
          subjectHasAttribute(subject, SubjectAttributeType.BUSINESS_NAME) &&
          subjectHasAttribute(subject, SubjectAttributeType.CID)
        ) ||
        subjectHasAttribute(subject, SubjectAttributeType.CID) ||
        subjectHasAttribute(subject, SubjectAttributeType.TAX_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.ART_1_P_3_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.VAT_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.EXCISE_TAX_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.EORI_CODE) ||
        subjectHasAttribute(subject, SubjectAttributeType.LE_ID)
      );
    }
    else { // PO
      return (
        (
          subjectHasSomeKindOfAddress(subject) &&
          subjectHasAttribute(subject, SubjectAttributeType.BUSINESS_NAME)
        ) ||
        subjectHasAttribute(subject, SubjectAttributeType.DATA_BOX) ||
        (
          subjectHasAttribute(subject, SubjectAttributeType.BUSINESS_NAME) &&
          subjectHasAttribute(subject, SubjectAttributeType.CID)
        ) ||
        subjectHasAttribute(subject, SubjectAttributeType.CID) ||
        subjectHasAttribute(subject, SubjectAttributeType.TAX_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.ART_1_P_3_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.VAT_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.EXCISE_TAX_ID) ||
        subjectHasAttribute(subject, SubjectAttributeType.EORI_CODE) ||
        subjectHasAttribute(subject, SubjectAttributeType.LE_ID)
      );
    }
  }

  static isIsdsVerified(subject: SubjectRecordDto): boolean {
    if (!subject) return false;

    return Boolean(subject.isdsVerified);
  }

  static isIszrVerified(subject: SubjectRecordDto) {
    return !isNil(subject?.iszrIdentifier);
  }

  static isVerified(subject: SubjectRecordDto) {
    return this.isIsdsVerified(subject) || this.isIszrVerified(subject);
  }

  static mailingAddressForm(form: IczFormGroup): IczFormGroup {
    return form?.get(SubjectAttributeType.MAILING_ADDRESS) as IczFormGroup;
  }

  static additionalAddressForms(form: IczFormGroup): IczFormArray {
    return form?.get(SubjectAttributeType.ADDITIONAL_ADDRESS) as IczFormArray;
  }

  static residentialAddressForm(form: IczFormGroup): IczFormGroup {
    return form?.get(SubjectAttributeType.RESIDENTIAL_ADDRESS) as IczFormGroup;
  }

  static postalBoxForms(form: IczFormGroup): IczFormArray {
    return form?.get(SubjectAttributeType.POSTAL_BOX) as IczFormArray;
  }

  static addressSearchForm(form: IczFormGroup): IczFormGroup {
    return form?.get('address') as IczFormGroup;
  }

  static getSubjectWeight(subject: SubjectRecordDto) {
    if (SubjectTemplateUtils.isIszrVerified(subject)) {
      return 5;
    }
    else if (SubjectTemplateUtils.isIsdsVerified(subject) && subject.id) {
      return 4;
    }
    else if (SubjectTemplateUtils.isIsdsVerified(subject) && !subject.id) {
      return 3;
    }
    else if (SubjectTemplateUtils.isIdentifiable(subject)) {
      return 2;
    }
    else {
      return 1;
    }
  }

  static getBusinessSortedAddresses(subject: SubjectRecordDto, includePostalBoxes = false): AddressWithTypeDto[] {
    if (!subject?.attributes) return [];
    const mailing = subject.attributes[SubjectAttributeType.MAILING_ADDRESS];
    const residential = subject.attributes[SubjectAttributeType.RESIDENTIAL_ADDRESS];
    const additionalsAttrs = subject.attributes[SubjectAttributeType.ADDITIONAL_ADDRESS];
    const postalBoxes = subject.attributes[SubjectAttributeType.POSTAL_BOX];
    const result: AddressWithTypeDto[] = [];

    if (mailing) { // show mailing as first
      result.push({
        type: SubjectAttributeType.MAILING_ADDRESS,
        address: mailing.value,
        format: (mailing.value as AddressCompleteDto)._Class as unknown as AddressFormat
      });
    }
    if (residential) { // show residential as second
      result.push({
        type: SubjectAttributeType.RESIDENTIAL_ADDRESS,
        address: residential.value,
        format: (residential.value as AddressCompleteDto)._Class as unknown as AddressFormat
      });
    }
    if (additionalsAttrs?.length) { // show additionals as third
      const sortedAdditionals: AddressCompleteDto[] = additionalsAttrs.sort((a, b) => {
        return (a.validFrom! < b.validFrom!) ? 1 : -1;
      });

      for (const addr of sortedAdditionals) {
        if (addr.value) {
          result.push({
            type: SubjectAttributeType.ADDITIONAL_ADDRESS,
            address: addr.value,
            format: addr.value._Class as unknown as AddressFormat
          });
        }
      }
    }
    if (includePostalBoxes && postalBoxes?.length) { // show postal boxes as last
      for (const postalBox of postalBoxes) {
        if (postalBox.value) {
          result.push({
            type: SubjectAttributeType.POSTAL_BOX,
            address: postalBox.value,
            format: (postalBox.value as AddressCompleteDto)._Class as unknown as AddressFormat
          });
        }
      }
    }

    return result;
  }

  static getSubjectName(subject: SubjectRecordDto): string {
    if (subject.classification === SubjectRecordClassification.FO) {
      return `${subject.attributes.firstName!.value ?? ''} ${subject.attributes.surname!.value ?? ''}`;
    }
    else {
      return String(subject.attributes.businessName!.value ?? '');
    }
  }

  static getSubjectStreetAndNumber(selectedAddress: Nullable<AddressCompleteDto>): string {
    if (!selectedAddress) {
      return '';
    }
    else {
      const selectedCzechAddress = selectedAddress as (CzechAddressDto | SlovakAddressDto);

      let out = '';

      if (selectedCzechAddress.street) {
        out += `${selectedCzechAddress.street} `;
      }
      else if (selectedCzechAddress.city) {
        out += `${selectedCzechAddress.city} `;
      }

      if (selectedCzechAddress.houseNumber) out += `${selectedCzechAddress.houseNumber}`;
      if (selectedCzechAddress.houseNumber && selectedCzechAddress.orientationNumber) out += '/';
      if (selectedCzechAddress.orientationNumber) out += selectedCzechAddress.orientationNumber;
      if (selectedCzechAddress.orientationNumberLastCharacter) out += selectedCzechAddress.orientationNumberLastCharacter;

      return out;
    }
  }

  static inlineDisplayGenericLineAddress(address: Nullable<AddressCompleteDto>) {
    if (!address || address._Class !== AddressFormat.GenericLineAddressDto) return '';

    let out = '';
    (address as GenericLineAddressDto).addressLines!.forEach((line: string) => out += line);
    return out;
  }

  static getSubjectZipAndCity(selectedAddress: Nullable<AddressCompleteDto>) {
    if (!selectedAddress) {
      return '';
    }
    else {
      // todo(rb) should work with more address formats later, however it will be reiterated
      const selectedCzechAddress = selectedAddress as CzechAddressDto;
      return `${selectedCzechAddress.postalCode} ${selectedCzechAddress.city}`;
    }
  }

  static getAddressString(addressWithType: AddressWithTypeDto, classification: SubjectRecordClassification, omitAddressType = false, countries?: CzemItemDto[]): string {
    let addressTypeLabel = getAddressTypeLabelForLegalLike(addressWithType.type);
    if (classification === SubjectRecordClassification.FO) {
      addressTypeLabel = getAddressTypeLabelForNaturalPerson(addressWithType.type);
    }

    let addressToString = (omitAddressType ? '' : `${addressTypeLabel}: `);

    addressToString += this.getAddressWithoutTypeString(addressWithType.address, countries);

    return addressToString;
  }

  static getAddressWithoutTypeString(addressCompleteDto: AddressCompleteDto, countries?: CzemItemDto[]): string {
    let addressToString = '';

    if (addressCompleteDto._Class === AddressFormat.GenericLineAddressDto && countries) {
      const genericAddress = (addressCompleteDto as GenericLineAddressDto);
      addressToString += genericAddress.addressLines?.join(', ');
      const country =  countries.find(c => c.alpha3Code === genericAddress.country);
      addressToString += country ? `, ${country.englishShortName}` : '';
    }
    else {
      addressToString += `${this.getSubjectStreetAndNumber(addressCompleteDto)}, ${this.getSubjectZipAndCity(addressCompleteDto)}`;
    }

    return addressToString;
  }

  static getDataboxString(databox: DataBoxDto): string {
    return `${databox.id} (${databox.type})`;
  }

  static getPostalBoxString(poBox: PostalBoxAddressDto): string {
    return `${poBox.box} (${poBox.postOffice})`;
  }

}
