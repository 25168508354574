import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation,} from '@angular/core';
import {TableRow} from '../selected-rows.service';
import {IczTreeTableDataSource, NODE_LEVEL_SYMBOL} from './tree-table.datasource';
import {TABLE_HOST_CONTENTS, TABLE_IMPORTS, TABLE_PROVIDERS, TableComponent} from '../table.component';

/**
 * @internal
 */
interface TreeTableRow extends TableRow {
  [NODE_LEVEL_SYMBOL]?: Nullable<number>;
}

/**
 * @internal
 */
const SINGLE_TREE_LEVEL_INDENT_SIZE_PX = 32;

/**
 * A data grid which is regarding functionality basically identical to TableComponent
 * but is able to work with hierarchical trees - mainly expanding/collapsing tree nodes
 * and their rendering with appropriate left padding.
 */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: TABLE_IMPORTS,
  providers: TABLE_PROVIDERS,
  host: TABLE_HOST_CONTENTS,
  selector: 'icz-tree-table',
  standalone: true,
  styleUrls: ['../table.component.scss'],
  templateUrl: '../table.component.html',
})
export class TreeTableComponent<TColumnKey extends string> extends TableComponent<TColumnKey> {

  /**
   * Tree table datasource. Must work with hierarchical entities.
   */
  @Input({required: true})
  override dataSource: Nullable<IczTreeTableDataSource<any>>;

  protected override isTreeTable = true;

  protected override isExpanded(row: TreeTableRow) {
    return this.dataSource!.isNodeExpanded(row);
  }

  protected override toggleExpansionState(event: Event, row: TreeTableRow) {
    event.stopPropagation();

    if (!this.isExpanded(row)) {
      this.dataSource!.expandTreeBranch(row);
    }
    else {
      this.dataSource!.collapseTreeBranch(row);
    }
  }

  protected override getRowLevelIndentValue(row: TreeTableRow) {
    return (row[NODE_LEVEL_SYMBOL] ?? 0) * SINGLE_TREE_LEVEL_INDENT_SIZE_PX;
  }

  protected override isNodeExpanderVisible(row: TreeTableRow) {
    return this.dataSource!.isNodeExpandable(row);
  }

}
