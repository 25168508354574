import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AUTH_MICROSERVICE_ROOT, CONFIG_MICROSERVICE_ROOT} from '../api';
import {
  ApiEmailNodesOauthService,
  ApiPropertyValueOauthService,
  ApiPropertyValueService,
  EmailNodeDto,
  PropertyValueDto
} from '|api/config-server';
import {SearchApiService} from './search-api.service';
import {ApiSubstitutionService, SubstitutionDto} from '|api/auth-server';
import {Page, SearchParams} from '@icz/angular-table';

/**
 * Contains mostly app-wide public methods with URLs for various endpoints that support SearchAPI.
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigSearchService extends SearchApiService {

  findConfigPropertyValues(searchParams: Partial<SearchParams>): Observable<Page<PropertyValueDto>> {
    return this.searchApi(searchParams, CONFIG_MICROSERVICE_ROOT + ApiPropertyValueService.PropertyValueSearchPath);
  }

  findConfigPropertyValuesOauth(searchParams: Partial<SearchParams>): Observable<Page<PropertyValueDto>> {
    return this.searchApi(searchParams, CONFIG_MICROSERVICE_ROOT + ApiPropertyValueOauthService.PropertyValueOauthSearchPath);
  }

  findEmailNodesOauth(searchParams: Partial<SearchParams>): Observable<Page<EmailNodeDto>> {
    return this.searchApi(searchParams, CONFIG_MICROSERVICE_ROOT + ApiEmailNodesOauthService.EmailNodesOauthSearchPath);
  }

  findSubstitutions(searchParams: Partial<SearchParams>): Observable<Page<SubstitutionDto>> {
    return this.searchApi(searchParams, AUTH_MICROSERVICE_ROOT + ApiSubstitutionService.SubstitutionSearchPath);
  }

}
