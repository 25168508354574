import {CirculationActivityState, CirculationActivityType} from '|api/commons';
import {CirculationSearchService} from '../../../services/circulation-search.service';
import {UnitViewSearchConfig} from '../../../services/search-api-with-elastic.service';
import {CirculationTableColumn} from './circulation-table.columnsets';
import {ExtendedCirculationActivityDto} from '../model/elastic-extended-entities.interface';
import {CurrentSessionService} from '../../../services/current-session.service';
import {fetchInFullComponents} from './circulation-datasources.common';
import {EsslComponentSearchService} from '../../../services/essl-component-search.service';
import {Observable} from 'rxjs';
import {FilterOperator, IczTableDataSource, Page} from '@icz/angular-table';


export const inProgressActivityStates = [
  CirculationActivityState.IN_PROGRESS,
];

export const doneActivityStates = [
  CirculationActivityState.DONE,
  CirculationActivityState.REVOKED,
];

export class MyActivitiesDatasource extends IczTableDataSource<ExtendedCirculationActivityDto> {

  constructor(
    searchService: CirculationSearchService,
    currentSessionService: CurrentSessionService,
    esslComponentSearchService: EsslComponentSearchService,
    unitView: UnitViewSearchConfig,
  ) {
    super(
      searchParams => {
        if (!unitView.isUnitView) {
          const hasFunctionalPositionFilter = searchParams.filter.find(f => f.fieldName === CirculationTableColumn.INIT_FUNCTIONAL_POSITION_ID);

          if (!hasFunctionalPositionFilter) {
            searchParams.filter.push(
              {
                fieldName: CirculationTableColumn.INIT_FUNCTIONAL_POSITION_ID,
                operator: FilterOperator.equals,
                value: String(currentSessionService.currentUserFunctionalPosition!.id),
              },
            );
          }
        }

        searchParams.filter.push(
          {
            fieldName: CirculationTableColumn.ACTIVITY_TYPE,
            operator: FilterOperator.inSet,
            not: true,
            value: String([CirculationActivityType.DOCUMENT_ISSD_HANDOVER, CirculationActivityType.FILE_ISSD_HANDOVER]),
          },
        );

        return searchService.findActivitiesGlobally(searchParams, unitView).pipe(
          fetchInFullComponents(esslComponentSearchService),
        ) as Observable<Page<ExtendedCirculationActivityDto>>;
      },
    );
  }

}
