import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {map, take} from 'rxjs/operators';
import {EpdzErrorDto} from '|api/sad';
import {EpdzErrorsToolbarButtonsService, EpdzErrorsToolbarView} from './epdz-errors-toolbar-buttons.service';
import {ToolbarDataService} from '../toolbar-data.service';
import {AuthorizedButtonService} from '../authorized-button.service';
import {of} from 'rxjs';
import {AbstractToolbarButtonsComponent} from '../abstract-toolbar-buttons.component';


@Component({
  selector: 'icz-epdz-errors-toolbar-buttons',
  templateUrl: './epdz-errors-toolbar-buttons.component.html',
  styleUrls: ['./epdz-errors-toolbar-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EpdzErrorsToolbarButtonsComponent extends AbstractToolbarButtonsComponent<EpdzErrorDto, EpdzErrorsToolbarView> implements OnInit {

  private epdzErrorsToolbarButtonsService = inject(EpdzErrorsToolbarButtonsService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private toolbarDataService = inject(ToolbarDataService);
  private destroyRef = inject(DestroyRef);

  ngOnInit() {
    this.epdzErrorsToolbarButtonsService.actionCompleted$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.operationCompleted.emit();
    });
  }

  protected generateToolbarContents(rows: Nullable<EpdzErrorDto[]>) {
    of(this.epdzErrorsToolbarButtonsService.getToolbarButtons(rows, this.view)).pipe(
      map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)),
      take(1),
    ).subscribe(
      toolbarButtons => this.toolbarButtons = toolbarButtons
    );
  }
}
