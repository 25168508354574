<icz-table
  id="epdz-container-contents"
  [columnsData]="columnsData"
  [dataSource]="dataSource"
  [hideTableToolbar]="true"
  [waiting]="loadingIndicatorService.isLoading$(this) | async"
>

  <icz-label notFoundLabel noDataLabel label="Kontejner je prázdný"></icz-label>

  <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'name'; withEllipsis: true">
    <a class="p-4 icz-body-1 visible-clickable-area" iczLinkWithoutHref (click.stop)="openEpdzAttachment(row)">
      <span>{{ row.name }}</span>
    </a>
  </ng-container>

</icz-table>
