import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {EmpowermentDto} from '|api/commons';
import {IczTableFilterWithInternalForm} from '@icz/angular-table';
import {FilterSubValue, NonemptyFilterItem} from '@icz/angular-table';

export const EMPOWERMENT_SHAPE_INDICATOR = 'empowerment';


@Component({
  selector: 'icz-empowerment-custom-filter',
  templateUrl: './empowerment-custom-filter.component.html',
  styleUrls: ['./empowerment-custom-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmpowermentCustomFilterComponent extends IczTableFilterWithInternalForm implements OnInit {

  override set item(newItem: NonemptyFilterItem) {
    this._item = newItem;
    if (newItem.subValues && newItem.subValues.length > 0) {
      this.selectedEmpowerment = {};
      newItem.subValues.forEach(sv => {
        const dtoString = sv.subValueId?.replace('empowerment.', '');
        this.selectedEmpowerment![dtoString!] = sv.value;
      });
    }
  }
  override get item(): NonemptyFilterItem {
    return this._item;
  }

  selectedEmpowerment: Nullable<Record<string, any>> = null;
  searchTerm!: string;

  ngOnInit(): void {
    this.initForm();
    this.initOperatorAndValueChangeHandlers();
  }

  applyFilter(): void {
    this.emitFilterValue();
    this.closeFilterPopup();
  }

  emitFilterValue(): void {
    const value = this.form.get('value')!.value;
    const filterOperator = this.form.get('filterOperator')!.value;
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (!value) return;
    const subValues: FilterSubValue[] = [];
    Object.entries(value).forEach(([key, val]) => {
      if (val) {
        subValues.push({
          operator: filterOperator,
          value: String(val),
          subValueId: 'empowerment.' + key
        });
      }
    });
    this.setFilterValue.emit({
      value: EMPOWERMENT_SHAPE_INDICATOR,
      label: null,
      filterOperator,
      subValues,
      closeAfter: false,
    });
  }

  empowermentFilterChanged(empowermentValue: Nullable<EmpowermentDto>) {
    this.form.get('value')!.setValue(empowermentValue);
    this.selectedEmpowerment = empowermentValue;
  }

}
