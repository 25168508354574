<div class="w-full h-full">
  @if (isActivityView) {
    <icz-table-preview
      [opened]="openQuickPreview && isQuickPreviewOpen"
      (closed)="iczTable.unsetActiveRow()">
      <icz-label previewHeader label="Přehled úkonu"></icz-label>
      @if (activeRow !== null && openQuickPreview && isQuickPreviewOpen) {
        <icz-quick-activity-preview
          [isMultiColumn]="false"
          [activityId]="activeRow?.id"
          (circulationActionCompleted)="dataSource.reload(true)"
        ></icz-quick-activity-preview>
      }
    </icz-table-preview>
  }

  @if (_viewType && columnsData) {
    <icz-table
      #iczTable
      class="grow"
      [id]="tableId"
      [dataSource]="dataSource"
      [columnsData]="columnsData"
      [config]="config"
      [rowKeyFn]="gracefulElasticRowKeyFn"
      (pageLoadStarted)="onPageLoadStarted($event)"
      (activeRowChanged)="setActiveRow($event)"
      (selectedRowsChanged)="setSelectedRows($event)"
    >
      <icz-document-toolbar-buttons
        tools
        [isUnitView]="isUnitView"
        [selectedRows]="selectedRows"
        [view]="_viewType"
        (operationCompleted)="unselectAndReloadRow()"
      ></icz-document-toolbar-buttons>
      <ng-content select="[tableTitle]" tableTitle></ng-content>
      <ng-content select="[tableTabs]" tableTabs></ng-content>
      <ng-content select="[noDataLabel]" noDataLabel></ng-content>
      <icz-label notFoundLabel label="Žádné úkoly nebyly nalezeny"></icz-label>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.OBJECT_CLASS">
        <icz-table-entity-icon
          [documentsTableEntity]="row"
          [view]="viewType"
        ></icz-table-entity-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.HAS_TASKS">
        <icz-task-activity-state-icon
          [ngClass]="{'visible-clickable-area': !isIssdTaskView}"
          [activity]="isActivityView ? row : null"
          [task]="isTaskView ? row : null"
          [entityId]="getEntityId(row)"
          [entityType]="getEntityType(row)"
          (circulationActionCompleted)="dataSource.reload(true)"
        ></icz-task-activity-state-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.ORDER_BY_DOCUMENT">
        <span>{{row.orderByDocument}}</span>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.ACTIVITY_TYPE; withEllipsis: true">
        @if (row.activityType; as activityType) {
          <span [iczTooltip]="'en.circulationActivityType.' + activityType">
            <icz-activity-type-designator [activityType]="activityType"></icz-activity-type-designator>
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.TASK_TYPE; withEllipsis: true">
        @if (row.taskType; as taskType) {
          <span [iczTooltip]="'en.circulationTaskType.' + taskType">
            <icz-task-type-designator [taskType]="taskType"></icz-task-type-designator>
          </span>
        }
      </ng-container>
      <!-- If using withEllipsis: true for *iczColumnTemplate, container, template contents must be wrapped inside a <span> element. -->
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.REF_NUMBER; withEllipsis: true; let column = column">
        <icz-ref-number-link [entity]="row"
          [columnDefinition]="column"
          [view]="viewType!"
          (detailOpened)="iczTable.setFocusedRow(row)"
        ></icz-ref-number-link>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.DEADLINE; let column = column">
        <span [iczPassedDeadlineWarning]="row.deadline" [iczTooltip]="row.deadline">
          {{ row.deadline | localizedDate }}
        </span>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.ACTIVITY_STATE; let column = column">
        <div class="pr-4">
          <icz-activity-state-tag [activityState]="row.activityState"></icz-activity-state-tag>
        </div>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.COMPONENT_ID; let column = column">
        @if (isComponentCirculation(row)) {
          <a
            class="p-4 icz-body-1 visible-clickable-area" iczLinkWithoutHref
            (click.stop)="openCirculationComponent(row)"
            >
            <span>{{ row.componentLabel }}</span>
          </a>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.TASK_STATE; let column = column">
        <div class="pr-4">
          <icz-task-state-tag [taskState]="row.taskState"></icz-task-state-tag>
        </div>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.REPRESENTING_SUBJECT; withEllipsis: true">
        @if (row.representingSubject; as representingSubject) {
          <span [iczTooltip]="representingSubject">
            {{ representingSubject }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.LEAF_TARGET_PARTICIPANTS; withEllipsis: true">
        @if (row.leafTargetParticipants?.length > 0) {
          @if (getPrimaryTargetParticipantText(row.leafTargetParticipants); as primaryTargetParticipant) {
            <span
              [iczTooltip]="row.leafTargetParticipants | findInList:functionalPositionTreeOptions:'fp'"
            >
              {{ primaryTargetParticipant }}
            </span>
          }
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.LEAF_TARGET_PARTICIPANT_ORG_UNITS; withEllipsis: true">
        @if (row.leafTargetParticipantOrgunits?.length > 0) {
          @if (getPrimaryTargetParticipantOrgUnitText(row.leafTargetParticipantOrgunits); as primaryTargetParticipantOrgUnit) {
            <span
              [iczTooltip]="row.leafTargetParticipantOrgunits | findInList:organizationalUnitTreeOptions:'ou'"
              >
              {{ primaryTargetParticipantOrgUnit }}
            </span>
          }
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.INIT_FUNCTIONAL_POSITION_ID; withEllipsis: true">
        @if (
          (row.initFunctionalPositionId | findFunctionalPositionById:true | async) +
          ' - ' +
          (row.initUserId | findUserById | async)
          ; as initFunctionalPositionId
          ) {
          <span
            [iczTooltip]="initFunctionalPositionId"
            >
            {{ initFunctionalPositionId }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.COMPONENT_NAME;">
        @if (row?.fullComponent; as baseComponent) {
          <div>
            @if (getLatestDigitalComponentVersion(baseComponent); as version) {
              <div>
                <icz-essl-component-link
                  class="visible-clickable-area block p-8"
                  [versionId]="version.id!"
                  [documentEsslComponent]="baseComponent"
                  [showFileName]="true"
                  [isReadonly]="true"
                  (click)="$event.stopPropagation()"
                  (metadataChanged)="dataSource.reload(true)"
                ></icz-essl-component-link>
              </div>
            }
          </div>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.SIGNATURES;">
        @if (row?.fullComponent; as baseComponent) {
          <div>
            @if (getLatestDigitalComponentVersion(baseComponent); as version) {
              <div>
                @if (version.digitalComponentVersionSignatureFlags?.length) {
                  <icz-digital-component-flags
                    [digitalComponentVersionId]="version.id!"
                    [flags]="version.digitalComponentVersionSignatureFlags"
                  ></icz-digital-component-flags>
                }
              </div>
            }
          </div>
        }
      </ng-container>
      <!-- FE-only table cell -->
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.ACTIVITY_NOTES; let column = column">
        <icz-activity-notes
          [activity]="row"
        ></icz-activity-notes>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: CirculationTableColumn.REJECTION_NOTE; let column = column">
        @if (row.taskState === CirculationTaskState.REJECTED_BY_ISSD || row.taskState === CirculationTaskState.RETURNED_BY_ISSD) {
          <icz-issd-task-rejected-notes
            [task]="row"
          ></icz-issd-task-rejected-notes>
        }
        @else {
          @if (row.rejectionNote; as rejectionNote) {
            <span [iczTooltip]="rejectionNote">{{ rejectionNote }}</span>
          }
        }
      </ng-container>
    </icz-table>
  }
</div>
