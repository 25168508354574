import {Button} from '../button-collection/button-collection.component';
import {Observable, Subject} from 'rxjs';

/**
 * ToolbarProvider is a class that produces a fragment of toolbar
 * which can be further adjusted based on entity context.
 * - Generic parameter E ... Entity Context
 * - Generic parameter A ... Actions Enum - used for find grained control over handling action completion events
 * - Generic parameter C ... Extra Context - needed when data, such as view name,
 *    apart from selected entity are used to conditionally render the buttons.
 */
export abstract class ToolbarProvider<E, A, C = void> {

  private _actionCompleted = new Subject<A>();
  actionCompleted$ = this._actionCompleted.asObservable();

  abstract getToolbarButtons(selection: Nullable<E[]>, extraContext: C): Button[] | Observable<Button[]>;

  protected announceActionCompleted(action: A) {
    this._actionCompleted.next(action);
  }

}
