import {ChangeDetectorRef, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {DeliveryServiceCombinationDto} from '|api/codebook';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczFormGroup} from '@icz/angular-form-elements';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {isValidNow} from '../../../../../core/services/data-mapping.utils';
import {IczOption} from '@icz/angular-form-elements';

@Component({
  selector: 'icz-delivery-service-combination-selector',
  templateUrl: './delivery-service-combination-selector.component.html',
  styleUrls: ['./delivery-service-combination-selector.component.scss'],
})
export class DeliveryServiceCombinationSelectorComponent implements IczOnChanges {

  private codebookService = inject(CodebookService);
  private cd = inject(ChangeDetectorRef);

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName!: string;
  @Input({required: true})
  deliveryTypeId: Nullable<number>;
  @Output()
  loadingStatusChanged = new EventEmitter<boolean>(true);

  deliveryServiceCombinationOptions: IczOption[] = [];
  hoveredDeliveryServiceCombination: Nullable<DeliveryServiceCombinationDto>;

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.deliveryTypeId) {
      if (this.deliveryTypeId) {
        this.loadDeliveryServiceCombinationOptions(this.deliveryTypeId);
      }
      else {
        this.deliveryServiceCombinationOptions = [];
      }
    }
  }

  showDeliveryServiceCombinationInfo(deliveryServiceCombination: DeliveryServiceCombinationDto) {
    this.hoveredDeliveryServiceCombination = deliveryServiceCombination;
  }

  hideDeliveryServiceCombinationInfo() {
    this.hoveredDeliveryServiceCombination = null;
  }

  private loadDeliveryServiceCombinationOptions(selectedDeliveryTypeId: number) {
    this.loadingStatusChanged.emit(true);

    this.codebookService.deliveryServiceCombinations().subscribe(combinations => {
      this.deliveryServiceCombinationOptions = combinations
        .filter(c => isValidNow(c))
        .filter(c => c.basicService?.deliveryTypeId)
        .filter(c => c.basicService?.deliveryTypeId === selectedDeliveryTypeId)
        .map(c => ({
          value: c.id,
          label: `${c.name ?? ''}`,
          data: {
            deliveryServiceCombination: c,
          }
        }));

      this.loadingStatusChanged.emit(false);

      if (!this.deliveryServiceCombinationOptions.some(op => op.value === this.form.get(this.controlName)?.value)) {
        this.form.get(this.controlName)?.setValue(null);
      }
      this.cd.detectChanges();
    });
  }

}
