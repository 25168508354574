import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ConsignmentValidationResult} from '|api/sad';
import {EsslErrorCodeExceptionDto} from '|api/commons';

export interface ConsignmentValidationDialogResult extends ConsignmentValidationResult {
  alreadyRan: boolean;
  loading: boolean;
}


@Component({
  selector: 'icz-consignment-validation',
  templateUrl: './consignment-validation.component.html',
  styleUrls: ['./consignment-validation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentValidationComponent {

  @Input({required: true})
  consignmentValidationResult: Nullable<ConsignmentValidationDialogResult>;

  isErrorTechnical(error: EsslErrorCodeExceptionDto) {
    return error.status && error.status >= 500 && error.status < 600;
  }

}
