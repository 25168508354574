/* eslint-disable @typescript-eslint/quotes */

/**
 * Icons aggregated from libs/icz-angular-modal/assets/icons/.
 *
 * Used as a method of resource inlining to decrease HTTP GET overhead
 * for fetching lots of small icons at application startup.
 */
export const ICONS = {
  "modal_error": null,
  "modal_info": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"70\" height=\"70\" fill=\"none\" viewBox=\"0 0 70 70\">\n    <path fill=\"#2A68DE\" fill-rule=\"evenodd\" d=\"M0 35c0 19.33 15.67 35 35 35s35-15.67 35-35S54.33 0 35 0 0 15.67 0 35zm35 32c17.673 0 32-14.327 32-32C67 17.327 52.673 3 35 3 17.327 3 3 17.327 3 35c0 17.673 14.327 32 32 32zm-6-47c0 3.314 2.686 6 6 6s6-2.686 6-6-2.686-6-6-6-6 2.686-6 6zm6 4c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm6 12c0-3.314-2.686-6-6-6s-6 2.686-6 6v14c0 3.314 2.686 6 6 6s6-2.686 6-6V36zm-10 0c0-2.21 1.79-4 4-4s4 1.79 4 4v14c0 2.21-1.79 4-4 4s-4-1.79-4-4V36z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "warning_color": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"18\" fill=\"none\" viewBox=\"0 0 20 18\">\n    <path fill=\"#FFAC33\" fill-rule=\"evenodd\" d=\"M19.802 15.512L11.28.739c-.13-.225-.317-.411-.542-.541-.707-.408-1.61-.165-2.018.541L.198 15.512c-.13.224-.198.479-.198.738 0 .816.661 1.477 1.477 1.477h17.045c.26 0 .514-.068.739-.197.706-.408.949-1.312.541-2.018zM10 1.478L1.477 16.25h17.045L10 1.478zm0 12.925c-.612 0-1.108-.496-1.108-1.108 0-.612.496-1.108 1.108-1.108.612 0 1.108.496 1.108 1.108 0 .612-.496 1.108-1.108 1.108zm0-8.495c.53 0 .96.43.96.961 0 .046-.002.091-.009.136l-.53 3.708c-.03.21-.21.366-.421.366-.212 0-.392-.156-.422-.366l-.53-3.708c-.075-.525.29-1.012.816-1.087.045-.007.09-.01.136-.01z\" clip-rule=\"evenodd\"/>\n</svg>\n"
};
