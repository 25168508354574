import {Component, inject} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {ApiOwnConsignmentService, OwnConsignmentDistributionDto, OwnPaperConsignmentDto} from '|api/sad';
import {LoadingIndicatorService, TabItem} from '@icz/angular-essentials';
import {
  PrepareOrDispatchDialogData,
  PrepareOrDispatchDialogResult,
  PrepareOrDispatchDialogType,
  PrepareOrDispatchFormData,
} from '../../dispatch-dialogs.model';
import {cloneDeep, isEqual, isObjectLike} from 'lodash';
import {ConsignmentType, EsslMoneyDto, OwnConsignmentStatus} from '|api/commons';
import {TranslateService} from '@ngx-translate/core';
import {
  GenericOwnConsignment, isOwnInternalPaperElasticConsignment,
  isOwnMultiPaperElasticConsignment,
  isOwnPaperElasticConsignment
} from '../../../own-consignment-table/model/own-consignment-model';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {DISTRIBUTOR_POST_CODE} from '../../../shared-document.utils';
import {DialogService, injectModalData, injectModalRef} from '@icz/angular-modal';

interface PrepareOrDispatchDialogConfig {
  confirmLabel: string;
  confirmReq$: {
    // API returns void observable for DELETE methods, hence Observable<GenericOwnConsignment> | void>
    paper$?: Nullable<Observable<GenericOwnConsignment | void>>,
    multiPaper$?: Nullable<Observable<GenericOwnConsignment | void>>,
    internalPaper$?: Nullable<Observable<GenericOwnConsignment | void>>,
    bulkConsignments$?: Nullable<Observable<void>>,
  };
}

function checkObjectPropsAreNull(val: any) {
  let propsAreNull = true;
  if (isObjectLike(val)) {
    for (const nestedKey in val) {
      if (val[nestedKey as any] !== null) propsAreNull = false;
    }
  }
  return propsAreNull;
}

function isNullOrObjectsPropsAreNull(o1: any, o2: any) {
  return isEqual(o1, o2) ||
    (o1 == null && checkObjectPropsAreNull(o2)) ||
    (o2 == null && checkObjectPropsAreNull(o1));
}

@Component({
  selector: 'icz-prepare-or-dispatch',
  templateUrl: './prepare-or-dispatch-dialog.component.html',
  styleUrls: ['./prepare-or-dispatch-dialog.component.scss'],
})
export class PrepareOrDispatchDialogComponent {

  private modalRef = injectModalRef<Nullable<PrepareOrDispatchDialogResult>>();
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private dialogService = inject(DialogService);
  private translateService = inject(TranslateService);
  private codebookService = inject(CodebookService);
  protected modalData = injectModalData<PrepareOrDispatchDialogData>();

  readonly GENERAL_TAB = 'general';

  isBulk = false;
  defaultConsignment: Nullable<OwnPaperConsignmentDto>;
  generalTabInitialValue: Nullable<OwnPaperConsignmentDto>;
  consignments: OwnPaperConsignmentDto[] = [];
  activeTabId = this.GENERAL_TAB;
  overrideValuesByGeneralTab = true;
  overrideQuestionModalOpened = false;

  tabs: TabItem[] = [
    {
      id: this.GENERAL_TAB,
      label: 'Výchozí hodnoty',
    },
  ];

  get activeTab(): Nullable<TabItem<any>> {
    return this.tabs.find(t => t.id === this.activeTabId);
  }

  get isBulkAction(): boolean {
    return this.modalData.consignments.length > 1;
  }

  private patchDistributionValues(c: OwnPaperConsignmentDto, v: OwnConsignmentDistributionDto): OwnPaperConsignmentDto {
    return {
      ...c,
      payoutAmount: v.payoutAmount as EsslMoneyDto,
      weight: v.weight,
      consignmentPostingNumber: v.consignmentPostingNumber,
    };
  }

  private isDistributionValueEqual(v1: OwnPaperConsignmentDto | OwnConsignmentDistributionDto,
                                   v2: OwnPaperConsignmentDto | OwnConsignmentDistributionDto): boolean {
    return v1.weight === v2.weight &&
      isNullOrObjectsPropsAreNull(v1.payoutAmount, v2.payoutAmount) &&
      isNullOrObjectsPropsAreNull(v1.consignmentPostingNumber, v2.consignmentPostingNumber);
  }

  get formData(): PrepareOrDispatchFormData {
    return {
      type: this.modalData.type,
      documentId: this.modalData.documentId,
      showMetadata: this.isBulkAction,
    };
  }

  get generalTabFormData(): PrepareOrDispatchFormData {
    return {...this.formData, showMetadata: false};
  }

  ngOnInit() {
    if (this.modalData.consignments) {
      this.loadingIndicatorService.doLoading(
        combineLatest([
          this.codebookService.deliveryServiceCombinationsForCzechPost(),
          this.codebookService.distributors(),
          this.apiOwnConsignmentService.ownConsignmentGetByIds({body:  this.modalData.consignments!.map(c => c.id)}),
        ]),
        this
      ).subscribe(([dsc, distributors, consignments]) => {
        this.consignments = cloneDeep(consignments as OwnPaperConsignmentDto[]) ;
        this.consignments.forEach((c, i) => {
          this.tabs.push({id: c.id!.toString(), label: `${i + 1}. ${this.translateService.instant('Zásilka')}`});
        });

        const distributorCzechPostId = distributors.find(d => d.name === DISTRIBUTOR_POST_CODE)!.id; // name property has unique constraint
        const deliveryServiceCombinationForCzechPostDistributorId = dsc!.content!.find(
          d => d.basicService.distributorId === distributorCzechPostId
        )!.id;

        // setting mock consignment for the Default tab which leads to "full" form for czech post - including CZD, weight and payoutAmount
        this.defaultConsignment = {
          componentIds: [0],
          consignmentType: ConsignmentType.OWN_PAPER_ADDRESS,
          deliveryTypeId: 0,
          id: 0,
          documentId: 0,
          ownerFunctionalPositionId: 0,
          status: OwnConsignmentStatus.IN_DISTRIBUTION,
          deliveryServiceCombinationId: deliveryServiceCombinationForCzechPostDistributorId,
          consigneeAddress: {box: '', country: '', postOffice: '', _Class: '', postalCode: ''},
        };

        this.generalTabInitialValue = cloneDeep(this.defaultConsignment);
      });

      this.isBulk = this.modalData.consignments.length > 1;
    }
  }

  tabChanged(activatedTab: TabItem<string>) {
    if (activatedTab.id === this.GENERAL_TAB && this.activeTabId !== this.GENERAL_TAB) {
      this.overrideValuesByGeneralTab = false;
      this.generalTabInitialValue = cloneDeep(this.defaultConsignment);
    }

    this.activeTabId = activatedTab.id;
  }

  specificConsignmentValueChange(value: OwnConsignmentDistributionDto, i: number) {
    this.consignments[i] = this.patchDistributionValues(this.consignments[i], value);
  }

  specificConsignmentsHaveNullDistributionValues() {
    let result = true;
    this.consignments.forEach(c => {
      if (c.weight !== null || !checkObjectPropsAreNull(c.payoutAmount) || !checkObjectPropsAreNull(c.consignmentPostingNumber)) {
        result = false;
      }
    });
    return result;
  }

  generalTabValueChange(value: OwnConsignmentDistributionDto) {
    const isDistributionValueEqual = Boolean(this.generalTabInitialValue) && this.isDistributionValueEqual(this.generalTabInitialValue!, value);
    if (this.defaultConsignment) {
      this.defaultConsignment = this.patchDistributionValues(this.defaultConsignment, value);
    }
    if (this.generalTabInitialValue) {
      this.generalTabInitialValue = this.patchDistributionValues(this.generalTabInitialValue, value);
    } else {
      this.generalTabInitialValue = cloneDeep(this.defaultConsignment);
    }

    if (isDistributionValueEqual) {
      // if default tab value didn't actually change, only event was fired, do nothing
      return;
    }
    if (this.specificConsignmentsHaveNullDistributionValues()) {
      // if user didn't yet change distribution values for specific consignments, always use values from default tab
      this.overrideValuesByGeneralTab = true;
    }
    if (this.overrideValuesByGeneralTab) {
      this.consignments.forEach((v, i) => {
        this.consignments[i] = this.patchDistributionValues(this.consignments[i], this.defaultConsignment!);
      });
    } else {
      if (this.overrideQuestionModalOpened) return;

      this.dialogService.openQuestionDialogWithAnswer({
        title: 'Přepsat vlastní hodnoty',
        description: 'Chystáte se přepsat vlastní hodnoty jednotlivých objektů hodnotami z první záložky.',
        question: 'Přejete si přepsat vlastní hodnoty výchozími hodnotami?',
        leftButtonTitle: 'Přepsat výchozími hodnotami',
      }).subscribe(result => {
        this.overrideValuesByGeneralTab = true;
        this.overrideQuestionModalOpened = false;

        if (result) {
          this.consignments.forEach((v, i) => {
            this.consignments[i] = this.patchDistributionValues(this.consignments[i], this.defaultConsignment!);
          });
        }
      });
      this.overrideQuestionModalOpened = true;
    }
  }

  get consignmentTypeRequest$(): keyof PrepareOrDispatchDialogConfig['confirmReq$'] {
    if (this.isBulk) return 'bulkConsignments$';
    else {
      if (isOwnPaperElasticConsignment(this.modalData.consignments[0])) return 'paper$';
      else if (isOwnMultiPaperElasticConsignment(this.modalData.consignments[0])) return 'multiPaper$';
      else if (isOwnInternalPaperElasticConsignment(this.modalData.consignments[0])) return 'internalPaper$';
      else return 'paper$';
    }
  }

  get prepareOrDispatchDialogConfig(): Nullable<Record<PrepareOrDispatchDialogType, PrepareOrDispatchDialogConfig>> {
    if (!this.modalData?.consignments[0]) return null;

    const distributionDtos: OwnConsignmentDistributionDto[] = this.consignments.map(c => {
      return {
        consignmentPostingNumber: c.consignmentPostingNumber,
        consignmentType: c.consignmentType,
        id: c.id,
        payoutAmount: c.payoutAmount,
        weight: c.weight,
      };
    });
    const id = this.modalData.consignments[0].id!;
    const singleConsignmentReqData = {id, body: distributionDtos[0]};

    return {
      [PrepareOrDispatchDialogType.PREPARE_FOR_DISTRIBUTION]: {
        confirmLabel: 'Předat do distribuce',
        confirmReq$: {
          paper$: this.apiOwnConsignmentService.ownConsignmentPrepareForDistributionPaper(singleConsignmentReqData),
          multiPaper$: this.apiOwnConsignmentService.ownConsignmentPrepareForDistributionMultiPaper(singleConsignmentReqData),
          internalPaper$: this.apiOwnConsignmentService.ownConsignmentPrepareForDistributionInternalPaper(singleConsignmentReqData),
          bulkConsignments$: this.apiOwnConsignmentService.ownConsignmentBulkPrepareForDistributionPaper({body: distributionDtos}),
        },
      },
      [PrepareOrDispatchDialogType.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION]: {
        confirmLabel: 'Převzít a předat do distribuce',
        confirmReq$: {
          paper$: this.apiOwnConsignmentService.ownConsignmentTakeoverAndPrepareForDistributionPaper(singleConsignmentReqData),
          multiPaper$: this.apiOwnConsignmentService.ownConsignmentTakeoverAndPrepareForDistributionMultiPaper(singleConsignmentReqData),
          internalPaper$: this.apiOwnConsignmentService.ownConsignmentTakeoverAndPrepareForDistributionInternalPaper(singleConsignmentReqData),
          bulkConsignments$: this.apiOwnConsignmentService.ownConsignmentBulkTakeoverAndPrepareForDistributionPaper({body: distributionDtos}),
        },
      },
      [PrepareOrDispatchDialogType.MARK_AS_DISPATCH]: {
        confirmLabel: 'Potvrdit vypravení',
        confirmReq$: {
          paper$: this.apiOwnConsignmentService.ownConsignmentMarkAsDispatchedPaper(singleConsignmentReqData),
          multiPaper$: this.apiOwnConsignmentService.ownConsignmentMarkAsDispatchedMultiPaper(singleConsignmentReqData),
          internalPaper$: this.apiOwnConsignmentService.ownConsignmentMarkAsDispatchedInternalPaper(singleConsignmentReqData),
          bulkConsignments$: this.apiOwnConsignmentService.ownConsignmentBulkMarkAsDispatchedPaper({body: distributionDtos}),
        },
      },
    };
  }

  submit() {
    this.loadingIndicatorService.doLoading(
      this.prepareOrDispatchDialogConfig![this.modalData.type]!.confirmReq$[this.consignmentTypeRequest$ as keyof PrepareOrDispatchDialogConfig['confirmReq$']]! as Observable<GenericOwnConsignment | void>,
      this,
    ).subscribe(ownConsignment => {
      this.modalRef.close({
        resultType: this.modalData.type,
        consignment: ownConsignment,
      } as PrepareOrDispatchDialogResult);
    });
  }

  close() {
    this.modalRef.close(null);
  }
}
