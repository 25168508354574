/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AgendaTransferEnvelopeDto } from '|api/commons';
import { agendaTransferEnvelopeFindAll } from '../fn/agenda-transfer-envelope/agenda-transfer-envelope-find-all';
import { AgendaTransferEnvelopeFindAll$Params } from '../fn/agenda-transfer-envelope/agenda-transfer-envelope-find-all';
import { agendaTransferEnvelopeFindAvailable } from '../fn/agenda-transfer-envelope/agenda-transfer-envelope-find-available';
import { AgendaTransferEnvelopeFindAvailable$Params } from '../fn/agenda-transfer-envelope/agenda-transfer-envelope-find-available';
import { agendaTransferEnvelopeFindById } from '../fn/agenda-transfer-envelope/agenda-transfer-envelope-find-by-id';
import { AgendaTransferEnvelopeFindById$Params } from '../fn/agenda-transfer-envelope/agenda-transfer-envelope-find-by-id';
import { agendaTransferEnvelopeUpdateEnvelopes } from '../fn/agenda-transfer-envelope/agenda-transfer-envelope-update-envelopes';
import { AgendaTransferEnvelopeUpdateEnvelopes$Params } from '../fn/agenda-transfer-envelope/agenda-transfer-envelope-update-envelopes';


/**
 * Controller for agenda transfer envelope objects
 */
@Injectable({ providedIn: 'root' })
export class ApiAgendaTransferEnvelopeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `agendaTransferEnvelopeUpdateEnvelopes()` */
  static readonly AgendaTransferEnvelopeUpdateEnvelopesPath = '/document/agenda-transfer-envelope/update-configuration';

  /**
   * Update agenda transfer envelopes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferEnvelopeUpdateEnvelopes()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agendaTransferEnvelopeUpdateEnvelopes$Response(params: AgendaTransferEnvelopeUpdateEnvelopes$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AgendaTransferEnvelopeDto>>> {
    return agendaTransferEnvelopeUpdateEnvelopes(this.http, this.rootUrl, params, context);
  }

  /**
   * Update agenda transfer envelopes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferEnvelopeUpdateEnvelopes$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agendaTransferEnvelopeUpdateEnvelopes(params: AgendaTransferEnvelopeUpdateEnvelopes$Params, context?: HttpContext): Observable<Array<AgendaTransferEnvelopeDto>> {
    return this.agendaTransferEnvelopeUpdateEnvelopes$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AgendaTransferEnvelopeDto>>): Array<AgendaTransferEnvelopeDto> => r.body)
    );
  }

  /** Path part for operation `agendaTransferEnvelopeFindById()` */
  static readonly AgendaTransferEnvelopeFindByIdPath = '/document/agenda-transfer-envelope/{id}';

  /**
   * Find agenda transfer envelope object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferEnvelopeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferEnvelopeFindById$Response(params: AgendaTransferEnvelopeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<AgendaTransferEnvelopeDto>> {
    return agendaTransferEnvelopeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find agenda transfer envelope object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferEnvelopeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferEnvelopeFindById(params: AgendaTransferEnvelopeFindById$Params, context?: HttpContext): Observable<AgendaTransferEnvelopeDto> {
    return this.agendaTransferEnvelopeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AgendaTransferEnvelopeDto>): AgendaTransferEnvelopeDto => r.body)
    );
  }

  /** Path part for operation `agendaTransferEnvelopeFindAvailable()` */
  static readonly AgendaTransferEnvelopeFindAvailablePath = '/document/agenda-transfer-envelope/find-available';

  /**
   * Gets agenda transfer envelopes available for current FP
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferEnvelopeFindAvailable()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferEnvelopeFindAvailable$Response(params?: AgendaTransferEnvelopeFindAvailable$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<AgendaTransferEnvelopeDto>>> {
    return agendaTransferEnvelopeFindAvailable(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets agenda transfer envelopes available for current FP
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferEnvelopeFindAvailable$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferEnvelopeFindAvailable(params?: AgendaTransferEnvelopeFindAvailable$Params|null|undefined, context?: HttpContext): Observable<Array<AgendaTransferEnvelopeDto>> {
    return this.agendaTransferEnvelopeFindAvailable$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AgendaTransferEnvelopeDto>>): Array<AgendaTransferEnvelopeDto> => r.body)
    );
  }

  /** Path part for operation `agendaTransferEnvelopeFindAll()` */
  static readonly AgendaTransferEnvelopeFindAllPath = '/document/agenda-transfer-envelope/';

  /**
   * Find all agenda transfer envelope objects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferEnvelopeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferEnvelopeFindAll$Response(params?: AgendaTransferEnvelopeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<AgendaTransferEnvelopeDto>>> {
    return agendaTransferEnvelopeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all agenda transfer envelope objects
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferEnvelopeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferEnvelopeFindAll(params?: AgendaTransferEnvelopeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<AgendaTransferEnvelopeDto>> {
    return this.agendaTransferEnvelopeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AgendaTransferEnvelopeDto>>): Array<AgendaTransferEnvelopeDto> => r.body)
    );
  }

}
