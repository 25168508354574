export enum ExecutionDateClauseTimestampUsage {
  NEVER = 'NEVER',
  ALLWAYS = 'ALLWAYS',
  BY_USER = 'BY_USER',
}

export enum FileHandlingTypeMode {
  PRIOR_ORDER = 'PRIOR_ORDER',
  COLLECTION_FILE = 'COLLECTION_FILE',
  BOTH = 'BOTH',
}

export enum StrongCrossReferenceType {
  OLDEST ='OLDEST',
  NEWEST ='NEWEST',
}
