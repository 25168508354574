import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT} from '../api';
import {ApiBulkPostingFormService, BulkPostingFormDto} from '|api/sad';
import {SearchApiService} from './search-api.service';
import {FilterOperator, Page, SearchParams} from '@icz/angular-table';

@Injectable({
  providedIn: 'root'
})
export class BulkPostingFormsSearchService extends SearchApiService {

  findAllBulkPostingForms(searchParams: Partial<SearchParams>, dispatchOfficeId: Nullable<number>): Observable<Page<BulkPostingFormDto>> {
    if (dispatchOfficeId) {
      searchParams.filter!.push({fieldName: 'dispatchOfficeId', value: String(dispatchOfficeId), operator: FilterOperator.equals});
    }

    return this.searchApi<Page<BulkPostingFormDto>>(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiBulkPostingFormService.BulkPostingFormSearchPath, {}));
  }

}
