/* tslint:disable */
/* eslint-disable */

/**
 * Applied dimensions; at least one dimension is required
 */
export enum StatisticsDimension {
  DISPOSAL_SCHEDULE = 'DISPOSAL_SCHEDULE',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  ENTITY_CLASS = 'ENTITY_CLASS',
  ENTITY_STATE = 'ENTITY_STATE',
  FILE_TYPE = 'FILE_TYPE',
  OBJECT_CLASS = 'OBJECT_CLASS',
  OWNER_FP = 'OWNER_FP',
  OWNER_ORG_UNIT = 'OWNER_ORG_UNIT',
  SECURITY_CATEGORY = 'SECURITY_CATEGORY',
  STORAGE_UNIT = 'STORAGE_UNIT',
  BUSINESS_RULE = 'BUSINESS_RULE',
  PUID = 'PUID',
  HANDED_OVER_BY_FP = 'HANDED_OVER_BY_FP',
  HANDED_OVER_BY_ORG_UNIT = 'HANDED_OVER_BY_ORG_UNIT',
  REGISTRY_OFFICE = 'REGISTRY_OFFICE',
  ENTITY_CLASS_TYPE = 'ENTITY_CLASS_TYPE'
}
