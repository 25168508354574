/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageSubjectObjectRelationDto } from '../models/page-subject-object-relation-dto';
import { relatedObjectElasticFindRelations } from '../fn/related-object/related-object-elastic-find-relations';
import { RelatedObjectElasticFindRelations$Params } from '../fn/related-object/related-object-elastic-find-relations';
import { relatedObjectGetRelationsCount } from '../fn/related-object/related-object-get-relations-count';
import { RelatedObjectGetRelationsCount$Params } from '../fn/related-object/related-object-get-relations-count';
import { relatedObjectLinkObjectsToSubject } from '../fn/related-object/related-object-link-objects-to-subject';
import { RelatedObjectLinkObjectsToSubject$Params } from '../fn/related-object/related-object-link-objects-to-subject';
import { relatedObjectLinkSubjectsToObject } from '../fn/related-object/related-object-link-subjects-to-object';
import { RelatedObjectLinkSubjectsToObject$Params } from '../fn/related-object/related-object-link-subjects-to-object';
import { relatedObjectRemoveRepresentingSubject } from '../fn/related-object/related-object-remove-representing-subject';
import { RelatedObjectRemoveRepresentingSubject$Params } from '../fn/related-object/related-object-remove-representing-subject';
import { relatedObjectSearch } from '../fn/related-object/related-object-search';
import { RelatedObjectSearch$Params } from '../fn/related-object/related-object-search';
import { relatedObjectSetRepresentingSubject } from '../fn/related-object/related-object-set-representing-subject';
import { RelatedObjectSetRepresentingSubject$Params } from '../fn/related-object/related-object-set-representing-subject';
import { relatedObjectUnlinkObjectsFromSubject } from '../fn/related-object/related-object-unlink-objects-from-subject';
import { RelatedObjectUnlinkObjectsFromSubject$Params } from '../fn/related-object/related-object-unlink-objects-from-subject';
import { relatedObjectUnlinkSubjectsFromObject } from '../fn/related-object/related-object-unlink-subjects-from-object';
import { RelatedObjectUnlinkSubjectsFromObject$Params } from '../fn/related-object/related-object-unlink-subjects-from-object';
import { SubjectObjectRelationDto } from '|api/commons';
import { SubjectRecordDto } from '|api/commons';


/**
 * Controller for handling relations between subject records and related objects.
 */
@Injectable({ providedIn: 'root' })
export class ApiRelatedObjectService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `relatedObjectUnlinkSubjectsFromObject()` */
  static readonly RelatedObjectUnlinkSubjectsFromObjectPath = '/subject-object-relation/unlink-subjects-from-object';

  /**
   * Unlinks subjects from object.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectUnlinkSubjectsFromObject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectUnlinkSubjectsFromObject$Response(params: RelatedObjectUnlinkSubjectsFromObject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return relatedObjectUnlinkSubjectsFromObject(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlinks subjects from object.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectUnlinkSubjectsFromObject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectUnlinkSubjectsFromObject(params: RelatedObjectUnlinkSubjectsFromObject$Params, context?: HttpContext): Observable<void> {
    return this.relatedObjectUnlinkSubjectsFromObject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `relatedObjectUnlinkObjectsFromSubject()` */
  static readonly RelatedObjectUnlinkObjectsFromSubjectPath = '/subject-object-relation/unlink-objects-from-subject/{subjectId}';

  /**
   * Unlinks objects from subject.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectUnlinkObjectsFromSubject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectUnlinkObjectsFromSubject$Response(params: RelatedObjectUnlinkObjectsFromSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return relatedObjectUnlinkObjectsFromSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Unlinks objects from subject.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectUnlinkObjectsFromSubject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectUnlinkObjectsFromSubject(params: RelatedObjectUnlinkObjectsFromSubject$Params, context?: HttpContext): Observable<void> {
    return this.relatedObjectUnlinkObjectsFromSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `relatedObjectSetRepresentingSubject()` */
  static readonly RelatedObjectSetRepresentingSubjectPath = '/subject-object-relation/set-representing-subject/{subjectId}';

  /**
   * Sets representing subject to given related object.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectSetRepresentingSubject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectSetRepresentingSubject$Response(params: RelatedObjectSetRepresentingSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return relatedObjectSetRepresentingSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Sets representing subject to given related object.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectSetRepresentingSubject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectSetRepresentingSubject(params: RelatedObjectSetRepresentingSubject$Params, context?: HttpContext): Observable<void> {
    return this.relatedObjectSetRepresentingSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `relatedObjectRemoveRepresentingSubject()` */
  static readonly RelatedObjectRemoveRepresentingSubjectPath = '/subject-object-relation/remove-representing-subject';

  /**
   * Removes representing subject from given related object.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectRemoveRepresentingSubject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectRemoveRepresentingSubject$Response(params: RelatedObjectRemoveRepresentingSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return relatedObjectRemoveRepresentingSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Removes representing subject from given related object.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectRemoveRepresentingSubject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectRemoveRepresentingSubject(params: RelatedObjectRemoveRepresentingSubject$Params, context?: HttpContext): Observable<void> {
    return this.relatedObjectRemoveRepresentingSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `relatedObjectLinkSubjectsToObject()` */
  static readonly RelatedObjectLinkSubjectsToObjectPath = '/subject-object-relation/link-subjects-to-object';

  /**
   * Links subjects to object.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectLinkSubjectsToObject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectLinkSubjectsToObject$Response(params: RelatedObjectLinkSubjectsToObject$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubjectObjectRelationDto>>> {
    return relatedObjectLinkSubjectsToObject(this.http, this.rootUrl, params, context);
  }

  /**
   * Links subjects to object.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectLinkSubjectsToObject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectLinkSubjectsToObject(params: RelatedObjectLinkSubjectsToObject$Params, context?: HttpContext): Observable<Array<SubjectObjectRelationDto>> {
    return this.relatedObjectLinkSubjectsToObject$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubjectObjectRelationDto>>): Array<SubjectObjectRelationDto> => r.body)
    );
  }

  /** Path part for operation `relatedObjectLinkObjectsToSubject()` */
  static readonly RelatedObjectLinkObjectsToSubjectPath = '/subject-object-relation/link-objects-to-subject/{subjectId}';

  /**
   * Links objects to subject.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectLinkObjectsToSubject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectLinkObjectsToSubject$Response(params: RelatedObjectLinkObjectsToSubject$Params, context?: HttpContext): Observable<StrictHttpResponse<SubjectRecordDto>> {
    return relatedObjectLinkObjectsToSubject(this.http, this.rootUrl, params, context);
  }

  /**
   * Links objects to subject.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectLinkObjectsToSubject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectLinkObjectsToSubject(params: RelatedObjectLinkObjectsToSubject$Params, context?: HttpContext): Observable<SubjectRecordDto> {
    return this.relatedObjectLinkObjectsToSubject$Response(params, context).pipe(
      map((r: StrictHttpResponse<SubjectRecordDto>): SubjectRecordDto => r.body)
    );
  }

  /** Path part for operation `relatedObjectElasticFindRelations()` */
  static readonly RelatedObjectElasticFindRelationsPath = '/subject-object-relation/find-relations';

  /**
   * Finds relations by input dto.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectElasticFindRelations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectElasticFindRelations$Response(params: RelatedObjectElasticFindRelations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubjectObjectRelationDto>>> {
    return relatedObjectElasticFindRelations(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds relations by input dto.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectElasticFindRelations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  relatedObjectElasticFindRelations(params: RelatedObjectElasticFindRelations$Params, context?: HttpContext): Observable<Array<SubjectObjectRelationDto>> {
    return this.relatedObjectElasticFindRelations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SubjectObjectRelationDto>>): Array<SubjectObjectRelationDto> => r.body)
    );
  }

  /** Path part for operation `relatedObjectSearch()` */
  static readonly RelatedObjectSearchPath = '/subject-object-relation/search-api';

  /**
   * Finds relations based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  relatedObjectSearch$Response(params?: RelatedObjectSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageSubjectObjectRelationDto>> {
    return relatedObjectSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds relations based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  relatedObjectSearch(params?: RelatedObjectSearch$Params|null|undefined, context?: HttpContext): Observable<PageSubjectObjectRelationDto> {
    return this.relatedObjectSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageSubjectObjectRelationDto>): PageSubjectObjectRelationDto => r.body)
    );
  }

  /** Path part for operation `relatedObjectGetRelationsCount()` */
  static readonly RelatedObjectGetRelationsCountPath = '/subject-object-relation/get-relations-count/{subjectId}';

  /**
   * Returns relation count for given subject
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `relatedObjectGetRelationsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  relatedObjectGetRelationsCount$Response(params: RelatedObjectGetRelationsCount$Params, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return relatedObjectGetRelationsCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns relation count for given subject
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `relatedObjectGetRelationsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  relatedObjectGetRelationsCount(params: RelatedObjectGetRelationsCount$Params, context?: HttpContext): Observable<number> {
    return this.relatedObjectGetRelationsCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
