<div [class.shortcut-grid]="isColumnView">
  @for (shortcut of shortcutListOptions; track shortcut.value) {
    <div
      class="shortcut" (click)="onShortcutClick(shortcut.value)"
      [class.shortcut--active]="shortcut.value === activeIntervalShortcut"
    >
      {{ shortcut.label | translate }}
    </div>
  }
</div>
<div class="shortcuts-subform">
  @if (activeIntervalShortcut === DatePickerShortcut.LAST_X_DAYS) {
    <form [formGroup]="daysAgoFromTodayForm">
      <icz-form-field iczAutoFocus type="integer" formControlName="days" (keydown.enter)="applySelectedDays()">
        <span prefix>{{ 'Počet dnů' | translate }}:&nbsp;</span>
      </icz-form-field>
      <icz-button
        primary label="Použít" class="mb-16"
        (onAction)="applySelectedDays()"
      ></icz-button>
    </form>
  }
</div>
