/* tslint:disable */
/* eslint-disable */

/**
 * Handover type for agenda transfer
 */
export enum AgendaTransferHandoverType {
  LINK = 'LINK',
  WITH_FILE = 'WITH_FILE'
}
