<form class="col grow" [waiting]="loadingIndicatorService.isLoading(this)" [formGroup]="form">
  <div class="col grow">
    <div class="row grow gap-24">
      <icz-section class="grow" label="Zobrazení sekcí na nástěnce">
        <icz-label label="Které informace chcete na nástěnce zobrazovat?" [hasNormalWhitespace]="true" class="mb-8"></icz-label>
        <icz-checkbox [formControlName]="IczStatistic.NEW_DOCUMENT_COUNT" label="Počet nových dokumentů" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.NEW_FILE_COUNT" label="Počet nových spisů" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.DOCUMENT_COUNT" label="Počet dokumentů" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.FILE_COUNT" label="Počet spisů" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.DOCUMENT_SIZE" label="Velikost dokumentů" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.FILE_SIZE" label="Velikost spisů" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.FILE_BY_PERMISSION_COUNT" label="Počet spisů podle přístupových oprávnění" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.NEW_COMPONENT_COUNT" label="Počet nových komponent" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.FILE_IN_STORING_UNIT_COUNT" label="Počet spisů v ukládacích jednotkách" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.NEW_ENTITY_CLASSES_CURRENT_CS" label="Nové věcné skupiny aktuálního Spisového plánu" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.FILES_TRANSFERRED_TO_REGISTRY_OFFICE" label="Počet spisů přenesených do spisovny" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.COMPONENT_COUNT" label="Počet komponent" class="mb-8"></icz-checkbox>
        <icz-checkbox [formControlName]="IczStatistic.DOCUMENT_BY_PERMISSION_COUNT" label="Počet dokumentů podle přístupových oprávnění" class="mb-8"></icz-checkbox>
      </icz-section>
    </div>
  </div>
</form>
<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingIndicatorService.isLoading(this)"
              (onAction)="submit()" primary label="Uložit"></icz-button>
  <icz-button rightButtons [disabled]="loadingIndicatorService.isLoading(this)"
              (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
