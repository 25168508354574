import {UntypedFormGroup} from '@angular/forms';
import {Observable, ObservableInput} from 'rxjs';
import {DialogMode, DialogSeverity, IczModalService, SimpleDialogComponent, SimpleDialogData} from '@icz/angular-modal';

export interface IFormGroupCheckable {
  formGroupsToCheck: string[];
}

export interface IFormGroupCheckableWithInitialValue extends IFormGroupCheckable {
  initialFormValue: Record<string, any>;
}

type ChangeTypeCallbacks<R> = {
  changesDetected: (afterClosed$: Observable<boolean>) => R;
  changesNotDetected: () => Exclude<R, ObservableInput<boolean>>;
};

export function isIFormGroupCheckableWithInitialValue(object: any): object is IFormGroupCheckableWithInitialValue {
  return 'initialFormValue' in object;
}

export function hasUnsavedChanges(component: IFormGroupCheckable): boolean {
  return component.formGroupsToCheck.some(formGroupKey => {
    // @ts-ignore -- purely runtime characteristic, can't be typed ahead of time
    return (component[formGroupKey] && component[formGroupKey] instanceof UntypedFormGroup && component[formGroupKey].dirty);
  });
}

export function openUnsavedChangesDialog(modalService: IczModalService): Observable<boolean> {
  return modalService.openComponentInModal<boolean, SimpleDialogData>({
    component: SimpleDialogComponent,
    modalOptions: {
      width: 450,
      height: 350,
      titleTemplate: 'Ztratíte neuložená data',
      isClosable: false,
    },
    data: {
      severity: DialogSeverity.INFO,
      mode: DialogMode.ACCENTED,
      content: [
        {
          text: 'Ztratíte neuložená data'
        },
        {
          text:  'Opouštíte rozpracovaný formulář. Neuložená data budou ztracena.'
        }
      ],
      leftButtonTitle: 'Zůstat',
      rightButtonTitle: 'Opustit',
      showLeftButton: true,
      showRightButton: true,
    }
  });
}

export function openModalOnChanges<R>(
  component: IFormGroupCheckable,
  modalService: IczModalService,
  callbacks: ChangeTypeCallbacks<R>,
): R {
  const hasChanges = hasUnsavedChanges(component);

  if (hasChanges) {
    return callbacks.changesDetected(openUnsavedChangesDialog(modalService));
  }
  else {
    return callbacks.changesNotDetected();
  }
}
