import {Directive, HostListener, inject, OnInit} from '@angular/core';
import {ButtonComponent} from '@icz/angular-essentials';
import {ComponentModalComponent, IczModalService, SimpleDialogComponent} from '@icz/angular-modal';


@Directive({
  selector: '[iczFormCancel]'
})
export class IczFormCancelDirective implements OnInit {

  private modalService = inject(IczModalService);
  private parentModal = inject(ComponentModalComponent, {optional: true});
  private iczButton = inject(ButtonComponent, {optional: true});

  @HostListener('window:keydown.escape', ['$event'])
  private escapePressed($event: Event) {
    if (
      (
        !this.parentModal ||
        (this.parentModal && this.modalService.isModalComponentCurrentlyActive(this.parentModal))
      ) &&
      !this.modalService.hasOpenModalOfType(SimpleDialogComponent)
    ) {
      this.iczButton!.buttonClicked($event);
    }
  }

  ngOnInit() {
    if (!this.iczButton) {
      console.warn(`[iczFormCancel] directive is not placed on <icz-button> element. It will not perform any effects.`);
    }
  }

}
