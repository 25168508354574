import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DataBoxDto} from '|api/commons';
import {AbstractConsignmentSpecificationComponent} from '../abstract-consignment-specification.component';
import {OwnDataboxConsignmentForm} from '../consignment-dialog.form';

import {SubjectAttributeType} from '../../../model/subject-attribute-type';
import {IczOption} from '@icz/angular-form-elements';


@Component({
  selector: 'icz-databox-consignment-specification',
  templateUrl: './databox-consignment-specification.component.html',
  styleUrls: ['./databox-consignment-specification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataboxConsignmentSpecificationComponent extends AbstractConsignmentSpecificationComponent implements OnInit {

  @Input({required: true})
  override specificFieldsForm!: OwnDataboxConsignmentForm;

  databoxOptions: IczOption<DataBoxDto>[] = [];

  distributionNodesLoading = false;

  ngOnInit(): void {
    const attributes = this.consignee?.attributes ?? {};
    this.databoxOptions = attributes[SubjectAttributeType.DATA_BOX] ? attributes[SubjectAttributeType.DATA_BOX]!
      .map(a => ({
        value: a.value,
        label: (a.value as DataBoxDto).id,
      })) as IczOption<DataBoxDto>[] : [];

    if (this.databoxOptions.length === 1) {
      this.specificFieldsForm.get('consigneeDataBox')!.setValue(this.databoxOptions[0].value);
    }
  }

}
