import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {combineLatest, Observable, of, startWith, tap} from 'rxjs';
import {
  GenderType,
  IszrRppDto,
  OvmType,
  SubjectAttributeAddressDto,
  SubjectAttributeDataBoxDto,
  SubjectAttributesDto,
  SubjectAttributeStringDto,
  SubjectRecordClassification,
  SubjectRecordDto
} from '|api/commons';
import {addressDtoToForm, createAddressFormGroup} from '../address/address-utils';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczFormArray, IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {enumToOptions} from '../../../../core/services/data-mapping.utils';
import {
  AttributeComponentType,
  ATTRIBUTES_WITH_MULTI_VALUES,
  InvalidLegalFormHandling,
  mapLegalForms,
  SingleAddressCreateResult,
} from '../../model/subjects.model';
import {
  AddressAttributeType,
  getUiKeyForSubjectCreateAttribute,
  stringAttributesWithMultiplicityOne,
  SubjectLoaderIds
} from '../../../../services/subjects.service';
import {CodebookService} from '../../../../core/services/codebook.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {AddressForm} from '../../model/addresses.model';
import {IczValidators} from '@icz/angular-form-elements';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {LegalFormDto} from '|api/codebook';
import {
  getNewDataboxAttribute,
  getNewGenderTypeAttribute,
  getNewStringAttribute
} from '../../model/subject-attribute.model';
import {isSubjectRegisterManager} from '../../../../core/model/user-roles.model';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {LocalizedDatetimePipe} from '@icz/angular-essentials';
import {TranslateService} from '@ngx-translate/core';
import {SubjectAttributeType} from '../../model/subject-attribute-type';
import {IczOption} from '@icz/angular-form-elements';
import {SharedBusinessValidators} from '../../shared-business-validators';

export enum SubjectCreateComponentMode {
  CREATE_FULL = 'CREATE_FULL',
  CREATE_ESSENTIAL = 'CREATE_ESSENTIAL',
  UPDATE = 'UPDATE',
}

interface IdentifierWithClassRestriction {
  type: SubjectAttributeType,
  for: (SubjectRecordClassification | OvmType)[],
}


@Component({
  selector: 'icz-subject-create-form',
  templateUrl: './subject-create-form.component.html',
  styleUrls: ['./subject-create-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SubjectCreateFormComponent implements OnInit, IczOnChanges {

  private codebookService = inject(CodebookService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);
  private currentSessionService = inject(CurrentSessionService);
  private translateService = inject(TranslateService);
  private changeDetectorRef = inject(ChangeDetectorRef);

  @Input({required: true}) form!: IczFormGroup;
  @Input() singleAddressForm: Nullable<IczFormGroup>;
  @Input() subject: Nullable<SubjectRecordDto>;
  @Input() mode = SubjectCreateComponentMode.CREATE_FULL;
  @Input() isReadonly = false;
  @Input() nonpersistentSubjectMode = false;
  @Input() enforceAddressValidator = true;
  @Output() blockingAction = new EventEmitter<boolean>();
  @Output() backToSearch = new EventEmitter<void>();
  @Output() technicalClassificationChange = new EventEmitter<boolean>();
  @Output() disposeSubject = new EventEmitter<void>();
  @Output() singleAddressResult = new EventEmitter<Nullable<SingleAddressCreateResult>>();


  static patchPrimitiveSubjectValues(form: IczFormGroup, attrs: SubjectAttributesDto): IczFormGroup {
    const toPatch: Record<string, SubjectAttributeStringDto> = {};
    stringAttributesWithMultiplicityOne.forEach(attrType => {
      if ((attrs[attrType] as SubjectAttributeStringDto)?.value) {
        toPatch[attrType] = (attrs[attrType] as unknown as SubjectAttributeStringDto)!;
      }
    });

    form.patchValue(toPatch);

    return form;
  }

  static patchAttributesWithMultipleValues(form: IczFormGroup, subjectToUpdate: SubjectRecordDto): IczFormGroup {
    const additionalAddresses = subjectToUpdate.attributes[SubjectAttributeType.ADDITIONAL_ADDRESS];
    const additionalAddressesAsForm: Nullable<AddressForm>[] = additionalAddresses?.length ? additionalAddresses.map(aa => addressDtoToForm(aa.value!, aa.id, aa.source, aa.state, aa.validFrom))! : [];

    const postalBoxes = subjectToUpdate.attributes[SubjectAttributeType.POSTAL_BOX];
    const postalBoxesAsForm: Nullable<AddressForm>[] = postalBoxes?.length ? postalBoxes.map(pb => addressDtoToForm(pb.value!, pb.id, pb.source, pb.state, pb.validFrom))! : [];

    const dataBoxes = subjectToUpdate.attributes[SubjectAttributeType.DATA_BOX];

    const emails = subjectToUpdate.attributes[SubjectAttributeType.EMAIL];

    const phoneFaxes = subjectToUpdate.attributes[SubjectAttributeType.PHONE_FAX];

    ATTRIBUTES_WITH_MULTI_VALUES.forEach(attr => {
      const attrControl = form.get(attr) as IczFormArray;
      let attrObjects: Nullable<SubjectAttributeStringDto | SubjectAttributeDataBoxDto | AddressForm>[] = [];
      if (attr === SubjectAttributeType.ADDITIONAL_ADDRESS) {
        attrObjects = additionalAddressesAsForm!;
      } else if (attr === SubjectAttributeType.DATA_BOX) {
        attrObjects = dataBoxes!;
      } else if (attr === SubjectAttributeType.EMAIL) {
        attrObjects = emails!;
      } else if (attr === SubjectAttributeType.PHONE_FAX) {
        attrObjects = phoneFaxes!;
      } else if (attr === SubjectAttributeType.POSTAL_BOX) {
        attrObjects = postalBoxesAsForm;
      }
      attrControl.setSize(attrObjects?.length ?? 0);
      attrObjects?.forEach((obj: any, i: number) => {
        if (obj) {
          attrControl.controls[i]!.patchValue(obj);
        }
      });
    });

    return form;
  }

  static getCreateSubjectFormGroup(subjectToUpdate?: Nullable<SubjectRecordDto>): IczFormGroup {
    const attrs = subjectToUpdate?.attributes!;
    const residentialAddress: Nullable<SubjectAttributeAddressDto> = attrs ? subjectToUpdate?.attributes.residentialAddress : null;
    const mailingAddress: Nullable<SubjectAttributeAddressDto> = attrs ? subjectToUpdate?.attributes.mailingAddress : null;
    const residentialAddressAsForm: Nullable<AddressForm> = residentialAddress ? addressDtoToForm(
      residentialAddress.value,
      residentialAddress.id,
      residentialAddress.source,
      residentialAddress.state,
      residentialAddress.validFrom,
    )! : null;
    const mailingAddressAsForm: Nullable<AddressForm> = mailingAddress ? addressDtoToForm(
      mailingAddress.value,
      mailingAddress.id,
      mailingAddress.source,
      mailingAddress.state,
      mailingAddress.validFrom,
    ) : null;
    const dataBoxFormArray = new IczFormArray(() => getNewDataboxAttribute(), []);

    let form = new IczFormGroup({
      classification: new IczFormControl<Nullable<SubjectRecordClassification>>(subjectToUpdate?.classification ?? SubjectRecordClassification.FO,
        [IczValidators.required()]),
      ovmType: new IczFormControl<Nullable<OvmType>>(subjectToUpdate?.ovmType ?? null, []),
      // Common fields
      [SubjectAttributeType.DATA_BOX]: dataBoxFormArray,
      [SubjectAttributeType.CLIENT_ID]: getNewStringAttribute(null, []),
      [SubjectAttributeType.NOTE]: getNewStringAttribute(null, []),
      [SubjectAttributeType.EMAIL]: new IczFormArray(() => getNewStringAttribute(null, [IczValidators.email()]), []),
      [SubjectAttributeType.PHONE_FAX]: new IczFormArray(() => getNewStringAttribute(null, [IczValidators.phoneNumber()]), []),
      // Natural Person fields
      [SubjectAttributeType.FIRST_NAME]: getNewStringAttribute({value: null, important: true, disabled: false}, []),
      // For FO subjects surname is REQUIRED, which takes precedence over IMPORTANT. For PFO it is just IMPORTANT.
      [SubjectAttributeType.SURNAME]: getNewStringAttribute({value: null, important: true, disabled: false}, []),
      [SubjectAttributeType.BIRTH_DATE]: getNewStringAttribute({value: null, important: true, disabled: false}, []),
      [SubjectAttributeType.GENDER]: getNewGenderTypeAttribute(null, []),
      [SubjectAttributeType.IDENTITY_CARD_ID]: getNewStringAttribute(null, []),
      [SubjectAttributeType.DRIVING_LICENCE_ID]: getNewStringAttribute(null, []),
      [SubjectAttributeType.PASSPORT_ID]: getNewStringAttribute(null, []),
      [SubjectAttributeType.BIRTH_NAME]: getNewStringAttribute(null, []),
      [SubjectAttributeType.BIRTH_SURNAME]: getNewStringAttribute(null, []),
      [SubjectAttributeType.BIRTH_PLACE]: getNewStringAttribute(null, []),
      [SubjectAttributeType.DEGREE_BEFORE]: getNewStringAttribute(null, []),
      [SubjectAttributeType.DEGREE_AFTER]: getNewStringAttribute(null, []),
      // Legal Person, Business Individual or Public Authority (OVM in czech) fields
      [SubjectAttributeType.CID]: getNewStringAttribute({value: null, important: true, disabled: false}, [SharedBusinessValidators.isCID()]),
      [SubjectAttributeType.LEGAL_FORM]: getNewStringAttribute({value: null, important: true, disabled: false}, []),
      [SubjectAttributeType.COMPANY_ID]: getNewStringAttribute({value: null, disabled: false}, []),
      [SubjectAttributeType.VAT_ID]: getNewStringAttribute(null, []),
      [SubjectAttributeType.ART_1_P_3_ID]: getNewStringAttribute(null, []),
      [SubjectAttributeType.EORI_CODE]: getNewStringAttribute(null, []),
      [SubjectAttributeType.LE_ID]: getNewStringAttribute(null, []),
      [SubjectAttributeType.TAX_ID]: getNewStringAttribute(null, []),
      [SubjectAttributeType.BUSINESS_NAME]: getNewStringAttribute(null, []),
      [SubjectAttributeType.EXCISE_TAX_ID]: getNewStringAttribute(null, []),
      // Addresses
      [SubjectAttributeType.RESIDENTIAL_ADDRESS]: createAddressFormGroup(residentialAddressAsForm),
      [SubjectAttributeType.MAILING_ADDRESS]: createAddressFormGroup(mailingAddressAsForm),
      [SubjectAttributeType.ADDITIONAL_ADDRESS]: new IczFormArray(() => createAddressFormGroup(), []),
      [SubjectAttributeType.POSTAL_BOX]: new IczFormArray(() => createAddressFormGroup(), []),
    });

    // patching all other values, that can be patched easily
    if (subjectToUpdate) {
      form = SubjectCreateFormComponent.patchPrimitiveSubjectValues(form, attrs);
      form = SubjectCreateFormComponent.patchAttributesWithMultipleValues(form, subjectToUpdate);
    }

    return form;
  }

  localizedDatetimePipe = new LocalizedDatetimePipe();

  classificationOptions = enumToOptions('subjectRecordClassification', SubjectRecordClassification);
  allOvmTypeOptions = enumToOptions('ovmType', OvmType).concat({value: null, label: 'Není OVM'});
  ovmTypeOptions = [...this.allOvmTypeOptions];

  singleAddressTypeForm = new IczFormGroup({
    type: new IczFormControl(SubjectAttributeType.MAILING_ADDRESS, [IczValidators.required()])
  });
  singleAddressTypeOptions: IczOption[] = [
    {value: SubjectAttributeType.RESIDENTIAL_ADDRESS, label: 'Trvalý pobyt'}, // can be changed, if FO 'Trvalý pobyt'else 'Adresa sídla'
    {value: SubjectAttributeType.MAILING_ADDRESS, label: 'Doručovací adresa'},
    {value: SubjectAttributeType.ADDITIONAL_ADDRESS, label: 'Kontaktní adresa'},
  ];

  legalFormOptions$: Nullable<Observable<IczOption<string>[]>> = null; // options depend on classification, and thus are set in OnChanges
  originalSubjectData: Nullable<SubjectRecordDto>;
  isSubjectRegisterManager = false;
  iszrAgendas: IszrRppDto[] = [];

  readonly SubjectLoaderIds = SubjectLoaderIds;
  readonly SubjectAttributeType = SubjectAttributeType;

  readonly genderOptions: IczOption[] = enumToOptions('subjectGender', GenderType);
  readonly attributeValueForClear = {
    value: null
  };

  get disposalAlertHeading() {
    return `${this.translateService.instant('Plánované vyřazení subjektu')}: ${this.localizedDatetimePipe.transform(this.subject?.disposalDate)}`;
  }

  get iszrFormattedMetadata() {
    if (!this.subject || !this.subject.iszrMetadata || !this.iszrAgendas.length) return '';
    const agenda = this.iszrAgendas.find(a => a.id === this.subject!.iszrMetadata!.iszrRppId);
    if (!agenda) return '';
    return `${this.translateService.instant('Agenda')}: ${agenda.agendaCode} ${agenda.agendaName},
     ${this.translateService.instant('Činnost')}: ${agenda.activityRoleCode} ${agenda.activityRoleName}`;
  }

  get verifiedTooltip(): string {
    if (!this.subject) return '';
    return this.isIszrSubject ? 'Ověřeno v ISZR' : 'Ověřeno v ISDS';
  }

  get isVerified() {
    return this.isIsdsSubject || this.isIszrSubject;
  }

  get isIszrSubject() {
    return !this.subject ? false : SubjectTemplateUtils.isIszrVerified(this.subject);
  }

  get isIsdsSubject() {
    return !this.subject ? false : SubjectTemplateUtils.isIsdsVerified(this.subject);
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.form) {
      this.setDynamicValidators();
      this.legalFormOptions$ = this.getLegalFormsSource$().pipe(mapLegalForms(this.mode === SubjectCreateComponentMode.CREATE_FULL ? InvalidLegalFormHandling.FILTER : InvalidLegalFormHandling.DISABLE));

      this.form.get('classification')!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        this.setDynamicValidators();
        this.legalFormOptions$ = this.getLegalFormsSource$().pipe(
          mapLegalForms(this.mode === SubjectCreateComponentMode.CREATE_FULL ? InvalidLegalFormHandling.FILTER : InvalidLegalFormHandling.DISABLE),
          tap(legalForms => {
            const currentLegalFormValue = this.form.get(SubjectAttributeType.LEGAL_FORM)!.get('value')!.value;
            if (currentLegalFormValue) {
              const currentLegalFormInOptions = legalForms.find(lf => lf.value === currentLegalFormValue);
              if (!currentLegalFormInOptions) {
                this.form.get(SubjectAttributeType.LEGAL_FORM)!.patchValue(this.attributeValueForClear);
              }
            }
          }));
        this.trimDataBoxesOverRange();
        this.setOvmTypeOptions();
        const currentOvmType = SubjectTemplateUtils.ovmType(this.form)!;
        const allowedOvmTypeOptions = this.ovmTypeOptions.map(o => o.value);
        if (!allowedOvmTypeOptions.includes(currentOvmType)) {
          this.form.get('ovmType')!.setValue(null);
        }

        if (this.isCreateOnlyEssentialMode) {
          this.displayedIdentifiers = this.displayedIdentifiers.filter(identifier => this.isIdentifierAvailableForClass(identifier));
          this.unavailableIdentifiersForClass.forEach(available => {
            this.clearIdentifierFormValue(available.type);
          });

          const residentialOption = this.singleAddressTypeOptions.find(o => o.value === SubjectAttributeType.RESIDENTIAL_ADDRESS)!;
          if (this.isNaturalPerson) {
            residentialOption.label = 'Trvalý pobyt';
          }
          else {
            residentialOption.label = 'Adresa sídla';
          }
        }
      });
    }
    if (changes.isReadonly) {
      if (changes.isReadonly.currentValue) {
        this.form.disable();
      } else {
        this.form.enable();
      }
    }
    if (this.isUpdateMode && changes.subject && changes.subject.currentValue) {
      if (SubjectTemplateUtils.isVerified(this.subject!)) {
        this.form.get('classification')!.disable();
        this.form.get('ovmType')!.disable();
      } else {
        this.originalSubjectData = this.subject!;
        if (this.originalSubjectData.classification === SubjectRecordClassification.PO) {
          this.classificationOptions = [{
            label: `en.subjectRecordClassification.${SubjectRecordClassification.PFO}`,
            value: SubjectRecordClassification.PFO
          }, {
            label: `en.subjectRecordClassification.${SubjectRecordClassification.PO}`,
            value: SubjectRecordClassification.PO,
            disabled: true
          }];
        }
        if (this.originalSubjectData.classification === SubjectRecordClassification.FO) {
          this.classificationOptions = [{
            label: `en.subjectRecordClassification.${SubjectRecordClassification.PFO}`,
            value: SubjectRecordClassification.PFO
          }, {
            label: `en.subjectRecordClassification.${SubjectRecordClassification.FO}`,
            value: SubjectRecordClassification.FO,
            disabled: true
          }];
        }
        if (this.originalSubjectData.classification === SubjectRecordClassification.PFO) {
          this.classificationOptions = [
            {label: `en.subjectRecordClassification.${SubjectRecordClassification.PO}`, value: SubjectRecordClassification.PO},
            {label: `en.subjectRecordClassification.${SubjectRecordClassification.FO}`, value: SubjectRecordClassification.FO},
            {label: `en.subjectRecordClassification.${SubjectRecordClassification.PFO}`, value: SubjectRecordClassification.PFO, disabled: true}];
        }
      }
    }
  }

  ngOnInit() {
    this.setOvmTypeOptions();

    if (this.isCreateOnlyEssentialMode) {
      (this.form.get(SubjectAttributeType.ADDITIONAL_ADDRESS) as IczFormArray).setSize(1);
      (this.form.get(SubjectAttributeType.DATA_BOX) as IczFormArray).setSize(1);
      // Usually every field of databox is required, in essential mode this attribute is optional
      this.dataBoxForm!.get('value')!.get('id')!.setValidators([SharedBusinessValidators.isValidDataboxIdentifier()]);
      this.dataBoxForm!.get('value')!.get('type')!.clearValidators();

      if (!this.singleAddressForm) return;

      combineLatest([
        this.singleAddressTypeForm.get('type')!.valueChanges.pipe(startWith(this.singleAddressTypeForm.get('type')!.value)),
        this.singleAddressForm!.valueChanges.pipe(startWith(null)),
      ]).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([addressType, addressValue]) => {
        this.singleAddressResult.emit({
          addressForm: this.singleAddressForm!,
          type: addressType,
          valid: this.singleAddressForm!.valid
        });
      });
    }

    this.currentSessionService.currentUserFunctionalPosition$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUserInfo => {
        this.isSubjectRegisterManager = isSubjectRegisterManager(currentUserInfo!);
        this.changeDetectorRef.detectChanges();
      });

    this.codebookService.iszrAgendasWithActivityRoles().subscribe(iszrAgendas => {
      this.iszrAgendas = iszrAgendas;
      this.changeDetectorRef.detectChanges();
    });
  }

  get singleAddressLabel(): string {
    return this.singleAddressTypeOptions.find(o => o.value === this.singleAddressTypeForm.get('type')!.value)!.label;
  }

  transformSubjectByClassification(newClassification: SubjectRecordClassification) {
    if (this.isUpdateMode) {
      if (this.originalSubjectData) {
        if (this.originalSubjectData.classification === SubjectRecordClassification.PO && newClassification === SubjectRecordClassification.PFO) {
          this.transformPOtoPFO();
        }
        if (this.originalSubjectData.classification === SubjectRecordClassification.FO && newClassification === SubjectRecordClassification.PFO) {
          this.transformFOtoPFO();
        }
        if (this.originalSubjectData.classification === SubjectRecordClassification.PFO && newClassification === SubjectRecordClassification.PO) {
          this.transformPFOtoPO();
        }
        if (this.originalSubjectData.classification === SubjectRecordClassification.PFO && newClassification === SubjectRecordClassification.FO) {
          this.transformPFOtoFO();
        }
      }
    }
  }

  transformPOtoPFO() {
    if (this.originalSubjectData!.ovmType) {
      this.form.get('ovmType')!.setValue(OvmType.PFO_OVM);
    }
    this.form.get(SubjectAttributeType.SURNAME)!.patchValue(this.originalSubjectData!.attributes.businessName);
  }

  transformPFOtoPO() {
    if (this.originalSubjectData!.ovmType) {
      this.form.get('ovmType')!.setValue(OvmType.PO_OVM);
    }
    this.form.get(SubjectAttributeType.DEGREE_BEFORE)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.DEGREE_AFTER)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.FIRST_NAME)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.SURNAME)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.BIRTH_DATE)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.GENDER)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.BIRTH_NAME)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.BIRTH_SURNAME)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.BIRTH_PLACE)!.patchValue(this.attributeValueForClear);

    this.form.get(SubjectAttributeType.BUSINESS_NAME)!.patchValue(this.originalSubjectData!.attributes.businessName);
    this.form.get(SubjectAttributeType.COMPANY_ID)!.patchValue(this.originalSubjectData!.attributes.companyId);
    this.form.get(SubjectAttributeType.LEGAL_FORM)!.patchValue(this.originalSubjectData!.attributes.legalForm);
    this.form.get(SubjectAttributeType.VAT_ID)!.patchValue(this.originalSubjectData!.attributes.vatId);
    this.form.get(SubjectAttributeType.TAX_ID)!.patchValue(this.originalSubjectData!.attributes.taxId);
    this.form.get(SubjectAttributeType.CID)!.patchValue(this.originalSubjectData!.attributes.cid);
    this.form.get(SubjectAttributeType.EXCISE_TAX_ID)!.patchValue(this.originalSubjectData!.attributes.exciseTaxId);
    this.form.get(SubjectAttributeType.LE_ID)!.patchValue(this.originalSubjectData!.attributes.leId);
    this.form.get(SubjectAttributeType.EORI_CODE)!.patchValue(this.originalSubjectData!.attributes.eoriCode);
  }

  transformFOtoPFO() {
    this.technicalClassificationChange.emit(true);
    const transformedBusinessName: Nullable<string>[] = [];
    transformedBusinessName.push(this.originalSubjectData!.attributes.degreeBefore?.value);
    transformedBusinessName.push(this.originalSubjectData!.attributes.firstName?.value);
    transformedBusinessName.push(this.originalSubjectData!.attributes.surname?.value);
    transformedBusinessName.push(this.originalSubjectData!.attributes.degreeAfter?.value);
    this.form.get(SubjectAttributeType.BUSINESS_NAME)!.patchValue({value: transformedBusinessName.filter(val => !isNil(val)).join(' ')});
  }

  transformPFOtoFO() {
    this.technicalClassificationChange.emit(true);
    if (this.originalSubjectData!.ovmType) {
      this.form.get('ovmType')!.setValue(OvmType.FO_OVM);
    }
    this.form.get(SubjectAttributeType.BUSINESS_NAME)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.COMPANY_ID)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.LEGAL_FORM)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.VAT_ID)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.TAX_ID)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.CID)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.EXCISE_TAX_ID)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.LE_ID)!.patchValue(this.attributeValueForClear);
    this.form.get(SubjectAttributeType.EORI_CODE)!.patchValue(this.attributeValueForClear);

    this.form.get(SubjectAttributeType.DEGREE_BEFORE)!.patchValue(this.originalSubjectData!.attributes.degreeBefore);
    this.form.get(SubjectAttributeType.DEGREE_AFTER)!.patchValue(this.originalSubjectData!.attributes.degreeAfter);
    this.form.get(SubjectAttributeType.FIRST_NAME)!.patchValue(this.originalSubjectData!.attributes.firstName);
    this.form.get(SubjectAttributeType.SURNAME)!.patchValue(this.originalSubjectData!.attributes.surname);
    this.form.get(SubjectAttributeType.BIRTH_DATE)!.patchValue(this.originalSubjectData!.attributes.birthDate);
    this.form.get(SubjectAttributeType.GENDER)!.patchValue(this.originalSubjectData!.attributes.gender);
    this.form.get(SubjectAttributeType.BIRTH_NAME)!.patchValue(this.originalSubjectData!.attributes.birthName);
    this.form.get(SubjectAttributeType.BIRTH_SURNAME)!.patchValue(this.originalSubjectData!.attributes.birthSurname);
    this.form.get(SubjectAttributeType.BIRTH_PLACE)!.patchValue(this.originalSubjectData!.attributes.birthPlace);
  }

  get dataBoxForm(): Nullable<IczFormGroup> {
    return (this.form.get(SubjectAttributeType.DATA_BOX) as IczFormArray)?.controls[0] as IczFormGroup;
  }

  isIdentifierAvailableForClass(identifier: Nullable<SubjectAttributeType>) {
    if (!identifier) return true;
    return this.availableIdentifierControls!.find(available => available.type === identifier)!.for.includes(this.classificationOrOvm);
  }

  identifierOptionsForIndex(i: number): IczOption[] {
    return this.identifierAttributes
      .map(identifier => {return {value: identifier, label: getUiKeyForSubjectCreateAttribute(identifier)};})
      .filter(a => this.isIdentifierAvailableForClass(a.value))
      .filter(a => a.value === this.displayedIdentifiers[i] || !this.displayedIdentifiers.includes(a.value));
  }

  clearIdentifierFormValue(identifier: SubjectAttributeType) {
    if (identifier === SubjectAttributeType.DATA_BOX) {
      this.dataBoxForm!.get('value')!.get('id')!.setValue(null);
      this.dataBoxForm!.get('value')!.get('type')!.setValue(null);
    } else {
      this.form.get(identifier)!.setValue(null);
    }
  }

  setIdentifier(i: number, newIdentifier: SubjectAttributeType) {
    const oldIdentifier = this.displayedIdentifiers[i] as Nullable<SubjectAttributeType>;
    if (oldIdentifier && newIdentifier && oldIdentifier !== newIdentifier) {
      this.clearIdentifierFormValue(oldIdentifier);
    }
    this.displayedIdentifiers[i] = newIdentifier;
  }

  addIdentifier() {
    const firstAvailable = this.availableIdentifiersForClass.filter(identifier => !this.displayedIdentifiers.includes(identifier.type))?.[0];
    if (firstAvailable) {
      this.displayedIdentifiers.push(firstAvailable.type);
    }
  }

  removeIdentifier(i: number) {
    const oldIdentifier = this.displayedIdentifiers[i] as Nullable<SubjectAttributeType>;
    if (oldIdentifier) {
      this.clearIdentifierFormValue(oldIdentifier);
    }

    this.displayedIdentifiers.splice(i, 1);
  }

  setDynamicValidators() {
    if (this.isNaturalPerson) {
      this.form.get(SubjectAttributeType.SURNAME)?.get('value')!.setValidators([IczValidators.required(), IczValidators.minLength(2)]);
      this.form.get(SubjectAttributeType.BUSINESS_NAME)?.get('value')!.clearValidators();
    } else {
      this.form.get(SubjectAttributeType.SURNAME)?.get('value')!.clearValidators();
      this.form.get(SubjectAttributeType.BUSINESS_NAME)?.get('value')!.setValidators([IczValidators.required(), IczValidators.minLength(3)]);
    }
    this.form.get(SubjectAttributeType.SURNAME)?.get('value')!.updateValueAndValidity();
    this.form.get(SubjectAttributeType.BUSINESS_NAME)?.get('value')!.updateValueAndValidity();
  }

  private setOvmTypeOptions() {
    if (SubjectTemplateUtils.isNaturalPerson(this.classification)) {
      this.ovmTypeOptions = [...this.allOvmTypeOptions].filter(o => {return o.value === OvmType.FO_OVM || o.value === null;});
    } else if (SubjectTemplateUtils.isLegalPerson(this.classification)) {
      this.ovmTypeOptions = [...this.allOvmTypeOptions].filter(o => {return o.value === OvmType.PO_OVM || o.value === OvmType.OVM || o.value === null;});
    } else if (SubjectTemplateUtils.isBusinessIndividual(this.classification)) {
      this.ovmTypeOptions = [...this.allOvmTypeOptions].filter(o => {return o.value === OvmType.PFO_OVM || o.value === null;});
    } else {
      this.ovmTypeOptions = [...this.allOvmTypeOptions].filter(o => {return o.value === null;});
    }
  }

  get isCreateFullMode(): boolean {
    return this.mode === SubjectCreateComponentMode.CREATE_FULL;
  }

  get isCreateOnlyEssentialMode(): boolean {
    return this.mode === SubjectCreateComponentMode.CREATE_ESSENTIAL;
  }

  get isUpdateMode(): boolean {
    return this.mode === SubjectCreateComponentMode.UPDATE;
  }

  get classification(): SubjectRecordClassification {
    return SubjectTemplateUtils.classification(this.form);
  }

  get ovmType(): Nullable<OvmType> {
    return SubjectTemplateUtils.ovmType(this.form);
  }

  get classificationOrOvm(): SubjectRecordClassification | OvmType {
    return this.ovmType || this.classification;
  }

  get isNaturalPerson(): boolean {
    return SubjectTemplateUtils.isNaturalPerson(this.classification);
  }

  get isBusinessIndividual(): boolean {
    return SubjectTemplateUtils.isBusinessIndividual(this.classification);
  }

  get isLegalPerson(): boolean {
    return SubjectTemplateUtils.isLegalPerson(this.classification);
  }

  get dataBoxMultiplicity(): [number, number] {
    return this.isNaturalPerson ? [0, 1] : [0, Infinity];
  }

  get singleAddressFormAttribute(): AddressAttributeType {
    return this.singleAddressTypeForm.get('type')!.value as AddressAttributeType;
  }

  trimDataBoxesOverRange() {
    const dataBoxControl = this.form.get(SubjectAttributeType.DATA_BOX) as IczFormArray;

    if ((dataBoxControl)?.controls.length > this.dataBoxMultiplicity[1]) {
      dataBoxControl.setSize(this.dataBoxMultiplicity[1], true);
    }
  }

  get availableIdentifierControls(): IdentifierWithClassRestriction[] {
    return [
      {
        type: SubjectAttributeType.DATA_BOX,
        for: [
          OvmType.PO_OVM, OvmType.FO_OVM, OvmType.PFO_OVM, OvmType.OVM,
          SubjectRecordClassification.FO, SubjectRecordClassification.PO, SubjectRecordClassification.PFO]
      },
      {
        type: SubjectAttributeType.CLIENT_ID,
        for: [
          OvmType.PO_OVM, OvmType.FO_OVM, OvmType.PFO_OVM, OvmType.OVM,
          SubjectRecordClassification.FO, SubjectRecordClassification.PO, SubjectRecordClassification.PFO]
      },
      {
        type: SubjectAttributeType.DRIVING_LICENCE_ID,
        for: [
          SubjectRecordClassification.FO, SubjectRecordClassification.PFO]
      },
      {
        type: SubjectAttributeType.PASSPORT_ID,
        for: [
          SubjectRecordClassification.FO, SubjectRecordClassification.PFO]
      },
      {
        type: SubjectAttributeType.IDENTITY_CARD_ID,
        for: [
          SubjectRecordClassification.FO, SubjectRecordClassification.PFO]
      }
    ];
  };

  get availableIdentifiersForClass(): IdentifierWithClassRestriction[] {
    return this.availableIdentifierControls.filter(a => this.isIdentifierAvailableForClass(a.type));
  }

  get unavailableIdentifiersForClass(): IdentifierWithClassRestriction[] {
    return this.availableIdentifierControls.filter(a => !this.isIdentifierAvailableForClass(a.type));
  }

  displayedIdentifiers: SubjectAttributeType[] = [
    SubjectAttributeType.DATA_BOX,
  ];

  readonly SubjectAttributeComponentType = AttributeComponentType;

  readonly identifierAttributes: SubjectAttributeType[] = [
    SubjectAttributeType.DATA_BOX,
    SubjectAttributeType.IDENTITY_CARD_ID,
    SubjectAttributeType.DRIVING_LICENCE_ID,
    SubjectAttributeType.PASSPORT_ID,
    SubjectAttributeType.CLIENT_ID,
  ];

  private getLegalFormsSource$(): Observable<LegalFormDto[]> {
    if (this.classification === SubjectRecordClassification.PFO) {
      return this.codebookService.legalFormsPFO();
    }
    else if (this.classification === SubjectRecordClassification.PO) {
      return this.codebookService.legalFormsPO();
    }
    else {
      return of([]);
    }
  }

}
