/* tslint:disable */
/* eslint-disable */

/**
 * Type of permission a member of registry office can have.
 */
export enum RegistryOfficeMemberPermissionType {
  ACCESS = 'ACCESS',
  TRANSFER = 'TRANSFER'
}
