import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {sub} from 'date-fns';
import {map, take} from 'rxjs/operators';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {OrganizationalStructureOption} from '../../../core/services/organizational-structure.service';
import {CirculationView} from '../../shared-business-components/document-toolbar/services/toolbar-common.utils';
import {MyActivitiesDatasource} from '../../shared-business-components/circulation-table/my-activities.datasource';
import {CirculationSearchService} from '../../../services/circulation-search.service';
import {CurrentSessionService} from '../../../services/current-session.service';
import {LocalStorageKey, UserSettingsService} from '../../../services/user-settings.service';
import {COMPONENT_ENTITY_TYPES} from '../../shared-business-components/shared-document.utils';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {
  ExtendedCirculationActivityDto
} from '../../shared-business-components/model/elastic-extended-entities.interface';
import {EsslComponentModalService} from '../../../services/essl-component-modal.service';
import {TaskFilterPeriod} from '../dashboard-settings/dashboard-settings-dialog.component';
import {
  DocumentFiltersDataService
} from '../../shared-business-components/document-table/components/document-filters/document-filters-data.service';
import {FilterOperator, getDefaultSearchParams} from '@icz/angular-table';
import {
  formatPrimaryTargetParticipant
} from '../../shared-business-components/circulation-table/circulation-table.component';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EsslComponentSearchService} from '../../../services/essl-component-search.service';
import {TranslateParser, TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'icz-dashboard-flow-activities-preview',
  templateUrl: './dashboard-flow-activities-preview.component.html',
  styleUrls: ['./dashboard-flow-activities-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardFlowActivitiesPreviewComponent implements OnInit {

  private circulationSearchService = inject(CirculationSearchService);
  private currentSessionService = inject(CurrentSessionService);
  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private esslComponentModalService = inject(EsslComponentModalService);
  private router = inject(Router);
  private cd = inject(ChangeDetectorRef);
  private userSettingsService = inject(UserSettingsService);
  private esslComponentSearchService = inject(EsslComponentSearchService);
  private destroyRef = inject(DestroyRef);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);

  activities: ExtendedCirculationActivityDto[] = [];

  functionalPositionTreeOptions: OrganizationalStructureOption[] = [];

  viewType = CirculationView.ACTIVITIES_IN_PROGRESS;
  taskFilterPeriod!: TaskFilterPeriod;

  myActivitiesDatasource: MyActivitiesDatasource = new MyActivitiesDatasource(
    this.circulationSearchService,
    this.currentSessionService,
    this.esslComponentSearchService,
    {isUnitView: false},
  );

  get taskFilterText() {
    const text = 'úkoly za posledních {{hours}} hodin';
    const context: Record<string,  string> = {};
    if (this.taskFilterPeriod === TaskFilterPeriod.TWO_DAYS) {
      context['hours'] = '48';
    } else {
      context['hours'] = '24';
    }
    return this.translateParser.interpolate(this.translateService.instant(text), context);
  }

  ngOnInit() {
    this.myActivitiesDatasource.loadPageResult$.pipe(
      take(1),
      map(page => page.content)
    ).subscribe(activities => {
      this.activities = activities;
      this.cd.detectChanges();
    });

    this.documentFiltersDataService.functionalPositionTreeOptions.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(functionalPositionTreeOptions => {
      this.functionalPositionTreeOptions = functionalPositionTreeOptions;
      this.cd.detectChanges();
    });

    const tasksPeriodSearchSetting = this.userSettingsService.getParsedValue(LocalStorageKey.DASHBOARD_TASK_TIME_PERIOD);
    this.taskFilterPeriod = TaskFilterPeriod.ONE_DAY;
    let timeSearchParam = sub(new Date(), {days: 1});
    if (tasksPeriodSearchSetting) {
      this.taskFilterPeriod = tasksPeriodSearchSetting.timePeriod;
    }
    if (this.taskFilterPeriod === TaskFilterPeriod.TWO_DAYS) {
      timeSearchParam = sub(new Date(), {days: 2});

    }
    const searchParams = {...getDefaultSearchParams(), size: 1000};
    searchParams.filter = [
      {
        fieldName: 'initFunctionalPositionId',
        operator: FilterOperator.equals,
        value: String(this.currentSessionService.currentUserFunctionalPosition?.id)
      },
      {
        fieldName: 'initDate',
        operator: FilterOperator.greaterOrEqual,
        value: timeSearchParam.toISOString(),
      }
    ];
    this.myActivitiesDatasource.loadPage(searchParams);
  }

  getPrimaryTargetParticipantText(targetParticipants: number[]) {
    return formatPrimaryTargetParticipant(targetParticipants, this.functionalPositionTreeOptions, 'fp');
  }

  isComponentCirculation(activity: ExtendedCirculationActivityDto) {
    return COMPONENT_ENTITY_TYPES.includes(activity.circulationEntityType);
  }

  openCirculationComponent(activity: ExtendedCirculationActivityDto) {
    const castedRow = activity as CirculationTaskDto | CirculationActivityDto;

    this.esslComponentModalService.openEsslComponentDetailWithFullFetch(
      castedRow.componentId!,
      castedRow.digitalComponentVersionId,
      castedRow.documentId!,
      true,
      true,
    );
  }

  navigateToMyActivities() {
    this.router.navigateByUrl(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      DocumentsRoute.TASKS,
      {view: 'byMe'}
    ));
  }
}
