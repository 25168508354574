import {Component, OnInit} from '@angular/core';
import {IczTableDataSource} from '../../../../table.datasource';
import {TableColumnsData} from '../../../../table-columns-data';
import {IczFormDirective, IczFormGroup, IczOption, isNilOrEmptyString} from '@icz/angular-form-elements';
import {ButtonComponent, FormButtonsComponent} from '@icz/angular-essentials';
import {getDefaultTableConfig} from '../../../../table.component';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {FilterOperator} from '../../../../table.utils';
import {
  PopupSelectorTableDialogComponent
} from '../../../../popup-selector-table-dialog/popup-selector-table-dialog.component';
import {ModalTableFilterHeaderComponent} from '../../modal-table-filter-header/modal-table-filter-header.component';
import {
  PopupSelectorTableComponent,
  PopupSelectorTableData
} from '../../../../popup-selector-table-dialog/popup-selector-table/popup-selector-table.component';


export interface PageSelectorFilterDialogResult {
  form: Nullable<IczFormGroup>;
  selectedRows: any[];
}

export interface PageSelectorFilterDialogData {
  filterTableId: string;
  filterOperators: Array<IczOption<FilterOperator>>;
  form: IczFormGroup;
  tableTitle: string;
  tableTitleTag: string;
  schema: TableColumnsData<string>;
  defaultFilterColumns: string[];
  dataSource: IczTableDataSource<any>;
  fulltextSearchFieldPlaceholder: Nullable<string>;
}

@Component({
  selector: 'icz-page-selector-filter-dialog',
  templateUrl: './page-selector-filter-dialog.component.html',
  styleUrls: ['./page-selector-filter-dialog.component.scss'],
  imports: [
    ButtonComponent,
    PopupSelectorTableDialogComponent,
    FormButtonsComponent,
    IczFormDirective,
    ModalTableFilterHeaderComponent,
    PopupSelectorTableComponent
  ],
  standalone: true
})
export class PageSelectorFilterDialogComponent implements OnInit {
  private modalRef = injectModalRef<PageSelectorFilterDialogResult>();
  protected data = injectModalData<PageSelectorFilterDialogData>();

  pageSelectorData!: PopupSelectorTableData;

  form!: IczFormGroup;
  selectedRows: any[] = [];

  ngOnInit(): void {
    this.form = this.data.form;

    this.pageSelectorData = {
      dataSource: this.data.dataSource,
      schema: this.data.schema,
      tableId: this.data.filterTableId,
      tableTitle: this.data.tableTitle,
      tableTitleTag: this.data.tableTitleTag,
      tableConfig: {
        defaultFilterColumns: [],
        toolbarConfig: {
          ...getDefaultTableConfig().toolbarConfig,
          showFulltextSearch: !isNilOrEmptyString(this.data.fulltextSearchFieldPlaceholder),
          fulltextSearchFieldPlaceholder: this.data.fulltextSearchFieldPlaceholder ?? ''
        }
      }
    };
  }

  selectedRowsChanged(rows: any[]) {
    this.selectedRows = rows;
  }

  applyFilter(): void {
    this.close();
  }

  close() {
    this.modalRef.close({
      form: this.form,
      selectedRows: this.selectedRows,
    });
  }
}
