import {FilterParam, IczTableDataSource} from '@icz/angular-table';
import {EsslComponentDtoWithActivity, EsslComponentSearchService} from '../../../services/essl-component-search.service';

export class EsslComponentsWithTaskTableDatasource extends IczTableDataSource<EsslComponentDtoWithActivity> {
  constructor(
    searchService: EsslComponentSearchService,
    documentId: number,
    additionalFilters?: FilterParam[]
  ) {
    super(searchParams => searchService.findEsslComponentsWithActivities(searchParams, documentId, additionalFilters));
  }
}
