import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {createAddressFormGroup} from '../../subjects/address/address-utils';
import {IczFormArray} from '../../../../../../../icz-angular-form-elements/src';
import {AddressCompleteDto, AddressForm, AddressFormat, Countries} from '../../model/addresses.model';
import {IczTableFilterWithInternalForm} from '@icz/angular-table';
import {FilterSubValue, NonemptyFilterItem} from '@icz/angular-table';

export const ADDRESS_SHAPE_INDICATOR = 'consignments.subjectAddress';


@Component({
  selector: 'icz-address-custom-filter',
  templateUrl: './address-custom-filter.component.html',
  styleUrls: ['./address-custom-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressCustomFilterComponent extends IczTableFilterWithInternalForm implements OnInit {

  override set item(newItem: NonemptyFilterItem) {
    this._item = newItem;
    const subValueIds: string[] = [];
    if (newItem.subValues && newItem.subValues.length > 0) {
      this.selectedAddress = {};
      newItem.subValues.forEach(sv => {
        const dtoString = sv.subValueId?.replace(`${ADDRESS_SHAPE_INDICATOR}.`, '');
        subValueIds.push(dtoString!);
        this.selectedAddress![dtoString! as keyof AddressCompleteDto] = sv.value;
      });
    }
    if (!newItem.subValues?.length) return;

    if (subValueIds.includes('addressLines')) {
      this.addressFormValue.get('_Class')?.setValue(AddressFormat.GenericLineAddressDto);
      (this.addressFormValue.get(AddressFormat.GenericLineAddressDto)!
        .get('addressLines') as IczFormArray)!.controls[0].get('line')?.setValue((this.selectedAddress! as any).addressLines);
    }
    else {
      if (this.selectedAddress!.country === Countries.CZE) {
        this.addressFormValue.get('_Class')?.setValue(AddressFormat.CzechAddressDto);
        this.addressFormValue.get(AddressFormat.CzechAddressDto)?.patchValue(this.selectedAddress);
      } else if (this.selectedAddress!.country === Countries.SVK) {
        this.addressFormValue.get('_Class')?.setValue(AddressFormat.SlovakAddressDto);
        this.addressFormValue.get(AddressFormat.SlovakAddressDto)?.patchValue(this.selectedAddress);
      }
    }
  }
  override get item(): NonemptyFilterItem {
    return this._item;
  }

  get addressFormValue() {
    return this.addressForm.get('value')!;
  }

  selectedAddress: Nullable<Partial<Record<keyof AddressCompleteDto, any>>> = null;
  searchTerm!: string;

  addressForm = createAddressFormGroup(null, 1);

  ngOnInit(): void {
    this.initForm();
    this.initOperatorAndValueChangeHandlers();
  }

  applyFilter(): void {
    this.emitFilterValue();
    this.closeFilterPopup();
  }

  emitFilterValue(): void {
    const value = this.form.get('value')!.value as AddressForm;
    if (!value) return;

    const flattened = {
      ...value.value[AddressFormat.CzechAddressDto],
      ...value.value[AddressFormat.SlovakAddressDto],
      ...value.value[AddressFormat.GenericLineAddressDto],
      country: value.value.country!,
      _Class: value.value._Class!,
    };

    const filterOperator = this.form.get('filterOperator')!.value;
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    const subValues: FilterSubValue[] = [];
    Object.entries(flattened).forEach(([key, val]) => {
      const noFilterKeys = ['_Class', 'houseNumberType', 'orientationNumberLastCharacter'];  // missing support in elastic right now
      if (key === 'addressLines') {
        val = (val as {line: string}[])[0].line; // in search address form, there is exactly 1 line from genericLineAddress
      }
      if (val && !noFilterKeys.includes(key)) {
        subValues.push({
          operator: filterOperator,
          value: String(val),
          subValueId: `${ADDRESS_SHAPE_INDICATOR}.${key}`
        });
      }
    });
    if (subValues.length > 1) { // country is always set as param, but itself isn't satisfying for emit, so requiring at least 2 params
      this.setFilterValue.emit({
        value: ADDRESS_SHAPE_INDICATOR,
        label: null,
        filterOperator,
        subValues,
        closeAfter: false,
      });
    }
  }

  addressFilterChanged(addressValue: Nullable<AddressCompleteDto>) {
    this.form.get('value')!.setValue(addressValue);
    this.selectedAddress = addressValue;
  }

}
