import {ChangeDetectionStrategy, Component} from '@angular/core';
import {RejectDialogData} from '../../reject-dialog/reject-dialog.service';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {IczValidators} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';


@Component({
  selector: 'icz-bulk-posting-form-template-name-dialog',
  templateUrl: './bulk-posting-form-template-name-dialog.component.html',
  styleUrls: ['./bulk-posting-form-template-name-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkPostingFormTemplateNameDialogComponent {

  private modalRef = injectModalRef<Nullable<string>>();
  protected modalData = injectModalData<RejectDialogData>();

  form = new IczFormGroup({
    name: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  ok() {
    this.modalRef.close(this.form.get('name')!.value);
  }

  cancel() {
    this.modalRef.close(null);
  }


}
