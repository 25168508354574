/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageOfStatisticDimensionsRowsDto } from '../models/page-of-statistic-dimensions-rows-dto';
import { statisticsGetDimensionsAggregation } from '../fn/statistics/statistics-get-dimensions-aggregation';
import { StatisticsGetDimensionsAggregation$Params } from '../fn/statistics/statistics-get-dimensions-aggregation';
import { statisticsGetStatisticTilesAsync } from '../fn/statistics/statistics-get-statistic-tiles-async';
import { StatisticsGetStatisticTilesAsync$Params } from '../fn/statistics/statistics-get-statistic-tiles-async';
import { statisticsGetXyAxesAggregation } from '../fn/statistics/statistics-get-xy-axes-aggregation';
import { StatisticsGetXyAxesAggregation$Params } from '../fn/statistics/statistics-get-xy-axes-aggregation';
import { StatisticTileDto } from '../models/statistic-tile-dto';
import { XyAxesAggregationResponseDto } from '../models/xy-axes-aggregation-response-dto';


/**
 * Statistics api providing elasticsearch aggregations
 */
@Injectable({ providedIn: 'root' })
export class ApiStatisticsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `statisticsGetXyAxesAggregation()` */
  static readonly StatisticsGetXyAxesAggregationPath = '/elastic/statistics/xy-axes-graph-data';

  /**
   * Get graph points for X and Y axis aggregation for given statistic
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsGetXyAxesAggregation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetXyAxesAggregation$Response(params: StatisticsGetXyAxesAggregation$Params, context?: HttpContext): Observable<StrictHttpResponse<XyAxesAggregationResponseDto>> {
    return statisticsGetXyAxesAggregation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get graph points for X and Y axis aggregation for given statistic
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsGetXyAxesAggregation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetXyAxesAggregation(params: StatisticsGetXyAxesAggregation$Params, context?: HttpContext): Observable<XyAxesAggregationResponseDto> {
    return this.statisticsGetXyAxesAggregation$Response(params, context).pipe(
      map((r: StrictHttpResponse<XyAxesAggregationResponseDto>): XyAxesAggregationResponseDto => r.body)
    );
  }

  /** Path part for operation `statisticsGetStatisticTilesAsync()` */
  static readonly StatisticsGetStatisticTilesAsyncPath = '/elastic/statistics/get-statistic-tiles-async';

  /**
   * Asynchronously retrieve required statistic tiles and return them to user by websocket
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsGetStatisticTilesAsync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetStatisticTilesAsync$Response(params: StatisticsGetStatisticTilesAsync$Params, context?: HttpContext): Observable<StrictHttpResponse<StatisticTileDto>> {
    return statisticsGetStatisticTilesAsync(this.http, this.rootUrl, params, context);
  }

  /**
   * Asynchronously retrieve required statistic tiles and return them to user by websocket
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsGetStatisticTilesAsync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetStatisticTilesAsync(params: StatisticsGetStatisticTilesAsync$Params, context?: HttpContext): Observable<StatisticTileDto> {
    return this.statisticsGetStatisticTilesAsync$Response(params, context).pipe(
      map((r: StrictHttpResponse<StatisticTileDto>): StatisticTileDto => r.body)
    );
  }

  /** Path part for operation `statisticsGetDimensionsAggregation()` */
  static readonly StatisticsGetDimensionsAggregationPath = '/elastic/statistics/dimensions-data';

  /**
   * Get dimensions for given statistic
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `statisticsGetDimensionsAggregation()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetDimensionsAggregation$Response(params: StatisticsGetDimensionsAggregation$Params, context?: HttpContext): Observable<StrictHttpResponse<PageOfStatisticDimensionsRowsDto>> {
    return statisticsGetDimensionsAggregation(this.http, this.rootUrl, params, context);
  }

  /**
   * Get dimensions for given statistic
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `statisticsGetDimensionsAggregation$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  statisticsGetDimensionsAggregation(params: StatisticsGetDimensionsAggregation$Params, context?: HttpContext): Observable<PageOfStatisticDimensionsRowsDto> {
    return this.statisticsGetDimensionsAggregation$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOfStatisticDimensionsRowsDto>): PageOfStatisticDimensionsRowsDto => r.body)
    );
  }

}
