/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { authorizationAuthorizeAgendaTransferOperations } from '../fn/authorization/authorization-authorize-agenda-transfer-operations';
import { AuthorizationAuthorizeAgendaTransferOperations$Params } from '../fn/authorization/authorization-authorize-agenda-transfer-operations';
import { authorizationAuthorizeComponentOperations } from '../fn/authorization/authorization-authorize-component-operations';
import { AuthorizationAuthorizeComponentOperations$Params } from '../fn/authorization/authorization-authorize-component-operations';
import { authorizationAuthorizeDocumentOperations } from '../fn/authorization/authorization-authorize-document-operations';
import { AuthorizationAuthorizeDocumentOperations$Params } from '../fn/authorization/authorization-authorize-document-operations';
import { authorizationAuthorizeFileOperations } from '../fn/authorization/authorization-authorize-file-operations';
import { AuthorizationAuthorizeFileOperations$Params } from '../fn/authorization/authorization-authorize-file-operations';
import { authorizationAuthorizeOperations } from '../fn/authorization/authorization-authorize-operations';
import { AuthorizationAuthorizeOperations$Params } from '../fn/authorization/authorization-authorize-operations';
import { authorizationAuthorizeOwnConsignmentOperations } from '../fn/authorization/authorization-authorize-own-consignment-operations';
import { AuthorizationAuthorizeOwnConsignmentOperations$Params } from '../fn/authorization/authorization-authorize-own-consignment-operations';
import { authorizationAuthorizeReceivedConsignmentOperations } from '../fn/authorization/authorization-authorize-received-consignment-operations';
import { AuthorizationAuthorizeReceivedConsignmentOperations$Params } from '../fn/authorization/authorization-authorize-received-consignment-operations';
import { authorizationAuthorizeSharedFolderOperations } from '../fn/authorization/authorization-authorize-shared-folder-operations';
import { AuthorizationAuthorizeSharedFolderOperations$Params } from '../fn/authorization/authorization-authorize-shared-folder-operations';
import { authorizationAuthorizeStorageUnitOperations } from '../fn/authorization/authorization-authorize-storage-unit-operations';
import { AuthorizationAuthorizeStorageUnitOperations$Params } from '../fn/authorization/authorization-authorize-storage-unit-operations';
import { ComponentAuthorizationResult } from '../models/component-authorization-result';
import { DocumentAuthorizationResult } from '../models/document-authorization-result';
import { FileAuthorizationResult } from '../models/file-authorization-result';
import { OwnConsignmentAuthorizationResult } from '../models/own-consignment-authorization-result';
import { ReceivedConsignmentAuthorizationResult } from '../models/received-consignment-authorization-result';
import { SharedFolderAuthorizationResult } from '../models/shared-folder-authorization-result';
import { StorageUnitAuthorizationResult } from '../models/storage-unit-authorization-result';

@Injectable({ providedIn: 'root' })
export class ApiAuthorizationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authorizationAuthorizeStorageUnitOperations()` */
  static readonly AuthorizationAuthorizeStorageUnitOperationsPath = '/authorization/storage-unit/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeStorageUnitOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeStorageUnitOperations$Response(params: AuthorizationAuthorizeStorageUnitOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitAuthorizationResult>> {
    return authorizationAuthorizeStorageUnitOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeStorageUnitOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeStorageUnitOperations(params: AuthorizationAuthorizeStorageUnitOperations$Params, context?: HttpContext): Observable<StorageUnitAuthorizationResult> {
    return this.authorizationAuthorizeStorageUnitOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitAuthorizationResult>): StorageUnitAuthorizationResult => r.body)
    );
  }

  /** Path part for operation `authorizationAuthorizeSharedFolderOperations()` */
  static readonly AuthorizationAuthorizeSharedFolderOperationsPath = '/authorization/shared-folder/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeSharedFolderOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeSharedFolderOperations$Response(params: AuthorizationAuthorizeSharedFolderOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<SharedFolderAuthorizationResult>> {
    return authorizationAuthorizeSharedFolderOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeSharedFolderOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeSharedFolderOperations(params: AuthorizationAuthorizeSharedFolderOperations$Params, context?: HttpContext): Observable<SharedFolderAuthorizationResult> {
    return this.authorizationAuthorizeSharedFolderOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<SharedFolderAuthorizationResult>): SharedFolderAuthorizationResult => r.body)
    );
  }

  /** Path part for operation `authorizationAuthorizeFileOperations()` */
  static readonly AuthorizationAuthorizeFileOperationsPath = '/authorization/file/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeFileOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeFileOperations$Response(params: AuthorizationAuthorizeFileOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<FileAuthorizationResult>> {
    return authorizationAuthorizeFileOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeFileOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeFileOperations(params: AuthorizationAuthorizeFileOperations$Params, context?: HttpContext): Observable<FileAuthorizationResult> {
    return this.authorizationAuthorizeFileOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<FileAuthorizationResult>): FileAuthorizationResult => r.body)
    );
  }

  /** Path part for operation `authorizationAuthorizeDocumentOperations()` */
  static readonly AuthorizationAuthorizeDocumentOperationsPath = '/authorization/document/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeDocumentOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeDocumentOperations$Response(params: AuthorizationAuthorizeDocumentOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<DocumentAuthorizationResult>> {
    return authorizationAuthorizeDocumentOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeDocumentOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeDocumentOperations(params: AuthorizationAuthorizeDocumentOperations$Params, context?: HttpContext): Observable<DocumentAuthorizationResult> {
    return this.authorizationAuthorizeDocumentOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<DocumentAuthorizationResult>): DocumentAuthorizationResult => r.body)
    );
  }

  /** Path part for operation `authorizationAuthorizeReceivedConsignmentOperations()` */
  static readonly AuthorizationAuthorizeReceivedConsignmentOperationsPath = '/authorization/consignment/received/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeReceivedConsignmentOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeReceivedConsignmentOperations$Response(params: AuthorizationAuthorizeReceivedConsignmentOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<ReceivedConsignmentAuthorizationResult>> {
    return authorizationAuthorizeReceivedConsignmentOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeReceivedConsignmentOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeReceivedConsignmentOperations(params: AuthorizationAuthorizeReceivedConsignmentOperations$Params, context?: HttpContext): Observable<ReceivedConsignmentAuthorizationResult> {
    return this.authorizationAuthorizeReceivedConsignmentOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ReceivedConsignmentAuthorizationResult>): ReceivedConsignmentAuthorizationResult => r.body)
    );
  }

  /** Path part for operation `authorizationAuthorizeOwnConsignmentOperations()` */
  static readonly AuthorizationAuthorizeOwnConsignmentOperationsPath = '/authorization/consignment/own/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeOwnConsignmentOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeOwnConsignmentOperations$Response(params: AuthorizationAuthorizeOwnConsignmentOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<OwnConsignmentAuthorizationResult>> {
    return authorizationAuthorizeOwnConsignmentOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeOwnConsignmentOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeOwnConsignmentOperations(params: AuthorizationAuthorizeOwnConsignmentOperations$Params, context?: HttpContext): Observable<OwnConsignmentAuthorizationResult> {
    return this.authorizationAuthorizeOwnConsignmentOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<OwnConsignmentAuthorizationResult>): OwnConsignmentAuthorizationResult => r.body)
    );
  }

  /** Path part for operation `authorizationAuthorizeComponentOperations()` */
  static readonly AuthorizationAuthorizeComponentOperationsPath = '/authorization/component/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeComponentOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeComponentOperations$Response(params: AuthorizationAuthorizeComponentOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<ComponentAuthorizationResult>> {
    return authorizationAuthorizeComponentOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeComponentOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeComponentOperations(params: AuthorizationAuthorizeComponentOperations$Params, context?: HttpContext): Observable<ComponentAuthorizationResult> {
    return this.authorizationAuthorizeComponentOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<ComponentAuthorizationResult>): ComponentAuthorizationResult => r.body)
    );
  }

  /** Path part for operation `authorizationAuthorizeOperations()` */
  static readonly AuthorizationAuthorizeOperationsPath = '/authorization/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeOperations$Response(params: AuthorizationAuthorizeOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<(DocumentAuthorizationResult | FileAuthorizationResult | OwnConsignmentAuthorizationResult | ReceivedConsignmentAuthorizationResult | ComponentAuthorizationResult | StorageUnitAuthorizationResult)>>> {
    return authorizationAuthorizeOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeOperations(params: AuthorizationAuthorizeOperations$Params, context?: HttpContext): Observable<Array<(DocumentAuthorizationResult | FileAuthorizationResult | OwnConsignmentAuthorizationResult | ReceivedConsignmentAuthorizationResult | ComponentAuthorizationResult | StorageUnitAuthorizationResult)>> {
    return this.authorizationAuthorizeOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<(DocumentAuthorizationResult | FileAuthorizationResult | OwnConsignmentAuthorizationResult | ReceivedConsignmentAuthorizationResult | ComponentAuthorizationResult | StorageUnitAuthorizationResult)>>): Array<(DocumentAuthorizationResult | FileAuthorizationResult | OwnConsignmentAuthorizationResult | ReceivedConsignmentAuthorizationResult | ComponentAuthorizationResult | StorageUnitAuthorizationResult)> => r.body)
    );
  }

  /** Path part for operation `authorizationAuthorizeAgendaTransferOperations()` */
  static readonly AuthorizationAuthorizeAgendaTransferOperationsPath = '/authorization/agenda-transfer/check';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `authorizationAuthorizeAgendaTransferOperations()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeAgendaTransferOperations$Response(params: AuthorizationAuthorizeAgendaTransferOperations$Params, context?: HttpContext): Observable<StrictHttpResponse<SharedFolderAuthorizationResult>> {
    return authorizationAuthorizeAgendaTransferOperations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `authorizationAuthorizeAgendaTransferOperations$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  authorizationAuthorizeAgendaTransferOperations(params: AuthorizationAuthorizeAgendaTransferOperations$Params, context?: HttpContext): Observable<SharedFolderAuthorizationResult> {
    return this.authorizationAuthorizeAgendaTransferOperations$Response(params, context).pipe(
      map((r: StrictHttpResponse<SharedFolderAuthorizationResult>): SharedFolderAuthorizationResult => r.body)
    );
  }

}
