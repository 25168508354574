@if (dataSource) {
  <icz-table [columnsData]="columnsData" [config]="tableConfig"
    [dataSource]="dataSource" id="bpf-content">
    <icz-label label="Seznam položek" tableTitle></icz-label>
    <icz-label label="Žádné zásilky na archu" noDataLabel></icz-label>
    <icz-label label="Žádné zásilky na archu nebyly nalezeny" notFoundLabel></icz-label>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'ownPaperConsignmentDto.uid'">
      <span>{{row.ownPaperConsignmentDto.uid.uid}}</span>
    </ng-container>
    <ng-container
      *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'ownPaperConsignmentDto.consignee'; withEllipsis: true">
      <icz-consignment-table-consignee
        [consignment]="row.ownPaperConsignmentDto"
      ></icz-consignment-table-consignee>
    </ng-container>
    <ng-container
      *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'ownPaperConsignmentDto.consigneeAddress'">
      <icz-consignment-table-address
        [consignment]="row.ownPaperConsignmentDto"
      ></icz-consignment-table-address>
    </ng-container>
    <ng-container
      *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'ownPaperConsignmentDto.consignmentPostingNumber'; withEllipsis: true; let column = column">
      @if ($any(row).consignmentPostingNumber) {
        <span
        [iczTooltip]="getConsignmentPostingNumber(row)">{{ getConsignmentPostingNumber(row) }}</span>
      }
    </ng-container>
    <ng-container
      *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'ownPaperConsignmentDto.payoutAmount'; withEllipsis: true; let column = column">
      @if ((row.ownPaperConsignmentDto.payoutAmount | money); as payoutAmount) {
        <span [iczTooltip]="payoutAmount">{{ payoutAmount }}</span>
      }
    </ng-container>
    <ng-container
      *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'ownPaperConsignmentDto.declaredValue'; withEllipsis: true; let column = column">
      @if ((row.ownPaperConsignmentDto.declaredValue | money); as declaredValue) {
        <span [iczTooltip]="declaredValue">{{ declaredValue }}</span>
      }
    </ng-container>
    <ng-container
      *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'ownPaperConsignmentDto.weight'; withEllipsis: true; let column = column">
      @if (row.ownPaperConsignmentDto.weight; as weight) {
        <span [iczTooltip]="weight + 'g'">{{ weight + 'g' }}</span>
      }
    </ng-container>
  </icz-table>
}
