import {DestroyRef, inject, Injectable, Type} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable, of} from 'rxjs';
import {CirculationActivityType, FileState, ObjectClass} from '|api/commons';
import {AuthorizedEntityType, FileAuthorizedOperation} from '|api/core';
import {ApiFileService, DocumentDto, FileDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {Button} from '../../../button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {FileToolbarDisablers} from './file-toolbar-buttons.disablers';
import {FileHandoverDialogComponent} from '../../file-toolbar/file-handover-dialog/file-handover-dialog.component';
import {AbstractHandoverFormModalComponent} from '../components/abstract-handover-form-modal.component';
import {FileToastService, FileToastType} from '../../../../core/services/notifications/file-toast.service';
import {FileCirculationToastService} from '../../../../core/services/notifications/file-circulation-toast.service';
import {CirculationToastService} from '../../../../core/services/notifications/circulation-toast.service';
import {
  InternalNotificationBulkFileActionMessageCode,
  InternalNotificationMessageCode
} from '../../../../core/services/notifications/internal-notification.enum';
import {
  HandoverEntity,
  HandoverToExternalAppDialogComponent,
  HandoverToExternalAppDialogData
} from '../../handover-to-external-app-dialog/handover-to-external-app-dialog.component';
import {DialogService, IczModalService} from '@icz/angular-modal';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {getObjectIcon, getObjectLabel, isDocumentEntity, isFileObject} from '../../shared-document.utils';
import {SKIP_ERROR_DIALOG} from '../../../../core/error-handling/http-errors';
import {DocumentView, openGeneralCirculationModal} from './toolbar-common.utils';
import {FileOrProfileDtoWithAuthorization} from '../../model/dto-with-permissions.interface';
import {AbstractFileDialogsManagerService} from '../../../../services/abstract-file-dialogs-manager.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {
  BulkOperationValidationService,
  OperationValidator
} from 'libs/shared/src/lib/services/bulk-operation-validation.service';
import {FileOperationValidators} from './file-operation-validators';
import {GeneralAuthorizedOperation} from '../../permissions/permissions.utils';
import {CommonToolbarValidators} from './toolbar-common.validators';
import {PrintFileInfoDialogComponent} from '../components/print-file-info-dialog/print-file-info-dialog.component';
import {esslErrorDtoToToastParameters, MessageType, ToastService} from '../../../notifications/toast.service';
import {
  FileRenditionDialogComponent,
  FileRenditionOutputType
} from '../components/file-rendition-dialog/file-rendition-dialog.component';
import {WITHOUT_REF_NUMBER} from '../../shared-business-components.model';
import {createAbsoluteRoute} from '../../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../../enums/shared-routes.enum';
import {Router} from '@angular/router';
import {DocumentsRoute} from '../../../../enums/documents-routes.enum';
import {FileDetailService} from '../../../../services/file-detail.service';

enum FileOperation {
  SETTLE = 'SETTLE',
  CLOSE = 'CLOSE',
  WITHDRAW_SETTLE = 'WITHDRAW_SETTLE',
  REOPEN = 'REOPEN',
}

export enum FileToolbarAction {
  FILE_SETTLED = 'FILE_SETTLED',
  FILE_CLOSED = 'FILE_CLOSED',
  FILE_SETTLEMENT_WITHDRAWN = 'FILE_SETTLEMENT_WITHDRAWN',
  FILE_REOPENED = 'FILE_REOPENED',
  FILE_DEACTIVATED = 'FILE_DEACTIVATED',
  FILE_HANDED_OVER_TO_EXTERNAL_APP = 'FILE_HANDED_OVER_TO_EXTERNAL_APP',
  FILE_CIRCULATION_STARTED = 'FILE_CIRCULATION_STARTED',
  FILE_SETTLE_STARTED = 'FILE_SETTLE_STARTED',
  FILE_REACTIVATED = 'FILE_REACTIVATED'
}

@Injectable()
export class FileToolbarButtonsService extends ToolbarProvider<FileOrProfileDtoWithAuthorization, FileToolbarAction, DocumentView> {

  private authorizedButtonService = inject(AuthorizedButtonService);
  private apiFileService = inject(ApiFileService);
  private modalService = inject(IczModalService);
  private fileToastService = inject(FileToastService);
  private translateService = inject(TranslateService);
  private fileCirculationToastService = inject(FileCirculationToastService);
  private circulationToastService = inject(CirculationToastService);
  private dialogService = inject(DialogService);
  private currentSessionService = inject(CurrentSessionService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private fileDialogsManager = inject(AbstractFileDialogsManagerService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private destroyRef = inject(DestroyRef);
  private toastService = inject(ToastService);
  private globalLoading = inject(GlobalLoadingIndicatorService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private fileDetailService = inject(FileDetailService, {optional: true});
  private router = inject(Router);

  constructor() {
    super();

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.FILE_SETTLEMENT_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_SETTLED);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.FILE_SETTLEMENT_ERROR)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_SETTLED);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationBulkFileActionMessageCode.BULK_WITHDRAW_SETTLE_FILE_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_SETTLEMENT_WITHDRAWN);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationBulkFileActionMessageCode.BULK_CLOSE_FILE_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_CLOSED);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationBulkFileActionMessageCode.BULK_CLOSE_FILE_ERROR)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_CLOSED);
      }
    );

    this.wsNotificationService.getMessageListener$(InternalNotificationBulkFileActionMessageCode.BULK_REOPEN_FILE_SUCCESS)
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_REOPENED);
      }
    );
  }

  getCreateFileContentToolbarButtons(selection: FileDto[], viewType?: DocumentView): Observable<Button[]> {
    const singleEntity = selection?.[0];

    const buttons: AuthorizedButton[] = [
      {
        label: 'Založit',
        icon: 'add_new',
        show: selection?.[0]?.fileState !== FileState.DELETED,
        submenuItems: [
          {
            label: 'Založit do spisu vlastní dokument',
            authorizedOperations: [FileAuthorizedOperation.FILE_ADD_DOCUMENT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
              FileToolbarDisablers.isSomeFileNotOpen(selection),
              FileToolbarDisablers.isSomeFileDeactivated(selection),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
            ],
            action: () => this.createOwnDocumentToFile(selection)
          },
          {
            label: 'Založit do spisu doručený dokument',
            authorizedOperations: [FileAuthorizedOperation.FILE_ADD_DOCUMENT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
              FileToolbarDisablers.isSomeFileNotOpen(selection),
              FileToolbarDisablers.isSomeFileDeactivated(selection),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
            ],
            action: () => this.createReceivedDocumentToFile(selection)
          }
        ]
      },
    ];

    return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
      {
        [AuthorizedEntityType.FILE]: isFileObject(singleEntity) ? singleEntity?.id : null,
      },
      of(buttons),
    );
  }

  getToolbarButtons(selection: FileDto[], viewType?: DocumentView): Observable<Button[]> {
    const singleEntity = selection?.[0];
    const isFileDeactivated = singleEntity?.fileState === FileState.DEACTIVATED;
    const isOnFileDetail = this.fileDetailService !== null;
    const isSettledFileView = viewType === DocumentView.SETTLED_DOCUMENTS || isOnFileDetail || viewType === DocumentView.MANUAL_SHARED;

    const buttons: AuthorizedButton[] = [
      {
        label: 'Úpravy',
        icon: 'edit',
        buttonDisablers: [CommonToolbarDisablers.isNoItemSelected(selection)],
        show: selection?.[0]?.fileState !== FileState.DELETED,
        submenuItems: [
          {
            label: 'Vyřídit spis',
            show: viewType !== DocumentView.SETTLED_DOCUMENTS,
            authorizedOperations: [FileAuthorizedOperation.FILE_SETTLE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
              FileToolbarDisablers.isSomeFileNotOpen(selection),
              FileToolbarDisablers.isSomeFileDeactivated(selection),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
              CommonToolbarDisablers.isFileOrDocumentSuspended(selection)
            ],
            action: button => this.onFileOperationClick(
              selection,
              FileOperation.SETTLE,
              'Některé spisy ({{errorCount}}) není možné vyřídit. Hromadná akce vyřizení bude provedena jen s vyhovujícími spisy ({{successCount}}).',
              [
                FileOperationValidators.selectedItemIsNotFile(),
                FileOperationValidators.isFileNotOpen(),
                FileOperationValidators.isFileDeactivated(),
                CommonToolbarValidators.isFileOrDocumentSuspended()
              ],
              button.authorizedOperations
            ),
          },
          {
            label: 'Uzavřít spis',
            authorizedOperations: [FileAuthorizedOperation.FILE_CLOSE],
            show: isSettledFileView,
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
              FileToolbarDisablers.isSomeFileNotSettled(selection),
              FileToolbarDisablers.isSomeFileDeactivated(selection),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
            ],
            action: button => this.onFileOperationClick(
              selection,
              FileOperation.CLOSE,
              'Některé spisy ({{errorCount}}) není možné uzavřít. Hromadná akce uzavření bude provedena jen s vyhovujícími spisy ({{successCount}}).',
              [
                FileOperationValidators.selectedItemIsNotFile(),
                FileOperationValidators.isFileNotSettled(),
                FileOperationValidators.isFileDeactivated(),
              ],
              button.authorizedOperations
            ),
          },
          {
            label: 'Zrušit vyřízení spisu',
            authorizedOperations: [FileAuthorizedOperation.FILE_REVOKE_SETTLEMENT],
            show: isSettledFileView,
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
              FileToolbarDisablers.isSomeFileClosed(selection),
              FileToolbarDisablers.isSomeFileNotSettled(selection),
              FileToolbarDisablers.isSomeFileDeactivated(selection),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
              CommonToolbarDisablers.isEntityInRegistryOffice(selection),
            ],
            action: button => this.onFileOperationClick(
              selection,
              FileOperation.WITHDRAW_SETTLE,
              'U některých spisů ({{errorCount}}) není možné zrušit vyřízení. Hromadná akce bude provedena jen s vyhovujícími spisy ({{successCount}}).',
              [
                FileOperationValidators.selectedItemIsNotFile(),
                FileOperationValidators.isFileClosed(),
                FileOperationValidators.isFileNotSettled(),
                FileOperationValidators.isFileDeactivated(),
                CommonToolbarValidators.isEntityInRegistryOffice(),
              ],
              button.authorizedOperations
            ),
          },
          {
            label: 'Opětovně otevřít spis',
            authorizedOperations: [FileAuthorizedOperation.FILE_OPEN_CLOSED],
            show: isSettledFileView,
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
              FileToolbarDisablers.isSomeFileDeactivated(selection),
              FileToolbarDisablers.isSomeFileNotClosed(selection),
              FileToolbarDisablers.isFileInStorageUnit(selection),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
              CommonToolbarDisablers.isEntityInRegistryOffice(selection),
            ],
            action: button => this.onFileOperationClick(
              selection,
              FileOperation.REOPEN,
              'Některé spisy ({{errorCount}}) není možné opětovně otevřít. Hromadná akce bude provedena jen s vyhovujícími spisy ({{successCount}}).',
              [
                FileOperationValidators.selectedItemIsNotFile(),
                FileOperationValidators.isFileDeactivated(),
                CommonToolbarValidators.isEntityInRegistryOffice(),
              ],
              button.authorizedOperations
            ),
          },
          {
            label: 'Obnovit spis',
            authorizedOperations: [FileAuthorizedOperation.FILE_REACTIVATE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
            ],
            show: isFileDeactivated,
            action: () => this.onReativationClick(selection[0]),
          },
        ],
      },
      {
        label: 'Oběh',
        icon: 'move_document',
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
          FileToolbarDisablers.selectedItemIsNotFile(selection),
          FileToolbarDisablers.isSomeFileDeactivated(selection),
          CommonToolbarDisablers.isEsslObjectLocked(selection),
        ],
        submenuItems: [
          {
            label: 'Předat spis',
            iconAfter: 'info',
            tooltip: 'fe.ui.circulation.fileHandover.tooltip',
            authorizedOperations: [FileAuthorizedOperation.FILE_CREATE_HANDOVER_ACTIVITY],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
            ],
            action: button => this.onFileHandoverClick(
              selection,
              [
                FileOperationValidators.selectedItemIsNotFile(),
                CommonToolbarValidators.isEsslObjectLocked(),
              ],
              button.authorizedOperations
            ),
          },
          {
            label: 'Předat externí aplikaci',
            iconAfter: 'info',
            tooltip: 'fe.ui.circulation.externalHandover.tooltip',
            authorizedOperations: [FileAuthorizedOperation.FILE_CREATE_HANDOVER_ACTIVITY],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FileToolbarDisablers.isFileInStorageUnit(selection),
              FileToolbarDisablers.fileHandoverToExternalApplicationPreconditionNotMet(selection as FileDto[]),
              CommonToolbarDisablers.isEsslObjectLocked(selection),
            ],
            action: () => this.onHandoverToExternalAppClick(selection),
          },
        ]
      },
      {
        label: 'Více',
        icon: 'more',
        submenuItems: [
          {
            label: 'Ztvárnění spisu',
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
            ],
            action: () => this.onGenerateFileRendition(selection),
          },
          {
            label: 'Tisk informací o spisu',
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FileToolbarDisablers.selectedItemIsNotFile(selection),
            ],
            action: () => this.onPrintFileInfo(selection),
          },
        ]
      }
    ];

    return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
      {
        [AuthorizedEntityType.FILE]: isFileObject(singleEntity) ? singleEntity?.id : null,
      },
      of(buttons),
    );
  }

  runFileOperation(selection: FileDto[], operation: FileOperation) {
    if (operation === FileOperation.SETTLE) {
      this.onFileResolutionClick(selection);
    }
    else if (operation === FileOperation.CLOSE) {
      this.onFileCloseClick(selection);
    }
    else if (operation === FileOperation.WITHDRAW_SETTLE) {
      this.onFileWithdrawSettlement(selection);
    }
    else if (operation === FileOperation.REOPEN) {
      this.onFileReopenClick(selection);
    }
  }

  onFileOperationClick(
    selectionData: FileDto[],
    operation: FileOperation,
    dialogWarningLabel: string,
    validators?: OperationValidator<FileDto>[],
    authorizedOperations?: GeneralAuthorizedOperation[]
  ) {
    this.bulkOperationValidationService.validateEsslObjects<FileDto>(
      {
        dialogWarningLabel,
        dialogWarningLabelContext: {},
        operationValidators: validators ?? [],
        esslObjects: selectionData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.runFileOperation(validatedObjects as FileDto[], operation);
      }
    });
  }

  onFileResolutionClick(fileData: FileDto[]) {
    if (!fileData) return;
    this.fileDialogsManager.openFileSettleDialog(fileData).subscribe(_ => {
      this.announceActionCompleted(FileToolbarAction.FILE_SETTLE_STARTED);
    });
  }

  onFileCloseClick(fileData: FileDto[]) {
    this.fileDialogsManager.openFileCloseDialog(fileData).subscribe(result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_CLOSED);
      }
    });
  }

  onFileWithdrawSettlement(fileData: FileDto[]) {
    const isBulk = fileData.length > 1;
    this.dialogService.openSimpleDialog({
      title: 'Zrušení vyřízení',
      leftButtonTitle: 'Zrušit vyřízení',
      content: [
        {
          text: isBulk ? 'Spisy budou přesunuty do složky Vyřizované referenta, který je zpracovatelem spisu.' :
            'Spis bude přesunut do složky Vyřizované referenta, který je zpracovatelem spisu.'
        }
      ]
    }).subscribe(result => {
      if (result) {
        this.globalLoading.doLoading(this.apiFileService.fileBulkWithdrawSettle({body: fileData.map(f => f.id)})).subscribe({
          next: _ => {
            if (fileData.length === 1) {
              this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_WITHDRAW_SETTLEMENT_STARTED, {
                [InternalNotificationKey.FILE_ID]: fileData[0].id,
                [InternalNotificationKey.FILE_SUBJECT]: fileData[0].subject,
              });
            }
            else {
              this.fileToastService.dispatchBulkOperationStartedToast(FileToastType.BULK_FILE_WITHDRAW_SETTLEMENT_STARTED, {
                [InternalNotificationKey.COUNT]: fileData.length,
              });
            }
            this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
            this.announceActionCompleted(FileToolbarAction.FILE_SETTLEMENT_WITHDRAWN);
          },
          error: error => {
            this.fileToastService.dispatchFileErrorToast(FileToastType.FILE_WITHDRAW_SETTLEMENT_ERROR, esslErrorDtoToToastParameters(this.translateService, error.error));
          }
        });
      }
    });
  }

  onFileReopenClick(fileData: FileDto[]) {
    const isBulk = fileData.length > 1;
    this.dialogService.openSimpleDialog({
      title: 'Znovuotevření spisu',
      leftButtonTitle: 'Znovuotevřít',
      content: [
        {
          text: isBulk ? 'Spisy budou znovuotevřeny a přesunuty do stavu Vyřízen.' : 'Spis bude znovuotevřen a přesunut do stavu Vyřízen.',
        }
      ]
    }).subscribe(result => {
      if (result) {
        this.globalLoading.doLoading(this.apiFileService.fileBulkReopen(
          {
            body: fileData.map(f => f.id),
          },
          SKIP_ERROR_DIALOG
        )).subscribe({
          next: _ => {
            if (fileData.length === 1) {
              this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_REOPEN_STARTED, {
                [InternalNotificationKey.FILE_ID]: fileData[0].id,
                [InternalNotificationKey.FILE_SUBJECT]: fileData[0].subject,
              });
            }
            else {
              this.fileToastService.dispatchBulkOperationStartedToast(FileToastType.BULK_FILE_REOPEN_STARTED, {
                [InternalNotificationKey.COUNT]: fileData.length,
              });
            }
            this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
            this.announceActionCompleted(FileToolbarAction.FILE_REOPENED);
          },
          error: e => {
            this.fileToastService.dispatchFileReopenedError(esslErrorDtoToToastParameters(this.translateService, e.error));
          },
        });
      }
    });
  }

  esslObjectToValidationObject(object: FileDto) {
    return {
      entityId: object.id,
      authorizedEntityType: isDocumentEntity(object.entityType) ? AuthorizedEntityType.DOCUMENT : AuthorizedEntityType.FILE,
      entityIcon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      entityData: object,
      entityName: getObjectLabel(this.translateService, object),
    };
  }

  onFileHandoverClick(selectionData: FileDto[],
                      validators: OperationValidator<FileDto>[],
                      authorizedOperations?: GeneralAuthorizedOperation[]) {
    this.bulkOperationValidationService.validateEsslObjects<FileDto>(
      {
        dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést předání. Hromadná akce předání bude provedena jen s vyhovujícími objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: validators ?? [],
        esslObjects: selectionData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.openCirculationModal(
          'Předat spis - {{refNumber}}',
          'Hromadné předání ({{count}}) spisů',
          FileHandoverDialogComponent,
          validatedObjects,
        ).subscribe(resultActivityId => {
          if (selectionData.length === 1) {
            if (resultActivityId) {
              const templateData = {
                [InternalNotificationKey.FILE_ID]: String(selectionData[0].id),
                [InternalNotificationKey.FILE_SUBJECT]: selectionData[0].subject,
                [InternalNotificationKey.ACTIVITY_ID]: resultActivityId,
                [InternalNotificationKey.REF_NUMBER]: selectionData[0].refNumber ?? this.translateService.instant(WITHOUT_REF_NUMBER),
              };

              this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
              this.fileCirculationToastService.dispatchFileHandoverAssignedInitiator(templateData);
            }
          }
          else {
            const templateData = {
              [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(`en.circulationActivityType.${CirculationActivityType.FILE_HANDOVER}`),
              [InternalNotificationKey.COUNT]: String(selectionData.length),
            };
            this.fileCirculationToastService.dispatchBulkFileHandoverAssignedInitiator(templateData);
          }
        });
      }
    });
  }

  private openCirculationModal(
    titleTemplate: string,
    titleTemplateBulk: string,
    modalComponent: Type<AbstractHandoverFormModalComponent>,
    documentData: Nullable<DocumentDto | FileDto>[],
  ) {
    return openGeneralCirculationModal(
      // @ts-ignore -- circumvent private property error
      this,
      titleTemplate,
      titleTemplateBulk,
      modalComponent,
      documentData,
      FileToolbarAction.FILE_CIRCULATION_STARTED,
    );
  }

  onReativationClick(fileData: FileDto) {
    this.globalLoading.doLoading(this.apiFileService.fileReactivateFile({id: fileData.id!})).subscribe({
      next: _ => {
        this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_REACTIVATED, {
          [InternalNotificationKey.FILE_ID]: fileData.id,
          [InternalNotificationKey.FILE_SUBJECT]: fileData.subject,
        });
        this.announceActionCompleted(FileToolbarAction.FILE_REACTIVATED);
      }
    });
  }

  onHandoverToExternalAppClick(fileData: FileDto[]) {
    const dialogTitle = 'Předání spisu - {{refNumber}}';
    const dialogTitleParams = {
      refNumber: fileData[0].refNumber!
    };

    const handoverEntities: HandoverEntity[] = [
      {
        entityId: fileData[0].id,
        entityType: fileData[0].entityType!,
        fileTypeId: fileData[0].fileTypeId,
      }
    ];

    this.modalService.openComponentInModal<boolean, HandoverToExternalAppDialogData>({
      component: HandoverToExternalAppDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: dialogTitle,
        titleTemplateContext: dialogTitleParams
      },
      data: {
        handoverEntities
      },
    }).subscribe(result => {
      if (result) {
        this.circulationToastService.dispatchHandoverTaskToExternalAppStarted({
          [InternalNotificationKey.ENTITY_TYPE]: String(fileData[0].entityType!),
          [InternalNotificationKey.REF_NUMBER]: String(fileData[0].refNumber),
          [InternalNotificationKey.ID]: String(fileData[0].id),
          [InternalNotificationKey.ACTIVITY_TYPE_KEY]: this.translateService.instant(
            'en.circulationActivityType.' + CirculationActivityType.FILE_ISSD_HANDOVER),
          [InternalNotificationKey.SUBJECT]: String(fileData[0].subject)
        });
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_HANDED_OVER_TO_EXTERNAL_APP);
      }
    });
  }

  onPrintFileInfo(fileData: FileDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<FileDto>(
      {
        dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést tisk jejich informací. Hromadný tisk informací bude proveden jen s vyhovujícími spisy ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          FileOperationValidators.selectedItemIsNotFile(),
        ],
        esslObjects: fileData.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations: [],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        let dialogTitle: string;
        let dialogTitleContext: Record<string, string>;

        if (validatedObjects.length === 1) {
          dialogTitle = 'Tisk informací o spisu';
          dialogTitleContext = {};
        }
        else {
          dialogTitle = 'Tisk informací o spisech ({{count}})';
          dialogTitleContext = {count: String(validatedObjects.length)};
        }

        this.modalService.openComponentInModal<boolean, FileDto[]>({
          component: PrintFileInfoDialogComponent,
          modalOptions: {
            width: 400,
            height: 500,
            titleTemplate: dialogTitle,
            titleTemplateContext: dialogTitleContext,
          },
          data: validatedObjects,
        }).subscribe(dialogResult => {
          if (dialogResult) {
            this.toastService.dispatchSimpleToast(MessageType.INFO, FileToastType.FILE_PRINT_INFO_CREATED);
          }
        });
      }
    });
  }

  onGenerateFileRendition(fileData: FileDto[]) {
    this.modalService.openComponentInModal<Nullable<FileRenditionOutputType>, FileDto[]>({
      component: FileRenditionDialogComponent,
      modalOptions: {
        width: 800,
        height: 600,
        titleTemplate: 'Ztvárnění spisu',
      },
      data: fileData,
    }).subscribe(dialogResult => {
      if (dialogResult === FileRenditionOutputType.DOWNLOAD) {
        this.toastService.dispatchSimpleToast(MessageType.INFO, FileToastType.FILE_RENDITION_DOWNLOADED);
      }
      else if (dialogResult === FileRenditionOutputType.APPEND_TO_DOCUMENT) {
        this.toastService.dispatchSimpleToast(MessageType.INFO, FileToastType.FILE_RENDITION_APPENDED_TO_DOCUMENT);
      }
    });
  }

  createOwnDocumentToFile(fileData: FileDto[]) {
    this.router.navigateByUrl(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      DocumentsRoute.FILE,
      fileData[0].id,
      DocumentsRoute.OWN_DOCUMENT_TO_FILE,
    ));
  }

  createReceivedDocumentToFile(fileData: FileDto[]) {
    this.router.navigateByUrl(createAbsoluteRoute(
      ApplicationRoute.DOCUMENTS,
      DocumentsRoute.FILE,
      fileData[0].id,
      DocumentsRoute.RECEIVED_DOCUMENT_TO_FILE,
    ));
  }

}
