/* eslint-disable */
import {
  AbstractControl,
  AsyncValidatorFn,
  FormArray,
  FormControl,
  FormControlOptions,
  FormControlState,
  FormGroup,
  ValidatorFn
} from '@angular/forms';
import {Subject} from 'rxjs';
import {IczValidators} from '../validators/icz-validators/icz-validators';
import {IczValidatorFn} from '../validators/icz-validators/validator-decorators';

/**
 * @internal
 * copied over from @angular/forms defs
 */
type EmitEventOpts = {emitEvent?: boolean, onlySelf?: boolean};

/**
 * @internal
 * copied over from @angular/forms defs
 */
export declare type ɵRawValue<T extends AbstractControl | undefined> = T extends AbstractControl<any, any> ? (T['setValue'] extends ((v: infer R) => void) ? R : never) : never;

/**
 * @internal
 * copied over from @angular/forms defs
 */
declare type ɵIsAny<T, Y, N> = 0 extends (1 & T) ? Y : N;

/**
 * @internal
 * copied over from @angular/forms defs
 */
export declare type ɵTypedOrUntyped<T, Typed, Untyped> = ɵIsAny<T, Untyped, Typed>;

/**
 * @internal
 * copied over from @angular/forms defs
 */
type ɵFormGroupRawValue<T extends {
  [K in keyof T]?: AbstractControl<any>;
}> = ɵTypedOrUntyped<T, {
  [K in keyof T]: ɵRawValue<T[K]>;
}, {
  [key: string]: any;
}>;

/**
 * @internal
 * copied over from @angular/forms defs
 */
export declare type ɵFormArrayRawValue<T extends AbstractControl<any>> = ɵTypedOrUntyped<T, Array<ɵRawValue<T>>, any[]>;

/**
 * Our extension of boxed form control initial value which is able to bear the important flag.
 */
export interface IczFormControlState<T> extends FormControlState<T> {
  /**
   * Important flag marks a given form control with light blue background.
   * Used for example for recommending to fill a specified set of non-required personal information etc.
   */
  important?: boolean;
}

/**
 * Comprehensive information about an invalid form control.
 */
export interface InvalidFormControlInfo {
  /**
   * Control key in reactive model.
   */
  controlName: string;
  /**
   * Control value.
   */
  value: any;
  /**
   * An array of Angular reactive form validator validation errors.
   */
  errors: string[];
}

/**
 * A set of predefined text lengths corresponding to our database text types.
 */
export enum TextLength {
  TINY = 50,
  /**
   * Default.
   */
  SHORT = 255,
  LONG = 4000,
  /**
   * Might come in handy when some other validator already validates text length
   */
  UNLIMITED = -1,
}

/**
 * A helper function to mass-enable multiple form fields at the same time
 */
export function enableFormFields<TControl extends {[K in keyof TControl]: AbstractControl<any>} = any>(
  form: IczFormGroup<TControl>,
  attributesToEnable: ReadonlyArray<keyof TControl>
) {
  for (const attribute of attributesToEnable) {
    // @ts-ignore
    form.get(attribute)!.enable();
  }
}

/**
 * A helper function to mass-disable multiple form fields at the same time
 */
export function disableFormFields<TControl extends {[K in keyof TControl]: AbstractControl<any>} = any>(
  form: IczFormGroup<TControl>,
  attributesToEnable: Array<keyof TControl>
) {
  for (const attribute of attributesToEnable) {
    // @ts-ignore
    form.get(attribute)!.disable();
  }
}

/**
 * Method for recursively updating validity status of FormGroup/FormArray. By default, this method prevents parent of
 * `this` FormGroup from beeing notified about changes in validity status. To prevent this behavior use onlySelf = false
 * parameter in opts object
 * @param opts Options for enabling/disabling status changes events
 */
function recursivelyUpdateValueAndValidity(this: IczFormGroup|IczFormArray, opts?: EmitEventOpts) {
  // depth-first traversal
  for (const key in this.controls) {
    if (this.controls.hasOwnProperty(key)) {
      const formElement = this.get(key);
      const recursiveCallOpts = {onlySelf: true, ...(opts ?? {})};

      if (formElement instanceof IczFormControl) {
        formElement.updateValueAndValidity(recursiveCallOpts);
      }
      else if (formElement instanceof IczFormGroup || formElement instanceof IczFormArray) {
        formElement.recursivelyUpdateValueAndValidity(recursiveCallOpts);
      }
    }
  }

  this.updateValueAndValidity({onlySelf: true, ...(opts ?? {})});
}

/**
 * Given a FormGroup/FormArray, this function will recursively retrieve validation errors from all
 * constituent form controls and put them into a single list using mutation.
 */
export function recursivelyListInvalidControls(form: IczFormGroup|IczFormArray, list: InvalidFormControlInfo[]) {
  // depth-first traversal
  for (const key in form.controls) {
    if (form.controls.hasOwnProperty(key)) {
      const formElement = form.get(key);

      if (formElement instanceof IczFormControl && formElement.invalid) {
        list.push({controlName: key, value: formElement.value, errors: Object.keys(formElement.errors ?? {})});
      }
      else if (formElement instanceof IczFormGroup || formElement instanceof IczFormArray) {
        recursivelyListInvalidControls(formElement, list);
      }
    }
  }
}

/**
 * Method for recursively updating "touched" status of FormGroup/FormArray to TRUE.
 * @param opts Options for enabling/disabling status changes events
 */
function recursivelyMarkAsTouched(this: IczFormGroup|IczFormArray) {
  // depth-first traversal
  Object.values(this.controls).forEach(control => {
    control.markAsTouched();

    if (control instanceof IczFormGroup && control.controls) {
      control.markFormGroupTouched();
    }
    if (control instanceof IczFormArray && control.controls) {
      control.markFormArrayTouched();
    }
  });
}

/**
 * @internal
 */
function getMaxLengthValidator(maxLength: number) {
  return maxLength !== TextLength.UNLIMITED ? IczValidators.maxLength(maxLength) : null;
}

/**
 * A standard Angular FormGroup with our extensions:
 * - initial validators concept - used for quickly restoring an original set of validators at control declaration time after clearing them,
 * - helpers for mass-updating value and validity and touched control status,
 * - observable status changes
 */
export class IczFormGroup<TControl extends { [K in keyof TControl]: AbstractControl<any> } = any> extends FormGroup<TControl> {
  private initialValidators: Record<string, ValidatorFn> = {};

  constructor(
    formState?: TControl,
    validatorOrOpts?: Nullable<ValidatorFn | ValidatorFn[] | FormControlOptions>,
    asyncValidator?: Nullable<AsyncValidatorFn | AsyncValidatorFn[]>,
  ) {
    super(formState!, validatorOrOpts, asyncValidator);

    this.setInitialValidators();
  }

  /**
   * Marks all controls in this form group as touched.
   * @see recursivelyMarkAsTouched
   */
  markFormGroupTouched() {
    recursivelyMarkAsTouched.bind(this)();
  }

  /**
   * Updates value and validity for all controls in this group.
   * @see recursivelyUpdateValueAndValidity
   */
  recursivelyUpdateValueAndValidity(opts?: EmitEventOpts) {
    recursivelyUpdateValueAndValidity.bind(this)(opts);
  }

  /**
   * Resets validators
   */
  resetValidatorsToInitial() {
    for (const key in this.controls) {
      if (this.controls.hasOwnProperty(key)) {
        const formElement = this.get(key);
        if (formElement && this.initialValidators[key]) {
          formElement.validator = this.initialValidators[key];
        }
      }
    }
  }

  /**
   * Checks if all controls corresponding to keys in formControlNames are valid.
   */
  areFormControlsValid(formControlNames: Array<keyof TControl>) {
    let out = true;

    // todo(rb) fix those ts-ignores
    for (const formControlName of formControlNames) {
      // Disabled form controls are marked as invalid when calling FormControl.valid.
      // Assuming that disabled form controls are always valid bcs they could not accept user input.
      out &&= (
        // @ts-ignore
        this.controls[formControlName].status === 'VALID' ||
        // @ts-ignore
        this.controls[formControlName].status === 'DISABLED'
      );
    }

    return out;
  }

  /**
   * Overrides setValue method of Angular Form Group to prevent null error in setValue method.
   * When setValue is called on FormGroup angular it takes all controls names in form group and tries to find them on `value` (value[formControlName]).
   * This check fails in error when `value` is null.
   * todo(mh) fix the component that made trouble, this is a bit dirty
   */
  override setValue(value: ɵFormGroupRawValue<TControl>, options?: { onlySelf?: boolean; emitEvent?: boolean }) {
    if (!isNil(value)) {
      super.setValue(value, options);
    }
  }

  private setInitialValidators() {
    Object.entries(this.controls).forEach(([key, value]) => {
      this.initialValidators[key] = value.validator!;
    });
  }

  // IczControlFlagChangeListenable - duplicated across IczFormControl, IczFormGroup and IczFormArray.
  //  Keep them in sync pls.

  private _dirtinessChanged = new Subject<boolean>();
  private _touchChanged: Subject<boolean> = new Subject<boolean>();

  /**
   * @internal
   * @deprecated - will be removed after upgrade to Angular 18.
   */
  dirtinessChanged = this._dirtinessChanged.asObservable();
  /**
   * @internal
   * @deprecated - will be removed after upgrade to Angular 18.
   */
  touchChanged = this._touchChanged.asObservable();

  /**
   * Marks the control as touched and emits to touchChanged.
   */
  override markAsTouched(opts?: { onlySelf?: boolean }) {
    super.markAsTouched(opts);
    this._touchChanged.next(this.touched);
  }
  /**
   * Marks the control as untouched and emits to touchChanged.
   */
  override markAsUntouched(opts?: { onlySelf?: boolean }) {
    super.markAsUntouched(opts);
    this._touchChanged.next(this.touched);
  }
  /**
   * Marks the control as dirty and emits to dirtinessChanged.
   */
  override markAsDirty(opts?: { onlySelf?: boolean }) {
    super.markAsDirty(opts);
    this._dirtinessChanged.next(this.dirty);
  }
  /**
   * Marks the control as dirty and emits to dirtinessChanged.
   */
  override markAsPristine(opts?: { onlySelf?: boolean }) {
    super.markAsPristine(opts);
    this._dirtinessChanged.next(this.dirty);
  }

}

/**
 * A standard Angular FormControl with our extensions:
 * - importance flag - a visual distinguisher of controls which are recommended to be filled,
 * - observable status changes
 */
/** @ts-ignore */
export class IczFormControl<TValue = any> extends FormControl<TValue> { // todo(rb) remove that ts-ignore
  private maxLength: number;
  private _important: Nullable<boolean>;

  /**
   * Importance flag can be specified by declaring a boxed value with important:true in formState.
   */
  constructor(
    formState?: IczFormControlState<TValue> | TValue,
    validatorOrOpts?: Nullable<ValidatorFn | ValidatorFn[] | FormControlOptions>,
    asyncValidator?: Nullable<AsyncValidatorFn | AsyncValidatorFn[]>,
    maxValueLength?: TextLength,
  ) {
    const maxLength = (maxValueLength ?? TextLength.SHORT) as number;
    const maxLengthValidator: Nullable<IczValidatorFn> = getMaxLengthValidator(maxLength);

    if (Array.isArray(validatorOrOpts)) {
      if (maxLengthValidator) {
        validatorOrOpts.push(maxLengthValidator);
      }
    }
    else if (validatorOrOpts instanceof Function) {
      if (maxLengthValidator) {
        validatorOrOpts = [validatorOrOpts, maxLengthValidator];
      }
    }
    else if (validatorOrOpts?.validators) {
      if (!Array.isArray(validatorOrOpts.validators)) {
        if (maxLengthValidator) {
          validatorOrOpts.validators = [validatorOrOpts.validators];
        }
      }
      else if (maxLengthValidator) {
        validatorOrOpts.validators.push(maxLengthValidator);
      }
    }
    else if (maxLengthValidator) { // validatorOrOpts is falsy
      validatorOrOpts = maxLengthValidator;
    }

    super(formState!, validatorOrOpts, asyncValidator);

    // Extending ng forms _isBoxedValue() method:
    // If important is specified in formState, we don't want to use the config object as a value, we want to apply formState.value as value
    if (this._isBoxedValueWithImportant(formState)) {
      this._important = Boolean(formState?.important);
      super.setValue((formState as IczFormControlState<TValue>).value);
    }

    this.maxLength = maxLength;
  }

  /**
   * Returns if the control is marked as important.
   */
  get important() {
    return this._important;
  }

  // ngForms _isBoxedValue() internal method is already checking for value + disabled props of formState to be treated as config object
  private _isBoxedValueWithImportant(formState: Nullable<IczFormControlState<TValue> | TValue>): formState is IczFormControlState<TValue> {
    if (formState === null || formState === undefined || typeof formState !== 'object') return false;
    else {
      return (Object.keys(formState).length === 2 && 'value' in formState && 'important' in formState) ||
        (Object.keys(formState).length === 3 && 'value' in formState && 'important' in formState && 'disabled' in formState);
    }
  }

  /**
   * Works like original Angular FormControl.reset but restores "important flag" to the value it had at control declaration time.
   */
  override reset(formState: IczFormControlState<TValue> | TValue, options?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
  }) {
    if (this._isBoxedValueWithImportant(formState)) {
      this._important = Boolean((formState as any)?.important);
    }
    super.reset(formState as FormControlState<TValue>, options);
  }

  /**
   * Works like original Angular FormControl.setValidators but
   * automagically sets predefined validation for text length.
   */
  override setValidators(newValidator: Nullable<ValidatorFn | ValidatorFn[]>): void {
    const maxLengthValidator: Nullable<IczValidatorFn> = getMaxLengthValidator(this.maxLength);

    if (newValidator) {
      if (!Array.isArray(newValidator)) {
        newValidator = [newValidator];
      }

      if (maxLengthValidator) {
        newValidator.push(maxLengthValidator);
      }

      super.setValidators(newValidator);
    }
    else if (maxLengthValidator) {
      super.setValidators(maxLengthValidator);
    }
  }

  // IczControlFlagChangeListenable - duplicated across IczFormControl, IczFormGroup and IczFormArray.
  //  Keep them in sync pls.

  private _dirtinessChanged = new Subject<boolean>();
  private _touchChanged: Subject<boolean> = new Subject<boolean>();

  /**
   * @internal
   * @deprecated - will be removed after upgrade to Angular 18.
   */
  dirtinessChanged = this._dirtinessChanged.asObservable();
  /**
   * @internal
   * @deprecated - will be removed after upgrade to Angular 18.
   */
  touchChanged = this._touchChanged.asObservable();

  /**
   * Marks the control as touched and emits to touchChanged.
   */
  override markAsTouched(opts?: { onlySelf?: boolean }) {
    super.markAsTouched(opts);
    this._touchChanged.next(this.touched);
  }
  /**
   * Marks the control as untouched and emits to touchChanged.
   */
  override markAsUntouched(opts?: { onlySelf?: boolean }) {
    super.markAsUntouched(opts);
    this._touchChanged.next(this.touched);
  }
  /**
   * Marks the control as dirty and emits to dirtinessChanged.
   */
  override markAsDirty(opts?: { onlySelf?: boolean }) {
    super.markAsDirty(opts);
    this._dirtinessChanged.next(this.dirty);
  }
  /**
   * Marks the control as dirty and emits to dirtinessChanged.
   */
  override markAsPristine(opts?: { onlySelf?: boolean }) {
    super.markAsPristine(opts);
    this._dirtinessChanged.next(this.dirty);
  }

}

/**
 * A function which is used to construct a single IczFormArray item slot.
 * Future index of the slot is supplied to the function while the function returns an AbstractControl (usually an IczFormGroup).
 */
export type ControlFactory<TControl> = (newControlIndex: number) => TControl;

/**
 * A standard Angular FormArray with our extensions:
 * - helpers for sizing the array at runtime,
 * - helpers for mass-updating value and validity and touched control status,
 * - observable status changes
 */
export class IczFormArray<TControl extends AbstractControl<any> = any> extends FormArray<TControl> {
  /**
   * Declares a form array capable of holding repeated homogenous data structures.
   * @param createControl - single array element factory
   * @param controls  - should be empty in most cases as sizing of the array happens when setting value
   * @param rest - other args delegated to Angular superclass
   */
  constructor(public createControl: ControlFactory<TControl>, controls: Array<TControl>, ...rest: any[]) {
    super(controls, ...rest);
  }

  /**
   * Auto-sizes the form array slots to accommodate the new value.
   */
  override setValue(value: ɵFormArrayRawValue<TControl>, options: {onlySelf?: boolean, emitEvent?: boolean} = {}): void {
    this.setSize(value.length);
    super.setValue(value, options);
  }

  /**
   * Sets specified number of controls in the array
   * @param size of the array
   * @param startFromLast whether to remove beginning from the end of array
   */
  setSize(size: number, startFromLast = false) {
    while (size < this.controls.length) this.removeAt(startFromLast ? this.controls.length - 1 : 0);
    while (size > this.controls.length) this.push(this.createControl(this.controls.length));
  }

  insertAtIndex(index: number): TControl {
    this.insert(index, this.createControl(index));
    return this.at(index) as TControl;
  }

  /**
   * Increments IczFormArray controls by calling supplied form builder method.
   * @returns {AbstractControl} control created by calling form builder method
   */
  incrementSize(): TControl {
    const control = this.createControl(this.controls.length);
    this.push(control);
    return control;
  }

  /**
   * Gets last control.
   */
  getLastControl(): Nullable<TControl> {
    return this.controls.length > 0 ? this.controls[this.controls.length - 1] as TControl : null;
  }

  /**
   * Pops last controls from the array.
   */
  removeLastControl() {
    this.setSize(this.controls.length - 1, true);
  }

  /**
   * Updates value and validity for all controls in this group.
   * @see recursivelyUpdateValueAndValidity
   */
  recursivelyUpdateValueAndValidity(opts?: EmitEventOpts) {
    recursivelyUpdateValueAndValidity.bind(this)(opts);
  }

  /**
   * Marks all controls in this form group as touched.
   * @see recursivelyMarkAsTouched
   */
  markFormArrayTouched() {
    recursivelyMarkAsTouched.bind(this)();
  }

  // IczControlFlagChangeListenable - duplicated across IczFormControl, IczFormGroup and IczFormArray.
  //  Keep them in sync pls.

  private _dirtinessChanged = new Subject<boolean>();
  private _touchChanged: Subject<boolean> = new Subject<boolean>();

  /**
   * @internal
   * @deprecated - will be removed after upgrade to Angular 18.
   */
  dirtinessChanged = this._dirtinessChanged.asObservable();
  /**
   * @internal
   * @deprecated - will be removed after upgrade to Angular 18.
   */
  touchChanged = this._touchChanged.asObservable();

  /**
   * Marks the control as touched and emits to touchChanged.
   */
  override markAsTouched(opts?: { onlySelf?: boolean }) {
    super.markAsTouched(opts);
    this._touchChanged.next(this.touched);
  }
  /**
   * Marks the control as untouched and emits to touchChanged.
   */
  override markAsUntouched(opts?: { onlySelf?: boolean }) {
    super.markAsUntouched(opts);
    this._touchChanged.next(this.touched);
  }
  /**
   * Marks the control as dirty and emits to dirtinessChanged.
   */
  override markAsDirty(opts?: { onlySelf?: boolean }) {
    super.markAsDirty(opts);
    this._dirtinessChanged.next(this.dirty);
  }
  /**
   * Marks the control as dirty and emits to dirtinessChanged.
   */
  override markAsPristine(opts?: { onlySelf?: boolean }) {
    super.markAsPristine(opts);
    this._dirtinessChanged.next(this.dirty);
  }

}
