import {Component, inject, Input, OnInit} from '@angular/core';
import {DocumentState, FileState, RelatedObjectType} from '|api/commons';
import {extendDefaultTableConfig, FilterType, IczTableDataSource, TableColumnsData} from '@icz/angular-table';
import {enumToOptions} from '../../../../core/services/data-mapping.utils';
import {ApiElasticsearchService} from '|api/elastic';
import {SubjectRecordUsageDto} from '../../../../../../../api/elastic/src/lib/models/subject-record-usage-dto';
import {GenericSearchService} from '../../../../services/search-api.service';
import {CORE_MICROSERVICE_ROOT} from '../../../../api';
import {IczSimpleTableDataSource} from 'libs/shared/src/lib/services';

@Component({
  selector: 'icz-subject-usage-report',
  templateUrl: './subject-usage-report.component.html',
  styleUrls: ['./subject-usage-report.component.scss'],
})
export class SubjectUsageReportComponent implements OnInit {

  private genericSearchService = inject(GenericSearchService);

  @Input({required: true}) subjectId!: number;

  readonly RelatedObjectType = RelatedObjectType;

  dataSource!: IczTableDataSource<SubjectRecordUsageDto>;

  tableConfig = extendDefaultTableConfig({
    toolbarConfig: {
      autoOpenFilter: false,
      filterIsStatic: false,
      showReload: true,
      showFilter: false,
      showToolbar: true,
    }
  });

  columnsData = new TableColumnsData<keyof SubjectRecordUsageDto>([
    {id: 'relatedObjectType', label: 'Druh objektu', filterType: FilterType.ENUM, disableSort: true},
    {id: 'refNumber', label: 'Název', filterType: FilterType.NONE, disableSort: true},
    {id: 'createdAt', label: 'Datum přidání subjektu', filterType: FilterType.DATETIME, disableSort: true},
    {
      id: 'state',
      label: 'Stav',
      filterType: FilterType.ENUM,
      disableSort: true,
      list: enumToOptions('documentState', DocumentState).concat(enumToOptions('fileState', FileState)),
    },
  ]);

  ngOnInit(): void {
    if (!this.subjectId) return;

    this.dataSource = new IczSimpleTableDataSource<SubjectRecordUsageDto>(this.genericSearchService, {
      url: ApiElasticsearchService.ElasticsearchGetSubjectUsagePath,
      microServiceRoot: CORE_MICROSERVICE_ROOT,
      staticPathParams: {subjectId: String(this.subjectId!)}
    });
  }

}
