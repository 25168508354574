<div class="w-full h-full">
  @if (activeRow && !isRegistryOfficeTransferView) {
    <icz-document-file-sidebar
      [object]="activeRow"
      [opened]="openQuickPreview && isQuickPreviewOpen"
      (closed)="iczTable.unsetActiveRow()"
    ></icz-document-file-sidebar>
  }

  @if (activeRow && !isRegistryOfficeTransferView) {
    <icz-constrained-document-file-sidebar
      [document]="activeRow"
      [opened]="openQuickPreview && isQuickConstrainedPreviewOpen"
      [searchTerms]="[_searchTerm]"
      (closed)="iczTable.unsetActiveRow()"
    ></icz-constrained-document-file-sidebar>
  }

  @if (_viewType && columnsData) {
    <icz-table
      #iczTable
      class="grow"
      [id]="tableId"
      [dataSource]="dataSource"
      [columnsData]="columnsData"
      [config]="config"
      [searchTerm]="_searchTerm"
      [enabledFilters]="enabledFilters"
      [enableGlobalSelectAll]="viewType === DocumentView.OWNERSHIP_TRANSFER || viewType === DocumentView.STORAGE_UNIT_FILLER_SELECTION_FILTERS"
      [allowSavingFiltersWithEmptyValues]="viewType !== DocumentView.GLOBAL_SEARCH"
      [rowKeyFn]="gracefulElasticRowKeyFn"
      [preselectedRows]="preselectedObjects"
      (initialized)="afterTableInitialized($any($event))"
      (searchTermChange)="searchTermChanged($event)"
      (pageLoadStarted)="onPageLoadStarted($event)"
      (activeRowChanged)="setActiveRow($event)"
      (selectedRowsChanged)="setSelectedRows($event)"
      (activeFiltersChanged)="activeFiltersChanged($event)"
      [rowSelectionDisabler]="rowDisabler"
      [rowDisabler]="rowDisabler"
      (searchParamsSelected)="searchParamsChanged($event)"
      (editQueryRequested)="editQueryRequested.emit()"
      [hideTableToolbar]="hideTableToolbar"
      >
      @if (!hideTableToolbarButtons) {
        <icz-document-toolbar-buttons
          tools
          [selectedRows]="selectedRows"
          [view]="_viewType"
          [isUnitView]="isUnitView"
          (operationCompleted)="unselectAndReload()"
          [storageUnitFillerId]="storageUnitFillerId"
        ></icz-document-toolbar-buttons>
      }
      <ng-content select="[tableTitle]" tableTitle></ng-content>
      <ng-content select="[tableTabs]" tableTabs></ng-content>
      <ng-content select="[noDataLabel]" noDataLabel></ng-content>

      <div beforeToolbar class="row grow justify-end">
        <ng-content select="[beforeToolbar]"></ng-content>
      </div>

      <icz-label notFoundLabel label="Žádné dokumenty nebyly nalezeny"></icz-label>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.OBJECT_CLASS">
        <icz-table-entity-icon
          [documentsTableEntity]="row"
          [view]="viewType"
        ></icz-table-entity-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.OBJECT_CLASS_FULL; withEllipsis: true">
        <span>{{ row.objectClass | findInList:objectClassOptions | translate }}</span>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.HAS_INCIDENTS; withEllipsis: true">
        <icz-quick-incidents-list-icon [entity]="row"></icz-quick-incidents-list-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.IS_CONSTRAINED_VIEW; let column = column">
        <!--      uncomment when shareInfo is added to document-->
        <!--      <icz-icon svgIcon="addUser" [defaultIconColor]="true" *ngIf="row.shareInfo else notShared"></icz-icon>
        <ng-template #notShared>
          <icz-icon iczTooltip="Je dostupný pouze profil dokumentu" *ngIf="isOnlyConstrainedPreviewAvailable(row)" size="small" svgIcon="eye"></icz-icon>
        </ng-template>-->
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS; withEllipsis: true">
        @if (row.holderFunctionalPositionIds | findFunctionalPositionById | async; as holders) {
          <span [iczTooltip]="holders">
            {{ holders }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.REPRESENTING_SUBJECT; withEllipsis: true">
        @if (row.representingSubject; as representingSubject) {
          <span [iczTooltip]="representingSubject">
            {{ representingSubject }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID; withEllipsis: true">
        @if (row.ownerFunctionalPositionId | findFunctionalPositionById | async; as owner) {
          <span [iczTooltip]="owner">
            {{ owner }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.CREATED_BY; withEllipsis: true">
        @if (row.auditInfo.createdByFunctionalPositionId | findFunctionalPositionById | async; as createdBy) {
          <span [iczTooltip]="createdBy">
            {{ createdBy }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.MODIFIED_BY; withEllipsis: true">
        @if (row.auditInfo.modifiedByFunctionalPositionId | findFunctionalPositionById | async; as modifiedBy) {
          <span [iczTooltip]="modifiedBy">
            {{ modifiedBy }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.TRIGGER_EVENT_CHECK_YEAR; withEllipsis: true">
        @if (row.triggerEventCheckYear) {
          <span [ngClass]="{'icz-error': isTriggerEventCheckYearInvalid(row.triggerEventCheckYear)}">
            {{ row.triggerEventCheckYear }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.RELEVANCE; let column = column">
        <div class="row gap-0">
          <div class="pt-8 relevance-percentage" cdkOverlayOrigin #relevancePopupOrigin="cdkOverlayOrigin">
            {{ row.relevance.toFixed(0) + ' %' }}
          </div>
          <icz-button
            background="transparent" disableRipple
            [iczTooltip]="row.highlights?.length ? null : 'Žádné údaje o místě nalezení k dispozici'"
            svgIcon="more" [disabled]="!row.highlights?.length"
            (onAction)="relevanceDetailsClick($event, row)"
          ></icz-button>
          <icz-popover
            [overlayOrigin]="relevancePopupOrigin"
            [isOpen]="currentEntityForRelevanceDetailView === row"
            (onClose)="currentEntityForRelevanceDetailView = null;"
            >
            <icz-document-relevance-details class="mx-16" [highlights]="row.highlights" [columnNames]="columnNames"> </icz-document-relevance-details>
          </icz-popover>
        </div>
      </ng-container>
      <!-- If using withEllipsis: true for *iczColumnTemplate, container, template contents must be wrapped inside a <span> element. -->
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.REF_NUMBER; withEllipsis: true; let column = column">
        @if (viewType !== DocumentView.DOCUMENT_SELECTOR && viewType !== DocumentView.OWNERSHIP_TRANSFER && viewType !== DocumentView.STORAGE_UNIT_INSERT) {
          <span class="ref-number-progress-indicator-wrapper" [class.is-locked]="row.lockedState">
            <icz-static-progress-indicator class="ref-number-progress-indicator"></icz-static-progress-indicator>
            <icz-ref-number-link
              [entity]="row"
              [columnDefinition]="column"
              [searchTermWords]="searchTermWords"
              [view]="viewType!"
              (detailOpened)="iczTable.setFocusedRow(row)"
            ></icz-ref-number-link>
          </span>
        }
        @else {
          @if (row.refNumber; as refNumber) {
            <span class="ref-number-progress-indicator-wrapper" [class.is-locked]="row.lockedState" [iczTooltip]="refNumber">
              <icz-static-progress-indicator class="ref-number-progress-indicator"></icz-static-progress-indicator>
              {{ refNumber }}
            </span>
          }
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.STORAGE_UNIT_NUMBER; withEllipsis: true; let column = column">
        @if (viewType !== StorageUnitView.STORAGE_UNIT_INSERT_DETAIL) {
          <span>
            <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref (click.stop)="openStorageUnitDetail(row)">
              {{ row.storageUnitNumber }}
            </a>
          </span>
        }
        @else {
          <span>
            {{ row.storageUnitNumber }}
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.SUBJECT; withEllipsis: true; let column = column">
        <span>
          @if (row.subject; as subject) {
            <icz-highlightable-text-content
              [fieldId]="column.id"
              [originalValue]="row.subject"
              [searchTermWords]="searchTermWords"
              [highlights]="row.highlights">
            </icz-highlightable-text-content>
          }
        </span>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.DESCRIPTION; withEllipsis: true; let column = column">
        <span>
          @if (row.description; as description) {
            <icz-highlightable-text-content
              [fieldId]="column.id"
              [originalValue]="row.description"
              [searchTermWords]="searchTermWords"
              [highlights]="row.highlights">
            </icz-highlightable-text-content>
          }
        </span>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.ENTITY_STATE; let column = column;">
        @if (row.entityState || row.documentState) {
          @if (row.entityState && !row.documentState) {
            <icz-tag [color]="getObjectTagColor(row)" [inline]="true" [label]="row.entityState | findInList:column.list! | translate"></icz-tag>
          }
          @if (row.documentState) {
            <icz-tag [color]="getObjectTagColor(row)" [inline]="true" [label]="row.documentState | findInList:column.list! | translate"></icz-tag>
          }
          @if (row.storageUnitState) {
            <icz-tag [color]="getObjectTagColor(row)" [inline]="true" [label]="row.storageUnitState | findInList:column.list! | translate"></icz-tag>
          }
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.RESOLUTION_DATE; withEllipsis: true">
        <span [iczPassedDeadlineWarning]="row.resolutionDate" [iczTooltip]="row.resolutionDate | localizedDate">
          {{ row.resolutionDate | localizedDate }}
        </span>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.IDENTIFIER; withEllipsis: true; let column = column">
        <icz-highlightable-text-content
          [fieldId]="column.id"
          [originalValue]="row.uid?.uid"
          [searchTermWords]="searchTermWords"
          [highlights]="$any(row).highlights">
        </icz-highlightable-text-content>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.OBJECT_FORM; withEllipsis: true; let column = column">
        @if (isDocumentObject(row)) {
          @if (row.documentForm | findInList:column.list! | translate; as documentForm) {
            <span [iczTooltip]="documentForm">{{ documentForm }}</span>
          }
        }
        @if (isFileObject(row)) {
          @if (row.fileForm | findInList:column.list! | translate; as fileForm) {
            <span [iczTooltip]="fileForm">{{ fileForm }}</span>
          }
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.EMPOWERMENT; withEllipsis: true">
        @if (row.empowerment | empowerment; as empowerment) {
          <span [iczTooltip]="empowerment">{{ empowerment }}</span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.HANDOVER_ACTIVITY_ID; let column = column">
        @if (row.handoverActivity) {
          <icz-task-activity-state-icon [activity]="row.handoverActivity"></icz-task-activity-state-icon>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.HANDOVER_ACTIVITY_TARGET_FP_ID; withEllipsis: true; let column = column">
        @if (row.handoverActivity?.targetParticipants?.length) {
          <span>
            @if (getActivityTargetParticipantIds(row.handoverActivity) | findFunctionalPositionById | async; as targetParticipants) {
              <span [iczTooltip]="targetParticipants">
                {{ targetParticipants }}
              </span>
            }
          </span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.HANDOVER_ACTIVITY_REJECT_REASON; withEllipsis: true; let column = column">
        @if (row.handoverActivity) {
          <icz-activity-notes [activity]="row.handoverActivity"> </icz-activity-notes>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.ISSD_TASK; let column = column">
        <icz-issd-activity-state-icon
          class="visible-clickable-area"
          [entityId]="row.id"
          [issdAppsOptions]="issdAppsOptions"
          [entityType]="row.entityType"
        ></icz-issd-activity-state-icon>
      </ng-container>
      <!--    Filing office column-->
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID; withEllipsis: true; let column = column">
        @if (row.consignments?.[0].deliveryTypeId | findInList:column.list!; as deliveryType) {
          <span [iczTooltip]="deliveryType">{{ deliveryType }}</span>
        }
      </ng-container>
      <!--    Filing office column-->
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER; withEllipsis: true; let column = column">
        @if (row.consignments?.[0].consignmentOriginalSender; as consignmentOriginalSender) {
          <span [iczTooltip]="consignmentOriginalSender">{{ consignmentOriginalSender }}</span>
        }
      </ng-container>
      <!--    Filing office column-->
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.CONSIGNMENT_SUBJECT_RECORD; withEllipsis: true">
        @if (row.sender) {
          <div class="row items-center">
            <icz-subject-identification-status-icon
              [subject]="row.sender"
              size="small"
            ></icz-subject-identification-status-icon>
            <span>{{row.sender | subjectName:true}}</span>
          </div>
        }
      </ng-container>
      <!--    Filing office column-->
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.CONSIGNMENT_DELIVERY_DATE; withEllipsis: true; let column = column">
        @if (row.consignments?.[0].deliveryDate | localizedDatetime; as deliveryDatetime) {
          <span [iczTooltip]="deliveryDatetime">{{ deliveryDatetime }}</span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.CONSIGNMENT_EXTERNAL_ID; withEllipsis: true; let column = column">
        @if (row.consignments?.[0].externalId; as externalId) {
          <span [iczTooltip]="externalId">{{ externalId }}</span>
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.STORAGE_UNIT_CONTENT_BLOCKED; withEllipsis: true; let column = column">
        <icz-icon [svgIcon]="getStorageUnitBlockedIcon(row)" size="small"></icz-icon>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.STORAGE_UNIT_FILLER_ID; withEllipsis: true; let column = column">
        <span>
            <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref (click.stop)="openStorageUnitFillerDetailSettingsTab(row)">
            {{ row.id }}
          </a>
        </span>
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.STORAGE_UNIT_FILLER_REJECTED_ENTITIES; withEllipsis: true; let column = column">
        @if (row.statistics?.entitiesRejectedCount > 0) {
        <span>
            <a class="icz-body-1 visible-clickable-area p-4" iczLinkWithoutHref (click.stop)="openStorageUnitFillerDetailErroredEntities(row)">
            {{ row.statistics?.entitiesRejectedCount }}
          </a>
        </span>
        } @else {
          {{ row.statistics?.entitiesRejectedCount }}
        }
      </ng-container>
      <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: DocumentsTableColumn.STORAGE_UNIT_FILLER_HUMAN_READABLE_FILTER; withEllipsis: true; let column = column">
        <span class="icz-body-1 mt-4" [innerHTML]="row.humanReadableFilter"></span>
      </ng-container>

    </icz-table>
  }
</div>
