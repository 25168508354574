import {Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {
  DistributionClass, RelatedObjectDto,
  RelatedObjectType,
  SubjectObjectRelationFindDto,
  SubjectObjectRelationType,
  SubjectRecordDto,
  SubjectRecordFindMode
} from '|api/commons';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {IczInMemoryDatasource} from '@icz/angular-table';
import {SubjectAndAddress, SubjectRecordSource, SubjectRecordWithSourceDto} from '../../../model/subjects.model';
import {CreateNewConsignmentDialogType} from '../../../own-consignment-table/model/own-consignment-model';
import {
  SubjectSearchWithResultsComponent
} from '../../../subjects/add-subject/subject-search-with-results/subject-search-with-results.component';
import {SubjectsTableAction, SubjectsTableActionCommand} from '../../../subjects/subjects-table/subjects-table-actions';
import {
  TABLE_ACTION_DELIVERY_TYPE_CLASSES
} from '../../../subjects/subjects-table/subjects-table-consignment-actions/subjects-table-consignment-actions.component';
import {SubjectTableColumnSet} from '../../../subjects/subjects-table/subjects-table/subjects-table.component';
import {ConsignmentDocumentData} from '../abstract-consignment-dialog-data';
import {DocumentDetailCountType, DocumentDetailService} from '../../../../../services/document-detail.service';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {SubjectsService} from '../../../../../services/subjects.service';
import {ScrollingService} from '@icz/angular-form-elements';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SubjectToolbarContext} from '../../../subjects/subjects-toolbar/subjects-toolbar.component';

export interface DistributionClassDispatchSpecifier {
  distributionClass: Nullable<DistributionClass>;
  subject?: SubjectRecordDto;
  publicPosting: boolean;
}

export enum ConsigneeSelectionMode {
  CONSIGNEE_SEARCH = 'CONSIGNEE_SEARCH',
  DOCUMENT_SUBJECTS = 'DOCUMENT_SUBJECTS',
  FILE_SUBJECTS = 'FILE_SUBJECTS',
  DISTRIBUTION_LIST = 'DISTRIBUTION_LIST',
  OFFICE_DESK_PUBLIC_POSTING = 'OFFICE_DESK_PUBLIC_POSTING',
}

@Component({
  selector: 'icz-consignment-consignee-selection',
  templateUrl: './consignment-consignee-selection.component.html',
  styleUrls: ['./consignment-consignee-selection.component.scss'],
})
export class ConsignmentConsigneeSelectionComponent implements OnInit, IczOnChanges {

  private destroyRef = inject(DestroyRef);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private subjectsService = inject(SubjectsService);
  private scrollingService = inject(ScrollingService);
  private elementRef = inject(ElementRef);
  private documentDetailService = inject(DocumentDetailService, {optional: true});

  @ViewChild('subjectSearch')
  subjectSearchWithResultsComponent!: SubjectSearchWithResultsComponent;

  @Input({required: true})
  documentData!: ConsignmentDocumentData;
  @Output()
  consigneeCreationRequested = new EventEmitter<Record<string, any>>();
  @Output()
  initialized = new EventEmitter<this>();
  @Output()
  distributionClassDispatchSelected = new EventEmitter<DistributionClassDispatchSpecifier>();
  @Output()
  consigneeAddressChanged = new EventEmitter<SubjectAndAddress>();
  @Input({required: true})
  createNewConsignmentDialogType!: CreateNewConsignmentDialogType;

  documentSubjectsDataSource: Nullable<IczInMemoryDatasource>;
  fileSubjectsDataSource: Nullable<IczInMemoryDatasource>;

  consigneeSelectionMode = ConsigneeSelectionMode.CONSIGNEE_SEARCH;

  loading = false;
  CreateNewConsignmentDialogType = CreateNewConsignmentDialogType;
  documentSubjectsCount = 0;
  fileSubjectsCount = 0;

  get documentSubjectsCheckboxTitle(): string {
    return this.translateParser.interpolate(
      this.translateService.instant(
        'Vybrat ze subjektů dokumentu ({{count}})'
      ) ?? '',
      {count: this.documentSubjectsCount}
    ) ?? '';
  }

  get fileSubjectsCheckboxTitle(): string {
    return this.translateParser.interpolate(
      this.translateService.instant(
        'Vybrat ze subjektů spisu ({{count}})'
      ) ?? '',
      {count: this.fileSubjectsCount}
    ) ?? '';
  }
  readonly ConsigneeSelectionMode = ConsigneeSelectionMode;
  readonly SubjectTableColumnSet = SubjectTableColumnSet;
  readonly SubjectObjectRelationType = SubjectObjectRelationType;
  readonly loaderIdIsdsFind = 'ISDS_FIND';

  ngOnInit(): void {
    this.getDocumentSubjectsCount();
    this.getFileSubjectsCount();
    this.initialized.emit(this);
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.createNewConsignmentDialogType?.currentValue) {
      if (this.createNewConsignmentDialogType === CreateNewConsignmentDialogType.OFFICE_DESK_ONLY) {
        this.consigneeSelectionMode = ConsigneeSelectionMode.OFFICE_DESK_PUBLIC_POSTING;
      }
    }
  }

  dataSourceReloaded() {
    this.setDataSources();
  }

  get isSearchFormEmpty() {
    return this.subjectsService.isSearchFormEmpty(this.subjectSearchWithResultsComponent.form);
  }

  searchSubjects(findMode: SubjectRecordFindMode) {
    if (!this.isSearchFormEmpty) {
      this.subjectSearchWithResultsComponent.searchSubjectsByKeys(false, findMode);
    }
  }

  private distributionClassDispatch(action: SubjectsTableAction, subject: SubjectRecordWithSourceDto) {
    switch (action) {
      case SubjectsTableAction.DISPATCH_USING_ISDS:
      case SubjectsTableAction.DISPATCH_USING_PAPER:
      case SubjectsTableAction.DISPATCH_USING_EMAIL:
      case SubjectsTableAction.DISPATCH_USING_IN_PERSON:
      case SubjectsTableAction.POST_ON_OFFICE_DESK:
        this.distributionClassDispatchSelected.emit({
          distributionClass: TABLE_ACTION_DELIVERY_TYPE_CLASSES[action]!,
          subject,
          publicPosting: false,
        });
        break;
    }
  }

  tableActionExecuted(command: SubjectsTableActionCommand) {
    if (command.subject.subjectSource === SubjectRecordSource.ISDS_SEARCH) {
      this.scrollToTop();
      this.loadingIndicatorService.doLoading(
        this.subjectsService.findUsingIsdsFind(command.subject).pipe(takeUntilDestroyed(this.destroyRef)),
        this,
        this.loaderIdIsdsFind,
      ).subscribe(subjectFromIsdsFind => {
        if (subjectFromIsdsFind) {
          this.distributionClassDispatch(command.action, subjectFromIsdsFind);
        }
      });
    } else {
      this.distributionClassDispatch(command.action, command.subject);
    }
  }

  private emitResetDistributionClass() {
    this.distributionClassDispatchSelected.emit({
      distributionClass: null,
      publicPosting: false,
    });
  }

  consigneeSelectionModeChanged(consigneeSelectionMode: ConsigneeSelectionMode) {
    if (consigneeSelectionMode === ConsigneeSelectionMode.DOCUMENT_SUBJECTS ||
      consigneeSelectionMode === ConsigneeSelectionMode.FILE_SUBJECTS) {
      this.setDataSources();
    }
    if (this.consigneeSelectionMode === ConsigneeSelectionMode.OFFICE_DESK_PUBLIC_POSTING &&
      this.consigneeSelectionMode !== consigneeSelectionMode) {
      this.emitResetDistributionClass();
    }

    this.consigneeSelectionMode = consigneeSelectionMode;

    if (consigneeSelectionMode === ConsigneeSelectionMode.OFFICE_DESK_PUBLIC_POSTING) {
      this.distributionClassDispatchSelected.emit({
        distributionClass: TABLE_ACTION_DELIVERY_TYPE_CLASSES.POST_ON_OFFICE_DESK!,
        publicPosting: true,
      });
    }
  }

  private getDocumentSubjectsCount() {
    if (this.documentDetailService) {
      this.documentSubjectsCount = this.documentDetailService.counts.getCount(DocumentDetailCountType.SUBJECTS);
    }
  }

  private getFileSubjectsCount() {
    if (this.documentData.parentFileId) {
      const related: RelatedObjectDto = {
        relatedObjectId: this.documentData.parentFileId,
        relatedObjectType: RelatedObjectType.FILE
      };

      this.apiSubjectRecordNgElasticService.subjectRecordElasticGetRelatedSubjectCount({body: related}).subscribe(count => {
        this.fileSubjectsCount = count;
      });
    }
  }

  private scrollToTop() {
    setTimeout(() => {
      this.scrollingService.scrollTo(this.elementRef.nativeElement);
    }, 0);
  }

  private setDataSources() {
    if (!this.documentSubjectsDataSource || !this.fileSubjectsDataSource) {
      this.loading = true;
    }

    const relatedForDocument: SubjectObjectRelationFindDto = {
      relatedObjectIds: [this.documentData.id],
      relatedObjectType: RelatedObjectType.DOCUMENT,
    };
    this.apiSubjectRecordNgElasticService.subjectRecordElasticElasticFindSubjectsByRelations({body: relatedForDocument}).subscribe(subjects => {
        this.documentSubjectsDataSource = new IczInMemoryDatasource(() => []);
        this.documentSubjectsDataSource.setDataFactory(
          () => subjects ?? []
        );
        this.loading = false;
    });

    if (this.documentData.parentFileId) {
      const relatedForFile: SubjectObjectRelationFindDto = {
        relatedObjectIds: [this.documentData.parentFileId],
        relatedObjectType: RelatedObjectType.FILE,
      };
      this.apiSubjectRecordNgElasticService.subjectRecordElasticElasticFindSubjectsByRelations({body: relatedForFile}).subscribe(subjects => {
        this.fileSubjectsDataSource = new IczInMemoryDatasource(() => []);
        this.fileSubjectsDataSource.setDataFactory(
          () => subjects ?? []
        );
        this.loading = false;
      });
    }
  }

	protected readonly SubjectToolbarContext = SubjectToolbarContext;
  protected readonly RelatedObjectType = RelatedObjectType;
}
