export enum ActionRoute {
  NEW = 'new',
  EDIT = 'edit',
  GROUP = 'group',
  IMPORT = 'import',
}

export enum DynamicRoutePlaceholder {
  /**
   * A meta placeholder which is used when we don't care about the contents of
   * a particular routing segment but we want it fully dynamic.
   */
  ANYTHING = ':anything',
  GROUP = ':group',
  UUID = ':uuid',
  ID = ':id',
}


export enum ApplicationRoute {
  ROOT = '/',
  WAITING_TO_BE = 'waiting-to-be',
  NETWORK_UNAVAILABLE = 'network-unavailable',
  APPLICATION_UNAVAILABLE = 'application-unavailable',
  DEMO = 'demo',
  LOGIN = 'login',
  FILING_OFFICE = 'filing-office',
  DISPATCH = 'dispatch',
  UNIT = 'unit',
  PLACE_SELECT = 'place-select',
  DOCUMENTS = 'documents',
  OFFICE_DESK = 'office-desk',
  ADMIN = 'admin',
  CONFIG = 'config',
  REGISTRY_OFFICE = 'registry-office',
  SHARED_FOLDERS = 'shared-folders',
  AGENDA_TRANSFER = 'agenda-transfer',
}

export enum SharedRoute {
  NOT_IMPLEMENTED = 'not-implemented',
}

export enum LandingPage {
  DASHBOARD = 'DASHBOARD',
  LAST_USED_PAGE = 'LAST_USED_PAGE'
}
