/* eslint-disable @angular-eslint/directive-class-suffix */
import {AfterViewInit, Directive, TemplateRef} from '@angular/core';

/**
 * An utility type which extracts all the ViewChild ng-template references from a component type
 * into a key-value map where key is template reference property name and value is TemplateRef.
 */
export type TemplatePool<P> = {[K in keyof P]: P[K] extends TemplateRef<any> ? P[K] : never};

/**
 * An abstraction of a collection of reusable ng-templates to be used
 * in conjunction with TemplatePoolService.
 * @see TemplatePoolService
 */
@Directive()
export abstract class AbstractTemplateCollection implements AfterViewInit {

  /**
   * Reference to all the ng-templates placed into component's template.
   * Note that the templates must be referenced in component controller
   * using ViewChild and must have suffix "template" in their property name.
   */
  // @ts-ignore
  pool: TemplatePool<this> = {};

  /**
   * Initialization occurs here and not in the constructor
   * because `this` must also contain properties of eventual subclass.
   * @internal
   */
  ngAfterViewInit() {
    for (const propertyName of Object.keys(this)) {
      if (propertyName.toLowerCase().includes('template')) {
         // @ts-ignore
        this.pool[propertyName] = this[propertyName as keyof this];
      }
    }
  }

}
