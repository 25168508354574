/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { StorageUnitFillerConfigurationDto } from '../models/storage-unit-filler-configuration-dto';
import { storageUnitFillerConfigurationOauthGet } from '../fn/storage-unit-filler-configuration-oauth/storage-unit-filler-configuration-oauth-get';
import { StorageUnitFillerConfigurationOauthGet$Params } from '../fn/storage-unit-filler-configuration-oauth/storage-unit-filler-configuration-oauth-get';
import { storageUnitFillerConfigurationOauthUpdate } from '../fn/storage-unit-filler-configuration-oauth/storage-unit-filler-configuration-oauth-update';
import { StorageUnitFillerConfigurationOauthUpdate$Params } from '../fn/storage-unit-filler-configuration-oauth/storage-unit-filler-configuration-oauth-update';


/**
 * API for management of storage unit filler configuration.
 */
@Injectable({ providedIn: 'root' })
export class ApiStorageUnitFillerConfigurationOauthService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `storageUnitFillerConfigurationOauthGet()` */
  static readonly StorageUnitFillerConfigurationOauthGetPath = '/oauth/storage-unit-filler-configuration';

  /**
   * Get storage unit filler configuration instance.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerConfigurationOauthGet()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerConfigurationOauthGet$Response(params?: StorageUnitFillerConfigurationOauthGet$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitFillerConfigurationDto>> {
    return storageUnitFillerConfigurationOauthGet(this.http, this.rootUrl, params, context);
  }

  /**
   * Get storage unit filler configuration instance.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerConfigurationOauthGet$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerConfigurationOauthGet(params?: StorageUnitFillerConfigurationOauthGet$Params|null|undefined, context?: HttpContext): Observable<StorageUnitFillerConfigurationDto> {
    return this.storageUnitFillerConfigurationOauthGet$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitFillerConfigurationDto>): StorageUnitFillerConfigurationDto => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerConfigurationOauthUpdate()` */
  static readonly StorageUnitFillerConfigurationOauthUpdatePath = '/oauth/storage-unit-filler-configuration';

  /**
   * Update storage unit filler configuration
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerConfigurationOauthUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFillerConfigurationOauthUpdate$Response(params: StorageUnitFillerConfigurationOauthUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitFillerConfigurationDto>> {
    return storageUnitFillerConfigurationOauthUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update storage unit filler configuration
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerConfigurationOauthUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFillerConfigurationOauthUpdate(params: StorageUnitFillerConfigurationOauthUpdate$Params, context?: HttpContext): Observable<StorageUnitFillerConfigurationDto> {
    return this.storageUnitFillerConfigurationOauthUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitFillerConfigurationDto>): StorageUnitFillerConfigurationDto => r.body)
    );
  }

}
