export enum FileDetailRoute {
  PREVIEW = 'preview',
  HISTORY = 'history',
  CONTENT = 'content',
  SHARE = 'share',
  RELATED = 'related',
  SUBJECTS = 'subjects',
  ACTIVITIES = 'activities',
  INCIDENTS = 'incidents'
}
