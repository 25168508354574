import {Directive, EventEmitter, inject, Output} from '@angular/core';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {IczFormArray} from '@icz/angular-form-elements';


@Directive()
export abstract class AbstractDragAndDropComponent {

  @Output() fileSelected = new EventEmitter<void>();

  protected translateService = inject(TranslateService);
  protected translateParser = inject(TranslateParser);

  protected isFileDrag = false;

  abstract allowedFileExtensions: string[];

  abstract processFileUpload(dataTransfer?: DataTransfer): void;

  abstract get files(): IczFormArray;

  abstract get isMultiUpload(): boolean | 'multiple';

  get dndAreaHelpText() {
    if (this.allowedFileExtensions.length > 0) {
      const text = this.isMultiUpload ? 'Můžete sem přetáhnout soubory s příponou {{allowedExtensions}} nebo je nahrát z počítače.' : 'Můžete sem přetáhnout soubor s příponou {{allowedExtensions}} nebo jej nahrát z počítače.';
      return this.translateParser.interpolate( this.translateService.instant(text),
        {allowedExtensions: this.allowedFileExtensions.join(', ')}
      );
    } else {
      const text = this.isMultiUpload ? 'Můžete sem přetáhnout soubory nebo je nahrát z počítače.' : 'Můžete sem přetáhnout soubor nebo jej nahrát z počítače.';
      return this.translateService.instant(text);
    }
  }

  get multipleFilesDroppedInSingleUploadModeHelpText() {
    return 'Přetáhli jste více než 1 soubor. Pouze první soubor ze seznamu bude zpracován.';
  }

  get fileInputAccepted() {
    if (!this.allowedFileExtensions.length) {
      return null;
    }
    else {
      return this.allowedFileExtensions.join(',');
    }
  }

  get canDropAnotherFile() {
    return this.isMultiUpload || this.files.length === 0;
  }

  multipleFilesDroppedInSingleUploadMode = false;

  onFileDragStart(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.dataTransfer?.clearData(); // Firefox only supports dataTransfer.clearData() on dragStart
  }

  onFileDragover(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileDrag = true;
  }

  onFileDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileDrag = false;
  }

  onFileDrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isFileDrag = false;

    const dataTransfer = event.dataTransfer;
    if (dataTransfer?.files.length && this.canDropAnotherFile) {
      this.multipleFilesDroppedInSingleUploadMode = !this.isMultiUpload && dataTransfer.files.length > 1;
      this.processFileUpload(dataTransfer);
      this.fileSelected.emit();
    }
  }

}
