import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SubjectAttributeStringDto, SubjectRecordDto} from '|api/commons';
import {LocalizedDatePipe} from '@icz/angular-essentials';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {subjectGetAttribute, UNNAMED_SUBJECT_TEXT} from '../model/subjects.model';
import {SubjectAttributeType} from '../model/subject-attribute-type';

@Pipe({
  name: 'subjectName',
  pure: true,
})
export class SubjectNamePipe implements PipeTransform {

  private translateService = inject(TranslateService);
  private localizedDatePipe = new LocalizedDatePipe();

  private readonly birthDateLabel = this.translateService.instant('nar.');
  private readonly cidLabel = this.translateService.instant('IČO');
  private readonly unnamedSubjectLabel = this.translateService.instant(UNNAMED_SUBJECT_TEXT);

  transform(
    subject: Nullable<SubjectRecordDto>,
    showExtendedSubjectInfo = false,
    additionalName?: string,
  ): string {
    if (!subject) return '';

    let out = '';
    const classificationIfShowExtend = showExtendedSubjectInfo ? `${subject.classification} - ` : '';

    const firstNameAttr = subjectGetAttribute(subject, SubjectAttributeType.FIRST_NAME) as Nullable<SubjectAttributeStringDto>;
    const surnameAttr = subjectGetAttribute(subject, SubjectAttributeType.SURNAME) as Nullable<SubjectAttributeStringDto>;
    const businessNameAttr = subjectGetAttribute(subject, SubjectAttributeType.BUSINESS_NAME) as Nullable<SubjectAttributeStringDto>;
    const cidAttr = subjectGetAttribute(subject, SubjectAttributeType.CID) as Nullable<SubjectAttributeStringDto>;

    if (!firstNameAttr && !surnameAttr && !businessNameAttr && !cidAttr) {
      return this.unnamedSubjectLabel;
    }

    const firstNameValue = firstNameAttr?.value ?? '';
    let surnameValue = surnameAttr?.value ?? '';

    if (showExtendedSubjectInfo && additionalName?.length) {
      surnameValue += `, ${additionalName}`;
    }

    if (SubjectTemplateUtils.isNaturalPerson(subject.classification!)) {
      const birthDateValue = (subjectGetAttribute(subject, SubjectAttributeType.BIRTH_DATE) as SubjectAttributeStringDto)?.value ?? '';

      let birthDate = '';

      if (showExtendedSubjectInfo && birthDateValue) {
        birthDate = `, ${this.birthDateLabel} ${this.localizedDatePipe.transform(birthDateValue as string)}`;
      }

      out = `${classificationIfShowExtend}${firstNameValue} ${surnameValue}${birthDate}`;
    }
    else {
      const businessNameValue = businessNameAttr?.value ?? '';
      const cidValue = cidAttr?.value ?? '';

      let cid = '';

      if (cidValue) {
        cid = ` ${this.cidLabel} ${cidValue}`;
      }

      if (businessNameValue || cid) {
        out = `${classificationIfShowExtend}${businessNameValue}${cid}`;
      }
      else {
        out = `${classificationIfShowExtend}${firstNameValue} ${surnameValue}`;
      }
    }

    return out;
  }
}
