import {
  ChangeDetectorRef,
  createComponent,
  Directive,
  ElementRef,
  EnvironmentInjector,
  inject,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';
import {SpinnerComponent} from './spinner/spinner.component';
import {IczOnChanges, IczSimpleChanges} from './icz-on-changes';

/**
 * @internal
 * @deprecated
 */
type TestingFeature = 'ACCESSIBLE' | 'HIDDEN' | 'LABELED';

/**
 * @internal
 */
export type Inaccessibility = TestingFeature | 'spinner';

/**
 * An object used for configuring advanced behavior of blocking overlays.
 */
interface BlockingOverlayOpts {
  /**
   * If TRUE, the blocking overlay will not have semitransparent white background.
   */
  noOpacity?: boolean;
  /**
   * If TRUE, the opacity will show a cursor with a loading wheel.
   */
  cursorWaiting?: boolean;
  /**
   * If TRUE, the opacity will show a cursor with a loading wheel.
   */
  cursorNotAllowed?: boolean;
  /**
   * Configures spinner overlay size.
   */
  spinnerDiameter?: number;
  /**
   * Configures custom spinner vertical positioning from top in px. Used in very specific secnarios.
   */
  spinnerCenteringTopOffset?: number;
  /**
   * If TRUE, the spinner will become white instead of blue.
   */
  invertSpinnerColor?: boolean;
}

/**
 * A directive that will display a semitransparent overlay element over its host element
 * if its [testingFeature]/[waiting]/[blockingOverlay] input evaluates to a truthy value.
 * Used for implementing loading indicators over specific view parts or for marking
 * specific parts of components as "not ready yet".
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[testingFeature],[waiting],[blockingOverlay]',
  standalone: true,
})
export class InaccessibleDirective implements OnInit, IczOnChanges {

  private elementRef = inject(ElementRef);
  private renderer = inject(Renderer2);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private environmentInjector = inject(EnvironmentInjector);

  /**
   * If TRUE, will turn on blocking overlay loading spinner mode.
   */
  @Input()
  waiting: Nullable<boolean> = false;
  /**
   * If TRUE, will turn on blocking overlay "feature not ready yet" mode.
   */
  @Input()
  set testingFeature(value: Nullable<boolean | ''>) { this.setTestingFeatureFlag(value); }
  get testingFeature() { return this._testingFeature; }
  private _testingFeature = false;
  /**
   * If TRUE, will turn on generic blocking overlay mode. Use it in conjunction with @Input blockingOverlayOpts.
   */
  @Input()
  set blockingOverlay(value: boolean | '') { this.setTestingFeatureFlag(value); }
  get blockingOverlay() { return this._testingFeature; }
  /**
   * Label used when testingFeature input is TRUE.
   */
  @Input()
  testingFeatureLabel: Nullable<string> = 'Not implemented yet';
  /**
   * Advanced overlay options.
   * @see BlockingOverlayOpts
   */
  @Input()
  blockingOverlayOpts: BlockingOverlayOpts = {noOpacity: false, cursorWaiting: false, cursorNotAllowed: false};

  private get inaccessibility(): Inaccessibility {
    if (this.testingFeature) return 'LABELED';
    if (this.waiting) return 'spinner';
    return 'ACCESSIBLE';
  }

  private get overlayElement() {
    if (!this._overlayElement) {
      this._overlayElement = this.renderer.createElement('div');
      this.renderer.addClass(this._overlayElement, 'inaccessible-overlay');
      if (this.blockingOverlayOpts?.noOpacity) {
        this.renderer.addClass(this._overlayElement, 'inaccessible-overlay-none');
      }
      if (this.blockingOverlayOpts?.cursorWaiting) {
        this.renderer.addClass(this._overlayElement, 'cursor-waiting');
      }
      if (this.blockingOverlayOpts?.cursorNotAllowed) {
        this.renderer.addClass(this._overlayElement, 'cursor-not-allowed');
      }
      if (this.blockingOverlayOpts?.spinnerCenteringTopOffset) {
        this.renderer.setStyle(this._overlayElement, 'padding-top', `${this.blockingOverlayOpts?.spinnerCenteringTopOffset}px`);
      }
    }
    return this._overlayElement;
  }
  private _overlayElement: Nullable<HTMLDivElement>;

  private get spinnerElement() {
    if (!this._spinnerElement) {
      const spinnerComponent = createComponent(SpinnerComponent, {
        environmentInjector: this.environmentInjector,
      });
      spinnerComponent.instance.diameter = this.blockingOverlayOpts?.spinnerDiameter ? this.blockingOverlayOpts?.spinnerDiameter : 100;
      spinnerComponent.instance.invertSpinnerColor = this.blockingOverlayOpts?.invertSpinnerColor ? this.blockingOverlayOpts?.invertSpinnerColor : false;
      spinnerComponent.changeDetectorRef.detectChanges();
      this._spinnerElement = spinnerComponent.instance.elementRef.nativeElement;
    }
    return this._spinnerElement;
  }
  private _spinnerElement!: HTMLElement;

  private get labelElement() {
    if (!this._labelElement) {
      this._labelElement = this.renderer.createText(this.testingFeatureLabel ?? '');
    }
    return this._labelElement;
  }
  private _labelElement?: HTMLDivElement;

  private previousInaccessibility: Inaccessibility = 'ACCESSIBLE';
  private previousDisplayStyle!: string;
  private previousOverflowStyle!: string;

  /**
   * @internal
   */
  ngOnInit() {
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
  }

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>) {
    this.updateInaccessibility(Boolean(changes.testingFeatureLabel));
    this.changeDetectorRef.markForCheck();
  }

  private updateInaccessibility(updateLabel: boolean) {
    if (!updateLabel && this.previousInaccessibility === this.inaccessibility) return;

    switch (this.previousInaccessibility) {
      case 'HIDDEN':
        this.elementRef.nativeElement.style.display = this.previousDisplayStyle;
        break;
      case 'spinner':
        this.elementRef.nativeElement.style.overflow = this.previousOverflowStyle;
        this.renderer.removeChild(this.overlayElement, this.spinnerElement);
        this.renderer.removeChild(this.elementRef.nativeElement, this.overlayElement);
        break;
      case 'LABELED':
        this.renderer.removeChild(this.overlayElement, this.labelElement);
        this.renderer.removeChild(this.elementRef.nativeElement, this.overlayElement);
        break;
    }

    if (updateLabel) this._labelElement = undefined;

    switch (this.inaccessibility) {
      case 'HIDDEN':
        this.previousDisplayStyle = this.elementRef.nativeElement.style.display;
        this.elementRef.nativeElement.style.display = 'none';
        break;
      case 'spinner':
        this.previousOverflowStyle = this.elementRef.nativeElement.style.overflow;
        this.elementRef.nativeElement.style.overflow = 'hidden';
        this.renderer.appendChild(this.elementRef.nativeElement, this.overlayElement);
        this._overlayElement!.style.top = `${this.elementRef.nativeElement.scrollTop}px`;
        this.renderer.appendChild(this.overlayElement, this.spinnerElement);
        break;
      case 'LABELED':
        this.renderer.appendChild(this.elementRef.nativeElement, this.overlayElement);
        this.renderer.appendChild(this.overlayElement, this.labelElement);
        break;
    }

    this.previousInaccessibility = this.inaccessibility;
  }

  private setTestingFeatureFlag(newValue: Nullable<boolean|''>) {
    this._testingFeature = newValue === '' || Boolean(newValue);
  }

}
