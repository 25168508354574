import {Injectable, InjectionToken} from '@angular/core';

/**
 * An object encapsulating a predicate for bank holidays and other kinds of free days.
 */
export interface FreeDaysPredicate {
  /**
   * Predicate which checks if a given date is a bank holiday.
   */
  isFreeDay(d: Date): boolean;
}

/**
 * A token used for providing free days functionality for datepickers.
 *
 * Free days could be bank holidays, weekends or other days on which target organization is not
 * operational and thus for example task deadlines should not be set at such dates.
 */
export const FREE_DAYS_PROVIDER = new InjectionToken<FreeDaysPredicate>('Free Days Provider');

/**
 * A free days provider which makes all days working days.
 */
@Injectable()
export class NoFreeDaysService implements FreeDaysPredicate {
  isFreeDay(_: Date) {
    return false;
  }
}

/**
 * Represents a single chip selector kind such that multiple instances of
 * chip selectors on multiple screens can fulfll same business case.
 */
export type ChipNamespace = string;

/**
 * A map from chip option value to its usage count.
 */
export type ChipUsageRates = Record<string, number>;

/**
 * Chip usage rates stored for all namespaces.
 */
export type PersistedChipUsageRates = Record<ChipNamespace, ChipUsageRates>;

/**
 * Option labels and values are both user-defined strings.
 */
export type CustomChips = string[];

/**
 * User-defined custom chips stores for all namespaces.
 */
export type PersistedCustomChips = Record<ChipNamespace, CustomChips>;

/**
 * Interface for implementing chip selector state persistence.
 */
export interface ChipSelectorStatePersistor {
  /**
   * Retrieves usage rates of all chips in a given namespace.
   */
  getChipUsageRates(chipNamespace: ChipNamespace): ChipUsageRates;
  /**
   * Sets usage rate for a particular chip in a particular namespace.
   * @param rate - total count of the chip being used.
   */
  setChipUsageRate(chipNamespace: ChipNamespace, chipName: string, rate: number): void;
  /**
   * Retvrieves a list of user-defined chips in a given namespace.
   */
  getCustomChips(chipNamespace: ChipNamespace): CustomChips;
  /**
   * Adds a new user-defined chip to a given chip namespace.
   */
  addCustomChip(chipNamespace: ChipNamespace, chipName: string): void;
}

/**
 * A token used for providing persistence strategy for chip selectors.
 *
 * Chip selectors save user-created custom chips he created and also save usage statistics
 * related to those chips (the chips get sorted by usage rate descending).
 */
export const CHIP_SELECTOR_STATE_PERSISTOR = new InjectionToken<ChipSelectorStatePersistor>('Chip Selector State Persistor');

/**
 * A persistor which does not store any user-created custom chips (instantly forgets them on chip selector destroy).
 * !!! Not recommended for production, good only for unit testing or demonstration purposes.
 */
@Injectable()
export class NoopChipSelectorStatePersistorService implements ChipSelectorStatePersistor {
  addCustomChip(chipNamespace: ChipNamespace, chipName: string): void {}

  getChipUsageRates(chipNamespace: ChipNamespace): ChipUsageRates {
    return {};
  }

  getCustomChips(chipNamespace: ChipNamespace): CustomChips {
    return [];
  }

  setChipUsageRate(chipNamespace: ChipNamespace, chipName: string, rate: number): void {}
};
