import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {IconComponent, TooltipDirective} from '@icz/angular-essentials';

/**
 * A simple form building block which gives a hint to the user.
 * @deprecated - we might consider removing it and substituting it with an icz-alert in compact mode.
 */
@Component({
  selector: 'icz-form-help-field',
  templateUrl: './form-help-field.component.html',
  styleUrls: ['./form-help-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    TooltipDirective,
    IconComponent,
  ]
})
export class FormHelpFieldComponent {

  /**
   * Help text.
   */
  @Input({required: true})
  helpText: string = '';

}
