import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EssentialsModule} from '@icz/angular-essentials';
import {FormElementsModule} from '@icz/angular-form-elements';
import {HighlightsModule} from '../highlights/highlights.module';
import {NotificationsModule} from '../notifications/notifications.module';
import {AppInfoPopupComponent} from './app-info-popup/app-info-popup.component';
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component';
import {FavouritePageSelectorComponent} from './favourite-page-selector/favourite-page-selector.component';
import {HistoryBitsComponent} from './history-bits/history-bits.component';
import {MainApplicationBarComponent} from './main-application-bar/main-application-bar.component';
import {MenuAndBarComponent} from './menu-and-bar/menu-and-bar.component';
import {SettingsPopupComponent} from './settings-popup/settings-popup.component';
import {SideMenuItemsComponent} from './side-menu-items/side-menu-items.component';
import {SideMenuComponent} from './side-menu/side-menu.component';
import {UserSettingsPopupComponent} from './user-settings-popup/user-settings-popup.component';
import {AppAboutDialogComponent} from './app-about-dialog/app-about-dialog.component';
import {SharedBusinessComponentsModule} from '../shared-business-components/shared-business-components.module';
import {LoginScreensModule} from '../login-screens/login-screens.module';
import {LazyModuleOutletComponent} from './lazy-module-outlet/lazy-module-outlet.component';
import {LibraryImportModule} from '../.module-library-import/library-import.module';
import {IczFormDirectivesModule} from '../icz-form-directives/icz-form-directives.module';

@NgModule({
  declarations: [
    AppInfoPopupComponent,
    HistoryBitsComponent,
    MenuAndBarComponent,
    MainApplicationBarComponent,
    SettingsPopupComponent,
    SideMenuComponent,
    SideMenuItemsComponent,
    UserSettingsPopupComponent,
    FavouritePageSelectorComponent,
    BreadcrumbsComponent,
    AppAboutDialogComponent,
    LazyModuleOutletComponent,
  ],
  exports: [
    AppInfoPopupComponent,
    HistoryBitsComponent,
    MenuAndBarComponent,
    MainApplicationBarComponent,
    SettingsPopupComponent,
    SideMenuComponent,
    SideMenuItemsComponent,
    UserSettingsPopupComponent,
    FavouritePageSelectorComponent,
    BreadcrumbsComponent,
    AppAboutDialogComponent,
    LazyModuleOutletComponent,
  ],
  imports: [
    CommonModule,
    EssentialsModule,
    NotificationsModule,
    FormElementsModule,
    HighlightsModule,
    SharedBusinessComponentsModule,
    LoginScreensModule,
    LibraryImportModule,
    IczFormDirectivesModule,
  ]
})
export class GeneralScreenAreaModule { }
