import {inject, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DigitalComponentCompleteDto} from '|api/component';
import {
  DigitalComponentVersionExtendResult,
  DigitalComponentVersionMarkResult,
  DigitalComponentVersionSignResult
} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {
  CLOSE_THIS_TOAST,
  getToastBodyTemplateTranslateKey,
  getToastHeaderTemplateTranslateKey,
  MessageType,
  SErrorParameters,
  SImplicitlyStringifiable,
  ToastService
} from '../../../components/notifications/toast.service';
import {createAbsoluteRoute} from '../../routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentDetailRoute, DocumentsRoute} from '../../../enums/documents-routes.enum';
import {getLastDigitalComponentVersionId} from '../../../components/shared-business-components/shared-document.utils';
import {InProgressSignature} from '../../../services/sign-mark-timestamp.service';
import {
  DigitalComponentWithResult
} from '../../../components/shared-business-components/essl-components/models/signature-dialog.model';
import {z} from 'zod';
import {
  interpolateBackendErrorMessage
} from '../../../components/shared-business-components/interpolate-backend-error.pipe';
import {ButtonType} from '../../../components/button-collection/button-collection.component';

export const SComponentTemplateData = z.object({
  [InternalNotificationKey.ESSL_COMPONENT_LABEL]: SImplicitlyStringifiable,
});
export type ComponentTemplateData = z.infer<typeof SComponentTemplateData>;

export const SDigitalComponentTemplateData = z.intersection(
  SComponentTemplateData,
  z.object({
    [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
    [InternalNotificationKey.DIGITAL_COMPONENT_ID]: SImplicitlyStringifiable,
    [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: z.optional(SImplicitlyStringifiable),
    [InternalNotificationKey.ESSL_COMPONENT_LABEL]: SImplicitlyStringifiable,
    [InternalNotificationKey.DOCUMENT_SUBJECT]: z.optional(SImplicitlyStringifiable),
    [InternalNotificationKey.ERROR_DESCRIPTION]: z.optional(SImplicitlyStringifiable),
    [InternalNotificationKey.ERROR_PARAMETERS]: z.optional(SErrorParameters),
    [InternalNotificationKey.ESSL_COMPONENT_ID]: z.optional(SImplicitlyStringifiable), // todo(mh) will be deleted
  })
);
export type DigitalComponentTemplateData = z.infer<typeof SDigitalComponentTemplateData>;

export const SDigitalComponentDeletedTemplateData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type DigitalComponentDeletedTemplateData = z.infer<typeof SDigitalComponentDeletedTemplateData>;

export const SConversionErrorTemplateData = z.intersection(
  SDigitalComponentTemplateData,
  z.object({
    onPrimaryButtonClick: z.function(),
  })
);
export type ConversionErrorTemplateData = z.infer<typeof SConversionErrorTemplateData>;

export const SBulkDigitalComponentSignedTemplateData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type BulkDigitalComponentSignedTemplateData = z.infer<typeof SBulkDigitalComponentSignedTemplateData>;

export const SComponentBulkOperationStartedData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type ComponentBulkOperationStartedData = z.infer<typeof SComponentBulkOperationStartedData>;

export const SDigitalComponentSimpleErrorTemplateData = z.object({
  [InternalNotificationKey.ERROR_DESCRIPTION]: z.optional(SImplicitlyStringifiable),
  [InternalNotificationKey.ERROR_PARAMETERS]: z.optional(SErrorParameters),
});
export type DigitalComponentSimpleErrorTemplateData = z.infer<typeof SDigitalComponentSimpleErrorTemplateData>;

export const SComponnentAnonymizationTemplateData = z.object({
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DIGITAL_COMPONENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DIGITAL_COMPONENT_FILE_NAME]: SImplicitlyStringifiable,
});
export type ComponnentAnonymizationTemplateData = z.infer<typeof SComponnentAnonymizationTemplateData>;

export enum EsslComponentToastType {
  CONVERSION_SUCCESS = 'CONVERSION_SUCCESS',
  CONVERSION_ERROR = 'CONVERSION_ERROR',
  AUTHORIZED_CONVERSION_STARTED_SUCCESS = 'AUTHORIZED_CONVERSION_STARTED_SUCCESS',
  AUTHORIZED_CONVERSION_STARTED_ERROR = 'AUTHORIZED_CONVERSION_STARTED_ERROR',
  AUTHORIZED_CONVERSION_SUCCESS = 'AUTHORIZED_CONVERSION_SUCCESS',
  AUTHORIZED_CONVERSION_ERROR = 'AUTHORIZED_CONVERSION_ERROR',
  VERIFIED_CONVERSION_STARTED_SUCCESS = 'VERIFIED_CONVERSION_STARTED_SUCCESS',
  VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS = 'VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS',
  VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS = 'VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS',
  VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR = 'VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR',
  VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR = 'VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR',
  CONVERSION_WITH_CLAUSE_SUCCESS = 'CONVERSION_WITH_CLAUSE_SUCCESS',
  CONVERSION_WITH_CLAUSE_ERROR = 'CONVERSION_WITH_CLAUSE_ERROR',
  ADD_TIMESTAMP_SUCCESS = 'ADD_TIMESTAMP_SUCCESS',
  ADD_TIMESTAMP_FAILED = 'ADD_TIMESTAMP_FAILED',
  MANUAL_DIGITAL_COMPONENT_VALIDATION_SUCCESS = 'MANUAL_DIGITAL_COMPONENT_VALIDATION_SUCCESS',
  MANUAL_DIGITAL_COMPONENT_VALIDATION_FAIL = 'MANUAL_DIGITAL_COMPONENT_VALIDATION_FAIL',
  COMPONENT_DELETED = 'COMPONENT_DELETED',
  COMPONENT_LOCKED = 'COMPONENT_LOCKED',
  COMPONENT_LOCKED_ERROR = 'COMPONENT_LOCKED_ERROR',
  COMPONENT_UNLOCKED = 'COMPONENT_UNLOCKED',
  COMPONENT_UNLOCKED_ERROR = 'COMPONENT_UNLOCKED_ERROR',
  COMPONENT_CHECKED_IN = 'COMPONENT_CHECKED_IN',
  COMPONENT_CHECKED_IN_ERROR = 'COMPONENT_CHECKED_IN_ERROR',
  COMPONENT_CHECKED_OUT = 'COMPONENT_CHECKED_OUT',
  COMPONENT_CHECKED_OUT_ERROR = 'COMPONENT_CHECKED_OUT_ERROR',
  SIGN_FILE_ACCOMPLISH = 'SIGN_FILE_ACCOMPLISH',
  SIGN_FILE_ERROR = 'SIGN_FILE_ERROR',
  MARK_FILE_ACCOMPLISH = 'MARK_FILE_ACCOMPLISH',
  MARK_FILE_ERROR = 'MARK_FILE_ERROR',
  MARK_TIMESTAMP_FILE_ACCOMPLISH = 'MARK_TIMESTAMP_FILE_ACCOMPLISH',
  MARK_TIMESTAMP_FILE_ERROR = 'MARK_TIMESTAMP_FILE_ERROR',
  MARK_FINAL_ERROR = 'MARK_FINAL_ERROR',

  CONVERSION_INVALID_INPUT_FORMAT_ERROR = 'CONVERSION_INVALID_INPUT_FORMAT_ERROR',
  CONVERSION_WITH_CLAUSE_INVALID_INPUT_FORMAT_ERROR = 'CONVERSION_WITH_CLAUSE_INVALID_INPUT_FORMAT_ERROR',
  MARK_INVALID_INPUT_FORMAT_ERROR = 'MARK_INVALID_INPUT_FORMAT_ERROR',
  EXTEND_INVALID_INPUT_FORMAT_ERROR = 'EXTEND_INVALID_INPUT_FORMAT_ERROR',

  CONVERSION_ON_SETTLED_DOCUMENT_ERROR = 'CONVERSION_ON_SETTLED_DOCUMENT_ERROR',
  CONVERSION_WITH_CLAUSE_ON_SETTLED_DOCUMENT_ERROR = 'CONVERSION_WITH_CLAUSE_ON_SETTLED_DOCUMENT_ERROR',
  MARK_ON_SETTLED_DOCUMENT_ERROR = 'MARK_ON_SETTLED_DOCUMENT_ERROR',
  EXTEND_ON_SETTLED_DOCUMENT_ERROR = 'EXTEND_ON_SETTLED_DOCUMENT_ERROR',

  CONVERSION_WHILE_CONVERSION_RUNNING = 'CONVERSION_WHILE_CONVERSION_RUNNING',
  CONVERSION_WITH_CLAUSE_WHILE_CONVERSION_RUNNING = 'CONVERSION_WITH_CLAUSE_WHILE_CONVERSION_RUNNING',
  MARK_WHILE_CONVERSION_RUNNING = 'MARK_WHILE_CONVERSION_RUNNING',
  EXTEND_WHILE_CONVERSION_RUNNING = 'EXTEND_WHILE_CONVERSION_RUNNING',

  EXTEND_SIGNATURE_NOT_FOUND_ERROR = 'EXTEND_SIGNATURE_NOT_FOUND_ERROR',
  EXTEND_FILE_ERROR = 'EXTEND_FILE_ERROR',

  DOWNLOAD_ERROR = 'DOWNLOAD_ERROR',
  DELETE_ERROR = 'DELETE_ERROR',

  COMPONENT_DELETION_SUCCESS = 'COMPONENT_DELETION_SUCCESS',
  COMPONENT_DELETION_ERROR = 'COMPONENT_DELETION_ERROR',

  COMPONENT_BULK_ACTION_ERROR = 'DOCUMENT_BULK_ACTION_ERROR',
  BULK_TASK_COMPONENT_SIGN_STARTED = 'BULK_TASK_COMPONENT_SIGN_STARTED',

  COMPONENT_BULK_OUTPUT_FORMAT_CONVERSION_STARTED = 'COMPONENT_BULK_OUTPUT_FORMAT_CONVERSION_STARTED',
  COMPONENT_BULK_CONVERSION_WITH_CLAUSE_STARTED = 'COMPONENT_BULK_CONVERSION_WITH_CLAUSE_STARTED',
  COMPONENT_BULK_LOCK_STARTED = 'COMPONENT_BULK_LOCK_STARTED',
  COMPONENT_BULK_UNLOCK_STARTED = 'COMPONENT_BULK_UNLOCK_STARTED',
  COMPONENT_BULK_DELETE_STARTED = 'COMPONENT_BULK_DELETE_STARTED',
  COMPONENT_BULK_CONTAINER_EXTRACT_STARTED = 'COMPONENT_BULK_CONTAINER_EXTRACT_STARTED',
  COMPONENT_BULK_CONTAINER_EXTRACT_SUCCESS = 'COMPONENT_BULK_CONTAINER_EXTRACT_SUCCESS',
  COMPONENT_BULK_CONTAINER_EXTRACT_ERROR = 'COMPONENT_BULK_CONTAINER_EXTRACT_ERROR',
  CONVERSION_TO_OUTPUT_WITH_CLAUSE_ERROR = 'CONVERSION_TO_OUTPUT_WITH_CLAUSE_ERROR',
  CONVERSION_TO_OUTPUT_ERROR = 'CONVERSION_TO_OUTPUT_ERROR',
  EXECUTION_CLAUSE_CREATE_STARTED = 'EXECUTION_CLAUSE_CREATE_STARTED',
  EXECUTION_CLAUSE_CREATE_ERROR = 'EXECUTION_CLAUSE_CREATE_ERROR',
  EXECUTION_CLAUSE_CREATION_SUCCESS = 'EXECUTION_CLAUSE_CREATION_SUCCESS',
  EXECUTION_CLAUSE_CREATION_ERROR = 'EXECUTION_CLAUSE_CREATION_ERROR',
  COMPONENT_CREATED = 'COMPONENT_CREATED',
  COMPONENT_TAKEN_OVER = 'COMPONENT_TAKEN_OVER',
  COMPONENT_DUPLICATED = 'COMPONENT_DUPLICATED',
  COMPONENT_PARAMETERS_UPDATED = 'COMPONENT_PARAMETERS_UPDATED',
  COMPONENT_PARAMETERS_UPDATE_ERROR = 'COMPONENT_PARAMETERS_UPDATE_ERROR',
  COMPONENT_ANONYMIZATION_COMPLETED = 'COMPONENT_ANONYMIZATION_COMPLETED',
  COMPONENT_ANONYMIZATION_ERROR = 'COMPONENT_ANONYMIZATION_ERROR',
  COMPONENT_ANONYMIZATION_CANCELED = 'COMPONENT_ANONYMIZATION_CANCELED',
}

@Injectable({
  providedIn: 'root',
})
export class EsslComponentToastService {

  private toastService = inject(ToastService);
  private translateService = inject(TranslateService);
  private router = inject(Router);

  dispatchComponentInfoToast(documentToastType: EsslComponentToastType, templateData: DigitalComponentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, documentToastType, templateData, SDigitalComponentTemplateData);
  }

  dispatchComponentErrorToast(documentToastType: EsslComponentToastType, templateData: DigitalComponentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, documentToastType, templateData, SDigitalComponentTemplateData);
  }

  dispatchComponentSimpleInfoToast(esslComponentToastType: EsslComponentToastType) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, esslComponentToastType);
  }

  dispatchComponentSimpleErrorToast(esslComponentToastType: EsslComponentToastType) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, esslComponentToastType);
  }

  dispatchBulkOperationStartedToast(esslComponentToastType: EsslComponentToastType, templateData: ComponentBulkOperationStartedData) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, esslComponentToastType, templateData, SComponentBulkOperationStartedData);
  }

  dispatchComponentWarningToast(documentToastType: EsslComponentToastType, templateData: DigitalComponentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, documentToastType, templateData, SDigitalComponentTemplateData);
  }

  dispatchComponentAuthConversionStartedToast(templateData: ComponentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, EsslComponentToastType.AUTHORIZED_CONVERSION_STARTED_SUCCESS, templateData, SComponentTemplateData);
  }

  dispatchComponentVerifiedConversionStartedToast(templateData: ComponentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, EsslComponentToastType.VERIFIED_CONVERSION_STARTED_SUCCESS, templateData, SComponentTemplateData);
  }

  dispatchConversionSuccess(templateData: DigitalComponentTemplateData) {
    this.dispatchComponentInfoToast(EsslComponentToastType.CONVERSION_SUCCESS, templateData);
  }

  dispatchConversionError(templateData: ConversionErrorTemplateData) {
    this.dispatchGenericConversionError(templateData, EsslComponentToastType.CONVERSION_ERROR);
  }

  dispatchConversionWithClauseSuccess(templateData: DigitalComponentTemplateData) {
    this.dispatchComponentInfoToast(EsslComponentToastType.CONVERSION_WITH_CLAUSE_SUCCESS, templateData);
  }

  dispatchConversionWithClauseError(templateData: ConversionErrorTemplateData) {
    this.dispatchGenericConversionError(templateData, EsslComponentToastType.CONVERSION_WITH_CLAUSE_ERROR);
  }

  private dispatchGenericConversionError(templateData: ConversionErrorTemplateData, toastType: EsslComponentToastType) {
    this.toastService.dispatchToast({
      type: MessageType.ERROR,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(toastType),
        },
        body: {
          template: getToastBodyTemplateTranslateKey(toastType),
          templateData,
          templateDataSchema: SConversionErrorTemplateData,
        },
        buttons: [
          {
            type: ButtonType.PRIMARY,
            label: 'Otevřít komponentu',
            action: templateData.onPrimaryButtonClick,
          },
          {
            type: ButtonType.SECONDARY,
            label: 'Zavřít',
            action: () => CLOSE_THIS_TOAST,
          },
        ],
      },
    });
  }

  dispatchActionError(templateData: DigitalComponentTemplateData, toastHeader: EsslComponentToastType, toastBody: EsslComponentToastType) {
    this.toastService.dispatchToast({
      isUnclosable: true,
      type: MessageType.ERROR,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(toastHeader),
        },
        body: {
          template: getToastBodyTemplateTranslateKey(toastBody),
          templateData,
          templateDataSchema: SDigitalComponentTemplateData,
        },
        buttons: [
          {
            type: ButtonType.PRIMARY,
            label: 'Otevřít komponentu',
            action: () => this.router.navigateByUrl(
              createAbsoluteRoute(
                ApplicationRoute.DOCUMENTS,
                DocumentsRoute.DOCUMENT,
                String(templateData[InternalNotificationKey.DOCUMENT_ID]),
                DocumentDetailRoute.COMPONENTS,
                {
                  digitalComponentId: templateData[InternalNotificationKey.DIGITAL_COMPONENT_ID],
                  digitalComponentVersionId: templateData[InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID],
                }
              )
            ),
          },
          {
            type: ButtonType.SECONDARY,
            label: 'Zavřít',
            action: () => CLOSE_THIS_TOAST,
          },
        ],
      },
    });
  }

  dispatchAddTimestampToasters(digitalComponents: DigitalComponentCompleteDto[], extendResult?: Nullable<DigitalComponentVersionExtendResult[]>) {
    for (const result of (extendResult ?? [])) {
      const digitalComponent = digitalComponents.find(row => getLastDigitalComponentVersionId(row) === result.digitalComponentVersionId)!;

      const toastTemplateData = {
        [InternalNotificationKey.DOCUMENT_ID]: digitalComponents[0].documentId!,
        [InternalNotificationKey.DIGITAL_COMPONENT_ID]: digitalComponent.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: result.digitalComponentVersionId!,
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: digitalComponent.label!,
      };

      if (!result.error) {
        this.dispatchComponentInfoToast(EsslComponentToastType.ADD_TIMESTAMP_SUCCESS, toastTemplateData);
      } else {
        const templateData = {
          ...toastTemplateData,
          [InternalNotificationKey.ERROR_DESCRIPTION]: this.translateService.instant(result.error.code!), // smeels fishy, errors usually have `errorCode` prop??
        };

        this.toastService.dispatchToast({
          type: MessageType.ERROR,
          data: {
            header: {
              template: getToastHeaderTemplateTranslateKey(EsslComponentToastType.ADD_TIMESTAMP_FAILED),
              templateData,
            },
            body: {
              template: getToastBodyTemplateTranslateKey(EsslComponentToastType.ADD_TIMESTAMP_FAILED),
              templateData,
            },
          },
        });
      }
    }
  }

  dispatchSignDigitalComponentsToasts(
    digitalComponentsWithoutManualSignature: DigitalComponentWithResult[],
    digitalComponentsWithManualSignature: InProgressSignature[],
    documentId: number,
    signResult?: Nullable<DigitalComponentVersionSignResult[]>
  ) {
    const mapped: DigitalComponentWithResult[] = digitalComponentsWithManualSignature.map(a => a.digitalComponent).map(a => {
      return {...a, fileName: ''};
    });
    const digitalComponents = digitalComponentsWithoutManualSignature.concat(mapped);

    for (const result of (signResult ?? [])) {
      const {digitalComponentVersionId} = result;
      const digitalComponent = digitalComponents.find(row => getLastDigitalComponentVersionId(row) === digitalComponentVersionId)!;

      const toastTemplateData = {
        [InternalNotificationKey.DOCUMENT_ID]: documentId,
        [InternalNotificationKey.DIGITAL_COMPONENT_ID]: digitalComponent.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: digitalComponentVersionId!,
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: digitalComponent.label!,
      };

      if (!result.error) {
        this.dispatchComponentInfoToast(EsslComponentToastType.SIGN_FILE_ACCOMPLISH, toastTemplateData);
      } else {
        this.dispatchComponentErrorToast(EsslComponentToastType.SIGN_FILE_ERROR, {
          ...toastTemplateData,
          [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(this.translateService, {errorCode: result.error.code!, parameters: result.error.parameters ?? undefined}),
        });
      }
    }
  }

  dispatchMarkDigitalComponentToasters(
    digitalComponents: DigitalComponentCompleteDto[],
    documentId: number,
    assignTimestamp: boolean,
    markResult?: Nullable<DigitalComponentVersionMarkResult[]>,
  ) {
    for (const result of (markResult ?? [])) {
      const digitalComponent = digitalComponents.find(row => getLastDigitalComponentVersionId(row) === result.digitalComponentVersionId)!;

      const toastTemplateData = {
        [InternalNotificationKey.DOCUMENT_ID]: documentId,
        [InternalNotificationKey.DIGITAL_COMPONENT_ID]: digitalComponent.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: result.digitalComponentVersionId!,
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: digitalComponent.label!,
      };

      if (!result.error) {
        if (assignTimestamp) {
          this.dispatchComponentInfoToast(EsslComponentToastType.MARK_TIMESTAMP_FILE_ACCOMPLISH, toastTemplateData);
        } else {
          this.dispatchComponentInfoToast(EsslComponentToastType.MARK_FILE_ACCOMPLISH, toastTemplateData);
        }
      } else {
        if (assignTimestamp) {
          this.dispatchComponentErrorToast(EsslComponentToastType.MARK_TIMESTAMP_FILE_ERROR, {
            ...toastTemplateData,
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(
              this.translateService,
              {
                errorCode: result.error.code!,
                parameters: result.error.parameters ?? [],
              }
            ),
          });
        } else {
          this.dispatchComponentErrorToast(EsslComponentToastType.MARK_FILE_ERROR, {
            ...toastTemplateData,
            [InternalNotificationKey.ERROR_DESCRIPTION]: interpolateBackendErrorMessage(
              this.translateService,
              {
                errorCode: result.error.code!,
                parameters: result.error.parameters ?? [],
              }
            ),
          });
        }
      }
    }
  }

  dispatchDigitalComponentDeleted(templateData: DigitalComponentDeletedTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, EsslComponentToastType.COMPONENT_DELETED, templateData, SDigitalComponentDeletedTemplateData);
  }

  dispatchBulkComponentSignTask(templateData: BulkDigitalComponentSignedTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, EsslComponentToastType.BULK_TASK_COMPONENT_SIGN_STARTED, templateData, SBulkDigitalComponentSignedTemplateData);
  }

}
