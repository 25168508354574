import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {InternalNotificationDto, NotificationType} from '|api/notification';
import {IconSize} from '@icz/angular-essentials';


@Component({
  selector: 'icz-notification-type-icon',
  templateUrl: './notification-type-icon.component.html',
  styleUrls: ['./notification-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationTypeIconComponent {
  @Input({required: true})
  notification!: InternalNotificationDto;
  @Input()
  isRead: boolean = false;
  @Input()
  size: IconSize = 'small';

  readonly NotificationType = NotificationType;
}
