<div>
  <div class="row items-center toolbar modal-header"
    cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container">
    <div class="row grow">
      <icz-user-initials
        class="mr-8"
        [initials]="getUserAcronym(message.auditInfo!.createdBy!)"
        [isSystem]="message.auditInfo!.createdBy === 'system'"
        [color]="UserInitialsColor.GREEN">
      </icz-user-initials>

      <div class="mt-8">
        @if (message.auditInfo!.createdBy! && message.auditInfo!.createdBy! !== 'system') {
          <span>{{message.auditInfo!.createdBy!}}</span> -
          <span>{{message.auditInfo!.createdByFunctionalPositionId! | findFunctionalPositionById:true | async}}</span>
        }
        @if (!message.auditInfo!.createdBy! || message.auditInfo!.createdBy! === 'system') {
          <span>{{ 'Zpráva zaslaná systémem' | translate }}</span>
        }
      </div>
    </div>
    <div class="row">
      <icz-button-collection
        size="large"
        [groupButtons]="true"
        [hideBorders]="true"
        [buttons]="notificationActionButtons"
        [areCollapsed]="isToolbarCollapsed$ | async"
        (buttonClicked)="buttonClicked($event)"
        class="mr-16"
      ></icz-button-collection>

      <icz-button svgIcon="close" (onAction)="close()" background="transparent" cdkDragHandle cdkDragHandleDisabled size="small" disableRipple></icz-button>
    </div>
  </div>
  <div class="col grow modal-body">
    <div class="row grow">
      <div class="grow">
        <div class="row">
          <icz-notification-type-icon
            size="default"
            [notification]="message"
            [isRead]="isRead(message)">
          </icz-notification-type-icon>
          <icz-label [label]="getNotificationStatusLabel()"></icz-label>
        </div>
      </div>
      <div class="row">
        <icz-label class="secondary-label" [label]="getNotificationDateLabel()"></icz-label>
        <icz-label class="notifiction-status" [label]="isRead(message) ? 'Přečteno' : 'Nepřečteno'"></icz-label>
      </div>
    </div>
    <div class="mt-32">
      <icz-notification-message-header
        [isRead]="isRead(message)"
        [isBulkNotification]="message.bulkNotification!"
        [code]="$any(message.subjectCode)"
        [params]="getMessageHeaderParams(message)">
      </icz-notification-message-header>
      @for (parameter of message.parameters; track parameter) {
        <div [ngClass]="{'message-body-separator': !$last}">
          <icz-notification-message-body
            class="icz-body-1"
            [code]="$any(message.messageCode)"
            [params]="parameter"
            [isRead]="isRead(message)">
          </icz-notification-message-body>
        </div>
      }
    </div>
  </div>
</div>
