import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {
  EntityWithRefNumber,
  RegistryOfficeTransferIncidentView
} from '../../../ref-number-link/ref-number-link.component';
import {createAbsoluteRoute} from '../../../../../core/routing/routing.helpers';
import {DocumentDetailRoute} from '../../../../../enums/documents-routes.enum';
import {DocumentsTableDataType, getDetailUrlByEntity} from '../../documents-table.models';
import {DocumentView} from '../../../document-toolbar/services/toolbar-common.utils';
import {GenericSearchService, IczSimpleTableDataSource, unwrapSearchContent} from '../../../../../services';
import {RegistryOfficeTransferDto, RegistryOfficeTransferIncidentDto} from '|api/document';
import {EntityType, RegistryOfficeTransferIncidentState} from '|api/commons';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {isDocumentObject, isFileObject} from '../../../shared-document.utils';
import {ApiElasticsearchService} from '|api/elastic';
import {FilterOperator} from '@icz/angular-table';

function isRegistryOfficeTransfer(entity: any): entity is RegistryOfficeTransferDto {
  return entity && typeof entity === 'object' && entity.entityType === EntityType.REGISTRY_OFFICE_TRANSFER;
}

@Component({
  selector: 'icz-quick-incidents-list',
  templateUrl: './quick-incidents-list.component.html',
  styleUrls: ['./quick-incidents-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickIncidentsListComponent implements OnInit {

  private genericSearchService = inject(GenericSearchService);
  private destroyRef = inject(DestroyRef);
  private cd = inject(ChangeDetectorRef);

  MAX_INCIDENTS_COUNT = 5;

  @Input({ required: true })
  entity!: DocumentsTableDataType;

  dataSource!: IczSimpleTableDataSource<RegistryOfficeTransferIncidentDto>;

  unresolvedIncidents: RegistryOfficeTransferIncidentDto[] = [];
  entityIncidentsLink!: string;
  totalIncidentsCount = 0;

  readonly RegistryOfficeTransferIncidentView = RegistryOfficeTransferIncidentView;

  ngOnInit(): void {
    if (this.entity) {
      this.entityIncidentsLink = createAbsoluteRoute(
        getDetailUrlByEntity(DocumentView.FILE_CONTENTS, this.entity as unknown as EntityWithRefNumber)!,
        DocumentDetailRoute.INCIDENTS,
      );

      const effectiveEntityType = isDocumentObject(this.entity) ?
        EntityType.DOCUMENT :
        (
          isFileObject(this.entity) ?
            EntityType.FILE :
            EntityType.STORAGE_UNIT
        );

      let effectiveEntityId: number;

      if (isRegistryOfficeTransfer(this.entity)) {
        if (effectiveEntityType === EntityType.DOCUMENT) {
          effectiveEntityId = this.entity.documentId!;
        }
        else if (effectiveEntityType === EntityType.FILE) {
          effectiveEntityId = this.entity.fileId!;
        }
        else { // storage unit
          effectiveEntityId = this.entity.storageUnitId!;
        }
      }
      else {
        effectiveEntityId = this.entity.id;
      }

      this.dataSource = new IczSimpleTableDataSource<RegistryOfficeTransferIncidentDto>(
        this.genericSearchService,
        {
          url: ApiElasticsearchService.ElasticsearchFindRegistryOfficeTransferIncidentsForEntityPath,
          staticPathParams: {
            entityType: effectiveEntityType,
            entityId: String(effectiveEntityId),
          },
          customMapper: unwrapSearchContent(),
        }
      );

      this.dataSource.loadPageResult$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(incidentsPage => {
        this.totalIncidentsCount = incidentsPage.totalElements;
        this.unresolvedIncidents = incidentsPage.content;
        this.cd.detectChanges();
      });

      this.dataSource.loadPage({
        page: 0,
        size: this.MAX_INCIDENTS_COUNT,
        filter: [
          {
            fieldName: 'state',
            operator: FilterOperator.equals,
            value: RegistryOfficeTransferIncidentState.IN_PROGRESS,
          }
        ],
        sort: [
          {
            fieldName: 'auditInfo.createdAt',
            descending: true,
          }
        ],
      });
    }
  }

}
