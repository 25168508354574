import {Component, DestroyRef, forwardRef, inject, Input, OnInit} from '@angular/core';
import {DatePickerComponent} from '../date-picker/date-picker.component';
import {TimePickerComponent} from '../time-picker/time-picker.component';
import {IczFormControl, IczFormGroup} from '../icz-form-controls';
import {ReactiveFormsModule} from '@angular/forms';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PrimitiveValueFormField} from '../common/abstract-form-field';
import {formatAsLocalIsoDateTime, isValidDate} from '../form-elements.model';
import {ValidationErrorsListComponent} from '../validators/validation-errors-list/validation-errors-list.component';

@Component({
  selector: 'icz-datetime-picker',
  standalone: true,
  imports: [
    DatePickerComponent,
    TimePickerComponent,
    ReactiveFormsModule,
    ValidationErrorsListComponent,
  ],
  templateUrl: './datetime-picker.component.html',
  styleUrl: './datetime-picker.component.scss',
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => DatetimePickerComponent),
  }],
})
export class DatetimePickerComponent extends PrimitiveValueFormField<string> implements OnInit {

  @Input()
  override set value(newValue: Nullable<string>) {
    const parsedDate = newValue ? new Date(newValue) : null;

    if (parsedDate && isValidDate(parsedDate)) {
      const locallyFormattedIsoDateTime = formatAsLocalIsoDateTime(parsedDate);
      const dateTimeParts = locallyFormattedIsoDateTime!.split(' ');
      const date = dateTimeParts[0];
      const timeParts = dateTimeParts[1].split(':');
      const time = `${timeParts[0]}:${timeParts[1]}`;

      this._formGroup.setValue({
        date,
        time,
      }, {emitEvent: false});
    }
    else {
      this._formGroup.setValue({
        date: null,
        time: null,
      }, {emitEvent: false});
      this._realValue = null;
    }

    this._realValue = newValue;
  }
  override get value(): Nullable<string> {
    return this._realValue;
  }

  private destroyRef = inject(DestroyRef);

  protected _formGroup = new IczFormGroup({
    date: new IczFormControl<Nullable<string>>(null),
    time: new IczFormControl<Nullable<string>>(null),
  });

  private _realValue: Nullable<string> = null;

  ngOnInit() {
    this._formGroup.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(value => {
      const dateValue = value.date;
      const timeValue = value.time;

      if (dateValue && timeValue) {
        // Date.getTimezoneOffset returns offset from GMT to current timezone, we want it exactly opposite
        // way due to how ISO standard serialized timezone information so we are multiplying by -1 here.
        const timezoneOffsetToGmtMins = new Date().getTimezoneOffset() * -1;
        const timezoneOffsetToGmtHrs = timezoneOffsetToGmtMins / 60;

        let timezoneOffset = 'Z';

        if (timezoneOffsetToGmtHrs) {
          const isNegative = timezoneOffsetToGmtHrs < 0;
          const zeroPaddedHoursValue = String(Math.floor(Math.abs(timezoneOffsetToGmtHrs))).padStart(2, '0');

          timezoneOffset = isNegative ? '-' : '+';
          timezoneOffset += `${zeroPaddedHoursValue}:`;

          if (Number.isInteger(timezoneOffsetToGmtHrs)) {
            timezoneOffset += '00';
          }
          else {
            timezoneOffset += String(timezoneOffsetToGmtMins % 60).padStart(2, '0');
          }
        }

        const date = value ? `${dateValue}T${timeValue}:00.000${timezoneOffset}` : null;

        if (date) {
          this._valueChanged(date);
        }
      }
      else {
        this._realValue = null;
        this.valueChange.emit(this._realValue);
      }
    });
  }

  protected _valueChanged($event: string): void {
    this.value = $event;
    this.valueChange.emit($event);
    this.blur.emit();
  }

}
