/* eslint-disable no-console */
import {HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {clone} from 'lodash';
import {UserInteractionLogger} from '@icz/angular-essentials';

export enum LogLevel {
  VERBOSE = 'VERBOSE',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export function getFriendlyLoglevelName(logLevel: LogLevel): string {
  switch (logLevel) {
    case LogLevel.ERROR:
      return 'Chyby';
    case LogLevel.WARNING:
      return 'Varování';
    case LogLevel.INFO:
      return 'Debug logy';
    case LogLevel.VERBOSE:
      return 'Standardní logy';
  }
}

interface LogItem {
  timestamp: Date;
}

export interface LogMessage extends LogItem {
  logLevel: LogLevel;
  contentParts: any[];
}

export interface StompMessage extends LogItem {
  topic: string;
  payload: any;
}

export interface IdtInboundMessage extends LogItem {
  success: boolean;
  data: any;
}

export interface IdtOutboundMessage extends LogItem {
  method: string;
  data: any;
}

export interface UserInteractionLogMessage extends LogItem {
  description: string;
}

export interface HttpMessage extends LogItem {
  httpMethod: string;
  requestUrl: string;
  statusCode: number;
  requestHeaders: HttpHeaders;
  responseHeaders: HttpHeaders;
  requestPayload: any;
  responsePayload: any;
}

const HTTP_REQUESTS_TRACE_SIZE = 50;
const STOMP_MESSAGES_TRACE_SIZE = 10;
const CONSOLE_LOGS_TRACE_SIZE = 50;
const IDT_INBOUND_MESSAGES_TRACE_SIZE = 10;
const IDT_OUTBOUND_MESSAGES_TRACE_SIZE = 10;
const USER_INTERACTION_TRACE_SIZE = 150;

function stripPasswordFromMessage(message: any) {
  if (Array.isArray(message)) {
    for (const messageItem of message) {
      stripPasswordFromMessage(messageItem);
    }
  }
  else if (message && typeof(message) === 'object') {
    Object.keys(message).forEach(key => {
      if (key.match(/password/i)) {
        message[key] = '***';
      }
      else if (Array.isArray(message[key]) || typeof(message[key]) === 'object') {
        stripPasswordFromMessage(message[key]);
      }
    });
  }

  return message;
}

@Injectable({providedIn: 'root'})
export class DebugLoggingService implements UserInteractionLogger {

  httpRequests: Array<HttpMessage> = [];
  stompMessages: Array<StompMessage> = [];

  idtInboundMessages: Array<IdtInboundMessage> = [];
  idtOutboundMessages: Array<IdtOutboundMessage> = [];

  consoleLogsInfo: Array<LogMessage> = [];
  consoleLogsWarning: Array<LogMessage> = [];
  consoleLogsError: Array<LogMessage> = [];
  consoleLogsVerbose: Array<LogMessage> = [];

  userInteractionHistory: Array<UserInteractionLogMessage> = [];

  initialize() {
    const originalConsoleLog = console.log;
    const originalConsoleInfo = console.info;
    const originalConsoleWarn = console.warn;
    const originalConsoleError = console.error;

    console.log = (...args) => {
      this.logConsoleMessage({
        logLevel: LogLevel.VERBOSE,
        contentParts: args,
      });
      originalConsoleLog(...args);
    };
    console.info = (...args) => {
      this.logConsoleMessage({
        logLevel: LogLevel.INFO,
        contentParts: args,
      });
      originalConsoleInfo(...args);
    };
    console.warn = (...args) => {
      this.logConsoleMessage({
        logLevel: LogLevel.WARNING,
        contentParts: args,
      });
      originalConsoleWarn(...args);
    };
    console.error = (...args) => {
      this.logConsoleMessage({
        logLevel: LogLevel.ERROR,
        contentParts: args,
      });
      originalConsoleError(...args);
    };
  }

  logConsoleMessage(message: Omit<LogMessage, 'timestamp'>) {
    let targetArray: Nullable<Array<LogMessage>>;

    if (message.logLevel === LogLevel.VERBOSE) {
      targetArray = this.consoleLogsVerbose;
    }
    else if (message.logLevel === LogLevel.WARNING) {
      targetArray = this.consoleLogsWarning;
    }
    else if (message.logLevel === LogLevel.ERROR) {
      targetArray = this.consoleLogsError;
    }
    else if (message.logLevel === LogLevel.INFO) {
      targetArray = this.consoleLogsInfo;
    }

    if (targetArray) {
      targetArray.push(this.addTimestamp(message));
      if (targetArray.length > CONSOLE_LOGS_TRACE_SIZE) {
        targetArray.shift();
      }
    }
  }

  logStompMessage(message: Omit<StompMessage, 'timestamp'>) {
    this.stompMessages.push(this.addTimestamp(message));
    if (this.stompMessages.length > STOMP_MESSAGES_TRACE_SIZE) {
      this.stompMessages.shift();
    }
  }

  logIdtMessageInbound(message: Omit<IdtInboundMessage, 'timestamp'>) {
    this.idtInboundMessages.push(this.preprocessIdtMessage(this.addTimestamp(message)) as IdtInboundMessage);
    if (this.idtInboundMessages.length > IDT_INBOUND_MESSAGES_TRACE_SIZE) {
      this.idtInboundMessages.shift();
    }
  }

  logIdtMessageOutbound(message: Omit<IdtOutboundMessage, 'timestamp'>) {
    this.idtOutboundMessages.push(this.preprocessIdtMessage(this.addTimestamp(message)) as IdtOutboundMessage);
    if (this.idtOutboundMessages.length > IDT_OUTBOUND_MESSAGES_TRACE_SIZE) {
      this.idtOutboundMessages.shift();
    }
  }

  logHttpRequest(message: Omit<HttpMessage, 'timestamp'>) {
    this.httpRequests.push(this.preprocessHttpMessage(this.addTimestamp(message)));
    if (this.httpRequests.length > HTTP_REQUESTS_TRACE_SIZE) {
      this.httpRequests.shift();
    }
  }

  logUserInteraction(message: Omit<UserInteractionLogMessage, 'timestamp'>) {
    this.userInteractionHistory.push(this.addTimestamp(message));
    if (this.userInteractionHistory.length > USER_INTERACTION_TRACE_SIZE) {
      this.userInteractionHistory.shift();
    }
  }

  private addTimestamp<T>(item: T): T & LogItem {
    return {
      ...item,
      timestamp: new Date(),
    };
  }

  private preprocessHttpMessage(message: HttpMessage): HttpMessage {
    message.requestHeaders = message.requestHeaders.delete('Authorization');

    if (
      (message.httpMethod === 'GET' && message.requestUrl.includes('/codebook/')) ||
      message.requestUrl.includes('find-all') ||
      message.requestUrl.includes('organizational-unit') ||
      message.requestUrl.includes('functional-position') ||
      message.requestUrl.includes('/dictionary/') ||
      message.requestUrl.includes('/oauth/property-value/') ||
      message.requestUrl.includes('/token') ||
      message.requestUrl.includes('/assets/changelog.json')
    ) {
      return {
        ...message,
        responsePayload: `Vypuštěno (${JSON.stringify(message.responsePayload).length} Bajtů)`
      };
    }
    else {
      return message;
    }
  }

  private preprocessIdtMessage(message: IdtInboundMessage | IdtOutboundMessage): IdtInboundMessage | IdtOutboundMessage {
    return {
      ...message,
      data: stripPasswordFromMessage(clone(message.data ?? {})),
    };
  }

}
