import {Directive} from '@angular/core';
import {MatRadioGroup} from '@angular/material/radio';

/**
 * An element directive which is used to group several related radio buttons
 * such that they create a group which is validated together for selection exclusivity.
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'icz-radio-group',
  standalone: true,
})
export class IczRadioGroupDirective extends MatRadioGroup {}
