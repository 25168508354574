import {inject, Injectable} from '@angular/core';
import {tap} from 'rxjs';
import {InternalNotificationKey} from '|api/notification';
import {OwnPaperConsignmentDto, OwnPaperConsignmentElasticDto} from '|api/sad';
import {ConsignmentsToastService, ConsignmentToastData} from '../../../consignment-dialog/consignments-toast.service';
import {WithdrawForCorrectionDialogComponent} from './withdraw-for-correction-dialog.component';
import {IczModalService} from '@icz/angular-modal';


export enum WithdrawForCorrectionDialogResultType {
  WithdrawnForCorrection = 'WithdrawnForCorrection',
}

export interface WithdrawForCorrectionDialogResult {
  resultType: WithdrawForCorrectionDialogResultType;
  consignment?: OwnPaperConsignmentDto;
}

export interface WithdrawForCorrectionDialogData {
  documentId: Nullable<number>;
  consignments: OwnPaperConsignmentElasticDto[];
}

@Injectable({
  providedIn: 'root'
})
export class WithdrawForCorrectionDialogService {

  private modalService = inject(IczModalService);
  private consignmentsToastService = inject(ConsignmentsToastService);

  openWithdrawForCorrectionDialog(documentId: Nullable<number>, consignments: OwnPaperConsignmentElasticDto[]) {
    return this.modalService.openComponentInModal<Nullable<WithdrawForCorrectionDialogResult>, WithdrawForCorrectionDialogData>({
      component: WithdrawForCorrectionDialogComponent,
      modalOptions: {
        titleTemplate: 'Stažení zásilky k nápravě',
        width: 800,
        height: 400,
      },
      data: {
        documentId,
        consignments,
      },
    }).pipe(
      tap(result => {
        if (result) {
          const isBulk = consignments.length > 1;
          if (isBulk) {
            const toastData: ConsignmentToastData = {
              [InternalNotificationKey.COUNT]: consignments.length,
            };

            this.consignmentsToastService.dispatchConsignmentBulkActionAccepted(toastData);
          } else {
            const toastData: ConsignmentToastData = {
              [InternalNotificationKey.CONSIGNMENT_ID]: result.consignment!.id,
              [InternalNotificationKey.CONSIGNMENT_UID]: result.consignment!.uid!.uid!,
            };
            if (documentId) {
              toastData[InternalNotificationKey.DOCUMENT_ID] = documentId;
            }

            this.consignmentsToastService.dispatchConsignmentWithdrawnForCorrection(toastData);
          }
        }
      }),
    );
  }

}
