import {EventEmitter} from '@angular/core';
import {isEqual} from 'lodash';
import {combineLatest, Observable, of, pipe} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';

import {IczOption} from '../form-elements.model';

/*
 * Will find an Option object corresponding to a scalar value (usually a string).
 */
export type ValueToOptionOperator<O> = ((value$: Observable<any>) => Observable<O>);
/*
 * Will find appropriate Options based on a search term which is a string.
 */
export type SearchtermToOptionsOperator<O> = ((searchTerm$: Observable<string>) => Observable<O[]>);
/**
 * Will find a default value in case the term is empty.
 * @deprecated
 */
export type DefaultOptionValue$ = () => Observable<any>;

/**
 * @internal
 * @deprecated - bound to unreliable and not interoperable functionalities of autocomplete. We also might decide
 *   to remove this construct at any time. Please avoid using it.
 */
export interface FormOptionsDefinitionGen<O extends IczOption> {
  valueToOptionOperator: ValueToOptionOperator<O>;
  searchtermToOptionsOperator: SearchtermToOptionsOperator<O>;
  defaultOptionValue$?: DefaultOptionValue$;
}

/**
 * @internal
 * @deprecated - bound to unreliable and not interoperable functionalities of autocomplete. We also might decide
 *   to remove this construct at any time. Please avoid using it.
 */
export interface FormOptionsDefinition extends FormOptionsDefinitionGen<IczOption> {}

/**
 * @internal
 */
export type FormOptions = IczOption[] | Observable<IczOption[]>;

/**
 * Contexts required to be implemented for ng-template outlets
 */
interface ISelectedOptionInputs {
  /**
   * An array of currently selected options.
   */
  selectedOptions: IczOption[];
}

/**
 * @internal
 */
interface IPopoverInputs {
  options: IczOption[];
  selectedOptions: IczOption[];
}

/**
 * @internal
 */
interface IPopoverOutputs {
  selectionChanged: (selectedOptions: IczOption[]) => void;
  searchTermChanged: (searchTerm: string) => void;
}

/**
 * @internal
 */
export interface ISelectedOptionContext extends ISelectedOptionInputs {}

/**
 * @internal
 */
export interface IPopoverContext extends IPopoverInputs, IPopoverOutputs {}

/**
 * @internal
 */
export interface IAutocompleteListItemContext {
  option: IczOption;
}

/**
 * @internal
 */
export interface IImplicitTemplateContext<T> {
  $implicit: T;
}

/**
 * This helper type allows to strongly type the components that
 * are gonna be passed the ISelectedOptionContext and IPopoverContext
 * and IAutocompleteListItemContext typed objects.
 */
export type ComponentWithContext<C> = {
  [K in keyof C]: (
    C[K] extends (oneArgument: any) => void ? // is it a handler?
      EventEmitter<Parameters<C[K]>[0]> : // then the component should have corresponding event emitter
      C[K]
    )
};

/**
 * A factory function that prepares simple operator definitions.
 *
 * @deprecated - bound to unreliable and not interoperable functionalities of autocomplete. We also might decide
 *   to remove this construct at any time. Please avoid using it.
 *
 * @param options$ observable of options list - because the source of the options may be
 *                                              either synchronous or asynchronous value
 * @param strForSearch a function for generating string for full-text search from option properties
 */
export type OptionsDefinitionFactory = (options$: Observable<IczOption[]>, strForSearch: (s: IczOption) => string) => FormOptionsDefinition;

/**
 * Options definition factory used for finite lists.
 * @deprecated
 * @see OptionsDefinitionFactory
 */
export function makeDefaultOptionsDefinition(
  options$: Observable<IczOption[]>,
  strForSearch: (s: IczOption) => string,
): FormOptionsDefinition {
  const sharedOptions$ = options$.pipe(
    map(options => options || []),
    shareReplay(1),
  );

  const valueToOptionOperator: ValueToOptionOperator<IczOption> = value$ =>
    combineLatest([value$, sharedOptions$]).pipe(
      // find option for given value by isEqual deep comparison
      map(([value, options]) => options.find(option => isEqual(option.value, value))!),
    );

  const searchtermToOptionsOperator: SearchtermToOptionsOperator<IczOption> = pipe(
    map(searchterm => searchterm.toLowerCase()),
    searchterm$ => combineLatest([searchterm$, sharedOptions$]),
    // select the options with label containing the search string
    map(([searchTerm, options]) => options.filter((option: IczOption) => {
        const str = strForSearch(option);
        if (!str) return !searchTerm;
        return str.toLowerCase().includes(searchTerm.toLowerCase());
      }),
    ),
  );

  return {valueToOptionOperator, searchtermToOptionsOperator, defaultOptionValue$: () => of(null)};
}
