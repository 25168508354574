import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {ModalsModule} from '@icz/angular-modal';
import {EssentialsModule} from '@icz/angular-essentials';
import {FormElementsModule} from '@icz/angular-form-elements';
import {HighlightsModule} from '../highlights/highlights.module';
import {TableModule} from '@icz/angular-table';
import {ActivityStateTagComponent} from './activity-state-tag/activity-state-tag.component';
import {ActivityTypeDesignatorComponent} from './activity-type-designator/activity-type-designator.component';
import {EntityClassSelectorComponent} from './entity-class-selector/entity-class-selector.component';
import {
  AddAnalogComponentToDocumentComponent,
} from './add-analog-component-to-document/add-analog-component-to-document.component';
import {
  AnalogComponentCreateFormComponent,
} from './analog-component-create-form/analog-component-create-form.component';
import {CirculationTableComponent} from './circulation-table/circulation-table.component';
import {ActivityNotesComponent} from './circulation-table/components/activity-notes/activity-notes.component';
import {
  IssdTaskRejectedNotesComponent,
} from './circulation-table/components/issd-task-rejected-notes/issd-task-rejected-notes.component';
import {
  ApplicableDeliveryTypeSelectorComponent,
} from './consignment-dialog/consignment-dialog/applicable-delivery-type-selector/applicable-delivery-type-selector.component';
import {
  ApplicableOutcomeDistributionNodeSelectorComponent,
} from './consignment-dialog/consignment-dialog/applicable-outcome-distribution-node-selector/applicable-outcome-distribution-node-selector.component';
import {
  ConsignmentComponentSelectionComponent,
} from './consignment-dialog/consignment-dialog/consignment-component-selection/consignment-component-selection.component';
import {
  ConsignmentConsigneeCreationComponent,
} from './consignment-dialog/consignment-dialog/consignment-consignee-creation/consignment-consignee-creation.component';
import {
  ConsignmentConsigneeSelectionComponent,
} from './consignment-dialog/consignment-dialog/consignment-consignee-selection/consignment-consignee-selection.component';
import {
  ConsignmentConsigneesOverviewComponent,
} from './consignment-dialog/consignment-dialog/consignment-consignees-overview/consignment-consignees-overview.component';
import {
  ConsignmentDialogButtonsComponent,
} from './consignment-dialog/consignment-dialog/consignment-dialog-buttons/consignment-dialog-buttons.component';
import {ConsignmentDialogComponent} from './consignment-dialog/consignment-dialog/consignment-dialog.component';
import {
  ConsignmentMetaOverviewComponent,
} from './consignment-dialog/consignment-dialog/consignment-meta-overview/consignment-meta-overview.component';
import {
  ConsignmentOrgUnitSelectionComponent,
} from './consignment-dialog/consignment-dialog/consignment-org-unit-selection/consignment-org-unit-selection.component';
import {
  ConsignmentValidationComponent,
} from './consignment-dialog/consignment-dialog/consignment-validation/consignment-validation.component';
import {
  DataboxConsignmentSpecificationComponent,
} from './consignment-dialog/consignment-dialog/databox-consignment-specification/databox-consignment-specification.component';
import {
  DeliveryServiceCombinationSelectorComponent,
} from './consignment-dialog/consignment-dialog/delivery-service-combination-selector/delivery-service-combination-selector.component';
import {
  AddSubjectEmailDialogComponent,
} from './consignment-dialog/consignment-dialog/email-consignment-specification/add-subject-email-dialog/add-subject-email-dialog.component';
import {
  EmailConsignmentSpecificationComponent,
} from './consignment-dialog/consignment-dialog/email-consignment-specification/email-consignment-specification.component';
import {
  OfficeDeskConsignmentSpecificationComponent,
} from './consignment-dialog/consignment-dialog/office-desk-consignment-specification/office-desk-consignment-specification.component';
import {
  ConsignmentAddressDialogComponent,
} from './consignment-dialog/consignment-dialog/paper-consignment-specification/consignment-address-dialog/consignment-address-dialog.component';
import {
  DeliveryServiceCombinationInfoComponent,
} from './consignment-dialog/consignment-dialog/paper-consignment-specification/delivery-service-combination-info/delivery-service-combination-info.component';
import {
  EnvelopePreviewComponent,
} from './consignment-dialog/consignment-dialog/paper-consignment-specification/envelope-preview/envelope-preview.component';
import {
  PaperOrPersonalOrInternalConsignmentSpecificationComponent,
} from './consignment-dialog/consignment-dialog/paper-consignment-specification/paper-or-personal-or-internal-consignment-specification.component';
import {
  ConstrainedDocumentFileSidebarComponent,
} from './constrained-document-file-sidebar/constrained-document-file-sidebar.component';
import {DeliveryInfoDataboxComponent} from './delivery-info-databox/delivery-info-databox.component';
import {DigitalComponentFlagsComponent} from './digital-component-flags/digital-component-flags.component';
import {DigitalComponentSelectorComponent} from './digital-component-selector/digital-component-selector.component';
import {
  DigitalComponentManualSignaturePositionComponent,
} from './digital-component-signature/digital-component-manual-signature-position/digital-component-manual-signature-position.component';
import {
  DigitalComponentPreSignatureValidationDialogComponent,
} from './digital-component-signature/digital-component-pre-signature-validation-dialog/digital-component-pre-signature-validation-dialog.component';
import {
  DigitalComponentSignatureConfigurationDialogComponent,
} from './digital-component-signature/digital-component-signature-configuration-dialog/digital-component-signature-configuration-dialog.component';
import {
  DigitalComponentsUploadFormComponent,
} from './digital-components-upload-form/digital-components-upload-form.component';
import {
  DigitalMessageSignatureStatusComponent,
} from './digital-message-signature-status/digital-message-signature-status.component';
import {
  BulkPostingFormDialogComponent,
} from './dispatch-dialogs/components/bulk-posting-form/bulk-posting-form-dialog.component';
import {
  BulkPostingFormTemplateNameDialogComponent,
} from './dispatch-dialogs/components/bulk-posting-form/bulk-posting-form-template-name-dialog/bulk-posting-form-template-name-dialog.component';
import {
  PrepareOrDispatchDialogComponent,
} from './dispatch-dialogs/components/prepare-or-dispatch-dialog/prepare-or-dispatch-dialog.component';
import {RejectDialogComponent} from './dispatch-dialogs/components/reject-dialog/reject-dialog.component';
import {
  WithdrawForCorrectionDialogComponent,
} from './dispatch-dialogs/components/withdraw-for-correction-dialog/withdraw-for-correction-dialog.component';
import {DispatchOfficeSelectorComponent} from './dispatch-office-selector/dispatch-office-selector.component';
import {DisposalTipComponent} from './disposal-tip/disposal-tip.component';
import {
  DocumentFileInsufficientPermissionsAlertComponent,
} from './document-file-insufficient-permissions-alert/document-file-insufficient-permissions-alert.component';
import {
  DocumentFileSidebarTabDeliveryComponent,
} from './document-file-sidebar/document-file-sidebar-tab-delivery/document-file-sidebar-tab-delivery.component';
import {
  DocumentFileSidebarTabOverviewComponent,
} from './document-file-sidebar/document-file-sidebar-tab-overview/document-file-sidebar-tab-overview.component';
import {
  DocumentFileSidebarTabRelatedComponent,
} from './document-file-sidebar/document-file-sidebar-tab-related/document-file-sidebar-tab-related.component';
import {
  DocumentFileSidebarTabSubjectsComponent,
} from './document-file-sidebar/document-file-sidebar-tab-subjects/document-file-sidebar-tab-subjects.component';
import {DocumentFileSidebarComponent} from './document-file-sidebar/document-file-sidebar.component';
import {
  DocumentRegistrationTimelineLinkComponent,
} from './document-registration-timeline-link/document-registration-timeline-link.component';
import {
  DocumentRegistrationTimelineComponent,
} from './document-registration-timeline/document-registration-timeline.component';
import {
  DocumentRelevanceDetailsComponent,
} from './document-table/components/document-relevance-details/document-relevance-details.component';
import {
  IssdEntityActivityIconComponent,
} from './document-table/components/issd-entity-activity-icon/issd-entity-activity-icon.component';
import {TableEntityIconComponent} from './document-table/components/table-entity-icon/table-entity-icon.component';
import {DocumentsTableComponent} from './document-table/documents-table.component';
import {AddToFileDialogComponent} from './document-toolbar/components/add-to-file-dialog/add-to-file-dialog.component';
import {
  BulkOperationErrorsDialogComponent,
} from './document-toolbar/components/bulk-operation-errors-dialog/bulk-operation-errors-dialog.component';
import {RevokeTaskDialogComponent} from './document-toolbar/components/cancel-task-dialog/revoke-task-dialog.component';
import {
  DocumentToolbarButtonsComponent,
} from './document-toolbar/components/document-toolbar-buttons/document-toolbar-buttons.component';
import {
  GenericPassToNextHandlerDialogComponent,
} from './document-toolbar/components/generic-pass-to-next-handler-dialog/generic-pass-to-next-handler-dialog.component';
import {
  HandForAcknowledgementDialogComponent,
} from './document-toolbar/components/hand-for-acknowledgement/hand-for-acknowledgement-dialog.component';
import {
  HandForAcknowledgementSpecificationComponent,
} from './document-toolbar/components/hand-for-acknowledgement/hand-for-acknowledgement-specification/hand-for-acknowledgement-specification.component';
import {
  HandForApprovalDialogComponent,
} from './document-toolbar/components/hand-for-approval-dialog/hand-for-approval-dialog.component';
import {
  HandForStatementDialogComponent,
} from './document-toolbar/components/hand-for-statement-dialog/hand-for-statement-dialog.component';
import {HandoverDialogComponent} from './document-toolbar/components/handover-dialog/handover-dialog.component';
import {
  MoveToAnotherFileDialogComponent,
} from './document-toolbar/components/move-to-another-file-dialog/move-to-another-file-dialog.component';
import {
  PassHandoverToNextHandlerDialogComponent,
} from './document-toolbar/components/pass-handover-to-next-handler-dialog/pass-handover-to-next-handler-dialog.component';
import {ReregisterDialogComponent} from './document-toolbar/components/reregister-dialog/reregister-dialog.component';
import {
  WorkflowTextResponseDialogComponent,
} from './document-toolbar/components/workflow-text-response-dialog/workflow-text-response-dialog.component';
import {DocumentTypeSelectorComponent} from './document-type-selector/document-type-selector.component';
import {EmailMetadataPreviewComponent} from './email-preview/email-metadata-preview/email-metadata-preview.component';
import {EmailPreviewComponent} from './email-preview/email-preview.component';
import {EntityItemComponent} from './entity-item/entity-item.component';
import {
  EnvelopeOrLabelCustomFieldsFormComponent,
} from './envelope-or-label-custom-fields-form/envelope-or-label-custom-fields-form.component';
import {
  EnvelopeOrLabelPreviewCanvasComponent,
} from './envelope-or-label-preview-canvas/envelope-or-label-preview-canvas.component';
import {
  EnvelopeOrLabelTestConsigneeFormComponent,
} from './envelope-or-label-test-consignee-form/envelope-or-label-test-consignee-form.component';
import {EnvelopePrintDialogComponent} from './envelope-print-dialog/envelope-print-dialog.component';
import {
  EpdzAttachmentSubsetSelectorComponent,
} from './epdz-attachment-subset-selector/epdz-attachment-subset-selector.component';
import {EsslComponentLinkComponent} from './essl-component-link/essl-component-link.component';
import {EsslComponentViewerDialogComponent} from './essl-component-viewer/essl-component-viewer-dialog.component';
import {EsslComponentsTableComponent} from './essl-components-table/essl-components-table.component';
import {
  AdvancedEsslComponentMetadataComponent,
} from './essl-components/components/advanced-essl-component-metadata/advanced-essl-component-metadata.component';
import {
  AnalogComponentCreateDialogComponent,
} from './essl-components/components/analog-component-create-dialog/analog-component-create-dialog.component';
import {
  AuthorizedConversionAnalogToDigitalDialogComponent,
} from './essl-components/components/authorized-conversion-analog-to-digital-dialog/authorized-conversion-analog-to-digital-dialog.component';
import {
  DigitalComponentMarkTimestampDialogComponent,
} from './essl-components/components/digital-component-mark-timestamp-dialog/digital-component-mark-timestamp-dialog.component';
import {
  DigitalComponentPreMarkExtendValidationDialogComponent,
} from './essl-components/components/digital-component-pre-mark-extend-validation-dialog/digital-component-pre-mark-extend-validation-dialog.component';
import {
  DigitalComponentSignatureDetailDialogComponent,
} from './essl-components/components/digital-component-signature-detail-dialog/digital-component-signature-detail-dialog.component';
import {
  DigitalComponentSignatureInfoComponent,
} from './essl-components/components/digital-component-signature-detail-dialog/digital-component-signature-info/digital-component-signature-info.component';
import {
  DigitalComponentUploadDialogComponent,
} from './essl-components/components/digital-component-upload-dialog/digital-component-upload-dialog.component';
import {
  EsslComponentsToolbarButtonsComponent,
} from './essl-components/components/essl-components-toolbar-buttons/essl-components-toolbar-buttons.component';
import {
  HandComponentForApprovalDialogComponent,
} from './essl-components/components/hand-component-for-approval-dialog/hand-component-for-approval-dialog.component';
import {
  HandForSigningDialogComponent,
} from './essl-components/components/hand-for-signing-dialog/hand-for-signing-dialog.component';
import {FileSelectorComponent} from './file-selector/file-selector.component';
import {FileHandoverDialogComponent} from './file-toolbar/file-handover-dialog/file-handover-dialog.component';
import {FileUploadListComponent} from './file-upload-list/file-upload-list.component';
import {FindFunctionalPositionByIdPipe} from './find-functional-position-by-id.pipe';
import {FindOrganizationalUnitByIdPipe} from './find-organizational-unit-by-id.pipe';
import {FindUserByIdPipe} from './find-user-by-id.pipe';
import {FindUserByUsernamePipe} from './find-user-by-name.pipe';
import {
  OrgStructureSelectionLoaderComponent,
} from './functional-position-loader/org-structure-selection-loader.component';
import {OrgStructureSelectionSaverComponent} from './functional-position-saver/org-structure-selection-saver.component';
import {OrgStructureSelectorComponent} from './functional-position-selector/org-structure-selector.component';
import {
  HandoverToExternalAppDialogComponent,
} from './handover-to-external-app-dialog/handover-to-external-app-dialog.component';
import {HighlightableTextContentComponent} from './highlightable-text-content/highlightable-text-content.component';
import {KeywordsSelectorComponent} from './keyword-selector/keywords-selector.component';
import {NotificationCardComponent} from './notification-card/notification-card.component';
import {ObjectClassIconComponent} from './object-class-icon/object-class-icon.component';
import {ObjectClassNamePipe} from './object-class-name.pipe';
import {
  ConsignmentDeliveryResultComponent,
} from './own-consignment-table/components/consignment-delivery-result/consignment-delivery-result.component';
import {
  DataboxProofOfDeliveryComponent,
} from './own-consignment-table/components/databox-proof-of-delivery/databox-proof-of-delivery.component';
import {
  ManualDeliveryResultDialogComponent,
} from './own-consignment-table/components/manual-delivery-result-dialog/manual-delivery-result-dialog.component';
import {OwnConsignmentTableComponent} from './own-consignment-table/own-consignment-table.component';
import {
  ConsignmentToolbarButtonsComponent,
} from './own-consignment-toolbar/consignment-toolbar-buttons/consignment-toolbar-buttons.component';
import {OwnDocumentFormComponent} from './own-document-form/own-document-form.component';
import {PageCornerFormFragmentComponent} from './page-corner-widget/page-corner-form-fragment.component';
import {PageDesignatorFormFragmentComponent} from './page-designator-widget/page-designator-form-fragment.component';
import {ParentDocumentInfoComponent} from './parent-document-info/parent-document-info.component';
import {PermissionAddToolbarComponent} from './permissions/permission-add-toolbar/permission-add-toolbar.component';
import {
  PermissionSelectorFormComponent,
} from './permissions/permission-selector-form/permission-selector-form.component';
import {
  PermissionSelectorPopupComponent,
} from './permissions/permission-selector-popup/permission-selector-popup.component';
import {PermissionSelectorComponent} from './permissions/permission-selector/permission-selector.component';
import {
  PermissionSettingsTableComponent,
} from './permissions/permission-settings-table/permission-settings-table.component';
import {HighlightFragmentForFieldPipe} from './pipes/highlight-fragment-for-field.pipe';
import {ShouldHighlightEverythingPipe} from './pipes/should-highlight-everything.pipe';
import {
  PrimaryFunctionalPositionPickerComponent,
} from './primary-functional-position-picker/primary-functional-position-picker.component';
import {
  ActivityStepsPreviewComponent,
} from './quick-task-preview/activity-steps-preview/activity-steps-preview.component';
import {QuickActivityPreviewComponent} from './quick-task-preview/quick-activity-preview.component';
import {
  SubprocessDesignatorComponent,
} from './quick-task-preview/subprocess-designator/subprocess-designator.component';
import {
  ReceiveConsignmentSetSenderComponent,
} from './receive-consignment-set-sender/receive-consignment-set-sender.component';
import {
  ReceivePaperConsignmentSetDispatchComponent,
} from './receive-paper-consignment-set-delivery/receive-paper-consignment-set-dispatch.component';
import {ReceivedDocumentFormComponent} from './received-document-form/received-document-form.component';
import {RefNumberLinkComponent} from './ref-number-link/ref-number-link.component';
import {SecurityCategorySelectorComponent} from './security-category-selector/security-category-selector.component';
import {SenderInfoDataboxComponent} from './sender-info-databox/sender-info-databox.component';
import {SheetLabelPrintDialogComponent} from './sheet-label-print-dialog/sheet-label-print-dialog.component';
import {SubjectsModule} from './subjects/subjects.module';
import {TaskActivityStateIconComponent} from './task-activity-state-icon/task-activity-state-icon.component';
import {TaskInfoBitComponent} from './task-info-bit/task-info-bit.component';
import {TaskStateTagComponent} from './task-info-bit/task-state-tag/task-state-tag.component';
import {TaskTypeDesignatorComponent} from './task-type-designator/task-type-designator.component';
import {UnitSelectorComponent} from './unit-selector/unit-selector.component';
import {
  TelefaxConsignmentSpecificationComponent,
} from './consignment-dialog/consignment-dialog/telefax-consignment-specification/telefax-consignment-specification.component';
import {TakeoverByBarcodeDialogComponent} from './takeover-by-barcode-dialog/takeover-by-barcode-dialog.component';
import {
  BarcodeScannedConsignmentsTableComponent,
} from './barcode-scanned-consignments-table/barcode-scanned-consignments-table.component';
import {
  ConsignmentTableAddressComponent,
} from './own-consignment-table/components/consignment-table-address/consignment-table-address.component';
import {
  ConsignmentTableConsigneeComponent,
} from './own-consignment-table/components/consignment-table-consignee/consignment-table-consignee.component';
import {
  ConsignmentChangeDeliveryServiceDialogComponent,
} from './takeover-by-barcode-dialog/consignment-change-delivery-service-dialog/consignment-change-delivery-service-dialog.component';
import {PrinterSelectionDialogComponent} from './printer-selection-dialog/printer-selection-dialog.component';
import {IdtMalfunctionAlertComponent} from './idt-malfunction-alert/idt-malfunction-alert.component';
import {FileTypeSelectorComponent} from './file-type-selector/file-type-selector.component';
import {IdtVersionInfoComponent} from './idt-version-info/idt-version-info.component';
import {
  ConsignmentDeliveryResultOverviewComponent,
} from './consignment-dialog/consignment-dialog/consignment-delivery-result-overview/consignment-delivery-result-overview.component';
import {
  BulkOperationValidationDialogComponent,
} from './bulk-operation-validation-dialog/bulk-operation-validation-dialog.component';
import {
  AvailableCirculationActionsDialogComponent,
} from './document-toolbar/components/available-actions-dialog/available-circulation-actions-dialog.component';
import {
  PrepareOrDispatchFormComponent,
} from './dispatch-dialogs/components/prepare-or-dispatch-dialog/prepare-or-dispatch-form/prepare-or-dispatch-form.component';
import {KeyValuePairComponent} from './key-value-pair/key-value-pair.component';
import {
  ReceiveDocumentDigitalComponentsComponent,
} from './receive-document-digital-components/receive-document-digital-components.component';
import {OwnConsignmentTypeIconComponent} from './own-consignment-type-icon/own-consignment-type-icon.component';
import {
  DevCreateConsignmentDialogComponent,
} from './own-consignment-toolbar/consignment-toolbar-buttons/dev-create-consignment-dialog/dev-create-consignment-dialog.component';
import {
  ReceiveOrRegisterReceivedConsignmentComponent,
} from './receive-or-register-received-consignment/receive-or-register-received-consignment.component';
import {
  ReceivedDocumentWithHandoverFormComponent,
} from './received-document-with-handover-form/received-document-with-handover-form.component';
import {
  ReceiveConsignmentDisplaySenderComponent,
} from './receive-consignment-display-sender/receive-consignment-display-sender.component';
import {DocumentRegisterContentComponent} from './document-register-content/document-register-content.component';
import {
  ExecutionDateClauseCreateDialogComponent,
} from './essl-components/components/execution-date-clause-create-dialog/execution-date-clause-create-dialog.component';
import {
  BulkPostingFormDialogConsignmentsComponent
} from './dispatch-dialogs/components/bulk-posting-form/bulk-posting-form-dialog-consignments/bulk-posting-form-dialog-consignments.component';
import {NoOfficialContentDialogComponent} from './no-official-content-dialog/no-official-content-dialog.component';
import {EntityInfoComponent} from './entity-info/entity-info.component';
import {
  PrintDocumentInfoDialogComponent
} from './document-toolbar/components/print-document-rendition-dialog/print-document-info-dialog.component';
import {
  PrintFileInfoDialogComponent
} from './document-toolbar/components/print-file-info-dialog/print-file-info-dialog.component';
import {
  FileRenditionDialogComponent
} from './document-toolbar/components/file-rendition-dialog/file-rendition-dialog.component';
import {DocumentSelectorComponent} from './document-selector/document-selector.component';
import {RejectEmailMessageDialogComponent} from './reject-email-message-dialog/reject-email-message-dialog.component';
import {
  ReceivedDigitalMessagesTableComponent
} from './received-digital-messages-table/received-digital-messages-table.component';
import {EpdzAttachmentViewerComponent} from './epdz-attachment-viewer/epdz-attachment-viewer.component';
import {EpdzAttachmentLinkComponent} from './epdz-attachment-link/epdz-attachment-link.component';
import {DigitalMessageIssuesComponent} from './digital-message-issues/digital-message-issues.component';
import {EpdzErrorsToolbarButtonsComponent} from './epdz-errors-toolbar-buttons/epdz-errors-toolbar-buttons.component';
import {
  DigitalMessageProcessingErrorsComponent
} from './digital-message-processing-errors/digital-message-processing-errors.component';
import {
  DigitalComponentFromTemplateDialogComponent
} from './essl-components/components/digital-component-from-template-dialog/digital-component-from-template-dialog.component';
import {
  EsslComponentsTakeoverFromDocumentDialogComponent
} from './essl-components/components/essl-components-takeover-from-document-dialog/essl-components-takeover-from-document-dialog.component';
import {OwnDocumentSelectorComponent} from './own-document-selector/own-document-selector.component';
import {
  RegistryOfficeTransferIncidentsTableComponent
} from './registry-office-transfer-incidents-table/registry-office-transfer-incidents-table.component';
import {
  RegistryOfficeTransferIncidentToolbarButtonsComponent
} from './registry-office-transfer-incidents-table/registry-office-transfer-incident-toolbar-buttons/registry-office-transfer-incident-toolbar-buttons.component';
import {
  RegistryOfficeTransferIncidentDialogComponent
} from './registry-office-transfer-incidents-table/registry-office-transfer-incident-dialog/registry-office-transfer-incident-dialog.component';
import {
  QuickIncidentsListComponent
} from './document-table/components/quick-incidents-list/quick-incidents-list.component';
import {
  RegistryOfficeIncidentResolutionDialogComponent
} from './registry-office-transfer-incidents-table/registry-office-transfer-incident-toolbar-buttons/registry-office-incident-resolution-dialog/registry-office-incident-resolution-dialog.component';
import {
  CreateEmailConsignmentDialogComponent
} from './create-email-consignment-dialog/create-email-consignment-dialog.component';
import {
  QuickIncidentsListIconComponent
} from './document-table/components/quick-incidents-list-icon/quick-incidents-list-icon.component';
import {
  UpdateComponentTemplateDialogComponent
} from './essl-components/components/update-component-template-dialog/update-component-template-dialog.component';
import {
  MembersWithPermissionsTableComponent
} from './members-with-permissions-table/members-with-permissions-table.component';
import {SharedFolderSelectorComponent} from './shared-folder-selector/shared-folder-selector.component';
import {
  SharedFolderSelectionDialogComponent
} from './document-toolbar/components/shared-folder-selection-dialog/shared-folder-selection-dialog.component';
import {DocumentOrFileSelectorComponent} from './document-or-file-selector/document-or-file-selector.component';
import {
  SharedFolderInsertDialogComponent
} from './document-toolbar/components/shared-folder-insert-dialog/shared-folder-insert-dialog.component';
import {
  DocumentSettleDialogComponent
} from './document-toolbar/components/document-settle-dialog/document-settle-dialog.component';
import {
  EpdzContainerContentsDialogComponent
} from './epdz-container-contents-dialog/epdz-container-contents-dialog.component';
import {
  DocumentSettleErrorDialogComponent
} from './document-toolbar/components/document-settle-dialog/document-settle-error-dialog/document-settle-error-dialog.component';
import {
  DocumentSettleByDocumentFormComponent
} from './document-toolbar/components/document-settle-dialog/document-settle-by-document-form/document-settle-by-document-form.component';
import {
  ContainerContentsTableComponent
} from './essl-component-viewer/container-contents-table/container-contents-table.component';
import {
  DocumentSettleComponent
} from './document-toolbar/components/document-settle-dialog/document-settle/document-settle.component';
import {
  DocumentSettleGeneralSettingComponent
} from './document-toolbar/components/document-settle-dialog/document-settle-general-setting/document-settle-general-setting.component';
import {MoneyPipe} from './money.pipe';
import {EmpowermentPipe} from './empowerment.pipe';
import {
  PlainComponentVersionViewerDialogComponent
} from './plain-component-version-viewer-dialog/plain-component-version-viewer-dialog.component';
import {SharedTemplateCollectionComponent} from './shared-template-collection/shared-template-collection.component';
import {ThemeOutletComponent} from './theme-outlet/theme-outlet.component';
import {InterpolateBackendErrorPipe} from './interpolate-backend-error.pipe';
import {StatusCardComponent} from './status-card/status-card.component';
import {HtmlOutletComponent} from './html-outlet/html-outlet.component';
import {FileContentsViewerComponent} from './file-contents-viewer/file-contents-viewer.component';
import {RtfOutletComponent} from './rtf-outlet/rtf-outlet.component';
import {ButtonCollectionModule} from '../button-collection/button-collection.module';
import {LibraryImportModule} from '../.module-library-import/library-import.module';
import {EsslComponentComponent} from './digital-component/essl-component.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {ValidityWidgetComponent} from './validity-widget/validity-widget.component';
import {ConsignmentPostingNumberComponent} from './consignment-posting-number/consignment-posting-number.component';
import {EmpowermentModule} from '../empowerment/empowerment.module';
import {IczFormDirectivesModule} from '../icz-form-directives/icz-form-directives.module';
import {FormMoneyInputComponent} from './form-money-input/form-money-input.component';
import {SharedBusinessFieldValueAccessorDirective} from './shared-business-field.value-accessor';
import {AddressPipeModule} from '../address-pipe/address-pipe.module';
import {WizardModule} from '../wizard/wizard.module';
import {DialogsModule} from '../dialogs/dialogs.module';
import {
  DigitalComponentAnonymizationDialogComponent
} from './essl-components/components/digital-component-anonymization-dialog/digital-component-anonymization-dialog.component';
import {TableTreeviewComponent} from './table-treeview/table-treeview.component';
import {TableTreeMenuComponent} from './table-tree-menu/table-tree-menu.component';
import {TableExpansionPanelComponent} from './table-expansion-panel/table-expansion-panel.component';
import {
  EmpowermentFilterComponent
} from './custom-filters/empowerment-custom-filter/empowerment-filter/empowerment-filter.component';
import {
  EmpowermentCustomFilterComponent
} from './custom-filters/empowerment-custom-filter/empowerment-custom-filter.component';
import {
  VerifyConversionClauseDialogComponent
} from './essl-components/components/verify-conversion-clause-dialog/verify-conversion-clause-dialog.component';
import {
  DocumentSettleFormComponent
} from './document-toolbar/components/document-settle-dialog/document-settle-form/document-settle-form.component';
import {
  RetentionTriggerTypeWidgetComponent
} from './retention-trigger-type-widget/retention-trigger-type-widget.component';
import {
  DisposalScheduleComparatorComponent
} from './disposal-schedule-comparator/disposal-schedule-comparator.component';
import {
  EntityClassTreeDetailComponent
} from './entity-class-selector/entity-class-tree-detail/entity-class-tree-detail.component';
import {
  DisposalScheduleRetentionTriggerWidgetComponent
} from './disposal-schedule-retention-trigger-widget/disposal-schedule-retention-trigger-widget.component';
import {
  ExtRetentionTriggerSelectorComponent
} from './ext-retention-trigger-selector/ext-retention-trigger-selector.component';
import {
  DocumentTypeSelectorPopupComponent
} from './document-type-selector/document-type-selector-popup/document-type-selector-popup.component';
import {
  EntityClassSelectorPopupComponent
} from './entity-class-selector/entity-class-selector-popup/entity-class-selector-popup.component';
import {
  BarcodeScannedConsignmentsFormComponent
} from './barcode-scanned-consignments-form/barcode-scanned-consignments-form.component';
import {
  ManualDeliveryResultFormComponent
} from './own-consignment-table/components/manual-delivery-result-form/manual-delivery-result-form.component';
import {
  ManualDeliveryResultByBarcodeDialogComponent
} from './own-consignment-table/components/manual-delivery-result-by-barcode/manual-delivery-result-by-barcode.component';
import {
  StorageUnitFillerDialogComponent
} from './document-toolbar/components/storage-unit-filler-dialog/storage-unit-filler-dialog.component';
import {
  MoveToTransferEnvelopeDialogComponent
} from './document-toolbar/components/move-to-transfer-envelope-dialog/move-to-transfer-envelope-dialog.component';
import {
  ConfirmAgendaTransferDialogComponent
} from './document-toolbar/components/confirm-agenda-transfer-dialog/confirm-agenda-transfer-dialog.component';
import {
  DocumentFileCustomFieldsSectionComponent
} from './document-file-custom-fields-section/document-file-custom-fields-section.component';
import {
  DigitalComponentExistingSignatureDecisionDialogComponent
} from './digital-component-signature/digital-component-existing-signature-decision-dialog/digital-component-existing-signature-decision-dialog.component';
import {
  StorageUnitFillerSelectionDialogComponent
} from './document-toolbar/components/storage-unit-filler-dialog/storage-unit-filler-selection-dialog/storage-unit-filler-selection-dialog.component';
import {
  PortalConsignmentSpecificationComponent
} from './consignment-dialog/consignment-dialog/portal-consignment-specification/portal-consignment-specification.component';

@NgModule({
  declarations: [
    ActivityNotesComponent,
    ActivityStateTagComponent,
    AddAnalogComponentToDocumentComponent,
    AnalogComponentCreateFormComponent,
    BulkPostingFormDialogComponent,
    BulkPostingFormDialogConsignmentsComponent,
    BulkPostingFormTemplateNameDialogComponent,
    DeliveryInfoDataboxComponent,
    DigitalComponentFlagsComponent,
    DigitalComponentManualSignaturePositionComponent,
    DigitalComponentExistingSignatureDecisionDialogComponent,
    DigitalComponentPreSignatureValidationDialogComponent,
    DigitalComponentSignatureConfigurationDialogComponent,
    DigitalComponentsUploadFormComponent,
    DigitalMessageSignatureStatusComponent,
    DispatchOfficeSelectorComponent,
    DisposalTipComponent,
    DocumentTypeSelectorComponent,
    EmailMetadataPreviewComponent,
    EmailPreviewComponent,
    EntityItemComponent,
    EnvelopeOrLabelCustomFieldsFormComponent,
    EnvelopeOrLabelPreviewCanvasComponent,
    EnvelopeOrLabelTestConsigneeFormComponent,
    EnvelopePrintDialogComponent,
    EpdzAttachmentSubsetSelectorComponent,
    FileSelectorComponent,
    DocumentSelectorComponent,
    FileUploadListComponent,
    FindFunctionalPositionByIdPipe,
    FindOrganizationalUnitByIdPipe,
    FindUserByIdPipe,
    FindUserByUsernamePipe,
    HandoverToExternalAppDialogComponent,
    HighlightFragmentForFieldPipe,
    HighlightableTextContentComponent,
    KeywordsSelectorComponent,
    NotificationCardComponent,
    OrgStructureSelectionLoaderComponent,
    OrgStructureSelectionSaverComponent,
    OrgStructureSelectorComponent,
    PageCornerFormFragmentComponent,
    PageDesignatorFormFragmentComponent,
    PrepareOrDispatchDialogComponent,
    PrepareOrDispatchFormComponent,
    ReceiveConsignmentSetSenderComponent,
    ReceivePaperConsignmentSetDispatchComponent,
    ReceivedDocumentFormComponent,
    RefNumberLinkComponent,
    RejectDialogComponent,
    SecurityCategorySelectorComponent,
    SenderInfoDataboxComponent,
    SheetLabelPrintDialogComponent,
    ShouldHighlightEverythingPipe,
    TaskInfoBitComponent,
    TaskStateTagComponent,
    UnitSelectorComponent,
    WithdrawForCorrectionDialogComponent,
    EsslComponentsTableComponent,
    DigitalComponentSelectorComponent,
    DigitalComponentsUploadFormComponent,
    OwnDocumentFormComponent,
    PrimaryFunctionalPositionPickerComponent,
    ConsignmentAddressDialogComponent,
    AddSubjectEmailDialogComponent,
    EntityClassSelectorComponent,
    EsslComponentLinkComponent,
    ParentDocumentInfoComponent,
    FileHandoverDialogComponent,
    TaskActivityStateIconComponent,
    ActivityTypeDesignatorComponent,
    TaskTypeDesignatorComponent,
    QuickActivityPreviewComponent,
    ActivityStepsPreviewComponent,
    SubprocessDesignatorComponent,
    DocumentFileInsufficientPermissionsAlertComponent,
    ConstrainedDocumentFileSidebarComponent,
    ObjectClassIconComponent,
    ObjectClassNamePipe,
    DocumentFileSidebarComponent,
    DocumentFileSidebarTabSubjectsComponent,
    DocumentFileSidebarTabRelatedComponent,
    DocumentFileSidebarTabOverviewComponent,
    DocumentFileSidebarTabDeliveryComponent,
    DocumentRegistrationTimelineComponent,
    DocumentRegistrationTimelineLinkComponent,
    PermissionAddToolbarComponent,
    PermissionSelectorPopupComponent,
    PermissionSelectorComponent,
    PermissionSettingsTableComponent,
    PermissionSelectorFormComponent,
    ConsignmentToolbarButtonsComponent,
    DevCreateConsignmentDialogComponent,
    OwnConsignmentTableComponent,
    ConsignmentDeliveryResultComponent,
    DataboxProofOfDeliveryComponent,
    ManualDeliveryResultDialogComponent,
    AdvancedEsslComponentMetadataComponent,
    AnalogComponentCreateDialogComponent,
    AuthorizedConversionAnalogToDigitalDialogComponent,
    DigitalComponentMarkTimestampDialogComponent,
    DigitalComponentSignatureDetailDialogComponent,
    DigitalComponentSignatureInfoComponent,
    DigitalComponentUploadDialogComponent,
    EsslComponentViewerDialogComponent,
    ContainerContentsTableComponent,
    EsslComponentsToolbarButtonsComponent,
    DigitalComponentPreMarkExtendValidationDialogComponent,
    HandForSigningDialogComponent,
    HandComponentForApprovalDialogComponent,
    AddToFileDialogComponent,
    HandForStatementDialogComponent,
    WorkflowTextResponseDialogComponent,
    DocumentSettleDialogComponent,
    DocumentSettleFormComponent,
    DocumentToolbarButtonsComponent,
    HandoverDialogComponent,
    RevokeTaskDialogComponent,
    PassHandoverToNextHandlerDialogComponent,
    GenericPassToNextHandlerDialogComponent,
    HandForApprovalDialogComponent,
    HandForAcknowledgementDialogComponent,
    HandForAcknowledgementSpecificationComponent,
    BulkOperationErrorsDialogComponent,
    ReregisterDialogComponent,
    MoveToAnotherFileDialogComponent,
    DocumentSettleErrorDialogComponent,
    DocumentSettleByDocumentFormComponent,
    DocumentsTableComponent,
    DocumentRelevanceDetailsComponent,
    TableEntityIconComponent,
    IssdEntityActivityIconComponent,
    ApplicableDeliveryTypeSelectorComponent,
    ConsignmentDialogComponent,
    ConsignmentConsigneeSelectionComponent,
    ConsignmentDialogButtonsComponent,
    PaperOrPersonalOrInternalConsignmentSpecificationComponent,
    OfficeDeskConsignmentSpecificationComponent,
    ConsignmentComponentSelectionComponent,
    ConsignmentConsigneeCreationComponent,
    ConsignmentConsigneesOverviewComponent,
    ConsignmentValidationComponent,
    DataboxConsignmentSpecificationComponent,
    EmailConsignmentSpecificationComponent,
    DeliveryServiceCombinationSelectorComponent,
    DeliveryServiceCombinationInfoComponent,
    ConsignmentMetaOverviewComponent,
    ConsignmentDeliveryResultOverviewComponent,
    EnvelopePreviewComponent,
    ApplicableOutcomeDistributionNodeSelectorComponent,
    ConsignmentOrgUnitSelectionComponent,
    CirculationTableComponent,
    IssdTaskRejectedNotesComponent,
    TelefaxConsignmentSpecificationComponent,
    PortalConsignmentSpecificationComponent,
    TakeoverByBarcodeDialogComponent,
    BarcodeScannedConsignmentsTableComponent,
    ConsignmentTableAddressComponent,
    ConsignmentTableConsigneeComponent,
    ConsignmentChangeDeliveryServiceDialogComponent,
    PrinterSelectionDialogComponent,
    IdtMalfunctionAlertComponent,
    FileTypeSelectorComponent,
    IdtVersionInfoComponent,
    BulkOperationValidationDialogComponent,
    AvailableCirculationActionsDialogComponent,
    KeyValuePairComponent,
    ReceiveDocumentDigitalComponentsComponent,
    OwnConsignmentTypeIconComponent,
    ReceiveOrRegisterReceivedConsignmentComponent,
    ReceivedDocumentWithHandoverFormComponent,
    ReceiveConsignmentDisplaySenderComponent,
    DocumentRegisterContentComponent,
    ExecutionDateClauseCreateDialogComponent,
    NoOfficialContentDialogComponent,
    RejectEmailMessageDialogComponent,
    ReceivedDigitalMessagesTableComponent,
    EpdzAttachmentViewerComponent,
    EpdzAttachmentLinkComponent,
    DigitalMessageIssuesComponent,
    EpdzErrorsToolbarButtonsComponent,
    DigitalMessageProcessingErrorsComponent,
    DocumentSettleComponent,
    DocumentSettleGeneralSettingComponent,
    EntityInfoComponent,
    PrintDocumentInfoDialogComponent,
    PrintFileInfoDialogComponent,
    FileRenditionDialogComponent,
    DigitalComponentFromTemplateDialogComponent,
    EsslComponentsTakeoverFromDocumentDialogComponent,
    OwnDocumentSelectorComponent,
    RegistryOfficeTransferIncidentsTableComponent,
    RegistryOfficeTransferIncidentToolbarButtonsComponent,
    RegistryOfficeTransferIncidentDialogComponent,
    QuickIncidentsListComponent,
    RegistryOfficeIncidentResolutionDialogComponent,
    CreateEmailConsignmentDialogComponent,
    QuickIncidentsListIconComponent,
    UpdateComponentTemplateDialogComponent,
    MembersWithPermissionsTableComponent,
    SharedFolderSelectorComponent,
    SharedFolderSelectionDialogComponent,
    DocumentOrFileSelectorComponent,
    SharedFolderInsertDialogComponent,
    VerifyConversionClauseDialogComponent,
    EpdzContainerContentsDialogComponent,
    MoneyPipe,
    EmpowermentPipe,
    PlainComponentVersionViewerDialogComponent,
    SharedTemplateCollectionComponent,
    ThemeOutletComponent,
    InterpolateBackendErrorPipe,
    StatusCardComponent,
    HtmlOutletComponent,
    FileContentsViewerComponent,
    RtfOutletComponent,
    EsslComponentComponent,
    PaginatorComponent,
    ValidityWidgetComponent,
    ConsignmentPostingNumberComponent,
    FormMoneyInputComponent,
    SharedBusinessFieldValueAccessorDirective,
    DigitalComponentAnonymizationDialogComponent,
    TableTreeviewComponent,
    TableTreeMenuComponent,
    TableExpansionPanelComponent,
    EmpowermentFilterComponent,
    EmpowermentCustomFilterComponent,
    RetentionTriggerTypeWidgetComponent,
    DisposalScheduleComparatorComponent,
    EntityClassTreeDetailComponent,
    DisposalScheduleRetentionTriggerWidgetComponent,
    ExtRetentionTriggerSelectorComponent,
    DisposalScheduleComparatorComponent,
    DocumentTypeSelectorPopupComponent,
    EntityClassSelectorPopupComponent,
    BarcodeScannedConsignmentsFormComponent,
    ManualDeliveryResultFormComponent,
    ManualDeliveryResultByBarcodeDialogComponent,
    MoveToTransferEnvelopeDialogComponent,
    ConfirmAgendaTransferDialogComponent,
    ManualDeliveryResultByBarcodeDialogComponent,
    DocumentFileCustomFieldsSectionComponent,
    StorageUnitFillerDialogComponent
  , StorageUnitFillerSelectionDialogComponent],
  exports: [
    ActivityNotesComponent,
    ActivityStateTagComponent,
    AddAnalogComponentToDocumentComponent,
    AnalogComponentCreateFormComponent,
    DeliveryInfoDataboxComponent,
    DigitalComponentFlagsComponent,
    DigitalComponentManualSignaturePositionComponent,
    DigitalComponentPreSignatureValidationDialogComponent,
    DigitalComponentSignatureConfigurationDialogComponent,
    DigitalComponentsUploadFormComponent,
    DispatchOfficeSelectorComponent,
    DisposalTipComponent,
    DocumentTypeSelectorComponent,
    EmailMetadataPreviewComponent,
    EmailPreviewComponent,
    EntityItemComponent,
    EnvelopeOrLabelCustomFieldsFormComponent,
    EnvelopeOrLabelPreviewCanvasComponent,
    EpdzAttachmentSubsetSelectorComponent,
    FileSelectorComponent,
    DocumentSelectorComponent,
    FileUploadListComponent,
    FindFunctionalPositionByIdPipe,
    FindOrganizationalUnitByIdPipe,
    FindUserByIdPipe,
    FindUserByUsernamePipe,
    HandoverToExternalAppDialogComponent,
    HighlightFragmentForFieldPipe,
    HighlightableTextContentComponent,
    KeywordsSelectorComponent,
    NotificationCardComponent,
    OrgStructureSelectionLoaderComponent,
    OrgStructureSelectionSaverComponent,
    OrgStructureSelectorComponent,
    PageCornerFormFragmentComponent,
    PageDesignatorFormFragmentComponent,
    ReceiveConsignmentSetSenderComponent,
    ReceivePaperConsignmentSetDispatchComponent,
    ReceivedDocumentFormComponent,
    RefNumberLinkComponent,
    SecurityCategorySelectorComponent,
    SenderInfoDataboxComponent,
    ShouldHighlightEverythingPipe,
    TaskInfoBitComponent,
    TaskStateTagComponent,
    UnitSelectorComponent,
    EsslComponentsTableComponent,
    DigitalComponentSelectorComponent,
    DigitalComponentsUploadFormComponent,
    OwnDocumentFormComponent,
    PrimaryFunctionalPositionPickerComponent,
    ConsignmentAddressDialogComponent,
    EntityClassSelectorComponent,
    EsslComponentLinkComponent,
    ParentDocumentInfoComponent,
    FileHandoverDialogComponent,
    TaskActivityStateIconComponent,
    ActivityTypeDesignatorComponent,
    TaskTypeDesignatorComponent,
    QuickActivityPreviewComponent,
    ActivityStepsPreviewComponent,
    SubprocessDesignatorComponent,
    DocumentFileInsufficientPermissionsAlertComponent,
    ConstrainedDocumentFileSidebarComponent,
    ObjectClassIconComponent,
    ObjectClassNamePipe,
    DocumentFileSidebarComponent,
    DocumentFileSidebarTabSubjectsComponent,
    DocumentFileSidebarTabRelatedComponent,
    DocumentFileSidebarTabOverviewComponent,
    DocumentFileSidebarTabDeliveryComponent,
    DocumentRegistrationTimelineComponent,
    DocumentRegistrationTimelineLinkComponent,
    PermissionAddToolbarComponent,
    PermissionSelectorPopupComponent,
    PermissionSelectorComponent,
    PermissionSettingsTableComponent,
    PermissionSelectorFormComponent,
    ConsignmentToolbarButtonsComponent,
    OwnConsignmentTableComponent,
    AdvancedEsslComponentMetadataComponent,
    AnalogComponentCreateDialogComponent,
    AuthorizedConversionAnalogToDigitalDialogComponent,
    VerifyConversionClauseDialogComponent,
    DigitalComponentMarkTimestampDialogComponent,
    DigitalComponentSignatureDetailDialogComponent,
    DigitalComponentSignatureInfoComponent,
    DigitalComponentUploadDialogComponent,
    EsslComponentViewerDialogComponent,
    EsslComponentsToolbarButtonsComponent,
    DigitalComponentPreMarkExtendValidationDialogComponent,
    HandForSigningDialogComponent,
    HandComponentForApprovalDialogComponent,
    DocumentToolbarButtonsComponent,
    DocumentsTableComponent,
    TableEntityIconComponent,
    ApplicableDeliveryTypeSelectorComponent,
    ConsignmentDialogComponent,
    ConsignmentConsigneeSelectionComponent,
    ConsignmentDialogButtonsComponent,
    PaperOrPersonalOrInternalConsignmentSpecificationComponent,
    OfficeDeskConsignmentSpecificationComponent,
    ConsignmentComponentSelectionComponent,
    ConsignmentConsigneeCreationComponent,
    ConsignmentConsigneesOverviewComponent,
    ConsignmentValidationComponent,
    DataboxConsignmentSpecificationComponent,
    EmailConsignmentSpecificationComponent,
    DeliveryServiceCombinationSelectorComponent,
    DeliveryServiceCombinationInfoComponent,
    ConsignmentDeliveryResultOverviewComponent,
    ConsignmentMetaOverviewComponent,
    DataboxConsignmentSpecificationComponent,
    EnvelopePreviewComponent,
    ApplicableOutcomeDistributionNodeSelectorComponent,
    ConsignmentOrgUnitSelectionComponent,
    CirculationTableComponent,
    IssdTaskRejectedNotesComponent,
    FileTypeSelectorComponent,
    IdtMalfunctionAlertComponent,
    IdtVersionInfoComponent,
    BulkOperationValidationDialogComponent,
    AvailableCirculationActionsDialogComponent,
    PrepareOrDispatchFormComponent,
    KeyValuePairComponent,
    ReceiveDocumentDigitalComponentsComponent,
    OwnConsignmentTypeIconComponent,
    DevCreateConsignmentDialogComponent,
    ReceiveOrRegisterReceivedConsignmentComponent,
    ReceivedDocumentWithHandoverFormComponent,
    ReceiveConsignmentDisplaySenderComponent,
    DocumentRegisterContentComponent,
    ExecutionDateClauseCreateDialogComponent,
    BulkPostingFormDialogConsignmentsComponent,
    RejectEmailMessageDialogComponent,
    ReceivedDigitalMessagesTableComponent,
    EpdzErrorsToolbarButtonsComponent,
    EntityInfoComponent,
    OwnDocumentSelectorComponent,
    RegistryOfficeTransferIncidentsTableComponent,
    RegistryOfficeTransferIncidentToolbarButtonsComponent,
    CreateEmailConsignmentDialogComponent,
    MembersWithPermissionsTableComponent,
    SharedFolderSelectorComponent,
    DocumentOrFileSelectorComponent,
    SharedFolderInsertDialogComponent,
    EpdzContainerContentsDialogComponent,
    MoneyPipe,
    EmpowermentPipe,
    PlainComponentVersionViewerDialogComponent,
    SharedTemplateCollectionComponent,
    ThemeOutletComponent,
    InterpolateBackendErrorPipe,
    StatusCardComponent,
    HtmlOutletComponent,
    FileContentsViewerComponent,
    RtfOutletComponent,
    EsslComponentComponent,
    PaginatorComponent,
    ValidityWidgetComponent,
    ConsignmentPostingNumberComponent,
    FormMoneyInputComponent,
    SharedBusinessFieldValueAccessorDirective,
    TableTreeviewComponent,
    TableTreeMenuComponent,
    TableExpansionPanelComponent,
    RetentionTriggerTypeWidgetComponent,
    DisposalScheduleComparatorComponent,
    DisposalScheduleRetentionTriggerWidgetComponent,
    ExtRetentionTriggerSelectorComponent,
    DocumentTypeSelectorPopupComponent,
    EntityClassSelectorPopupComponent,
    MoveToTransferEnvelopeDialogComponent,
    ConfirmAgendaTransferDialogComponent,
    DocumentFileCustomFieldsSectionComponent,
  ],
  imports: [
    CommonModule,
    EssentialsModule,
    FormElementsModule,
    TableModule,
    HighlightsModule,
    SubjectsModule,
    PdfViewerModule,
    ModalsModule,
    DialogsModule,
    ButtonCollectionModule,
    LibraryImportModule,
    EmpowermentModule,
    IczFormDirectivesModule,
    AddressPipeModule,
    WizardModule,
  ],
})
export class SharedBusinessComponentsModule {}
