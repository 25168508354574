import {Component, inject} from '@angular/core';
import {ApiConversionService, ApiPaperComponentService, PaperComponentDto} from '|api/component';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {AnalogProtectiveElement} from '|api/commons';
import {EsslComponentToastService} from '../../../../../core/services/notifications/essl-component-toast.service';
import {ConversionAnalogToDigitalModalData, ConversionAnalogToDigitalType} from '../../models/digital-components.model';
import {Observable, of} from 'rxjs';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {
  CheckUnsavedFormDialogService
} from 'libs/shared/src/lib/services/check-unsaved/check-unsaved-form-dialog.service';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {LoadingIndicatorService} from '@icz/angular-essentials';

export function getConvertToDigitalForm() {
  return new IczFormGroup({
    numberOfPages: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    protectiveElementSet: new IczFormControl<Nullable<AnalogProtectiveElement[]>>([], []),
  });
}

@Component({
  selector: 'icz-authorized-conversion-analog-to-digital-dialog',
  templateUrl: './authorized-conversion-analog-to-digital-dialog.component.html',
  styleUrls: ['./authorized-conversion-analog-to-digital-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class AuthorizedConversionAnalogToDigitalDialogComponent implements IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected data = injectModalData<ConversionAnalogToDigitalModalData>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected apiPaperComponentService = inject(ApiPaperComponentService);
  protected apiConversionService = inject(ApiConversionService);
  protected esslComponentToastService = inject(EsslComponentToastService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);

  formGroupsToCheck!: string[];
  paperComponent!: PaperComponentDto;

  protectiveElementOptions = enumToOptions('analogProtectiveElement', AnalogProtectiveElement);

  form = getConvertToDigitalForm();

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    if (this.data) {
      this.paperComponent = this.data.paperComponent;
      this.form.get('protectiveElementSet')?.setValue(this.paperComponent.protectiveElementSet!);
      this.form.get('numberOfPages')!.setValue(this.paperComponent.numberOfPages!);
    }
  }

  get isAuthorizedConversion() {
    return this.data.conversionType === ConversionAnalogToDigitalType.AUTHORIZED_CONVERSION;
  }

  get isVerifiedCopy() {
    return this.data.conversionType === ConversionAnalogToDigitalType.VERIFIED_COPY;
  }

  submit() {
    this.form.markAsPristine();
    const formValue = this.form.getRawValue();

    let req$: Observable<void> = of();
    if (this.isAuthorizedConversion) {
      req$ = this.apiConversionService.conversionConvertAnalogToDigitalAuth({
        body: {
          componentId: this.paperComponent?.id,
          protectiveElementSet: formValue.protectiveElementSet
        }
      });
    } else if (this.isVerifiedCopy) {
      req$ = this.apiConversionService.conversionConvertAnalogToDigital({
        body: {
          componentId: this.paperComponent?.id,
          protectiveElementSet: formValue.protectiveElementSet
        }
      });
    }

    this.loadingIndicatorService.doLoading(req$, this).subscribe(_ => { // void res
      this.modalRef.close(true);
    });
  }

  close() {
    this.modalRef.close(null);
  }

}
