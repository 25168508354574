import {DocumentDto, FileDto, ReceivedDocumentDto} from '|api/document';
import {ButtonDisablerFn, Guard} from '../../../button-collection/button-collection.component';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {FileDetailService} from '../../../../services/file-detail.service';
import {DocumentDetailService} from '../../../../services/document-detail.service';
import {SearchRecordSourceDocumentFileDto} from '|api/elastic';
import {CirculationView, DocumentView} from './toolbar-common.utils';
import {DocumentOperationValidators} from './document-operation-validators';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {CirculationActivityDto} from '|api/flow';


export class DocumentToolbarDisablers {
  @Guard()
  static selectedItemIsNotDocument(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.selectedItemIsNotDocument());
    };
  }

  @Guard()
  static isNotReceivedDocument(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.selectedItemIsNotReceivedDocument());
    };
  }

  @Guard()
  static isNotOwnDocument(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.selectedItemIsNotOwnDocument());
    };
  }

  static isDocumentDeactivated(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentDeactivated());
    };
  }

  static isDocumentUnopened(selection: ReceivedDocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentUnopened());
    };
  }

  static isDocumentNotUnopened(selection: ReceivedDocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentNotUnopened());
    };
  }

  static isDocumentToOwnHands(selection: ReceivedDocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentToOwnHands());
    };
  }

  static isDocumentNotToOwnHands(selection: ReceivedDocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentNotToOwnHands());
    };
  }

  static hasExecutionDate(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.documentHasExecutionDate());
    };
  }

  static isDocumentInFile(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentInFile());
    };
  }

  static isDocumentNotInFile(selection: DocumentDto[] | SearchRecordSourceDocumentFileDto[], viewType: DocumentView | CirculationView): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentNotInFile(viewType));
    };
  }

  static isParentFileDeactivated(selection: DocumentDto[], fileDetailService: Nullable<FileDetailService>, fileDto: Nullable<FileDto>): ButtonDisablerFn {
    return () => {
      return DocumentOperationValidators.isParentFileDeactivated(fileDetailService, fileDto)(selection[0]);
    };
  }

  static isParentFileClosed(selection: DocumentDto[], fileDetailService: Nullable<FileDetailService>, fileDto: Nullable<FileDto>): ButtonDisablerFn {
    return () => {
      return DocumentOperationValidators.isParentFileClosed(fileDetailService, fileDto)(selection[0]);
    };
  }

  static isDocumentSettled(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentSettled());
    };
  }

  static isDocumentNotSettled(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentNotSettled());
    };
  }

  static isDocumentNotInProgress(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentNotInProgress());
    };
  }

  static isDocumentInStorageUnit(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isDocumentInStorageUnit());
    };
  }

  static documentIsNotRegistered(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.documentIsNotRegistered());
    };
  }

  static documentIsNotAnalog(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.documentIsNotAnalog());
    };
  }

  static documentIsNotAnalogOrHybrid(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.documentIsNotAnalogOrHybrid());
    };
  }

  static documentIsRegistered(selection: DocumentDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.documentIsRegistered());
    };
  }

  static isParentFileRunningActivity(
    selection: DocumentDto[],
    documentDetailService: DocumentDetailService,
    isOnDocumentDetail: boolean,
  ): ButtonDisablerFn {
    return () => {
      return DocumentOperationValidators.isParentFileRunningActivity(documentDetailService, isOnDocumentDetail)(selection[0]);
    };
  }

  static isNotDocumentOwnerOrSupervisor(
    currentSessionService: CurrentSessionService,
    organizationalStructureService: OrganizationalStructureService,
    selection: DocumentDto[],
  ): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.isNotDocumentOwnerOrSupervisor(currentSessionService, organizationalStructureService));
    };
  }

  static documentHandoverToExternalApplicationPreconditionNotMet(
    selection: DocumentDto[],
  ): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.documentHandoverToExternalApplicationPreconditionNotMet());
    };
  }

  static documentIsInExternalApplication(
    selection: DocumentDto[],
  ): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.documentIsInExternalApplication());
    };
  }

  static documentInCirculation(
    runningActivity: Nullable<CirculationActivityDto>,
    selection: DocumentDto[],
  ): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, DocumentOperationValidators.documentInCirculation(runningActivity));
    };
  }
}
