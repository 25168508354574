import {Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {switchMap} from 'rxjs/operators';
import {FunctionalPositionWithSubstitutionDto} from '|api/auth-server';
import {FormAutocompleteComponent, IczOption, PrimitiveControlValueType} from '@icz/angular-form-elements';
import {ToastService} from '../../notifications/toast.service';
import {getUserInitials} from '../../../model';
import {GlobalLoadingIndicatorService, LocalizedDatePipe} from '@icz/angular-essentials';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {LocalStorageKey, UserSettingsService} from '../../../services/user-settings.service';
import {CurrentSessionService} from '../../../services/current-session.service';
import {AuthService, LogoutType} from '../../../core/authentication/auth.service';
import {DialogService, DialogSeverity} from '@icz/angular-modal';
import {ApplicationRoute, LandingPage} from '../../../enums/shared-routes.enum';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {DocumentsDashboardRoute} from '../../../enums/documents-routes.enum';
import {RouteRestorationService} from '../../../services/route-restoration.service';
import {loadTranslations} from '../../../core/localization/loader';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IdtLinkService} from '../../../core/services/idt/idt-link.service';
import {HistoryService} from '../../../services/history.service';

interface FunctionalPositionOptionData {
  code: string;
  name: string;
  holderName?: Nullable<string>;
  isSubstituted?: boolean;
  substitutionTo?: Nullable<string>;
  userInitials?: Nullable<string>;
}

type FunctionalPositionOption = IczOption<string, FunctionalPositionOptionData>;


@Component({
  selector: 'icz-place-selection-page',
  templateUrl: './place-selection.page.html',
  styleUrls: ['./place-selection.page.scss'],
})
export class PlaceSelectionPageComponent implements OnInit {

  private globalLoadingIndicatorService = inject(GlobalLoadingIndicatorService);
  private applicationConfigService = inject(ApplicationConfigService);
  private translateService = inject(TranslateService);
  private userSettingsService = inject(UserSettingsService);
  private currentSessionService = inject(CurrentSessionService);
  private routeRestorationService = inject(RouteRestorationService);
  private router = inject(Router);
  private authService = inject(AuthService);
  private toastService = inject(ToastService);
  private dialogService = inject(DialogService);
  private destroyRef = inject(DestroyRef);
  private idtLinkService = inject(IdtLinkService);
  private historyService = inject(HistoryService);

  @ViewChild('autocomplete', {read: FormAutocompleteComponent})
  autocomplete!: FormAutocompleteComponent;

  placeOptions: FunctionalPositionOption[] = [];
  userFunctionalPositions: FunctionalPositionWithSubstitutionDto[] = [];

  localizedDatePipe = new LocalizedDatePipe();

  getUserInitials(fullName: string) {
    if (!fullName) return null;

    return getUserInitials(fullName.split(' '));
  }

  ngOnInit() {
    this.translateService.use(this.userSettingsService.getRawValue(LocalStorageKey.USER_LANGUAGE)!);

    this.globalLoadingIndicatorService.startLoading();

    this.currentSessionService.getAvailableFunctionalPlacesWithSubstitutions().subscribe({
      next: functionalPositions => {
        const options: FunctionalPositionOption[] = [];
        this.userFunctionalPositions = functionalPositions;

        for (const fp of functionalPositions) {
          options.push(
            {
              label: fp.holderName ? `${fp.holderName} ${fp.code} ${fp.name}` : `${fp.code} ${fp.name}`,
              value: fp.code,
              data: {
                holderName: fp.holderName ?? 'Bez vykonávajícího',
                userInitials: fp.holderName ?? '',
                code: fp.code,
                name: fp.name,
                isSubstituted: fp.substituted!,
                substitutionTo: fp.substitutionTo
              }
            }
          );
        }

        this.globalLoadingIndicatorService.endLoading();

        if (options.length === 1) {
          this.select(options[0].value);
        }
        else if (options.length === 0) {
          this.authService.logOut(LogoutType.LOGIN_ABORTED);

          this.dialogService.openSimpleDialog({
            severity: DialogSeverity.ERROR,
            title: 'Nelze dokončit přihlášení',
            leftButtonTitle: 'Zpět na přihlašovací stránku',
            content: [
              {
                text: 'Dané přihlašovací údaje patří uživateli, který nemá ve spisové službě přiřazené žádné pracovní místo.'
              }
            ]
          });
        }
        else {
          this.placeOptions = options;
        }
      },
      error: _ => {
        this.authService.logOut(LogoutType.LOGIN_ABORTED);
      }
    });
  }

  select(functionalPositionCode: PrimitiveControlValueType) {
    if (this.authService.isAuthenticatedWithoutFunctionalPosition) {
      this.globalLoadingIndicatorService.startLoading();

      if (this.isLoggedInAsDifferentUserInAnotherTab(functionalPositionCode as string)) {
        this.dialogService.openSimpleDialog({
          severity: DialogSeverity.WARNING,
          title: 'Přihlášení',
          titleContext: {},
          leftButtonTitle: 'Odhlásit ostatní a pokračovat',
          rightButtonTitle: 'Převzít existující přihlášení',
          content: [
            {
              text: 'Již jste přihlášeni jako jiný uživatel nebo funkční místo v jiné záložce nebo okně prohlížeče.',
            }
          ],
        }).subscribe(result => {
          if (result) {
            this.authService.logOutSessionsInOtherTabs();

            setTimeout(() => {
              this.select(functionalPositionCode);
            }, 500);
          }
          else {
            location.href = '/';
          }
        });
      }
      else {
        this.authService.selectFunctionalPosition(functionalPositionCode as string).pipe(
          // Configuration values might become different between user sessions so loading them here.
          switchMap(_ => this.applicationConfigService.loadConfig()),
          takeUntilDestroyed(this.destroyRef),
          switchMap(_ => this.loadTranslations()),
        ).subscribe({
          next: () => {
            this.globalLoadingIndicatorService.endLoading();

            // Toasts must be cleared upon entering different functional position session.
            this.toastService.reinitialize();
            this.idtLinkService.initialize();
            this.navigateToNextScreen();
          },
          error: e => {
            this.goToLoginPage();
            this.globalLoadingIndicatorService.endLoading();
          },
        });
      }
    }
    else {
      this.authService.cancelLoginProcess();
      this.goToLoginPage();
    }
  }

  substitutionDateToText(substitutionTo: Nullable<string>) {
    const dateTo = substitutionTo ? this.localizedDatePipe.transform(substitutionTo) : this.translateService.instant('neurčito');
    return `${this.translateService.instant('zástup do')} ${dateTo}`;
  }

  private navigateToNextScreen() {
    const landingPageSetting = this.userSettingsService.getParsedValue(LocalStorageKey.USER_LANDING_PAGE);

    let nextUrl: string;

    if (landingPageSetting) {
      if (landingPageSetting.landingPage === LandingPage.DASHBOARD) {
        nextUrl = createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsDashboardRoute.DASHBOARD);
      }
      else {
        nextUrl = this.routeRestorationService.restoreUrlBeforeLogin() || '/';
      }
    }
    else {
      nextUrl = '/';
    }

    const urlParts = nextUrl.split('?');

    if (urlParts.length > 1) {
      const parsed = this.router.parseUrl(nextUrl);
      this.router.navigateByUrl(parsed);
    }
    else {
      this.router.navigate([nextUrl]);
    }
  }

  private loadTranslations() {
    return loadTranslations(this.translateService, this.userSettingsService, this.authService);
  }

  private goToLoginPage() {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.LOGIN));
  }

  private isLoggedInAsDifferentUserInAnotherTab(functionalPositionCode: string) {
    const globalSessionAccessTokenData = this.authService.globalSessionAccessTokenData;

    if (!globalSessionAccessTokenData) {
      return false;
    }
    else {
      return (
        globalSessionAccessTokenData.organization_code !== this.currentSessionService.currentOrganization!.code ||
        globalSessionAccessTokenData.user_name !== this.currentSessionService.currentUser!.username ||
        globalSessionAccessTokenData.functional_position !== functionalPositionCode
      );
    }
  }

}
