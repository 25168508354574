import {Component, DestroyRef, inject, Input, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {clone, isEqual} from 'lodash';
import {distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {DispatchOfficeDto} from '|api/codebook';
import {ConsignmentType, DocumentState, OwnConsignmentStatus} from '|api/commons';
import {OwnDocumentDto, ReceivedDocumentDto} from '|api/document';
import {ApiOwnConsignmentService, OwnConsignmentPerStateCountForOwnerDto, OwnPaperConsignmentElasticDto, SheetNodeDto} from '|api/sad';
import {
  ConsignmentToolbarButtonsService,
} from '../own-consignment-toolbar/consignment-toolbar-buttons/consignment-toolbar-buttons.service';
import {
  GenericOwnElasticConsignmentWithConsignee,
  getConsignmentPostingNumber,
  getOutboundConsignmentIdentifier,
  isForDispatchOfficerView,
  isForOfficerView,
  isMultiConsignmentContentView,
  isOfficeDeskView,
  isOwnDigitalElasticConsignment,
  isOwnOfficeDeskElasticConsignment,
  isOwnPaperElasticConsignment,
  OwnConsignmentOfficeDeskTableView,
  OwnConsignmentTableView,
} from './model/own-consignment-model';
import {
  dispatchOfficerDispatchedColumnSet,
  dispatchOfficerInDistributionColumnSet,
  dispatchOfficerSpecificStatusColumnSet,
  getColumnsData,
  officeDeskOfficerViewAllColumnSet,
  officeDeskOfficerViewInProgressColumnSet,
  officeDeskOfficerViewRejectedColumnSet,
  officeDeskViewPostedColumnSet,
  officeDeskViewToPostColumnSet,
  officeDeskViewToTakeoverColumnSet,
  officeDeskViewUnpostedColumnSet,
  officerAllColumnSet,
  officerConsignmentsOnDocumentColumnSet,
  officerInProgressColumnSet,
  officerRejectedColumnSet,
  OwnConsignmentColumnKey,
  recordDeliveryResultColumnSet
} from './own-consignment.columnsets';
import {ConsignmentDialogService} from '../consignment-dialog/consignment-dialog.service';
import {
  extendDefaultTableConfig,
  getDefaultTableConfig,
  SearchParams,
  TableColumnsData,
  TableComponent,
  TableConfig
} from '@icz/angular-table';
import {getDocumentIdFromConsignment} from './own-consignment-workflow.service';
import {ConsignmentDialogType, ConsignmentDocumentData} from '../consignment-dialog/consignment-dialog/abstract-consignment-dialog-data';
import {DocumentDetailOwnConsignmentsDatasource} from './datasources/document-detail-own-consignments.datasource';
import {OwnConsignmentsForDispatchOfficerDatasource} from './datasources/own-consignments-for-dispatch-officer.datasource';
import {OwnConsignmentsForOwnerOrUnitDatasource} from './datasources/own-consignments-for-owner-or-unit.datasource';
import {OwnConsignmentsOfficeDeskDispatchOfficerDatasource} from './datasources/own-consignments-office-desk-dispatch-officer.datasource';
import {OwnConsignmentsOfficeDeskOwnerDatasource} from './datasources/own-consignments-office-desk-owner.datasource';
import {ReceivedDocumentDetailOwnConsignmentsDatasource} from './datasources/received-document-detail-own-consignments.datasource';
import {IconSize, IczOnChanges, IczSimpleChanges, LoadingIndicatorService, TabItem} from '@icz/angular-essentials';
import {CodebookService} from '../../../core/services/codebook.service';
import {findMenuItemByViewId, MainMenuCountsService} from '../../../core/services/main-menu-counts.service';
import {DocumentView} from '../document-toolbar/services/toolbar-common.utils';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {OwnConsignmentsSearchService} from '../../../services/own-consignments-search.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {OwnConsignmentTableConsignmentTypeFilter} from '../consignment-dialog/consignment-dialog/consignment.model';
import {enumValuesToArray} from '../../../core/services/data-mapping.utils';
import {DISPATCH_OFFICE_VIEW_QUERY_PARAM_NAME} from '../dispatch-office-selector/dispatch-office-selector.component';
import {PrimitiveControlValueType} from '@icz/angular-form-elements';
import {combineLatest} from 'rxjs';
import {filterByClass} from '../../../lib/rxjs';
import {UNIT_VIEW_QUERY_PARAM_NAME} from '../abstract-unit-view-component/abstract-unit-view-component';
import {MenuItem} from '../../general-screen-area/side-menu/side-menu.component';
import {MenuItemCountViewIds} from '../../../core/routing/menu-item-count-view-ids';
import {createTabTableId} from '../abstract-table-with-tabs-and-selector.component';
import {DocumentFiltersDataService} from '../document-table/components/document-filters/document-filters-data.service';
import {DocumentDetailService} from '../../../services/document-detail.service';

const visibleConsignmentTypes = [
  ConsignmentType.OWN_PAPER_ADDRESS,
  ConsignmentType.OWN_PAPER_MULTI_ADDRESS,
  ConsignmentType.OWN_PAPER_ON_PLACE,
  ConsignmentType.OWN_DIGITAL_DATABOX,
  ConsignmentType.OWN_DIGITAL_EMAIL,
  ConsignmentType.OWN_DIGITAL_INTERNAL,
  ConsignmentType.OWN_PAPER_INTERNAL,
  ConsignmentType.TELEFAX,
  ConsignmentType.PORTAL,
];

function isDispatchOfficerSpecificStatusView(view: OwnConsignmentTableView) {
  return [
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER,
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_DISPATCH,
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION,
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED,
  ].includes(view);
}

function isOfficerSpecificStatusView(view: OwnConsignmentTableView) {
  return [
    OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS,
    OwnConsignmentTableView.OFFICER_VIEW_REJECTED,
    OwnConsignmentTableView.OFFICER_VIEW_ALL,
  ].includes(view);
}

const MESSAGE_VIEW_QUERY_PARAM_NAME = 'view';

@Component({
  selector: 'icz-own-consignment-table',
  templateUrl: './own-consignment-table.component.html',
  styleUrls: ['./own-consignment-table.component.scss'],
  providers: [
    ConsignmentDialogService,
  ]
})
export class OwnConsignmentTableComponent implements OnInit, IczOnChanges {

  private codebookService = inject(CodebookService);
  protected documentDetailService = inject(DocumentDetailService, {optional: true});
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private ownConsignmentsSearchService = inject(OwnConsignmentsSearchService);
  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private consignmentDialogService = inject(ConsignmentDialogService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  protected activatedRoute = inject(ActivatedRoute);
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  @ViewChild(ConsignmentToolbarButtonsService)
  consignmentToolbarButtonsService!: ConsignmentToolbarButtonsService;

  @ViewChild('tableComponent')
  tableComponent!: TableComponent<OwnConsignmentColumnKey>;

  tableConfig: TableConfig<OwnConsignmentColumnKey> = getDefaultTableConfig();
  columnsData!: TableColumnsData<OwnConsignmentColumnKey>;
  selectedRows: GenericOwnElasticConsignmentWithConsignee[] = [];
  dataSource!: DocumentDetailOwnConsignmentsDatasource | OwnConsignmentsForDispatchOfficerDatasource | OwnConsignmentsForOwnerOrUnitDatasource | OwnConsignmentsOfficeDeskDispatchOfficerDatasource | OwnConsignmentsOfficeDeskOwnerDatasource;
  dispatchOfficeDistributionNodeIds: number[] = [];
  consignmentTypes: Nullable<ConsignmentType[]> = visibleConsignmentTypes;
  isOwnDocumentDeactivated = false;
  sheetNodes: SheetNodeDto[] = [];
  activatedTab: Nullable<TabItem<OwnConsignmentTableConsignmentTypeFilter>>;

  @Input() document?: OwnDocumentDto | ReceivedDocumentDto;
  @Input() states!: Array<OwnConsignmentStatus>;
  @Input() ownPaperMultiConsignmentId: Nullable<number>;
  @Input({required: true}) tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView = OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS;
  @Input({required: true}) tableTitle = '';
  @Input() isNonBlockingLoading = true;

  readonly OwnConsignmentColumnKey = OwnConsignmentColumnKey;
  readonly ConsignmentType = ConsignmentType;
  readonly DocumentView = DocumentView;

  readonly isOwnPaperElasticConsignment = isOwnPaperElasticConsignment;
  readonly isOwnDigitalElasticConsignment = isOwnDigitalElasticConsignment;
  readonly isOwnOfficeDeskElasticConsignment = isOwnOfficeDeskElasticConsignment;

  readonly allOwnConsignmentStates = enumValuesToArray(OwnConsignmentStatus) as OwnConsignmentStatus[];

  _showDispatchOfficeSelector = false;
  _canCreateBulkPostingForm = false;
  selectedDispatchOfficeId: Nullable<number> = null;
  private isSingleDispatchOfficeSystem = false;
  tableId = '';
  isUnitView = false;
  orgUnitId: Nullable<number> = null;
  guidedBPFGenerationEnabled = false; // important to start with false
  excludedConsignmentIdsForBpf: number[] = [];

  get showDispatchOfficeSelector() {
    return this._showDispatchOfficeSelector;
  }
  set showDispatchOfficeSelector(show) {
    this._showDispatchOfficeSelector = show;
  }
  get canCreateBulkPostingForm() {
    return this._canCreateBulkPostingForm;
  }
  set canCreateBulkPostingForm(canCreateBulkPostingForm) {
    this._canCreateBulkPostingForm = canCreateBulkPostingForm;
  }

  get iconSize(): IconSize {
    return this.isOfficerCreateView ? 'default' : 'small';
  }

  get showTabs() {
    return (
      !this.isOfficerCreateView &&
      !this.isOfficeDeskView &&
      !this.isMultiConsignmentContentView &&
      this.tableView !== OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT &&
      this.tableView !== OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT
    );
  }

  tableTabs: TabItem<OwnConsignmentTableConsignmentTypeFilter>[] = [
    {
      id: OwnConsignmentTableConsignmentTypeFilter.ALL,
      label: 'Všechna',
      showCount: true,
      count: 0,
    },
    {
      id: OwnConsignmentTableConsignmentTypeFilter.OWN_PAPER_ADDRESS,
      label: 'Listinná',
      count: 0,
    },
    {
      id: OwnConsignmentTableConsignmentTypeFilter.OWN_DIGITAL_DATABOX,
      label: 'Datovou schránkou',
      count: 0,
    },
    {
      id: OwnConsignmentTableConsignmentTypeFilter.OWN_DIGITAL_EMAIL,
      label: 'E-mailem',
      count: 0,
    },
    {
      id: OwnConsignmentTableConsignmentTypeFilter.OWN_INTERNAL,
      label: 'Interní',
      count: 0,
    },
  ];

  tableTabViewChanged(activatedTab: TabItem<OwnConsignmentTableConsignmentTypeFilter>) {
    const queryParams: Params = {
      [MESSAGE_VIEW_QUERY_PARAM_NAME]: activatedTab.id,
    };

    const urlParams = new URLSearchParams(window.location.search);
    let selectorParamValue = urlParams.get(DISPATCH_OFFICE_VIEW_QUERY_PARAM_NAME);
    if (selectorParamValue) {
      queryParams[DISPATCH_OFFICE_VIEW_QUERY_PARAM_NAME] = selectorParamValue;
    }

    selectorParamValue = urlParams.get(UNIT_VIEW_QUERY_PARAM_NAME);
    if (selectorParamValue) {
      queryParams[UNIT_VIEW_QUERY_PARAM_NAME] = selectorParamValue;
    }

    this.router.navigate(
      [],
      {
        queryParams,
        relativeTo: this.activatedRoute
      }
    );
  }

  handleTableTabViewChange(activatedTab: TabItem<OwnConsignmentTableConsignmentTypeFilter>) {
    this.tableId = createTabTableId(`${this.tableView}-${activatedTab.id}-own-consignment`);
    this.activatedTab = activatedTab;
    if (activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.ALL) {
      this.consignmentTypes = visibleConsignmentTypes;
    } else if (activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.OWN_PAPER_ADDRESS) {
      this.consignmentTypes = [ConsignmentType.OWN_PAPER_ADDRESS, ConsignmentType.OWN_PAPER_ON_PLACE, ConsignmentType.OWN_PAPER_MULTI_ADDRESS];
    } else if (activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.OWN_DIGITAL_DATABOX) {
      this.consignmentTypes = [ConsignmentType.OWN_DIGITAL_DATABOX];
    } else if (activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.OWN_DIGITAL_EMAIL) {
      this.consignmentTypes = [ConsignmentType.OWN_DIGITAL_EMAIL];
    } else if (activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.OWN_INTERNAL) {
      this.consignmentTypes = [ConsignmentType.OWN_PAPER_INTERNAL, ConsignmentType.OWN_DIGITAL_INTERNAL];
    } else {
      throw new Error(`Unknown consignment table tab selected!`);
    }

    if (this.tableComponent) {
      this.tableComponent.clearSearchParams();
      this.tableComponent.tableToolbarService.clearAllFilters();
    }

    this.setDataSource();
    this.setColumnSet();
  }

  getExcludeValue(row: GenericOwnElasticConsignmentWithConsignee) {
    return this.excludedConsignmentIdsForBpf.includes(row.id!);
  }

  getExcludeDisabled(row: GenericOwnElasticConsignmentWithConsignee): boolean {
    return !isOwnPaperElasticConsignment(row) || Boolean((row as OwnPaperConsignmentElasticDto).bulkPostingFormName);
  }

  excludedConsignmentIdsChanged(value: PrimitiveControlValueType, row: GenericOwnElasticConsignmentWithConsignee) {
    if (value === true) {
      if (!this.excludedConsignmentIdsForBpf.includes(row.id!)) {
        this.excludedConsignmentIdsForBpf.push(row.id!);
      }
    } else {
      if (this.excludedConsignmentIdsForBpf.includes(row.id!)) {
        this.excludedConsignmentIdsForBpf.splice(this.excludedConsignmentIdsForBpf.indexOf(row.id!), 1);
      }
    }
  }

  pageLoadStarted(searchParams: SearchParams) {
    this.clearExcludedConsignmentsForBpf();
  }

  get isOfficerCreateNonOfficeDeskView() {
    return this.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS;
  }

  get isOfficerCreateOfficeDeskView() {
    return this.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_CREATE_OFFICE_DESK_CONSIGNMENTS;
  }

  get isOfficerCreateView() {
    return this.isOfficerCreateNonOfficeDeskView || this.isOfficerCreateOfficeDeskView;
  }

  get isForDispatchOfficerView() {
   return isForDispatchOfficerView(this.tableView);
  }

  get isForOfficerView() {
    return isForOfficerView(this.tableView);
  }

  get isOfficeDeskView() {
    return isOfficeDeskView(this.tableView);
  }

  get isMultiConsignmentContentView() {
    return isMultiConsignmentContentView(this.tableView);
  }

  get isViewWithHandoverDateSort () {
    if (!this.tableView) return false;
    return [
      OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED,
      OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT,
      OwnConsignmentTableView.OFFICER_VIEW_ALL,
    ].includes(this.tableView as OwnConsignmentTableView);
  }

  getConsignmentPostingNumber = getConsignmentPostingNumber;

  rowClicked(row: GenericOwnElasticConsignmentWithConsignee) {
    this.tableComponent.setFocusedRow(row);

    const consignmentDialogType = ConsignmentDialogType.EDIT_CONSIGNMENT;

    const openDialog = (documentData: ConsignmentDocumentData, consignmentDialogType: ConsignmentDialogType) => this.consignmentDialogService.openConsignmentDialog(documentData, consignmentDialogType, row).subscribe(result => {
      this.dataSource.reload(true);
    });

    const documentId = getDocumentIdFromConsignment(row);
    openDialog({
      id: documentId!,
    }, consignmentDialogType);
  }

  private determineShowDispatchOfficeSelector(dispatchOffices: DispatchOfficeDto[]) {
   this.showDispatchOfficeSelector = dispatchOffices.length > 1 && this.isForDispatchOfficerView;
  }

  private adjustTableConfig() {
    if (this.isOfficerCreateView) {
      this.tableConfig = {...this.tableConfig, rowHeight: 70};
    }
  }

  private setConsignmentFiltersByTab() {
    if (this.activatedTab) {
      if (this.activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.ALL) {
        this.consignmentTypes = visibleConsignmentTypes;
      } else if (this.activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.OWN_PAPER_ADDRESS) {
        this.consignmentTypes = [ConsignmentType.OWN_PAPER_ADDRESS, ConsignmentType.OWN_PAPER_ON_PLACE, ConsignmentType.OWN_PAPER_MULTI_ADDRESS];
      } else if (this.activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.OWN_DIGITAL_DATABOX) {
        this.consignmentTypes = [ConsignmentType.OWN_DIGITAL_DATABOX];
      } else if (this.activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.OWN_DIGITAL_EMAIL) {
        this.consignmentTypes = [ConsignmentType.OWN_DIGITAL_EMAIL];
      } else if (this.activatedTab.id === OwnConsignmentTableConsignmentTypeFilter.OWN_INTERNAL) {
        this.consignmentTypes = [ConsignmentType.OWN_PAPER_INTERNAL, ConsignmentType.OWN_DIGITAL_INTERNAL];
      }
    } else { // fallback is all types
      this.consignmentTypes = visibleConsignmentTypes;
    }
  }

  private setTableTitleByOrgUnit() {
    // table title is usually set in DispatchOwnConsignmentsTableComponent, but since we need to listen to query params in this component,
    // for unit views tableTitle is set here.
    if (this.isUnitView && isEqual(this.states, [OwnConsignmentStatus.TO_HANDOVER])) {
      this.tableTitle = 'Útvar - Vypravení - připravované';
    }
    else if (this.isUnitView && isEqual(this.states.length, this.allOwnConsignmentStates.length)) {
      this.tableTitle = 'Útvar - Vypravení - vše';
    }
    else if (this.isUnitView && isEqual(this.states, [OwnConsignmentStatus.REJECTED])) {
      this.tableTitle = 'Útvar - Vrácené zásilky';
    }
    else if (this.isUnitView && isEqual(this.states, [OwnConsignmentStatus.DISPATCHED_AND_WAITING_FOR_DELIVERY_CONFIRMATION])) {
      this.tableTitle = 'Útvar - Zápis doručení';
    }
  }

  private setDataSource() {
    if (this.isOfficerCreateView) {
      if (this.document) {
        if (this.isOfficerCreateNonOfficeDeskView) {
          this.dataSource = new DocumentDetailOwnConsignmentsDatasource(this.ownConsignmentsSearchService, this.document!.id);
        } else if (this.isOfficerCreateOfficeDeskView) {
          this.dataSource = new ReceivedDocumentDetailOwnConsignmentsDatasource(this.ownConsignmentsSearchService, this.document!.id);
        }
      }
    }
    else if (this.isForDispatchOfficerView) {
      if (this.isOfficeDeskView) {
        this.dataSource = new OwnConsignmentsOfficeDeskDispatchOfficerDatasource(this.ownConsignmentsSearchService, this.states);
      }
      else {
        this.setConsignmentFiltersByTab();
        this.dataSource = new OwnConsignmentsForDispatchOfficerDatasource(this.ownConsignmentsSearchService, this.states, this.dispatchOfficeDistributionNodeIds, this.consignmentTypes);
      }
    }
    else if (this.isForOfficerView) {
      if (this.isOfficeDeskView) {
        this.dataSource = new OwnConsignmentsOfficeDeskOwnerDatasource(this.ownConsignmentsSearchService, this.states);
      } else {
        this.setConsignmentFiltersByTab();
        if (this.isUnitView) {
          this.dataSource = new OwnConsignmentsForOwnerOrUnitDatasource(this.ownConsignmentsSearchService, {isUnitView: true, orgUnitId: this.orgUnitId}, this.states, this.consignmentTypes);
        } else {
          this.dataSource = new OwnConsignmentsForOwnerOrUnitDatasource(this.ownConsignmentsSearchService, {isUnitView: false}, this.states, this.consignmentTypes);
        }
      }
    }
    else if (this.isMultiConsignmentContentView) {
      this.dataSource = new OwnConsignmentsForDispatchOfficerDatasource(this.ownConsignmentsSearchService, this.states, this.dispatchOfficeDistributionNodeIds, this.consignmentTypes, this.ownPaperMultiConsignmentId);
    }
  }

  dispatchOfficeSelectorChanged(dispatchOffice: Nullable<DispatchOfficeDto>) {
    if (dispatchOffice) {
      this.dispatchOfficeDistributionNodeIds = dispatchOffice.distributionNodes;
    } else {
      this.dispatchOfficeDistributionNodeIds = [];
    }
    this.setDataSource();

    this.selectedDispatchOfficeId = dispatchOffice?.id ?? null;
    this.canCreateBulkPostingForm = dispatchOffice !== null;
    this.clearExcludedConsignmentsForBpf();
  }

  unitSelected(orgUnitId: Nullable<number>) {
    this.orgUnitId = orgUnitId;
    this.setDataSource();
    this.handleTabCountsForUnit();
  }

  getOutboundConsignmentIdentifier(row: GenericOwnElasticConsignmentWithConsignee): Nullable<string> {
    return getOutboundConsignmentIdentifier(row);
  }

  private setColumnSet() {
    let columnSet = officerConsignmentsOnDocumentColumnSet;

    if (this.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS) {
      columnSet = officerConsignmentsOnDocumentColumnSet;
    }
    else if (this.tableView === OwnConsignmentTableView.OFFICER_VIEW_ALL) {
      columnSet = officerAllColumnSet;
    }
    else if (this.tableView === OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS) {
      columnSet = officerInProgressColumnSet;
    }
    else if (this.tableView === OwnConsignmentTableView.OFFICER_VIEW_REJECTED) {
      columnSet = officerRejectedColumnSet;
    }
    else if (this.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_CREATE_OFFICE_DESK_CONSIGNMENTS ||
      this.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS) {
      columnSet = officeDeskOfficerViewInProgressColumnSet;
    }
    else if (this.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION) {
      columnSet = dispatchOfficerInDistributionColumnSet;
    }
    else if (this.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED) {
      columnSet = dispatchOfficerDispatchedColumnSet;
    }
    else if (isDispatchOfficerSpecificStatusView(this.tableView as OwnConsignmentTableView)) {
      columnSet = dispatchOfficerSpecificStatusColumnSet;
    }
    else if (
      this.tableView === OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT ||
      this.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT
    ) {
      columnSet = recordDeliveryResultColumnSet;
    }
    else if (this.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL_POSTED ||
      this.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL) {
      columnSet = officeDeskOfficerViewAllColumnSet;
    }
    else if (this.tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER) {
      columnSet = officeDeskViewToTakeoverColumnSet;
    }
    else if (this.tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_POST) {
      columnSet = officeDeskViewToPostColumnSet;
    }
    else if (this.tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_POSTED) {
      columnSet = officeDeskViewPostedColumnSet;
    }
    else if (this.tableView === OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED) {
      columnSet = officeDeskOfficerViewRejectedColumnSet;
    }
    else if (this.tableView === OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_UNPOSTED) {
      columnSet = officeDeskViewUnpostedColumnSet;
    }
    else if (this.tableView === OwnConsignmentTableView.MULTI_CONSIGNMENT_CONTENT) {
      columnSet = officerAllColumnSet;
    }

    this.columnsData = getColumnsData(
      this.codebookService.deliveryTypes(),
      this.codebookService.deliveryResults(),
      this.codebookService.deliveryServiceBasics(),
      this.codebookService.deliveryServiceAdditionals(),
      this.codebookService.deliveryServiceCombinations(),
      this.codebookService.officeDeskCategories(),
      this.codebookService.officeDeskRegions(),
      this.codebookService.officeDeskNodes(),
      this.codebookService.distributionNodesAll(),
      this.documentFiltersDataService,
      columnSet,
      this.tableView as OwnConsignmentTableView,
      this.activatedTab ? this.activatedTab.id as OwnConsignmentTableConsignmentTypeFilter : OwnConsignmentTableConsignmentTypeFilter.ALL,
    );
  }

  private handleTabCountsForUnit() {
    if (!this.isUnitView) return;

    const allTab = this.tableTabs.find(t => t.id === OwnConsignmentTableConsignmentTypeFilter.ALL)!;

    const countsPerStateAndUnit = (counts: OwnConsignmentPerStateCountForOwnerDto) => {
      allTab!.count ??= 0;

      if (this.states.length === this.allOwnConsignmentStates.length) {
        allTab!.count = counts.allCount;
      }
      else {
        if (this.states.includes(OwnConsignmentStatus.TO_HANDOVER)) {
          allTab!.count = counts.toHandoverCount;
        }
        if (this.states.includes(OwnConsignmentStatus.REJECTED)) {
          allTab!.count = counts.rejectedCount;
        }
      }

      this.tableTabs = clone(this.tableTabs);
    };

    if (isOfficerSpecificStatusView(this.tableView as OwnConsignmentTableView)) {
      if (!isNil(this.orgUnitId)) {
        this.apiOwnConsignmentService.ownConsignmentReturnConsignmentsPerStateCountForSecretariat_1({
          orgUnitId: this.orgUnitId,
          page: 0,
          size: 100
        }).subscribe(counts => {
          countsPerStateAndUnit(counts);
        });
      } else {
        this.apiOwnConsignmentService.ownConsignmentReturnConsignmentsPerStateCountForSecretariat({page: 0, size: 100}).subscribe(counts => {
          countsPerStateAndUnit(counts);
        });
      }
    }
  }

  private handleTabCounts() {
    const allTab = this.tableTabs.find(t => t.id === OwnConsignmentTableConsignmentTypeFilter.ALL)!;

    if (isDispatchOfficerSpecificStatusView(this.tableView as OwnConsignmentTableView)) {
      this.mainMenuCountsService.menuItemsWithCounts$.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((menuItems: Nullable<MenuItem[]>) => {
        if (!this.isUnitView && menuItems) {
          allTab!.count = 0;

          if (this.states.length !== this.allOwnConsignmentStates.length) {
            this.setAllTabCountByDispatchCounts(allTab, menuItems, true);
          }

          this.tableTabs = clone(this.tableTabs);
        }
      });
    } else if (isOfficerSpecificStatusView(this.tableView as OwnConsignmentTableView)){
      this.mainMenuCountsService.menuItemsWithCounts$.pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe((menuItems: Nullable<MenuItem[]>) => {
        if (!this.isUnitView && menuItems) {
          allTab!.count = 0;
          if (this.states.length === this.allOwnConsignmentStates.length) {
            allTab!.count! += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_ALL)?.count ?? 0;
          }
          else {
            this.setAllTabCountByDispatchCounts(allTab, menuItems, false);
          }

          this.tableTabs = clone(this.tableTabs);
        }
      });
    }
  }

  private setAllTabCountByDispatchCounts(allTab: TabItem<OwnConsignmentTableConsignmentTypeFilter>,
                                         menuItems: MenuItem[],
                                         isCurrentUserDispatchOfficer: boolean,
  ) {
    allTab!.count = 0;

    if (this.states.includes(OwnConsignmentStatus.IN_DISTRIBUTION)) {
      allTab!.count += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_IN_DISTRIBUTION)?.count ?? 0;
    }
    if (this.states.includes(OwnConsignmentStatus.TO_TAKEOVER)) {
      allTab!.count += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_TO_TAKE_OVER)?.count ?? 0;
    }
    if (this.states.includes(OwnConsignmentStatus.TAKEN_OVER)) {
      allTab!.count += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_TO_DISPATCH)?.count ?? 0;
    }
    if (this.states.includes(OwnConsignmentStatus.DISPATCHED)) {
      allTab!.count += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_DISPATCHED)?.count ?? 0;
    }
    if (this.states.includes(OwnConsignmentStatus.DISPATCHED_AND_WAITING_FOR_DELIVERY_CONFIRMATION)) {
      if (isCurrentUserDispatchOfficer) {
        allTab!.count += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_DISPATCH_OFFICER_DISPATCH_OFFICE_RECORD_DELIVERY_RESULT)?.count ?? 0;
      } else {
        allTab!.count += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_RECORD_DELIVERY_RESULT)?.count ?? 0;
      }
    }
    if (this.states.includes(OwnConsignmentStatus.REJECTED)) {
      allTab!.count += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_REJECTED)?.count ?? 0;
    }
    if (this.states.includes(OwnConsignmentStatus.TO_HANDOVER)) {
      allTab!.count += findMenuItemByViewId(menuItems, MenuItemCountViewIds.CONSIGNMENTS_OFFICER_VIEW_IN_PROGRESS)?.count ?? 0;
    }
  }

  ngOnInit() {
    this.adjustTableConfig();
    this.setColumnSet();
    this.handleTabCounts();

    combineLatest([
      this.activatedRoute.queryParamMap,
      this.router.events.pipe(
        filterByClass(NavigationEnd),
        startWith(null),
        map(_ => this.router.url.split('?')[0]),
        distinctUntilChanged(),
      ),
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([paramMap, url]) => {
      if (paramMap.has(MESSAGE_VIEW_QUERY_PARAM_NAME)) {
        const activatedView = paramMap.get(MESSAGE_VIEW_QUERY_PARAM_NAME)! as OwnConsignmentTableConsignmentTypeFilter;

        if (enumValuesToArray(OwnConsignmentTableConsignmentTypeFilter).includes(activatedView)) {
          this.activatedTab = this.tableTabs.find(t => t.id === activatedView)!;
        }
      }
      else {
        this.activatedTab = this.tableTabs[0];
      }

      this.isUnitView = Boolean(url.startsWith(`/${ApplicationRoute.UNIT}`));
      this.setTableTitleByOrgUnit();

      if (this.isUnitView) {
        this.handleTabCountsForUnit();
      }

      this.handleTableTabViewChange(this.activatedTab!);
    });

    this.codebookService.ownPaperConsignmentConfiguration().subscribe(config => {
      this.guidedBPFGenerationEnabled = config.guidedBPFGenerationEnabled;
    });

    this.tableConfig = extendDefaultTableConfig({
      defaultSort: (
        this.tableView === OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER ?
          {fieldName: OwnConsignmentColumnKey.HANDOVER_DATE, descending: true} :
          (this.isViewWithHandoverDateSort ?
              {fieldName: OwnConsignmentColumnKey.DISPATCH_DATE, descending: true} :
              undefined
          )
      ),
      rowHeight: this.tableView === OwnConsignmentTableView.OFFICER_CREATE_CONSIGNMENTS ? 2 * 36 : 36,
      defaultFilterColumns: [
        OwnConsignmentColumnKey.CONSIGNMENT_TYPE,
        OwnConsignmentColumnKey.ORIGINAL_CONSIGNEE,
        OwnConsignmentColumnKey.DOCUMENT_REF_NUMBER,
        OwnConsignmentColumnKey.UUID,
        OwnConsignmentColumnKey.DELIVERY_TYPE_ID,
        OwnConsignmentColumnKey.CREATED_AT,
        OwnConsignmentColumnKey.DELIVERY_SERVICE_COMBINATION_ID,
      ],
    });

    this.codebookService.dispatchOffices().subscribe(dispatchOffices => {
      if (!dispatchOffices?.length) return;
      this.isSingleDispatchOfficeSystem = dispatchOffices.length === 1;
      this.canCreateBulkPostingForm = this.isSingleDispatchOfficeSystem;
      this.determineShowDispatchOfficeSelector(dispatchOffices);
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.document?.currentValue && isNil(changes.document?.previousValue)) {
      if (this.isOfficerCreateView) {
        this.setDataSource();
      }
      if (changes.document.currentValue.documentState === DocumentState.DEACTIVATED) {
        this.isOwnDocumentDeactivated = true;
      }
    }
  }

  selectedRowsChanged($event: GenericOwnElasticConsignmentWithConsignee[]) {
    this.selectedRows = $event;
  }

  private clearExcludedConsignmentsForBpf() {
    // Optimization: do not change exclusion array reference if it has no items
    //  to reduce number of requests made by ConsignmentToolbarButtonsComponent.
    if (this.excludedConsignmentIdsForBpf.length > 0) {
      this.excludedConsignmentIdsForBpf = [];
    }
  }

  protected readonly pageXOffset = pageXOffset;
}
