<icz-chip [isDisabled]="isDisabled" (clicked)="clicked.emit()">
  <ng-container content>
    @if (customTemplate) {
      <ng-container [ngTemplateOutlet]="customTemplate"></ng-container>
    }
    @if (!customTemplate) {
      @if (!isActive) {
        <div class="row no-value">
          <ng-content select="[prefix]"></ng-content>
          @if ((filterName | translate); as filterNameTranslated) {
            <div class="filter-name icz-body-1 grow" [iczTooltip]="filterNameTranslated">{{filterNameTranslated}}</div>
          }
        </div>
      }
      @if (isActive) {
        @if ((filterName | translate); as filterNameTranslated) {
          <div class="filter-name-active icz-body-1 truncate" [iczTooltip]="filterNameTranslated">{{filterNameTranslated}}</div>
        }
        @if (selectedOptions) {
          <div class="icz-body-1 pt-2">
            {{ getTranslationKey(operator) | translate | lowercase }}
            <!--
            manual indexation instead of @for is intentional here,
            avoiding filtering, selectedOptions might have thousands of items
            -->
            <span class="active-item-value font-bold">
              @if (selectedOptions!.length > 0) {
                {{ selectedOptions![0].label | translate }}
              }
              @if (selectedOptions!.length > 1) {
                , {{ selectedOptions![1].label | translate }}
              }
              @if (selectedOptions!.length > 2) {
                + {{ selectedOptions!.length - 2 }}
              }
            </span>
          </div>
        }
        @else {
          <div class="active-item row">
            <div class="icz-body-1">
              {{ getTranslationKey(operator) | translate | lowercase }}
            </div>
            <div [iczTooltip]="filterValue! | translate"
              [iczTooltipShowDelay]="TRUNCATED_TEXT_TOOLTIP_DELAY"
              class="active-item-value grow font-bold truncate">
              {{ filterValue }}
            </div>
          </div>
        }
      }
    }
  </ng-container>
  <ng-container suffix>
    <ng-content select="[suffix]"></ng-content>
  </ng-container>
</icz-chip>
