/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageRegistryOfficeTransferProblemDto } from '../models/page-registry-office-transfer-problem-dto';
import { registryOfficeTransferBulkMarkAllAsResolved } from '../fn/registry-office-transfer/registry-office-transfer-bulk-mark-all-as-resolved';
import { RegistryOfficeTransferBulkMarkAllAsResolved$Params } from '../fn/registry-office-transfer/registry-office-transfer-bulk-mark-all-as-resolved';
import { registryOfficeTransferBulkMarkAsResolved } from '../fn/registry-office-transfer/registry-office-transfer-bulk-mark-as-resolved';
import { RegistryOfficeTransferBulkMarkAsResolved$Params } from '../fn/registry-office-transfer/registry-office-transfer-bulk-mark-as-resolved';
import { registryOfficeTransferBulkMarkAsResolvedSync } from '../fn/registry-office-transfer/registry-office-transfer-bulk-mark-as-resolved-sync';
import { RegistryOfficeTransferBulkMarkAsResolvedSync$Params } from '../fn/registry-office-transfer/registry-office-transfer-bulk-mark-as-resolved-sync';
import { registryOfficeTransferCancelTransfer } from '../fn/registry-office-transfer/registry-office-transfer-cancel-transfer';
import { RegistryOfficeTransferCancelTransfer$Params } from '../fn/registry-office-transfer/registry-office-transfer-cancel-transfer';
import { registryOfficeTransferFindAllRegistryOfficeTransferProblems } from '../fn/registry-office-transfer/registry-office-transfer-find-all-registry-office-transfer-problems';
import { RegistryOfficeTransferFindAllRegistryOfficeTransferProblems$Params } from '../fn/registry-office-transfer/registry-office-transfer-find-all-registry-office-transfer-problems';
import { registryOfficeTransferFindRegistryOfficeTransferProblems } from '../fn/registry-office-transfer/registry-office-transfer-find-registry-office-transfer-problems';
import { RegistryOfficeTransferFindRegistryOfficeTransferProblems$Params } from '../fn/registry-office-transfer/registry-office-transfer-find-registry-office-transfer-problems';
import { registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount } from '../fn/registry-office-transfer/registry-office-transfer-find-registry-office-transfer-problems-and-count';
import { RegistryOfficeTransferFindRegistryOfficeTransferProblemsAndCount$Params } from '../fn/registry-office-transfer/registry-office-transfer-find-registry-office-transfer-problems-and-count';
import { registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos } from '../fn/registry-office-transfer/registry-office-transfer-find-registry-office-transfer-with-main-entity-infos';
import { RegistryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos$Params } from '../fn/registry-office-transfer/registry-office-transfer-find-registry-office-transfer-with-main-entity-infos';
import { registryOfficeTransferGenerateSip } from '../fn/registry-office-transfer/registry-office-transfer-generate-sip';
import { RegistryOfficeTransferGenerateSip$Params } from '../fn/registry-office-transfer/registry-office-transfer-generate-sip';
import { registryOfficeTransferGetRegistryOfficeTransferProblemById } from '../fn/registry-office-transfer/registry-office-transfer-get-registry-office-transfer-problem-by-id';
import { RegistryOfficeTransferGetRegistryOfficeTransferProblemById$Params } from '../fn/registry-office-transfer/registry-office-transfer-get-registry-office-transfer-problem-by-id';
import { RegistryOfficeTransferIncidentDto } from '../models/registry-office-transfer-incident-dto';
import { RegistryOfficeTransferProblemDto } from '../models/registry-office-transfer-problem-dto';
import { registryOfficeTransferReTransferToDesa } from '../fn/registry-office-transfer/registry-office-transfer-re-transfer-to-desa';
import { RegistryOfficeTransferReTransferToDesa$Params } from '../fn/registry-office-transfer/registry-office-transfer-re-transfer-to-desa';
import { registryOfficeTransferReTransferToRegistryOffice } from '../fn/registry-office-transfer/registry-office-transfer-re-transfer-to-registry-office';
import { RegistryOfficeTransferReTransferToRegistryOffice$Params } from '../fn/registry-office-transfer/registry-office-transfer-re-transfer-to-registry-office';
import { registryOfficeTransferReturnToOffice } from '../fn/registry-office-transfer/registry-office-transfer-return-to-office';
import { RegistryOfficeTransferReturnToOffice$Params } from '../fn/registry-office-transfer/registry-office-transfer-return-to-office';
import { registryOfficeTransferTransferCreate } from '../fn/registry-office-transfer/registry-office-transfer-transfer-create';
import { RegistryOfficeTransferTransferCreate$Params } from '../fn/registry-office-transfer/registry-office-transfer-transfer-create';
import { registryOfficeTransferTransferReject } from '../fn/registry-office-transfer/registry-office-transfer-transfer-reject';
import { RegistryOfficeTransferTransferReject$Params } from '../fn/registry-office-transfer/registry-office-transfer-transfer-reject';
import { registryOfficeTransferTransferTakeover } from '../fn/registry-office-transfer/registry-office-transfer-transfer-takeover';
import { RegistryOfficeTransferTransferTakeover$Params } from '../fn/registry-office-transfer/registry-office-transfer-transfer-takeover';
import { registryOfficeTransferUpdateRegistryOfficeTransferIncident } from '../fn/registry-office-transfer/registry-office-transfer-update-registry-office-transfer-incident';
import { RegistryOfficeTransferUpdateRegistryOfficeTransferIncident$Params } from '../fn/registry-office-transfer/registry-office-transfer-update-registry-office-transfer-incident';
import { registryOfficeTransferUpdateRegistryOfficeTransferProblem } from '../fn/registry-office-transfer/registry-office-transfer-update-registry-office-transfer-problem';
import { RegistryOfficeTransferUpdateRegistryOfficeTransferProblem$Params } from '../fn/registry-office-transfer/registry-office-transfer-update-registry-office-transfer-problem';
import { RegistryOfficeTransferWithMainEntityInfoDto } from '../models/registry-office-transfer-with-main-entity-info-dto';


/**
 * Controller for registry office transfer
 */
@Injectable({ providedIn: 'root' })
export class ApiRegistryOfficeTransferService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `registryOfficeTransferGenerateSip()` */
  static readonly RegistryOfficeTransferGenerateSipPath = '/registry-office-transfer/{entityType}/{entityId}/generate-sip';

  /**
   * Generates SIP
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferGenerateSip()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferGenerateSip$Response(params: RegistryOfficeTransferGenerateSip$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferGenerateSip(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates SIP
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferGenerateSip$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferGenerateSip(params: RegistryOfficeTransferGenerateSip$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferGenerateSip$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferGetRegistryOfficeTransferProblemById()` */
  static readonly RegistryOfficeTransferGetRegistryOfficeTransferProblemByIdPath = '/registry-office-transfer/problems/{id}';

  /**
   * Gets registry office transfer problem by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferGetRegistryOfficeTransferProblemById()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferGetRegistryOfficeTransferProblemById$Response(params: RegistryOfficeTransferGetRegistryOfficeTransferProblemById$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistryOfficeTransferProblemDto>> {
    return registryOfficeTransferGetRegistryOfficeTransferProblemById(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets registry office transfer problem by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferGetRegistryOfficeTransferProblemById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferGetRegistryOfficeTransferProblemById(params: RegistryOfficeTransferGetRegistryOfficeTransferProblemById$Params, context?: HttpContext): Observable<RegistryOfficeTransferProblemDto> {
    return this.registryOfficeTransferGetRegistryOfficeTransferProblemById$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistryOfficeTransferProblemDto>): RegistryOfficeTransferProblemDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferUpdateRegistryOfficeTransferProblem()` */
  static readonly RegistryOfficeTransferUpdateRegistryOfficeTransferProblemPath = '/registry-office-transfer/problems/{id}';

  /**
   * Updates registry office transfer problem with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferUpdateRegistryOfficeTransferProblem()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferUpdateRegistryOfficeTransferProblem$Response(params: RegistryOfficeTransferUpdateRegistryOfficeTransferProblem$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistryOfficeTransferProblemDto>> {
    return registryOfficeTransferUpdateRegistryOfficeTransferProblem(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates registry office transfer problem with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferUpdateRegistryOfficeTransferProblem$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferUpdateRegistryOfficeTransferProblem(params: RegistryOfficeTransferUpdateRegistryOfficeTransferProblem$Params, context?: HttpContext): Observable<RegistryOfficeTransferProblemDto> {
    return this.registryOfficeTransferUpdateRegistryOfficeTransferProblem$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistryOfficeTransferProblemDto>): RegistryOfficeTransferProblemDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferUpdateRegistryOfficeTransferIncident()` */
  static readonly RegistryOfficeTransferUpdateRegistryOfficeTransferIncidentPath = '/registry-office-transfer/incidents/{id}';

  /**
   * Updates registry office transfer incident with given id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferUpdateRegistryOfficeTransferIncident()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferUpdateRegistryOfficeTransferIncident$Response(params: RegistryOfficeTransferUpdateRegistryOfficeTransferIncident$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistryOfficeTransferIncidentDto>> {
    return registryOfficeTransferUpdateRegistryOfficeTransferIncident(this.http, this.rootUrl, params, context);
  }

  /**
   * Updates registry office transfer incident with given id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferUpdateRegistryOfficeTransferIncident$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferUpdateRegistryOfficeTransferIncident(params: RegistryOfficeTransferUpdateRegistryOfficeTransferIncident$Params, context?: HttpContext): Observable<RegistryOfficeTransferIncidentDto> {
    return this.registryOfficeTransferUpdateRegistryOfficeTransferIncident$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistryOfficeTransferIncidentDto>): RegistryOfficeTransferIncidentDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferTransferTakeover()` */
  static readonly RegistryOfficeTransferTransferTakeoverPath = '/registry-office-transfer/takeover';

  /**
   * Finishes transfer activity by takeover.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferTransferTakeover()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferTransferTakeover$Response(params: RegistryOfficeTransferTransferTakeover$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferTransferTakeover(this.http, this.rootUrl, params, context);
  }

  /**
   * Finishes transfer activity by takeover.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferTransferTakeover$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferTransferTakeover(params: RegistryOfficeTransferTransferTakeover$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferTransferTakeover$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferReturnToOffice()` */
  static readonly RegistryOfficeTransferReturnToOfficePath = '/registry-office-transfer/return-to-office';

  /**
   * Returns a faulty registry office transfer back to its original initiator.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferReturnToOffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferReturnToOffice$Response(params: RegistryOfficeTransferReturnToOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferReturnToOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Returns a faulty registry office transfer back to its original initiator.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferReturnToOffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferReturnToOffice(params: RegistryOfficeTransferReturnToOffice$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferReturnToOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferTransferReject()` */
  static readonly RegistryOfficeTransferTransferRejectPath = '/registry-office-transfer/reject';

  /**
   * Finishes transfer activity by reject.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferTransferReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferTransferReject$Response(params: RegistryOfficeTransferTransferReject$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferTransferReject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finishes transfer activity by reject.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferTransferReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferTransferReject(params: RegistryOfficeTransferTransferReject$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferTransferReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferReTransferToRegistryOffice()` */
  static readonly RegistryOfficeTransferReTransferToRegistryOfficePath = '/registry-office-transfer/re-transfer-to-registry-office';

  /**
   * Attempts to re-transfer a rejected transfer to registry office.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferReTransferToRegistryOffice()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferReTransferToRegistryOffice$Response(params: RegistryOfficeTransferReTransferToRegistryOffice$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferReTransferToRegistryOffice(this.http, this.rootUrl, params, context);
  }

  /**
   * Attempts to re-transfer a rejected transfer to registry office.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferReTransferToRegistryOffice$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferReTransferToRegistryOffice(params: RegistryOfficeTransferReTransferToRegistryOffice$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferReTransferToRegistryOffice$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferReTransferToDesa()` */
  static readonly RegistryOfficeTransferReTransferToDesaPath = '/registry-office-transfer/re-transfer-to-desa';

  /**
   * Attempts to re-transfer a rejected transfer to DESA.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferReTransferToDesa()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferReTransferToDesa$Response(params: RegistryOfficeTransferReTransferToDesa$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferReTransferToDesa(this.http, this.rootUrl, params, context);
  }

  /**
   * Attempts to re-transfer a rejected transfer to DESA.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferReTransferToDesa$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferReTransferToDesa(params: RegistryOfficeTransferReTransferToDesa$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferReTransferToDesa$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferBulkMarkAllAsResolved()` */
  static readonly RegistryOfficeTransferBulkMarkAllAsResolvedPath = '/registry-office-transfer/problems/bulk-mark-all-problem-incidents-as-resolved';

  /**
   * Bulk marks registry office transfer incidents as resolved.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferBulkMarkAllAsResolved()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferBulkMarkAllAsResolved$Response(params: RegistryOfficeTransferBulkMarkAllAsResolved$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferBulkMarkAllAsResolved(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk marks registry office transfer incidents as resolved.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferBulkMarkAllAsResolved$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferBulkMarkAllAsResolved(params: RegistryOfficeTransferBulkMarkAllAsResolved$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferBulkMarkAllAsResolved$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferBulkMarkAsResolved()` */
  static readonly RegistryOfficeTransferBulkMarkAsResolvedPath = '/registry-office-transfer/incidents/bulk-mark-as-resolved';

  /**
   * Bulk marks registry office transfer incidents as resolved.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferBulkMarkAsResolved()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferBulkMarkAsResolved$Response(params: RegistryOfficeTransferBulkMarkAsResolved$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferBulkMarkAsResolved(this.http, this.rootUrl, params, context);
  }

  /**
   * Bulk marks registry office transfer incidents as resolved.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferBulkMarkAsResolved$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferBulkMarkAsResolved(params: RegistryOfficeTransferBulkMarkAsResolved$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferBulkMarkAsResolved$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferBulkMarkAsResolvedSync()` */
  static readonly RegistryOfficeTransferBulkMarkAsResolvedSyncPath = '/registry-office-transfer/incidents/bulk-mark-as-resolved-sync';

  /**
   * Synchronously bulk marks registry office transfer incidents as resolved.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferBulkMarkAsResolvedSync()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferBulkMarkAsResolvedSync$Response(params: RegistryOfficeTransferBulkMarkAsResolvedSync$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferBulkMarkAsResolvedSync(this.http, this.rootUrl, params, context);
  }

  /**
   * Synchronously bulk marks registry office transfer incidents as resolved.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferBulkMarkAsResolvedSync$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferBulkMarkAsResolvedSync(params: RegistryOfficeTransferBulkMarkAsResolvedSync$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferBulkMarkAsResolvedSync$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferTransferCreate()` */
  static readonly RegistryOfficeTransferTransferCreatePath = '/registry-office-transfer/create';

  /**
   * Creates transfer to registry office activity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferTransferCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferTransferCreate$Response(params: RegistryOfficeTransferTransferCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferTransferCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates transfer to registry office activity.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferTransferCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferTransferCreate(params: RegistryOfficeTransferTransferCreate$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferTransferCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferCancelTransfer()` */
  static readonly RegistryOfficeTransferCancelTransferPath = '/registry-office-transfer/cancel-transfer';

  /**
   * Cancels to transfer to registry office..
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferCancelTransfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferCancelTransfer$Response(params: RegistryOfficeTransferCancelTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeTransferCancelTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Cancels to transfer to registry office..
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferCancelTransfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeTransferCancelTransfer(params: RegistryOfficeTransferCancelTransfer$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeTransferCancelTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos()` */
  static readonly RegistryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfosPath = '/registry-office-transfer/{entityType}/{entityId}/with-main-entity';

  /**
   * Finds a registry office transfer with identifiers linking to its main transfer entity
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos$Response(params: RegistryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistryOfficeTransferWithMainEntityInfoDto>> {
    return registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds a registry office transfer with identifiers linking to its main transfer entity
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos(params: RegistryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos$Params, context?: HttpContext): Observable<RegistryOfficeTransferWithMainEntityInfoDto> {
    return this.registryOfficeTransferFindRegistryOfficeTransferWithMainEntityInfos$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistryOfficeTransferWithMainEntityInfoDto>): RegistryOfficeTransferWithMainEntityInfoDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferFindAllRegistryOfficeTransferProblems()` */
  static readonly RegistryOfficeTransferFindAllRegistryOfficeTransferProblemsPath = '/registry-office-transfer/problems';

  /**
   * Finds all registry office transfer problems
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferFindAllRegistryOfficeTransferProblems()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferFindAllRegistryOfficeTransferProblems$Response(params?: RegistryOfficeTransferFindAllRegistryOfficeTransferProblems$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistryOfficeTransferProblemDto>>> {
    return registryOfficeTransferFindAllRegistryOfficeTransferProblems(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all registry office transfer problems
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferFindAllRegistryOfficeTransferProblems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferFindAllRegistryOfficeTransferProblems(params?: RegistryOfficeTransferFindAllRegistryOfficeTransferProblems$Params|null|undefined, context?: HttpContext): Observable<Array<RegistryOfficeTransferProblemDto>> {
    return this.registryOfficeTransferFindAllRegistryOfficeTransferProblems$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistryOfficeTransferProblemDto>>): Array<RegistryOfficeTransferProblemDto> => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferFindRegistryOfficeTransferProblems()` */
  static readonly RegistryOfficeTransferFindRegistryOfficeTransferProblemsPath = '/registry-office-transfer/problems/search-api';

  /**
   * Finds all registry office transfer problems based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferFindRegistryOfficeTransferProblems()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferFindRegistryOfficeTransferProblems$Response(params?: RegistryOfficeTransferFindRegistryOfficeTransferProblems$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageRegistryOfficeTransferProblemDto>> {
    return registryOfficeTransferFindRegistryOfficeTransferProblems(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all registry office transfer problems based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferFindRegistryOfficeTransferProblems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferFindRegistryOfficeTransferProblems(params?: RegistryOfficeTransferFindRegistryOfficeTransferProblems$Params|null|undefined, context?: HttpContext): Observable<PageRegistryOfficeTransferProblemDto> {
    return this.registryOfficeTransferFindRegistryOfficeTransferProblems$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageRegistryOfficeTransferProblemDto>): PageRegistryOfficeTransferProblemDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount()` */
  static readonly RegistryOfficeTransferFindRegistryOfficeTransferProblemsAndCountPath = '/registry-office-transfer/problems/search-api/count';

  /**
   * Gets count of all registry office transfer problems based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount$Response(params?: RegistryOfficeTransferFindRegistryOfficeTransferProblemsAndCount$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<number>> {
    return registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount(this.http, this.rootUrl, params, context);
  }

  /**
   * Gets count of all registry office transfer problems based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount(params?: RegistryOfficeTransferFindRegistryOfficeTransferProblemsAndCount$Params|null|undefined, context?: HttpContext): Observable<number> {
    return this.registryOfficeTransferFindRegistryOfficeTransferProblemsAndCount$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

}
