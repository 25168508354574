import {TemplateRef} from '@angular/core';
import {
  BaseFilterDefinition,
  BASIC_FILTER_TYPES,
  BasicFilterDefinition,
  CodebookFilterDefinition,
  CombinedFilterDefinition,
  EnumFilterDefinition,
  FilterSubValue,
  FilterType, PageSelectorFilterDefinition
} from './filter.types';
import {IconSize} from '@icz/angular-essentials';
import {IczOption} from '@icz/angular-form-elements';
import {FilterOperator, getOperatorTranslationKey, SortParam} from './table.utils';

/**
 * @internal
 */
export const MIN_SEARCH_TERM_LENGTH = 2;

/**
 * @see AutoPageSizeConfig
 */
export interface AutoPageSizeByOffsets {
  /**
   * Table element vertical offset from screen top in px.
   */
  screenTopOffset: number;
  /**
   * Table element vertical offset from screen bottom in px.
   */
  screenBottomOffset: number;
}

/**
 * @see AutoPageSizeConfig
 */
export interface AutoPageSizeByManualHeight {
  /**
   * Fixed table height in px.
   */
  tableHeight: number;
}

/**
 * Auto page size can be determined by a total of three modes corresponding to individual type constituents:
 * * true - most commonly used - when computing row count in auto mode, SCREEN HEIGHT - 1x TOOLBAR HEIGHT will be used,
 * * AutoPageSizeByOffsets - table height is determined from offset by a fixed number of px from the top of the screen and the same applies for the bottom of the screen,
 * * AutoPageSizeByManualHeight - table height will be supplied manually, good if the table itself has fixed height (be careful not to use it in situations where table height can be flexible)
 */
export type AutoPageSizeConfig = true | AutoPageSizeByOffsets | AutoPageSizeByManualHeight;

/**
 * @internal
 */
export type TableTemplates = {
  [columnId: string]: {
    template: TemplateRef<any>,
    withEllipsis: boolean,
  }
};

/**
 * @internal
 */
export interface ResizeLineBounds {
  x: number;
  y: number;
  height: number;
}

/**
 * Configures various UI aspects of table row area.
 */
export interface TableConfig<TColumnKey extends string, TC = TableToolbarConfig> {
  /**
   * If true, clicking a table row (outside of clickable table row widgets) will make
   *   the given row blue and also will emit to @Output TableComponent.activeRowChanged.
   * An active row can be also un-activated by clicking it again. This will emit null to the aforementioned Output.
   * Used most commonly with TablePreviewComponent to open a quick row preview info bar.
   */
  hasActiveRow: boolean;
  /**
   * Specifies default sorting column and direction.
   * If unspecified, row order from database will be used.
   */
  defaultSort: Nullable<SortParam<TColumnKey>>;
  /**
   * Allows to pre-add
   */
  defaultFilterColumns: TColumnKey[];
  /**
   * If true, the rows will change color to light blue on hover.
   */
  hoverableRows: boolean;
  /**
   * Row height in px. Default = 36px.
   */
  rowHeight: number;
  /**
   * Auto page size mode config.
   * @see AutoPageSizeConfig
   */
  autoPageSizeConfig: AutoPageSizeConfig;
  /**
   * If true, allows checkbox selection to survive pagination.
   *   else selection will be erased on each pagination event.
   */
  allowMultiPageSelection: boolean;
  /**
   * A flag which will cause a specific table column to disobey its saved sort in user's local storage.
   *   Used as a workaround for a historic bug. Should not be used in production code unless really necessary.
   * @deprecated - we plan to remove this in the future once we migrate user settings from local storage to a database storage.
   */
  disableLocalStorageSortPersistence: boolean;
  /**
   * @see TableToolbarConfig
   */
  toolbarConfig: TC;
}

/**
 * A recursively partial type of table config used for patching default table configuration.
 */
export type TableConfigExtensions<TColumnKey extends string> = Partial<TableConfig<TColumnKey, Partial<TableToolbarConfig>>>;

/**
 * Configures various UI aspects of table toolbar functions
 */
export interface TableToolbarConfig {
  /**
   * Enables/disables the entire table toolbar.
   * Default true.
   */
  showToolbar: boolean;
  /**
   * Enables/disables structured logical query filtering feature.
   * Default true.
   */
  showFilter: boolean;
  /**
   * Enables/disables reload button.
   * Default true.
   */
  showReload: boolean;
  /**
   * Enables/disables table button toolbar display passed using [tools] ng-content slot.
   * Default true.
   */
  showTools: boolean;
  /**
   * Enables/disables fuzzy fulltext filtering feature.
   * Default true.
   */
  showFulltextSearch: boolean;
  /**
   * Shows a placeholder in fuzzy fulltext filter field.
   */
  fulltextSearchFieldPlaceholder: string;
  /**
   * Enables/disables filter saving/restoring feature.
   * Default true.
   */
  showSavedFilters: boolean;
  /**
   * Enables/disables table columns configuration toolbar which contains
   * checkboxes allowing the user to show/hide table columns in his view.
   */
  showColumnSelector: boolean;
  /**
   * If true, the table will not allow the user to add/remove filters to the table.
   * instead, a default set of available filters will always be visible.
   * The default set of available filters is configurable using ColumnProperties.defaultFilterColumns.
   */
  filterIsStatic: boolean;
  /**
   * If true, the filtering toolbar will be open on table init,
   * saving the user from opening the filters using filter button everytime.
   */
  autoOpenFilter: boolean;
  /**
   * If true, entirely disables filtering capabilities of the table.
   */
  disableFilter: boolean;
}

/**
 * Configures various UI aspects which are common to all table cells in a single table column.
 */
export interface ColumnProperties {
  displayed?: boolean;
  /**
   * Makes the column unshowable/unhidable using table column settings panel.
   */
  toggleable?: boolean;
  /**
   * Specifies an icon which will be visibie in the header header cell of the column.
   * Specifying a column icon automatically turns off header cell label.
   */
  icon?: string;
  /**
   * Controls column icon size. Ineffective if the icon property is unset.
   */
  iconSize?: IconSize;
  /**
   * If fixedWidth is defined, fixedWidth takes precedence both over minWidth and maxWidth and column cannot be resized.
   */
  fixedWidth?: number;
  /**
   * Minimal size of the column in px. The user cannot resize the column below this value.
   */
  minWidth?: number;
  /**
   * Maximal size of the column in px. The user cannot resize the column above this value.
   */
  maxWidth?: number;
  /**
   * Disables sorting capabilities of the column.
   */
  disableSort?: boolean;
  /**
   * Makes column header to have no label.
   */
  hideLabelInHeader?: boolean;
  /**
   * Should only ever be set to FALSE together with displayed: false as well.
   * Used when a certain column should never be visible - for instance when we want only
   * filtering capabilities for a certain attribute which is rendered in a different column.
   */
  allowSettingInContextMenu?: boolean;
}

/**
 * A client type for specifying table columns. Works like a discriminated union - type constituents
 * corresponding to individual filter types are automatically chosen based on filterType property value.
 */
export type ColumnDefinition<T extends string = never> = ColumnProperties & BaseFilterDefinition<T>;

/**
 * @internal
 */
export type CombinedColumnDefinition<T extends string = never> = ColumnProperties & CombinedFilterDefinition<T>;

/**
 * Type predicate which checks if the given column definition belongs to a basic filter.
 */
export function isBasicColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & BasicFilterDefinition<string> {
  return (BASIC_FILTER_TYPES as unknown as FilterType[]).includes(cd?.filterType!);
}

/**
 * Type predicate which checks if the given column definition belongs to an enum filter.
 */
export function isEnumColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & EnumFilterDefinition<string> {
  return cd?.filterType === FilterType.ENUM;
}

/**
 * Type predicate which checks if the given column definition belongs to a codebook filter.
 */
export function isCodebookColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & CodebookFilterDefinition<string> {
  return cd?.filterType === FilterType.CODEBOOK;
}

/**
 * Type predicate which checks if the given column definition belongs to a page selector filter.
 */
export function isPageSelectorColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & PageSelectorFilterDefinition<string> {
  return cd?.filterType === FilterType.PAGE_SELECTOR;
}

/**
 * Type predicate which checks if the given column definition belongs to an enum or codebook filter.
 */
export function isListColumnDefinition(cd: Nullable<Partial<ColumnDefinition<string>>>): cd is ColumnProperties & (EnumFilterDefinition<string> | CodebookFilterDefinition<string>) {
  return isEnumColumnDefinition(cd) || isCodebookColumnDefinition(cd);
}

/**
 * @internal
 */
export interface DeferredColumnList {
  /**
   * It is expected to mutate this property after the list arrives from an async operations.
   */
  list: IczOption[];
  /**
   * Loading indicator of the deferred list. Loading indicator changes
   * need to be made through mutating this property.
   */
  isListLoading: boolean;
}

/**
 * An object which carries value and value metadata of a table filter.
 */
export interface FilterValue {
  /**
   * Main value of the filter.
   */
  value: Nullable<string | string[]>;
  /**
   * A list of all filter options if applicable which also contains
   * an option associated to the value property.
   */
  list?: IczOption<Nullable<string | number>, any>[];
  /**
   * If subValues are set, value is ignored both in the rendering and filtering process.
   * We recommend setting a non-null reserved value, such as "interval" to FilterValue.value tho.
   * @see FilterSubValue
   */
  subValues?: Nullable<FilterSubValue[]>;
  /**
   * Label of the filter value as visible in the view (1)
   * @deprecated
   */
  viewValue?: string | number | string[];
  /**
   * Label of the filter value as visible in the view (2)
   * @deprecated
   */
  label: Nullable<string>;
  /**
   * Filtering operator to apply for the value
   */
  filterOperator: Nullable<FilterOperator>;
  /**
   * A flag which denotes whether to close filter widget popup when
   * an object of this type gets emitted from a filter component.
   */
  closeAfter?: boolean;
}

export interface TablePage {
  page: number;
  size: number;
}

/** @internal */
const EQUALS = {label: getOperatorTranslationKey(FilterOperator.equals), value: FilterOperator.equals, icon: 'equal'};
/** @internal */
const NOT_EQUALS = {label: getOperatorTranslationKey(FilterOperator.notEquals), value: FilterOperator.notEquals, icon: 'unequal'};
/** @internal */
const EMPTY = {label: getOperatorTranslationKey(FilterOperator.empty), value: FilterOperator.empty, icon: 'empty'};
/** @internal */
const NOT_EMPTY = {label: getOperatorTranslationKey(FilterOperator.notEmpty), value: FilterOperator.notEmpty, icon: 'not_empty'};
/** @internal */
const CONTAINS = {label: getOperatorTranslationKey(FilterOperator.contains), value: FilterOperator.contains, icon: 'contains'};
/** @internal */
const DOES_NOT_CONTAINS = {label: getOperatorTranslationKey(FilterOperator.doesNotContains), value: FilterOperator.doesNotContains, icon: 'does_not_contain'};
/** @internal */
const STARTS_WITH = {label: getOperatorTranslationKey(FilterOperator.startsWith), value: FilterOperator.startsWith, icon: 'starting_with'};
/** @internal */
const ENDS_WITH = {label: getOperatorTranslationKey(FilterOperator.endsWith), value: FilterOperator.endsWith, icon: 'ending'};
/** @internal */
const GREATER = {label: getOperatorTranslationKey(FilterOperator.greater), value: FilterOperator.greater, icon: 'bigger'};
/** @internal */
const GREATER_OR_EQUAL = {label: getOperatorTranslationKey(FilterOperator.greaterOrEqual), value: FilterOperator.greaterOrEqual, icon: 'bigger_or_equal'};
/** @internal */
const LESS = {label: getOperatorTranslationKey(FilterOperator.less), value: FilterOperator.less, icon: 'smaller'};
/** @internal */
const LESS_OR_EQUAL = {label: getOperatorTranslationKey(FilterOperator.lessOrEqual), value: FilterOperator.lessOrEqual, icon: 'smaller_or_equal'};

/**
 * @internal
 */
export const allToolbarOperators: Array<IczOption<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  EMPTY,
  NOT_EMPTY,
  CONTAINS,
  DOES_NOT_CONTAINS,
  STARTS_WITH,
  ENDS_WITH,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL
];

/**
 * @internal
 */
export const textToolbarOperators: Array<IczOption<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  CONTAINS,
  DOES_NOT_CONTAINS,
  STARTS_WITH,
  ENDS_WITH,
  EMPTY,
  NOT_EMPTY,
];

/**
 * @internal
 */
export const listToolbarOperators: Array<IczOption<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  EMPTY,
  NOT_EMPTY,
];

/**
 * @internal
 */
export const numberToolbarOperators: Array<IczOption<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,
  EMPTY,
  NOT_EMPTY,
];

/**
 * @internal
 */
export const fileSizeToolbarOperators: Array<IczOption<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,
];

/**
 * @internal
 */
export const empowermentToolbarOperators: Array<IczOption<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
];

/**
 * @internal
 */
export const subjectRecordToolbarOperators = [EQUALS];

/**
 * @internal
 */
export const addressToolbarOperators = [
  CONTAINS,
  DOES_NOT_CONTAINS,
  EMPTY,
  NOT_EMPTY
];

/**
 * @internal
 */
export const dateToolbarOperators: Array<IczOption<FilterOperator>> = [
  EQUALS,
  NOT_EQUALS,
  GREATER,
  GREATER_OR_EQUAL,
  LESS,
  LESS_OR_EQUAL,
  EMPTY,
  NOT_EMPTY,
];

/**
 * @internal
 */
export const dateStatisticsToolbarOperators: Array<IczOption<FilterOperator>> = [
  EQUALS,
];

/**
 * @internal
 */
export const booleanOperator: Array<IczOption<FilterOperator>> = [
  EQUALS
];
