import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {filter} from 'rxjs/operators';
import {
  IczValidatorFn,
  ValidationErrorMessage
} from '@icz/angular-form-elements';
import {FunctionalPositionGroupItems, SavedFunctionalPositionsService} from '../saved-functional-positions.service';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {IczValidators} from '@icz/angular-form-elements';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


export class OrgStructureSelectionValidators {
  @ValidationErrorMessage('Zvolte pro skupinu jiný název, tento by byl duplicitní.')
  static nameNotDuplicated(savedFunctionalPositionsService: SavedFunctionalPositionsService): IczValidatorFn {
    return (control: AbstractControl) => {
      const groupName = control.value;

      return groupName in (savedFunctionalPositionsService.savedFunctionalPositions ?? {}) ?
        {nameNotDuplicated: true} :
        null;
    };
  }
}


@Component({
  selector: 'icz-org-structure-selection-saver',
  templateUrl: './org-structure-selection-saver.component.html',
  styleUrls: ['./org-structure-selection-saver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgStructureSelectionSaverComponent implements OnInit {

  private cd = inject(ChangeDetectorRef);
  private savedFunctionalPositionsService = inject(SavedFunctionalPositionsService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  parentForm!: IczFormGroup;
  @Input()
  isGroupOrdered = false;
  @Input()
  group: FunctionalPositionGroupItems = [];

  groupSaved = false;

  form = new IczFormGroup({
    groupName: new IczFormControl<Nullable<string>>(null, this.getGroupNameValidators()),
  });

  isExpanded = false;
  tooltipText = 'Pokud vybraná funkční místa a organizační jednotky budete opakovaně používat, můžete si je uložit ' +
    'jako skupinu, kterou pak v oběhových aktivitách jednoduše vyberete. Skupiny si můžete spravovat v administraci aplikace.';

  groupJustSaved = false;

  ngOnInit(): void {
    this.parentForm.dirtinessChanged.pipe(
      filter(isDirty => isDirty === true),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.groupSaved = false;
    });
  }

  saveClicked() {
    if (this.form.valid) {
      const groupNameControl = this.form.get('groupName')!;
      groupNameControl.clearValidators();

      this.savedFunctionalPositionsService.addFunctionalPositionGroup(groupNameControl.value!, this.group, this.isGroupOrdered);

      this.groupJustSaved = true;

      setTimeout(() => {
        this.groupJustSaved = false;
        this.groupSaved = true;
        this.isExpanded = false;

        this.form.reset();
        this.parentForm.markAsPristine();

        groupNameControl.addValidators(this.getGroupNameValidators());

        this.cd.detectChanges();
      }, 2000);
    }
  }

  toggleExpansion() {
    this.isExpanded = !this.isExpanded;

    if (!this.isExpanded) {
      this.form.reset();
    }
  }

  private getGroupNameValidators() {
    return [
      IczValidators.required(),
      OrgStructureSelectionValidators.nameNotDuplicated(this.savedFunctionalPositionsService),
    ];
  }

}
