<div class="row">
  <div class="col grow">
    <div class="row">
      <icz-label label="Changelog" class="grow"></icz-label>
      <icz-button svgIcon="close" background="transparent" (onAction)="onClose.emit()"></icz-button>
    </div>

    <hr>

      <div class="col gap-8 list">
        <ul class="ml--16">
          @for (commit of (changelog$ | async); track commit.commitHash) {
            <li class="whitespace-nowrap"
              [ngClass]="{'non-merge-commit': !commit['pullReqNr']}">
              {{commit["authDate"]}}
              @if (!commit['pullReqNr']) {
                <a target="_blank" href="{{repoURL}}/commits/{{commit['commitHash']}}">
                  {{commit['commitHash']}}
                </a>
                {{commit["commitMsg"]}}
              }
              @else {
                <a target="_blank" href="{{repoURL}}/pull-requests/{{commit['pullReqNr']}}/overview">
                  PR {{commit['pullReqNr']}}
                </a>
                from {{commit["srcBranch"]}}
                to {{commit["tgtBranch"]}}
              }
              ({{commit["author"]}})
            </li>
          }
        </ul>
      </div>
    </div>
  </div>
