import {ChangeDetectionStrategy, Component, EventEmitter, inject, Output} from '@angular/core';
import {of, pipe} from 'rxjs';
import {catchError, debounceTime, map, switchMap, tap} from 'rxjs/operators';
import {CzechAddressDto} from '|api/commons';
import {
  IczFormControl,
  IczFormGroup,
  IczOption,
  locateOptionByValue,
  makeDefaultOptionsDefinition,
  OptionsDefinitionFactory
} from '@icz/angular-form-elements';
import {ApiRuianAddressesService} from '|api/elastic';
import {formatCzechAddress} from '../../../address-pipe/address.pipe';

type AddressOption = IczOption<string, CzechAddressDto>;

@Component({
  selector: 'icz-address-fulltext-selector',
  templateUrl: './address-fulltext-selector.component.html',
  styleUrls: ['./address-fulltext-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressFulltextSelectorComponent {

  private apiRuianAddressesService = inject(ApiRuianAddressesService);

  @Output()
  addressSelected = new EventEmitter<CzechAddressDto>();

  form = new IczFormGroup({
    searchField: new IczFormControl(null),
  });

  makeFileSearchOptionsDefinition: OptionsDefinitionFactory = (options$, strForSearch) => {
    const defaultDefinition = makeDefaultOptionsDefinition(options$, strForSearch);

    defaultDefinition.searchtermToOptionsOperator = pipe(
      debounceTime(300),
      switchMap(searchTerm => {
        if (searchTerm.length >= this.minSearchTermLength) {
          return this.findAddressesUsingFulltext(searchTerm);
        }
        else {
          return of([]);
        }
      }),
      catchError(_ => of([])),
      map(addresses => addresses.map(a => ({
        value: a.id,
        label: formatCzechAddress(a),
        data: a
      })) as AddressOption[]),
      tap(options => this.searchedOptions = options),
    );

    return defaultDefinition;
  };

  minSearchTermLength = 3;

  searchedOptions: AddressOption[] = [];

  private findAddressesUsingFulltext(searchTerm: string) {
    return this.apiRuianAddressesService.ruianAddressesSearchAddresses({
      body: {
        queryText: searchTerm,
      }
    });
  }

  onAddressSelected(selectedId: string) {
    const selection = locateOptionByValue(this.searchedOptions, selectedId);

    if (selection){
      this.form.reset();
      this.addressSelected.emit(selection.data);
    }
  }

}
