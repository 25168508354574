import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  OnInit
} from '@angular/core';
import {ApiDigitalComponentVersionService, DigitalComponentCompleteDto} from '|api/component';
import {IczFormArray, IczFormGroup} from '@icz/angular-form-elements';
import {defaultDigitalFormConfig} from '../../essl-components-utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  DigitalComponentUploadDialogData,
  DigitalComponentUploadDialogResult,
  DigitalComponentUploadDialogType,
  UploadedDigitalComponent
} from '../../models/digital-components.model';
import {
  getDigitalComponentUploadForm
} from '../../../digital-components-upload-form/digital-components-upload-form.component';
import {injectModalData, injectModalRef} from '@icz/angular-modal';

@Component({
  selector: 'icz-digital-component-upload-dialog',
  templateUrl: './digital-component-upload-dialog.component.html',
  styleUrls: ['./digital-component-upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalComponentUploadDialogComponent implements OnInit, AfterViewInit {

  protected modalRef = injectModalRef<Nullable<DigitalComponentUploadDialogResult>>();
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private destroyRef = inject(DestroyRef);
  protected data = injectModalData<DigitalComponentUploadDialogData>();

  ngOnInit() {
    this.digitalComponent = this.data.data!;

    if (this.digitalComponent) {
      this.form = new IczFormGroup({
        digitalComponents: new IczFormArray(() => getDigitalComponentUploadForm(false), [])
      });
    }

    this.modalRef.close = result => {
      if (
        result === null || // Modal close button / overlay click
        (Array.isArray(result) && !result.length) // "Cancel" button
      ) {
        if (this.digitalComponents && !this.digitalComponent) {
          const body = this.digitalComponents.map(a => a.digitalComponentVersionId);
          this.apiDigitalComponentVersionService.digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds({
            body
          }).subscribe();
        }
      }

      this.modalRef.forceClose(result);
    };
  }

  selectFiles = new EventEmitter();
  uploadFiles = new EventEmitter();
  form: IczFormGroup = new IczFormGroup({});
  digitalComponent!: DigitalComponentCompleteDto;
  digitalComponentsConfig = defaultDigitalFormConfig;
  isUploading = false;
  private _primarySubmitDisabled = false;

  get primarySubmitDisabled(): boolean {
    return this._primarySubmitDisabled;
  }

  set primarySubmitDisabled(value: boolean) {
    this._primarySubmitDisabled = value;
  }

  get isCheckInDialog() {
    return this.data.type === DigitalComponentUploadDialogType.CHECK_IN;
  }

  get isAddDigitalRenditionDialog() {
    return this.data.type === DigitalComponentUploadDialogType.ADD_DIGITAL_RENDITION_TO_PAPER;
  }

  get digitalComponents(): UploadedDigitalComponent[] {
    return this.form.value.digitalComponents;
  }

  get digitalComponentsControl(): IczFormArray {
    return this.form.get('digitalComponents') as IczFormArray;
  }

  get isAtleastOneDigitalComponentUploaded() {
    return this.digitalComponents?.length > 0;
  }

  get uploadNextLabel() {
    return this.isAtleastOneDigitalComponentUploaded ? 'Vybrat další' : 'Vybrat';
  }

  get canUpload() {
    if (this.isAddDigitalRenditionDialog) {
      return !this.isAtleastOneDigitalComponentUploaded;
    }
    if (this.digitalComponent) {
      return !this.digitalComponents || !this.isAtleastOneDigitalComponentUploaded;
    }
    return true;
  }

  get disabled() {
    return this.isUploading || !this.digitalComponents || !this.isAtleastOneDigitalComponentUploaded || this.form.invalid;
  }

  fileSelected() {
    if (this.isCheckInDialog) {
      this.digitalComponentsControl.controls[0].get('isFinal')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((final: boolean) => {
        this.primarySubmitDisabled = final;
      });
    } else return;
  }

  ngAfterViewInit() {
    this.selectFiles.emit();

  }

  submit(options: {unlock: boolean}) {
    if (this.digitalComponent) {
      this.isUploading = true;
      this.uploadFiles.emit({unlock: options.unlock});
      return;
    }
    this.close(true);
  }

  submitAndUnlock() {
    this.submit({unlock: true});
  }

  close(isDone?: boolean) {
    this.modalRef.close({metadata: isDone ? this.digitalComponents : []});
  }

  fileUploaded(digitalComponentVersionId: Nullable<number>) {
    if (!this.digitalComponent) return;
    this.isUploading = false;
    if (digitalComponentVersionId) this.close(true);
  }

}
