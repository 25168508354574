import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {CodebookService} from '../../../core/services/codebook.service';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {getExternalRetentionTriggersOptions} from '../shared-document.utils';

@Component({
  selector: 'icz-ext-retention-trigger-selector',
  templateUrl: './ext-retention-trigger-selector.component.html',
  styleUrls: ['./ext-retention-trigger-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExtRetentionTriggerSelectorComponent implements OnInit, IczOnChanges {

  private codebookService = inject(CodebookService);

  @Input()
  form!: IczFormGroup;
  @Input()
  isMultiselect = false;
  @Input()
  fieldDisabled: Nullable<boolean>;
  @Input()
  availableExternalRetentionTriggerIds: number[] = [];

  allExternalRetentionTriggerOptions: IczOption<number>[] = [];
  externalRetentionTriggerOptions: IczOption<number>[] = [];

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.availableExternalRetentionTriggerIds.currentValue) {
      this.filterExternalRetentionTriggers();
    }
  }

  ngOnInit(): void {
    getExternalRetentionTriggersOptions(this.codebookService).subscribe(options => {
      this.allExternalRetentionTriggerOptions = options;
      this.filterExternalRetentionTriggers();
    });
  }

  filterExternalRetentionTriggers() {
    if (this.allExternalRetentionTriggerOptions.length) {
      if (this.availableExternalRetentionTriggerIds.length === 0) {
        this.externalRetentionTriggerOptions = [...this.allExternalRetentionTriggerOptions];
      } else {
        this.externalRetentionTriggerOptions = this.allExternalRetentionTriggerOptions.filter(op => this.availableExternalRetentionTriggerIds.includes(op.value!));
      }
    }
  }
}
