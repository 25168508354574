<div class="table-master-wrapper">
    <icz-table-toolbar
      [columnsData]="columnsData"
      [config]="config.toolbarConfig"
      [searchTerm]="searchTerm"
      [enabledFilters]="enabledFilters"
      [subToolbarOpened]="toolbarOpened"
      [listLoadingStates]="listLoadingStates"
      [tableIdForLocalStorage]="id"
      [allowSavingFiltersWithEmptyValues]="allowSavingFiltersWithEmptyValues"
      (searchTermChange)="searchTermChanged($event)"
      (columnSettingsClicked)="openContextMenu()"
      (editQueryRequested)="editQueryRequested.emit()"
      (reloadClicked)="dataSource!.reload(true)"
      [hidden]="hideTableToolbar"
    >
      <ng-content select="[beforeFilters]" beforeFilters></ng-content>
      <ng-content select="[tableTitle]" tableTitle></ng-content>
      <ng-content select="[tableTag]" tableTag></ng-content>
      <ng-content select="[tableTabs]" tableTabs></ng-content>
      <ng-content select="[tools]" tools></ng-content>
      <ng-content select="[beforeToolbar]" beforeToolbar></ng-content>
    </icz-table-toolbar>
  <!--  this div.context-menu-origin is just an anchor for context menu, as we can't have dynamic popover origin from header cell elements-->
  <div class="context-menu-origin">
    <div cdkOverlayOrigin #contextMenuOrigin="cdkOverlayOrigin"></div>
  </div>

  <ng-content select="[underToolbar]"></ng-content>

  @if (isNonBlockingLoading) {
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  }

  <div
    #tableContainerEl
    class="table-container" [waiting]="dataSource?.loading$ | async"
    [blockingOverlayOpts]="{spinnerCenteringTopOffset: 20}"
  >
    <table
      #tableEl
      mat-table
      matSort
      multiTemplateDataRows
      cdkDropList
      cdkDropListLockAxis="x"
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="dropListDropped($event)"
      [dataSource]="dataSource!"
      [class.show-select-all-th]="selectedRowsService.isAllSelectedOrDisabled()"
      [matSortActive]="currentSort?.active ?? ''"
      (matSortChange)="sortChanged($event)"
      [ngClass]="(tableWidthIsAutomatic$ | async) ? 'w-full': 'table-layout-fixed'"
      [style.width]="tableWidth"
      class="grow-1"
      >
      @for (column of this.columnsData!.columnDefinitions; track column.id) {
        <ng-container [matColumnDef]="column.id">
          <!-- data table header and body content templates -->
          <th
            mat-sort-header
            [disabled]="column.disableSort || dataSource?.disablePagination || column.id === TableReservedColumns.SELECTION"
            [ngClass]="{'pointer-events-none': isResizing}"
            *matHeaderCellDef
            mat-header-cell
            class="mat-header mat-header-with-mover cell-horizontal-padding"
            [id]="MAT_HEADER_ID + column.id"
            [class.column-template]="customTableCells[column.id]"
            [style.width]="column.fixedWidth ? (column.fixedWidth + 'px') : columnWidths[column.id]"
            mwlResizable
            [enableGhostResize]="false"
            (mouseover.silent)="onHeaderMouseover(column.id)"
            (mouseleave.silent)="onHeaderMouseleave()"
            (resizeStart)="onResizeStart($event, column.id)"
            (resizing)="onResizing($event, column.id)"
            (resizeEnd)="onResizeEnd($event, column.id)"
            >
            @if (column.id === TableReservedColumns.SELECTION) {
              <icz-checkbox
                [style.marginLeft.px]="isTreeTable ? 50 : 8"
                [checked]="selectedRowsService.isAllSelectedOrDisabled()"
                [indeterminate]="selectedRowsService.isIndeterminate()"
                (valueChange)="selectedRowsService.masterToggle()"
                [generousClickArea]="true"
              ></icz-checkbox>
            }
            @else {
              <icz-table-header-cell [column]="column" [columnIndex]="$index" [columnWidth]="columnWidths[column.id]"></icz-table-header-cell>
              @if (column?.fixedWidth) {
                <div class="resize-handle-right-not-allowed" mwlResizeHandle [resizeEdges]="{right: true}"></div>
              }
              @if (!column?.fixedWidth) {
                <div class="resize-handle-right" mwlResizeHandle [resizeEdges]="{right: true}"></div>
              }
            }
            @if (hoveredHeader === column.id) {
              <div class="column-end"></div>
            }
          </th>
          <td
            mat-cell *matCellDef="let row"
            [class.cell-horizontal-padding]="!isColumnReserved(column)"
            [class.ellipsis]="!isColumnReserved(column) && isEllipsisApplied(column)"
            >
            @if (column.id === TableReservedColumns.SELECTION) {
              <div class="row items-center ml--8" [style.paddingLeft.px]="getRowLevelIndentValue(row)">
                @if (isTreeTable) {
                  <icz-button
                    [class.invisible]="!isNodeExpanderVisible(row)"
                    [svgIcon]="isExpanded(row) ? 'expand_more' : 'after'"
                    (onAction)="toggleExpansionState($event, row)"
                    background="transparent" disableRipple
                  ></icz-button>
                }
                <icz-checkbox
                  (click)="onRowClick($event, row, true)"
                  [checked]="selectedRowsService.isSelected(row)"
                  [fieldDisabled]="isRowSelectionDisabled(row)"
                  (valueChange)="tableCheckboxClicked($event, row)"
                  [iczTooltip]="getCheckboxTooltip(row)"
                  [generousClickArea]="true"
                ></icz-checkbox>
              </div>
            }
            @else {
              @if (listLoadingStates[column.id]) {
                <icz-newspaper-loader></icz-newspaper-loader>
              }
              @else {
                <icz-table-data-cell
                  [customTableCells]="customTableCells" [columnId]="column.id"
                  [row]="row" [columnsData]="columnsData!"
                ></icz-table-data-cell>
              }
            }
          </td>
        </ng-container>
      }

      <!-- reserved 100% colspan'd cell for expanded cell inner content -->
      @if (expandedRowTemplate) {
        <ng-container matColumnDef="expanded-detail">
          <td mat-cell *matCellDef="let row" [attr.colspan]="columnsData!.columnDefinitions!.length">
            @if (row === expandedRow) {
              <div class="expandable" [@rowExpandAnimation]>
                <ng-container *ngTemplateOutlet="expandedRowTemplate; context: {$implicit: row}"></ng-container>
              </div>
            }
          </td>
        </ng-container>
      }

      <ng-container matColumnDef="expanded-header-search-select-all">
        <th mat-header-cell *matHeaderCellDef [attr.colspan]="columnsData!.columnDefinitions!.length">
          <div class="row grow global-select">
            @if (isGlobalSelected) {
              <div class="row">
                <span>{{ globalSelectionSelectedtext | translate | interpolate:{totalCount: selectedRowsService.dataSource.paginator!.length} }}</span>
                <a class="pl-4 icz-body-1 visible-clickable-area" iczLinkWithoutHref (click.stop)="clearSelection()">
                  <span>{{ '(zrušit výběr)' | translate }}</span>
                </a>
              </div>
            }
            @else {
              <div>
                {{ globalSelectionTextInfo | translate | interpolate:{pageCount: selectedRowsService.dataSource.data.length} }}
                <a class="p-4 icz-body-1 visible-clickable-area" iczLinkWithoutHref (click)="selectAllItemsByTotalCount()">
                  <span>{{ globalSelectionTextLink | translate | interpolate:{totalCount: selectedRowsService.dataSource.paginator!.length} }}</span></a>
                </div>
              }
            </div>
          </th>
        </ng-container>

        <!-- pseudorows used for laying out table row order -->
        <tr id="matHeaderId" mat-header-row *matHeaderRowDef="columnsData!.displayedColumns; sticky: true"></tr>
        <tr mat-header-row *matHeaderRowDef="['expanded-header-search-select-all']; sticky: true" [hidden]="!showGlobalSelection"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsData!.displayedColumns;"
          [class.active-row]="config.hasActiveRow && selectedRowsService.isActive(row)"
          [class.active-checkbox-row]="selectedRowsService.isSelected(row)"
          [class.focused-row]="isRowFocused(row)"
          [class.disabled-row]="isRowDisabledByDisabler(row)"
          [class.hoverable-row]="config.hoverableRows"
          [style.height.px]="config.rowHeight"
          (click)="onRowClick($event, row, false)"
        ></tr>
        @if (expandedRowTemplate) {
          <tr
            mat-row
            *matRowDef="let row; columns: ['expanded-detail']"
            [class]="'expandable-row ' + (row === expandedRow ? 'expanded' : 'collapsed')"
          ></tr>
        }

      </table>

      @if ((dataSource?.loading$ | async) === false) {
        <div>
          @if (dataSource?.error$ | async; as error) {
            <div class="data-loading-error">
              @if (error.error?.errorCode === 'be.error.elastic.resultWindowIsTooLarge') {
                <icz-icon svgIcon="modal_info"></icz-icon>
                <icz-label class="mt-16 font-bold" label="Dosáhli jste konce maximální zobrazené množiny záznamů"></icz-label>
                <icz-label class="mt-16" label="Pro zobrazení dalších záznamů je potřeba využít možnosti pokročilého filtrování složky."></icz-label>
              }
              @else {
                <icz-icon svgIcon="modal_error"></icz-icon>
                <icz-label class="mt-16 font-bold" label="Došlo k chybě"></icz-label>
                <icz-label class="mt-16" label="Zkuste změnit parametry filtrování/řazení, nebo tabulku načtěte znovu."></icz-label>
              }
            </div>
          }
          @else {
            @if (hasNoData$ | async) {
              <div class="no-items-text" data-cy="no-documents-found">
                <ng-content select="[noDataLabel]"></ng-content>
              </div>
            }
            @if (emptyDataSubsetFound$ | async) {
              <div class="no-items-text">
                <ng-content select="[notFoundLabel]"></ng-content>
              </div>
            }
          }
        </div>
      }
    </div>

    @if (isResizing) {
      <div class="resize-friendly-line"
        [style.height.px]="resizeLineBounds!.height"
        [style.top.px]="resizeLineBounds!.y"
        [style.left.px]="resizeLineBounds!.x"
      ></div>
    }

    <icz-table-paginator
      [hidden]="dataSource?.disablePagination"
      [selectedRowsCount]="selectedRowsService.selection?.selected?.length ?? 0"
      [pageRowCount]="tablePageRowCount"
      (pageChanged)="pageNumberChanged($event)"
      (rowCountChanged)="rowCountSettingsChanged($event)"
    ></icz-table-paginator>
  </div>

  <icz-popover
    [isOpen]="(showContextMenu$ | async)!"
    [overlayOrigin]="contextMenuOrigin"
    (onClose)="closeContextMenu()"
    >
    @if ((showContextMenu$ | async)!) {
      <icz-table-context-menu
        [heightLimit]="maxContextMenuHeight"
        [columnDefinitions]="columnsData!.columnDefinitions"
        (columnToggled)="toggleColumn($event.id)"
        (defaultClicked)="resetUserAdjustmentsToDefault()"
        (onClose)="closeContextMenu()"
      ></icz-table-context-menu>
    }
  </icz-popover>
