export enum ClassificationSchemesRoute {
  CLASSIFICATION_SCHEMES = 'classification-schemes',
  ENTITY_CLASS = 'entity-class',
}

export enum EntityClassTypeRoute {
  DOCUMENT_FILE_INNER_NODE = 'document-file-inner-node',
  DOCUMENT_FILE = 'document-file',
  CASE_FILE = 'case-file',
  CASE_FILE_PART = 'case-file-part',
  CASE_FILE_LEAF = 'case-file-leaf',
}
