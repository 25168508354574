<div class="items-center row logo-bar">
  @if (isTrainingEnv || isTestingEnv) {
    <icz-icon svgIcon="logo_small_bglight" class="original-size"></icz-icon>
  }
  @if (isProdMain) {
    <icz-icon svgIcon="logo_small_bgdark" class="original-size"></icz-icon>
  }
  @if (isProdAdmin) {
    <span class="app-description">
      <icz-icon svgIcon="settings" size="default" class="w-24 h-24" withCustomIconColor></icz-icon>
      {{ 'Nastavení aplikace' | translate }}
    </span>
  }
  @if (isProdConfig) {
    <icz-icon svgIcon="logo_small_bgpurple" class="original-size"></icz-icon>
  }
  @if (isProdConfig) {
    <span class="app-description">
      Konfigurace
    </span>
  }
  <div class="grow"></div>
  <icz-button svgIcon="arrow_big" [square]="true"
    (onAction)="closeClicked.emit()" background="transparent"
    size="large" [primary]="isProdMain || isProdConfig"
  ></icz-button>
</div>

@if (isNotificationDotVisible$ | async) {
  <span class="notification-dot"></span>
}

<icz-tabs
  class="menu-tabs"
  [tabs]="sideMenuTabs"
  size="small"
  (tabClick)="activeTabChanged($event)"
  [shouldNavigate]="false"
></icz-tabs>

<div class="scroll-vertically relative grow">
  @if (activeMenuTab === SideMenuTab.MENU) {
    <div
      class="menu-tab-content" [@tabAnimation]="tabAnimationState"
      >
      <ng-content select="[sideMenuItems]"></ng-content>
    </div>
  }
  @if (activeMenuTab === SideMenuTab.FAVORITES) {
    <div
      class="menu-tab-content" [@tabAnimation]="tabAnimationState"
      >
      <ng-container *ngTemplateOutlet="favorite"></ng-container>
    </div>
  }
</div>

@if (isTestingEnv) {
  <div class="footer">
    <icz-icon svgIcon="info" withCustomIconColor></icz-icon>Testovací prostředí
  </div>
}
@if (isTrainingEnv) {
  <div class="footer">
    <icz-icon svgIcon="info" withCustomIconColor></icz-icon>Školící prostředí
  </div>
}

<ng-template #favorite>
  @if (favouriteMenu$ | async; as favouriteMenu) {
    @if (!favouriteMenu.length) {
      <div class="text-center icz-body-1 mt-48 px-16">
        <p class="mb-16">{{ 'Zatím nemáte žádné oblíbené sekce' | translate }}</p>
        <p>{{ 'Označte si sekci pomocí hvězdičky, aby se vám zde zobrazila.' | translate }}</p>
      </div>
    }
    @if (favouriteMenu.length) {
      <icz-side-menu-items
        [menuData]="favouriteMenu"
      ></icz-side-menu-items>
    }
  }
</ng-template>
