<icz-table-preview [opened]="opened" (closed)="onPreviewClose()">
  <ng-container previewHeader>
    <span>{{ 'Detail operace' | translate }} - {{ eventTypeLocalizationString | translate }}</span>
  </ng-container>
  <div class="gap-16 p-16 col">
    @if (attributes?.length) {
      @for (attr of attributes; track attr.id) {
        <div>
          <icz-label [label]="getAttributeLabel(attr)" [dontTranslate]="attr.disableAttributeNameTranslation!" justifyContent="left"></icz-label>
          <div class="gap-4 row">
            <div
              class="field grow"
              [iczTooltip]="formatAttributeValue(attr, TransactionEventAttributeValueKey.ORIGINAL_VALUE) | async"
              iczTooltipClass="breakable-attribute"
              >
              {{ formatAttributeValue(attr, TransactionEventAttributeValueKey.ORIGINAL_VALUE) | async }}
            </div>
            <icz-icon svgIcon="after"></icz-icon>
            <div
              class="field new-value grow"
              [iczTooltip]="formatAttributeValue(attr, TransactionEventAttributeValueKey.CURRENT_VALUE) | async"
              iczTooltipClass="breakable-attribute"
              >
              {{ formatAttributeValue(attr, TransactionEventAttributeValueKey.CURRENT_VALUE) | async }}
            </div>
          </div>
        </div>
      }
    }
    @else {
      <icz-label label="Žádné atributy nebyly změněny"></icz-label>
    }
  </div>
</icz-table-preview>
