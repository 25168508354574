/* eslint-disable @typescript-eslint/quotes */

/**
 * Icons aggregated from libs/icz-angular-table/assets/icons/.
 *
 * Used as a method of resource inlining to decrease HTTP GET overhead
 * for fetching lots of small icons at application startup.
 */
export const ICONS = {
  "chevron_left": null,
  "chevron_right": null,
  "filter": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"15\" fill=\"none\" viewBox=\"0 0 16 15\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M9 13.09V8.75L16 0H0l7 8.75V15l2-1.91zM8 6.799L4.161 2h7.678L8 6.798z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "first_page": null,
  "reference_list": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\n    <path fill=\"#6C6F75\" d=\"M10 2H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6h-2v6H4V4h6V2z\"/>\n    <path fill=\"#6C6F75\" d=\"M15.707 5.707L18 8V2h-6l2.293 2.293-5.536 5.535 1.415 1.415 5.535-5.536z\"/>\n</svg>\n",
  "refresh": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"14\" fill=\"none\" viewBox=\"0 0 20 14\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M10 0a7 7 0 0 0-7 7H0l4 4 4-4H5a5 5 0 0 1 7.96-4.03l1.425-1.426A6.97 6.97 0 0 0 10 0zm0 14a7 7 0 0 0 7-7h3l-4-4-4 4h3a5 5 0 0 1-7.96 4.03l-1.425 1.426A6.97 6.97 0 0 0 10 14z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "save_filter": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\n<svg\n   width=\"18\"\n   height=\"15\"\n   viewBox=\"0 0 18 15\"\n   fill=\"none\"\n   version=\"1.1\"\n   id=\"svg13\"\n   xmlns=\"http://www.w3.org/2000/svg\"\n   xmlns:svg=\"http://www.w3.org/2000/svg\">\n  <defs\n     id=\"defs17\" />\n  <path\n     fill-rule=\"evenodd\"\n     clip-rule=\"evenodd\"\n     d=\"m 9.3339367,10.686352 c -0.3296631,0.337464 -0.3296631,0.884593 0,1.222057 l 2.9846563,3.055059 5.372368,-5.4991059 c 0.329689,-0.3374566 0.329689,-0.8845757 0,-1.2220321 -0.329603,-0.3374479 -0.864123,-0.3374479 -1.193812,0 l -4.178556,4.277109 -1.790759,-1.833087 c -0.329689,-0.337423 -0.8642087,-0.337423 -1.1938973,0 z\"\n     fill=\"#4caf50\"\n     id=\"path9\"\n     style=\"stroke-width:0.861675\" />\n  <path\n     fill-rule=\"evenodd\"\n     clip-rule=\"evenodd\"\n     d=\"M 9,13.0909 V 8.75 L 16,0 H 0 L 7,8.75 V 15 Z M 8,6.79844 4.16125,2 h 7.67755 z\"\n     fill=\"#665d76\"\n     id=\"path11\" />\n</svg>\n",
  "saved_filter": "<?xml version=\"1.0\" encoding=\"UTF-8\" standalone=\"no\"?>\n<svg\n   width=\"18\"\n   height=\"15\"\n   fill=\"none\"\n   viewBox=\"0 0 18 15\"\n   version=\"1.1\"\n   id=\"svg101\"\n   xmlns=\"http://www.w3.org/2000/svg\"\n   xmlns:svg=\"http://www.w3.org/2000/svg\">\n  <defs\n     id=\"defs105\" />\n  <path\n     fill=\"#6c6f75\"\n     fill-rule=\"evenodd\"\n     d=\"m 10,8.75 v 4.34 L 8,15 V 8.75 L 1,0 h 16 z\"\n     clip-rule=\"evenodd\"\n     id=\"path99\" />\n</svg>\n",
  "search": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17\" height=\"17\" fill=\"none\" viewBox=\"0 0 17 17\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M1.845 10.754a6.301 6.301 0 0 0 8.105.68l5.258 5.258a1.05 1.05 0 0 0 1.484-1.484L11.434 9.95a6.301 6.301 0 0 0-9.589-8.105 6.3 6.3 0 0 0 0 8.909zM9.269 3.33A4.2 4.2 0 1 1 3.33 9.269a4.2 4.2 0 0 1 5.939-5.94z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "table_settings": null
};
