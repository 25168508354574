import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
  FunctionalPositionGroup,
  FunctionalPositionGroupId,
  SavedFunctionalPositionsService
} from '../saved-functional-positions.service';
import {IczFormControl, IczFormGroup, IczOption, PrimitiveControlValueType} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {OrganizationalStructureService} from '../../../core/services/organizational-structure.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DialogService} from '@icz/angular-modal';

@Component({
  selector: 'icz-org-structure-selection-loader',
  templateUrl: './org-structure-selection-loader.component.html',
  styleUrls: ['./org-structure-selection-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgStructureSelectionLoaderComponent implements OnInit {

  loadingService = inject(LoadingIndicatorService);
  private translateService = inject(TranslateService);
  private dialogService = inject(DialogService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private savedFunctionalPositionsService = inject(SavedFunctionalPositionsService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  parentForm!: IczFormGroup;
  @Input()
  label = 'Předat předdefinované skupině funkčních míst / org. jednotek';
  @Input()
  constrainToFunctionalPositions = false;
  @Output()
  groupSelected = new EventEmitter<Nullable<FunctionalPositionGroup>>();

  form = new IczFormGroup({
    functionalPositionGroup: new IczFormControl<Nullable<string>>(null),
  });

  savedFunctionalPositions: IczOption[] = [];

  get hasSomeSavedFunctionalPositions(): boolean {
    return Object.keys(this.savedFunctionalPositionsService.savedFunctionalPositions ?? {}).length > 0;
  }

  private readonly incompatibleGroupQuestionHeader = this.translateService.instant(
    'Následující organizační jednotky budou po výběru této skupiny ignorovány'
  );

  ngOnInit() {
    this.retrieveSavedGroups();

    this.form.get('functionalPositionGroup')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(groupKey => {
      this.savedGroupSelected(groupKey);
    });
  }

  savedGroupSelected(groupKey: PrimitiveControlValueType) {
    if (groupKey) {
      const group = this.savedFunctionalPositionsService.savedFunctionalPositions[groupKey as FunctionalPositionGroupId];
      const orgUnitsInGroup = group.items.filter(groupItem => Number.isInteger(groupItem));

      if (orgUnitsInGroup.length && this.constrainToFunctionalPositions) {
        this.loadingService.doLoading(
          this.organizationalStructureService.organizationalUnitsOptions(),
          this
        ).subscribe(organizationalUnitsOptions => {
          const incompatibleOrgUnitNames = organizationalUnitsOptions
            .filter(o => orgUnitsInGroup.includes(o.value))
            .map(o => o.label);

          this.dialogService.openQuestionDialogWithAnswer(
            {
              title: 'Nekompatibilní skupina',
              question: 'Tento oběh podporuje pouze předání na funkční místa. Opravdu chcete použít tuto skupinu?',
              description: `${this.incompatibleGroupQuestionHeader}: ${incompatibleOrgUnitNames.join(', ')}.`,
              leftButtonTitle: 'Použít skupinu',
            }
          ).subscribe(result => {
            if (result) {
              this.groupSelected.emit({
                ...group,
                items: group.items.filter(groupItem => !orgUnitsInGroup.includes(groupItem)),
              });
            }
            else {
              this.form.reset();
            }
          });
        });
      }
      else {
        this.groupSelected.emit(group);
      }
    }
  }

  deleteSavedGroup($event: Event, option: IczOption) {
    $event.stopPropagation();

    this.dialogService.openQuestionDialog(
      {
        title: 'Smazat uloženou skupinu',
        question: `${this.translateService.instant('Opravdu chcete smazat skupinu funkčních míst a org. jednotek')} "${option.label}"?`,
        description: 'Tuto operaci nelze vrátit.',
      }
    ).subscribe(_ => {
      this.savedFunctionalPositionsService.removeFunctionalPositionGroup(option.value as string);
      this.retrieveSavedGroups();
    });
  }

  private retrieveSavedGroups() {
    const store = this.savedFunctionalPositionsService.savedFunctionalPositions;

    this.savedFunctionalPositions = Object.keys(store).map(k => ({
      label: k,
      value: k,
    }));
  }

}
