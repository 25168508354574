/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageRegistryOfficeDto } from '../models/page-registry-office-dto';
import { registryOfficeCreate } from '../fn/registry-office/registry-office-create';
import { RegistryOfficeCreate$Params } from '../fn/registry-office/registry-office-create';
import { registryOfficeDelete } from '../fn/registry-office/registry-office-delete';
import { RegistryOfficeDelete$Params } from '../fn/registry-office/registry-office-delete';
import { RegistryOfficeDto } from '../models/registry-office-dto';
import { registryOfficeFindAll } from '../fn/registry-office/registry-office-find-all';
import { RegistryOfficeFindAll$Params } from '../fn/registry-office/registry-office-find-all';
import { registryOfficeFindById } from '../fn/registry-office/registry-office-find-by-id';
import { RegistryOfficeFindById$Params } from '../fn/registry-office/registry-office-find-by-id';
import { registryOfficeFindRegistryOfficesForFunctionalPosition } from '../fn/registry-office/registry-office-find-registry-offices-for-functional-position';
import { RegistryOfficeFindRegistryOfficesForFunctionalPosition$Params } from '../fn/registry-office/registry-office-find-registry-offices-for-functional-position';
import { registryOfficeSearch } from '../fn/registry-office/registry-office-search';
import { RegistryOfficeSearch$Params } from '../fn/registry-office/registry-office-search';
import { registryOfficeUpdate } from '../fn/registry-office/registry-office-update';
import { RegistryOfficeUpdate$Params } from '../fn/registry-office/registry-office-update';


/**
 * Controller for registry office objects
 */
@Injectable({ providedIn: 'root' })
export class ApiRegistryOfficeService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `registryOfficeFindById()` */
  static readonly RegistryOfficeFindByIdPath = '/codebook/registry-office/{id}';

  /**
   * Find registry office object by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeFindById$Response(params: RegistryOfficeFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistryOfficeDto>> {
    return registryOfficeFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Find registry office object by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeFindById(params: RegistryOfficeFindById$Params, context?: HttpContext): Observable<RegistryOfficeDto> {
    return this.registryOfficeFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistryOfficeDto>): RegistryOfficeDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeUpdate()` */
  static readonly RegistryOfficeUpdatePath = '/codebook/registry-office/{id}';

  /**
   * Update existing registry office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeUpdate$Response(params: RegistryOfficeUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistryOfficeDto>> {
    return registryOfficeUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update existing registry office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeUpdate(params: RegistryOfficeUpdate$Params, context?: HttpContext): Observable<RegistryOfficeDto> {
    return this.registryOfficeUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistryOfficeDto>): RegistryOfficeDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeDelete()` */
  static readonly RegistryOfficeDeletePath = '/codebook/registry-office/{id}';

  /**
   * Delete existing registry office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeDelete$Response(params: RegistryOfficeDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return registryOfficeDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete existing registry office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeDelete(params: RegistryOfficeDelete$Params, context?: HttpContext): Observable<void> {
    return this.registryOfficeDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `registryOfficeFindAll()` */
  static readonly RegistryOfficeFindAllPath = '/codebook/registry-office';

  /**
   * Finds all registry office objects
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeFindAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeFindAll$Response(params?: RegistryOfficeFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistryOfficeDto>>> {
    return registryOfficeFindAll(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds all registry office objects
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeFindAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeFindAll(params?: RegistryOfficeFindAll$Params|null|undefined, context?: HttpContext): Observable<Array<RegistryOfficeDto>> {
    return this.registryOfficeFindAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistryOfficeDto>>): Array<RegistryOfficeDto> => r.body)
    );
  }

  /** Path part for operation `registryOfficeCreate()` */
  static readonly RegistryOfficeCreatePath = '/codebook/registry-office';

  /**
   * Create new registry office object
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeCreate$Response(params: RegistryOfficeCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<RegistryOfficeDto>> {
    return registryOfficeCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new registry office object
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  registryOfficeCreate(params: RegistryOfficeCreate$Params, context?: HttpContext): Observable<RegistryOfficeDto> {
    return this.registryOfficeCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<RegistryOfficeDto>): RegistryOfficeDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeSearch()` */
  static readonly RegistryOfficeSearchPath = '/codebook/registry-office/search-api';

  /**
   * Finds registry office objects based on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeSearch()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeSearch$Response(params?: RegistryOfficeSearch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageRegistryOfficeDto>> {
    return registryOfficeSearch(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds registry office objects based on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeSearch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeSearch(params?: RegistryOfficeSearch$Params|null|undefined, context?: HttpContext): Observable<PageRegistryOfficeDto> {
    return this.registryOfficeSearch$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageRegistryOfficeDto>): PageRegistryOfficeDto => r.body)
    );
  }

  /** Path part for operation `registryOfficeFindRegistryOfficesForFunctionalPosition()` */
  static readonly RegistryOfficeFindRegistryOfficesForFunctionalPositionPath = '/codebook/registry-office/for/{registryOfficeMemberPermissionType}';

  /**
   * Finds registry offices available for logged functional position.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `registryOfficeFindRegistryOfficesForFunctionalPosition()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeFindRegistryOfficesForFunctionalPosition$Response(params: RegistryOfficeFindRegistryOfficesForFunctionalPosition$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<RegistryOfficeDto>>> {
    return registryOfficeFindRegistryOfficesForFunctionalPosition(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds registry offices available for logged functional position.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `registryOfficeFindRegistryOfficesForFunctionalPosition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  registryOfficeFindRegistryOfficesForFunctionalPosition(params: RegistryOfficeFindRegistryOfficesForFunctionalPosition$Params, context?: HttpContext): Observable<Array<RegistryOfficeDto>> {
    return this.registryOfficeFindRegistryOfficesForFunctionalPosition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<RegistryOfficeDto>>): Array<RegistryOfficeDto> => r.body)
    );
  }

}
