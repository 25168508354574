import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {NavigationEnd, Params, Router} from '@angular/router';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {distinctUntilChanged, filter, map, tap} from 'rxjs/operators';
import {ResponsivityService} from '../responsivity.service';
import {TabDirective} from './tab.directive';
import {TabItem, TabItemWithPriority, TabPriority, TabsSize} from './tabs.component.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {NgScrollbar} from 'ngx-scrollbar';
import {AsyncPipe, NgTemplateOutlet} from '@angular/common';
import {TabItemComponent} from './tab-item/tab-item.component';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {LabelComponent} from '../label/label.component';
import {PopoverComponent} from '../popover/popover.component';
import {USER_INTERACTION_LOGGER} from '../essentials.providers';

type _TabItem<I = string | number> = TabItem<I> | TabItemWithPriority<I>;

/**
 * @internal
 */
const TABLET_TAB_PRIORITIES: TabPriority[] = [TabPriority.HIGHEST];

/**
 * @internal
 */
const SMALL_DESKTOP_TAB_PRIORITIES: TabPriority[] = [TabPriority.HIGHEST, TabPriority.HIGH];

/**
 * @internal
 */
const MID_DESKTOP_TAB_PRIORITIES: TabPriority[] = [TabPriority.HIGHEST, TabPriority.HIGH, TabPriority.MEDIUM];

/**
 * @internal
 */
const LARGE_DESKTOP_TAB_PRIORITIES: TabPriority[] = [TabPriority.HIGHEST, TabPriority.HIGH, TabPriority.MEDIUM, TabPriority.LOW];

/**
 * A tabset which binds together all available tabs and their content templates.
 * The available tabs should be passed to the component using @Input tabs while
 *   tab contents should be passed by ng-content ContentChildren using the TabDirective.
 * The tabs are rendered left-to-right but they might get excluded from rendering
 *   based on various conditions which are defined in TabItemWithPriority and TabItem.isHidden.
 * The tabset might become scrollable using a horizonal scrollbar if the tabs do not
 *   fit into their designated space demarked by icz-tabs element.
 *
 * @see TabItem
 * @see TabDirective
 * @see TabItemWithPriority
 */
@Component({
  selector: 'icz-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgScrollbar, AsyncPipe, TabItemComponent, CdkOverlayOrigin, LabelComponent, PopoverComponent, NgTemplateOutlet],
})
export class TabsComponent<T extends string | number = string | number> implements AfterContentInit {

  private router = inject(Router);
  private responsivityService = inject(ResponsivityService);
  private destroyRef = inject(DestroyRef);
  private el = inject(ElementRef);
  private logger = inject(USER_INTERACTION_LOGGER, {optional: true});

  @ViewChild(NgScrollbar)
  protected customScrollbar!: NgScrollbar;
  @ContentChildren(TabDirective)
  protected staticTabs!: QueryList<TabDirective>;

  /**
   * If TRUE and the tabset does not have tab content using TabDirective defined,
   *   the tabset will be used for navigation between (child) routes by navigating to
   *   baseUrl + (selected tab).id.
   */
  @Input()
  shouldNavigate = true;
  /**
   * Base URL for tab routing.
   * @see TabsComponent.shouldNavigate
   */
  @Input()
  baseUrl: Nullable<string> = '';
  /**
   * If defined, will append the supplied query parameters to URL while routing in shouldNavigate mode.
   */
  @Input()
  queryParams: Nullable<Params>;
  /**
   * A TabItem which represents an active tab. Changing this property will trigger tab activation.
   *   Be careful, tab must be present in tabs, in terms of object reference equality.
   *   Passing object copies to this input will not be effective.
   * @deprecated - refactor to activeTabId.
   */
  @Input()
  set activeTab(tab: Nullable<_TabItem<T>>) {
    this._activeTab$.next(tab);
  }
  get activeTab() {
    return this._activeTab$.value;
  }
  /**
   * Size of the tabs in the tabset.
   * @see TabsSize
   */
  @Input()
  size: TabsSize = 'default';
  /**
   * Hides the tabset while making visible only tab contents.
   * Tab switching can still be done programmatically.
   */
  @Input()
  hideTabset = false;
  /**
   * Makes the tabset sticky, i.e. applies position:sticky.
   */
  @Input()
  @HostBinding('class.sticky-tabs')
  withStickyTabs = false;
  /**
   * Emits activated tab when the user clicks it.
   */
  @Output()
  tabClick = new EventEmitter<_TabItem<T>>();
  /**
   * Tabset definition. Defined available tabs and their additional metadata and behavior.
   * Each tab of the tabset must have a unique ID.
   */
  @Input({required: true})
  set tabs(tabs: _TabItem<T>[]) {
    if (this.tabs !== tabs) {
      this._tabs$.next(tabs);
    }
    const currentTabs = this.tabs;

    if (currentTabs?.length) {
      const segments = this.router.url.split('?')[0].split('/');
      const location = segments[segments.length - 1];

      const tabCorrespondsToRouterSegment = currentTabs.map(t => t.id).some(u => u === location);

      if (!this.activeTab) {
        if (tabCorrespondsToRouterSegment) {
          this.activeTab = currentTabs.find(t => t.id === location)!;
        } else {
          this.activeTab = currentTabs.find(t => t.id === this.activeTab?.id) ?? currentTabs[0];
        }
      }
    }
  }
  get tabs() {
    return this._tabs$.value;
  }

  private get usePriorityFilter() {
    return this.tabs?.every(t => (t as TabItemWithPriority).priority);
  }

  private customScrollbarLogicInitialized = false;

  private _activeTab$ = new BehaviorSubject<Nullable<_TabItem<T>>>(null);

  protected activeTab$ = this._activeTab$.asObservable();

  private _moreTabsOpened$ = new BehaviorSubject(false);

  protected moreTabsOpened$ = this._moreTabsOpened$.asObservable();

  private _tabs$ = new BehaviorSubject<_TabItem<T>[]>([]);
  private nonHiddenTabs$ = this._tabs$.pipe(
    filter(Boolean),
    map(tabs => tabs.filter(t => !t.isHidden)),
  );

  private visibleTabPriorities: Observable<TabPriority[]> = combineLatest([
    this.responsivityService.isTablet$,
    this.responsivityService.isSmallDesktop$,
    this.responsivityService.isMidDesktop$,
  ]).pipe(
    map(([isTablet, isSmallDesktop, isMidDesktop]) => {
      if (isTablet) {
        return TABLET_TAB_PRIORITIES;
      } else if (isSmallDesktop) {
        return SMALL_DESKTOP_TAB_PRIORITIES;
      } else if (isMidDesktop) {
        return MID_DESKTOP_TAB_PRIORITIES;
      } else {
        return LARGE_DESKTOP_TAB_PRIORITIES;
      }
    }),
    distinctUntilChanged(),
    tap(_ => {
      this.closeMoreTabs();
    })
  );

  protected currentlyVisibleTabs$ = combineLatest([
    this.nonHiddenTabs$,
    this.visibleTabPriorities,
    this.activeTab$,
  ]).pipe(
    map(([tabs, activePriorities, _]) => {
      if (this.usePriorityFilter) {
        return tabs.filter(t => activePriorities.includes((t as TabItemWithPriority).priority!));
      }
      else {
        return tabs;
      }
    }),
    filter(Boolean),
    map(tabs => {
      if (!tabs?.find(t => t.id === this.activeTab?.id) && this.usePriorityFilter) {
        const tabsWithoutLastItem = tabs.slice(0, tabs.length - 1);
        return [...tabsWithoutLastItem, this.activeTab!];
      } else {
        return tabs;
      }
    })
  );

  protected moreTab: _TabItem = {label: 'Více', id: 'more', icon: 'expand_more'};

  protected hiddenTabsInMoreSelect$ = this.currentlyVisibleTabs$.pipe(
    map(tabs => {
      return this.tabs?.filter(t1 => !tabs?.find(t2 => t1.id === t2?.id)).filter(t => !t.isHidden).reverse() ?? [];
    })
  );

  @HostBinding('class.small')
  protected get isSmall(): boolean {
    return this.size === 'small';
  }

  protected get hasStaticTabs() {
    return this.staticTabs.length > 0;
  }

  protected get activeTabTemplate() {
    return this.hasStaticTabs && this.activeTab ? this.staticTabs.toArray().find(tab => tab.id === this.activeTab?.id)?.content : null;
  }

  private get hasRoutableTabs() {
    // this.hasStaticTabs is known after content init
    return !this.hasStaticTabs && this.shouldNavigate;
  }

  protected openMoreTabs() {
    this._moreTabsOpened$.next(true);
  }

  protected closeMoreTabs() {
    this._moreTabsOpened$.next(false);
  }

  /**
   * @internal
   */
  ngAfterContentInit() {
    if (this.hasRoutableTabs) {
      this.router.events.pipe(
        takeUntilDestroyed(this.destroyRef),
        filter(e => e instanceof NavigationEnd),
        map(_ => this.router.url),
      ).subscribe((targetUrl: string) => {
        if (this.tabs) {
          const targetTabId = targetUrl.split('?')[0].split('/').at(-1); // gets last routing segment

          const routingTargetTab = this.tabs.find(t => t.id === targetTabId);

          if (routingTargetTab) {
            this.activeTab = routingTargetTab;
          }
        }
      });
    }
  }

  /**
   * Will go through tabs, pick the first tab with valid === false,
   *  activate it and scroll to it if the tabset is scrollable.
   */
  selectAndScrollToFirstInvalid() {
    const firstInvalid = this.tabs.find(t => t.showTabValidity === true && t.valid === false);
    if (firstInvalid) {
      const firstInvalidTabItem: HTMLElement = this.el.nativeElement.querySelector(`[data-icztabid="${firstInvalid.id}"]`);
      if (!firstInvalidTabItem) return;
      const leftScroll = firstInvalidTabItem.offsetLeft;

      this.customScrollbar.scrollTo({
        left: leftScroll,
        duration: 500,
      });

      this.activeTab = firstInvalid;
    }
  }

  /**
   * Will programmatically activete a given tab.
   * @param closeMoreTabs will close the "More tabs" dropdown used in responsive mode with TabItemWithProprity.
   */
  navigate(tab: _TabItem<T>, closeMoreTabs = false) {
    if (closeMoreTabs) {
      this.closeMoreTabs();
    }
    if (!tab.disabled) {
      if (this.hasRoutableTabs) {
        const route = `${this.baseUrl}/${tab.id}`;
        if (this.queryParams) {
          this.router.navigate([route], {queryParams: this.queryParams});
        } else {
          this.router.navigate([route]);
        }
      }
      else {
        this.activeTab = tab;
      }
      this.logger?.logUserInteraction({description: `Použita karta '${tab.label}'`});
      this.tabClick.emit(tab);
    }
  }

  protected customScrollbarUpdated() {
    if (!this.customScrollbarLogicInitialized) {
      this.customScrollbarLogicInitialized = true;

      // will cause that mousewheel scrolling will scroll the tabset horizonzally as the user would expect
      setTimeout(() => {
        this.customScrollbar.nativeElement.addEventListener('wheel', evt => {
          evt.preventDefault();

          // In case of mac touchpads which can scroll also horizontally select the "intended" scroll direction
          const finalDelta = Math.abs(evt.deltaX) > Math.abs(evt.deltaY) ? evt.deltaX : evt.deltaY;

          this.customScrollbar.scrollTo({
            left: this.customScrollbar.viewport.nativeElement.scrollLeft + finalDelta,
            duration: 0,
          });
        });
      }, 0);
    }
  }

}
