import {Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Observable} from 'rxjs';
import {BusinessRuleName, DigitalComponentOrigin, EsslComponentRelation} from '|api/commons';
import {
  ApiDigitalComponentService,
  ApiDigitalComponentVersionService,
  DigitalComponentCompleteDto,
  DigitalComponentVersionCompleteDto,
  DigitalComponentVersionDto,
} from '|api/component';
import {IczFormArray, IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {ComponentUploadStatus, CreateDigitalFormConfig} from '../essl-components/essl-components-utils';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {DialogService} from '@icz/angular-modal';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DigitalComponentUploadDialogType} from '../essl-components/models/digital-components.model';
import {CodebookService} from '../../../core/services/codebook.service';
import {SharedBusinessValidators} from '../shared-business-validators';
import {AbstractDragAndDropComponent} from '../file-upload-list/abstract-drag-and-drop.component';
import {AllowedFormatsDto} from '|api/codebook';

export function getDigitalComponentUploadForm(addGroupValidator = true) {
  return new IczFormGroup({
    label: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    relationType: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    originType: new IczFormControl<Nullable<string>>(null),
    description: new IczFormControl<Nullable<string>>(null, []),
    digitalComponentTemplateId: new IczFormControl<Nullable<number>>(null, []),
    isFinal: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
    digitalComponentVersionId: new IczFormControl<Nullable<string>>(null, []),
    // not a part of the DTO, important for FE logic:
    file: new IczFormControl<Nullable<string>>({value: null, disabled: true}, []),
    status: new IczFormControl<Nullable<ComponentUploadStatus>>(ComponentUploadStatus.IDLE, []),
    isValidOutputFormat: new IczFormControl<Nullable<boolean>>(true),
  }, addGroupValidator ? [SharedBusinessValidators.isDigitalComponentUploadFormValid()] : []);
}

export function getFileFormArray(validators?: IczValidators[]) {
  return new IczFormArray<IczFormGroup<{ file: IczFormControl<Nullable<File>> }>>(
    () => new IczFormGroup({file: new IczFormControl<Nullable<File>>()}),
    [], // initial length = 0
    validators ?? []
  );
}

type DigitalComponentUploadForm = ReturnType<typeof getDigitalComponentUploadForm>;

interface CompleteVersionWithLoading {
  id: number,
  completeVersion: Nullable<DigitalComponentVersionCompleteDto>,
  isLoading: boolean,
}

@Component({
  selector: 'icz-digital-components-upload-form',
  templateUrl: './digital-components-upload-form.component.html',
  styleUrls: ['./digital-components-upload-form.component.scss'],
})
export class DigitalComponentsUploadFormComponent extends AbstractDragAndDropComponent implements OnInit, IczOnChanges {

  private dialogService = inject(DialogService);
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private codebookService = inject(CodebookService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);

  @ViewChild('fileUpload', {static: false})
  fileUpload!: ElementRef;

  @Output() fileUploaded = new EventEmitter<Nullable<number>>();

  @Output() outDigitalComponentCount = new EventEmitter<IczFormArray>();

  @Input({required: true}) set selectFiles(emitter: EventEmitter<any>) {
    emitter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => this.openSystemDialog());
  }

  @Input() set uploadFiles(emitter: Nullable<EventEmitter<{unlock?: boolean}>>) {
    if (emitter) {
      emitter.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(v => this.startUpload({unlock: v.unlock!}));
      this.autoUpload = false;
    }
  }

  @Input() autofillName: Nullable<string> = null;

  @Input({required: true}) config!: CreateDigitalFormConfig;

  @Input() type!: DigitalComponentUploadDialogType;

  @Input() digitalComponent!: DigitalComponentCompleteDto;

  @Input() totalComponentsLimit = Infinity;

  @Input() businessRule: Nullable<BusinessRuleName>;
  autoUpload = true;
  allowedFormats: Nullable<AllowedFormatsDto>;

  get digitalComponentsControl() {
    return this.form.get('digitalComponents') as IczFormArray;
  }

  get showSignatureFlags() {
    return this.type === DigitalComponentUploadDialogType.NEW_DIGITAL_COMPONENT_WITH_SIGNATURES_CHECK;
  }

  get files() {
    return this.digitalComponentsControl;
  }

  get isMultiUpload() {
    return this.digitalComponent ? false : 'multiple';
  }

  completeVersions: CompleteVersionWithLoading[] = [];

  getCompleteVersion(componentVersionId: number): Nullable<CompleteVersionWithLoading> {
    return this.completeVersions.find(c => c.id === componentVersionId);
  }

  setCompleteVersion(id: number, completeVersion: Nullable<DigitalComponentVersionCompleteDto>) {
    this.completeVersions[this.completeVersions.findIndex(c => c.id === id)] = {
      id,
      completeVersion,
      isLoading: false,
    };
  }

  get allowedFileExtensions() {
    return this.allowedFormats?.allowedExtensions ?? [];
  }

  @Input({required: true}) set form(form: IczFormGroup) {
    if (!form.get('digitalComponents')) {
      form.addControl('digitalComponents', new IczFormArray(() => getDigitalComponentUploadForm(), []));
    }
    this._formGroup = form;
  }
  get form() {
    return this._formGroup;
  }
  private _formGroup!: IczFormGroup;

  @Input()
  set isUploading(isUploading: boolean) {
    if (isUploading && this.form.enabled) {
      this.form.disable();
    }
    else if (!isUploading && this.form.disabled) {
      this.form.enable();
    }
  }

  originTypeOptions: IczOption[] = [];
  esslComponentRelationOptions: IczOption[] = [];

  private setComponentFinality() {
    if (this.config?.setFieldIsFinalAsTrue === true) {
      this.digitalComponentsControl.controls.forEach(control => control.get('isFinal')?.setValue(true));
    }
  }

  ngOnInit() {
    this.originTypeOptions = enumToOptions('digitalComponentOrigin', DigitalComponentOrigin);
    this.esslComponentRelationOptions = enumToOptions('esslComponentRelation', EsslComponentRelation);

    if (this.type === DigitalComponentUploadDialogType.CHECK_IN) {
      this.autoUpload = false;
    }
    if (this.businessRule) {
      let rulesReq$ = this.codebookService.businessRulesGetAllowedFormatsPersonal();
      if (this.businessRule === BusinessRuleName.DISTRIBUTION_PERSONAL_TECHNICAL_ATTACHMENT_FORMAT) {
        rulesReq$ = this.codebookService.businessRulesGetAllowedFormatsPersonal();
      }
      else if (this.businessRule === BusinessRuleName.DISTRIBUTION_EMAIL_TECHNICAL_ATTACHMENT_FORMAT) {
        rulesReq$ = this.codebookService.businessRulesGetAllowedFormatsEmail();
      }

      this.loadingIndicatorService.doLoading(
        rulesReq$, this).subscribe(allowedFormats => {
        this.allowedFormats = allowedFormats;
      });
    }
  }

  get isAddDigitalRenditionDialog() {
    return this.type === DigitalComponentUploadDialogType.ADD_DIGITAL_RENDITION_TO_PAPER;
  }

  openSystemDialog() {
    this.fileUpload.nativeElement.click();
  }

  processFileUpload(dataTransfer?: DataTransfer): void {
    const fileUpload = this.fileUpload.nativeElement;
    const files = dataTransfer?.files ?? fileUpload.files;

    if (files.length + this.digitalComponentsControl.length > this.totalComponentsLimit) {
      this.dialogService.showError(
        this.translateParser.interpolate(
          this.translateService.instant('Není možné nahrát {{selectedComponentsCount}} komponent. Nahrajte maximálně {{maxAllowableComponents}} komponent a další případně nahrajte na obrazovce detailu dokumentu v záložce Komponenty.'),
          {
            selectedComponentsCount: files.length,
            maxAllowableComponents: this.totalComponentsLimit - this.digitalComponentsControl.length,
          }
        )!,
        'Nelze nahrát komponenty'
      );
      fileUpload.value = null;
      return;
    }

    for (let i = 0; i < files.length; i++) {
      if (this.multipleFilesDroppedInSingleUploadMode && i > 0) {
        return;
      }
      this.digitalComponentsControl.setValue([...this.digitalComponentsControl.getRawValue(), this.getEmptyDigitalComponent(files[i])]);
    }

    fileUpload.value = null;

    if (this.autoUpload) this.startUpload();
    this.fileSelected.emit();
  }

  private startUpload(options?: {unlock?: boolean}) {
    this.digitalComponentsControl.controls.forEach(control => {
      const uploadBlob = control.get('file')!.value;
      const digitalComponentVersionId = control.get('digitalComponentVersionId')!.value;
      const digitalComponentTemplateId = control.get('digitalComponentTemplateId')!.value;
      const statusControl = control.get('status');
      this.setComponentFinality();

      if (digitalComponentVersionId || digitalComponentTemplateId) return;

      statusControl!.setValue(ComponentUploadStatus.UPLOADING);

      const body = {
        file: uploadBlob,
      };

      let request$: Nullable<Observable<DigitalComponentCompleteDto | DigitalComponentVersionDto>>;
      if (this.type === DigitalComponentUploadDialogType.NEW_DIGITAL_VERSION) {
        request$ = this.apiDigitalComponentService.digitalComponentUploadNewFileVersion({digitalComponentId: this.digitalComponent.id!, body});
      } else if (this.type === DigitalComponentUploadDialogType.NEW_DIGITAL_COMPONENT ||
        this.type === DigitalComponentUploadDialogType.NEW_DIGITAL_COMPONENT_WITH_SIGNATURES_CHECK ||
                 this.type === DigitalComponentUploadDialogType.ADD_DIGITAL_RENDITION_TO_PAPER) {
        request$ = this.apiDigitalComponentVersionService.digitalComponentVersionUploadNewFile({body, validate: true});
      } else if (this.type === DigitalComponentUploadDialogType.CHECK_IN) {
        request$ =  this.apiDigitalComponentService.digitalComponentCheckin(
          {
            id: this.digitalComponent.id!,
            final: control.get('isFinal')!.value,
            unlock: options!.unlock!,
            body,
          }
        );
      }
      this.form.get('digitalComponents')!.markAsDirty();

      request$!.subscribe({
        next: (result: DigitalComponentCompleteDto | DigitalComponentVersionCompleteDto) => {
          control.get('digitalComponentVersionId')!.setValue(result.id);
          this.fileUploaded.emit(result.id);

          if (this.showSignatureFlags) {
            this.completeVersions.push({id: result.id!, completeVersion: null, isLoading: true});
            this.setCompleteVersion(result.id!, result);
          }
          if (this.businessRule && this.allowedFormats && (result as DigitalComponentVersionCompleteDto).fileName) {
            const fileNameExtension = (result as DigitalComponentVersionCompleteDto)!.fileName!.split('.')!.pop()!.toLowerCase();
            const isAllowedExtension = Boolean(this.allowedFormats.allowedExtensions!.find(a => a.toLowerCase() === fileNameExtension));
            control.get('isValidOutputFormat')!.setValue(isAllowedExtension);
          }

          this.outDigitalComponentCount.emit(this.digitalComponentsControl);
          statusControl!.setValue(ComponentUploadStatus.SUCCESS);
        },
        error: () => {
          this.fileUploaded.emit(null);
          statusControl!.setValue(ComponentUploadStatus.ERROR);
        }
      });
    });
  }

  deleteDigitalComponentDraft(index: number, item: Record<keyof DigitalComponentUploadForm['value'], any>) {
    if (item.digitalComponentVersionId && item.status === ComponentUploadStatus.SUCCESS) {
      this.loadingIndicatorService.doLoading(
        this.apiDigitalComponentVersionService.digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds({
          body: [item.digitalComponentVersionId]
        }), this, index)
        .subscribe(() => {
          this.digitalComponentsControl.removeAt(index);
          this.outDigitalComponentCount.emit(this.digitalComponentsControl);
        });
    } else if (item.status === ComponentUploadStatus.ERROR){
      this.digitalComponentsControl.removeAt(index);
      this.outDigitalComponentCount.emit(this.digitalComponentsControl);
    } else if (item.digitalComponentTemplateId && item.status === ComponentUploadStatus.SUCCESS) {
      this.digitalComponentsControl.removeAt(index);
      this.outDigitalComponentCount.emit(this.digitalComponentsControl);
    }
  }

  private getEmptyDigitalComponent(file: File) {
    const relationType = this.digitalComponent ? this.digitalComponent.relationType : EsslComponentRelation.ENCLOSURE;
    const originType = this.digitalComponent ? this.digitalComponent.originType : DigitalComponentOrigin.DIGITAL_BORN;
    const filenameParts = file.name.split('.');

    if (filenameParts.length > 1) {
      filenameParts.pop();
    }

    return {
      relationType,
      description: this.digitalComponent ? this.digitalComponent.description : null,
      label: this.autofillName ? this.getAutofilledNameWithPrefix(this.autofillName) :
         this.digitalComponent ? this.digitalComponent.label : filenameParts.join('.'),
      isFinal: false,
      originType,
      digitalComponentVersionId: null,
      file,
      status: ComponentUploadStatus.IDLE,
      isValidOutputFormat: true,
      digitalComponentTemplateId: null,
    };
  }

  showSpinner(item: Record<keyof DigitalComponentUploadForm['value'], any>) {
    return item.status === ComponentUploadStatus.UPLOADING;
  }

  showDeleteBtn(item: Record<keyof DigitalComponentUploadForm['value'], any>) {
    return item.status === ComponentUploadStatus.SUCCESS || item.status === ComponentUploadStatus.ERROR;
  }

  getIcon(item: Record<keyof DigitalComponentUploadForm['value'], any>): Nullable<string> {
    if (item.status === ComponentUploadStatus.SUCCESS) return 'success';
    else if (item.status === ComponentUploadStatus.ERROR) return 'error';
    else return null;
  }

  getAutofilledNameWithPrefix(autofilled: string): string {
    return `Obsah nosiče ${autofilled}`;
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    const change = changes.autofillName;
    if (change && change.currentValue !== change.previousValue) {
      const currentValue = changes.autofillName.currentValue;
      this.digitalComponentsControl.controls.forEach(c => c.get('label')?.setValue(this.getAutofilledNameWithPrefix(currentValue!)));
    }
  }

}
