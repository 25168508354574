@if (form) {
  @if (isBulkPrinting) {
    <icz-wizard
      [alwaysEnableAllSteps]="true"
      (stepChanged)="wizardStepChanged()"
      (canceled)="cancel()"
      #wizard
    >
      <ng-template
        iczWizardStep="printSettings"
        label="Nastavení tisku"
        submitLabel="Tisknout"
        [customSubmitHandler]="printSubmitHandler"
      >
        <ng-container *ngTemplateOutlet="printTaskForm; context: {$implicit: {form: form.get('masterPrintSettings'), index: -1}}"></ng-container>
      </ng-template>
      @for (consignment of data.consignments; track consignment.id) {
        <ng-template
          [iczWizardStep]="PRINT_ADJUSTMENTS_STEP_PREFIX + $index"
          [label]="getPrintTaskTabHeading($index)"
          [customSubmitHandler]="printSubmitHandler"
          submitLabel="Tisknout"
        >
          <ng-container *ngTemplateOutlet="printTaskForm; context: {$implicit: {form: form.get('printTasks')!.get('' + $index)!, index: $index}}"></ng-container>
        </ng-template>
      }
      <ng-container extraLeftButtons>
        @if (wizard.stepIndex < (wizard.steps?.length ?? 0) - 1) {
          <icz-button
            iczFormSubmit
            [label]="wizard.stepIndex === 0 ? 'Kontrola jednotlivých obálek' : 'Zkontrolovat další obálku'"
            (click)="wizard.basicNextStep()"
          ></icz-button>
        }
      </ng-container>
    </icz-wizard>
  }
  @else {
    <ng-container *ngTemplateOutlet="printTaskForm; context: {$implicit: {form: form, index: 0}}"></ng-container>
  }
}


<ng-template #printTaskForm let-context>
  <form
    [formGroup]="context.form" [class.p-16]="isBulkPrinting" class="h-full overflow-hidden"
    [waiting]="loadingService.isLoading$(this, EnvelopePrintDialogLoader.OTHER) | async"
    >
    <div class="row gap-32 h-full">
      <div class="col print-specification-col h-full overflow-y-auto">
        @if (!isTestingPrintMode) {
          <icz-section label="Vyberte obálku">
            <icz-form-autocomplete
              formControlName="envelopeTemplateId"
              label="Šablona obálky"
              [options]="envelopeTemplateOptions"
            ></icz-form-autocomplete>
          </icz-section>
        }
        @if (isTestingPrintMode) {
          <icz-envelope-or-label-test-consignee-form
            [form]="context.form"
          ></icz-envelope-or-label-test-consignee-form>
        }
        @else {
          @if (context.index === -1 || !isConsigneeBusiness(context.index)) {
            <icz-form-field
              formControlName="salutation"
              label="Oslovení"
            ></icz-form-field>
          }
          @if ((context.index === -1 || isConsigneeBusiness(context.index))) {
            <icz-form-field
              formControlName="consigneeAdditionalName"
              label="Doplňující název"
            ></icz-form-field>
            <icz-form-field
              formControlName="contactPerson"
              label="Kontaktní osoby"
            ></icz-form-field>
          }
        }
        @if (context.index === -1 ? masterCustomFieldDefs?.length : customFieldDefs[context.index]?.length) {
          <icz-envelope-or-label-custom-fields-form
            [form]="$any(context.form.get('customText'))"
            [customFields]="context.index === -1 ? masterCustomFieldDefs : customFieldDefs[context.index]"
          ></icz-envelope-or-label-custom-fields-form>
        }
        @if (!isBulkPrinting || (isBulkPrinting && context.index === -1)) {
          <icz-section label="Tiskové nastavení">
            <icz-form-field
              type="integer"
              formControlName="xPrintOffsetMm"
              label="Odsadit vodorovně"
              >
              <div suffix class="icz-body-1">mm</div>
            </icz-form-field>
            <icz-form-field
              type="integer"
              formControlName="yPrintOffsetMm"
              label="Odsadit svisle"
              >
              <div suffix class="icz-body-1">mm</div>
            </icz-form-field>
          </icz-section>
        }
      </div>
      <div class="col grow h-full overflow-y-auto">
        @if (!isBulkPrinting || (isBulkPrinting && context.index !== -1)) {
          @if (getSelectedEnvelopeTemplate(context.index); as selectedEnvelopeTemplate) {
            <icz-envelope-or-label-preview-canvas
              [template]="selectedEnvelopeTemplate"
              [previewImageDataUrl]="previewDataUrls[context.index]"
              [isPreviewLoading]="loadingService.isLoading$(this, EnvelopePrintDialogLoader.PREVIEW) | async"
            ></icz-envelope-or-label-preview-canvas>
          }
        }
      </div>
    </div>
  </form>
</ng-template>

@if (!isBulkPrinting) {
  <icz-form-buttons>
    <icz-button leftButtons iczFormSubmit
      [disabled]="loadingService.isLoading(this)"
      (onAction)="submit()"
      primary label="Tisknout"
    ></icz-button>
    <icz-button rightButtons
      [disabled]="loadingService.isLoading(this)"
      (onAction)="cancel()" label="Storno"
    ></icz-button>
  </icz-form-buttons>
}
