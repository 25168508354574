import {inject, Injectable} from '@angular/core';
import {finalize, Observable} from 'rxjs';
import {LoadingIndicatorService} from './loading-indicator.service';

import {AnyComponent} from './essentials.utils';

/**
 * Used for showing/hiding global loading indicator which overlays the whole application
 * vierport with a semitransparent overlay and a loading spinner.
 * Use this sparingly and only for short operations as the global loader prevents the users from doing anything.
 */
@Injectable({
  providedIn: 'root'
})
export class GlobalLoadingIndicatorService {

  private loadingService = inject(LoadingIndicatorService);

  /**
   * Global loading explanation. Use if the loading takes a long time.
   */
  globalLoadingDescription: Nullable<string>;
  /**
   * @internal
   */
  isInitialized = false;

  private appComponent!: AnyComponent;

  /**
   * Call this method in ngOnInit of your AppComponent after binding it to a viewport element in AppComponent's template.
   */
  initialize(appComponent: AnyComponent) {
    this.isInitialized = true;
    this.appComponent = appComponent;
  }

  /**
   * Works in a similar fashion to LoadingIndicatorService.doLoading but has only one global context.
   * @see LoadingIndicatorService.doLoading
   */
  doLoading<T>(obs$: Observable<T>, globalLoadingDescription?: Nullable<string>): Observable<T> {
    this.globalLoadingDescription = globalLoadingDescription;

    return this.loadingService.doLoading(obs$, this.appComponent).pipe(
      finalize(() => this.globalLoadingDescription = null),
    );
  }

  startLoading(globalLoadingDescription?: Nullable<string>): void {
    this.globalLoadingDescription = globalLoadingDescription;
    this.loadingService.startLoading(this.appComponent);
  }
  /**
   * Hides global loading indicator.
   */
  endLoading(): void {
    this.globalLoadingDescription = null;
    this.loadingService.endLoading(this.appComponent);
  }

}
