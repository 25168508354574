import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Injector,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  CzechAddressDto,
  HouseNumberType,
  SubjectAttributeDataBoxDto,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto
} from '|api/commons';
import {IczModalService} from '@icz/angular-modal';
import {isAddressPartiallyFilled, SubjectsService} from '../../../../services/subjects.service';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';
import {createAddressFormGroup} from '../address/address-utils';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {AddressCompleteDto, AddressForm, AddressFormat, AddressWithTypeDto} from '../../model/addresses.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {EditSubjectDialogMode} from '../edit-subject/edit-subject-dialog.component';
import {
  constructSubjectName,
  SubjectReplacementConfig,
  transformSubjectRecordCreateOrUpdateDtoToSubjectRecordDto
} from '../../model/subjects.model';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {delay} from 'rxjs/operators';
import {of, switchMap} from 'rxjs';
import {ELASTIC_RELOAD_DELAY} from '../../document-toolbar/services/toolbar-common.utils';
import {SubjectsToolbarDisablers} from '../subjects-toolbar/subjects-toolbar.disablers';
import {IszrRppUserRelationFilteredDto} from '|api/codebook';
import {CodebookService} from '../../../../core/services/codebook.service';
import {SubjectIszrIdentificationService} from '../subject-iszr-identification.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  CreateOrUpdateSubjectAction,
  SubjectCreateOrUpdateWithDuplicateResolveService
} from '../subject-create-or-update-with-duplicate-resolve.service';
import {SubjectToastService, SubjectToastType} from '../../../../core/services/notifications/subject-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {TranslateService} from '@ngx-translate/core';
import {SubjectAttributeType} from '../../model/subject-attribute-type';
import {esslErrorDtoToToastParameters} from '../../../notifications/toast.service';

export interface SubjectAddressSelection {
  existingAddressSelected: boolean;
  address: AddressCompleteDto;
}


@Component({
  selector: 'icz-subject-detail-card',
  templateUrl: './subject-detail-card.component.html',
  styleUrls: ['./subject-detail-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectDetailCardComponent implements OnInit {

  private injector = inject(Injector);
  private iczModalService = inject(IczModalService);
  private subjectsService = inject(SubjectsService);
  private subjectToastService = inject(SubjectToastService);
  private codebookService = inject(CodebookService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private subjectIszrIdentificationService = inject(SubjectIszrIdentificationService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private translateService = inject(TranslateService);
  loadingIndicatorService = inject(LoadingIndicatorService);
  private subjectCreateOrUpdateWithDuplicateResolveService = inject(SubjectCreateOrUpdateWithDuplicateResolveService);

  @Input({
    transform: transformSubjectRecordCreateOrUpdateDtoToSubjectRecordDto,
    required: true
  }) subject!: SubjectRecordDto;
  @Input() areAddressesReadonly = false;
  @Input() showAllAttributes = false;
  @Input() subjectOU: Nullable<string>;
  @Output() subjectAddressSelected = new EventEmitter<SubjectAddressSelection>();
  @Output() subjectEdited = new EventEmitter<SubjectRecordDto>();
  @Output() subjectRemoved = new EventEmitter<void>();
  @Input() label: Nullable<string>;
  @Input() allowSubjectEdit = true;
  @Input() allowSubjectIdentify = true;
  @Input() allowSubjectUnlink = true;
  @Input() subjectReplacementConfig: Nullable<SubjectReplacementConfig>;

  /**
   * FormGroup for selecting the sending address. Unfortunately addresses don't necessarily have an ID or any other
   * unique property, so we rely on custom index
   */
  selectAddressGroup = new IczFormGroup({
    sendingAddressIndex: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });
  newAddressFormGroup = createAddressFormGroup();

  showNewAdditionalAddressForm = false;
  _addresses: Array<AddressWithTypeDto> = [];

  readonly SubjectAttributeType = SubjectAttributeType;

  get addresses(): any {
    return this._addresses;
  }

  iszrAgendasForCurrentFunctionalPosition: IszrRppUserRelationFilteredDto[] = [];

  setAddressesForSelection(subject: SubjectRecordDto) {
    this._addresses = SubjectTemplateUtils.getBusinessSortedAddresses(subject);

    if (!this.areAddressesReadonly) {
      this._addresses.push({
        type: SubjectAttributeType.ADDITIONAL_ADDRESS,
        address: {
          _Class: AddressFormat.CzechAddressDto,
          country: 'CZE',
          city: '',
          houseNumber: '',
          houseNumberType: HouseNumberType.STREET_NUMBER,
          postalCode: ''},
        format: AddressFormat.CzechAddressDto
      });
    }
    this.newAddressIndex = this.addressIndexes().length - 1;

    if (this._addresses.length && !this.selectAddressGroup.get('sendingAddressIndex')!.value) {
      this.selectAddressGroup.get('sendingAddressIndex')!.setValue(0);
    }
  }

  get isUserNotPermittedForIszrOperations(): boolean {
    return Boolean(SubjectsToolbarDisablers.isUserNotPermittedForIszrOperations(this.iszrAgendasForCurrentFunctionalPosition)());
  }

  addressIndexes(): number[] {
    return Array.from(Array(this.addresses?.length).keys());
  }

  newAddressIndex = 0;

  get dataBoxes(): SubjectAttributeDataBoxDto[] {
    return (this.subject?.attributes[SubjectAttributeType.DATA_BOX]) ?? [];
  }

  updateCard(subjectId: number) {
    of(delay(ELASTIC_RELOAD_DELAY)).pipe(switchMap(_ => {return this.apiSubjectRecordNgElasticService.subjectRecordElasticGet({subjectId});}))
    .subscribe((subject: SubjectRecordDto) => {
      this.setSubject(subject);
    });
  }

  editSubject() {
    let mode = EditSubjectDialogMode.EDIT_NONPERSISTENT;
    if (this.subject!.isdsVerified) mode = EditSubjectDialogMode.ISDS_VIEW_ONLY;
    if (this.subject!.id) mode = EditSubjectDialogMode.FULL;

    SubjectTemplateUtils.openEditSubjectModal(
      this.iczModalService,
      this.subject!,
      mode,
      null,
      null,
      this.subjectReplacementConfig!,
      this.injector,
    ).subscribe((result: any) => {
        if (result) {
          if (!result.id) {
            this.setSubject(result);
          }
          else {
            this.updateCard(result.id);
          }
        }
      });
  }

  identifySubject() {
    if (!this.subject!.id) return;

    this.loadingIndicatorService.doLoading(
      this.subjectIszrIdentificationService.tryIdentifySubject(this.subject!, false, this.injector)
        .pipe(switchMap(iszrIdentifiedSubject => {
          if (!iszrIdentifiedSubject?.subject) return of(null);
          const subject = this.subjectsService.enrichExistingSubjectWithIdentified(this.subject!, iszrIdentifiedSubject.subject).enrichedSubject;
          const updatedSubject: SubjectRecordCreateOrUpdateDto = {
            id: subject.id,
            classification: subject.classification,
            forceMode: false,
            enrichMode: true,
            ovmType: subject.ovmType,
            attributes: subject.attributes,
            valueCorrectionMode: iszrIdentifiedSubject.valueCorrectionMode,
            iszrIdentifier: subject.iszrIdentifier,
            iszrMetadata: subject.iszrMetadata,
          };
          return this.subjectCreateOrUpdateWithDuplicateResolveService.createOrUpdateSubjectWithDuplicateResolve(
            CreateOrUpdateSubjectAction.CREATE_OR_UPDATE_SUBJECT,
            {subject: updatedSubject},
          );
        }))
      ,
      this)
      .subscribe({
        next: subject => {
          if (!subject) {
            this.changeDetectorRef.detectChanges();
            return;
          }
          this.subject = subject as SubjectRecordDto;
          this.setAddressesForSelection(this.subject!);
          if (this.showAllAttributes) {
            this.addAllAttributes();
          }
          this.updateCard(this.subject.id!);

          const name = constructSubjectName(subject);
          this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_UPDATE_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
        },
        error: err => {
          const name = constructSubjectName(this.subject);

          this.subjectToastService.dispatchSubjectErrorToast(
            SubjectToastType.SUBJECT_UPDATE_ERROR, {
              [InternalNotificationKey.SUBJECT_NAME]: name,
              ...esslErrorDtoToToastParameters(this.translateService, err.error),
            });
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  onAddressRadioSelected() {
    this.selectAddressGroup.get('sendingAddressIndex')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(index => {
      if (index! < this.newAddressIndex) {
        const addressWithTypeDto = this.addresses[index!];
        if (!addressWithTypeDto) return;
        this.subjectAddressSelected.emit({existingAddressSelected: true, address: addressWithTypeDto!.address});
        this.showNewAdditionalAddressForm = false;
      } else if (index! === this.newAddressIndex) {
        this.showNewAdditionalAddressForm = true;
      }
    });
  }

  onNewAddressValueChange() {
    this.newAddressFormGroup.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((newAddress: AddressForm) => {
      const addressFormat = newAddress.value._Class as AddressFormat;
      let addressByFormat = newAddress.value[addressFormat]!;
      if (!addressByFormat) return;
      addressByFormat!._Class = newAddress.value._Class!;
      addressByFormat!.country = newAddress.value.country!;

      this._addresses[this.newAddressIndex] = {
        type: SubjectAttributeType.ADDITIONAL_ADDRESS,
        address: (newAddress.value[addressFormat] as AddressCompleteDto)!,
        format: newAddress.value._Class as AddressFormat
      };

      if (addressFormat === AddressFormat.CzechAddressDto) {
        addressByFormat = this.subjectsService.handleBuildingNumbers(addressByFormat as CzechAddressDto);
      }

      if (isAddressPartiallyFilled(this.newAddressFormGroup) && this.newAddressFormGroup.valid) {
        this.subjectAddressSelected.emit({existingAddressSelected: false, address: addressByFormat as CzechAddressDto});
      }
    });
  }

  orderedAttributes: {key: SubjectAttributeType, label: string}[] = [
    // Natural person attributes
    {key: SubjectAttributeType.BIRTH_DATE, label: 'Datum narození'},
    // Legal person like attributes
    {key: SubjectAttributeType.TAX_ID, label: 'DIČ'},
    {key: SubjectAttributeType.VAT_ID, label: '  Identifikační číslo pro účely DPH'},
    {key: SubjectAttributeType.EXCISE_TAX_ID, label: 'ID Spotřební daně'},
    {key: SubjectAttributeType.LE_ID, label: 'Identifikační údaj právnické osoby'},
    {key: SubjectAttributeType.ART_1_P_3_ID, label: 'Identifikační údaj uvedený v čl.3 odst. 1'},
    {key: SubjectAttributeType.EORI_CODE, label: 'EORI kód'},
  ];

  private addAllAttributes() {
    this.orderedAttributes.splice(1, 0,
      {key: SubjectAttributeType.IDENTITY_CARD_ID, label: 'Občanský průkaz'},
      {key: SubjectAttributeType.DRIVING_LICENCE_ID, label: 'Řidičský průkaz'},
      {key: SubjectAttributeType.PASSPORT_ID, label: 'Cestovní pas'},
      {key: SubjectAttributeType.PHONE_FAX, label: 'Telefon/fax'},
      {key: SubjectAttributeType.EMAIL, label: 'E-mail'},
      {key: SubjectAttributeType.BIRTH_NAME, label: 'Rodné jméno'},
      {key: SubjectAttributeType.BIRTH_SURNAME, label: 'Rodné příjmení'},
      {key: SubjectAttributeType.BIRTH_PLACE, label: 'Místo narození'},
      {key: SubjectAttributeType.DEGREE_BEFORE, label: 'Titul před'},
      {key: SubjectAttributeType.DEGREE_AFTER, label: 'Titul za'},
    );
  }

  ngOnInit() {
    this.codebookService.iszrAgendasWithActivityRolesForCurrentFunctionalPosition().subscribe(iszrAgendas => {
      this.iszrAgendasForCurrentFunctionalPosition = iszrAgendas;
    });
    this.onAddressRadioSelected();
    this.setAddressesForSelection(this.subject!);
    this.onNewAddressValueChange();
    if (this.showAllAttributes) this.addAllAttributes();
  }

  private setSubject(subject: SubjectRecordDto) {
    this.subject = subject;
    this.setAddressesForSelection(this.subject!);
    this.cd.detectChanges();
    this.subjectEdited.emit(this.subject);
  }

}
