import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {IczOnChanges, IczSimpleChanges} from '../icz-on-changes';
import {TooltipDirective} from '../tooltip.directive';
import {NgClass, NgStyle, NgTemplateOutlet} from '@angular/common';
import {LabelComponent} from '../label/label.component';
import {IconComponent} from '../icon/icon.component';
import {USER_INTERACTION_LOGGER} from '../essentials.providers';

/**
 * Button size.
 * "small" and "default" are the most commonly used sizes in our design systems.
 */
export type ButtonSize = 'tiny' | 'small' | 'compact' | 'default' | 'large' | 'xlarge';

/**
 * A button with additional icon
 */
@Component({
  selector: 'icz-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgStyle, MatButton, TooltipDirective, LabelComponent, IconComponent, NgTemplateOutlet]
})
export class ButtonComponent implements OnInit, IczOnChanges {

  private logger = inject(USER_INTERACTION_LOGGER, {optional: true});
  private attachedTooltip = inject(TooltipDirective, {optional: true, self: true});

  /**
   * Button label.
   */
  @Input()
  label!: string;
  /**
   * Button icon.
   */
  @Input()
  svgIcon: Nullable<string>;
  /**
   * Button tab index, works in the same fashion as plain <button> tabindex.
   */
  @Input()
  tabIndex = '0';
  @Input()
  set disableRipple(value: Nullable<boolean | ''>) { this._disableRipple = value === '' ? true : Boolean(value); }
  get disableRipple() { return this._disableRipple; }
  private _disableRipple: boolean = false;
  /**
   * If TRUE, will disable entering the button with keyboard.
   * @deprecated - use tabIndex property.
   */
  @Input()
  disableFocus: boolean|'' = false;
  /**
   * If TRUE, will render button borders without any rounding.
   */
  @HostBinding('class.square')
  @Input()
  square = false;
  /**
   * If true, the button will be collapsed into compact form, bearing only icon and having
   * a special tooltip in case the user enters the button with his/her mouse.
   */
  @Input()
  isCollapsed: Nullable<boolean> = false;
  /**
   * Stops click event bubbling in DOM after the button is clicked.
   * @deprecated - call $event.stopPropagation in your onAction handler instead.
   */
  @Input()
  stopClickPropagation = false;
  /**
   * An arrow function containing a predicate which when
   * evaluated to TRUE will disable emit to onAction output.
   */
  @Input()
  cancelActionIf!: () => boolean;
  /**
   * If TRUE, forces the icon in the button to have its original color as defined in its SVG source.
   * Otherwise will apply a color from our design system to button icon paths.
   */
  @Input()
  set originalIconColor(value: Nullable<boolean | ''>) { this._originalIconColor = value === '' ? true : Boolean(value); }
  get originalIconColor() { return this._originalIconColor; }
  private _originalIconColor = false;
  /**
   * Position of button icon relative to button label.
   */
  @Input()
  iconPosition: 'left' | 'right' = 'left';
  @HostBinding('class.icon-right')
  protected get isIconRight() { return this.iconPosition === 'right'; }
  /**
   * Button size.
   * @see ButtonSize
   */
  @Input()
  size: ButtonSize = 'default';

  @HostBinding('class.tiny')
  protected get isTiny() { return this.size === 'tiny'; }
  @HostBinding('class.small')
  protected get isSmall() { return this.size === 'small'; }
  @HostBinding('class.large')
  protected get isLarge() { return this.size === 'large'; }
  @HostBinding('class.xlarge')
  protected get isXLarge() { return this.size === 'xlarge'; }
  @HostBinding('class.compact')
  protected get isCompact() { return this.size === 'compact'; }

  /**
   * If TRUE, marks the button as disabled which makes it greyed out and unclickable.
   */
  @Input()
  set disabled(value: Nullable<boolean | ''>) { this._disabled = value === '' ? true : Boolean(value); }
  get disabled() { return this._disabled; }
  private _disabled = false;
  /**
   * If TRUE, will add rounding to button.
   * Icon-only buttons will become circle buttons with this option enabled.
   */
  @HostBinding('class.rounded')
  @Input()
  set rounded(value: boolean | '') { this._rounded = value === '' ? true : value; }
  get rounded() { return this._rounded; }
  private _rounded = false;
  /**
   * If TRUE, makes button text and icon not centered to horizontal center.
   */
  @HostBinding('class.no-centered')
  @Input()
  set noCentered(value: boolean | '') { this._noCentered = value === '' ? true : value; }
  get noCentered() { return this._noCentered; }
  private _noCentered = false;
  /**
   * If true, removes button border from button style.
   */
  @Input()
  set noBorder(value: boolean | '') { this._noBorder = value === '' ? true : value; }
  get noBorder() { return this._noBorder; }
  private _noBorder = false;
  /**
   * If true, makes the button visually emphasised using blue color.
   * Non-primary buttons have white color.
   */
  @Input()
  set primary(value: boolean | '') { this._primary = value === '' ? true : value; }
  get primary() { return this._primary; }
  private _primary = false;

  protected get color() { return this._primary ? 'primary' : undefined; }

  /**
   * Accepts a string representing a CSS color literal to be applied as button background color.
   * Most usually used in conjunction with "transparent" color.
   */
  @Input()
  set background(value: string) { this._background = value; }
  get background() { return this._background || this.color!; }

  private _background: Nullable<string>;

  @HostBinding('class.no-border')
  get hasNoBorder() {
    return this._background === 'transparent' || this._noBorder || this._primary;
  }

  /**
   * Emits a button click event when the button is clicked.
   */
  @Output()
  onAction = new EventEmitter<Event>();

  protected get collapsedTooltip() {
    return (this.isCollapsed && !this.attachedTooltip) ? this.label : null;
  }

  protected get hasDisabledFocus(): boolean { return this.disableFocus === '' || this.disableFocus; }
  protected get innerButtonTabIndex(): string { return this.hasDisabledFocus ? '-1' : this.tabIndex; }

  protected hasNoContent!: boolean;

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.label || changes.isCollapsed) {
      if (this.attachedTooltip) {
        this.attachedTooltip.tooltipButtonLabel = this.isCollapsed ? this.label : null;
        this.attachedTooltip.ngOnChanges();
      }
    }
  }

  /**
   * @internal
   */
  @HostListener('click', ['$event'])
  buttonClicked($event: Event) {
    if (this.stopClickPropagation) $event.stopPropagation();
    if (this.disabled) return;
    if (typeof(this.cancelActionIf) !== 'function' || !this.cancelActionIf()) {
      this.logger?.logUserInteraction({description: `Použito tlačítko '${this.label ?? this.svgIcon}'`});
      this.onAction.emit($event);
    }
  }

  /**
   * @internal
   */
  ngOnInit() {
    this.hasNoContent = !Boolean(this.label);
  }

}
