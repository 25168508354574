import {inject, Pipe, PipeTransform} from '@angular/core';
import {interpolateWithArrayParams} from '@icz/angular-essentials';
import {TranslateService} from '@ngx-translate/core';

export function interpolateBackendErrorMessage(translateService: TranslateService, error: Nullable<{errorCode?: Nullable<string>, parameters?: Nullable<string[]>}>): string {
  if (!error?.errorCode) {
    return translateService.instant('Neznámá chyba');
  }
  else {
    const translatedErrorCode = translateService.instant(error.errorCode);

    if (error.parameters?.length) {
      return interpolateWithArrayParams(translateService, translatedErrorCode, error.parameters);
    }
    else {
      return translatedErrorCode;
    }
  }
}


@Pipe({
  name: 'interpolateBackendError',
  pure: false, // impure because it should rerender on language changes
})
export class InterpolateBackendErrorPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  transform(code: Nullable<string>, parameters?: Nullable<string[]>): string {
    return interpolateBackendErrorMessage(this.translateService, {errorCode: code!, parameters: parameters!});
  }

}
