import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {of, OperatorFunction, switchMap} from 'rxjs';
import {TypedSearchRecordDto} from './search-api.service';
import {
  EntityType,
  RelatedObjectDto,
  RelatedObjectType,
  SubjectObjectRelationFindDto,
  SubjectRecordWithRelationsDto
} from '|api/commons';
import {
  entityTypeToRelatedObjectType,
  relatedObjectClassToRelatedObjectType
} from '../components/shared-business-components/shared-document.utils';
import {map} from 'rxjs/operators';
import {constructSubjectName} from '../components/shared-business-components/model/subjects.model';
import {Page} from '@icz/angular-table';
import {
  GenericOwnElasticConsignment,
  GenericOwnElasticConsignmentWithConsignee
} from '../components/shared-business-components/own-consignment-table/model/own-consignment-model';

enum SearchRepresentingSubjectForEntity {
  ENTITY_ITSELF = 'ENTITY_ITSELF',
  RELATED_OBJECT_BY_OBJECT_CLASS = 'RELATED_OBJECT_BY_OBJECT_CLASS',
}

export interface EntityWithRepresentingSubject {
  representingSubject?: string;
}

export function extendEsslObjectsWithRepresenting(apiSubjectRecordNgElasticService: ApiSubjectRecordElasticService,):
  OperatorFunction<Page<TypedSearchRecordDto<any & EntityWithRepresentingSubject>>, Page<TypedSearchRecordDto<any & EntityWithRepresentingSubject>>> {
  return switchMap(res => {
    if (!res.content) return of(res);
    let findRelations: Array<RelatedObjectDto>;
    let searchMode: SearchRepresentingSubjectForEntity;

    if (res.content.every(d => d.source.entityType === EntityType.CIRCULATION_ACTIVITY || d.source.entityType === EntityType.CIRCULATION_TASK)) {
      searchMode = SearchRepresentingSubjectForEntity.RELATED_OBJECT_BY_OBJECT_CLASS;
      findRelations = res.content!.map(d => {
        return {
          relatedObjectId: d.source.documentId ?? d.source.fileId,
          relatedObjectType: relatedObjectClassToRelatedObjectType(d.source.objectClass!)!
        };
      });
    } else {
      searchMode = SearchRepresentingSubjectForEntity.ENTITY_ITSELF;
      findRelations = res.content!.map(d => {
        return {
          relatedObjectId: d.source.id,
          relatedObjectType: entityTypeToRelatedObjectType(d.source.entityType!)!
        };
      });
    }

    if (findRelations.length) {
      return apiSubjectRecordNgElasticService.subjectRecordElasticGetRepresentingSubjects({body: findRelations}).pipe(map((subjectsWithRelations: Array<SubjectRecordWithRelationsDto>) => {
        res.content.forEach(c => {
          let relatedSubject: Nullable<SubjectRecordWithRelationsDto> = null;

          if (searchMode === SearchRepresentingSubjectForEntity.RELATED_OBJECT_BY_OBJECT_CLASS) {
            relatedSubject = subjectsWithRelations.find(s => s.objectRelations!.find(r => {
              return (r.relatedObjectId === c.source.documentId || r.relatedObjectId === c.source.fileId) &&
                r.relatedObjectType === relatedObjectClassToRelatedObjectType(c.source.objectClass!)!; }
            ));
          } else if (searchMode === SearchRepresentingSubjectForEntity.ENTITY_ITSELF) {
            relatedSubject = subjectsWithRelations.find(s => s.objectRelations!.find(r => {
              return r.relatedObjectId === c.source.id && r.relatedObjectType === entityTypeToRelatedObjectType(c.source.entityType!)!; }
            ));
          }

          if (relatedSubject) {
            const representingRelation = relatedSubject?.objectRelations!.find(r => Boolean(r.representing));
            if (representingRelation) {
              c.source.representingSubject = constructSubjectName(relatedSubject, true);
            }
          }
        });

        return res;
      }));
    }
    else {
      return of(res);
    }
  });
}

export function extendConsignmentsWithConsignee(apiSubjectRecordNgElasticService: ApiSubjectRecordElasticService,):
  OperatorFunction<Page<GenericOwnElasticConsignment>, Page<GenericOwnElasticConsignmentWithConsignee>> {
  return switchMap(res => {
    if (!res.content) return of(res as Page<GenericOwnElasticConsignmentWithConsignee>);

    const findRelations: SubjectObjectRelationFindDto = {
      relatedObjectIds: res.content!.map(d => d.id!),
      relatedObjectType: RelatedObjectType.CONSIGNMENT
    };

    if (findRelations.relatedObjectIds!.length) {
      return apiSubjectRecordNgElasticService.subjectRecordElasticElasticFindSubjectsByRelations({body: findRelations}).pipe(map((subjectsWithRelations: Array<SubjectRecordWithRelationsDto>) => {
        res.content.forEach(consignment => {
          const consignmentWithSubject = consignment as GenericOwnElasticConsignmentWithConsignee;
          const relatedSubject: Nullable<SubjectRecordWithRelationsDto> = subjectsWithRelations.find(s => s.objectRelations!.find(r => {
            return r.relatedObjectId === consignment.id && r.relatedObjectType === RelatedObjectType.CONSIGNMENT;
          }));

          if (relatedSubject) {
            consignmentWithSubject.consigneeDetail = relatedSubject;
          } else {
            consignmentWithSubject.consigneeDetail = null;
          }
        });
        return res as Page<GenericOwnElasticConsignmentWithConsignee>;
      }));
    } else {
      return of(res as Page<GenericOwnElasticConsignmentWithConsignee>);
    }
  });
}
