import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {DocumentDto, FileDto} from '|api/document';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {OrganizationalStructureService} from '../../../core/services/organizational-structure.service';

@Component({
  selector: 'icz-constrained-document-file-sidebar',
  templateUrl: './constrained-document-file-sidebar.component.html',
  styleUrls: ['./constrained-document-file-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConstrainedDocumentFileSidebarComponent {

  private organizationalStructureService = inject(OrganizationalStructureService);

  @Input()
  opened = false;
  @Input({required: true})
  set document(newValue: Nullable<DocumentDto|FileDto>) {
    if (newValue) {
      this.form.patchValue({
        ...newValue,
        createdAt: newValue?.auditInfo?.createdAt,
        identifier: newValue.uid?.uid,
      });
      this._document = newValue;
    }
  }
  @Input()
  searchTerms: string[] = [];
  @Output()
  closed = new EventEmitter<void>();

  private _document!: DocumentDto|FileDto;

  functionalPositionsOptions$ = this.organizationalStructureService.functionalPositionsOptions();

  form = new IczFormGroup({
    subject: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    refNumber: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    identifier: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    holderFunctionalPositionIds: new IczFormControl<Nullable<number[]>>({value: null, disabled: true}),
    ownerFunctionalPositionId: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    createdAt: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    representingSubject: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
  });

  get hasDocumentForConstrainedPreview(): boolean {
    return !isNil(this._document) && this.opened;
  }

}
