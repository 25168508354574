/* eslint-disable @typescript-eslint/quotes */
import {Component, inject, OnInit} from '@angular/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {FilterType, IczInMemoryDatasource, TableColumnsData} from '@icz/angular-table';
import {ApiReceivedDigitalConsignmentService, ReceivedEpdzAttachmentDto, ReceivedEpdzMessageDto} from '|api/sad';
import {EpdzAttachmentViewerService} from '../epdz-attachment-viewer/epdz-attachment-viewer.service';
import {ICZ_MODAL_DATA} from '@icz/angular-modal';

export interface EpdzContainerContentsDialogData {
  message: ReceivedEpdzMessageDto;
  containerEpdzAttachmentId: number;
}

@Component({
  selector: 'icz-epdz-container-contents-dialog',
  templateUrl: './epdz-container-contents-dialog.component.html',
  styleUrls: ['./epdz-container-contents-dialog.component.scss'],
})
export class EpdzContainerContentsDialogComponent implements OnInit {

  private dialogData = inject<EpdzContainerContentsDialogData>(ICZ_MODAL_DATA);
  private epdzAttachmentViewerService = inject(EpdzAttachmentViewerService);
  private apiReceivedDigitalConsignmentService = inject(ApiReceivedDigitalConsignmentService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);

  columnsData = new TableColumnsData<keyof ReceivedEpdzAttachmentDto|'containerExtractionInfo.pathInContainer'>([
    {id: 'name', label: 'Název', filterType: FilterType.NONE},
    {id: 'containerExtractionInfo.pathInContainer', label: 'Umístění v kontejneru', filterType: FilterType.NONE},
    {id: 'fileSize', label: 'Velikost', filterType: FilterType.FILE_SIZE},
  ]);

  dataSource = new IczInMemoryDatasource(() => []);

  ngOnInit() {
    this.loadingIndicatorService.doLoading(
      this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentFindContainerContents({
        attachmentId: this.dialogData.containerEpdzAttachmentId,
      }),
      this
    ).subscribe(containerContents => {
      this.dataSource.setDataFactory(() => containerContents);
    });
  }

  openEpdzAttachment(attachment: ReceivedEpdzAttachmentDto) {
    this.epdzAttachmentViewerService.openAttachmentViewer({
      message: this.dialogData.message,
      attachments: [
        attachment,
      ],
    });
  }

}
