import {ButtonDisablerFn, Guard} from '../../../button-collection/button-collection.component';
import {OperationValidator, ValidationResultWithTooltip} from '../../../../services/bulk-operation-validation.service';
import {EsslObject} from '../../model/entity.model';
import {CommonToolbarValidators} from './toolbar-common.validators';
import {DocumentDto, FileDto} from '|api/document';

export class CommonToolbarDisablers {

  static evalSelectionWithValidator<T>(selection: Nullable<T[]>, validator: OperationValidator<T>): Nullable<ValidationResultWithTooltip> {
    return (selection && selection.length === 1) ? validator(selection[0]) : null;
  }

  @Guard()
  static isNoOrMultipleItemsSelected(items: Nullable<unknown[]>): ButtonDisablerFn {
    return () => {
      if (!items || items.length === 0) {
        return {tooltip: 'Vyberte položku seznamu'};
      }
      else if (items.length > 1) {
        return {tooltip: 'Akci lze provést pouze s jednou položkou'};
      }
      else {
        return null;
      }
    };
  }

  @Guard()
  static isNoItemSelected(items: Nullable<unknown[]>): ButtonDisablerFn {
    return () => {
      return (!items || items.length < 1) ? {tooltip: 'Vyberte alespoň jednu položku seznamu' } : null;
    };
  }

  static isEsslObjectLocked(selection: Nullable<EsslObject[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, CommonToolbarValidators.isEsslObjectLocked());
    };
  }

  static isFileOrDocumentSuspended(selection: Nullable<(DocumentDto | FileDto)[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, CommonToolbarValidators.isFileOrDocumentSuspended());
    };
  }

  static isFileOrDocumentNotSuspended(selection: Nullable<(DocumentDto | FileDto)[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, CommonToolbarValidators.isFileOrDocumentNotSuspended());
    };
  }

  static isFileOrDocumentInExternalApp(selection: Nullable<(DocumentDto | FileDto)[]>): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, CommonToolbarValidators.isFileOrDocumentInExternalApp());
    };
  }

  static isEntityInRegistryOffice(selection:  (DocumentDto|FileDto)[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, CommonToolbarValidators.isEntityInRegistryOffice());
    };
  }
}
