<div class="sidebar" [style.width.px]="totalWidth">

  <ng-content></ng-content>

  <icz-icon
    size="small" svgIcon="more-vertical"
    class="grabber h-resize"
    (mousedown)="onSideResizeStart($event)"
  ></icz-icon>

</div>
