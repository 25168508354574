import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {RTFJS} from 'rtf.js';
import {LoadingIndicatorService} from '@icz/angular-essentials';

@Component({
  selector: 'icz-rtf-outlet',
  templateUrl: './rtf-outlet.component.html',
  styleUrls: ['./rtf-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RtfOutletComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);

  @Input({required: true})
  rtfContents: Nullable<ArrayBuffer>;

  rtfHtmlContents = '';

  ngOnInit(): void {
    if (this.rtfContents) {
      const rtfDocument = new RTFJS.Document(this.rtfContents, {});

      this.loadingService.startLoading(this);

      rtfDocument.render().then(htmlDomNodes => {
        const virtualContainer = document.createElement('div');
        virtualContainer.append(...htmlDomNodes);

        this.rtfHtmlContents = virtualContainer.innerHTML;
        this.loadingService.endLoading(this);
      });
    }
  }

}
