/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ImportMetadataAuditCountDto } from '../models/import-metadata-audit-count-dto';
import { OrganizationalStructureChangeDto } from '../models/organizational-structure-change-dto';
import { organizationalStructureDownloadXmlFromEos } from '../fn/organizational-structure/organizational-structure-download-xml-from-eos';
import { OrganizationalStructureDownloadXmlFromEos$Params } from '../fn/organizational-structure/organizational-structure-download-xml-from-eos';
import { organizationalStructureFindImportMetadata } from '../fn/organizational-structure/organizational-structure-find-import-metadata';
import { OrganizationalStructureFindImportMetadata$Params } from '../fn/organizational-structure/organizational-structure-find-import-metadata';
import { organizationalStructureFindImports } from '../fn/organizational-structure/organizational-structure-find-imports';
import { OrganizationalStructureFindImports$Params } from '../fn/organizational-structure/organizational-structure-find-imports';
import { organizationalStructureGetImportMetadataCounts } from '../fn/organizational-structure/organizational-structure-get-import-metadata-counts';
import { OrganizationalStructureGetImportMetadataCounts$Params } from '../fn/organizational-structure/organizational-structure-get-import-metadata-counts';
import { organizationalStructureGetLastImportMetadataRecord } from '../fn/organizational-structure/organizational-structure-get-last-import-metadata-record';
import { OrganizationalStructureGetLastImportMetadataRecord$Params } from '../fn/organizational-structure/organizational-structure-get-last-import-metadata-record';
import { organizationalStructureGetUnprocessedChanges } from '../fn/organizational-structure/organizational-structure-get-unprocessed-changes';
import { OrganizationalStructureGetUnprocessedChanges$Params } from '../fn/organizational-structure/organizational-structure-get-unprocessed-changes';
import { organizationalStructureIdmImport } from '../fn/organizational-structure/organizational-structure-idm-import';
import { OrganizationalStructureIdmImport$Params } from '../fn/organizational-structure/organizational-structure-idm-import';
import { OrganizationalStructureImportMetadataDto } from '../models/organizational-structure-import-metadata-dto';
import { organizationalStructureImportXml } from '../fn/organizational-structure/organizational-structure-import-xml';
import { OrganizationalStructureImportXml$Params } from '../fn/organizational-structure/organizational-structure-import-xml';
import { organizationalStructureImportXmlFromEos } from '../fn/organizational-structure/organizational-structure-import-xml-from-eos';
import { OrganizationalStructureImportXmlFromEos$Params } from '../fn/organizational-structure/organizational-structure-import-xml-from-eos';
import { organizationalStructureLoadFromEos } from '../fn/organizational-structure/organizational-structure-load-from-eos';
import { OrganizationalStructureLoadFromEos$Params } from '../fn/organizational-structure/organizational-structure-load-from-eos';
import { organizationalStructureLoadFromOrgAdmin } from '../fn/organizational-structure/organizational-structure-load-from-org-admin';
import { OrganizationalStructureLoadFromOrgAdmin$Params } from '../fn/organizational-structure/organizational-structure-load-from-org-admin';
import { organizationalStructureMarkChangesAsProcessed } from '../fn/organizational-structure/organizational-structure-mark-changes-as-processed';
import { OrganizationalStructureMarkChangesAsProcessed$Params } from '../fn/organizational-structure/organizational-structure-mark-changes-as-processed';
import { organizationalStructureSetActualTimestamp } from '../fn/organizational-structure/organizational-structure-set-actual-timestamp';
import { OrganizationalStructureSetActualTimestamp$Params } from '../fn/organizational-structure/organizational-structure-set-actual-timestamp';
import { PageImportMetadataAuditDto } from '../models/page-import-metadata-audit-dto';
import { PageOrganizationalStructureImportMetadataDto } from '../models/page-organizational-structure-import-metadata-dto';


/**
 * Controller with operations over client details
 */
@Injectable({ providedIn: 'root' })
export class ApiOrganizationalStructureService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `organizationalStructureSetActualTimestamp()` */
  static readonly OrganizationalStructureSetActualTimestampPath = '/organizational-structure/set-cache-timestamp';

  /**
   * Sets actual timestamp of cache job
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureSetActualTimestamp()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureSetActualTimestamp$Response(params?: OrganizationalStructureSetActualTimestamp$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return organizationalStructureSetActualTimestamp(this.http, this.rootUrl, params, context);
  }

  /**
   * Sets actual timestamp of cache job
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureSetActualTimestamp$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureSetActualTimestamp(params?: OrganizationalStructureSetActualTimestamp$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.organizationalStructureSetActualTimestamp$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `organizationalStructureMarkChangesAsProcessed()` */
  static readonly OrganizationalStructureMarkChangesAsProcessedPath = '/organizational-structure/mark-changes-as-processed';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureMarkChangesAsProcessed()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureMarkChangesAsProcessed$Response(params: OrganizationalStructureMarkChangesAsProcessed$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return organizationalStructureMarkChangesAsProcessed(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureMarkChangesAsProcessed$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureMarkChangesAsProcessed(params: OrganizationalStructureMarkChangesAsProcessed$Params, context?: HttpContext): Observable<void> {
    return this.organizationalStructureMarkChangesAsProcessed$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `organizationalStructureLoadFromOrgAdmin()` */
  static readonly OrganizationalStructureLoadFromOrgAdminPath = '/organizational-structure/load-orgadmin';

  /**
   * Imports organization structure from Orgadmin
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureLoadFromOrgAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureLoadFromOrgAdmin$Response(params?: OrganizationalStructureLoadFromOrgAdmin$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return organizationalStructureLoadFromOrgAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports organization structure from Orgadmin
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureLoadFromOrgAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureLoadFromOrgAdmin(params?: OrganizationalStructureLoadFromOrgAdmin$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.organizationalStructureLoadFromOrgAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `organizationalStructureLoadFromEos()` */
  static readonly OrganizationalStructureLoadFromEosPath = '/organizational-structure/load-eos';

  /**
   * Imports organization structure from EOS
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureLoadFromEos()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureLoadFromEos$Response(params?: OrganizationalStructureLoadFromEos$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return organizationalStructureLoadFromEos(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports organization structure from EOS
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureLoadFromEos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureLoadFromEos(params?: OrganizationalStructureLoadFromEos$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.organizationalStructureLoadFromEos$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `organizationalStructureImportXml()` */
  static readonly OrganizationalStructureImportXmlPath = '/organizational-structure/import';

  /**
   * Imports organization structure from XML file
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureImportXml()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  organizationalStructureImportXml$Response(params?: OrganizationalStructureImportXml$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return organizationalStructureImportXml(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports organization structure from XML file
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureImportXml$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  organizationalStructureImportXml(params?: OrganizationalStructureImportXml$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.organizationalStructureImportXml$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `organizationalStructureImportXmlFromEos()` */
  static readonly OrganizationalStructureImportXmlFromEosPath = '/organizational-structure/import-eos';

  /**
   * Imports organization structure from XML file from eos
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureImportXmlFromEos()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  organizationalStructureImportXmlFromEos$Response(params?: OrganizationalStructureImportXmlFromEos$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return organizationalStructureImportXmlFromEos(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports organization structure from XML file from eos
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureImportXmlFromEos$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  organizationalStructureImportXmlFromEos(params?: OrganizationalStructureImportXmlFromEos$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.organizationalStructureImportXmlFromEos$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `organizationalStructureIdmImport()` */
  static readonly OrganizationalStructureIdmImportPath = '/organizational-structure/idm-import';

  /**
   * Automatic import from IDM
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureIdmImport()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureIdmImport$Response(params?: OrganizationalStructureIdmImport$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return organizationalStructureIdmImport(this.http, this.rootUrl, params, context);
  }

  /**
   * Automatic import from IDM
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureIdmImport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureIdmImport(params?: OrganizationalStructureIdmImport$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.organizationalStructureIdmImport$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `organizationalStructureFindImportMetadata()` */
  static readonly OrganizationalStructureFindImportMetadataPath = '/organizational-structure/{importId}/import-metadata/search-api';

  /**
   * Finds import metadata based on import id and search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureFindImportMetadata()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureFindImportMetadata$Response(params: OrganizationalStructureFindImportMetadata$Params, context?: HttpContext): Observable<StrictHttpResponse<PageImportMetadataAuditDto>> {
    return organizationalStructureFindImportMetadata(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds import metadata based on import id and search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureFindImportMetadata$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureFindImportMetadata(params: OrganizationalStructureFindImportMetadata$Params, context?: HttpContext): Observable<PageImportMetadataAuditDto> {
    return this.organizationalStructureFindImportMetadata$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageImportMetadataAuditDto>): PageImportMetadataAuditDto => r.body)
    );
  }

  /** Path part for operation `organizationalStructureGetImportMetadataCounts()` */
  static readonly OrganizationalStructureGetImportMetadataCountsPath = '/organizational-structure/{importId}/import-metadata/counts';

  /**
   * Get import metadata counts
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureGetImportMetadataCounts()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureGetImportMetadataCounts$Response(params: OrganizationalStructureGetImportMetadataCounts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ImportMetadataAuditCountDto>>> {
    return organizationalStructureGetImportMetadataCounts(this.http, this.rootUrl, params, context);
  }

  /**
   * Get import metadata counts
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureGetImportMetadataCounts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureGetImportMetadataCounts(params: OrganizationalStructureGetImportMetadataCounts$Params, context?: HttpContext): Observable<Array<ImportMetadataAuditCountDto>> {
    return this.organizationalStructureGetImportMetadataCounts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ImportMetadataAuditCountDto>>): Array<ImportMetadataAuditCountDto> => r.body)
    );
  }

  /** Path part for operation `organizationalStructureGetLastImportMetadataRecord()` */
  static readonly OrganizationalStructureGetLastImportMetadataRecordPath = '/organizational-structure/last-import-metadata-record';

  /**
   * Get last import metadata record
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureGetLastImportMetadataRecord()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureGetLastImportMetadataRecord$Response(params?: OrganizationalStructureGetLastImportMetadataRecord$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OrganizationalStructureImportMetadataDto>> {
    return organizationalStructureGetLastImportMetadataRecord(this.http, this.rootUrl, params, context);
  }

  /**
   * Get last import metadata record
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureGetLastImportMetadataRecord$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureGetLastImportMetadataRecord(params?: OrganizationalStructureGetLastImportMetadataRecord$Params|null|undefined, context?: HttpContext): Observable<OrganizationalStructureImportMetadataDto> {
    return this.organizationalStructureGetLastImportMetadataRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationalStructureImportMetadataDto>): OrganizationalStructureImportMetadataDto => r.body)
    );
  }

  /** Path part for operation `organizationalStructureFindImports()` */
  static readonly OrganizationalStructureFindImportsPath = '/organizational-structure/import/search-api';

  /**
   * Finds functional positions OrganizationalStructureImportMetadataDto on search request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureFindImports()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureFindImports$Response(params?: OrganizationalStructureFindImports$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageOrganizationalStructureImportMetadataDto>> {
    return organizationalStructureFindImports(this.http, this.rootUrl, params, context);
  }

  /**
   * Finds functional positions OrganizationalStructureImportMetadataDto on search request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureFindImports$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureFindImports(params?: OrganizationalStructureFindImports$Params|null|undefined, context?: HttpContext): Observable<PageOrganizationalStructureImportMetadataDto> {
    return this.organizationalStructureFindImports$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageOrganizationalStructureImportMetadataDto>): PageOrganizationalStructureImportMetadataDto => r.body)
    );
  }

  /** Path part for operation `organizationalStructureGetUnprocessedChanges()` */
  static readonly OrganizationalStructureGetUnprocessedChangesPath = '/organizational-structure/get-unprocessed-changes';

  /**
   * Get unprocessed changes
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureGetUnprocessedChanges()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureGetUnprocessedChanges$Response(params?: OrganizationalStructureGetUnprocessedChanges$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OrganizationalStructureChangeDto>> {
    return organizationalStructureGetUnprocessedChanges(this.http, this.rootUrl, params, context);
  }

  /**
   * Get unprocessed changes
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureGetUnprocessedChanges$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureGetUnprocessedChanges(params?: OrganizationalStructureGetUnprocessedChanges$Params|null|undefined, context?: HttpContext): Observable<OrganizationalStructureChangeDto> {
    return this.organizationalStructureGetUnprocessedChanges$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationalStructureChangeDto>): OrganizationalStructureChangeDto => r.body)
    );
  }

  /** Path part for operation `organizationalStructureDownloadXmlFromEos()` */
  static readonly OrganizationalStructureDownloadXmlFromEosPath = '/organizational-structure/download-xml-eos';

  /**
   * Imports organization structure from EOS
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `organizationalStructureDownloadXmlFromEos()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureDownloadXmlFromEos$Response(params?: OrganizationalStructureDownloadXmlFromEos$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<string>>> {
    return organizationalStructureDownloadXmlFromEos(this.http, this.rootUrl, params, context);
  }

  /**
   * Imports organization structure from EOS
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `organizationalStructureDownloadXmlFromEos$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  organizationalStructureDownloadXmlFromEos(params?: OrganizationalStructureDownloadXmlFromEos$Params|null|undefined, context?: HttpContext): Observable<Array<string>> {
    return this.organizationalStructureDownloadXmlFromEos$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<string>>): Array<string> => r.body)
    );
  }

}
