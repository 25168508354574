import {ChangeDetectionStrategy, Component, Input,} from '@angular/core';
import {IczFormGroup} from '@icz/angular-form-elements';


@Component({
  selector: 'icz-consignment-posting-number',
  templateUrl: './consignment-posting-number.component.html',
  styleUrls: ['./consignment-posting-number.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentPostingNumberComponent {

  @Input({required: true}) form!: IczFormGroup;

}
