@if (mode === StatisticDashboardMode.DASHBOARD) {
  <div class="w-full" [waiting]="loadingIndicatorService.isLoading(this)">
    <div class="row mb-32">
      <icz-button svgIcon="dashboard" label="Nastavení statistické nástěnky" (onAction)="openSettings()"></icz-button>
    </div>
    <div class="row grow" cdkDropListGroup>
      <div cdkDropList
           id="dropList_0"
           #dropList0="cdkDropList"
           [cdkDropListConnectedTo]="[dropList1, dropList2]"
           [cdkDropListData]="statisticTrendConfigsColumnGrid[0]"
           class="statistics-counter-drop-list"
           cdkDropListOrientation="vertical"
           (cdkDropListDropped)="itemDropped($event)">
        @for (widget of statisticTrendConfigsColumnGrid[0]; track widget.id; let i = $index) {
          <!-- duplication of this content is intentional, cdkDropList and cdkDrag must be inlined otherwise drag&drop will not work properly -->
          <div cdkDrag [cdkDragData]="widget" [ngClass]="{'statistic-counter-size': isStatisticTrendNormal(widget.id), 'statistic-counter-size-big': isStatisticTrendExtended(widget.id)}" [hidden]="!getStatisticConfig(widget.id)!.displayed">
            <div class="statistic-counter-placeholder" *cdkDragPlaceholder></div>
            <icz-statistic-trend-counter
              class="block"
              [config]="getStatisticConfig(widget.id)!"
              (goToDetailClicked)="goToDetail($event)"
              [statisticCountMessage$]="statisticsCountMessage$"
            ></icz-statistic-trend-counter>
          </div>
        }
      </div>
      <div cdkDropList
           id="dropList_1"
           [cdkDropListConnectedTo]="[dropList0, dropList2]"
           [cdkDropListData]="statisticTrendConfigsColumnGrid[1]"
           #dropList1="cdkDropList"
           class="statistics-counter-drop-list"
           cdkDropListOrientation="vertical"
           (cdkDropListDropped)="itemDropped($event)">
        @for (widget of statisticTrendConfigsColumnGrid[1]; track widget.id; let i = $index) {
          <div cdkDrag [cdkDragData]="widget" [ngClass]="{'statistic-counter-size': isStatisticTrendNormal(widget.id), 'statistic-counter-size-big': isStatisticTrendExtended(widget.id)}" [hidden]="!getStatisticConfig(widget.id)!.displayed">
            <div class="statistic-counter-placeholder" *cdkDragPlaceholder></div>
            <icz-statistic-trend-counter
              class="block"
              [config]="getStatisticConfig(widget.id)!"
              (goToDetailClicked)="goToDetail($event)"
              [statisticCountMessage$]="statisticsCountMessage$"
            ></icz-statistic-trend-counter>
          </div>
        }
      </div>
      <div cdkDropList
           id="dropList_2"
           [cdkDropListConnectedTo]="[dropList0, dropList1]"
           [cdkDropListData]="statisticTrendConfigsColumnGrid[2]"
           #dropList2="cdkDropList"
           class="statistics-counter-drop-list"
           cdkDropListOrientation="vertical"
           (cdkDropListDropped)="itemDropped($event)">
        @for (widget of statisticTrendConfigsColumnGrid[2]; track widget.id; let i = $index) {
          <div cdkDrag [cdkDragData]="widget" [ngClass]="{'statistic-counter-size': isStatisticTrendNormal(widget.id), 'statistic-counter-size-big': isStatisticTrendExtended(widget.id)}" [hidden]="!getStatisticConfig(widget.id)!.displayed">
            <div class="statistic-counter-placeholder" *cdkDragPlaceholder></div>
            <icz-statistic-trend-counter
              class="block"
              [config]="getStatisticConfig(widget.id)!"
              (goToDetailClicked)="goToDetail($event)"
              [statisticCountMessage$]="statisticsCountMessage$"
            ></icz-statistic-trend-counter>
          </div>
        }
      </div>
    </div>
  </div>
}
@if (mode === StatisticDashboardMode.DETAIL) {
  <div class="col grow">
    @if (getStatisticConfig(selectedStatistics!); as config) {
      <icz-statistics-table
        class="col grow"
        [tableTitle]="getTableTitle(selectedStatistics!)"
        [statisticConfig]="config"
        [allowedDimensions]="config.dimensions"
        [period]="config.period"
      >
        <icz-button
          svgIcon="chevron_left" rounded
          iczTooltip="Zpět na přehled statistik"
          (onAction)="goToDashboard()"
        ></icz-button>
      </icz-statistics-table>
    }
  </div>
}
