import {ChangeDetectionStrategy, Component, inject, Input, OnInit} from '@angular/core';
import {ConsignmentType} from '|api/commons';
import {GenericOwnElasticConsignmentWithConsignee} from '../../model/own-consignment-model';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {namedDtosToOptions} from '../../../../../core/services/data-mapping.utils';
import {IczOption} from '@icz/angular-form-elements';


@Component({
  selector: 'icz-consignment-delivery-result',
  templateUrl: './consignment-delivery-result.component.html',
  styleUrls: ['./consignment-delivery-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentDeliveryResultComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);

  @Input({required: true})
  consignment!: GenericOwnElasticConsignmentWithConsignee;

  isProofOfDeliveryPopupOpen = false;
  deliveryResultOptions: IczOption[] = [];

  readonly ConsignmentType = ConsignmentType;

  ngOnInit(): void {
    const consignmentId = this.consignment?.id;

    if (consignmentId) {
      this.loadingService.doLoading(
        this.codebookService.deliveryResults().pipe(namedDtosToOptions),
        this
      ).subscribe(deliveryResultOptions => {
        this.deliveryResultOptions = deliveryResultOptions;
      });
    }
  }
}
