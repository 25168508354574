import {SelectionModel} from '@angular/cdk/collections';
import {ChangeDetectorRef, DestroyRef, inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {PrimitiveControlValueType} from '@icz/angular-form-elements';
import {IczTableDataSource} from './table.datasource';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

/**
 * @internal
 */
export type TableRow = {
  id: any;
};

/**
 * A function which generates row key for the purpose of checkbox selection/deselection and focused rows use-case.
 */
export type RowKeyFn<TRow, TKey> = (a: TRow) => NonNullable<TKey>;

/**
 * An algorithm which compares two table row keys.
 */
export function areTableRowKeysEqual(a: unknown, b: unknown): boolean {
  // eslint-disable-next-line eqeqeq -- when we deserialize query parameters, they will arrive as string and we might need to gracefully compare them with numbers from table datasource
  return a == b;
}

/**
 * An algorithm which compares two table rows.
 */
export function areTableRowsEqual(rowKeyFn: RowKeyFn<TableRow, unknown>, a: TableRow, b: TableRow): boolean {
  return areTableRowKeysEqual(rowKeyFn(a), rowKeyFn(b));
}

/**
 * A predicate which signifies whether the supplied table record should be disabled in some context.
 */
export type RowDisableFn<T extends Record<string, any> = Record<string, any>> = (a: T) => boolean;

/**
 * @internal
 */
class IczSelectionModel extends SelectionModel<TableRow> {
  constructor(
    private rowKeyFn: RowKeyFn<TableRow, unknown>,
  ) {
    super(true, []);
  }

  override select = (row: TableRow) => {
    if (this.isSelected(row)) return;
    this.selected.push(row);
  };

  override deselect = (rows: TableRow | TableRow[]) => {
    const rowIndex = (row: any) => this.selected.findIndex(item => areTableRowsEqual(this.rowKeyFn, item, row));

    if (Array.isArray(rows)) {
      rows.forEach(row => {
        if (rowIndex(row) === -1) return; // nothing to deselect
        this.selected.splice(rowIndex(row), 1);
      });
      return;
    }

    if (rowIndex(rows) === -1) return; // nothing to deselect
    this.selected.splice(rowIndex(rows), 1);
  };

  override isSelected(row: TableRow) {
    if (!row) return false;
    else {
      return this.selected.some(item => this.rowKeyFn(item) === this.rowKeyFn(row));
    }
  }

  override hasValue(): boolean {
    return Boolean(this.selected.length);
  }
}

/**
 * @internal
 */
@Injectable()
export class SelectedRowsService {

  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  dataSource!: IczTableDataSource<any>;
  selection!: IczSelectionModel;
  activeRow$!: Observable<Nullable<TableRow>>;
  selectedRows$!: Observable<TableRow[]>;

  private rowKeyFn!: RowKeyFn<TableRow, unknown>;
  private rowSelectionDisabler!: RowDisableFn;
  private _activeRow$!: BehaviorSubject<Nullable<TableRow>>;
  private _selectedRows$!: BehaviorSubject<TableRow[]>;

  isInitialized = false;

  masterToggle() {
    if (this.isAllSelectedOrDisabled()) {
      this.deselectAll();
    } else {
      this.selectAllOnPage();
    }
    this.emitSelectedRows();
    this.cd.detectChanges();
  }

  isAllSelectedOrDisabled() {
    if (this.isInitialized) {
      if (!this.dataSource.data.length) return false;
      const thisPageIds = this.dataSource.data.map(d => d.id);
      const selectionIds = this.selection.selected.map(s => s.id);
      const disabledIds: any[] = [];
      this.dataSource.data.map(r => {
        if (this.isSelectionDisabled(r)) disabledIds.push(r.id);
      });
      if(disabledIds.length === thisPageIds.length) {
        return false;
      } else {
        return thisPageIds.every(id => [...selectionIds, ...disabledIds].includes(id));
      }
    } else {
      return false;
    }
  }

  isIndeterminate() {
    if (this.isAllSelectedOrDisabled()) return false;
    const thisPageIds = this.dataSource.data.map(d => d.id);
    const selectionIds = this.selection.selected.map(s => s.id);
    return [...selectionIds].some(s => thisPageIds.includes(s));
  }

  isAllOnPageSelected() {
    if (!this.dataSource.data.length) return false;
    const numSelected = this.selection.selected.length;
    const pageCount = this.dataSource.data.length;
    return numSelected === pageCount;
  }

  rowSelectionChanged(checked: PrimitiveControlValueType, row: TableRow) {
    if (checked) {
      this.selection.select(row);
    } else {
      this.selection.deselect(row);
    }
    this.emitSelectedRows();
  }

  selectMany(rows: Array<TableRow>, ignoreDisabled = false) {
    rows.forEach(row => {
      if (ignoreDisabled || !this.isSelectionDisabled(row)) {
        this.selection.select(row);
      }
    });
    this.emitSelectedRows();
  }

  deselectMany(rows: Array<TableRow>) {
    rows.forEach(row => {
      this.selection.deselect(row);
    });
  }

  deselectAll() {
    if (this.isInitialized) {
      this.dataSource.data.forEach(row => {
        if (!this.isSelectionDisabled(row)) {
          this.selection.deselect(row);
        }
      });
      this.emitSelectedRows();
    }
  }

  init(
    dataSource: IczTableDataSource<any>,
    rowKeyFn: RowKeyFn<TableRow, unknown>,
    rowSelectionDisablingChanges$: Observable<Nullable<RowDisableFn>>
  ) {
    this.rowKeyFn = rowKeyFn;

    this.selection = new IczSelectionModel(this.rowKeyFn);
    this._activeRow$ = new BehaviorSubject<any>(null);
    this.activeRow$ = this._activeRow$.asObservable();
    this._selectedRows$ = new BehaviorSubject<any>([]);
    this.selectedRows$ = this._selectedRows$.asObservable();
    this.isInitialized = true;

    this.dataSource = dataSource;
    this.dataSource.unselectAll$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(_ => this.deselectAll());

    rowSelectionDisablingChanges$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(rowSelectionDisabler => {
      this.rowSelectionDisabler = rowSelectionDisabler ?? (_ => false);
    });
  }

  isSelected(row: TableRow) {
    return this.selection.isSelected(row);
  }

  isSelectionDisabled(row: TableRow) {
    return this.rowSelectionDisabler(row);
  }

  isActive(row: TableRow) {
    return this._activeRow$.value === row;
  }

  toggleActiveRow(row: TableRow) {
    const currentlyActive = this._activeRow$.getValue();
    if (currentlyActive && areTableRowsEqual(this.rowKeyFn, currentlyActive, row)) this.unsetActiveRow();
    else this._activeRow$.next(row);
  }

  unsetActiveRow() {
    this._activeRow$.next(null);
  }

  private emitSelectedRows() {
    const selectedRowsArray = this.selection.selected;
    this._selectedRows$.next([...selectedRowsArray]);
  }

  private selectAllOnPage() {
    this.dataSource.data.forEach(row => {
      if (!this.isSelectionDisabled(row)) {
        this.selection.select(row);
      }
    });
  }

}
