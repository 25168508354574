/* tslint:disable */
/* eslint-disable */

/**
 * Volume Lifespan
 */
export enum CaseFileVolumeLifespan {
  YEAR = 'YEAR',
  CALENDAR_YEAR = 'CALENDAR_YEAR',
  SCHOOL_YEAR = 'SCHOOL_YEAR',
  CALENDAR_MONTH = 'CALENDAR_MONTH',
  DAY = 'DAY'
}
