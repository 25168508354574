/* tslint:disable */
/* eslint-disable */

/**
 * Font size
 */
export enum FontSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  PT_9 = 'PT9',
  PT_11 = 'PT11',
  PT_13 = 'PT13',
  PT_15 = 'PT15',
  PT_17 = 'PT17'
}
