import {inject, Injectable} from '@angular/core';
import {
  GeneralAuthorizationResult,
  GeneralAuthorizedOperation,
  GeneralAuthorizedOperationPermitStatus,
  getAuthorizedOperationsByEntityTypes,
} from '../components/shared-business-components/permissions/permissions.utils';
import {ApiAuthorizationService, AuthorizedEntityType} from '|api/core';
import {map, Observable, of, switchMap} from 'rxjs';
import {AnyComponent, InterpolationContext, LoadingIndicatorService} from '@icz/angular-essentials';
import {IczModalService} from '@icz/angular-modal';
import {
  BulkOperationValidationDialogComponent,
  BulkOperationValidationDialogData,
  ValidationInfo,
} from '../components/shared-business-components/bulk-operation-validation-dialog/bulk-operation-validation-dialog.component';
import {TranslateService} from '@ngx-translate/core';

export function ValidatorGuard() {
  return (target: any, propertyKey: string | symbol, propertyDescriptor: TypedPropertyDescriptor<any>) => {
    const untransformedDisablerFnFactory = propertyDescriptor.value!;

    propertyDescriptor.value = () => {
      const disablerFn = untransformedDisablerFnFactory();
      disablerFn.isBlockingValidation = true;
      return disablerFn;
    };
  };
}

export interface ValidationResultWithTooltip {
  tooltip?: string;
  validationMessage?: string;
  validationMessageContext?: {[k: string]: string}
}

export interface OperationValidator<T> {
  (operationEntity: T): Nullable<ValidationResultWithTooltip>;
  // function object metadata used in process of evaluating the validation
  isBlockingValidation?: boolean;
}

export interface EsslObjectForValidation<T> {
  entityId: number;
  authorizedEntityType: Nullable<AuthorizedEntityType>,
  entityName: string;
  entityIcon: string;
  entityData: T;
}

export interface BulkOperationValidationData<T> {
  esslObjects: EsslObjectForValidation<T>[];
  authorizedOperations?: GeneralAuthorizedOperation[];
  operationValidators: OperationValidator<T>[];
  dialogTitle?: string;
  dialogTitleContext?: Nullable<InterpolationContext>;
  dialogWarningLabel: string;
  dialogWarningLabelContext: Nullable<InterpolationContext>;
}

interface ValidationResult<T> {
  validationResultToDisplay: ValidationInfo[];
  correctObjectsList: T[];
}

@Injectable({
  providedIn: 'root'
})
export class BulkOperationValidationService {

  private loadingService = inject(LoadingIndicatorService);
  private modalService = inject(IczModalService);
  private translateService = inject(TranslateService);
  private apiAuthorizationService = inject(ApiAuthorizationService);

  validateEsslObjects<T>(
    validationData: BulkOperationValidationData<T>,
    loadingContext: AnyComponent,
  ): Observable<T[]> {
    this.loadingService.startLoading(loadingContext);

    if (validationData.esslObjects.length > 1) {
      if (validationData.authorizedOperations && validationData.authorizedOperations.length > 0) {
        const entitiesToAuthorize: Partial<Record<AuthorizedEntityType, number[]>> = {};
        validationData.esslObjects.forEach(obj => {
          if (obj.authorizedEntityType) {
            if (isNil(entitiesToAuthorize[obj.authorizedEntityType])) {
              entitiesToAuthorize[obj.authorizedEntityType] = [obj.entityId];
            }
            else {
              entitiesToAuthorize[obj.authorizedEntityType]!.push(obj.entityId);
            }
          }
        });

        return this.fetchBulkAuthorizedOperationPermissions(entitiesToAuthorize, validationData.authorizedOperations).pipe(switchMap(
          authorizationResult => {
            return this.processValidationResult<T>(
              this.validate<T>(validationData.esslObjects, validationData.operationValidators, authorizationResult),
              validationData.dialogTitle!,
              validationData.dialogTitleContext,
              validationData.dialogWarningLabel ?? 'Nebylo možné hromadně zpracovat {{errorCount}} objektů. Hromadná akce bude provedena jen s vyhovujícími objekty ({{successCount}})',
              validationData.dialogWarningLabelContext,
              loadingContext,
            );
          }
        ));
      }
      else {
        return this.processValidationResult<T>(
          this.validate<T>(validationData.esslObjects, validationData.operationValidators),
          validationData.dialogTitle!,
          validationData.dialogTitleContext,
          validationData.dialogWarningLabel ?? 'Nebylo možné hromadně zpracovat {{errorCount}} objektů. Hromadná akce bude provedena jen s vyhovujícími objekty ({{successCount}}).',
          validationData.dialogWarningLabelContext,
          loadingContext,
        );
      }
    }
    else {
      return of(
        validationData.esslObjects.map(ob => ob.entityData)
      );
    }
  }

  private processValidationResult<T>(
    validationResult: ValidationResult<T>,
    dialogTitle: string,
    dialogTitleContext: Nullable<InterpolationContext>,
    dialogWarningLabel: string,
    dialogWarningLabelContext: Nullable<InterpolationContext>,
    loadingContext: AnyComponent,
  ): Observable<T[]> {
    this.loadingService.endLoading(loadingContext);

    if (validationResult.validationResultToDisplay && validationResult.validationResultToDisplay.length) {
      if (dialogWarningLabelContext) {
        dialogWarningLabelContext['errorCount'] = validationResult.validationResultToDisplay.length;
        dialogWarningLabelContext['successCount'] = validationResult.correctObjectsList.length;
      }
      else {
        dialogWarningLabelContext = {
          count: validationResult.validationResultToDisplay.length
        };
      }

      return this.modalService.openComponentInModal<T[], BulkOperationValidationDialogData>({
        component: BulkOperationValidationDialogComponent,
        modalOptions: {
          width: 1100,
          height: 700,
          titleTemplate: dialogTitle ?? 'Hromadná operace',
          titleTemplateContext: dialogTitleContext ?? {},
        },
        data: {
          correctObjectsList: validationResult.correctObjectsList,
          validationResultToDisplay: validationResult.validationResultToDisplay,
          dialogWarningLabel,
          dialogWarningLabelContext
        }
      });
    }
    else {
      return of(validationResult.correctObjectsList);
    }
  }

  private validate<T>(esslObjects: EsslObjectForValidation<T>[], validators: OperationValidator<T>[], authorizationResults?: Map<string, GeneralAuthorizedOperationPermitStatus[]>): ValidationResult<T> {
    const validationResultToDisplay: ValidationInfo[] = [];
    const correctObjectsList: any[] = [];
    if (isNil(esslObjects)) {
      return {
        correctObjectsList: [],
        validationResultToDisplay: []
      };
    }

    esslObjects.forEach(obj => {
      const objResult: ValidationInfo = {
        entityId: obj.entityId,
        esslObjectIcon: obj.entityIcon,
        authorizedEntityType: obj.authorizedEntityType,
        esslObjectLabel: obj.entityName,
        validationErrors: [],
      };

      for (const validator of validators) {
        const result = validator(obj.entityData);
        if (!isNil(result)) {
          objResult.validationErrors.push({validationMessage: result.validationMessage, validationMessageContext: result.validationMessageContext});

          if (validator.isBlockingValidation) {
            break;
          }
        }
      }

      if (authorizationResults && obj.authorizedEntityType) {
        const entityCode = obj.authorizedEntityType.concat(String(obj.entityId));
        if(authorizationResults.has(entityCode)) {
          const authorizedOperationResult = authorizationResults.get(entityCode);
          authorizedOperationResult!.forEach(res => {
            if (!res.userAuthorized) {
              const permissionNotGrantedReason = res.permissionsNotGranted[0]?.reason;
              if (permissionNotGrantedReason) {
                objResult.validationErrors.push({validationMessage: this.translateService.instant(`en.authorizationBusinessReason.${permissionNotGrantedReason}`)});
              }
            }
          });
        }
      }

      if (objResult.validationErrors.length > 0) {
        validationResultToDisplay.push(objResult);
      } else {
        correctObjectsList.push(obj.entityData);
      }
    });

    return {
      validationResultToDisplay,
      correctObjectsList,
    };
  }

  private fetchBulkAuthorizedOperationPermissions(
    authorizedEntityIds: Partial<Record<AuthorizedEntityType, number[]>>,
    authorizedOperationsToFetch: GeneralAuthorizedOperation[],
  ): Observable<Map<string, GeneralAuthorizedOperationPermitStatus[]>> {
    const authorizedOperationsByEntityType = getAuthorizedOperationsByEntityTypes(authorizedOperationsToFetch);

    const authorizedOperationsToRequest: any[] = [];
    (Object.keys(authorizedEntityIds) as AuthorizedEntityType[]).forEach((entityType: AuthorizedEntityType) => {
      authorizedEntityIds[entityType]?.forEach(entityId => {
        authorizedOperationsToRequest.push({
          authorizedEntityId: entityId,
          authorizedEntityType: entityType,
          operationsToAuthorize: authorizedOperationsByEntityType[entityType]
        });
      });
    });

    if (authorizedOperationsToRequest.length === 0) {
      return of(new Map());
    } else {
      return this.apiAuthorizationService.authorizationAuthorizeOperations({
        body: authorizedOperationsToRequest
      }).pipe(
        map((authorizationResults: GeneralAuthorizationResult[]) => {
          const authorizationByEssLObject = new Map<string, GeneralAuthorizedOperationPermitStatus[]>();

          authorizationResults.forEach(result => {
            authorizationByEssLObject.set(result.authorizedEntityType.concat(String(result.authorizedEntityId)), result.authorizedOperations);
          });
          return authorizationByEssLObject;
        }),
      );
    }
  }

}
