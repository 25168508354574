/* eslint-disable @typescript-eslint/ban-types */
import {Injectable} from '@angular/core';
import {DialogPosition, MatDialogRef, MatDialogState} from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {ComponentModalComponent} from './component-modal/component-modal.component';

/**
 * @internal
 */
export type ModalTitleOrContext = (
  {title: string, titleContext?: Record<string, string>} |
  {title?: string, titleContext: Record<string, string>}
);

/**
 * @internal
 */
function setModalTitle(this: IczModalRef<unknown>, title: ModalTitleOrContext): void {
  if (title.title) {
    this.componentInstance.dialogData.modalOptions.titleTemplate = title.title;
  }
  if (title.titleContext) {
    this.componentInstance.dialogData.modalOptions.titleTemplateContext = title.titleContext;
  }
}

/**
 * @internal
 */
function toggleMaximize(this: IczModalRef<unknown>): void {
  if (!this.isMaximized) {
    this.updateSize('100vw', '100vh');
    this.isMaximized = true;
  }
  else {
    this.updateSize(this.originalWidth, this.originalHeight);
    this.isMaximized = false;
  }
}

/**
 * An extension of MatDialogRef with extra ICZ functionalities.
 * ---
 * Implementation remarks:
 * - it is an abstract class because I didn't want to inject raw modal ref token using @Inject decorator.
 * - abstract methods `forceClose`, `setModalTitle`, `isMaximized` and `toggleMaximize` are populated inside `iczModalRefFactory`.
 * - the rest of abstract methods is in fact implemented in `MatDialogRef`, however I need them here otherwise Angular compiler starts complaining.
 */
@Injectable()
export abstract class IczModalRef<R> {

  /**
   * @internal
   */
  abstract originalWidth: string;
  /**
   * @internal
   */
  abstract originalHeight: string;

  /**
   * A flag indicating if the modal is in maximized state
   */
  abstract isMaximized: boolean;

  /*
   * A reserved function which guarrantees that the modal will be immediately closed.
   * ---
   * CheckUnsavedFormDialogService will override IczModalRef#close with its own
   * decorated version which will ask for saving changes. That is undesirable in
   * case we need to force-close our modals on various critical events in the application.
   */
  abstract forceClose(modalResult?: R | undefined): void;

  /**
   * Used when modal components want to change modal title from the inside. Useful for lazy loading etc.
   * @param title
   */
  abstract setModalTitle(title: ModalTitleOrContext): void;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract addPanelClass(classes: string | string[]): this;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract afterClosed(): Observable<R | undefined>;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract afterOpened(): Observable<void>;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract backdropClick(): Observable<MouseEvent>;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract beforeClosed(): Observable<R | undefined>;

  /**
   * Will close the modal and send dialogResult to IczModalService.openComponentInModal observable.
   * Some consuments may deliberately override the behavior of close for exmple for blocking modal closing.
   */
  abstract close(dialogResult?: R | undefined): void;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract componentInstance: ComponentModalComponent<unknown>;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract disableClose: boolean | undefined;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract getState(): MatDialogState;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract id: string;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract keydownEvents(): Observable<KeyboardEvent>;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract removePanelClass(classes: string | string[]): this;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract updatePosition(position: DialogPosition | undefined): this;

  /**
   * See MatDialogRef documentation at Angular Material webpage.
   */
  abstract updateSize(width: string | undefined, height: string | undefined): this;

  /**
   * Will toggle maximization state. Note that every modal window is not maximized after opening.
   */
  abstract toggleMaximize(): void;
}

/**
 * @internal
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- here we need to work with the original
export function iczModalRefFactory(modalRef: MatDialogRef<unknown, unknown>) {
  const castedModalRef = modalRef as unknown as IczModalRef<unknown>;

  castedModalRef.forceClose = modalRef.close;

  castedModalRef.setModalTitle = setModalTitle.bind(castedModalRef);

  castedModalRef.isMaximized = false;
  castedModalRef.toggleMaximize = toggleMaximize.bind(castedModalRef);

  return modalRef;
}
