import {dataToPage, IczTableDataSource} from '@icz/angular-table';
import {of} from 'rxjs';

type StatisticsTableDataFactory = () => any[];

/**
 * A custom implementation of datasource for statistics table derived from IczInMemoryDatasource
 */
export class StatisticsTableDatasource extends IczTableDataSource<any> {
  /**
   * @param tableDataFactory a function with no parameters which returns an array of data.
   *                in case of data mutations, please call StatisticsTableDatasource#reload().
   */
  constructor(protected tableDataFactory: StatisticsTableDataFactory) {
    super(searchParams => {
      const {page, size} = searchParams;

      return of(this.tableDataFactory()).pipe(
        dataToPage<any>(page, size)
      );
    });
  }

  /**
   * Changes tableDataFactory and performs an automatic data reload
   */
  setDataFactory(factory: StatisticsTableDataFactory) {
    this.tableDataFactory = factory;
    this.reload(true);
  }
}
