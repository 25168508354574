import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * A layouting component with additional styling used for rendering a toolbar at the bottom of each application form.
 * has [leftButtons] and [rightButtons] content slots. It is recommended that "positive actions" are in the [leftButtons] slot
 * while "negative" or "cancel" actions are in the [rightButtons] slot.
 */
@Component({
  selector: 'icz-form-buttons',
  templateUrl: './form-buttons.component.html',
  styleUrls: ['./form-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class FormButtonsComponent {}
