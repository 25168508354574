/**
 * A component type with no specific shape.
 * Used merely for marking places where any component type is expected.
 */
export interface AnyComponent {}

/**
 * Mustache expressions interpolation context for InterplatePipe.
 */
export type InterpolationContext = Record<string, any>;

/**
 * A string with associated interpolation context.
 * @deprecated - conventions for component interfaces have changed, instead label and context should be in separate @Inputs.
 */
export interface ContextRenderableString {
  /**
   * String, optionally containing mustache-like expressions.
   */
  label: string;
  /**
   * Interpolation context for the label.
   */
  context?: InterpolationContext;
}

/**
 * Produces a hash of any JS value containing a stringified number.
 */
// eslint-disable-next-line @typescript-eslint/ban-types -- accepts generic object
export function hashed(obj: Nullable<object | string | number | boolean>): string {
  const str = JSON.stringify(obj === undefined ? '___undEfinEd__' : obj);
  let hash = 0;
  let i;
  let chr;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = ((hash << 5) - hash) + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash ? hash.toString(10) : '';
}

/**
 * Produces a random integer from range <min, max>, inclusive.
 */
export function randomInteger(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Makes a supplied object deep-frozen, i.e. object properties will not be changeable.
 * Be careful about deep-freezong objects as nested objects might be used elsewhere where non-frozen objects are desired.
 */
export function deepFreeze(object: Record<string, any>) {
  const propNames = Object.getOwnPropertyNames(object);

  for (const name of propNames) {
    const value = object[name];

    if (value && typeof value === 'object') {
      deepFreeze(value);
    }
  }

  return Object.freeze(object);
}

/**
 * Removes duplicates from an array based on computed item keys from comparator result.
 */
export function removeDuplicates<T>(arr: T[], comparator: (arg0: T) => any = (x: T) => x): T[] {
  return arr.filter((item, i) => {
    const comparedValue = comparator(item);
    const itemIndex = arr.findIndex(val => Object.is(comparator(val), comparedValue));

    return itemIndex === i; // evaluates to true on second and higher occurrence
  });
}
