import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {EssentialsModule} from '@icz/angular-essentials';
import {DashboardGaugeCounterComponent} from './dashboard-gauge/dashboard-gauge-counter.component';
import {DashboardIconCounterComponent} from './dashboard-icon-counter/dashboard-icon-counter.component';
import {
  DashboardSubstitutionsCounterComponent
} from './dashboard-substitutions-counter/dashboard-substitutions-counter.component';
import {
  DashboardFlowActivitiesPreviewComponent
} from './dashboard-flow-activities-preview/dashboard-flow-activities-preview.component';
import {FormElementsModule} from '@icz/angular-form-elements';
import {DashboardFilingDispatchComponent} from './dashboard-filing-dispatch/dashboard-filing-dispatch.component';
import {DashboardOfficerComponent} from './dashboard-officer/dashboard-officer.component';
import {DashboardSettingsDialogComponent} from './dashboard-settings/dashboard-settings-dialog.component';
import {DashboardOrgUnitComponent} from './dashboard-org-unit/dashboard-org-unit.component';
import {SharedBusinessComponentsModule} from '../shared-business-components/shared-business-components.module';
import {DashboardRegistryComponent} from './dashboard-registry/dashboard-registry.component';
import {
  DashboardUnreadNotificationsCounterComponent
} from './dashboard-unread-notifications-counter/dashboard-unread-notifications-counter.component';
import {LibraryImportModule} from '../.module-library-import/library-import.module';
import {IczFormDirectivesModule} from '../icz-form-directives/icz-form-directives.module';


@NgModule({
    declarations: [
      DashboardGaugeCounterComponent,
      DashboardIconCounterComponent,
      DashboardSubstitutionsCounterComponent,
      DashboardFlowActivitiesPreviewComponent,
      DashboardOfficerComponent,
      DashboardFilingDispatchComponent,
      DashboardSettingsDialogComponent,
      DashboardOrgUnitComponent,
      DashboardRegistryComponent,
    DashboardUnreadNotificationsCounterComponent,],
    exports: [
      DashboardGaugeCounterComponent,
      DashboardIconCounterComponent,
      DashboardSubstitutionsCounterComponent,
      DashboardFlowActivitiesPreviewComponent,
      DashboardOfficerComponent,
      DashboardFilingDispatchComponent,
      DashboardSettingsDialogComponent,
      DashboardOrgUnitComponent,
      DashboardRegistryComponent,
    ],
    imports: [
        CommonModule,
        EssentialsModule,
        FormElementsModule,
        SharedBusinessComponentsModule,
        LibraryImportModule,
      IczFormDirectivesModule
    ]
})
export class DashboardsModule { }
