import {InterpolationContext} from '@icz/angular-essentials';

export enum DialogSeverity {
  /**
   * A red exclamation mark triangle.
   */
  ERROR = 'ERROR',
  /**
   * An orange exclamation mark triangle.
   */
  WARNING = 'WARNING',
  /**
   * A blue info sign.
   */
  INFO = 'INFO',
}

export enum DialogMode {
  /**
   * All lines will have normal formatting.
   */
  SIMPLE = 'SIMPLE',
  /**
   * The first line will have bold text formatting; the rest will be normal.
   */
  ACCENTED = 'ACCENTED'
}

export interface DialogItem {
  /**
   * Dialog line text content. May contain mustache-like expressions.
   */
  text: string;
  /**
   * Dialog line interpolation context for mustache-like expressions.
   */
  context?: InterpolationContext;
}

export interface DialogWithTitleData extends SimpleDialogData {
  /**
   * Dialog title. May contain mustache-like expressions.
   */
  title: string;
  /**
   * Dialog title interpolation context for mustache-like expressions.
   */
  titleContext?: InterpolationContext;
}

export interface SimpleDialogData {
  /**
   * Controls dialog icon which is rendered above its content.
   */
  severity?: DialogSeverity;
  /**
   * Dialog content is defined line-by-line.
   */
  content: DialogItem[];
  /**
   * Affects dialog content text formatting
   */
  mode?: DialogMode;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
  showLeftButton?: boolean;
  showRightButton?: boolean;
}

/**
 * Question dialog content definition.
 */
export interface QuestionDialogData {
  /**
   * Dialog title. May contain mustache-like expressions.
   */
  title: string;
  /**
   * Dialog title interpolation context for mustache-like expressions.
   */
  titleContext?: InterpolationContext;
  /**
   * Controls dialog icon which is rendered above its content.
   */
  severity?: DialogSeverity;
  /**
   * Question text - the first bold line of dialog contents. May contain mustache-like expressions.
   */
  question: string;
  /**
   * Dialog question text interpolation context for mustache-like expressions.
   */
  questionContext?: InterpolationContext;
  /**
   * Description text - should contain important implication of user's choice (such as "this action is destructive" etc.).
   * May contain mustache-like expressions.
   */
  description: string;
  /**
   * Dialog description text interpolation context for mustache-like expressions.
   */
  descriptionContext?: InterpolationContext;
  leftButtonTitle?: string;
  rightButtonTitle?: string;
}
