<icz-button background="transparent" svgIcon="{{ currentLanguage?.icon }}"
            cdkOverlayOrigin
            #menuPopupOrigin="cdkOverlayOrigin"
            (onAction)="popoverOpen = true" originalIconColor>
  {{ currentLanguage?.label! | translate | lowercase }}
  <icz-icon svgIcon="expand_more"></icz-icon>
</icz-button>
<icz-popover-menu [isOpen]="popoverOpen"
                  [overlayOrigin]="menuPopupOrigin"
                  (onClose)="popoverOpen = false;">
  @for (language of availableLanguages; track language) {
    <icz-button
      class="language-selector-item"
      background="transparent" svgIcon="{{ language.icon }}" originalIconColor
      (onAction)="changeLanguage(language.value)" [label]="language.label"></icz-button>
  }
</icz-popover-menu>
