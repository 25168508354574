import {NgModule} from '@angular/core';
import {IczFormSubmitDirective} from './icz-form-submit.directive';
import {IczFormCancelDirective} from './icz-form-cancel.directive';
import {FormElementsModule} from '@icz/angular-form-elements';
import {EssentialsModule} from '@icz/angular-essentials';
import {SubmitBarComponent} from './submit-bar/submit-bar.component';

@NgModule({
  imports: [
    EssentialsModule,
    FormElementsModule,
  ],
  declarations: [
    IczFormSubmitDirective,
    IczFormCancelDirective,
    SubmitBarComponent,
  ],
  exports: [
    IczFormSubmitDirective,
    IczFormCancelDirective,
    SubmitBarComponent,
  ],
})
export class IczFormDirectivesModule {}
