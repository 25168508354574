import {Component, forwardRef, Input} from '@angular/core';
import {AbstractSelectField} from '../common/abstract-select-field';
import {TreeItemSelectionStrategy} from '../tree-view/tree-view.component';
import {
  FormAutocompleteListTextItemComponent
} from '../form-autocomplete/form-autocomplete-list-text-item/form-autocomplete-list-text-item.component';
import {FormAutocompleteTreeListComponent} from './form-autocomplete-tree-list/form-autocomplete-tree-list.component';
import {NgTemplateOutlet} from '@angular/common';
import {AutoFocusDirective, TooltipDirective} from '@icz/angular-essentials';
import {
  FormAutocompleteChipComponent
} from '../form-autocomplete/form-autocomplete-chip/form-autocomplete-chip.component';
import {
  ComposableFormAutocompleteComponent
} from '../form-autocomplete/composable-form-autocomplete/composable-form-autocomplete.component';
import {ValidationErrorsListComponent} from '../validators/validation-errors-list/validation-errors-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {getOptionsPathToRoot, getOptionsSubtree, IczOption} from '../form-elements.model';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

@Component({
  selector: 'icz-form-tree-selector',
  templateUrl: './form-tree-selector.component.html',
  styleUrls: ['./form-tree-selector.component.scss'],
  standalone: true,
  imports: [
    FormAutocompleteListTextItemComponent,
    FormAutocompleteTreeListComponent,
    NgTemplateOutlet,
    TooltipDirective,
    FormAutocompleteChipComponent,
    ComposableFormAutocompleteComponent,
    ValidationErrorsListComponent,
    TranslateModule,
    AutoFocusDirective,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => FormTreeSelectorComponent),
  }],
})
export class FormTreeSelectorComponent<T extends string|number = string|number> extends AbstractSelectField<T> {

  /**
   * @see TreeItemSelectionStrategy
   * Effective only if the tree selector is multiselect.
   */
  @Input()
  selectionStrategy = TreeItemSelectionStrategy.SIMPLE;
  /**
   * Upper limit of number of text/chip rows in selected value field.
   */
  @Input()
  autoSizeMax = 1;

  /**
   * Height of container enclosing this component
   */
  @Input()
  containerHeight: Nullable<number>;

  // Override
  protected override optionDeselector(elementToRemove: IczOption<T>) {
    this.__value = FormTreeSelectorComponent.deselectOption(this.__value, elementToRemove, this.selectionStrategy);
  }

  static override deselectOption<T>(options: Array<IczOption<Nullable<T>>>, optionToDeselect: IczOption<Nullable<T>>, selectionStrategy: TreeItemSelectionStrategy): Array<IczOption<Nullable<T>>> {
    if (selectionStrategy === TreeItemSelectionStrategy.BULK) {
      let result: Array<IczOption<Nullable<T>>> = options;

      const subtree = getOptionsSubtree(options, optionToDeselect.value);
      // If there were selected inner tree nodes and we unselected some of its descendants, deselect that
      // inner tree node as well because its checked state signifies that there are ALL descendants selected.
      const pathToRoot = getOptionsPathToRoot(options, optionToDeselect.value);

      // factor out only selected options
      const uniqueOptionsToRemove = [...new Set([...subtree, ...pathToRoot])]
        .filter(o => options.find(v => o && v.value === o.value));

      for (const optionToRemove of uniqueOptionsToRemove) {
        result = AbstractSelectField.deselectOption(result, optionToRemove!);
      }

      return result;
    }
    else { // SIMPLE, HYBRID
      return AbstractSelectField.deselectOption(options, optionToDeselect);
    }
  }

}
