import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {AutoFocusDirective, ButtonComponent, IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {FormFieldComponent, IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FilterOperator} from '../../../../table.utils';

/**
 * @internal
 */
export enum NumberFilterSelectionMode {
  SINGLE_VALUE = 'SINGLE_VALUE',
  INTERVAL = 'INTERVAL',
}

/**
 * @internal
 */
export interface NumberRange {
  from: number;
  to: number;
}

/**
 * @internal
 */
export function isNumberRange(value: any): value is NumberRange {
  return value && typeof value === 'object' && 'from' in value && 'to' in value;
}

/**
 * @internal
 */
@Component({
  selector: 'icz-number-filter',
  templateUrl: './number-filter.component.html',
  styleUrls: ['./number-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    FormFieldComponent,
    AutoFocusDirective,
    TranslateModule,
    ReactiveFormsModule,
  ],
})
export class NumberFilterComponent implements OnInit, IczOnChanges {

  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  selectedValue: Nullable<number | NumberRange>;
  @Input({required: true})
  currentOperator!: FilterOperator;
  @Output()
  selectedValueChanged = new EventEmitter<Nullable<number | NumberRange>>();

  protected selectionMode = NumberFilterSelectionMode.SINGLE_VALUE;

  protected form = new IczFormGroup({
    from: new IczFormControl<Nullable<number>>(null, [IczValidators.isDecimal()]),
    to: new IczFormControl<Nullable<number>>(null, [IczValidators.isDecimal()]),
    value: new IczFormControl<Nullable<number>>(null, [IczValidators.isDecimal()]),
  });

  protected get isUsingIntervalableOperator(): boolean {
    return this.currentOperator === FilterOperator.equals || this.currentOperator === FilterOperator.notEquals;
  }

  protected isIntervalSwitchDisabled = false;

  protected readonly NumberFilterSelectionMode = NumberFilterSelectionMode;

  ngOnInit() {
    if (!isNil(this.selectedValue)) {
      if (isNumberRange(this.selectedValue)) {
        this.form.patchValue(this.selectedValue);
      }
      else {
        this.form.get('value')!.setValue(this.selectedValue);
      }
    }

    this.form.get('value')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(value => {
      if (value) {
        if (!Number.isNaN(parseInt(String(value)))) {
          this.form.get('from')!.reset();
          this.form.get('to')!.reset();
          this.selectedValueChanged.emit(value);
        }
        else {
          this.selectedValueChanged.emit(null);
        }
      }
      else {
        this.selectedValueChanged.emit(null);
      }
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.currentOperator) {
      if (this.isUsingIntervalableOperator) {
        this.isIntervalSwitchDisabled = false;

        if (changes.selectedValue) {
          if (isNumberRange(this.selectedValue)) {
            this.selectionMode = NumberFilterSelectionMode.INTERVAL;
          }
          else {
            this.selectionMode = NumberFilterSelectionMode.SINGLE_VALUE;
          }
        }
      }
      else {
        this.isIntervalSwitchDisabled = true;

        // clear filter state only when change INTERVAL->DATE happens
        if (this.selectionMode === NumberFilterSelectionMode.INTERVAL) {
          this.selectionMode = NumberFilterSelectionMode.SINGLE_VALUE;
          this.selectedValue = null;
          this.selectedValueChanged.emit(null);
        }
      }
    }
  }

  protected useIntervalValues() {
    const formValue = this.form.value;

    if (this.form.valid) {
      if (!isNil(formValue.from) && !isNil(formValue.to) && Number(formValue.from) <= Number(formValue.to)) {
        this.form.get('value')!.reset();

        this.selectedValueChanged.emit({
          from: formValue.from!,
          to: formValue.to!,
        });
      }
    }
  }

  protected switchToSingleValueMode() {
    this.changeSelectionMode(NumberFilterSelectionMode.SINGLE_VALUE);
  }

  protected switchToIntervalMode() {
    this.changeSelectionMode(NumberFilterSelectionMode.INTERVAL);
  }

  private changeSelectionMode(newSelectionMode: NumberFilterSelectionMode) {
    const oldSelectionMode = this.selectionMode;
    this.selectionMode = newSelectionMode;

    if (
      oldSelectionMode === NumberFilterSelectionMode.INTERVAL &&
      newSelectionMode === NumberFilterSelectionMode.SINGLE_VALUE
    ) {
      this.selectedValue = null;
      this.selectedValueChanged.emit(null);
    }
  }

}
