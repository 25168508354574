import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';

import {InterpolationContext} from '../essentials.utils';
import {IconComponent} from '../icon/icon.component';
import {LabelComponent} from '../label/label.component';
import {ButtonComponent} from '../button/button.component';

/**
 * Alert severity, determining alert color and icon.
 * - error - red color + warning triangle,
 * - warning - orange color + warning triangle,
 * - info - blue color + circled info icon,
 * - neutral - grey color + warning triangle,
 * - success - green color + green tick icon.
 */
export type AlertStatus = 'error' | 'warning' | 'info' | 'neutral' | 'success';

/**
 * Alert size, determining visual impact of the alert onto the page.
 * - full - the alert will be boxed in a colored box,
 * - compact - the alert will be styled as simple colored text.
 */
export type AlertMode = 'full' | 'compact';

/**
 * @internal
 */
const SEVERITY_ICONS: Record<AlertStatus, string> = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  neutral: 'error',
  success: 'success',
};

/**
 * @internal
 */
const SEVERITY_COMPACT_ICONS: Record<AlertStatus, string> = {
  info: 'info_blue',
  warning: 'warning',
  error: 'error',
  neutral: 'error',
  success: 'config_success',
};

/**
 * A component used to convey red/green/blue/grey information messages with associated icons to the user in a specific business context.
 * Its content can either be specified using @Input heading / @Input label or alternatively,
 * if rich content is needed, using ng-content slots [content] and [extraContent] while omitting
 * the aforementioned Inputs in consumer components.
 */
@Component({
  selector: 'icz-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, LabelComponent, ButtonComponent],
})
export class AlertComponent {
  /**
   * Main alert text rendered at topmost position, with bold font weight.
   * Can contain mustache-like expressions.
   */
  @Input()
  heading = '';
  /**
   * Secondary alert text used for further descriptions of statement in heading input.
   * Can contain mustache-like expressions.
   */
  @Input()
  label = '';
  /**
   * Alert severity impacting alert color and alert icon.
   * @see AlertStatus
   */
  @Input()
  severity: AlertStatus = 'info';
  /**
   * Mustache expression context for both heading and label inputs.
   */
  @Input()
  textContext: Nullable<InterpolationContext>;
  /**
   * If false, will not render alert icon, leaving only colored text with a box.
   */
  @Input()
  showIcon = true;
  /**
   * Custom icon.
   */
  @Input()
  customSvgIcon: Nullable<string>;
  /**
   * If true, the alert will be dismissable, i.e. will display an "X" button for alert closing.
   * @deprecated - this behavior should be implemented in clients using ng-content slots. We might remove this in the future.
   */
  @Input()
  isDismissable = false;
  /**
   * If true, secondary contents in place of @Input label will be hidden under an expander button with up/down chevron.
   */
  @Input()
  isLabelExpandable = false;
  /**
   * Alert mode determining alert size and alert visual impact onto page.
   * @see AlertMode
   */
  @Input()
  alertMode: AlertMode = 'full';
  /**
   * @deprecated - this behavior will be extracted to styling of consumers
   */
  @HostBinding('class.pinned-top')
  @Input()
  isPinnedToTop = false;

  /**
   * Emits when this alert is dismissed using its "X" button which is visible when @Input isDismissable is set to TRUE.
   * @deprecated - this behavior should be implemented in clients using ng-content slots. We might remove this in the future.
   */
  @Output()
  dismissed = new EventEmitter<void>();

  protected isExpanded = false;

  protected get icon() {
    if (this.customSvgIcon) {
      return this.customSvgIcon;
    }
    else {
      if (this.isFullAlert) {
        return SEVERITY_ICONS[this.severity];
      }
      else {
        return SEVERITY_COMPACT_ICONS[this.severity];
      }
    }
  }

  protected get isFullAlert() {
    return this.alertMode === 'full';
  }

  @HostBinding('class.mode-full')
  protected get hasFullModeStyle() {
    return this.isFullAlert;
  }

  @HostBinding('class.info')
  protected get isInfo() {
    return this.severity === 'info';
  }

  @HostBinding('class.error')
  protected get isError() {
    return this.severity === 'error';
  }

  @HostBinding('class.warning')
  protected get isWarning() {
    return this.severity === 'warning';
  }

  @HostBinding('class.neutral')
  protected get isNeutral() {
    return this.severity === 'neutral';
  }

  @HostBinding('class.success')
  protected get isSuccess() {
    return this.severity === 'success';
  }

  protected toggleExpansionState() {
    this.isExpanded = !this.isExpanded;
  }
}
