import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {IFormGroupCheckable} from '../../../../lib/form-group-checks';
import {CheckUnsavedFormDialogService} from '../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {enumToOptions, SKIP_ERROR_DIALOG} from '../../../../core';
import {forkJoin, Observable, of} from 'rxjs';
import {
  ApiDocumentService,
  ApiFileService,
  ApiRegistryOfficeTransferService,
  ApiStorageUnitService,
  DocumentDto,
  FileDto,
  RegistryOfficeTransferIncidentDto,
  RegistryOfficeTransferIncidentUpdateDto,
  RegistryOfficeTransferProblemDto,
  StorageUnitDto
} from '|api/document';
import {ApiDigitalComponentService, EsslComponentExtendedDto} from '|api/component';
import {RegistryOfficeTransferErrorSource, RegistryOfficeTransferIncidentState} from '|api/commons';
import {
  RegistryOfficeIncidentResolutionDialogComponent,
  RegistryOfficeIncidentResolutionDialogData
} from '../registry-office-transfer-incident-toolbar-buttons/registry-office-incident-resolution-dialog/registry-office-incident-resolution-dialog.component';
import {IczModalService, injectModalData, injectModalRef} from '@icz/angular-modal';


export enum RegistryOfficeTransferIncidentDialogResultType {
  SAVED = 'SAVED',
  SAVED_AND_RESOLVED = 'SAVED_AND_RESOLVED',
}

export interface RegistryOfficeTransferIncidentDialogResult {
  resultType: RegistryOfficeTransferIncidentDialogResultType;
  incident: RegistryOfficeTransferIncidentDto;
}

@Component({
  selector: 'icz-registry-office-transfer-incident-dialog',
  templateUrl: './registry-office-transfer-incident-dialog.component.html',
  styleUrls: ['./registry-office-transfer-incident-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class RegistryOfficeTransferIncidentDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  private apiDocumentService = inject(ApiDocumentService);
  private apiFileService = inject(ApiFileService);
  private apiStorageUnitService = inject(ApiStorageUnitService);
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private modalService = inject(IczModalService);
  private modalRef = injectModalRef<Nullable<RegistryOfficeTransferIncidentDialogResult>>();
  private apiRegistryOfficeTransferService = inject(ApiRegistryOfficeTransferService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected incident = injectModalData<RegistryOfficeTransferIncidentDto>();

  form = new IczFormGroup({
    errorId: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    name: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    state: new IczFormControl<Nullable<RegistryOfficeTransferIncidentState>>({value: null, disabled: true}),
    resolutionDescription: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
  });

  objectWithError!: DocumentDto|FileDto|StorageUnitDto;
  componentWithError!: Nullable<EsslComponentExtendedDto>;
  associatedProblem!: Nullable<RegistryOfficeTransferProblemDto>;

  transferIncidentStateEnumPrefix = 'en.registryOfficeTransferIncidentState.';
  incidentStateOptions: IczOption<RegistryOfficeTransferIncidentState>[] = [
    {
      value: RegistryOfficeTransferIncidentState.IN_PROGRESS,
      label: `${this.transferIncidentStateEnumPrefix}${RegistryOfficeTransferIncidentState.IN_PROGRESS}`,
      icon: 'crossed',
    },
    {
      value: RegistryOfficeTransferIncidentState.SOLVED,
      label: `${this.transferIncidentStateEnumPrefix}${RegistryOfficeTransferIncidentState.SOLVED}`,
      icon: 'checked',
    }
  ];

  incidentSourceOptions = enumToOptions('registryOfficeTransferErrorSource', RegistryOfficeTransferErrorSource);

  formGroupsToCheck!: string[];

  get isIncidentSolved() {
    return this.incident.state === RegistryOfficeTransferIncidentState.SOLVED;
  }

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.form.patchValue(this.incident);

    if (this.isIncidentSolved) {
      this.form.disable();
    }
    else {
      if (this.incident.source !== RegistryOfficeTransferErrorSource.MANUAL) {
        this.form.get('errorId')!.disable();
      }
    }

    this.fetchAssociatedObjects();
  }

  submit() {
    this.loadingService.doLoading(
      this.getSaveRequest$(),
      this,
    ).subscribe(incident => {
      this.modalRef.close({
        resultType: RegistryOfficeTransferIncidentDialogResultType.SAVED,
        incident,
      });
    });
  }

  submitAndMarkAsResolved() {
    this.loadingService.doLoading(
      this.getSaveRequest$(),
      this,
    ).subscribe(incident => {
      this.modalService.openComponentInModal<RegistryOfficeTransferIncidentDto[], RegistryOfficeIncidentResolutionDialogData>({
        component: RegistryOfficeIncidentResolutionDialogComponent,
        modalOptions: {
          width: 600,
          height: 450,
          titleTemplate: 'Řešení chyby {{errorId}} {{name}}',
          titleTemplateContext: {
            count: String(1),
            errorId: incident.errorId,
            name: incident.name,
          },
        },
        data: {
          incidents: [incident],
          syncMode: true,
        },
      }).subscribe(result => {
        if (result) {
          this.modalRef.close({
            resultType: RegistryOfficeTransferIncidentDialogResultType.SAVED_AND_RESOLVED,
            incident,
          });
        }
      });
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

  private getSaveRequest$() {
    const formValue = this.form.getRawValue();

    const requestDto: RegistryOfficeTransferIncidentUpdateDto = {
      errorId: formValue.errorId!,
      name: formValue.name!,
    };

    return this.apiRegistryOfficeTransferService.registryOfficeTransferUpdateRegistryOfficeTransferIncident({
      id: this.incident.id,
      body: requestDto,
    });
  }

  private fetchAssociatedObjects() {
    let objectWithError$!: Observable<DocumentDto | FileDto | StorageUnitDto>;

    if (this.incident.documentId) {
      objectWithError$ = this.apiDocumentService.documentFindById({
        id: this.incident.documentId,
      });
    }
    else if (this.incident.fileId) {
      objectWithError$ = this.apiFileService.fileFindById({
        id: this.incident.fileId,
      });
    }
    else if (this.incident.storageUnitId) {
      objectWithError$ = this.apiStorageUnitService.storageUnitFindById({
        id: this.incident.storageUnitId,
      });
    }

    let componentWithError$: Observable<Nullable<EsslComponentExtendedDto>> = of(null);

    if (this.incident.componentId) {
      componentWithError$ = this.apiDigitalComponentService.digitalComponentFindById({
        id: this.incident.componentId,
      }, SKIP_ERROR_DIALOG);
    }

    let associatedProblem$: Observable<Nullable<RegistryOfficeTransferProblemDto>> = of(null);

    if (this.incident.registryOfficeTransferProblemId) {
      associatedProblem$ = this.apiRegistryOfficeTransferService.registryOfficeTransferGetRegistryOfficeTransferProblemById({
        id: this.incident.registryOfficeTransferProblemId,
      });
    }

    this.loadingService.doLoading(
      forkJoin([
        objectWithError$,
        componentWithError$,
        associatedProblem$,
      ]),
      this
    ).subscribe(([objectWithError, componentWithError, associatedProblem]) => {
      this.objectWithError = objectWithError;
      this.componentWithError = componentWithError;
      this.associatedProblem = associatedProblem;
    });
  }

}
