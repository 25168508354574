import {ChangeDetectionStrategy, Component} from '@angular/core';

/**
 * A simple loaadng indicator which is used in place of text with a nice unobtrusive animation.
 */
@Component({
  selector: 'icz-newspaper-loader',
  templateUrl: './newspaper-loader.component.html',
  styleUrls: ['./newspaper-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class NewspaperLoaderComponent {}
