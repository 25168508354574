/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { OwnConsignmentPerStateCountForOwnerDto } from '../../models/own-consignment-per-state-count-for-owner-dto';

export interface OwnConsignmentReturnConsignmentsPerStateCountForOwner$Params {
}

export function ownConsignmentReturnConsignmentsPerStateCountForOwner(http: HttpClient, rootUrl: string, params?: OwnConsignmentReturnConsignmentsPerStateCountForOwner$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<OwnConsignmentPerStateCountForOwnerDto>> {
  const rb = new RequestBuilder(rootUrl, ownConsignmentReturnConsignmentsPerStateCountForOwner.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<OwnConsignmentPerStateCountForOwnerDto>;
    })
  );
}

ownConsignmentReturnConsignmentsPerStateCountForOwner.PATH = '/sad/own-consignment/elastic/per-state-count/owner';