import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {
  IczStatistic,
  StatisticConfig,
  StatisticFormatter,
  TrendCounterStoredSettings,
  TrendPeriod,
  TrendWidgetData
} from '../../statistics-model';
import {LocalStorageKey, UserSettingsService} from '../../../../services/user-settings.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {ApiStatisticsService, Statistic, StatisticTileDto} from '|api/elastic';
import {BehaviorSubject, Subscription} from 'rxjs';

export interface StatisticTrendConfig extends StatisticConfig {
  label: string;
  period: TrendPeriod;
  displayed: boolean;
  order: number;
}

const SIZE_SPECIFIC_STATISTICS = [
  Statistic.DOCUMENTS_SIZE_COUNT,
  Statistic.FILES_SIZE_COUNT,
];

const TIME_SPECIFIC_STATISTICS = [
  Statistic.DOCUMENTS_RANGE_COUNT,
  Statistic.FILES_RANGE_COUNT,
  Statistic.FILES_TRANSFERRED_TO_REGISTRY_OFFICE_RANGE_COUNT,
  Statistic.DIGITAL_COMPONENTS_RANGE_COUNT,
  Statistic.ENTITY_CLASSES_OF_VALID_CLASSIFICATION_SCHEME_RANGE_COUNT,
];

@Component({
  selector: 'icz-statistic-trend-counter',
  templateUrl: './statistic-trend-counter.component.html',
  styleUrls: ['./statistic-trend-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatisticTrendCounterComponent implements IczOnChanges {
  private userSettingsService = inject(UserSettingsService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected destroyRef = inject(DestroyRef);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private apiStatisticsService = inject(ApiStatisticsService);

  @Input({required: true})
  config!: StatisticTrendConfig;
  @Input({required: true})
  statisticCountMessage$!: BehaviorSubject<Nullable<StatisticTileDto>>;
  @Output() goToDetailClicked = new EventEmitter<IczStatistic>();
  @Output() periodChange = new EventEmitter<TrendPeriod>();

  trendWidgetData: TrendWidgetData = {
    total: 0,
    trend: 0,
  };
  statisticsConfig: Nullable<StatisticConfig>;
  statisticCountMessageSub!: Subscription;

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.statisticCountMessage$ && changes.statisticCountMessage$.currentValue) {
      if (!this.statisticCountMessageSub) {
        this.statisticCountMessageSub = this.statisticCountMessage$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(count => {
          if (count && count.statistic === this.config.statisticKey) {
            const trendData = {...this.trendWidgetData};

            if (TIME_SPECIFIC_STATISTICS.includes(this.config.statisticKey)) {
              trendData.total = count.watchedPeriodCount!;
              trendData.trend = count.countChangeRelativeToSamePrecedingPeriod!;
            } else if (SIZE_SPECIFIC_STATISTICS.includes(this.config.statisticKey)) {
              trendData.total = count.totalSize!;
            } else {
              trendData.total = count.totalCount!;
            }

            if (count.defaultDimensionRows) {
              trendData.aggregatedData = count.defaultDimensionRows.map(row => ({label: row.first!, value: Number(row.second)}));
            }
            this.trendWidgetData = trendData;
            this.changeDetectorRef.detectChanges();
          }
        });
      }
    }

    if (changes.config && changes.config.currentValue) {
      if (SIZE_SPECIFIC_STATISTICS.includes(this.config.statisticKey)) {
        this.statisticsConfig = {
          ...this.config,
          formatter: StatisticFormatter.FILESIZE
        };
      } else {
        this.statisticsConfig = {
          ...this.config
        };
      }
      this.changeDetectorRef.detectChanges();
    }
  }

  loadCounterData() {
    const fromDate = new Date();

    if (this.config.period === TrendPeriod.LAST_7_DAYS) {
      fromDate.setDate(fromDate.getDate() - 6);
    }
    else if (this.config.period === TrendPeriod.LAST_30_DAYS) {
      fromDate.setDate(fromDate.getDate() - 29);
    }

    this.apiStatisticsService.statisticsGetStatisticTilesAsync({
      body: [{
        statistic: this.config.statisticKey,
        watchedPeriodToDate: new Date().toISOString(),
        watchedPeriodFromDate: fromDate.toISOString(),
      }]
    }).subscribe();
  }

  periodChanged(event: { id: IczStatistic, period: TrendPeriod }) {
    this.config.period = event.period;
    this.loadCounterData();
    const statisticsSettings = this.userSettingsService.getParsedValue(LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS);
    if (statisticsSettings) {
      const userSettings = statisticsSettings[event.id] as TrendCounterStoredSettings;
      userSettings.period = event.period;
      this.userSettingsService.setParsedValue(LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS, statisticsSettings);
    }
    this.periodChange.emit(event.period);
  }
}
