import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Page, SearchParams} from '@icz/angular-table';
import {ApiStorageUnitFillerService, StorageUnitFillerDto} from '|api/document';
import {SearchApiService} from './search-api.service';
import {CORE_MICROSERVICE_ROOT} from '../api';

interface StorageUnitFillerDtoExtended extends StorageUnitFillerDto {
  scheduledRunAt?: Nullable<string>;
}

@Injectable({
  providedIn: 'root',
})
export class StorageUnitFillerEntitiesSearchService extends SearchApiService {

  findStorageUnitFillerEntities(searchParams: Partial<SearchParams>, id: number): Observable<Page<StorageUnitFillerDtoExtended>> {
    return this.searchApi<Page<StorageUnitFillerDtoExtended>>(searchParams, CORE_MICROSERVICE_ROOT + ApiStorageUnitFillerService.StorageUnitFillerFindContentPath.replace('{id}', id.toString()));
  }

}
