<form class="col grow overflow-hidden" iczForm [formGroup]="form">
  <div
    class="row items-center justify-between toolbar"
    cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    >

    <icz-form-autocomplete
      class="mt-16"
      cdkDragHandle
      cdkDragHandleDisabled
      formControlName="filterOperator"
      [clearable]="false"
      [options]="data.filterOperators"
    ></icz-form-autocomplete>

    <icz-button svgIcon="close" (onAction)="close()" background="transparent" disableRipple size="small"></icz-button>
  </div>

  <icz-subject-search-with-results [showFooter]="foundSubjectsCount === 0"
                                   [showInlineSearchButton]="false"
                                   [allowCreateOnNotFound]="false"
                                   [showSearchAppendix]="true"
                                   [allowInternalSearchOnly]="true"
                                   [subjectToolbarContext]="SubjectToolbarContext.OVERVIEW"
                                   (selectedRowsChanged)="selectedSubjectsChanged($event)"
                                   (searchResultDone)="onSearchDone($event)"
                                   tableViewId="subject-custom-filter-step-search"
                                   class="search-content overflow-auto"
                                   [resultTableColumnSet]="SubjectTableColumnSet.RESULT_SET_FOR_LISTING">
  </icz-subject-search-with-results>

  @if (foundSubjectsCount > 0) {
    <icz-form-buttons>
      <icz-button primary iczFormSubmit leftButtons
        label="Potvrdit"
        [disabled]="!selectedSubjects.length"
        (onAction)="applyFilter()">
      </icz-button>
      <icz-button leftButtons
        label="Upravit hodnoty filtru"
        (onAction)="resetFoundCounter()">
      </icz-button>
    </icz-form-buttons>
  }

</form>
