@if (loadingIndicatorService.isLoading(this, loaderIdIsdsFind)) {
  <icz-alert class="my-16"
  heading="Probíhá ověření všech dat o subjektu ze systému Datových schránek" severity="info"></icz-alert>
}
@if (activeRow) {
  <icz-subject-table-sidebar
    [subject]="activeRow"
    [opened]="sidebarOpened"
    (closed)="iczTable.unsetActiveRow()"
  ></icz-subject-table-sidebar>
}

@if (columnsData && tableConfig && dataSource) {
  <icz-table
    #iczTable
    class="grow h-full"
    [id]="tableId"
    [dataSource]="dataSource"
    [columnsData]="columnsData"
    [config]="tableConfig"
    (activeRowChanged)="setActiveRow($event)"
    [waiting]="loadingIndicatorService.isLoading$(this, loaderIdIsdsFind) | async"
    (selectedRowsChanged)="selectedRowsChanged.emit($event)"
    (rowClicked)="rowClicked.emit($event)"
  >
    @if (tableTitle) {
      <icz-label tableTitle [label]="tableTitle"></icz-label>
    }
    <icz-label noDataLabel label="Žádné subjekty"></icz-label>
    <ng-content tools select="[tools]"></ng-content>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.CLASSIFICATION">
      <icz-subject-initials [subject]="row" class="mr-4"></icz-subject-initials>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_NAME">
      @if (canUserShowSubjectDetail) {
        <a class="p-4 icz-body-1 inline-block visible-clickable-area" iczLinkWithoutHref
           (click.stop)="showSubjectInfo(row, ShowSubjectInfoAction.OPEN_MODAL)"
          >
          <ng-container *ngTemplateOutlet="subjectName"></ng-container>
        </a>
      }
      @else {
        <ng-container *ngTemplateOutlet="subjectName"></ng-container>
      }
      <ng-template #subjectName>
        {{ row | subjectName}}
      </ng-template>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.SOURCE">
      @if (row.subjectSource === SubjectRecordSource.INTERNAL) {
        <span>{{ 'Rejstřík' | translate }}</span>
      } @else if (row.subjectSource === SubjectRecordSource.ISZR) {
        <span>{{ 'ISZR' | translate }}</span>
      } @else if (row.subjectSource === SubjectRecordSource.ISDS_SEARCH || row.subjectSource === SubjectRecordSource.ISDS_FIND) {
        <icz-icon svgIcon="datova_zprava"></icz-icon>
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.IDENTIFICATION_STATUS">
      <icz-subject-identification-status-icon [subject]="row"></icz-subject-identification-status-icon>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ADDRESS">
      <icz-subject-address-list [subject]="row" [value]="getDefaultSubjectAddress(row)"></icz-subject-address-list>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ADDRESSES_WITH_SELECTOR">
      @if (getSubjectAddressCount(row); as subjectAddressCount) {
        @if (subjectAddressCount === 1) {
          <icz-address-details
            [addressWithType]="getSuitableAddressForDisplay(row)"
          ></icz-address-details>
        }
        @if (subjectAddressCount > 1) {
          <icz-subject-address-selector
            [subject]="row"
            [showValidationStatus]="false"
            (valueChange)="addressSelectorValueChanged(row, $event)"
          ></icz-subject-address-selector>
        }
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_DATA_BOX">
      @if (row.attributes.dataBoxes; as dataBoxes) {
        <div class="row items-center">
          <icz-databox-details [dataBoxes]="dataBoxes"></icz-databox-details>
        </div>
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_EMAIL">
      @if (row.attributes.emails; as emails) {
        <div>{{emails | attributesValue }}</div>
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.DATA_BOX_OR_EMAIL">
      <div class="col">
        @if (row.attributes.dataBoxes; as dataBoxes) {
          <icz-databox-details [dataBoxes]="dataBoxes"></icz-databox-details>
        }
        @if (row.attributes.emails; as emails) {
          <div>{{emails | attributesValue }}</div>
        }
      </div>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_PHONE_FAX">
      @if (row.attributes.phoneFaxes; as phoneFaxes) {
        <div>{{phoneFaxes | attributesValue }}</div>
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.REPRESENTING">
      @if (row.id === currentObjectRepresentingSubject?.id) {
        <div>{{ 'Reprezentující' | translate }}</div>
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_LEGAL_FORM">
      <span>{{ row.attributes.legalForm?.value | findInList: (legalFormOptions$ | async)!}}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.RELATION_TYPE">
      @if (getRelationType(row); as relationType) {
        <span> {{ ('en.subjectObjectRelationType.' + relationType) | translate }} </span>
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_NOTE">
      <span>{{ row.attributes.note?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_CID">
      <span>{{ row.attributes.cid?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_BIRTH_DATE">
      <span>{{ row.attributes.birthDate?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_CLIENT_ID">
      <span>{{ row.attributes.clientId?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_PASSPORT_ID">
      <span>{{ row.attributes.passportId?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_DRIVING_LICENCE_ID">
      <span>{{ row.attributes.drivingLicenceId?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_IDENTITY_CARD_ID">
      <span>{{ row.attributes.identityCardId?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_BIRTH_PLACE">
      <span>{{ row.attributes.birthPlace?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_LEGAL_FORM">
      <span>{{ row.attributes.legalForm?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_LE_ID">
      <span>{{ row.attributes.leId?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_TAX_ID">
      <span>{{ row.attributes.taxId?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_EORI_CODE">
      <span>{{ row.attributes.eoriCode?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ATTRIBUTES_VAT_ID">
      <span>{{ row.attributes.vatId?.value }}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ACTIONS">
      <icz-subjects-table-generic-actions
        [subject]="row"
        (actionExecuted)="actionExecuted.emit($event)"
        [linkButtonLabel]="linkButtonLabel"
        [linkButtonDisabled]="linkButtonDisabled"
        [linkButtonDisabledTooltip]="linkButtonDisabledReason"
        (click.stop)="0"
      ></icz-subjects-table-generic-actions>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.CONSIGNMENT_ACTIONS">
      <icz-subjects-table-consignment-actions
        [subject]="row"
        [createNewConsignmentDialogType]="createNewConsignmentDialogType"
        (actionExecuted)="actionExecuted.emit($event)"
        (click.stop)="0"
      ></icz-subjects-table-consignment-actions>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: SubjectColumnKeys.ADMINISTRATION_ACTIONS">
      <div class="row justify-end" (click.stop)="0">
        <icz-button
          primary
          label="Náhled návrhu"
          (onAction)="actionExecuted.emit({action: SubjectsTableAction.MERGE_SUBJECTS, subject: row})"
        ></icz-button>
      </div>
    </ng-container>
  </icz-table>
}
