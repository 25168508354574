import {inject, Injectable} from '@angular/core';
import {FilterItem, FilterValueFormatter} from '@icz/angular-table';
import {Observable, of} from 'rxjs';
import {EmpowermentDto} from '|api/commons';
import {EmpowermentPipe} from '../../empowerment.pipe';

@Injectable()
export class EmpowermentFilterValueFormatterService implements FilterValueFormatter {

  private empowermentPipe = inject(EmpowermentPipe);

  format(filterItem: FilterItem): Observable<string> {
    const empowermentDto: Partial<Record<keyof EmpowermentDto, number|string>> = {};

    filterItem.subValues?.forEach(subValue => {
      const attribute = subValue.subValueId!.replace('empowerment.', '');
      empowermentDto[attribute as keyof EmpowermentDto] = subValue.value!;
    });

    return of(this.empowermentPipe.transform(empowermentDto as EmpowermentDto));
  }

}
