import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {DateRange} from '@angular/material/datepicker';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {DateFilterSelectionMode} from './date-filter.models';
import {
  DatePickerShortcut,
  DatePickerShortcutsComponent,
  RELATIVE_INTERVAL_SHORTCUTS,
  ShortcutClickedEvent
} from '../date-picker-shortcuts/date-picker-shortcuts.component';
import {
  AlertComponent,
  AutoFocusDirective,
  ButtonComponent,
  iczFormatDate,
  IczOnChanges,
  IczSimpleChanges,
  TabDirective,
  TabItem,
  TabsComponent
} from '@icz/angular-essentials';
import {
  areDateRangesEqual,
  CalendarComponent,
  CalendarRangeComponent,
  FormFieldComponent,
  getTodayMidnight,
  IczFormControl,
  IczFormGroup,
  IczValidators,
  isDateRange,
  parseDateFromLocalDateString
} from '@icz/angular-form-elements';
import {ReactiveFormsModule} from '@angular/forms';
import {FilterOperator, isValidIczDurationString} from '../../../../table.utils';

/**
 * String can be a duration value
 */
export type DateFilterValue = Date | DateRange<Date> | string;

/**
 * @internal
 */
export function isDateOperatorIntervalable(filterOperator: FilterOperator) {
  return (
    filterOperator === FilterOperator.equals ||
    filterOperator === FilterOperator.notEquals
  );
}

/**
 * @internal
 */
@Component({
  selector: 'icz-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    DatePickerShortcutsComponent,
    FormFieldComponent,
    AutoFocusDirective,
    CalendarComponent,
    CalendarRangeComponent,
    ReactiveFormsModule,
    TranslateModule,
    AlertComponent,
    TabsComponent,
    TabDirective,
  ],
})
export class DateFilterComponent implements IczOnChanges {

  protected translateService = inject(TranslateService);

  @Input({required: true})
  selected: Nullable<DateFilterValue>;
  @Input({required: true})
  currentOperator!: FilterOperator;
  @Output()
  selectedDateChanged = new EventEmitter<Nullable<DateFilterValue>>();

  @Output()
  daySelected = new EventEmitter();

  protected calendarDateForm = new IczFormGroup({
    date: new IczFormControl<Nullable<string>>(null, [IczValidators.localDate()]),
  });

  protected calendarRangeForm = new IczFormGroup({
    start: new IczFormControl<Nullable<string>>(null, [IczValidators.localDate()]),
    end: new IczFormControl<Nullable<string>>(null, [IczValidators.localDate()]),
  });

  protected activeShortcutType: Nullable<DatePickerShortcut>;
  protected selectedDaysAgo: Nullable<number>;

  protected selectionMode = DateFilterSelectionMode.RELATIVE_INTERVAL;

  protected modeTabs: TabItem<DateFilterSelectionMode>[] = [
    {
      id: DateFilterSelectionMode.DATE,
      label: 'Pevné datum',
    },
    {
      id: DateFilterSelectionMode.ABSOLUTE_INTERVAL,
      label: 'Pevné období',
    },
    {
      id: DateFilterSelectionMode.RELATIVE_INTERVAL,
      label: 'Flexibilní interval',
    }
  ];

  protected get activeModeTab() {
    return this.modeTabs.find(t => t.id === this.selectionMode);
  }

  private get isUsingIntervalableOperator(): boolean {
    return this.currentOperator === FilterOperator.equals || this.currentOperator === FilterOperator.notEquals;
  }

  protected readonly DateFilterSelectionMode = DateFilterSelectionMode;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.currentOperator) {
      if (this.isUsingIntervalableOperator) {
        this.setIntervalTabsDisableState(false);

        if (changes.selected) {
          if (isValidIczDurationString(this.selected)) {
            this.selectionMode = DateFilterSelectionMode.RELATIVE_INTERVAL;

            const shortcut = RELATIVE_INTERVAL_SHORTCUTS.find(s => s.data === this.selected);

            if (shortcut) {
              this.activeShortcutType = shortcut.value;
            }
            else {
              const xDaysAgoRe = /^P(\d+)D$/;

              if (xDaysAgoRe.test(this.selected as string)) {
                this.selectedDaysAgo = Number(xDaysAgoRe.exec(this.selected as string)![1]);
                this.activeShortcutType = DatePickerShortcut.LAST_X_DAYS;
              }
            }
          }
          else if (isDateRange(this.selected)) {
            this.selectionMode = DateFilterSelectionMode.ABSOLUTE_INTERVAL;
          }
          else {
            this.selectionMode = DateFilterSelectionMode.DATE;
          }
        }
      }
      else if (!changes.currentOperator.firstChange) {
        this.setIntervalTabsDisableState(true);

        // clear filter state only when change INTERVAL->DATE happens
        if (this.isIntervalSelectionMode(this.selectionMode)) {
          this.selectionMode = DateFilterSelectionMode.DATE;
          this.activeShortcutType = null;
          this.selected = null;
          this.selectedDaysAgo = null;
          this.selectedDateChanged.emit(null);
        }
      }
    }
    if (changes.selected) {
      if (this.selected) {
        this.applySelectionToManualInputs();
      }
    }
  }

  protected applyDateInputValue() {
    if (this.calendarDateForm.valid) {
      const dateText = this.calendarDateForm.value.date;

      if (dateText) {
        const parsedDate = parseDateFromLocalDateString(dateText);

        if (parsedDate) {
          this.selected = parsedDate;
          this.onDateChange(parsedDate);
        }
      }
      else {
        this.selected = null;
        this.onDateChange(null);
      }
    }
  }

  protected applyDateRangeInputValue() {
    const todayMidnight = getTodayMidnight();
    const shortcutParams = this.calendarRangeForm.value;

    if (this.calendarRangeForm.valid && shortcutParams.start && shortcutParams.end) {
      const start: Nullable<Date> = parseDateFromLocalDateString(shortcutParams.start ?? '');
      const end: Nullable<Date> = parseDateFromLocalDateString(shortcutParams.end ?? '');

      const dateRange = new DateRange(start ?? todayMidnight, end ?? todayMidnight);

      this.onDateChange(dateRange);
    }
  }

  protected onDateChange(date: Nullable<DateFilterValue>) {
    if (isDateRange(date)) {
      if (date.start && date.end) {
        const lastDateDiffersFromCurrentDate = !this.selected || this.selected instanceof Date || (
          isDateRange(this.selected) &&
          !areDateRangesEqual(this.selected!, date)
        );

        if (lastDateDiffersFromCurrentDate) {
          this.activeShortcutType = null;
          this.selected = date;
        }

        this.applySelectionToManualInputs();
        this.selectedDateChanged.emit(date);
      }
    }
    else {
      this.selected = date;
      this.selectedDateChanged.emit(date);
    }
  }

  protected onDayClick() {
    this.daySelected.emit();
  }

  protected handleDateShortcutChange(event: ShortcutClickedEvent) {
    this.activeShortcutType = event.shortcutType;

    if (event.shortcutValue) {
      this.selected = event.shortcutValue;
      this.onDateChange(event.shortcutValue);
    }
  }

  protected changeSelectionMode(newSelectionMode: DateFilterSelectionMode) {
    const oldSelectionMode = this.selectionMode;
    this.selectionMode = newSelectionMode;
    this.activeShortcutType = null;

    if (this.isIntervalSelectionMode(oldSelectionMode) && newSelectionMode === DateFilterSelectionMode.DATE) {
      this.selected = null;
      this.selectedDateChanged.emit(null);
    }
  }

  private applySelectionToManualInputs() {
    if (this.selectionMode === DateFilterSelectionMode.DATE) {
      this.calendarDateForm.get('date')!.setValue(
        this.formatDateForManualFilterInput(this.selected as Date)
      );
    }
    else if (this.selectionMode === DateFilterSelectionMode.ABSOLUTE_INTERVAL) {
      this.calendarRangeForm.setValue({
        start: this.formatDateForManualFilterInput((this.selected as DateRange<Date>).start),
        end: this.formatDateForManualFilterInput((this.selected as DateRange<Date>).end),
      });
    }
  }

  private formatDateForManualFilterInput(date: Nullable<Date>) {
    return iczFormatDate(this.translateService.currentLang, date).replaceAll(' ', '');
  }

  private setIntervalTabsDisableState(disabled: boolean) {
    this.modeTabs = this.modeTabs.map(t => {
      if (this.isIntervalSelectionMode(t.id)) {
        return {
          ...t,
          disabled,
        };
      }
      else {
        return t;
      }
    });
  }

  private isIntervalSelectionMode(mode: DateFilterSelectionMode) {
    return mode === DateFilterSelectionMode.ABSOLUTE_INTERVAL || mode === DateFilterSelectionMode.RELATIVE_INTERVAL;
  }

}
