import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {UserInitialsColor} from './user-initials.model';
import {ButtonComponent} from '../button/button.component';
import {UpperCasePipe} from '@angular/common';

/**
 * Renders a user monogram as a colored circle.
 * If the user monogram is unknown, renders a person icon inside a circle.
 */
@Component({
  selector: 'icz-user-initials',
  templateUrl: './user-initials.component.html',
  styleUrls: ['./user-initials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, UpperCasePipe],
})
export class UserInitialsComponent {

  /**
   * User monogram. Supply at most three characters.
   */
  @Input({required: true})
  initials: Nullable<string> = '';
  /**
   * If true, will render a specific person icon corresponding to system-issued messages.
   */
  @Input()
  isSystem = false;
  /**
   * Initials circle and text/icon color.
   */
  @Input()
  color = UserInitialsColor.BLUE;

  protected readonly UserInitialsColor = UserInitialsColor;

  protected get initialsIcon(): Nullable<string> {
    if (this.initials) return null;
    else {
      return this.isSystem ? 'system_user' : 'user_blue';
    }
  }

  protected get backgroundColor(): string {
    if (this.isSystem) {
      return '#e2e7eb';
    }
    else {
      switch (this.color) {
        case UserInitialsColor.BLUE:
          return '#d4e0fc';
        case UserInitialsColor.GREEN:
          return '#cde9ce';
        case UserInitialsColor.PURPLE:
          return '#d9cef8';
      }
    }
  }

}
