@if (tab) {
  <div [iczTooltip]="tab.tooltip" class="tab-content">
    @if (isActive) {
      <div class="active-line"></div>
    }
    @if (tab.icon) {
      <icz-icon [svgIcon]="tab.icon" size="small" class="mr-8"></icz-icon>
    }
    <icz-label class="label grow" [label]="tab.label"></icz-label>
    @if (isLoading) {
      <icz-spinner [diameter]="20" class="ml-4"></icz-spinner>
    }
    @if (isValid) {
      <icz-icon svgIcon="checked" class="ml-4"></icz-icon>
    }
    @if (isInvalid) {
      <icz-icon svgIcon="error" class="ml-4"></icz-icon>
    }
    @if (tab.showCount) {
      <span class="count icz-body-strong"
        [class.count-nonzero]="tab.count">
        {{ tab.count ?? 0 | numberToLocaleString }}
      </span>
    }
  </div>
}
