import {ChangeDetectionStrategy, Component, forwardRef, inject, Input, OnInit} from '@angular/core';
import {CalendarComponent, ValidDateFn, ValidDateFnWithMessage} from '../calendar/calendar.component';
import {IczValidators} from '../validators/icz-validators/icz-validators';
import {IczFormControl, IczFormGroup} from '../icz-form-controls';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AbstractPicker} from '../common/abstract-picker';
import {IconComponent, iczFormatDate, PopoverComponent} from '@icz/angular-essentials';
import {TranslateService} from '@ngx-translate/core';
import {IczMatCalendarDirective} from '../calendar/icz-mat-calendar.directive';
import {FormFieldComponent} from '../form-field/form-field.component';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {ReactiveFormsModule} from '@angular/forms';
import {ValidationErrorsListComponent} from '../validators/validation-errors-list/validation-errors-list.component';
import {
  formatAsLocalIsoDate,
  getStartOfTheDay,
  isValidDate,
  parseDateFromLocalDateString
} from '../form-elements.model';
import {FREE_DAYS_PROVIDER} from '../form-elements.providers';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

/**
 * Specifier of an ISO-compliant date format produced/consumed by the datepicker.
 * Date - YYYY-MM-DD
 * Time - YYYY-MM-DDTHH:MM:SS.nnnTZ
 */
export type OutputDateFormat = 'date' | 'dateTime';

/**
 * A form field which allows the user to either input his own date using text
 * in local date format or by picking it from a popover with a calendar.
 */
@Component({
  selector: 'icz-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CalendarComponent,
    IczMatCalendarDirective,
    PopoverComponent,
    IconComponent,
    FormFieldComponent,
    CdkOverlayOrigin,
    ReactiveFormsModule,
    ValidationErrorsListComponent,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => DatePickerComponent),
  }],
})
export class DatePickerComponent extends AbstractPicker implements OnInit {

  private freeDaysProvider = inject(FREE_DAYS_PROVIDER);
  private translateService = inject(TranslateService);

  protected valueValidator = IczValidators.localDate();

  /**
   * If true, holiday dates supplied by FREE_DAYS_PROVIDER will be rendered red and will be
   * unselectable in addition to unselectable dates from selectableDates predicate result.
   */
  @Input()
  areHolidaysSelectable = true;
  /**
   * Seaialization model format of selected picker value.
   * @see OutputDateFormat
   */
  @Input()
  outputFormat: OutputDateFormat = 'date';
  @Input()
  override set value(newValue: Nullable<string>) {
    if (this._formGroup) {
      this.valueInput.setValue(newValue ? iczFormatDate(this.translateService.currentLang, newValue) : null, {emitEvent: false});
      this._realValue = newValue;
    }
  }
  override get value(): Nullable<string> {
    return this._realValue;
  }
  /**
   * Date validator which greys out unselectable dates.
   */
  @Input()
  selectableDates: ValidDateFnWithMessage = {
    validationFn: _ => true
  };
  /**
   * A special visually distinguished label of the field to the right of the ordinary label.
   */
  @Input()
  rightLabel: Nullable<string>;
  /**
   * Tooltip of the right label.
   * Applicable only if rightLabel is non-null.
   */
  @Input()
  rightLabelTooltip: Nullable<string>;
  /**
   * A flag determining if clicking the right label will open a popover.
   * Applicable only if rightLabel is non-null.
   */
  @Input()
  showRightLabelPopupOnClick = false;

  /**
   * a predicate which makes the dates which do
   * not satisfy it red plus makes them unselectable
   */
  nonHolidayDates: ValidDateFn = d => !this.freeDaysProvider.isFreeDay(d);

  /**
   *
   */
  private _defaultInvalidDateMessage = {
    errorMessageTemplate: 'Zadané datum nevyhovuje omezení na datum'
  };

  protected _formGroup = new IczFormGroup({
    valueInput: new IczFormControl<Nullable<string>>(null, [this.valueValidator]),
  });

  /**
   * @internal
   */
  override ngOnInit() {
    super.ngOnInit();

    this.valueInput.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      const isValidDate = value && IczValidators.localDate()(new IczFormControl<Nullable<string>>(value)) === null;

      if (isValidDate) {
        const date = value ? this.parseLocalDateFormat(value) : null;

        if (date) {
          this._valueChanged(date.toISOString());
        }
      } else {
        this._realValue = null;
        this.valueChange.emit(this._realValue);
      }
    });
  }

  /**
   * @internal
   * @param $event
   */
  override onInputFieldKeydown($event: KeyboardEvent): void {
    super.onInputFieldKeydown($event);

    const keyPressed = $event.key;

    if (keyPressed === 'd') {
      this.setDateToToday($event);
    }
    else if (keyPressed === 'v') {
      this.setDateToYesterday($event);
    }
  }

  protected onDatePick(newDate: Date): void {
    this.valueInput.reset(undefined);
    this._valueChanged(newDate.toISOString());
    this.unsetControlErrors();
    this.closePopover();
  }

  /**
   * @internal
   */
  _valueChanged($value: string): void { // $value is ISO string
    let newValue = $value;

    if (this.outputFormat === 'date') {
      newValue = formatAsLocalIsoDate($value);
    }

    this.value = newValue;
    this.valueChange.emit(newValue);
    this.blur.emit();
  }

  /**
   * Triggers revalidation of internal form control
   */
  updateInternalFormValidity() {
    this.valueInput.markAsTouched();
    this.valueInput.setValue(this.valueInput.value);
  }

  private setDateToToday($event: Event): void {
    $event.preventDefault();
    this.autoSetViewDate(new Date());
  }

  private setDateToYesterday($event: Event): void {
    $event.preventDefault();
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    this.autoSetViewDate(yesterday);
  }

  private parseLocalDateFormat(dateSource: string): Nullable<Date> {
    // dateSource needs to get presanitized because it
    // will gain extra spaces after successful parsing.
    dateSource = dateSource.replaceAll(' ', '');

    const date = parseDateFromLocalDateString(dateSource);

    const isUnselectableDate = isValidDate(date) && this.selectableDates && !this.selectableDates.validationFn(date!);
    const isHolidayDate = isValidDate(date) && !this.nonHolidayDates(date!);

    if (isUnselectableDate) {
      if (this.selectableDates.invalidDateMessage) {
        this.setControlErrors({
          invalidvalueInput: this.selectableDates.invalidDateMessage,
        });
      } else {
        this.setControlErrors({
          invalidvalueInput: this._defaultInvalidDateMessage,
        });
      }
      return null;
    } else if (!this.areHolidaysSelectable && isHolidayDate) {
      this.setControlErrors({
        invalidvalueInput: {
          errorMessageTemplate: 'Zadané datum je svátek'
        },
      });
      return null;
    } else {
      if (isValidDate(date)) {
        this.unsetControlErrors();
        return date;
      }
      else {
        this.setControlErrors({
          invalidvalueInput: {
            errorMessageTemplate: 'Zadané datum je neplatné'
          },
        });
        return null;
      }
    }
  }

  private autoSetViewDate(date: Date): void {
    if (this.isSelectableDate(date)) {
      this.onDatePick(getStartOfTheDay(date));
    }
  }

  private isSelectableDate(date: Date) {
    if (this.areHolidaysSelectable) {
      return this.selectableDates.validationFn(date);
    }
    else {
      return this.selectableDates.validationFn(date) && this.nonHolidayDates(date);
    }
  }

}
