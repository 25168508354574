import {SubjectRecordClassification, SubjectRecordFindDto} from '|api/commons';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {SharedBusinessValidators} from '../../shared-business-validators';
import {createAddressFormGroup} from '../address/address-utils';
import {SubjectAttributeType} from '../../model/subject-attribute-type';
import {SearchOnlyAttribute, SubjectIdentifierType} from '../subject-search.model';

export function getSearchSubjectFormGroup(initialValue?: Nullable<SubjectRecordFindDto>, withImportantFieldFlags = true) {
  const form = new IczFormGroup({
    classification: new IczFormControl<Nullable<SubjectRecordClassification>>({
      value: null,
      disabled: true
    }, []),
    identityType: new IczFormControl<Nullable<SubjectIdentifierType>>(SubjectIdentifierType.DATA_BOX, []),
    [SearchOnlyAttribute.DATA_BOX]: new IczFormControl<Nullable<string>>(null, [SharedBusinessValidators.isValidDataboxIdentifier()]),
    [SearchOnlyAttribute.ADDRESS]: createAddressFormGroup(),
    [SearchOnlyAttribute.EMAIL]: new IczFormControl<Nullable<string>>({
      value: null,
      important: withImportantFieldFlags,
      disabled: false
    }, []),
    [SubjectAttributeType.IDENTITY_CARD_ID]: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(2)]),
    [SubjectAttributeType.DRIVING_LICENCE_ID]: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(2)]),
    [SubjectAttributeType.PASSPORT_ID]: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(2)]),
    [SubjectAttributeType.CLIENT_ID]: new IczFormControl<Nullable<string>>(null, [IczValidators.minLength(2)]),
    [SubjectAttributeType.FIRST_NAME]: new IczFormControl<Nullable<string>>({
      value: null,
      important: withImportantFieldFlags,
      disabled: false
    }, [IczValidators.minLength(2)]),
    [SubjectAttributeType.SURNAME]: new IczFormControl<Nullable<string>>({
      value: null,
      important: withImportantFieldFlags,
      disabled: false
    }, [IczValidators.minLength(2)]),
    [SubjectAttributeType.BIRTH_DATE]: new IczFormControl<Nullable<string>>({
      value: null,
      important: withImportantFieldFlags,
      disabled: false
    }, []),
    [SubjectAttributeType.BUSINESS_NAME]: new IczFormControl<Nullable<string>>({
      value: null,
      important: withImportantFieldFlags,
      disabled: false
    }, [IczValidators.minLength(3)]),
    [SubjectAttributeType.CID]: new IczFormControl<Nullable<string>>({
      value: null,
      important: withImportantFieldFlags,
      disabled: false
    }, [SharedBusinessValidators.isCID()]),
  });
  if (initialValue) {
    form.patchValue(initialValue!);
  }
  return form;
}

export type SubjectSearchFormData = ReturnType<typeof getSearchSubjectFormGroup>['value'];
