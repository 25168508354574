import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map, skip, startWith, switchMap, take} from 'rxjs/operators';
import {
  ApiConversionService,
  ApiDigitalComponentService,
  DigitalComponentCompleteDto,
  DocumentSignRequest
} from '|api/component';
import {DocumentSignResponse} from '|api/document';
import {DialogService, DialogSeverity, IczModalService} from '@icz/angular-modal';
import {
  BulkComponentSignResponse,
  PreSignatureValidationDialogData,
  SignatureConfigurationDialogData,
  SignatureFlowType
} from '../models/signature-dialog.model';
import {
  DigitalComponentPreSignatureValidationDialogComponent,
  PreSignatureValidationDialogActionAfterClose,
  PreSignatureValidationDialogResult
} from '../../digital-component-signature/digital-component-pre-signature-validation-dialog/digital-component-pre-signature-validation-dialog.component';
import {
  DigitalComponentSignatureConfigurationDialogComponent
} from '../../digital-component-signature/digital-component-signature-configuration-dialog/digital-component-signature-configuration-dialog.component';
import {
  DigitalComponentExistingSignatureDecisionDialogComponent
} from '../../digital-component-signature/digital-component-existing-signature-decision-dialog/digital-component-existing-signature-decision-dialog.component';
import {getLatestDigitalComponentVersion} from '../../shared-document.utils';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {InternalNotificationMessageCode} from '../../../../core/services/notifications/internal-notification.enum';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';


const signModalHeight = '85vh';

@Injectable({
  providedIn: 'root',
})
export class DigitalComponentSignatureDialogService {

  private modalService = inject(IczModalService);
  private dialogService = inject(DialogService);
  private apiConversionService = inject(ApiConversionService);
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private wsNotificationService = inject(WebSocketNotificationsService);

  openSignatureDialogFlow<TResponse>(
    signatureFlowType: SignatureFlowType,
    digitalComponentData: DigitalComponentCompleteDto[],
    signRequestFn: (documentId: number, signRequest: DocumentSignRequest) => Observable<TResponse>,
    signResponseSelector: (res: TResponse) => DocumentSignResponse | BulkComponentSignResponse,
  ): Observable<Nullable<TResponse>> {
    return this.loadModalWithPreSignatureValidation(signatureFlowType, digitalComponentData).pipe(
      switchMap(data => {
        if (data) {
          if (data.actionAfterClose === PreSignatureValidationDialogActionAfterClose.OPEN_SIGNING_DIALOG) {
            return of(data);
          }
          else if (data.actionAfterClose === PreSignatureValidationDialogActionAfterClose.OPEN_PRE_SIGNATURE_CONVERSION_DECISION_DIALOG) {
            return this.dialogService.openQuestionDialogWithAnswer({
              title: 'Komponenta není ve výstupním formátu',
              question: 'Komponenta není ve výstupním formátu, lze ji však převést do formátu, který podepsat lze.',
              questionContext: {},
              description: 'Převod může chvíli trvat. Přejete si pokračovat?',
              descriptionContext: {},
              leftButtonTitle: 'Převést a podepsat',
              rightButtonTitle: 'Storno',
            }).pipe(
              switchMap(shouldConvertComponent => {
                if (shouldConvertComponent) {
                  return this.convertComponentForSignatureDialog(digitalComponentData, data);
                }
                else {
                  return of(null);
                }
              })
            );
          }
          else if (data.actionAfterClose === PreSignatureValidationDialogActionAfterClose.OPEN_EXISTING_SIGNATURE_DECISION_DIALOG) {
            return this.modalService.openComponentInModal<Nullable<boolean>, void>({
              component: DigitalComponentExistingSignatureDecisionDialogComponent,
              modalOptions: {
                width: 600,
                height: 500,
                titleTemplate: 'Komponenta obsahuje podpisy',
              },
            }).pipe(
              switchMap(shouldConvertComponent => {
                if (shouldConvertComponent === true) {
                  return this.convertComponentForSignatureDialog(digitalComponentData, data);
                }
                else if (shouldConvertComponent === false) {
                  return of({
                    ...data,
                    signatureConfigurationDialogData: {
                      ...data.signatureConfigurationDialogData,
                      signableWithVisualSignatureOption: [],
                      signableWithoutVisualSignatureOption: data.signatureConfigurationDialogData.signableWithVisualSignatureOption,
                    }
                  });
                }
                else { // user cancelled the dialog
                  return of(null);
                }
              }),
            );
          }
        }

        return of(null);
      }),
      switchMap(data => {
        if (data) {
          const dialogData = data.signatureConfigurationDialogData;
          return this.openSignatureDialog(digitalComponentData, dialogData, signRequestFn, signResponseSelector);
        }
        else {
          return of(null);
        }
      }),
    );
  }

  openSignatureInvalidationDialog(invalidatedComponentsLabels: string[]) {
    return this.dialogService.openSimpleDialog({
      title: 'Editací komponent zneplatníte podpisy těchto komponent',
      content: invalidatedComponentsLabels.map(label => ({text: label})),
      severity: DialogSeverity.ERROR,
      leftButtonTitle: 'Pokračovat v editaci',
    });
  }

  private loadModalWithPreSignatureValidation(signatureFlowType: SignatureFlowType, digitalComponentData: DigitalComponentCompleteDto[]) {
    const multipleDigitalComponentsSelected = digitalComponentData.length > 1;
    const singleDigitalComponentDialogTitle = 'Validace podepsání komponenty {{fileName}}';
    const multipleDigitalComponentsDialogTitle = 'Validace podepsání komponent';

    const titleTemplateContext = {
      fileName: digitalComponentData[0].label!,
      length: (digitalComponentData?.length ?? 0).toString(),
    };

    const {documentId} = digitalComponentData[0];

    return this.modalService.openComponentInModal<Nullable<PreSignatureValidationDialogResult>, PreSignatureValidationDialogData>({
      component: DigitalComponentPreSignatureValidationDialogComponent,
      modalOptions: {
        titleTemplate: multipleDigitalComponentsSelected ? multipleDigitalComponentsDialogTitle : singleDigitalComponentDialogTitle,
        titleTemplateContext,
        width: 650,
        height: '80vh',
      },
      data: {
        digitalComponents: digitalComponentData,
        isBulkOperation: multipleDigitalComponentsSelected,
        documentId: documentId!,
        signatureFlowType,
      },
    });
  }

  private convertComponentForSignatureDialog(digitalComponentData: DigitalComponentCompleteDto[], data: PreSignatureValidationDialogResult) {
    this.apiConversionService.conversionConvertToOutputFormat(
      {
        digitalComponentVersionId: getLatestDigitalComponentVersion(digitalComponentData[0])!.id!,
      },
    ).subscribe();

    this.globalLoadingService.startLoading('Převod komponenty může chvíli trvat');

    return combineLatest([
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.CONVERSION_SUCCESS).pipe(
        startWith(null),
      ),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.CONVERSION_ERROR).pipe(
        startWith(null),
      ),
    ]).pipe(
      skip(1),
      take(1),
      switchMap(([success, error]) => {
        if (success) {
          const convertedDigitalComponentId = data.signatureConfigurationDialogData.signableWithVisualSignatureOption![0]!.id!;

          return this.apiDigitalComponentService.digitalComponentFindById({
            id: convertedDigitalComponentId,
          }).pipe(
            map(digitalComponent => {
              this.globalLoadingService.endLoading();

              return {
                actionAfterClose: PreSignatureValidationDialogActionAfterClose.OPEN_SIGNING_DIALOG,
                signatureConfigurationDialogData: {
                  ...data.signatureConfigurationDialogData,
                  signableWithVisualSignatureOption: [
                    {
                      ...digitalComponent,
                      fileName: digitalComponent.label,
                    }
                  ]
                }
              };
            }),
          );
        }
        else if (error) {
          this.globalLoadingService.endLoading();
          this.dialogService.showError(
            'Došlo k chybě při převádění komponenty.',
            undefined,
          );
          return of(null);
        }
        else {
          this.globalLoadingService.endLoading();
          return of(null);
        }
      }),
    );
  }

  private openSignatureDialog<TResponse>(digitalComponentData: DigitalComponentCompleteDto[], dialogData: Partial<SignatureConfigurationDialogData<unknown>>, signRequestFn: (documentId: number, signRequest: DocumentSignRequest) => Observable<TResponse>, signResponseSelector: (res: TResponse) => (DocumentSignResponse | BulkComponentSignResponse)) {
    return this.loadModalWithSignatureConfiguration(
      digitalComponentData,
      {
        documentId: dialogData.documentId!,
        isBulkOperation: dialogData.isBulkOperation!,
        signableWithVisualSignatureOption: dialogData.signableWithVisualSignatureOption,
        signableWithoutVisualSignatureOption: dialogData.signableWithoutVisualSignatureOption,
        signRequestFn,
        signResponseSelector,
      }
    );
  }

  private loadModalWithSignatureConfiguration<TResponse>(
    digitalComponentData: DigitalComponentCompleteDto[],
    data: SignatureConfigurationDialogData<TResponse>
  ): Observable<TResponse> {
    const getTitleTemplateContext = () => {
      const withVisibleCount = data.signableWithVisualSignatureOption?.length ?? 0;
      const withoutVisibleCount = data.signableWithoutVisualSignatureOption?.length ?? 0;
      const totalSignable = withVisibleCount + withoutVisibleCount;
      const withVisible = data.signableWithVisualSignatureOption;
      const withoutVisible = data.signableWithVisualSignatureOption;

      if (digitalComponentData.length > 1 && totalSignable === 1) {
        return {
          fileName: withVisible?.length ? withVisible[0].label! : withoutVisible![0].label!,
          length: digitalComponentsLength.toString(),
        };
      } else {
        return {
          fileName: digitalComponentData[0].label!,
          length: digitalComponentsLength.toString(),
        };
      }
    };

    const {signableWithVisualSignatureOption, signableWithoutVisualSignatureOption} = data;
    const singleDigitalComponentDialogTitle = 'Podepsání dokumentu';
    const multipleDigitalComponentsDialogTitle = 'Hromadné podepsání dokumentů';

    const digitalComponentsLength = (signableWithVisualSignatureOption?.length ?? 0) + (signableWithoutVisualSignatureOption?.length ?? 0);

    const isBulkOperation = digitalComponentsLength > 1;

    const titleTemplateContext = getTitleTemplateContext();

    return this.modalService.openComponentInModal<TResponse, SignatureConfigurationDialogData<TResponse>>({
      component: DigitalComponentSignatureConfigurationDialogComponent,
      modalOptions: {
        titleTemplate: isBulkOperation ? multipleDigitalComponentsDialogTitle : singleDigitalComponentDialogTitle,
        titleTemplateContext,
        width: 1200,
        height: signModalHeight,
        disableAutoMargin: true,
      },
      data: {...data, isBulkOperation},
    });
  }

}
