import {EsslComponentDto, EsslComponentSearchService} from '../../../services/essl-component-search.service';
import {FilterParam, IczTableDataSource} from '@icz/angular-table';

export class EsslComponentsTableDatasource extends IczTableDataSource<EsslComponentDto> {
  constructor(
    searchService: EsslComponentSearchService,
    documentId: number,
    additionalFilters?: FilterParam[]
  ) {
    super(searchParams => searchService.findEsslComponents(searchParams, documentId, additionalFilters));
  }
}
