import {ChangeDetectorRef, Directive, HostBinding, inject} from '@angular/core';
import {SubjectAttributesDto, SubjectAttributeSource, SubjectAttributeState, SubjectRecordDto} from '|api/commons';
import {
  TemplateCustomFieldDefinition
} from './envelope-or-label-custom-fields-form/envelope-or-label-custom-fields-form.component';
import {IczFormGroup} from '@icz/angular-form-elements';
import {
  TEST_BUSINESS_NAME,
  TEST_CZECH_ADDRESS,
  TEST_FIRST_NAME,
  TEST_GENERIC_LINE_ADDRESS,
  TEST_POBOX_ADDRESS,
  TEST_SALUTATION,
  TEST_SLOVAK_ADDRESS,
  TEST_SURNAME
} from './envelope-or-label-test-consignee-form/envelope-or-label-test-consignee-form.component';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {CodebookService} from '../../core/services/codebook.service';
import {RemoteBinaryFileDownloadService} from '../../services/remote-binary-file-download.service';
import {LocalBinaryFileDownloadService} from '../../services/local-binary-file-download.service';
import {PrintService} from './print.service';
import {GenericOwnElasticConsignmentWithConsignee} from './own-consignment-table/model/own-consignment-model';

export interface AbstractTemplatePrintDialogData {
  consignments?: Array<GenericOwnElasticConsignmentWithConsignee>; // paper, personal or internal paper
}

@Directive()
export abstract class AbstractTemplatePrintDialog<TPreviewRequest> {

  protected data = injectModalData<AbstractTemplatePrintDialogData>();
  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected codebookService = inject(CodebookService);
  protected remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  protected localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  protected printService = inject(PrintService);
  protected cd = inject(ChangeDetectorRef);

  abstract form: IczFormGroup;

  isTestingPrintMode = true;

  masterCustomFieldDefs: TemplateCustomFieldDefinition[] = [];
  customFieldDefs: TemplateCustomFieldDefinition[][] = [];

  previewDataUrls: Array<Nullable<string>> = [];

  @HostBinding('class.bulk-printing')
  get isBulkPrinting() {
    return (this.data.consignments?.length ?? 0) > 1;
  }

  abstract submit(): void;

  abstract cancel(): void;

  protected abstract requestVisualPreview(printTaskIndex: number): void;

  protected abstract getPreviewRequestDto(isForPreviewCanvas: boolean, printTaskIndex?: number): TPreviewRequest;

  protected abstract setUpCustomFieldDefs(): void;

  protected abstract getTestConsigneeSubjectDto(): SubjectRecordDto;

  protected getBusinessEntityTestConsignee(): SubjectRecordDto {
    const formValue = this.form.getRawValue();

    return {
      identifiable: false,
      classification: formValue.consigneeSubjectClassification,
      attributes: {
        businessName: {
          value: formValue.consigneeSubjectName,
          state: SubjectAttributeState.CORRECT,
          source: SubjectAttributeSource.INTERNAL,
          validFrom: new Date().toISOString(),
        }
      }
    };
  }

  protected getNaturalPersonTestConsignee(): SubjectRecordDto {
    const baseAttr = {
      source: SubjectAttributeSource.INTERNAL,
      state: SubjectAttributeState.CORRECT,
      validFrom: new Date().toISOString(),
      value: '',
    };

    const formValue = this.form.getRawValue();
    const attributes: SubjectAttributesDto = {
      firstName: {
        ...baseAttr,
        value: formValue.consigneeFirstName,
      },
      surname: {
        ...baseAttr,
        value: formValue.consigneeSurname,
      },
    };

    if (formValue.consigneeDegreeBeforeName) {
      attributes.degreeBefore = {
        ...baseAttr,
        value: formValue.consigneeDegreeBeforeName,
      };
    }
    if (formValue.consigneeDegreeAfterName) {
      attributes.degreeAfter = {
        ...baseAttr,
        value: formValue.consigneeDegreeAfterName,
      };
    }
    if (formValue.consigneeBirthDate) {
      attributes.birthName = {
        ...baseAttr,
        value: formValue.consigneeBirthDate,
      };
    }

    return {
      identifiable: false,
      classification: formValue.consigneeSubjectClassification,
      attributes
    };
  }

  protected patchFormWithTestData() {
    this.form.get('salutation')!.patchValue(TEST_SALUTATION);
    this.form.get('consigneeFirstName')!.patchValue(TEST_FIRST_NAME);
    this.form.get('consigneeSurname')!.patchValue(TEST_SURNAME);
    this.form.get('consigneeSubjectName')!.patchValue(TEST_BUSINESS_NAME);

    const addressForm = this.form.get('address')!;
    addressForm.get('value')!.get('CzechAddressDto')!.patchValue(TEST_CZECH_ADDRESS);
    addressForm.get('value')!.get('SlovakAddressDto')!.patchValue(TEST_SLOVAK_ADDRESS);
    addressForm.get('value')!.get('PostalBoxAddressDto')!.patchValue(TEST_POBOX_ADDRESS);
    addressForm.get('value')!.get('GenericLineAddressDto')!.patchValue(TEST_GENERIC_LINE_ADDRESS);
  }

}
