<div
  class="flex"
  cdkOverlayOrigin
  #tasksPopupOrigin="cdkOverlayOrigin"
  (click.stop)="taskIconClicked()"
>

  @if (task || activity) {
    <icz-icon
      [svgIcon]="stateColor"
      [iczTooltip]="tooltip"
      size="small"
    ></icz-icon>
  }

  <icz-popover
    [overlayOrigin]="tasksPopupOrigin"
    [isOpen]="isTasksPopupOpen"
    (onClose)="isTasksPopupOpen = false;"
    [waiting]="loadingIndicatorService.isLoading$(this) | async"
  >
    <div class="tasks-popup">
      @if (isTasksPopupOpen) {
        @if (noPermissionToViewActivities) {
          <icz-alert
            class="mx-16 my-32" severity="info"
            heading="Nemáte dostatečné oprávnění pro zobrazení informací o úkolech objektu."
          ></icz-alert>
          <span></span>

        } @else {
          <icz-quick-activity-preview
            class="block"
            [isMultiColumn]="true"
            [activityId]="currentActivityId"
            (circulationActionCompleted)="circulationActionCompleted.emit()"
          ></icz-quick-activity-preview>
          @if (!allActivitiesDisplayed) {
            @if (this.entityType && this.entityId && moreActivities.length > 0) {
              <icz-button [label]="moreActivitiesLabel" (onAction)="showMoreActivities()"></icz-button>
            }
          } @else {
            @for (activity of moreActivities;track activity) {
              <hr class="my-4 mx-16">
              <icz-quick-activity-preview
                class="block"
                [isMultiColumn]="true"
                [activity]="activity"
                [showLink]="showLink"
                (circulationActionCompleted)="circulationActionCompleted.emit()"
              ></icz-quick-activity-preview>
            }
          }
        }
      }
    </div>
  </icz-popover>
</div>
