@for (attributeValueFormGroup of multipleValuesControl?.controls; track attributeValueFormGroup) {
  @if (isMultiplicityOne) {
    <ng-container *ngTemplateOutlet="content"></ng-container>
  } @else {
    <icz-section [label]="numberedSectionLabel($index)" [showExpansionHint]="!boxed" [boxed]="boxed" [expanded]="initExpanded" class="mb-24">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </icz-section>
  }

  <ng-template #content>
    @if (!isReadonly && !isMultiplicityOne) {
      <icz-button svgIcon="delete" [disabled]="isVerifiedAttribute($any(attributeValueFormGroup))" (onAction)="deleteAttrValue($index)"
                  size="small" sectionHeaderAppendix class="mr-16" background="transparent"></icz-button>
    }
    @if (isEmail || isPhoneFax) {
      <ng-container [ngTemplateOutlet]="stringTemplate"
                    [ngTemplateOutletContext]="{$implicit: attributeValueFormGroup}"
      ></ng-container>
    }
    @if (isDataBox) {
      <ng-container [ngTemplateOutlet]="dataBoxTemplate"
                    [ngTemplateOutletContext]="{$implicit: {form: attributeValueFormGroup, $index}}"
      ></ng-container>
    }
    @if (isAdditionalAddress) {
      <ng-container [ngTemplateOutlet]="additionalAddressTemplate"
                    [ngTemplateOutletContext]="{$implicit: attributeValueFormGroup}"
      ></ng-container>
    }
    @if (isPostalBox) {
      <ng-container [ngTemplateOutlet]="poBoxTemplate"
                    [ngTemplateOutletContext]="{$implicit: attributeValueFormGroup}"
      ></ng-container>
    }
  </ng-template>
}

<!--  Add new attribute value button-->
<div class="row">
  @if (!isReadonly) {
    <icz-button background="white" noBorder svgIcon="add_new"
      (onAction)="addNewAttrValue()"
      [label]="addNewAttrValueLabel"
      [disabled]="!canAddNew"
    [iczTooltip]="disabledAddButtonTooltip"></icz-button>
  }
</div>

<!-- Individual templates below-->
<ng-template #stringTemplate let-attributeValueFormGroup>
  <ng-container [formGroup]="$any(attributeValueFormGroup)">
    <div class="row">
      <icz-form-field class="grow" [label]="stringAttributeLabel" formControlName="value"></icz-form-field>
    </div>
  </ng-container>
</ng-template>

<ng-template #dataBoxTemplate let-implicit>
  <icz-databox-with-type [form]="$any(implicit.form)"
                         groupName="."
                         [showDataboxType]="true"
                         (blockingAction)="setBlockingAction(implicit.i, $event)"></icz-databox-with-type>
</ng-template>

<ng-template #additionalAddressTemplate let-attributeValueFormGroup>
  <icz-address [form]="$any(attributeValueFormGroup)"
               groupName="."
               [wrapInSection]="false"
               [isReadonly]="isReadonly"
               [addressAttributeType]="SubjectAttributeType.ADDITIONAL_ADDRESS"
               [withAddressValidationButton]="true"
  >
  </icz-address>
</ng-template>

<ng-template #poBoxTemplate let-attributeValueFormGroup>
  <icz-address [form]="$any(attributeValueFormGroup)"
               groupName="."
               [wrapInSection]="false"
               [isReadonly]="isReadonly"
               [addressAttributeType]="SubjectAttributeType.POSTAL_BOX"
               [withAddressValidationButton]="true"
               [initExpanded]="false">
  </icz-address>
</ng-template>
