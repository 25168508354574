import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  Output
} from '@angular/core';
import {
  BasicFilterItem,
  CodebookFilterItem,
  EnumFilterItem,
  FilterItemValue, FilterWithDatasource,
  FilterWithList,
  NonemptyFilterItem, PageSelectorFilterItem
} from '../../filter.types';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DeferredColumnList, isListColumnDefinition} from '../../table.models';
import {GenericValueAccessor, IczOption, VALUE_ACCESSIBLE_COMPONENT} from '@icz/angular-form-elements';
import {FilterItemComponent} from '../filter-item/filter-item.component';
import {FilterOperator, getOperatorTranslationKey} from '../../table.utils';

/**
 * Filter item definition acceptable by the form filter.
 */
export type FormFilterItem = BasicFilterItem | ((EnumFilterItem | CodebookFilterItem ) & FilterWithList) | (PageSelectorFilterItem & FilterWithDatasource) ;

/**
 * A component which renders table filters as inline form controls.
 * These form controls can directly be used in reactive forms.
 */
@Component({
  selector: 'icz-form-filter',
  templateUrl: './form-filter.component.html',
  styleUrls: ['./form-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FilterItemComponent,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => FormFilterComponent),
  }],
})
export class FormFilterComponent implements IczOnChanges {

  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  /**
   * Value of the control in case the control is not used
   * in reactive forms but rather with data binding.
   */
  @Input()
  set value(newValue: Nullable<FilterItemValue>) {
    if (newValue !== this._value) {
      this._value = newValue;
      this.item = this.getFilterItem();
    }
  }
  get value(): Nullable<FilterItemValue> {
    return this._value;
  }

  /**
   * Filter behavior definition.
   */
  @Input({required: true})
  filterItem: Partial<FormFilterItem> = {};

  /**
   * Reactive form control key.
   */
  @Input({required: true})
  formControlName = '';

  /**
   * Controls whether the control should be disabled.
   */
  @Input()
  set fieldDisabled(newValue: Nullable<boolean>) { // not just [disabled] cause it clashes with another directive from ReactiveFormsModule
    this._fieldDisabledFromTemplate = newValue;
  }
  get fieldDisabled(): Nullable<boolean> {
    return this._fieldDisabledFromTemplate ?? this._fieldDisabledFromReactiveModel;
  }

  /**
   * Makes the form filter uncancelable (hides filter cancel button)
   */
  @Input()
  isStatic = true;

  /**
   * Used for notifying data-bound form control consumers about value changes.
   * In conjunction with value property can be used to implement two-way data binding.
   */
  @Output()
  valueChange = new EventEmitter<FilterItemValue>();

  /**
   * Fired on control blur, i.e. when the user closes filter popup window.
   */
  @Output()
  blur = new EventEmitter<void>();

  /**
   * Fired when user clears filter value to distinguish null value set
   * inside filter popup from explicit filter clear in filtering chip.
   */
  @Output()
  cancelClicked = new EventEmitter<boolean>();

  private _fieldDisabledFromTemplate: Nullable<boolean>;
  private _fieldDisabledFromReactiveModel: Nullable<boolean>;

  protected isListLoading = false;
  private resolvedList: Nullable<IczOption<any, any>[]>;

  protected item!: FormFilterItem;
  private _value: Nullable<FilterItemValue>;

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.formControlName || changes.filterItem) {
      this.item = this.getFilterItem();
    }
  }

  /**
   * @internal
   */
  ngOnInit() {
    if ((this.filterItem as FilterWithList).list$) {
      this.isListLoading = true;
      (this.filterItem as FilterWithList).list$!.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe({
        next: resolvedList => {
          this.resolvedList = resolvedList;
          this.isListLoading = false;
          this.cd.detectChanges();
        },
        error: _ => this.isListLoading = false,
      });
    }
  }

  protected valueChanged($event: FormFilterItem) {
    this.value = FilterItemValue.fromFilterItem($event);
    this.item = this.getFilterItem();
    this.valueChange.emit(this._value!);
  }

  protected getDeferredFilterList(): DeferredColumnList {
    if (isListColumnDefinition(this.filterItem)) {
      return {list: (this.resolvedList ?? ( this.filterItem as FilterWithList).list ?? []), isListLoading: this.isListLoading};
    }
    else {
      return {list: [], isListLoading: false};
    }
  }

  private getFilterOptionFromValue(): Nullable<IczOption<FilterOperator>> {
    if (this._value?.operator) {
      return {
        value: this._value?.operator,
        label: getOperatorTranslationKey(this._value?.operator),
      };
    }
    else {
      return undefined;
    }
  }

  private getFilterItem(): NonemptyFilterItem {
    return {
      id: this.formControlName,
      filterType: this.filterItem?.filterType!,
      label: String(this._value?.value ?? ''),
      filterOption: this.getFilterOptionFromValue()!,
      value: this._value?.value ?? '',
      subValues: this._value?.subValues,
      columnLabel: this.filterItem?.label ?? '',
      list: this.filterItem?.list ?? this._value?.list ?? [],
      ...(this.filterItem ?? {}),
    };
  }

}
