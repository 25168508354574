import {of} from 'rxjs';
import {filterAndSortArray} from './filter.utils';
import {IczTableDataSource} from './table.datasource';
import {dataToPage} from './table.utils';

type TableDataFactory = () => any[];

/**
 * A kind of DataSource which works with an unpaged in-memory array.
 * Useful if working with unpaged endpoints or if working with generic array data to be presented in table form.
 */
export class IczInMemoryDatasource extends IczTableDataSource<any> {
  /**
   * @param tableDataFactory a function with no parameters which returns an array of data.
   *                in case of data mutations, please call IczInMemoryDatasource#reload().
   */
  constructor(protected tableDataFactory: TableDataFactory) {
    super(searchParams => {
      const {page, size, filter, sort, fulltextSearchTerm} = searchParams;

      // the sorting process should not sort the original
      // array so imma create shallow copy
      return of(filterAndSortArray([...this.tableDataFactory()], filter, fulltextSearchTerm, sort)).pipe(
        dataToPage<any>(page, size)
      );
    });
  }

  /**
   * Changes tableDataFactory and performs an automatic data reload
   */
  setDataFactory(factory: TableDataFactory) {
    this.tableDataFactory = factory;
    this.reload(true);
  }
}
