import {Component, inject, Input, OnInit} from '@angular/core';
import {map, Observable} from 'rxjs';
import {FilterItemValue} from '../../filter.types';
import {TableToolbarService} from '../table-toolbar.service';
import {FULLTEXT_SEARCH_TERM_FILTER_ID} from '../saved-filters.service';

import {
  filterItemTreeToFilterItemValueTree,
  FilterItemValueTree,
  FilterTreeOperator,
  isSimpleQueryFilterTree
} from '../../filter-trees.utils';
import {SavedFiltersComponent} from '../saved-filters/saved-filters.component';
import {AsyncPipe} from '@angular/common';

/**
 * @internal
 */
@Component({
  selector: 'icz-table-saved-filters',
  templateUrl: './table-saved-filters.component.html',
  styleUrls: ['./table-saved-filters.component.scss'],
  standalone: true,
  imports: [
    SavedFiltersComponent,
    AsyncPipe,
  ],
})
export class TableSavedFiltersComponent implements OnInit {

  protected tableToolbarService = inject(TableToolbarService);

  @Input({required: true})
  tableIdForLocalStorage!: string;
  @Input({required: true})
  defaultFilterName$!: Observable<Nullable<string>>;
  @Input()
  allowSavingFiltersWithEmptyValues = true;

  protected activeFilterValues$!: Observable<FilterItemValueTree>;

  ngOnInit(): void {
    if (this.allowSavingFiltersWithEmptyValues) {
      this.activeFilterValues$ = this.tableToolbarService.visibleFilters$.pipe(
        map(visibleFilters => filterItemTreeToFilterItemValueTree({
          operator: FilterTreeOperator.NONE,
          values: visibleFilters,
        })),
      );
    }
    else {
      this.activeFilterValues$ = this.tableToolbarService.activeFilters$.pipe(
        map(activeFiltersTree => filterItemTreeToFilterItemValueTree(activeFiltersTree)),
      );
    }
  }

  protected loadSavedFilter(filterItemValuesTree: FilterItemValueTree) {
    this.tableToolbarService.clearAllFilters();

    const searchTermControl = this.tableToolbarService.toolbarForm.get('searchTerm')!;

    if (isSimpleQueryFilterTree(filterItemValuesTree)) {
      for (const filterValue of (filterItemValuesTree.values as FilterItemValue[])) {
        if (filterValue.id === FULLTEXT_SEARCH_TERM_FILTER_ID) {
          searchTermControl.setValue(filterValue.value as string);
        }
        else {
          this.tableToolbarService.addItemValue(filterValue);
        }
      }
    }
    else {
      searchTermControl.setValue(null);
      this.tableToolbarService.addTreeItems(filterItemValuesTree);
    }

    this.tableToolbarService.autoOpen = false;
    this.tableToolbarService.reloadFilters();
  }

}
