<div class="row gap-24 pb-4">
  <div class="col grow gap-24">
    @if (showRegistryOfficeGauges$ | async) {
      <icz-card>
        <div class="row grow px-24"><icz-heading label="Vyžadující pozornost" [level]="'3'"></icz-heading></div>
        <icz-dashboard-gauge-counter
          [linksTo]="registryOfficeToTakeOverRoute"
          name="K převzetí"
          [count]="registryOfficeToTakeOver$ | async"
        ></icz-dashboard-gauge-counter>
        <icz-dashboard-gauge-counter
          [linksTo]="toResolveIssuesRoute"
          name="K nápravě"
          [showWarnColor]="true"
          [count]="toResolveIssuesCount$ | async"
        ></icz-dashboard-gauge-counter>
        <icz-dashboard-gauge-counter
          [linksTo]="problemsRoute"
          name="Problémy"
          [showWarnColor]="true"
          [count]="problemsWithUnresolvedIncidentsCount$ | async"
        ></icz-dashboard-gauge-counter>
      </icz-card>
    }
    @if (showFPSubstitutions) {
      <icz-dashboard-substitutions-counter
        class="mb-24"
        [substitutionsCounterMode]="SubstitutionsCounterMode.FP_SUBSTITUTIONS"
      ></icz-dashboard-substitutions-counter>
    }
  </div>
  <div class="grow">
    @if (showUserSubstitutions) {
      <icz-dashboard-substitutions-counter
        [substitutionsCounterMode]="SubstitutionsCounterMode.USER_SUBSTITUTIONS"
      ></icz-dashboard-substitutions-counter>
    }
  </div>
  <div class="grow">
    @if (showNotificationsSection) {
      <icz-dashboard-unread-notifications-counter
      ></icz-dashboard-unread-notifications-counter>
    }
  </div>
</div>
