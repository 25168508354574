import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  GenericOwnElasticConsignmentWithConsignee,
  isOwnDigitalElasticConsignment,
  isOwnFaxElasticConsignment,
  isOwnInternalElasticConsignment,
  isOwnMultiPaperElasticConsignment,
  isOwnOfficeDeskElasticConsignment,
  isOwnPaperElasticConsignment,
  isOwnPersonalElasticConsignment,
  isOwnPortalElasticConsignment
} from '../../model/own-consignment-model';

@Component({
  selector: 'icz-consignment-table-consignee',
  templateUrl: './consignment-table-consignee.component.html',
  styleUrls: ['./consignment-table-consignee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsignmentTableConsigneeComponent {

  @Input({required: true})
  consignment!: GenericOwnElasticConsignmentWithConsignee;

  protected readonly isOwnInternalElasticConsignment = isOwnInternalElasticConsignment;
  protected readonly isOwnPaperElasticConsignment = isOwnPaperElasticConsignment;
  protected readonly isOwnOfficeDeskElasticConsignment = isOwnOfficeDeskElasticConsignment;
  protected readonly isOwnDigitalElasticConsignment = isOwnDigitalElasticConsignment;
  protected readonly isOwnFaxElasticConsignment = isOwnFaxElasticConsignment;
  protected readonly isOwnPortalElasticConsignment = isOwnPortalElasticConsignment;
  protected readonly isOwnPersonalElasticConsignment = isOwnPersonalElasticConsignment;
  protected readonly isOwnMultiPaperElasticConsignment = isOwnMultiPaperElasticConsignment;
}
