import {Component, EventEmitter, Input, Output} from '@angular/core';
import { FilterOperator } from '../../../table.utils';
import {FormAutocompleteComponent, IczFormDirective, IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {ButtonComponent} from '@icz/angular-essentials';
import {CdkDrag, CdkDragHandle} from '@angular/cdk/drag-drop';
import {ReactiveFormsModule} from '@angular/forms';

@Component({
  selector: 'icz-modal-table-filter-header',
  templateUrl: './modal-table-filter-header.component.html',
  styleUrls: ['./modal-table-filter-header.component.scss'],
  imports: [
    FormAutocompleteComponent,
    ButtonComponent,
    CdkDrag,
    CdkDragHandle,
    IczFormDirective,
    ReactiveFormsModule
  ],
  standalone: true
})
export class ModalTableFilterHeaderComponent {
  @Input({required: true})
  filterOperators!: Array<IczOption<FilterOperator>>;
  @Input({required: true})
  form!: IczFormGroup;
  @Output()
  close = new EventEmitter();
}
