/* tslint:disable */
/* eslint-disable */

/**
 * Type of consignment
 */
export enum ConsignmentType {
  OWN_PAPER_ADDRESS = 'OWN_PAPER_ADDRESS',
  OWN_PAPER_MULTI_ADDRESS = 'OWN_PAPER_MULTI_ADDRESS',
  OWN_DIGITAL_DATABOX = 'OWN_DIGITAL_DATABOX',
  OWN_DIGITAL_EMAIL = 'OWN_DIGITAL_EMAIL',
  OWN_DIGITAL_INTERNAL = 'OWN_DIGITAL_INTERNAL',
  OWN_PAPER_INTERNAL = 'OWN_PAPER_INTERNAL',
  OWN_PAPER_ON_PLACE = 'OWN_PAPER_ON_PLACE',
  RECEIVED_PAPER_INTERNAL = 'RECEIVED_PAPER_INTERNAL',
  RECEIVED_PAPER_ADDRESS = 'RECEIVED_PAPER_ADDRESS',
  RECEIVED_DIGITAL_INTERNAL = 'RECEIVED_DIGITAL_INTERNAL',
  RECEIVED_DIGITAL_DATABOX = 'RECEIVED_DIGITAL_DATABOX',
  RECEIVED_DIGITAL_EMAIL = 'RECEIVED_DIGITAL_EMAIL',
  OFFICE_DESK = 'OFFICE_DESK',
  TELEFAX = 'TELEFAX',
  PORTAL = 'PORTAL'
}
