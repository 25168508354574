import {inject, Injectable} from '@angular/core';
import {z} from 'zod';

import {InternalNotificationKey} from '|api/notification';
import {
  MessageType,
  SErrorParameters,
  SImplicitlyStringifiable,
  ToastService
} from '../../../components/notifications/toast.service';

export const SFileTemplateData = z.object({
  [InternalNotificationKey.FILE_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.FILE_SUBJECT]: SImplicitlyStringifiable,
});
export type FileTemplateData = z.infer<typeof SFileTemplateData>;

export const SFileErrorTemplateData = z.object({
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type FileErrorTemplateData = z.infer<typeof SFileErrorTemplateData>;

export const SFileBulkOperationCountData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type FileBulkOperationCountData = z.infer<typeof SFileBulkOperationCountData>;


export enum FileToastType {
  FILE_SETTLED = 'FILE_SETTLED',
  FILE_SETTLE_ERROR = 'FILE_SETTLE_ERROR',
  SIGN_FILE_HANDED = 'SIGN_FILE_HANDED',
  FILE_CREATE_SUCCESS = 'FILE_CREATE_SUCCESS',
  FILE_CREATE_ERROR = 'FILE_CREATE_ERROR',
  FILE_UPDATED = 'FILE_UPDATED',
  FILE_DESTROYED = 'FILE_DESTROYED',
  FILE_REOPENED_SUCCESS = 'FILE_REOPENED_SUCCESS',
  FILE_REOPEN_STARTED = 'FILE_REOPEN_STARTED',
  FILE_REOPENED_ERROR = 'FILE_REOPENED_ERROR',
  FILE_WITHDRAW_SETTLEMENT = 'FILE_WITHDRAW_SETTLEMENT',
  FILE_WITHDRAW_SETTLEMENT_STARTED = 'FILE_WITHDRAW_SETTLEMENT_STARTED',
  FILE_WITHDRAW_SETTLEMENT_ERROR = 'FILE_WITHDRAW_SETTLEMENT_ERROR',
  FILE_CLOSED = 'FILE_CLOSED',
  FILE_CLOSE_ERROR = 'FILE_CLOSE_ERROR',
  FILE_SETTLEMENT_STARTED = 'FILE_SETTLEMENT_STARTED',
  FILE_DEACTIVATED = 'FILE_DEACTIVATED',
  FILE_DEACTIVATION_ERROR = 'FILE_DEACTIVATION_ERROR',
  FILE_REACTIVATED = 'FILE_REACTIVATED',
  FILE_REACTIVATION_ERROR = 'FILE_REACTIVATION_ERROR',
  CROSS_REFERENCE_FILE_CREATED = 'CROSS_REFERENCE_FILE_CREATED',
  BULK_FILE_SETTLEMENT_START_SUCCESS = 'BULK_FILE_SETTLEMENT_START_SUCCESS',
  BULK_FILE_SETTLEMENT_START_ERROR = 'BULK_FILE_SETTLEMENT_START_ERROR',
  BULK_FILE_SETTLEMENT_CLOSE_SUCCESS = 'BULK_FILE_SETTLEMENT_CLOSE_SUCCESS',
  BULK_FILE_SETTLEMENT_CLOSE_ERROR = 'BULK_FILE_SETTLEMENT_CLOSE_ERROR',
  BULK_FILE_WITHDRAW_SETTLEMENT_STARTED = 'BULK_FILE_WITHDRAW_SETTLEMENT_STARTED',
  BULK_FILE_REOPEN_STARTED = 'BULK_FILE_REOPEN_STARTED',
  FILE_PRINT_INFO_CREATED = 'FILE_PRINT_INFO_CREATED',
  FILE_SETTLEMENT_SUSPEND_STARTED = 'FILE_SETTLEMENT_SUSPEND_STARTED',
  FILE_SETTLEMENT_RESUME_STARTED = 'FILE_SETTLEMENT_RESUME_STARTED',
  FILE_SETTLEMENT_SUSPEND_ERROR = 'FILE_SETTLEMENT_SUSPEND_ERROR',
  FILE_SETTLEMENT_RESUME_ERROR = 'FILE_SETTLEMENT_RESUME_ERROR',
  FILE_RENDITION_DOWNLOADED = 'FILE_RENDITION_DOWNLOADED',
  FILE_RENDITION_APPENDED_TO_DOCUMENT = 'FILE_RENDITION_APPENDED_TO_DOCUMENT',
  FILE_RETENTION_TRIGGER_YEAR_UPDATED = 'FILE_RETENTION_TRIGGER_YEAR_UPDATED',
  FILE_RETENTION_TRIGGER_CHECK_YEAR_UPDATED = 'FILE_RETENTION_TRIGGER_CHECK_YEAR_UPDATED',
  FILE_RETENTION_TRIGGER_YEAR_UPDATE_ERROR = 'FILE_RETENTION_TRIGGER_YEAR_UPDATE_ERROR',
  FILE_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR = 'FILE_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR',
  FILE_AGENDA_TRANSFER_STARTED = 'FILE_AGENDA_TRANSFER_STARTED',
  FILE_AGENDA_TRANSFER_ERROR = 'FILE_AGENDA_TRANSFER_ERROR',
  FILE_STRONG_CROSS_REFERENCE_CREATED = 'FILE_STRONG_CROSS_REFERENCE_CREATED',
  FILE_STRONG_CROSS_REFERENCE_CREATE_ERROR = 'FILE_STRONG_CROSS_REFERENCE_CREATE_ERROR',
}

@Injectable({
  providedIn: 'root',
})
export class FileToastService {

  private toastService = inject(ToastService);

  dispatchFileInfoToast(fileToastType: string, templateData: FileTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, fileToastType, templateData, SFileTemplateData);
  }

  dispatchFileErrorToast(fileToastType: string, templateData: FileErrorTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, fileToastType, templateData, SFileErrorTemplateData);
  }

  dispatchFileWarningToast(fileToastType: string, templateData: FileTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, fileToastType, templateData, SFileTemplateData);
  }

  dispatchSignFileHanded(templateData: FileTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, FileToastType.SIGN_FILE_HANDED, templateData, SFileTemplateData);
  }

  dispatchFileDestroyed(templateData: FileTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, FileToastType.FILE_DESTROYED, templateData, SFileTemplateData);
  }

  dispatchFileReopenedSuccess(templateData: FileTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, FileToastType.FILE_REOPENED_SUCCESS, templateData, SFileTemplateData);
  }

  dispatchFileReopenedError(templateData: FileErrorTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, FileToastType.FILE_REOPENED_ERROR, templateData, SFileErrorTemplateData);
  }

  dispatchBulkOperationStartedToast(fileToastType: string, templateData: FileBulkOperationCountData) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, fileToastType, templateData, SFileBulkOperationCountData);
  }

}
