import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

/**
 * A simple label which renders "Annotation: Label" style text section.
 */
@Component({
  selector: 'icz-annotated-label',
  templateUrl: './annotated-label.component.html',
  styleUrls: ['./annotated-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class AnnotatedLabelComponent {

  /**
   * Annotation denoting label category/meaning.
   */
  @Input({required: true})
  annotation!: string;
  /**
   * Label.
   */
  @Input({required: true})
  label!: Nullable<string | number>;

  /**
   * If true, displays both annotation and label on a single text line.
   * If false, annotation will be in bold text and above label.
   */
  @Input()
  set inline(inline: boolean|'') { this._isInline = (inline === true || inline === ''); }

  /**
   * Renders the annotated label with light blue background.
   */
  @Input()
  set highlight(highlight: boolean|'') { this._isHighlighted = (highlight === true || highlight === ''); }

  protected _isInline = false;
  protected _isHighlighted = false;

  protected readonly isNil = isNil;

}
