import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT} from '../api';
import {ConsignmentType, OwnConsignmentStatus} from '|api/commons';
import {ApiOwnConsignmentService} from '|api/sad';
import {SearchApiService} from './search-api.service';
import {
  GenericOwnElasticConsignment,
  GenericOwnElasticConsignmentWithConsignee
} from '../components/shared-business-components/own-consignment-table/model/own-consignment-model';
import {UnitViewSearchConfig} from './search-api-with-elastic.service';
import {FilterOperator, Page, SearchParams} from '@icz/angular-table';
import {extendConsignmentsWithConsignee} from './search-api-data-utils';
import {ApiSubjectRecordElasticService} from '|api/subject-register';

@Injectable({
  providedIn: 'root'
})
export class OwnConsignmentsSearchService extends SearchApiService {

  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);

  private processConsignmentTypeFilterOverride(searchParams: Partial<SearchParams>, consignmentTypes?: Nullable<ConsignmentType[]>){
    if (consignmentTypes?.length && !searchParams.filter!.find(f => f.fieldName === 'consignmentType')) {
      searchParams.filter!.push({fieldName: 'consignmentType', value: consignmentTypes.toString(), operator: FilterOperator.inSet});
    }
  }

  findOwnDocumentOwnConsignments(documentId: number, searchParams: Partial<SearchParams>): Observable<Page<GenericOwnElasticConsignmentWithConsignee>> {
    return this.searchApi<Page<GenericOwnElasticConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiOwnConsignmentService.OwnConsignmentFindOwnConsignmentsForOwnDocumentPath, {id: documentId}))
      .pipe(extendConsignmentsWithConsignee(this.apiSubjectRecordNgElasticService));
  }

  findReceivedDocumentOwnConsignments(documentId: number, searchParams: Partial<SearchParams>): Observable<Page<GenericOwnElasticConsignmentWithConsignee>> {
    return this.searchApi<Page<GenericOwnElasticConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiOwnConsignmentService.OwnConsignmentFindOwnConsignmentsForDocumentPath, {id: documentId}))
      .pipe(extendConsignmentsWithConsignee(this.apiSubjectRecordNgElasticService));
  }

  findOwnConsignmentsForDispatchOfficer(searchParams: Partial<SearchParams>,
                                        states: Array<OwnConsignmentStatus>,
                                        dispatchOfficeDistributionNodeIds?: number[],
                                        consignmentTypes?: Nullable<ConsignmentType[]>,
                                        ownPaperMultiConsignmentId?: Nullable<number>): Observable<Page<GenericOwnElasticConsignmentWithConsignee>> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    if (dispatchOfficeDistributionNodeIds?.length) {
      searchParams.filter!.push({fieldName: 'dispatchOfficeDistributionNodeId', value: dispatchOfficeDistributionNodeIds.toString(), operator: FilterOperator.inSet});
    }
    if (consignmentTypes?.includes(ConsignmentType.OWN_PAPER_ADDRESS)) {
      if (ownPaperMultiConsignmentId) {
        searchParams.filter!.push({fieldName: 'ownPaperMultiConsignmentId', value: ownPaperMultiConsignmentId.toString(), operator: FilterOperator.equals});
      } else {
        searchParams.filter!.push({fieldName: 'ownPaperMultiConsignmentId', value: null, operator: FilterOperator.empty});
      }
    }

    this.processConsignmentTypeFilterOverride(searchParams, consignmentTypes);
    return this.searchApi<Page<GenericOwnElasticConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindConsignmentsForDispatchOfficerPath)
      .pipe(extendConsignmentsWithConsignee(this.apiSubjectRecordNgElasticService));
  }

  countOfficeDeskConsignmentsForDispatchOfficer(searchParams: Partial<SearchParams>, states: Array<OwnConsignmentStatus>): Observable<number> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    return this.searchApi<number>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentCountOfficeDeskConsignmentsForDispatchOfficerPath);
  }

  findOfficeDeskConsignmentsForDispatchOfficer(searchParams: Partial<SearchParams>, states: Array<OwnConsignmentStatus>): Observable<Page<GenericOwnElasticConsignmentWithConsignee>> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    return this.searchApi<Page<GenericOwnElasticConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindOfficeDeskConsignmentsForDispatchOfficerPath)
      .pipe(extendConsignmentsWithConsignee(this.apiSubjectRecordNgElasticService));
  }

  findOfficeDeskConsignmentsForForOwner(searchParams: Partial<SearchParams>, states: Array<OwnConsignmentStatus>): Observable<Page<GenericOwnElasticConsignmentWithConsignee>> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    return this.searchApi<Page<GenericOwnElasticConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindOfficeDeskConsignmentsForOwnerPath)
      .pipe(extendConsignmentsWithConsignee(this.apiSubjectRecordNgElasticService));
  }

  findOwnConsignmentsForOwnerOrUnit(searchParams: Partial<SearchParams>,
                                    unitView: UnitViewSearchConfig,
                                    states: Array<OwnConsignmentStatus>,
                                    consignmentTypes?: Nullable<ConsignmentType[]>): Observable<Page<GenericOwnElasticConsignmentWithConsignee>> {
    this.addConsignmentStatesToSearchParams(states, searchParams);
    this.processConsignmentTypeFilterOverride(searchParams, consignmentTypes);
    if (consignmentTypes?.includes(ConsignmentType.OWN_PAPER_ADDRESS)) {
      searchParams.filter!.push({fieldName: 'ownPaperMultiConsignmentId', value: null, operator: FilterOperator.empty});
    }

    if (unitView.isUnitView) {
      if (!isNil(unitView.orgUnitId)) {
        return this.searchApi<Page<GenericOwnElasticConsignment>>(searchParams,
          applyPathParams(CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindConsignmentsForSecretariat_1Path, {orgUnitId: unitView.orgUnitId}))
          .pipe(extendConsignmentsWithConsignee(this.apiSubjectRecordNgElasticService));
      } else {
        return this.searchApi<Page<GenericOwnElasticConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindConsignmentsForSecretariatPath)
          .pipe(extendConsignmentsWithConsignee(this.apiSubjectRecordNgElasticService));
      }
    } else {
      return this.searchApi<Page<GenericOwnElasticConsignment>>(searchParams, CORE_MICROSERVICE_ROOT + ApiOwnConsignmentService.OwnConsignmentFindConsignmentsForOwnerPath)
        .pipe(extendConsignmentsWithConsignee(this.apiSubjectRecordNgElasticService));
    }
  }

  private addConsignmentStatesToSearchParams(states: Array<OwnConsignmentStatus>, searchParams: Partial<SearchParams>) {
    if (states && !searchParams.filter!.find(f => f.fieldName === 'status')) {
      searchParams.filter!.push({fieldName: 'status', value: String(states), operator: FilterOperator.inSet});
    }
  }

}
