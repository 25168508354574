<div [formGroup]="form" [waiting]="loadingIndicatorService.isLoading$(this) | async" class="pb-32">
  @if (isSubjectSearchMode) {
    <icz-alert
      severity="info" class="mb-16"
      heading="Před odesláním dotazu do ISZR zkontrolujte a doplňte povinné údaje."
    ></icz-alert>
    <div formGroupName="iszrSubjectIdentificationCriteria" class="row gap-32">
      <icz-section class="grow" label="Údaje subjektu">
        <icz-form-autocomplete
          [options]="classificationOptions"
          class="col grow"
          formControlName="classification"
          label="Typ subjektu"
        ></icz-form-autocomplete>
        @if (selectedClassification === SubjectRecordClassification.FO) {
          <icz-subject-identifier-input-form-fragment
            [form]="iszrSubjectIdentificationCriteriaForm"
          ></icz-subject-identifier-input-form-fragment>
          <div class="row">
            <icz-form-field class="grow" [formControlName]="SubjectAttributeType.FIRST_NAME" label="Jméno"></icz-form-field>
            <icz-form-field class="grow" [formControlName]="SubjectAttributeType.SURNAME" label="Příjmení"></icz-form-field>
          </div>
          <icz-date-picker [formControlName]="SubjectAttributeType.BIRTH_DATE" [selectableDates]="birthDateValidator"
                           class="grow" label="Datum narození"
          ></icz-date-picker>
        }
        @else {
          <div class="row">
            <icz-form-field class="grow" [formControlName]="SubjectAttributeType.CID" label="IČO"></icz-form-field>
            <icz-form-field class="grow" [formControlName]="SearchOnlyAttribute.DATA_BOX" label="ID datové schránky"></icz-form-field>
          </div>
          <icz-form-field class="grow" [formControlName]="SubjectAttributeType.BUSINESS_NAME" label="Obchodní název"></icz-form-field>
        }
      </icz-section>
      <div class="grow">
        @if (selectedClassification === SubjectRecordClassification.FO) {
          <icz-address
            label="Adresa v České republice"
            [wrapInSection]="true"
            [withAddressTypeSelection]="false"
            [showSectionExpansionHint]="false"
            [withAddressValidationButton]="true"
            [form]="addressForm"
            groupName="."
          ></icz-address>
        }
      </div>
    </div>
  }
  @else {
    @if (allowableSearchCombinations.length > 1) {
      <icz-alert
        severity="warning" class="mb-16"
        heading="Dotaz pro ztotožnění může být položen vícero způsoby."
      ></icz-alert>
      <icz-section label="Zvolte podobu dotazu do základních registrů">
        <icz-radio-group [formGroup]="searchCombinationForm" class="col gap-8 mb-16">
          @for (searchCombination of allowableSearchCombinations; track searchCombination) {
            <icz-radio-button formControlName="searchCombinationIndex" [value]="$index">
              <icz-alert class="query-combination" [showIcon]="false">
                <icz-subject-search-appendix content [searchCriteria]="searchCombination" [showIdentifiers]="true"></icz-subject-search-appendix>
              </icz-alert>
            </icz-radio-button>
          }
        </icz-radio-group>
      </icz-section>
    }
    @else if (allowableSearchCombinations.length === 1) {
      <icz-section label="Podoba dotazu do registru obyvatel">
        <icz-alert [showIcon]="false">
          <icz-subject-search-appendix content [searchCriteria]="allowableSearchCombinations[0]" [showIdentifiers]="true"></icz-subject-search-appendix>
        </icz-alert>
      </icz-section>
    }
  }

  <icz-section label="Agenda a důvod dotazu">
    <div formGroupName="iszrRequestMetadata" class="row gap-32">
      <icz-form-autocomplete class="grow activity-role-selector" formControlName="iszrRppId" label="Agendová činnostní role" [options]="agendaActivityRoleOptions"></icz-form-autocomplete>
      <icz-form-field class="grow" formControlName="requestReason" label="Důvod dotazu"></icz-form-field>
    </div>
  </icz-section>
</div>

<icz-form-buttons>
  <div leftButtons class="row items-center">
    <icz-button iczFormSubmit
                [disabled]="!form.valid || !isQueryCombinationValid || loadingIndicatorService.isLoading(this)"
                [iczTooltip]="submitButtonTooltip"
                (onAction)="submit()"
                primary label="Ztotožnit v ISZR"
    ></icz-button>
    @if (showValueCorrection) {
      <icz-checkbox
        [value]="$any(!valueCorrectionMode)" (valueChange)="valueCorrectionMode = !valueCorrectionMode"
        [fieldDisabled]="valueCorrectionDisabled"
        label="Uchovat původní hodnotu pro pozdější vyhledávání" class="ml-16"
      ></icz-checkbox>
    }
  </div>
  <icz-button rightButtons
              [disabled]="loadingIndicatorService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
