<form class="col grow" [waiting]="loadingService.isLoading$(this) | async" [formGroup]="form">
  <div class="col grow">
    <div class="row grow gap-24">
      <icz-section label="Zobrazení sekcí na stránce Moje dokumenty">
        <icz-checkbox formControlName="attentionRequired" label="Sekce Vyžadující pozornost - povinná sekce, nelze odebrat" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="notifications" label="Sekce Upozornění" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="activities" label="Sekce Aktivity" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="fpSubstitutions" label="Sekce Vaše funkční místo je zastupované" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="userSubstitutions" label="Sekce Funkční místa, která zastupujete" class="mb-8"></icz-checkbox>
        <icz-checkbox formControlName="myTasks" label="Sekce Mnou zadané úkoly" class="mb-8"></icz-checkbox>
        <icz-radio-group>
          <div class="col gap-8 mt-8 ml-16">
            <icz-radio-button formControlName="tasksPeriod" label="Za posledních 24 hodin" [value]="TaskFilterPeriod.ONE_DAY"></icz-radio-button>
            <icz-radio-button formControlName="tasksPeriod" label="Za posledních 48 hodin" [value]="TaskFilterPeriod.TWO_DAYS"></icz-radio-button>
          </div>
        </icz-radio-group>
      </icz-section>
      <div>
        <icz-section class="grow" label="Zobrazení stránek na nástěnce">
          <icz-checkbox formControlName="showPageMyDocuments" label="Stránka Moje dokumenty" class="mb-8"></icz-checkbox>
          @if(displayShowPageFilingDispatch) {
            <icz-checkbox formControlName="showPageFilingDispatch" label="Stránka Podatelna a výpravna" class="mb-8"></icz-checkbox>
          }
          @if(displayShowPageRegistry) {
            <icz-checkbox formControlName="showPageRegistry" label="Stránka Spisovna" class="mb-8"></icz-checkbox>
          }
          @if(displayShowPageUnit) {
            <icz-checkbox formControlName="showPageUnit" label="Stránka Útvar" class="mb-8"></icz-checkbox>
          }
          <icz-checkbox formControlName="showPageStatistics" label="Stránka Statistiky" class="mb-8"></icz-checkbox>
        </icz-section>
        <icz-section class="grow" label="Jakou stránku chcete zobrazovat jako výchozí?">
          <icz-label label="Nastavte si stránku, kterou chcete po přihlášení do aplikace vidět jako první:" [hasNormalWhitespace]="true"></icz-label>
          <icz-radio-group>
            <div class="col gap-8 mt-8">
              <icz-radio-button formControlName="landingPage" label="Nástěnka" [value]="LandingPage.DASHBOARD"></icz-radio-button>
              <icz-radio-button formControlName="landingPage" label="Jako úvodní stránku zobrazit tu, na které jste skončili při posledním odhlášení z aplikace"
                                [value]="LandingPage.LAST_USED_PAGE"></icz-radio-button>
            </div>
          </icz-radio-group>
        </icz-section>
      </div>
    </div>
    <icz-alert severity="info" label="Pro zobrazení změn nastavení nástěnky je po uložení nutné obnovit stránku."></icz-alert>
  </div>
</form>
<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()" primary label="Uložit"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)"
              (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
