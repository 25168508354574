import {Component, Input} from '@angular/core';
import {IczFormGroup} from '@icz/angular-form-elements';

// todo(rb) delete that enum once BE introduces unified
//  abstraction for all signature/convertion endpoints
export enum PageCorner {
  TOP_LEFT = 'TOP_LEFT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  TOP_RIGHT = 'TOP_RIGHT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
}

@Component({
  selector: 'icz-page-corner-form-fragment',
  templateUrl: './page-corner-form-fragment.component.html',
  styleUrls: ['./page-corner-form-fragment.component.scss'],
})
export class PageCornerFormFragmentComponent {
  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName!: string;

  readonly PageCorner = PageCorner;
}
