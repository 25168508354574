import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  CategoryScale,
  Chart,
  ChartData,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import {LineChartConfig} from '../statistics-model';
import {CHART_COLORS_MAP, ChartElementColor, daysInBetween} from '../chart-utils';
import {IczOnChanges, IczSimpleChanges, LocalizedDatePipe, WINDOW} from '@icz/angular-essentials';
import {add} from 'date-fns';
import {fromEvent} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, startWith} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TooltipItem} from 'chart.js/dist/types';


@Component({
  selector: 'icz-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineChartComponent implements OnInit, AfterViewInit, IczOnChanges {
  private hostElement = inject(ElementRef);
  private destroyRef = inject(DestroyRef);
  private window = inject(WINDOW);
  private cd = inject(ChangeDetectorRef);

  @Input({required: true}) config!: LineChartConfig;

  @ViewChild('chart') private chartRef!: ElementRef;

  private chart!: Chart;

  chartWidth = 1000;
  localizedDatePipe = new LocalizedDatePipe();

  private windowResized$ = fromEvent(this.window, 'resize').pipe(
    startWith(null),
    map(_ => this.window.innerWidth),
    debounceTime(500),
    distinctUntilChanged(),
  );

  reinitGraph() {
    this.chartWidth = this.hostElement.nativeElement.clientWidth;
    this.cd.detectChanges();
    if (this.chart) {
      this.chart.destroy();
    }
    if (this.config) {
      this.setNewChart();
    }
  }

  ngOnInit() {
    Chart.register(LineController, LineElement, Filler, LinearScale, Title, CategoryScale, Tooltip, PointElement);
    this.windowResized$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.reinitGraph();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.reinitGraph();
    });
  }

  onTooltip(items: TooltipItem<any>[]) {
    const chartPoint = items[0];
    const xPoint = chartPoint.dataset.fullDataSet[chartPoint.dataIndex].x;
    return this.localizedDatePipe.transform(new Date(xPoint));
  }

  setNewChart() {
    this.chart = new Chart(this.chartRef.nativeElement, {
      type: 'line',
      data: this.toChartJsConfig(this.config),
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              title: this.onTooltip.bind(this),
            }
          }
        }
      },
    });
  }

  toChartJsConfig(config: LineChartConfig): ChartData<any> {
    const result: ChartData<any> = {...config};
    result.labels = this.xAxisRangeToLabels(config.xAxisRange);
    result.datasets = result.datasets.map(d => {
      const borderColor = (d.borderColor ?? ChartElementColor.BLUE) as ChartElementColor;
      const tension = d.tension ?? 0.1;
      const fill = d.fill ?? false;
      return {...d, borderColor: CHART_COLORS_MAP[borderColor], tension, fill};
    });
    return result;
  }

  xAxisRangeToLabels(dateRange: [string, string]): string[] {
    const minDate = new Date(dateRange[0]);
    const maxDate = new Date(dateRange[1]);
    let between = daysInBetween(minDate, maxDate);
    if (between < 0) between = between * -1;
    const labels: string[] = [];

    for (let i = 0; i <= between; i++) {
      const curr = add(new Date(dateRange[0]), {days: i});
      labels.push(this.localizedDatePipe.transform(curr));
    }
    return labels;
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.config.currentValue) {
      if (this.chart) {
        this.chart.data = this.toChartJsConfig(changes.config.currentValue);
        this.chart.update();
      }
      else if (this.chartRef?.nativeElement) {
        this.setNewChart();
      }
    }
  }

}
