import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {EssentialsModule} from '@icz/angular-essentials';
import {ErrorDialogComponent} from '../dialogs/error-dialog/error-dialog.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
    ErrorDialogComponent,
  ],
  exports: [
    ErrorDialogComponent,
  ],
  imports: [
    CommonModule,
    EssentialsModule,
    TranslateModule,
  ]
})
export class DialogsModule {
}
