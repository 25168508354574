<ng-container [formGroup]="form">
  @if (hasSomeSavedFunctionalPositions) {
    <icz-form-autocomplete
      formControlName="functionalPositionGroup"
      [iczAutoFocus]="hasSomeSavedFunctionalPositions"
      [label]="label"
      [customOptionTemplate]="deletableOptionItem"
      [options]="savedFunctionalPositions"
      >
      @if (loadingService.isLoading$(this) | async) {
        <icz-spinner
          suffix
          class="inline-block align-middle mr-8"
          [diameter]="16"
        ></icz-spinner>
      }
    </icz-form-autocomplete>
  }
</ng-container>

<ng-template #deletableOptionItem let-context>
  <div class="row grow">
    <div class="col grow">
      {{context.option.label}}
    </div>
    <icz-button svgIcon="delete" background="transparent" size="small"
      (onAction)="deleteSavedGroup($event, context.option)">
    </icz-button>
  </div>
</ng-template>
