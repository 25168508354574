import {Observable} from 'rxjs';
import {SharedFolderDto} from '|api/document';

export interface SharedFolderCreateUpdateDialogData {
  sharedFolder?: Nullable<SharedFolderDto>;
  isCreateMode: boolean;
}

/**
 * Necessary abstract class for avoiding cyclic dependency between shared and other modules,
 * dependency direction being reversed by providing services implementing this shared abstract
 */
export abstract class AbstractSharedFolderDialogsManagerService {
  abstract openSharedFolderCreateUpdateDialog(dialogData: SharedFolderCreateUpdateDialogData): Observable<Nullable<SharedFolderDto>>;
}
