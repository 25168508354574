  <icz-section [label]="digitalComponentsLabel" [showExpansionHint]="true">
    <icz-digital-components-upload-form [form]="form" [selectFiles]="selectFiles" [config]="digitalComponentsConfig"
                                        [businessRule]="businessRule"
                                        [type]="digitalComponentsUploadFormType" class="grow wide">
    </icz-digital-components-upload-form>
    <div class="row mt-16">
      <icz-button data-cy="digitalComponentComponent" (onAction)="selectFiles.emit()" [label]="openFileSelectLabel"  class="add-component-button"></icz-button>
      <div class="grow"></div>
    </div>
  </icz-section>
