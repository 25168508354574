<form class="col grow overflow-hidden" iczForm [formGroup]="form">
  @if (data.showParentDocumentInfo && parentDocument) {
    <icz-parent-document-info
      [parentDocument]="parentDocument"
      (documentLinkClicked)="parentDocumentLinkClicked()"
    ></icz-parent-document-info>
  }
  <div class="row items-center toolbar"
    cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container">
    @if (isLocked) {
      <icz-icon svgIcon="icon_lock"></icz-icon>
    }
    <icz-icon [svgIcon]="digitalComponentVersionFileIcon" [iczTooltip]="digitalComponentVersion?.fileName"></icz-icon>
    @if (hasExecutionDateClause || conversionRegistersExists) {
      <icz-icon svgIcon="conversion" [iczTooltip]="conversionTooltip"></icz-icon>
    }
    @if (hasCirculationActivity) {
      <icz-task-activity-state-icon
        [activity]="circulationActivity"
        [showLink]="false"
        class="visible-clickable-area"
      ></icz-task-activity-state-icon>
    }
    <icz-form-field
      class="digital-component-label grow-4"
      formControlName="label"
      placeholder="Název komponenty"
      [showValidationStatus]="false"
      cdkDragHandle cdkDragHandleDisabled
    ></icz-form-field>
    @if (isDigitalComponent) {
      <div class="col">
        <div class="version">{{ 'Verze' | translate }} {{ digitalComponentVersionNumber }}</div>
        @if (this.isLocked) {
          <icz-tag label="Uzamknuto"></icz-tag>
        }
        @else {
          @if (this.digitalComponentVersion?.extractionState; as extractionState) {
            <icz-tag
              [color]="getExtractionStateTagColor(extractionState)"
              [label]="extractionState | findInList:extractionStateOptions"
            ></icz-tag>
          }
        }
      </div>
    }
    <div class="grow"></div>
    @if (parentDocument) {
      <icz-essl-components-toolbar-buttons
        cdkDragHandle
        cdkDragHandleDisabled
        class="tools"
        [view]="DigitalComponentView.DIGITAL_COMPONENT_VIEWER"
        [disableAllButtons]="isReadonly"
        [parentDocument]="parentDocument"
        [selectedRows]="[esslComponent]"
        [digitalComponentVersionDownloadUrl]="downloadUrl"
        [digitalComponentVersionDownloadFilename]="digitalComponentVersionFileName"
        [digitalComponentVersionId]="digitalComponentVersionId!"
        (operationCompleted)="digitalComponentOperationCompleted($event)">
      </icz-essl-components-toolbar-buttons>
    }
    <icz-button [svgIcon]="isMaximized ? 'minim' : 'copy'" (onAction)="toggleMaximize()" background="transparent" cdkDragHandle cdkDragHandleDisabled size="small" disableRipple></icz-button>
    <icz-button svgIcon="close" (onAction)="close()" background="transparent" cdkDragHandle cdkDragHandleDisabled size="small" disableRipple></icz-button>
  </div>
  @if (!isMaximized) {
    <div class="form-bar">
      <div class="row grow">
        <div class="grow-1">
          <icz-form-field [label]="descriptionLabel" formControlName="description" [autoSizeMax]="6"></icz-form-field>
        </div>
        <div class="row grow-3">
          <icz-form-autocomplete
            class="grow-2"
            label="Vztah k dokumentu"
            formControlName="relationType"
            [options]="esslComponentRelationOptions"
            [clearable]="false"
          ></icz-form-autocomplete>
          @if (isMediumOrPhysicalItemComponent) {
            <icz-form-chip-input
              formControlName="type"
              class="grow-2"
              chipNamespace="icz-analog-component-create-form"
              [label]="physicalTypeLabel"
              [options]="analogComponentTypesOptions"
              [isMultiselect]="false"
            ></icz-form-chip-input>
          }
          @if (isDigitalComponent || isPaperComponent) {
            <icz-form-autocomplete
              class="grow-2" label="Původ" formControlName="originType" [options]="originTypeOptions" [clearable]="false"></icz-form-autocomplete>
          }
          <icz-checkbox
            formControlName="isFinal"
            class="grow-2"
            label="Komponenta je finální"
            [verticalAlignmentWithFormField]="true"
          ></icz-checkbox>
          @if (digitalComponentVersion?.digitalComponentVersionSignatureFlags?.length) {
            <icz-digital-component-flags
              [flags]="digitalComponentVersion?.digitalComponentVersionSignatureFlags"
              class="signature-flags-align-offset" [digitalComponentVersionId]="digitalComponentVersionId"
            ></icz-digital-component-flags>
          }
          @if (isPaperComponent) {
            <icz-button (onAction)="printComponentLabel()" class="mt-16 inline-block" label="Tisk štítku"></icz-button>
          }
          <icz-toggle-button
            #moreToggleButton
            label="Více" class="mt-16 inline-block"
            (onAction)="toggleAdvancedMetadata()"
          ></icz-toggle-button>
        </div>
      </div>
    </div>
  }
  @if (!canUserModifyComponentProfile && !isMaximized) {
    <icz-alert
      class="mx-16 my-32" severity="info"
      heading="Nemáte dostatečné oprávnění pro editaci metadat této komponenty."
    ></icz-alert>
  }
  @if (!canUserShowComponentContent) {
    <icz-alert
      class="mx-16 my-32" severity="info"
      [class.mt-0]="!canUserModifyComponentProfile"
      heading="Nemáte dostatečné oprávnění nahlížet na obsah této komponenty."
    ></icz-alert>
  }
  @if (showAdvancedMetadata && !isMaximized) {
    <icz-advanced-essl-component-metadata
      class="icz-workspace grow"
      [esslComponent]="esslComponent"
      [digitalComponentVersion]="digitalComponentVersion"
      [conversionRegisters]="conversionRegisters"
      [form]="form"
    ></icz-advanced-essl-component-metadata>
  }
  @if (isContainerExtracted) {
    <icz-section label="Obsah kontejneru" boxed noPadding>
      <icz-container-contents-table
        [digitalComponent]="digitalComponent"
        [digitalComponentVersionId]="digitalComponentVersionId!"
        [documentId]="esslComponent.documentId!"
      ></icz-container-contents-table>
    </icz-section>
  }
  <!-- using [hidden] instead of ngIf because rendering component content might be very performance taxing operation -->
  @if (canUserShowComponentContent) {
    <div
      class="grow overflow-auto canvas"
      [hidden]="showAdvancedMetadata && !isMaximized"
      [waiting]="!digitalComponentVersionFileContents && hasDigitalComponentVersionId"
      >
      @if (isContainer && !isContainerExtracted) {
        <icz-label label="Pro zobrazení obsahu kontejneru je nutno provést vytěžení kontejneru z menu Konverze." class="w-full h-full"></icz-label>
      }
      @else if (hasDigitalComponentVersionId) {
        <div class="h-full">
          @if (isCreatedByTemplate && !allParametersFilled) {
            <icz-alert [heading]="emptyParametersError" severity="error">
              <div content>
                <div class="row mt-8">
                  <icz-button label="Aktualizovat parametry" (onAction)="openUpdateParamsDialog()" [disabled]="isComponentFinal"></icz-button>
                </div>
              </div>
            </icz-alert>
          }
          <icz-file-contents-viewer
            [fileName]="digitalComponentVersionFileName"
            [fileContents]="digitalComponentVersionFileContents"
            [mimeType]="digitalComponentMimeType"
            (downloadRequested)="download()"
          ></icz-file-contents-viewer>
        </div>
      }
      @else {
        <icz-label label="Komponenta nemá digitální obsah." class="w-full h-full"></icz-label>
      }
    </div>
  }
  @if (form.dirty) {
    <icz-submit-bar
      [savingProgress]="loadingIndicatorService.isLoading(this)"
      (submit)="saveChanges()"
      (cancel)="cancelChanges()"
      isNewOrLabel="Uložit změny"
    ></icz-submit-bar>
  }
</form>
