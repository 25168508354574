<icz-section label="Údaje o zásilce">
  <div class="row">
    <icz-readonly-field class="grow" [value]="ownConsignment?.status" label="Stav zásilky" [options]="statusOptions"></icz-readonly-field>
  </div>
  <div class="row">
    <icz-readonly-field class="grow" [value]="ownConsignment?.uid?.uid" label="UID zásilky"></icz-readonly-field>
    <icz-readonly-field class="grow" [value]="outboundConsignmentIdentifier" label="Označení odchozí zásilky"></icz-readonly-field>
  </div>
  <div class="row">
    <icz-readonly-field class="grow" [value]="ownConsignment?.handoverDate | localizedDate" label="Předáno výpravně"></icz-readonly-field>
  </div>
  <div class="row">
    <icz-readonly-field class="grow" [value]="ownConsignment?.handoverFPId" label="Kdo předal" [options]="functionalPositionOptions$ | async"></icz-readonly-field>
  </div>
  <div class="row">
    <icz-readonly-field class="grow" [value]="ownConsignment?.takeoverDate | localizedDate" label="Převzato výpravnou"></icz-readonly-field>
  </div>
  @if (!isOfficeDeskConsignment) {
    <div class="row">
      <icz-readonly-field class="grow" [value]="ownConsignment?.dispatchDate | localizedDate" label="Vypraveno"></icz-readonly-field>
    </div>
  }
  @if (isOfficeDeskConsignment) {
    <div class="row">
      <icz-readonly-field class="grow" [value]="ownConsignmentAsOfficeDesk?.posted | localizedDate" label="Vyvěšeno od"></icz-readonly-field>
    </div>
  }
  @if (isOfficeDeskConsignment) {
    <div class="row">
      <icz-readonly-field class="grow" [value]="ownConsignmentAsOfficeDesk?.unposted | localizedDate" label="Svěšeno dne"></icz-readonly-field>
    </div>
  }
  <div class="row">
    <icz-readonly-field class="grow" [value]="ownConsignmentAsOfficeDesk?.deliveryConfirmationRequired | localizedBoolean" label="Vyžaduje potvrzení dodání"></icz-readonly-field>
  </div>
</icz-section>
