import {ChangeDetectionStrategy, Component} from '@angular/core';
import {TableColumnsData} from '../table-columns-data';
import {TableConfig} from '../table.models';
import {IczTableDataSource} from '../table.datasource';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {PopupSelectorTableComponent} from './popup-selector-table/popup-selector-table.component';

/**
 * @internal
 */
export interface PopupSelectorTableDialogData<TColumnId extends string> {
  tableId: string;
  tableTitle: string;
  tableTitleTag: string;
  schema: TableColumnsData<TColumnId>;
  dataSource: IczTableDataSource<any>;
  tableConfig: Partial<TableConfig<TColumnId>>;
}

/**
 * @internal
 */
@Component({
  selector: 'icz-popup-selector-table-dialog',
  templateUrl: './popup-selector-table-dialog.component.html',
  styleUrls: ['./popup-selector-table-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PopupSelectorTableComponent
  ]
})
export class PopupSelectorTableDialogComponent {
  protected modalRef = injectModalRef<unknown>();
  protected dialogData = injectModalData<PopupSelectorTableDialogData<string>>();

  protected tableConfig!:  TableConfig<string>;

  protected rowClickCallback(row: unknown) {
    this.modalRef.close(row);
  }
}
