import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, DestroyRef,
  EventEmitter,
  inject,
  Input, OnInit,
  Output
} from '@angular/core';
import {DeliveryTypeDto, DistributionClassDto} from '|api/codebook';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {ReceivedDocumentUsage} from '../received-consignments.model';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {CurrentSessionService} from '../../../services/current-session.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

@Component({
  selector: 'icz-received-document-with-handover-form',
  templateUrl: './received-document-with-handover-form.component.html',
  styleUrls: ['./received-document-with-handover-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivedDocumentWithHandoverFormComponent implements IczOnChanges, OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  private applicationConfig = inject(ApplicationConfigService);
  private currentSessionService = inject(CurrentSessionService);
  private destroyRef = inject(DestroyRef);

  get receivedDocumentFormGroup() {
    return this.form?.get('receivedDocumentWithComponentsCreateBaseDto') as IczFormGroup;
  }
  get ownerFunctionalPositionIdControl() {
    return this.form?.get('ownerFunctionalPositionId');
  }
  get ownerOrganizationalUnitIdControl() {
    return this.form?.get('ownerOrganizationalUnitId');
  }

  get isInternalConsignment() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_INTERNAL_CONSIGNMENT;
  }

  @Input({required: true}) form!: IczFormGroup;
  @Input({required: true}) distributionClass: Nullable<DistributionClassDto>;
  @Input() resetForm!: Nullable<EventEmitter<void>>;
  @Input() deliveryType: Nullable<DeliveryTypeDto>;
  @Input() receivedDocumentUsage = ReceivedDocumentUsage.CREATE_FILING_OFFICE_PAPER_CONSIGNMENT;
  @Input() canAddAnalogComponentsByOpenedState: Nullable<boolean>;
  @Input() disableAssignRegNr = false;
  @Output() canAddDigitalComponentsByDocumentForm = new EventEmitter<boolean>();

  ownerChangedWithOriginIds(v: Nullable<IczOption[] | IczOption>) {
    if (!v || Array.isArray(v)) return; //change condition if owner becomes multiselect
    if (v!.originId) {
      if (v.originId === 'fp') {
        this.ownerFunctionalPositionIdControl!.setValue(v.value);
        this.ownerOrganizationalUnitIdControl!.setValue(null);
      } else if (v.originId === 'ou') {
        this.ownerFunctionalPositionIdControl!.setValue(null);
        this.ownerOrganizationalUnitIdControl!.setValue(v.value);
      }
    }
  }

  private assignHandoverFunctionalPosition() {
    if (this.applicationConfig.receivedDocumentDeliveryWithoutForwarding && !this.isInternalConsignment) {
      this.form.get('formOwnerOrganizationEntityId')!.setValue(this.currentSessionService.currentUserFunctionalPosition!.code);
      this.ownerChangedWithOriginIds({value: this.currentSessionService.currentUserFunctionalPosition!.id, originId: 'fp', label: this.currentSessionService.currentUserFunctionalPosition!.name});
    }
  }

  ngOnInit() {
    this.assignHandoverFunctionalPosition();
    this.resetForm?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.assignHandoverFunctionalPosition();
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.distributionClass && changes.distributionClass.currentValue) {
      this.changeDetectorRef.detectChanges();
    }
  }
}
