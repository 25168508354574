import {inject, Injectable} from '@angular/core';
import {tap} from 'rxjs';
import {InternalNotificationKey} from '|api/notification';
import {OwnPaperConsignmentElasticDto} from '|api/sad';
import {ConsignmentsToastService, ConsignmentToastData} from '../../../consignment-dialog/consignments-toast.service';
import {PrepareOrDispatchDialogComponent} from './prepare-or-dispatch-dialog.component';
import {IczModalService} from '@icz/angular-modal';
import {
  dispatchDialogTitles,
  PrepareOrDispatchDialogData,
  PrepareOrDispatchDialogResult,
  PrepareOrDispatchDialogType,
} from '../../dispatch-dialogs.model';
import {constructBulkModalTitle} from '../../../../../lib/utils';
import {OwnConsignmentWorkflowAction} from '../../../consignment-dialog/consignment-dialog/consignment.model';

@Injectable({
  providedIn: 'root'
})
export class PrepareOrDispatchDialogService {

  private modalService = inject(IczModalService);
  private consignmentsToastService = inject(ConsignmentsToastService);

  openPrepareOrDispatchDialog(documentId: Nullable<number>, consignments: OwnPaperConsignmentElasticDto[], dialogType: PrepareOrDispatchDialogType) {
    const isBulk = consignments.length > 1;
    let titleTemplate = '';
    if (dialogType === PrepareOrDispatchDialogType.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION) {
      titleTemplate = dispatchDialogTitles[OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION];
    } else if (dialogType === PrepareOrDispatchDialogType.PREPARE_FOR_DISTRIBUTION) {
      titleTemplate = dispatchDialogTitles[OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION];
    } else if (dialogType === PrepareOrDispatchDialogType.MARK_AS_DISPATCH) {
      titleTemplate = dispatchDialogTitles[OwnConsignmentWorkflowAction.DISPATCH];
    }

    if (isBulk) titleTemplate = constructBulkModalTitle(titleTemplate);

    return this.modalService.openComponentInModal<Nullable<PrepareOrDispatchDialogResult>, PrepareOrDispatchDialogData>({
      component: PrepareOrDispatchDialogComponent,
      modalOptions: {
        titleTemplate,
        titleTemplateContext: {count: consignments.length.toString()},
        width: 800,
        height: 600,
        disableAutoMargin: true,
      },
      data: {
        type: dialogType,
        documentId,
        consignments,
      },
    }).pipe(
      tap(result => {
        if (result) {
          const isBulk = consignments.length > 1;
          if (isBulk) {
            const toastData: ConsignmentToastData = {
              [InternalNotificationKey.COUNT]: consignments.length,
            };

            this.consignmentsToastService.dispatchConsignmentBulkActionAccepted(toastData);
          } else {
            const toastData: ConsignmentToastData = {
              [InternalNotificationKey.CONSIGNMENT_ID]: result.consignment.id,
              [InternalNotificationKey.CONSIGNMENT_UID]: result.consignment.uid!.uid!,
            };
            if (documentId) {
              toastData[InternalNotificationKey.DOCUMENT_ID] = documentId;
            }

            if (result.resultType === PrepareOrDispatchDialogType.PREPARE_FOR_DISTRIBUTION) {
              this.consignmentsToastService.dispatchConsignmentPreparedForDistribution(toastData);
            } else if (result.resultType === PrepareOrDispatchDialogType.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION) {
              this.consignmentsToastService.dispatchConsignmentTakenOverAndPreparedForDistribution(toastData);
            } else if (result.resultType === PrepareOrDispatchDialogType.MARK_AS_DISPATCH) {
              this.consignmentsToastService.dispatchConsignmentMarkedAsDispatched(toastData);
            }
          }
        }
      }),
    );


  }

}
