import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/**
 * @internal
 */
const TRUE_STRING_VALUE = 'true';
/**
 * @internal
 */
const FALSE_STRING_VALUE = 'false';

/**
 * Parses boolean from its stringified representation "true"/"false".
 */
export function parseBoolean(value: Nullable<string>): Nullable<boolean> {
  if (value === TRUE_STRING_VALUE || value === FALSE_STRING_VALUE) {
    return value === TRUE_STRING_VALUE;
  }
  else {
    return null;
  }
}

/**
 * A function that formats a boolean value as Yes/No string.
 */
export function formatBoolean(translateService: TranslateService, value: Nullable<boolean>) {
  if (isNil(value)) {
    return '';
  }
  else {
    return translateService.instant(value ? 'Ano' : 'Ne');
  }
}

/**
 * A pipe that formats a boolean value as Yes/No string.
 */
@Pipe({
  name: 'localizedBoolean',
  pure: false, // false because it also depends on app locale
  standalone: true,
})
export class LocalizedBooleanPipe implements PipeTransform {

  private translateService = inject(TranslateService);

  transform(value: Nullable<boolean | string>): string {
    return formatBoolean(
      this.translateService,
      typeof(value) === 'string' ? parseBoolean(value) : value,
    );
  }

}
