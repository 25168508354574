import {Component, inject} from '@angular/core';
import {AvailableCirculationActionsDialogData} from './available-circulation-actions-dialog-model';
import {ExtendedCirculationTaskDto} from '../../../model/elastic-extended-entities.interface';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {injectModalData, injectModalRef} from '@icz/angular-modal';

@Component({
  selector: 'icz-available-circulation-actions-dialog',
  templateUrl: './available-circulation-actions-dialog.component.html',
  styleUrls: ['./available-circulation-actions-dialog.component.scss']
})
export class AvailableCirculationActionsDialogComponent {

  protected modalRef = injectModalRef<ExtendedCirculationTaskDto[]>();
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  protected modalData = injectModalData<AvailableCirculationActionsDialogData>();

  modalText = 'Vybraná operace není pro některé zvolené úkoly podporována ({{unavailableCount}}). Operace bude provedena pro validní úkoly ({{availableCount}}).';

  confirm() {
    this.modalRef.close(this.modalData.availableTasks);
  }

  getContinueLabel() {
    return this.translateParser.interpolate(this.translateService.instant(this.modalData.continueButtonText), {count: String(this.modalData.availableTasks.length)}) ?? this.translateService.instant('Pokračovat');
  }

  cancel() {
    this.modalRef.close([]);
  }

}
