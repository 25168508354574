import {DOCUMENT} from '@angular/common';
import {DestroyRef, Directive, ElementRef, HostListener, inject, Input, OnDestroy} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import tippy, {
  Instance as TippyInstance,
  Placement as TippyPlacement,
  Props as TippyProps,
  SingleTarget,
} from 'tippy.js';
import {IczOnChanges} from './icz-on-changes';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import Timeout = NodeJS.Timeout;

/**
 * @internal
 */
export const TRUNCATED_TEXT_TOOLTIP_DELAY = 1000; // ms

/**
 * @internal
 */
export const DISABLED_ELEMENT_TOOLTIP_DELAY = 500; // ms

/**
 * A tooltip wrapper around tippyJS with extra performance optimizations for frequent usage.
 */
@Directive({
  selector: '[iczTooltip]',
  exportAs: 'iczTooltip',
  standalone: true,
})
export class TooltipDirective implements IczOnChanges, OnDestroy {

  private element = inject(ElementRef);
  private translateService = inject(TranslateService);
  private destroyRef = inject(DestroyRef);
  private document = inject(DOCUMENT);

  /**
   * Tooltip content.
   * Can also be an HTML string so it is assumed that tooltip contents are from a safe source.
   */
  @Input({required: true})
  iczTooltip: Nullable<string> = '';
  /**
   * Additional tooltip content wrapper div class to add custom styling.
   */
  @Input()
  iczTooltipClass: string = '';
  /**
   * Delay after which the tooltip is displayed to the user after entering host element of this directive with mouse.
   */
  @Input()
  iczTooltipShowDelay: number = 500;
  /**
   * Tooltip content positioning relative to host element of this directive.
   */
  @Input()
  iczTooltipPosition: TippyPlacement = 'top-start';
  /**
   * If true, renders an arrow extending from tooltip contents pointing at the host element of this directive.
   */
  @Input()
  iczTooltipWithArrow: boolean = false;
  /**
   * Disables translation of inner tooltip content.
   */
  @Input()
  iczTooltipDisableTranslate: Nullable<boolean> = false;

  /**
   * @internal
   * Used to address very specific use-case related to icz-button collapsing.
   */
  tooltipButtonLabel: Nullable<string>;

  private lazyInitializationTimeout: Nullable<Timeout> = null;

  /**
   * [iczTooltip] has deferred initialization to first hover time
   * because tippy() initializer takes quite a long time
   *
   * Performance optimization:
   * - eventName.silent will run event handler outside Angular Zone
   */
  @HostListener('mouseenter.silent', ['$event'])
  protected lazyInitialize() {
    this.isAfterLazyInit = true;
    this.lazyInitializationTimeout = setTimeout(() => {
      if (!this.tippyInstance && this.isAfterLazyInit) {
        this.initTooltip();
        (this.tippyInstance as unknown as TippyInstance)?.show();
        this.isAfterLazyInit = false;
      }
    }, this.iczTooltipShowDelay);
  }

  @HostListener('mouseleave.silent', ['$event'])
  protected afterLazyInitializeLeave() {
    if (!this.tippyInstance && (this.isAfterLazyInit || this.lazyInitializationTimeout !== null)) {
      clearTimeout(this.lazyInitializationTimeout!);
      this.lazyInitializationTimeout = null;
      this.isAfterLazyInit = false;
    }
  }

  // Needs to be done because our tooltips currently work only with mouse
  // and programmatic refocusing to tooltip'd element should not show it.
  @HostListener('blur.silent', ['$event'])
  protected afterBlur() {
    this.destroyTooltip();
  }

  private tippyInstance: Nullable<TippyInstance>;
  private isAfterLazyInit = false;

  /**
   * @internal
   */
  ngOnInit() {
    this.translateService.onLangChange.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.reinitTooltip();
    });
  }

  /**
   * @internal
   */
  ngOnChanges() {
    if (this.tippyInstance) {
      this.reinitTooltip();
    }
  }

  /**
   * @internal
   */
  ngOnDestroy() {
    this.destroyTooltip();
  }

  private reinitTooltip() {
    this.destroyTooltip();
    this.initTooltip();
  }

  private initTooltip() {
    const nativeElement = this.element.nativeElement;

    if (
      (this.iczTooltip || this.tooltipButtonLabel) &&
      this.document.body.contains(nativeElement) // tooltip host element might have been ngIfed away in the meantime
    ) {
      const tippyOptions: Partial<TippyProps> = {
        // options configurable via @Inputs()

        // content html can't be indented in a better way due to whitespace preservation styling for the tooltips
        content: `<div class="${this.iczTooltipClass}">${this.tooltipButtonLabel ? ((this.iczTooltip ? '<strong>' : '') + this.translateService.instant(this.tooltipButtonLabel) + (this.iczTooltip ? '</strong>' : '') + '\n') : ''}${this.iczTooltip ? ( this.iczTooltipDisableTranslate ? this.iczTooltip : this.translateService.instant(this.iczTooltip)) : ''}</div>`,
        placement: this.iczTooltipPosition,
        arrow: this.iczTooltipWithArrow,
        delay: [this.iczTooltipShowDelay, 0],
        // static options
        allowHTML: true, // this assumes that all texts passed to tooltip content are from safe sources
        appendTo: document.body,
        animation: 'fade',
        duration: 200,
      };

      this.tippyInstance = tippy(nativeElement as SingleTarget, tippyOptions);
    }
  }

  private destroyTooltip() {
    this.tippyInstance?.destroy();
    this.tippyInstance = null;
  }

}
