import {ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, ViewEncapsulation} from '@angular/core';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';

@Component({
  selector: 'icz-html-outlet',
  templateUrl: './html-outlet.component.html',
  styleUrls: ['./html-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.ShadowDom, // will ensure that styles in this component and its descendants will get reset
})
export class HtmlOutletComponent implements IczOnChanges {

  @ViewChild('htmlContent')
  htmlContent!: ElementRef;

  @Input({required: true})
  html: Nullable<string>;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.html && this.html) {
      setTimeout(() => {
        const linkElements = Array.from<HTMLLinkElement>(
          this.htmlContent.nativeElement.querySelectorAll('a')
        );

        for (const linkElement of linkElements) {
          linkElement.target = '_blank';
        }
      }, 0);
    }
  }

}
