import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {CirculationTaskResolution, CirculationTaskType, EntityType} from '|api/commons';
import {CirculationActivityDto, CirculationTaskDto} from '|api/flow';
import {IczTagColor, LoadingIndicatorService} from '@icz/angular-essentials';
import {ACTIVITY_STATE_COLORS} from '../activity-state-tag/activity-state-tag.component';
import {ApiElasticsearchService, PageSearchRecordDto} from '|api/elastic';
import {TranslateService} from '@ngx-translate/core';
import {isDocumentEntity, isFileEntity} from '../shared-document.utils';
import {Observable, of} from 'rxjs';
import {SKIP_ERROR_DIALOG} from '../../../core/error-handling/http-errors';
import {HttpErrorResponse} from '@angular/common/http';
import {CommunicationErrorDialogService} from '../../../core/services/communication-error-dialog.service';

@Component({
  selector: 'icz-task-activity-state-icon',
  templateUrl: './task-activity-state-icon.component.html',
  styleUrls: ['./task-activity-state-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaskActivityStateIconComponent implements OnInit {
  // task and activity are mutually exclusive
  @Input()
  task: Nullable<CirculationTaskDto>;
  @Input()
  activity: Nullable<CirculationActivityDto>;
  @Input()
  entityId: Nullable<number>;
  @Input()
  entityType: Nullable<EntityType>;
  @Input()
  showLink = true;
  @Output()
  circulationActionCompleted = new EventEmitter<void>();

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private apiElasticsearchService = inject(ApiElasticsearchService);
  private translateService = inject(TranslateService);
  private communicationErrorDialogService = inject(CommunicationErrorDialogService);

  moreActivities: CirculationActivityDto[] = [];
  allActivitiesDisplayed = false;
  noPermissionToViewActivities = false;

  get stateColor(): string {
    let iconColor: Nullable<IczTagColor>;

    if (this.activity) {
      iconColor = this.activityStateColors[this.activity.activityState];
    }
    else if (this.task) {
      iconColor = this.taskResolutionColors[this.task.resolution];
    }

    return `task_${iconColor ?? 'grey'}`;
  }

  get tooltip(): string {
    if (this.task) {
      return `en.circulationTaskResolution.${this.task.resolution}`;
    }
    else if (this.activity) {
      return `en.circulationActivityState.${this.activity.activityState}`;
    }
    else {
      return '-';
    }
  }

  get currentActivityId() {
    if (this.activity) {
      return this.activity.id;
    } else if (this.task) {
      return this.task!.rootActivityId;
    } else {
      return null;
    }
  }

  ngOnInit() {
    let req$: Observable<Nullable<PageSearchRecordDto>> = of(null);
    if (isDocumentEntity(this.entityType)) {
      req$ = this.apiElasticsearchService.elasticsearchFindDocumentActivities({documentId: this.entityId!}, SKIP_ERROR_DIALOG);
    }
    else if (isFileEntity(this.entityType)) {
      req$ = this.apiElasticsearchService.elasticsearchFindFileActivities({fileId: this.entityId!}, SKIP_ERROR_DIALOG);
    }
    this.loadingIndicatorService.doLoading(req$, this)
      .subscribe({
        next: activites => {
          if (!activites) return;
          const firstActivityId = this.activity?.id || this.task!.rootActivityId;
          this.moreActivities = activites.content!.map(c => c.source).filter(a => a.id !== firstActivityId);
        },
        error: err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.noPermissionToViewActivities = true;
          } else {
            this.communicationErrorDialogService.showCommunicationError(err);
          }
        }
      });
  }

  isTasksPopupOpen = false;

  private readonly activityStateColors = ACTIVITY_STATE_COLORS;

  private taskResolutionColors: Record<CirculationTaskResolution, IczTagColor> = {
    [CirculationTaskResolution.IN_PROGRESS]: 'orange',
    [CirculationTaskResolution.ASSIGNED]: 'orange',
    [CirculationTaskResolution.DONE_SUCCESS]: 'green',
    [CirculationTaskResolution.DONE_FAILURE]: 'red',
    [CirculationTaskResolution.DONE_NEUTRAL]: 'grey',
  };

  taskIconClicked() {
    if (!(this.task && this.task.taskType === CirculationTaskType.TAKEOVER_ISSD)) {
      this.isTasksPopupOpen = true;
    }
  }

  get moreActivitiesLabel() {
    return `${this.translateService.instant('Všechny úkoly')} (${this.moreActivities.length + 1})`;
  }

  showMoreActivities() {
    this.allActivitiesDisplayed = true;
  }
}
