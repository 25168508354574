import {inject, Injectable} from '@angular/core';
import {InternalNotificationKey} from '|api/notification';
import {
  getToastBodyTemplateTranslateKey,
  getToastHeaderTemplateTranslateKey,
  MessageType,
  SErrorParameters,
  SImplicitlyStringifiable,
  ToastService
} from '../../../components/notifications/toast.service';
import {SDigitalComponentTemplateData} from './essl-component-toast.service';
import {ButtonType} from '../../../components/button-collection/button-collection.component';
import {SFileTemplateData} from './file-toast.service';
import {z} from 'zod';

export const SDocumentTemplateData = z.object({
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
});
export type DocumentTemplateData = z.infer<typeof SDocumentTemplateData>;

export const SDocumentTemplateDataError = z.object({
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type DocumentTemplateDataError = z.infer<typeof SDocumentTemplateDataError>;

export const SDocumentTemplateErrorData = z.object({
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type DocumentTemplateErrorData = z.infer<typeof SDocumentTemplateErrorData>;

export const SConsignmentTemplateData = z.intersection(
  SDocumentTemplateData,
  z.object({
    [InternalNotificationKey.CONSIGNMENT_UID]: SImplicitlyStringifiable,
    [InternalNotificationKey.CONSIGNMENT_NAME]: SImplicitlyStringifiable.optional(),
  }),
);
export type ConsignmentTemplateData = z.infer<typeof SConsignmentTemplateData>;

export const SInsertToFileTemplateData = z.intersection(
  SDocumentTemplateData,
  SFileTemplateData,
);
export type InsertToFileTemplateData = z.infer<typeof SInsertToFileTemplateData>;

export const SInsertToFileTemplateDataError = z.intersection(
  SDocumentTemplateData,
  SFileTemplateData,
);
export type InsertToFileTemplateDataError = z.infer<typeof SInsertToFileTemplateDataError>;

export const SMoveToAnotherFileTemplateData = z.intersection(
  SDocumentTemplateData,
  SFileTemplateData,
  z.object({
    [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
    [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
  }),
);
export type MoveToAnotherFileTemplateData = z.infer<typeof SMoveToAnotherFileTemplateData>;

export const SSettleDocumentStartedTemplateData = z.intersection(
  SDocumentTemplateData,
  z.object({
    cancelCallback: z.function(),
  })
);
export type SettleDocumentStartedTemplateData = z.infer<typeof SSettleDocumentStartedTemplateData>;

export const SSettleDocumentFailedTemplateData = z.intersection(
  SDocumentTemplateData,
  z.object({
    [InternalNotificationKey.FAILED_DIGITAL_COMPONENTS]: z.array(SDigitalComponentTemplateData),
    processCallback: z.function(),
    cancelCallback: z.function(),
  })
);
export type SettleDocumentFailedTemplateData = z.infer<typeof SSettleDocumentFailedTemplateData>;

export const SDispatchSignFileErrorTemplateData = z.intersection(
  SDigitalComponentTemplateData,
  z.object({
    [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
    [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
  })
);
export type DispatchSignFileErrorTemplateData = z.infer<typeof SDispatchSignFileErrorTemplateData>;

export const SDocumentTemplateWithLabelPrintData = z.intersection(
  SDocumentTemplateData,
  z.object({
    printDocumentLabel: z.function(),
  }),
);
export type DocumentTemplateWithLabelPrintData = z.infer<typeof SDocumentTemplateWithLabelPrintData>;

export const SDocumentBulkOperationCountData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type DocumentBulkOperationCountData = z.infer<typeof SDocumentBulkOperationCountData>;

export enum DocumentToastType {
  DOCUMENT_INSERTED_TO_FILE = 'DOCUMENT_INSERTED_TO_FILE',
  DOCUMENT_INSERTED_TO_FILE_ERROR = 'DOCUMENT_INSERTED_TO_FILE_ERROR',
  DOCUMENT_REMOVED_FROM_FILE = 'DOCUMENT_REMOVED_FROM_FILE',
  DOCUMENT_REMOVED_FROM_FILE_ERROR = 'DOCUMENT_REMOVED_FROM_FILE_ERROR',
  SETTLE_DOCUMENT_STARTED = 'SETTLE_DOCUMENT_STARTED',
  DOCUMENT_SETTLED = 'DOCUMENT_SETTLED',
  DOCUMENT_SETTLE_ERROR = 'DOCUMENT_SETTLE_ERROR',
  DOCUMENT_DUPLICATED = 'DOCUMENT_DUPLICATED',
  DOCUMENT_REGISTERED = 'DOCUMENT_REGISTERED',
  DOCUMENT_REGISTERED_ERROR = 'DOCUMENT_REGISTERED_ERROR',
  DOCUMENT_REREGISTERED = 'DOCUMENT_REREGISTERED',
  DOCUMENT_CONTENT_REGISTERED = 'DOCUMENT_CONTENT_REGISTERED',
  DOCUMENT_CONTENT_REGISTERED_ERROR = 'DOCUMENT_CONTENT_REGISTERED_ERROR',
  DOCUMENT_CREATED = 'DOCUMENT_CREATED',
  DOCUMENT_CREATION_ERROR = 'DOCUMENT_CREATION_ERROR',
  DOCUMENT_UPDATED = 'DOCUMENT_UPDATED',
  DOCUMENT_UPDATE_ERROR = 'DOCUMENT_UPDATE_ERROR',
  CONSIGNMENT_UPDATED = 'CONSIGNMENT_UPDATED',
  CONSIGNMENT_UPDATE_ERROR = 'CONSIGNMENT_UPDATE_ERROR',
  CROSS_REFERENCE_DOCUMENT_CREATED = 'CROSS_REFERENCE_DOCUMENT_CREATED',
  CROSS_REFERENCE_DELETED = 'CROSS_REFERENCE_DELETED',
  CROSS_REFERENCE_CREATE_ERROR = 'CROSS_REFERENCE_CREATE_ERROR',
  CROSS_REFERENCES_DELETE_ERROR = 'CROSS_REFERENCES_DELETE_ERROR',
  DOCUMENT_MOVED_TO_ANOTHER_FILE = 'DOCUMENT_MOVED_TO_ANOTHER_FILE',
  DOCUMENT_MOVED_TO_ANOTHER_FILE_ERROR = 'DOCUMENT_MOVED_TO_ANOTHER_FILE_ERROR',
  WITHDRAW_DOCUMENT_SETTLEMENT_SUCCESS = 'WITHDRAW_DOCUMENT_SETTLEMENT_SUCCESS',
  WITHDRAW_DOCUMENT_SETTLEMENT_ERROR = 'WITHDRAW_DOCUMENT_SETTLEMENT_ERROR',
  MIGHT_TAKE_LONG_TIME_WARNING = 'MIGHT_TAKE_LONG_TIME_WARNING',
  DOCUMENT_DEACTIVATED = 'DOCUMENT_DEACTIVATED',
  DOCUMENT_DEACTIVATE_ERROR = 'DOCUMENT_DEACTIVATE_ERROR',
  DOCUMENT_REACTIVATED = 'DOCUMENT_REACTIVATED',
  DOCUMENT_REACTIVATE_ERROR = 'DOCUMENT_REACTIVATE_ERROR',
  DOCUMENT_BULK_ACTION_ERROR = 'DOCUMENT_BULK_ACTION_ERROR',
  DOCUMENT_BULK_ADD_TO_FILE_STARTED = 'DOCUMENT_BULK_ADD_TO_FILE_STARTED',
  DOCUMENT_BULK_REMOVE_FROM_FILE_STARTED = 'DOCUMENT_BULK_REMOVE_FROM_FILE_STARTED',
  DOCUMENT_BULK_MOVE_FROM_FILE_STARTED = 'DOCUMENT_BULK_MOVE_FROM_FILE_STARTED',
  DOCUMENT_BULK_REGISTER_STARTED = 'DOCUMENT_BULK_REGISTER_STARTED',
  DOCUMENT_BULK_REREGISTER_STARTED = 'DOCUMENT_BULK_REREGISTER_STARTED',
  DOCUMENT_BULK_SETTLEMENT_STARTED = 'DOCUMENT_BULK_SETTLEMENT_STARTED',
  DOCUMENT_BULK_WITHDRAW_SETTLEMENT_STARTED = 'DOCUMENT_BULK_WITHDRAW_SETTLEMENT_STARTED',
  DOCUMENT_PRINT_INFO_CREATED = 'DOCUMENT_PRINT_INFO_CREATED',
  EMAIL_CONSIGNMENT_CREATED = 'EMAIL_CONSIGNMENT_CREATED',
  EMAIL_CONSIGNMENT_CREATE_ERROR = 'EMAIL_CONSIGNMENT_CREATE_ERROR',
  SETTLEMENT_SUSPEND_STARTED = 'SETTLEMENT_SUSPEND_STARTED',
  BULK_SETTLEMENT_SUSPEND_STARTED = 'BULK_SETTLEMENT_SUSPEND_STARTED',
  SETTLEMENT_RESUME_STARTED = 'SETTLEMENT_RESUME_STARTED',
  BULK_SETTLEMENT_RESUME_STARTED = 'BULK_SETTLEMENT_RESUME_STARTED',
  SETTLEMENT_SUSPEND_ERROR = 'SETTLEMENT_SUSPEND_ERROR',
  BULK_SETTLEMENT_SUSPEND_ERROR = 'BULK_SETTLEMENT_SUSPEND_ERROR',
  SETTLEMENT_RESUME_ERROR = 'SETTLEMENT_RESUME_ERROR',
  BULK_SETTLEMENT_RESUME_ERROR = 'BULK_SETTLEMENT_RESUME_ERROR',
  BULK_RETENTION_TRIGGER_YEAR_UPDATED = 'BULK_RETENTION_TRIGGER_YEAR_UPDATED',
  BULK_RETENTION_TRIGGER_CHECK_YEAR_UPDATED = 'BULK_RETENTION_TRIGGER_CHECK_YEAR_UPDATED',
  BULK_RETENTION_TRIGGER_YEAR_UPDATE_ERROR = 'BULK_RETENTION_TRIGGER_YEAR_UPDATE_ERROR',
  BULK_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR = 'BULK_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR',
  DOCUMENT_RETENTION_TRIGGER_YEAR_UPDATED = 'DOCUMENT_RETENTION_TRIGGER_YEAR_UPDATED',
  DOCUMENT_RETENTION_TRIGGER_CHECK_YEAR_UPDATED = 'DOCUMENT_RETENTION_TRIGGER_CHECK_YEAR_UPDATED',
  DOCUMENT_RETENTION_TRIGGER_YEAR_UPDATE_ERROR = 'DOCUMENT_RETENTION_TRIGGER_YEAR_UPDATE_ERROR',
  DOCUMENT_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR = 'DOCUMENT_RETENTION_TRIGGER_CHECK_YEAR_UPDATE_ERROR',
  BULK_AGENDA_TRANSFER_STARTED = 'BULK_AGENDA_TRANSFER_STARTED',
  AGENDA_TRANSFER_STARTED = 'AGENDA_TRANSFER_STARTED',
  BULK_AGENDA_TRANSFER_ERROR = 'BULK_AGENDA_TRANSFER_ERROR',
  AGENDA_TRANSFER_ERROR = 'AGENDA_TRANSFER_ERROR',
  BULK_AGENDA_TRANSFER_REMOVE = 'BULK_AGENDA_TRANSFER_REMOVE',
  BULK_AGENDA_TRANSFER_REMOVE_ERROR = 'BULK_AGENDA_TRANSFER_REMOVE_ERROR',
  AGENDA_TRANSFER_CONFIRMED = 'AGENDA_TRANSFER_CONFIRMED',
  AGENDA_TRANSFER_CONFIRM_ERROR = 'AGENDA_TRANSFER_CONFIRM_ERROR',
  DOCUMENTS_WITHDRAWN_FROM_STORAGE_UNIT_FILLER = 'DOCUMENTS_WITHDRAWN_FROM_STORAGE_UNIT_FILLER',
}

@Injectable({
  providedIn: 'root',
})
export class DocumentToastService {

  private toastService = inject(ToastService);

  dispatchSimpleInfoToast(documentToastType: string) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, documentToastType);
  }

  dispatchSimpleWarningToast(documentToastType: string) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, documentToastType);
  }

  dispatchSimpleErrorToast(documentToastType: string) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, documentToastType);
  }

  dispatchBulkOperationStartedToast(documentToastType: string, templateData: DocumentBulkOperationCountData) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, documentToastType, templateData, SDocumentBulkOperationCountData);
  }

  dispatchBulkOperationToast(documentToastType: string, templateData: DocumentBulkOperationCountData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, documentToastType, templateData, SDocumentBulkOperationCountData);
  }

  dispatchDocumentInfoToast(documentToastType: string, templateData: DocumentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, documentToastType, templateData, SDocumentTemplateData);
  }

  dispatchDocumentErrorToast(documentToastType: string, templateData: DocumentTemplateErrorData) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, documentToastType, templateData, SDocumentTemplateErrorData);
  }

  dispatchDocumentWarningToast(documentToastType: string, templateData: DocumentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, documentToastType, templateData, SDocumentTemplateData);
  }

  dispatchConsignmentInfoToast(documentToastType: string, templateData: ConsignmentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, documentToastType, templateData, SConsignmentTemplateData);
  }

  dispatchDocumentInsertedToFile(templateData: InsertToFileTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, DocumentToastType.DOCUMENT_INSERTED_TO_FILE, templateData, SInsertToFileTemplateData);
  }

  dispatchDocumentRemovedFromFile(templateData: InsertToFileTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, DocumentToastType.DOCUMENT_REMOVED_FROM_FILE, templateData, SInsertToFileTemplateData);
  }

  dispatchDocumentMovedToAnotherFile(templateData: MoveToAnotherFileTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, DocumentToastType.DOCUMENT_MOVED_TO_ANOTHER_FILE, templateData, SMoveToAnotherFileTemplateData);
  }

  dispatchPrintLabelToast(templateData: DocumentTemplateWithLabelPrintData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(DocumentToastType.DOCUMENT_CREATED),
          templateData,
          templateDataSchema: SDocumentTemplateWithLabelPrintData,
        },
        buttons: [
          {
            type: ButtonType.SECONDARY,
            label: 'Tisk štítků',
            action: templateData.printDocumentLabel,
          },
        ],
      },
    });
  }

  dispatchSettleDocumentStarted(templateData: SettleDocumentStartedTemplateData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(DocumentToastType.SETTLE_DOCUMENT_STARTED),
          templateData,
          templateDataSchema: SSettleDocumentStartedTemplateData,
        },
        body: {
          template: getToastBodyTemplateTranslateKey(DocumentToastType.SETTLE_DOCUMENT_STARTED),
        },
        buttons: [
          {
            type: ButtonType.SECONDARY,
            label: 'Zrušit vyřízení',
            action: templateData.cancelCallback,
          },
        ],
      },
    });
  }

  dispatchSettleDocumentFailed(templateData: SettleDocumentFailedTemplateData) {
    this.toastService.dispatchToast({
      type: MessageType.ERROR,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(DocumentToastType.DOCUMENT_SETTLE_ERROR),
          templateData,
          templateDataSchema: SSettleDocumentFailedTemplateData,
        },
        body: {
          template: getToastBodyTemplateTranslateKey(DocumentToastType.DOCUMENT_SETTLE_ERROR),
          templateData,
          templateDataSchema: SSettleDocumentFailedTemplateData,
        },
        buttons: [
          {
            type: ButtonType.PRIMARY,
            label: 'Vyřídit',
            action: templateData.processCallback,
          },
          {
            type: ButtonType.SECONDARY,
            label: 'Storno vyřízení',
            action: templateData.cancelCallback,
          },
        ],
      },
    });
  }

  dispatchWithdrawSettlementSuccess(templateData: DocumentTemplateData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, DocumentToastType.WITHDRAW_DOCUMENT_SETTLEMENT_SUCCESS, templateData, SDocumentTemplateData);
  }

  dispatchWithdrawSettlementError(templateData: DocumentTemplateErrorData) {
    this.dispatchDocumentErrorToast(DocumentToastType.WITHDRAW_DOCUMENT_SETTLEMENT_ERROR, templateData);
  }

}
