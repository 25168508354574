import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnInit} from '@angular/core';
import {getOptionsSubtree, IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {ApiSharedFolderService, SharedFolderDto} from '|api/document';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {Observable} from 'rxjs';

export enum SharedFolderSelectorView {
  MANAGEABLE_FOLDERS = 'MANAGEABLE_FOLDERS',
  FOLDERS_SUITABLE_FOR_INSERTION = 'FOLDERS_SUITABLE_FOR_INSERTION',
}

@Component({
  selector: 'icz-shared-folder-selector',
  templateUrl: './shared-folder-selector.component.html',
  styleUrls: ['./shared-folder-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SharedFolderSelectorComponent implements OnInit {

  private cd = inject(ChangeDetectorRef);
  private loadingService = inject(LoadingIndicatorService);
  private apiSharedFolderService = inject(ApiSharedFolderService);

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName!: string;
  @Input({required: true})
  view!: SharedFolderSelectorView;
  @Input({required: true})
  label!: string;
  @Input()
  disabledFolderIds: Nullable<number[]>;
  @Input()
  disabledSubtreeRootId: Nullable<number>;

  options: IczOption<number, SharedFolderDto>[] = [];

  ngOnInit() {
    let sharedFolders$!: Observable<SharedFolderDto[]>;

    if (this.view === SharedFolderSelectorView.MANAGEABLE_FOLDERS) {
      sharedFolders$ = this.apiSharedFolderService.sharedFolderGetAllManageableFolders();
    }
    else if (this.view === SharedFolderSelectorView.FOLDERS_SUITABLE_FOR_INSERTION) {
      sharedFolders$ = this.apiSharedFolderService.sharedFolderGetAllFoldersSuitableForInsertion();
    }

    this.loadingService.doLoading(
      sharedFolders$,
      this
    ).subscribe(folders => {
      const sharedFolderOptions = folders.map(f => ({
        value: f.id,
        label: f.name,
        parent: f.parentId!,
        icon: 'dil_typoveho_spisu',
        data: f,
      }));

      let disabledFolderIds: number[] = [];

      if (this.disabledFolderIds && this.disabledSubtreeRootId) {
        console.warn(`Inputs disabledFolderIds and disabledSubtreeRootId can't be used together. No items in SharedFolderSelector will be disabled.`);
      }
      else if (this.disabledFolderIds) {
        disabledFolderIds = this.disabledFolderIds;
      }
      else if (this.disabledSubtreeRootId) {
        disabledFolderIds = getOptionsSubtree(sharedFolderOptions, this.disabledSubtreeRootId).map(o => o.value);
      }

      this.options = sharedFolderOptions.map(o => ({
        ...o,
        disabled: disabledFolderIds.includes(o.value),
      }));
      this.cd.markForCheck();
    });
  }

}
