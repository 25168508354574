<div (click.stop)="0">
  <mat-checkbox
    disableRipple
    class="mat-primary block mr-8 icz-body-1"
    [class.checkbox-align]="verticalAlignmentWithFormField"
    [indeterminate]="indeterminate"
    [checked]="value === true"
    [disabled]="fieldDisabled"
    (change)="_valueChanged($event)">
    {{label | translate}}
    <ng-content></ng-content>
  </mat-checkbox>
  @if (secondaryLabel) {
    <div class="icz-body-1 secondary-label">{{secondaryLabel | translate}}</div>
  }
</div>
