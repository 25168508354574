/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { ObjectSubjectsRelationDto } from '|api/commons';
import { SubjectObjectRelationDto } from '|api/commons';

export interface RelatedObjectLinkSubjectsToObject$Params {
  
    /**
     * DTO with related object and subject identifiers.
     */
    body: ObjectSubjectsRelationDto
}

export function relatedObjectLinkSubjectsToObject(http: HttpClient, rootUrl: string, params: RelatedObjectLinkSubjectsToObject$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SubjectObjectRelationDto>>> {
  const rb = new RequestBuilder(rootUrl, relatedObjectLinkSubjectsToObject.PATH, 'post');
  if (params) {
    rb.body(params.body, 'application/json');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<SubjectObjectRelationDto>>;
    })
  );
}

relatedObjectLinkSubjectsToObject.PATH = '/subject-object-relation/link-subjects-to-object';