<form iczAutoFocus class="grow" [formGroup]="form">
  @if (applicationConfigService.receivedDocumentRegistrationRequired) {
    <div class="items-baseline row grow mb-12">
      <icz-checkbox formControlName="assignRefNr" data-cy="assignRefNr" label="Přidělit číslo jednací"
      [iczTooltip]="disableAssignRegNr ? 'Zásilka do vlastních rukou nemůže mít přiděleno číslo jednací.' : ''"></icz-checkbox>
    </div>
  }

  <div class="row grow">
    <icz-form-field formControlName="subject" label="Věc" class="grow" [autoSizeMax]="3"></icz-form-field>
  </div>
  <div class="row grow">
    <icz-form-field formControlName="description" label="Popis" class="grow"></icz-form-field>
  </div>
  <div class="row grow">
    <icz-form-field formControlName="note" label="Poznámka" class="grow"></icz-form-field>
  </div>
  <div class="row grow">
    @if (!isFilingOfficeInternal) {
      <icz-form-field formControlName="relatedEvidence" label="Související evidence" class="grow"></icz-form-field>
    }
  </div>
  @if (applicationConfigService.receivedDocumentExternalIdEnabled) {
    <div class="row grow">
      <icz-form-field formControlName="externalId" label="Identifikátor dokumentu" class="grow"></icz-form-field>
    </div>
  }
  <div class="row grow">
    <icz-form-field formControlName="senderRefNr" label="Číslo jednací odesílatele" class="grow"></icz-form-field>
  </div>
  @if (!isFullDocument) {
    <icz-document-file-custom-fields-section
      [form]="form"
      [entityType]="EntityType.RECEIVED_DOCUMENT"
    ></icz-document-file-custom-fields-section>
  }
  @else {
    <div class="row grow">
      <icz-form-field formControlName="label" label="Značka" class="grow" ></icz-form-field>
      <icz-form-autocomplete formControlName="documentForm" label="Forma" class="grow" [options]="documentFormOptions"></icz-form-autocomplete>
    </div>
    <icz-section label="Zatřídění">
      <icz-document-type-selector
        [form]="form"
        controlName="documentTypeId"
        [showPopupDetails]="true"
      ></icz-document-type-selector>
      <icz-entity-class-selector
        [form]="form"
        controlName="entityClassId"
        [showPopupDetails]="true"
      ></icz-entity-class-selector>
      <icz-disposal-schedule-retention-trigger-widget
        [entityClassId]="entityClassId"
        [documentTypeId]="documentTypeId"
        [form]="form"
      ></icz-disposal-schedule-retention-trigger-widget>
    </icz-section>
    <icz-section label="Specifické">
      <icz-security-category-selector
        [form]="form"
        controlName="securityCategoryId"
      ></icz-security-category-selector>
      <icz-keywords-selector [form]="form" controlName="keywordIds" class="grow"></icz-keywords-selector>
      <icz-document-file-custom-fields-section
        [form]="form"
        [entityType]="EntityType.RECEIVED_DOCUMENT"
        [documentTypeId]="documentTypeId"
      ></icz-document-file-custom-fields-section>
    </icz-section>
    <icz-section label="Vyřízení">
      <div class="row grow">
        <icz-date-picker formControlName="resolutionDate" label="Termín vyřízení" [selectableDates]="presentOrFutureDateValidator" class="grow" outputFormat="dateTime"></icz-date-picker>
      </div>
      <div class="row grow">
        <icz-empowerment formControlName="empowerment"></icz-empowerment>
      </div>
    </icz-section>
  }
  @if (isFilingOfficeInternal) {
    <div class="row grow">
      <icz-empowerment formControlName="empowerment"></icz-empowerment>
    </div>
  }

  <icz-validation-errors-list [control]="esslComponentCountFormCtrl" [fieldsValidationErrors]="[]" [wrapInAlert]="true">
  </icz-validation-errors-list>

  @if (canAddAnalogComponents$ | async) {
    <icz-add-analog-component-to-document [form]="form" [analogComponentsConfig]="analogComponentsConfig" [resetForm]="resetForm"
    [showComponentDetailedInputToggle]="showComponentDetailedInputToggle"></icz-add-analog-component-to-document>
  }

</form>
