import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {get} from 'lodash';
import {
  FileSizePipe,
  IczOnChanges,
  IczSimpleChanges,
  LocalizedBooleanPipe,
  LocalizedDatePipe,
  LocalizedDatetimePipe,
  NumberToLocaleStringPipe,
  TooltipDirective
} from '@icz/angular-essentials';
import {getOptionsByValuesList} from '@icz/angular-form-elements';
import {TableTemplates} from '../table.models';
import {CodebookFilterDefinition, FilterType} from '../filter.types';
import {TableColumnsData} from '../table-columns-data';
import {distinctUntilChanged} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Subscription} from 'rxjs';
import {NgTemplateOutlet} from '@angular/common';

/**
 * @internal
 */
@Component({
  selector: 'icz-table-data-cell',
  templateUrl: './table-data-cell.component.html',
  styleUrls: ['./table-data-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    FileSizePipe,
    LocalizedDatePipe,
    LocalizedDatetimePipe,
    LocalizedBooleanPipe,
    NumberToLocaleStringPipe,
  ],
  standalone: true,
  imports: [
    TooltipDirective,
    NgTemplateOutlet,
  ],
})
export class TableDataCellComponent implements IczOnChanges {

  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private fileSizePipe = inject(FileSizePipe);
  private localizedDatePipe = inject(LocalizedDatePipe);
  private localizedDatetimePipe = inject(LocalizedDatetimePipe);
  private localizedBooleanPipe = inject(LocalizedBooleanPipe);
  private numberToLocaleStringPipe = inject(NumberToLocaleStringPipe);
  private translateService = inject(TranslateService);

  @Input({required: true})
  customTableCells: TableTemplates = {};
  @Input({required: true})
  columnId!: string;
  @Input({required: true})
  columnsData!: TableColumnsData<string>;
  @Input({required: true})
  row: Record<string, any> = {};

  private listLoadingStateChangeSubscription: Nullable<Subscription>;

  protected get column() {
    return this.columnsData?.getColumnById(this.columnId);
  }

  protected get formattedCellData(): string {
    const value = get(this.row, this.column!.id!);
    const translatePrefix = this.column!.translateWithPrefix ?? '';

    let unformattedValue: string;

    if (!isNil(value)) {
      unformattedValue = `${translatePrefix}${value}`;
    }
    else {
      unformattedValue = this.column!.nullValueText ?? '';
    }

    if (this.column!.filterType === FilterType.FILE_SIZE) {
      return this.fileSizePipe.transform(unformattedValue);
    }
    else if (this.column!.filterType === FilterType.DATE) {
      return this.localizedDatePipe.transform(unformattedValue);
    }
    else if (this.column!.filterType === FilterType.DATETIME) {
      return this.localizedDatetimePipe.transform(unformattedValue);
    }
    else if (this.column!.filterType === FilterType.BOOLEAN) {
      return this.localizedBooleanPipe.transform(unformattedValue);
    }
    else if (this.column!.filterType === FilterType.NUMBER && !this.column!.filterConfig?.disableLocalizedNumberFormatting) {
      return this.numberToLocaleStringPipe.transform(value);
    }
    else if (this.column!.filterType === FilterType.CODEBOOK || this.column!.filterType === FilterType.ENUM || this.column!.filterType === FilterType.PAGE_SELECTOR) {
      let foundOptionLabels: Array<string> = [];

      if (!isNil((this.column as CodebookFilterDefinition).list)) {
        foundOptionLabels = getOptionsByValuesList(
          (this.column as CodebookFilterDefinition).list!,
          Array.isArray(value) ? value : [value],
          (this.column as CodebookFilterDefinition).filterConfig?.originId,
        ).map(o => o.label);
      }
      else {
        foundOptionLabels = [value];
      }

      if (this.column!.allowTranslation) {
        if (!foundOptionLabels.length) {
          return this.column!.nullValueText ?? '';
        }
        else {
          return foundOptionLabels.map(
            label => this.translateService.instant(`${translatePrefix}${label}`)
          ).join(', ');
        }
      }
      else {
        return foundOptionLabels.join(', ');
      }
    }
    else {
      if (this.column!.allowTranslation) {
        if (unformattedValue === '') {
          return '';
        }
        else {
          return this.translateService.instant(unformattedValue);
        }
      }
      else {
        return unformattedValue;
      }
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if ((changes.columnsData || changes.columnId) && this.columnsData && this.columnId) {
      this.listLoadingStateChangeSubscription?.unsubscribe();
      this.listLoadingStateChangeSubscription = this.columnsData.listLoadingStatesChange$.pipe(
        distinctUntilChanged(
          (prev, curr) => prev[this.columnId] === false && curr[this.columnId] === true
        ),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(_ => {
        this.cd.markForCheck();
      });
    }
  }

}
