import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {ComponentWithContext, IAutocompleteListItemContext,} from '../form-autocomplete.model';
import {IconComponent} from '@icz/angular-essentials';
import {TranslateModule} from '@ngx-translate/core';
import {IczOption} from '../../form-elements.model';

/**
 * Implements rendering capabilities of all text-based option aspects.
 * Comprehensive docs explaining this component are in ComposableFormAutocomplete JSDoc
 */
@Component({
  selector: 'icz-form-autocomplete-list-text-item',
  templateUrl: './form-autocomplete-list-text-item.component.html',
  styleUrls: ['./form-autocomplete-list-text-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    TranslateModule,
  ]
})
export class FormAutocompleteListTextItemComponent implements ComponentWithContext<IAutocompleteListItemContext> {

  /**
   * Option to be rendered.
   */
  @Input({required: true})
  option!: IczOption;

  /**
   * Disables fixed option height mode.
   */
  @HostBinding('class.multiline')
  @Input()
  isMultiline = false;

}
