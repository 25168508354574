import {DigitalComponentVersionSignatureFlagType} from '|api/commons';
import {DigitalComponentVersionSignatureFlagDto} from '|api/component';
import {
  IczValidatorFn,
  ValidationErrorMessage
} from '@icz/angular-form-elements';
import {AbstractControl} from '@angular/forms';

export function epdzToEsslSignatureFlags(signatureFlagTypes: Nullable<DigitalComponentVersionSignatureFlagType[]>): DigitalComponentVersionSignatureFlagDto[] {
  if (!signatureFlagTypes) {
    return [];
  }
  else {
    return signatureFlagTypes.map(signatureFlagType => ({
      flag: signatureFlagType,
    }));
  }
}

export class AttachmentSubsetSelectorValidators {

  @ValidationErrorMessage('Musí být vybrána alespoň jedna příloha.')
  static atLeastOneAttachmentSelected(): IczValidatorFn {
    return (control: AbstractControl) => {
      return control.value?.length === 0 ? {atLeastOneAttachmentSelected: true} : null;
    };
  }

}
