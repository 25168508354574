import {inject, Injectable} from '@angular/core';
import {FilterItem, FilterValueFormatter} from '@icz/angular-table';
import {forkJoin, map, Observable} from 'rxjs';
import {SubjectRecordDto} from '|api/commons';
import {SubjectNamePipe} from '../../subjects/subject-name.pipe';
import {ApiSubjectRecordElasticService} from '|api/subject-register';

@Injectable()
export class SubjectFilterValueFormatterService implements FilterValueFormatter {

  private subjectNamePipe = inject(SubjectNamePipe);
  private apiSubjectRecordElasticService = inject(ApiSubjectRecordElasticService);

  format(filterItem: FilterItem): Observable<string> {
    const observables: Observable<SubjectRecordDto>[] = (filterItem.value! as string[])!.map(
      subjectId => this.apiSubjectRecordElasticService.subjectRecordElasticGet({subjectId: parseInt(subjectId!)})
    );

    return forkJoin(observables).pipe(
      map(subjects => {
        return subjects.map(s => this.subjectNamePipe.transform(s)).join(', ');
      }),
    );
  }

}
