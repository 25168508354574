import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {ConsignmentType, DeliveryResultEventType} from '|api/commons';
import {ApiOwnConsignmentService, DeliveryEventDto, OwnDigitalConsignmentDto} from '|api/sad';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';

@Component({
  selector: 'icz-databox-proof-of-delivery',
  templateUrl: './databox-proof-of-delivery.component.html',
  styleUrls: ['./databox-proof-of-delivery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataboxProofOfDeliveryComponent implements IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);

  @Input({required: true})
  consignmentId!: number;

  nullText = 'Nezadáno';

  deliveryEvents: DeliveryEventDto[] = [];
  fullConsignment: Nullable<OwnDigitalConsignmentDto>;

  get dispatchedAt() {
    if (this.fullConsignment && this.fullConsignment.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX) {
      return this.fullConsignment.deliveryEvents?.find(de => de.eventCode === DeliveryResultEventType.EV_5)?.eventDate;
    }
    else {
      return null;
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes && changes.consignmentId && isNil(this.fullConsignment)) {
      this.loadingService.doLoading(
        this.apiOwnConsignmentService.ownConsignmentGetOwnDigitalConsignment({id: this.consignmentId}),
        this
      ).subscribe(digiConsignment => {
        this.fullConsignment = digiConsignment;
        const sortedDeliveryEvents = [...this.fullConsignment.deliveryEvents ?? []];
        sortedDeliveryEvents.sort((de1, de2) => de1.eventDate < de2.eventDate ? 1 : -1);

        this.deliveryEvents = sortedDeliveryEvents;
      });
    }
  }
}
