/* eslint-disable @angular-eslint/directive-selector */
import {Directive, HostBinding, HostListener, inject, Input} from '@angular/core';
import {Router} from '@angular/router';

/**
 * Works similarly to [routerLink] but works for non-<a> elements plus adds "hand finger" cursor on top of its host.
 */
@Directive({
  selector: '[linksTo]',
  standalone: true,
})
export class LinksToDirective {

  private router = inject(Router);

  /**
   * Router link to navifate to.
   */
  @Input({required: true})
  linksTo = '';

  @HostBinding('class.cursor-pointer')
  protected get isClickable(): boolean {
    return this.linksTo !== '';
  }

  @HostListener('click')
  protected routeToLink() {
    this.router.navigateByUrl(this.linksTo);
  }

}
