import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NetworkUnavailablePageComponent} from './network-unavailable-page/network-unavailable-page.component';
import {
  ApplicationUnavailablePageComponent
} from './application-unavailable-page/application-unavailable-page.component';
import {NotFoundPageComponent} from './not-found-page/not-found-page.component';
import {NotImplementedComponent} from './not-implemented/not-implemented.component';
import {WaitingToBeComponent} from './waiting-to-be/waiting-to-be.component';
import {EssentialsModule} from '@icz/angular-essentials';
import {LibraryImportModule} from '../.module-library-import/library-import.module';

@NgModule({
  declarations: [
    NotImplementedComponent,
    WaitingToBeComponent,
    NotFoundPageComponent,
    NetworkUnavailablePageComponent,
    ApplicationUnavailablePageComponent,
  ],
  exports: [
    NotImplementedComponent,
    WaitingToBeComponent,
  ],
  imports: [
    CommonModule,
    EssentialsModule,
    LibraryImportModule,
  ]
})
export class SharedScreensModule { }
