/* tslint:disable */
/* eslint-disable */
export enum AuthorizedEntityType {
  DOCUMENT = 'DOCUMENT',
  FILE = 'FILE',
  OWN_CONSIGNMENT = 'OWN_CONSIGNMENT',
  RECEIVED_CONSIGNMENT = 'RECEIVED_CONSIGNMENT',
  RECEIVED_INTERNAL_MESSAGE = 'RECEIVED_INTERNAL_MESSAGE',
  COMPONENT = 'COMPONENT',
  STORAGE_UNIT = 'STORAGE_UNIT',
  SHARED_FOLDER = 'SHARED_FOLDER',
  AGENDA_TRANSFER = 'AGENDA_TRANSFER'
}
