<icz-validation-errors-list [control]="control">
  <icz-form-field class="selected-value-field"
    (blur)="blur.emit()"
    (inputClick)="openTable()"
    (keydown.arrowDown)="openTable()"
    (keydown.enter)="openTable()"
    (keydown.space)="openTable()"
    [showValidationStatus]="false"
    [hasClickableInput]="true"
    [fieldDisabled]="fieldDisabled"
    [hideInputElement]="true"
    [rightLabel]="rightLabel"
    [showRightLabelPopupOnClick]="true"
    [label]="label">
    <div class="internal-content" [class.internal-content-disabled]="fieldDisabled" [iczTooltip]="selectedValue ? selectedValue.viewValue : ''">
      @if (placeholder) {
        <span class="icz-select-placeholder">{{ placeholder }}</span>
      }
      <ng-container>
        {{ viewValue }}&nbsp;
      </ng-container>
    </div>
    <ng-container suffix>
      @if (isClearButtonVisible) {
        <icz-icon
          class="clear-selection"
          size="small"
          svgIcon="delete"
          (click)="clearClicked($event)">
        </icz-icon>
      }
      @if (!fieldDisabled) {
        <icz-icon
          class="open-table"
          size="small"
          svgIcon="reference_list">
        </icz-icon>
      }
    </ng-container>
    <ng-content select="[rightLabelPopup]" rightLabelPopup></ng-content>
  </icz-form-field>
</icz-validation-errors-list>
