import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {IczFormGroup} from '@icz/angular-form-elements';
import {enumToOptions} from '../../../../core/services/data-mapping.utils';

import {SubjectAttributeType} from '../../model/subject-attribute-type';
import {SearchOnlyAttribute, SubjectIdentifierType} from '../subject-search.model';

@Component({
  selector: 'icz-subject-identifier-input-form-fragment',
  templateUrl: './subject-identifier-input-form-fragment.component.html',
  styleUrls: ['./subject-identifier-input-form-fragment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectIdentifierInputFormFragmentComponent {

  @Input({ required: true })
  form!: IczFormGroup;
  @Output()
  enterPressed = new EventEmitter<void>();

  readonly subjectIdentifierTypeOptions = enumToOptions('subjectIdentifierType', SubjectIdentifierType);
  readonly SubjectIdentifierType = SubjectIdentifierType;
  readonly SearchOnlyAttribute = SearchOnlyAttribute;
  readonly SubjectAttributeType = SubjectAttributeType;

  isIdSelected(identifier: SubjectIdentifierType): boolean {
    return this.form?.get('identityType')?.value === identifier;
  }

  onInputFieldKeydown($event: KeyboardEvent): void {
    const keyPressed = $event.key;

    if (keyPressed === 'Enter') {
      $event.stopPropagation();
      this.enterPressed.emit();
    }
  }

}
