import {DestroyRef, inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HistoryBit} from './history.model';
import {AuthService} from '../core/authentication/auth.service';
import {ApplicationLanguage} from '../core/services/environment.models';
import {CurrentSessionService} from './current-session.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  AllSavedFilters,
  DehydratedFilterItemValue,
  FilterTreeOperator,
  hydrateFilterItemValue,
  hydrateFilterItemValueTree,
  PersistedUserTableProperties,
  SavedFiltersPersistor,
  TablePropertiesPersistor
} from '@icz/angular-table';
import {SectionSettingsPersistor} from '@icz/angular-essentials';
import {
  ChipNamespace,
  ChipSelectorStatePersistor,
  ChipUsageRates,
  CustomChips,
  PersistedChipUsageRates,
  PersistedCustomChips
} from '@icz/angular-form-elements';


export enum LocalStorageKey {
  ACCESS_TOKEN = 'g2-accessToken',
  NPROD_BEARER = 'g2dev-configserver-bearer',
  NPROD_USER = 'g2dev-configserver-user',
  SAVED_FILTERS = 'g2-saved-filters',
  SAVED_FUNCTIONAL_POSITIONS = 'g2-saved-functional-positions',
  SHOW_TABLE_LABELS = 'g2-show-table-labels',
  TABLE_PROPERTIES = 'g2-table-properties',
  USER_LANGUAGE = 'g2-language',
  FAVOURITE_PAGES = 'g2-favourite-pages',
  MENU_OPEN_STATE = 'g2-menu-open-state',
  CUSTOM_CHIPS = 'g2-custom-chips',
  CHIP_USAGE_RATES = 'g2-chip-usage-rates',
  SAVED_HISTORY = 'g2-saved-history',
  TABLE_USER_SETTINGS = 'g2-table-user-settings',
  USER_DASHBOARD_SECTIONS = 'g2-user-dashboard-sections',
  USER_DASHBOARD_PAGES = 'g2-user-dashboard-pages',
  USER_STATISTIC_DASHBOARD_SECTIONS = 'g2-user-statistic-dashboard-sections',
  USER_STATISTIC_TABLE_DIMENSIONS = 'g2-user-statistic-table-dimensions',
  DASHBOARD_TASK_TIME_PERIOD = 'g2-dashboard-task-time-period',
  USER_LANDING_PAGE = 'g2-user-landing-page',
  USER_ICON_COLOR_SCHEME = 'g2-user-icon-color-scheme',
  PRINTER_PREFERENCE_SETTINGS = 'g2-printer-preference-settings',
  USER_SECTION_CLOSE_STATUS = 'g2-user-section-closed-status',
  DISABLE_INFO_TOASTS = 'g2-disable-info-toasts',
  LAST_USED_ORG_ID =  'g2-last-used-org-id',
  LAST_USED_SIGN_CERTIFICATE =  'g2-last-used-sign-certificate'
}

const USER_AND_FP_SCOPED_SETTINGS: LocalStorageKey[] = [
  LocalStorageKey.SAVED_HISTORY,
];

const USER_SCOPED_SETTINGS: LocalStorageKey[] = [
  LocalStorageKey.SAVED_FILTERS,
  LocalStorageKey.SAVED_FUNCTIONAL_POSITIONS,
  LocalStorageKey.SHOW_TABLE_LABELS,
  LocalStorageKey.USER_DASHBOARD_SECTIONS,
  LocalStorageKey.USER_DASHBOARD_PAGES,
  LocalStorageKey.USER_LANDING_PAGE,
  LocalStorageKey.TABLE_PROPERTIES,
  LocalStorageKey.FAVOURITE_PAGES,
  LocalStorageKey.MENU_OPEN_STATE,
  LocalStorageKey.CUSTOM_CHIPS,
  LocalStorageKey.CHIP_USAGE_RATES,
  LocalStorageKey.TABLE_USER_SETTINGS,
  LocalStorageKey.USER_STATISTIC_DASHBOARD_SECTIONS,
  LocalStorageKey.USER_STATISTIC_TABLE_DIMENSIONS,
  LocalStorageKey.USER_ICON_COLOR_SCHEME,
  LocalStorageKey.PRINTER_PREFERENCE_SETTINGS,
  LocalStorageKey.USER_SECTION_CLOSE_STATUS,
  LocalStorageKey.DISABLE_INFO_TOASTS,
  LocalStorageKey.LAST_USED_SIGN_CERTIFICATE,
];

const PRIMITIVE_SETTINGS: LocalStorageKey[] = [
  LocalStorageKey.NPROD_BEARER,
  LocalStorageKey.SHOW_TABLE_LABELS,
  LocalStorageKey.USER_LANGUAGE,
  LocalStorageKey.MENU_OPEN_STATE,
  LocalStorageKey.USER_ICON_COLOR_SCHEME,
  LocalStorageKey.DISABLE_INFO_TOASTS,
  LocalStorageKey.LAST_USED_ORG_ID,
];
const ERRORED_VALUE = null;

export enum PrintingOperationType {
  LABEL = 'LABEL',
  SHEET_LABEL = 'SHEET_LABEL',
  ENVELOPE = 'ENVELOPE',
}

export type PrinterPreferenceSettings = Record<PrintingOperationType, Nullable<string>>;

export interface TableUserSettings {
  pageRowCount: number;
}

export enum EnvironmentIconColorScheme {
  GRAY_SCHEME = 'GRAY_SCHEME',
  COLOR_SCHEME = 'COLOR_SCHEME'
}

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService implements SectionSettingsPersistor, ChipSelectorStatePersistor, SavedFiltersPersistor, TablePropertiesPersistor {

  private authService = inject(AuthService);
  private currentSessionService = inject(CurrentSessionService);
  private destroyRef = inject(DestroyRef);

  constructor() {
    this.initialize();

    this.authService.login$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.initialize();
    });
  }

  private _showTableLabels$ = new BehaviorSubject<boolean>(this.showTableLabelsValue);
  showTableLabels$ = this._showTableLabels$.asObservable();

  private _disableInfoToasts$ = new BehaviorSubject<boolean>(this.disableInfoToastsValue);
  disableInfoToasts$ = this._disableInfoToasts$.asObservable();

  private _environmentIconColorScheme$ = new BehaviorSubject<Nullable<EnvironmentIconColorScheme>>(this.iconColorSchemeValue);
  environmentIconColorScheme$ = this._environmentIconColorScheme$.asObservable();

  private get showTableLabelsValue(): boolean {
    return JSON.parse(this.getRawValue(LocalStorageKey.SHOW_TABLE_LABELS)!);
  }

  private get disableInfoToastsValue(): boolean {
    return JSON.parse(this.getRawValue(LocalStorageKey.DISABLE_INFO_TOASTS)!);
  }

  private get iconColorSchemeValue() {
    return this.getRawValue(LocalStorageKey.USER_ICON_COLOR_SCHEME) as EnvironmentIconColorScheme;
  }

  // getting settings stored as strings in localStorage
  getRawValue(key: LocalStorageKey): Nullable<string> {
    if (!PRIMITIVE_SETTINGS.includes(key)) return ERRORED_VALUE;

    let value = localStorage.getItem(this.getUserScopedKey(key));

    // Various scenarios are covered in IczTranslateLoader, this just ensures that if there is a saved language, it's actually a language
    if (key === LocalStorageKey.USER_LANGUAGE) {
      if (!Object.values(ApplicationLanguage).includes(value as ApplicationLanguage)) {
        value = ApplicationLanguage.CZECH;
      }
    }
    else if (key === LocalStorageKey.SHOW_TABLE_LABELS || key === LocalStorageKey.MENU_OPEN_STATE) {
      const defaultValue = 'true';

      try {
        const parsed = JSON.parse(value ?? 'null');
        if (typeof parsed !== 'boolean') return defaultValue;
        else return value;
      } catch (e) {
        return defaultValue;
      }
    }
    else if (key === LocalStorageKey.DISABLE_INFO_TOASTS) {
      const defaultValue = 'false';

      try {
        const parsed = JSON.parse(value ?? 'null');
        if (typeof parsed !== 'boolean') return defaultValue;
        else return value;
      } catch (e) {
        return defaultValue;
      }
    }

    return value ?? null;
  }

  removeRawValue(key: LocalStorageKey): void {
    localStorage.removeItem(this.getUserScopedKey(key)); // just returns undefined if key doesn't exist, no need to check for existence
  }

  setRawValue(key: LocalStorageKey, value: string): void {
    localStorage.setItem(this.getUserScopedKey(key), value);
  }

  // getting settings stored as stringified objects in localStorage
  // keeping in mind that accessToken isn't really user "setting", but until we have the actual user settings stored in config app,
  // the api for manipulating accessToken can be shared with settings
  getParsedValue(key: LocalStorageKey): Nullable<Record<string, any>> {
    if (PRIMITIVE_SETTINGS.includes(key)) return ERRORED_VALUE;

    try {
      return JSON.parse(localStorage.getItem(this.getUserScopedKey(key))!) ?? null;
    } catch (e) {
      return ERRORED_VALUE;
    }
  }

  setParsedValue(key: LocalStorageKey, value: Record<string, any>): void {
    this.setRawValue(key, JSON.stringify(value));
  }

  setParsedValueScoped(key: LocalStorageKey, scope: string, value: Record<string, any>): void {
    const all = this.getParsedValue(key) ?? {};
    all[scope] = value;

    this.setRawValue(key, JSON.stringify(all));
  }

  // Chip selector helpers below
  //
  //

  getChipUsageRates(chipNamespace: ChipNamespace): ChipUsageRates {
    let persistedValues: Nullable<PersistedChipUsageRates> = this.getParsedValue(LocalStorageKey.CHIP_USAGE_RATES);

    if (persistedValues === null) {
      this.setParsedValue(LocalStorageKey.CHIP_USAGE_RATES, {});
      persistedValues = {};
    }

    if (!persistedValues!.hasOwnProperty(chipNamespace)) {
      return {};
    }
    else {
      return persistedValues![chipNamespace];
    }
  }

  setChipUsageRate(chipNamespace: ChipNamespace, chipName: string, rate: number): void {
    let persistedValues: Nullable<PersistedChipUsageRates> = this.getParsedValue(LocalStorageKey.CHIP_USAGE_RATES);

    if (persistedValues === null) {
      this.setParsedValue(LocalStorageKey.CHIP_USAGE_RATES, {});
      persistedValues = {};
    }

    if (!persistedValues!.hasOwnProperty(chipNamespace)) {
      persistedValues![chipNamespace] = {};
    }

    persistedValues![chipNamespace][chipName] = rate;

    this.setParsedValue(LocalStorageKey.CHIP_USAGE_RATES, persistedValues!);
  }

  getCustomChips(chipNamespace: ChipNamespace): CustomChips {
    let persistedValues: Nullable<PersistedCustomChips> = this.getParsedValue(LocalStorageKey.CUSTOM_CHIPS);

    if (persistedValues === null) {
      this.setParsedValue(LocalStorageKey.CUSTOM_CHIPS, {});
      persistedValues = {};
    }

    if (!persistedValues!.hasOwnProperty(chipNamespace)) {
      return [];
    }
    else {
      return persistedValues![chipNamespace];
    }
  }

  addCustomChip(chipNamespace: ChipNamespace, chipName: string): void {
    let persistedValues: Nullable<PersistedCustomChips> = this.getParsedValue(LocalStorageKey.CUSTOM_CHIPS);

    if (persistedValues === null) {
      this.setParsedValue(LocalStorageKey.CUSTOM_CHIPS, {});
      persistedValues = {};
    }

    if (!persistedValues!.hasOwnProperty(chipNamespace)) {
      persistedValues![chipNamespace] = [];
    }

    persistedValues![chipNamespace].push(chipName);

    this.setParsedValue(LocalStorageKey.CUSTOM_CHIPS, persistedValues!);
  }

  // Table column helpers below
  //
  //

  loadAllTablePropertiesColumns(): Nullable<{ [key: string]: PersistedUserTableProperties }> {
    return this.getParsedValue(LocalStorageKey.TABLE_PROPERTIES);
  }

  getTableProperties(tableId: string): Nullable<PersistedUserTableProperties> {
    const allColumns = this.loadAllTablePropertiesColumns();

    if (!allColumns) return ERRORED_VALUE;

    const tableProperties = allColumns[tableId];

    if (Array.isArray(tableProperties)) {
      this.deleteCustomPropertiesForTable(tableId);
      return ERRORED_VALUE;
    }
    else if (tableProperties) {
      if (tableProperties.columns) {
        for (const column of tableProperties.columns) {
          if (typeof column.id !== 'string' || typeof column.isVisible !== 'boolean' ||
            (column.columnWidth && typeof column.columnWidth !== 'number')) {
            return ERRORED_VALUE;
          }
        }
      }

      if (typeof tableProperties.tableSchemaVersionHash !== 'string') {
        return ERRORED_VALUE;
      }

      if (
        tableProperties.sort && (
          typeof tableProperties.sort.sortColumnId !== 'string' ||
          (tableProperties.sort.sortDirection !== 'asc' && tableProperties.sort.sortDirection !== 'desc')
        )
      ) {
        return ERRORED_VALUE;
      }

      return tableProperties;
    }
    else {
      return null;
    }
  }

  saveTableProperties(tableId: string, columns: PersistedUserTableProperties): void {
    const storedColumns = this.loadAllTablePropertiesColumns();

    if (storedColumns) {
      storedColumns[tableId] = columns;
      this.setRawValue(LocalStorageKey.TABLE_PROPERTIES, JSON.stringify(storedColumns));
    }
  }

  deleteCustomPropertiesForTable(tableId: string): void {
    const storedColumns = this.loadAllTablePropertiesColumns()!;

    if (storedColumns.hasOwnProperty(tableId)) {
      delete storedColumns[tableId];
      this.setRawValue(LocalStorageKey.TABLE_PROPERTIES, JSON.stringify(storedColumns));
    }
  }

  // Saved filters helpers below
  //
  //

  getSavedFilters(): Nullable<AllSavedFilters> {
    const allFilters: Nullable<AllSavedFilters> = this.getParsedValue(LocalStorageKey.SAVED_FILTERS);

    if (!allFilters) return ERRORED_VALUE;

    for (const tableId in allFilters) {
      if (!allFilters.hasOwnProperty(tableId)) {
        continue;
      }

      for (const filterId in allFilters[tableId]) {
        if (!allFilters[tableId].hasOwnProperty(filterId)) {
          continue;
        }

        // migration of legacy saved filters to new tree format
        if (Array.isArray(allFilters[tableId][filterId])) {
          allFilters[tableId][filterId] = {
            operator: FilterTreeOperator.NONE,
            values: (allFilters[tableId][filterId] as unknown as DehydratedFilterItemValue[]).map(hydrateFilterItemValue),
            isDefault: false,
          };
        }
        else {
          allFilters[tableId][filterId] = {isDefault: allFilters[tableId][filterId].isDefault, ...hydrateFilterItemValueTree(allFilters[tableId][filterId])};
        }
      }
    }

    return allFilters;
  }

  saveSavedFilters(savedFilters: AllSavedFilters) {
    this.setParsedValue(LocalStorageKey.SAVED_FILTERS, savedFilters);
  }

  // Saved history helpers below
  //
  //

  getSavedHistory(): HistoryBit[] {
    return ((this.getParsedValue(LocalStorageKey.SAVED_HISTORY) as HistoryBit[]) ?? [])
      .map((hb: HistoryBit) => ({...hb, date: new Date(hb.date), isAwaitingAlias: false}));
  }

  persistSavedHistory(history: HistoryBit[]) {
    this.setParsedValue(
      LocalStorageKey.SAVED_HISTORY,
      history,
    );
  }

  // Table row count helpers below
  //
  //

  getTablePageRowCount(): number {
    return (this.getParsedValue(
        LocalStorageKey.TABLE_USER_SETTINGS) as Nullable<TableUserSettings>
    )?.pageRowCount ?? 0;
  }

  setTablePageRowCount(pageRowCount: number) {
    this.setParsedValue(LocalStorageKey.TABLE_USER_SETTINGS, {pageRowCount} as TableUserSettings);
  }

  // Printer preference settings helpers below
  //
  //

  getPreferredPrinter(printingOperationType: PrintingOperationType): Nullable<string> {
    return (this.getParsedValue(LocalStorageKey.PRINTER_PREFERENCE_SETTINGS) ?? {})[printingOperationType];
  }

  setPreferredPrinter(printingOperationType: PrintingOperationType, printerName: string) {
    const printerPreferences = this.getParsedValue(LocalStorageKey.PRINTER_PREFERENCE_SETTINGS)!;

    printerPreferences[printingOperationType] = printerName;

    this.setParsedValue(LocalStorageKey.PRINTER_PREFERENCE_SETTINGS, printerPreferences);
  }

  // Sections closed state helpers below
  //
  //

  setSectionClosedState(sectionId: string, isClosed: boolean) {
    let closedStates = this.getParsedValue(LocalStorageKey.USER_SECTION_CLOSE_STATUS);

    if (closedStates) {
      closedStates[sectionId] = isClosed;
    } else {
      closedStates = {
        [sectionId]: isClosed
      };
    }

    this.setParsedValue(LocalStorageKey.USER_SECTION_CLOSE_STATUS, closedStates!);
  }

  getSectionClosedState(sectionId: string): Nullable<boolean> {
    const closedStates = this.getParsedValue(LocalStorageKey.USER_SECTION_CLOSE_STATUS);

    if (closedStates) {
      if (closedStates.hasOwnProperty(sectionId)) {
        return closedStates[sectionId];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  // User settings helpers below
  //
  //

  setShowTableLabels(val: boolean): void {
    if (isNil(val)) return;
    this.setRawValue(LocalStorageKey.SHOW_TABLE_LABELS, String(val));
    this._showTableLabels$.next(val);
  }

  setDisableInfoToasts(val: boolean): void {
    if (isNil(val)) return;
    this.setRawValue(LocalStorageKey.DISABLE_INFO_TOASTS, String(val));
    this._disableInfoToasts$.next(val);
  }

  setEnvironmentIconColorScheme(colorScheme: EnvironmentIconColorScheme) {
    this.setRawValue(LocalStorageKey.USER_ICON_COLOR_SCHEME, colorScheme);
    this._environmentIconColorScheme$.next(colorScheme);
  }

  private initialize() {
    if (!this.authService.isAuthenticatedWithFunctionalPosition) {
      return;
    }

    if (this.authService.isAuthenticatedWithFunctionalPosition && !this.loadAllTablePropertiesColumns()) {
      this.setRawValue(LocalStorageKey.TABLE_PROPERTIES, '{}');
    }

    if (!this.getParsedValue(LocalStorageKey.PRINTER_PREFERENCE_SETTINGS)) {
      this.setParsedValue(
        LocalStorageKey.PRINTER_PREFERENCE_SETTINGS,
        {
          [PrintingOperationType.ENVELOPE]: null,
          [PrintingOperationType.LABEL]: null,
          [PrintingOperationType.SHEET_LABEL]: null,
        } satisfies PrinterPreferenceSettings
      );
    }

    this._showTableLabels$.next(this.showTableLabelsValue);
    this._disableInfoToasts$.next(this.disableInfoToastsValue);
    this._environmentIconColorScheme$.next(this.iconColorSchemeValue);
  }

  private getUserScopedKey(key: LocalStorageKey): LocalStorageKey|`__${string}__${LocalStorageKey}`|`__${string}__${string}__${LocalStorageKey}` {
    if (USER_SCOPED_SETTINGS.includes(key)) {
      return `__${this.currentSessionService.currentUser?.username ?? ''}__${key}`;
    }
    else if (USER_AND_FP_SCOPED_SETTINGS.includes(key)) {
      return `__${this.currentSessionService.currentUser?.username ?? ''}__${this.currentSessionService.currentUserFunctionalPosition?.id ?? ''}__${key}`;
    }
    else {
      return key;
    }
  }

}
