import {UserDto} from '|api/auth-server';
import {DigitalComponentVersionTypeFlag, EntityType} from '|api/commons';

export interface ElasticIdentifiable {
  elasticId: string; // concatenation {entityType} + {numeric ID}
}

export const FORM_SUBMIT_DEBOUNCE_TIME = 500; // ms

export function getComponentIconByFormatFlag(formatFlag: Nullable<DigitalComponentVersionTypeFlag>, isContainer?: boolean) {
  if (isContainer && formatFlag !== DigitalComponentVersionTypeFlag.PDFA) {
    return 'kontejner';
  }
  else {
    switch (formatFlag) {
      case DigitalComponentVersionTypeFlag.AUDIO:
        return 'attachment_audio';
      case DigitalComponentVersionTypeFlag.DOC:
        return 'attachment_doc';
      case DigitalComponentVersionTypeFlag.DOCX:
        return 'attachment_docx';
      case DigitalComponentVersionTypeFlag.FO:
        return 'attachment_fo';
      case DigitalComponentVersionTypeFlag.IMAGE:
        return 'attachment_img';
      case DigitalComponentVersionTypeFlag.ODP:
        return 'attachment_odp';
      case DigitalComponentVersionTypeFlag.ODS:
        return 'attachment_ods';
      case DigitalComponentVersionTypeFlag.ODT:
        return 'attachment_odt';
      case DigitalComponentVersionTypeFlag.PDF:
        return 'attachment_pdf';
      case DigitalComponentVersionTypeFlag.PDFA:
        return isContainer ? 'pdfa_kontejner' : 'attachment_pdfa';
      case DigitalComponentVersionTypeFlag.PPT:
        return 'attachment_ppt';
      case DigitalComponentVersionTypeFlag.PPTX:
        return 'attachment_pptx';
      case DigitalComponentVersionTypeFlag.XLS:
        return 'attachment_xls';
      case DigitalComponentVersionTypeFlag.XLSX:
        return 'attachment_xlsx';
      case DigitalComponentVersionTypeFlag.XML:
        return 'attachment_xml';
      case DigitalComponentVersionTypeFlag.ZFO:
        return 'attachment_zfo';
      case DigitalComponentVersionTypeFlag.OTHER:
      default:
        return 'attachment_rest';
    }
  }
}

export const getComponentIcon = (entityType: EntityType, formatFlag?: Nullable<DigitalComponentVersionTypeFlag>, isContainer?: boolean): string => {
  switch (entityType) {
    case EntityType.PAPER_COMPONENT:
      return 'attachment_paper';
    case EntityType.MEDIUM_COMPONENT:
      return 'nosic';
    case EntityType.PHYSICAL_ITEM_COMPONENT:
      return 'spanner';
    case EntityType.DIGITAL_COMPONENT:
      return getComponentIconByFormatFlag(formatFlag, isContainer);
    default:
      return 'attachment_rest';
  }
};

export const getComponentIconByFilenameSuffix = (fileName: string) => {
  const filenameParts = fileName.split('.');
  const fileSuffix = filenameParts[filenameParts.length - 1].toLowerCase();

  switch (fileSuffix) {
    case 'pdf':
      return 'attachment_pdf';
    case 'doc':
      return 'attachment_doc';
    case 'docx':
      return 'attachment_docx';
    case 'fo':
      return 'attachment_fo';
    case 'odp':
      return 'attachment_odp';
    case 'ods':
      return 'attachment_ods';
    case 'odt':
      return 'attachment_odt';
    case 'pdfa':
      return 'attachment_pdfa';
    case 'ppt':
      return 'attachment_ppt';
    case 'pptx':
      return 'attachment_pptx';
    case 'xsl':
      return 'attachment_xsl';
    case 'xsls':
      return 'attachment_xsls';
    case 'zfo':
      return 'attachment_zfo';
    case 'eml':
    case 'msg':
      return 'email';
    case 'bmp':
    case 'gif':
    case 'jfif':
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'ppeg':
    case 'tiff':
      return 'attachment_img';
    case 'wav':
    case 'mp3':
    case 'ogg':
    case 'flac':
      return 'attachment_audio';
    default:
      return 'attachment_rest';
  }
};

export function getUserFullName(u: UserDto, withTitles = false) {
  if (u.username === 'system') {
    return 'Systém';
  }
  else if (isNil(u.firstName) && isNil(u.surname)) {
    return 'Uživatel beze jména';
  }
  else {
    const nameWithoutTitles = `${u.firstName ?? ''}${u.firstName ? ' ' : ''}${u.surname ?? ''}`;

    if (withTitles) {
      return `${u.titleBeforeName ? `${u.titleBeforeName} ` : ''}${nameWithoutTitles}${u.titleAfterName ? `, ${u.titleAfterName}` : ''}`;
    }
    else {
      return nameWithoutTitles;
    }
  }
}

export function getUserInitials(namesOrUser: string[] | UserDto): string {
  let names: string[] = [];

  if (Array.isArray(namesOrUser)) {
    names = namesOrUser;
  }
  else {
    names = [namesOrUser!.firstName ?? '', namesOrUser!.surname ?? ''];
  }

  if (!names?.length) return '';

  if (names.length === 1) {
    return names.at(0)!.charAt(0);
  }
  else {
    return `${names.at(0)!.charAt(0)}${names.at(-1)!.charAt(0)}`;
  }
}
