import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Page} from './page';
import {FilterParamTree} from './filter-trees.utils';
import {getMidnightOfTheDay, IczOption} from '@icz/angular-form-elements';
import {DateRange} from '@angular/material/datepicker';

import {startOfMonth, startOfWeek, startOfYear, sub} from 'date-fns';
import {parse} from 'iso8601-duration';
import {LocaleOptions} from 'date-fns/locale/types';

/**
 * This custom RxJS operator transforms unpaged data in array to paged data
 * for the needs of IczTableDataSource descendants
 */
export function dataToPage<T>(page: Nullable<number> = null, size: Nullable<number> = null) {
  return (input$: Observable<T[]>): Observable<Page<T>> => {
    return input$.pipe(
      map(data => {
        let slicedData = data;

        if (page !== null && size !== null) {
          slicedData = data.slice(size * page, size * (page + 1));
        }

        return {
          content: slicedData,
          totalElements: data.length
        };
      })
    );
  };
}

/**
 * Generates translation key for a filter operator enum member
 */
export function getOperatorTranslationKey(op: Nullable<FilterOperator>): string {
  if (!op) return '';

  return `fe.ui.filter.operator.${op}`;
}

/**
 * Enum of supported table filtering operators
 */
export enum FilterOperator {
  equals = 'eq',
  notEquals = 'ne',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  contains = 'contains',
  doesNotContains = 'doesNotContains',
  less = 'lt',
  lessOrEqual = 'lte',
  greater = 'gt',
  greaterOrEqual = 'gte',
  inSet = 'in',
  empty = 'empty',
  notEmpty = 'notEmpty',
}

/**
 * Predicate which returns whether given filtering operator is supposed to have a value sent with it when requesting filtering
 */
export function isNoValueOperator(filterOperator: Nullable<FilterOperator>) {
  return filterOperator === FilterOperator.empty || filterOperator === FilterOperator.notEmpty;
}

/**
 * A partial interface that specifies a field for filtering/sorting
 */
export interface FieldParam<TFieldKey> {
  fieldName: TFieldKey;
}

/**
 * Definition of sorting query
 */
export interface SortParam<TFieldKey extends string | number | symbol> extends FieldParam<TFieldKey> {
  descending?: boolean;
}

/**
 * Operators for usage in complexFilters, used for building structured logical filtering conditions
 */
export enum ComplexOperator {
  AND = '_AND',
  OR = '_OR',
  NOT = '_NOT',
  NONE = '_NONE'
}

/**
 * Used in SearchParams.globalOperator. Used for specifying root-level logical operator between
 * individual filtering query constituents in SearchParams.filter array.
 * Default is AND.
 */
export enum GlobalOperator {
  and = 'AND',
  or = 'OR',
}

export interface FilterDefinition {
  operator?: FilterOperator;
  /**
   * When used outside of complexFilter context, this flag ensures that all FilterDefinitions
   * related to the same filtering field will be in logical disjunction
   */
  or?: boolean;
  /**
   * Similar behavior to FilterDefinition.or but works as AND
   * true by default
   */
  and?: boolean;
  /**
   * Negates this filtering term
   */
  not?: boolean;
  /**
   * Value to filter against. Non-string values like numbers or booleans should be stringified
   */
  value: Nullable<string>;
  /**
   * Only applicable for string filtering
   */
  isCaseInsensitive?: boolean;
}

/**
 * Definition of filtering query
 */
export interface FilterParam extends FieldParam<string>, FilterDefinition {}

/**
 * Definition of paginated query with filtering and sorting
 */
export interface SearchParams {
  /**
   * Used simple filter expressions; individual filtering terms are
   * connected by globalOperator operator.
   */
  filter: FilterParam[];
  /**
   * A query used for building structured logical filtering conditions with unlimited nesting.
   */
  complexFilter?: FilterParamTree;
  /**
   * Sorting criteria to apply in cascade.
   * Note that table components only supports at most one sort at a time.
   */
  sort: SortParam<string>[];
  /**
   * Size of the page to retrieve
   */
  size: number;
  /**
   * Zero-based index of page to retrieve
   */
  page: number;
  /**
   * @deprecated please use complexFilter instead.
   */
  globalOperator?: GlobalOperator;
  /**
   * A reserved field used for fuzzy fulltext filtering by a text query
   * instead of a structured query of logical expression.
   */
  fulltextSearchTerm?: string;
}

/**
 * A helper for generating IczOptions from number array
 */
export function numberArrayToOptions(numbers: number[]): IczOption<number>[] {
  return numbers.map(i => ({value: i, label: String(i)}));
}

/**
 * This parsing function parses our adjusted standard of ISO 8601 duration strings that brings time context in mind:
 * C ... current, L ... last (e.g. PCY = this year, PLY = last year, PLM = last month, etc.).
 * Plus duration support is reduced to day granularity at most because it is used in a context of date filtering.
 */
export function iczParseDurationString(durationString: string, today: Date, currentLang: string): Date | DateRange<Date> {
  const localeOptions: LocaleOptions = {};

  if (currentLang === 'cs' || currentLang === 'sk') {
    localeOptions.weekStartsOn = 1;
  }
  else if (currentLang === 'en') {
    localeOptions.weekStartsOn = 0;
  }

  if (durationString === 'PCD') {
    return getMidnightOfTheDay(today);
  }
  else if (durationString === 'PLD') {
    return sub(today, {days: 1});
  }
  else if (durationString === 'PCW') {
    return new DateRange(startOfWeek(today, localeOptions), today);
  }
  else if (durationString === 'PLW') {
    const weekStart = startOfWeek(today, localeOptions);
    return new DateRange(sub(weekStart, {weeks: 1}), sub(weekStart, {days: 1}));
  }
  else if (durationString === 'PCM') {
    return new DateRange(startOfMonth(today), today);
  }
  else if (durationString === 'PLM') {
    const monthStart = startOfMonth(today);
    return new DateRange(sub(monthStart, {months: 1}), sub(monthStart, {days: 1}));
  }
  else if (durationString === 'PCY') {
    return new DateRange(startOfYear(today), today);
  }
  else if (durationString === 'PLY') {
    const yearStart = startOfYear(today);
    return new DateRange(sub(yearStart, {years: 1}), sub(yearStart, {days: 1}));
  }
  else {
    const duration = parse(durationString);

    if (duration.days) {
      --duration.days;
    }

    return new DateRange(getMidnightOfTheDay(sub(today, duration)), today);
  }
}

/**
 * Validates our adjusted standard of ISO 8601 duration strings.
 */
export function isValidIczDurationString(value: any) {
  if (value && typeof value === 'string') {
    try {
      return (
        value === 'PCD' ||
        value === 'PLD' ||
        value === 'PCW' ||
        value === 'PLW' ||
        value === 'PCM' ||
        value === 'PLM' ||
        value === 'PCY' ||
        value === 'PLY' ||
        !isNil(parse(value))
      );
    }
    catch {
      return false;
    }
  }
  else {
    return false;
  }
}
