@if (task) {
  <span
    cdkOverlayOrigin
    #digitalComponentsPopupOrigin="cdkOverlayOrigin"
  >
    <a class="visible-clickable-area p-4"
      iczLinkWithoutHref
      (click.stop)="isNotesPopupOpen = true;"
    >{{ getNotesFieldText() }}</a>
    <icz-popover [overlayOrigin]="digitalComponentsPopupOrigin" [isOpen]="isNotesPopupOpen" (onClose)="isNotesPopupOpen = false;">
      <icz-notification-card class="cursor-default">
        <div class="icz-body-strong mt-8" header>{{ 'en.circulationTaskState.' + task.taskState | translate }}</div>
        <div class="icz-body-1" body>{{ task.settlementNote }}</div>
      </icz-notification-card>
    </icz-popover>
  </span>
}
