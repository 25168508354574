<div class="col grow overflow-hidden">
  <icz-modal-table-filter-header [filterOperators]="data.filterOperators" [form]="form" (close)="close()"></icz-modal-table-filter-header>
  <div class="table-content">
    <icz-popup-selector-table [selectorData]="pageSelectorData" (selectedRowsChanged)="selectedRowsChanged($event)"></icz-popup-selector-table>
  </div>

  <icz-form-buttons>
    <icz-button primary leftButtons
                label="Potvrdit"
                [disabled]="!selectedRows.length"
                (onAction)="applyFilter()">
    </icz-button>
  </icz-form-buttons>
</div>
