import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Observable, of} from 'rxjs';
import {
  ApiPermissionService,
  AuthorizedEntityType,
  DocumentPermission,
  FilePermission,
  PermissionSetLevel,
  SharedFolderPermission,
  StorageUnitPermission
} from '|api/core';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {arrayToOptions, enumToOptions} from '../../../../core/services/data-mapping.utils';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {isLeader} from '../../../../core/model/user-roles.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CodebookService} from '../../../../core/services/codebook.service';
import {SharedFolderPermissionLevel} from '|api/commons';
import {IczOption} from '@icz/angular-form-elements';

export type PermissionSelectorPermission = DocumentPermission | FilePermission | StorageUnitPermission | SharedFolderPermission;

export interface PermissionSelectorValue {
  detailedPermissions: boolean;
  revoking: boolean;
  permissions?: Nullable<PermissionSelectorPermission[]>;
  permissionSetLevel?: Nullable<PermissionSetLevel | SharedFolderPermissionLevel>;
}

export function areSelectorValuesSame(psv1: PermissionSelectorValue, psv2: PermissionSelectorValue) {
  return (
    psv1.revoking === psv2.revoking &&
    psv1.detailedPermissions === psv2.detailedPermissions &&
    Boolean(psv1.permissionSetLevel) === Boolean(psv2.permissionSetLevel) && (
      isNil(psv1.permissionSetLevel) && isNil(psv2.permissionSetLevel) ||
      psv1.permissionSetLevel === psv2.permissionSetLevel
    ) &&
    Boolean(psv1.permissions) === Boolean(psv2.permissions) && (
      (isNil(psv1.permissions) && isNil(psv2.permissions)) ||
      (String(psv1.permissions?.sort?.()) === String(psv2.permissions?.sort?.()))
    )
  );
}

interface PermissionPopupListItem {
  label: string;
  options: IczOption<PermissionSelectorPermission>[];
}

enum PermissionUiGroup {
  MAIN = 'MAIN',
  TASK = 'TASK',
  COMPONENT = 'COMPONENT',
  SUBJECT = 'SUBJECT',
  RECEIVED_CONSIGNMENT = 'RECEIVED_CONSIGNMENT',
  DISPATCH = 'DISPATCH',
  RELATED_OBJECT = 'RELATED_OBJECT',
  HISTORY = 'HISTORY',
  ACTIONS = 'ACTIONS',
  PERMISSIONS = 'PERMISSIONS',
  DOCUMENTS_IN_FILE_ACTIONS = 'DOCUMENTS_IN_FILE_ACTIONS',
  DOCUMENTS_IN_FILE = 'DOCUMENTS_IN_FILE',
  ENTITIES_IN_STORAGE_UNIT_ACTIONS = 'ENTITIES_IN_STORAGE_UNIT_ACTIONS',
  ENTITIES_IN_STORAGE_UNIT = 'ENTITIES_IN_STORAGE_UNIT',
}

const documentPermissionsWithUiGroup: Record<DocumentPermission, Nullable<PermissionUiGroup>> = {
  [DocumentPermission.SHOW_IN_LIST]: PermissionUiGroup.MAIN,
  [DocumentPermission.PROFILE_READ]: PermissionUiGroup.MAIN,
  [DocumentPermission.PROFILE_WRITE]: PermissionUiGroup.MAIN,
  [DocumentPermission.BULK_RECEIVED_CONSIGNMENT_READ_PROFILE]: PermissionUiGroup.RECEIVED_CONSIGNMENT,
  [DocumentPermission.BULK_RECEIVED_CONSIGNMENT_WRITE_PROFILE]: PermissionUiGroup.RECEIVED_CONSIGNMENT,
  [DocumentPermission.BULK_RECEIVED_CONSIGNMENT_READ_COMPONENT_CONTENT]: PermissionUiGroup.RECEIVED_CONSIGNMENT,
  [DocumentPermission.OWN_CONSIGNMENT_SHOW_LIST]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.OWN_CONSIGNMENT_ADD]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.BULK_OWN_CONSIGNMENT_READ_PROFILE]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.BULK_OWN_CONSIGNMENT_WRITE_PROFILE]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.BULK_OWN_CONSIGNMENT_READ_CONTENT]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.BULK_OWN_CONSIGNMENT_WRITE_CONTENT]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.BULK_OWN_CONSIGNMENT_HANDOVER]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.BULK_OWN_CONSIGNMENT_CONFIRM_DELIVERY_RESULT]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.BULK_OWN_CONSIGNMENT_DELETE]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.BULK_OWN_CONSIGNMENT_SHOW_CONTENT_LIST_OR_METADATA]: PermissionUiGroup.DISPATCH,
  [DocumentPermission.COMPONENT_SHOW_LIST]: PermissionUiGroup.COMPONENT,
  [DocumentPermission.COMPONENT_ADD]: PermissionUiGroup.COMPONENT,
  [DocumentPermission.BULK_COMPONENT_WRITE_PROFILE]: PermissionUiGroup.COMPONENT,
  [DocumentPermission.BULK_COMPONENT_READ_CONTENT]: PermissionUiGroup.COMPONENT,
  [DocumentPermission.BULK_COMPONENT_WRITE_CONTENT]: PermissionUiGroup.COMPONENT,
  [DocumentPermission.BULK_COMPONENT_CONVERT]: PermissionUiGroup.COMPONENT,
  [DocumentPermission.BULK_COMPONENT_DELETE]: PermissionUiGroup.COMPONENT,
  [DocumentPermission.RELATED_OBJECT_SHOW_LIST]: PermissionUiGroup.RELATED_OBJECT,
  [DocumentPermission.RELATED_OBJECT_ADD]: PermissionUiGroup.RELATED_OBJECT,
  [DocumentPermission.RELATED_OBJECT_REMOVE]: PermissionUiGroup.RELATED_OBJECT,
  [DocumentPermission.SUBJECT_SHOW_LIST]: PermissionUiGroup.SUBJECT,
  [DocumentPermission.SUBJECT_ADD]: PermissionUiGroup.SUBJECT,
  [DocumentPermission.SUBJECT_READ]: PermissionUiGroup.SUBJECT,
  [DocumentPermission.SUBJECT_WRITE]: PermissionUiGroup.SUBJECT,
  [DocumentPermission.SUBJECT_REMOVE]: PermissionUiGroup.SUBJECT,
  [DocumentPermission.PERMISSION_SET_READ]: PermissionUiGroup.PERMISSIONS,
  [DocumentPermission.PERMISSION_SET_WRITE]: PermissionUiGroup.PERMISSIONS,
  [DocumentPermission.HISTORY_SHOW_LIST]: PermissionUiGroup.HISTORY,
  [DocumentPermission.ACTIVITY_SHOW_LIST]: PermissionUiGroup.TASK,
  [DocumentPermission.ACTIVITY_MANAGE]: PermissionUiGroup.TASK,
  [DocumentPermission.TASK_SETTLE_OR_REJECT]: PermissionUiGroup.TASK,
  [DocumentPermission.REGISTER]: PermissionUiGroup.ACTIONS,
  [DocumentPermission.SETTLE_OR_UNSETTLE]: PermissionUiGroup.ACTIONS,
  [DocumentPermission.PASS_ON_TO_EXTERNAL_ORGANIZATION]: PermissionUiGroup.TASK,
  [DocumentPermission.CANCEL_OR_RESTORE]: PermissionUiGroup.ACTIONS,
  [DocumentPermission.CREATE_FILE]: PermissionUiGroup.ACTIONS,
  [DocumentPermission.DUPLICATE]: PermissionUiGroup.ACTIONS,
};

const filePermissionsWithUiGroup: Record<FilePermission, Nullable<PermissionUiGroup>> = {
  [FilePermission.SHOW_IN_LIST]: PermissionUiGroup.MAIN,
  [FilePermission.PROFILE_READ]: PermissionUiGroup.MAIN,
  [FilePermission.PROFILE_WRITE]: PermissionUiGroup.MAIN,
  [FilePermission.DOCUMENT_SHOW_LIST]: PermissionUiGroup.DOCUMENTS_IN_FILE_ACTIONS,
  [FilePermission.DOCUMENT_ADD]: PermissionUiGroup.DOCUMENTS_IN_FILE_ACTIONS,
  [FilePermission.DOCUMENT_ADDITIONAL_ADD]: PermissionUiGroup.DOCUMENTS_IN_FILE_ACTIONS,
  [FilePermission.DOCUMENT_REMOVE]: PermissionUiGroup.DOCUMENTS_IN_FILE_ACTIONS,
  [FilePermission.BULK_DOCUMENT_PROFILE_ONLY_LEVEL]: PermissionUiGroup.DOCUMENTS_IN_FILE,
  [FilePermission.BULK_DOCUMENT_READ_ONLY_LEVEL]: PermissionUiGroup.DOCUMENTS_IN_FILE,
  [FilePermission.BULK_DOCUMENT_READ_AND_WRITE_LEVEL]: PermissionUiGroup.DOCUMENTS_IN_FILE,
  [FilePermission.BULK_DOCUMENT_FULL_ACCESS_LEVEL]: PermissionUiGroup.DOCUMENTS_IN_FILE,
  [FilePermission.RELATED_OBJECT_SHOW_LIST]: PermissionUiGroup.RELATED_OBJECT,
  [FilePermission.RELATED_OBJECT_ADD]: PermissionUiGroup.RELATED_OBJECT,
  [FilePermission.RELATED_OBJECT_REMOVE]: PermissionUiGroup.RELATED_OBJECT,
  [FilePermission.SUBJECT_SHOW_LIST]: PermissionUiGroup.SUBJECT,
  [FilePermission.SUBJECT_ADD]: PermissionUiGroup.SUBJECT,
  [FilePermission.SUBJECT_READ]: PermissionUiGroup.SUBJECT,
  [FilePermission.SUBJECT_WRITE]: PermissionUiGroup.SUBJECT,
  [FilePermission.SUBJECT_REMOVE]: PermissionUiGroup.SUBJECT,
  [FilePermission.PERMISSION_SET_READ]: PermissionUiGroup.PERMISSIONS,
  [FilePermission.PERMISSION_SET_WRITE]: PermissionUiGroup.PERMISSIONS,
  [FilePermission.HISTORY_SHOW_LIST]: PermissionUiGroup.HISTORY,
  [FilePermission.ACTIVITY_SHOW_LIST]: PermissionUiGroup.TASK,
  [FilePermission.ACTIVITY_MANAGE]: PermissionUiGroup.TASK,
  [FilePermission.TASK_SETTLE_OR_REJECT]: PermissionUiGroup.TASK,
  [FilePermission.SETTLE_OR_UNSETTLE]: PermissionUiGroup.ACTIONS,
  [FilePermission.OPEN_CLOSED]: PermissionUiGroup.ACTIONS,
  [FilePermission.PASS_ON_TO_EXTERNAL_ORGANIZATION]: PermissionUiGroup.TASK,
  [FilePermission.CANCEL_OR_RESTORE]: PermissionUiGroup.ACTIONS,
};

const storageUnitPermissionsWithUiGroup: Record<StorageUnitPermission, Nullable<PermissionUiGroup>> = {
  [StorageUnitPermission.SHOW_IN_LIST]: PermissionUiGroup.MAIN,
  [StorageUnitPermission.PROFILE_READ]: PermissionUiGroup.MAIN,
  [StorageUnitPermission.PROFILE_WRITE]: PermissionUiGroup.MAIN,
  [StorageUnitPermission.DELETE]: PermissionUiGroup.MAIN,
  [StorageUnitPermission.CONTENT_SHOW_LIST]: PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT_ACTIONS,
  [StorageUnitPermission.CONTENT_ADD]: PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT_ACTIONS,
  [StorageUnitPermission.CONTENT_WITHDRAW]: PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT_ACTIONS,
  [StorageUnitPermission.CONTENT_MOVE]: PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT_ACTIONS,
  [StorageUnitPermission.BULK_CONTENT_PROFILE_ONLY_LEVEL]: PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT,
  [StorageUnitPermission.BULK_CONTENT_READ_ONLY_LEVEL]: PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT,
  [StorageUnitPermission.BULK_CONTENT_READ_AND_WRITE_LEVEL]: PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT,
  [StorageUnitPermission.BULK_CONTENT_FULL_ACCESS_LEVEL]: PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT,
  [StorageUnitPermission.PERMISSION_SET_READ]: PermissionUiGroup.PERMISSIONS,
  [StorageUnitPermission.PERMISSION_SET_WRITE]: PermissionUiGroup.PERMISSIONS,
  [StorageUnitPermission.HISTORY_SHOW_LIST]: PermissionUiGroup.HISTORY,
  [StorageUnitPermission.ACTIVITY_MANAGE]: null,
  [StorageUnitPermission.ACTIVITY_SHOW_LIST]: null,
  [StorageUnitPermission.TASK_SETTLE_OR_REJECT]: null,
};

type PermissionsWithUiGroup = (typeof documentPermissionsWithUiGroup | typeof filePermissionsWithUiGroup | typeof storageUnitPermissionsWithUiGroup);
export type GeneralPermissionSetLevel = PermissionSetLevel | SharedFolderPermissionLevel;

/* Permission selector popup
  ---------------------------
  1. Permission selector popup is used with permission selector form element
  2. It is used to select permissions, which will be displayed in permission selector
  3. Permission selector popup has 2 view, simple and detailed
    3.1 Simple view: User can select `permissionSetLevel`, which is collection of predefined permissions for certain use-cases
    3.2 Detailed view: User can select permissions from `permissions`, which is list of all available permissions
  4. User can choose with select on top of popup, if selected permissions will be enabled or disabled for user
  5. Detailed view has 3 sections: DOCUMENT_FILE_PERMISSIONS, TASK_WITH_DOCUMENT_FILE_PERMISSIONS and COMPONENT_IN_DOCUMENT_PERMISSIONS
*/


@Component({
  selector: 'icz-permission-selector-popup',
  templateUrl: './permission-selector-popup.component.html',
  styleUrls: ['./permission-selector-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionSelectorPopupComponent implements OnInit {

  private cd = inject(ChangeDetectorRef);
  private apiPermissionService = inject(ApiPermissionService);
  private currentSessionService = inject(CurrentSessionService);
  loadingIndicatorService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);
  private codebookService = inject(CodebookService);

  @Input({required: true}) popoverOrigin!: CdkOverlayOrigin;
  @Input() isOpened = false;
  // Does not react to any further changes after ngOnInit as it has
  // problematic behavior in conjunction with internal reactive form.
  @Input({required: true}) permissionOptions!: PermissionSelectorValue;
  @Input({required: true}) entityType!: AuthorizedEntityType;

  @Output() onClose = new EventEmitter<boolean>();
  @Output() permissionsChanged = new EventEmitter<PermissionSelectorValue>();

  form = new IczFormGroup({
    detailedPermissions: new IczFormControl<Nullable<boolean>>(null),
    revoking: new IczFormControl<Nullable<boolean>>(null),
    permissions: new IczFormGroup<Partial<Record<PermissionSelectorPermission, IczFormControl<boolean>>>>({}),
    permissionSetLevel: new IczFormControl<Nullable<PermissionSetLevel | SharedFolderPermissionLevel>>(null),
  });

  permissionsFormGroup = this.form.get('permissions') as IczFormGroup;

  private getUiGroupOptions(uiGroup: PermissionUiGroup) {
    let permissionsWithUiGroup: PermissionsWithUiGroup;
    let translatePrefix: string;

    if (this.isDocumentEntity) {
      permissionsWithUiGroup = documentPermissionsWithUiGroup;
      translatePrefix = 'documentPermission';
    }
    else if (this.isFileEntity) {
      permissionsWithUiGroup = filePermissionsWithUiGroup;
      translatePrefix = 'filePermission';
    }
    else if (this.isStorageUnitEntity) {
      permissionsWithUiGroup = storageUnitPermissionsWithUiGroup;
      translatePrefix = 'storageUnitPermission';
    }

    return arrayToOptions(
      Object.entries(permissionsWithUiGroup!)
        .filter(([_, v]) => v === uiGroup)
        .map(([k, _]) => k),
      translatePrefix!
    ) as IczOption<PermissionSelectorPermission>[];
  }

  readonly fullDocumentPermissionOptions = enumToOptions('documentPermission', DocumentPermission) as IczOption<DocumentPermission>[];
  readonly fullFilePermissionOptions = enumToOptions('filePermission', FilePermission) as IczOption<FilePermission>[];
  readonly fullStorageUnitPermissionOptions = enumToOptions('storageUnitPermission', StorageUnitPermission) as IczOption<StorageUnitPermission>[];

  get fullPermissionOptions() {
    if (this.isDocumentEntity) {
      return this.fullDocumentPermissionOptions;
    }
    else if (this.isFileEntity) {
      return this.fullFilePermissionOptions;
    }
    else { // isStorageUnitEntity
      return this.fullStorageUnitPermissionOptions;
    }
  }

  fullPermissionListSettings: PermissionPopupListItem[] = [];
  shortPermissionOptions: IczOption<GeneralPermissionSetLevel>[] = [];

  revokeOptions: IczOption<boolean>[] = [
    {label: 'Povolit oprávnění', value: false},
  ];

  get isAtleastOnePermissionSelectedInFullList() {
    return Object.values(this.form.value.permissions ?? {}).some(Boolean);
  }

  get isPermissionActionSetSelected() {
    return Boolean(this.form.value.permissionSetLevel);
  }

  get isButtonDisabled() {
    return (
      (this.isFullPermissionListVisible && !this.isAtleastOnePermissionSelectedInFullList) ||
      (!this.isFullPermissionListVisible && !this.isPermissionActionSetSelected)
    );
  }

  get detailedPermissionsControl() {
    return this.form.get('detailedPermissions');
  }

  get isFullPermissionListVisible() {
    return this.detailedPermissionsControl!.value;
  }

  get isDocumentEntity() {
    return this.entityType === AuthorizedEntityType.DOCUMENT;
  }

  get isFileEntity() {
    return this.entityType === AuthorizedEntityType.FILE;
  }

  get isStorageUnitEntity() {
    return this.entityType === AuthorizedEntityType.STORAGE_UNIT;
  }

  get isSharedFolderEntity() {
    return this.entityType === AuthorizedEntityType.SHARED_FOLDER;
  }

  get isAuthorizedEntityTypeWithFineGranedPermissions() {
    return this.entityType !== AuthorizedEntityType.SHARED_FOLDER;
  }

  documentPermissionsWithRequires: Record<string, DocumentPermission[]> = {};
  filePermissionsWithRequires: Record<string, FilePermission[]> = {};
  storageUnitPermissionsWithRequires: Record<string, StorageUnitPermission[]> = {};

  private generateFullPermissionFormControls() {
    for (const option of this.fullPermissionOptions) {
      this.permissionsFormGroup.addControl(option.value, new IczFormControl<Nullable<boolean>>(false), {emitEvent: false});
    }

    for (const [permission, control] of Object.entries(this.permissionsFormGroup.controls)) {
      control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(checked => {

        if (!control.dirty) return;

        let permissionTree: Record<string, Array<PermissionSelectorPermission>>;

        if (this.isDocumentEntity) {
          permissionTree = this.documentPermissionsWithRequires;
        }
        else if (this.isFileEntity) {
          permissionTree = this.filePermissionsWithRequires;
        }
        else if (this.isStorageUnitEntity) {
          permissionTree = this.storageUnitPermissionsWithRequires;
        }

        const revoking = this.form.get('revoking')!.value;
        const parents = permissionTree![permission];
        const children: PermissionSelectorPermission[] = [];

        for (const [key, value] of Object.entries(permissionTree!)) {
          if (value.includes(permission as PermissionSelectorPermission)) {
            children.push(key as PermissionSelectorPermission);
          }
        }

        if (checked) {
          if (!revoking) {
            parents.forEach(require => {
              this.permissionsFormGroup.controls[require]?.setValue(true, {emitEvent: false});
              this.permissionsFormGroup.controls[require]?.disable();
            });
          }
          else {
            children.forEach(child => {
              this.permissionsFormGroup.controls[child]?.setValue(true, {emitEvent: false});
              this.permissionsFormGroup.controls[child]?.disable();
            });
          }
        }
        else {
          if (!revoking) {
            parents.forEach(require => {
              this.permissionsFormGroup.controls[require]?.enable();
            });

            // When user edits the permissions on existing sharing, we don't know which permission was "originally" selected to force a parent permission to be selected,
            // so all permissions are editable. However we still want to avoid invalid combinations, so when unchecking a "parent" permission, better
            // uncheck all "children" permissions as well
            children.forEach(child => {
              this.permissionsFormGroup.controls[child]?.setValue(false, {emitEvent: false});
            });
          }
          else {
            children.forEach(child => {
              this.permissionsFormGroup.controls[child]?.setValue(false, {emitEvent: false});
              this.permissionsFormGroup.controls[child]?.enable();
            });
          }
        }
      });
    }

    this.permissionsFormGroup.recursivelyUpdateValueAndValidity();
  }

  generatePermissionListSettings() {
    if (this.isDocumentEntity) {
      this.fullPermissionListSettings = [
        {label: 'Oprávnění k dokumentu', options: this.getUiGroupOptions(PermissionUiGroup.MAIN)},
        {label: 'Oprávnění k úkolům nad dokumentem', options: this.getUiGroupOptions(PermissionUiGroup.TASK)},
        {label: 'Oprávnění ke komponentě přidružené k dokumentu', options: this.getUiGroupOptions(PermissionUiGroup.COMPONENT)},
        {label: 'Oprávnění k subjektům dokumentu', options: this.getUiGroupOptions(PermissionUiGroup.SUBJECT)},
        {label: 'Oprávnění k nastavení oprávnění k dokumentu', options: this.getUiGroupOptions(PermissionUiGroup.PERMISSIONS)},
        {label: 'Oprávnění k akcím nad dokumentem', options: this.getUiGroupOptions(PermissionUiGroup.ACTIONS)},
        {label: 'Oprávnění k historii dokumentu', options: this.getUiGroupOptions(PermissionUiGroup.HISTORY)},
        {label: 'Oprávnění k vypravení dokumentu', options: this.getUiGroupOptions(PermissionUiGroup.DISPATCH)},
        {label: 'Oprávnění k doručeným zásilkám dokumentu', options: this.getUiGroupOptions(PermissionUiGroup.RECEIVED_CONSIGNMENT)},
        {label: 'Oprávnění k související objektům s dokumentem', options: this.getUiGroupOptions(PermissionUiGroup.RELATED_OBJECT)},
      ];
    }
    else if (this.isFileEntity) {
      this.fullPermissionListSettings = [
        {label: 'Oprávnění k spisu', options: this.getUiGroupOptions(PermissionUiGroup.MAIN)},
        {label: 'Oprávnění k úkolům nad spisem', options: this.getUiGroupOptions(PermissionUiGroup.TASK)},
        {label: 'Oprávnění k úkolům dokumentů ve spisu', options: this.getUiGroupOptions(PermissionUiGroup.DOCUMENTS_IN_FILE_ACTIONS)},
        {label: 'Oprávnění k dokumentům ve spisu', options: this.getUiGroupOptions(PermissionUiGroup.DOCUMENTS_IN_FILE)},
        {label: 'Oprávnění k subjektům spisu', options: this.getUiGroupOptions(PermissionUiGroup.SUBJECT)},
        {label: 'Oprávnění k související objektům se spisem', options: this.getUiGroupOptions(PermissionUiGroup.RELATED_OBJECT)},
        {label: 'Oprávnění k nastavení oprávnění ke spisu', options: this.getUiGroupOptions(PermissionUiGroup.PERMISSIONS)},
        {label: 'Oprávnění k historii spisu', options: this.getUiGroupOptions(PermissionUiGroup.HISTORY)},
      ];
    }
    else if (this.isStorageUnitEntity) {
      this.fullPermissionListSettings = [
        {label: 'Oprávnění k ukládací jednotce', options: this.getUiGroupOptions(PermissionUiGroup.MAIN)},
        {label: 'Oprávnění k úkolům dokumentů/spisů v ukládací jednotce', options: this.getUiGroupOptions(PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT_ACTIONS)},
        {label: 'Oprávnění k dokumentům/spisům v ukládací jednotce', options: this.getUiGroupOptions(PermissionUiGroup.ENTITIES_IN_STORAGE_UNIT)},
        {label: 'Oprávnění k nastavení oprávnění ukládací jednotky', options: this.getUiGroupOptions(PermissionUiGroup.PERMISSIONS)},
        {label: 'Oprávnění k historii ukládací jednotky', options: this.getUiGroupOptions(PermissionUiGroup.HISTORY)},
      ];
    }
  }

  private updateForm() {
    if (this.permissionOptions) {
      const detailedPermissions = this.permissionOptions.detailedPermissions;

      if (detailedPermissions) {
        const {permissions} = this.permissionOptions;
        for (const name of permissions!) {
          this.form.get('permissions')?.get(name)?.setValue(true);
        }
      }

      this.form.patchValue({
        permissionSetLevel: this.permissionOptions.permissionSetLevel,
        detailedPermissions: this.permissionOptions.detailedPermissions,
        revoking: this.permissionOptions.revoking,
      });
    }
  }

  showFullPermissionList() {
    const selectedPermissionSetLevel = this.form.get('permissionSetLevel')!.value;

    if (selectedPermissionSetLevel) {
      this.form.get('permissions')!.reset();

      let req$: Observable<PermissionSelectorPermission[]>;

      if (this.isDocumentEntity) {
        req$ = this.apiPermissionService.permissionGetDocumentPermissionsByLevel({
          level: selectedPermissionSetLevel as PermissionSetLevel,
        });
      }
      else if (this.isFileEntity) {
        req$ = this.apiPermissionService.permissionGetFilePermissionsByLevel({
          level: selectedPermissionSetLevel as PermissionSetLevel,
        });
      }
      else if (this.isStorageUnitEntity) {
        req$ = this.apiPermissionService.permissionGetStorageUnitPermissionsByLevel({
          level: selectedPermissionSetLevel as PermissionSetLevel,
        });
      }
      else if (this.isSharedFolderEntity) {
        req$ = of([]); // shared folders do not have fine-grained permission control
      }

      req$!.subscribe((permissions: PermissionSelectorPermission[]) => {
        for (const permissionActionName of permissions) {
          this.form.get('permissions')!.get(permissionActionName)!.setValue(true, {emitEvent: false});
        }

        this.permissionsFormGroup.recursivelyUpdateValueAndValidity();
        this.detailedPermissionsControl!.setValue(true, {emitEvent: false});

        this.cd.detectChanges();
      });
    }
    else {
      this.detailedPermissionsControl!.setValue(true, {emitEvent: false});
    }

    this.cd.detectChanges();
  }

  hideFullPermissionList() {
    this.detailedPermissionsControl!.setValue(false, {emitEvent: false});
  }

  closePopup(permissionsUpdated?: boolean) {
    this.onClose.emit(permissionsUpdated);
  }

  private getAllowedPermissionActionNames(values: Record<PermissionSelectorPermission, boolean>) {
    return Object.keys(values).filter(key => values[key as PermissionSelectorPermission]) as PermissionSelectorPermission[];
  }

  applyPermissions() {
    const permissions = this.getAllowedPermissionActionNames(this.permissionsFormGroup.getRawValue());
    const event: PermissionSelectorValue = {
      detailedPermissions: this.form.value.detailedPermissions!,
      revoking: this.form.value.revoking!,
      permissionSetLevel: this.form.value.permissionSetLevel,
      permissions
    };
    this.permissionsChanged.emit(event);
    this.closePopup(true);
  }

  revokingChange() {
    this.form.get('permissions')!.reset();
    this.form.get('permissions')!.enable();
  }

  ngOnInit() {
    if (isLeader(this.currentSessionService.currentUserFunctionalPosition!)) {
      this.revokeOptions = [
        {label: 'Povolit oprávnění', value: false},
        {label: 'Zakázat oprávnění', value: true},
      ];
    }

    this.form.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(value => {
      if (value.detailedPermissions && value.permissionSetLevel && this.isAtleastOnePermissionSelectedInFullList) {
        this.form.get('permissionSetLevel')!.reset();
      }
      else if (!value.detailedPermissions && this.isAtleastOnePermissionSelectedInFullList && value.permissionSetLevel) {
        this.form.get('permissions')!.reset();
      }
    });
    this.generateFullPermissionFormControls();
    this.generatePermissionListSettings();
    this.updateForm();

    if (this.isDocumentEntity) {
      this.loadingIndicatorService.doLoading(this.codebookService.getAllDocumentPermissionsWithDependencies(), this)
        .subscribe(all => {
          this.documentPermissionsWithRequires = all;
        });
    }
    else if (this.isFileEntity) {
      this.loadingIndicatorService.doLoading(this.codebookService.getAllFilePermissionsWithDependencies(), this)
        .subscribe(all => {
          this.filePermissionsWithRequires = all;
        });
    }
    else if (this.isStorageUnitEntity) {
      this.loadingIndicatorService.doLoading(this.codebookService.getAllStorageUnitPermissionsWithDependencies(), this)
        .subscribe(all => {
          this.storageUnitPermissionsWithRequires = all;
        });
    }

    if (this.isStorageUnitEntity) {
      this.shortPermissionOptions = enumToOptions('permissionSetLevel', PermissionSetLevel)
        .filter(o => o.value === PermissionSetLevel.READ_ONLY || o.value === PermissionSetLevel.FULL_ACCESS) as IczOption<GeneralPermissionSetLevel>[];
    }
    else if (this.isSharedFolderEntity) {
      this.shortPermissionOptions = enumToOptions('sharedFolderPermissionLevel', SharedFolderPermissionLevel) as IczOption<GeneralPermissionSetLevel>[];
    }
    else {
      this.shortPermissionOptions = enumToOptions('permissionSetLevel', PermissionSetLevel) as IczOption<GeneralPermissionSetLevel>[];
    }
  }

}
