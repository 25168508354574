import {isExternalRetentionTriggerType, isRetentionTriggerTypeWithPeriod} from '../shared-document.utils';
import {IczFormControl, IczValidators} from '@icz/angular-form-elements';
import {EventEmitter, WritableSignal} from '@angular/core';
import {RetentionTriggerTypeCode} from '|api/commons';

export function processRetentionTriggerTypeChange(
  selectedRTT: Nullable<RetentionTriggerTypeCode>,
  isRTTWithPeriod: WritableSignal<boolean>,
  isExternalRTTWithPeriod: WritableSignal<boolean>,
  retentionTriggerYearCtrl: IczFormControl,
  retentionTriggerCheckYearCtrl: IczFormControl
) {
  if (selectedRTT) {
    isRTTWithPeriod.set(isRetentionTriggerTypeWithPeriod(selectedRTT));
    isExternalRTTWithPeriod.set(isExternalRetentionTriggerType(selectedRTT));
    if (isRetentionTriggerTypeWithPeriod(selectedRTT)) {
      retentionTriggerYearCtrl.enable();
      retentionTriggerCheckYearCtrl.enable();
      retentionTriggerYearCtrl.setValidators([IczValidators.required()]);
      retentionTriggerCheckYearCtrl.setValidators([IczValidators.required(), IczValidators.min((new Date()).getFullYear()), IczValidators.isInteger()]);
    } else {
      retentionTriggerYearCtrl.disable();
      retentionTriggerCheckYearCtrl.disable();
      retentionTriggerYearCtrl.clearValidators();
      retentionTriggerCheckYearCtrl.clearValidators();
    }
  } else {
    isRTTWithPeriod.set(false);
    isExternalRTTWithPeriod.set(false);
    retentionTriggerYearCtrl.disable();
    retentionTriggerCheckYearCtrl.disable();
    retentionTriggerYearCtrl.clearValidators();
    retentionTriggerCheckYearCtrl.clearValidators();
  }

  retentionTriggerCheckYearCtrl.updateValueAndValidity({emitEvent: false});
  (retentionTriggerCheckYearCtrl.statusChanges as EventEmitter<any>).emit(retentionTriggerCheckYearCtrl.status);
  retentionTriggerYearCtrl.updateValueAndValidity({emitEvent: false});
  (retentionTriggerYearCtrl.statusChanges as EventEmitter<any>).emit(retentionTriggerYearCtrl.status);
}

export function processRetentionTriggerYearChange(
  value: Nullable<number>,
  retentionTriggerCheckYearCtrl: IczFormControl
) {
  if (value) {
    retentionTriggerCheckYearCtrl.clearValidators();
    retentionTriggerCheckYearCtrl.disable();
    retentionTriggerCheckYearCtrl.setValue(null);
  } else {
    retentionTriggerCheckYearCtrl.setValidators([IczValidators.required()]);
    retentionTriggerCheckYearCtrl.enable();
  }
  retentionTriggerCheckYearCtrl.updateValueAndValidity({emitEvent: false});
  (retentionTriggerCheckYearCtrl.statusChanges as EventEmitter<any>).emit(retentionTriggerCheckYearCtrl.status);
}

export function processRetentionTriggerCheckYearChange(
  value: Nullable<number>,
  retentionTriggerYearCtrl: IczFormControl
) {
  if (value) {
    retentionTriggerYearCtrl.clearValidators();
  } else {
    retentionTriggerYearCtrl.setValidators([IczValidators.required()]);
  }
  retentionTriggerYearCtrl.updateValueAndValidity({emitEvent: false});
  (retentionTriggerYearCtrl.statusChanges as EventEmitter<any>).emit(retentionTriggerYearCtrl.status);
}
