import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DisposalScheduleComparison, DisposalScheduleSourceType} from '../disposal-tip/disposal-tip.component';
import { DisposalTipDetailLevel } from '../shared-business-components.model';
import {IczFormGroup} from '@icz/angular-form-elements';
import {DocumentTypeDto, EntityClassDto} from '|api/codebook';
import {BehaviorSubject} from 'rxjs';
import {RetentionTriggerWidgetMode} from '../shared-document.utils';

@Component({
  selector: 'icz-disposal-schedule-retention-trigger-widget',
  templateUrl: './disposal-schedule-retention-trigger-widget.component.html',
  styleUrls: ['./disposal-schedule-retention-trigger-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisposalScheduleRetentionTriggerWidgetComponent {
  @Input({required: true})
  entityClassId: Nullable<number>;
  @Input()
  documentTypeId: Nullable<number>;
  @Input({required: true})
  form!: IczFormGroup;
  @Input()
  useValuesFromForm = false;
  @Input()
  retentionTriggerWidgetMode: Nullable<BehaviorSubject<RetentionTriggerWidgetMode>>;

  disposalScheduleSource: Nullable<DisposalScheduleSourceType>;
  disposalScheduleComparison: Nullable<DisposalScheduleComparison>;
  selectedDisposalScheduleSourceEntity: Nullable<EntityClassDto|DocumentTypeDto>;
  readonly DisposalTipDetailLevel = DisposalTipDetailLevel;

  get formDisposalScheduleId() {
    if (this.useValuesFromForm) {
      return this.form.get('disposalScheduleId')!.value;
    } else {
      return null;
    }
  }

  get disposalScheduleSourceEntitySelectedId() {
    if (this.useValuesFromForm) {
      return this.form.get('disposalScheduleId')!.value;
    } else if (this.selectedDisposalScheduleSourceEntity){
      return this.selectedDisposalScheduleSourceEntity.disposalScheduleId;
    } else {
      return null;
    }
  }

  get externalRetentionTriggerSelectedIds() {
    if (this.useValuesFromForm) {
      return this.form.get('externalRetentionTriggerIds')!.value;
    } else if (this.selectedDisposalScheduleSourceEntity){
      return [this.selectedDisposalScheduleSourceEntity?.externalRetentionTriggerId!];
    } else {
      return null;
    }
  }

  onDisposalScheduleSource(disposalScheduleSource: Nullable<DisposalScheduleSourceType>) {
    this.disposalScheduleSource = disposalScheduleSource;
  }

  onDisposalScheduleSourceEntitySelected(source: Nullable<EntityClassDto|DocumentTypeDto>) {
    this.selectedDisposalScheduleSourceEntity = source;
  }

  onDisposalScheduleComparison(comparison: Nullable<DisposalScheduleComparison>) {
    this.disposalScheduleComparison = comparison;
  }
}
