import {Component, inject, OnInit} from '@angular/core';
import {AbstractTableFilter} from '../../../abstract-table-filter.component';
import {PageSelectorFilterItem} from '../../../filter.types';
import {IczModalService} from '@icz/angular-modal';
import {
  PageSelectorFilterDialogComponent,
  PageSelectorFilterDialogData,
  PageSelectorFilterDialogResult
} from './page-selector-filter-dialog/page-selector-filter-dialog.component';
import {isNoValueOperator} from '../../../table.utils';

/**
 * @internal
 */
@Component({
  selector: 'icz-page-selector-filter',
  templateUrl: './page-selector-filter.component.html',
  styleUrls: ['./page-selector-filter.component.scss'],
  standalone: true,
})
export class PageSelectorFilterComponent extends AbstractTableFilter implements OnInit {

  private iczModalService = inject(IczModalService);

  selectedRows: any[] = [];

  applyFilter(): void {
    this.emitFilterValue();
    this.closeFilterPopup();
  }

  emitFilterValue(): void {
    let value = this.form.get('value')!.value;
    if (!value?.length || !this.selectedRows.length) return;

    value = value.sort();
    const filterOperator = this.form.get('filterOperator')!.value;
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    this.selectedRows = this.selectedRows.sort((a, b) => {
      return a.id! - b.id!;
    });

    this.setFilterValue.emit({
      value,
      label: null,
      filterOperator,
      closeAfter: true,
    });
  }

  applyFilterOperator(): void {
    const filterOperator = this.form.get('filterOperator')!.value;
    const valueControl = this.form.get('value');
    this.item.filterOption = this.filterOperators.find(f => f.value === filterOperator)!;

    if (isNoValueOperator(filterOperator)) {
      valueControl!.setValue(null, {emitEvent: false});
      valueControl!.disable();
      this.emitNoValueFilterValue(filterOperator);
      this.closeFilterPopup();
    } else if (valueControl!.value) {
      this.emitFilterValue();
    } else {
      valueControl!.enable();
    }
  }

  ngOnInit(): void {
    this.initForm();
    const pageSelectorFilterItem = (this.item as PageSelectorFilterItem);
    this.iczModalService.openComponentInModal<PageSelectorFilterDialogResult, PageSelectorFilterDialogData>({
      component: PageSelectorFilterDialogComponent,
      modalOptions: {
        width: 1000,
        height: 700,
        titleTemplate: (this.item as PageSelectorFilterItem).tableFilterTitle,
        disableAutoMargin: true,
        useCustomHeader: true,
      },
      data: {
        filterTableId: pageSelectorFilterItem.filterTableId!,
        form: this.form,
        filterOperators: this.filterOperators,
        tableTitle: pageSelectorFilterItem.tableFilterTitle!,
        tableTitleTag: pageSelectorFilterItem.tableTag!,
        schema: pageSelectorFilterItem.tableSchema!,
        defaultFilterColumns: pageSelectorFilterItem.defaultFilterColumns!,
        dataSource: pageSelectorFilterItem.filterDataSource!,
        fulltextSearchFieldPlaceholder: pageSelectorFilterItem.filterFulltextSearchFieldPlaceholder,
      },
    }).subscribe(result => {
      if (!result) return;
      this.selectedRows = result.selectedRows;
      const ids = this.selectedRows.map(s => s[pageSelectorFilterItem.modelValueKey!]!);
      this.form.get('value')!.setValue(ids);
      if (this.selectedRows.length) {
        this.applyFilter();
      }
    });
  }
}
