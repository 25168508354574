import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  formatAsLocalIsoDate,
  getTodayMidnight,
  IczFormGroup,
  OutputDateFormat,
  ValidDateFnWithMessage
} from '@icz/angular-form-elements';

const INVALID_VALIDITY_RANGE_MESSAGE: string = 'Rozsah platnosti je neplatný';

@Component({
  selector: 'icz-validity-widget',
  templateUrl: './validity-widget.component.html',
  styleUrls: ['./validity-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ValidityWidgetComponent {

  @Input({required: true}) form!: IczFormGroup;
  @Input() wrapInSection = false;
  @Input() sectionLabel = 'Platnost';
  @Input() dateOutputFormat: OutputDateFormat = 'date';

  @Input() validFromControlName = 'validFrom';
  @Input() validToControlName = 'validTo';
  @Input() validFromHelpTooltip = '';
  @Input() validToHelpTooltip = '';

  get validFromValue() {
    return this.form.get(this.validFromControlName)!.value;
  }

  get validToValue() {
    return this.form.get(this.validToControlName)!.value;
  }

  selectableValidFromDates: ValidDateFnWithMessage = {
    validationFn: validFromDate => {
      return (
        validFromDate >= getTodayMidnight() &&
        (
          !this.validToValue ||
          (this.validToValue && formatAsLocalIsoDate(validFromDate) <= formatAsLocalIsoDate(this.validToValue))
        )
      );
    },
    invalidDateMessage: {
      errorMessageTemplate: INVALID_VALIDITY_RANGE_MESSAGE,
    },
  };
  selectableValidToDates: ValidDateFnWithMessage = {
    validationFn: validToDate => {
      return (
        validToDate >= getTodayMidnight() &&
        (
          !this.validFromValue ||
          (this.validFromValue && formatAsLocalIsoDate(this.validFromValue) <= formatAsLocalIsoDate(validToDate))
        )
      );
    },
    invalidDateMessage: {
      errorMessageTemplate: INVALID_VALIDITY_RANGE_MESSAGE,
    },
  };

}
