import {Component, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {CirculationActivityType} from '|api/commons';
import {
  ApiCirculationTaskAcknowledgementService,
  ApiCirculationTaskApprovalService,
  ApiCirculationTaskStatementService,
  CirculationTaskDto,
  CirculationTaskPassDto
} from '|api/flow';
import {AbstractHandoverFormModalComponent} from '../abstract-handover-form-modal.component';
import {DerivedOrgStructureElementOption} from '../abstract-handover-form.component';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup, IczOption, IczValidators, TextLength} from '@icz/angular-form-elements';
import {SKIP_ERROR_DIALOG} from '../../../../../core/error-handling/http-errors';
import {ExtendedCirculationTaskDto} from '../../../model/elastic-extended-entities.interface';
import {handleCirculationErrorAsToast} from '../../../../../utils/document-file-circulation.utils';
import {injectModalData} from '@icz/angular-modal';


@Component({
  selector: 'icz-generic-pass-to-next-handler-dialog',
  templateUrl: './generic-pass-to-next-handler-dialog.component.html',
  styleUrls: ['./generic-pass-to-next-handler-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class GenericPassToNextHandlerDialogComponent extends AbstractHandoverFormModalComponent {

  private apiCirculationTaskAcknowledgementService = inject(ApiCirculationTaskAcknowledgementService);
  private apiCirculationTaskStatementService = inject(ApiCirculationTaskStatementService);
  private apiCirculationTaskApprovalService = inject(ApiCirculationTaskApprovalService);
  protected override circulationEntities = injectModalData<ExtendedCirculationTaskDto[]>();

  override form = new IczFormGroup({
    orgStructureElementIds: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    targetTaskInstruction: new IczFormControl<Nullable<string>>(null, [], [], TextLength.LONG),
    deadline: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    printHandoverProtocol: new IczFormControl<Nullable<boolean>>(false),
  });

  protected override selectedFlowType = null;

  get activityType() {
    return this.circulationEntities[0].activityType;
  }

  get isSelectorMultiselect() {
    return this.activityType === CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT;
  }

  protected override get selectedOrgStructureOptions(): DerivedOrgStructureElementOption[] {
    const controlValue = this.orgStructureElementsControl!.value ?? [];

    return (Array.isArray(controlValue) ? controlValue : [controlValue]).map((orgStructureOptionValue: number) => {
      const foundOption = this.orgStructureOptions.find(o => o.value === orgStructureOptionValue) as IczOption<number>;
      return {...foundOption, value: foundOption.id!, data: {originalValue: foundOption.value}};
    });
  }

  override ngOnInit() {
    super.ngOnInit();

    const deadlineControl = this.form.get('deadline')!;

    if (!this.circulationEntities[0].deadline) {
      deadlineControl.enable();
    }
    else {
      deadlineControl.setValue(this.circulationEntities[0].deadline);
    }
  }

  passToNextHandler() {
    const taskId = this.circulationEntities[0].id;
    const formValue = this.form.getRawValue();

    // todo implement singleselect FP/OU selector support after BE ready
    const taskPassDto: CirculationTaskPassDto = {
      targetTaskInstruction: formValue.targetTaskInstruction!,
      functionalPositions: this.selectedFunctionalPositions,
      organizationalUnits: this.selectedOrganizationalUnits,
      deadline: this.circulationEntities[0].deadline ? formValue.deadline! : undefined,
    };

    let request$!: Observable<CirculationTaskDto>;

    if (this.activityType === CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT) {
      request$ = this.apiCirculationTaskAcknowledgementService.circulationTaskAcknowledgementPass(
        {
          id: taskId,
          body: taskPassDto,
        },
        SKIP_ERROR_DIALOG
      );
    }
    else if (this.activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS) {
      request$ = this.apiCirculationTaskStatementService.circulationTaskStatementPass(
        {
          id: taskId,
          body: taskPassDto,
        },
        SKIP_ERROR_DIALOG
      );
    }
    else if (this.activityType === CirculationActivityType.DOCUMENT_STATEMENT) {
      request$ = this.apiCirculationTaskStatementService.circulationTaskStatementPass(
        {
          id: taskId,
          body: taskPassDto,
        },
        SKIP_ERROR_DIALOG
      );
    }
    else if (
      this.activityType === CirculationActivityType.DOCUMENT_APPROVAL ||
      this.activityType === CirculationActivityType.COMPONENT_APPROVAL
    ) {
      request$ = this.apiCirculationTaskApprovalService.circulationTaskApprovalPass(
        {
          id: taskId,
          body: taskPassDto,
        },
        SKIP_ERROR_DIALOG
      );
    }

    this.loadingService.doLoading(
      request$,
      this,
    ).subscribe({
      next: createdTask => this.modalRef.close(createdTask.id),
      error: errorResponse => handleCirculationErrorAsToast(
        this.circulationEntities[0],
        errorResponse,
        this.toastService,
        this.translateService,
        this.modalRef,
        this.circulationEntities[0].activityType
      ),
    });
  }

}
