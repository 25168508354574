import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {combineLatest} from 'rxjs';
import {CirculationActivityFlowType} from '|api/commons';
import {ApiCirculationActivityService, ApiCirculationTaskService, CirculationActivityDto} from '|api/flow';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {getTasksInfo, TaskInfo} from '../../task-info-bit/task-info.model';
import {LoadingIndicatorService} from '@icz/angular-essentials';

@Component({
  selector: 'icz-activity-steps-preview',
  templateUrl: './activity-steps-preview.component.html',
  styleUrls: ['./activity-steps-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivityStepsPreviewComponent implements IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private cd = inject(ChangeDetectorRef);
  private apiCirculationTaskService = inject(ApiCirculationTaskService);
  private apiCirculationActivityService = inject(ApiCirculationActivityService);

  @Input({required: true})
  activity!: CirculationActivityDto;

  activityTasksInfo: TaskInfo[] = [];

  activityTasksInfoBeforeChild: TaskInfo[] = [];
  activityTasksInfoAfterChild: TaskInfo[] = [];

  get childActivity(): Nullable<CirculationActivityDto> {
    return this.activity.childActivities?.[0];
  }

  readonly CirculationActivityFlowType = CirculationActivityFlowType;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.activity && this.activity) {
      combineLatest([
        this.apiCirculationTaskService.circulationTaskFindByActivityId({
          activityId: this.activity.id
        }),
        this.apiCirculationActivityService.circulationActivityGetTargetParticipants({
          id: this.activity.id
        }),
      ]).subscribe(([activityTasks, activityParticipants]) => {
        this.activityTasksInfo = getTasksInfo(
          this.activity,
          activityTasks,
          activityParticipants
        );

        this.activityTasksInfoBeforeChild = getTasksInfo(
          this.activity,
          this.childActivity ? activityTasks.filter(t => t.settlementDate && t.settlementDate < this.childActivity!.initDate) : activityTasks,
          activityParticipants
        );

        if (this.childActivity) {
          this.activityTasksInfoAfterChild = getTasksInfo(
            this.activity,
            activityTasks.filter(t => !t.settlementDate || t.settlementDate > this.childActivity!.initDate),
            activityParticipants,
            this.activityTasksInfoBeforeChild,
          );
        }

        this.cd.detectChanges();
      });
    }
  }

}
