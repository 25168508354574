import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ApplicationLanguage} from '../../../core/services/environment.models';
import {AVAILABLE_LANGUAGES} from '../../../core/localization/loader';

@Component({
  selector: 'icz-login-language-selector',
  templateUrl: './login-language-selector.component.html',
  styleUrls: ['./login-language-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginLanguageSelectorComponent {
  @Input({required: true})
  languageCode: string = ApplicationLanguage.CZECH;
  @Output()
  languageChanged = new EventEmitter<ApplicationLanguage>();

  availableLanguages = AVAILABLE_LANGUAGES;
  popoverOpen = false;

  get currentLanguage() {
    return this.availableLanguages.find(l => l.value === this.languageCode);
  }

  changeLanguage(languageCode: ApplicationLanguage) {
    this.languageChanged.emit(languageCode);
  }
}
