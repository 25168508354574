import {TranslateService} from '@ngx-translate/core';
import {SheetNodeDto} from '|api/config-server';
import {AddressFormat} from '../../model/addresses.model';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';
import {IczOption} from '@icz/angular-form-elements';

export enum OwnConsignmentWorkflowAction {
  HANDOVER = 'HANDOVER',
  TAKEOVER = 'TAKEOVER',
  PREPARE_FOR_DISTRIBUTION = 'PREPARE_FOR_DISTRIBUTION',
  TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION = 'TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION',
  DISPATCH = 'DISPATCH',
  DISPATCH_OUTSIDE_FILING_OFFICE = 'DISPATCH_OUTSIDE_FILING_OFFICE',
  POST = 'POST', // 'ručně vyvěsit'
  UNPOST = 'UNPOST', // 'ručně svěsit'
  REJECT = 'REJECT',
  WITHDRAW_FROM_DISTRIBUTION = 'WITHDRAW_FROM_DISTRIBUTION', // withdraw from distribution paper specific
  WITHDRAW_FOR_CORRECTION = 'WITHDRAW_FOR_CORRECTION',
  CANCEL = 'CANCEL',
  DELETE = 'DELETE',
}

export enum ConsignmentDeliveryFilingType {
	DELIVERY_FILLING_OFFICE = 'DELIVERY_FILLING_OFFICE',
	DELIVERY_NO_FILLING_OFFICE = 'DELIVERY_NO_FILLING_OFFICE',
}

export enum OwnConsignmentTableConsignmentTypeFilter {
	ALL = 'ALL',
	OWN_PAPER_ADDRESS = 'OWN_PAPER_ADDRESS',
	OWN_DIGITAL_DATABOX = 'OWN_DIGITAL_DATABOX',
	OWN_DIGITAL_EMAIL = 'OWN_DIGITAL_EMAIL',
	OWN_INTERNAL = 'OWN_INTERNAL',
}

export function sheetNodeToOption(translateService: TranslateService) {
  return (sheetNode: SheetNodeDto): IczOption<number> => {
    const addressClass = sheetNode.address._Class;
    if (addressClass === AddressFormat.CzechAddressDto || addressClass === AddressFormat.SlovakAddressDto) {
      return {
        label: `${sheetNode.nodeName} - ${SubjectTemplateUtils.getSubjectStreetAndNumber(sheetNode.address)}`,
        value: sheetNode.id
      };
    }
    else if (addressClass === AddressFormat.GenericLineAddressDto) {
      return {
        label: `${sheetNode.nodeName} - ${SubjectTemplateUtils.inlineDisplayGenericLineAddress(sheetNode.address)}`,
        value: sheetNode.id
      };
    }
    else {
      return {label: `${sheetNode.nodeName} - ${translateService.instant('Neznámý druh adresy')}`, value: sheetNode.id};
    }
  };
}
