import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, startWith} from 'rxjs/operators';
import {DeferredColumnList, isListColumnDefinition, MIN_SEARCH_TERM_LENGTH, TableToolbarConfig} from '../table.models';
import {FormAutocompleteComponent, FormFieldComponent, PrimitiveControlValueType} from '@icz/angular-form-elements';
import {
  AutoFocusDirective,
  ButtonComponent,
  DetachingService,
  IconComponent,
  IczOnChanges,
  IczSimpleChanges,
  LinkWithoutHrefDirective,
  PopoverComponent,
  ToggleButtonComponent
} from '@icz/angular-essentials';
import {FilterItem, FilterType} from '../filter.types';
import {TableToolbarService} from './table-toolbar.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TableColumnsData} from '../table-columns-data';
import {FilterNameService} from '../filter-name.service';
import {FilterTreeOperator, isFilterTreeEmpty, isSimpleQueryFilterTree} from '../filter-trees.utils';
import {FilterTagComponent} from './filter-tag/filter-tag.component';
import {ReactiveFormsModule} from '@angular/forms';
import {AsyncPipe, UpperCasePipe} from '@angular/common';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {FilterItemComponent} from './filter-item/filter-item.component';
import {TableSavedFiltersComponent} from './table-saved-filters/table-saved-filters.component';

/**
 * Default table toolbar config.
 */
export function getDefaultTableToolbarConfig(): TableToolbarConfig {
  return {
    showFilter: true,
    showReload: true,
    showToolbar: true,
    showTools: true,
    showFulltextSearch: false,
    fulltextSearchFieldPlaceholder: '',
    showColumnSelector: true,
    filterIsStatic: false,
    autoOpenFilter: false,
    showSavedFilters: true,
    disableFilter: false,
  };
}

/**
 * @internal
 */
function isNonEmptyElement(el: Element): boolean {
  return el.innerHTML.trim().length > 0;
}

/**
 * @internal
 */
@Component({
  selector: 'icz-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    FilterNameService,
  ],
  standalone: true,
  imports: [
    ToggleButtonComponent,
    ButtonComponent,
    FilterItemComponent,
    AutoFocusDirective,
    FormFieldComponent,
    IconComponent,
    FilterTagComponent,
    PopoverComponent,
    FormAutocompleteComponent,
    TranslateModule,
    LinkWithoutHrefDirective,
    TableSavedFiltersComponent,
    ReactiveFormsModule,
    AsyncPipe,
    CdkOverlayOrigin,
    UpperCasePipe,
  ],
})
export class TableToolbarComponent implements OnInit, IczOnChanges {

  protected tableToolbarService = inject(TableToolbarService);
  protected translate = inject(TranslateService);
  protected router = inject(Router);
  private cd = inject(ChangeDetectorRef);
  private detachingService = inject(DetachingService);
  private destroyRef = inject(DestroyRef);
  private filterNameService = inject(FilterNameService);

  @ViewChild('searchTermInput', {static: false})
  protected searchTermInput!: FormFieldComponent;
  @ViewChild('filterChild', {static: false})
  protected filterButton!: ToggleButtonComponent;
  @ViewChild('titleContent', {static: true})
  protected titleContentEl!: ElementRef;
  @ViewChild('toolsContent', {static: true})
  protected toolsContentEl!: ElementRef;
  @ViewChild('tabsContent', {static: true})
  protected tabsContentEl!: ElementRef;

  @Input({required: true})
  config: TableToolbarConfig = getDefaultTableToolbarConfig();
  @Input({required: true})
  set enabledFilters(newValue: Nullable<string[]>) {
    if (this.tableToolbarService.enabledFilters$.value !== newValue) {
      this.tableToolbarService.enabledFilters$.next(newValue);
    }
  }

  @Input({required: true}) tableIdForLocalStorage!: string;
  @Input({required: true}) columnsData: Nullable<TableColumnsData<string>>;
  @Input({required: true}) listLoadingStates!: Record<string, boolean>;
  @Input()
  set searchTerm(newValue: string) {
    if (newValue.trim() !== this._searchTerm.trim()) {
      this._searchTerm = newValue;

      if (this._searchTerm) {
        const realSearchTerm = this._searchTerm.trim();

        this.toolbarForm.get('searchTerm')!.setValue(realSearchTerm);

        if (this.searchTermInput) {
          this.searchTermInput.focusField();
        }
      }
    }
  }
  @Input() allowSavingFiltersWithEmptyValues = true;
  @Input() subToolbarOpened  = false;

  @Output() searchTermChange = new EventEmitter<string>();
  @Output() reloadClicked = new EventEmitter<void>();
  @Output() editQueryRequested = new EventEmitter<void>();
  @Output() columnSettingsClicked = new EventEmitter<void>();

  protected toolbarForm = this.tableToolbarService.toolbarForm;

  protected isMoreFiltersPopupOpen = false;

  private collator = new Intl.Collator(this.translate.currentLang);

  protected availableColumnFilterOptions$ = combineLatest([
    this.tableToolbarService.allColumnFilterOptions$,
    this.tableToolbarService.enabledFilters$,
  ]).pipe(
    map(([allColumnFilterOptions, enabledFilters]) => {
      if (enabledFilters) {
        return allColumnFilterOptions.filter(f => enabledFilters.includes(String(f.value)));
      }
      else {
        return allColumnFilterOptions;
      }
    }),
    map(columnOptions => columnOptions.sort((o1, o2) => this.collator.compare(o1.label, o2.label))),
  );

  protected distinctiveFilters$ = this.tableToolbarService.distinctiveFilters$;

  protected visibleFilters$ = this.tableToolbarService.visibleFilters$;

  protected isSimpleQueryMode$ = this.tableToolbarService.activeFilters$.pipe(
    map(activeFilters => isSimpleQueryFilterTree(activeFilters)),
  );

  private searchTermValue$ = this.searchTermControl.valueChanges.pipe(
    startWith(this.searchTermControl.value),
  );

  protected filterName$ = this.filterNameService.getFilterNameObs(
    this.tableToolbarService.activeFilters$.pipe(debounceTime(1000)),
    this.searchTermValue$,
    true,
  );

  protected defaultFilterName$!: Observable<Nullable<string>>;

  get hasTabsContent(): boolean {
    return this.tabsContentEl ? isNonEmptyElement(this.tabsContentEl.nativeElement) : false;
  }

  protected get hasTitleContent(): boolean {
    return this.titleContentEl ? isNonEmptyElement(this.titleContentEl.nativeElement) : false;
  }

  private get searchTermControl() {
    return this.tableToolbarService.toolbarForm.get('searchTerm')!;
  }

  protected getDeferredFilterListPerColumn(item: FilterItem): DeferredColumnList {
    const columnDefinition = this.columnsData?.getColumnById(item.id);

    if (isListColumnDefinition(columnDefinition)) {
      return {list: (columnDefinition.list ?? []), isListLoading: this.listLoadingStates[columnDefinition.id] ?? false};
    }
    else {
      return {list: [], isListLoading: false};
    }
  }

  protected _searchTerm: string = '';

  private listLoadingStatesSubscription: Nullable<Subscription>;

  ngOnInit() {
    this.detachingService.registerDetach('columnResize', this.cd);

    this.tableToolbarService.reloadFilters$.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(_ => this.cd.detectChanges());

    this.router.events.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(navigationEvent => {
      if (navigationEvent instanceof NavigationStart) {
        this.tableToolbarService.autoOpen = false;
      }
    });

    if (this.config?.autoOpenFilter) {
      this.subToolbarOpened = true;
    }

    this.toolbarForm.get('searchTerm')!.valueChanges.pipe(
      filter(searchTerm => {
        const coalescedSearchTermLength = searchTerm?.length ?? 0;
        return coalescedSearchTermLength === 0 || coalescedSearchTermLength >= MIN_SEARCH_TERM_LENGTH;
      }),
      map(searchTerm => searchTerm ?? ''),
      debounceTime(500),
      distinctUntilChanged(),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(newTerm => this.searchTermChange.emit(newTerm!));

    if (this.allowSavingFiltersWithEmptyValues) {
      this.defaultFilterName$ = this.filterNameService.getFilterNameObs(
        this.tableToolbarService.visibleFilters$.pipe(
          map(visibleFilters => ({
            operator: FilterTreeOperator.NONE,
            values: visibleFilters,
          })),
        ),
        this.searchTermValue$,
        false,
      );
    }
    else {
      this.defaultFilterName$ = this.filterNameService.getFilterNameObs(
        this.tableToolbarService.activeFilters$,
        this.searchTermValue$,
        false,
      );
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.columnsData && this.columnsData) {
      this.listLoadingStatesSubscription?.unsubscribe();

      this.listLoadingStatesSubscription = this.columnsData!.listLoadingStatesChange$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(() => this.cd.detectChanges());
    }
  }

  private addFilter(filterId: string) {
    const item = this.tableToolbarService.allColumnFilters$.value.find(u => u.id === filterId || u.customFilterId === filterId)!;
    this.tableToolbarService.autoOpen = item.filterType !== FilterType.BOOLEAN;
    this.tableToolbarService.addFilterItem({...item, id: filterId, value: null, label: null});
  }

  protected toggleFilter() {
    this.tableToolbarService.autoOpen = false;
    this.subToolbarOpened = !this.subToolbarOpened;
  }

  protected activeFilterIndication(): boolean {
    return !isFilterTreeEmpty(this.tableToolbarService.activeFilterValues$.value) && !this.subToolbarOpened;
  }

  protected moreFiltersChanged(filterId: PrimitiveControlValueType) {
    this.isMoreFiltersPopupOpen = false;
    this.addFilter(filterId as string);
  }

  protected isSearchTermRightLength(searchTerm: Nullable<string>) {
    return (searchTerm?.length ?? 0) >= MIN_SEARCH_TERM_LENGTH;
  }

}
