/* tslint:disable */
/* eslint-disable */

/**
 * Export type for agenda transfer
 */
export enum AgendaTransferExportType {
  TESTING = 'TESTING',
  FINAL = 'FINAL'
}
