import {Component, EventEmitter, inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DigitalComponentVersionSignatureFlagType, EpdzMessageType} from '|api/commons';
import {
  ApiReceivedDigitalConsignmentService,
  EpdzErrorDto,
  ReceivedEpdzAttachmentDto,
  ReceivedEpdzMessageDto
} from '|api/sad';
import {EpdzAttachmentViewerService} from '../epdz-attachment-viewer/epdz-attachment-viewer.service';
import {
  ColumnDefinition,
  extendDefaultTableConfig,
  FilterType,
  IczTableDataSource,
  SearchParams,
  TableColumnsData,
  TableComponent,
  TableConfig,
  TableReservedColumns
} from '@icz/angular-table';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {CodebookService} from '../../../core/services/codebook.service';
import {
  PlainComponentVersionViewerDialogService
} from '../plain-component-version-viewer-dialog/plain-component-version-viewer-dialog.service';
import {getEpdzMessageIcon} from '../shared-document.utils';
import {map, of} from 'rxjs';
import {FindFunctionalPositionByIdPipe} from '../find-functional-position-by-id.pipe';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {CurrentSessionService} from '../../../services/current-session.service';
import {IczOption} from '@icz/angular-form-elements';

export enum ReceivedDigitalMessagesTableView {
  EMAIL_CONSIGNMENTS = 'EMAIL_CONSIGNMENTS',
  EMAIL_CONSIGNMENTS_WITH_DEFECTS = 'EMAIL_CONSIGNMENTS_WITH_DEFECTS',
  DATABOX_CONSIGNMENTS = 'DATABOX_CONSIGNMENTS',
  EPDZ_ERRORS = 'EPDZ_ERRORS'
}

export enum ReceivedDigitalMessagesTableColumn {
  EXTERNAL_ID = 'externalId',
  MESSAGE_EXTERNAL_ID = 'messageExternalId',
  MESSAGE_ID = 'messageId',
  SUBJECT = 'subject',
  CONSIGNEE_ADDRESS = 'consigneeAddress',
  SENDER_ADDRESS = 'senderAddress',
  MESSAGE_ISSUES = 'messageIssues',
  PROCESSING_ERRORS = 'processingErrors',
  DATA_BOX_ID = 'dataBoxId',
  CONSIGNEE_ADDRESS_ALIAS = 'consigneeAddressDatabox',
  MESSAGE_TYPE = 'messageType',
  RECEIVED_DATE = 'receivedDate',
  RECEIVED_TIME = 'receivedTime',
  SENDER_LABEL = 'senderLabel',
  SIGNATURE_FLAG = 'signatureFlag',
}

const COLUMNSETS: Record<ReceivedDigitalMessagesTableView, (TableReservedColumns.SELECTION|ReceivedDigitalMessagesTableColumn)[]> = {
  [ReceivedDigitalMessagesTableView.EMAIL_CONSIGNMENTS]: [
    TableReservedColumns.SELECTION,
    ReceivedDigitalMessagesTableColumn.MESSAGE_TYPE,
    ReceivedDigitalMessagesTableColumn.SUBJECT,
    ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS,
    ReceivedDigitalMessagesTableColumn.SENDER_LABEL,
    ReceivedDigitalMessagesTableColumn.RECEIVED_DATE,
    ReceivedDigitalMessagesTableColumn.SIGNATURE_FLAG,
    ReceivedDigitalMessagesTableColumn.EXTERNAL_ID,
  ],
  [ReceivedDigitalMessagesTableView.EMAIL_CONSIGNMENTS_WITH_DEFECTS]: [
    TableReservedColumns.SELECTION,
    ReceivedDigitalMessagesTableColumn.MESSAGE_TYPE,
    ReceivedDigitalMessagesTableColumn.SUBJECT,
    ReceivedDigitalMessagesTableColumn.MESSAGE_ISSUES,
    ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS,
    ReceivedDigitalMessagesTableColumn.SENDER_LABEL,
    ReceivedDigitalMessagesTableColumn.RECEIVED_DATE,
    ReceivedDigitalMessagesTableColumn.SIGNATURE_FLAG,
    ReceivedDigitalMessagesTableColumn.EXTERNAL_ID,
  ],
  [ReceivedDigitalMessagesTableView.DATABOX_CONSIGNMENTS]: [
    TableReservedColumns.SELECTION,
    ReceivedDigitalMessagesTableColumn.MESSAGE_TYPE,
    ReceivedDigitalMessagesTableColumn.SUBJECT,
    ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS_ALIAS,
    ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS,
    ReceivedDigitalMessagesTableColumn.SENDER_LABEL,
    ReceivedDigitalMessagesTableColumn.DATA_BOX_ID,
    ReceivedDigitalMessagesTableColumn.RECEIVED_DATE,
    ReceivedDigitalMessagesTableColumn.SIGNATURE_FLAG,
    ReceivedDigitalMessagesTableColumn.EXTERNAL_ID,
  ],
  [ReceivedDigitalMessagesTableView.EPDZ_ERRORS]: [
    TableReservedColumns.SELECTION,
    ReceivedDigitalMessagesTableColumn.MESSAGE_TYPE,
    ReceivedDigitalMessagesTableColumn.PROCESSING_ERRORS,
    ReceivedDigitalMessagesTableColumn.MESSAGE_ID,
    ReceivedDigitalMessagesTableColumn.SUBJECT,
    ReceivedDigitalMessagesTableColumn.SENDER_ADDRESS,
    ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS,
    ReceivedDigitalMessagesTableColumn.RECEIVED_TIME,
    ReceivedDigitalMessagesTableColumn.MESSAGE_EXTERNAL_ID,
  ],
};


type ReceivedDigitalMessagesTableColumnId = TableReservedColumns.SELECTION|ReceivedDigitalMessagesTableColumn;

function getColumnDefinitionsByView(
  view: ReceivedDigitalMessagesTableView
): Record<ReceivedDigitalMessagesTableColumnId, ColumnDefinition<ReceivedDigitalMessagesTableColumn>> {
  function getConsigneeAddressColumnLabel() {
    if (view === ReceivedDigitalMessagesTableView.DATABOX_CONSIGNMENTS) {
      return 'Dat. schránka adresáta';
    }
    else if (view === ReceivedDigitalMessagesTableView.EPDZ_ERRORS) {
      return 'Adresa adresáta';
    }
    else {
      return 'Adresát';
    }
  }

  return {
    [TableReservedColumns.SELECTION]: {
      id: TableReservedColumns.SELECTION,
      label: 'Výběr',
      filterType: FilterType.NONE,
    },
    [ReceivedDigitalMessagesTableColumn.EXTERNAL_ID]: {
      id: ReceivedDigitalMessagesTableColumn.EXTERNAL_ID,
      label: 'Identifikátor',
      filterType: FilterType.TEXT,
    },
    [ReceivedDigitalMessagesTableColumn.MESSAGE_EXTERNAL_ID]: {
      id: ReceivedDigitalMessagesTableColumn.MESSAGE_EXTERNAL_ID,
      label: 'Označení přijaté zprávy',
      filterType: FilterType.TEXT,
    },
    [ReceivedDigitalMessagesTableColumn.MESSAGE_ID]: {
      id: ReceivedDigitalMessagesTableColumn.MESSAGE_ID,
      label: 'Identifikátor',
      filterType: FilterType.NUMBER,
    },
    [ReceivedDigitalMessagesTableColumn.DATA_BOX_ID]: {
      id: ReceivedDigitalMessagesTableColumn.DATA_BOX_ID,
      label: 'Dat. schránka odesílatele', // column used solely for databox messages
      filterType: FilterType.TEXT,
    },
    [ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS]: {
      id: ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS,
      label: getConsigneeAddressColumnLabel(),
      filterType: FilterType.TEXT,
    },
    [ReceivedDigitalMessagesTableColumn.SENDER_ADDRESS]: {
      id: ReceivedDigitalMessagesTableColumn.SENDER_ADDRESS,
      label: 'Adresa odesílatele',
      filterType: FilterType.TEXT,
    },
    [ReceivedDigitalMessagesTableColumn.SENDER_LABEL]: {
      id: ReceivedDigitalMessagesTableColumn.SENDER_LABEL,
      label: 'Odesílatel',
      filterType: FilterType.TEXT,
    },
    [ReceivedDigitalMessagesTableColumn.SUBJECT]: {
      id: ReceivedDigitalMessagesTableColumn.SUBJECT,
      label: 'Věc',
      filterType: FilterType.TEXT,
    },
    [ReceivedDigitalMessagesTableColumn.MESSAGE_ISSUES]: {
      id: ReceivedDigitalMessagesTableColumn.MESSAGE_ISSUES,
      label: 'Chyby ve vstupní kontrole',
      filterType: FilterType.NONE,
      disableSort: true,
    },
    [ReceivedDigitalMessagesTableColumn.PROCESSING_ERRORS]: {
      id: ReceivedDigitalMessagesTableColumn.PROCESSING_ERRORS,
      label: 'Chyby zpracování',
      icon: 'error',
      filterType: FilterType.NONE,
      disableSort: true,
      fixedWidth: 35,
    },
    [ReceivedDigitalMessagesTableColumn.MESSAGE_TYPE]: {
      id: ReceivedDigitalMessagesTableColumn.MESSAGE_TYPE,
      label: 'Typ',
      filterType: (view === ReceivedDigitalMessagesTableView.EMAIL_CONSIGNMENTS ? FilterType.NONE : FilterType.ENUM),
      list: (
        view === ReceivedDigitalMessagesTableView.EMAIL_CONSIGNMENTS ?
          undefined :
          enumToOptions('epdzMessageType', EpdzMessageType)
            .filter(o => o.value !== EpdzMessageType.EMAIL)
      ),
      disableSort: true,
    },
    [ReceivedDigitalMessagesTableColumn.RECEIVED_DATE]: {
      id: ReceivedDigitalMessagesTableColumn.RECEIVED_DATE,
      label: 'Doručen',
      filterType: FilterType.DATETIME,
    },
    [ReceivedDigitalMessagesTableColumn.RECEIVED_TIME]: {
      id: ReceivedDigitalMessagesTableColumn.RECEIVED_TIME,
      label: 'Doručeno',
      filterType: FilterType.DATETIME,
    },
    [ReceivedDigitalMessagesTableColumn.SIGNATURE_FLAG]: {
      id: ReceivedDigitalMessagesTableColumn.SIGNATURE_FLAG,
      label: 'Podpis/pečeť',
      filterType: FilterType.ENUM,
      list: enumToOptions(
        'digitalComponentVersionSignatureFlagType',
        DigitalComponentVersionSignatureFlagType
      ),
    },
    [ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS_ALIAS]: {
      id: ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS_ALIAS,
      label: 'Název schránky adresáta',
      filterType: FilterType.NONE, // todo(rb) make it filterable after multi databox ready on BE
      disableSort: true,
    },
  };
}

function isEpdzErrorDto(val: any): val is EpdzErrorDto {
  return val && 'messageExternalId' in val && 'originalDigitalComponentVersionId' in val;
}

@Component({
  selector: 'icz-received-digital-messages-table',
  templateUrl: './received-digital-messages-table.component.html',
  styleUrls: ['./received-digital-messages-table.component.scss'],
  providers: [FindFunctionalPositionByIdPipe]
})
export class ReceivedDigitalMessagesTableComponent<T extends ReceivedEpdzMessageDto|EpdzErrorDto> implements IczOnChanges, OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private apiReceivedDigitalConsignmentService = inject(ApiReceivedDigitalConsignmentService);
  private plainComponentVersionViewerDialogService = inject(PlainComponentVersionViewerDialogService);
  private epdzAttachmentViewerService = inject(EpdzAttachmentViewerService);
  private codebook = inject(CodebookService);
  private functionalPositionPipe = inject(FindFunctionalPositionByIdPipe);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private currentSessionService = inject(CurrentSessionService);

  @ViewChild('iczTable')
  iczTable!: TableComponent<ReceivedDigitalMessagesTableColumn>;

  @Input({required: true})
  tableId: string = '';
  @Input({required: true})
  view!: ReceivedDigitalMessagesTableView;
  @Input({required: true})
  dataSource!: IczTableDataSource<T>;
  @Output()
  rowClicked = new EventEmitter<T>();
  @Output()
  selectedRows = new EventEmitter<T[]>();
  @Output()
  pageLoadStarted = new EventEmitter<SearchParams>();

  tableConfig!: TableConfig<ReceivedDigitalMessagesTableColumn>;
  columnsData!: TableColumnsData<ReceivedDigitalMessagesTableColumn>;

  isAttachmentsPopupOpen = false;
  epdzMessageAttachments: ReceivedEpdzAttachmentDto[] = [];
  currentEpdzMessageId: Nullable<number> = null;
  databoxNodeNames: IczOption[] = [];

  readonly getEpdzMessageIcon = getEpdzMessageIcon;

  readonly DigitalComponentVersionSignatureFlagType = DigitalComponentVersionSignatureFlagType;
  readonly ReceivedDigitalMessagesTableColumn = ReceivedDigitalMessagesTableColumn;
  readonly ReceivedDigitalMessagesTableView = ReceivedDigitalMessagesTableView;
  readonly EpdzMessageType = EpdzMessageType;

  rowDisabler = (row: ReceivedEpdzMessageDto) => {
    return !isNil(row.lockedBy) && (row.lockedByFP !== this.currentSessionService.currentUserFunctionalPosition!.id);
  };

  ngOnInit() {
    if (this.view === ReceivedDigitalMessagesTableView.DATABOX_CONSIGNMENTS) {
      this.codebook.databoxNodes().subscribe(nodes => {
        this.databoxNodeNames = nodes.map(n => ({value: n.id, label: n.nodeName}));
      });
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.view && this.view) {
      const viewColumnDefinitions = getColumnDefinitionsByView(this.view);
      this.columnsData = new TableColumnsData<ReceivedDigitalMessagesTableColumn>(COLUMNSETS[this.view].map(
        columnId => viewColumnDefinitions[columnId]
      ));

      const isDataboxConsignmentsView = (this.view === ReceivedDigitalMessagesTableView.DATABOX_CONSIGNMENTS);

      this.tableConfig = extendDefaultTableConfig<ReceivedDigitalMessagesTableColumn>({
        defaultFilterColumns: (
          isDataboxConsignmentsView ?
            [
              ReceivedDigitalMessagesTableColumn.MESSAGE_TYPE,
              ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS,
              ReceivedDigitalMessagesTableColumn.SENDER_LABEL,
              ReceivedDigitalMessagesTableColumn.DATA_BOX_ID,
              ReceivedDigitalMessagesTableColumn.RECEIVED_DATE,
            ] :
            [
              ReceivedDigitalMessagesTableColumn.SUBJECT,
              ReceivedDigitalMessagesTableColumn.SENDER_LABEL,
              ReceivedDigitalMessagesTableColumn.RECEIVED_DATE,
            ]
        ),
        toolbarConfig: {
          autoOpenFilter: isDataboxConsignmentsView
        },
        defaultSort: {
          fieldName: ReceivedDigitalMessagesTableColumn.RECEIVED_DATE,
          descending: false,
        }
      });
    }
  }

  onRowClick(row: T) {
    this.iczTable.setFocusedRow(row);
    this.rowClicked.emit(row);
  }

  openAttachmentsPopup(row: T) {
    if (isEpdzErrorDto(row)) {
      if (row.originalDigitalComponentVersionId) {
        this.plainComponentVersionViewerDialogService.openPlainComponentVersionViewerWithVersionFetch(
          row.originalDigitalComponentVersionId,
        ).subscribe();
      }
    }
    else {
      if (row.haveAttachments) {
        this.loadingIndicatorService.doLoading(
          this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentFindEpdzMessageById({
            messageId: row.id!,
          }),
          this
        ).subscribe(epdzMessageDetail => {
          this.epdzMessageAttachments = (epdzMessageDetail.receivedEpdzAttachmentDtoList ?? []).filter(
            a => a.type === 'BODY' || a.type === 'ATTACHMENT' || a.type === 'MAIN'
          ).sort(
            // message body should always be the first
            (a1, _) => (a1.type === 'BODY' || a1.type === 'MAIN') ? -1 : 1
          );

          this.currentEpdzMessageId = row.id!;
          this.isAttachmentsPopupOpen = true;
        });
      }
    }
  }

  closeAttachmentsPopup() {
    this.isAttachmentsPopupOpen = false;
    this.epdzMessageAttachments = [];
    this.currentEpdzMessageId = null;
  }

  openCurrentMessageEpdzAttachmentAtIndex(epdzMessage: ReceivedEpdzMessageDto, attachmentIndex: number) {
    this.openEpdzMessageAttachmentsViewer(epdzMessage, attachmentIndex);
    this.isAttachmentsPopupOpen = false;
  }

  private openEpdzMessageAttachmentsViewer(epdzMessage: ReceivedEpdzMessageDto, attachmentIndex?: number) {
    this.epdzAttachmentViewerService.openAttachmentViewer({
      message: epdzMessage,
      attachments: this.epdzMessageAttachments,
      initialAttachmentIndex: attachmentIndex,
    });
  }

  getConsignmentTypeTooltip(row: ReceivedEpdzMessageDto) {
    if (!isNil(row.lockedByFP)) {
      return this.functionalPositionPipe.transform(row.lockedByFP).pipe(map(fp => this.translateParser.interpolate(this.translateService.instant('Zpráva je aktuálně zpracovávána {{fp}}.'), {fp})));
    } else {
      return of(`en.epdzMessageType.${row.messageType}`);
    }
  }

}
