/* eslint-disable no-restricted-imports */
import {
  IczFormArray,
  IczFormGroup,
  IczValidatorFn,
  ValidationErrorMessage,
  ValidatorResult
} from '@icz/angular-form-elements';
import {AbstractControl, Validators} from '@angular/forms';
import {
  OrganizationalStructureOption,
  OrganizationalUnitOptionData
} from '../../core/services/organizational-structure.service';
import {AnalogCompositionType, SubjectRecordClassification, SubjectRecordCreateOrUpdateDto} from '|api/commons';
import {SubjectAsSender} from './model/subjects.model';
import {isNil} from 'lodash';

// Source: https://en.wikipedia.org/wiki/Luhn_mod_N_algorithm , adapted to our needs
function generalizedLuhnCheck(nums: number[], modulus: number) {
  if (modulus <= 0 || modulus % 2 !== 0) {
    throw new Error(`Luhn check modulus must be even natural number. Currently it is not - mod=${modulus}.`);
  }
  if (nums.some(n => n < 0 || n >= modulus)) {
    throw new Error(`Luhn check mod ${modulus} input array contains number(s) outside the base: ${nums}.`);
  }

  let factor = 1;
  let sum = 0;

  for (let i = nums.length - 1; i >= 0; i--) {
    let addend = factor * nums[i];

    factor = (factor === 2) ? 1 : 2;

    addend = (Math.floor(addend / modulus)) + (addend % modulus);
    sum += addend;
  }

  const remainder = sum % modulus;
  return (remainder === 0);
}


export class SharedBusinessValidators {

  @ValidationErrorMessage('Nelze vybrat funkční místo, které nikdo nevykonává ani organizační jednotku, která nemá určeného reprezentanta.')
  static isNonEmptyOrgEntity(options: OrganizationalStructureOption[]): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (control.value && options.length) {
        const option = options.find(o => o.value === control.value);
        if ((option?.data as OrganizationalUnitOptionData)?.isEmptyOrgEntity) {
          return {isNonEmptyOrgEntity: false};
        } return null;
      }
      return null;
    };
  }

  @ValidationErrorMessage('Nebyl vybrán platný Odesílatel. Je nutno buďto vyhledat existující subjekt, nebo zadat minimální údaje pro jeho založení včetně adresy.')
  static isValidSenderSubject(): IczValidatorFn {
    const looksLikeSenderDto = (value: SubjectAsSender) => {
      return typeof(value) === 'number' ||
        Object.values(SubjectRecordClassification).includes((value as SubjectRecordCreateOrUpdateDto)!.classification!);
    };

    return (control: AbstractControl): ValidatorResult => {
      if (!control.value || !looksLikeSenderDto(control.value)) {
        return {isValidSenderSubject: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Nebylo přidáno žádné oprávnění.')
  static isPermissionListFilled(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (control.value.length < 1) {
        return {isPerrmisionAdded: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Není nahrán soubor.')
  static isDigitalComponentUploadFormValid(): IczValidatorFn {
    return (control: AbstractControl) => {
      if (isNil((control as IczFormGroup).value.digitalComponentVersionId) && isNil((control as IczFormGroup).value.digitalComponentTemplateId)) {
        return {
          uploadInvalid: false,
        };
      } else {
        return null;
      }
    };
  }

  @ValidationErrorMessage('Datum odeslání nesmí být pozdější než datum doručení')
  static isDispatchDateValid(): IczValidatorFn {
    return (control: AbstractControl) => {
      if (!isNil(control.parent) && !isNil(control.value) ){
        const deliveryDateValue = control.parent!.get('deliveryDate')!.value;
        if (new Date(deliveryDateValue) < new Date(control.value)) {
          return {isDispatchDateValid: false};
        } else {
          return null;
        }
      } else {
        return null;
      }
    };
  }

  @ValidationErrorMessage('Je nutné vybrat právě jeden soubor.')
  static oneFileSelected(): IczValidatorFn {
    return (control: AbstractControl) => {
      if ((control as IczFormArray).length === 1) {
        return null;
      } else {
        return {
          fileSelected: false,
        };
      }
    };
  }

  @ValidationErrorMessage('Musí být nahrána šablona štítku.')
  static isLabelTemplateUploaded(): IczValidatorFn {
    return Validators.required;
  }

  @ValidationErrorMessage('Musí být nahrána výchozí šablona štítku.')
  static isDefaultLabelTemplateSet(): IczValidatorFn {
    return Validators.required;
  }

  @ValidationErrorMessage('Musí být platný formát pro paragraf v zákoně. Příklad: „40a“.')
  static isValidEmpowermentSection(): IczValidatorFn {
    return Validators.pattern(/^(\d+)[a-z]?$/);
  }

  @ValidationErrorMessage('Musí být platný formát pro písmeno v odstavci zákona.')
  static isValidEmpowermentPoint(): IczValidatorFn {
    return Validators.pattern(/^(?!.*ch)[a-z]{1,2}$/);
  }

  // lp: deprecated, but still possible this validator will be needed (with slight modification)
  static analogVolumesFormValidator: IczValidatorFn = (fg: AbstractControl) => {
    if (fg.get('analogCompositionType')!.value === AnalogCompositionType.VOLUMES) return null;
    const paperComponentSheetEnclosureCount = fg.get('paperComponentSheetEnclosureCount')!.value;
    const paperComponentSheetCount = fg.get('paperComponentSheetCount')!.value;
    return paperComponentSheetEnclosureCount !== null && paperComponentSheetCount !== null && paperComponentSheetEnclosureCount <= paperComponentSheetCount
      ? null
      : {analogVolumesFormValidator: {errorMessageTemplate: 'Počet listů všech komponent nesmí být menší než Počet komponent.'}};
  };

  @ValidationErrorMessage('Musí být poštovní směrovací číslo.')
  static postalCode(includeSpace: boolean): IczValidatorFn {
    return includeSpace ? Validators.pattern(/^(\d{3}) *(\d{2})$/) : Validators.pattern(/^(\d{5})$/);
  }

  @ValidationErrorMessage('Zadejte platné orientační číslo ve formátu "číslo/vchod budovy"')
  static orientationNumber(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      const buildingNumericValue = parseInt((control.value?.match(/\d+/))?.[0]);
      if (buildingNumericValue < 1) {
        return {orientationNumber: false};
      }
      return Validators.pattern(/^(\d)+(\/)?([a-z]|[A-Z]?)$/)(control);
    };
  }

  @ValidationErrorMessage('Není platné IČO')
  static isCID(): IczValidatorFn {
    const isIco = (v: Nullable<string>) => {
      let a = 0;

      if (!v || v.length !== 8) return false;

      const b = v.split('');

      let c = 0;

      for (let i = 0; i < 7; i++) {
        a += (parseInt(b[i]) * (8 - i));
      }

      a = a % 11;
      c = 11 - a;
      if (a === 1) c = 0;
      if (a === 0) c = 1;
      if (a === 10) c = 1;
      return parseInt(b[7]) === c;
    };

    return (control: AbstractControl): ValidatorResult => {
      if (control.value && (typeof(control.value) === 'string' && !isIco(control.value))) {
        return {isCID: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Musí končit názvem jednotky {kB, MB}, který není oddělen mezerou. Příklad: „100MB“.')
  static isValidFileSize(): IczValidatorFn {
    return Validators.pattern(/^(\d*\.?\d+)[kM]B$/);
  }

  @ValidationErrorMessage('Musí být platný identifikátor datové schránky.')
  static isValidDataboxIdentifier(): IczValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }
      else {
        // base space symbols are sensitive to reordering. keep them like this pls
        const baseSpaceSymbols = 'abcdefghijkmnpqrstuvwxyz23456789';
        const formatValidatorResult = Validators.pattern(new RegExp(`^[${baseSpaceSymbols}]{7}$`))(control);

        if (formatValidatorResult === null) {
          const characterCodesInBaseSpace = (control.value as string).split('').map(letter => baseSpaceSymbols.indexOf(letter));
          const isLuhnCheckValid = generalizedLuhnCheck(characterCodesInBaseSpace, baseSpaceSymbols.length);

          if (isLuhnCheckValid) {
            return null;
          }
          else {
            return {isValidDataboxIdentifier: false};
          }
        }
        else {
          return {isValidDataboxIdentifier: false};
        }
      }
    };
  }

}
