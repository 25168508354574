<icz-table
  class="h-full"
  [id]="tableId"
  [config]="tableConfig"
  [columnsData]="columnsData"
  [dataSource]="dataSource"
  (rowClicked)="rowClicked.emit($event)"
  [preselectedRows]=""
  (selectedRowsChanged)="selectedRowsChanged.emit($event)"
>
  @if (tableTitleTag) {
    <icz-tag tableTag [label]="tableTitleTag" class="mr-8"></icz-tag>
  }
  <icz-label tableTitle [label]="tableTitle"></icz-label>
  <icz-label notFoundLabel noDataLabel label="Žádné možnosti nenalezeny"></icz-label>
</icz-table>
