import {SubjectAttributeType} from '../model/subject-attribute-type';

export enum SearchOnlyAttribute {
  ADDRESS = 'address',
  DATA_BOX = 'dataBoxId',
  EMAIL = 'email',
}

export enum SubjectIdentifierType {
  CLIENT_ID = SubjectAttributeType.CLIENT_ID,
  DATA_BOX = SearchOnlyAttribute.DATA_BOX,
  DRIVING_LICENCE_ID = SubjectAttributeType.DRIVING_LICENCE_ID,
  IDENTITY_CARD_ID = SubjectAttributeType.IDENTITY_CARD_ID,
  PASSPORT_ID = SubjectAttributeType.PASSPORT_ID,
}
