import {Component, Input} from '@angular/core';
import {IczFormGroup} from '@icz/angular-form-elements';

// todo(rb) delete that enum once BE introduces unified
//  abstraction for all signature/convertion endpoints
export enum PageDesignator {
  FIRST = 'FIRST',
  LAST = 'LAST',
}

@Component({
  selector: 'icz-page-designator-form-fragment',
  templateUrl: './page-designator-form-fragment.component.html',
  styleUrls: ['./page-designator-form-fragment.component.scss'],
})
export class PageDesignatorFormFragmentComponent {
  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName!: string;

  readonly PageDesignator = PageDesignator;
}
