import {Component, EventEmitter, forwardRef, Input, Output, ViewChild} from '@angular/core';
import {PrimitiveControlValueType, PrimitiveValueFormField} from '../common/abstract-form-field';
import {OptionsListComponent} from '../form-autocomplete/options-list/options-list.component';
import {
  FormAutocompleteListTextItemComponent
} from '../form-autocomplete/form-autocomplete-list-text-item/form-autocomplete-list-text-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {IczOption} from '../form-elements.model';
import {IconComponent} from '@icz/angular-essentials';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

/**
 * A simple select-like component. Used as a selector of filter operators.
 * We recommend using this if you plan to implement your own table filters.
 */
@Component({
  selector: 'icz-form-inline-select',
  templateUrl: './form-inline-select.component.html',
  styleUrls: ['./form-inline-select.component.scss'],
  standalone: true,
  imports: [
    FormAutocompleteListTextItemComponent,
    OptionsListComponent,
    IconComponent,
    TranslateModule,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => FormInlineSelectComponent),
  }],
})
export class FormInlineSelectComponent extends PrimitiveValueFormField {

  /**
   * Options to choose from.
   * @param newOptions
   */
  @Input({required: true})
  set options(newOptions: IczOption<any>[]) {
    this._visibleOptions = newOptions.filter(o => !o.isHidden);
  }
  get options(): IczOption<any>[] {
    return this._visibleOptions;
  }
  /**
   * Text visible if no option has been selected.
   */
  @Input()
  override placeholder = 'Vyberte...';
  /**
   * Emits on options list open (TRUE)/close (FALSE).
   */
  @Output()
  openStatusChanged = new EventEmitter<boolean>();

  @Input()
  override set value(newValue: Nullable<PrimitiveControlValueType>) {
    this.selectedOption = this.options.find(o => o.value === newValue) ?? null;
  }
  override get value(): Nullable<PrimitiveControlValueType> {
    return this.selectedOption?.value ?? null;
  }

  protected _visibleOptions: IczOption[] = [];

  protected selectedOption: Nullable<IczOption> = null;
  protected isOpen = false;

  @ViewChild(OptionsListComponent, {static: false})
  protected optionsList: Nullable<OptionsListComponent<any>>;

  protected override _valueChanged() {
    this.valueChange.emit(this.value);
  }

  protected selectionChanged($event: IczOption[]) {
    if ($event.length > 0) {
      this.selectedOption = $event[0];
    }

    this._valueChanged();
    this.close();
  }

  protected toggleOpen() {
    if (!this.fieldDisabled) {
      if (this.isOpen) {
        this.close();
      }
      else {
        this.open();
      }
    }
  }

  private open() {
    this.isOpen = true;
    this.openStatusChanged.emit(true);
  }

  private close() {
    this.isOpen = false;
    this.blur.emit();
    this.openStatusChanged.emit(false);
  }

}
