import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DigitalComponentVersionSignatureFlagType} from '|api/commons';
import {ReceivedEpdzAttachmentDto, ReceivedEpdzMessageDetailDto} from '|api/sad';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczOption, PrimitiveValueFormField} from '@icz/angular-form-elements';
import {epdzToEsslSignatureFlags} from './epdz-attachment.utils';
import {getComponentIconByFilenameSuffix} from '../../../model';
import {getDigitalMessageDefectSummaryTranslationKey} from '../../../enums/digital-message-issue.enum';
import {EpdzAttachmentViewerService} from '../epdz-attachment-viewer/epdz-attachment-viewer.service';
import {IczModalService} from '@icz/angular-modal';
import {
  EpdzContainerContentsDialogComponent,
  EpdzContainerContentsDialogData
} from '../epdz-container-contents-dialog/epdz-container-contents-dialog.component';

@Component({
  selector: 'icz-epdz-attachment-subset-selector',
  templateUrl: './epdz-attachment-subset-selector.component.html',
  styleUrls: ['./epdz-attachment-subset-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EpdzAttachmentSubsetSelectorComponent extends PrimitiveValueFormField<Array<number>> implements IczOnChanges {

  private modalService = inject(IczModalService);
  private translateService = inject(TranslateService);
  private epdzAttachmentViewerService = inject(EpdzAttachmentViewerService);

  @Input({required: true})
  message!: ReceivedEpdzMessageDetailDto;
  @Input()
  isSelectionActive = false;
  @Input()
  disabledEpdzComponentIds: Nullable<number[]>;

  @Input()
  override set value(newValue: Nullable<Array<number>>) {
    this._value = newValue;

    this.setInternalValue();
  }
  override get value(): Nullable<Array<number>> {
    return this._value;
  }

  componentOptions!: IczOption<number>[];

  __value: IczOption<number>[] = [];

  readonly DigitalComponentVersionSignatureFlagType = DigitalComponentVersionSignatureFlagType;

  readonly epdzToEsslSignatureFlags = epdzToEsslSignatureFlags;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if ((changes.message || changes.disabledEpdzComponentIds) && this.message) {
      this.componentOptions = (this.message.receivedEpdzAttachmentDtoList ?? [])
        .filter(c => c.type === 'MAIN' || c.type === 'ATTACHMENT' || c.type === 'ATTACHMENT_CONTAINER' || c.type === 'BODY')
        .map(c => ({
          value: c.id!,
          label: c.name!,
          disabled: (this.disabledEpdzComponentIds ?? []).includes(c.epdzComponentId!),
          data: {
            epdzComponent: c,
          }
        }));

      this.setInternalValue();
    }

    if (changes.isSelectionActive) {
      this.setInternalValue();
    }
  }

  hasNonEmptySignatureFlag(epdzAttachment: ReceivedEpdzAttachmentDto) {
    return epdzAttachment.signatureFlags?.length;
  }

  getAttachmentIcon(epdzAttachment: ReceivedEpdzAttachmentDto) {
    return getComponentIconByFilenameSuffix(epdzAttachment.name!);
  }

  isContainer(epdzAttachment: ReceivedEpdzAttachmentDto) {
    return epdzAttachment.type === 'ATTACHMENT_CONTAINER';
  }

  openEpdzContainerContentsModal(epdzAttachment: ReceivedEpdzAttachmentDto) {
    this.modalService.openComponentInModal<void, EpdzContainerContentsDialogData>({
      component: EpdzContainerContentsDialogComponent,
      modalOptions: {
        width: 1000,
        height: 600,
        titleTemplate: 'Obsah kontejneru {{name}}',
        titleTemplateContext: {
          name: epdzAttachment.name!,
        },
        disableAutoMargin: true,
      },
      data: {
        message: this.message,
        containerEpdzAttachmentId: epdzAttachment.id!,
      }
    });
  }

  _valueChanged($event: number[]) {
    this.valueChange.emit(this._value);
  }

  selectionChanged(selection: IczOption<number>[]) {
    this.__value = selection;
    this._value = selection.map(c => c.value!);

    this.blur.emit();
    this._valueChanged(this._value);
  }

  openAttachmentPreview(epdzAttachment: ReceivedEpdzAttachmentDto) {
    this.epdzAttachmentViewerService.openAttachmentViewer({
      message: this.message!,
      attachments: [
        epdzAttachment,
      ],
    });
  }

  getAttachmentIssuesTooltip(epdzAttachment: ReceivedEpdzAttachmentDto) {
    const applicableDefects = (this.message.defects ?? []).filter(
      d => (d.epdzComponentIds ?? []).includes(epdzAttachment.epdzComponentId!)
    );

    if (applicableDefects.length) {
      let out = '';

      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      for (let i = 0; i < applicableDefects.length; ++i) {
        const defect = applicableDefects[i];
        const issueSummary = getDigitalMessageDefectSummaryTranslationKey(defect.defectType);
        let issueDescription: string;

        if (defect.rejectionReasonAlternativeText) {
          issueDescription = `${defect.rejectionReasonText}<br>---<br>${defect.rejectionReasonAlternativeText}`;
        }
        else {
          issueDescription = `${defect.rejectionReasonText}`;
        }

        out += `<strong>${this.translateService.instant(issueSummary)}</strong><br><br>${issueDescription}`;

        if (i < applicableDefects.length - 1) {
          out += '<br><br>';
        }
      }

      return out;
    }
    else {
      return '';
    }
  }

  attachmentHasIssue(epdzAttachment: ReceivedEpdzAttachmentDto) {
    return this.message.defects?.find(d => d.epdzComponentIds?.includes(epdzAttachment.epdzComponentId!));
  }

  private clearSelection() {
    this.__value = [];
    this._value = [];

    this._valueChanged(this._value);
  }

  private setSelectedComponentsByIds(componentIds: Nullable<number[]>) {
    if (!componentIds?.length) {
      this.clearSelection();
    }
    else {
      this.__value = this.componentOptions.filter(c => componentIds.includes(c.value!));
      this._value = componentIds;
    }
  }

  private setInternalValue() {
    this.setSelectedComponentsByIds(this._value);
  }

}
