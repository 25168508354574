<div [formGroup]="specificFieldsForm" [waiting]="areDeliveryServiceCombinationsLoading || (loadingService.isLoading$(this) | async)">
  <div class="col envelope-properties mx--16 p-16">
    <div class="row grow">
      <icz-delivery-service-combination-selector
        class="mb-2 grow"
        [form]="specificFieldsForm"
        controlName="deliveryServiceCombinationId"
        [deliveryTypeId]="deliveryTypeId"
        (loadingStatusChanged)="areDeliveryServiceCombinationsLoading = $event"
      ></icz-delivery-service-combination-selector>

      <icz-form-autocomplete formControlName="dispatchOfficeDistributionNodeId"
        [label]="isInternalConsignment ? 'Vypravit přes' : 'Listovní uzel'"
        class="grow"
        [options]="sheetNodeOptions"
      ></icz-form-autocomplete>
    </div>


    @if (!isDigitalDeliveryType) {
      <div class="row w-full">
        <icz-section class="grow-1" label="Údaje na obálce">
          <icz-form-autocomplete
            formControlName="envelopeTemplateId"
            label="Šablona obálky"
            [options]="envelopeTemplateOptions"
          ></icz-form-autocomplete>
          <!-- dynamic parameters -->
          @if (envelopeCustomFieldDefs?.length) {
            <icz-envelope-or-label-custom-fields-form
              [form]="$any(specificFieldsForm.get('customText'))"
              [wrapInSection]="false"
              [disabled]="disabled"
              [customFields]="envelopeCustomFieldDefs"
            ></icz-envelope-or-label-custom-fields-form>
          }
          <!-- static parameters -->
          <icz-form-field
            formControlName="salutation"
            label="Oslovení"
          ></icz-form-field>
          @if (consignee?.classification !== SubjectRecordClassification.FO) {
            <icz-form-field
              formControlName="contactPerson"
              label="Kontaktní osoba"
            ></icz-form-field>
            <icz-form-field
              formControlName="consigneeAdditionalName"
              label="Doplňující název subjektu"
            ></icz-form-field>
          }
          <icz-subject-address-selector
            label="Adresa"
            formControlName="consigneeAddress"
            [subject]="consignee"
            [additionalAddresses]="additionalAddresses"
          ></icz-subject-address-selector>
          @if (canAddNewAddress) {
            <a class="icz-body-1" iczLinkWithoutHref (click)="addAddress()">+ {{ 'Přidat adresu' | translate | lowercase }}</a>
          }
        </icz-section>
        <icz-envelope-preview
          class="grow-2"
          [consignment]="consignmentForEnvelopePreview"
          [uid]="consignmentUid"
          [currentEnvelopeTemplate]="selectedEnvelopeTemplate"
          [consignmentType]="isInternalConsignment ? ConsignmentType.OWN_PAPER_INTERNAL : isMultiPaperConsignment ? ConsignmentType.OWN_PAPER_MULTI_ADDRESS : ConsignmentType.OWN_PAPER_ADDRESS"
        ></icz-envelope-preview>
      </div>
    }
  </div>
  @if (showAdditionalInfoSection) {
    <icz-section-expander
      class="pt-16 pb-8"
      [label]="isInternalConsignment? 'Zadat doplňující údaje - evidenční čísla' : 'Zadat doplňující údaje - evidenční čísla a poštovní služby'"
      [(expanded)]="showAdditionalDeliveryInfo"
    ></icz-section-expander>
  }
  @if (showAdditionalDeliveryInfo) {
    <div class="row gap-48">
      <icz-section class="grow" label="Evidenční čísla">
        <icz-form-field
          label="Naše číslo jednací"
          [fieldDisabled]="true"
          [value]="documentRefNumber"
        ></icz-form-field>
        <icz-form-field
          label="Naše spisová značka"
          [fieldDisabled]="true"
          [value]="fileRefNumber"
        ></icz-form-field>
        <icz-form-field
          formControlName="consignorRefNumber"
          label="Vaše číslo jednací"
        ></icz-form-field>
        <icz-form-field
          formControlName="consignorFileRefNumber"
          label="Vaše spisová značka"
        ></icz-form-field>
        <icz-form-field
          formControlName="note"
          label="Poznámka k zásilce"
        ></icz-form-field>
      </icz-section>
      <div class="grow">
        @if (distributionClassForDispatch !== DistributionClass.INTERNAL) {
          <icz-section label="Poštovní služby">
            <div class="row">
              <icz-form-money-input
                class="grow"
                formControlName="codAmount"
                label="Dobírka"
              ></icz-form-money-input>
              <icz-form-money-input
                class="grow"
                formControlName="declaredValue"
                label="Udaná cena"
              ></icz-form-money-input>
            </div>
            <icz-consignment-posting-number [form]="specificFieldsForm" class="mb-16"></icz-consignment-posting-number>
            <div class="row">
              <icz-form-field
                class="grow"
                type="integer"
                formControlName="weight"
                label="Hmotnost"
                >
                <span suffix>g</span>
              </icz-form-field>
              <icz-form-money-input
                class="grow"
                formControlName="payoutAmount"
                label="Výplatné"
              ></icz-form-money-input>
            </div>
          </icz-section>
        }
      </div>
    </div>
  }
  @if (isPersonalConsignment) {
    <div class="row">
      <div class="grow">
        <icz-section label="Poznámka">
          <icz-form-field
            formControlName="note"
            label="Poznámka k zásilce"
            [autoSizeMax]="5"
          ></icz-form-field>
        </icz-section>
      </div>
      <div class="grow">
      </div>
    </div>
  }
  @if (distributionClassForDispatch === DistributionClass.INTERNAL && isDigitalDeliveryType) {
    <div class="row">
      <div class="grow">
        <icz-section label="Způsob vypravení">
          <icz-form-field
            formControlName="consigneeAdditionalName"
            label="Doplňující název subjektu"
          ></icz-form-field>
          <icz-subject-address-selector
            label="Adresa"
            formControlName="consigneeAddress"
            [subject]="consignee"
            [additionalAddresses]="additionalAddresses"
          ></icz-subject-address-selector>
        </icz-section>
        <icz-section class="grow" label="Evidenční čísla">
          <icz-form-field
            label="Naše číslo jednací"
            [fieldDisabled]="true"
            [value]="documentRefNumber"
          ></icz-form-field>
          <icz-form-field
            label="Naše spisová značka"
            [fieldDisabled]="true"
            [value]=""
          ></icz-form-field>
          <icz-form-field
            formControlName="consignorRefNumber"
            label="Vaše číslo jednací"
          ></icz-form-field>
          <icz-form-field
            formControlName="consignorFileRefNumber"
            label="Vaše spisová značka"
          ></icz-form-field>
          <icz-form-field
            formControlName="note"
            label="Poznámka k zásilce"
            [autoSizeMax]="5"
          ></icz-form-field>
        </icz-section>
      </div>
      <div class="grow">
        <icz-section label="Zmocnění">
          <icz-empowerment [fieldDisabled]="true" [value]="documentData?.empowerment"></icz-empowerment>
        </icz-section>
      </div>
    </div>
  }
</div>
