import {ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {ButtonComponent, ButtonSize} from '../button/button.component';
import {TooltipDirective} from '../tooltip.directive';

/**
 * A simple shortcut button which on click copies its @Input text contents to system clipboard.
 * This button also includes icons and state transitions related to successful copy feedback.
 */
@Component({
  selector: 'icz-copy-to-clipboard-button',
  templateUrl: './copy-to-clipboard-button.component.html',
  styleUrls: ['./copy-to-clipboard-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, TooltipDirective],
})
export class CopyToClipboardButtonComponent {

  private clipboard = inject(Clipboard);
  private cd = inject(ChangeDetectorRef);

  /**
   * Text to copy to clipboard.
   */
  @Input({ required: true })
  text!: string;
  /**
   * Button size.
   */
  @Input()
  size: ButtonSize = 'default';

  protected copied = false;

  protected copyUrlToClipboard($event: Event) {
    $event.stopPropagation();
    this.clipboard.copy(this.text);
    this.copied = true;

    setTimeout(() => {
      this.copied = false;
      this.cd.detectChanges();
    }, 5000);
  }

}
