<icz-section label="Údaje o doručení" [waiting]="loadingIndicatorService.isLoading$(this) | async">
  @if (ownConsignment?.proofOfDelivery?.documentId || ownConsignment?.proofOfDelivery?.digitalComponentVersionId) {
    <div>
      <div class="row">
        <icz-label label="Doklad o doručení" class="light"></icz-label>
      </div>
      @if (ownConsignment?.proofOfDelivery?.digitalComponentVersionId) {
        <div class="row my-8">
          <a class="icz-body-1 visible-clickable-area row" iczLinkWithoutHref (click)="openProofOfDeliveryComponentPreview(ownConsignment!.proofOfDelivery!)">
            <icz-icon [svgIcon]="digitalComponentVersionFileIcon"
              iczTooltip="{{ownConsignment!.proofOfDelivery!.digitalComponentVersionFileName}}"
            ></icz-icon>
            <icz-label label="{{ownConsignment!.proofOfDelivery!.digitalComponentVersionFileName}}"></icz-label>
          </a>
          <a class="icz-body-1 visible-clickable-area row pl-4" iczLinkWithoutHref (click)="download(ownConsignment!.proofOfDelivery!)">
            <icz-label label="Stáhnout" class="primary"></icz-label>
          </a>
        </div>
      }
      @if (ownConsignment?.proofOfDelivery?.documentId) {
        @if (proofOfDeliveryDocument) {
          <div class="row mt-8">
            <a class="icz-body-1 visible-clickable-area row" iczLinkWithoutHref (click)="openProofOfDeliveryDocument(ownConsignment!.proofOfDelivery!)">
              <icz-object-class-icon
                [objectClass]="proofOfDeliveryDocument.objectClass"
                iczTooltip="{{proofOfDeliveryDocument.subject}}"
                iconSize="default"
              ></icz-object-class-icon>
              <icz-label label="{{proofOfDeliveryDocument.subject}}"></icz-label>
            </a>
          </div>
        }
        @if (hasProofOfDeliveryDocumentInsufficientPermissions) {
          <icz-alert
            heading="K dokumentu s dokladem o doručení nemáte potřebná oprávnění."
            severity="warning"
            class="mt-8"
          ></icz-alert>
        }
      }
    </div>
  }
  @if (!isOfficeDeskConsignment) {
    <div class="row">
      <icz-readonly-field class="grow" [value]="ownConsignment?.proofOfDelivery?.acknowledgementDate | localizedDate" label="Datum doručení"></icz-readonly-field>
      <icz-readonly-field class="grow" [value]="deliveryResultName" label="Výsledek doručení"></icz-readonly-field>
    </div>
  }
  <div class="row">
    <icz-readonly-field class="grow" [value]="ownConsignment?.proofOfDelivery?.note" label="Poznámka k doručení"></icz-readonly-field>
  </div>
</icz-section>
