/** @internal */
type DigitValidator = (char: string) => boolean;

/** @internal */
const numericValidator = (char: string) => /\d/.test(char);
/** @internal */
const decimalPointValidator = (char: string) => /[,.]/.test(char);
/** @internal */
const lowerCaseValidator = (char: string) => /[a-z]{1}/.test(char);
/** @internal */
const upperCaseValidator = (char: string) => /[A-Z]{1}/.test(char);
/** @internal */
const anyValidator = (char: string) => true;
/** @internal */
const numberRangeValidator = (maxValue: number, char: string) =>
  numericValidator(char) && parseInt(char, 10) <= maxValue;
/** @internal */
export const neverValidator = (char: string) => false;

/** @internal */
export const maskDigitValidators: {[key: string]: DigitValidator} = {
  'a': lowerCaseValidator,
  'A': upperCaseValidator,
  '.': decimalPointValidator,
  '*': anyValidator,
};

for (let i = 0; i <= 9; i++) {
  maskDigitValidators[i] = numberRangeValidator.bind(undefined, i);
}
