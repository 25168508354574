import {inject, Injectable} from '@angular/core';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {isEmpty} from 'lodash';
import {EMPTY, forkJoin, map, Observable, of, switchMap} from 'rxjs';
import {ApiAuthorizationService, AuthorizedEntityType} from '|api/core';
import {
  GeneralAuthorizationResult,
  GeneralAuthorizedOperation,
  getAuthorizedOperationsByEntityTypes,
  getInsufficientPermissionsTooltip
} from './permissions/permissions.utils';
import {Button} from '../button-collection/button-collection.component';
import {enumValuesToArray} from '../../core/services/data-mapping.utils';
import {ValidationResultWithTooltip} from '../../services/bulk-operation-validation.service';

type AuthorizableButtonWithDisablers = {
  authorizedOperations?: GeneralAuthorizedOperation[];
};

export type AuthorizedButton = Omit<Button, 'submenuItems'|'action'> & AuthorizableButtonWithDisablers & {
  submenuItems?: AuthorizedButton[];
  action?: (thisButton: AuthorizedButton) => any;
};

@Injectable({
  providedIn: 'root'
})
export class AuthorizedButtonService {

  private translateParser = inject(TranslateParser);
  private translateService = inject(TranslateService);
  private apiAuthorizationService = inject(ApiAuthorizationService);

  readonly AUTHORIZED_ENTITY_TYPES = enumValuesToArray(AuthorizedEntityType) as AuthorizedEntityType[];

  // eslint-disable-next-line @typescript-eslint/ban-types -- I want a generic type here on purpose
  readonly AUTHORIZATION_METHODS: Record<AuthorizedEntityType, Nullable<Function>> = {
    [AuthorizedEntityType.DOCUMENT]: this.apiAuthorizationService.authorizationAuthorizeDocumentOperations.bind(this.apiAuthorizationService),
    [AuthorizedEntityType.FILE]: this.apiAuthorizationService.authorizationAuthorizeFileOperations.bind(this.apiAuthorizationService),
    [AuthorizedEntityType.OWN_CONSIGNMENT]: this.apiAuthorizationService.authorizationAuthorizeOwnConsignmentOperations.bind(this.apiAuthorizationService),
    [AuthorizedEntityType.RECEIVED_CONSIGNMENT]: this.apiAuthorizationService.authorizationAuthorizeReceivedConsignmentOperations.bind(this.apiAuthorizationService),
    [AuthorizedEntityType.COMPONENT]: this.apiAuthorizationService.authorizationAuthorizeComponentOperations.bind(this.apiAuthorizationService),
    [AuthorizedEntityType.STORAGE_UNIT]: this.apiAuthorizationService.authorizationAuthorizeStorageUnitOperations.bind(this.apiAuthorizationService),
    [AuthorizedEntityType.SHARED_FOLDER]: this.apiAuthorizationService.authorizationAuthorizeSharedFolderOperations.bind(this.apiAuthorizationService),
    [AuthorizedEntityType.RECEIVED_INTERNAL_MESSAGE]: null,
    [AuthorizedEntityType.AGENDA_TRANSFER]: null,
  };

  fetchBulkAuthorizedButtonPermissions(
    authorizedEntityIds: Partial<Record<AuthorizedEntityType, number[]>>,
    authorizedButtons$: Observable<AuthorizedButton[]>,
  ): Observable<Button[]> {
    return authorizedButtons$.pipe(
      switchMap(authorizedButtons => {
        const authorizedOperationsByEntityType = this.getAuthorizedOperationsFromButtonCollection(authorizedButtons);
        const authorizedOperationsToRequest: any[] = [];

        (Object.keys(authorizedEntityIds) as AuthorizedEntityType[]).forEach((entityType: AuthorizedEntityType) => {
          authorizedEntityIds[entityType]?.forEach(entityId => {
            if (authorizedOperationsByEntityType[entityType]?.length) {
              authorizedOperationsToRequest.push({
                authorizedEntityId: entityId,
                authorizedEntityType: entityType,
                operationsToAuthorize: authorizedOperationsByEntityType[entityType]
              });
            }
          });
        });

        if (authorizedOperationsToRequest.length === 0) {
          return of(this.evaluateButtonDefinition(authorizedButtons));
        } else {
          return this.apiAuthorizationService.authorizationAuthorizeOperations({
            body: authorizedOperationsToRequest
          }).pipe(
            map((authorizationResultsByEntity: GeneralAuthorizationResult[]) => {
              const authorizationByOperation = new Map<GeneralAuthorizedOperation, boolean>();

              authorizationResultsByEntity.forEach(result => {
                result.authorizedOperations.forEach(operation => {
                  if (operation.userAuthorized) {
                    if (!authorizationByOperation.has(operation.operation)) {
                      authorizationByOperation.set(operation.operation, operation.userAuthorized);
                    }
                  } else {
                    authorizationByOperation.set(operation.operation, operation.userAuthorized);
                  }
                });
              });

              return this.evaluateButtonDefinitionByOperation(
                authorizedButtons,
                authorizationByOperation
              );
            }),
          );
        }
      })
    );
  }

  private getAuthorizedOperationsFromButtonCollection(authorizedButtons: AuthorizedButton[]) {
    const authorizedOperationsToFetch: GeneralAuthorizedOperation[] = [];
    this.findAuthorizedButtonOperations(authorizedButtons, authorizedOperationsToFetch);

    return getAuthorizedOperationsByEntityTypes(authorizedOperationsToFetch);
  }

  fetchAuthorizedButtonPermissions(
    authorizedEntityIds: Partial<Record<AuthorizedEntityType, Nullable<number>>>,
    authorizedButtons$: Observable<AuthorizedButton[]>,
  ): Observable<Button[]> {
    return authorizedButtons$.pipe(
      switchMap(authorizedButtons => {
        const authorizedOperationsByEntityType = this.getAuthorizedOperationsFromButtonCollection(authorizedButtons);
        const authorizationRequest$: Partial<Record<AuthorizedEntityType, Observable<GeneralAuthorizationResult>>> = {};

        for (const authorizedEntityType of this.AUTHORIZED_ENTITY_TYPES) {
          this.addEntityAuthorizationOperationsToRequest(
            authorizedOperationsByEntityType,
            authorizedEntityIds,
            authorizedEntityType,
            authorizationRequest$,
          );
        }

        if (isEmpty(authorizationRequest$)) {
          return of(this.evaluateButtonDefinition(authorizedButtons));
        } else {
          return forkJoin(authorizationRequest$).pipe(
            map((authorizationResultsByEntityType: Partial<Record<AuthorizedEntityType, GeneralAuthorizationResult>>) => {
              const authorizationResultContexts = Object.keys(authorizationResultsByEntityType) as AuthorizedEntityType[];

              let out = authorizedButtons;

              for (const context of authorizationResultContexts) {
                out = this.evaluateButtonDefinition(
                  out,
                  authorizationResultsByEntityType[context],
                  context
                );
              }

              return out;
            }),
          );
        }
      }),
    );
  }

  evaluateButtonDefinitionByOperation(
    buttonDefinition: AuthorizedButton[],
    authorizationResult: Map<GeneralAuthorizedOperation, boolean>
  ): Button[] {
    buttonDefinition.forEach(button => {
      if (isNil(button.show) || button.show) {
        let shouldDisable = false;
        const disableTooltips: ValidationResultWithTooltip[] = [];
        if (button.buttonDisablers) {
          for (const disabler of button.buttonDisablers) {
            const disablerResult = disabler();

            if (disablerResult) {
              shouldDisable = true;
              disableTooltips.push(disablerResult);

              if (disabler.isGuard) {
                break;
              }
            }
          }
        }

        if (button.authorizedOperations && button.authorizedOperations.length) {
          let isButtonActionAuthorized = true;
          button.authorizedOperations.forEach(operation => {
            const opResult = authorizationResult.get(operation);
            if (!isNil(opResult)) {
              isButtonActionAuthorized = isButtonActionAuthorized && opResult;
            }
          });
          if (!isButtonActionAuthorized) {
            shouldDisable = true;
            disableTooltips.push({
              validationMessage: this.translateService.instant('Vyběr obsahuje entitu pro kterou nemáte práva k provedení akce'),
            });
          }
        }

        button.disable = button.disable || shouldDisable;

        if (button.disable && disableTooltips.length) {
          button.disableTooltip = this.constructAuthorizedButtonTooltip(
            disableTooltips
          );
        }

        if (button.submenuItems && button.submenuItems.length) {
          this.evaluateButtonDefinitionByOperation(
            button.submenuItems,
            authorizationResult
          );
        }
      }
    });

    return buttonDefinition;
  }

  evaluateButtonDefinition(
    buttonDefinition: AuthorizedButton[],
    authorizationResult?: GeneralAuthorizationResult,
    evaluationContext?: AuthorizedEntityType,
  ): Button[] {
    buttonDefinition.forEach(button => {
      if (isNil(button.show) || button.show) {
        let shouldDisable = false;
        const disableTooltips: ValidationResultWithTooltip[] = [];
        if (button.buttonDisablers) {
          for (const disabler of button.buttonDisablers) {
            const disablerResult = disabler();

            if (disablerResult) {
              shouldDisable = true;
              disableTooltips.push(disablerResult);

              if (disabler.isGuard) {
                break;
              }
            }
          }
        }

        if (button.authorizedOperations?.length) {
          for (const authorizedOperation of button.authorizedOperations) {
            if (
              !evaluationContext ||
              (evaluationContext && (authorizedOperation ?? '').startsWith(evaluationContext))
            ) {
              if (authorizedOperation && authorizationResult) {
                const insufficientPermissionTooltip = getInsufficientPermissionsTooltip(authorizationResult, authorizedOperation, this.translateService);

                if (insufficientPermissionTooltip) {
                  shouldDisable = true; // if there is an insufficientPermissionTooltip it means that authorized operation is not allowed
                  if (insufficientPermissionTooltip.tooltip) {
                    insufficientPermissionTooltip.tooltip = insufficientPermissionTooltip.tooltip;
                    disableTooltips.push(insufficientPermissionTooltip);
                  }
                }
              }
            }
          }
        }

        button.disable = button.disable || shouldDisable;

        if (button.disable && disableTooltips.length) {
          button.disableTooltip = this.constructAuthorizedButtonTooltip(
            disableTooltips
          );
        }

        if (button.submenuItems && button.submenuItems.length) {
          this.evaluateButtonDefinition(
            button.submenuItems,
            authorizationResult,
            evaluationContext
          );
        }
      }
    });

    return buttonDefinition;
  }

  private findAuthorizedButtonOperations(buttonDefinition: AuthorizedButton[], operations: GeneralAuthorizedOperation[]) {
    buttonDefinition.forEach(button => {
      if ((isNil(button.show) || button.show)) {
        if (button.authorizedOperations) {
          button.authorizedOperations.forEach(authOp => {
            if (operations.every(op => op !== authOp)) operations.push(authOp);
          });
        }
      }
      if (button.submenuItems && button.submenuItems.length) {
        this.findAuthorizedButtonOperations(button.submenuItems, operations);
      }
    });
  }

  private constructAuthorizedButtonTooltip(
    disableTooltips?: ValidationResultWithTooltip[]
  ): Nullable<string> {

    let disableTooltipParts: string[] = [];

    if (disableTooltips) {
      disableTooltipParts = disableTooltips.filter(
        t => t.validationMessage || t.tooltip
      ).map(t =>
        // todo(mh) temporary keeping t.tooltip because not all disablers are refactored to use operation validators
        this.translateParser.interpolate(this.translateService.instant(t.tooltip ? t.tooltip : t.validationMessage!), t.validationMessageContext)!
      );
    }

    let disableTooltip = '';

    if (disableTooltipParts.length) {
      const disableTooltipsHeading = this.translateService.instant('Chyby bránící provedení akce');
      disableTooltip = `${disableTooltipsHeading}:<br>${disableTooltipParts.join('<br>')}`;
    }

    if (disableTooltip) {
      return disableTooltip;
    } else {
      return null;
    }
  }

  private addEntityAuthorizationOperationsToRequest(
    authorizedOperationsByEntityType: Record<AuthorizedEntityType, GeneralAuthorizedOperation[]>,
    authorizedEntityIds: Partial<Record<AuthorizedEntityType, Nullable<number>>>,
    authorizedEntityType: AuthorizedEntityType,
    authorizationRequest$: Partial<Record<AuthorizedEntityType, Observable<Nullable<GeneralAuthorizationResult>>>>,
  ) {
    if (authorizedOperationsByEntityType[authorizedEntityType].length) {
      if (!authorizedEntityIds[authorizedEntityType]) {
        authorizationRequest$[authorizedEntityType] = of(null);
      }
      else {
        const authorizationMethod = this.AUTHORIZATION_METHODS[authorizedEntityType];

        if (authorizationMethod) {
          authorizationRequest$[authorizedEntityType] = authorizationMethod({
            body: {
              authorizedEntityType,
              authorizedEntityId: authorizedEntityIds[authorizedEntityType],
              operationsToAuthorize: authorizedOperationsByEntityType[authorizedEntityType],
            }
          });
        }
        else {
          authorizationRequest$[authorizedEntityType] =  EMPTY;
        }
      }
    }
  }

}
