import {NgModule} from '@angular/core';
import {EmpowermentComponent} from './empowerment/empowerment.component';
import {FormElementsModule} from '@icz/angular-form-elements';
import {EssentialsModule} from '@icz/angular-essentials';
import {TranslateModule} from '@ngx-translate/core';
import {ReactiveFormsModule} from '@angular/forms';
import {IczFormDirectivesModule} from '../icz-form-directives/icz-form-directives.module';
import {EmpowermentValueAccessorDirective} from './empowerment.value-accessor';

@NgModule({
  declarations: [
    EmpowermentComponent,
    EmpowermentValueAccessorDirective,
  ],
  imports: [
    ReactiveFormsModule,
    TranslateModule,
    EssentialsModule,
    FormElementsModule,
    IczFormDirectivesModule,
  ],
  exports: [
    EmpowermentComponent,
    EmpowermentValueAccessorDirective,
  ]
})
export class EmpowermentModule {}
