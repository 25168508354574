<div class="context-menu-wrapper col" [style.height.px]="contextMenuContentHeight">
  <div class="justify-between row context-menu-header">
    <icz-label label="Které sloupce chcete zobrazit?"></icz-label>
    <icz-button svgIcon="close" size="small" background="transparent" disableRipple
      (onAction)="this.onClose.emit()">
    </icz-button>
  </div>

  <div class="p-24 context-menu-content grow">
    @for (column of columnDefinitionsForContextMenu; track column.id) {
      <icz-checkbox
        [checked]="column.displayed!"
        [label]="column.label || ''"
        [fieldDisabled]="!isToggleableColumn(column)"
        class="mb-8"
        (changed)="columnToggled.emit(column)">
      </icz-checkbox>
    }
  </div>

  <div class="row context-menu-footer">
    <icz-button (onAction)="defaultClicked.emit()" label="Vrátit sloupce a řazení do původního nastavení" size="large"></icz-button>
  </div>
</div>
