import {inject, Injectable, Injector} from '@angular/core';
import {combineLatest, forkJoin, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {
  FilterItemTree,
  FilterTreeOperator,
  isFilterTree,
  isSimpleQueryFilterTree,
  StringifiedTree
} from './filter-trees.utils';
import {TranslateService} from '@ngx-translate/core';
import {FilterItem} from './filter.types';
import {removeDuplicates} from '@icz/angular-essentials';
import {DEFAULT_FILTERS, FILTER_PLUGINS} from './table.providers';


/**
 * Plugin-based service used for generating predefined filter name from
 * user-specified fulltext filtering query and structured filter query.
 */
@Injectable()
export class FilterNameService {

  private defaultFilters = inject(DEFAULT_FILTERS);
  private filterPlugins = inject(FILTER_PLUGINS, {optional: true});
  private translateService = inject(TranslateService);
  private injector = inject(Injector);

  private combinedFilterPlugins = {
    ...this.defaultFilters,
    ...(this.filterPlugins ?? {}),
  };
  private formattersInjector: Injector;

  constructor() {
    const formattersDependencySymbols = Object.values(this.combinedFilterPlugins)
      .flatMap(pluginDef => pluginDef?.filterValueFormatterDeps ?? []);
    const formattersInjectableSymbols = Object.values(this.combinedFilterPlugins)
      .map(pluginDef => pluginDef?.filterValueFormatter)
      .filter(Boolean);

    this.formattersInjector = Injector.create({
      parent: this.injector,
      providers: removeDuplicates([
        ...formattersDependencySymbols,
        ...formattersInjectableSymbols,
      ]),
    });
  }

  /**
   * Constructs user friendly description of user-specified filter.
   */
  getFilterNameObs(
    activeFilters$: Observable<FilterItemTree>,
    searchTerm$: Observable<Nullable<string>>,
    withFormatting: boolean,
  ): Observable<Nullable<string>> {
    return combineLatest([
      activeFilters$,
      searchTerm$,
    ]).pipe(
      switchMap(([activeFilters, searchTerm]) => {
        return combineLatest([
          of(searchTerm),
          this.filterItemTreeToStringifiedTree(activeFilters, withFormatting)
        ]);
      }),
      map(([searchTerm, stringifiedTree]) => {
        return [
          searchTerm,
          this.stringifiedTreeToHumanReadableExpression(stringifiedTree, withFormatting, this.translateService),
        ];
      }),
      map(([searchTerm, humanReadableExpression]) => {
        if (searchTerm) {
          const formattedSearchTermDescription = `${this.translateService.instant('Hledat')}: ${searchTerm}`;

          if (humanReadableExpression) {
            return `${formattedSearchTermDescription}, ${humanReadableExpression}`;
          }
          else {
            return formattedSearchTermDescription;
          }
        }
        else {
          return humanReadableExpression;
        }
      }),
    );
  }

  /**
   * Formats filter item value in user-friendly way.
   */
  getFilterItemValue(item: FilterItem): Observable<string> {
    if (!item?.value) {
      return of(this.translateService.instant('není nastaveno'));
    }

    const associatedFormatter = this.combinedFilterPlugins[item?.filterType]?.filterValueFormatter;

    if (associatedFormatter) {
      return this.formattersInjector.get(associatedFormatter)!.format(item);
    }
    else {
      return of(item.value as string);
    }
  }

  private stringifiedTreeToHumanReadableExpression(
    stringifiedTree: StringifiedTree,
    withTextFormatting: boolean,
    translateService: TranslateService,
  ) {
    if (isSimpleQueryFilterTree(stringifiedTree)) {
      return stringifiedTree.values.join(', ');
    }
    else {
      const translatedOperator = translateService.instant(
        stringifiedTree.operator === FilterTreeOperator.AND ?
          'A ZÁROVEŇ' :
          'NEBO'
      );

      const formattedOperator = withTextFormatting ?
        ` <span class="icz-body-strong">${translatedOperator}</span> ` :
        ` ${translatedOperator} `;

      const stringifiedParts: string[] = stringifiedTree.values.map(treeValue => {
        if (isFilterTree(treeValue)) {
          return this.stringifiedTreeToHumanReadableExpression(treeValue, withTextFormatting, translateService);
        }
        else {
          return treeValue;
        }
      });

      return `( ${stringifiedParts.join(formattedOperator)} )`;
    }
  }

  private filterItemTreeToStringifiedTree(filterItemTree: FilterItemTree, withTextFormatting: boolean): Observable<StringifiedTree> {
    if (!filterItemTree.values.length) {
      return of({
        operator: filterItemTree.operator,
        values: [],
      });
    }
    else {
      if (isSimpleQueryFilterTree(filterItemTree)) {
        return forkJoin(
          (filterItemTree.values as FilterItem[]).map(f => this.filterItemValueToString(f, withTextFormatting))
        ).pipe(
          map(resolvedTreeValues => ({
            operator: filterItemTree.operator,
            values: resolvedTreeValues,
          })),
        );
      }
      else {
        return forkJoin(
          filterItemTree.values.map(v => {
            if (isFilterTree(v)) {
              return this.filterItemTreeToStringifiedTree(v, withTextFormatting);
            }
            else {
              return this.filterItemValueToString(v, withTextFormatting);
            }
          })
        ).pipe(
          map(resolvedTreeValues => ({
            operator: filterItemTree.operator,
            values: resolvedTreeValues,
          }))
        );
      }
    }
  }

  private filterItemValueToString(item: FilterItem, withTextFormatting: boolean) {
    return this.getFilterItemValue(item).pipe(map(
      filterValue => {
        const columnName = this.translateService.instant(item.columnLabel ?? item.label);
        const operatorName = item.value ? `${this.translateService.instant(item.filterOption!.label).toLowerCase()} ` : '';

        if (Array.isArray(item.value) && isNil(item.list)) {
          if (withTextFormatting) {
            filterValue = '<span class="list-loading-dark inline-block w-64 align-sub"></span>';
          }
          else {
            filterValue = '...';
          }
        }

        if (withTextFormatting) {
          return `${columnName} <i>${operatorName}</i>${filterValue}`;
        }
        else {
          return `${columnName} ${operatorName}${filterValue}`;
        }
      }
    ));
  }

}
