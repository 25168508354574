import {Component, inject, Input, OnInit} from '@angular/core';
import {EsslComponentsTableDatasource} from '../../essl-components-table/essl-components-table.datasource';
import {EsslComponentsTableColumnSet} from '../../essl-components-table/essl-components-table.component';
import {EsslComponentSearchService} from 'libs/shared/src/lib/services';
import {DigitalComponentDto} from '|api/component';
import {FilterOperator} from '@icz/angular-table';

@Component({
  selector: 'icz-container-contents-table',
  templateUrl: './container-contents-table.component.html',
  styleUrls: ['./container-contents-table.component.scss'],
})
export class ContainerContentsTableComponent implements OnInit {

  private searchService = inject(EsslComponentSearchService);

  @Input({ required: true })
  digitalComponent!: DigitalComponentDto;
  @Input({ required: true })
  digitalComponentVersionId!: number;
  @Input({ required: true })
  documentId!: number;

  dataSource!: EsslComponentsTableDatasource;

  readonly EsslComponentsTableColumnSet = EsslComponentsTableColumnSet;

  ngOnInit() {
    if (this.digitalComponent) {
      this.dataSource = new EsslComponentsTableDatasource(
        this.searchService,
        this.documentId,
        [
          {
            fieldName: 'parentContainerDigitalComponentVersionId',
            operator: FilterOperator.inSet,
            value: String([
              this.digitalComponentVersionId,
              ...(this.digitalComponent.descendantContainerComponentIds ?? []).map(c => c.digitalComponentVersionId),
            ]),
          },
        ]
      );
    }
  }

}
