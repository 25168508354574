import {Observable} from 'rxjs';

/**
 * Necessary abstract class for avoiding cyclic dependency between shared and other modules,
 * dependency direction being reversed by providing services implementing this shared abstract
 */
export abstract class AbstractFileSubjectRelationDialogsManagerService {
  abstract openAddRelationToFileDialog(): Observable<Nullable<boolean>>;
  abstract openAddRelationToFileConsignmentDialog(): Observable<Nullable<boolean>>;
  abstract openRemoveRelationFromFileDialog(): Observable<Nullable<boolean>>;
  abstract openAddAllRelatedSubjectsToFileDialog(count: number): Observable<Nullable<boolean>>;
  abstract openRemoveRelationsFromFileNotOnFileContentDialog(count: number): Observable<Nullable<boolean>>;
}
