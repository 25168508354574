import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {IczFormArray, IczFormControl, IczFormGroup, IczOption} from '@icz/angular-form-elements';
import {
  ReceiveConsignmentSetSenderComponent,
} from '../receive-consignment-set-sender/receive-consignment-set-sender.component';
import {DistributionClassDto} from '|api/codebook';
import {combineLatest, Observable, of, Subject} from 'rxjs';
import {anonymousSenderSet, SubjectAsSender} from '../model/subjects.model';
import {AddressCompleteDto} from '../model/addresses.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {map, startWith} from 'rxjs/operators';
import {ReceivedDocumentUsage} from '../received-consignments.model';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {TranslateService} from '@ngx-translate/core';
import {ReceivedPaperConsignmentDto} from '|api/sad';
import {CodebookService} from '../../../core/services/codebook.service';
import {DistributionClass} from '|api/commons';
import {ApiDocumentService} from '|api/document';
import {NoOfficialContentDialogComponent} from '../no-official-content-dialog/no-official-content-dialog.component';
import {IczModalService} from '@icz/angular-modal';
import {DocumentToastService, DocumentToastType} from '../../../core/services/notifications/document-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {Router} from '@angular/router';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {isUnopenedDocument} from '../shared-document.utils';

@Component({
  selector: 'icz-receive-or-register-received-consignment',
  templateUrl: './receive-or-register-received-consignment.component.html',
  styleUrls: ['./receive-or-register-received-consignment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceiveOrRegisterReceivedConsignmentComponent implements OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected translateService = inject(TranslateService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  protected codebookService = inject(CodebookService);
  protected iczModalService = inject(IczModalService);
  protected apiDocumentService = inject(ApiDocumentService);
  protected documentToastService = inject(DocumentToastService);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);

  @Input({required: true}) form!: IczFormGroup;
  @Input({required: true}) distributionNodeOptions: Nullable<IczOption[]>;
  @Input({required: true}) receivedDocumentUsage!: ReceivedDocumentUsage;
  @Input() enableEntityClassPrefill = true;
  @Input() title: Nullable<string>;
  @Input() resetSearchForm: Nullable<EventEmitter<void>>;
  @Input() resetDigitalComponents: Nullable<EventEmitter<void>>;
  @Input() unopenedConsignment: Nullable<ReceivedPaperConsignmentDto>;
  @Output() distributionClassDtoChanged = new EventEmitter<DistributionClassDto>();
  @Output() blockingAction = new EventEmitter<boolean>();

  @ViewChild(ReceiveConsignmentSetSenderComponent)
  subjectSetter!: ReceiveConsignmentSetSenderComponent;

  readonly ReceivedDocumentUsage = ReceivedDocumentUsage;
  wasSenderRemoved = false;
  receivedDocumentExpandedIndex = 0;
  distributionClassDto: Nullable<DistributionClassDto>;

  canAddComponentsByOpenedState$: Observable<boolean> = of(true);
  canAddDigitalComponents$: Observable<boolean> = of(false);
  canAddDigitalComponentsByDocumentForm$ = new Subject<boolean>();

  get manyDocumentsFormGroupName(): string {
    if (this.isFilingOffice) {
      return 'receivedDocumentCreateDtoListWithHandover';
    } else if (this.isOfficer) {
      return 'receivedDocumentCreateDtoListNoHandover';
    } else return 'receivedDocumentCreateDtoListNoHandover';
  }

  get manyDocumentsControl(): IczFormArray {
    if (this.isFilingOffice) {
      return this.documentsListWithHandoverControl;
    } else if (this.isOfficer) {
      return this.documentsListNoHandoverControl;
    } else return this.documentsListNoHandoverControl;
  }

  get disableAssignRegNr() {
    return this.form.get('toOwnHands')!.value && this.receivedDocumentUsage !== ReceivedDocumentUsage.REGISTER_CONTENT_OFFICER &&
      this.receivedDocumentUsage !== ReceivedDocumentUsage.REGISTER_CONTENT_FILING_OFFICE;
  }

  get isConsignmentToOwnHands() {
    return Boolean(this.unopenedConsignment?.toOwnHands);
  }

  get isUnopenedDocument() {
    return isUnopenedDocument(this.unopenedConsignment?.receivedDocuments![0]);
  }

  get documentsListWithHandoverControl(): IczFormArray {
    return this.form?.get('receivedDocumentCreateDtoListWithHandover') as IczFormArray;
  }

  get documentsListNoHandoverControl(): IczFormArray {
    return this.form?.get('receivedDocumentCreateDtoListNoHandover') as IczFormArray;
  }

  get firstDocumentWithHandoverForm(): IczFormGroup {
    return this.documentsListWithHandoverControl?.controls[0] as IczFormGroup;
  }

  get firstDocumentNoHandoverForm(): IczFormGroup {
    return this.documentsListNoHandoverControl?.controls[0] as IczFormGroup;
  }

  get firstDocumentForDigitalComponents(): IczFormGroup {
    if (this.isCreateOfficerFullDocument) return this.firstDocumentNoHandoverForm;
    else if (this.isCreateFilingOfficePersonal) return this.firstReceivedDocumentBaseDtoForm;
    else return this.firstDocumentNoHandoverForm;
  }

  get firstReceivedDocumentBaseDtoForm(): IczFormGroup {
    return this.firstDocumentWithHandoverForm!.get('receivedDocumentWithComponentsCreateBaseDto') as IczFormGroup;
  }

  get senderDefinitionControl(): IczFormControl {
    return this.form?.get('senderDefinition') as IczFormControl;
  }

  get unopenedControl(): IczFormControl {
    return this.form?.get('unopened') as IczFormControl;
  }

  get toOwnHandsControl(): IczFormControl {
    return this.form?.get('toOwnHands') as IczFormControl;
  }

  get isCreateUnopened(): boolean {
    return this.unopenedControl!.value === true;
  }

  get isCreateOfficerFullDocument() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_OFFICER_FULL_DOCUMENT;
  }

  get isCreateFilingOfficePaper() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_PAPER_CONSIGNMENT;
  }

  get isCreateFilingOfficePersonal() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_PERSONAL_CONSIGNMENT;
  }

  get isRegisterContentFilingOfficePaper() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_FILING_OFFICE && this.isDistributionClassPostOffice;
  }

  get isRegisterContentFilingOfficePersonal() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_FILING_OFFICE && this.isDistributionClassPersonal;
  }

  get isRegisterContentOfficerPaper() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_OFFICER && this.isDistributionClassPostOffice;
  }

  get isRegisterContentOfficerPersonal() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_OFFICER && this.isDistributionClassPersonal;
  }

  get isFilingOffice() {
    return this.isCreateFilingOfficePaper || this.isCreateFilingOfficePersonal ||
      this.isRegisterContentFilingOfficePaper || this.isRegisterContentFilingOfficePersonal;
  }

  get isOfficer() {
    return this.isRegisterContentOfficerPaper || this.isRegisterContentOfficerPersonal ||
      this.isCreateOfficerFullDocument;
  }

  get isCreateAnyConsignment() {
    return this.isCreateOfficerFullDocument || this.isFilingOfficeCreate;
  }

  get isRegisterAnyContent() {
    return this.isRegisterContentFilingOfficePaper || this.isRegisterContentOfficerPersonal ||
      this.isRegisterContentFilingOfficePersonal || this.isRegisterContentOfficerPaper;
  }

  get isFilingOfficeCreate() {
    return this.isCreateFilingOfficePaper || this.isCreateFilingOfficePersonal;
  }

  get isSingleDocumentConsignment() {
    return this.isRegisterContentFilingOfficePersonal || this.isRegisterContentOfficerPersonal ||
      this.isCreateFilingOfficePersonal || this.isCreateOfficerFullDocument;
  }

  get isDistributionClassPostOffice() {
    return this.distributionClassDto?.distributionClassValue === DistributionClass.ADDRESS_PLACE;
  }

  get isDistributionClassPersonal() {
    return this.distributionClassDto?.distributionClassValue === DistributionClass.ON_PLACE;
  }

  get documentListControlByUsage(): IczFormArray {
    if (this.isRegisterContentOfficerPaper || this.isRegisterContentOfficerPersonal || this.isCreateOfficerFullDocument) {
      return this.documentsListNoHandoverControl;
    } else if (this.isRegisterContentFilingOfficePersonal || this.isRegisterContentFilingOfficePaper || this.isFilingOfficeCreate) {
      return this.documentsListWithHandoverControl;
    }
    else return this.documentsListNoHandoverControl;
  }

  get pageTitle(): string {
    if (this.title) {
      return this.title;
    } else {
      let title = '';
      if (this.isCreateFilingOfficePaper) {
        title = 'Přijetí listinné zásilky';
      } else if (this.isCreateFilingOfficePersonal) {
        title = 'Osobní podání';
      } else if (this.isCreateOfficerFullDocument) {
        title = 'Založení doručeného dokumentu';
      } else if (this.isRegisterAnyContent) {
        title = 'Zaevidování obsahu';
      }
      return title;
    }
  }

  distributionClassDtoChange(dist: DistributionClassDto) {
    this.distributionClassDto = dist;
    this.distributionClassDtoChanged.emit(dist);
  }

  addReceivedDocument() {
    this.documentListControlByUsage.incrementSize();
    this.receivedDocumentExpandedIndex = this.documentsListWithHandoverControl.length - 1;
  }

  removeReceivedDocument(index: number) {
    const minIndex = 0;
    if (index >= minIndex) this.documentListControlByUsage.removeAt(index);
  }

  setSender(subject: SubjectAsSender) {
    this.form.get('senderDefinition')?.setValue(subject);
  }

  setSenderAddress(address: Nullable<{value: AddressCompleteDto, valid: boolean}>) {
    this.form.get('senderAddress')?.setValue(address?.value);
    if (address?.valid) {
      this.form.get('senderAddressValid')!.setValue(address?.valid);
    } else {
      this.form.get('senderAddressValid')!.setValue(null);
    }
  }

  anonymousSenderSet(anonymousSender: boolean) {
    return anonymousSenderSet(anonymousSender, this.form);
  }

  documentSectionLabel(i: number) {
    return `${this.translateService.instant('Dokument')} ${i + 1}`;
  }

  onNoOfficialContentClick() {
    const document = this.unopenedConsignment!.receivedDocuments![0]!;
    this.iczModalService
      .openComponentInModal({
        component: NoOfficialContentDialogComponent,
        modalOptions: {
          width: 800,
          height: 250,
          titleTemplate: 'Dokument nemá úřední obsah',

        },
        data: document,
      })
      .subscribe(result => {
        if (result) {
          this.loadingIndicatorService.doLoading(
            this.apiDocumentService.documentDeactivateDocument({id: document.id, body: {reason: ''}}), this).subscribe(_ => {
            this.router.navigateByUrl(createAbsoluteRoute(
              ApplicationRoute.DOCUMENTS,
              DocumentsRoute.IN_PROGRESS,
            ));
            this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.DOCUMENT_DEACTIVATED, {
              [InternalNotificationKey.DOCUMENT_ID]: document.id,
              [InternalNotificationKey.DOCUMENT_SUBJECT]: document.subject,
            });
          });
        }
      });
  }

  private handleUnopenedConsignment() {
    const unopenedValueChanges = this.unopenedControl!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), startWith(this.unopenedControl!.value));
    const toOwnHandsValueChanges = this.toOwnHandsControl!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef), startWith(this.toOwnHandsControl!.value));

    toOwnHandsValueChanges.subscribe(toOwnHands => {
      if (toOwnHands) {
        this.unopenedControl!.setValue(true);
        this.unopenedControl!.disable();
      } else {
        this.unopenedControl!.enable();
      }
    });

    this.canAddComponentsByOpenedState$ = combineLatest([
      unopenedValueChanges,
      toOwnHandsValueChanges,
    ]).pipe(map(([unopened, toOwnHands]) => !unopened && !toOwnHands));

    // this truncates documents list to a single document if unopened/toOwnHands consignment
    this.canAddComponentsByOpenedState$.subscribe(canAddAnalogComponentsByOpenedState => {
      if (!canAddAnalogComponentsByOpenedState) {
        this.manyDocumentsControl.setSize(1);
      }
    });
    this.canAddDigitalComponents$ = combineLatest([
      this.canAddComponentsByOpenedState$,
      this.canAddDigitalComponentsByDocumentForm$
    ]).pipe(takeUntilDestroyed(this.destroyRef),
      (map(([byOpenedState, byDocumentForm]) => byOpenedState && byDocumentForm)));

    this.canAddDigitalComponents$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(canAdd => {
      if (canAdd) {
        (this.firstDocumentForDigitalComponents?.get('digitalComponents') as IczFormArray)?.controls.forEach(formGroup => {
          (formGroup as IczFormGroup).resetValidatorsToInitial();
        });
      }
      else {
	      (this.firstDocumentForDigitalComponents?.get('digitalComponents') as IczFormArray)?.controls.forEach(formGroup => {
		      Object.values((formGroup as IczFormGroup).controls).forEach(control => {
			      (control as IczFormControl).clearValidators();
		      });
	      });
      }
    });
  }

  ngOnInit() {
    if (this.unopenedConsignment) {
      this.codebookService.deliveryTypes().subscribe(dt => {
        const consignmentDeliveryType = dt.filter(deliveryType => deliveryType.id === this.unopenedConsignment!.deliveryTypeId)[0];
        this.distributionClassDto = consignmentDeliveryType.distributionClass;
      });
    }
    this.resetSearchForm?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.subjectSetter?.resetSearchForm();
      this.subjectSetter?.removeAsSender();
      this.subjectSetter?.resetAnonymousSenderForm();
      this.form.get('senderAddressValid')?.setValue(true);
    });
    this.resetDigitalComponents?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      (this.firstDocumentForDigitalComponents?.get('digitalComponents') as IczFormArray)?.setSize(0);
    });

    this.handleUnopenedConsignment();
  }

}
