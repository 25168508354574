<ng-container [formGroup]="form">
  @if (wrapInSection) {
    <icz-section [label]="sectionLabel">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </icz-section>
  }
  @else {
    <ng-container *ngTemplateOutlet="content"></ng-container>
  }

  <ng-template #content>
    <div class="row gap-16">
      <icz-date-picker class="grow" [formControlName]="validFromControlName" label="Platnost od"
                       [selectableDates]="selectableValidFromDates" [outputFormat]="dateOutputFormat">
        @if (validFromHelpTooltip) {
          <icz-icon suffix size="small" svgIcon="info" [iczTooltip]="validFromHelpTooltip"></icz-icon>
        }
      </icz-date-picker>
      <icz-date-picker class="grow" [formControlName]="validToControlName" label="Platnost do"
                       [selectableDates]="selectableValidToDates" [outputFormat]="dateOutputFormat">
        @if (validToHelpTooltip) {
          <icz-icon suffix size="small" svgIcon="info" [iczTooltip]="validToHelpTooltip"></icz-icon>
        }
      </icz-date-picker>
    </div>
  </ng-template>

  <ng-content select="[moreContent]"></ng-content>
</ng-container>
