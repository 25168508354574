import {ChangeDetectionStrategy, Component, EventEmitter, inject, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {AVAILABLE_LANGUAGES} from '../../../core/localization/loader';
import {
  EnvironmentIconColorScheme,
  LocalStorageKey,
  UserSettingsService
} from '../../../services/user-settings.service';
import {ENVIRONMENT} from '../../../core/services/environment.service';
import {ApplicationLanguage, EnvironmentType} from '../../../core/services/environment.models';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {locateOptionByValue, IczOption} from '@icz/angular-form-elements';


@Component({
  selector: 'icz-settings-popup',
  templateUrl: './settings-popup.component.html',
  styleUrls: ['./settings-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettingsPopupComponent implements OnInit {

  private router = inject(Router);
  private translateService = inject(TranslateService);
  protected userSettingsService = inject(UserSettingsService);
  private applicationConfigService = inject(ApplicationConfigService);
  private environment = inject(ENVIRONMENT);

  @Output()
  onClose = new EventEmitter<void>();

  languageOptions = AVAILABLE_LANGUAGES;
  currentLanguageOption = this.languageOptions[0];
  currentColorSchemeOption!: IczOption<EnvironmentIconColorScheme>;
  languagesPopupOpen = false;
  colorSchemePopupOpen = false;

  environmentOptions: IczOption[] = [];
  currentEnvironment = '';

  environmentPopupOpen = false;

  environamentColorOptions: IczOption<EnvironmentIconColorScheme>[] = (enumToOptions('environmentIconColorScheme', EnvironmentIconColorScheme) as IczOption<EnvironmentIconColorScheme>[]);

  get currentEnvironmentOption() {
    return locateOptionByValue(this.environmentOptions, this.currentEnvironment)!;
  }

  ngOnInit() {
    const currentLanguage = this.languageOptions.find(o => o.value === this.translateService.currentLang);
    const curentIconColorScheme = this.userSettingsService.getRawValue(LocalStorageKey.USER_ICON_COLOR_SCHEME);

    if (currentLanguage) {
      this.currentLanguageOption = currentLanguage;
    }

    if (curentIconColorScheme) {
      this.currentColorSchemeOption = this.environamentColorOptions.find(o => o.value === curentIconColorScheme as EnvironmentIconColorScheme)!;
    } else {
      this.currentColorSchemeOption = this.environamentColorOptions.find(o => o.value === this.applicationConfigService.defaultIconColorScheme as EnvironmentIconColorScheme)!;
    }

    const environmentOptions = this.environment.availableEnvironments.map(availableEnvironment => ({
      value: availableEnvironment.host,
      label: availableEnvironment.name,
      icon: this.getEnvironmentIconByType(availableEnvironment.type),
    }));

    if (location.host.includes('localhost')) {
      const localEnvironmentHostUrl = `http://${location.host}`;

      environmentOptions.unshift({
        value: localEnvironmentHostUrl,
        label: `${this.translateService.instant('Lokální')} (${this.translateService.instant('proti')} ${this.environment.environmentName})`,
        icon: this.getEnvironmentIconByType(EnvironmentType.TESTING),
      });
      this.currentEnvironment = localEnvironmentHostUrl;
    }
    else {
      this.currentEnvironment = this.environment.hostUrl;
    }

    this.environmentOptions = environmentOptions;
  }

  openEnvironmentsPopup() {
    this.environmentPopupOpen = true;
  }

  environmentsPopupClosed() {
    this.environmentPopupOpen = false;
  }

  openLanguagesPopup() {
    this.languagesPopupOpen = true;
  }

  languagesPopupClosed() {
    this.languagesPopupOpen = false;
  }

  openColorSchemePopup() {
    this.colorSchemePopupOpen = true;
  }

  colorSchemePopupClosed() {
    this.colorSchemePopupOpen = false;
  }

  setShowTableLabels(val: boolean) {
    this.userSettingsService.setShowTableLabels(val);
  }

  setDisableInfoToasts(val: boolean) {
    this.userSettingsService.setDisableInfoToasts(val);
  }

  onIconColorSchemeChange(colorScheme : Array<IczOption<EnvironmentIconColorScheme>>) {
    this.userSettingsService.setEnvironmentIconColorScheme(colorScheme[0].value);
    this.onClose.emit();
  }

  languageChanged(selectedLangs: Array<IczOption<ApplicationLanguage>>) {
    const lang = selectedLangs[0].value as string;
    this.currentLanguageOption = selectedLangs[0];

    this.userSettingsService.setRawValue(LocalStorageKey.USER_LANGUAGE, lang);
    this.translateService.use(lang);
    this.languagesPopupClosed();
    this.onClose.emit();
  }

  environmentChanged(selectedEnvs: Array<IczOption>) {
    window.open(selectedEnvs[0].value as string, '_blank')!.focus();
  }

  routeToAdmin() {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.ADMIN));
  }

  routeToConfig() {
    this.router.navigateByUrl(createAbsoluteRoute(ApplicationRoute.CONFIG, 'organizations'));
  }

  private getEnvironmentIconByType(type: EnvironmentType) {
    switch (type) {
      case EnvironmentType.PRODUCTION:
        return 'environment_prod';
      case EnvironmentType.TESTING:
      case EnvironmentType.DEVELOPMENT:
        return 'environment_test';
      case EnvironmentType.TRAINING:
        return 'environment_edu';
    }
  }

}
