import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AbstractObjectSelectorComponent} from '../file-selector/abstract-object-selector.component';
import {DocumentDto, FileDto} from '|api/document';
import {
  FILE_OBJECT_CLASSES,
  OWN_DOCUMENT_OBJECT_CLASSES,
  RECEIVED_DOCUMENT_OBJECT_CLASSES
} from '../shared-document.utils';
import {SearchParams} from '@icz/angular-table';
import {WITHOUT_REF_NUMBER} from '../shared-business-components.model';

@Component({
  selector: 'icz-document-or-file-selector',
  templateUrl: './document-or-file-selector.component.html',
  styleUrls: ['./document-or-file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentOrFileSelectorComponent extends AbstractObjectSelectorComponent<DocumentDto|FileDto> {

  readonly objectClasses = [...OWN_DOCUMENT_OBJECT_CLASSES, ...RECEIVED_DOCUMENT_OBJECT_CLASSES, ...FILE_OBJECT_CLASSES];
  readonly recentlyVisitedObjectsHeading = 'Naposledy navštívené objekty:';
  protected additionalFilteringCriteriaFactory = () => [];
  readonly withoutRefNumberText = WITHOUT_REF_NUMBER;

  protected findObjects(searchParams: SearchParams) {
    return this.searchService.findDocumentsGlobally(searchParams);
  }

}
