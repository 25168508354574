import {DestroyRef, inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiStorageUnitService, StorageUnitDto} from '|api/document';
import {ToolbarProvider} from '../components/shared-business-components/table-toolbar-buttons.utils';
import {StorageUnitView} from '../components/shared-business-components/document-toolbar/services/toolbar-common.utils';
import {Button} from '../components/button-collection/button-collection.component';
import {AbstractStorageUnitDialogsManagerService} from './abstract-storage-unit-dialogs-manager.service';
import {
  AuthorizedButton,
  AuthorizedButtonService
} from '../components/shared-business-components/authorized-button.service';
import {
  CommonToolbarDisablers
} from '../components/shared-business-components/document-toolbar/services/toolbar-common.disablers';
import {AuthorizedEntityType, StorageUnitAuthorizedOperation} from '|api/core';
import {filter, map} from 'rxjs/operators';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {BulkOperationValidationService} from './bulk-operation-validation.service';
import {
  StorageUnitToolbarValidators
} from '../components/shared-business-components/document-toolbar/services/storage-unit-toolbar.validators';
import {StorageUnitToastService, StorageUnitToastType} from '../core/services/notifications/storage-unit-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {Router} from '@angular/router';
import {createAbsoluteRoute} from '../core/routing/routing.helpers';
import {ApplicationRoute} from '../enums/shared-routes.enum';
import {DocumentsRoute, StorageUnitDetailRoute} from '../enums/documents-routes.enum';
import {InternalNotificationMessageCode} from '../core/services/notifications/internal-notification.enum';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CounterTypeGroup, MainMenuCountsService} from '../core/services/main-menu-counts.service';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../components/notifications/web-socket-notifications.service';
import {
  StorageUnitToolbarDisablers
} from '../components/shared-business-components/document-toolbar/services/storage-unit-toolbar.disablers';
import {StorageUnitDetailService} from './storage-unit-detail.service';
import {ObjectClass} from '|api/commons';
import {getObjectIcon, getStorageUnitLabel} from '../components/shared-business-components/shared-document.utils';
import {GeneralAuthorizedOperation} from '../components/shared-business-components/permissions/permissions.utils';
import {
  TransferableObjectToolbarAction
} from '../components/shared-business-components/document-toolbar/services/transferable-object-toolbar-buttons.service';
import {
  RegistryOfficeTransferDisablers
} from '../components/shared-business-components/document-toolbar/services/registry-office-transfer.disablers';
import {
  RegistryOfficeTransferValidators
} from '../components/shared-business-components/document-toolbar/services/registry-office-transfer.validators';
import {DialogService} from '@icz/angular-modal';

export enum StorageUnitToolbarAction {
  STORAGE_UNIT_CREATE = 'STORAGE_UNIT_CREATE',
  STORAGE_UNIT_ENTITIES_ADD = 'STORAGE_UNIT_ENTITIES_ADD',
  STORAGE_UNIT_BLOCK = 'STORAGE_UNIT_BLOCK',
  STORAGE_UNIT_UNBLOCK = 'STORAGE_UNIT_UNBLOCK',
  STORAGE_UNIT_DELETE = 'STORAGE_UNIT_DELETE',
}

function isNotificationOfCurrentlyOpenedStorageUnit(storageUnitDetailService: Nullable<StorageUnitDetailService>): (n: WebSocketNotification) => boolean {
  if (storageUnitDetailService) {
    return webSocketNotification => !isNil(webSocketNotification.parameters.find(
      p => p[InternalNotificationKey.STORAGE_UNIT_ID] === String(storageUnitDetailService.objectId)
    ));
  }
  else {
    return () => true;
  }
}

@Injectable()
export class StorageUnitToolbarButtonsService extends ToolbarProvider<StorageUnitDto, StorageUnitToolbarAction | TransferableObjectToolbarAction, StorageUnitView> {

  private storageUnitDialogService = inject(AbstractStorageUnitDialogsManagerService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private globalLoading = inject(GlobalLoadingIndicatorService);
  private storageUnitService = inject(ApiStorageUnitService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private storageUnitToastService = inject(StorageUnitToastService);
  private dialogService = inject(DialogService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private destroyRef = inject(DestroyRef);
  private router = inject(Router);
  private storageUnitDetailService = inject(StorageUnitDetailService, {optional: true});

  constructor() {
    super();

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_INSERT_TO_STORAGE_UNIT_SUCCESS).pipe(
      filter(isNotificationOfCurrentlyOpenedStorageUnit(this.storageUnitDetailService)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
      this.announceActionCompleted(StorageUnitToolbarAction.STORAGE_UNIT_ENTITIES_ADD);
    });

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_STORAGE_UNIT_DELETE_SUCCESS).pipe(
      filter(isNotificationOfCurrentlyOpenedStorageUnit(this.storageUnitDetailService)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
      this.announceActionCompleted(StorageUnitToolbarAction.STORAGE_UNIT_DELETE);
    });

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_STORAGE_UNIT_LOCK_SUCCESS).pipe(
      filter(isNotificationOfCurrentlyOpenedStorageUnit(this.storageUnitDetailService)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.announceActionCompleted(StorageUnitToolbarAction.STORAGE_UNIT_BLOCK);
    });

    this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.BULK_STORAGE_UNIT_UNLOCK_SUCCESS).pipe(
      filter(isNotificationOfCurrentlyOpenedStorageUnit(this.storageUnitDetailService)),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.announceActionCompleted(StorageUnitToolbarAction.STORAGE_UNIT_UNBLOCK);
    });
  }

  getToolbarButtons(selection: StorageUnitDto[], view?: StorageUnitView): Observable<Button[]> {
    const buttons: AuthorizedButton[] = [
      {
        label: 'Nová ukládací jednotka',
        icon: 'add_new',
        show: view === StorageUnitView.STORAGE_UNITS_TABLE,
        action: _ => this.onCreateStorageUnitClick()
      },
      {
        label: 'Úpravy',
        icon: 'edit',
        submenuItems: [
          {
            label: 'Vložit objekty',
            authorizedOperations: [StorageUnitAuthorizedOperation.STORAGE_UNIT_ENTITY_ADD],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              StorageUnitToolbarDisablers.isStorageUnitBlocked(selection),
              RegistryOfficeTransferDisablers.isEntityInRegistryOffice(selection),
            ],
            action: () => this.onAddEntitiesClick(selection)
          },
          {
            label: 'Smazat',
            authorizedOperations: [StorageUnitAuthorizedOperation.STORAGE_UNIT_DELETE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              RegistryOfficeTransferDisablers.isEntityInRegistryOffice(selection),
            ],
            action: button =>
              this.onStorageUnitActionClick(
                selection,
                button.authorizedOperations!,
                [
                  RegistryOfficeTransferValidators.isEntityInRegistryOffice(),
                ],
                StorageUnitToolbarAction.STORAGE_UNIT_DELETE
              )
          },
          {
            label: 'Zablokovat',
            authorizedOperations: [StorageUnitAuthorizedOperation.STORAGE_UNIT_BLOCK_CONTENT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              StorageUnitToolbarDisablers.isStorageUnitBlocked(selection),
              RegistryOfficeTransferDisablers.isEntityInRegistryOffice(selection),
            ],
            action: button =>
              this.onStorageUnitActionClick(
                selection,
                button.authorizedOperations!,
                [
                  StorageUnitToolbarValidators.isStorageUnitBlocked(),
                  RegistryOfficeTransferValidators.isEntityInRegistryOffice(),
                ],
                StorageUnitToolbarAction.STORAGE_UNIT_BLOCK
              )
          },
          {
            label: 'Odblokovat',
            authorizedOperations: [StorageUnitAuthorizedOperation.STORAGE_UNIT_UNBLOCK_CONTENT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              StorageUnitToolbarDisablers.isStorageUnitNotBlocked(selection),
              RegistryOfficeTransferDisablers.isEntityInRegistryOffice(selection),
            ],
            action: button =>
              this.onStorageUnitActionClick(
                selection,
                button.authorizedOperations!,
                [
                  StorageUnitToolbarValidators.isStorageUnitNotBlocked(),
                  RegistryOfficeTransferValidators.isEntityInRegistryOffice(),
                ],
                StorageUnitToolbarAction.STORAGE_UNIT_UNBLOCK
              )
          },
        ]
      }
    ];

    if (selection.length === 1) {
      return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
        {
          [AuthorizedEntityType.STORAGE_UNIT]: selection[0]?.id,
        },
        of(buttons)
      );
    } else {
      return of(buttons).pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  private onCreateStorageUnitClick() {
    this.storageUnitDialogService.openStorageUnitCreateDialog().subscribe( result => {
      if (result) {
        const storageUnit = result.storageUnit;

        this.storageUnitToastService.dispatchStorageUnitCreated({
          [InternalNotificationKey.ID]: storageUnit.id,
          [InternalNotificationKey.STORAGE_UNIT_NUMBER]: storageUnit.storageUnitNumber,
        });

        if (result.redirectToPermissionSettings) {
          this.router.navigateByUrl(createAbsoluteRoute(
            ApplicationRoute.DOCUMENTS,
            DocumentsRoute.STORAGE_UNIT,
            storageUnit.id,
            StorageUnitDetailRoute.SHARE,
          ));
        }
        else {
          this.router.navigateByUrl(createAbsoluteRoute(
            ApplicationRoute.DOCUMENTS,
            DocumentsRoute.STORAGE_UNIT,
            storageUnit.id,
          ));
        }

        this.announceActionCompleted(StorageUnitToolbarAction.STORAGE_UNIT_CREATE);
      }
    });
  }

  private storageUnitToValidationObject(storageUnit: StorageUnitDto) {
    return {
      entityId: storageUnit.id!,
      authorizedEntityType: AuthorizedEntityType.STORAGE_UNIT,
      entityIcon: getObjectIcon(storageUnit.objectClass as unknown as ObjectClass)!,
      entityData: storageUnit,
      entityName: getStorageUnitLabel(storageUnit),
    };
  }

  private onStorageUnitActionClick(selection: StorageUnitDto[], authorizedOperations: GeneralAuthorizedOperation[], validators: any[], actionType: StorageUnitToolbarAction) {
    this.bulkOperationValidationService.validateEsslObjects<StorageUnitDto>(
      {
        dialogWarningLabel: 'Operaci nelze vykonat pro některé ukládací jednotky ({{errorCount}}). Hromadná akce bude provedena jen s vyhovujícími ukládacími jednotkami ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: validators,
        esslObjects: selection.map(su => this.storageUnitToValidationObject(su)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects) {
        if (actionType === StorageUnitToolbarAction.STORAGE_UNIT_BLOCK) {
          this.onBlockContentClick(validatedObjects);
        }
        else if (actionType === StorageUnitToolbarAction.STORAGE_UNIT_UNBLOCK) {
          this.onUnblockContentClick(validatedObjects);
        }
        else if (actionType === StorageUnitToolbarAction.STORAGE_UNIT_DELETE) {
          this.onDeleteUnitClick(validatedObjects);
        }
      }
    });
  }

  private onAddEntitiesClick(selection: StorageUnitDto[]) {
    this.storageUnitDialogService.openStorageUnitInsertDialog(selection[0]).subscribe(result => {
      if (result) {
        this.storageUnitToastService.dispatchSimpleWarningToast(StorageUnitToastType.STORAGE_UNIT_INSERT_STARTED);
      }
    });
  }

  private onDeleteUnitClick(selection: StorageUnitDto[]) {
    this.dialogService.openQuestionDialog(
      {
        title: 'Smazání ukládací jednotky',
        question: (selection.length === 1) ? 'Opravdu chcete smazat tuto ukládací jednotku?' : 'Opravdu chcete smazat ukládací jednotky ({{COUNT}})?',
        questionContext: {
          COUNT: selection.length
        },
        description: 'Tuto operaci nelze vrátit.',
        leftButtonTitle: 'Smazat',
      }
    ).subscribe(() => {
      this.globalLoading.doLoading(
        this.storageUnitService.storageUnitBulkDelete({
          body: selection.map(su => su.id)
        })
      ).subscribe(_ => {
        this.storageUnitToastService.dispatchSimpleWarningToast(StorageUnitToastType.STORAGE_UNIT_DELETE_STARTED);
      });
    });
  }

  private onBlockContentClick(selection: StorageUnitDto[]) {
    this.dialogService.openQuestionDialog(
      {
        title: 'Zablokování ukládací jednotky',
        question: (selection.length === 1) ? 'Opravdu chcete zablokovat tuto ukládací jednotku?' : 'Opravdu chcete zablokovat ukládací jednotky ({{COUNT}})?',
        questionContext: {
          COUNT: selection.length
        },
        description: '',
        leftButtonTitle: 'Zablokovat',
      }
    ).subscribe(() => {
      this.globalLoading.doLoading(
        this.storageUnitService.storageUnitBulkBlock({
          body: selection.map(su => su.id)
        })
      ).subscribe(_ => {
        this.storageUnitToastService.dispatchSimpleWarningToast(StorageUnitToastType.STORAGE_UNIT_BLOCK_STARTED);
      });
    });
  }

  private onUnblockContentClick(selection: StorageUnitDto[]) {
    this.dialogService.openQuestionDialog(
      {
        title: 'Odblokování ukládací jednotky',
        question: (selection.length === 1) ? 'Opravdu chcete odblokovat tuto ukládací jednotku?' : 'Opravdu chcete odblokovat ukládací jednotky ({{COUNT}})?',
        questionContext: {
          COUNT: selection.length
        },
        description: '',
        leftButtonTitle: 'Odblokovat',
      }
    ).subscribe(() => {
      this.globalLoading.doLoading(
        this.storageUnitService.storageUnitBulkUnblock({
          body: selection.map(su => su.id)
        })
      ).subscribe(_ => {
        this.storageUnitToastService.dispatchSimpleWarningToast(StorageUnitToastType.STORAGE_UNIT_UNBLOCK_STARTED);
      });
    });

  }
}
