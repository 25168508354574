<div [waiting]="this.loadingIndicatorService.isLoading$(this) | async" class="h-full">
  @if (consignments.length) {
    @if (isBulkAction) {
      <icz-tabs [tabs]="tabs"
                [activeTab]="activeTab"
                [shouldNavigate]="false"
                (tabClick)="tabChanged($event)"
                class="h-full"
      >
        <ng-template [iczTab]="GENERAL_TAB">
          @if (defaultConsignment) {
            <icz-prepare-or-dispatch-form [formData]="generalTabFormData" [consignment]="defaultConsignment" [isDefaultConsignment]="true"
                                          [waiting]="loadingIndicatorService.isLoading(this)"
                                          (formValueChanged)=generalTabValueChange($event)>
            </icz-prepare-or-dispatch-form>
          }
        </ng-template>
        @for (consignment of modalData.consignments; track consignment.id) {
          <ng-template [iczTab]="consignment.id!.toString()">
            @if (defaultConsignment) {
              <icz-prepare-or-dispatch-form [formData]="formData" [consignment]="consignments[$index]" [isDefaultConsignment]="false"
                                            (formValueChanged)="specificConsignmentValueChange($event, $index)">
              </icz-prepare-or-dispatch-form>
            }
          </ng-template>
        }
      </icz-tabs>
    }
    @else {
      <icz-prepare-or-dispatch-form [formData]="formData" [consignment]="consignments[0]" class="px-24 py-16" [isDefaultConsignment]="false"
                                    (formValueChanged)="specificConsignmentValueChange($event, 0)">
      </icz-prepare-or-dispatch-form>
    }
  }
</div>

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit [disabled]="loadingIndicatorService.isLoading(this)"
  (onAction)="submit()" primary [label]="prepareOrDispatchDialogConfig?.[modalData.type]!.confirmLabel"></icz-button>
  <icz-button rightButtons [disabled]="loadingIndicatorService.isLoading(this)"
  (onAction)="close()" label="Storno"></icz-button>
</icz-form-buttons>
