import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonComponent, ChipComponent} from '@icz/angular-essentials';

/**
 * @internal
 */
@Component({
  selector: 'icz-form-autocomplete-chip',
  templateUrl: './form-autocomplete-chip.component.html',
  styleUrls: ['./form-autocomplete-chip.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
    ChipComponent,
  ]
})
export class FormAutocompleteChipComponent {
  @Input()
  removable = true;
  @Input()
  isMultiline = false;
  @Output()
  removeClicked = new EventEmitter<void>();

  protected removeButtonClicked($event: Event) {
    $event.stopPropagation();
    this.removeClicked.emit();
  }
}
