<form class="grow" [formGroup]="form">
  <div class="gap-64 row">
    <div class="col grow">
      <icz-section label="Dokument">
        <icz-form-field [iczAutoFocus]="'deffered'" formControlName="subject" label="Věc" [autoSizeMax]="3"></icz-form-field>
        <icz-form-field formControlName="description" label="Popis" [autoSizeMax]="6"></icz-form-field>
        <icz-form-field formControlName="note" label="Poznámka"></icz-form-field>
        <icz-form-field formControlName="relatedEvidence" label="Související evidence"></icz-form-field>
        @if (applicationConfigService.ownDocumentExternalIdEnabled) {
          <icz-form-field formControlName="externalId" label="Identifikátor dokumentu"></icz-form-field>
        }
        <div class="row">
          <icz-form-field formControlName="label" label="Značka" class="grow"></icz-form-field>
          <icz-form-autocomplete formControlName="documentForm" label="Forma" class="grow"
          [options]="documentFormOptions"></icz-form-autocomplete>
        </div>
      </icz-section>
      <icz-section label="Zatřídění">
        <icz-document-type-selector
          [form]="form"
          controlName="documentTypeId"
          [showPopupDetails]="true"
        ></icz-document-type-selector>
        @if (withEntityClassSelection) {
          <icz-entity-class-selector
            [form]="form"
            controlName="entityClassId"
            [showPopupDetails]="true"
          ></icz-entity-class-selector>
        }
        <icz-disposal-schedule-retention-trigger-widget
          [entityClassId]="entityClassId"
          [documentTypeId]="documentTypeId"
          [form]="form"
        ></icz-disposal-schedule-retention-trigger-widget>
      </icz-section>
    </div>

    <div class="col grow">
      <icz-section label="Specifické">
        <icz-security-category-selector
          [form]="form"
          controlName="securityCategoryId"
        ></icz-security-category-selector>
        <icz-keywords-selector [form]="form" controlName="keywordIds" ></icz-keywords-selector>
        <icz-document-file-custom-fields-section
          [form]="form"
          [entityType]="EntityType.OWN_DOCUMENT"
          [documentTypeId]="documentTypeId"
        ></icz-document-file-custom-fields-section>
      </icz-section>

      <icz-section label="Zmocnění a termín vyřízení" date-cy="resolutionAndLawDate">
        <div class="row grow">
          <icz-date-picker formControlName="resolutionDate" label="Termín vyřízení" class="grow" [selectableDates]="presentOrFutureDateValidator" outputFormat="dateTime">
          </icz-date-picker>
          <icz-date-picker formControlName="executionDate" label="Právní moc" class="grow" outputFormat="dateTime">
          </icz-date-picker>
        </div>
        <div class="row grow">
          <icz-empowerment formControlName="empowerment"></icz-empowerment>
        </div>
      </icz-section>
    </div>
  </div>
  <icz-validation-errors-list [control]="esslComponentCountFormCtrl" [fieldsValidationErrors]="[]" [wrapInAlert]="true" [useFocusableArea]="true">
  </icz-validation-errors-list>
  <div class="col">
    <icz-section label="Digitální komponenty" [showExpansionHint]="true">
      <icz-digital-components-upload-form [form]="form" [selectFiles]="selectFiles" [config]="digitalComponentsConfig"
        [totalComponentsLimit]="digitalComponentsCountLimit" [type]="DigitalComponentUploadDialogType.NEW_DIGITAL_COMPONENT" class="grow wide">
      </icz-digital-components-upload-form>
      <div class="row mt-16">
        <icz-button
          data-cy="digitalComponentComponent"
          class="add-component-button"
          label="Vybrat soubor z počítače"
          (onAction)="addAnotherDigitalComponent()"
          [disabled]="digitalComponentsCountExceedsLimit"
          [iczTooltip]="digitalComponentsCountExceedsLimitTooltip"
        ></icz-button>
        <icz-button
          class="add-component-button"
          label="Založit ze šablony"
          (onAction)="addAnotherDigitalComponentFromTemplate()"
          [disabled]="digitalComponentsCountExceedsLimit"
          [iczTooltip]="digitalComponentsCountExceedsLimitTooltip"
        ></icz-button>
        <div class="grow"></div>
      </div>
    </icz-section>
    <icz-add-analog-component-to-document [form]="form" [analogComponentsConfig]="analogComponentsConfig"></icz-add-analog-component-to-document>
  </div>
</form>
