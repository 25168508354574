import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InternalNotificationKey} from '|api/notification';
import {
  EsslObjectType,
  RelatedObjectType,
  SubjectObjectRelationType,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto,
  UpdateRelationWithSubjectDto,
} from '|api/commons';
import {SubjectToastService, SubjectToastType} from '../../../../core/services/notifications/subject-toast.service';
import {
  SubjectCreateComponentMode,
  SubjectCreateFormComponent
} from '../subject-create-form/subject-create-form.component';
import {SubjectsService} from '../../../../services/subjects.service';
import {LoadingIndicatorService, TabItem} from '@icz/angular-essentials';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';
import {
  constructSubjectName,
  SubjectDuplicateResolveDialogResult,
  SubjectReplacementConfig
} from '../../model/subjects.model';
import {stripUnwantedProps} from '../../../../lib/utils';
import {DocumentDetailService} from '../../../../services/document-detail.service';
import {
  SubjectOperation,
  SubjectOperatonComplete,
  SubjectToolbarContext
} from '../subjects-toolbar/subjects-toolbar.component';
import {Observable, of} from 'rxjs';
import {CheckUnsavedFormDialogService} from '../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../lib/form-group-checks';
import {ApiSubjectAdministrationService, ApiSubjectRecordService,} from '|api/subject-register';
import {SKIP_ERROR_DIALOG} from '../../../../core/error-handling/http-errors';
import {
  ConsignmentWithDuplicateResolveService
} from '../../received-document-form/consignment-with-duplicate-resolve.service';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {isSubjectRegisterManager} from '../../../../core/model/user-roles.model';
import {CodebookService} from '../../../../core/services/codebook.service';
import {cloneDeep} from 'lodash';
import {DialogService, injectModalData, injectModalRef} from '@icz/angular-modal';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {debounceTime} from 'rxjs/operators';
import {esslErrorDtoToToastParameters} from '../../../notifications/toast.service';

export enum EditSubjectDialogMode {
  ISDS_VIEW_ONLY = 'ISDS_VIEW_ONLY',
  ISZR_VIEW = 'ISZR_VIEW',
  READ_ONLY = 'READ_ONLY',
  EDIT_NONPERSISTENT = 'EDIT_NONPERSISTENT',
  FULL = 'FULL',
}

export interface EditSubjectDialogData {
  subject: SubjectRecordDto;
  mode: EditSubjectDialogMode;
  relationType?: Nullable<SubjectObjectRelationType>;
  representing?: Nullable<boolean>;
  subjectReplacementConfig?: SubjectReplacementConfig;
  allowSubjectReplace?: Nullable<boolean>;
}

enum EditSubjectDialogTab {
  OVERVIEW = 'OVERVIEW',
  USAGE_REPORT = 'USAGE_REPORT',
  HISTORY = 'HISTORY',
  DISTRIBUTION_LISTS = 'DISTRIBUTION_LISTS',
  RELATED = 'RELATED',
}

@Component({
  selector: 'icz-edit-subject',
  templateUrl: './edit-subject-dialog.component.html',
  styleUrls: ['./edit-subject-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditSubjectDialogComponent implements OnInit, IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<SubjectRecordDto>>();
  private apiSubjectRecordNgService = inject(ApiSubjectRecordService);
  private destroyRef = inject(DestroyRef);
  private apiSubjectAdministrationService = inject(ApiSubjectAdministrationService);
  private codebookService = inject(CodebookService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private subjectsService = inject(SubjectsService);
  private consignmentWithDuplicateResolveService = inject(ConsignmentWithDuplicateResolveService);
  private subjectToastService = inject(SubjectToastService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private translateService = inject(TranslateService);
  private currentSessionService = inject(CurrentSessionService);
  private dialogService = inject(DialogService);
  private checkUnsavedFormDialogService = inject(CheckUnsavedFormDialogService);
  protected dialogData = injectModalData<EditSubjectDialogData>();
  protected documentDetailService = inject(DocumentDetailService, {optional: true});

  subject = cloneDeep(this.dialogData?.subject);

  readonly null$ = of(null);
  readonly ComponentMode = SubjectCreateComponentMode;
  readonly SubjectToolbarContext = SubjectToolbarContext;
  form = SubjectCreateFormComponent.getCreateSubjectFormGroup(this.subject);

  isSubjectRegisterManager = false;
  submitBlocked = false;
  iszrIdentifiedSubject: Nullable<SubjectRecordDto>;
  skipIsdsMode = false;

  formGroupsToCheck!: string[];
  classificationChangeWithReferenceUpdate = false;

  private setTabs() {
    this.tabs = [
      {
        id: EditSubjectDialogTab.OVERVIEW,
        label: 'Přehled',
        showCount: false,
      },
      {
        id: EditSubjectDialogTab.USAGE_REPORT,
        label: 'Použití',
        showCount: false,
        isHidden: !this.isSubjectRegisterManager,
      },
      {
        id: EditSubjectDialogTab.HISTORY,
        label: 'Historie',
        showCount: false,
      },
      // {
      //   id: EditSubjectDialogTab.DISTRIBUTION_LISTS,
      //   label: 'Distribuční seznamy',
      //   showCount: false,
      //   disabled: true,
      // },
      // {
      //   id: EditSubjectDialogTab.RELATED,
      //   label: 'Související',
      //   showCount: false,
      //   disabled: true,
      // },
    ];
  }

  @ViewChild('subjectCreateFormComponent') subjectCreateFormComponent!: SubjectCreateFormComponent;

  tabs: TabItem<EditSubjectDialogTab>[] = [];
  activeTab = EditSubjectDialogTab.OVERVIEW;

  valueCorrectionMode = true;

  readonly EditSubjectDialogTab = EditSubjectDialogTab;
  readonly EsslObjectType = EsslObjectType;

  get subjectToolbarContext() {
    if (this.isIsdsViewOnly) {
      return this.SubjectToolbarContext.SUBJECT_DETAIL_ISDS;
    } else if (this.isIszrView) {
      return this.SubjectToolbarContext.SUBJECT_DETAIL_ISZR;
    } else {
      return this.SubjectToolbarContext.SUBJECT_DETAIL;
    }
  }

  get isSubjectRepresenting(): boolean {
    return Boolean(this.documentDetailService && this.subject?.id && (
      this.subject?.id === this.documentDetailService.objectRepresentingSubject?.id));
  }

  get isIszrSubject() {
    return SubjectTemplateUtils.isIszrVerified(this.subject);
  }

  get isNonpersistentSubjectMode() {
    return this.isIsdsViewOnly || this.isEditNonpersistentMode;
  }

  get isFullEdit() {
    return this.dialogData.mode === EditSubjectDialogMode.FULL;
  }

  get isReadOnly() {
    return this.dialogData?.mode === EditSubjectDialogMode.READ_ONLY;
  }

  get isIsdsViewOnly() {
    return this.dialogData?.mode === EditSubjectDialogMode.ISDS_VIEW_ONLY;
  }

  get isIszrView() {
    return this.dialogData?.mode === EditSubjectDialogMode.ISZR_VIEW;
  }

  get isEditNonpersistentMode() {
    return this.dialogData?.mode === EditSubjectDialogMode.EDIT_NONPERSISTENT;
  }

  get showTabs() {
    return !this.isIsdsViewOnly && !this.isEditNonpersistentMode && !this.isIszrView;
  }

  ngOnInit() {
    this.checkUnsavedFormDialogService.addUnsavedFormCheck(this, ['form']);

    if (this.isReadOnly || this.isIsdsViewOnly) {
      this.form.disable();
    }
    if (this.isIszrSubject) {
      this.skipIsdsMode = true;
    }
    this.currentSessionService.currentUserFunctionalPosition$.pipe(
      takeUntilDestroyed(this.destroyRef))
      .subscribe(currentUserInfo => {
        this.isSubjectRegisterManager = isSubjectRegisterManager(currentUserInfo!);
        this.setTabs();
      });
    this.form.valueChanges.pipe(debounceTime(250), takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      const oldAttributes = cloneDeep(this.dialogData.subject.attributes);
      const formValue = stripUnwantedProps<any, any>(this.form.getRawValue(), [], {removeNull: false, removeEmptyString: true});
      if (this.subject) {
        this.subject = {
          ...cloneDeep(this.subject),
          classification: SubjectTemplateUtils.classification(this.form),
          attributes: this.subjectsService.formValueToAttributesDto(formValue, oldAttributes)
        };
      }
    });
  }

  private enrichExistingSubjectWithIdentified(iszrIdentifiedSubject: SubjectRecordDto) {
    const formValue = stripUnwantedProps<any, any>(this.form.getRawValue(), [], {removeNull: false, removeEmptyString: true});
    const enrichResult = this.subjectsService.enrichExistingSubjectWithIdentified(this.subject, iszrIdentifiedSubject, formValue);
    this.subject = enrichResult.enrichedSubject;
    this.skipIsdsMode = true;
    this.form = SubjectCreateFormComponent.getCreateSubjectFormGroup(this.subject);

    Object.entries(enrichResult.diff).forEach(([key, value]) => {
      this.form.get(key)!.get('previousValue')?.setValue(value);
    });
  }

  toolbarOperationCompleted(op: SubjectOperatonComplete) {
    if (op.outputSubject && op.operation === SubjectOperation.IDENTIFY) {
      this.iszrIdentifiedSubject = op.outputSubject;
      this.enrichExistingSubjectWithIdentified(op.outputSubject);
      if (op.additionalOperationResult?.valueCorrectionMode !== undefined) {
        this.valueCorrectionMode = op.additionalOperationResult?.valueCorrectionMode;
      }
      if (this.isFullEdit) {
        this.update(false);
      }
    }
  }

  cancel() {
    if (this.isIszrView) {
      this.cancelFromIszrView();
    } else if (this.iszrIdentifiedSubject) {
      this.modalRef.close(this.subject);
    } else {
      this.modalRef.close();
    }
  }

  disposeSubject() {
    this.loadingIndicatorService.doLoading(
      this.apiSubjectAdministrationService.subjectAdministrationDisposeSubject({subjectId: this.subject.id!})
      , this)
      .subscribe(_ => {
        const name = constructSubjectName(this.subject!);
        this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_DISPOSE_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
        this.cancel();
      });
  }

  private onUpdateSuccess(subject: SubjectRecordDto | SubjectRecordCreateOrUpdateDto, closeDialog = true) {
    const name = constructSubjectName(subject);
    this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_UPDATE_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
    this.subject = subject as SubjectRecordDto;
    this.changeDetectorRef.detectChanges();
    if (closeDialog) {
      this.modalRef.close(subject as SubjectRecordDto);
    }
  }

  private onUpdateTechnicalError(subject: SubjectRecordCreateOrUpdateDto, error: any) {
    const name = constructSubjectName(subject);

    this.subjectToastService.dispatchSubjectErrorToast(
      SubjectToastType.SUBJECT_UPDATE_ERROR, {
        [InternalNotificationKey.SUBJECT_NAME]: name,
        ...esslErrorDtoToToastParameters(this.translateService, error.error),
      });
  }

  closeWithIszrSubject() {
    if (!this.isIszrView) return;
    const formValue = stripUnwantedProps<any, any>(this.form.getRawValue(), [], {removeNull: false, removeEmptyString: true});
    this.subject.attributes = this.subjectsService.formValueToAttributesDto(formValue, this.subject.attributes);

    this.modalRef.close(this.subject);
  }

  cancelFromIszrView() {
    this.dialogService.openSimpleDialog({
      title: 'Upozornění',
      content: [{
        text: 'Data o subjektu, zjištěná dotazem do registru, budou ztracena.\n' +
          'Váš dotaz na subjekt bude ale zaznamenán a uložen do transakčního protokolu.'
      }],
      leftButtonTitle: 'Použít subjekt',
      rightButtonTitle: 'Rozumím, subjekt nechci použít'
    }).subscribe(result => {
      if (result) {
        this.closeWithIszrSubject();
      }
      else {
        this.modalRef.close();
      }
    });
  }

  private cloneSubjectAsNewForClassificationChange(originalSubject: SubjectRecordCreateOrUpdateDto): SubjectRecordCreateOrUpdateDto {
    const newSubjectDefinitionFromOriginal = cloneDeep(originalSubject);
    newSubjectDefinitionFromOriginal.id = null;
    Object.values(newSubjectDefinitionFromOriginal.attributes).forEach(attrValue => {
      if (Array.isArray(attrValue)) {
        attrValue.forEach(v => {
          if (v) v.id = null;
        });
      } else {
        if (attrValue) attrValue.id = null;
      }
    });

    return newSubjectDefinitionFromOriginal;
  }

  // todo(lp) write update as reusable stream
  update(closeDialog = true, updateRelationFromDuplicate?: UpdateRelationWithSubjectDto) {
    if (!this.dialogData || !this.form) return;

    const oldAttributes = cloneDeep(this.dialogData.subject.attributes);
    const valueCorrectionMode = this.valueCorrectionMode;
    const ovmType = SubjectTemplateUtils.ovmType(this.form);
    const classification = SubjectTemplateUtils.classification(this.form);

    const formValue = stripUnwantedProps<any, any>(this.form.getRawValue(), [], {removeNull: false, removeEmptyString: true});
    let attributes = this.subjectsService.formValueToAttributesDto(formValue, oldAttributes);
    attributes = this.subjectsService.correctForDeletedAttributesWithMultipleValues(attributes, oldAttributes);

    const updatedSubject: SubjectRecordCreateOrUpdateDto = {
      id: this.subject.id,
      classification,
      valueCorrectionMode,
      forceMode: false,
      enrichMode: true,
      ovmType,
      attributes,
      iszrIdentifier: this.subject.iszrIdentifier,
      iszrMetadata: this.subject.iszrMetadata,
      skipIsdsMode: this.skipIsdsMode,
    };

    // in-memory update for temporary subjects which are not yet saved into the database
    if (!this.subject.id) {
      this.onUpdateSuccess(updatedSubject, closeDialog);
    }
    else {
      let req$: Observable<any>;
      if (this.classificationChangeWithReferenceUpdate) {
        const newSubjectDefinitionFromUpdated = this.cloneSubjectAsNewForClassificationChange(updatedSubject);

        const initialUpdateRelation: UpdateRelationWithSubjectDto = {
          oldSubjectId: updatedSubject.id!,
          newSubjectId: null,
          newSubjectDefinition: newSubjectDefinitionFromUpdated,
          relatedObjectId: this.documentDetailService!.objectId,
          relatedObjectType: RelatedObjectType.DOCUMENT,
          relationType: this.dialogData.relationType,
          representing: this.dialogData.representing,
        };
        req$ = this.apiSubjectRecordNgService.subjectRecordUpdateRelationWithSubject({body: initialUpdateRelation}, SKIP_ERROR_DIALOG);
      } else if (updateRelationFromDuplicate) {
        req$ = this.apiSubjectRecordNgService.subjectRecordUpdateRelationWithSubject({body: updateRelationFromDuplicate}, SKIP_ERROR_DIALOG);
      } else {
        req$ = this.apiSubjectRecordNgService.subjectRecordCreateOrUpdateRecord({body: updatedSubject}, SKIP_ERROR_DIALOG);
      }

      this.loadingIndicatorService.doLoading(
        this.consignmentWithDuplicateResolveService.createOrUpdateWithDuplicateResolve(
          req$.pipe(takeUntilDestroyed(this.destroyRef)),
          updatedSubject,
          this.dialogData.subjectReplacementConfig!,
        ), this)
        .subscribe(
          {
          next: updateAttemptResult => {
            if (!updateAttemptResult) {
              this.changeDetectorRef.detectChanges();
              return; // no result means closing the duplicate dialog without any action
            }
            if (updateAttemptResult instanceof SubjectDuplicateResolveDialogResult) {
              if (updateAttemptResult.forceCreateOrUpdate && !this.classificationChangeWithReferenceUpdate) {
                this.loadingIndicatorService.doLoading(
                  this.apiSubjectRecordNgService.subjectRecordCreateOrUpdateRecord({
                    body: {...updatedSubject, forceMode: true},
                  }),
                  this).subscribe(
                  {
                    next: result => {
                      this.onUpdateSuccess(result, closeDialog);
                    }, error: err => {
                      this.onUpdateTechnicalError(updatedSubject, err);
                    }
                  });
              }
              else {
                if (this.documentDetailService?.objectId) {
                  const selectedNewSubject = {...updateAttemptResult.selectedSubject} as SubjectRecordCreateOrUpdateDto;
                  selectedNewSubject.forceMode = true;

                  const updateRelation: UpdateRelationWithSubjectDto = {
                    oldSubjectId: updatedSubject.id!,
                    newSubjectId: updateAttemptResult.selectedSubject!.id ?? null,
                    newSubjectDefinition: updateAttemptResult.selectedSubject!.id ? null : selectedNewSubject,
                    relatedObjectId: this.documentDetailService?.objectId,
                    relatedObjectType: RelatedObjectType.DOCUMENT,
                    relationType: this.dialogData.relationType,
                    representing: this.dialogData.representing,
                  };
                  this.update(closeDialog, updateRelation);
                } else {
                  this.onUpdateSuccess(updateAttemptResult.selectedSubject!, closeDialog);
                }
              }
            } else {
              this.onUpdateSuccess(updateAttemptResult, closeDialog);
            }
          },
          error: err => {
            this.onUpdateTechnicalError(updatedSubject, err);
          }
        }
      );
    }
  }

  tabClicked(tabItem: TabItem<EditSubjectDialogTab>) {
    this.activeTab = tabItem.id;
  }

}
