import {Component, inject} from '@angular/core';
import {CirculationActivityFlowType, CirculationActivityType, DocumentForm} from '|api/commons';
import {DigitalComponentCompleteDto} from '|api/component';
import {DocumentDto} from '|api/document';
import {
  ApiCirculationActivityBulkActionsService,
  ApiCirculationActivityService,
  CirculationActivityCreateDto
} from '|api/flow';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {
  AbstractHandoverFormModalComponent
} from '../../../document-toolbar/components/abstract-handover-form-modal.component';
import {IczFormControl, IczFormGroup, IczValidators, TextLength} from '@icz/angular-form-elements';
import {FunctionalPositionGroupItems} from '../../../saved-functional-positions.service';
import {OrganizationStructureType} from '../../../functional-position-selector/org-structure-selector.component';
import {MessageType} from '../../../../notifications/toast.service';
import {getLatestDigitalComponentVersion} from '../../../shared-document.utils';
import {SKIP_ERROR_DIALOG} from '../../../../../core/error-handling/http-errors';
import {handleCirculationErrorAsToast} from '../../../../../utils/document-file-circulation.utils';
import {InternalNotificationKey} from '|api/notification';
import {
  SEsslActivityErrorData
} from '../../../../../core/services/notifications/essl-component-circulation-toast.service';
import {injectModalData} from '@icz/angular-modal';


@Component({
  selector: 'icz-hand-component-for-approval-dialog',
  templateUrl: './hand-component-for-approval-dialog.component.html',
  styleUrls: ['./hand-component-for-approval-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class HandComponentForApprovalDialogComponent extends AbstractHandoverFormModalComponent {

  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private apiCirculationActivityBulkActionsService = inject(ApiCirculationActivityBulkActionsService);
  protected override circulationEntities = injectModalData<DigitalComponentCompleteDto[]>();

  document!: DocumentDto;

  readonly CirculationActivityFlowType = CirculationActivityFlowType;

  override form = new IczFormGroup({
    orgStructureElementIds: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    circulationFlowType: new IczFormControl<CirculationActivityFlowType>(CirculationActivityFlowType.PARALLEL),
    taskParticipantsOrder: new IczFormControl<Nullable<string>>(null),
    targetTaskInstruction: new IczFormControl<Nullable<string>>(null, [], [], TextLength.LONG),
    deadline: new IczFormControl<Nullable<string>>(null),
    printHandoverProtocol: new IczFormControl<Nullable<boolean>>(false),
  });

  protected override participantsOrderControlName = 'taskParticipantsOrder';

  override get selectedFlowType(): CirculationActivityFlowType {
    return this.form.get('circulationFlowType')!.value;
  }

  override get groupForSaving(): FunctionalPositionGroupItems {
    return this.participantsOrderControl?.value;
  }

  readonly OrganizationStructureType = OrganizationStructureType;
  readonly DocumentForm = DocumentForm;

  override ngOnInit() {
    super.ngOnInit();

    // todo(mh) ESSL-8643 this is commented out as it is not yet analyzed for single or bulk actions
    /*this.loadingService.doLoading(
      this.apiDocumentService.documentFindById({
        id: initEntity.documentId!
      }),
      this
    ).subscribe(document => this.document = document);*/
  }

  handForApproval() {

    const formValue = this.form.value;
    const activityCreateDtos: CirculationActivityCreateDto[] = this.circulationEntities.map(entity => ({
      circulationEntityType: entity.entityType!,
      componentId: entity.id,
      digitalComponentVersionId: getLatestDigitalComponentVersion(entity)?.id,
      deadline: formValue.deadline!,
      flowType: this.selectedFlowType,
      targetTaskInstruction: formValue.targetTaskInstruction!,
      functionalPositions: this.selectedFunctionalPositions,
      organizationalUnits: this.selectedOrganizationalUnits,
    }));

    if (this.isSingleEntityFlow) {
      this.loadingService.doLoading(
        this.apiCirculationActivityService.circulationActivityCreateApproval(
          {
            body: activityCreateDtos[0],
          },
          SKIP_ERROR_DIALOG
        ),
        this,
      ).subscribe({
        next: createdActivity => this.modalRef.close(createdActivity.id),
        error: errorResponse => handleCirculationErrorAsToast(
          this.circulationEntities[0],
          errorResponse,
          this.toastService,
          this.translateService,
          this.modalRef,
          CirculationActivityType.COMPONENT_APPROVAL
        ),
      });
    } else {
      this.loadingService.doLoading(
        this.apiCirculationActivityBulkActionsService.circulationActivityBulkActionsApproval(
          {
            body: activityCreateDtos,
          },
          SKIP_ERROR_DIALOG
        ),
        this,
      ).subscribe({
        next: _ => this.modalRef.close(true),
        error: _ => {
          this.modalRef.close(false);
          this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationActivityType.COMPONENT_APPROVAL, [{[InternalNotificationKey.ACTIVITY_TYPE_KEY]: CirculationActivityType.COMPONENT_APPROVAL}], SEsslActivityErrorData);
        }
      });
    }
  }

}
