import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {IconRegistryKeyProvider} from '@icz/angular-essentials';
import {EnvironmentIconColorScheme, UserSettingsService} from '../../services/user-settings.service';
import {ApplicationConfigService} from '../services/config/application-config.service';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {ICONS} from './icons';

@Injectable()
export class EsslIconRegistryKeyProviderService implements IconRegistryKeyProvider {

  private userSettings = inject(UserSettingsService);
  private applicationConfigService = inject(ApplicationConfigService);

  private colorSchemeMapping = {
    [EnvironmentIconColorScheme.GRAY_SCHEME]: '',
    [EnvironmentIconColorScheme.COLOR_SCHEME]: '__color'
  };

  getIconRegistryKey$(svgIcon$: Observable<Nullable<string>>): Observable<Nullable<string>> {
    return combineLatest([
      this.userSettings.environmentIconColorScheme$.pipe(distinctUntilChanged()),
      svgIcon$.pipe(distinctUntilChanged()),
    ]).pipe(
      map(([localStorageColorScheme, svgIcon]) => {
        const colorScheme = localStorageColorScheme ?? this.applicationConfigService.defaultIconColorScheme;

        if ((svgIcon + this.colorSchemeMapping[colorScheme]) in ICONS) {
          return (svgIcon + this.colorSchemeMapping[colorScheme]);
        }
        else {
          return svgIcon;
        }
      })
    );
  }

}
