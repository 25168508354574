import {inject, Injectable} from '@angular/core';
import {Observable, switchMap} from 'rxjs';
import {Page, SearchParams} from '@icz/angular-table';
import {SearchApiService} from './search-api.service';
import {CORE_MICROSERVICE_ROOT} from '../api';
import {ApiStorageUnitFillerService, StorageUnitFillerDto} from '|api/document';
import {map} from 'rxjs/operators';
import {StorageUnitFillerState} from '|api/commons';

export interface StorageUnitFillerDtoExtended extends StorageUnitFillerDto {
  scheduledRunAt?: Nullable<string>;
}

@Injectable({
  providedIn: 'root',
})
export class StorageUnitFillerSearchService extends SearchApiService {

  private apiStorageUnitFillerService = inject(ApiStorageUnitFillerService);

  findStorageUnitFillers(searchParams: Partial<SearchParams>): Observable<Page<StorageUnitFillerDtoExtended>> {
    return this.searchApi<Page<StorageUnitFillerDtoExtended>>(searchParams, CORE_MICROSERVICE_ROOT + ApiStorageUnitFillerService.StorageUnitFillerFindPath).pipe(
      switchMap(fillersPage => {
        return this.apiStorageUnitFillerService.storageUnitFillerGetScheduledRunAt().pipe(map(scheduledRunAt => {
          const content = fillersPage.content.map(c => ({
            ...c,
            scheduledRunAt: c.storageUnitFillerState === StorageUnitFillerState.PLANNED ? scheduledRunAt : null,
          }));

          return {...fillersPage, content};
        }));
      })
    );
  }

}
