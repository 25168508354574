import {OwnPaperConsignmentDto, OwnPaperConsignmentElasticDto} from '|api/sad';
import {OwnConsignmentWorkflowAction} from '../consignment-dialog/consignment-dialog/consignment.model';

export const dispatchDialogTitles: Record<OwnConsignmentWorkflowAction, string> = {
  [OwnConsignmentWorkflowAction.HANDOVER]: 'Předání výpravně',
  [OwnConsignmentWorkflowAction.TAKEOVER]: 'Převzetí',
  [OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION]: 'Předání do distribuce',
  [OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION]: 'Převzetí a předání do distribuce',
  [OwnConsignmentWorkflowAction.DISPATCH]: 'Potvrzení vypravení',
  [OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE]: 'Vypravení mimo výpravnu',
  [OwnConsignmentWorkflowAction.POST]: 'Vyvěsit',
  [OwnConsignmentWorkflowAction.UNPOST]: 'Svěsit',
  [OwnConsignmentWorkflowAction.REJECT]: 'Vrátit vypravení',
  [OwnConsignmentWorkflowAction.WITHDRAW_FROM_DISTRIBUTION]: 'Stáhnout z distribuce',
  [OwnConsignmentWorkflowAction.WITHDRAW_FOR_CORRECTION]: 'Stáhnout k nápravě',
  [OwnConsignmentWorkflowAction.CANCEL]: 'Stornování vypravení',
  [OwnConsignmentWorkflowAction.DELETE]: 'Smazání vypravení',
};

export enum PrepareOrDispatchDialogType {
  PREPARE_FOR_DISTRIBUTION = 'PREPARE_FOR_DISTRIBUTION',
  TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION = 'TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION',
  MARK_AS_DISPATCH = 'MARK_AS_DISPATCH',
}

export interface PrepareOrDispatchDialogResult {
  resultType: PrepareOrDispatchDialogType;
  consignment: OwnPaperConsignmentDto;
}

export interface PrepareOrDispatchDialogData {
  type: PrepareOrDispatchDialogType,
  documentId: Nullable<number>;
  consignments: OwnPaperConsignmentElasticDto[];
}

export interface PrepareOrDispatchFormData extends Omit<PrepareOrDispatchDialogData, 'consignments'> {
  showMetadata: boolean;
}
