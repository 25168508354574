import {ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {isNil} from 'lodash';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {DeliveryTypeDto} from '|api/codebook';
import {DistributionClass, NodeType, SubjectRecordClassification, SubjectRecordDto} from '|api/commons';
import {DocumentDto} from '|api/document';
import {
  ApiReceivedDigitalConsignmentService,
  ApiReceivedPaperConsignmentService,
  ReceivedDigitalConsignmentDto,
  ReceivedPaperConsignmentDto
} from '|api/sad';
import {IczOnChanges} from '@icz/angular-essentials';
import {ElasticDocumentOrFile} from '../../model/document-file-sidebar.model';
import {addressDtoToForm, createAddressFormGroup} from '../../subjects/address/address-utils';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {CodebookService} from '../../../../core/services/codebook.service';
import {AddressCompleteDto} from '../../model/addresses.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {SubjectAttributeType} from '../../model/subject-attribute-type';


@Component({
  selector: 'icz-document-file-sidebar-tab-delivery',
  templateUrl: './document-file-sidebar-tab-delivery.component.html',
  styleUrls: ['./document-file-sidebar-tab-delivery.component.scss'],
})
export class DocumentFileSidebarTabDeliveryComponent implements OnInit, IczOnChanges{

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);
  private apiReceivedDigitalConsignmentService = inject(ApiReceivedDigitalConsignmentService);
  private apiReceivedPaperConsignmentService = inject(ApiReceivedPaperConsignmentService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private translateService = inject(TranslateService);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  object!: ElasticDocumentOrFile;

  readonly SubjectAttributeType = SubjectAttributeType;

  deliveryTypeOptions!: DeliveryTypeDto[];

  consignmentDistributionClass: Nullable<DistributionClass>;

  isPaperConsignment = false;
  isDigitalConsignment = false;

  receivedConsignment: Nullable<ReceivedPaperConsignmentDto | ReceivedDigitalConsignmentDto>;
  deliveryTypeName!: Nullable<string>;
  senderSubject: Nullable<SubjectRecordDto> = {attributes: {}, identifiable: false, classification: SubjectRecordClassification.FO};
  senderAddressDtoForm = createAddressFormGroup();
  hasPaperReceivedComponents = false;
  hasDigitalReceivedComponents = false;

  get isPostDelivery() {
    return (this.consignmentDistributionClass === DistributionClass.ADDRESS_PLACE || this.consignmentDistributionClass === DistributionClass.PHYSICAL_BOX);
  }

  get isDataBoxDelivery() {
    return (this.consignmentDistributionClass === DistributionClass.ISDS_BOX);
  }

  get hasSender() {
    return !isNil(this.senderSubject) && Object.keys(this.senderSubject).length > 0;
  }

  ngOnInit(): void {
    this.codebookService.deliveryTypes()
      .pipe(takeUntilDestroyed(this.destroyRef), map(deliveryTypes => deliveryTypes.filter(type => type.nodeType === NodeType.LEAF)))
      .subscribe(deliveryTypes => this.deliveryTypeOptions = deliveryTypes);
    this.initTab();
  }

  ngOnChanges() {
    this.initTab();
  }

  get formattedPaperComponentsCount(): string {
    let composition: string = '';
    const compositionSeparator = ' + ';
    if (this.object) {
      if (isNil((this.object as DocumentDto).receivedQuantification?.mainPaperComponentSheetCount)) {
        composition += 0;
      } else {
        composition += String((this.object as DocumentDto).receivedQuantification?.mainPaperComponentSheetCount);
      }

      if (isNil((this.object as DocumentDto).receivedQuantification?.paperComponentSheetEnclosureCount)) {
        composition += `${compositionSeparator}${String(0)}/${String(0)}`;
      } else {
        const paperComponentSheetEnclosureCount = (this.object as DocumentDto).receivedQuantification?.paperComponentSheetEnclosureCount;
        const paperComponentSheetCount = (this.object as DocumentDto).receivedQuantification?.paperComponentSheetCount;
        composition += `${compositionSeparator}${String(paperComponentSheetEnclosureCount)}/${String(paperComponentSheetCount)}`;
      }

      if (isNil((this.object as DocumentDto).receivedQuantification?.paperComponentVolumeEnclosureCount)) {
        composition += compositionSeparator + String(0) + ' ' + this.translateService.instant('Sv.');
      } else {
        const paperComponentVolumeEnclosureCount = (this.object as DocumentDto).receivedQuantification?.paperComponentVolumeEnclosureCount;
        composition += compositionSeparator + String(paperComponentVolumeEnclosureCount) + ' ' + this.translateService.instant('Sv.');
      }
    }
    return composition;
  }

  get nonPaperReceivedComponents() {
    return (this.object as DocumentDto).receivedQuantification ? String((this.object as DocumentDto).receivedQuantification?.nonPaperComponentTypeAndCount) : '';
  }

  get digitalReceivedComponents() {
    return (this.object as DocumentDto).receivedQuantification ? String((this.object as DocumentDto).receivedQuantification?.digitalComponentCount) : '';
  }

  initTab() {
    if (this.object) {
      this.hasDigitalReceivedComponents = ((this.object as DocumentDto).receivedQuantification?.digitalComponentCount! > 0);
      this.hasPaperReceivedComponents = ((this.object as DocumentDto).receivedQuantification?.analogComponentCount! > 0);
      this.loadingService.doLoading(
        forkJoin([
          this.apiReceivedPaperConsignmentService
            .receivedPaperConsignmentFindConsignmentsForDocument({
              id: this.object.id!,
              page: 0,
            }),
          this.apiReceivedDigitalConsignmentService.receivedDigitalConsignmentFindConsignmentsForDocument({
            id: this.object.id!,
            page: 0,
          })
        ]),
        this
      ).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(([paperConsignmentPage, digitalConsignmentPage]) => {
        if (!isNil(paperConsignmentPage.content) && paperConsignmentPage.content.length > 0){
          this.isPaperConsignment = true;
          this.receivedConsignment = paperConsignmentPage.content![0];
          this.loadDeliveryTypeInfo(this.receivedConsignment);
          this.senderAddressDtoForm.patchValue(addressDtoToForm(this.receivedConsignment.senderAddress as AddressCompleteDto)!);
        }

        if (!isNil(digitalConsignmentPage.content) && digitalConsignmentPage.content.length > 0){
          this.isDigitalConsignment = true;
          this.receivedConsignment = digitalConsignmentPage.content![0];
          this.loadDeliveryTypeInfo(this.receivedConsignment);
        }
      });
      this.cd.detectChanges();
    }
  }

  loadDeliveryTypeInfo(consignment: ReceivedPaperConsignmentDto | ReceivedDigitalConsignmentDto) {
    const consignmentDeliveryType = this.deliveryTypeOptions.filter(deliveryType => deliveryType.id === consignment.deliveryTypeId)[0];
    this.consignmentDistributionClass = consignmentDeliveryType.distributionClass?.distributionClassValue;
    this.deliveryTypeName = consignmentDeliveryType.name;
    if (!isNil(consignment.sender)) {
      this.apiSubjectRecordNgElasticService.subjectRecordElasticGet({
        subjectId: consignment.sender,
      }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe( senderSubject => {
          if (senderSubject) {
            this.senderSubject = senderSubject;
            this.cd.detectChanges();
          }
        }
      );
    } else {
      this.senderSubject = null;
    }
    this.cd.detectChanges();
  }

}
