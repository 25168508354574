import {inject, Injectable} from '@angular/core';
import {Observable, of, switchMap, tap} from 'rxjs';
import {ConsignmentType} from '|api/commons';
import {
  ApiOwnConsignmentService,
  OwnConsignmentHandoverDto,
  OwnConsignmentTakeoverDto,
  OwnDigitalConsignmentElasticDto,
  OwnOfficeDeskConsignmentElasticDto, OwnPaperConsignmentDto,
  OwnPaperConsignmentElasticDto,
} from '|api/sad';
import {
  GenericOwnConsignment,
  GenericOwnElasticConsignment,
  GenericOwnElasticConsignmentWithConsignee,
  GenericUpdateOwnConsignment,
  isOwnDigitalElasticConsignment,
  isOwnInternalDigitalElasticConsignment,
  isOwnInternalPaperElasticConsignment,
  isOwnOfficeDeskElasticConsignment,
  isOwnPaperElasticConsignment,
  OwnConsignmentWorkflowActionOpts,
  convertOwnConsignmentToOwnElasticConsignment, isOwnMultiPaperElasticConsignment,
} from './model/own-consignment-model';
import {
  RejectDialogService,
  RejectDialogType,
} from '../dispatch-dialogs/components/reject-dialog/reject-dialog.service';
import {
  WithdrawForCorrectionDialogService,
} from '../dispatch-dialogs/components/withdraw-for-correction-dialog/withdraw-for-correction-dialog.service';
import {CounterTypeGroup, MainMenuCountsService} from '../../../core/services/main-menu-counts.service';
import {AbstractManualPostDialogService} from '../../../services/abstract-manual-post-dialog.service';
import {
  PrepareOrDispatchDialogService,
} from '../dispatch-dialogs/components/prepare-or-dispatch-dialog/prepare-or-dispatch-dialog.service';
import {PrepareOrDispatchDialogType} from '../dispatch-dialogs/dispatch-dialogs.model';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {OwnConsignmentWorkflowAction} from '../consignment-dialog/consignment-dialog/consignment.model';
import {DialogService} from '@icz/angular-modal';
import {map} from 'rxjs/operators';

export function isOwnEmailConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OWN_DIGITAL_EMAIL;
}

function isOwnDataboxConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX;
}

export function isOwnPaperConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS;
}

export function isOwnPaperOrMultiPaperConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS ||
    c?.consignmentType === ConsignmentType.OWN_PAPER_MULTI_ADDRESS;
}

export function isOwnMultiPaperConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OWN_PAPER_MULTI_ADDRESS;
}

export function isOwnPersonalConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OWN_PAPER_ON_PLACE;
}

export function isOwnInternalPaperConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OWN_PAPER_INTERNAL;
}

export function isOwnInternalDigitalConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OWN_DIGITAL_INTERNAL;
}

export function isOwnInternalConsignment(c: Nullable<GenericOwnConsignment>) {
  return isOwnInternalPaperConsignment(c) || isOwnInternalDigitalConsignment(c);
}

export function isOwnDigitalConsignment(c: Nullable<GenericOwnConsignment>) {
  return isOwnDataboxConsignment(c) || isOwnEmailConsignment(c);
}

export function isOwnOfficeDeskConsignment(c: Nullable<GenericOwnConsignment>) {
  return c?.consignmentType === ConsignmentType.OFFICE_DESK;
}

export function isPartOfOwnMultiPaperConsignment(c: Nullable<GenericOwnConsignment>) {
  return !isNil((c as Nullable<OwnPaperConsignmentDto>)?.ownPaperMultiConsignmentId);
}

export function getDocumentIdFromConsignment(c: GenericOwnElasticConsignment | GenericOwnConsignment): number {
  return (c as OwnPaperConsignmentDto).documentId!;
}

export function openValidWithWarningHandoverConfirmationDialog(iczDialogService: DialogService) {
  return iczDialogService.openQuestionDialog(
    {
      title: 'Předat zásilku výpravně',
      question: `Opravdu chcete zásilku předat výpravně?`,
      description: 'Zásilku je možné vypravit ale obsahuje chyby.',
      leftButtonTitle: 'Předat',
    }
  );
}

@Injectable()
export class OwnConsignmentWorkflowService {

  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private prepareOrDispatchDialogService = inject(PrepareOrDispatchDialogService);
  private rejectDialogService = inject(RejectDialogService);
  private manualPostDialogService = inject(AbstractManualPostDialogService);
  private withdrawForCorrectionDialogService = inject(WithdrawForCorrectionDialogService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);

  private refreshMenuCountsOnWorkflowSuccess = () => tap(result => {
    if (result) {
      this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.OWN_CONSIGNMENT_COUNTS]);
    }
  });

  private getSaveObs$(selection: GenericOwnElasticConsignmentWithConsignee[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) {
    let saveObs$: Observable<GenericOwnElasticConsignmentWithConsignee>;
    if (opts.saveFirst && updateReq$) {
      saveObs$ = updateReq$.pipe(map(updatedConsignment => convertOwnConsignmentToOwnElasticConsignment(updatedConsignment as GenericOwnConsignment)));
    } else {
      saveObs$ = of(selection[0]);
    }
    return saveObs$;
  }

  workflowAction: Record<OwnConsignmentWorkflowAction, (selection: GenericOwnElasticConsignmentWithConsignee[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => Observable<any>> = {

    [OwnConsignmentWorkflowAction.TAKEOVER]: (selection: GenericOwnElasticConsignmentWithConsignee[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => {
      const isBulk = selection.length > 1;
      if (!isBulk) {
        return this.getSaveObs$(selection, opts, updateReq$).pipe(switchMap((updatedConsignment: GenericOwnElasticConsignmentWithConsignee) => {
          return this.consignmentTakeover(updatedConsignment).pipe(this.refreshMenuCountsOnWorkflowSuccess());
        }));
      } else {
        const bulkDto: OwnConsignmentTakeoverDto[] = selection.map(c => {
          return {id: c.id, consignmentType: c.consignmentType};
        });
        return this.apiOwnConsignmentService.ownConsignmentBulkTakeover({body: bulkDto});
      }
    },

    [OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION]: (selection: (OwnPaperConsignmentElasticDto | OwnDigitalConsignmentElasticDto)[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => {
      return this.getSaveObs$(selection, opts, updateReq$).pipe(switchMap((updatedConsignment: GenericOwnElasticConsignmentWithConsignee) => {
        const isBulk = selection.length > 1;
        const updatedSelection: (GenericOwnElasticConsignmentWithConsignee)[] = isBulk ? selection : [updatedConsignment];

        return this.prepareOrDispatchDialogService.openPrepareOrDispatchDialog(
            (updatedSelection[0]).documentId!,
            updatedSelection,
            PrepareOrDispatchDialogType.PREPARE_FOR_DISTRIBUTION,)
            .pipe(this.refreshMenuCountsOnWorkflowSuccess());
      }));
    },

    [OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION]: (selection: (OwnPaperConsignmentElasticDto | OwnDigitalConsignmentElasticDto)[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => {
      const isBulk = selection.length > 1;

      return this.getSaveObs$(selection, opts, updateReq$).pipe(switchMap((updatedConsignment: GenericOwnElasticConsignmentWithConsignee) => {
        if (isOwnPaperElasticConsignment(selection[0]) || isOwnInternalPaperElasticConsignment(selection[0]) || isOwnMultiPaperConsignment(selection[0])) {
          const updatedSelection: (GenericOwnElasticConsignmentWithConsignee)[] = isBulk ? selection : [updatedConsignment];

          return this.prepareOrDispatchDialogService.openPrepareOrDispatchDialog(
            (updatedSelection[0]).documentId!,
            updatedSelection,
            PrepareOrDispatchDialogType.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION)
            .pipe(this.refreshMenuCountsOnWorkflowSuccess());
        } else {
          // digital "takeover" is actually takeover and distribution in 1 step - physically is just sending of email or databox message
          this.globalLoadingService.startLoading(); // start global loading, it will end on observable finalize

          if (isBulk) {
            return this.apiOwnConsignmentService.ownConsignmentBulkTakeover({
              body: selection.map(s => {
                return {consignmentType: s.consignmentType, id: s.id};
              }),
            });
          } else {
            return this.consignmentTakeover(selection[0]).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          }
        }
      }));
    },

    [OwnConsignmentWorkflowAction.DISPATCH]: (selection: (OwnPaperConsignmentElasticDto | OwnDigitalConsignmentElasticDto)[]) => {
      return this.prepareOrDispatchDialogService.openPrepareOrDispatchDialog(selection[0].documentId!, selection, PrepareOrDispatchDialogType.MARK_AS_DISPATCH).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE]: (selection: (OwnPaperConsignmentElasticDto | OwnDigitalConsignmentElasticDto)[]) => {
      return this.apiOwnConsignmentService.ownConsignmentMarkNonEpoAsDispatched({consignmentId: selection[0].id}).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.POST]: (selection: (OwnOfficeDeskConsignmentElasticDto)[]) => {
      return this.manualPostDialogService.openManualPostDialog(selection[0].documentId!, selection[0]).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.UNPOST]: (selection: (OwnOfficeDeskConsignmentElasticDto)[]) => {
      return this.rejectDialogService.openRejectDialog(getDocumentIdFromConsignment(selection[0]), selection, RejectDialogType.UNPOST).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.REJECT]: (selection: (OwnPaperConsignmentElasticDto | OwnOfficeDeskConsignmentElasticDto)[]) => {
      return this.rejectDialogService.openRejectDialog(getDocumentIdFromConsignment(selection[0]), selection, RejectDialogType.REJECT,).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.WITHDRAW_FROM_DISTRIBUTION]: (selection: (OwnPaperConsignmentElasticDto | OwnDigitalConsignmentElasticDto)[]) => {
      return this.rejectDialogService.openRejectDialog(selection[0].documentId!, selection, RejectDialogType.WITHDRAW_FROM_DISTRIBUTION).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.WITHDRAW_FOR_CORRECTION]: (selection: (OwnPaperConsignmentElasticDto | OwnOfficeDeskConsignmentElasticDto)[]) => {
      return this.withdrawForCorrectionDialogService.openWithdrawForCorrectionDialog(getDocumentIdFromConsignment(selection[0]), selection).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.CANCEL]: (selection: (GenericOwnElasticConsignmentWithConsignee)[]) => {
      return this.rejectDialogService.openRejectDialog(getDocumentIdFromConsignment(selection[0]), selection, RejectDialogType.CANCEL).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.DELETE]: (selection: (GenericOwnElasticConsignmentWithConsignee)[]) => {
      return this.rejectDialogService.openRejectDialog(getDocumentIdFromConsignment(selection[0]), selection, RejectDialogType.DELETE).pipe(this.refreshMenuCountsOnWorkflowSuccess());
    },

    [OwnConsignmentWorkflowAction.HANDOVER]: (selection: (GenericOwnElasticConsignmentWithConsignee)[], opts: OwnConsignmentWorkflowActionOpts, updateReq$?: Observable<GenericUpdateOwnConsignment>) => {
      const isBulk = selection.length > 1;

      if (!isBulk) {
        const id = selection[0].id;

        return this.getSaveObs$(selection, opts, updateReq$).pipe(switchMap((consignment: GenericOwnElasticConsignmentWithConsignee) => {
          if (isOwnPaperElasticConsignment(consignment)) {
            return this.apiOwnConsignmentService.ownConsignmentHandoverPaper({
              id,
              body: {}
            }).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          } else if (isOwnDigitalElasticConsignment(consignment)) {
            return this.apiOwnConsignmentService.ownConsignmentHandoverDigital({id, body: {}})
              .pipe(this.refreshMenuCountsOnWorkflowSuccess());
          } else if (isOwnInternalPaperElasticConsignment(consignment)) {
            return this.apiOwnConsignmentService.ownConsignmentHandoverInternalPaper({
              id,
              body: {}
            }).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          } else if (isOwnInternalDigitalElasticConsignment(consignment)) {
            return this.apiOwnConsignmentService.ownConsignmentHandoverInternalDigital({
              id,
              body: {}
            }).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          } else {
            return this.apiOwnConsignmentService.ownConsignmentHandoverOfficeDesk({
              id,
              body: {}
            }).pipe(this.refreshMenuCountsOnWorkflowSuccess());
          }
        }));
      } else {
        const bulkDto: OwnConsignmentHandoverDto[] = selection.map(c => {
          return {id: c.id, consignmentType: c.consignmentType};
        });
        return this.apiOwnConsignmentService.ownConsignmentBulkHandover({body: bulkDto});
      }
    },
  };

  consignmentTakeover(consignment: GenericOwnElasticConsignment): Observable<any> {
    if (isOwnPaperElasticConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverPaper({id: consignment.id});
    }
    else if (isOwnMultiPaperElasticConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverMultiPaper({id: consignment.id});
    }
    else if (isOwnDigitalElasticConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverAndDispatchDigital({id: consignment.id});
    }
    else if (isOwnOfficeDeskElasticConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverOfficeDesk({id: consignment.id});
    }
    else if (isOwnInternalDigitalElasticConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverAndDispatchInternalDigital({id: consignment.id});
    }
    else if (isOwnInternalPaperElasticConsignment(consignment)) {
      return this.apiOwnConsignmentService.ownConsignmentTakeoverInternalPaper({id: consignment.id});
    }
    else {
      throw new Error('Wrong type of own consignment for takeover!');
    }
  }

}
