import {OperationValidator} from '../../../../services/bulk-operation-validation.service';
import {EsslObject} from '../../model/entity.model';
import {isDocumentEntity, isDocumentObject} from '../../shared-document.utils';
import {DocumentDto, FileDto, OwnDocumentDto, ReceivedDocumentDto} from '|api/document';
import {DocumentState, FileState} from '|api/commons';

export class CommonToolbarValidators {
  static isEsslObjectLocked(): OperationValidator<EsslObject> {
    return (object: EsslObject) => {
      return (!isNil(object.lockedState)) ?
        {validationMessage: 'Nad objektem běží operace a je zamčený.'} :
        null;
    };
  }

  static isFileOrDocumentSuspended(): OperationValidator<DocumentDto | FileDto> {
    return (object: DocumentDto | FileDto) => {
      if (isDocumentEntity(object.entityType)) {
        return ((object as DocumentDto).documentState === DocumentState.SUSPENDED) ?
            {validationMessage: 'Řízení dokumentu je přerušeno.'} :
            null;
      } else {
        return ((object as FileDto).fileState === FileState.SUSPENDED) ?
            {validationMessage: 'Řízení spisu je přerušeno.'} :
            null;
      }
    };
  }

  static isFileOrDocumentNotSuspended(): OperationValidator<DocumentDto | FileDto> {
    return (object: DocumentDto | FileDto) => {
      if (isDocumentEntity(object.entityType)) {
        return ((object as DocumentDto).documentState !== DocumentState.SUSPENDED) ?
            {validationMessage: 'Řízení dokumentu není přerušeno.'} :
            null;
      } else {
        return ((object as FileDto).fileState !== FileState.SUSPENDED) ?
            {validationMessage: 'Řízení spisu není přerušeno.'} :
            null;
      }
    };
  }

  static isFileOrDocumentInExternalApp(): OperationValidator<DocumentDto | FileDto> {
    return (object: DocumentDto | FileDto) => {
      if (!isNil(object.issdId)) {
        if (isDocumentEntity(object.entityType)) {
          return {validationMessage: 'Dokument je v držení externí aplikací.'};
        } else {
          return {validationMessage: 'Spis je v držení externí aplikací.'};
        }
      } else {
        return null;
      }
    };
  }

  static isEntityInRegistryOffice(): OperationValidator<DocumentDto | FileDto> {
    return (entity: DocumentDto | FileDto) => {
      return Boolean(entity.registryOfficeTransfer?.registryOfficeTransferState) ? {validationMessage: 'Entita již byla předána spisovně'} : null;
    };
  }

  static documentTypeSafeValidation(validator: (OperationValidator<DocumentDto> | OperationValidator<ReceivedDocumentDto> | OperationValidator<OwnDocumentDto>)) {
    return (entity: DocumentDto | FileDto) => {
      if (isDocumentObject(entity)) {
        return validator(entity as any);
      } else {
        return null;
      }
    };
  }
}
