import {Observable, of, pipe} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {CirculationTaskResolution, CirculationTaskState, CirculationTaskType} from '|api/commons';
import {ApiCirculationTaskSystemService} from '|api/flow';
import {CirculationSearchService} from '../../../services/circulation-search.service';
import {UnitViewSearchConfig} from '../../../services/search-api-with-elastic.service';
import {DocumentsTableColumn} from '../document-table/documents-table.columnsets';
import {CirculationTableColumn} from './circulation-table.columnsets';
import {
  ExtendedCirculationTaskDto,
  ExtendedCirculationTaskDtoWithFullBaseObject
} from '../model/elastic-extended-entities.interface';
import {EsslComponentSearchService} from '../../../services/essl-component-search.service';
import {fetchInFullComponents} from './circulation-datasources.common';
import {CurrentSessionService} from '../../../services';
import {FilterOperator, IczTableDataSource, Page, SearchParams} from '@icz/angular-table';


export const inProgressTaskResolutions = [
  CirculationTaskResolution.ASSIGNED,
  CirculationTaskResolution.IN_PROGRESS,
];

export const doneTaskResolutions = [
  CirculationTaskResolution.DONE_SUCCESS,
  CirculationTaskResolution.DONE_NEUTRAL,
  CirculationTaskResolution.DONE_FAILURE,
];

const RESOLUTION_FIELD_NAME = 'resolution';
const IS_MANAGEMENT_TASK_FIELD_NAME = 'isManagementTask';

export enum TaskViewType {
  IN_PROGRESS = 'IN_PROGRESS',
  RESOLVED = 'RESOLVED',
}

export function startAssignedTasks(
  currentSessionService: CurrentSessionService,
  searchService: CirculationSearchService,
  systemTaskManagementService: ApiCirculationTaskSystemService,
  viewsForStartingTasks: string[],
  searchParams: SearchParams,
  taskScreenView: string,
  unitView: UnitViewSearchConfig,
) {
  return pipe(
    switchMap((responseData: Page<ExtendedCirculationTaskDto>) => {
      if (viewsForStartingTasks.includes(taskScreenView)) {
        const taskIdsToStartProgress = responseData.content
        .filter(taskDto => (
          !taskDto.isManagementTask && // management tasks should not be STARTED BY SYSTEM
          (
            taskDto.taskState === CirculationTaskState.ASSIGNED_TO_INITIATOR ||
            taskDto.taskState === CirculationTaskState.ASSIGNED_TO_TARGET
          ))
        )
        .filter(taskDto => taskDto.resolution === CirculationTaskResolution.ASSIGNED)
        .filter(taskDto => unitView || (!unitView && taskDto.ownerFunctionalPositionId === currentSessionService.currentUserFunctionalPosition!.id))
        .map(taskDto => taskDto.id);

        if (taskIdsToStartProgress.length) {
          return systemTaskManagementService.circulationTaskSystemStartProgress({
            body: taskIdsToStartProgress,
          }).pipe(
            // todo remove that catchError -- it is used to mitigate startProgress error 400
            //  due to duplicate requests initiated from table component, its origins are unknown
            catchError(_ => of(null)),
            switchMap(_ => searchService.findTasksGlobally(searchParams, unitView).pipe(
              map(tasksPage => ({
                ...tasksPage,
                content: tasksPage.content.map(t => {
                  if (taskIdsToStartProgress.includes(t.id)) {
                    return {
                      ...t,
                      taskState: CirculationTaskState.IN_PROGRESS_BY_SYSTEM,
                    };
                  }
                  else {
                    return t;
                  }
                })
              }))
            )),
          );
        }
      }

      return of(responseData);
    })
  );
}

export class MyTasksDatasource extends IczTableDataSource<ExtendedCirculationTaskDtoWithFullBaseObject> {

  constructor(
    searchService: CirculationSearchService,
    systemTaskManagementService: ApiCirculationTaskSystemService,
    currentSessionService: CurrentSessionService,
    esslComponentSearchService: EsslComponentSearchService,
    taskScreenView: TaskViewType,
    unitView: UnitViewSearchConfig,
  ) {
    super(
      searchParams => {
        const hasOwnerFunctionalPositionFilter = searchParams.filter.find(f => f.fieldName === DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID);
        const hasTaskTypeFilter = searchParams.filter.find(f => f.fieldName === CirculationTableColumn.TASK_TYPE);
        const hasResolutionFilter = searchParams.filter.find(f => f.fieldName === RESOLUTION_FIELD_NAME);

        if (!hasOwnerFunctionalPositionFilter && !hasTaskTypeFilter) {
          if (!unitView.isUnitView) {
            searchParams.filter.push(
              {
                fieldName: DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
                operator: FilterOperator.equals,
                value: String(currentSessionService.currentUserFunctionalPosition!.id),
              },
            );
          }

          searchParams.filter.push(
            {
              fieldName: IS_MANAGEMENT_TASK_FIELD_NAME,
              operator: FilterOperator.equals,
              value: String(false),
            }
          );
        }

        if (!hasResolutionFilter) {
          if (taskScreenView === TaskViewType.IN_PROGRESS) {
            searchParams.filter.push(
              {
                fieldName: RESOLUTION_FIELD_NAME,
                operator: FilterOperator.inSet,
                value: String(inProgressTaskResolutions),
              },
            );
          }
          else if (taskScreenView === TaskViewType.RESOLVED) {
            searchParams.filter.push(
              {
                fieldName: RESOLUTION_FIELD_NAME,
                operator: FilterOperator.inSet,
                value: String(doneTaskResolutions),
              },
            );
          }
        }

        searchParams.filter.push(
          {
            fieldName: 'taskType',
            operator: FilterOperator.notEquals,
            value: CirculationTaskType.TAKEOVER_ISSD,
          },
        );

        return searchService.findTasksGlobally(searchParams, unitView).pipe(
          startAssignedTasks(
            currentSessionService,
            searchService,
            systemTaskManagementService,
            [TaskViewType.IN_PROGRESS],
            searchParams,
            taskScreenView,
            unitView,
          ),
          fetchInFullComponents(esslComponentSearchService),
        ) as Observable<Page<ExtendedCirculationTaskDtoWithFullBaseObject>>;
      },
    );
  }

}
