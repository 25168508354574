import {HttpErrorResponse} from '@angular/common/http';
import {DestroyRef, inject, Injectable, Type} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {from, mergeMap, Observable, of} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {FunctionalPositionDto} from '|api/auth-server';
import {CirculationActivityType, DigitalComponentVersionSignatureFlagType, EntityType, FileState} from '|api/commons';
import {
  AnalogComponentToDocumentReferenceCreateDto,
  ApiComponentService,
  ApiConversionService,
  ApiDigitalComponentService,
  ApiDigitalComponentVersionService,
  ApiMediumComponentService,
  ApiPaperComponentService,
  ApiPhysicalItemComponentService,
  ApiSignatureService,
  DigitalComponentCompleteDto,
  DigitalComponentDto,
  DigitalComponentReferenceCreateDto,
  DigitalComponentVersionCompleteDto,
  DigitalComponentVersionValidationResult,
  PaperComponentDto
} from '|api/component';
import {AuthorizedEntityType, ComponentAuthorizedOperation, DocumentAuthorizedOperation} from '|api/core';
import {ApiFileService, DocumentDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {
  DigitalComponentTemplateData,
  EsslComponentToastService,
  EsslComponentToastType
} from '../../../../core/services/notifications/essl-component-toast.service';
import {EsslComponentDto} from '../../../../services/essl-component-search.service';
import {DigitalComponentUpdateService} from './digital-component-update.service';
import {
  BinaryWithCharset,
  RemoteBinaryFileDownloadService
} from '../../../../services/remote-binary-file-download.service';
import {SignMarkTimestampService} from '../../../../services/sign-mark-timestamp.service';
import {LocalBinaryFileDownloadService} from '../../../../services/local-binary-file-download.service';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {ApplicationConfigService} from '../../../../core/services/config/application-config.service';
import {
  CommunicationErrorDialogService,
  EsslErrorResponseDto
} from '../../../../core/services/communication-error-dialog.service';
import {
  EsslComponentCirculationToastService,
  EsslComponentCirculationToastType
} from '../../../../core/services/notifications/essl-component-circulation-toast.service';
import {DigitalComponentSignatureDialogService} from './digital-component-signature-dialog.service';
import {CommonToolbarDisablers} from '../../document-toolbar/services/toolbar-common.disablers';
import {EsslComponentToolbarDisablers} from './essl-component-toolbar.disablers';
import {
  DigitalComponentUploadDialogComponent,
} from '../components/digital-component-upload-dialog/digital-component-upload-dialog.component';
import {EsslAnalogComponentType} from '../../analog-component-create-form/analog-component-create-form.component';
import {getLatestDigitalComponentVersion, isReceivedDocumentObject} from '../../shared-document.utils';
import {SKIP_ERROR_DIALOG} from '../../../../core/error-handling/http-errors';
import {
  AuthorizedConversionAnalogToDigitalDialogComponent
} from '../components/authorized-conversion-analog-to-digital-dialog/authorized-conversion-analog-to-digital-dialog.component';
import {
  DigitalComponentMarkTimestampDialogComponent
} from '../components/digital-component-mark-timestamp-dialog/digital-component-mark-timestamp-dialog.component';
import {
  DigitalComponentPreMarkExtendValidationDialogComponent,
  PreMarkExtendValidationDialogData
} from '../components/digital-component-pre-mark-extend-validation-dialog/digital-component-pre-mark-extend-validation-dialog.component';
import {HandForSigningDialogComponent} from '../components/hand-for-signing-dialog/hand-for-signing-dialog.component';
import {
  HandComponentForApprovalDialogComponent
} from '../components/hand-component-for-approval-dialog/hand-component-for-approval-dialog.component';
import {
  AnalogComponentCreateDialogComponent,
  EsslAnalogComponentModalData
} from '../components/analog-component-create-dialog/analog-component-create-dialog.component';
import {
  AbstractHandoverFormModalComponent
} from '../../document-toolbar/components/abstract-handover-form-modal.component';
import {DocumentView, openGeneralCirculationModal} from '../../document-toolbar/services/toolbar-common.utils';
import {
  BulkOperationValidationService,
  OperationValidator
} from '../../../../services/bulk-operation-validation.service';
import {GeneralAuthorizedOperation} from '../../permissions/permissions.utils';
import {EsslComponentOperationValidators} from './essl-component-operation-validators';
import {
  ConversionAnalogToDigitalModalData,
  ConversionAnalogToDigitalType,
  DigitalComponentUploadDialogData,
  DigitalComponentUploadDialogResult,
  DigitalComponentUploadDialogType
} from '../models/digital-components.model';
import {ApiCirculationActivityBulkActionsService, ApiCirculationActivityService} from '|api/flow';
import {
  ExecutionDateClauseCreateDialogComponent,
  ExecutionDateClauseCreateDialogData
} from '../components/execution-date-clause-create-dialog/execution-date-clause-create-dialog.component';
import {DocumentToolbarDisablers} from '../../document-toolbar/services/document-toolbar-buttons.disablers';
import {
  InternalNotificationBulkComponentActionMessageCode,
  InternalNotificationMessageCode
} from '../../../../core/services/notifications/internal-notification.enum';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {
  DigitalComponentFromTemplateDialogComponent,
  DigitalComponentFromTemplateDialogResult
} from '../components/digital-component-from-template-dialog/digital-component-from-template-dialog.component';
import {
  EsslComponentsTakeoverFromDocumentDialogComponent,
  EsslComponentsTakeoverFromDocumentDialogData
} from '../components/essl-components-takeover-from-document-dialog/essl-components-takeover-from-document-dialog.component';
import {AllowedFormatsDto, DeliveryTypeDto} from '|api/codebook';
import {
  UpdateComponentTemplateDialogComponent
} from '../components/update-component-template-dialog/update-component-template-dialog.component';
import {
  DigitalComponentAnonymizationDialogComponent,
  DigitalComponentAnonymizationDialogData
} from '../components/digital-component-anonymization-dialog/digital-component-anonymization-dialog.component';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {GlobalLoadingIndicatorService, LoadingIndicatorService} from '@icz/angular-essentials';
import {DialogService, IczModalService} from '@icz/angular-modal';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {Button} from '../../../button-collection/button-collection.component';
import {
  VerifyConversionClauseDialogComponent
} from '../components/verify-conversion-clause-dialog/verify-conversion-clause-dialog.component';
import {esslErrorDtoToToastParameters} from '../../../notifications/toast.service';
import {SignatureFlowType} from '../models/signature-dialog.model';


export enum DigitalComponentView {
  DIGITAL_COMPONENT_VIEWER = 'DIGITAL_COMPONENT_VIEWER',
  DIGITAL_COMPONENT_LISTING = 'DIGITAL_COMPONENT_LISTING',
}

export interface DigitalComponentToolbarContext {
  view: DigitalComponentView;
  parentDocument: DocumentDto;
  deliveryType?: DeliveryTypeDto;
  digitalComponentVersionId?: number;
  anonymizationAllowedFormats?: AllowedFormatsDto;
}


export enum DigitalComponentOperationType {
  COMPONENT_ADDED = 'COMPONENT_ADDED',
  COMPONENT_VERSION_ADDED = 'COMPONENT_VERSION_ADDED',
  DIGITAL_RENDITION_ADDED = 'DIGITAL_RENDITION_ADDED',
  COMPONENT_CHECKED_OUT = 'COMPONENT_CHECKED_OUT',
  COMPONENT_CHECKED_IN = 'COMPONENT_CHECKED_IN',
  COMPONENT_LOCKED = 'COMPONENT_LOCKED',
  COMPONENT_UNLOCKED = 'COMPONENT_UNLOCKED',
  OUTPUT_FORMAT_CONVERSION = 'OUTPUT_FORMAT_CONVERSION',
  CONVERSION_WITH_CLAUSE = 'CONVERSION_WITH_CLAUSE',
  AUTHORIZED_CONVERSION_STARTED = 'AUTHORIZED_CONVERSION_STARTED',
  VERIFIED_CONVERSION_STARTED = 'VERIFIED_CONVERSION_STARTED',
  ADD_TIMESTAMP = 'ADD_TIMESTAMP',
  MARK_DIGITAL_COMPONENT = 'MARK_DIGITAL_COMPONENT',
  SIGN_DIGITAL_COMPONENT = 'SIGN_DIGITAL_COMPONENT',
  MANUAL_DIGITAL_COMPONENT_VALIDATION = 'MANUAL_DIGITAL_COMPONENT_VALIDATION',
  DOWNLOAD = 'DOWNLOAD',
  CIRCULATION_STARTED = 'CIRCULATION_STARTED',
  CIRCULATION_ACTION_COMPLETED = 'CIRCULATION_ACTION_COMPLETED',
  COMPONENTS_DELETED = 'COMPONENTS_DELETED',
  COMPONENT_DELETED = 'COMPONENT_DELETED',
  FORM_FILLER_CLAUSE_SUCCESS = 'FORM_FILLER_CLAUSE_SUCCESS',
  EXECUTION_CLAUSE_CREATED = 'EXECUTION_CLAUSE_CREATED',
  EXTRACTION = 'EXTRACTION',
  ANONYMIZATION = 'ANONYMIZATION',
  NATIVE_EDIT = 'NATIVE_EDIT',
  COMPONENT_TEMPLATE_UPDATED = 'COMPONENT_TEMPLATE_UPDATED'
}

export interface DigitalComponentOperationResult {
  operationType: DigitalComponentOperationType;
  selectedDigitalComponents: Nullable<EsslComponentDto>;
  addedComponentCount: Nullable<number>;
  removedComponentCount: Nullable<number>;
}

export enum EsslComponentErrorCode {
  ACTION_ON_SETTLED_DOCUMENT = 'alreadySettled',
  INVALID_INPUT_FORMAT = 'inputFormatNotAllowed',
  INVALID_OUTPUT_FORMAT = 'outputFormatNotAllowed',
  CONVERSION_RUNNING = 'conversionRunning',
}

export enum EsslComponentActionType {
  CONVERSION = 'CONVERSION',
  CONVERSION_WITH_CLAUSE = 'CONVERSION_WITH_CLAUSE',
  MARK = 'MARK',
  EXTEND = 'EXTEND',
  COMPONENT_CREATE_DIGITAL_COMPONENT_VERSION = 'COMPONENT_CREATE_DIGITAL_COMPONENT_VERSION',
  COMPONENT_PERFORM_AUTHORIZED_CONVERSION_LE = 'COMPONENT_PERFORM_AUTHORIZED_CONVERSION_LE',
  COMPONENT_PERFORM_AUTHORIZED_CONVERSION_EL = 'COMPONENT_PERFORM_AUTHORIZED_CONVERSION_EL',
  COMPONENT_SIGN = 'COMPONENT_SIGN',
  COMPONENT_SEAL = 'COMPONENT_SEAL',
  COMPONENT_ADD_TIMESTAMP = 'COMPONENT_ADD_TIMESTAMP',
  COMPONENT_VERIFY_SIGNATURE = 'COMPONENT_VERIFY_SIGNATURE',
  COMPONENT_DOWNLOAD = 'COMPONENT_DOWNLOAD',
  COMPONENT_LOCK = 'COMPONENT_LOCK',
  COMPONENT_UNLOCK = 'COMPONENT_UNLOCK',
  COMPONENT_DELETE = 'COMPONENT_DELETE',
  CONTAINER_EXTRACT = 'CONTAINER_EXTRACT',
}

export const esslComponentErrorToastTypeMapping: Record<EsslComponentActionType, Partial<Record<EsslComponentErrorCode, EsslComponentToastType>>> = {
  [EsslComponentActionType.CONVERSION]: {
    [EsslComponentErrorCode.ACTION_ON_SETTLED_DOCUMENT]: EsslComponentToastType.CONVERSION_ON_SETTLED_DOCUMENT_ERROR,
    [EsslComponentErrorCode.CONVERSION_RUNNING]: EsslComponentToastType.CONVERSION_WHILE_CONVERSION_RUNNING,
    [EsslComponentErrorCode.INVALID_OUTPUT_FORMAT]: EsslComponentToastType.CONVERSION_INVALID_INPUT_FORMAT_ERROR,
  },
  [EsslComponentActionType.CONVERSION_WITH_CLAUSE]: {
    [EsslComponentErrorCode.ACTION_ON_SETTLED_DOCUMENT]: EsslComponentToastType.CONVERSION_WITH_CLAUSE_ON_SETTLED_DOCUMENT_ERROR,
    [EsslComponentErrorCode.CONVERSION_RUNNING]: EsslComponentToastType.CONVERSION_WITH_CLAUSE_WHILE_CONVERSION_RUNNING,
    [EsslComponentErrorCode.INVALID_OUTPUT_FORMAT]: EsslComponentToastType.CONVERSION_WITH_CLAUSE_INVALID_INPUT_FORMAT_ERROR,
  },
  [EsslComponentActionType.MARK]: {
    [EsslComponentErrorCode.ACTION_ON_SETTLED_DOCUMENT]: EsslComponentToastType.MARK_ON_SETTLED_DOCUMENT_ERROR,
    [EsslComponentErrorCode.CONVERSION_RUNNING]: EsslComponentToastType.MARK_WHILE_CONVERSION_RUNNING,
    [EsslComponentErrorCode.INVALID_INPUT_FORMAT]: EsslComponentToastType.MARK_INVALID_INPUT_FORMAT_ERROR,
  },
  [EsslComponentActionType.EXTEND]: {
    [EsslComponentErrorCode.ACTION_ON_SETTLED_DOCUMENT]: EsslComponentToastType.EXTEND_ON_SETTLED_DOCUMENT_ERROR,
    [EsslComponentErrorCode.CONVERSION_RUNNING]: EsslComponentToastType.EXTEND_WHILE_CONVERSION_RUNNING,
    [EsslComponentErrorCode.INVALID_INPUT_FORMAT]: EsslComponentToastType.EXTEND_INVALID_INPUT_FORMAT_ERROR,
  },
  [EsslComponentActionType.COMPONENT_CREATE_DIGITAL_COMPONENT_VERSION]: {},
  [EsslComponentActionType.COMPONENT_PERFORM_AUTHORIZED_CONVERSION_LE]: {},
  [EsslComponentActionType.COMPONENT_PERFORM_AUTHORIZED_CONVERSION_EL]: {},
  [EsslComponentActionType.COMPONENT_SIGN]: {},
  [EsslComponentActionType.COMPONENT_SEAL]: {},
  [EsslComponentActionType.COMPONENT_ADD_TIMESTAMP]: {},
  [EsslComponentActionType.COMPONENT_VERIFY_SIGNATURE]: {},
  [EsslComponentActionType.COMPONENT_DOWNLOAD]: {},
  [EsslComponentActionType.COMPONENT_LOCK]: {},
  [EsslComponentActionType.COMPONENT_UNLOCK]: {},
  [EsslComponentActionType.COMPONENT_DELETE]: {},
  [EsslComponentActionType.CONTAINER_EXTRACT]: {},
};

interface BinaryForDownload {
  binary: BinaryWithCharset;
  fileName: string;
}


@Injectable()
export class EsslComponentToolbarButtonsService extends ToolbarProvider<EsslComponentDto, DigitalComponentOperationResult, DigitalComponentToolbarContext> {

  private loadingIndicatorService = inject(LoadingIndicatorService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private digitalComponentFileUploadService = inject(DigitalComponentUpdateService);
  private apiPaperComponentService = inject(ApiPaperComponentService);
  private apiMediumComponentService = inject(ApiMediumComponentService);
  private apiPhysicalItemComponentService = inject(ApiPhysicalItemComponentService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private signMarkTimestampService = inject(SignMarkTimestampService);
  private apiConversionService = inject(ApiConversionService);
  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private esslComponentToastService = inject(EsslComponentToastService);
  private localBinaryFileDownloadService = inject(LocalBinaryFileDownloadService);
  private modalService = inject(IczModalService);
  private currentSessionService = inject(CurrentSessionService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private translateService = inject(TranslateService);
  private apiSignatureService = inject(ApiSignatureService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private applicationConfigService = inject(ApplicationConfigService);
  private communicationErrorDialogService = inject(CommunicationErrorDialogService);
  private esslComponentCirculationToastService = inject(EsslComponentCirculationToastService);
  private digitalComponentSignatureDialogService = inject(DigitalComponentSignatureDialogService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private destroyRef = inject(DestroyRef);
  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private apiCirculationActivityBulkActionsService = inject(ApiCirculationActivityBulkActionsService);
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private apiFileService = inject(ApiFileService);
  private dialogService = inject(DialogService);
  private apiComponentService = inject(ApiComponentService);
  private wsNotificationService = inject(WebSocketNotificationsService);

  private document!: DocumentDto;
  private deliveryType: Nullable<DeliveryTypeDto>;
  private currentUserFP!: FunctionalPositionDto;

  constructor() {
    super();

    this.currentSessionService.currentUserFunctionalPosition$.pipe(
      takeUntilDestroyed(this.destroyRef)).subscribe(fp => this.currentUserFP = fp!);

    this.wsNotificationService.getMessagesListener$([
      InternalNotificationBulkComponentActionMessageCode.CONVERSION_BULK_SUCCESS,
      InternalNotificationBulkComponentActionMessageCode.CONVERSION_BULK_ERROR,
      InternalNotificationBulkComponentActionMessageCode.EXTRACTION_BULK_SUCCESS,
      InternalNotificationBulkComponentActionMessageCode.EXTRACTION_BULK_ERROR,
      InternalNotificationBulkComponentActionMessageCode.CHANGE_WITH_CLAUSE_BULK_SUCCESS,
      InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_LOCK_SUCCESS,
      InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_UNLOCK_SUCCESS,
      InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_DELETE_SUCCESS,
      InternalNotificationMessageCode.EXECUTION_DATE_CLAUSE_CREATE_SUCCESS,
      InternalNotificationMessageCode.COMPONENT_ANONYMIZATION_COMPLETED,
      InternalNotificationMessageCode.COMPONENT_ANONYMIZATION_CANCELED,
      InternalNotificationMessageCode.COMPONENT_NATIVE_EDIT_COMPLETED,
      InternalNotificationMessageCode.COMPONENT_NATIVE_EDIT_CANCELED,
      InternalNotificationMessageCode.FORM_FILLER_CLAUSE_SUCCESS
    ])
      .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(message => {
      if (message.messageCode === InternalNotificationBulkComponentActionMessageCode.CONVERSION_BULK_SUCCESS || message.messageCode === InternalNotificationBulkComponentActionMessageCode.CONVERSION_BULK_ERROR) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationBulkComponentActionMessageCode.EXTRACTION_BULK_SUCCESS || message.messageCode === InternalNotificationBulkComponentActionMessageCode.EXTRACTION_BULK_ERROR) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationBulkComponentActionMessageCode.CHANGE_WITH_CLAUSE_BULK_SUCCESS) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.CONVERSION_WITH_CLAUSE, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_LOCK_SUCCESS) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.COMPONENT_LOCKED, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_UNLOCK_SUCCESS) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.COMPONENT_UNLOCKED, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_DELETE_SUCCESS) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.COMPONENTS_DELETED, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationMessageCode.FORM_FILLER_CLAUSE_SUCCESS) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.FORM_FILLER_CLAUSE_SUCCESS, addedComponentCount: 1, selectedDigitalComponents: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationMessageCode.EXECUTION_DATE_CLAUSE_CREATE_SUCCESS) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationMessageCode.COMPONENT_ANONYMIZATION_COMPLETED || message.messageCode === InternalNotificationMessageCode.COMPONENT_ANONYMIZATION_CANCELED) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.ANONYMIZATION, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      } else if (message.messageCode === InternalNotificationMessageCode.COMPONENT_NATIVE_EDIT_COMPLETED || message.messageCode === InternalNotificationMessageCode.COMPONENT_NATIVE_EDIT_CANCELED) {
        this.announceActionCompleted({operationType: DigitalComponentOperationType.NATIVE_EDIT, selectedDigitalComponents: null, addedComponentCount: null, removedComponentCount: null});
      }
    });
  }

  getToolbarButtons(selection: EsslComponentDto[], toolbarContext: DigitalComponentToolbarContext): Observable<Button[]> {
    this.document = toolbarContext.parentDocument;
    this.deliveryType = toolbarContext.deliveryType;

    const isSelectedOneEsslComponent = selection?.length === 1;
    const isSelectedOneDigitalComponent = isSelectedOneEsslComponent && selection![0].entityType === EntityType.DIGITAL_COMPONENT;
    const isSelectedOnePaperComponent = isSelectedOneEsslComponent && selection![0].entityType === EntityType.PAPER_COMPONENT;
    const singleEntity = (selection as unknown as DigitalComponentCompleteDto[])[0];
    const parentDocumentId = (this.document ? this.document.id : singleEntity?.documentId);

    const buttonsObs: Observable<AuthorizedButton[]> = of([
      {
        label: 'Přidat naskenovaný obsah',
        show: toolbarContext.view === DigitalComponentView.DIGITAL_COMPONENT_VIEWER && isSelectedOnePaperComponent,
        icon: 'add_new',
        authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_DIGITIZE_ANALOG_COMPONENT],
        buttonDisablers: [
          CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
          EsslComponentToolbarDisablers.someComponentsAreNotPaper(selection),
        ],
        action: () => this.onAddDigitalRenditionToPaperClick(selection[0] as PaperComponentDto),
      },
      {
        label: 'Přidat',
        icon: 'add_new',
        show: toolbarContext.view === DigitalComponentView.DIGITAL_COMPONENT_LISTING,
        buttonDisablers: [
          EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
          EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
        ],
        submenuItems: [
          {
            label: 'Přidat digitální komponenty',
            authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_DIGITAL_COMPONENT],
            buttonDisablers: [
              EsslComponentToolbarDisablers.receivedConsignmentTypeDisallowsAdditionalDigitalComponents(this.document, this.deliveryType!),
            ],
            action: () => this.onNewDigitalComponentsClick(),
          },
          {
            label: 'Nová verze digitální komponenty',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CREATE_DIGITAL_COMPONENT_VERSION],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.someComponentsAreFinal(selection),
            ],
            action: () => this.checkComponentSignatureInvalidationAndRunAction(
              selection as DigitalComponentDto[],
              this.onNewDigitalComponentVersionClick.bind(this)
            ),
          },
          {
            label: 'Přidat listinné komponenty',
            authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_PAPER_COMPONENT],
            buttonDisablers: [
              EsslComponentToolbarDisablers.receivedConsignmentTypeDisallowsAdditionalAnalogComponents(this.document, this.deliveryType!),
            ],
            action: () => this.onNewPaperComponentsClick(),
          },
          {
            label: 'Přidat naskenovaný obsah',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_DIGITIZE_ANALOG_COMPONENT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotPaper(selection),
            ],
            action: () => this.onAddDigitalRenditionToPaperClick(selection[0] as PaperComponentDto),
          },
          {
            label: 'Přidat nosič',
            authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_MEDIUM_COMPONENT],
            buttonDisablers: [
              EsslComponentToolbarDisablers.receivedConsignmentTypeDisallowsAdditionalAnalogComponents(this.document, this.deliveryType!),
            ],
            action: () => this.onNewMediumComponentClick(),
          },
          {
            label: 'Přidat předmět',
            authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_PHYSICAL_ITEM_COMPONENT],
            buttonDisablers: [
              EsslComponentToolbarDisablers.receivedConsignmentTypeDisallowsAdditionalAnalogComponents(this.document, this.deliveryType!),
            ],
            action: () => this.onNewPhysicalItemComponentClick(),
          },
          {
            label: 'Založení komponenty ze šablony',
            authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_DIGITAL_COMPONENT],
            buttonDisablers: [
              EsslComponentToolbarDisablers.isParentDocumentReceived(this.document),
            ],
            action: () => this.openDigitalComponentCreateFromTemplateModal(),
          },
          {
            label: 'Převzít komponenty z jiného dokumentu',
            authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_DIGITAL_COMPONENT],
            buttonDisablers: [
              EsslComponentToolbarDisablers.isParentDocumentReceived(this.document),
            ],
            action: () => this.openDigitalComponentTakeoverFromDocumentModal(),
          },
          {
            label: 'Zduplikovat komponentu',
            authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_ADD_DIGITAL_COMPONENT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.isParentDocumentReceived(this.document),
            ],
            action: () => this.openDigitalComponentDuplicateFromDocumentModal(selection as DigitalComponentCompleteDto[]),
          },
        ]
      },
      {
        label: 'Konverze',
        icon: 'conversion_menu',
        show: toolbarContext.view !== DigitalComponentView.DIGITAL_COMPONENT_VIEWER || (toolbarContext.view === DigitalComponentView.DIGITAL_COMPONENT_VIEWER && isSelectedOneDigitalComponent),
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
          EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
          EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
        ],
        submenuItems: [
          {
            label: 'Převod do výstupního formátu',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CONVERT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreFinal(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.anotherAsyncOperationIsRunning(selection),
              EsslComponentToolbarDisablers.isNotLastComponentVersionInViewer(selection, toolbarContext.digitalComponentVersionId),
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.CONVERSION,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentIsFinal(),
                  EsslComponentOperationValidators.anotherAsyncOperationIsRunning(),
                  EsslComponentOperationValidators.componentIsNotDigital(),
                  EsslComponentOperationValidators.componentIsLocked(),
                ],
                true
              )
          },
          {
            label: 'Převod do výstupního formátu s doložkou',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CONVERT_WITH_CLAUSE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.anotherAsyncOperationIsRunning(selection),
              EsslComponentToolbarDisablers.isNotLastComponentVersionInViewer(selection, toolbarContext.digitalComponentVersionId),
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.CONVERSION_WITH_CLAUSE,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentIsFinal(),
                  EsslComponentOperationValidators.anotherAsyncOperationIsRunning(),
                  EsslComponentOperationValidators.componentIsNotDigital(),
                  EsslComponentOperationValidators.componentIsLocked(),
                ],
                true
              )
          },
          {
            label: 'Autorizovaná konverze z moci úřední L > E',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_PERFORM_AUTHORIZED_CONVERSION_LE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotPaper(selection),
              EsslComponentToolbarDisablers.someComponentsDontHaveDigitalRendition(selection as PaperComponentDto[]),
              EsslComponentToolbarDisablers.someComponentsAreNotInAllowedFormatForAuthConversionLE(selection as PaperComponentDto[]),
              EsslComponentToolbarDisablers.someComponentsAreNotFinal(selection),
              EsslComponentToolbarDisablers.isNotLastComponentVersionInViewer(selection, toolbarContext.digitalComponentVersionId),
            ],
            action: () => this.onAuthorizedConvertToDigital(selection as PaperComponentDto[]),
          },
          {
            label: 'Převod z listinné do elektronické podoby',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CONVERT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreNotPaper(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotFinal(selection),
              EsslComponentToolbarDisablers.someComponentsDontHaveDigitalRendition(selection as PaperComponentDto[]),
              EsslComponentToolbarDisablers.someComponentsAreNotInAllowedFormatForAuthConversionLE(selection as PaperComponentDto[]),
              EsslComponentToolbarDisablers.isNotLastComponentVersionInViewer(selection, toolbarContext.digitalComponentVersionId),
            ],
            action: () => this.onVerifiedConversionToDigital(selection as PaperComponentDto[]),
          },
          {
            label: 'Převod z elektronické do listinné podoby',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CONVERT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotFinal(selection),
              EsslComponentToolbarDisablers.componentsAreNotPdf(selection as DigitalComponentDto[]),
              EsslComponentToolbarDisablers.isNotLastComponentVersionInViewer(selection, toolbarContext.digitalComponentVersionId),
            ],
            action: () => this.checkComponentSignatureInvalidationAndRunAction(selection as DigitalComponentDto[], this.onVerifiedConversionToAnalog.bind(this)),
          },
          {
            label: 'Anonymizace',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_ANONYMIZATION],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.someComponentsAreInConversion(selection as DigitalComponentDto[]),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              CommonToolbarDisablers.isFileOrDocumentInExternalApp([this.document]),
              CommonToolbarDisablers.isEntityInRegistryOffice([this.document]),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotInAllowedFormat(selection as DigitalComponentDto[], toolbarContext.anonymizationAllowedFormats?.allowedFormats ?? []),
            ],
            action: () => this.onComponentAnonymizationClick(selection as DigitalComponentDto[], toolbarContext.digitalComponentVersionId),
          },
          {
            label: 'Ověření doložky KZMÚ',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_PERFORM_AUTHORIZED_CONVERSION_LE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.someComponentsDontHaveAuthConversion(selection),
            ],
            action: () => this.onVerifyConversionClause(selection),
          },
          {
            label: 'Generovat doložku právní moci',
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              DocumentToolbarDisablers.isDocumentNotInFile([this.document], DocumentView.DOCUMENT_DETAIL),
              DocumentToolbarDisablers.isNotOwnDocument([this.document]),
              DocumentToolbarDisablers.hasExecutionDate([this.document]),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.componentsAreNotPdf(selection as DigitalComponentDto[]),
              EsslComponentToolbarDisablers.someComponentsAreNotFinal(selection),
              EsslComponentToolbarDisablers.anotherAsyncOperationIsRunning(selection),
            ],
            action: () => this.openExecutionDateClauseCreateModal(selection as DigitalComponentDto[]),
          },
          {
            label: 'Vytěžit kontejner',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_EXTRACT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.anotherAsyncOperationIsRunning(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.someComponentsAreAlreadyExtracted(selection as DigitalComponentDto[]),
              EsslComponentToolbarDisablers.someComponentsAreNotContainers(selection as DigitalComponentDto[]),
            ],
            action: button => this.onEsslComponentActionClick(
              selection,
              EsslComponentActionType.CONTAINER_EXTRACT,
              button.authorizedOperations!,
              [
                EsslComponentOperationValidators.componentIsNotDigital(),
                EsslComponentOperationValidators.anotherAsyncOperationIsRunning(),
                EsslComponentOperationValidators.componentIsAlreadyExtracted() as OperationValidator<EsslComponentDto>,
                EsslComponentOperationValidators.componentIsNotContainer() as OperationValidator<EsslComponentDto>,
              ],
              false
            )
          },
        ],
      },
      {
        label: 'Podepisování',
        icon: 'signature',
        show: toolbarContext.view !== DigitalComponentView.DIGITAL_COMPONENT_VIEWER || (toolbarContext.view === DigitalComponentView.DIGITAL_COMPONENT_VIEWER && isSelectedOneDigitalComponent),
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
          EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
          EsslComponentToolbarDisablers.someComponentsAreFinal(selection),
        ],
        submenuItems: [
          {
            label: 'Dát k podpisu',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CREATE_SIGNING_ACTIVITY],
            show: this.applicationConfigService.allowComponentHandForSigning,
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
              EsslComponentToolbarDisablers.someComponentsAreFinal(selection),
            ],
            action: button => this.onCirculationOperationClick(
              'Dát k podpisu - {{componentName}}',
              'Hromadné předání ({{count}}) komponent k podpisu',
              HandForSigningDialogComponent,
              CirculationActivityType.COMPONENT_SIGNING,
              'Některé komponenty ({{count}}) není možno dát k podpisu.',
              selection,
              [
                EsslComponentOperationValidators.componentIsLocked(),
                EsslComponentOperationValidators.componentIsFinal(),
              ],
              button.authorizedOperations
            ),
          },
          {
            label: 'Dát ke schválení',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CREATE_APPROVAL_ACTIVITY],
            show: this.applicationConfigService.allowComponentHandForApproval,
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
              EsslComponentToolbarDisablers.someComponentsAreFinal(selection),
            ],
            action: button => this.onCirculationOperationClick(
              'Dát komponentu ke schválení - {{componentName}}',
              'Hromadné předání ({{count}}) komponent ke schválení',
              HandComponentForApprovalDialogComponent,
              CirculationActivityType.COMPONENT_APPROVAL,
              'Některé komponenty ({{errorCount}}) není možno dát ke schválení. Hromadná operace bude pokračovat s vyhovujícími komponentami ({{successCount}}).',
              selection,
              [
                EsslComponentOperationValidators.componentIsLocked(),
                EsslComponentOperationValidators.componentIsFinal(),
              ],
              button.authorizedOperations
            ),
          },
          {
            label: 'Podepsat',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_SIGN],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.COMPONENT_SIGN,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentIsNotDigital(),
                  EsslComponentOperationValidators.componentIsLocked(),
                ],
                false
              )
          },
          {
            label: 'Pečetit',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_SEAL],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.COMPONENT_SEAL,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentIsNotDigital(),
                  EsslComponentOperationValidators.componentIsLocked(),
                ],
                false
              )
          },
          {
            label: 'Přidat časové razítko',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_ADD_TIMESTAMP],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.COMPONENT_ADD_TIMESTAMP,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentIsNotDigital(),
                  EsslComponentOperationValidators.componentIsLocked(),
                ],
                false
              )
          },
          {
            label: 'Znovuověřit podpis/pečeť',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_VERIFY_SIGNATURE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.COMPONENT_VERIFY_SIGNATURE,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentIsNotDigital(),
                  EsslComponentOperationValidators.componentIsLocked(),
                ],
                false
              )
          },
        ],
      },
      {
        label: 'Stáhnout',
        icon: 'download',
        show: toolbarContext.view !== DigitalComponentView.DIGITAL_COMPONENT_VIEWER || (toolbarContext.view === DigitalComponentView.DIGITAL_COMPONENT_VIEWER && isSelectedOneDigitalComponent),
        authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_DOWNLOAD],
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
          EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
        ],
        action: button =>
          this.onEsslComponentActionClick(
            selection,
            EsslComponentActionType.COMPONENT_DOWNLOAD,
            button.authorizedOperations!,
            [
              EsslComponentOperationValidators.componentIsNotDigital(),
            ],
            false,
            toolbarContext
          )
      },
      {
        label: 'Více',
        icon: 'more',
        submenuItems: [
          {
            label: 'Vrátit k předchozí verzi',
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
            ],
            isTestingFeature: true,
          },
          {
            label: 'Změnit pořadí',
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
            ],
            isTestingFeature: true,
          },
          {
            label: 'Vyjmout k editaci',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CHECKOUT],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
            ],
            action: () => this.checkOutDigitalComponent(selection as DigitalComponentDto[]),
          },
          {
            label: 'Vložit po editaci',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CHECKIN],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreNotLocked(selection),
              EsslComponentToolbarDisablers.isUnlockPreconditionNotMet(
                selection,
                this.currentUserFP,
                this.organizationalStructureService,
                this.document,
              )
            ],
            action: () => this.checkInDigitalComponent(selection as DigitalComponentDto[]),
          },
          {
            label: 'Aktualizovat parametry šablony',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_CREATE_DIGITAL_COMPONENT_VERSION],
            buttonDisablers: [
              CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreFinal(selection),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotFromTemplate(selection as DigitalComponentCompleteDto[]),
            ],
            action: () => this.onUpdateComponentTemplateClick(selection as DigitalComponentDto[]),
          },
          {
            label: 'Uzamknout',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_LOCK],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreLocked(selection),
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.COMPONENT_LOCK,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentIsNotDigital(),
                  EsslComponentOperationValidators.componentIsLocked(),
                ],
                false
              )
          },
          {
            label: 'Odemknout',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_UNLOCK],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.someComponentsAreNotDigital(selection),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsAreNotLocked(selection),
              EsslComponentToolbarDisablers.isUnlockPreconditionNotMet(
                selection,
                this.currentUserFP,
                this.organizationalStructureService,
                this.document,
              )
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.COMPONENT_UNLOCK,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentIsNotDigital(),
                  EsslComponentOperationValidators.componentIsNotLocked(),
                  EsslComponentOperationValidators.isUnlockPreconditionNotMet(this.currentUserFP, this.organizationalStructureService, this.document),
                ],
                false
              )
          },
          {
            label: 'Smazat komponentu',
            authorizedOperations: [ComponentAuthorizedOperation.COMPONENT_DELETE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              EsslComponentToolbarDisablers.isParentDocumentDeactivated(this.document),
              EsslComponentToolbarDisablers.isParentDocumentSettled(this.document),
              EsslComponentToolbarDisablers.someComponentsHaveAuthConversion(selection),
            ],
            action: button =>
              this.onEsslComponentActionClick(
                selection,
                EsslComponentActionType.COMPONENT_DELETE,
                button.authorizedOperations!,
                [
                  EsslComponentOperationValidators.componentHasAuthorizedConversion(),
                ],
                false
              )
          },
        ],
      },
    ]);

    if (selection.length === 1) {
      return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
        {
          [AuthorizedEntityType.COMPONENT]: singleEntity?.id,
          [AuthorizedEntityType.DOCUMENT]: parentDocumentId,
        },
        buttonsObs
      );
    } else {
      return buttonsObs.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  onContainerExtract(selection: DigitalComponentDto[]) {
    this.globalLoadingService.doLoading(
      this.apiDigitalComponentVersionService.digitalComponentVersionExtractComponentsFromContainers({
        body: selection.map(component => getLatestDigitalComponentVersion(component)!.id!),
      })
    ).subscribe(() => {
      this.esslComponentToastService.dispatchBulkOperationStartedToast(
        EsslComponentToastType.COMPONENT_BULK_CONTAINER_EXTRACT_STARTED,
        {
          [InternalNotificationKey.COUNT]: selection.length,
        }
      );
      this.announceActionCompleted({
        operationType: DigitalComponentOperationType.EXTRACTION,
        addedComponentCount: null,
        removedComponentCount: null,
        selectedDigitalComponents: null,
      });
    });
  }

  onNewDigitalComponentsClick() {
    if (this.document.id) {
      this.openDigitalComponentUploadModal(DigitalComponentUploadDialogType.NEW_DIGITAL_COMPONENT).subscribe(uploadModalResult => {
        if (!uploadModalResult?.metadata) return;

        this.loadingIndicatorService.doLoading(
          this.digitalComponentFileUploadService.linkNewDigitalComponentsToDocument(
            uploadModalResult.metadata as DigitalComponentReferenceCreateDto[],
            this.document.id,
          ),
          this
        ).subscribe((results: DigitalComponentVersionValidationResult[]) => {

          if (results && results.length && results.some(r => r.error !== null)) {
            results.forEach((validationResult: DigitalComponentVersionValidationResult, index: number) => {
              this.esslComponentToastService.dispatchComponentWarningToast(EsslComponentToastType.MARK_FINAL_ERROR, {
                [InternalNotificationKey.ESSL_COMPONENT_LABEL]: uploadModalResult.metadata[index].label!,
                [InternalNotificationKey.DIGITAL_COMPONENT_ID]: validationResult.digitalComponentId!,
                [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: validationResult.digitalComponentVersionId!,
                [InternalNotificationKey.DOCUMENT_ID]: this.document.id!,
              });
            });
          }
          this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, uploadModalResult.metadata.length);
        });
      });
    }
  }

  private componentVersionHasNoSignature(version: DigitalComponentVersionCompleteDto) {
    return (
      !version.digitalComponentVersionSignatureFlags ||
      version.digitalComponentVersionSignatureFlags.length === 0 ||
      (version.digitalComponentVersionSignatureFlags.length === 1 && version.digitalComponentVersionSignatureFlags[0].flag === DigitalComponentVersionSignatureFlagType.NONE)
    );
  }

  runComponentAction(digitalComponentData: EsslComponentDto[],
                     actionType: EsslComponentActionType,
                     toolbarContext?: DigitalComponentToolbarContext) {
    if (actionType === EsslComponentActionType.CONVERSION) {
      this.onConvertToOutputFormatClick(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.CONVERSION_WITH_CLAUSE) {
      this.onConvertToOutputFormatWithClauseClick(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_PERFORM_AUTHORIZED_CONVERSION_LE) {
      this.onAuthorizedConvertToDigital(digitalComponentData as PaperComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_CREATE_DIGITAL_COMPONENT_VERSION) {
      this.onNewDigitalComponentVersionClick(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_SIGN) {
      this.onSignatureClick(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_SEAL) {
      this.onMarkClick(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_ADD_TIMESTAMP) {
      this.onExtendClick(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_VERIFY_SIGNATURE) {
      this.triggerManualDigitalComponentValidation(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_DOWNLOAD) {
      this.downloadDigitalComponentVersion(toolbarContext!.view, digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_LOCK) {
      this.lockDigitalComponent(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_UNLOCK) {
      this.unlockDigitalComponent(digitalComponentData as DigitalComponentDto[]);
    }
    else if (actionType === EsslComponentActionType.COMPONENT_DELETE) {
      this.deleteComponent(digitalComponentData);
    }
    else if (actionType === EsslComponentActionType.CONTAINER_EXTRACT) {
      this.onContainerExtract(digitalComponentData as DigitalComponentDto[]);
    }
  }

  private onEsslComponentActionClick(
    digitalComponentData: EsslComponentDto[],
    actionType: EsslComponentActionType,
    authorizedOperations: GeneralAuthorizedOperation[],
    validators: OperationValidator<EsslComponentDto>[],
    useSignatureInvalidationAlert: boolean,
    toolbarContext?: DigitalComponentToolbarContext
  ) {
    this.bulkOperationValidationService.validateEsslObjects<EsslComponentDto>(
      {
        dialogWarningLabel: 'Operaci nelze vykonat pro některé komponenty ({{errorCount}}). Hromadná akce bude provedena jen s vyhovujícími komponentami ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: validators,
        esslObjects: digitalComponentData.map(dd => this.esslComponentToValidationObject(dd)),
        authorizedOperations,
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        if (useSignatureInvalidationAlert) {
          const componentsWithSignature = (validatedObjects as DigitalComponentCompleteDto[]).filter(
            obj => !this.componentVersionHasNoSignature(getLatestDigitalComponentVersion(obj)!)
          );

          if (componentsWithSignature.length) {
            this.digitalComponentSignatureDialogService.openSignatureInvalidationDialog(componentsWithSignature.map(c => c.label!))
              .subscribe(
              closeValue => {
                if (closeValue) {
                  this.runComponentAction(validatedObjects, actionType, toolbarContext);
                }
              });
          }
          else {
            this.runComponentAction(validatedObjects, actionType, toolbarContext);
          }
        }
        else {
          this.runComponentAction(validatedObjects, actionType, toolbarContext);
        }
      }
    });
  }

  private checkComponentSignatureInvalidationAndRunAction(digitalComponentData: DigitalComponentCompleteDto[], action: (components: DigitalComponentCompleteDto[]) => void) {
    const selectedDigitalComponent = digitalComponentData[0]!;
    if (selectedDigitalComponent && !this.componentVersionHasNoSignature(getLatestDigitalComponentVersion(selectedDigitalComponent!)!)) {
      this.digitalComponentSignatureDialogService.openSignatureInvalidationDialog([selectedDigitalComponent!.label!])
        .subscribe(
        closeValue => {
          if (closeValue) {
            action(digitalComponentData);
          }
        });
    } else {
      action(digitalComponentData);
    }
  }

  onNewDigitalComponentVersionClick(digitalComponentData: DigitalComponentCompleteDto[]) {
    const selectedDigitalComponent = digitalComponentData[0]!;

    this.openDigitalComponentUploadModal(DigitalComponentUploadDialogType.NEW_DIGITAL_VERSION, selectedDigitalComponent).subscribe(uploadModalResult => {
      this.loadingIndicatorService.doLoading(
        this.digitalComponentFileUploadService.digitalComponentUpdateMetadata(
          uploadModalResult.metadata,
          selectedDigitalComponent,
        ),
        this
      ).subscribe(() => {
        this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, uploadModalResult.metadata.length);
      });
    });
  }

  onUpdateComponentTemplateClick(digitalComponentData: DigitalComponentCompleteDto[]) {
    const selectedDigitalComponent = digitalComponentData[0]!;

    this.modalService.openComponentInModal<boolean, DigitalComponentCompleteDto>({
          component: UpdateComponentTemplateDialogComponent,
          modalOptions: {
            titleTemplate: 'Aktualizace parametrů v obsahu komponenty',
            width: 1000,
            height: 800,
          },
          data: selectedDigitalComponent,
        }).subscribe(result => {
          if (result) {
            this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_TEMPLATE_UPDATED, null, 1);
          }
    });
  }

  onComponentAnonymizationClick(digitalComponentData: DigitalComponentCompleteDto[], contextDigitalComponentVersionId: Nullable<number>) {
    const selectedDigitalComponent = digitalComponentData[0]!;
    const digitalComponentVersionId = contextDigitalComponentVersionId ?? getLatestDigitalComponentVersion(selectedDigitalComponent)!.id!;

    this.modalService.openComponentInModal<boolean, DigitalComponentAnonymizationDialogData>({
          component: DigitalComponentAnonymizationDialogComponent,
          modalOptions: {
            titleTemplate: 'Anonymizace',
            width: 800,
            height: 600,
          },
          data: {
            isReceivedDocument: isReceivedDocumentObject(this.document),
            digitalComponent: selectedDigitalComponent,
            digitalComponentVersionId,
          },
        }).subscribe(result => {
          if (result) {
            this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, 1);
          }
    });
  }

  onNewPaperComponentsClick() {
    this.openAnalogComponentsCreateModal(EsslAnalogComponentType.PAPER_COMPONENT).subscribe(paperComponents => {
      if (!paperComponents) return;

      this.loadingIndicatorService.doLoading(
        this.apiPaperComponentService.paperComponentLinkPaperComponentToDocument(
          {body: paperComponents as AnalogComponentToDocumentReferenceCreateDto[], documentId: this.document.id!}),
        this
      ).subscribe(() => {
        this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, paperComponents.length);
      });
    });
  }

  onNewMediumComponentClick() {
    this.openAnalogComponentsCreateModal(EsslAnalogComponentType.MEDIUM_COMPONENT).subscribe(mediumComponents => {
      if (!mediumComponents) return;

      this.loadingIndicatorService.doLoading(
        this.apiMediumComponentService.mediumComponentLinkMediumComponentToDocument({body: mediumComponents as AnalogComponentToDocumentReferenceCreateDto[], documentId: this.document.id!}),
        this
      ).subscribe(() => {
        this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, mediumComponents.length);
      });
    });
  }

  onNewPhysicalItemComponentClick() {
    this.openAnalogComponentsCreateModal(EsslAnalogComponentType.PHYSICAL_ITEM_COMPONENT).subscribe(physicalItemComponents => {
      if (!physicalItemComponents) return;

      this.loadingIndicatorService.doLoading(
        this.apiPhysicalItemComponentService.physicalItemComponentLinkPhysicalItemComponentToDocument({
          body: physicalItemComponents as AnalogComponentToDocumentReferenceCreateDto[],
          documentId: this.document.id!
        }),
        this
      ).subscribe(() => {
        this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, physicalItemComponents.length);
      });
    });
  }

  onAddDigitalRenditionToPaperClick(paperComponent: PaperComponentDto) {
    this.openDigitalComponentUploadModal(DigitalComponentUploadDialogType.ADD_DIGITAL_RENDITION_TO_PAPER).subscribe(uploadModalResult => {
      if (uploadModalResult?.metadata) {
        this.loadingIndicatorService.doLoading(
          this.apiPaperComponentService.paperComponentLinkPaperComponentToDigitalRendition(
            {
              body: {
                analogComponentToDigitalRenditionReferenceList: [
                  {
                    analogComponentId: paperComponent.id!,
                    digitalRenditionId: (uploadModalResult.metadata[0] as DigitalComponentReferenceCreateDto).digitalComponentVersionId!
                  }
                ]
              }
            }
          ),
          this
        ).subscribe(() => {
          this.emitOperationSuccess(DigitalComponentOperationType.DIGITAL_RENDITION_ADDED, null);
        });
      }
    });
  }

  triggerManualDigitalComponentValidation(digitalComponentData: DigitalComponentCompleteDto[]) {
    this.globalLoadingService.doLoading(
      this.signMarkTimestampService.triggerManualDigitalComponentValidation(digitalComponentData)
    ).subscribe({
      next: _ => {
        this.emitOperationSuccess(DigitalComponentOperationType.MANUAL_DIGITAL_COMPONENT_VALIDATION, null);
      },
      complete: () => {
        this.signMarkTimestampService.deleteDigitalComponentsFromManualValidationInProgressList();
      },
    });
  }

  private handleComponentActionError(response: HttpErrorResponse, actionType: EsslComponentActionType, templateData: DigitalComponentTemplateData) {
    const errorResponseBody: EsslErrorResponseDto = typeof response.error === 'string' ? JSON.parse(response.error) : response.error;
    let toastBodyType: Nullable<EsslComponentToastType>;
    const errorCode = errorResponseBody.errorCode.split('.').pop();
    if (errorCode && Object.values(EsslComponentErrorCode).includes(errorCode as EsslComponentErrorCode)) {
      toastBodyType = esslComponentErrorToastTypeMapping[actionType]![errorCode as EsslComponentErrorCode];
    }

    let toastHeaderType: Nullable<EsslComponentToastType>;

    switch (actionType) {
      case EsslComponentActionType.CONVERSION:
        toastHeaderType = EsslComponentToastType.CONVERSION_ERROR;
        break;
      case EsslComponentActionType.CONVERSION_WITH_CLAUSE:
        toastHeaderType = EsslComponentToastType.CONVERSION_WITH_CLAUSE_ERROR;
        break;
      case EsslComponentActionType.MARK:
        toastHeaderType = EsslComponentToastType.MARK_FILE_ERROR;
        break;
      case EsslComponentActionType.EXTEND:
        toastHeaderType = EsslComponentToastType.EXTEND_FILE_ERROR;
        break;
    }

    if (toastBodyType && toastHeaderType) {
      this.esslComponentToastService.dispatchActionError(templateData, toastHeaderType, toastBodyType);
    } else {
      this.communicationErrorDialogService.showCommunicationError(response);
    }
  }

  onConvertToOutputFormatClick(digitalComponentData: DigitalComponentCompleteDto[]) {
    if (digitalComponentData.length > 1) {
      const digitalComponentVersions = digitalComponentData.map(
        component => getLatestDigitalComponentVersion(component)!.id!);
      this.globalLoadingService.doLoading(this.apiConversionService.conversionBulkConvertToOutputFormat(
        {
          body: digitalComponentVersions,
        },
        SKIP_ERROR_DIALOG
      )).subscribe({
          next: _ => {
            this.emitOperationSuccess(DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION, null);
            this.esslComponentToastService.dispatchBulkOperationStartedToast(EsslComponentToastType.COMPONENT_BULK_OUTPUT_FORMAT_CONVERSION_STARTED, {
              [InternalNotificationKey.COUNT]: digitalComponentData.length
            });
          },
          error: _ => {
            this.esslComponentToastService.dispatchComponentSimpleErrorToast(EsslComponentToastType.COMPONENT_BULK_ACTION_ERROR);
          }
        });
    } else {
      const digitalComponentVersionId = getLatestDigitalComponentVersion(digitalComponentData[0])!.id;

      if (digitalComponentVersionId) {
        this.globalLoadingService.doLoading(
          this.apiConversionService.conversionConvertToOutputFormat(
            {
              digitalComponentVersionId,
            },
            SKIP_ERROR_DIALOG
          )
        ).subscribe({
          next: _ => {
            this.emitOperationSuccess(DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION, null);
          },
          error: response => {
            this.handleComponentActionError(response, EsslComponentActionType.CONVERSION, {
              [InternalNotificationKey.DOCUMENT_ID]: String(this.document.id),
              [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: digitalComponentVersionId,
              [InternalNotificationKey.DIGITAL_COMPONENT_ID]: String(digitalComponentData[0].id),
              [InternalNotificationKey.ESSL_COMPONENT_LABEL]: String(digitalComponentData[0].label),
            });
          }
        });
      }
    }
  }

  onConvertToOutputFormatWithClauseClick(digitalComponentData: DigitalComponentCompleteDto[]) {
    if (digitalComponentData.length > 1) {
      const digitalComponentVersions = digitalComponentData.map(
        component => getLatestDigitalComponentVersion(component)!.id!);
      this.globalLoadingService.doLoading(
        this.apiConversionService.conversionBulkChangeDataFormatWithClause(
          {
            body: digitalComponentVersions,
          },
          SKIP_ERROR_DIALOG
        )
      ).subscribe({
          next: _ => {
            this.emitOperationSuccess(DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION, null);
            this.esslComponentToastService.dispatchBulkOperationStartedToast(EsslComponentToastType.COMPONENT_BULK_CONVERSION_WITH_CLAUSE_STARTED, {
              [InternalNotificationKey.COUNT]: digitalComponentData.length
            });
          },
          error: _ => {
            this.esslComponentToastService.dispatchComponentSimpleErrorToast(EsslComponentToastType.COMPONENT_BULK_ACTION_ERROR);
          }
        });
    } else {
      const digitalComponentVersionId = getLatestDigitalComponentVersion(digitalComponentData[0])!.id;

      if (digitalComponentVersionId) {
        this.globalLoadingService.doLoading(
          this.apiConversionService.conversionChangeDataFormatWithClause(
            {
              digitalComponentVersionId,
            },
            SKIP_ERROR_DIALOG
          )
        ).subscribe({
          next: _ => {
            this.emitOperationSuccess(DigitalComponentOperationType.OUTPUT_FORMAT_CONVERSION, null);
          },
          error: response => {
            this.handleComponentActionError(response, EsslComponentActionType.CONVERSION_WITH_CLAUSE,{
              [InternalNotificationKey.DOCUMENT_ID]: String(this.document.id),
              [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: digitalComponentVersionId,
              [InternalNotificationKey.DIGITAL_COMPONENT_ID]: String(digitalComponentData[0].id),
              [InternalNotificationKey.ESSL_COMPONENT_LABEL]: String(digitalComponentData[0].label),
            });
          }
        });
      }
    }
  }

  onVerifyConversionClause(esslComponents: EsslComponentDto[]) {
    this.modalService
      .openComponentInModal({
        component: VerifyConversionClauseDialogComponent,
        modalOptions: {
          titleTemplate: 'Ověření doložky KZMÚ',
          width: '80vw',
          height: '95vh',
        },
        data: {esslComponent: esslComponents[0]}
      })
      .subscribe(); // nothing to do, async action
  }

  onAuthorizedConvertToDigital(paperComponents: PaperComponentDto[]) {
    this.modalService
      .openComponentInModal<Nullable<boolean>, ConversionAnalogToDigitalModalData>({
        component: AuthorizedConversionAnalogToDigitalDialogComponent,
        modalOptions: {
          titleTemplate: 'Autorizovaná konverze z moci úřední L > E',
          width: 600,
          height: 700,
        },
        data: {paperComponent: paperComponents[0], conversionType: ConversionAnalogToDigitalType.AUTHORIZED_CONVERSION}
      })
      .subscribe(result => {
        if (result) {
          this.esslComponentToastService.dispatchComponentAuthConversionStartedToast({
            [InternalNotificationKey.ESSL_COMPONENT_LABEL]: paperComponents[0].label!,
          });
        }
        this.emitOperationSuccess(DigitalComponentOperationType.AUTHORIZED_CONVERSION_STARTED, null);
      });
  }

  onVerifiedConversionToDigital(paperComponents: PaperComponentDto[]) {
    this.modalService
      .openComponentInModal<Nullable<boolean>, ConversionAnalogToDigitalModalData>({
        component: AuthorizedConversionAnalogToDigitalDialogComponent,
        modalOptions: {
          titleTemplate: 'Převod z listinné do elektronické podoby',
          width: 600,
          height: 700,
        },
        data: {paperComponent: paperComponents[0], conversionType: ConversionAnalogToDigitalType.VERIFIED_COPY}
      })
      .subscribe(result => {
        if (result) {
          this.emitOperationSuccess(DigitalComponentOperationType.VERIFIED_CONVERSION_STARTED, null, 1);
        }
      });
  }

  onVerifiedConversionToAnalog(esslComponents: EsslComponentDto[]) {
    this.apiConversionService.conversionConvertDigitalToAnalog({digitalComponentId: esslComponents[0].id!})
      .subscribe(_ => {
        this.emitOperationSuccess(DigitalComponentOperationType.VERIFIED_CONVERSION_STARTED, null, 1);
      });
  }

  openMarkWithTimestampDialog(digitalComponentData: DigitalComponentCompleteDto[]) {
    if (digitalComponentData.length === 0) {
      return;
    }
    const {documentId} = digitalComponentData[0];
    this.modalService
      .openComponentInModal<{assignTimestamp: boolean}, number>({
        component: DigitalComponentMarkTimestampDialogComponent,
        modalOptions: {
          titleTemplate: 'Přidání pečetě',
          width: 500,
          height: 350,
        },
        data: digitalComponentData.length,
      })
      .pipe(
        filter(Boolean),
        switchMap((closeData: {assignTimestamp: boolean}) =>
          this.signMarkTimestampService.markDigitalComponent(digitalComponentData, documentId!, closeData.assignTimestamp, true)
        )
      )
      .subscribe({
        next: () => {
          this.emitOperationSuccess(DigitalComponentOperationType.MARK_DIGITAL_COMPONENT, null);
        },
        error: response => {
          this.handleComponentActionError(response, EsslComponentActionType.MARK, {
            [InternalNotificationKey.DOCUMENT_ID]: String(this.document.id),
            [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: String(getLatestDigitalComponentVersion(digitalComponentData[0])!.id),
            [InternalNotificationKey.DIGITAL_COMPONENT_ID]: String(digitalComponentData[0].id),
            [InternalNotificationKey.ESSL_COMPONENT_LABEL]: String(digitalComponentData[0].label),
          });
        },
        complete: () => {
          this.signMarkTimestampService.deleteDigitalComponentsFromMarkInProgressList();
        },
      });
  }

  addTimestampToDigitalComponents(digitalComponentData: DigitalComponentCompleteDto[]) {
    if (digitalComponentData.length === 0) {
      return;
    }

    this.signMarkTimestampService.addTimestamp(digitalComponentData, digitalComponentData[0].documentId!, true).subscribe({
      next: () => {
        this.emitOperationSuccess(DigitalComponentOperationType.ADD_TIMESTAMP, null);
      },
      error: response => {
        const errorResponseBody: EsslErrorResponseDto = typeof response.error === 'string' ? JSON.parse(response.error) : response.error;
        const templateData = {
          [InternalNotificationKey.DOCUMENT_ID]: String(this.document.id),
          [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: String(getLatestDigitalComponentVersion(digitalComponentData[0])!.id),
          [InternalNotificationKey.DIGITAL_COMPONENT_ID]: String(digitalComponentData[0].id),
          [InternalNotificationKey.ESSL_COMPONENT_LABEL]: digitalComponentData[0].label!,
        };
        // handling special case for 'extend' action
        if (errorResponseBody.errorCode.endsWith('signature.notFound')) {
          this.esslComponentToastService.dispatchActionError(templateData, EsslComponentToastType.EXTEND_FILE_ERROR, EsslComponentToastType.EXTEND_SIGNATURE_NOT_FOUND_ERROR);
        } else {
          this.handleComponentActionError(response, EsslComponentActionType.EXTEND, templateData);
        }
      },
      complete: () => {
        this.signMarkTimestampService.deleteDigitalComponentsFromTimestampInProgressList();
      },
    });
  }

  downloadDigitalComponentVersion(view: DigitalComponentView, digitalComponentData: DigitalComponentCompleteDto[]) {
    if (view === DigitalComponentView.DIGITAL_COMPONENT_VIEWER) {
      this.emitOperationSuccess(DigitalComponentOperationType.DOWNLOAD, digitalComponentData[0]);
    }
    else {
      const REQUEST_PARALLEL_LIMIT = 5;
      const digitalComponents$: Array<Observable<BinaryForDownload>> = [];

      digitalComponentData.forEach(data => {
        const version = getLatestDigitalComponentVersion(data);
        if (version) {
          digitalComponents$.push(this.remoteBinaryFileDownloadService.fetchDigitalComponentVersion(version.id!).pipe(map(binaryWithCharset => ({binary: binaryWithCharset, fileName: version.fileName!}))));
        }
      });

      from(digitalComponents$).pipe(
        mergeMap(observable => observable, REQUEST_PARALLEL_LIMIT),
      ).subscribe({
        next: binaryforDownload => {
          this.localBinaryFileDownloadService.downloadBlob(binaryforDownload.binary.buffer, binaryforDownload.fileName, 'application/octet-stream');
        },
        error: _ => {
          this.esslComponentToastService.dispatchComponentSimpleErrorToast(EsslComponentToastType.DOWNLOAD_ERROR);
        }
      });
    }
  }

  onMarkClick(digitalComponentData: DigitalComponentCompleteDto[]) {
    this.openMarkExtendValidationDialog(digitalComponentData, true);
  }

  onExtendClick(digitalComponentData: DigitalComponentCompleteDto[]) {
    this.openMarkExtendValidationDialog(digitalComponentData, false);
  }

  openMarkExtendValidationDialog(digitalComponentData: DigitalComponentCompleteDto[], isMark: boolean) {
    if (digitalComponentData.length === 0) {
      return;
    }

    const multipleDigitalComponentsSelected = digitalComponentData.length > 1;
    const singleDigitalComponentDialogTitle = isMark ? 'Validace pečetení komponenty {{fileName}}' : 'Validace přidání časového razítka na komponentu {{fileName}}';
    const multipleDigitalComponentsDialogTitle = isMark ? 'Validace pečetení komponent' : 'Validace přidání časového razítka na komponenty';

    const titleTemplateContext = {
      fileName: digitalComponentData[0].label!,
      length: (digitalComponentData?.length ?? 0).toString(),
    };

    const {documentId} = digitalComponentData[0];

    this.modalService
      .openComponentInModal<Nullable<DigitalComponentCompleteDto[]>, PreMarkExtendValidationDialogData>({
        component: DigitalComponentPreMarkExtendValidationDialogComponent,
        modalOptions: {
          titleTemplate: multipleDigitalComponentsSelected ? multipleDigitalComponentsDialogTitle : singleDigitalComponentDialogTitle,
          titleTemplateContext,
          width: 650,
          height: '80vh',
        },
        data: {
          digitalComponents: digitalComponentData,
          isBulkOperation: multipleDigitalComponentsSelected,
          isMark,
          documentId: documentId!,
        },
      })
      .subscribe(data => {
        if (data) {
          if (isMark) {
            this.openMarkWithTimestampDialog(data);
          } else {
            this.addTimestampToDigitalComponents(data);
          }
        }
      });
  }

  onSignatureClick(digitalComponentData: DigitalComponentCompleteDto[]) {
    if (digitalComponentData.length === 0) {
      return;
    }

    this.digitalComponentSignatureDialogService.openSignatureDialogFlow(
      SignatureFlowType.DOCUMENT_COMPONENTS_SIGNING,
      digitalComponentData,
      (documentId, signRequest) => this.apiSignatureService.signatureSignDocument({
        documentId,
        body: signRequest
      }),
      response => response,
    ).subscribe(documentSignResponse => {
      if (documentSignResponse) {
        this.emitOperationSuccess(DigitalComponentOperationType.SIGN_DIGITAL_COMPONENT, null);
      }
    });
  }

  esslComponentToValidationObject(object: EsslComponentDto) {
    return {
      entityId: object.id!,
      authorizedEntityType: AuthorizedEntityType.COMPONENT,
      entityIcon: '',
      entityData: object,
      entityName: object.label!
    };
  }

  onCirculationOperationClick(
    titleTemplate: string,
    titleTemplateBulk: string,
    modalComponent: Type<AbstractHandoverFormModalComponent>,
    circulationActivityType: CirculationActivityType,
    dialogWarningLabel: string,
    selectionData: EsslComponentDto[],
    validators: OperationValidator<EsslComponentDto>[],
    authorizedOperations?: GeneralAuthorizedOperation[]
  ) {
    const validateComponentsSigning = () => {
      this.bulkOperationValidationService.validateEsslObjects<EsslComponentDto>(
        {
          dialogWarningLabel,
          dialogWarningLabelContext: {},
          operationValidators: validators ?? [],
          esslObjects: selectionData.map(dc => this.esslComponentToValidationObject(dc)),
          authorizedOperations,
        },
        this,
      ).subscribe(validatedObjects => {
        if (validatedObjects && validatedObjects.length > 0) {
          this.openCirculationModal(
            titleTemplate,
            titleTemplateBulk,
            modalComponent,
            validatedObjects,
          ).subscribe(result => {
            if (result) {
              if (selectionData.length === 1) {
                const component = selectionData[0];
                const lastComponentVersion = getLatestDigitalComponentVersion(component as DigitalComponentDto);

                if (circulationActivityType === CirculationActivityType.COMPONENT_SIGNING) {
                  this.esslComponentCirculationToastService.dispatchEsslComponentSignAssignedInitiator({
                    [InternalNotificationKey.ENTITY_TYPE]: component.entityType!,
                    [InternalNotificationKey.ESSL_COMPONENT_LABEL]: component.label!,
                    [InternalNotificationKey.DOCUMENT_ID]: this.document.id,
                    [InternalNotificationKey.ESSL_COMPONENT_ID]: component.id!,
                    [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: lastComponentVersion?.id!,
                    [InternalNotificationKey.ACTIVITY_ID]: result,
                  });
                } else if (circulationActivityType === CirculationActivityType.COMPONENT_APPROVAL) {
                  this.esslComponentCirculationToastService.dispatchEsslComponentApprovalAssignedInitiator({
                    [InternalNotificationKey.ENTITY_TYPE]: component.entityType!,
                    [InternalNotificationKey.ESSL_COMPONENT_LABEL]: component.label!,
                    [InternalNotificationKey.DOCUMENT_ID]: this.document.id,
                    [InternalNotificationKey.ESSL_COMPONENT_ID]: component.id!,
                    [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: lastComponentVersion?.id!,
                    [InternalNotificationKey.ACTIVITY_ID]: result,
                  });
                }
              } else {
                if (circulationActivityType === CirculationActivityType.COMPONENT_SIGNING) {
                  const templateData = {
                    [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${CirculationActivityType.COMPONENT_SIGNING}`,
                    [InternalNotificationKey.COUNT]: String(validatedObjects.length),
                  };
                  this.esslComponentCirculationToastService.dispatchBulkTaskToast(templateData, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_BULK_SIGN_ASSIGNED_INITIATOR);
                } else if (circulationActivityType === CirculationActivityType.COMPONENT_APPROVAL) {
                  const templateData = {
                    [InternalNotificationKey.ACTIVITY_TYPE_KEY]: `en.circulationActivityType.${CirculationActivityType.COMPONENT_APPROVAL}`,
                    [InternalNotificationKey.COUNT]: String(validatedObjects.length),
                  };
                  this.esslComponentCirculationToastService.dispatchBulkTaskToast(templateData, EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_BULK_APPROVAL_ASSIGNED_INITIATOR);
                }
              }

              this.emitOperationSuccess(DigitalComponentOperationType.CIRCULATION_STARTED, null);
            }
          });
        }
      });
    };

    if (circulationActivityType === CirculationActivityType.COMPONENT_SIGNING) {
      this.globalLoadingService.doLoading(
        this.apiCirculationActivityService.circulationActivityExecuteComponentSigningPreconditionsCheck({
          body: selectionData.map(entity => entity.id!)
        })
      ).subscribe(validationResult => {
        validators.push(EsslComponentOperationValidators.handForSignPreconditionNotMet(validationResult));
        validateComponentsSigning();
      });
    } else {
      validateComponentsSigning();
    }
  }

  private emitOperationSuccess(
    operationType: DigitalComponentOperationType,
    selectedComponent: Nullable<EsslComponentDto>,
    addedComponentCount?: number,
    removedComponentCount?: number,
  ) {
    this.announceActionCompleted({
      operationType,
      selectedDigitalComponents: selectedComponent,
      addedComponentCount,
      removedComponentCount,
    });

    if (selectedComponent && [
      DigitalComponentOperationType.COMPONENT_LOCKED,
      DigitalComponentOperationType.COMPONENT_UNLOCKED,
      DigitalComponentOperationType.COMPONENT_CHECKED_IN,
      DigitalComponentOperationType.COMPONENT_CHECKED_OUT,
    ].includes(operationType)) {
      const digitalComponentVersion = (selectedComponent as DigitalComponentCompleteDto).digitalComponentVersions![0];

      const toastTemplateData = {
        [InternalNotificationKey.DOCUMENT_ID]: this.document.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_ID]: selectedComponent.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: digitalComponentVersion.id!,
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: selectedComponent.label!,
      };
      this.esslComponentToastService.dispatchComponentInfoToast(operationType as unknown as EsslComponentToastType, toastTemplateData);
    }
  }

  private dispatchOperationErrorToast(operationType: DigitalComponentOperationType, selectedDigitalComponent: DigitalComponentCompleteDto, res: HttpErrorResponse) {
    if (selectedDigitalComponent && [
      DigitalComponentOperationType.COMPONENT_LOCKED,
      DigitalComponentOperationType.COMPONENT_UNLOCKED,
      DigitalComponentOperationType.COMPONENT_CHECKED_IN,
      DigitalComponentOperationType.COMPONENT_CHECKED_OUT,
    ].includes(operationType)) {
      const errorOperationType = `${operationType}_ERROR`;
      const digitalComponentVersion = selectedDigitalComponent.digitalComponentVersions![0];

      const toastTemplateData = {
        [InternalNotificationKey.DOCUMENT_ID]: this.document.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_ID]: selectedDigitalComponent.id!,
        [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: digitalComponentVersion.id!,
        [InternalNotificationKey.ESSL_COMPONENT_LABEL]: selectedDigitalComponent.label!,
        ...esslErrorDtoToToastParameters(this.translateService, res.error),
      };
      this.esslComponentToastService.dispatchComponentErrorToast(errorOperationType as unknown as EsslComponentToastType, toastTemplateData);
    }
  }

  private checkOutDigitalComponent(selection: DigitalComponentCompleteDto[]) {
    this.loadingIndicatorService.doLoading(
      this.remoteBinaryFileDownloadService.fetchDigitalComponentCheckout(selection[0].id!),
      this
    ).subscribe({
      next: binaryWithCharset => {
        const lastComponentVersion = getLatestDigitalComponentVersion(selection[0])!;

        this.localBinaryFileDownloadService.downloadBlob(binaryWithCharset.buffer, lastComponentVersion.fileName!, 'application/octet-stream');
        this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_CHECKED_OUT, selection[0]);
      },
      error: error => {
        this.dispatchOperationErrorToast(DigitalComponentOperationType.COMPONENT_CHECKED_OUT, selection[0], error);
      }
    });
  }

  private lockDigitalComponent(digitalComponentData: DigitalComponentCompleteDto[]) {
    if (digitalComponentData.length > 1) {
      const digitalComponentIds = digitalComponentData.map(component => component!.id!);
      this.globalLoadingService.doLoading(this.apiDigitalComponentService.digitalComponentBulkLock(
        {
          body: digitalComponentIds,
        },
        SKIP_ERROR_DIALOG
      )).subscribe({
          next: _ => {
            this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_LOCKED, null);
            this.esslComponentToastService.dispatchBulkOperationStartedToast(EsslComponentToastType.COMPONENT_BULK_LOCK_STARTED, {
              [InternalNotificationKey.COUNT]: digitalComponentData.length
            });
          },
          error: _ => {
            this.esslComponentToastService.dispatchComponentSimpleErrorToast(EsslComponentToastType.COMPONENT_BULK_ACTION_ERROR);
          }
        });
    } else {
      this.apiDigitalComponentService.digitalComponentLock({id: digitalComponentData[0].id!}).subscribe({
        next: _ => {
          this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_LOCKED, digitalComponentData[0]);
        },
        error: error => {
          this.dispatchOperationErrorToast(DigitalComponentOperationType.COMPONENT_LOCKED, digitalComponentData[0], error);
        }
      });
    }
  }

  private unlockDigitalComponent(digitalComponentData: DigitalComponentCompleteDto[]) {
    if (digitalComponentData.length > 1) {
      const digitalComponentIds = digitalComponentData.map(component => component!.id!);
      this.globalLoadingService.doLoading(this.apiDigitalComponentService.digitalComponentBulkUnlock(
        {
          body: digitalComponentIds,
        },
        SKIP_ERROR_DIALOG
      )).subscribe({
        next: _ => {
          this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_UNLOCKED, null);
          this.esslComponentToastService.dispatchBulkOperationStartedToast(EsslComponentToastType.COMPONENT_BULK_UNLOCK_STARTED, {
            [InternalNotificationKey.COUNT]: digitalComponentData.length
          });
        },
        error: _ => {
          this.esslComponentToastService.dispatchComponentSimpleErrorToast(EsslComponentToastType.COMPONENT_BULK_ACTION_ERROR);
        }
      });
    } else {
      this.apiDigitalComponentService.digitalComponentUnlock({id: digitalComponentData[0].id!}).subscribe({
        next: _ => {
          this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_UNLOCKED, digitalComponentData[0]);
        },
        error: error => {
          this.dispatchOperationErrorToast(DigitalComponentOperationType.COMPONENT_UNLOCKED, digitalComponentData[0], error);
        }
      });
    }
  }

  private checkInDigitalComponent(selection: DigitalComponentCompleteDto[]) {
    const selectedDigitalComponent = selection[0]!;

    this.openDigitalComponentUploadModal(DigitalComponentUploadDialogType.CHECK_IN, selectedDigitalComponent).subscribe(uploadModalResult => {
      if (uploadModalResult?.metadata) {
        this.loadingIndicatorService.doLoading(
          this.digitalComponentFileUploadService.digitalComponentUpdateMetadata(
            uploadModalResult?.metadata,
            selectedDigitalComponent,
          ),
          this
        ).subscribe({
          next: _ => {
            this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_CHECKED_IN, selection[0]);
          },
          error: error => {
            this.dispatchOperationErrorToast(DigitalComponentOperationType.COMPONENT_CHECKED_IN, selection[0], error);
          }
        });
      }
    });
  }

  private deleteComponent(digitalComponentData: EsslComponentDto[]) {
    const templatesContext = {
      componentCount: digitalComponentData.length,
    };

    this.dialogService.openQuestionDialog(
      {
        title: 'Smazání komponent ({{componentCount}})',
        titleContext: templatesContext,
        question: 'Opravdu chcete smazat vybrané komponenty?',
        description: 'Budou smazány komponenty ({{componentCount}}), a to včetně jejich případných verzí a ztvárnění. Operaci nelze vzít zpět.',
        descriptionContext: templatesContext,
        leftButtonTitle: 'Smazat',
      }
    ).subscribe(_ => {
      if (digitalComponentData.length > 1) {
        this.globalLoadingService.doLoading(
          this.apiComponentService.componentBulkDelete({
            body: digitalComponentData.map(c => c.id!)
          })
        ).subscribe({
          next: _ => {
            this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_DELETED, null);
            this.esslComponentToastService.dispatchBulkOperationStartedToast(EsslComponentToastType.COMPONENT_BULK_DELETE_STARTED, {
              [InternalNotificationKey.COUNT]: digitalComponentData.length
            });
          },
          error: _ => {
            this.esslComponentToastService.dispatchComponentSimpleErrorToast(EsslComponentToastType.COMPONENT_BULK_ACTION_ERROR);
          }
        });
      } else {
        const selectedComponent = digitalComponentData[0]!;
        let delete$: Observable<void> = of();

        if (selectedComponent.entityType === EntityType.DIGITAL_COMPONENT) {
          delete$ = this.apiDigitalComponentService.digitalComponentDelete({
            componentId: selectedComponent.id!,
            documentId: selectedComponent.documentId!,
          });
        }
        else if (selectedComponent.entityType === EntityType.PAPER_COMPONENT) {
          delete$ = this.apiPaperComponentService.paperComponentDelete({
            componentId: selectedComponent.id!,
            documentId: selectedComponent.documentId!,
          });
        }
        else if (selectedComponent.entityType === EntityType.PHYSICAL_ITEM_COMPONENT) {
          delete$ = this.apiPhysicalItemComponentService.physicalItemComponentDelete({
            componentId: selectedComponent.id!,
            documentId: selectedComponent.documentId!,
          });
        }
        else if (selectedComponent.entityType === EntityType.MEDIUM_COMPONENT) {
          delete$ = this.apiMediumComponentService.mediumComponentDelete({
            componentId: selectedComponent.id!,
            documentId: selectedComponent.documentId!,
          });
        }

        this.globalLoadingService.doLoading(
          delete$,
        ).subscribe(() => {
          this.emitOperationSuccess(
            DigitalComponentOperationType.COMPONENT_DELETED,
            selectedComponent,
            undefined,
            1
          );
          this.esslComponentToastService.dispatchDigitalComponentDeleted({
            [InternalNotificationKey.COUNT]: 1,
          });
        });
      }
    });
  }

  private openDigitalComponentUploadModal(type: DigitalComponentUploadDialogType, digitalComponent?: DigitalComponentCompleteDto) {
    const isNewVersion = type === DigitalComponentUploadDialogType.NEW_DIGITAL_VERSION;
    const isNewComponent = type === DigitalComponentUploadDialogType.NEW_DIGITAL_COMPONENT;
    const isAddDigitalRendition = type === DigitalComponentUploadDialogType.ADD_DIGITAL_RENDITION_TO_PAPER;
    const isCheckIn = type === DigitalComponentUploadDialogType.CHECK_IN;

    return this.modalService
      .openComponentInModal<DigitalComponentUploadDialogResult, DigitalComponentUploadDialogData>({
        component: DigitalComponentUploadDialogComponent,
        modalOptions: {
          titleTemplate:
            isNewVersion ? 'Nahrání nové verze digitální komponenty' :
              isNewComponent ? 'Nahrání digitálních komponent' :
                isAddDigitalRendition ? 'Nahrání digitálního obsahu listinné komponenty' :
                  isCheckIn ? 'Vložit po editaci' : '',
          width: 1000,
          height: null,
        },
        data: {data: (isNewVersion || isCheckIn) ? digitalComponent : null, type},
      });
  }

  private openDigitalComponentCreateFromTemplateModal() {
    this.modalService.openComponentInModal<Nullable<DigitalComponentFromTemplateDialogResult>, void>({
      component: DigitalComponentFromTemplateDialogComponent,
      modalOptions: {
        titleTemplate: 'Založení komponenty ze šablony',
        width: 1000,
        height: null,
      },
    }).subscribe(digitalComponent => {
      if (digitalComponent) {
        this.globalLoadingService.doLoading(this.apiDigitalComponentService.digitalComponentCreateFromTemplate({
          body: {
            documentId: this.document.id!,
            description: digitalComponent.description,
            isFinal: digitalComponent.isFinal,
            label: digitalComponent.label,
            originType: digitalComponent.originType,
            relationType: digitalComponent.relationType,
            templateId: digitalComponent.templateId,
          }
        })).subscribe(result => {
          if (result) {
            this.esslComponentToastService.dispatchComponentInfoToast(EsslComponentToastType.COMPONENT_CREATED, {
              [InternalNotificationKey.DOCUMENT_ID]: this.document.id!,
              [InternalNotificationKey.DIGITAL_COMPONENT_ID]: result.id!,
              [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: String(getLatestDigitalComponentVersion(result)!.id),
              [InternalNotificationKey.ESSL_COMPONENT_LABEL]: result.label!,
            });
            this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, 1);
          }
        });
      }
    });
  }

  private openDigitalComponentTakeoverFromDocumentModal() {
    this.modalService.openComponentInModal<Nullable<number>, EsslComponentsTakeoverFromDocumentDialogData>({
      component: EsslComponentsTakeoverFromDocumentDialogComponent,
      modalOptions: {
        titleTemplate: 'Převzít komponenty z jiného dokumentu',
        width: 1200,
        height: 1000,
      },
      data: {
        duplicate: false,
        documentId: this.document.id!,
      }
    }).subscribe(result => {
      if (result && result > 0) {
        this.esslComponentToastService.dispatchComponentSimpleInfoToast(EsslComponentToastType.COMPONENT_TAKEN_OVER);
        this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, result);
      }
    });
  }

  private openDigitalComponentDuplicateFromDocumentModal(componentData: DigitalComponentCompleteDto[]) {
    this.modalService.openComponentInModal<Nullable<number>, EsslComponentsTakeoverFromDocumentDialogData>({
      component: EsslComponentsTakeoverFromDocumentDialogComponent,
      modalOptions: {
        titleTemplate: 'Duplikace komponenty',
        width: 600,
        height: 500,
      },
      data: {
        duplicate: true,
        documentId: this.document.id!,
        componentIds: componentData.map(c => c.id!),
      }
    }).subscribe(result => {
      if (result && result > 0) {
        this.esslComponentToastService.dispatchComponentSimpleInfoToast(EsslComponentToastType.COMPONENT_DUPLICATED);
        this.emitOperationSuccess(DigitalComponentOperationType.COMPONENT_ADDED, null, componentData.length);
      }
    });
  }

  private openAnalogComponentsCreateModal(analogComponentType: EsslAnalogComponentType) {
    return this.modalService
      .openComponentInModal<AnalogComponentToDocumentReferenceCreateDto[], EsslAnalogComponentModalData>({
        component: AnalogComponentCreateDialogComponent,
        modalOptions: {
          titleTemplate:
            analogComponentType === EsslAnalogComponentType.PAPER_COMPONENT ? 'Nahrání listinných komponent' :
              analogComponentType === EsslAnalogComponentType.MEDIUM_COMPONENT ? 'Nahrání nosiče' : 'Nahrání předmětu',
          width: 1000,
          height: null,
        },
        data: {analogComponentType},
      });
  }

  private openExecutionDateClauseCreateModal(componentData: DigitalComponentCompleteDto[]) {
    if (this.document.fileId) {
      this.globalLoadingService.doLoading(
        this.apiFileService.fileFindById({id: this.document.fileId})
      ).subscribe(file => {
        if (file.fileState === FileState.OPEN) {
          this.modalService
            .openComponentInModal<boolean, ExecutionDateClauseCreateDialogData>({
              component: ExecutionDateClauseCreateDialogComponent,
              modalOptions: {
                titleTemplate: 'Doložka právní moci a vykonatelnosti',
                width: 1200,
                height: 600,
              },
              data: {
                documentId: this.document.id,
                fileRefNumber: file.refNumber!,
                componentId: componentData[0].id!,
                componentLatestVersionId: getLatestDigitalComponentVersion(componentData[0])!.id!,
                documentRefNumber: this.document.refNumber!,
                componentLabel: componentData[0].label!,
              },
            }).subscribe(value => {
              if (value) {
                this.emitOperationSuccess(
                  DigitalComponentOperationType.EXECUTION_CLAUSE_CREATED,
                  componentData[0],
                );
              }
          });
        } else {
          this.dialogService.showError('Není možné generovat doložku právní moci do spisu, který není ve stavu Otevřený.');
        }
      });
    }
  }

  private openCirculationModal(
    titleTemplate: string,
    titleTemplateBulk: string,
    modalComponent: Type<AbstractHandoverFormModalComponent>,
    componentData: Nullable<EsslComponentDto>[],
  ) {
    return openGeneralCirculationModal(
      // @ts-ignore -- circumvent private property error
      this,
      titleTemplate,
      titleTemplateBulk,
      modalComponent,
      componentData
    );
  }

}
