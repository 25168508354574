import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output
} from '@angular/core';
import {IconComponent} from '../icon/icon.component';
import {ButtonComponent, ButtonSize} from '../button/button.component';

/**
 * A simple button with a rotating chevron, used for expanding/shrinking optional areas of views.
 */
@Component({
  selector: 'icz-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss', '../button/button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, IconComponent],
})
export class ToggleButtonComponent {

  /**
   * @internal
   */
  eleRef = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);

  /**
   * Button icon.
   */
  @Input()
  svgIcon = '';
  /**
   * Button size.
   * @see ButtonSize
   */
  @Input()
  size: ButtonSize = 'default';
  /**
   * Button label.
   */
  @Input()
  label!: string;
  /**
   * Accepts a string representing a CSS color literal to be applied as button background color.
   */
  @Input()
  background!: string;
  /**
   * If TRUE, marks the button as disabled which makes it greyed out and unclickable.
   */
  @Input()
  disabled = false;
  @Input()
  toggled = false;

  /**
   * Emits a button click event when the button is clicked.
   */
  @Output()
  onAction = new EventEmitter<Event>();

  toggle() {
    this.toggled = !this.toggled;
    this.cd.detectChanges();
  }

}
