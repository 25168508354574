import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {PrimitiveValueFormField} from '../common/abstract-form-field';
import {NgClass} from '@angular/common';
import {ButtonComponent} from '@icz/angular-essentials';
import {TranslateModule} from '@ngx-translate/core';
import {IczOption} from '../form-elements.model';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

/**
 * A simple selector which renders its options as horizontally grouped buttons.
 * Clicking a given button will select the option.
 * @deprecated - this should not be a form input, rather a plain tabset... so we might remove this component someday.
 */
@Component({
  selector: 'icz-button-toggle-group',
  templateUrl: './button-toggle-group.component.html',
  styleUrls: ['./button-toggle-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    ButtonComponent,
    TranslateModule,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => ButtonToggleGroupComponent),
  }],
})
export class ButtonToggleGroupComponent<T extends string | number = string | number> extends PrimitiveValueFormField<T> {

  /**
   * Options to be chosen from.
   */
  @Input({required: true})
  options!: IczOption[];

  protected _valueChanged(value: T) {
    this.value = value;
    this.valueChange.emit(value);
    this.blur.emit();
  }

  protected isButtonToggled(value: T) {
    return this.value === value;
  }

}
