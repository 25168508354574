import {inject, Injectable} from '@angular/core';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {ApiSharedFolderService, DocumentDto, FileDto, SharedFolderDto} from '|api/document';
import {Button, ButtonDisablerFn} from '../../../button-collection/button-collection.component';
import {map, Observable, of} from 'rxjs';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {
  SharedFolderSelectionDialogComponent,
  SharedFolderSelectionDialogData
} from '../components/shared-folder-selection-dialog/shared-folder-selection-dialog.component';
import {IczModalService} from '@icz/angular-modal';
import {TranslateService} from '@ngx-translate/core';
import {isDocumentObject} from '../../shared-document.utils';
import {AuthorizedEntityType} from '|api/core';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {FunctionalPositionWithSubstitutionDto} from '|api/auth-server';
import {CurrentSessionService} from 'libs/shared/src/lib/services';
import {SharedFolderToastService} from 'libs/shared/src/lib/core';
import {InternalNotificationKey} from '|api/notification';
import {WITHOUT_REF_NUMBER} from '../../shared-business-components.model';

export enum FolderShareableObjectToolbarAction {
  OBJECT_INSERTED_INTO_FOLDER = 'OBJECT_INSERTED_INTO_FOLDER',
  OBJECT_REMOVED_FROM_FOLDER = 'OBJECT_REMOVED_FROM_FOLDER',
}

export type FolderShareableObject = DocumentDto | FileDto;

export class FolderShareableObjectDisablers {

  static isObjectInNoFolder(selection: Nullable<FolderShareableObject[]>): ButtonDisablerFn {
    return () => {
      return selection?.[0]?.sharedFolderIds?.length ? {validationMessage: 'Objekt není přítomen v žádné složce.' } : null;
    };
  }

  static isCurrentUserObjectOwner(selection: Nullable<FolderShareableObject[]>, currentUserFunctionalPosition: FunctionalPositionWithSubstitutionDto): any {
    return () => {
      return selection?.[0]?.ownerFunctionalPositionId !== currentUserFunctionalPosition.id ? {validationMessage: 'Nejste zpracovatelem objektu.' } : null;
    };
  }

}

@Injectable()
export class FolderShareableToolbarButtonsService extends ToolbarProvider<FolderShareableObject, FolderShareableObjectToolbarAction> {

  private modalService = inject(IczModalService);
  private translateService = inject(TranslateService);
  private currentSessionService = inject(CurrentSessionService);
  private apiSharedFolderService = inject(ApiSharedFolderService);
  private sharedFolderToastService = inject(SharedFolderToastService);
  private globalLoadingIndicatorService = inject(GlobalLoadingIndicatorService);
  private authorizedButtonService = inject(AuthorizedButtonService);

  override getToolbarButtons(
    selection: Nullable<FolderShareableObject[]>,
  ): Observable<Button[]> {
    const buttons$: Observable<AuthorizedButton[]> = of([
      {
        label: 'Úpravy',
        icon: 'edit',
        submenuItems: [
          {
            label: 'Vložit do složky',
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FolderShareableObjectDisablers.isCurrentUserObjectOwner(selection, this.currentSessionService.currentUserFunctionalPosition!),
            ],
            action: () => this.onInsertToSharedFolderClick(selection!),
          },
          // todo(rb) implement in the next iteration
          /*{
            label: 'Vyjmout ze složky',
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              FolderShareableObjectDisablers.isObjectInNoFolder(selection),
            ],
            action: () => this.onRemoveFromSharedFolderClick(selection!),
          },*/
        ],
      },
    ]);

    if (selection?.length === 1) {
      return this.authorizedButtonService.fetchBulkAuthorizedButtonPermissions(
        isDocumentObject(selection[0]) ? {[AuthorizedEntityType.DOCUMENT]: [selection[0].id]} : {[AuthorizedEntityType.FILE]: [selection[0].id]},
        buttons$,
      );
    }
    else {
      return buttons$.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  // single action only
  private onInsertToSharedFolderClick(entities: FolderShareableObject[]) {
    const entity = entities[0];

    this.modalService.openComponentInModal<Nullable<SharedFolderDto>, SharedFolderSelectionDialogData>({
      component: SharedFolderSelectionDialogComponent,
      modalOptions: {
        width: 500,
        height: 580,
        titleTemplate: 'Vložit {{refNumber}} - {{subject}} do sdílené složky',
        titleTemplateContext: {
          refNumber: entity.refNumber ?? this.translateService.instant(WITHOUT_REF_NUMBER),
          subject: entity.subject,
        }
      },
      data: {
        submitButtonText: 'Vložit do složky',
        disabledFolderIds: entity.sharedFolderIds ?? [],
      }
    }).subscribe(targetFolder => {
      if (targetFolder) {
        this.globalLoadingIndicatorService.doLoading(
          this.apiSharedFolderService.sharedFolderAddObjectsToSharedFolder({
            id: targetFolder.id,
            body: entities.map(e => ({
              id: e.id,
              entityType: e.entityType!,
            })),
          })
        ).subscribe(() => {
          this.sharedFolderToastService.dispatchInsertIntoSharedFolderSuccess({
            [InternalNotificationKey.NAME]: targetFolder.name,
          });
          this.announceActionCompleted(FolderShareableObjectToolbarAction.OBJECT_INSERTED_INTO_FOLDER);
        });
      }
    });
  }

  private onRemoveFromSharedFolderClick(entities: FolderShareableObject[]) {

  }

}
