import {Component, inject} from '@angular/core';
import {CirculationActivityType} from '|api/commons';
import {
  ApiCirculationTaskBulkActionsService,
  ApiCirculationTaskHandoverService,
  ApiCirculationTaskHandoverToOwnHandsService,
  CirculationHandoverTaskPassDto,
  CirculationTaskDto
} from '|api/flow';
import {
  AbstractHandoverFormModalComponent,
  getSelectedSingleselectOrgStructureElement
} from '../abstract-handover-form-modal.component';
import {DerivedOrgStructureElementOption} from '../abstract-handover-form.component';
import {MessageType} from '../../../../notifications/toast.service';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup, IczValidators, TextLength} from '@icz/angular-form-elements';
import {SKIP_ERROR_DIALOG} from '../../../../../core/error-handling/http-errors';
import {ExtendedCirculationTaskDto} from '../../../model/elastic-extended-entities.interface';
import {handleCirculationErrorAsToast} from '../../../../../utils/document-file-circulation.utils';
import {CirculationToastType} from '../../../../../core/services/notifications/circulation-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {
  SEsslActivityErrorData
} from '../../../../../core/services/notifications/essl-component-circulation-toast.service';
import {Observable} from 'rxjs';
import {injectModalData} from '@icz/angular-modal';

@Component({
  selector: 'icz-pass-handover-to-next-handler-dialog',
  templateUrl: './pass-handover-to-next-handler-dialog.component.html',
  styleUrls: ['./pass-handover-to-next-handler-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class PassHandoverToNextHandlerDialogComponent extends AbstractHandoverFormModalComponent {

  private apiCirculationTaskHandoverService = inject(ApiCirculationTaskHandoverService);
  private apiCirculationTaskHandoverToOwnHandsService = inject(ApiCirculationTaskHandoverToOwnHandsService);
  private apiCirculationTaskBulkActionsService = inject(ApiCirculationTaskBulkActionsService);
  protected override circulationEntities = injectModalData<ExtendedCirculationTaskDto[]>();

  override form = new IczFormGroup({
    orgStructureElementId: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    settlementNote: new IczFormControl<Nullable<string>>(null, [], [], TextLength.LONG),
    targetTaskInstruction: new IczFormControl<Nullable<string>>(null, [], [], TextLength.LONG),
    deadline: new IczFormControl<Nullable<string>>({value: null, disabled: true}),
    printHandoverProtocol: new IczFormControl<Nullable<boolean>>(false),
  });

  protected override orgStructureElementIdsControlName = 'orgStructureElementId';

  protected override selectedFlowType = null;

  private getOptionValue = (o: DerivedOrgStructureElementOption, index: number) => o.value;

  protected override functionalPositionMapper = this.getOptionValue;
  protected override organizationalUnitMapper = this.getOptionValue;

  protected override get selectedOrgStructureOptions(): DerivedOrgStructureElementOption[] {
    return getSelectedSingleselectOrgStructureElement(this.orgStructureElementsControl, this.orgStructureOptions);
  }

  override ngOnInit() {
    super.ngOnInit();

    const deadlineControl = this.form.get('deadline')!;

    if (!this.circulationEntities[0].deadline) {
      deadlineControl.enable();
    }
    else {
      deadlineControl.setValue(this.circulationEntities[0].deadline);
    }
  }

  passToNextHandler() {
    const formValue = this.form.getRawValue();
    const activityCreateDtos: CirculationHandoverTaskPassDto[] = this.circulationEntities.map(entity => ({
      targetTaskInstruction: formValue.targetTaskInstruction!,
      settlementNote: formValue.settlementNote!,
      targetTaskId: entity.id,
      primaryFunctionalPositionId: this.selectedFunctionalPositions[0],
      primaryOrganizationalUnitId: this.selectedOrganizationalUnits[0],
      deadline: this.circulationEntities[0].deadline ? undefined : formValue.deadline!,
    }));
    if (this.isSingleEntityFlow) {
      let req$: Observable<CirculationTaskDto>;
      if (this.circulationEntities[0].activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS) {
        req$ = this.apiCirculationTaskHandoverToOwnHandsService.circulationTaskHandoverToOwnHandsPass(
          {
            id: this.circulationEntities[0].id,
            body: activityCreateDtos[0],
          },
          SKIP_ERROR_DIALOG
        );
      } else {
        req$ = this.apiCirculationTaskHandoverService.circulationTaskHandoverPass(
          {
            id: this.circulationEntities[0].id,
            body: activityCreateDtos[0],
          },
          SKIP_ERROR_DIALOG
        );
      }

      this.loadingService.doLoading(
        req$,
        this,
      ).subscribe({
        next: resultTask => this.modalRef.close(resultTask.id),
        error: errorResponse => handleCirculationErrorAsToast(
          this.circulationEntities[0],
          errorResponse,
          this.toastService,
          this.translateService,
          this.modalRef,
          CirculationActivityType.DOCUMENT_HANDOVER
        ),
      });
    } else {
      let req$: Observable<void | CirculationTaskDto>;
      if (this.circulationEntities[0].activityType === CirculationActivityType.DOCUMENT_HANDOVER_TO_OWN_HANDS) {
        req$ = this.apiCirculationTaskHandoverToOwnHandsService.circulationTaskHandoverToOwnHandsPass(
          {
            id: this.circulationEntities[0].id,
            body: activityCreateDtos[0],
          },
          SKIP_ERROR_DIALOG
        );
      } else {
        req$ = this.apiCirculationTaskBulkActionsService.circulationTaskBulkActionsPass(
          {
            body: activityCreateDtos,
          },
          SKIP_ERROR_DIALOG
        );
      }

      this.loadingService.doLoading(
        req$,
        this,
      ).subscribe({
        next: _ => this.modalRef.close(true),
        error: _ => {
          this.modalRef.close(false);
          this.toastService.dispatchBulkToast(MessageType.ERROR, CirculationToastType.FLOW_BULK_PASS_HANDOVER_ERROR, [{[InternalNotificationKey.ACTIVITY_TYPE_KEY]: CirculationActivityType.DOCUMENT_HANDOVER}], SEsslActivityErrorData);
        }
      });
    }

  }

}
