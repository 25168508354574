import {DateRange} from '@angular/material/datepicker';
import {isEqual, parse} from 'date-fns';

/**
 * ListItem contains common properties of Option, TreeNode and TreeFlatNode
 * which are likely to be used in HTML templates of option/tree lists.
 *
 * It also allows to make spreading like TreeNode = {...Option, someKey: someValue} or
 * Option = {...TreeNode, someKey: someValue} in type-safe way.
 */
export interface ListItem<D = unknown> {
  /**
   * Human-friendly option name.
   */
  label: string;
  /**
   * Option icon left to option label.
   */
  icon?: string;
  /**
   * Disabled options are greyed out and unselectable in the UI. They can be selected in the model though.
   */
  disabled?: boolean;
  /**
   * An optional tooltip text to show to the user if the option is disabled.
   */
  disableReason?: string;
  /**
   * Ädditional data the option may cary. Can be used as metadata in data manupulations or for
   * rendering in custom option ng-templates in case the label is not enough.
   */
  data?: D;
  /**
   * A special flag signifying a reserved value amongst options, will make it look bold+italic in options list.
   */
  isReserved?: boolean;
  /**
   * Makes the option unselectable and have bold fonts.
   */
  isSeparator?: boolean;
  /**
   * Makes the option unselectable and does not render checkbox, used primarily in trees.
   */
  isGroup?: boolean;
  /**
   * Makes the option expanded in initial state of tree view.
   */
  autoExpand?: boolean;
  /**
   * Disables translation of label. Used in options whose names originate in the database.
   * */
  disableTranslate?: boolean;
}

/**
 * Option item not only for form elements but most commonly used for autocomplete, tree selectors and filters.
 */
export interface IczOption<V = Nullable<string | number>, D = unknown> extends ListItem<D> {
  /**
   * Primary value - option key among other options. MUST be unique.
   */
  value: V;
  /**
   * Hidden options are not visible in available options list but can be selected in control models.
   */
  isHidden?: boolean;
  /**
   * Reference to parent IczOption.value in case we want to render the options as tree.
   * If there is a set of tree options (i.e. options with non-nullable parent values), tree root should have parent=undefined.
   */
  parent?: V;
  /**
   * Used in case, when IczOption value cannot reflect original database ID because of potential duplicity.
   */
  originId?: string;
  /**
   * System-wide identifier of the option, in case it differs from Option#value for some reason.
   */
  id?: number;
}

/**
 * Timepicker value type.
 * Wall time without bindings to time zones.
 */
export interface Time {
  hours: number;
  minutes: number;
}

/**
 * A ready to use constant with yes/no options for boolean autocomplete.
 */
export const BOOLEAN_OPTIONS: IczOption<boolean>[] = [
  {
    value: true,
    label: 'Ano',
  },
  {
    value: false,
    label: 'Ne',
  }
];

/**
 * Implements complete option resolving algorithm with originIds for singleselect.
 * If no option is found, returns null.
 */
export function locateOptionByValue<V, D>(options: Nullable<Array<IczOption<V, D>>>, value: Nullable<V>, originId?: Nullable<string>): Nullable<IczOption<V, D>> {
  if (!options) return null;

  return options.find(o => {
    // eslint-disable-next-line eqeqeq -- optimized by "value !== undefined && ==" to overcome costly conversion String(x) === String(y)
    if (originId && o.originId) return originId === o.originId && value !== undefined && (o.id as unknown as V) == value;
    // eslint-disable-next-line eqeqeq -- optimized by "value !== undefined && ==" to overcome costly conversion String(x) === String(y)
    else return value !== undefined && o.value == value;
  }) ?? null;
}

/**
 * Implements complete option resolving algorithm with originIds for multiselect.
 * If no options are found, returns an empty array.
 */
export function getOptionsByValuesList<V, D>(options: Array<IczOption<V, D>>, values: V[], originId?: Nullable<string>) {
  if (!values || !options) return [];

  return values.map(v => {
    if (v === undefined) {
      return undefined;
    }
    else {
      if (originId) {
        // eslint-disable-next-line eqeqeq -- optimized by "value !== undefined && ==" to overcome costly conversion String(x) === String(y)
        return options.find(o => originId === o.originId && v == (o.id as unknown as V))!;
      }
      else {
        // eslint-disable-next-line eqeqeq -- optimized by "value !== undefined && ==" to overcome costly conversion String(x) === String(y)
        return options.find(o => v == o.value)!;
      }
    }
  }).filter(o => !isNil(o)) as Array<IczOption<V, D>>;
}

/**
 * Extracts a subtree from tree options whose root is determined by an option with value=subtreeRootVal.
 * If no option corresponds to subtreeRootVal, the whole tree is returned.
 */
export function getOptionsSubtree<V>(options: Array<IczOption<V>>, subtreeRootVal: V): Array<IczOption<V>> {
  if (!subtreeRootVal) {
    return options;
  }

  const rootItem = options.find(item => item?.value === subtreeRootVal);

  if (!rootItem) {
    return options;
  }

  const validParents = [rootItem.value];

  for (const option of options) {
    if (validParents.includes(option.parent!)) {
      validParents.push(option.value);
    }
  }

  return options.filter(o => validParents.includes(o.parent!) || o === rootItem);
}

/**
 * Extracts a path of tree options from an option tree which starts at an option with value=subtreeRootVal
 * and ends at an option corresponding to tree root.
 */
export function getOptionsPathToRoot<V>(options: Array<IczOption<V>>, nonRootPathEndVal: V): Array<IczOption<V>> {
  if (nonRootPathEndVal === undefined) {
    // having an option with value === undefined is considered invalid, return empty set
    return [];
  }
  else if (nonRootPathEndVal === null) {
    // if there is a path with option of value === null, then it always is isolated set of one element
    return [locateOptionByValue(options, nonRootPathEndVal)!];
  }
  else {
    const path: IczOption<V>[] = [];
    let currentPathElement: IczOption<V> = locateOptionByValue(options, nonRootPathEndVal)!;

    if (currentPathElement) {
      path.push(currentPathElement);

      while (currentPathElement?.parent) {
        currentPathElement = locateOptionByValue(options, currentPathElement.parent)!;
        path.push(currentPathElement);
      }
    }

    return path;
  }
}

/**
 * Type predicate checking if the value is JS Date and the Date object has valid
 * intrinsic date value (i.e. there was no parsing error).
 */
export function isValidDate(d: any): d is Date {
  return d && d instanceof Date && !isNaN(Number(d));
}

/**
 * Type predicate checking if the value is Time object.
 * @see Time
 */
export function isValidTime(t: any): t is Time {
  return (
    t &&
    'hours' in t &&
    'minutes' in t &&
    t.hours >= 0 && t.hours <= 23 &&
    t.minutes >= 0 && t.minutes <= 59
  );
}

/**
 * Parses JS date object from various supported user date formats.
 *
 * The formats are momentarily not affected by current locale because
 * we expect a limited set of european languages to be supported.
 */
export function parseDateFromLocalDateString(dateSource: string): Nullable<Date> {
  const allowedInputFormats = [
    'd.M.yyyy',
    'dd.MM.yyyy',
    'd/M/yyyy',
    'dd/MM/yyyy',
    'ddMMyyyy',
  ];

  let date: Nullable<Date>;

  for (const dateFormat of allowedInputFormats) {
    date = parse(dateSource, dateFormat, new Date());

    if (isValidDate(date)) {
      break;
    }
  }

  return date;
}

/**
 * Parses a Time object out of HH:MM time string.
 */
export function parseTimeFromSimpleTimeString(timeSource: string): Nullable<Time> {
  const timeRe = /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/g;

  if (timeRe.test(timeSource)) {
    const timeParts = timeSource.split(':');

    return {
      hours: parseInt(timeParts[0], 10),
      minutes: parseInt(timeParts[1], 10),
    };
  }
  else {
    return null;
  }
}

/**
 * Checks if a given value is a DateRange.
 */
export function isDateRange(value: any): value is DateRange<Date> {
  return value && typeof value === 'object' && 'start' in value && 'end' in value;
}

/**
 * Checks equality of two DateRanges by comparing their bounds.
 */
export function areDateRangesEqual(dr1: DateRange<Date>, dr2: DateRange<Date>) {
  return isEqual(dr1.start!, dr2.start!) && isEqual(dr1.end!, dr2.end!);
}

/**
 * Checks is a given option is selectable and thus its value can be present in form control model.
 */
export function isOptionSelectable(o: IczOption) {
  return !o.disabled && !o.isSeparator && !o.isGroup;
}

/**
 * Returns a Date representing midnight of supplied date.
 * If no date is supplied, returns today midnight.
 */
export function getStartOfTheDay(value?: Nullable<string | Date>): Date {
  const date = value ? new Date(value) : new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

/**
 * Formats a given date to ISO format while also taking timezone into account.
 */
export function formatAsLocalIsoDate(date: Date | string) {
  // Performance hack - swedish has ISO-like official date formats,
  // no need to do any prior meddling or postprocessing.
  // Angular DatePipe has execution time 80 ms, this has execution time 3 ms.
  return new Date(date).toLocaleDateString('sv-SE');
}

/**
 * Formats a given datetime to ISO format while also taking timezone into account.
 */
export function formatAsLocalIsoDateTime(date: Date | string) {
  return new Date(date).toLocaleString('sv-SE');
}
