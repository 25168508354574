import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit, ViewChild} from '@angular/core';
import {IczModalService, injectModalData, injectModalRef} from '@icz/angular-modal';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {LoadingIndicatorService, TabItem} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {DocumentView, StorageUnitView} from '../../services/toolbar-common.utils';
import {IczSimpleTableDataSource} from '../../../../../services/simple-table.datasource';
import {GenericSearchService, SearchApiService} from '../../../../../services/search-api.service';
import {
  ApiStorageUnitElasticService,
  ApiStorageUnitFillerService,
  StorageUnitDto,
  StorageUnitFillerContentDto,
  StorageUnitFillerCreateDto,
  StorageUnitFillerDto,
  StorageUnitFillerStatisticsDto,
  StorageUnitFillerUpdateDto
} from '|api/document';
import {RegistryOfficeTakeoverType, StorageUnitFillerClassificationAttribute, StorageUnitFillerState} from '|api/commons';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  extendDefaultTableConfig,
  FilterItemTree,
  FilterNameService,
  FilterOperator,
  FilterType,
  IczTableDataSource,
  TableColumnsData,
  TableComponent,
  TableReservedColumns
} from '@icz/angular-table';
import {combineLatest, of} from 'rxjs';
import {
  StorageUnitFillerSelectionDialogComponent,
  StorageUnitFillerSelectionDialogData,
  StorageUnitFillerSelectionDialogResult
} from './storage-unit-filler-selection-dialog/storage-unit-filler-selection-dialog.component';
import {StorageUnitToastService, StorageUnitToastType} from '../../../../../core/services/notifications/storage-unit-toast.service';
import {DocumentFiltersDataService} from '../../../document-table/components/document-filters/document-filters-data.service';
import {DocumentsTableColumn} from '../../../document-table/documents-table.columnsets';
import {WITHOUT_REF_NUMBER} from '../../../shared-business-components.model';
import {StorageUnitFillerEntitiesDatasource} from './storage-unit-filler-entities.datasource';
import {StorageUnitFillerEntitiesSearchService} from '../../../../../services/storage-unit-filler-entities-search.service';
import {AbstractStorageUnitFillerClassKeysComponment} from './abstract-storage-unit-filler-class-keys';
import {getUnitViewSelectorOptions} from '../../../abstract-unit-view-component/abstract-unit-view-component';
import {map} from 'rxjs/operators';
import {isValidNow, namedDtosToOptions} from '../../../../../core/services/data-mapping.utils';

export interface StorageUnitFillerDialogData {
  storageUnitFiller: Nullable<StorageUnitFillerDto>;
  tab?: StorageUnitFillerTab;
  isUnitView: boolean;
}

export enum StorageUnitFillerTab {
  SETTINGS = 'SETTINGS',
  CONTENT_STORAGE_UNITS = 'CONTENT_STORAGE_UNITS',
  CONTENT_ENTITIES = 'CONTENT_ENTITIES',
}

@Component({
  selector: 'icz-storage-unit-filler-dialog',
  templateUrl: './storage-unit-filler-dialog.component.html',
  styleUrls: ['./storage-unit-filler-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService,
    FilterNameService,
  ]
})
export class StorageUnitFillerDialogComponent extends AbstractStorageUnitFillerClassKeysComponment implements OnInit, IFormGroupCheckable {

  protected override destroyRef = inject(DestroyRef);
  protected loadingService = inject(LoadingIndicatorService);
  protected translateService = inject(TranslateService);
  protected translateParser = inject(TranslateParser);
  protected iczModalService = inject(IczModalService);
  protected filterNameService = inject(FilterNameService);
  protected modalRef = injectModalRef<void>();
  protected modalData = injectModalData<StorageUnitFillerDialogData>();
  private apiStorageUnitFillerService = inject(ApiStorageUnitFillerService);
  private apiStorageUnitElasticService = inject(ApiStorageUnitElasticService);
  private genericSearchService = inject(GenericSearchService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private cd = inject(ChangeDetectorRef);
  private storageUnitToastService = inject(StorageUnitToastService);
  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private storageUnitFillerEntitiesSearchService = inject(StorageUnitFillerEntitiesSearchService);

  @ViewChild('entitiesTable')
  entitiesTable!: TableComponent<any>;

  override form = new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(null, []),
    classificationAttributes: new IczFormControl<Nullable<Array<StorageUnitFillerClassificationAttribute>>>([StorageUnitFillerClassificationAttribute.DISPOSAL_SCHEDULE], [IczValidators.required()]),
    enforceStrictDisposalSchedule: new IczFormControl<Nullable<boolean>>(true, [IczValidators.required()]),
    enforceStrictDisposalYear: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
    enforceStrictEntityClass: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
    filterPredicates: new IczFormControl<Nullable<string>>(null, []),
    note: new IczFormControl<Nullable<string>>(null, []),
    registryOfficeId: new IczFormControl<Nullable<number>>(null, []),
    finalRun: new IczFormControl<Nullable<boolean>>(false, []),
    storageUnitFillerState: new IczFormControl<StorageUnitFillerState>(StorageUnitFillerState.PLANNED, []),
    createdByFunctionalPositionId: new IczFormControl<Nullable<number>>(null, []),
    scheduledRunAt: new IczFormControl<Nullable<string>>(null, []),
    lastRunAt: new IczFormControl<Nullable<string>>(null, []),
    statistics: new IczFormGroup({
      documentsToInsertCount: new IczFormControl<Nullable<number>>(null, []),
      filesToInsertCount: new IczFormControl<Nullable<number>>(null, []),
      entitiesInsertedCount: new IczFormControl<Nullable<number>>(null, []),
      entitiesRejectedCount: new IczFormControl<Nullable<number>>(null, []),
      entitiesToInsertCount: new IczFormControl<Nullable<number>>(null, []),
      storageUnitsInsertedCount: new IczFormControl<Nullable<number>>(null, []),
      storageUnitsRejectedCount: new IczFormControl<Nullable<number>>(null, []),
      storageUnitsExpectedCount: new IczFormControl<Nullable<number>>(null, []),
    }),
  });

  tabs: TabItem[] = [];

  currentActiveTab: Nullable<TabItem>;

  contentStorageUnitsDataSource: Nullable<IczTableDataSource<StorageUnitDto>>;
  contentEntitiesDataSource: Nullable<IczTableDataSource<StorageUnitFillerContentDto>>;

  selectedStorageFillerContentRows: StorageUnitFillerContentDto[] = [];

  contentEntitiesColumnData = new TableColumnsData<keyof StorageUnitFillerContentDto>([
    {id: TableReservedColumns.SELECTION, label: 'Výběr', filterType: FilterType.NONE},
    {id: 'objectClass', label: 'Druh', filterType: FilterType.ENUM, list: this.documentFiltersDataService.objectClassOptions, fixedWidth: 60, disableSort: true},
    {id: 'refNumber', label: 'ČJ/SZ', filterType: FilterType.TEXT},
    {id: 'subject', label: 'Věc', filterType: FilterType.TEXT},
    {id: 'error', label: 'Nelze zpracovat', filterType: FilterType.BOOLEAN, disableSort: true},
  ]);
  contentEntitiesConfig = extendDefaultTableConfig({
    toolbarConfig: {
      autoOpenFilter: true,
    }
  });

  registryOfficeOptions!: IczOption[];
  organizationalUnitsOptions: IczOption[] = [];
  orgUnitId: Nullable<number> = null;

  readonly defaultHumanReadableFilter = 'Všechny dokumenty a spisy z Ukončených.';
  humanReadableFilter = this.defaultHumanReadableFilter;
  isPredefinedRegistryOfficeAvailableForMe = true;

  get isNew() {
    return !Boolean(this.modalData.storageUnitFiller);
  }

  get auditInfo() {
    return this.modalData.storageUnitFiller?.auditInfo;
  }

  get rawValue() {
    return this.form.getRawValue();
  }

  withdrawEntitiesFromFiller() {
    this.apiStorageUnitFillerService.storageUnitFillerBulkWithdrawEntities({
      id: this.modalData.storageUnitFiller!.id,
      body: this.selectedStorageFillerContentRows.map((s => s.id!))
    }).subscribe(_ => {
      this.contentEntitiesDataSource!.reload(true);
    });
  }

  selectedEntitesChanged(rows: StorageUnitFillerContentDto[]) {
    this.selectedStorageFillerContentRows = rows;
  }

  get toInsertEntitiesLabel() {
    return this.translateParser.interpolate(
      this.translateService.instant(
        'K uložení do ukládacích jednotek jste vybrali spisů: {{filesToInsertCount}} a dokumentů: {{documentsToInsertCount}}.'
      ),
      {
        filesToInsertCount: this.modalData.storageUnitFiller!.statistics!.filesToInsertCount,
        documentsToInsertCount: this.modalData.storageUnitFiller!.statistics!.documentsToInsertCount,
      }
    )!;
  }

  get statistics(): Nullable<StorageUnitFillerStatisticsDto> {
    return this.modalData.storageUnitFiller?.statistics;
  }

  get stateTagColor(): 'blue' | 'green' {
    return this.isPlanned ? 'blue' : 'green';
  }

  get isPlanned() {
    return this.rawValue.storageUnitFillerState === StorageUnitFillerState.PLANNED;
  }

  get isTested() {
    return this.rawValue.storageUnitFillerState === StorageUnitFillerState.TESTED;
  }

  get isFinished() {
    return this.rawValue.storageUnitFillerState === StorageUnitFillerState.FINISHED;
  }

  tabChanged(selectedTab: TabItem<string | number>) {
    this.currentActiveTab = selectedTab;
  }

  readonly StorageUnitFillerTabs = StorageUnitFillerTab;

  formGroupsToCheck!: string[];

  private setTabs() {
    this.tabs = [
      {
        id: StorageUnitFillerTab.SETTINGS,
        label: 'Naplánovaní dávky',
      },
      {
        id: StorageUnitFillerTab.CONTENT_STORAGE_UNITS,
        label: 'Ukládací jednotky dávky',
        isHidden: !this.isFinished,
        count: 0,
        showCount: true,
      },
      {
        id: StorageUnitFillerTab.CONTENT_ENTITIES,
        label: 'Přehled dokumentů a spisů v dávce',
        disabled: this.isNew,
      }
    ];
    this.currentActiveTab = this.tabs.find(t => t.id === StorageUnitFillerTab.SETTINGS);
  }

  selectObjects() {
    this.iczModalService.openComponentInModal<StorageUnitFillerSelectionDialogResult, StorageUnitFillerSelectionDialogData>({
      component: StorageUnitFillerSelectionDialogComponent,
      modalOptions: {
        width: 1200,
        height: 800,
        titleTemplate: 'Vyberte kritéria výběru objektů',
      },
      data: {isUnitView: this.modalData.isUnitView},
    }).subscribe(result => {
      if (result?.orgUnitId) {
        this.orgUnitId = result.orgUnitId;
      }
      if (result?.searchParams && result?.activeFilters) {
        let filterPredicates = '';
        this.filterItemTreeToString(result.activeFilters!, result.searchParams!.fulltextSearchTerm);
        result.searchParams.filter.push({
            fieldName: 'storageUnitId',
            operator: FilterOperator.empty,
            value: '',
          },
          {
            fieldName: 'lockedState',
            operator: FilterOperator.empty,
            value: '',
          });
        const searchApiParams: string = SearchApiService.searchApiParamsToHttpParams(
          SearchApiService.getFilterQueryParams(result.searchParams)).toString();
        if (result.searchParams!.fulltextSearchTerm) {
          filterPredicates = `text=${result.searchParams!.fulltextSearchTerm}?${searchApiParams}`;
        } else {
          filterPredicates = `${searchApiParams}`;
        }

        this.form.get('filterPredicates')!.setValue(filterPredicates);
        this.cd.detectChanges();
      }
    });
  }

  private filterItemTreeToString(filters: Nullable<FilterItemTree>, searchTerm: Nullable<string>) {
    if (filters && filters.values && filters.values.length > 0) {
      this.filterNameService.getFilterNameObs(of(filters), of(searchTerm), true)
        .pipe(takeUntilDestroyed(this.destroyRef)).subscribe(humanReadableFiltersValue => {
        this.humanReadableFilter = humanReadableFiltersValue!;
      });
    } else {
      this.humanReadableFilter = this.defaultHumanReadableFilter;
    }
  }

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    getUnitViewSelectorOptions(this.codebookService).subscribe(opts => {
      this.organizationalUnitsOptions = opts;
    });

    if (this.isNew) {
      combineLatest([
        this.codebookService.transferableRegistryOfficesForCurrentFunctionalPosition().pipe(
          map(ro => ro.filter(c => isValidNow(c))),
          namedDtosToOptions),
        this.codebookService.storageUnitFillerConfig()
      ]).subscribe(([options, config]) => {
        this.registryOfficeOptions = options;

        this.form.patchValue({
          enforceStrictEntityClass: config.prefillEnforceStrictEntityClass,
          enforceStrictDisposalYear: config.prefillEnforceStrictDisposalYear,
          scheduledRunAt: config.scheduledRunAt,
          registryOfficeId: config.targetRegistryOfficeId,
          note: config.prefillDescription,
          classificationAttributes: config.classificationAttributes,
        });

        if (!config.prefillEnforceStrictDisposalYearEditable) {
          this.form.get('enforceStrictDisposalYear')!.disable();
        }
        if (!config.prefillEnforceStrictEntityClassEditable) {
          this.form.get('enforceStrictEntityClass')!.disable();
        }
        if (!config.descriptionEditable) {
          this.form.get('note')!.disable();
        }
        if (!config.targetRegistryOfficeEditable) {
          this.form.get('registryOfficeId')!.disable();
        }
        if (config.registryOfficeTakeoverType === RegistryOfficeTakeoverType.AUTOMATIC || config.registryOfficeTakeoverType === RegistryOfficeTakeoverType.AUTOMATIC_FULL_CONTENT) {
          this.form.get('registryOfficeId')!.setValidators([IczValidators.required()]);

          if (config.targetRegistryOfficeId) {
            this.isPredefinedRegistryOfficeAvailableForMe = Boolean(this.registryOfficeOptions.find(r => r.value === config.targetRegistryOfficeId));
            if (!this.isPredefinedRegistryOfficeAvailableForMe) {
              this.form.get('registryOfficeId')!.setValue(null);
            }
          }
        }
      });

      this.syncEnforcedClassificationWithUserKeys();
      this.setTabs();
    }
    else {
      if (!this.modalData.storageUnitFiller) return;

      this.form.patchValue({...this.modalData.storageUnitFiller, statistics: this.modalData.storageUnitFiller.statistics!});

      this.setTabs();
      if (this.modalData.tab) {
        this.currentActiveTab = this.tabs.find(t => t.id === this.modalData.tab)!;

        setTimeout(() => {
          if (this.entitiesTable && this.currentActiveTab === this.tabs.find(t => t.id === StorageUnitFillerTab.CONTENT_ENTITIES)) {
            this.entitiesTable.tableToolbarService.addItemValue({
              id: 'error',
              operator: FilterOperator.equals,
              value: 'true',
              hasValidValue: () => true
            });
          }
        }, 0);
      }

      if (this.isFinished) {
        this.contentStorageUnitsDataSource = new IczSimpleTableDataSource<StorageUnitDto>(this.genericSearchService, {
          url: ApiStorageUnitElasticService.StorageUnitElasticFindByStorageUnitFillerPath,
          staticPathParams: {storageUnitFillerId: String(this.modalData.storageUnitFiller?.id)}
        });

        this.apiStorageUnitElasticService.storageUnitElasticFindByStorageUnitFiller({storageUnitFillerId: this.modalData.storageUnitFiller.id}).subscribe(res => {
          const tab = this.tabs.find(t => t.id === StorageUnitFillerTab.CONTENT_STORAGE_UNITS);
          tab!.count = res.totalElements;
        });
      }

      this.codebookService.storageUnitFillerConfig().subscribe(config => {
        if (config.registryOfficeTakeoverType === RegistryOfficeTakeoverType.AUTOMATIC) {
          this.form.get('registryOfficeId')!.setValidators([IczValidators.required()]);
        }
      });

      if (this.isPlanned) {
        this.apiStorageUnitFillerService.storageUnitFillerGetScheduledRunAt().subscribe(scheduledRunAt => {
          this.form.patchValue({
            scheduledRunAt
          });
        });
      }

      if (this.modalData.storageUnitFiller.finalRun == null) {
        this.form.get('finalRun')!.setValue(false);
      }
      this.humanReadableFilter = this.modalData.storageUnitFiller.humanReadableFilter!;

      this.contentEntitiesDataSource = new StorageUnitFillerEntitiesDatasource(this.storageUnitFillerEntitiesSearchService, this.modalData.storageUnitFiller.id);

      this.form.disable();
      this.form.get('finalRun')!.enable();
      this.form.get('registryOfficeId')!.enable();
    }
    this.form.get('enforceStrictDisposalSchedule')!.disable();
  }

  reloadFillerPlanning() {
    if (this.isTested) {
      this.loadingService.doLoading(
        this.apiStorageUnitFillerService.storageUnitFillerUpdate({
          id: this.modalData.storageUnitFiller!.id,
          body: {
            finalRun: this.rawValue.finalRun!,
            registryOfficeId: this.rawValue.registryOfficeId!,
            storageUnitFillerState: StorageUnitFillerState.PLANNED
          },
        }),
        this
      ).subscribe(filler => {
        this.form.get('storageUnitFillerState')!.setValue(filler.storageUnitFillerState);
        this.storageUnitToastService.dispatchSimpleInfoToast(StorageUnitToastType.STORAGE_UNIT_FILLER_UPDATED);
      });
    }
  }

  submit() {
    const createBody: StorageUnitFillerCreateDto = {
      classificationAttributes: this.rawValue.classificationAttributes!,
      enforceStrictDisposalSchedule: this.rawValue.enforceStrictDisposalSchedule!,
      enforceStrictDisposalYear: this.rawValue.enforceStrictDisposalYear!,
      enforceStrictEntityClass: this.rawValue.enforceStrictEntityClass!,
      humanReadableFilter: this.humanReadableFilter,
      filterPredicates: this.rawValue.filterPredicates!,
      note: this.rawValue.note!,
      registryOfficeId: this.rawValue.registryOfficeId!,
      finalRun: this.rawValue.finalRun!,
      storageUnitFillerState: StorageUnitFillerState.PLANNED,
      orgUnitView: this.modalData.isUnitView,
      orgUnitId: this.orgUnitId,
    };

    const updateBody: StorageUnitFillerUpdateDto = {
      finalRun: this.rawValue.finalRun!,
      registryOfficeId: this.rawValue.registryOfficeId!,
      storageUnitFillerState: this.rawValue.storageUnitFillerState,
    };

    const req$ = this.isNew ?
      this.apiStorageUnitFillerService.storageUnitFillerCreate({
        body: createBody,
      }) : this.apiStorageUnitFillerService.storageUnitFillerUpdate({
        id: this.modalData.storageUnitFiller!.id,
        body: updateBody,
      });

    this.loadingService.doLoading(
      req$,
      this,
    ).subscribe({
      next: _ => {
        if (this.isNew) {
          this.storageUnitToastService.dispatchSimpleInfoToast(StorageUnitToastType.STORAGE_UNIT_FILLER_CREATED);
        } else {
          this.storageUnitToastService.dispatchSimpleInfoToast(StorageUnitToastType.STORAGE_UNIT_FILLER_UPDATED);
        }
        this.form.markAsPristine();
        this.modalRef.close();
      },
      error: _ => {
        if (this.isNew) {
          this.storageUnitToastService.dispatchSimpleErrorToast(StorageUnitToastType.STORAGE_UNIT_FILLER_CREATE_FAILED);
        } else {
          this.storageUnitToastService.dispatchSimpleErrorToast(StorageUnitToastType.STORAGE_UNIT_FILLER_UPDATE_ERROR);
        }
      }
    });
  }

  cancel() {
    this.modalRef.close();
  }

  protected readonly StorageUnitView = StorageUnitView;
  protected readonly DocumentView = DocumentView;
  protected readonly DocumentsTableColumn = DocumentsTableColumn;
  protected readonly WITHOUT_REF_NUMBER = WITHOUT_REF_NUMBER;
}
