import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {ApiStorageUnitFillerService, StorageUnitFillerContentDto, StorageUnitFillerDto} from '|api/document';
import {ToolbarProvider} from '../components/shared-business-components/table-toolbar-buttons.utils';
import {DocumentView, StorageUnitView} from '../components/shared-business-components/document-toolbar/services/toolbar-common.utils';
import {Button} from '../components/button-collection/button-collection.component';
import {AuthorizedButton, AuthorizedButtonService} from '../components/shared-business-components/authorized-button.service';
import {CommonToolbarDisablers} from '../components/shared-business-components/document-toolbar/services/toolbar-common.disablers';
import {AuthorizedEntityType, DocumentAuthorizedOperation, StorageUnitAuthorizedOperation} from '|api/core';
import {map} from 'rxjs/operators';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {IczModalService} from '@icz/angular-modal';
import {
  StorageUnitFillerDialogComponent
} from '../components/shared-business-components/document-toolbar/components/storage-unit-filler-dialog/storage-unit-filler-dialog.component';
import {
  StorageUnitFillerToolbarDisablers
} from '../components/shared-business-components/document-toolbar/services/storage-unit-filler-toolbar.disablers';
import {StorageUnitToastService, StorageUnitToastType} from '../core/services/notifications/storage-unit-toast.service';

export enum StorageUnitFillerToolbarAction {
  STORAGE_UNIT_FILLER_CREATE = 'STORAGE_UNIT_FILLER_CREATE',
  STORAGE_UNIT_FILLER_DELETE = 'STORAGE_UNIT_FILLER_DELETE',
  STORAGE_UNIT_FILLER_WITHDRAW_ENTITY = 'STORAGE_UNIT_FILLER_WITHDRAW_ENTITY',
}

export interface StorageUnitFillerContext {
  view: DocumentView | StorageUnitView,
  storageUnitFillerId?: Nullable<number>,
  isUnitView: boolean,
}

@Injectable()
export class StorageUnitFillerToolbarButtonsService extends ToolbarProvider<StorageUnitFillerDto | StorageUnitFillerContentDto, StorageUnitFillerToolbarAction, StorageUnitFillerContext> {

  private authorizedButtonService = inject(AuthorizedButtonService);
  private globalLoading = inject(GlobalLoadingIndicatorService);
  private iczModalService = inject(IczModalService);
  private apiStorageUnitFillerService = inject(ApiStorageUnitFillerService);
  private storageUnitToastService = inject(StorageUnitToastService);

  constructor() {
    super();
  }

  getToolbarButtons(selection: Array<StorageUnitFillerDto | StorageUnitFillerContentDto>, ctx: StorageUnitFillerContext): Observable<Button[]> {
    const buttons: AuthorizedButton[] = [
      {
        label: 'Naplánovat plnění ukládacích jednotek',
        authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_MODIFY_PROFILE],
        buttonDisablers: [],
        icon: 'add_new',
        show: ctx.view === DocumentView.SETTLED_DOCUMENTS,
        action: _ => this.onCreateStorageUnitFiller(ctx),
      },
      {
        label: 'Úpravy',
        icon: 'edit',
        submenuItems: [
          {
            label: 'Zrušit dávku',
            show: ctx.view === StorageUnitView.STORAGE_UNIT_FILLERS_TABLE,
            authorizedOperations: [StorageUnitAuthorizedOperation.STORAGE_UNIT_DELETE],
            buttonDisablers: [
              CommonToolbarDisablers.isNoItemSelected(selection),
              StorageUnitFillerToolbarDisablers.isStorageUnitRunning(selection as StorageUnitFillerDto[]),
              StorageUnitFillerToolbarDisablers.isStorageUnitFinished(selection as StorageUnitFillerDto[]),
            ],
            action: button =>
              this.onDelete(
                selection as StorageUnitFillerDto[],
              )
          },
        ]
      }
    ];

    if (selection.length === 1) {
      return this.authorizedButtonService.fetchAuthorizedButtonPermissions(
        {
          [AuthorizedEntityType.STORAGE_UNIT]: selection[0]?.id,
        },
        of(buttons)
      );
    }
    else {
      return of(buttons).pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
    }
  }

  onDelete(selection: StorageUnitFillerDto[]) {
    if (selection.length === 1) {
      this.globalLoading.doLoading(
        this.apiStorageUnitFillerService.storageUnitFillerDelete({id: selection[0].id})).subscribe(_ => {
        this.storageUnitToastService.dispatchSimpleInfoToast(StorageUnitToastType.STORAGE_UNIT_FILLER_DELETE_SUCCESS);
        this.announceActionCompleted(StorageUnitFillerToolbarAction.STORAGE_UNIT_FILLER_DELETE);
      });
    }
  }

  onCreateStorageUnitFiller(ctx: StorageUnitFillerContext) {
    this.iczModalService.openComponentInModal({
      component: StorageUnitFillerDialogComponent,
      modalOptions: {
        width: 1200,
        height: 825,
        titleTemplate: '', //custom header
        disableAutoMargin: true,
        useCustomHeader: true,
      },
      data: {storageUnitFiller: null, isUnitView: ctx.isUnitView},
    }).subscribe(_ => {
      this.announceActionCompleted(StorageUnitFillerToolbarAction.STORAGE_UNIT_FILLER_CREATE);
    });
  }
}
