@if (isCreateFullMode || isUpdateMode) {
  <div [formGroup]="form"
    [ngClass]="{'update-mode': isUpdateMode, 'view-only-mode': isReadonly, 'nonpersistent-subject-mode': nonpersistentSubjectMode}"
    [waiting]="loadingIndicatorService.isLoading(this, SubjectLoaderIds.LINKING)">
    @if (isUpdateMode && subject) {
      <div class="subject-overview-top py-24">
        @if (subject.disposalDate) {
          <icz-alert severity="warning" [heading]="disposalAlertHeading">
            @if (isSubjectRegisterManager) {
              <icz-button extraContent primary (onAction)="disposeSubject.emit()" label="Vyřadit"></icz-button>
            }
          </icz-alert>
        }
        @if (subject.iszrMetadata) {
          <icz-card>
            <div class="row grow items-center px-16 py-8">
              <span class="icz-body-1">{{ 'Ztotožněno' | translate }} {{ subject.iszrMetadata.identificationDate | localizedDatetime }}</span>
              <icz-readonly-field [value]="iszrFormattedMetadata" class="iszr-metadata grow pb-0"></icz-readonly-field>
            </div>
          </icz-card>
        }
      </div>
    }
    <div class="row grow gap-48">
      <div class="col grow">
        <icz-section [boxed]="isUpdateMode" [showExpansionHint]="!isUpdateMode" label="Typ subjektu">
          <div class="row grow">
            <icz-form-autocomplete
              [options]="classificationOptions"
              (valueChange)="transformSubjectByClassification($event)"
              class="col grow"
              formControlName="classification"
              label="Typ subjektu"
              >
              @if (isVerified) {
                <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
              }
            </icz-form-autocomplete>
            <icz-form-autocomplete
              [options]="ovmTypeOptions"
              class="col grow"
              formControlName="ovmType"
              [clearable]="false"
              label="Typ OVM"
              >
              @if (isVerified) {
                <icz-icon [iczTooltip]="verifiedTooltip" size="small" suffix svgIcon="checked"></icz-icon>
              }
            </icz-form-autocomplete>
          </div>
        </icz-section>
        <icz-section [boxed]="isUpdateMode" [showExpansionHint]="!isUpdateMode" label="Základní údaje">
          <div class="row">
            @if (isLegalPerson || isBusinessIndividual) {
              <icz-subject-single-attribute [form]="form" [subjectAttributeType]="SubjectAttributeType.BUSINESS_NAME"
                class="grow">
              </icz-subject-single-attribute>
            }
          </div>
          <div class="row">
            @if (isNaturalPerson || isBusinessIndividual) {
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.BIRTH_DATE" [componentType]="SubjectAttributeComponentType.DATEPICKER"
                class="grow">
              </icz-subject-single-attribute>
            }
          </div>
          <div class="row">
            @if (isNaturalPerson || isBusinessIndividual) {
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.FIRST_NAME"
                class="grow">
              </icz-subject-single-attribute>
            }
            @if (isNaturalPerson || isBusinessIndividual) {
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.SURNAME"
                class="grow">
              </icz-subject-single-attribute>
            }
          </div>
          <div class="row">
            @if (isNaturalPerson || isBusinessIndividual) {
              <icz-subject-single-attribute
                [form]="form"
                [subjectAttributeType]="SubjectAttributeType.DEGREE_BEFORE"
                class="grow">
              </icz-subject-single-attribute>
            }
            @if (isNaturalPerson || isBusinessIndividual) {
              <icz-subject-single-attribute
                [form]="form"
                [subjectAttributeType]="SubjectAttributeType.DEGREE_AFTER"
                class="grow">
              </icz-subject-single-attribute>
            }
          </div>
          <div class="row">
            @if (isNaturalPerson) {
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.GENDER" [options]="genderOptions" [componentType]="SubjectAttributeComponentType.AUTOCOMPLETE"
                class="grow">
              </icz-subject-single-attribute>
            }
            @if (isNaturalPerson) {
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.BIRTH_PLACE"
                class="grow">
              </icz-subject-single-attribute>
            }
          </div>
          <div class="row">
            @if (isNaturalPerson) {
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.BIRTH_NAME"
                class="grow">
              </icz-subject-single-attribute>
            }
            @if (isNaturalPerson) {
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.BIRTH_SURNAME"
                class="grow">
              </icz-subject-single-attribute>
            }
          </div>
          <div class="row">
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.CLIENT_ID"
              class="grow">
            </icz-subject-single-attribute>
          </div>
        </icz-section>
        @if (isNaturalPerson || isBusinessIndividual) {
          <icz-section label="Doklady" [showExpansionHint]="!isUpdateMode" [boxed]="isUpdateMode">
            <div class="row">
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.IDENTITY_CARD_ID"
                class="grow">
              </icz-subject-single-attribute>
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.DRIVING_LICENCE_ID"
                class="grow">
              </icz-subject-single-attribute>
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.PASSPORT_ID"
                class="grow">
              </icz-subject-single-attribute>
            </div>
          </icz-section>
        }
        @if (isLegalPerson || isBusinessIndividual) {
          <icz-section label="Identifikační údaje" [showExpansionHint]="!isUpdateMode" [boxed]="isUpdateMode">
            <div class="row grow">
              <icz-subject-single-attribute [form]="form"
                [subjectAttributeType]="SubjectAttributeType.CID"
                class="grow">
              </icz-subject-single-attribute>
            </div>
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.LEGAL_FORM"
              [componentType]="SubjectAttributeComponentType.AUTOCOMPLETE"
              [options]="legalFormOptions$ | async"
              class="grow">
            </icz-subject-single-attribute>
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.TAX_ID"
              class="grow">
            </icz-subject-single-attribute>
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.VAT_ID"
              class="grow">
            </icz-subject-single-attribute>
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.ART_1_P_3_ID"
              class="grow">
            </icz-subject-single-attribute>
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.EORI_CODE"
              class="grow">
            </icz-subject-single-attribute>
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.LE_ID"
              class="grow">
            </icz-subject-single-attribute>
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.EXCISE_TAX_ID"
              class="grow">
            </icz-subject-single-attribute>
          </icz-section>
        }
        <!--       Databoxes -->
        <icz-subject-multiple-values-attribute class="mb-24" [boxed]="isUpdateMode" [isReadonly]="isReadonly"
          [subjectAttributeType]="SubjectAttributeType.DATA_BOX"
          [multiplicity]="dataBoxMultiplicity"
          (blockingAction)="blockingAction.emit($event)"
          [form]="form">
        </icz-subject-multiple-values-attribute>
        @if (isUpdateMode && !isReadonly && !nonpersistentSubjectMode) {
          <icz-subject-technical-info [subject]="subject!"></icz-subject-technical-info>
        }
      </div>
      <div class="col grow">
        <icz-subject-create-addresses [form]="form" [isUpdateMode]="isUpdateMode" [isReadonly]="isReadonly" [classification]="classification"></icz-subject-create-addresses>
        <icz-subject-create-contacts [boxed]="isUpdateMode" [readonly]="isReadonly" class="mt--24" [form]="form"></icz-subject-create-contacts>
      </div>
    </div>
  </div>
}

@if (isCreateOnlyEssentialMode) {
  <icz-section
    [alwaysOpened]="true"
    [boxed]="true" label="Založení odesílatele">
    <icz-button (onAction)="backToSearch.emit()"
      sectionHeaderAppendix
      class="mt-8" label="Zpět k hledání">
    </icz-button>
    <ng-container [formGroup]="form">
      <div class="row">
        <icz-form-autocomplete
          [options]="classificationOptions"
          class="col grow"
          formControlName="classification"
          label="Typ subjektu"
        ></icz-form-autocomplete>
        <icz-form-autocomplete
          [options]="ovmTypeOptions"
          [clearable]="false"
          class="col grow"
          formControlName="ovmType"
          label="Typ OVM"
        ></icz-form-autocomplete>
      </div>
      @if (isLegalPerson || isBusinessIndividual) {
        <div class="row">
          <icz-subject-single-attribute [form]="form"
            [subjectAttributeType]="SubjectAttributeType.BUSINESS_NAME"
            class="grow">
          </icz-subject-single-attribute>
          <div class="col grow"></div>
        </div>
      }
      @if (isNaturalPerson || isBusinessIndividual) {
        <div class="row">
          @if (isNaturalPerson || isBusinessIndividual) {
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.FIRST_NAME"
              class="grow">
            </icz-subject-single-attribute>
          }
          @if (isNaturalPerson || isBusinessIndividual) {
            <icz-subject-single-attribute [form]="form"
              [subjectAttributeType]="SubjectAttributeType.SURNAME"
              class="grow">
            </icz-subject-single-attribute>
          }
        </div>
      }
      @for (identifier of displayedIdentifiers; track identifier) {
        <div>
          <div class="row justify-end">
            @if (!$first) {
              <icz-button (onAction)="removeIdentifier($index)"
                class="delete-identifier"
                size="small"
                svgIcon="delete">
              </icz-button>
            }
          </div>
          <div class="row">
            <icz-form-autocomplete
              (valueChange)="setIdentifier($index, $any($event))"
              [options]="identifierOptionsForIndex($index)"
              [value]="identifier"
              [clearable]="false"
              class="grow"
              label="Typ identifikátoru"
            ></icz-form-autocomplete>
            <div class="grow">
              @if (dataBoxForm && (identifier === SubjectAttributeType.DATA_BOX)) {
                <icz-databox-with-type (blockingAction)="blockingAction.emit($event)"
                  [subjectAttributeType]="SubjectAttributeType.DATA_BOX"
                  groupName="."
                  [form]="dataBoxForm">
                </icz-databox-with-type>
              }
              @if (identifier === SubjectAttributeType.IDENTITY_CARD_ID) {
                <icz-subject-single-attribute
                  [form]="form"
                  [subjectAttributeType]="SubjectAttributeType.IDENTITY_CARD_ID"
                  class="grow">
                </icz-subject-single-attribute>
              }
              @if (identifier === SubjectAttributeType.DRIVING_LICENCE_ID) {
                <icz-subject-single-attribute
                  [form]="form"
                  [subjectAttributeType]="SubjectAttributeType.DRIVING_LICENCE_ID"
                  class="grow">
                </icz-subject-single-attribute>
              }
              @if (identifier === SubjectAttributeType.PASSPORT_ID) {
                <icz-subject-single-attribute
                  [form]="form"
                  [subjectAttributeType]="SubjectAttributeType.PASSPORT_ID"
                  class="grow">
                </icz-subject-single-attribute>
              }
              @if (identifier === SubjectAttributeType.CLIENT_ID) {
                <icz-subject-single-attribute
                  [form]="form"
                  [subjectAttributeType]="SubjectAttributeType.CLIENT_ID"
                  class="grow">
                </icz-subject-single-attribute>
              }
            </div>
          </div>
        </div>
      }
      <div class="row">
        <icz-button (onAction)="addIdentifier()"
          [disabled]="displayedIdentifiers.length === availableIdentifiersForClass.length"
          [label]="'Přidat další identifikátor'"
          class="mb-8"
          size="large">
        </icz-button>
      </div>
      @if (isLegalPerson || isBusinessIndividual) {
        <div class="row grow">
          <icz-subject-single-attribute [form]="form"
            [subjectAttributeType]="SubjectAttributeType.CID"
            class="col grow">
          </icz-subject-single-attribute>
          <div class="col grow"></div>
        </div>
      }
      @if (isNaturalPerson || isBusinessIndividual) {
        <div class="row grow">
          <icz-subject-single-attribute [form]="form"
            [subjectAttributeType]="SubjectAttributeType.BIRTH_DATE" [componentType]="SubjectAttributeComponentType.DATEPICKER"
            class="col grow">
          </icz-subject-single-attribute>
          <div class="col grow"></div>
        </div>
      }
      <div class="row grow">
        <icz-subject-multiple-values-attribute class="col grow" [multiplicity]="[1,1]"
                                               [subjectAttributeType]="SubjectAttributeType.EMAIL"
                                               [form]="form"
        ></icz-subject-multiple-values-attribute>
      </div>
    </ng-container>
    @if (singleAddressForm) {
      <icz-address [addressAttributeType]="singleAddressFormAttribute"
        [boxed]="false"
        [enforceValidators]="enforceAddressValidator"
        [form]="singleAddressForm"
        groupName="."
        [withAddressValidationButton]="true"
        class="mb-24"
        [label]="singleAddressLabel">
      </icz-address>
    }
    <ng-container [formGroup]="singleAddressTypeForm">
      <icz-form-autocomplete formControlName="type" [options]="singleAddressTypeOptions" label="Typ adresy"></icz-form-autocomplete>
    </ng-container>
  </icz-section>
}
