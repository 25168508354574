<mat-sidenav-container role="navigation">

  <mat-sidenav-content class="workspace grow">
    <icz-button
      class="menu-button" svgIcon="hamburger" size="large" primary [rounded]="false" (onAction)="openSideMenu()"
    ></icz-button>
    <div>
      <div class="h-screen env-line workspace-wrapper" [ngClass]="sidenavClass">
        <div class="side-menu-spacer" [class.side-menu-closed]="!isMenuOpen">
          <ng-content select="[toolbar]"></ng-content>
        </div>
        <ng-content></ng-content>
      </div>
    </div>
    <div class="toast-center-anchor"
         cdkOverlayOrigin
         #toastCenterOverlayOrigin="cdkOverlayOrigin"
    ></div>
    <icz-popover
      [isOpen]="true"
      [overlayOrigin]="toastCenterOverlayOrigin"
      [withBackdrop]="false"
      [showContentWindow]="false"
      [zIndex]="1001"
    >
      <icz-toast-center></icz-toast-center>
    </icz-popover>
  </mat-sidenav-content>

  <mat-sidenav mode="side" [(opened)]="isMenuOpen">
    <icz-side-menu
      (closeClicked)="closeSideMenu()" class="h-screen env-line" [ngClass]="sidenavClass">
      <ng-container sideMenuItems>
        <ng-content select="[sideMenuItems]"></ng-content>
      </ng-container>
    </icz-side-menu>
  </mat-sidenav>

</mat-sidenav-container>
