import {Component, inject, OnInit} from '@angular/core';
import {IssdDto} from '|api/config-server';
import {ApiCirculationActivityService, CirculationActivityIssdBaseDto} from '|api/flow';
import {EntityType} from '|api/commons';
import {CheckUnsavedFormDialogService} from '../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {IczValidators} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {isDocumentEntity, isFileEntity} from '../shared-document.utils';
import {CodebookService} from '../../../core/services/codebook.service';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {IczOption} from '@icz/angular-form-elements';

export interface HandoverEntity {
  entityId: number;
  entityType: EntityType;
  fileTypeId?: Nullable<number>;
  documentTypeId?: Nullable<number>;
}

export interface HandoverToExternalAppDialogData {
  handoverEntities: HandoverEntity[]
}


@Component({
  selector: 'icz-handover-to-external-app-dialog',
  templateUrl: './handover-to-external-app-dialog.component.html',
  styleUrls: ['./handover-to-external-app-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class HandoverToExternalAppDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<boolean>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private codebookService = inject(CodebookService);
  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  protected modalData = injectModalData<HandoverToExternalAppDialogData>();

  form = new IczFormGroup({
    issdId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()])
  });

  formGroupsToCheck!: string[];

  issdOptions: IczOption[] = [];
  showPreselectionFailed = false;

  ngOnInit(): void {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    this.loadingService.doLoading(
      this.codebookService.ermsIssd(),
      this
    ).subscribe( issdDtos => {
      if (issdDtos) {
        this.issdOptions = issdDtos.map(issd => ({value: issd.id, label: issd.name}));
        const preselectedIssd = this.preselectIssd(issdDtos as IssdDto[]);

        if (preselectedIssd) {
          this.form.get('issdId')!.setValue(preselectedIssd.id);
        } else {
          this.showPreselectionFailed = true;
        }
      }
    });
  }

  preselectIssd(issdDtos: IssdDto[]): Nullable<IssdDto> {
    let preselectedIssd: Nullable<IssdDto> = null;
    const documentTypeIds: number[] = [];
    const fileTypeIds: number[] = [];

    this.modalData.handoverEntities.forEach(he => {
      if (isFileEntity(he.entityType)) {
        if (he.fileTypeId) {
          fileTypeIds.push(he.fileTypeId);
        } else {
          fileTypeIds.push(-1);
        }
      }
      if (isDocumentEntity(he.entityType)) {
        if (he.documentTypeId) {
          documentTypeIds.push(he.documentTypeId);
        } else {
          documentTypeIds.push(-1);
        }
      }
    });

    issdDtos?.forEach(issd => {
      let isDocumentTypeMatch = false;
      let isFileTypeMatch = false;
      if (isNil(preselectedIssd)) {
        if (issd.documentTypes && issd.documentTypes.length) {
          isDocumentTypeMatch = documentTypeIds.every(dtId => issd.documentTypes!.some(dt => dt === dtId));
        }

        if (issd.fileTypes && issd.fileTypes.length) {
          isFileTypeMatch = fileTypeIds.every(dtId => issd.fileTypes!.some(dt => dt === dtId));
        }
      }

      if (
        (documentTypeIds.length && fileTypeIds.length && isDocumentTypeMatch && isFileTypeMatch) ||
        (documentTypeIds.length && fileTypeIds.length === 0 && isDocumentTypeMatch) ||
        (documentTypeIds.length === 0 && fileTypeIds.length && isFileTypeMatch)
      ) {
        preselectedIssd = issd;
      }
    });

    return preselectedIssd;
  }

  submit() {
    const formValue = this.form.getRawValue();
    const activities: CirculationActivityIssdBaseDto[] = [];
    this.modalData.handoverEntities.forEach(entity => {
      if (isDocumentEntity(entity.entityType)) {
        activities.push({
          circulationEntityType: entity.entityType,
          documentId: entity.entityId,
        });
      } else if (isFileEntity(entity.entityType)) {
        activities.push({
          circulationEntityType: entity.entityType,
          fileId: entity.entityId,
        });
      }
    });

    this.loadingService.doLoading(
      this.apiCirculationActivityService.circulationActivityCreateHandoverToIssd(
        {
          body: {
            issdId: formValue.issdId,
            activities
          }
        }
      ),
      this
    ).subscribe(_ => {
      this.modalRef.close(true);
    });
  }

  close() {
    this.modalRef.close(false);
  }

}
