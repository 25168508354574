import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {IFormGroupCheckable} from 'libs/shared/src/lib/lib/form-group-checks';
import {
  ApiRegistryOfficeTransferService,
  BulkMarkRegistryOfficeIncidentsAsResolvedRequestDto,
  RegistryOfficeTransferIncidentDto
} from '|api/document';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {Observable} from 'rxjs';

export interface RegistryOfficeIncidentResolutionDialogData {
  incidents: RegistryOfficeTransferIncidentDto[];
  syncMode: boolean;
}

@Component({
  selector: 'icz-registry-office-incident-resolution-dialog',
  templateUrl: './registry-office-incident-resolution-dialog.component.html',
  styleUrls: ['./registry-office-incident-resolution-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class RegistryOfficeIncidentResolutionDialogComponent implements IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<RegistryOfficeTransferIncidentDto[]>>();
  protected apiRegistryOfficeTransferService = inject(ApiRegistryOfficeTransferService);
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private modalData = injectModalData<RegistryOfficeIncidentResolutionDialogData>();

  form = new IczFormGroup({
    solutionDescription: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  formGroupsToCheck!: string[];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  submit() {
    const formValue = this.form.getRawValue();

    const requestDto: BulkMarkRegistryOfficeIncidentsAsResolvedRequestDto = {
      registryOfficeTransferIncidentIds: this.modalData.incidents.map(i => i.id),
      solutionDescription: formValue.solutionDescription!,
    };

    let request$: Observable<void>;

    if (this.modalData.syncMode) {
      request$ = this.apiRegistryOfficeTransferService.registryOfficeTransferBulkMarkAsResolvedSync({
        body: requestDto,
      });
    }
    else {
      request$ = this.apiRegistryOfficeTransferService.registryOfficeTransferBulkMarkAsResolved({
        body: requestDto,
      });
    }

    this.loadingService.doLoading(
      request$,
      this,
    ).subscribe(() => {
      this.modalRef.close(this.modalData.incidents);
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
