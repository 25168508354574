import {DestroyRef, inject, Injectable, OnDestroy} from '@angular/core';
import {RxStomp} from '@stomp/rx-stomp';
import {IMessage} from '@stomp/stompjs/esm6';
import {BehaviorSubject, merge, Subject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {InternalNotificationKey, InternalNotificationParameterDto} from '|api/notification';

import {ButtonType} from '../button-collection/button-collection.component';
import {CLOSE_THIS_TOAST, MessageType, ToastService} from './toast.service';
import {AuthService} from '../../core/authentication/auth.service';
import {ENVIRONMENT} from '../../core/services/environment.service';
import {DebugLoggingService} from '../../core/services/debug-logging.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';


export type MessageHandlerFn = (parsedBody: WebSocketNotification) => void;
export type CountHandlerFn = (count: number) => void;
export type WebSocketNotificationParameters = Partial<Record<InternalNotificationKey, string>>;

interface INotificationMessage extends IMessage {
  readonly body: string; // string containing: JSON-serialized Array<NotificationMessageBody>|NotificationCountBody;
}

export interface WebSocketNotificationBase {
  readonly notificationType: string;
  readonly messageCode: string;
}

export interface WebSocketNotificationDto extends WebSocketNotificationBase {
  readonly parameters: Array<Array<InternalNotificationParameterDto>>;
}

export interface WebSocketNotification extends WebSocketNotificationBase {
  readonly parameters: WebSocketNotificationParameters[];
}

export interface NotificationCountBody {
  readonly count: number;
}

@Injectable({
  providedIn: 'root'
})
export class WebSocketNotificationsService implements OnDestroy {

  private toastService = inject(ToastService);
  private authService = inject(AuthService);
  private debugLoggingService = inject(DebugLoggingService);
  private destroyRef = inject(DestroyRef);
  private environment = inject(ENVIRONMENT);

  private _message$ = new Subject<WebSocketNotification>();

  private messageHandlers = new Map<string, MessageHandlerFn>();
  private countHandler!: CountHandlerFn;

  private rxStompClient: Nullable<RxStomp> = null;

  stompClientCreated = new BehaviorSubject<boolean>(false);
  private stompWatchers: Record<string, Subscription> = {};

  private notificationMessageHandler = (msg: INotificationMessage) => {
    const parsedBodyDto: WebSocketNotificationDto[] = JSON.parse(msg.body);
    this.debugLoggingService.logStompMessage({
      topic: '/user/topic/notification',
      payload: parsedBodyDto,
    });

    for (const bodyPart of parsedBodyDto) {
      const notification: WebSocketNotification = {
        ...bodyPart,
        parameters: [],
      };

      for (const partParameters of bodyPart.parameters) {
        const messagePartParameters: WebSocketNotificationParameters = {};

        for (const keyValue of partParameters) {
          messagePartParameters[keyValue.key] = keyValue.value;
        }

        notification.parameters.push(messagePartParameters);
      }

      this._message$.next(notification);

      const handler = this.messageHandlers.get(notification.messageCode);

      if (handler) {
        handler(notification);
      }
      else {
        this.defaultMessageHandler(notification);
      }
    }
  };

  private countMessageHandler = (msg: INotificationMessage) => {
    if (this.countHandler) {
      const parsedBody: NotificationCountBody = JSON.parse(msg.body);
      this.debugLoggingService.logStompMessage({
        topic: '/user/topic/notification/count',
        payload: parsedBody,
      });

      const count = parsedBody.count;
      this.countHandler(count);
    }
  };

  initialize() {
    if (this.authService.isAuthenticatedWithFunctionalPosition) {
      this.openConnection();
    }

    merge(
      this.authService.login$,
      this.authService.refreshToken$
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.closeConnection();
      this.openConnection();
    });

    this.authService.logout$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => {
      this.closeConnection();
    });
  }

  ngOnDestroy(): void {
    this.closeConnection();
  }

  /**
   * Used for displaying toasts based on incoming notification - guarrantees that there
   * will be at most one handler for each message selector. Also warns about unimplemented notifications.
   * @param selector message code from BE (e.g. "be.notificationhandover.created")
   * @param messageHandler
   */
  registerMessageHandler(selector: string, messageHandler: MessageHandlerFn) {
    if (this.messageHandlers.has(selector)) {
      console.warn(`WebSocketNotificationsService already has a handler for selector "${selector}". ` +
                         `Duplicate handler has been ignored.`);
      return;
    }

    this.messageHandlers.set(selector, messageHandler);
  }

  registerCountHandler(countHandler: CountHandlerFn) {
    this.countHandler = countHandler;
  }

  /**
   * Used for handling side effects of incoming notifications in components
   * @param selector message code from BE (e.g. "be.notificationhandover.created")
   */
  getMessageListener$(selector: string) {
    return this._message$.asObservable().pipe(
      filter(notification => notification.messageCode === selector),
    );
  }

  getMessagesListener$(selectors: string[]) {
    return this._message$.asObservable().pipe(
      filter(notification => selectors.includes(notification.messageCode)),
    );
  }

  private defaultMessageHandler(parsedBody: WebSocketNotification) {
    this.toastService.dispatchToast({
      isUnclosable: true,
      type: MessageType.DEV_WARNING,
      duration: 600000, // 10 min
      data: {
        header: {
          template: 'DEV WARNING: Received unknown WS message',
        },
        body: {
          template: JSON.stringify(parsedBody),
        },
        buttons: [
          {
            type: ButtonType.SECONDARY,
            label: 'Ok, Imma implement that!',
            action: () => CLOSE_THIS_TOAST,
          }
        ],
      },
    });
  }

  openConnection() {
    this.rxStompClient = new RxStomp();
    this.rxStompClient.configure({
      brokerURL: `${this.environment.wsBaseUrl}?access_token=${this.authService.authToken!.access_token}`,
    });

    this.rxStompClient.connected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      this.debugLoggingService.logStompMessage({
        topic: 'interní notifikace',
        payload: 'spojení otevřeno',
      });
    });
    this.rxStompClient.activate();

    this.rxStompClient.watch({
      destination: '/user/topic/notification'
    }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(message => {
      this.notificationMessageHandler(message);
    });

    this.rxStompClient.watch({
      destination: '/user/topic/notification/count'
    }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(message => {
      this.countMessageHandler(message);
    });

    this.stompClientCreated.next(true);
  }

  registerStompWatcher(topic: string, handler: (messageBody: any) => void ) {
    if (this.rxStompClient) {
      this.stompWatchers[topic] = this.rxStompClient.watch({
        destination: topic
      }).pipe(takeUntilDestroyed(this.destroyRef)).subscribe(message => {
        const parsedBody = JSON.parse(message.body);
        this.debugLoggingService.logStompMessage({
          topic,
          payload: parsedBody,
        });
        handler(parsedBody);
      });
    } else {
      throw new Error('Registering stomp watcher before its initialization.');
    }
  }

  unregisterStompWatcher(topic: string) {
    if (this.stompWatchers[topic]) {
      this.stompWatchers[topic].unsubscribe();
    }
  }

  closeConnection() {
    if (this.rxStompClient) {
      this.rxStompClient.deactivate().then(() => {
        this.debugLoggingService.logStompMessage({
          topic: 'interní notifikace',
          payload: 'spojení uzavřeno',
        });
      });
    }
  }

  pushMockWsMessage(message: {body: string}) {
    this.notificationMessageHandler(message as INotificationMessage);
  }

}
