import {inject, Injectable} from '@angular/core';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {CommonToolbarDisablers} from '../../document-toolbar/services/toolbar-common.disablers';
import {IczModalService} from '@icz/angular-modal';
import {
  RegistryOfficeIncidentResolutionDialogComponent,
  RegistryOfficeIncidentResolutionDialogData
} from './registry-office-incident-resolution-dialog/registry-office-incident-resolution-dialog.component';
import {RegistryOfficeTransferIncidentDto} from '|api/document';
import {RegistryOfficeTransferDisablers} from '../../document-toolbar/services/registry-office-transfer.disablers';
import {WebSocketNotificationsService} from '../../../notifications/web-socket-notifications.service';
import {BulkOperationValidationService, EsslObjectForValidation} from '../../../../services';
import {RegistryOfficeTransferValidators} from '../../document-toolbar/services/registry-office-transfer.validators';
import {getObjectIcon} from '../../shared-document.utils';
import {InternalNotificationMessageCode} from '../../../../core/services/notifications/internal-notification.enum';

export enum RegistryOfficeTransferIncidentAction {
  RO_TRANSFER_INCIDENT_RESOLVED = 'RO_TRANSFER_INCIDENT_RESOLVED',
}

@Injectable()
export class RegistryOfficeTransferIncidentToolbarButtonsService extends ToolbarProvider<RegistryOfficeTransferIncidentDto, RegistryOfficeTransferIncidentAction, void> {

  private modalService = inject(IczModalService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private webSocketNotificationsService = inject(WebSocketNotificationsService);

  constructor() {
    super();

    this.webSocketNotificationsService.getMessageListener$(
      InternalNotificationMessageCode.BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_SUCCESS,
    ).subscribe(_ => this.announceActionCompleted(
      RegistryOfficeTransferIncidentAction.RO_TRANSFER_INCIDENT_RESOLVED
    ));
  }

  getToolbarButtons(selection: Nullable<RegistryOfficeTransferIncidentDto[]>): AuthorizedButton[] {
    return this.authorizedButtonService.evaluateButtonDefinition([
      {
        label: 'Označit jako vyřešené',
        icon: 'sent',
        buttonDisablers: [
          CommonToolbarDisablers.isNoItemSelected(selection),
          RegistryOfficeTransferDisablers.isIncidentAlreadyResolved(selection),
        ],
        action: () => this.markAsResolved(selection!),
      },
    ]);
  }

  private markAsResolved(selectedIncidents: RegistryOfficeTransferIncidentDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<RegistryOfficeTransferIncidentDto>(
      {
        dialogWarningLabel: 'Některé ze zvolených chyb ({{errorCount}}) nelze označit za vyřešené. Jen vyhovující chyby budou označeny za vyřešené ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          RegistryOfficeTransferValidators.isIncidentAlreadyResolved(),
        ],
        esslObjects: selectedIncidents.map(incident => this.registryOfficeTransferIncidentToValidationObject(incident)),
        authorizedOperations: [],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        const isBulkAction = validatedObjects.length > 1;

        this.modalService.openComponentInModal<RegistryOfficeTransferIncidentDto[], RegistryOfficeIncidentResolutionDialogData>({
          component: RegistryOfficeIncidentResolutionDialogComponent,
          modalOptions: {
            width: 600,
            height: 450,
            titleTemplate: isBulkAction ? 'Hromadné řešení chyb ({{count}})' : 'Řešení chyby {{errorId}} {{name}}',
            titleTemplateContext: {
              count: String(selectedIncidents.length),
              errorId: selectedIncidents[0]!.errorId,
              name: selectedIncidents[0]!.name,
            },
          },
          data: {
            incidents: selectedIncidents,
            syncMode: false,
          },
        }).subscribe();
      }
    });
  }

  private registryOfficeTransferIncidentToValidationObject(incident: RegistryOfficeTransferIncidentDto): EsslObjectForValidation<RegistryOfficeTransferIncidentDto> {
    return {
      entityId: incident.id,
      entityName: incident.name,
      entityIcon: getObjectIcon(incident.objectClass)!,
      authorizedEntityType: null,
      entityData: incident,
    };
  }

}
