<form class="col grow overflow-hidden">
  <div
    class="row items-center toolbar"
    cdkDrag cdkDragHandle
    cdkDragRootElement=".cdk-overlay-pane"
    cdkDragBoundary=".cdk-overlay-container"
    >
    <icz-icon [svgIcon]="digitalComponentVersionFileIcon"></icz-icon>
    <div class="icz-body-1">{{ versionData?.digitalComponentVersionFileName }}
      @if (versionData?.modalTitleSuffix) {
        <span> - <span>{{versionData.modalTitleSuffix! | translate}}</span></span>
      }
    </div>

    <div class="grow"></div>

    <div class="row" cdkDragHandle cdkDragHandleDisabled>
      <div class="row-group">
        <icz-button background="transparent" svgIcon="download" label="Stáhnout" (onAction)="download()"></icz-button>
        <icz-button testingFeature background="transparent" svgIcon="printer" label="Tisk" (onAction)="print()"></icz-button>
      </div>

      <icz-button [svgIcon]="isMaximized ? 'minim' : 'copy'" (onAction)="toggleMaximize()" size="small" background="transparent" disableRipple></icz-button>
      <icz-button svgIcon="close" (onAction)="cancel()" size="small" background="transparent" disableRipple></icz-button>
    </div>
  </div>

  <div class="row grow overflow-hidden canvas" [waiting]="loadingService.isLoading(this)">
    @if (fileContents) {
      <icz-file-contents-viewer
        class="grow"
        [fileName]="versionData.digitalComponentVersionFileName!"
        [fileContents]="fileContents"
        [mimeType]="versionData.digitalComponentVersionMimeType"
        (downloadRequested)="download()"
      ></icz-file-contents-viewer>
    }
  </div>
</form>
