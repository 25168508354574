import {AbstractControl} from '@angular/forms';
import {IczValidatorFn, ValidationErrorMessage} from '@icz/angular-form-elements';
import {ValidatorResult} from '@icz/angular-form-elements';

export class DocumentEsslComponentsValidator {

  @ValidationErrorMessage('Nebyla přidána žádná komponenta.')
  static isEsslComponentAdded(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (control.value < 1) {
        return {isEsslComponentAdded: false};
      }
      return null;
    };
  }
}
