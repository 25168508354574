@for (field of displayedCustomFields; track field.id) {
  @if (getFieldFormGroup(field); as formGroup) {
    <ng-container [formGroup]="formGroup">
      @if (field.fieldType === CustomFieldType.TEXT) {
        <icz-form-field
          formControlName="strValue"
          [label]="field.name"
        >
          <ng-container *ngTemplateOutlet="infoIconWithHelpTooltip; context: {$implicit: field}" suffix></ng-container>
        </icz-form-field>
      }
      @else if (field.fieldType === CustomFieldType.NUMERIC) {
        <icz-form-field
          formControlName="numericValue"
          [label]="field.name"
          [iczInputMask]="$any(field).mask"
          type="decimal"
        >
          <ng-container *ngTemplateOutlet="infoIconWithHelpTooltip; context: {$implicit: field}" suffix></ng-container>
        </icz-form-field>
      }
      @else if (field.fieldType === CustomFieldType.DATE) {
        <icz-date-picker
          formControlName="dateTimeValue"
          outputFormat="dateTime"
          [label]="field.name"
        >
          <ng-container *ngTemplateOutlet="infoIconWithHelpTooltip; context: {$implicit: field}" suffix></ng-container>
        </icz-date-picker>
      }
      @else if (field.fieldType === CustomFieldType.DATE_TIME) {
        <icz-datetime-picker
          formControlName="dateTimeValue"
          [label]="field.name"
        >
          <ng-container *ngTemplateOutlet="infoIconWithHelpTooltip; context: {$implicit: field}" suffix></ng-container>
        </icz-datetime-picker>
      }
      @else if (field.fieldType === CustomFieldType.ENUM) {
        <icz-form-autocomplete
          formControlName="strValue"
          [label]="field.name"
          [options]="getCodebookFieldOptions(field)"
          [customOptionTemplate]="codebookItemOptionTemplate"
          [hideDefaultTooltips]="true"
        >
          <ng-template #codebookItemOptionTemplate let-context>
            <div class="gap-2 row grow">
              <div
                class="option-label grow"
                [iczTooltip]="context.option.label"
              >
                {{ context.option.label }}
              </div>
              @if (context.option.data.description) {
                <icz-icon
                  svgIcon="info" size="small" (mouseenter.stop)="0"
                  [iczTooltip]="context.option.data.description"
                ></icz-icon>
              }
            </div>
          </ng-template>
          <ng-container *ngTemplateOutlet="infoIconWithHelpTooltip; context: {$implicit: field}" suffix></ng-container>
        </icz-form-autocomplete>
      }
      @else if (field.fieldType === CustomFieldType.BOOL) {
        <icz-checkbox
          class="mb-16"
          formControlName="strValue"
          [label]="field.name"
          [iczTooltip]="field.description"
        ></icz-checkbox>
      }
      @else {
        <div class="icz-body-strong">{{ 'Neznámý typ pole' | translate }} {{field.fieldType}}</div>
      }
    </ng-container>
  }
}

<ng-template #infoIconWithHelpTooltip let-field>
  @if (field.description) {
    <icz-icon size="small" svgIcon="info" [iczTooltip]="field.description"></icz-icon>
  }
</ng-template>
