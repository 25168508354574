/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { PageStorageUnitFillerContentDto } from '../models/page-storage-unit-filler-content-dto';
import { PageStorageUnitFillerDto } from '../models/page-storage-unit-filler-dto';
import { storageUnitFillerBulkWithdrawEntities } from '../fn/storage-unit-filler/storage-unit-filler-bulk-withdraw-entities';
import { StorageUnitFillerBulkWithdrawEntities$Params } from '../fn/storage-unit-filler/storage-unit-filler-bulk-withdraw-entities';
import { storageUnitFillerCreate } from '../fn/storage-unit-filler/storage-unit-filler-create';
import { StorageUnitFillerCreate$Params } from '../fn/storage-unit-filler/storage-unit-filler-create';
import { storageUnitFillerDelete } from '../fn/storage-unit-filler/storage-unit-filler-delete';
import { StorageUnitFillerDelete$Params } from '../fn/storage-unit-filler/storage-unit-filler-delete';
import { StorageUnitFillerDto } from '../models/storage-unit-filler-dto';
import { storageUnitFillerFind } from '../fn/storage-unit-filler/storage-unit-filler-find';
import { StorageUnitFillerFind$Params } from '../fn/storage-unit-filler/storage-unit-filler-find';
import { storageUnitFillerFindContent } from '../fn/storage-unit-filler/storage-unit-filler-find-content';
import { StorageUnitFillerFindContent$Params } from '../fn/storage-unit-filler/storage-unit-filler-find-content';
import { storageUnitFillerGetById } from '../fn/storage-unit-filler/storage-unit-filler-get-by-id';
import { StorageUnitFillerGetById$Params } from '../fn/storage-unit-filler/storage-unit-filler-get-by-id';
import { storageUnitFillerGetScheduledRunAt } from '../fn/storage-unit-filler/storage-unit-filler-get-scheduled-run-at';
import { StorageUnitFillerGetScheduledRunAt$Params } from '../fn/storage-unit-filler/storage-unit-filler-get-scheduled-run-at';
import { storageUnitFillerProcessStorageUnitFillerBatch } from '../fn/storage-unit-filler/storage-unit-filler-process-storage-unit-filler-batch';
import { StorageUnitFillerProcessStorageUnitFillerBatch$Params } from '../fn/storage-unit-filler/storage-unit-filler-process-storage-unit-filler-batch';
import { storageUnitFillerUpdate } from '../fn/storage-unit-filler/storage-unit-filler-update';
import { StorageUnitFillerUpdate$Params } from '../fn/storage-unit-filler/storage-unit-filler-update';


/**
 * Controller for storage unit filler
 */
@Injectable({ providedIn: 'root' })
export class ApiStorageUnitFillerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `storageUnitFillerGetById()` */
  static readonly StorageUnitFillerGetByIdPath = '/document/storage-unit-filler/{id}';

  /**
   * Get storage unit filler by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerGetById()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerGetById$Response(params: StorageUnitFillerGetById$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitFillerDto>> {
    return storageUnitFillerGetById(this.http, this.rootUrl, params, context);
  }

  /**
   * Get storage unit filler by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerGetById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerGetById(params: StorageUnitFillerGetById$Params, context?: HttpContext): Observable<StorageUnitFillerDto> {
    return this.storageUnitFillerGetById$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitFillerDto>): StorageUnitFillerDto => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerUpdate()` */
  static readonly StorageUnitFillerUpdatePath = '/document/storage-unit-filler/{id}';

  /**
   * Update storage unit filler by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerUpdate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFillerUpdate$Response(params: StorageUnitFillerUpdate$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitFillerDto>> {
    return storageUnitFillerUpdate(this.http, this.rootUrl, params, context);
  }

  /**
   * Update storage unit filler by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerUpdate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFillerUpdate(params: StorageUnitFillerUpdate$Params, context?: HttpContext): Observable<StorageUnitFillerDto> {
    return this.storageUnitFillerUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitFillerDto>): StorageUnitFillerDto => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerDelete()` */
  static readonly StorageUnitFillerDeletePath = '/document/storage-unit-filler/{id}';

  /**
   * Delete storage unit filler by its id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerDelete()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerDelete$Response(params: StorageUnitFillerDelete$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitFillerDelete(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete storage unit filler by its id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerDelete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerDelete(params: StorageUnitFillerDelete$Params, context?: HttpContext): Observable<void> {
    return this.storageUnitFillerDelete$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerCreate()` */
  static readonly StorageUnitFillerCreatePath = '/document/storage-unit-filler';

  /**
   * Create new storage unit filler
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerCreate()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFillerCreate$Response(params: StorageUnitFillerCreate$Params, context?: HttpContext): Observable<StrictHttpResponse<StorageUnitFillerDto>> {
    return storageUnitFillerCreate(this.http, this.rootUrl, params, context);
  }

  /**
   * Create new storage unit filler
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerCreate$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFillerCreate(params: StorageUnitFillerCreate$Params, context?: HttpContext): Observable<StorageUnitFillerDto> {
    return this.storageUnitFillerCreate$Response(params, context).pipe(
      map((r: StrictHttpResponse<StorageUnitFillerDto>): StorageUnitFillerDto => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerBulkWithdrawEntities()` */
  static readonly StorageUnitFillerBulkWithdrawEntitiesPath = '/document/storage-unit-filler/{id}/content/withdraw-entities';

  /**
   * Withdraw entities from current storage unit filler identified by id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerBulkWithdrawEntities()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFillerBulkWithdrawEntities$Response(params: StorageUnitFillerBulkWithdrawEntities$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitFillerBulkWithdrawEntities(this.http, this.rootUrl, params, context);
  }

  /**
   * Withdraw entities from current storage unit filler identified by id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerBulkWithdrawEntities$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  storageUnitFillerBulkWithdrawEntities(params: StorageUnitFillerBulkWithdrawEntities$Params, context?: HttpContext): Observable<void> {
    return this.storageUnitFillerBulkWithdrawEntities$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerProcessStorageUnitFillerBatch()` */
  static readonly StorageUnitFillerProcessStorageUnitFillerBatchPath = '/document/storage-unit-filler/process-batch';

  /**
   * Process storage unit filler batch
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerProcessStorageUnitFillerBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerProcessStorageUnitFillerBatch$Response(params?: StorageUnitFillerProcessStorageUnitFillerBatch$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return storageUnitFillerProcessStorageUnitFillerBatch(this.http, this.rootUrl, params, context);
  }

  /**
   * Process storage unit filler batch
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerProcessStorageUnitFillerBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerProcessStorageUnitFillerBatch(params?: StorageUnitFillerProcessStorageUnitFillerBatch$Params|null|undefined, context?: HttpContext): Observable<void> {
    return this.storageUnitFillerProcessStorageUnitFillerBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerFindContent()` */
  static readonly StorageUnitFillerFindContentPath = '/document/storage-unit-filler/{id}/content/search-api';

  /**
   * Find all instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerFindContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerFindContent$Response(params: StorageUnitFillerFindContent$Params, context?: HttpContext): Observable<StrictHttpResponse<PageStorageUnitFillerContentDto>> {
    return storageUnitFillerFindContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerFindContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerFindContent(params: StorageUnitFillerFindContent$Params, context?: HttpContext): Observable<PageStorageUnitFillerContentDto> {
    return this.storageUnitFillerFindContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageStorageUnitFillerContentDto>): PageStorageUnitFillerContentDto => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerFind()` */
  static readonly StorageUnitFillerFindPath = '/document/storage-unit-filler/search-api';

  /**
   * Find all instances based on criteria in request
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerFind$Response(params?: StorageUnitFillerFind$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<PageStorageUnitFillerDto>> {
    return storageUnitFillerFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Find all instances based on criteria in request
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerFind(params?: StorageUnitFillerFind$Params|null|undefined, context?: HttpContext): Observable<PageStorageUnitFillerDto> {
    return this.storageUnitFillerFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<PageStorageUnitFillerDto>): PageStorageUnitFillerDto => r.body)
    );
  }

  /** Path part for operation `storageUnitFillerGetScheduledRunAt()` */
  static readonly StorageUnitFillerGetScheduledRunAtPath = '/document/storage-unit-filler/get-scheduled-run-at';

  /**
   * Get the scheduled run time for the storage unit filler
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `storageUnitFillerGetScheduledRunAt()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerGetScheduledRunAt$Response(params?: StorageUnitFillerGetScheduledRunAt$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return storageUnitFillerGetScheduledRunAt(this.http, this.rootUrl, params, context);
  }

  /**
   * Get the scheduled run time for the storage unit filler
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `storageUnitFillerGetScheduledRunAt$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  storageUnitFillerGetScheduledRunAt(params?: StorageUnitFillerGetScheduledRunAt$Params|null|undefined, context?: HttpContext): Observable<string> {
    return this.storageUnitFillerGetScheduledRunAt$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

}
