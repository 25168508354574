import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FileState} from '|api/commons';
import {AbstractObjectSelectorComponent} from './abstract-object-selector.component';
import {FileDto} from '|api/document';
import {FILE_OBJECT_CLASSES} from '../shared-document.utils';
import {FilterOperator, FilterParam, SearchParams} from '@icz/angular-table';
import {WITHOUT_FILE_REF_NUMBER} from '../shared-business-components.model';

@Component({
  selector: 'icz-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileSelectorComponent extends AbstractObjectSelectorComponent<FileDto> {

  @Input()
  useUnrestrainedFileSearch = false;
  @Input()
  fileStatesCriteria: FileState[] = [FileState.OPEN];

  readonly objectClasses = FILE_OBJECT_CLASSES;
  readonly recentlyVisitedObjectsHeading = 'Naposledy navštívené spisy:';
  readonly withoutRefNumberText = WITHOUT_FILE_REF_NUMBER;

  protected additionalFilteringCriteriaFactory = () => ([
    {
      fieldName: 'fileState',
      operator: FilterOperator.inSet,
      value: String(this.fileStatesCriteria),
    },
  ] as FilterParam[]);

  protected findObjects(searchParams: SearchParams) {
    if (this.useUnrestrainedFileSearch) {
      return this.searchService.findFilesAllConstrained(searchParams);
    } else {
      return this.searchService.findFiles(searchParams);
    }
  }

}
