import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {NumberCounterFieldComponent} from './number-counter-field/number-counter-field.component';
import {NgClass} from '@angular/common';
import {ButtonComponent, IconComponent} from '@icz/angular-essentials';
import {TranslateModule} from '@ngx-translate/core';
import {FormFieldComponent} from '../../form-field/form-field.component';
import {Time} from '../../form-elements.model';

/**
 * Time selector emits partial selection value because the user might
 * make only partial choice (there are two counters in the component).
 */
export type TimeSelectorValue = Partial<Time>;

/**
 * Use-case of the time selector.
 */
export enum TimeSelectorUsage {
  /**
   * If in filter, the HH and MM fields are visible along with increment/decrement buttons and without an encompassing label.
   */
  FILTER = 'FILTER',
  /**
   * If in form, only HH and MM fields are visible and they have a common label.
   */
  FORM = 'FORM',
}

@Component({
  selector: 'icz-time-selector',
  templateUrl: './time-selector.component.html',
  styleUrls: ['./time-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NumberCounterFieldComponent,
    NgClass,
    IconComponent,
    TranslateModule,
    ButtonComponent,
    FormFieldComponent,
  ]
})
export class TimeSelectorComponent {

  /**
   * Initial value of hours.
   */
  @Input()
  set initialHours(hours: number) {
    this.hours ??= hours;
  }
  /**
   * Initial value of minutes.
   */
  @Input()
  set initialMinutes(minutes: number) {
    this.minutes ??= minutes;
  }
  /**
   * @see TimeSelectorUsage
   */
  @Input()
  timeSelectorUsage = TimeSelectorUsage.FILTER;
  /**
   * Time selector label similar to form field labels visible if timeSelectorUsage = FORM.
   */
  @Input()
  label = '';
  /**
   * If true, makes the time selector visually disabled.
   */
  @Input()
  fieldDisabled = false;
  /**
   * Emits a partial or whole time information once the user changes either HH or MM counter.
   */
  @Output()
  selectionChange = new EventEmitter<TimeSelectorValue>();

  protected hours: Nullable<number> = null;
  protected minutes: Nullable<number> = null;

  protected minutesChanged($event: number) {
    this.minutes = $event;
    this.emitCurrentValue();
  }

  protected hoursChanged($event: number) {
    this.hours = $event;
    this.emitCurrentValue();
  }

  protected emitCurrentValue() {
    this.selectionChange.emit({
      hours: this.hours!,
      minutes: this.minutes!,
    });
  }

  readonly TimeSelectorUsage = TimeSelectorUsage;

}
