import {inject, Injectable} from '@angular/core';
import {ToolbarProvider} from '../../table-toolbar-buttons.utils';
import {DocumentView, FileView} from './toolbar-common.utils';
import {BulkOperationValidationService} from '../../../../services/bulk-operation-validation.service';
import {GlobalLoadingIndicatorService} from '@icz/angular-essentials';
import {AbstractDocumentDialogsManagerService} from '../../../../services/abstract-document-dialogs-manager.service';
import {AuthorizedButton, AuthorizedButtonService} from '../../authorized-button.service';
import {TranslateService} from '@ngx-translate/core';
import {CounterTypeGroup, MainMenuCountsService} from '../../../../core/services/main-menu-counts.service';
import {DocumentOrFileDto, getObjectIcon, getObjectLabel, isDocumentEntity} from '../../shared-document.utils';
import {map, Observable, of} from 'rxjs';
import {Button} from '../../../button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {AuthorizedEntityType, DocumentAuthorizedOperation, FileAuthorizedOperation} from '|api/core';
import {ObjectClass} from '|api/commons';
import {CommonToolbarValidators} from './toolbar-common.validators';
import {DocumentToolbarDisablers} from './document-toolbar-buttons.disablers';
import {ApiAgendaTransferService, DocumentDto, FileDto, ReceivedDocumentDto} from '|api/document';
import {DocumentToolbarAction} from './document-toolbar-buttons.service';
import {IczModalService} from '@icz/angular-modal';
import {CurrentSessionService} from '../../../../services/current-session.service';
import {OrganizationalStructureService} from '../../../../core/services/organizational-structure.service';
import {DocumentDetailService} from '../../../../services/document-detail.service';
import {FileToolbarDisablers} from './file-toolbar-buttons.disablers';
import {FileToolbarAction} from './file-toolbar-buttons.service';
import {AbstractFileDialogsManagerService} from '../../../../services/abstract-file-dialogs-manager.service';
import {
  MoveToTransferEnvelopeDialogComponent,
  MoveToTransferEnvelopeDialogData
} from '../components/move-to-transfer-envelope-dialog/move-to-transfer-envelope-dialog.component';
import {
  ConfirmAgendaTransferDialogComponent
} from '../components/confirm-agenda-transfer-dialog/confirm-agenda-transfer-dialog.component';
import {DocumentToastService, DocumentToastType} from '../../../../core/services/notifications/document-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {esslErrorDtoToToastParameters} from '../../../notifications/toast.service';

export enum DocumentAndFileToolbarAction {
  SUSPEND_SETTLEMENT = 'SUSPEND_SETTLEMENT',
  RESUME_SETTLEMENT = 'RESUME_SETTLEMENT',
  MOVED_TO_AGENDA_TRANSFER = 'MOVED_TO_AGENDA_TRANSFER',
  REMOVED_FROM_AGENDA_TRANSFER = 'REMOVED_FROM_AGENDA_TRANSFER',
  AGENDA_TRANSFER_CONFIRMED = 'AGENDA_TRANSFER_CONFIRMED',
}

@Injectable()
export class DocumentAndFileToolbarButtonsService extends ToolbarProvider<DocumentOrFileDto, DocumentAndFileToolbarAction | DocumentToolbarAction | FileToolbarAction, DocumentView | FileView> {

  private bulkOperationValidationService = inject(BulkOperationValidationService);
  private globalLoading = inject(GlobalLoadingIndicatorService);
  private documentDialogService = inject(AbstractDocumentDialogsManagerService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private currentSessionService = inject(CurrentSessionService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private translateService = inject(TranslateService);
  private iczModalService = inject(IczModalService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private fileDialogsManager = inject(AbstractFileDialogsManagerService);
  private documentDetailService = inject(DocumentDetailService, {optional: true});
  private modalService = inject(IczModalService);
  protected apiAgendaTransferService = inject(ApiAgendaTransferService);
  private documentToastService = inject(DocumentToastService);

  getToolbarButtons(selection: Nullable<DocumentOrFileDto[]>, viewType: DocumentView | FileView): Observable<Button[]> {
    let buttons$: Observable<AuthorizedButton[]>;

    const isOnDocumentDetail = this.documentDetailService !== null;

    if (viewType === DocumentView.SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR) {
      buttons$ = of([
        {
          label: 'Editace lhůty kontroly událostí',
          icon: 'edit',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
          ],
          action: () => this.onRetentionYearEdit(selection!, true),
        },
        {
          label: 'Editace roku spouštěcí událostí',
          icon: 'edit',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
          ],
          action: () => this.onRetentionYearEdit(selection!, false),
        }
      ] as AuthorizedButton[]);
    } else if (viewType === DocumentView.AGENDA_TRANSFER_OBJECTS_SELECT) {
      buttons$ = of([
        {
          label: 'Předat ke spisové rozluce',
          icon: 'handover_to_distribution',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
          ],
          action: () => this.openMoveToTransferEnvelopeDialog(selection!),
        }
      ] as AuthorizedButton[]);
    } else if (viewType === DocumentView.AGENDA_TRANSFER_READY_TO_TRANSFER) {
      buttons$ = of([
        {
          label: 'Vyjmout z rozluky',
          icon: 'send_back',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
          ],
          action: () => this.removeFromAgendaTransferEnvelope(selection!),
        }
      ] as AuthorizedButton[]);
    } else if (viewType === DocumentView.AGENDA_TRANSFER_CONFIRMED) {
      buttons$ = of([
        {
          label: 'Nahrát potvrzení převzetí',
          icon: 'big_own_document',
          buttonDisablers: [],
          action: () => this.openConfirmAgendaTransferDialog(),
        }
      ] as AuthorizedButton[]);
    } else {
      buttons$ = of([
        {
          label: 'Úpravy',
          icon: 'edit',
          buttonDisablers: [
            CommonToolbarDisablers.isNoItemSelected(selection),
          ],
          submenuItems: [
            {
              label: 'Zrušení',
              isNestedSubmenu: true,
              submenuItems: [
                this.getDocumentDeactivateButton(selection, isOnDocumentDetail),
                this.getFileDeactivateButton(selection),
              ]
            },
            {
              label: 'Řízení',
              isNestedSubmenu: true,
              submenuItems: [
                this.getSuspendSettlementButton(selection),
                this.getResumeSettlementButton(selection),
              ]
            },
          ]
        }
      ] as AuthorizedButton[]);
    }
    return buttons$.pipe(map(buttons => this.authorizedButtonService.evaluateButtonDefinition(buttons)));
  }

  private onDocumentDeactivationClick(documentData: DocumentDto) {
    this.documentDialogService.openDeactivationDialog(documentData).subscribe( result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(DocumentToolbarAction.DOCUMENT_DEACTIVATED);
      }
    });
  }

  private onFileDeativationClick(fileData: FileDto) {
    this.fileDialogsManager.openDeactivationDialog(fileData).subscribe( result => {
      if (result) {
        this.mainMenuCountsService.updateMainMenuCounters([CounterTypeGroup.DOCUMENT_FILE_TASKS_RECEIVED_CONSIGNMENTS_TRANSFERS_COUNTS]);
        this.announceActionCompleted(FileToolbarAction.FILE_DEACTIVATED);
      }
    });
  }

  private esslObjectToValidationObject(object: DocumentOrFileDto) {
    return {
      entityId: object.id,
      authorizedEntityType: isDocumentEntity(object.entityType) ? AuthorizedEntityType.DOCUMENT : AuthorizedEntityType.FILE,
      entityIcon: getObjectIcon(object.objectClass as unknown as ObjectClass)!,
      entityData: object,
      entityName: getObjectLabel(this.translateService, object),
    };
  }

  private getSuspendSettlementButton(selection: Nullable<Array<DocumentOrFileDto>>): AuthorizedButton {
    return {
      label: 'Přerušit řízení',
      icon: '',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
        CommonToolbarDisablers.isFileOrDocumentSuspended(selection),
        CommonToolbarDisablers.isFileOrDocumentInExternalApp(selection),
      ],
      action: () => this.onSuspendSettlementClick(selection!),
    };
  }

  private getResumeSettlementButton(selection: Nullable<Array<DocumentOrFileDto>>): AuthorizedButton {
    return {
      label: 'Obnovit řízení',
      icon: '',
      buttonDisablers: [
        CommonToolbarDisablers.isNoItemSelected(selection),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
        CommonToolbarDisablers.isFileOrDocumentNotSuspended(selection),
      ],
      action: () => this.onResumeSettlementClick(selection!),
    };
  }

  private getDocumentDeactivateButton(selection: Nullable<Array<DocumentOrFileDto>>, isOnDocumentDetail: boolean): AuthorizedButton {
    return {
      label: 'Zrušit dokument',
      authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_DEACTIVATE],
      buttonDisablers: [
        CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
        DocumentToolbarDisablers.selectedItemIsNotDocument(selection as DocumentDto[]),
        DocumentToolbarDisablers.isNotDocumentOwnerOrSupervisor(this.currentSessionService, this.organizationalStructureService, selection as DocumentDto[]),
        DocumentToolbarDisablers.isParentFileRunningActivity(selection as DocumentDto[], this.documentDetailService!, isOnDocumentDetail),
        DocumentToolbarDisablers.isDocumentUnopened(selection as ReceivedDocumentDto[]), // deactivating unopened document is done via "Document has no official content" action
        CommonToolbarDisablers.isEsslObjectLocked(selection),
      ],
      action: () => this.onDocumentDeactivationClick(selection![0] as DocumentDto),
    };
  }

  private getFileDeactivateButton(selection: Nullable<Array<DocumentOrFileDto>>): AuthorizedButton {
    return {
      label: 'Zrušit spis',
      authorizedOperations: [FileAuthorizedOperation.FILE_DEACTIVATE],
      buttonDisablers: [
        CommonToolbarDisablers.isNoOrMultipleItemsSelected(selection),
        FileToolbarDisablers.selectedItemIsNotFile(selection as FileDto[]),
        FileToolbarDisablers.isSomeFileDeactivated(selection as FileDto[]),
        FileToolbarDisablers.isSomeFileNotOpen(selection as FileDto[]),
        FileToolbarDisablers.isSomeFileDeactivetePreconditionNotMet(
          this.currentSessionService,
          this.organizationalStructureService,
          selection as FileDto[]
        ),
        CommonToolbarDisablers.isEsslObjectLocked(selection),
      ],
      action: () => this.onFileDeativationClick(selection![0] as FileDto),
    };
  }

  private onSuspendSettlementClick(selectedObjects: DocumentOrFileDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<DocumentOrFileDto>(
      {
        dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést přerušení řízení. Hromadné přerušení řízení bude provedeno jen s vyhovujícími objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          CommonToolbarValidators.isEsslObjectLocked(),
          CommonToolbarValidators.isFileOrDocumentSuspended(),
          CommonToolbarValidators.isFileOrDocumentInExternalApp()
        ],
        esslObjects: selectedObjects.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_SETTLE, FileAuthorizedOperation.FILE_SETTLE],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openSuspendSettlementDialog(validatedObjects).subscribe(dialogResult => {
          if (dialogResult) {
            this.announceActionCompleted(DocumentAndFileToolbarAction.SUSPEND_SETTLEMENT);
          }
        });
      }
    });
  }

  private onResumeSettlementClick(selectedObjects: DocumentOrFileDto[]) {
    this.bulkOperationValidationService.validateEsslObjects<DocumentOrFileDto>(
      {
        dialogWarningLabel: 'Pro některé objekty ({{errorCount}}) není možno provést obnovení řízení. Hromadné obnovení řízení bude provedeno jen s vyhovujícími objekty ({{successCount}}).',
        dialogWarningLabelContext: {},
        operationValidators: [
          CommonToolbarValidators.isEsslObjectLocked(),
          CommonToolbarValidators.isFileOrDocumentNotSuspended()
        ],
        esslObjects: selectedObjects.map(dd => this.esslObjectToValidationObject(dd)),
        authorizedOperations: [DocumentAuthorizedOperation.DOCUMENT_SETTLE, FileAuthorizedOperation.FILE_SETTLE],
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openResumeSettlementDialog(validatedObjects).subscribe(dialogResult => {
          if (dialogResult) {
            this.announceActionCompleted(DocumentAndFileToolbarAction.RESUME_SETTLEMENT);
          }
        });
      }
    });
  }

  private onRetentionYearEdit(selectedObjects: DocumentOrFileDto[], editCheckYear: boolean) {
    const validationMessage = editCheckYear ? 'Pro některé objekty ({{errorCount}}) není možno provést editaci lhůty kontroly události. Hromadné akce bude provedena jen s vyhovujícími objekty ({{successCount}}).' :
      'Pro některé objekty ({{errorCount}}) není možno provést editaci roku spouštěcí události. Hromadné akce bude provedena jen s vyhovujícími objekty ({{successCount}}).';
    this.bulkOperationValidationService.validateEsslObjects<DocumentOrFileDto>(
      {
        dialogWarningLabel: validationMessage,
        dialogWarningLabelContext: {},
        operationValidators: [
          CommonToolbarValidators.isEsslObjectLocked()
        ],
        esslObjects: selectedObjects.map(dd => this.esslObjectToValidationObject(dd)),
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.documentDialogService.openRetentionYearEditDialog(validatedObjects, editCheckYear).subscribe(dialogResult => {
          if (dialogResult) {
            this.announceActionCompleted(DocumentAndFileToolbarAction.RESUME_SETTLEMENT);
          }
        });
      }
    });
  }

  private openMoveToTransferEnvelopeDialog(selectedObjects: DocumentOrFileDto[]) {
    const validationMessage = 'Pro některé objekty ({{errorCount}}) není možno provést předání k rozluce. Hromadná akce bude provedena jen s vyhovujícími objekty ({{successCount}}).';
    this.bulkOperationValidationService.validateEsslObjects<DocumentOrFileDto>(
      {
        dialogWarningLabel: validationMessage,
        dialogWarningLabelContext: {},
        operationValidators: [
          CommonToolbarValidators.isEsslObjectLocked()
        ],
        esslObjects: selectedObjects.map(dd => this.esslObjectToValidationObject(dd)),
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.modalService.openComponentInModal<boolean, MoveToTransferEnvelopeDialogData>({
          component: MoveToTransferEnvelopeDialogComponent,
          modalOptions: {
            width: 600,
            height: 400,
            titleTemplate: validatedObjects.length > 1 ? 'Hromadné předání k rozluce ({{count}})' : 'Předání k rozluce {{refNumber}}',
            titleTemplateContext: {
              count: String(selectedObjects.length),
              refNumber: selectedObjects[0].refNumber!,
            }
          },
          data: {
            selectedObjects
          }
        }).subscribe(dialogResult => {
          if (dialogResult) {
            this.announceActionCompleted(DocumentAndFileToolbarAction.MOVED_TO_AGENDA_TRANSFER);
          }
        });
      }
    });
  }

  private openConfirmAgendaTransferDialog() {
    this.modalService.openComponentInModal<boolean, void>({
      component: ConfirmAgendaTransferDialogComponent,
      modalOptions: {
        width: 600,
        height: 400,
        titleTemplate: 'Potvrzení spisové rozluky',
        titleTemplateContext: {}
      }
    }).subscribe(dialogResult => {
      if (dialogResult) {
        this.announceActionCompleted(DocumentAndFileToolbarAction.AGENDA_TRANSFER_CONFIRMED);
      }
    });
  }

  private removeFromAgendaTransferEnvelope(selectedObjects: DocumentOrFileDto[]) {
    const validationMessage = 'Pro některé objekty ({{errorCount}}) není možno provést vyjmutí z rozluky. Hromadná akce bude provedena jen s vyhovujícími objekty ({{successCount}}).';
    this.bulkOperationValidationService.validateEsslObjects<DocumentOrFileDto>(
      {
        dialogWarningLabel: validationMessage,
        dialogWarningLabelContext: {},
        operationValidators: [
          CommonToolbarValidators.isEsslObjectLocked()
        ],
        esslObjects: selectedObjects.map(dd => this.esslObjectToValidationObject(dd)),
      },
      this,
    ).subscribe(validatedObjects => {
      if (validatedObjects && validatedObjects.length > 0) {
        this.globalLoading.doLoading(
          this.apiAgendaTransferService.agendaTransferUnmarkForTransfer({
            body: {
              operationEntityDtoList: validatedObjects.map(obj => ({id: obj.id!, entityType: obj.entityType!})),
            }
          })
        ).subscribe({
          next: _ => {
            this.announceActionCompleted(DocumentAndFileToolbarAction.REMOVED_FROM_AGENDA_TRANSFER);
            this.documentToastService.dispatchBulkOperationToast(DocumentToastType.BULK_AGENDA_TRANSFER_REMOVE, {
              [InternalNotificationKey.COUNT]: validatedObjects.length
            });
          },
          error: err => {
            const errorData = esslErrorDtoToToastParameters(this.translateService, err.error);
            this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.BULK_AGENDA_TRANSFER_REMOVE_ERROR, errorData);
          }
        });
      }
    });
  }
}
