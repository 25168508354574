import {ChangeDetectionStrategy, Component, forwardRef, inject, Input, TemplateRef, ViewChild} from '@angular/core';
import {AbstractSelectField} from '../common/abstract-select-field';
import {
  ComposableFormAutocompleteComponent
} from './composable-form-autocomplete/composable-form-autocomplete.component';
import {
  FormAutocompleteListComponent,
  IAutocompleteNoResultsContext
} from './form-autocomplete-list/form-autocomplete-list.component';
import {makeDefaultOptionsDefinition} from './form-autocomplete.model';
import {LoadingIndicatorService, TooltipDirective} from '@icz/angular-essentials';
import {
  FormAutocompleteListTextItemComponent
} from './form-autocomplete-list-text-item/form-autocomplete-list-text-item.component';
import {AsyncPipe, NgTemplateOutlet} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {FormAutocompleteChipComponent} from './form-autocomplete-chip/form-autocomplete-chip.component';
import {ValidationErrorsListComponent} from '../validators/validation-errors-list/validation-errors-list.component';
import {IczOption} from '../form-elements.model';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

/**
 * A selector with a flat list of available values, similar to HTML <select> field.
 *
 * DEV notes:
 * Responsibilities:
 * - Defining desired @Inputs and @Outputs
 * - Composing the autocomplete itself from other components (see component template)
 * - Passing @Inputs to inner components
 *
 * More details about creating your own autocomplete
 * components are in ComposableFormAutocomplete JSDoc
 */
@Component({
  selector: 'icz-form-autocomplete',
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormAutocompleteListTextItemComponent,
    FormAutocompleteListComponent,
    AsyncPipe,
    TranslateModule,
    TooltipDirective,
    FormAutocompleteChipComponent,
    NgTemplateOutlet,
    ComposableFormAutocompleteComponent,
    ValidationErrorsListComponent,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => FormAutocompleteComponent),
  }],
})
export class FormAutocompleteComponent<T extends string | number = string | number> extends AbstractSelectField<T> {

  protected loadingService = inject(LoadingIndicatorService);

  @ViewChild('composableFormAutocomplete')
  private composableFormAutocomplete!: ComposableFormAutocompleteComponent;

  @Input()
  searchIndexer: Nullable<(o: IczOption<T>) => string>;
  @Input()
  noResultsTemplate: Nullable<TemplateRef<IAutocompleteNoResultsContext>>;
  /**
   * @internal
   * @deprecated
   */
  @Input()
  optionsDefinitionFactory = makeDefaultOptionsDefinition;
  /**
   * Minimal search term length. The component will not perform any option lookup if search term length is lower than this.
   */
  @Input()
  minSearchTermLength = 0;
  /**
   * Used for disabling rendering of validator error messages if its value is FALSE.
   * Usually needed only in very specific use-cases for wrapping autocompletes in more complex form controls with their own validations.
   */
  @Input()
  showValidationStatus = true;
  /**
   * @see OptionItemComponent.hideDefaultTooltip
   */
  @Input()
  hideDefaultTooltips = false;
  /**
   * Upper limit of number of text/chip rows in selected value field.
   */
  @Input()
  autoSizeMax = 1;
  /**
   * If true, displays a loading indicator when the autocomplete loads asynchronously available options.
   */
  @Input()
  allowLoadingIndicator: Nullable<boolean>;
  /**
   * Hides internal search field.
   */
  @Input()
  hideSearchField = false;
  /**
   * A special visually distinguished label of the field to the right of the ordinary label.
   */
  @Input()
  rightLabel: Nullable<string>;
  /**
   * Tooltip of the right label.
   * Applicable only if rightLabel is non-null.
   */
  @Input()
  rightLabelTooltip: Nullable<string>;
  /**
   * A flag determining if clicking the right label will open a popover.
   * Applicable only if rightLabel is non-null.
   */
  @Input()
  showRightLabelPopupOnClick = false;

  /**
   * Programmatically closes options list. Applicable only if asPopover is TRUE.
   */
  closeOptions() {
    this.composableFormAutocomplete.closeOptions(true);
  }

}
