import {inject, Injectable, Injector} from '@angular/core';
import {IczModalService} from '@icz/angular-modal';
import {
  IszrQueryDialogComponent,
  IszrQueryDialogData,
  IszrQueryDialogResult
} from './iszr-query-dialog/iszr-query-dialog.component';
import {switchMap, tap} from 'rxjs/operators';
import {constructSubjectName} from '../model/subjects.model';
import {SubjectToastService, SubjectToastType} from '../../../core/services/notifications/subject-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {SubjectTemplateUtils} from '../../../utils/subject-template-utils';
import {EditSubjectDialogMode} from './edit-subject/edit-subject-dialog.component';
import {map, Observable, of} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SubjectIszrIdentificationService {

  private modalService = inject(IczModalService);
  private subjectToastService = inject(SubjectToastService);
  private iczModalService = inject(IczModalService);

  tryIdentifySubject(data: IszrQueryDialogData, showIszrDetailDialog: boolean, injector: Injector): Observable<IszrQueryDialogResult> {
    return this.modalService.openComponentInModal<IszrQueryDialogResult, IszrQueryDialogData>({
      component: IszrQueryDialogComponent,
      modalOptions: {
        width: 1000,
        height: 720,
        titleTemplate: 'Ztotožnění subjektu',
        injector,
      },
      data,
    }).pipe(
      tap(result => {
        if (result?.subject) {
          const name = constructSubjectName(result.subject);
          this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_IDENTIFY_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
        }
      }),
      switchMap(result => {
        if (showIszrDetailDialog && result?.subject) {
          return SubjectTemplateUtils.openEditSubjectModal(this.iczModalService, result.subject, EditSubjectDialogMode.ISZR_VIEW)
            .pipe(map(modalResult => {
              return {subject: modalResult, valueCorrectionMode: result.valueCorrectionMode};
          }));
        }
        else {
          return of(result);
        }
      })
    );
  }

}
