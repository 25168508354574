<form [formGroup]="form">
  <div class="gap-24 row justify-between">
    <div class="row gap-24 mt-16 mb-32">
      <icz-heading [label]="pageTitle" level="3" class="my-0"></icz-heading>
      <div class="row gap-16 mb-8">
        @if (isCreateOfficerFullDocument || isFilingOfficeCreate) {
          <icz-checkbox
          label="Neotvírat" formControlName="unopened"></icz-checkbox>
        }
        @if (isCreateFilingOfficePaper) {
          <icz-checkbox
          label="Do vlastních rukou" formControlName="toOwnHands"></icz-checkbox>
        }
      </div>
    </div>
    @if (isRegisterAnyContent && isConsignmentToOwnHands && isUnopenedDocument) {
      <icz-button (onAction)="onNoOfficialContentClick()" svgIcon="closed" label="Nemá úřední obsah"></icz-button>
    }
  </div>

  <div class="row gap-64">
    <div class="col grow">
      @if (isFilingOfficeCreate && (distributionNodeOptions?.length ?? 0) > 1) {
        <icz-form-autocomplete
          label="Podatelna" [options]="distributionNodeOptions"
          formControlName="targetDistributionNodeId"
          [clearable]="false"
        ></icz-form-autocomplete>
      }

      <icz-receive-paper-consignment-set-dispatch class="mb-16"
        [form]="form"
        [receivedDocumentUsage]="receivedDocumentUsage"
        (distributionClassDtoChanged)="distributionClassDtoChange($event)">
      </icz-receive-paper-consignment-set-dispatch>

      @if (isSingleDocumentConsignment) {
        @if (isRegisterContentFilingOfficePersonal || isCreateFilingOfficePersonal) {
          <ng-container [ngTemplateOutletContext]="{receivedDocument: firstDocumentWithHandoverForm}"
          [ngTemplateOutlet]="receivedDocumentWithHandover"></ng-container>
        }
        @if (isRegisterContentOfficerPersonal || isCreateOfficerFullDocument) {
          <ng-container [ngTemplateOutletContext]="{receivedDocument: firstDocumentNoHandoverForm}"
          [ngTemplateOutlet]="receivedDocumentNoHandover"></ng-container>
        }
      }
      @else {
        <ng-container [formGroupName]="manyDocumentsFormGroupName">
          @for (receivedDocument of manyDocumentsControl.controls; track receivedDocument) {
            <div [formGroupName]="$index">
              <icz-section label="{{documentSectionLabel($index)}}" class="mb-64"
                [expanded]="receivedDocumentExpandedIndex === $index" [showExpansionHint]="true" [storeClosedState]="false">
                @if ($index > 0) {
                  <icz-button background="transparent"
                    sectionHeaderAppendix size="small" svgIcon="delete"
                    (onAction)="removeReceivedDocument($index)" class="mx-16"
                  ></icz-button>
                }
                @if (isFilingOffice) {
                  <ng-container [ngTemplateOutlet]="receivedDocumentWithHandover" [ngTemplateOutletContext]="{receivedDocument: receivedDocument}"></ng-container>
                }
                @if (isOfficer) {
                  <ng-container [ngTemplateOutlet]="receivedDocumentNoHandover" [ngTemplateOutletContext]="{receivedDocument: receivedDocument}"></ng-container>
                }
              </icz-section>
            </div>
          }
          @if (!isCreateUnopened) {
            <icz-button label="Přidat další dokument" (onAction)="addReceivedDocument()" class="mt-32"></icz-button>
          }
        </ng-container>
      }


    </div>

    <ng-template #receivedDocumentNoHandover>
      <icz-received-document-form
        [distributionClass]="distributionClassDto"
        [receivedDocumentUsage]="receivedDocumentUsage"
        [disableAssignRegNr]="disableAssignRegNr"
        [canAddAnalogComponentsByOpenedState]="canAddComponentsByOpenedState$! | async"
        (canAddDigitalComponentsByDocumentForm)="canAddDigitalComponentsByDocumentForm$.next($event)"
        [form]="firstDocumentNoHandoverForm"
        [resetForm]="resetSearchForm"
        [enableEntityClassPrefill]="enableEntityClassPrefill"
      ></icz-received-document-form>
    </ng-template>

    <ng-template #receivedDocumentWithHandover let-receivedDocument='receivedDocument'>
      <icz-received-document-with-handover-form [form]="$any(receivedDocument)"
        [receivedDocumentUsage]="receivedDocumentUsage"
        [disableAssignRegNr]="disableAssignRegNr"
        [canAddAnalogComponentsByOpenedState]="canAddComponentsByOpenedState$! | async"
        (canAddDigitalComponentsByDocumentForm)="canAddDigitalComponentsByDocumentForm$.next($event)"
        [distributionClass]="distributionClassDto"
        [resetForm]="resetSearchForm">
      </icz-received-document-with-handover-form>
    </ng-template>

    <div class="col grow">
      <icz-validation-errors-list [control]="senderDefinitionControl" [fieldsValidationErrors]="[]" [wrapInAlert]="true" [useFocusableArea]="true"></icz-validation-errors-list>

      @if (isRegisterAnyContent && unopenedConsignment && distributionClassDto) {
        <icz-receive-consignment-display-sender
          [senderSubjectId]="unopenedConsignment.sender!"
          [form]="form"
          [isTelefaxConsignment]="false"
          [consignmentDistributionClass]="distributionClassDto.distributionClassValue"
          [receivedPaperConsignment]="unopenedConsignment"
          (senderRemoved)="wasSenderRemoved = true"
          [isDocumentDeactivated]="false">
        </icz-receive-consignment-display-sender>
      }

      @if (isCreateAnyConsignment || wasSenderRemoved) {
        <icz-receive-consignment-set-sender
          sectionLabel="Odesílatel zásilky"
          (anonymousSenderSet)="anonymousSenderSet($event)"
          (senderSet)="setSender($event)"
          (senderAddressSet)="setSenderAddress($event)"
          (blockingAction)="blockingAction.emit($event)"
        ></icz-receive-consignment-set-sender>
      }

      @if (unopenedConsignment && form.value.originalSender) {
        <icz-section
          [boxed]="true" [expanded]="false" label="Údaje o odesílateli zadané při přijetí">
          <icz-form-field class="grow" formControlName="originalSender" label="Původní odesílatel"></icz-form-field>
        </icz-section>
      }
    </div>
  </div>

  @if ((isCreateFilingOfficePersonal || isRegisterContentOfficerPersonal || isCreateOfficerFullDocument) && (canAddDigitalComponents$! | async)) {
    <icz-receive-document-digital-components
      [receivedDocumentUsage]="receivedDocumentUsage"
      [form]="firstDocumentForDigitalComponents">
    </icz-receive-document-digital-components>
  }
</form>
