import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Observable, of} from 'rxjs';
import {CirculationActivityFlowType, CirculationActivityType, EntityType} from '|api/commons';
import {ApiCirculationActivityService, CirculationActivityDto} from '|api/flow';
import {ActivityStepsPreviewComponent} from './activity-steps-preview/activity-steps-preview.component';
import {AssignedTaskInfo, TaskInfoType} from '../task-info-bit/task-info.model';
import {COMPONENT_ENTITY_TYPES} from '../shared-document.utils';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {EsslComponentModalService} from '../../../services/essl-component-modal.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ICZ_MODAL_DATA, IczModalRef} from '@icz/angular-modal';

export interface QuickActivityPreviewModalData {
  activityId: number;
}


@Component({
  selector: 'icz-quick-activity-preview',
  templateUrl: './quick-activity-preview.component.html',
  styleUrls: ['./quick-activity-preview.component.scss']
})
export class QuickActivityPreviewComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private cd = inject(ChangeDetectorRef);
  private esslComponentModalService = inject(EsslComponentModalService);
  private apiCirculationActivityService = inject(ApiCirculationActivityService);
  private destroyRef = inject(DestroyRef);
  private modalData = inject<Nullable<QuickActivityPreviewModalData>>(ICZ_MODAL_DATA, {optional: true});
  private modalRef = inject<IczModalRef<Nil>>(IczModalRef, {optional: true});

  // ActivityStepsPreviewComponent and this component are always meant to be
  // used in pair thus their coupling is fine. ActivityStepsPreviewComponent is
  // standalone just because we needed to implement recursive drawing of childActivities.
  @ViewChild('rootActivity')
  rootActivity!: ActivityStepsPreviewComponent;

  // Inputs `activity` and `activityId` are mutually exclusive
  @Input()
  activity: Nullable<CirculationActivityDto>;
  @Input()
  activityId: Nullable<number>;
  @Input()
  showLink = true;
  @Output()
  circulationActionCompleted = new EventEmitter<void>();

  get instruction(): Nullable<string> {
    if (this.rootActivity.activityTasksInfo?.length && (this.activity!.activityType !== CirculationActivityType.DOCUMENT_ISSD_HANDOVER)) {
      return (this.rootActivity.activityTasksInfo.find(
        info => info.taskInfoType === TaskInfoType.ASSIGNED && info.instruction
      ) as Nullable<AssignedTaskInfo>)?.instruction ?? '';
    }
    else {
      return '';
    }
  }

  @Input()
  isMultiColumn = true;

  get activityHeading(): string {
    switch (this.activity?.activityType) {
      case CirculationActivityType.FILE_HANDOVER:
      case CirculationActivityType.FILE_OWNERSHIP_TRANSFER:
      case CirculationActivityType.DOCUMENT_HANDOVER:
      case CirculationActivityType.DOCUMENT_OWNERSHIP_TRANSFER:
        if (this.activity.circulationEntityType === EntityType.FILE) {
          return 'Spis předán';
        }
        else {
          return 'Dokument předán';
        }
      case CirculationActivityType.DOCUMENT_APPROVAL:
      case CirculationActivityType.COMPONENT_APPROVAL:
        return 'Dáno ke schválení';
      case CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT:
        return 'Dáno na vědomí';
      case CirculationActivityType.DOCUMENT_STATEMENT:
        return 'Dáno k vyjádření';
      case CirculationActivityType.COMPONENT_SIGNING:
        return 'Dáno k podpisu';
      case CirculationActivityType.FILE_ISSD_HANDOVER:
      case CirculationActivityType.DOCUMENT_ISSD_HANDOVER:
        return 'Předání externí aplikaci';
      case CirculationActivityType.FILE_ISSD_RETURN_OR_REJECT:
      case CirculationActivityType.DOCUMENT_ISSD_RETURN_OR_REJECT:
        return 'Vrácení z externí aplikaci';
      default:
        return 'Předáno';
    }
  }

  get isIssdActivity() {
    return (this.activity && (
      this.activity.activityType === CirculationActivityType.FILE_ISSD_HANDOVER ||
      this.activity.activityType === CirculationActivityType.DOCUMENT_ISSD_HANDOVER ||
      this.activity.activityType === CirculationActivityType.DOCUMENT_ISSD_RETURN_OR_REJECT ||
      this.activity.activityType === CirculationActivityType.FILE_ISSD_RETURN_OR_REJECT));
  }

  get deadlineDescription(): string {
    switch (this.activity?.activityType) {
      case CirculationActivityType.FILE_HANDOVER:
      case CirculationActivityType.FILE_OWNERSHIP_TRANSFER:
      case CirculationActivityType.DOCUMENT_HANDOVER:
      case CirculationActivityType.DOCUMENT_OWNERSHIP_TRANSFER:
        return 'předat do';
      case CirculationActivityType.DOCUMENT_APPROVAL:
        return 'schválit do';
      case CirculationActivityType.DOCUMENT_ACKNOWLEDGEMENT:
        return 'vzít na vědomí do';
      case CirculationActivityType.DOCUMENT_STATEMENT:
        return 'vyjádřit se do';
      default:
        return 'vyřešit do';
    }
  }

  get isComponentCirculation() {
    return COMPONENT_ENTITY_TYPES.includes(this.activity?.circulationEntityType);
  }

  readonly CirculationActivityType = CirculationActivityType;
  readonly CirculationActivityFlowType = CirculationActivityFlowType;

  ngOnInit(): void {
    if (this.modalData && this.modalData.activityId) {
      this.activityId = this.modalData.activityId;
    }

    let activity$: Observable<CirculationActivityDto>;

    if (!this.activity && this.activityId) {
      activity$ = this.apiCirculationActivityService.circulationActivityFindById(
        {id: this.activityId}
      );
    }
    else {
      activity$ = of(this.activity!);
    }

    this.loadingService.doLoading(activity$, this).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(activity => {
      this.activity = activity;
      this.cd.detectChanges();
    });
  }

  openCirculationComponent() {
    this.esslComponentModalService.openEsslComponentDetailWithFullFetch(
      this.activity!.componentId!,
      this.activity!.digitalComponentVersionId,
      this.activity!.documentId!,
      true,
      true,
    ).subscribe(componentChanged => {
      if (componentChanged) {
        this.circulationActionCompleted.emit();
      }
    });
  }

}
