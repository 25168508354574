import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {EntityType, RelatedObjectDto, RelatedObjectType} from '|api/commons';
import {
  ApiAuthorizationService,
  AuthorizedEntityType,
  DocumentAuthorizedOperation,
  FileAuthorizedOperation
} from '|api/core';
import {ApiCrossReferenceService} from '|api/document';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {ElasticDocumentOrFile} from '../model/document-file-sidebar.model';
import {
  GeneralAuthorizationResult,
  GeneralAuthorizedOperation,
  getInsufficientPermissionsTooltip,
  isAuthorizedOperationGranted
} from '../permissions/permissions.utils';
import {
  DOCUMENT_ENTITY_TYPES,
  getDetailUriByObject,
  isFileObject,
  isReceivedDocumentObject
} from '../shared-document.utils';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {EsslEntity} from '../model/entity.model';
import {ApiSubjectRecordElasticService} from '|api/subject-register';

interface SectionWithCount {
  id: DocumentFileSidebarSections;
  disabled: boolean;
  showCount: boolean;
  count?: number;
}

enum DocumentFileSidebarSections {
  OVERVIEW = 'Přehled',
  DELIVERY = 'Doručení',
  RELATED = 'Související',
  SUBJECTS = 'Subjekty',
}

@Component({
  selector: 'icz-document-file-sidebar',
  templateUrl: './document-file-sidebar.component.html',
  styleUrls: ['./document-file-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentFileSidebarComponent implements OnInit, IczOnChanges {

  private router = inject(Router);
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private loadingService = inject(LoadingIndicatorService);
  private apiCrossReferenceService = inject(ApiCrossReferenceService);
  private apiAuthorizationService = inject(ApiAuthorizationService);
  private apiSubjectRecordElasticService = inject(ApiSubjectRecordElasticService);

  @Input() opened = false;
  @Input({required: true}) object: Nullable<ElasticDocumentOrFile>;

  activeSection = DocumentFileSidebarSections.OVERVIEW;

  @Output()
  closed = new EventEmitter<void>();

  readonly DocumentFileSidebarSections = DocumentFileSidebarSections;

  showRelatedObjectsAuthorizedOperation!: GeneralAuthorizedOperation;
  showSubjectsAuthorizedOperation!: GeneralAuthorizedOperation;
  authorizationResult!: GeneralAuthorizationResult;

  canUserAccessSubjects = true;
  canUserAccessRelatedObjects = true;

  _sections: SectionWithCount[] = [];
  get sections(): SectionWithCount[] {
    return this._sections;
  }
  set sections(sections: SectionWithCount[]) {
    this._sections = sections;
  }

  setSections() {
    this.sections = [
      {id: DocumentFileSidebarSections.OVERVIEW, disabled: false, showCount: false},
      {id: DocumentFileSidebarSections.RELATED, disabled: false, showCount: true, count: 0},
      {id: DocumentFileSidebarSections.SUBJECTS, disabled: false, showCount: true, count: 0},
    ];

    if (this.isReceivedDocument) {
      this.sections.splice(1, 0, {id: DocumentFileSidebarSections.DELIVERY, disabled: false, showCount: false});
    }

    this.activeSection = DocumentFileSidebarSections.OVERVIEW;
  }

  get isFileObject() {
    return isFileObject(this.object);
  }

  get isReceivedDocument() {
    return isReceivedDocumentObject(this.object);
  }

  get hasDocumentForFullPreview(): boolean {
    return !isNil(this.object) && this.opened;
  }

  ngOnInit() {
    this.setSections();
  }

  isSectionImplemented(section: SectionWithCount) {
    return (
      section.id === DocumentFileSidebarSections.OVERVIEW ||
      section.id === DocumentFileSidebarSections.SUBJECTS ||
      section.id === DocumentFileSidebarSections.RELATED ||
      section.id === DocumentFileSidebarSections.DELIVERY
    );
  }

  openDetail() {
    const detailUri = getDetailUriByObject(this.object);
    this.router.navigate([detailUri]);
  }

  updateSubjectsTab() {
    const subjectsSection = this.sections.find(s => s.id === DocumentFileSidebarSections.SUBJECTS)!;

    subjectsSection.disabled = !this.canUserAccessSubjects;

    if (this.object && this.canUserAccessSubjects) {
      const related: RelatedObjectDto = {
        relatedObjectId: this.object.id,
        relatedObjectType: RelatedObjectType.DOCUMENT
      };

      this.apiSubjectRecordElasticService.subjectRecordElasticGetRelatedSubjectCount({body: related}).subscribe(count => {
        subjectsSection.count = count;
      });
    }
    else {
      subjectsSection.count = 0;
    }
  }

  updateRelatedObjectsTab() {
    const relatedSection: SectionWithCount = this.sections.find(s => s.id === DocumentFileSidebarSections.RELATED)!;

    relatedSection.disabled = !this.canUserAccessRelatedObjects;

    if (this.object && this.canUserAccessRelatedObjects) {
      this.apiCrossReferenceService.crossReferenceGetCount({entityId: this.object.id!, entityType: (this.object as unknown as EsslEntity).entityType!}).subscribe(count => {
        relatedSection.count = count;
      });
    }
    else {
      relatedSection.count = 0;
    }
  }

  getSidebarTabTooltip(section: SectionWithCount): Nullable<string> {
    if (
      (section.id === DocumentFileSidebarSections.SUBJECTS && !this.canUserAccessSubjects) ||
      (section.id === DocumentFileSidebarSections.RELATED && !this.canUserAccessRelatedObjects)
    ) {
      const tooltipData = getInsufficientPermissionsTooltip(
        this.authorizationResult,
        this.showSubjectsAuthorizedOperation,
        this.translateService
      );

      return this.translateParser.interpolate(
        tooltipData?.tooltip ?? '',
        tooltipData?.tooltipContext ?? {}
      );
    }
    else {
      return null;
    }
  }

  activateSection(section: SectionWithCount) {
    if (!section.disabled) {
      this.activeSection = section.id;
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes?.object?.currentValue && this.object) {
      this.setSections();

      let authorizationResult$: Observable<GeneralAuthorizationResult>;

      if (DOCUMENT_ENTITY_TYPES.includes(this.object.entityType!)) {
        this.showRelatedObjectsAuthorizedOperation = DocumentAuthorizedOperation.DOCUMENT_SHOW_RELATED_OBJECTS;
        this.showSubjectsAuthorizedOperation = DocumentAuthorizedOperation.DOCUMENT_SHOW_SUBJECTS;

        authorizationResult$ = this.apiAuthorizationService.authorizationAuthorizeDocumentOperations({
          body: {
            authorizedEntityId: this.object.id,
            authorizedEntityType: AuthorizedEntityType.DOCUMENT,
            operationsToAuthorize: [
              this.showRelatedObjectsAuthorizedOperation,
              this.showSubjectsAuthorizedOperation,
            ],
          }
        });
      }
      else if (this.object.entityType === EntityType.FILE) {
        this.showRelatedObjectsAuthorizedOperation = FileAuthorizedOperation.FILE_SHOW_RELATED_OBJECTS;
        this.showSubjectsAuthorizedOperation = FileAuthorizedOperation.FILE_SHOW_SUBJECTS;

        authorizationResult$ = this.apiAuthorizationService.authorizationAuthorizeFileOperations({
          body: {
            authorizedEntityId: this.object.id,
            authorizedEntityType: AuthorizedEntityType.FILE,
            operationsToAuthorize: [
              this.showRelatedObjectsAuthorizedOperation,
              this.showSubjectsAuthorizedOperation,
            ],
          }
        });
      }

      this.loadingService.doLoading(
        authorizationResult$!,
        this,
      ).subscribe(authorizationResult => {
        this.authorizationResult = authorizationResult;
        this.canUserAccessSubjects = isAuthorizedOperationGranted(authorizationResult, this.showRelatedObjectsAuthorizedOperation);
        this.canUserAccessRelatedObjects = isAuthorizedOperationGranted(authorizationResult, this.showSubjectsAuthorizedOperation);

        if (this.canUserAccessSubjects) {
          this.updateSubjectsTab();
        }
        if (this.canUserAccessRelatedObjects) {
          this.updateRelatedObjectsTab();
        }
      });
    }
  }

}
