import {Component, inject, ViewChild} from '@angular/core';
import {SubjectObjectRelationType, SubjectRecordCreateOrUpdateDto, SubjectRecordDto} from '|api/commons';
import {SubjectLoaderIds, SubjectsService} from '../../../../services/subjects.service';
import {WizardComponent} from '../../../wizard/wizard.component';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {AddSubjectDialogData, AddSubjectWizardData} from '../../model/subjects.model';
import {SubjectTableColumnSet} from '../subjects-table/subjects-table/subjects-table.component';
import {SubjectCreateFormComponent} from '../subject-create-form/subject-create-form.component';
import {IFormGroupCheckable} from '../../../../lib/form-group-checks';
import {CheckUnsavedFormDialogService} from '../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {SubjectToolbarContext} from '../subjects-toolbar/subjects-toolbar.component';

@Component({
  selector: 'icz-add-subject',
  templateUrl: './add-subject-dialog.component.html',
  styleUrls: ['./add-subject-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class AddSubjectDialogComponent implements IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<SubjectRecordCreateOrUpdateDto | SubjectRecordDto>>();
  protected subjectsService = inject(SubjectsService);
  protected checkUnsavedFormDialogService = inject(CheckUnsavedFormDialogService);
  protected dialogData = injectModalData<AddSubjectDialogData>();

  @ViewChild(WizardComponent)
  wizardComponent!: WizardComponent;

  form = SubjectCreateFormComponent.getCreateSubjectFormGroup();
  wizardSharedData: AddSubjectWizardData = {relationType: SubjectObjectRelationType.RELATED, searchForm: null, relatedObjectType: this.dialogData.relatedObjectType};

  formGroupsToCheck!: string[];

  readonly SubjectTableColumnSet = SubjectTableColumnSet;
  readonly SubjectLoaderIds = SubjectLoaderIds;

  ngOnInit() {
    this.checkUnsavedFormDialogService.addUnsavedFormCheck(this, ['form']);
  }

  advanceToCreate(searchForm: Record<string, any>) {
    this.wizardComponent.basicNextStep();
    this.wizardSharedData!.searchForm = searchForm;
  }

	protected readonly SubjectToolbarContext = SubjectToolbarContext;
}
