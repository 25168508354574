import {Component, inject, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {InterpolationContext} from '@icz/angular-essentials';
import {injectModalRef} from '@icz/angular-modal';
import {CheckUnsavedFormDialogService} from '../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../lib/form-group-checks';


type WorkflowTextResponseDialogResponseHandler = (response: string) => Observable<any>;

export interface WorkflowTextResponseDialogParams {
  infoAlertTemplate?: string;
  infoAlertTemplateContext?: InterpolationContext;
  responseFieldLabel: string;
  responseFieldDefaultValue?: string;
  primaryButtonLabel: string;
  responseHandlerFn: WorkflowTextResponseDialogResponseHandler;
}

export interface WorkflowTextResponseDialogResult {
  response: string;
}

@Component({
  selector: 'icz-digital-component-existing-signature-decision-dialog',
  templateUrl: './digital-component-existing-signature-decision-dialog.component.html',
  styleUrls: ['./digital-component-existing-signature-decision-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class DigitalComponentExistingSignatureDecisionDialogComponent implements OnInit, IFormGroupCheckable {

  protected modalRef = injectModalRef<Nullable<boolean>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);

  formGroupsToCheck!: string[];

  form = new IczFormGroup({
    shouldConvert: new IczFormControl<Nullable<boolean>>(null, [IczValidators.required()]),
  });

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  confirm() {
    this.modalRef.forceClose(this.form.get('shouldConvert')!.value!);
  }

  closeModal() {
    this.modalRef.close(null);
  }

}
