import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {DocumentToastService} from '../../../../../core/services/notifications/document-toast.service';
import {ConfigPropValueService} from '../../../../../services/config-prop-value.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ExecutionDateClauseTimestampUsage} from '../../../../../enums/config-enums';
import {ExecutionDateClauseConfigKeys} from '../../../../../core/services/config/config-keys.enum';
import {ApiConversionService, ExecutionDateClauseCreateDto} from '|api/component';
import {
  EsslComponentToastService,
  EsslComponentToastType
} from '../../../../../core/services/notifications/essl-component-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {esslErrorDtoToToastParameters} from '../../../../notifications/toast.service';

export interface ExecutionDateClauseCreateDialogData {
  componentId: number;
  componentLatestVersionId: number;
  documentId: number;
  fileRefNumber: string;
  documentRefNumber: string;
  componentLabel: string;
}
@Component({
  selector: 'icz-execution-date-clause-create-dialog',
  templateUrl: './execution-date-clause-create-dialog.component.html',
  styleUrls: ['./execution-date-clause-create-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class ExecutionDateClauseCreateDialogComponent implements IFormGroupCheckable, OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<boolean>>();
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private translateService = inject(TranslateService);
  private documentToastService = inject(DocumentToastService);
  private configPropService = inject(ConfigPropValueService);
  private destroyRef = inject(DestroyRef);
  private apiConversionService = inject(ApiConversionService);
  private componentToastService = inject(EsslComponentToastService);
  private modalData = injectModalData<ExecutionDateClauseCreateDialogData>();

  formGroup = new IczFormGroup({
    legalPrescription: new IczFormControl<Nullable<string>>(null, []),
    executionDate: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    enforceableDate: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    decisionEnforceableInPart: new IczFormControl<Nullable<string>>(null, []),
    createConsignments: new IczFormControl<boolean>(false, []),
    addTimestamp: new IczFormControl<boolean>({value: false, disabled: true}, []),
    documentRefNumber: new IczFormControl<string>({value: '', disabled: true}, []),
    fileRefNumber: new IczFormControl<string>({value: '', disabled: true}, []),
  });

  formGroupsToCheck!: string[];

  showLegalPrescription!: boolean;
  usageOfTimestamp!: ExecutionDateClauseTimestampUsage;

  pauseValueChange = false;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['formGroup']);

    this.formGroup.get('documentRefNumber')!.setValue(this.modalData.documentRefNumber);
    this.formGroup.get('fileRefNumber')!.setValue(this.modalData.fileRefNumber);

    this.loadingService.doLoading(
      this.configPropService.getManyPropValuesByOrganization([ExecutionDateClauseConfigKeys.SHOW_LEGAL_PRESCRIPTION, ExecutionDateClauseConfigKeys.USAGE_OF_TIMESTAMP], false, false),
      this
    ).subscribe(configValues => {
      this.showLegalPrescription = configValues[ExecutionDateClauseConfigKeys.SHOW_LEGAL_PRESCRIPTION].value as boolean;
      this.usageOfTimestamp = configValues[ExecutionDateClauseConfigKeys.USAGE_OF_TIMESTAMP].value as ExecutionDateClauseTimestampUsage;

      const addTimestampCtrl = this.formGroup.get('addTimestamp')!;
      if (this.usageOfTimestamp === ExecutionDateClauseTimestampUsage.ALLWAYS) {
        addTimestampCtrl.setValue(true);
      } else if (this.usageOfTimestamp === ExecutionDateClauseTimestampUsage.NEVER) {
        addTimestampCtrl.setValue(false);
      } else if (this.usageOfTimestamp === ExecutionDateClauseTimestampUsage.BY_USER) {
        addTimestampCtrl.enable();
      }
    });

    this.initMutuallyExclusiveFieldsRequiredValidator('enforceableDate', 'executionDate');
    this.initMutuallyExclusiveFieldsRequiredValidator('executionDate', 'enforceableDate');
  }

  submit() {
    const formValue = this.formGroup.getRawValue();
    const requestDto: ExecutionDateClauseCreateDto = {
      componentId: this.modalData.componentId,
      executionDate: formValue.executionDate,
      enforceableDate: formValue.enforceableDate,
      legalPrescription: formValue.legalPrescription,
      createConsignments: formValue.createConsignments,
      decisionEnforceableInPart: formValue.decisionEnforceableInPart,
      addTimestamp: formValue.addTimestamp
    };

    this.loadingService.doLoading(
      this.apiConversionService.conversionCreateExecutionDateClause({body: requestDto}),
      this,
    ).subscribe({
      next: _ => {
        this.componentToastService.dispatchComponentWarningToast(EsslComponentToastType.EXECUTION_CLAUSE_CREATE_STARTED, {
          [InternalNotificationKey.DOCUMENT_ID]: this.modalData.documentId,
          [InternalNotificationKey.DIGITAL_COMPONENT_ID]: this.modalData.componentId,
          [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: this.modalData.componentLatestVersionId,
          [InternalNotificationKey.ESSL_COMPONENT_LABEL]: this.modalData.componentLabel,
        });
        this.formGroup.reset();
        this.modalRef.close(true);
      },
      error: err => {
        this.componentToastService.dispatchComponentErrorToast(EsslComponentToastType.EXECUTION_CLAUSE_CREATE_ERROR, {
          [InternalNotificationKey.DOCUMENT_ID]: this.modalData.documentId,
          [InternalNotificationKey.DIGITAL_COMPONENT_ID]: this.modalData.componentId,
          [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: this.modalData.componentLatestVersionId,
          [InternalNotificationKey.ESSL_COMPONENT_LABEL]: this.modalData.componentLabel,
          ...esslErrorDtoToToastParameters(this.translateService, err.error),
        });
        this.modalRef.close(false);
      }
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

  private initMutuallyExclusiveFieldsRequiredValidator(controlName1: string, controlName2: string) {
    this.formGroup.get(controlName1)!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(value => {
      if (this.pauseValueChange) {
        this.pauseValueChange = false;
      }
      else {
        if (value) {
          this.formGroup.get(controlName2)!.clearValidators();
        }
        else {
          this.formGroup.get(controlName2)!.addValidators([IczValidators.required()]);
        }
        this.pauseValueChange = true;
        this.formGroup.get(controlName2)!.updateValueAndValidity();
      }
    });
  }

}
