import {Component, EventEmitter, inject, Input, OnDestroy, Output} from '@angular/core';
import {AllowedFormatsDto, ApiBusinessRulesService, ApiFormatRegisterService} from '|api/codebook';
import {BusinessRuleName, DataFileFormatAcceptability, EntityType} from '|api/commons';
import {DigitalComponentCompleteDto} from '|api/component';
import {IczOnChanges, IczSimpleChanges, LoadingIndicatorService} from '@icz/angular-essentials';
import {EsslComponentDto, EsslComponentSearchService} from '../../../services/essl-component-search.service';
import {FilterParam, IczTableDataSource, RowDisableFn} from '@icz/angular-table';
import {getLatestDigitalComponentVersion} from '../shared-document.utils';
import {EsslComponentsTableColumnSet} from '../essl-components-table/essl-components-table.component';
import {EsslComponentsTableDatasource} from '../essl-components-table/essl-components-table.datasource';

@Component({
  selector: 'icz-digital-components-selector',
  templateUrl: './digital-component-selector.component.html',
  styleUrls: ['./digital-component-selector.component.scss'],
})
export class DigitalComponentSelectorComponent implements IczOnChanges, OnDestroy {

  protected loadingService = inject(LoadingIndicatorService);
  private searchService = inject(EsslComponentSearchService);
  private apiBusinessRulesService = inject(ApiBusinessRulesService);
  private apiFormatRegisterService = inject(ApiFormatRegisterService);

  @Input({required: true})
  id!: string;
  @Input({required: true})
  documentId!: number;
  @Input()
  tableTitle: Nullable<string>;
  @Input()
  view = EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_EXTENDED;
  @Input()
  consignmentId: Nullable<number>;
  @Input()
  selection: Nullable<EsslComponentDto[]>;
  @Input()
  acceptableDataFileFormatRules: Nullable<DataFileFormatAcceptability[]>;
  @Input()
  constrainSelectionToBusinessRule: Nullable<BusinessRuleName>;
  @Input()
  rowCount: Nullable<number>;
  @Input()
  disableFinalComponents = false;
  @Input()
  disableAnalogComponents = false;
  @Input()
  disabled = false;
  @Input()
  additionalFilters: Nullable<FilterParam[]>;
  @Input()
  canUserDisplayComponentsContent = true;
  @Input()
  customDataSource: Nullable<IczTableDataSource<any>>;
  @Output()
  esslComponentsSelected = new EventEmitter<EsslComponentDto[]>();
  @Output()
  esslComponentIdsExcluded = new EventEmitter<number[]>();
  @Output()
  tableDestroyed = new EventEmitter<void>();
  @Input() hideTableToolbar = false;

  allowedFormatsForSelection: Nullable<AllowedFormatsDto>;
  acceptableDataFileFormats: Nullable<string[]>;

  rowSelectionDisabler: RowDisableFn<EsslComponentDto> = component => {
    if (this.disabled) return true;
    else if (this.disableFinalComponents && component.isFinal) {
      return true;
    } else if (component.entityType !== EntityType.DIGITAL_COMPONENT && this.disableAnalogComponents) {
      return true;
    } else if (component.entityType === EntityType.DIGITAL_COMPONENT && this.allowedFormatsForSelection) {
      const fileName = getLatestDigitalComponentVersion(component as DigitalComponentCompleteDto)?.fileName ?? '';
      const fileNameParts = fileName.split('.');
      const fileExtension = fileNameParts[fileNameParts.length - 1];
      const latestComponentVersionPuid = getLatestDigitalComponentVersion(component as DigitalComponentCompleteDto)?.puid!;
      const hasAllowedFileExtension = (this.allowedFormatsForSelection.allowedExtensions ?? []).includes(fileExtension);
      const hasAllowedPuid = (this.allowedFormatsForSelection.allowedFormats ?? []).includes(latestComponentVersionPuid);

      return !(hasAllowedPuid || hasAllowedFileExtension);
    } else return false;
  };

  dataSource!: EsslComponentsTableDatasource;

  get disabledRowTooltip(): string {
    if (this.constrainSelectionToBusinessRule === BusinessRuleName.DISTRIBUTION_EMAIL_ALLOWED_ATTACHMENT_FORMAT) {
      return 'Soubor není možné odeslat, je v nepovoleném formátu pro e-mailové vypravení';
    }
    else if (this.constrainSelectionToBusinessRule === BusinessRuleName.DISTRIBUTION_ISDS_ALLOWED_ATTACHMENT_FORMAT) {
      return 'Soubor není možné odeslat, je v nepovoleném formátu pro vypravení datovou schránkou';
    } else if (this.constrainSelectionToBusinessRule === BusinessRuleName.CONVERSION) {
      return 'Příloha je formátu, ktorý není možné převést do výstupního pro uložení do spisovny.';
    }
    else {
      return '';
    }
  }

  private setDataSource() {
    this.dataSource = new EsslComponentsTableDatasource(
      this.searchService,
      this.documentId,
      this.additionalFilters!,
    );
  }

  rowsSelected(rows: EsslComponentDto[]) {
    this.esslComponentsSelected.emit(rows);

    if (this.selection?.length) {
      const preselectedIds = this.selection.map(s => s.id!);
      const nowSelectedIds = rows.map(r => r.id!);
      const excluded = preselectedIds.filter(pre => !nowSelectedIds.includes(pre));
      this.esslComponentIdsExcluded.emit(excluded);
    }
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.rowCount && changes.rowCount.currentValue) {
      if (this.dataSource) this.dataSource.disablePagination = true;
    }
    if (changes.customDataSource && changes.customDataSource.currentValue) {
      this.dataSource = changes.customDataSource.currentValue;
    }
    if ((changes.documentId && changes.documentId.currentValue) || changes.additionalFilters) {
      if (isNil(this.customDataSource)) {
        this.setDataSource();
      }
      if (this.rowCount) this.dataSource.disablePagination = true;
    }
    if (changes.constrainSelectionToBusinessRule && changes.constrainSelectionToBusinessRule.currentValue) {
      if (this.constrainSelectionToBusinessRule) {
        this.loadingService.doLoading(
          this.apiBusinessRulesService.businessRulesGetAllowedFormats({
            name: this.constrainSelectionToBusinessRule
          }),
          this
        ).subscribe(allowedFormats => {
          this.allowedFormatsForSelection = allowedFormats;
        });
      }
      else {
        this.allowedFormatsForSelection = null;
      }
    }
    if (changes.acceptableDataFileFormatRules && changes.acceptableDataFileFormatRules.currentValue) {
      if (this.acceptableDataFileFormatRules) {
        this.loadingService.doLoading(
          this.apiFormatRegisterService.formatRegisterFindFormatsByAcceptability({
            body: {
              acceptability: this.acceptableDataFileFormatRules,
            }
          }),
          this
        ).subscribe( acceptableFormats => {
          this.acceptableDataFileFormats = acceptableFormats.map( dff => dff.puid!);
        });
      }
    }
  }

  /**
   * todo(mh) this onDestroy listener is used to save last selection data model status when preselection is used and user is leaving this esslComponent table.
   * Saving state when component is destroyed is necessary because table component is calling unselectAll when user is changing table page and
   * therefore messing up preselection dataset. Preselection dataset is stored outside of this component so that when user leaves and reenters this component,
   * the state of selected components is saved and then reselected. Saving must be done while onDestory because otherwise preselection would be cleared when table
   * calls unselectAll. This onDestroy() method would be possible to remove after refactoring table component to support multi-page selection.
   */
  ngOnDestroy(): void {
    this.tableDestroyed.emit();
  }

}
