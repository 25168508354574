import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output
} from '@angular/core';
import {shouldCall as ShouldCall} from '@tinkoff/ng-event-plugins';
import {IconComponent} from '../icon/icon.component';

/**
 * Extra width to be allocated to resizable sidebar scrollbar.
 * Usable in scenarios where initial width is supplied by icz-resizable-sidebar consumer.
 */
export const SIDEBAR_SCROLLBAR_RESERVE = 20;

/**
 * @internal
 * Predicates supplied to decorators must be exported non-arrow functions
 */
export function resizeInProgress(this: ResizableSidebarComponent) {
  return this.sideX !== 0;
}

/**
 * A left sidebar with a content slot which has a resize handle to allow for user resizing.
 */
@Component({
  selector: 'icz-resizable-sidebar',
  templateUrl: './resizable-sidebar.component.html',
  styleUrls: ['./resizable-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent],
})
export class ResizableSidebarComponent {

  private readonly DEFAULT_INITIAL_WIDTH = 300;

  /**
   * Initial sidebar width in px.
   * It should be set, such that it is in interval <minWidth, maxWidth>.
   */
  @Input({required: true})
  set initialWidth(width: number) {
    if (width !== this.DEFAULT_INITIAL_WIDTH) {
      this.sideWidth = width;
    }
  }
  /**
   * Minimal sidebar width in px. The user will not be able to shrink the sidebar below this value.
   */
  @Input()
  minWidth = 300;
  /**
   * Minimal sidebar width in px. The user will not be able to expand the sidebar below this value.
   */
  @Input()
  maxWidth = 640;
  /**
   * Emits actual width of the sidebar in px after the user performs sidebar resize.
   */
  @Output()
  widthChanged = new EventEmitter<number>();

  @HostBinding('style.width.px')
  protected sideWidth = this.DEFAULT_INITIAL_WIDTH;

  protected sideX = 0;
  protected readonly SIDEBAR_SCROLLBAR_RESERVE = SIDEBAR_SCROLLBAR_RESERVE;

  protected get totalWidth(): number {
    return this.sideWidth - this.SIDEBAR_SCROLLBAR_RESERVE;
  }

  protected onSideResizeStart(event: MouseEvent) {
    this.sideX = event.clientX;
  }

  /**
   * Performance optimization:
   * - eventName.silent will run event handler outside Angular Zone
   * - init.eventName will hand current ChangeDetector over to @ShouldCall
   * - @ShouldCall will re-enter the Zone if its predicate (resizeInProgress)
   *    holds thus it will not trigger unnecessary dirty check cycles.
   */
  @HostListener('document:mousemove.silent', ['$event'])
  @HostListener('document:mousemove.init', ['$event'])
  @ShouldCall(resizeInProgress)
  protected onSideResize(event: MouseEvent) {
    if (this.sideX) {
      this.sideWidth += event.clientX - this.sideX;
      if (this.sideWidth < this.minWidth) {
        this.sideWidth = this.minWidth;
      } else if (this.sideWidth > this.maxWidth) {
        this.sideWidth = this.maxWidth;
      }
      this.sideX = event.clientX;
      this.widthChanged.emit(this.totalWidth);
    }
  }

  @HostListener('document:mouseup.silent', ['$event'])
  @HostListener('document:mouseup.init', ['$event'])
  @ShouldCall(resizeInProgress)
  protected onSideResizeStop() {
    this.sideX = 0;
  }

}
