/* eslint-disable @typescript-eslint/quotes */
import {EventEmitter, inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {catchError, concatMap, map, shareReplay, switchMap, take} from 'rxjs/operators';
import {EntityType, RegistryOfficeTransferIncidentState, RelatedObjectDto, RelatedObjectType} from '|api/commons';
import {
  ApiAuthorizationService,
  ApiEntitySharingService,
  AuthorizedEntityType,
  FileAuthorizedOperation
} from '|api/core';
import {ApiCrossReferenceService, ApiFileService, ApiStorageUnitService, FileDto} from '|api/document';
import {ApiElasticsearchService} from '|api/elastic';
import {
  FileDtoWithAuthorization,
  FileOrProfileDtoWithAuthorization
} from '../components/shared-business-components/model/dto-with-permissions.interface';
import {
  getInsufficientPermissionsTooltip,
  isAuthorizedOperationGranted
} from '../components/shared-business-components/permissions/permissions.utils';
import {createAbsoluteRoute} from '../core/routing/routing.helpers';
import {ApplicationRoute, SharedRoute} from '../enums/shared-routes.enum';
import {DocumentFileDetailService, ObjectDetailLoadType} from './abstract-object-detail.service';
import {TranslateService} from '@ngx-translate/core';
import {TabItemWithPriority, TabPriority} from '@icz/angular-essentials';
import {ObjectCounts} from '../lib/object-counts';
import {
  FileDetailRoute
} from '../../../../../apps/espis/src/app/modules/documents/modules/file-detail/enums/file-detail-routes.enum';
import {HistoryService} from './history.service';
import {SKIP_ERROR_DIALOG} from '../core/error-handling/http-errors';
import {ApiSubjectRecordElasticService} from '|api/subject-register';
import {DocumentDetailRoute} from '../enums/documents-routes.enum';
import {IncidentDto} from '|api/incident-log';


export enum FileDetailCountType {
  CONTENT = 'content',
  RELATED = 'related',
  ACTIVITIES = 'activities',
  SUBJECTS = 'subjects',
  SHARE = 'share',
  INCIDENTS = 'incidents',
}

@Injectable()
export class FileDetailService extends DocumentFileDetailService<FileOrProfileDtoWithAuthorization, FileDetailCountType> {

  private apiFileService = inject(ApiFileService);
  private historyService = inject(HistoryService);
  private apiCrossReferenceService = inject(ApiCrossReferenceService);
  private apiAuthorizationService = inject(ApiAuthorizationService);
  private apiElasticsearchService = inject(ApiElasticsearchService);
  private apiEntitySharingService = inject(ApiEntitySharingService);
  private apiSubjectRecordNgElasticService = inject(ApiSubjectRecordElasticService);
  private apiStorageUnitService = inject(ApiStorageUnitService);
  private translateService = inject(TranslateService);

  protected activitiesAuthorizedOperation = FileAuthorizedOperation.FILE_SHOW_ACTIVITIES;
  protected objectReferenceIdFieldName = 'fileId' as const;

  constructor() {
    super();
    super.initialize();
  }

  override counts = new ObjectCounts<FileDetailCountType>({
    [FileDetailCountType.ACTIVITIES]: fileId => this.object$.pipe(
      take(1),
      switchMap(_ => {
        return this.apiElasticsearchService.elasticsearchGetFileActivityCount({fileId});
      }),
    ),
    [FileDetailCountType.CONTENT]: fileId => this.object$.pipe(
      take(1),
      switchMap(_ =>
        this.apiElasticsearchService.elasticsearchCountDocumentsInFile({fileId})
      ),
    ),
    [FileDetailCountType.RELATED]: fileId => this.object$.pipe(
      take(1),
      switchMap(_ => {
        return this.apiCrossReferenceService.crossReferenceGetCount({entityId: fileId, entityType: EntityType.FILE});
      }),
    ),
    [FileDetailCountType.SUBJECTS]: fileId => this.object$.pipe(
      take(1),
      switchMap(_ => {
        const related: RelatedObjectDto = {
          relatedObjectId: fileId,
          relatedObjectType: RelatedObjectType.FILE
        };

        return this.apiSubjectRecordNgElasticService.subjectRecordElasticGetRelatedSubjectCount({body: related});
      })
    ),
    [FileDetailCountType.SHARE]: fileId => this.object$.pipe(
      take(1),
      switchMap(_ => this.apiEntitySharingService.entitySharingCountSharingStatusForFile({id: fileId})),
    ),
    [FileDetailCountType.INCIDENTS]: fileId => this.object$.pipe(
      take(1),
      switchMap(_ => this.apiElasticsearchService.elasticsearchCountRegistryOfficeTransferIncidentsForEntity({
        entityType: EntityType.FILE,
        entityId: fileId,
        urlPredicates: {'state': RegistryOfficeTransferIncidentState.IN_PROGRESS} as Partial<Record<keyof IncidentDto, string>>,
      })),
    ),
  });

  override object$: Observable<Nullable<FileOrProfileDtoWithAuthorization>> = this.loadTypeWithAuthorization$.pipe(
    concatMap(loadTypeWithAuthorization => {
      const loadType = loadTypeWithAuthorization.loadType;
      const authorization = loadTypeWithAuthorization.authorization;
      let file$: Nullable<Observable<FileOrProfileDtoWithAuthorization>>;

      if (loadType === ObjectDetailLoadType.FULL_LOAD || loadType === ObjectDetailLoadType.RELOAD_OBJECT) {
        if (isAuthorizedOperationGranted(authorization, FileAuthorizedOperation.FILE_SHOW_PROFILE)) {
          file$ = this.apiFileService.fileFindById({
            id: authorization!.authorizedEntityId,
          });
        }
        else {
          file$ = this.apiFileService.fileReadFileProfile({
            fileId: authorization!.authorizedEntityId,
          }).pipe(
            catchError(_ => {
              this.historyService.routeBack(createAbsoluteRoute(ApplicationRoute.DOCUMENTS));
              return of(this.object);
            }),
          );
        }
      }
      else {
        file$ = of(this.object);
      }

      return this.loadingService.doLoading(file$, this).pipe(
        map(file => ({...file, authorization})),
      );
    }),
    shareReplay(1)
  );

  override objectRepresentingSubject$ = this.object$.pipe(
    switchMap(f => {
      const related: RelatedObjectDto = {relatedObjectId: f!.id, relatedObjectType: RelatedObjectType.FILE};
      return this.apiSubjectRecordNgElasticService.subjectRecordElasticGetRepresentingSubject({body: related});
    }),
    shareReplay(1)
  );

  override tabs$: Observable<TabItemWithPriority[]> = combineLatest([
    this.object$,
    this.counts.countsChanged$,
  ]).pipe(
    map(([object, counts]) => {
      const canUserShowDocuments = isAuthorizedOperationGranted(object!.authorization, FileAuthorizedOperation.FILE_SHOW_DOCUMENTS);
      const canUserShowRelatedObjects = isAuthorizedOperationGranted(object!.authorization, FileAuthorizedOperation.FILE_SHOW_RELATED_OBJECTS);
      const canUserShowSubjects = isAuthorizedOperationGranted(object!.authorization, FileAuthorizedOperation.FILE_SHOW_SUBJECTS);
      const canUserShowProfile = isAuthorizedOperationGranted(object!.authorization, FileAuthorizedOperation.FILE_SHOW_PROFILE);
      const canUserShowHistory = isAuthorizedOperationGranted(object!.authorization, FileAuthorizedOperation.FILE_SHOW_HISTORY);
      const canUserShowActivities = isAuthorizedOperationGranted(object!.authorization, FileAuthorizedOperation.FILE_SHOW_ACTIVITIES);
      const canUserShowSharing = isAuthorizedOperationGranted(object!.authorization, FileAuthorizedOperation.FILE_SHOW_SHARING);

      return [
        {
          id: FileDetailRoute.PREVIEW,
          label: 'Přehled',
          showCount: false,
          priority: TabPriority.HIGHEST,
        },
        {
          id: FileDetailRoute.CONTENT,
          label: 'Obsah spisu',
          showCount: canUserShowDocuments,
          count: counts[FileDetailRoute.CONTENT],
          priority: TabPriority.HIGHEST,
          disabled: !canUserShowDocuments,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, FileAuthorizedOperation.FILE_SHOW_DOCUMENTS, this.translateService)?.tooltip
        },
        {
          id: FileDetailRoute.RELATED,
          label: 'Související',
          showCount: canUserShowRelatedObjects,
          count: counts[FileDetailRoute.RELATED],
          priority: TabPriority.HIGHEST,
          disabled: !canUserShowRelatedObjects,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, FileAuthorizedOperation.FILE_SHOW_RELATED_OBJECTS, this.translateService)?.tooltip
        },
        {
          id: FileDetailRoute.SUBJECTS,
          label: 'Subjekty',
          priority: TabPriority.HIGHEST,
          showCount: canUserShowSubjects,
          count: counts[FileDetailCountType.SUBJECTS],
          disabled: !canUserShowSubjects,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, FileAuthorizedOperation.FILE_SHOW_SUBJECTS, this.translateService)?.tooltip
        },
        {
          id: FileDetailRoute.HISTORY,
          label: 'Historie',
          priority: TabPriority.HIGH,
          disabled: !canUserShowHistory,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, FileAuthorizedOperation.FILE_SHOW_HISTORY, this.translateService)?.tooltip
        },
        {
          id: SharedRoute.NOT_IMPLEMENTED,
          label: 'Registratury',
          priority: TabPriority.MEDIUM,
          disabled: !canUserShowProfile,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, FileAuthorizedOperation.FILE_SHOW_PROFILE, this.translateService)?.tooltip
        },
        {
          id: FileDetailRoute.ACTIVITIES,
          label: 'Úkony',
          showCount: canUserShowActivities,
          count: counts[FileDetailRoute.ACTIVITIES],
          priority: TabPriority.HIGH,
          disabled: !canUserShowActivities,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, FileAuthorizedOperation.FILE_SHOW_ACTIVITIES, this.translateService)?.tooltip
        },
        {
          id: FileDetailRoute.SHARE,
          label: 'Sdíleno',
          showCount: canUserShowSharing,
          count: counts[FileDetailRoute.SHARE],
          priority: TabPriority.HIGH,
          disabled: !canUserShowSharing,
          tooltip: getInsufficientPermissionsTooltip(object!.authorization, FileAuthorizedOperation.FILE_SHOW_SHARING, this.translateService)?.tooltip
        },
        {
          id: FileDetailRoute.INCIDENTS,
          label: 'Chyby při předání',
          isHidden: !(object as FileDtoWithAuthorization)!.registryOfficeTransfer?.registryOfficeTransferState,
          icon: counts[DocumentDetailRoute.INCIDENTS] ? 'error' : null,
          showCount: true,
          count: counts[FileDetailRoute.INCIDENTS],
        },
      ] as TabItemWithPriority[];
    })
  );

  relatedObjectsChanged = new EventEmitter<void>();

  getStorageUnit(file: FileDto) {
    if (!file.storageUnitId) return null;

    return this.apiStorageUnitService.storageUnitFindById({id: file.storageUnitId}, SKIP_ERROR_DIALOG);
  }

  protected loadAuthorizationForTabsAndPreview(objectId: number) {
    return this.apiAuthorizationService.authorizationAuthorizeFileOperations({
      body: {
        authorizedEntityId: objectId,
        authorizedEntityType: AuthorizedEntityType.FILE,
        operationsToAuthorize: [
          FileAuthorizedOperation.FILE_SHOW_DOCUMENTS,
          FileAuthorizedOperation.FILE_SHOW_RELATED_OBJECTS,
          FileAuthorizedOperation.FILE_SHOW_SUBJECTS,
          FileAuthorizedOperation.FILE_SHOW_PROFILE,
          FileAuthorizedOperation.FILE_MODIFY_PROFILE,
          FileAuthorizedOperation.FILE_SHOW_HISTORY,
          FileAuthorizedOperation.FILE_SHOW_ACTIVITIES,
          FileAuthorizedOperation.FILE_SHOW_SHARING,
        ],
      }
    });
  }

}
