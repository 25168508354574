import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {applyPathParams, CORE_MICROSERVICE_ROOT} from '../api';
import {EntityType} from '|api/commons';
import {ApiCrossReferenceService, CrossReferenceWithDetailDto} from '|api/document';
import {ApiElasticsearchService, SearchRecordDto} from '|api/elastic';
import {SearchApiService} from './search-api.service';
import {Page, SearchParams} from '@icz/angular-table';

@Injectable({
  providedIn: 'root'
})
export class CrossReferenceSearchService extends SearchApiService {

  findCrossReferenceEntitiesForRelatedView(entityId: number, entityType: EntityType, searchParams: Partial<SearchParams>): Observable<Page<CrossReferenceWithDetailDto>> {
    return this.searchApi<Page<CrossReferenceWithDetailDto>>(searchParams, CORE_MICROSERVICE_ROOT + applyPathParams(ApiCrossReferenceService.CrossReferenceSearchPath, {entityType, entityId}));
  }

  findEntitiesForCrossReference(searchParams: Partial<SearchParams>): Observable<Page<SearchRecordDto>> {
    return this.searchApi<Page<SearchRecordDto>>(
      searchParams,
      CORE_MICROSERVICE_ROOT +
      ApiElasticsearchService.ElasticsearchFindInEntitiesPath + `?text=`
    );
  }

}
