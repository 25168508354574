import {formatNumber} from '@angular/common';
import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

/**
 * @internal
 */
const FILE_SIZE_BASE = 1024;

/**
 * @internal
 */
export enum FileSizeUnit {
  B = 1,
  KB = 2,
  MB = 3,
  GB = 4,
}

/**
 * @internal
 */
const fileSizeUnitLabels = {
  [String(FileSizeUnit.B)]: 'B',
  [String(FileSizeUnit.KB)]: 'kB',
  [String(FileSizeUnit.MB)]: 'MB',
  [String(FileSizeUnit.GB)]: 'GB',
};

/**
 * @internal
 */
export function getFileSizeUnitLabel(unit: FileSizeUnit): string {
  return fileSizeUnitLabels[unit];
}

/**
 * Converts file size as number in KB/MB/etc. to/from Bytes.
 * @param unit - unit of value (10 kB = value=10, unit=FileSizeUnit.KB).
 * @param toBytes - if TRUE, will convert value+unit to Bytes, else will convert value in bytes to unit.
 */
export function transformFileSize(value: number, unit: FileSizeUnit, toBytes: boolean): number {
  return toBytes ? value * Math.pow(FILE_SIZE_BASE, unit - 1) : value / Math.pow(FILE_SIZE_BASE, unit - 1);
}

/**
 * A function that converts number of Bytes into a human-friendly file size in kB/MB/...
 * Note that our base for file size computations is 1024 which corresponds to Windows OS file size base.
 */
export function formatFileSize(translateService: TranslateService, bytesValue: string | number): string {
  const isString = typeof bytesValue === 'string';
  const fileSize = isString ? parseInt(bytesValue as string, 10) : bytesValue as number;
  return convertUnitSize(translateService, fileSize);
}

/**
 * @internal
 */
function convertUnitSize(translateService: TranslateService, val: number): string {
  if (val == null) return '';
  let sizeUnit: number;
  if (val < Math.pow(FILE_SIZE_BASE, FileSizeUnit.B)) {
    sizeUnit = FileSizeUnit.B;
  } else if (val < Math.pow(FILE_SIZE_BASE, FileSizeUnit.KB)) {
    sizeUnit = FileSizeUnit.KB;
  } else if (val < Math.pow(FILE_SIZE_BASE, FileSizeUnit.MB)) {
    sizeUnit = FileSizeUnit.MB;
  } else {
    sizeUnit = FileSizeUnit.GB;
  }

  const formattedValue = formatNumber(
    transformFileSize(val, sizeUnit, false),
    translateService.currentLang,
    '1.1-1'
  );

  return `${formattedValue} ${fileSizeUnitLabels[String(sizeUnit)]}`;
}

/**
 * A pipe that converts number of Bytes into a human-friendly file size in kB/MB/...
 * Note that our base for file size computations is 1024 which corresponds to Windows OS file size base.
 */
@Pipe({
  name: 'fileSize',
  pure: false, // has to check for language change so is impure
  standalone: true,
})
export class FileSizePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  /**
   * Convert number of Bytes to human readable file size notation.
   */
  transform(bytesValue: Nullable<string | number>): string {
    if (isNil(bytesValue) || bytesValue === '') {
      return '';
    }
    else {
      return formatFileSize(this.translateService, bytesValue);
    }
  }

}
