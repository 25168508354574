export enum InternalNotificationSubjectCode {
  FLOW_TASK_ASSIGNED = 'be.internalNotification.subject.flow.task.assigned',
  FLOW_TASK_SUCCESS = 'be.internalNotification.subject.flow.task.success',
  FLOW_TASK_REJECTED = 'be.internalNotification.subject.flow.task.rejected',
  FLOW_TASK_REVOKED = 'be.internalNotification.subject.flow.task.revoked',
  FLOW_TASK_PASSED = 'be.internalNotification.subject.flow.task.passed',
  FLOW_HANDOVER_TASK_ASSIGNED = 'be.internalNotification.subject.flow.handover.task.assigned',
  FLOW_HANDOVER_TASK_SUCCESS = 'be.internalNotification.subject.flow.handover.task.success',
  FLOW_HANDOVER_TASK_REJECTED = 'be.internalNotification.subject.flow.handover.task.rejected',
  FLOW_HANDOVER_TASK_REVOKED = 'be.internalNotification.subject.flow.handover.task.revoked',
  FLOW_HANDOVER_TASK_PASSED = 'be.internalNotification.subject.flow.handover.task.passed',

  FLOW_COMPONENT_APPROVAL_TASK_SUCCESS = 'be.internalNotification.subject.flow.component.approval.task.success',
  FLOW_COMPONENT_APPROVAL_TASK_REVOKED = 'be.internalNotification.subject.flow.component.approval.task.revoked',
  // todo it is not in BE sources FLOW_COMPONENT_APPROVAL_TASK_DISAPPROVED = 'be.internalNotification.subject.flow.component.approval.task.revokedOrDisapproved', // todo
  FLOW_COMPONENT_APPROVAL_TASK_REJECTED = 'be.internalNotification.subject.flow.component.approval.task.rejected',
  FLOW_COMPONENT_APPROVAL_TASK_PASSED = 'be.internalNotification.subject.flow.component.approval.task.passed',

  FLOW_COMPONENT_SIGNING_TASK_SUCCESS = 'be.internalNotification.subject.flow.component.signing.task.success',
  FLOW_COMPONENT_SIGNING_TASK_REVOKED = 'be.internalNotification.subject.flow.component.signing.task.revoked',
  FLOW_COMPONENT_SIGNING_TASK_REJECTED = 'be.internalNotification.subject.flow.component.signing.task.rejected',

  FLOW_COMPONENT_SIGNING_TASK_SIGNED_INITIATOR = 'be.internalNotification.subject.flow.component.signing.task.signed.initiator',
  FLOW_COMPONENT_SIGNING_TASK_REVOKED_TARGET_PARTICIPANT = 'be.internalNotification.subject.flow.component.signing.task.revoked.targetParticipant',
  FLOW_COMPONENT_SIGNING_TASK_REJECTED_INITIATOR = 'be.internalNotification.subject.component.signing.task.rejected.initiator',

  CONVERSION_WITH_CLAUSE_SUCCESS = 'be.internalNotification.subject.changeWithClause.success',
  CONVERSION_WITH_CLAUSE_ERROR = 'be.internalNotification.subject.changeWithClause.failed',
  CONVERSION_SUCCESS = 'be.internalNotification.subject.conversion.success',
  CONVERSION_ERROR = 'be.internalNotification.subject.conversion.failed',

  DOCUMENT_SETTLEMENT_SUCCESS = 'be.internalNotification.subject.settlement.document.success',
  DOCUMENT_SETTLEMENT_ERROR = 'be.internalNotification.subject.settlement.document.error',
  FILE_SETTLEMENT_SUCCESS = 'be.internalNotification.subject.settlement.file.success',
  FILE_SETTLEMENT_ERROR = 'be.internalNotification.subject.settlement.file.error',

  OFFICE_DESK_POST_SUCCESS = 'be.internalNotification.subject.officeDesk.post.success',
  OFFICE_DESK_POST_ERROR = 'be.internalNotification.subject.officeDesk.post.error',
  OFFICE_DESK_UNPOST_SUCCESS = 'be.internalNotification.subject.officeDesk.unpost.success',
  OFFICE_DESK_UNPOST_ERROR = 'be.internalNotification.subject.officeDesk.unpost.error',

  FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED = 'be.internalNotification.subject.filingRegister.rendition.scheduled.subject',
  FILING_REGISTER_RENDITION_FINISHED = 'be.internalNotification.subject.filingRegister.rendition.finished.subject',
  EXECUTION_CLAUSE_DATE_CREATION_FINISHED = 'be.internalNotification.subject.executionDateClause.create.success',

  BULK_COMPONENT_HANDOVER_FOR_SIGN_SUCCESS = 'be.internalNotification.subject.bulk.componentHandForSigning.success',
  BULK_COMPONENT_HAND_FOR_APPROVAL_SUCCESS = 'be.internalNotification.subject.bulk.componentHandForApproval.success',
  BULK_COMPONENT_ANALOG_SIGN_SUCCESS = 'be.internalNotification.subject.bulk.signAnalogComponentByTarget.success',
  BULK_COMPONENT_DIGITAL_SIGN_SUCCESS = 'be.internalNotification.subject.bulk.signDigitalComponentByTarget.success',
  BULK_DOCUMENT_HANDOVER_SUCCESS = 'be.internalNotification.subject.bulk.documentHandover.success',
  BULK_FILE_HANDOVER_SUCCESS = 'be.internalNotification.subject.bulk.fileHandover.success',
  BULK_HANDOVER_TAKEOVER_SUCCESS = 'be.internalNotification.subject.bulk.takeoverByTarget.success',
  BULK_HANDOVER_FILE_TAKEOVER_SUCCESS = 'be.internalNotification.subject.bulk.fileTakeoverByTarget.success',
  BULK_DOCUMENT_HAND_FOR_APPROVAL_SUCCESS = 'be.internalNotification.subject.bulk.documentHandForApproval.success',
  BULK_DOCUMENT_HAND_FOR_STATEMENET_SUCCESS = 'be.internalNotification.subject.bulk.handForStatement.success',
  BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_SUCCESS = 'be.internalNotification.subject.bulk.handForAcknowledgement.success',
  BULK_DOCUMENT_ACKNOWLEDGE_TARGET_SUCCESS = 'be.internalNotification.subject.bulk.acknowledgeByTarget.success',
  BULK_DOCUMENT_APPROVE_BY_TARGET_SUCCESS = 'be.internalNotification.subject.bulk.documentApproveByTarget.success',
  BULK_DOCUMENT_STATEMENT_BY_TARGET_SUCCESS = 'be.internalNotification.subject.bulk.statementByTarget.success',
  BULK_DOCUMENT_DISAPPROVE_BY_TARGET_SUCCESS = 'be.internalNotification.subject.bulk.documentDisapproveByTarget.success',
  BULK_COMPONENT_APPROVE_BY_TARGET_SUCCESS = 'be.internalNotification.subject.bulk.componentApproveByTarget.success',
  BULK_COMPONENT_DISAPPROVE_BY_TARGET_SUCCESS = 'be.internalNotification.subject.bulk.componentDisapproveByTarget.success',
  BULK_DOCUMENT_TAKEOVER_AND_PASS_SUCCESS = 'be.internalNotification.subject.bulk.documentTakeoverAndPass.success',
  BULK_FILE_TAKEOVER_AND_PASS_SUCCESS = 'be.internalNotification.subject.bulk.fileTakeoverAndPass.success',
  BULK_TASK_REJECT_SUCCESS = 'be.internalNotification.subject.bulk.taskReject.success',
  BULK_TASK_REVOKE_SUCCESS = 'be.internalNotification.subject.bulk.taskRevoke.success',
  BULK_TASK_PASS_TO_NEXT_SUCCESS = 'be.internalNotification.subject.bulk.taskPassToNext.success',

  BULK_COMOPNENT_HANDOVER_FOR_SIGN_ERROR = 'be.internalNotification.subject.bulk.componentHandForSigning.error',
  BULK_COMPONENT_HAND_FOR_APPROVAL_ERROR = 'be.internalNotification.subject.bulk.componentHandForApproval.error',
  BULK_COMPONENT_ANALOG_SIGN_ERROR = 'be.internalNotification.subject.bulk.signAnalogComponentByTarget.error',
  BULK_COMPONENT_DIGITAL_SIGN_ERROR = 'be.internalNotification.subject.bulk.signDigitalComponentByTarget.error',
  BULK_COMPONENT_SIGNING_TARGET_ERROR = 'be.internalNotification.subject.bulk.componentSigningByTarget.error',
  BULK_DOCUMENT_HANDOVER_ERROR = 'be.internalNotification.subject.bulk.documentHandover.error',
  BULK_FILE_HANDOVER_ERROR = 'be.internalNotification.subject.bulk.fileHandover.error',
  BULK_DOCUMENT_HANDOVER_TAKEOVER_ERROR = 'be.internalNotification.subject.bulk.takeoverByTarget.error',
  BULK_DOCUMENT_HAND_FOR_STATEMENET_ERROR = 'be.internalNotification.subject.bulk.handForStatement.error',
  BULK_DOCUMENT_HAND_FOR_APPROVAL_ERROR = 'be.internalNotification.subject.bulk.documentHandForApproval.error',
  BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_ERROR = 'be.internalNotification.subject.bulk.handForAcknowledgement.error',
  BULK_DOCUMENT_ACKNOWLEDGE_TARGET_ERROR = 'be.internalNotification.subject.bulk.acknowledgeByTarget.error',
  BULK_DOCUMENT_APPROVE_BY_TARGET_ERROR = 'be.internalNotification.subject.bulk.documentApproveByTarget.error',
  BULK_DOCUMENT_STATEMENT_BY_TARGET_ERROR = 'be.internalNotification.subject.bulk.statementByTarget.error',
  BULK_DOCUMENT_DISAPPROVE_BY_TARGET_ERROR = 'be.internalNotification.subject.bulk.documentDisapproveByTarget.error',
  BULK_COMPONENT_APPROVE_BY_TARGET_ERROR = 'be.internalNotification.subject.bulk.componentApproveByTarget.error',
  BULK_COMPONENT_DISAPPROVE_BY_TARGET_ERROR = 'be.internalNotification.subject.bulk.componentDisapproveByTarget.error',
  BULK_DOCUMENT_TAKEOVER_AND_PASS_ERROR = 'be.internalNotification.subject.bulk.documentTakeoverAndPass.error',
  BULK_FILE_TAKEOVER_AND_PASS_ERROR = 'be.internalNotification.subject.bulk.fileTakeoverAndPass.error',
  BULK_TASK_REJECT_ERROR = 'be.internalNotification.subject.bulk.taskReject.error',
  BULK_TASK_REVOKE_ERROR = 'be.internalNotification.subject.bulk.taskRevoke.error',
  BULK_TASK_PASS_TO_NEXT_ERROR = 'be.internalNotification.subject.bulk.taskPassToNext.error',

  BULK_STORAGE_UNIT_LOCK_SUCCESS = 'be.internalNotification.subject.bulk.blockStorageUnit.success',
  BULK_STORAGE_UNIT_LOCK_ERROR = 'be.internalNotification.subject.bulk.blockStorageUnit.error',
  BULK_STORAGE_UNIT_UNLOCK_SUCCESS = 'be.internalNotification.subject.bulk.unblockStorageUnit.success',
  BULK_STORAGE_UNIT_UNLOCK_ERROR = 'be.internalNotification.subject.bulk.unblockStorageUnit.error',
  BULK_STORAGE_UNIT_DELETE_SUCCESS = 'be.internalNotification.subject.bulk.deleteStorageUnit.success',
  BULK_STORAGE_UNIT_DELETE_ERROR = 'be.internalNotification.subject.bulk.deleteStorageUnit.error',
  BULK_INSERT_TO_STORAGE_UNIT_SUCCESS = 'be.internalNotification.subject.bulk.storageUnit.entitiesInsert.success',
  BULK_INSERT_TO_STORAGE_UNIT_ERROR = 'be.internalNotification.subject.bulk.storageUnit.entitiesInsert.error',
  BULK_WITHDRAW_FROM_STORAGE_UNIT_SUCCESS = 'be.internalNotification.subject.bulk.storageUnit.entitiesWithdraw.success',
  BULK_WITHDRAW_FROM_STORAGE_UNIT_ERROR = 'be.internalNotification.subject.bulk.storageUnit.entitiesWithdraw.error',
  BULK_MOVE_BETWEEN_STORAGE_UNITS_SUCCESS = 'be.internalNotification.subject.bulk.storageUnit.entitiesMove.success',
  BULK_MOVE_BETWEEN_STORAGE_UNITS_ERROR = 'be.internalNotification.subject.bulk.storageUnit.entitiesMove.error',
  BULK_EPDZ_ERROR_PROCESSING_SUCCESS = 'be.internalNotification.subject.epdz.error.bulk.success',
  BULK_EPDZ_ERROR_PROCESSING_ERROR = 'be.internalNotification.subject.epdz.error.bulk.error',
  EPDZ_ERROR_GENERAL = 'be.internalNotification.subject.epdz.error.general',
  STORAGE_UNIT_FILLER_SUCCESS = 'be.internalNotification.subject.storageUnit.filler.success',
  STORAGE_UNIT_FILLER_STARTED = 'be.internalNotification.subject.storageUnit.filler.started',
  STORAGE_UNIT_FILLER_ERROR = 'be.internalNotification.subject.storageUnit.filler.error',

  BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_SUCCESS = 'be.internalNotification.subject.bulk.registryOfficeTransferIncidentMarkResolved.success',
  BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_ERROR = 'be.internalNotification.subject.bulk.registryOfficeTransferIncidentMarkResolved.error',
  BULK_RO_TRANSFER_CANCEL_SUCCESS = 'be.internalNotification.subject.bulk.registryOfficeCancelTransfer.success',
  BULK_RO_TRANSFER_CANCEL_ERROR = 'be.internalNotification.subject.bulk.registryOfficeCancelTransfer.error',
  BULK_RO_TRANSFER_RETRANSFER_SUCCESS = 'be.internalNotification.subject.bulk.registryOfficeReTransfer.success',
  BULK_RO_TRANSFER_RETRANSFER_ERROR = 'be.internalNotification.subject.bulk.registryOfficeReTransfer.error',
  BULK_RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS = 'be.internalNotification.subject.bulk.registryOfficeReturnToOffice.success',
  BULK_RO_TRANSFER_RETURN_TO_OFFICE_ERROR = 'be.internalNotification.subject.bulk.registryOfficeReturnToOffice.error',
  BULK_RO_TRANSFER_CREATED_SUCCESS = 'be.internalNotification.subject.bulk.registryOfficeCreated.success',
  BULK_RO_TRANSFER_CREATED_ERROR = 'be.internalNotification.subject.bulk.registryOfficeCreated.error',
  BULK_RO_TRANSFER_TAKEN_OVER_SUCCESS = 'be.internalNotification.subject.bulk.registryOfficeTakenOver.success',
  BULK_RO_TRANSFER_TAKEN_OVER_ERROR = 'be.internalNotification.subject.bulk.registryOfficeTakenOver.error',
  BULK_RO_TRANSFER_REJECT_SUCCESS = 'be.internalNotification.subject.bulk.registryOfficeReject.success',
  BULK_RO_TRANSFER_REJECT_ERROR = 'be.internalNotification.subject.bulk.registryOfficeReject.error',

  NOMENCLATURE_SYNCHRONIZATION_UPLOAD_SUCCESS = 'be.internalNotification.subject.nomenclature.upload.success',
  NOMENCLATURE_SYNCHRONIZATION_UPLOAD_ERROR = 'be.internalNotification.subject.nomenclature.upload.error',
  NOMENCLATURE_SYNCHRONIZATION_UPDATE_SUCCESS = 'be.internalNotification.subject.nomenclature.update.success',
  NOMENCLATURE_SYNCHRONIZATION_UPDATE_ERROR = 'be.internalNotification.subject.nomenclature.update.error',

  COMPONENT_ANONYMIZATION_COMPLETED = 'be.internalNotification.subject.component.anonymization.completed',
  COMPONENT_ANONYMIZATION_CANCELED = 'be.internalNotification.subject.component.anonymization.canceled',
  COMPONENT_NATIVE_EDIT_COMPLETED = 'be.internalNotification.subject.component.nativeEdit.completed',
  COMPONENT_NATIVE_EDIT_CANCELED = 'be.internalNotification.subject.component.nativeEdit.canceled',

  AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS = 'be.internalNotification.subject.paperToElectronicAuthFormConversion.success',
  AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_ERROR = 'be.internalNotification.subject.paperToElectronicAuthFormConversion.failed',
  VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS = 'be.internalNotification.subject.paperToElectronicFormConversion.success',
  VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR = 'be.internalNotification.subject.paperToElectronicFormConversion.failed',

  AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS = 'be.internalNotification.subject.electronicToPaperAuthFormConversion.success',
  AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_ERROR = 'be.internalNotification.subject.electronicToPaperAuthFormConversion.failed',
  VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS = 'be.internalNotification.subject.electronicToPaperFormConversion.success',
  VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR = 'be.internalNotification.subject.electronicToPaperFormConversion.failed',
  FORM_FILLER_CLAUSE_SUCCESS = 'be.internalNotification.subject.formFiller.clause.success',
}

export enum InternalNotificationConsignmentSubjectCode {
  CONSIGNMENT_BULK_HANDOVER_SUCCESS = 'be.internalNotification.subject.consignment.bulk.handover.success',
  CONSIGNMENT_BULK_HANDOVER_ERROR = 'be.internalNotification.subject.consignment.bulk.handover.error',
  CONSIGNMENT_BULK_TAKEOVER_SUCCESS = 'be.internalNotification.subject.consignment.bulk.takeover.success',
  CONSIGNMENT_BULK_TAKEOVER_ERROR = 'be.internalNotification.subject.consignment.bulk.takeover.error',
  CONSIGNMENT_BULK_PREPARE_FOR_DISTRIBUTION_SUCCESS = 'be.internalNotification.subject.consignment.bulk.prepareForDistribution.success',
  CONSIGNMENT_BULK_PREPARE_FOR_DISTRIBUTION_ERROR = 'be.internalNotification.subject.consignment.bulk.prepareForDistribution.error',
  CONSIGNMENT_BULK_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_SUCCESS = 'be.internalNotification.subject.consignment.bulk.takeoverAndPrepareForDistribution.success',
  CONSIGNMENT_BULK_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_ERROR = 'be.internalNotification.subject.consignment.bulk.takeoverAndPrepareForDistribution.error',
  CONSIGNMENT_BULK_DISPATCH_SUCCESS = 'be.internalNotification.subject.consignment.bulk.markAsDispatch.success',
  CONSIGNMENT_BULK_DISPATCH_ERROR = 'be.internalNotification.subject.consignment.bulk.markAsDispatch.error',
  CONSIGNMENT_BULK_DISPATCH_OUTSIDE_FILING_OFFICE_SUCCESS = 'be.internalNotification.subject.consignment.bulk.dispatchOutsideFilingOffice.success',
  CONSIGNMENT_BULK_DISPATCH_OUTSIDE_FILING_OFFICE_ERROR = 'be.internalNotification.subject.consignment.bulk.dispatchOutsideFilingOffice.error',
  CONSIGNMENT_BULK_REJECT_SUCCESS = 'be.internalNotification.subject.consignment.bulk.reject.success',
  CONSIGNMENT_BULK_REJECT_ERROR = 'be.internalNotification.subject.consignment.bulk.reject.error',
  CONSIGNMENT_BULK_WITHDRAW_FROM_DISTRIBUTION_SUCCESS = 'be.internalNotification.subject.consignment.bulk.withdrawFromDistribution.success',
  CONSIGNMENT_BULK_WITHDRAW_FROM_DISTRIBUTION_ERROR = 'be.internalNotification.subject.consignment.bulk.withdrawFromDistribution.error',
  CONSIGNMENT_BULK_WITHDRAW_FOR_CORRECTION_SUCCESS = 'be.internalNotification.subject.consignment.bulk.withdrawForCorrection.success',
  CONSIGNMENT_BULK_WITHDRAW_FOR_CORRECTION_ERROR = 'be.internalNotification.subject.consignment.bulk.withdrawForCorrection.error',
  CONSIGNMENT_BULK_CANCEL_SUCCESS = 'be.internalNotification.subject.consignment.bulk.cancel.success',
  CONSIGNMENT_BULK_CANCEL_ERROR = 'be.internalNotification.subject.consignment.bulk.cancel.error',
  CONSIGNMENT_BULK_DELETE_SUCCESS = 'be.internalNotification.subject.consignment.bulk.delete.success',
  CONSIGNMENT_BULK_DELETE_ERROR = 'be.internalNotification.subject.consignment.bulk.delete.error',
  CONSIGNMENT_BULK_PROOF_OF_DELIVERY_SUCCESS = 'be.internalNotification.subject.consignment.bulk.proofOfDelivery.success',
  CONSIGNMENT_BULK_PROOF_OF_DELIVERY_ERROR = 'be.internalNotification.subject.consignment.bulk.proofOfDelivery.error',
}

export enum InternalNotificationMessageCode {
  FLOW_TASK_ASSIGNED_INITIATOR = 'be.internalNotification.message.flow.task.assigned.initiator',
  FLOW_TASK_ASSIGNED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.task.assigned.targetParticipant',
  FLOW_TASK_SUCCESS_INITIATOR = 'be.internalNotification.message.flow.task.success.initiator',
  FLOW_TASK_SUCCESS_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.task.success.targetParticipant',
  FLOW_TASK_REJECTED_INITIATOR = 'be.internalNotification.message.flow.task.rejected.initiator',
  FLOW_TASK_REJECTED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.task.rejected.targetParticipant',
  FLOW_TASK_REVOKED_INITIATOR = 'be.internalNotification.message.flow.task.revoked.initiator',
  FLOW_TASK_REVOKED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.task.revoked.targetParticipant',
  FLOW_TASK_PASSED_INITIATOR = 'be.internalNotification.message.flow.task.passed.initiator',
  FLOW_TASK_PASSED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.task.passed.targetParticipant',
  FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR = 'be.internalNotification.message.flow.handover.task.assigned.initiator',
  FLOW_HANDOVER_TASK_ASSIGNED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handover.task.assigned.targetParticipant',
  FLOW_HANDOVER_TASK_SUCCESS_INITIATOR = 'be.internalNotification.message.flow.handover.task.success.initiator',
  FLOW_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handover.task.success.targetParticipant',
  FLOW_HANDOVER_TASK_REJECTED_INITIATOR = 'be.internalNotification.message.flow.handover.task.rejected.initiator',
  FLOW_HANDOVER_TASK_REJECTED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handover.task.rejected.targetParticipant',
  FLOW_HANDOVER_TASK_REVOKED_INITIATOR = 'be.internalNotification.message.flow.handover.task.revoked.initiator',
  FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handover.task.revoked.targetParticipant',
  FLOW_HANDOVER_TASK_PASSED_INITIATOR = 'be.internalNotification.message.flow.handover.task.passed.initiator',
  FLOW_HANDOVER_TASK_PASSED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handover.task.passed.targetParticipant',

  FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_INITIATOR = 'be.internalNotification.message.flow.handoverToOwnHands.task.returned.initiator',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handoverToOwnHands.task.returned.targetParticipant',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_INITIATOR = 'be.internalNotification.message.flow.handoverToOwnHands.task.rejected.initiator',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handoverToOwnHands.task.rejected.targetParticipant',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_INITIATOR = 'be.internalNotification.message.flow.handoverToOwnHands.task.revoked.initiator',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handoverToOwnHands.task.revoked.targetParticipant',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_INITIATOR = 'be.internalNotification.message.flow.handoverToOwnHands.task.success.initiator',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handoverToOwnHands.task.success.targetParticipant',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_INITIATOR = 'be.internalNotification.message.flow.handoverToOwnHands.task.passed.initiator',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.handoverToOwnHands.task.passed.targetParticipant',

  FLOW_COMPONENT_APPROVAL_TASK_APPROVED_INITIATOR = 'be.internalNotification.message.flow.component.approval.task.approved.initiator',
  FLOW_COMPONENT_APPROVAL_TASK_REVOKED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.component.approval.task.revoked.targetParticipant',
  FLOW_COMPONENT_APPROVAL_TASK_DISAPPROVED_INITIATOR = 'be.internalNotification.message.flow.component.approval.task.disapproved.initiator',
  FLOW_COMPONENT_APPROVAL_TASK_REJECTED_INITIATOR = 'be.internalNotification.message.flow.component.approval.task.rejected.initiator',
  FLOW_COMPONENT_APPROVAL_TASK_PASSED_INITIATOR = 'be.internalNotification.message.flow.component.approval.task.passed.initiator',

  FLOW_COMPONENT_SIGNING_TASK_SIGNED_INITIATOR = 'be.internalNotification.message.flow.component.signing.task.signed.initiator',
  FLOW_COMPONENT_SIGNING_TASK_REVOKED_TARGET_PARTICIPANT = 'be.internalNotification.message.flow.component.signing.task.revoked.targetParticipant',
  FLOW_COMPONENT_SIGNING_TASK_REJECTED_INITIATOR = 'be.internalNotification.message.flow.component.signing.task.rejected.initiator',

  AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS = 'be.internalNotification.message.paperToElectronicAuthFormConversion.success',
  AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_ERROR = 'be.internalNotification.message.paperToElectronicAuthFormConversion.failed',
  VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS = 'be.internalNotification.message.paperToElectronicFormConversion.success',
  VERIFIED_CONVERSION_PAPER_TO_DIGITAL_ERROR = 'be.internalNotification.message.paperToElectronicFormConversion.failed',

  AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS = 'be.internalNotification.message.electronicToPaperAuthFormConversion.success',
  AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_ERROR = 'be.internalNotification.message.electronicToPaperAuthFormConversion.failed',
  FORM_FILLER_CLAUSE_SUCCESS = 'be.internalNotification.message.formFiller.clause.success',
  VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS = 'be.internalNotification.message.electronicToPaperFormConversion.success',
  VERIFIED_CONVERSION_DIGITAL_TO_PAPER_ERROR = 'be.internalNotification.message.electronicToPaperFormConversion.failed',

  CONVERSION_WITH_CLAUSE_SUCCESS = 'be.internalNotification.message.changeWithClause.success',
  CONVERSION_WITH_CLAUSE_ERROR = 'be.internalNotification.message.changeWithClause.failed',
  CONVERSION_SUCCESS = 'be.internalNotification.message.conversion.success',
  CONVERSION_ERROR = 'be.internalNotification.message.conversion.failed',

  DOCUMENT_SETTLEMENT_SUCCESS = 'be.internalNotification.message.settlement.document.success',
  DOCUMENT_SETTLEMENT_ERROR = 'be.internalNotification.message.settlement.document.error',
  FILE_SETTLEMENT_SUCCESS = 'be.internalNotification.message.settlement.file.success',
  FILE_SETTLEMENT_ERROR = 'be.internalNotification.message.settlement.file.error',

  OFFICE_DESK_POST_SUCCESS = 'be.internalNotification.message.officeDesk.post.success',
  OFFICE_DESK_POST_ERROR = 'be.internalNotification.message.officeDesk.post.error',
  OFFICE_DESK_UNPOST_SUCCESS = 'be.internalNotification.message.officeDesk.unpost.success',
  OFFICE_DESK_UNPOST_ERROR = 'be.internalNotification.message.officeDesk.unpost.error',

  FILING_REGISTER_RENDITION_SCHEDULE_SCHEDULED = 'be.internalNotification.message.filingRegister.rendition.scheduled',
  FILING_REGISTER_RENDITION_FINISHED = 'be.internalNotification.message.filingRegister.rendition.finished',

  IMPORT_SUCCESS = 'be.internalNotification.message.import.success',
  IMPORT_ERROR = 'be.internalNotification.message.import.error',

  BULK_COMPONENT_HANDOVER_FOR_SIGN_SUCCESS = 'be.internalNotification.message.bulk.componentHandForSigning.success',
  BULK_COMPONENT_HAND_FOR_APPROVAL_SUCCESS = 'be.internalNotification.message.bulk.componentHandForApproval.success',
  BULK_COMPONENT_ANALOG_SIGN_SUCCESS = 'be.internalNotification.message.bulk.signAnalogComponentByTarget.success',
  BULK_COMPONENT_DIGITAL_SIGN_SUCCESS = 'be.internalNotification.message.bulk.signDigitalComponentByTarget.success',
  BULK_DOCUMENT_HANDOVER_SUCCESS = 'be.internalNotification.message.bulk.documentHandover.success',
  BULK_FILE_HANDOVER_SUCCESS = 'be.internalNotification.message.bulk.fileHandover.success',
  BULK_HANDOVER_TAKEOVER_SUCCESS = 'be.internalNotification.message.bulk.takeoverByTarget.success',
  BULK_HANDOVER_FILE_TAKEOVER_SUCCESS = 'be.internalNotification.message.bulk.fileTakeoverByTarget.success',
  BULK_DOCUMENT_HAND_FOR_APPROVAL_SUCCESS = 'be.internalNotification.message.bulk.documentHandForApproval.success',
  BULK_DOCUMENT_HAND_FOR_STATEMENT_SUCCESS = 'be.internalNotification.message.bulk.handForStatement.success',
  BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_SUCCESS = 'be.internalNotification.message.bulk.handForAcknowledgement.success',
  BULK_DOCUMENT_ACKNOWLEDGE_TARGET_SUCCESS = 'be.internalNotification.message.bulk.acknowledgeByTarget.success',
  BULK_DOCUMENT_APPROVE_BY_TARGET_SUCCESS = 'be.internalNotification.message.bulk.documentApproveByTarget.success',
  BULK_DOCUMENT_STATEMENT_BY_TARGET_SUCCESS = 'be.internalNotification.message.bulk.statementByTarget.success',
  BULK_DOCUMENT_DISAPPROVE_BY_TARGET_SUCCESS = 'be.internalNotification.message.bulk.documentDisapproveByTarget.success',
  BULK_COMPONENT_APPROVE_BY_TARGET_SUCCESS = 'be.internalNotification.message.bulk.componentApproveByTarget.success',
  BULK_COMPONENT_DISAPPROVE_BY_TARGET_SUCCESS = 'be.internalNotification.message.bulk.componentDisapproveByTarget.success',
  BULK_DOCUMENT_TAKEOVER_AND_PASS_SUCCESS = 'be.internalNotification.message.bulk.documentTakeoverAndPass.success',
  BULK_FILE_TAKEOVER_AND_PASS_SUCCESS = 'be.internalNotification.message.bulk.fileTakeoverAndPass.success',
  BULK_TASK_REJECT_SUCCESS = 'be.internalNotification.message.bulk.taskReject.success',
  BULK_TASK_REVOKE_SUCCESS = 'be.internalNotification.message.bulk.taskRevoke.success',
  BULK_TASK_PASS_TO_NEXT_SUCCESS = 'be.internalNotification.message.bulk.taskPassToNext.success',

  BULK_COMPONENT_HANDOVER_FOR_SIGN_ERROR = 'be.internalNotification.message.bulk.componentHandForSigning.error',
  BULK_COMPONENT_HAND_FOR_APPROVAL_ERROR = 'be.internalNotification.message.bulk.componentHandForApproval.error',
  BULK_COMPONENT_ANALOG_SIGN_ERROR = 'be.internalNotification.message.bulk.signAnalogComponentByTarget.error',
  BULK_COMPONENT_DIGITAL_SIGN_ERROR = 'be.internalNotification.message.bulk.signDigitalComponentByTarget.error',
  BULK_COMPONENT_SIGNING_TARGET_ERROR = 'be.internalNotification.message.bulk.componentSigningByTarget.error',
  BULK_DOCUMENT_HANDOVER_ERROR = 'be.internalNotification.message.bulk.documentHandover.error',
  BULK_FILE_HANDOVER_ERROR = 'be.internalNotification.message.bulk.fileHandover.error',
  BULK_DOCUMENT_HANDOVER_TAKEOVER_ERROR = 'be.internalNotification.message.bulk.takeoverByTarget.error',
  BULK_DOCUMENT_HAND_FOR_STATEMENET_ERROR = 'be.internalNotification.message.bulk.handForStatement.error',
  BULK_DOCUMENT_HAND_FOR_APPROVAL_ERROR = 'be.internalNotification.message.bulk.documentHandForApproval.error',
  BULK_DOCUMENT_HAND_FOR_ACKNOWLEDGEMENT_ERROR = 'be.internalNotification.message.bulk.handForAcknowledgement.error',
  BULK_DOCUMENT_ACKNOWLEDGE_TARGET_ERROR = 'be.internalNotification.message.bulk.acknowledgeByTarget.error',
  BULK_DOCUMENT_APPROVE_BY_TARGET_ERROR = 'be.internalNotification.message.bulk.documentApproveByTarget.error',
  BULK_DOCUMENT_STATEMENT_BY_TARGET_ERROR = 'be.internalNotification.message.bulk.statementByTarget.error',
  BULK_DOCUMENT_DISAPPROVE_BY_TARGET_ERROR = 'be.internalNotification.message.bulk.documentDisapproveByTarget.error',
  BULK_COMPONENT_APPROVE_BY_TARGET_ERROR = 'be.internalNotification.message.bulk.componentApproveByTarget.error',
  BULK_COMPONENT_DISAPPROVE_BY_TARGET_ERROR = 'be.internalNotification.message.bulk.componentDisapproveByTarget.error',
  BULK_DOCUMENT_TAKEOVER_AND_PASS_ERROR = 'be.internalNotification.message.bulk.documentTakeoverAndPass.error',
  BULK_FILE_TAKEOVER_AND_PASS_ERROR = 'be.internalNotification.message.bulk.fileTakeoverAndPass.error',
  BULK_TASK_REJECT_ERROR = 'be.internalNotification.message.bulk.taskReject.error',
  BULK_TASK_REVOKE_ERROR = 'be.internalNotification.message.bulk.taskRevoke.error',
  BULK_TASK_PASS_TO_NEXT_ERROR = 'be.internalNotification.message.bulk.taskPassToNext.error',
  EXECUTION_DATE_CLAUSE_CREATE_SUCCESS = 'be.internalNotification.message.executionDateClause.create.success',
  EXECUTION_DATE_CLAUSE_CREATE_ERROR = 'be.internalNotification.message.executionDateClause.create.error',

  BULK_STORAGE_UNIT_LOCK_SUCCESS = 'be.internalNotification.message.bulk.blockStorageUnit.success',
  BULK_STORAGE_UNIT_LOCK_ERROR = 'be.internalNotification.message.bulk.blockStorageUnit.error',
  BULK_STORAGE_UNIT_UNLOCK_SUCCESS = 'be.internalNotification.message.bulk.unblockStorageUnit.success',
  BULK_STORAGE_UNIT_UNLOCK_ERROR = 'be.internalNotification.message.bulk.unblockStorageUnit.error',
  BULK_STORAGE_UNIT_DELETE_SUCCESS = 'be.internalNotification.message.bulk.deleteStorageUnit.success',
  BULK_STORAGE_UNIT_DELETE_ERROR = 'be.internalNotification.message.bulk.deleteStorageUnit.error',
  BULK_INSERT_TO_STORAGE_UNIT_SUCCESS = 'be.internalNotification.message.bulk.storageUnit.entitiesInsert.success',
  BULK_INSERT_TO_STORAGE_UNIT_ERROR = 'be.internalNotification.message.bulk.storageUnit.entitiesInsert.error',
  BULK_WITHDRAW_FROM_STORAGE_UNIT_SUCCESS = 'be.internalNotification.message.bulk.storageUnit.entitiesWithdraw.success',
  BULK_WITHDRAW_FROM_STORAGE_UNIT_ERROR = 'be.internalNotification.message.bulk.storageUnit.entitiesWithdraw.error',
  BULK_MOVE_BETWEEN_STORAGE_UNITS_SUCCESS = 'be.internalNotification.message.bulk.storageUnit.entitiesMove.success',
  BULK_MOVE_BETWEEN_STORAGE_UNITS_ERROR = 'be.internalNotification.message.bulk.storageUnit.entitiesMove.error',
  BULK_EPDZ_ERROR_PROCESSING_SUCCESS = 'be.internalNotification.message.epdz.error.bulk.success',
  BULK_EPDZ_ERROR_PROCESSING_ERROR = 'be.internalNotification.message.epdz.error.bulk.error',
  EPDZ_ERROR_NO_CONTAINER = 'be.internalNotification.message.epdz.error.noContainer',
  EPDZ_ERROR_CONTAINER_CORRUPT = 'be.internalNotification.message.epdz.error.containerCorrupt',
  EPDZ_ERROR_GENERAL = 'be.internalNotification.message.epdz.error.general',
  STORAGE_UNIT_FILLER_SUCCESS = 'be.internalNotification.message.storageUnit.filler.success',
  STORAGE_UNIT_FILLER_STARTED = 'be.internalNotification.message.storageUnit.filler.started',
  STORAGE_UNIT_FILLER_ERROR = 'be.internalNotification.message.storageUnit.filler.error',

  BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_SUCCESS = 'be.internalNotification.message.bulk.registryOfficeTransferIncidentMarkResolved.success',
  BULK_RO_TRANSFER_INCIDENT_MARK_AS_RESOLVED_ERROR = 'be.internalNotification.message.bulk.registryOfficeTransferIncidentMarkResolved.error',
  BULK_RO_TRANSFER_CANCEL_SUCCESS = 'be.internalNotification.message.bulk.registryOfficeCancelTransfer.success',
  BULK_RO_TRANSFER_CANCEL_ERROR = 'be.internalNotification.message.bulk.registryOfficeCancelTransfer.error',
  BULK_RO_TRANSFER_RETRANSFER_SUCCESS = 'be.internalNotification.message.bulk.registryOfficeReTransfer.success',
  BULK_RO_TRANSFER_RETRANSFER_ERROR = 'be.internalNotification.message.bulk.registryOfficeReTransfer.error',
  BULK_RO_TRANSFER_RETURN_TO_OFFICE_SUCCESS = 'be.internalNotification.message.bulk.registryOfficeReturnToOffice.success',
  BULK_RO_TRANSFER_RETURN_TO_OFFICE_ERROR = 'be.internalNotification.message.bulk.registryOfficeReturnToOffice.error',
  BULK_RO_TRANSFER_CREATED_SUCCESS = 'be.internalNotification.message.bulk.registryOfficeCreated.success',
  BULK_RO_TRANSFER_CREATED_ERROR = 'be.internalNotification.message.bulk.registryOfficeCreated.error',
  BULK_RO_TRANSFER_TAKEN_OVER_SUCCESS = 'be.internalNotification.message.bulk.registryOfficeTakenOver.success',
  BULK_RO_TRANSFER_TAKEN_OVER_ERROR = 'be.internalNotification.message.bulk.registryOfficeTakenOver.error',
  BULK_RO_TRANSFER_REJECT_SUCCESS = 'be.internalNotification.message.bulk.registryOfficeReject.success',
  BULK_RO_TRANSFER_REJECT_ERROR = 'be.internalNotification.message.bulk.registryOfficeReject.error',

  DOCUMENT_TYPE_IMPORT_SUCCESS = 'be.internalNotification.message.bulk.documentTypeImport.success',
  DOCUMENT_TYPE_IMPORT_ERROR = 'be.internalNotification.message.bulk.documentTypeImport.error',
  NOMENCLATURE_SYNCHRONIZATION_UPLOAD_SUCCESS = 'be.internalNotification.message.nomenclature.upload.success',
  NOMENCLATURE_SYNCHRONIZATION_UPDATE_SUCCESS = 'be.internalNotification.message.nomenclature.update.success',
  NOMENCLATURE_SYNCHRONIZATION_UPLOAD_ERROR = 'be.internalNotification.message.nomenclature.upload.error',
  NOMENCLATURE_SYNCHRONIZATION_UPDATE_ERROR = 'be.internalNotification.message.nomenclature.update.error',

  COMPONENT_ANONYMIZATION_COMPLETED = 'be.internalNotification.message.component.anonymization.completed',
  COMPONENT_ANONYMIZATION_CANCELED = 'be.internalNotification.message.component.anonymization.canceled',
  COMPONENT_NATIVE_EDIT_COMPLETED = 'be.internalNotification.message.component.nativeEdit.completed',
  COMPONENT_NATIVE_EDIT_CANCELED = 'be.internalNotification.message.component.nativeEdit.canceled',
}

export enum InternalNotificationConsignmentMessageCode {
  CONSIGNMENT_BULK_HANDOVER_SUCCESS = 'be.internalNotification.message.consignment.bulk.handover.success',
  CONSIGNMENT_BULK_HANDOVER_ERROR = 'be.internalNotification.message.consignment.bulk.handover.error',
  CONSIGNMENT_BULK_TAKEOVER_SUCCESS = 'be.internalNotification.message.consignment.bulk.takeover.success',
  CONSIGNMENT_BULK_TAKEOVER_ERROR = 'be.internalNotification.message.consignment.bulk.takeover.error',
  CONSIGNMENT_BULK_PREPARE_FOR_DISTRIBUTION_SUCCESS = 'be.internalNotification.message.consignment.bulk.prepareForDistribution.success',
  CONSIGNMENT_BULK_PREPARE_FOR_DISTRIBUTION_ERROR = 'be.internalNotification.message.consignment.bulk.prepareForDistribution.error',
  CONSIGNMENT_BULK_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_SUCCESS = 'be.internalNotification.message.consignment.bulk.takeoverAndPrepareForDistribution.success',
  CONSIGNMENT_BULK_TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION_ERROR = 'be.internalNotification.message.consignment.bulk.takeoverAndPrepareForDistribution.error',
  CONSIGNMENT_BULK_DISPATCH_SUCCESS = 'be.internalNotification.message.consignment.bulk.markAsDispatch.success',
  CONSIGNMENT_BULK_DISPATCH_ERROR = 'be.internalNotification.message.consignment.bulk.markAsDispatch.error',
  CONSIGNMENT_BULK_DISPATCH_OUTSIDE_FILING_OFFICE_SUCCESS = 'be.internalNotification.message.consignment.bulk.dispatchOutsideFilingOffice.success',
  CONSIGNMENT_BULK_DISPATCH_OUTSIDE_FILING_OFFICE_ERROR = 'be.internalNotification.message.consignment.bulk.dispatchOutsideFilingOffice.error',
  CONSIGNMENT_BULK_REJECT_SUCCESS = 'be.internalNotification.message.consignment.bulk.reject.success',
  CONSIGNMENT_BULK_REJECT_ERROR = 'be.internalNotification.message.consignment.bulk.reject.error',
  CONSIGNMENT_BULK_WITHDRAW_FROM_DISTRIBUTION_SUCCESS = 'be.internalNotification.message.consignment.bulk.withdrawFromDistribution.success',
  CONSIGNMENT_BULK_WITHDRAW_FROM_DISTRIBUTION_ERROR = 'be.internalNotification.message.consignment.bulk.withdrawFromDistribution.error',
  CONSIGNMENT_BULK_WITHDRAW_FOR_CORRECTION_SUCCESS = 'be.internalNotification.message.consignment.bulk.withdrawForCorrection.success',
  CONSIGNMENT_BULK_WITHDRAW_FOR_CORRECTION_ERROR = 'be.internalNotification.message.consignment.bulk.withdrawForCorrection.error',
  CONSIGNMENT_BULK_CANCEL_SUCCESS = 'be.internalNotification.message.consignment.bulk.cancel.success',
  CONSIGNMENT_BULK_CANCEL_ERROR = 'be.internalNotification.message.consignment.bulk.cancel.error',
  CONSIGNMENT_BULK_DELETE_SUCCESS = 'be.internalNotification.message.consignment.bulk.delete.success',
  CONSIGNMENT_BULK_DELETE_ERROR = 'be.internalNotification.message.consignment.bulk.delete.error',
  CONSIGNMENT_BULK_PROOF_OF_DELIVERY_SUCCESS = 'be.internalNotification.message.consignment.bulk.proofOfDelivery.success',
  CONSIGNMENT_BULK_PROOF_OF_DELIVERY_ERROR = 'be.internalNotification.message.consignment.bulk.proofOfDelivery.error',
}

export enum InternalNotificationBulkFileActionMessageCode {
  BULK_CLOSE_FILE_SUCCESS = 'be.internalNotification.message.bulk.closeFile.success',
  BULK_CLOSE_FILE_ERROR = 'be.internalNotification.message.bulk.closeFile.error',
  BULK_WITHDRAW_SETTLE_FILE_SUCCESS ='be.internalNotification.message.bulk.withdrawSettleFile.success',
  BULK_WITHDRAW_SETTLE_FILE_ERROR = 'be.internalNotification.message.bulk.withdrawSettleFile.error',
  BULK_REOPEN_FILE_SUCCESS = 'be.internalNotification.message.bulk.reopen.success',
  BULK_REOPEN_FILE_ERROR = 'be.internalNotification.message.bulk.reopen.error',
}

export enum InternalNotificationBulkFileActionSubjectCode {
  BULK_CLOSE_FILE_SUCCESS = 'be.internalNotification.subject.bulk.closeFile.success',
  BULK_CLOSE_FILE_ERROR = 'be.internalNotification.subject.bulk.closeFile.error',
  BULK_WITHDRAW_SETTLE_FILE_SUCCESS ='be.internalNotification.subject.bulk.withdrawSettleFile.success',
  BULK_WITHDRAW_SETTLE_FILE_ERROR = 'be.internalNotification.subject.bulk.withdrawSettleFile.error',
  BULK_REOPEN_FILE_SUCCESS = 'be.internalNotification.subject.bulk.reopen.success',
  BULK_REOPEN_FILE_ERROR = 'be.internalNotification.subject.bulk.reopen.error',
}

export enum InternalNotificationBulkDocumentActionMessageCode {
  BULK_INSERT_DOCUMENTS_INTO_FILE_SUCCESS = 'be.internalNotification.message.bulk.insertDocumentsIntoFile.success',
  BULK_INSERT_DOCUMENTS_INTO_FILE_ERROR = 'be.internalNotification.message.bulk.insertDocumentsIntoFile.error',
  BULK_REMOVE_DOCUMENTS_FROM_FILE_SUCCESS = 'be.internalNotification.message.bulk.removeDocumentsFromFile.success',
  BULK_REMOVE_DOCUMENTS_FROM_FILE_ERROR = 'be.internalNotification.message.bulk.removeDocumentsFromFile.error',
  BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_SUCCESS = 'be.internalNotification.message.bulk.moveDocumentsToAnotherFile.success',
  BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_ERROR = 'be.internalNotification.message.bulk.moveDocumentsToAnotherFile.error',
  BULK_REGISTER_DOCUMENT_SUCCESS = 'be.internalNotification.message.bulk.registerDocument.success',
  BULK_REGISTER_DOCUMENT_ERROR = 'be.internalNotification.message.bulk.registerDocument.error',
  BULK_WITHDRAW_SETTLE_DOCUMENT_SUCCESS = 'be.internalNotification.message.bulk.withdrawSettleDocument.success',
  BULK_WITHDRAW_SETTLE_DOCUMENT_ERROR = 'be.internalNotification.message.bulk.withdrawSettleDocument.error',
}

export enum InternalNotificationBulkDocumentActionSubjectCode {
  BULK_INSERT_DOCUMENTS_INTO_FILE_SUCCESS = 'be.internalNotification.subject.bulk.insertDocumentsIntoFile.success',
  BULK_INSERT_DOCUMENTS_INTO_FILE_ERROR = 'be.internalNotification.subject.bulk.insertDocumentsIntoFile.error',
  BULK_REMOVE_DOCUMENTS_FROM_FILE_SUCCESS = 'be.internalNotification.subject.bulk.removeDocumentsFromFile.success',
  BULK_REMOVE_DOCUMENTS_FROM_FILE_ERROR = 'be.internalNotification.subject.bulk.removeDocumentsFromFile.error',
  BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_SUCCESS = 'be.internalNotification.subject.bulk.moveDocumentsToAnotherFile.success',
  BULK_MOVE_DOCUMENTS_TO_ANOTHER_FILE_ERROR = 'be.internalNotification.subject.bulk.moveDocumentsToAnotherFile.error',
  BULK_REGISTER_DOCUMENT_SUCCESS = 'be.internalNotification.subject.bulk.registerDocument.success',
  BULK_REGISTER_DOCUMENT_ERROR = 'be.internalNotification.subject.bulk.registerDocument.error',
  BULK_WITHDRAW_SETTLE_DOCUMENT_SUCCESS = 'be.internalNotification.subject.bulk.withdrawSettleDocument.success',
  BULK_WITHDRAW_SETTLE_DOCUMENT_ERROR = 'be.internalNotification.subject.bulk.withdrawSettleDocument.error',
}

export enum InternalNotificationBulkComponentActionMessageCode {
  CONVERSION_BULK_SUCCESS = 'be.internalNotification.message.bulk.conversion.success',
  CONVERSION_BULK_ERROR = 'be.internalNotification.message.bulk.conversion.failed',
  EXTRACTION_BULK_SUCCESS = 'be.internalNotification.message.component.extraction.success',
  EXTRACTION_BULK_ERROR = 'be.internalNotification.message.component.extraction.failed',
  CHANGE_WITH_CLAUSE_BULK_SUCCESS = 'be.internalNotification.message.bulk.changeWithClause.success',
  CHANGE_WITH_CLAUSE_BULK_ERROR = 'be.internalNotification.message.bulk.changeWithClause.failed',
  BULK_COMPONENT_LOCK_SUCCESS = 'be.internalNotification.message.bulk.lockComponent.success',
  BULK_COMPONENT_LOCK_ERROR = 'be.internalNotification.message.bulk.lockComponent.error',
  BULK_COMPONENT_UNLOCK_SUCCESS = 'be.internalNotification.message.bulk.unlockComponent.success',
  BULK_COMPONENT_UNLOCK_ERROR = 'be.internalNotification.message.bulk.unlockComponent.error',
  BULK_COMPONENT_DELETE_SUCCESS = 'be.internalNotification.message.bulk.deleteComponent.success',
  BULK_COMPONENT_DELETE_ERROR = 'be.internalNotification.message.bulk.deleteComponent.error',
}

export enum InternalNotificationBulkComponentActionSubjectCode {
  CONVERSION_BULK_SUCCESS = 'be.internalNotification.subject.bulk.conversion.success',
  CONVERSION_BULK_ERROR = 'be.internalNotification.subject.bulk.conversion.failed',
  EXTRACTION_BULK_SUCCESS = 'be.internalNotification.subject.component.extraction.success',
  EXTRACTION_BULK_ERROR = 'be.internalNotification.subject.component.extraction.failed',
  CHANGE_WITH_CLAUSE_BULK_SUCCESS = 'be.internalNotification.subject.bulk.changeWithClause.success',
  CHANGE_WITH_CLAUSE_BULK_ERROR = 'be.internalNotification.subject.bulk.changeWithClause.failed',
  BULK_COMPONENT_LOCK_SUCCESS = 'be.internalNotification.subject.bulk.lockComponent.success',
  BULK_COMPONENT_LOCK_ERROR = 'be.internalNotification.subject.bulk.lockComponent.error',
  BULK_COMPONENT_UNLOCK_SUCCESS = 'be.internalNotification.subject.bulk.unlockComponent.success',
  BULK_COMPONENT_UNLOCK_ERROR = 'be.internalNotification.subject.bulk.unlockComponent.error',
  BULK_COMPONENT_DELETE_SUCCESS = 'be.internalNotification.subject.bulk.deleteComponent.success',
  BULK_COMPONENT_DELETE_ERROR = 'be.internalNotification.subject.bulk.deleteComponent.error',
}
