import {inject, Injectable} from '@angular/core';
import {FilterItem, FilterValueFormatter} from '@icz/angular-table';
import {Observable} from 'rxjs';
import {AddressCompleteDto, AddressFormat, Countries} from '../../model/addresses.model';
import {ADDRESS_SHAPE_INDICATOR} from './address-custom-filter.component';
import {AddressPipe} from '../../../address-pipe/address.pipe';

@Injectable()
export class AddressFilterValueFormatterService implements FilterValueFormatter {

  private addressPipe = inject(AddressPipe);

  format(filterItem: FilterItem): Observable<string> {
    const address: Partial<AddressCompleteDto> = {};
    const subValueIds: string[] = [];

    filterItem.subValues?.forEach(subValue => {
      const attribute = subValue.subValueId!.replace(`${ADDRESS_SHAPE_INDICATOR}.`, '');
      subValueIds.push(attribute);
      address[attribute] = subValue.value!;
    });

    if (subValueIds.includes('addressLines')) {
      address._Class = AddressFormat.GenericLineAddressDto;
    }
    else {
      if (address.country === Countries.CZE) {
        address._Class = AddressFormat.CzechAddressDto;
      }
      else if (address.country === Countries.SVK) {
        address._Class = AddressFormat.SlovakAddressDto;
      }
    }

    return this.addressPipe.transform(address as AddressCompleteDto);
  }

}
