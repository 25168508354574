// list of methods, ie native app functions
export enum Idt2MethodName {
  sign = 'sign',
  getVersion = 'getVersion',
  getCertList = 'getCertList',
  getPrinters = 'getPrinters',
  print = 'print',
  openFile = 'openFile',
  openAnonymization = 'openAnonymization',
  getOpenCommand = 'getOpenCommand',
  setCustomOpenCommand = 'setCustomOpenCommand',
}

// IDT2 ecosystem consists of web page (web app), browser extension and native application
export enum Idt2Target {
  webApp = 'idt2-web',
  extension = 'idt2-ext',
  nativeApp = 'idt2-cli',
}

export enum Idt2HashAlgorithm {
  MD5 = 'md5',
  SHA1 = 'sha1',
  SHA256 = 'sha256',
  SHA512 = 'sha512',
}

export enum Idt2CertificateProvider {
  FILE = 'FILE',
  WINDOWS = 'WINDOWS',
  // SafeNet = 'SafeNet',
}

export type Idt2Message = Idt2RequestMessage | Idt2ResponseMessage | Idt2ErrorMessage;

export interface Idt2Header {
  messageId: string,
  source: Idt2Target,
  target: Idt2Target,
  methodName: Idt2MethodName,
}

export interface Idt2RequestHeader extends Idt2Header {
  // TODO adding of the result property in descendants should be disabled
  // instead, it causes an Typescript error TS2741: Property 'result' is missing
  // result: never
}

export interface Idt2ResponseHeader extends Idt2Header {
  result: true
}

export interface Idt2ErrorMessage extends Idt2Header {
  result: false,
  code: number,
  errorType: string,
  message: string,
}

/**
 * Workaround for undefined enums at runtime - duplicated enums from @icz/idt/shared/messages.ts.
 * Try importing those enums from @icz/idt/shared/messages.ts after upgrading to TypeScript 4.7.
 */

export enum ErrorType {
  nativeAppUnreachable = 100,
  methodNotImplemented = 110,
  methodNotFound,
  methodInternalError,
  certStoreNotConfigured = 120,
  certNotFound,
  openFileError = 130,
  mimeTypeInvalid,
  mimeTypeNotFound,
  fileExtensionInvalid,
  fileExtensionNotFound,
  unknownFileType,
  customOpenCommandsDBInvalid,
}


// type guards
// ---------------------------------------------------------------------------------------------

export type Idt2RequestMessage =
  getVersionReq
  | getCertListReq
  | signReq
  | openFileReq
  | openAnonymizationReq
  | getOpenCommandReq
  | setCustomOpenCommandReq
  | getPrintersReq
  | printReq;

export type Idt2ResponseMessage =
  getVersionRes
  | getCertListRes
  | signRes
  | openFileRes
  | openAnonymizationRes
  | getOpenCommandRes
  | setCustomOpenCommandRes
  | getPrintersRes
  | printRes;

// general test if value is included in enum (in form of type guard)
const typeGuardForEnum = <T>(e: T) =>
  (value: any): value is T[keyof T] =>
    Object.values(e as Record<string, unknown>).includes(value as T[keyof T]);

export const isIdt2Target = typeGuardForEnum(Idt2Target);
export const isIdt2MethodName = typeGuardForEnum(Idt2MethodName);

// in fact Idt2Header conformity is tested, however, the value is considered an Idt2Message
export function isIdt2Message(value: any): value is Idt2Message {
  return isIdt2MethodName(value?.methodName)
    && isIdt2Target(value?.source)
    && isIdt2Target(value?.target);
}

export function isIdt2RequestMessage(value: Idt2Message): value is Idt2RequestMessage {
  return !('result' in (value as any));
}

export function isIdt2ResponseMessage(value: Idt2Message): value is Idt2ResponseMessage {
  return (value as any).result === true;
}

export function isIdt2ErrorMessage(value: Idt2Message): value is Idt2ErrorMessage {
  return (value as any).result === false;
}

// message types by methodName
// ---------------------------------------------------------------------------------------------

export interface getVersionReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.getVersion,
}

export interface getVersionRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.getVersion,
  data: {
    version: string,
  },
}

export interface getCertListReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.getCertList,
  data?: {
    eidas: boolean,
  },
}

export interface Idt2CertificateLocation {
  provider?: Idt2CertificateProvider,
  alias: string,
}

export interface Idt2Certificate {
  password: boolean, // keystore je chráněn heslem
  kestore?: string,
  notAfter: Date,
  notBefore: Date,
  serialNumber: string,
  signature?: boolean,
  sscd?: boolean,
  seal?: boolean,
  subject: string,
  issuer: string,
  location: Idt2CertificateLocation,
  sha1: string,
  pem: string,
  chain?: string[],
}

export interface getCertListRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.getCertList,
  data: {
    locations: Idt2Certificate[],
  },
}

interface signReqDataBase {
  sha1: string,
  password?: string,
  hashAlg?: Idt2HashAlgorithm,
}

export interface signReqDataMessage extends signReqDataBase {
  message: string, // Base64 encoded message to sign
}

export interface signReqDataHash extends signReqDataBase {
  hash: string,
}

export function isSignReqDataMessage(data: signReqDataMessage | signReqDataHash): data is signReqDataMessage {
  return typeof (data as signReqDataMessage).message === 'string';
}

export interface signReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.sign,
  data: signReqDataMessage | signReqDataHash,
}

export interface signRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.sign,
  data: {
    signature: string,
  },
}

export interface getPrintersReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.getPrinters,
}

export interface getPrintersRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.getPrinters,
  data: {
    printers: Idt2Printer[],
  },
}

export interface Idt2Printer {
  name: string,
}

export interface printReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.print,
  data: {
    printerName: string,
    barcode: string,
  },
}

export interface printRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.print,
}

export interface Idt2OpenCommandSelector {
  mimeType?: string,
  fileExtension?: string,
}

export interface Idt2OpenCommandDefinition extends Idt2OpenCommandSelector {
  command: string,
}

export interface openFileReqData extends Idt2OpenCommandSelector {
  url: string,
}

export interface openAnonymizationReqData extends Idt2OpenCommandSelector {
  url: string,
}

export interface Idt2OpenCommandInfo extends Idt2OpenCommandDefinition {
  isCustom: boolean,
}

export interface openFileReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.openFile,
  data: openFileReqData,
}

export interface openFileRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.openFile,
}

export interface openAnonymizationReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.openAnonymization,
  data: openAnonymizationReqData,
}

export interface openAnonymizationRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.openAnonymization,
}

export interface getOpenCommandReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.getOpenCommand,
  data: Partial<openFileReqData>,
}

export interface getOpenCommandRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.getOpenCommand,
  data: Idt2OpenCommandInfo,
}

export interface setCustomOpenCommandReq extends Idt2RequestHeader {
  methodName: Idt2MethodName.setCustomOpenCommand,
  data: Idt2OpenCommandDefinition,
}

export interface setCustomOpenCommandRes extends Idt2ResponseHeader {
  methodName: Idt2MethodName.setCustomOpenCommand,
  data: Idt2OpenCommandInfo,
}
