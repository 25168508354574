<div class="row gap-16 items-center">
  <div class="gauge-counter" [ngClass]="gaugeColorClass">
    <mat-progress-spinner
      class="example-margin"
      mode="determinate"
      [diameter]="55"
      [value]="100">
    </mat-progress-spinner>
    <div class="count font-bold">
      {{ count ?? 0 | numberToLocaleString }}
    </div>
  </div>
  <span class="font-bold">
    {{ name | translate }}
  </span>
</div>
