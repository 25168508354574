/* tslint:disable */
/* eslint-disable */

/**
 * Batch separation type for agenda transfer
 */
export enum AgendaTransferBatchSeparation {
  BY_OBJECT = 'BY_OBJECT',
  BY_BATCH_SIZE = 'BY_BATCH_SIZE'
}
