import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {
  ApiEntitySharingManagementService,
  AuthorizedEntityType,
  DocumentSharingCreateDto,
  DocumentSharingDto,
  DocumentSharingUpdateDto,
  FileSharingCreateDto,
  FileSharingDto,
  FileSharingUpdateDto,
  SharedFolderSharingCreateDto,
  SharedFolderSharingDto,
  SharedFolderSharingUpdateDto,
  StorageUnitSharingCreateDto,
  StorageUnitSharingDto,
  StorageUnitSharingUpdateDto
} from '|api/core';
import {PermissionToastService} from './permission-toast.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {DialogService} from '@icz/angular-modal';

export type ManualSharingWithFineGrainedPermissionsCreateDto = DocumentSharingCreateDto | FileSharingCreateDto | StorageUnitSharingCreateDto;
export type ManualSharingCreateDto = ManualSharingWithFineGrainedPermissionsCreateDto | SharedFolderSharingCreateDto;
export type ManualSharingWithFineGrainedPermissionsUpdateDto = DocumentSharingUpdateDto | FileSharingUpdateDto | StorageUnitSharingUpdateDto;
export type ManualSharingUpdateDto = ManualSharingWithFineGrainedPermissionsUpdateDto | SharedFolderSharingUpdateDto;
export type ManualSharingWithFineGrainedPermissionsDto = DocumentSharingDto | FileSharingDto | StorageUnitSharingDto;
export type ManualSharingDto = ManualSharingWithFineGrainedPermissionsDto | SharedFolderSharingDto;

@Injectable({
  providedIn: 'root',
})
export class PermissionService {

  private permissionToastService = inject(PermissionToastService);
  private apiEntitySharingManagementService = inject(ApiEntitySharingManagementService);
  private loadingIndicatorService = inject(LoadingIndicatorService);
  private dialogService = inject(DialogService);

  addPermission(entityType: AuthorizedEntityType, permission: ManualSharingCreateDto) {
    let req$!: Observable<ManualSharingDto>;

    if (entityType === AuthorizedEntityType.DOCUMENT) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementSetupDocumentSharing({body: permission as DocumentSharingCreateDto});
    }
    else if (entityType === AuthorizedEntityType.FILE) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementSetupFileSharing({body: permission as FileSharingCreateDto});
    }
    else if (entityType === AuthorizedEntityType.STORAGE_UNIT) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementSetupStorageUnitSharing({body: permission as StorageUnitSharingCreateDto});
    }
    else if (entityType === AuthorizedEntityType.SHARED_FOLDER) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementSetupSharedFolderSharing({body: permission as SharedFolderSharingCreateDto});
    }

    return this.loadingIndicatorService.doLoading(
      req$.pipe(tap(() => this.permissionToastService.dispatchPermissionAdded())),
      this
    );
  }

  updatePermission(entityType: AuthorizedEntityType, sharingId: number, permission: ManualSharingUpdateDto) {
    let req$!: Observable<ManualSharingDto>;

    if (entityType === AuthorizedEntityType.DOCUMENT) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementRedefineDocumentSharing({sharingId, body: permission as DocumentSharingUpdateDto});
    }
    else if (entityType === AuthorizedEntityType.FILE) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementRedefineFileSharing({sharingId, body: permission as FileSharingUpdateDto});
    }
    else if (entityType === AuthorizedEntityType.STORAGE_UNIT) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementRedefineStorageUnitSharing({sharingId, body: permission as StorageUnitSharingDto});
    }
    else if (entityType === AuthorizedEntityType.SHARED_FOLDER) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementRedefineSharedFolderSharing({sharingId, body: permission as SharedFolderSharingDto});
    }

    return this.loadingIndicatorService.doLoading(
      req$.pipe(tap(() => this.permissionToastService.dispatchPermissionUpdated())),
      this
    );
  }

  deletePermission(entityType: AuthorizedEntityType, sharingId: number) {
    let req$!: Observable<void>;

    if (entityType === AuthorizedEntityType.DOCUMENT) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementRevokeDocumentSharing({sharingId});
    }
    else if (entityType === AuthorizedEntityType.FILE) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementRevokeFileSharing({sharingId});
    }
    else if (entityType === AuthorizedEntityType.STORAGE_UNIT) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementRevokeStorageUnitSharing({sharingId});
    }
    else if (entityType === AuthorizedEntityType.SHARED_FOLDER) {
      req$ =  this.apiEntitySharingManagementService.entitySharingManagementRevokeSharedFolderSharing({sharingId});
    }

    return this.dialogService.openQuestionDialog({
      title: 'Odstranění sdílení',
      description: 'Nasdílení organizační jednotce/funkčnímu místu bude odstraněno.',
      question: 'Odstranění sdílení',
      leftButtonTitle: 'Odstranit sdílení',
    }).pipe(
      switchMap(() => {
        return this.loadingIndicatorService.doLoading(req$, this);
      }),
      tap(() => this.permissionToastService.dispatchPermissionDeleted())
    );
  }

}
