import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {
  ApplicationConfigService,
  SignaturePlaceTimestamp
} from '../../../../../core/services/config/application-config.service';
import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {injectModalData, injectModalRef} from '@icz/angular-modal';

interface DigitalComponentMarkTimestampDialogResult {
  assignTimestamp: boolean;
}

/*
  DigitalComponent can be marked with or without timestamp
  DigitalComponent can also be marked visually - not implemented yet
*/

@Component({
  selector: 'icz-digital-component-mark-timestamp-dialog',
  templateUrl: './digital-component-mark-timestamp-dialog.component.html',
  styleUrls: ['./digital-component-mark-timestamp-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalComponentMarkTimestampDialogComponent {

  protected applicationConfigService = inject(ApplicationConfigService);
  protected modalRef = injectModalRef<Nullable<DigitalComponentMarkTimestampDialogResult>>();
  private translateService = inject(TranslateService);
  private translateParser = inject(TranslateParser);
  private componentCount = injectModalData<number>();

  readonly SignaturePlaceTimestamp = SignaturePlaceTimestamp;

  get dialogText() {
    if (this.componentCount === 1) {
      return this.translateService.instant('Opatřit komponentu elektronickou pečetí.');
    } else {
      return this.translateParser.interpolate(this.translateService.instant('Opatřit komponenty ({{count}}) elektronickou pečetí.'), {count: String(this.componentCount)});
    }
  }

  close() {
    this.modalRef.close();
  }

  markDigitalComponent(assignTimestamp: boolean) {
    this.modalRef.close({assignTimestamp});
  }

}
