import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {DateRange, MatCalendar, MatCalendarCellCssClasses, MatCalendarView} from '@angular/material/datepicker';
import {IczValidatorResult} from '../validators/icz-validators/validator-decorators';

import {isDateRange} from '../form-elements.model';
import {FREE_DAYS_PROVIDER} from '../form-elements.providers';

/**
 * A predicate which, based on the provided date, returns:
 * - true if the date is considered valid in a given context,
 * - false otherwise.
 */
export type ValidDateFn = (date: Date) => boolean;

/**
 * Special predicates which implement DatePicker validation capabilities.
 * @deprecated - we would like to merge these into IczValidators with additional metadata for interoperability.
 */
export interface ValidDateFnWithMessage {
  /**
   * @see ValidDateFn
   */
  validationFn: ValidDateFn;
  /**
   * If validationFn returns false, this object specifying a message with optional
   * interpolation parameters is used for rendering a validation message below an affected datepicker.
   */
  invalidDateMessage?: IczValidatorResult;
}

/**
 * Inline calendar component which allows the user to pick a specific date from
 * a set of dates in a grid layout. The set of dates can be constrained using ValidDateFns.
 */
@Component({
  selector: 'icz-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCalendar,
  ]
})
export class CalendarComponent {

  private freeDaysProvider = inject(FREE_DAYS_PROVIDER);

  /**
   * The calendar view will be paged to the month where this date belongs to.
   */
  @Input()
  startAt: Nullable<Date | DateRange<Date> | string>;
  /**
   * Selected date value.
   */
  @Input()
  selected: Nullable<Date | DateRange<Date> | string>;
  /**
   * Date validator which greys out unselectable dates.
   */
  @Input()
  selectableDates: ValidDateFn = _ => true;
  /**
   * If true, holiday dates supplied by FREE_DAYS_PROVIDER will be rendered red and will be
   * unselectable in addition to unselectable dates from selectableDates predicate result.
   */
  @Input()
  areHolidaysSelectable = true;
  /**
   * Fires when the user clicks a date element in the calendar.
   */
  @Output()
  daySelected = new EventEmitter<void>();
  /**
   * Emits on date selecton. Can be used for two-way data binding.
   */
  @Output()
  selectedChange = new EventEmitter<Date | DateRange<Date>>();

  /**
   * @internal
   * a predicate which makes the dates which do
   * not satisfy it red plus makes them unselectable
   */
  private nonHolidayDates: ValidDateFn = d => !this.freeDaysProvider.isFreeDay(d);

  private isSelectableDate(date: Date, view: MatCalendarView = 'month') {
    if (view === 'month') {
      if (this.areHolidaysSelectable) {
        return this.selectableDates(date);
      }
      else {
        return this.selectableDates(date) && this.nonHolidayDates(date);
      }
    }
    else {
      return true;
    }
  }

  protected dateClass = (d: Date, view: MatCalendarView): MatCalendarCellCssClasses => {
    const classes: string[] = [];

    if (!this.isSelectableDate(d, view)) {
      classes.push('calendar-date-disabled');
    }

    if (!this.nonHolidayDates(d) && view === 'month') {
      classes.push('calendar-date-holiday');
    }

    return classes;
  };

  protected onDatePick(newValue: Nullable<Date | DateRange<Date>>): void {
    if (isDateRange(newValue)) {
      this.selectedChange.emit(newValue);
    }
    else {
      if (newValue) {
        const date = new Date(newValue);

        if (this.isSelectableDate(date)) {
          this.selectedChange.emit(date);
        }
      }
    }
  }

}
