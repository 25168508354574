import {ChangeDetectionStrategy, Component, HostBinding, Input, TemplateRef} from '@angular/core';
import {TooltipDirective, TRUNCATED_TEXT_TOOLTIP_DELAY} from '@icz/angular-essentials';
import {IAutocompleteListItemContext} from '../../form-autocomplete.model';
import {NgTemplateOutlet} from '@angular/common';
import {CheckboxComponent} from '../../../checkbox/checkbox.component';
import {
  FormAutocompleteListTextItemComponent
} from '../../form-autocomplete-list-text-item/form-autocomplete-list-text-item.component';
import {IczOption} from '../../../form-elements.model';

/**
 * A component which completely implements rendering of IczOptions in the context of a selector with options.
 */
@Component({
  selector: 'icz-option-item',
  templateUrl: './option-item.component.html',
  styleUrls: ['./option-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    CheckboxComponent,
    TooltipDirective,
    FormAutocompleteListTextItemComponent,
  ]
})
export class OptionItemComponent<T> {

  @HostBinding('class.disabled')
  protected get isOptionDisabled() {
    return this.option?.disabled;
  }
  @HostBinding('class.separator')
  protected get isOptionSeparator() {
    return this.option?.isSeparator;
  }

  /**
   * Input option.
   */
  @Input()
  option!: IczOption<T>;
  /**
   * Whether the option is selected.
   */
  @Input()
  @HostBinding('class.selected')
  isSelected = false;
  /**
   * Whether to render the checkbox.
   */
  @Input()
  isMultiselect: Nullable<boolean> = false;
  /**
   * Custom option rendering template. Will not substitute selected/isMultiselect rendering
   * capabilities; only inner option data rendering will be changed.
   */
  @Input()
  listItemTemplate: Nullable<TemplateRef<IAutocompleteListItemContext>>;
  /**
   * Used for preventing iczTooltip collisions in some scenarios.
   * @internal
   */
  @Input()
  hideDefaultTooltip = false;

  protected get optionTooltip() {
    if (this.hideDefaultTooltip || this.option?.isSeparator) {
      return null;
    }
    else {
      return this.option?.disabled && this.option?.disableReason ?
        this.option?.disableReason :
        this.option?.label;
    }
  }

  protected readonly TRUNCATED_TEXT_TOOLTIP_DELAY = TRUNCATED_TEXT_TOOLTIP_DELAY;

}
