/* tslint:disable */
/* eslint-disable */

/**
 * Origin of the analog component
 */
export enum AnalogComponentOrigin {
  ANALOG_BORN = 'ANALOG_BORN',
  ANALOG_CONVERSION = 'ANALOG_CONVERSION',
  ANALOG_AUTH_CONVERSION = 'ANALOG_AUTH_CONVERSION',
  ANALOG_DIGITIZATION = 'ANALOG_DIGITIZATION',
  CONVERSION_COPY = 'CONVERSION_COPY'
}
