import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
  ApiOwnConsignmentService,
  OwnInternalPaperConsignmentDto,
  OwnInternalPaperConsignmentUpdateDto,
  OwnPaperConsignmentDto,
  OwnPaperConsignmentUpdateDto
} from '|api/sad';
import {SKIP_ERROR_DIALOG} from '../../../core';
import {OwnConsignmentWorkflowAction} from '../consignment-dialog/consignment-dialog/consignment.model';
import {isOwnPaperConsignment} from '../own-consignment-table/model/own-consignment-model';
import {ConsignmentCommand} from '../barcode-scanned-consignments-table/barcode-scanned-consignments-table.component';

type OwnPaperConsignmentChanges = Partial<Pick<
  OwnPaperConsignmentUpdateDto,
  'deliveryServiceCombinationId'|'payoutAmount'|'weight'
>>;

export type TakeoverByBarcodeWorkflowAction = (
  OwnConsignmentWorkflowAction.TAKEOVER |
  OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION |
  OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION
);

@Injectable()
export class TakeoverByBarcodeDialogService {

  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);

  changeConsignmentAttributes(
    consignment: OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto,
    changes: OwnPaperConsignmentChanges,
  ): Observable<OwnPaperConsignmentDto | OwnInternalPaperConsignmentDto> {
    if (isOwnPaperConsignment(consignment)) {
      const updateDto: Required<OwnPaperConsignmentUpdateDto> = {
        codAmount: consignment.codAmount!,
        componentIds: consignment.componentIds,
        consigneeDefinition: (consignment as OwnPaperConsignmentUpdateDto).consigneeDefinition!,
        consigneeId: ((consignment as OwnPaperConsignmentDto).consigneeId)!,
        consigneeAddress: consignment.consigneeAddress,
        consignmentPostingNumber: consignment.consignmentPostingNumber!,
        consignorFileRefNumber: consignment.consignorFileRefNumber!,
        consignorRefNumber: consignment.consignorRefNumber!,
        consigneeAdditionalName: consignment.consigneeAdditionalName!,
        contactPerson: consignment.contactPerson!,
        customText: consignment.customText!,
        declaredValue: consignment.declaredValue!,
        deliveryServiceCombinationId: changes.deliveryServiceCombinationId ?? consignment.deliveryServiceCombinationId!,
        deliveryTypeId: consignment.deliveryTypeId,
        deliveryTypeCanChange: (consignment as OwnPaperConsignmentUpdateDto).deliveryTypeCanChange!,
        dispatchDate: consignment.dispatchDate!,
        dispatchOfficeDistributionNodeId: consignment.dispatchOfficeDistributionNodeId!,
        envelopeTemplateId: consignment.envelopeTemplateId!,
        note: consignment.note!,
        payoutAmount: changes.payoutAmount ?? consignment.payoutAmount!,
        salutation: consignment.salutation!,
        weight: changes.weight ?? consignment.weight!,
      };

      return this.apiOwnConsignmentService.ownConsignmentUpdateOwnPaperConsignment({
        id: consignment.id,
        body: updateDto,
      });
    } else {
      const updateDto: OwnInternalPaperConsignmentUpdateDto = {
        consigneeOrganizationalUnitId: consignment.consigneeOrganizationalUnitId!,
        componentIds: consignment.componentIds,
        consignorFileRefNumber: consignment.consignorFileRefNumber!,
        consignorRefNumber: consignment.consignorRefNumber!,
        consigneeAdditionalName: consignment.consigneeAdditionalName!,
        contactPerson: consignment.contactPerson!,
        customText: consignment.customText!,
        deliveryServiceCombinationId: changes.deliveryServiceCombinationId ?? consignment.deliveryServiceCombinationId!,
        deliveryTypeId: consignment.deliveryTypeId,
        deliveryTypeCanChange: (consignment as OwnInternalPaperConsignmentUpdateDto).deliveryTypeCanChange!,
        dispatchOfficeDistributionNodeId: consignment.dispatchOfficeDistributionNodeId!,
        envelopeTemplateId: consignment.envelopeTemplateId!,
        note: consignment.note!,
        salutation: consignment.salutation!,
        documentId: consignment.documentId,
      };

      return this.apiOwnConsignmentService.ownConsignmentUpdateOwnInternalPaperConsignment({
        id: consignment.id,
        body: updateDto,
      });
    }
  }

  requestAction(
    action: TakeoverByBarcodeWorkflowAction,
    consignmentsToProcess: ConsignmentCommand[]
  ) {
    let request$!: Observable<void>;

    if (action === OwnConsignmentWorkflowAction.TAKEOVER) {
      request$ =  this.apiOwnConsignmentService.ownConsignmentBulkTakeover(
        {
          body: consignmentsToProcess.map(consignmentToProcess => ({
            id: consignmentToProcess.consignment.id,
            consignmentType: consignmentToProcess.consignment.consignmentType,
          })),
        },
        SKIP_ERROR_DIALOG
      );
    }
    else if (action === OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION) {
      request$ = this.apiOwnConsignmentService.ownConsignmentBulkTakeoverAndPrepareForDistributionPaper(
        {
          body: consignmentsToProcess.map(consignmentToProcess => ({
            id: consignmentToProcess.consignment.id,
            consignmentType: consignmentToProcess.consignment.consignmentType,
            weight: consignmentToProcess.code2DMetadata?.weight,
            payoutAmount: consignmentToProcess.code2DMetadata ? {
              number: consignmentToProcess.code2DMetadata.payoutAmount,
              currency: 'CZK',
            } : undefined,
          })),
        },
        SKIP_ERROR_DIALOG
      );
    }
    else if (action === OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION) {
      request$ = this.apiOwnConsignmentService.ownConsignmentBulkPrepareForDistributionPaper(
        {
          body: consignmentsToProcess.map(consignmentToProcess => ({
            id: consignmentToProcess.consignment.id,
            consignmentType: consignmentToProcess.consignment.consignmentType,
            weight: consignmentToProcess.code2DMetadata?.weight,
            payoutAmount: consignmentToProcess.code2DMetadata ? {
              number: consignmentToProcess.code2DMetadata.payoutAmount,
              currency: 'CZK',
            } : undefined,
          })),
        },
        SKIP_ERROR_DIALOG
      );
    }

    return request$;
  }
}
