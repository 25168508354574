import {Component, inject, Input, OnInit} from '@angular/core';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {getOptionsPathToRoot, IczOption, TreeItemSelectionStrategy} from '@icz/angular-form-elements';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {CodebookService} from '../../../../core/services/codebook.service';
import {
  classificationSchemesToTreeMapping,
  EntityClassFilterOption,
  entityClassToTreeMapping
} from '../../../../core/services/data-mapping.utils';

@Component({
  selector: 'icz-entity-class-tree-detail',
  templateUrl: './entity-class-tree-detail.component.html',
  styleUrls: ['./entity-class-tree-detail.component.scss']
})
export class EntityClassTreeDetailComponent implements OnInit, IczOnChanges {
  private codebookService = inject(CodebookService);

  @Input()
  entityClassId: Nullable<number>;

  _searchTerm: string = '';
  selectedOptions: IczOption[] = [{value: 1126014, label: ''}];
  entityClassOptions: EntityClassFilterOption[] = [];
  selectedEntityClassPath: IczOption[] = [];
  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.entityClassId && changes.entityClassId.currentValue) {
      this.selectPathToRoot();
    }
  }
  ngOnInit(): void {
    combineLatest([
      this.codebookService.classificationSchemes(),
      this.codebookService.entityClasses(),
    ]).pipe(
      map(([classificationSchemes, entityClasses]) => {
        const classificationSchemeOptions: EntityClassFilterOption[] = classificationSchemesToTreeMapping(classificationSchemes);

        const entityClassOptions = entityClassToTreeMapping(entityClasses, classificationSchemes);

        return [...classificationSchemeOptions, ...entityClassOptions];
      }),
    ).subscribe(options => {
      this.entityClassOptions = options;
      this.selectPathToRoot();
    });
  }

  selectPathToRoot() {
    if (this.entityClassOptions.length > 0 && this.entityClassId) {
      this.selectedEntityClassPath = getOptionsPathToRoot(this.entityClassOptions, this.entityClassId);
      this.selectedEntityClassPath.forEach(op => op.autoExpand = true);
    }
  }

  protected readonly TreeItemSelectionStrategy = TreeItemSelectionStrategy;
}
