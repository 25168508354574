import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';

/**
 * A value bound to our design system's default text size.
 * @internal
 */
const LINE_HEIGHT = 20; // px

/**
 * A simple text-only paragraph component which can have a limit of lines displayed.
 * If the text exceeds text line limit, the component will show a chevron with
 * "More" text. Clicking the chevron will show the rest of paragraph contents.
 * While in expanded state, the paragraph can be shrunk back using another chevron button.
 */
@Component({
  selector: 'icz-expandable-paragraph',
  templateUrl: './expandable-paragraph.component.html',
  styleUrls: ['./expandable-paragraph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule],
})
export class ExpandableParagraphComponent {

  /**
   * Paragraph text contents.
   */
  @Input({required: true})
  contents = '';
  /**
   * Text line limit for shrunk paragraph state.
   */
  @Input()
  lineCountLimit = 2;

  protected isExpanded = false;

  @HostBinding('style.max-height.px')
  protected get heightLimit() {
    return this.isExpanded ? null : this.lineCountLimit * LINE_HEIGHT;
  }

  protected expanderClicked() {
    this.isExpanded = !this.isExpanded;
  }

  protected isContentExpandable(contentElement: Element) {
    return contentElement.clientHeight > (this.heightLimit ?? -Infinity);
  }

}
