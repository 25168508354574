import {Component, HostBinding, inject, Input, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {IczOnChanges, IczSimpleChanges} from '../icz-on-changes';
import {MatIcon} from '@angular/material/icon';
import {AsyncPipe} from '@angular/common';
import {ICON_REGISTRY_KEY_PROVIDER} from '../essentials.providers';

/**
 * Icon size.
 * "small" and "default" are the most commonly used sizes in our design systems.
 */
export type IconSize = 'tiny' | 'small' | 'compact' | 'default' | 'large';

/**
 * Icon component, utilizing Material Icon system SVG icons with Icon Registry.
 * see https://v7.material.angular.io/components/icon
 */
@Component({
  selector: 'icz-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatIcon, AsyncPipe],
})
export class IconComponent implements IczOnChanges {

  private iconRegistryKeyProvider = inject(ICON_REGISTRY_KEY_PROVIDER);

  /**
   * Icon key into MaterialIconRegistry. Before using this component, do not forget to
   * initialize the icon registry somewhere in a global initializer.
   */
  @Input()
  svgIcon: Nullable<string>;
  /**
   * Icon size.
   * @see IconSize
   */
  @Input()
  size: IconSize = 'default';

  @HostBinding('class.tiny')
  protected get isTiny() { return this.size === 'tiny'; }
  @HostBinding('class.small')
  protected get isSmall() { return this.size === 'small'; }
  @HostBinding('class.large')
  protected get isLarge() { return this.size === 'large'; }
  @HostBinding('class.compact')
  protected get isCompact() { return this.size === 'compact'; }

  /**
   * If TRUE, forces the icon to have its original color as defined in its SVG source.
   * Otherwise will apply a color from our design system to button icon paths.
   */
  @Input()
  set withCustomIconColor(value: Nullable<boolean | ''>) { this._withCustomIconColor = value === '' ? true : Boolean(value); }
  get withCustomIconColor() { return this._withCustomIconColor; }
  private _withCustomIconColor = false;

  private _svgIcon$ = new BehaviorSubject<Nullable<string>>(null);

  protected svgIconByScheme$ = this.iconRegistryKeyProvider.getIconRegistryKey$(this._svgIcon$);

  /**
   * @internal
   */
  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.svgIcon) {
      this._svgIcon$.next(this.svgIcon);
    }
  }

}
