import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {MAT_RADIO_DEFAULT_OPTIONS, MAT_RADIO_GROUP, MatRadioButton, MatRadioChange} from '@angular/material/radio';
import {IczRadioGroupDirective} from './radio-group.directive';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateModule} from '@ngx-translate/core';
import {USER_INTERACTION_LOGGER} from '@icz/angular-essentials';

/**
 * A radio button.
 * Put all related radio button into a single icz-radio-group element to enable value access
 * in reactive forms and correct exclusivity validations (see RadioGroupDirective).
 */
@Component({
  selector: 'icz-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {color: 'primary'},
    },
    {
      provide: MAT_RADIO_GROUP,
      useExisting: IczRadioGroupDirective,
    }
  ],
  standalone: true,
  imports: [
    TranslateModule,
    MatRadioButton,
  ]
})
export class RadioButtonComponent implements OnInit {

  private debugLogger = inject(USER_INTERACTION_LOGGER);
  private destroyRef = inject(DestroyRef);

  /**
   * Radio label.
   */
  @Input()
  label!: string;
  /**
   * Secondary radio label, placed directly under the normal label, written in muted color, used for descriptive/help texts.
   */
  @Input()
  secondaryLabel: Nullable<string>;
  /**
   * Radio value for reactive forms.
   */
  @Input()
  value: any;
  /**
   * Makes the radio visually checked - handy when the radio is not used in reactive forms.
   */
  @Input()
  checked = false;
  /**
   * Visually disables the radio.
   */
  @HostBinding('class.disabled')
  @Input()
  set fieldDisabled(newValue: Nullable<boolean>) {
    this._fieldDisabledFromTemplate = newValue;
  }
  get fieldDisabled(): Nullable<boolean> {
    return this._fieldDisabledFromTemplate ?? this._fieldDisabledFromReactiveModel;
  }

  /**
   * Fires when the radio is un-focused by the user by any possible means.
   */
  @Output()
  blur = new EventEmitter<FocusEvent>();
  /**
   * Fires when radio is clicked such that the value of the whole radio group changes.
   */
  @Output()
  change = new EventEmitter<MatRadioChange>();

  /**
   * @internal
   */
  _fieldDisabledFromReactiveModel: Nullable<boolean>;
  protected _fieldDisabledFromTemplate: Nullable<boolean>;

  /**
   * @internal
   */
  ngOnInit() {
    this.blur.pipe(takeUntilDestroyed(this.destroyRef)).subscribe( _ =>
      this.debugLogger.logUserInteraction({description: `Input '${this.label}', hodnota: ${String(this.value)}`})
    );
  }

  protected radioValueChanged(radioChangeEvent: MatRadioChange) {
    this.checked = radioChangeEvent.value === this.value;
    this.change.emit(radioChangeEvent);
  }

}
