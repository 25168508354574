@if (dataSource) {
  <icz-documents-table tableId="ownership-transfer-object-selection"
                       class="grow"
                       [dataSource]="dataSource"
                       [viewType]="DocumentView.STORAGE_UNIT_FILLER_SELECTION_FILTERS"
                       [openQuickPreview]="false"
                       [hideTableToolbar]="false"
                       [hideTableToolbarButtons]="true"
                       (searchParamsSelected)="searchParamsSelected($event)"
                       (activeFiltersChange)="activeFiltersChanged($event)"
  >
    <icz-label noDataLabel label="Žádné objekty"></icz-label>
    <ng-container tableTitle>
      <icz-label [label]="modalData.isUnitView ? 'Útvar - Ukončené' : 'Ukončené'"></icz-label>
      @if (modalData.isUnitView) {
        <icz-form-autocomplete
          class="ml-16 table-view-selector"
          [options]="selectorOptions$ | async"
          [value]="activeSelectorValue"
          (valueChange)="selectorValueChanged($event)"
          [showValidationStatus]="false"
          [clearable]="false"
        ></icz-form-autocomplete>
      }
    </ng-container>
  </icz-documents-table>
}

<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this)"
              (onAction)="submit()"
              primary label="Uložit kritéria výběru objektů"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
