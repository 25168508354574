import {CommonModule} from '@angular/common';
import {inject, NgModule} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {NG_EVENT_PLUGINS} from '@tinkoff/ng-event-plugins';
import {ModalsModule} from '@icz/angular-modal';
import {FormElementsModule} from '@icz/angular-form-elements';
import {EssentialsModule, LoadingIndicatorService, TemplatePoolService} from '@icz/angular-essentials';
import {GeneralScreenAreaModule} from './components/general-screen-area/general-screen-area.module';
import {HighlightsModule} from './components/highlights/highlights.module';
import {LoginScreensModule} from './components/login-screens/login-screens.module';
import {NotificationsModule} from './components/notifications/notifications.module';
import {SharedScreensModule} from './components/shared-screens/shared-screens.module';
import {TableModule} from '@icz/angular-table';
import {initIconRegistry} from './core/icon-registry/icon.registry';
import {
  SharedBusinessComponentsModule
} from './components/shared-business-components/shared-business-components.module';
import {ButtonCollectionModule} from './components/button-collection/button-collection.module';
import {LibraryImportModule} from './components/.module-library-import/library-import.module';
import {EmpowermentModule} from './components/empowerment/empowerment.module';
import {IczFormDirectivesModule} from './components/icz-form-directives/icz-form-directives.module';
import {AddressPipeModule} from './components/address-pipe/address-pipe.module';
import {
  SharedTemplateCollectionComponent
} from './components/shared-business-components/shared-template-collection/shared-template-collection.component';
import {WizardModule} from './components/wizard/wizard.module';
import {DialogsModule} from './components/dialogs/dialogs.module';
import {initDatepickerLocale} from './core/localization/calendar-locale';

// isNil is inserted here into global scope because otherwise
//  unit tests of libs can't resolve it in runtime.
window.isNil = (value: any): value is Nil => {
  return value === null || value === undefined;
};

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    EssentialsModule,
    FormElementsModule,
    ModalsModule,
    DialogsModule,
    TableModule,
    SharedScreensModule,
    NotificationsModule,
    LoginScreensModule,
    HighlightsModule,
    GeneralScreenAreaModule,
    SharedBusinessComponentsModule,
    ButtonCollectionModule,
    LibraryImportModule,
    EmpowermentModule,
    IczFormDirectivesModule,
    AddressPipeModule,
    WizardModule,
  ],
  providers: [
    LoadingIndicatorService,
    NG_EVENT_PLUGINS,
    TemplatePoolService
  ],
})
export class SharedModule {

  constructor() {
    initIconRegistry(inject(MatIconRegistry), inject(DomSanitizer));
    initDatepickerLocale(inject(TranslateService), inject(DateAdapter));
    inject(TemplatePoolService).initialize(SharedTemplateCollectionComponent);
  }

}
