import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import {OwnConsignmentStatus} from '|api/commons';
import {OwnOfficeDeskConsignmentDto} from '|api/sad';
import {OrganizationalStructureService} from '../../../../../core/services/organizational-structure.service';
import {
  GenericOwnConsignment,
  getOutboundConsignmentIdentifier,
  isOfficeDeskConsignment
} from '../../../own-consignment-table/model/own-consignment-model';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';


@Component({
  selector: 'icz-consignment-meta-overview',
  templateUrl: './consignment-meta-overview.component.html',
  styleUrls: ['./consignment-meta-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentMetaOverviewComponent {

  private organizationalStructureService = inject(OrganizationalStructureService);

  @Input({required: true}) ownConsignment!: Nullable<GenericOwnConsignment>;

  requiresDeliveryConfirmation = false;

  functionalPositionOptions$ = this.organizationalStructureService.functionalPositionsOptions();
  statusOptions = enumToOptions('ownConsignmentStatus', OwnConsignmentStatus);

  get ownConsignmentAsOfficeDesk() {
    return this.ownConsignment as OwnOfficeDeskConsignmentDto;
  }

  get isOfficeDeskConsignment() {
    return isOfficeDeskConsignment(this.ownConsignment);
  }

  get outboundConsignmentIdentifier(): Nullable<string> {
    return getOutboundConsignmentIdentifier(this.ownConsignment!);
  }

}
