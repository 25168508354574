import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY
} from '@angular/material/datepicker';
import {CalendarComponent} from '../calendar/calendar.component';

/**
 * A calendar which allows the user to pick a date interval.
 */
@Component({
  selector: 'icz-calendar-range',
  templateUrl: './calendar-range.component.html',
  styleUrls: ['./calendar-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    // additional important configuration to allow the
    // internal calendar to select interval and not just mere dates
    {
      provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
      useClass: DefaultMatCalendarRangeStrategy,
    },
  ],
  standalone: true,
  imports: [
    CalendarComponent,
  ]
})
export class CalendarRangeComponent {

  /**
   * Date interval.
   */
  @Input()
  selectedDateRange: Nullable<DateRange<Date>>;
  /**
   * Date interval change notifications for two-way data binding.
   */
  @Output()
  selectedDateRangeChange = new EventEmitter<DateRange<Date>>();

  protected _onSelectedChange(date: Date): void {
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
    }
    else {
      this.selectedDateRange = new DateRange(date, null);
    }

    this.selectedDateRangeChange.emit(this.selectedDateRange);
  }

}
