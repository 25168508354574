import {catchError, map, switchMap} from 'rxjs/operators';
import {combineLatest, Observable, of, tap} from 'rxjs';
import {ApiDocumentFileSubjectsService} from '|api/document';
import {
  RelatedObjectType,
  SubjectObjectRelationCreateDto,
  SubjectObjectRelationDeleteDto,
  SubjectObjectRelationFindDto,
  SubjectObjectRelationType,
  SubjectRecordCreateOrUpdateDto,
  SubjectRecordDto
} from '|api/commons';
import {constructSubjectName} from '../model/subjects.model';
import {SubjectToastService, SubjectToastType} from '../../../core/services/notifications/subject-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {inject, Injectable} from '@angular/core';
import {ApiRelatedObjectService, ApiSubjectRecordElasticService} from '|api/subject-register';
import {AbstractFileSubjectRelationDialogsManagerService} from '../../../services/abstract-file-subject-relation-dialogs-manager.service';
import {flatten} from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class SubjectPropagateToFileService {

  private apiSubjectRecordElasticService = inject(ApiSubjectRecordElasticService);
  private apiRelatedObjectService = inject(ApiRelatedObjectService);
  private subjectToastService = inject(SubjectToastService);
  private apiDocumentFileSubjectsService = inject(ApiDocumentFileSubjectsService);
  private abstractFileSubjectRelationDialogsManagerService = inject(AbstractFileSubjectRelationDialogsManagerService);

  private checkPresenceOfSubjectsInFile(subjectIds: number[], documentId: number) {
    return {
      relatedObject: {
        relatedObjectId: documentId,
        relatedObjectType: RelatedObjectType.DOCUMENT,
      },
      subjects: subjectIds
    };
  }

  checkAndAddRelationToFile(questionDialogType: 'addRelationToFile' | 'addRelationToFileConsignment' = 'addRelationToFile',
                            subject: Nullable<SubjectRecordDto | SubjectRecordCreateOrUpdateDto>,
                            documentId: number,
                            fileId: Nullable<number>):
    Observable<Nullable<SubjectRecordDto | SubjectRecordCreateOrUpdateDto>> {
    if (!subject) return of(null);
    if (!fileId) return of(subject);

    return this.apiDocumentFileSubjectsService.documentFileSubjectsCheckPresence({body: this.checkPresenceOfSubjectsInFile([subject.id!], documentId)})
      .pipe(switchMap(fileCheckResult => {
        const parentFileHasThisSubject = fileCheckResult.file.subjects.includes(subject.id!);

        if (parentFileHasThisSubject) return of(subject);
        else {
          const questionDialogType$ = questionDialogType === 'addRelationToFile' ? this.abstractFileSubjectRelationDialogsManagerService.openAddRelationToFileDialog() :
            this.abstractFileSubjectRelationDialogsManagerService.openAddRelationToFileConsignmentDialog();

          return questionDialogType$.pipe(switchMap(userConfirmed => {
            if (!userConfirmed) return of(subject);
            else {
              const newFileRelation: SubjectObjectRelationCreateDto = {
                relatedObjectId: fileId!,
                relatedObjectType: RelatedObjectType.FILE,
                relationType: SubjectObjectRelationType.RELATED,
                representing: false,
              };
              return this.apiRelatedObjectService.relatedObjectLinkObjectsToSubject({
                subjectId: subject.id!,
                body: [newFileRelation],
              }).pipe(tap(_ => {
                  const name = constructSubjectName(subject);
                  this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_LINK_FILE_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
                }),
                catchError(_ => {
                  //error of secondary requests when propagating relations for file are not critical, so even on error return original success observable
                  return of(subject);
                }));
            }
          }));
        }
      }));
  }

  checkAndRemoveRelationFromFile(subject: SubjectRecordDto,
                                 documentId: number,
                                 fileId: Nullable<number>):
    Observable<void> {
    if (!(fileId)) return of(undefined);

    else {
      return this.apiDocumentFileSubjectsService.documentFileSubjectsCheckPresence({body: this.checkPresenceOfSubjectsInFile([subject.id!], documentId)})
        .pipe(switchMap(fileCheckResult => {
          const parentFileHasThisSubject = fileCheckResult.file.subjects.includes(subject.id!);
          const documentsInFileHasThisSubject = flatten(fileCheckResult.documents.map(d => d.subjects)).includes((subject.id!));

          if (parentFileHasThisSubject && !documentsInFileHasThisSubject) {
            return this.abstractFileSubjectRelationDialogsManagerService.openRemoveRelationFromFileDialog().pipe(switchMap(userConfirmed => {
              if (!userConfirmed) return of(undefined);
              else {
                const relationDelete: SubjectObjectRelationDeleteDto[] = [{
                  relatedObjectId: fileId!,
                  relatedObjectType: RelatedObjectType.FILE
                }];

                return this.apiRelatedObjectService.relatedObjectUnlinkObjectsFromSubject({subjectId: subject.id!, body: relationDelete}
                ).pipe(tap(_ => {
                    const name = constructSubjectName(subject);
                    this.subjectToastService.dispatchSubjectInfoToast(SubjectToastType.SUBJECT_UNLINK_FILE_SUCCESS, {[InternalNotificationKey.SUBJECT_NAME]: name});
                  }),
                  catchError(_ => {
                    //error of secondary requests when propagating relations for file are not critical, so even on error return original success observable
                    return of(undefined);
                  }));
              }
            }));
          }
          else {
            return of(undefined);
          }
        }));
    }
  }

  checkAndAddAllSubjectsOfDocumentToFile(documentIds: number[], fileId: number): Observable<void> {
    if (!documentIds.length) return of(undefined);

    const getSubjectsOfDocuments: SubjectObjectRelationFindDto = {
      relatedObjectIds: documentIds,
      relatedObjectType: RelatedObjectType.DOCUMENT,
    };

    const getSubjectsOfFileToInsertInto: SubjectObjectRelationFindDto = {
      relatedObjectIds: [fileId],
      relatedObjectType: RelatedObjectType.FILE,
    };

    return combineLatest([
      this.apiSubjectRecordElasticService.subjectRecordElasticElasticFindSubjectsByRelations({body: getSubjectsOfDocuments}),
      this.apiSubjectRecordElasticService.subjectRecordElasticElasticFindSubjectsByRelations({body: getSubjectsOfFileToInsertInto})
    ]).pipe(switchMap(([relationsOfDocument, relationsOfFile]) => {
      const subjectsOfDocumentIds = relationsOfDocument.map(r => r.id);
      const subjectsOfFileIds = relationsOfFile.map(r => r.id);

      const subjectsOnlyOnDocumentIds = subjectsOfDocumentIds.filter(s => !subjectsOfFileIds.includes(s!));

      if (subjectsOnlyOnDocumentIds.length) {
        return this.abstractFileSubjectRelationDialogsManagerService.openAddAllRelatedSubjectsToFileDialog(subjectsOnlyOnDocumentIds.length)
          .pipe(switchMap(userConfirmed => {
          if (userConfirmed) {
            return this.apiRelatedObjectService.relatedObjectLinkSubjectsToObject({
              body: {
                relatedObject: {
                  relatedObjectType: RelatedObjectType.FILE,
                  relatedObjectId: fileId
                },
                subjectIds: subjectsOnlyOnDocumentIds as number[]
              }
            }).pipe(
              tap(_ => {
                this.subjectToastService.dispatchBulkSubjectsInfoToast(
                  SubjectToastType.SUBJECT_BULK_LINK_FILE_SUCCESS,
                  {[InternalNotificationKey.COUNT]: subjectsOnlyOnDocumentIds.length});
              }),
              catchError(_ => {
                //error of secondary requests when propagating relations for file are not critical, so even on error return original success observable
                return of(undefined);
              }),
              map(_ => undefined));
          }
          else {
            return of(undefined);
          }
        }));
      }
      else {
        return of(undefined);
      }
    }));
  }

  checkAndRemoveAllSubjectsOfDocumentFromFile(documentIds: number[], fileId: number): Observable<void> {
    if (!documentIds.length) return of(undefined);

    const getSubjectsOfDocuments: SubjectObjectRelationFindDto = {
      relatedObjectIds: documentIds,
      relatedObjectType: RelatedObjectType.DOCUMENT,
    };

    const getSubjectsOfFileToInsertInto: SubjectObjectRelationFindDto = {
      relatedObjectIds: [fileId],
      relatedObjectType: RelatedObjectType.FILE,
    };

    return combineLatest([
      this.apiSubjectRecordElasticService.subjectRecordElasticElasticFindSubjectsByRelations({body: getSubjectsOfDocuments}),
      this.apiSubjectRecordElasticService.subjectRecordElasticElasticFindSubjectsByRelations({body: getSubjectsOfFileToInsertInto}),
      this.apiDocumentFileSubjectsService.documentFileSubjectsCheckPresence({body: {relatedObject: {relatedObjectId: fileId, relatedObjectType: RelatedObjectType.FILE}, subjects: []}}),
    ]).pipe(switchMap(([relationsOfDocument, relationsOfFile, fileCheckResult]) => {
      const subjectsOfDocumentIds = relationsOfDocument.map(r => r.id);
      const subjectsOfFileIds = relationsOfFile.map(r => r.id);
      const subjectsOfFileContentIds = flatten(fileCheckResult.documents.map(d => d.subjects)).filter(s => !fileCheckResult.file.subjects.includes(s!));

      const subjectsOnDocumentAndFileAndNotOnFileContentIds = subjectsOfDocumentIds.filter(s => subjectsOfFileIds.includes(s!)).filter(s => !subjectsOfFileContentIds.includes(s!));

      if (subjectsOnDocumentAndFileAndNotOnFileContentIds.length) {
        return this.abstractFileSubjectRelationDialogsManagerService.openRemoveRelationsFromFileNotOnFileContentDialog(subjectsOnDocumentAndFileAndNotOnFileContentIds.length)
          .pipe(switchMap(userConfirmed => {
          if (userConfirmed) {
            return this.apiRelatedObjectService.relatedObjectUnlinkSubjectsFromObject({
              body: {
                relatedObject: {
                  relatedObjectType: RelatedObjectType.FILE,
                  relatedObjectId: fileId
                },
                subjectIds: subjectsOnDocumentAndFileAndNotOnFileContentIds as number[],
              }
            }).pipe(
              tap(_ => {
                this.subjectToastService.dispatchBulkSubjectsInfoToast(
                  SubjectToastType.SUBJECT_BULK_UNLINK_FILE_SUCCESS,
                  {[InternalNotificationKey.COUNT]: subjectsOnDocumentAndFileAndNotOnFileContentIds.length});
              }),
              catchError(_ => {
                //error of secondary requests when propagating relations for file are not critical, so even on error return original success observable
                return of(undefined);
              }),
              map(_ => undefined));
          }
          else {
            return of(undefined);
          }
        }));
      }
      else {
        return of(undefined);
      }
    }));
  }

}
