import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {SignMarkTimestampService} from '../../../../services/sign-mark-timestamp.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {getLastDigitalComponentVersionId, getLatestDigitalComponentVersion} from '../../shared-document.utils';
import {
  PreSignatureValidationDialogData,
  PreSignatureValidationResult,
  SignatureConfigurationDialogData,
  SignatureFlowType
} from '../../essl-components/models/signature-dialog.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {injectModalData, injectModalRef} from '@icz/angular-modal';


export const digitalComponentPreSignatureCodes = {
  infoCodes: {
    notVisibleSignable: 'be.error.incompatibleFormatWith.visibleSignature'
  },
  errorCodes: {
    conversionNeeded: 'be.error.conversion.needed',
    signatureFound: 'be.error.signature.found',
  }
};

export enum PreSignatureValidationDialogActionAfterClose {
  OPEN_SIGNING_DIALOG = 'OPEN_SIGNING_DIALOG',
  OPEN_EXISTING_SIGNATURE_DECISION_DIALOG = 'OPEN_EXISTING_SIGNATURE_DECISION_DIALOG',
  OPEN_PRE_SIGNATURE_CONVERSION_DECISION_DIALOG = 'OPEN_PRE_SIGNATURE_CONVERSION_DECISION_DIALOG',
}

export interface PreSignatureValidationDialogResult {
  actionAfterClose: PreSignatureValidationDialogActionAfterClose;
  signatureConfigurationDialogData: Partial<SignatureConfigurationDialogData<unknown>>;
}

/*
  Basic info
  -----------------
  Before signing, digitalComponents need to be validated. Validation is done by the server and response is returned for each digitalComponent, if it can be signed
  DigitalComponent can or cannot be signed depending on file format, if its final digitalComponent and more things
  Validation will aslo tell if digitalComponent can be signed visually (drag and drop of certificate div) or not. This depends on file format

  Allowed file formats
  ----------------------
  1. PDF
  2. PDFA
  3. XML

  States of validation:
  ------------------------
  1. If validation of all digitalComponents is successful, then the user can continue to signing
  2. If validation of any digitalComponent is unsuccessful, then the user will be notified with alert and if atleast 1 digitalComponent is signable, user can continue to signing
  3. If validation of all digitalComponents is unsuccessful, then the user will be notified with alert and cannot continue to signing
*/
@Component({
  selector: 'icz-digital-component-pre-signature-validation-dialog',
  templateUrl: './digital-component-pre-signature-validation-dialog.component.html',
  styleUrls: ['./digital-component-pre-signature-validation-dialog.component.scss'],
})
export class DigitalComponentPreSignatureValidationDialogComponent implements OnInit {

  protected modalRef = injectModalRef<Nullable<PreSignatureValidationDialogResult>>();
  private signMarkTimestampService = inject(SignMarkTimestampService);
  private destroyRef = inject(DestroyRef);
  protected loadingService = inject(LoadingIndicatorService);
  protected data = injectModalData<PreSignatureValidationDialogData>();

  private validationResultsRequest$ = this.loadingService.doLoading(
    this.signMarkTimestampService.checkDigitalComponentsBeforeSign(this.digitalComponents, this.documentId),
    this
  );

  private validationResult$: Observable<PreSignatureValidationResult> = this.validationResultsRequest$.pipe(
    map(validationsResults => {
      return {
        documentCriticalError: (
          validationsResults.error ?
            {
              errorCode: validationsResults.error.code,
              errorParameters: validationsResults.error.parameters,
            } :
            undefined
        ),
        digitalComponentResults: validationsResults.checkBeforeSignResult!.map(result => {
          const digitalComponent = this.digitalComponents.find(digitalComponent => getLastDigitalComponentVersionId(digitalComponent) === result.digitalComponentVersionId)!;
          const fileName = getLatestDigitalComponentVersion(digitalComponent)!.fileName;
          if (result.error) {
            return {
              ...digitalComponent,
              errorCode: result.error.code,
              infoCode: null,
              errorParameters: result.error.parameters,
              fileName,
            };
          }
          else if (result.info) {
            return {
              ...digitalComponent,
              errorCode: null,
              infoCode: result.info.code,
              infoParameters: result.info.parameters,
              fileName,
            };
          }
          else {
            return {
              ...digitalComponent,
              errorCode: null,
              infoCode: null,
              fileName,
            };
          }
        }),
      };
    }),
    shareReplay(1),
  );

  private withErrorResults$ = this.validationResult$.pipe(
    map(validationResult => validationResult.digitalComponentResults.filter(digitalComponent => Boolean(digitalComponent.errorCode)))
  );

  private withInfoResults$ = this.validationResult$.pipe(
    map(validationResult => validationResult.digitalComponentResults.filter(digitalComponent => Boolean(digitalComponent.infoCode)))
  );

  parentDocumentError$ = this.validationResult$.pipe(
    map(validationResult => validationResult.documentCriticalError),
  );

  signableDigitalComponents$ = this.validationResult$.pipe(
    map(validationResult => validationResult.digitalComponentResults.filter(
      digitalComponent => {
        return (
          !digitalComponent.errorCode ||
          digitalComponent.errorCode === digitalComponentPreSignatureCodes.errorCodes.signatureFound ||
          digitalComponent.errorCode === digitalComponentPreSignatureCodes.errorCodes.conversionNeeded
        );
      }
    ))
  );

  notSignableDigitalComponents$ = this.validationResult$.pipe(
    map(validationResult => validationResult.digitalComponentResults.filter(digitalComponent => Boolean(digitalComponent.errorCode)))
  );

  signableWithoutVisualSignatureOption$ = this.signableDigitalComponents$.pipe(
    map(validationResult => validationResult.filter(digitalComponent => digitalComponent.infoCode === digitalComponentPreSignatureCodes.infoCodes.notVisibleSignable))
  );

  signableWithVisualSignatureOption$ = this.signableDigitalComponents$.pipe(
    map(validationResult => validationResult.filter(digitalComponent => !digitalComponent.infoCode))
  );

  numberOfErrors$ = this.withErrorResults$.pipe(map(errorList => errorList.length));

  numberOfWithoutVisualSignatureOption$ = this.signableWithoutVisualSignatureOption$.pipe(map(digitalComponents => digitalComponents.length));

  hasErrors$ = this.numberOfErrors$.pipe(
    map(numberOfErrors => numberOfErrors > 0),
    shareReplay(1)
  );

  hasInfos$ = this.withInfoResults$.pipe(
    map(infoList => infoList.length > 0),
    shareReplay(1)
  );

  hasParentDocumentError$ = this.parentDocumentError$.pipe(
    map(Boolean),
    shareReplay(1)
  );

  isThereDigitalComponentToSign$ = this.numberOfErrors$.pipe(
    map(numberOfErrors => numberOfErrors !== this.digitalComponentsCount)
  );

  canContinueToSign$ = combineLatest([
    this.numberOfErrors$,
    this.hasParentDocumentError$,
  ]).pipe(
    map(([componentErrorCount, hasParentDocumentError]) => !hasParentDocumentError && componentErrorCount < this.digitalComponentsCount)
  );

  alertTranslateContextForError$ = this.numberOfErrors$.pipe(
    map(numberOfErrors => ({numberOfErrors, numberOfValidDigitalComponents: this.digitalComponentsCount - numberOfErrors}))
  );

  alertTranslateContextForWarning$ = this.numberOfWithoutVisualSignatureOption$.pipe(
    map(numberOfWithoutVisualSignatureOption => ({numberOfWithoutVisualSignatureOption}))
  );

  get digitalComponents() {
    return this.data.digitalComponents;
  }

  get digitalComponentsCount() {
    return this.digitalComponents.length;
  }

  get documentId() {
    return this.data.documentId;
  }

  get isBulkOperation() {
    return this.data.isBulkOperation;
  }

  get noDigitalComponentToSignAlertText() {
    return this.isBulkOperation ? 'fe.ui.alert.heading.NO_DIGITAL_COMPONENTS_TO_SIGN_ERROR' : 'fe.ui.alert.heading.NO_DIGITAL_COMPONENT_TO_SIGN_ERROR';
  }

  closeDialog(data?: PreSignatureValidationDialogResult) {
    this.modalRef.close(data);
  }

  continueToSigning() {
    combineLatest([this.signableWithVisualSignatureOption$, this.signableWithoutVisualSignatureOption$]).subscribe(
      ([signableWithVisualSignatureOption, signableWithoutVisualSignatureOption]) => {
        const {documentId, isBulkOperation} = this.data;
        this.closeDialog({
          actionAfterClose: PreSignatureValidationDialogActionAfterClose.OPEN_SIGNING_DIALOG,
          signatureConfigurationDialogData: {
            documentId,
            isBulkOperation,
            signableWithVisualSignatureOption,
            signableWithoutVisualSignatureOption,
          }
        });
      }
    );
  }

  ngOnInit() {
    this.checkIfShouldAutoCloseDialog();
  }

  private checkIfShouldAutoCloseDialog() {
    combineLatest([
      this.hasErrors$,
      this.hasInfos$,
      this.hasParentDocumentError$,
      this.validationResult$,
      this.signableWithVisualSignatureOption$,
      this.signableWithoutVisualSignatureOption$,
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(([
      hasErrors,
      hasInfos,
      hasParentDocumentError,
      validationResults,
      signableWithVisualSignatureOption,
      signableWithoutVisualSignatureOption,
    ]) => {
      if (!hasErrors && !hasInfos && !hasParentDocumentError) {
        this.continueToSigning();
      }
      else {
        const digitalComponentResults = validationResults.digitalComponentResults ?? [];

        if (this.data.signatureFlowType === SignatureFlowType.DOCUMENT_COMPONENTS_SIGNING && digitalComponentResults.length === 1) {
          const conversionNeeded = digitalComponentResults.find(
            result => result.errorCode === digitalComponentPreSignatureCodes.errorCodes.conversionNeeded
          );
          const signatureFound = digitalComponentResults.find(
            result => result.errorCode === digitalComponentPreSignatureCodes.errorCodes.signatureFound
          );

          const {documentId, isBulkOperation} = this.data;

          if (conversionNeeded) {
            this.closeDialog({
              actionAfterClose: PreSignatureValidationDialogActionAfterClose.OPEN_PRE_SIGNATURE_CONVERSION_DECISION_DIALOG,
              signatureConfigurationDialogData: {
                documentId,
                isBulkOperation,
                signableWithVisualSignatureOption,
                signableWithoutVisualSignatureOption,
              }
            });
          }
          else if (signatureFound) {
            this.closeDialog({
              actionAfterClose: PreSignatureValidationDialogActionAfterClose.OPEN_EXISTING_SIGNATURE_DECISION_DIALOG,
              signatureConfigurationDialogData: {
                documentId,
                isBulkOperation,
                signableWithVisualSignatureOption,
                signableWithoutVisualSignatureOption,
              }
            });
          }
        }
      }
    });
  }

}
