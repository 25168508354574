import {DestroyRef, Directive, inject, Input, OnInit} from '@angular/core';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {startWith} from 'rxjs/operators';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {SubjectAttributeSource} from '|api/commons';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {Subscription} from 'rxjs';
import {SubjectAttributeType} from '../../model/subject-attribute-type';

@Directive()
export abstract class AbstractSubjectVerifiableAttributeComponent implements OnInit, IczOnChanges {

  protected destroyRef = inject(DestroyRef);

  @Input({required: true}) form!: IczFormGroup;
  @Input() groupName: Nullable<string>;
  @Input() subjectAttributeType: Nullable<SubjectAttributeType>;

  get singleAttributeForm(): IczFormGroup {
    if (this.groupName === '.') return this.form;
    else if (this.subjectAttributeType) return this.form.get(this.subjectAttributeType)! as IczFormGroup;
    else if (this.groupName) return this.form.get(this.groupName)! as IczFormGroup;
    else throw new Error('No group name provided for VerifiableAttributeComponent!');
  }

  get valueForm(): IczFormGroup {
    return this.singleAttributeForm.get('value') as IczFormGroup;
  }

  get sourceForm(): IczFormControl {
    return this.singleAttributeForm.get('source') as IczFormControl;
  }

  get isIszrVerified(): boolean {
    return this.sourceForm?.value === SubjectAttributeSource.ROS ||
      this.sourceForm?.value === SubjectAttributeSource.ROB;
  }

  get isIsdsVerified(): boolean {
    return this.sourceForm?.value === SubjectAttributeSource.ISDS;
  }

  get isVerified(): boolean {
    return this.isIsdsVerified || this.isIszrVerified;
  }

  get verifiedTooltip(): string {
    return this.sourceForm?.value === SubjectAttributeSource.ISDS ? 'Ověřeno v ISDS' :
      this.sourceForm?.value === SubjectAttributeSource.ROS ? 'Ověřeno v ROS' :
      this.sourceForm?.value === SubjectAttributeSource.ROB ? 'Ověřeno v ROB' :
        '';
  }

  ngOnInit() {
    const source = this.singleAttributeForm.get('source')!.value;
    if (source !== SubjectAttributeSource.INTERNAL) {
      this.valueForm.disable();
    }
  }

  private sourceSubscription: Nullable<Subscription>;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.form?.currentValue) {
      this.sourceSubscription?.unsubscribe();

      this.sourceSubscription = this.singleAttributeForm?.get('source')!.valueChanges.pipe(
        startWith(this.singleAttributeForm.get('source')!.value),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(source => {
        if (source !== SubjectAttributeSource.INTERNAL) {
          this.valueForm.disable();
        }
      });
    }
  }
}
