import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {IczValidators} from '@icz/angular-form-elements';
import {CheckUnsavedFormDialogService} from '../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IdtService} from '../../../core/services/idt/idt.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IdtConnectionState, IdtLinkService} from '../../../core/services/idt/idt-link.service';
import {switchMap, take, tap} from 'rxjs/operators';
import {of} from 'rxjs';
import {PrintingOperationType, UserSettingsService} from '../../../services/user-settings.service';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {IczOption} from '@icz/angular-form-elements';

@Component({
  selector: 'icz-printer-selection-dialog',
  templateUrl: './printer-selection-dialog.component.html',
  styleUrls: ['./printer-selection-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class PrinterSelectionDialogComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<string>>();
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private userSettingsService = inject(UserSettingsService);
  private idtLinkService = inject(IdtLinkService);
  private idtService = inject(IdtService);
  private destroyRef = inject(DestroyRef);
  private printingOperationType = injectModalData<PrintingOperationType>();

  form = new IczFormGroup({
    printerName: new IczFormControl<Nullable<string>>(
      this.userSettingsService.getPreferredPrinter(this.printingOperationType),
      [IczValidators.required()]
    ),
  });

  idtStatus = IdtConnectionState.INITIATING;

  printerOptions: IczOption[] = [];

  readonly IdtConnectionState = IdtConnectionState;

  ngOnInit() {
    this.loadPrinters(1);
  }

  loadPrinters(count: number){
    this.loadingService.doLoading(
      this.idtLinkService.idtConnectionStatus$.pipe(
        take(count),
        tap(status => this.idtStatus = status),
        switchMap(status => {
          if (status === IdtConnectionState.RUNNING) {
            return this.idtService.getPrinters();
          }
          else {
            return of({printers: []});
          }
        }),
      ),
      this
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(idtPrinters => {
      this.printerOptions = idtPrinters.printers.map(p => ({
        value: p.name,
        label: p.name,
      }));
    });
  }

  submit() {
    const printerName = this.form.value.printerName!;

    this.userSettingsService.setPreferredPrinter(this.printingOperationType, printerName);
    this.modalRef.close(printerName);
  }

  cancel() {
    this.modalRef.close(null);
  }

  reconnect() {
    this.idtLinkService.initialize();
    this.loadPrinters(2);
  }

}
