import {FileDto} from '|api/document';
import {Observable} from 'rxjs';

/**
 * Necessary abstract class for avoiding cyclic dependency between shared and other modules,
 * dependency direction being reversed by providing services implementing this shared abstract
 */
export abstract class AbstractFileDialogsManagerService {
  abstract openDeactivationDialog(fileData: FileDto): Observable<Nullable<boolean>>;
  abstract openFileSettleDialog(fileData: FileDto[]): Observable<Nullable<boolean>>;
  abstract openFileCloseDialog(fileData: FileDto[]): Observable<Nullable<boolean>>;
}
