import {ChangeDetectionStrategy, Component, ElementRef, inject, Input, OnInit} from '@angular/core';

/**
 * A simple resizable loading spinner.
 */
@Component({
  selector: 'icz-spinner',
  templateUrl: './spinner.component.html',
  // Styles of this component are defined in global.scss
  //  because Angular since v17 loads component styles only when included in a
  //  template and that would not work in conjunction with InaccessibleDirective.
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class SpinnerComponent implements OnInit {

  /**
   * @internal
   * elementRef is used by InaccessibleDirective
   */
  elementRef = inject(ElementRef);

  /**
   * Spinner size corresponding to spinner wheel diameter in px.
   */
  @Input()
  diameter: number = 100;
  /**
   * If TRUE, the spinner will become white instead of default dark blue color.
   */
  @Input()
  invertSpinnerColor = false;

  protected border!: number;

  /**
   * @internal
   */
  ngOnInit() {
    this.border = this.diameter / 7;
  }

}
