import {ButtonDisablerFn, Guard} from '../../../../button-collection/button-collection.component';
import {ConsignmentValidationResult} from '|api/sad';

export class ConsignmentDialogButtonsDisablers {

  @Guard()
  static isConsignmentInvalid(validationResult: Nullable<ConsignmentValidationResult>): ButtonDisablerFn {
    return () => {
      return (validationResult && !validationResult.valid) ? {tooltip: 'Zásílka není validní'} : null;
    };
  }
}
