import {Component, inject, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {SubstitutionsCounterMode} from '../dashboard-substitutions-counter/dashboard-substitutions-counter.component';
import {IconCounterBadgeColor} from '../dashboard-icon-counter/dashboard-icon-counter.component';
import {Router} from '@angular/router';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ActionRoute, ApplicationRoute} from '../../../enums/shared-routes.enum';
import {DocumentsRoute} from '../../../enums/documents-routes.enum';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {findMenuItemByViewId, MainMenuCountsService} from '../../../core/services/main-menu-counts.service';
import {DocumentSearchService} from '../../../services/document-search.service';
import {FilterOperator, getDefaultSearchParams} from '@icz/angular-table';
import {MenuItemCountViewIds} from '../../../core/routing/menu-item-count-view-ids';
import {AbstractDashboardConnectedToSettingsComponent} from '../abstract-dashboard-connected-to-settings.component';
import {
  CreateEmailConsignmentDialogComponent
} from '../../shared-business-components/create-email-consignment-dialog/create-email-consignment-dialog.component';
import {IczModalService} from '@icz/angular-modal';
import {formatAsLocalIsoDate, getStartOfTheDay, getTodayMidnight} from '@icz/angular-form-elements';
import {CirculationSearchService} from '../../../services/circulation-search.service';


export function getTasksAfterDeadlineFilter() {
  return [
    {
      fieldName: 'isManagementTask',
      operator: FilterOperator.equals,
      value: String(false),
    },
    {
      fieldName: 'deadline',
      operator: FilterOperator.lessOrEqual,
      value: String(formatAsLocalIsoDate(getTodayMidnight())),
    }
  ];
}

@Component({
  selector: 'icz-dashboard-officer',
  templateUrl: './dashboard-officer.component.html',
  styleUrls: ['./dashboard-officer.component.scss']
})
export class DashboardOfficerComponent extends AbstractDashboardConnectedToSettingsComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private circulationSearchService = inject(CirculationSearchService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private documentSearchService = inject(DocumentSearchService);
  private modalService = inject(IczModalService);
  private router = inject(Router);

  readonly documentTasksRoute = createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.TASKS);
  readonly documentsExpiringRoute = createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.EXPIRING);
  readonly documentsNewOwnDocument = createAbsoluteRoute(ApplicationRoute.DOCUMENTS, ActionRoute.NEW);
  readonly documentsNewReceivedDocument = createAbsoluteRoute(ApplicationRoute.DOCUMENTS, DocumentsRoute.RECEIVED);

  readonly SubstitutionsCounterMode = SubstitutionsCounterMode;
  readonly IconCounterBadgeColor = IconCounterBadgeColor;

  taskCount$ = this.mainMenuCountsService.menuItemsWithCounts$.pipe(map(menuItems => menuItems ? findMenuItemByViewId(menuItems, MenuItemCountViewIds.DOCUMENTS_TASKS)?.count ?? 0 : 0));

  hasTasksAfterDeadline$!: Observable<boolean>;
  expiringDocumentsCount$!: Observable<number>;
  expiredDocumentsCount$!: Observable<number>;

  override ngOnInit() {
    super.ngOnInit();

    const triggerDate = getStartOfTheDay();
    triggerDate.setDate(triggerDate.getDate() + 3);

    this.expiringDocumentsCount$ = this.documentSearchService.countExpiringDocuments({...getDefaultSearchParams(), size: 0, filter: []}, triggerDate, {isUnitView: false});
    this.expiredDocumentsCount$ = this.documentSearchService.countExpiredDocuments({...getDefaultSearchParams(), size: 0, filter: []}, {isUnitView: false});
    this.hasTasksAfterDeadline$ = this.circulationSearchService.countTasksGlobally({...getDefaultSearchParams(), size: 0, filter: getTasksAfterDeadlineFilter()}, {isUnitView: false}).pipe(
      map(taskCount => taskCount > 0),
    );
  }

  goToPage(route: string) {
    this.router.navigateByUrl(route);
  }

  onCreateEmailConsignmentClick() {
    return this.modalService.openComponentInModal<boolean, void>({
      component: CreateEmailConsignmentDialogComponent,
      modalOptions: {
        width: 600,
        height: 800,
        titleTemplate: 'Předání e-mailové zprávy doručené mimo podatelnu',
      }
    });
  }

}
