import {NgModule} from '@angular/core';
import {ComponentModalComponent} from './component-modal/component-modal.component';
import {ModalDialogComponent} from './modal-dialog/modal-dialog.component';
import {SimpleDialogComponent} from './simple-dialog/simple-dialog.component';

/**
 * @internal
 */
const MODALS = [
  ModalDialogComponent,
  ComponentModalComponent,
  SimpleDialogComponent,
];

/**
 * The components, directives and pipes in this library are fully standalone. Import this NgModule
 * if you prefer using NgModules instead of standalone imports in your codebase.
 */
@NgModule({
  imports: MODALS,
  exports: MODALS,
})
export class ModalsModule {}
