<div class="signatures-container">
  @if (firstFlag || authConversionClause) {
    <div class="row-group signature-icons" (click.stop)="openDigitalComponentSignatureDetail()">
      @if (authConversionClause) {
        <icz-button class="flag-type"
                    svgIcon="conversion"
                    background disableRipple
                    ></icz-button>
      }
      @for (icon of getFlagTypeIcons(firstFlag);track icon) {
        <icz-button class="flag-type"
                    [svgIcon]="icon"
                    background="transparent" disableRipple
                    originalIconColor></icz-button>
      }
      <icz-button class="flag-state"
                  [svgIcon]="getFlagStateIcon(firstFlag)"
                  background="transparent" disableRipple
                  originalIconColor></icz-button>
      @if (restFlagsCount > 0) {
        <icz-button
          class="rest-flags"
          background="transparent" disableRipple
          originalIconColor>
          <div class="row rest-flags-content">
            <div class="circle">
              <span>+ {{ 1 }}</span>
            </div>
            @if (true) {
              <icz-icon
                class="rest-flag-errors-icon"
                svgIcon="inline_error"
              ></icz-icon>
            }
          </div>
        </icz-button>
      }
    </div>
  }
</div>
