<icz-card>
  <div class="px-24 pb-16 card-divider">
    <div class="row grow"><icz-heading class="heading-custom-margin" label="Mnou zadané úkoly" [level]="'3'"></icz-heading></div>
    <div class="mb-8">{{ taskFilterText }}</div>
    @for (activity of activities; track activity.id) {
      <div class="my-12">
        <div class="row grow">
          <icz-task-activity-state-icon
            class="visible-clickable-area"
            [activity]="activity"
          ></icz-task-activity-state-icon>
          <icz-activity-state-tag [activityState]="activity.activityState"></icz-activity-state-tag>
          <span class="ml-8 date-time-secondary">{{activity.initDate | localizedDatetime}}</span>
        </div>
        <div class="row grow ml-24 mt-8">
          @if (isComponentCirculation(activity)) {
            <a
              class="p-4 icz-body-1 visible-clickable-area" iczLinkWithoutHref
              (click)="openCirculationComponent(activity)"
            >
              <span>{{ $any(activity).componentLabel }}</span>
            </a>
          }
          @else {
            <div>
              <icz-ref-number-link [entity]="$any(activity)"
                                   [view]="viewType"
              ></icz-ref-number-link>
              <span class="ml-8">{{activity.subject}}</span>
            </div>
          }
        </div>
        <div class="ml-24 col">
          <div class="row grow my-8">
            @if (activity.targetParticipants!.length > 0) {
              @if (getPrimaryTargetParticipantText($any(activity.targetParticipants)); as primaryTargetParticipant) {
                <span [iczTooltip]="primaryTargetParticipant">
                  {{ primaryTargetParticipant }}
                </span>
              }
            }
          </div>
          <div>
            <icz-activity-notes
              [activity]="activity"
            ></icz-activity-notes>
          </div>
        </div>
      </div>
    }
    @if (!activities.length) {
      <div>
        <span>{{'Žádné úkoly' | translate}}</span>
      </div>
    }
  </div>
  <icz-button size="xlarge" label="Všechny mnou zadané úkoly" background="transparent" (onAction)="navigateToMyActivities()"></icz-button>
</icz-card>
