import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {DigitalComponentCompleteDto} from '|api/component';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IdtConnectionState, IdtLinkService} from '../../../../../core/services/idt/idt-link.service';
import {IdtService} from '../../../../../core/services/idt/idt.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {switchMap, take} from 'rxjs/operators';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {ICZ_MODAL_DATA, IczModalRef} from '@icz/angular-modal';
import {ApiEsslWebdavShareRequestService, WebDavShareRequestRegistrationDto} from '|api/webdav';
import {WebDavSharePurpose} from '|api/commons';
import {ENVIRONMENT} from '../../../../../core/services/environment.service';
import {getLatestDigitalComponentVersion} from '../../../shared-document.utils';
import {
  EsslComponentToastService,
  EsslComponentToastType
} from '../../../../../core/services/notifications/essl-component-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {TranslateService} from '@ngx-translate/core';
import {esslErrorDtoToToastParameters} from '../../../../notifications/toast.service';

export interface DigitalComponentAnonymizationDialogData {
  isReceivedDocument: boolean;
  digitalComponent: DigitalComponentCompleteDto;
  digitalComponentVersionId: number;
}

@Component({
  selector: 'icz-digital-component-anonymization-dialog',
  templateUrl: './digital-component-anonymization-dialog.component.html',
  styleUrls: ['./digital-component-anonymization-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class DigitalComponentAnonymizationDialogComponent implements OnInit {
  protected modalRef = inject(IczModalRef<void>);
  protected loadingService = inject(LoadingIndicatorService);
  private data = inject(ICZ_MODAL_DATA) as DigitalComponentAnonymizationDialogData;
  private idtLinkService = inject(IdtLinkService);
  private idtService = inject(IdtService);
  private destroyRef = inject(DestroyRef);
  private apiEsslWebdavShareRequestService = inject(ApiEsslWebdavShareRequestService);
  private componentToastService = inject(EsslComponentToastService);
  private translateService = inject(TranslateService);
  private environment = inject(ENVIRONMENT);

  form = new IczFormGroup({
    saveAsNewComponent: new IczFormControl<boolean>(false),
    fileName: new IczFormControl<Nullable<string>>(null),
    description: new IczFormControl<Nullable<string>>(null),
  });
  forceNewComponentCreation = false;
  idtStatus = IdtConnectionState.INITIATING;
  readonly IdtConnectionState = IdtConnectionState;
  readonly FILE_SUFFIX = '_Anonymizováno';

  loadIdt(count: number) {
    this.loadingService.doLoading(
      this.idtLinkService.idtConnectionStatus$.pipe(
        take(count),
      ),
      this
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(status => {
      this.idtStatus = status;
    });
  }

  ngOnInit() {
    this.loadIdt(1);
    this.form.get('saveAsNewComponent')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(value => {
      if (value) {
        this.form.get('fileName')!.setValidators([IczValidators.required()]);
      } else {
        this.form.get('fileName')!.clearValidators();
      }
      this.form.get('fileName')!.updateValueAndValidity();
    });

    if (this.data.isReceivedDocument) {
      this.forceNewComponentCreation = this.data.isReceivedDocument;
    } else {
      if (this.data.digitalComponent.isFinal) {
        this.forceNewComponentCreation = true;
      }
    }

    this.form.get('fileName')!.setValue(this.data.digitalComponent.label + this.FILE_SUFFIX);
    this.form.get('description')!.setValue(this.data.digitalComponent.description);
    this.form.get('saveAsNewComponent')!.setValue(this.forceNewComponentCreation);
  }

  reconnect() {
    this.idtLinkService.initialize();
    this.loadIdt(2);
  }

  submit() {
    const formValue = this.form.getRawValue();
    const domain = this.environment.hostUrl;
    const fileExtension = getLatestDigitalComponentVersion(this.data.digitalComponent)!.fileName!.split('.').at(-1);
    let newFileName = '';
    if (formValue.saveAsNewComponent) {
      newFileName = `${formValue.fileName!}.${fileExtension}`;
    } else {
      newFileName = `${this.data.digitalComponent.label}${this.FILE_SUFFIX}.${fileExtension}`;
    }
    const request: WebDavShareRequestRegistrationDto = {
      originalComponentId: this.data.digitalComponent.id!,
      originalComponentVersionId: this.data.digitalComponentVersionId,
      documentId: this.data.digitalComponent.documentId!,
      purpose: WebDavSharePurpose.ANONYMIZATION,
      fileName: newFileName,
      description : formValue.description,
      saveAsNewComponent: formValue.saveAsNewComponent,
      mimeType: getLatestDigitalComponentVersion(this.data.digitalComponent)!.mimeType!
    };
    this.loadingService.doLoading(
      this.apiEsslWebdavShareRequestService.esslWebdavShareRequestRegister({body: request}).pipe(switchMap(response => {
        return this.idtService.openAnonymization({
          mimeType: getLatestDigitalComponentVersion(this.data.digitalComponent)!.mimeType!,
          url: `${domain}${response.url}`,
        }).pipe(
          takeUntilDestroyed(this.destroyRef),
        );
      })),
      this
    ).subscribe({
      next: _ => this.modalRef.close(true),
      error: error => {
        this.componentToastService.dispatchComponentErrorToast(EsslComponentToastType.COMPONENT_ANONYMIZATION_ERROR, {
          [InternalNotificationKey.DOCUMENT_ID]: String(this.data.digitalComponent.documentId),
          [InternalNotificationKey.DIGITAL_COMPONENT_ID]: String(this.data.digitalComponent.id),
          [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: String(getLatestDigitalComponentVersion(this.data.digitalComponent)!.id),
          [InternalNotificationKey.ESSL_COMPONENT_LABEL]: String(this.data.digitalComponent.label),
          ...esslErrorDtoToToastParameters(this.translateService, error.error),
        });
        this.modalRef.close(false);
      }
    });
  }

  cancel() {
    this.modalRef.close(false);
  }
}
