/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { agendaTransferConfirmTransfer } from '../fn/agenda-transfer/agenda-transfer-confirm-transfer';
import { AgendaTransferConfirmTransfer$Params } from '../fn/agenda-transfer/agenda-transfer-confirm-transfer';
import { agendaTransferCreateExportForEnvelope } from '../fn/agenda-transfer/agenda-transfer-create-export-for-envelope';
import { AgendaTransferCreateExportForEnvelope$Params } from '../fn/agenda-transfer/agenda-transfer-create-export-for-envelope';
import { agendaTransferMarkForTransfer } from '../fn/agenda-transfer/agenda-transfer-mark-for-transfer';
import { AgendaTransferMarkForTransfer$Params } from '../fn/agenda-transfer/agenda-transfer-mark-for-transfer';
import { agendaTransferUnmarkForTransfer } from '../fn/agenda-transfer/agenda-transfer-unmark-for-transfer';
import { AgendaTransferUnmarkForTransfer$Params } from '../fn/agenda-transfer/agenda-transfer-unmark-for-transfer';


/**
 * Controller for managing agenda transfer confirmation.
 */
@Injectable({ providedIn: 'root' })
export class ApiAgendaTransferService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `agendaTransferUnmarkForTransfer()` */
  static readonly AgendaTransferUnmarkForTransferPath = '/document/agenda-transfer/unmark-for-transfer';

  /**
   * Unmarks files or documents as ready for agenda transfer (sets flag isReadyForTransfer to false).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferUnmarkForTransfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agendaTransferUnmarkForTransfer$Response(params: AgendaTransferUnmarkForTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return agendaTransferUnmarkForTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Unmarks files or documents as ready for agenda transfer (sets flag isReadyForTransfer to false).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferUnmarkForTransfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agendaTransferUnmarkForTransfer(params: AgendaTransferUnmarkForTransfer$Params, context?: HttpContext): Observable<void> {
    return this.agendaTransferUnmarkForTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `agendaTransferMarkForTransfer()` */
  static readonly AgendaTransferMarkForTransferPath = '/document/agenda-transfer/mark-for-transfer';

  /**
   * Marks files or documents as ready for agenda transfer (sets flag isReadyForTransfer to true).
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferMarkForTransfer()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agendaTransferMarkForTransfer$Response(params: AgendaTransferMarkForTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return agendaTransferMarkForTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Marks files or documents as ready for agenda transfer (sets flag isReadyForTransfer to true).
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferMarkForTransfer$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  agendaTransferMarkForTransfer(params: AgendaTransferMarkForTransfer$Params, context?: HttpContext): Observable<void> {
    return this.agendaTransferMarkForTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `agendaTransferConfirmTransfer()` */
  static readonly AgendaTransferConfirmTransferPath = '/document/agenda-transfer/confirm-transfer/{digitalComponentVersionId}';

  /**
   * Confirms agenda transfer.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferConfirmTransfer()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferConfirmTransfer$Response(params: AgendaTransferConfirmTransfer$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return agendaTransferConfirmTransfer(this.http, this.rootUrl, params, context);
  }

  /**
   * Confirms agenda transfer.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferConfirmTransfer$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferConfirmTransfer(params: AgendaTransferConfirmTransfer$Params, context?: HttpContext): Observable<void> {
    return this.agendaTransferConfirmTransfer$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `agendaTransferCreateExportForEnvelope()` */
  static readonly AgendaTransferCreateExportForEnvelopePath = '/document/agenda-transfer/run-export/{envelopeId}/{isTestingExport}';

  /**
   * Generates export zip file for agenda transfer envelope
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferCreateExportForEnvelope()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferCreateExportForEnvelope$Response(params: AgendaTransferCreateExportForEnvelope$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return agendaTransferCreateExportForEnvelope(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates export zip file for agenda transfer envelope
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferCreateExportForEnvelope$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferCreateExportForEnvelope(params: AgendaTransferCreateExportForEnvelope$Params, context?: HttpContext): Observable<void> {
    return this.agendaTransferCreateExportForEnvelope$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
