import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {forkJoin} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  ApiDocumentService,
  DocumentDto,
  RegisterDocumentInFilingRegisterRequestDto,
  RegisterDocumentInFilingRegisterResponseDto
} from '|api/document';
import {ApiFilingRegisterService} from '|api/records';
import {BasicRegistersService} from '../../../basic-registers.service';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {DialogService, DialogSeverity, injectModalData, injectModalRef} from '@icz/angular-modal';
import {namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {
  DocumentToastService,
  DocumentToastType
} from '../../../../../core/services/notifications/document-toast.service';
import {InternalNotificationKey} from '|api/notification';

@Component({
  selector: 'icz-reregister-dialog',
  templateUrl: './reregister-dialog.component.html',
  styleUrls: ['./reregister-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class ReregisterDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = injectModalRef<Nullable<RegisterDocumentInFilingRegisterResponseDto | boolean>>();
  private apiDocumentService = inject(ApiDocumentService);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  private basicRegistersService = inject(BasicRegistersService);
  private apiFilingRegisterService = inject(ApiFilingRegisterService);
  private dialogService = inject(DialogService);
  private documentToastService = inject(DocumentToastService);
  protected documents = injectModalData<DocumentDto[]>();

  form = new IczFormGroup({
    curentRegisterId: new IczFormControl<Nullable<number>>({value: null, disabled: true}),
    newRegisterId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  basicRegisterOptions!: IczOption[];
  applicableBasicRegisterOptions!: IczOption[];

  formGroupsToCheck!: string[];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);

    // when multiple documents are selected as input of this dialog, they should be with same curentRegisterId
    this.form.get('curentRegisterId')?.setValue(this.documents[0].filingRegisterId);

    this.loadingService.doLoading(
      forkJoin([
        this.basicRegistersService.getBasicRegisters(),
        this.apiFilingRegisterService.filingRegisterGetValidFilingRegisters(),
      ]),
      this,
    ).pipe(
      map(([basicRegisters, applicableBasicRegisters]) => ([basicRegisters.map(namedDtoToOption()), applicableBasicRegisters.map(namedDtoToOption())])),
    ).subscribe(([basicRegisterOptions, applicableBasicRegisterOptions]) => {
      this.basicRegisterOptions = basicRegisterOptions;
      this.applicableBasicRegisterOptions = applicableBasicRegisterOptions.filter(o => o.value !== this.documents[0].filingRegisterId);

      if (!this.applicableBasicRegisterOptions.length) {
        // Using timeout because otherwise it sometimes did not open the modal under some circumstances.
        setTimeout(() => {
          this.dialogService.openSimpleDialog({
            severity: DialogSeverity.ERROR,
            title: 'Není kam přeevidovat',
            content: [
              {
                text: 'V systému není založena žádná další platná základní evidence mimo podací deník, kam je nyní dokument zaevidován.',
              }
            ]
          }).subscribe(_ => this.modalRef.close(false));
        }, 0);
      }
    });
  }

  submit() {
    const targetFilingRegisterId = this.form.get('newRegisterId')!.value;


    if (this.documents.length > 1) {
      this.loadingService.doLoading(
        this.apiDocumentService.documentBulkRegister({
          body: this.documents.map(d => ({documentId: d.id, targetFilingRegisterId}))
        }),
        this
      ).subscribe({
        next: _ => {
          this.modalRef.close(true);
          this.documentToastService.dispatchBulkOperationStartedToast(DocumentToastType.DOCUMENT_BULK_REREGISTER_STARTED, {
            [InternalNotificationKey.COUNT]: this.documents.length
          });
        },
        error: _ => {
          this.modalRef.close(false);
          this.documentToastService.dispatchSimpleErrorToast(DocumentToastType.DOCUMENT_BULK_ACTION_ERROR);
        }
      });
    } else {
      const requestDto: RegisterDocumentInFilingRegisterRequestDto = {
        targetFilingRegisterId
      };
      this.loadingService.doLoading(
        this.apiDocumentService.documentRegister({
          id: this.documents[0].id,
          body: requestDto,
        }),
        this,
      ).subscribe(result => {
        this.modalRef.close(result);
      });
    }
  }

  cancel() {
    this.modalRef.close(null);
  }

}
