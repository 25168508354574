import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {DestroyRef, inject, Injectable} from '@angular/core';
import {cloneDeep, isEqual} from 'lodash';
import {Subscription} from 'rxjs';
import {
  ErrorDialogDataService,
  ErrorDialogItem,
  ValidationError
} from '../../components/dialogs/error-dialog/error-dialog-data.service';
import {DialogSeverity, DialogWithTitleData, IczModalRef, IczModalService, SimpleDialogData} from '@icz/angular-modal';
import {GlobalLoadingIndicatorService, InterpolationContext} from '@icz/angular-essentials';
import {ApplicationConfigService} from './config/application-config.service';
import {BugReportService} from './bug-report.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ErrorDialogComponent} from '../../components/dialogs/error-dialog/error-dialog.component';


export interface EsslErrorDto {
  code: string;
  codes?: string[];
  arguments: Nullable<string[]>;
  objectName?: string;
  field?: string;
  rejectedValue?: any;
  defaultMessage?: string;
  bindingFailure?: boolean;
}

export interface EsslErrorResponseDto {
  errorCode: string;
  timestamp: string;
  status: HttpStatusCode;
  error: string;
  message: string;
  errors?: EsslErrorDto[];
  parameters?: string[];
  innerErrorParameters?: string[];
  suppressFrontendErrorReporting: boolean;
}

function arrayBufferToObject(buffer: any): EsslErrorResponseDto {
  let str = '';
  const decoder = new TextDecoder();
  str = decoder.decode(buffer);

  /*const array = new Uint8Array(buffer);
  array.forEach(intChar => {
    str += String.fromCharCode(intChar);
  });*/
  return JSON.parse(str) as EsslErrorResponseDto;
}

@Injectable({
  providedIn: 'root'
})
export class CommunicationErrorDialogService {

  private modalService = inject(IczModalService);
  private errorDialogDataService = inject(ErrorDialogDataService);
  private globalLoadingService = inject(GlobalLoadingIndicatorService);
  private applicationConfigService = inject(ApplicationConfigService);
  private bugReportService = inject(BugReportService);
  private destroyRef = inject(DestroyRef);

  private communicationErrorModalRef: Nullable<IczModalRef<boolean>> = null;

  showCommunicationError(response: HttpErrorResponse) {
    let text = '';
    let context: InterpolationContext = {};
    let severity: DialogSeverity = DialogSeverity.ERROR;
    const validationErrors: ValidationError[] = [];

    let errorResponseBody: EsslErrorResponseDto;
    if (typeof response.error === 'string') {
      errorResponseBody = JSON.parse(response.error);
    } else if (response.error instanceof ArrayBuffer) {
      errorResponseBody = arrayBufferToObject(response.error);
    } else {
      errorResponseBody = response.error;
    }

    switch (response.status) {
      case 400:
        if (!errorResponseBody) break;

        if (errorResponseBody.errors) {
          errorResponseBody.errors.forEach(error => {
            validationErrors.push({
              objectType: error.objectName!,
              field: error.field!,
              message: error.defaultMessage!,
              errorCode: error.code,
              parameters: error.arguments!
            });
          });
        }
        else {
          validationErrors.push({
            objectType: 'Anonymous',
            field: 'unknown',
            message: errorResponseBody.message ?? errorResponseBody.errorCode,
            errorCode: errorResponseBody.errorCode,
            parameters: errorResponseBody.parameters ?? [],
          });
        }
        break;

      case 401:
        text = 'ui.http-error.unauthorized';
        context = {...errorResponseBody};
        break;

      case 404:
        text = 'ui.http-error.not-found';
        context = {...errorResponseBody};
        break;

      case 423:
        if (errorResponseBody?.errorCode === 'elastic.index.isIndexing') { // should it be response.error?.errors[0].errorCode ? Need to check with BE
          severity = DialogSeverity.INFO;
          text = 'ui.http-info-elastic-indexing-in-progress';
        }
        else if (
          errorResponseBody?.errorCode === 'elastic.index.notInitialized' ||
          errorResponseBody?.errorCode === 'elastic.index.errorDuringIndexing') {
          text = 'ui.http-error-elastic-errored';
        }
        else {
          text = 'ui.http-error.try-it-later';
        }

        context = {...errorResponseBody};
        break;

      case 500:
      case 502:
        text = 'ui.http-error.severe-error';
        context = {path: response.url, error: errorResponseBody.error};

        validationErrors.push({
          objectType: 'Anonymous',
          field: 'unknown',
          message: errorResponseBody.message ?? errorResponseBody.errorCode,
          errorCode: errorResponseBody.errorCode,
          parameters: errorResponseBody.parameters ?? [],
        });
        break;

      case 0:
        return;

      default: // namely 503
        text = 'ui.http-error.try-it-later';
    }

    const errorItem: ErrorDialogItem = {
      text,
      context,
      validationErrors,
    };

    if (!this.communicationErrorModalRef) {
      this.errorDialogDataService.setData([errorItem]);
      this.communicationErrorModalRef = this.openCommunicationErrorDialog({
        title: 'ui.error.title',
        severity,
        content: [],
        leftButtonTitle: this.applicationConfigService.isErrorReportingEnabled ? 'Chybové hlášení' : 'Zavřít',
        showRightButton: this.applicationConfigService.isErrorReportingEnabled,
      });

      const afterClosedSubscription = this.communicationErrorModalRef!.afterClosed().pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(result => {
        if (result && this.applicationConfigService.isErrorReportingEnabled) {
          this.globalLoadingService.doLoading(
            this.bugReportService.generateBugReport(),
            'Tvorba chybového hlášení může chvíli trvat'
          ).subscribe();
        }

        this.closeDialog(afterClosedSubscription);
      });
    }
    else {
      if (!this.dialogAlreadyContainsError(errorItem)) {
        this.errorDialogDataService.addDialogItem(errorItem);
      }
    }
  }

  private openCommunicationErrorDialog(data: DialogWithTitleData) {
    return this.modalService.openComponentInModalWithInstance<boolean, SimpleDialogData>({
      component: ErrorDialogComponent,
      modalOptions: {
        width: 450,
        height: 350,
        titleTemplate: data.title ?? (data.severity === DialogSeverity.ERROR ? 'Nastala chyba' : 'Upozornění'),
      },
      data
    });
  }

  private dialogAlreadyContainsError(errorItem: ErrorDialogItem) {
    const actualData = this.errorDialogDataService.getActualData();

    if (actualData) {
      return actualData.find(item => {
        // compare errors without timestamps if they are present - such way it provides unique error list
        const itemCopy = cloneDeep(item);
        delete itemCopy.context.timestamp;
        const errorItemCopy = cloneDeep(errorItem);
        delete errorItemCopy.context.timestamp;

        return (
          itemCopy.text === errorItemCopy.text &&
          isEqual(itemCopy.context, errorItemCopy.context) &&
          isEqual(itemCopy.validationErrors, errorItemCopy.validationErrors)
        );
      }) !== undefined;
    }
    else {
      return false;
    }
  }

  private closeDialog(afterClosedSubscription: Subscription) {
    this.communicationErrorModalRef = null;
    afterClosedSubscription.unsubscribe();
  }

}
