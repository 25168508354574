@if (consignment) {
  @if (isOwnOfficeDeskElasticConsignment(consignment) && $any(consignment).publicPosting) {
    <div>
      <span>{{'Veřejné vyvěšení' | translate}}</span>
    </div>
  }
  @else {
    @if (isOwnInternalElasticConsignment(consignment)) {
      <span> {{ consignment.consigneeOrganizationalUnitId! | findOrganizationalUnitById | async }}</span>
    }
    @if (isOwnPaperElasticConsignment(consignment) || isOwnOfficeDeskElasticConsignment(consignment) || isOwnPersonalElasticConsignment(consignment) || isOwnDigitalElasticConsignment(consignment) ||
    isOwnFaxElasticConsignment(consignment) || isOwnPortalElasticConsignment(consignment) || isOwnMultiPaperElasticConsignment(consignment)) {
      <div class="row items-center">
        <icz-subject-identification-status-icon
          [subject]="consignment.consigneeDetail"
          size="small"
        ></icz-subject-identification-status-icon>
        <span class="inline truncate">{{consignment.consigneeDetail | subjectName:true:$any(consignment).consigneeAdditionalName}}</span>
      </div>
    }
  }
}
