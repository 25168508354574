import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {add} from 'date-fns';

/**
 * @internal
 */
function stripTimezoneFromDate(value: Nullable<Date>): Nullable<Date> {
  return isNil(value) ? value : add(value, {minutes: value.getTimezoneOffset()});
}

/**
 * A function used for formatting dates as Date objects/ISO strings/Unix timestamps to local date format based on app language.
 */
export function iczFormatDate(
  currentLocale: string,
  value: Nullable<string | number | Date>,
  ignoreTimezoneOffset = false
) {
  const convertedValue = convertValueToDateObject(value, ignoreTimezoneOffset);

  if (currentLocale === 'cs' || currentLocale === 'sk') {
    return convertedValue?.toLocaleDateString('cs-CZ') ?? '';
  }
  else {
    return convertedValue?.toLocaleDateString('en-US') ?? '';
  }
}

/**
 * Converts Unix timestamp number or ISO string to JS Date object.
 * If a Date object arrives to the function, it is left as-is.
 */
export function convertValueToDateObject(value: Nullable<string | number | Date>, ignoreTimezoneOffset = false): Nullable<Date> {
  if (value && (typeof (value) === 'number' || typeof (value) === 'string')) {
    if (typeof (value) === 'string' && !Number.isNaN(Number(value))) {
      value = Number(value);
    }
    value = new Date(value);
  }

  let convertedValue = value as Nullable<Date>;

  if (ignoreTimezoneOffset) {
    convertedValue = stripTimezoneFromDate(convertedValue);
  }

  return convertedValue;
}

/**
 * A pipe used for formatting dates as Date objects/ISO strings/Unix timestamps to local date format based on app language.
 */
@Pipe({
  name: 'localizedDate',
  pure: false, // false because it also depends on app locale
  standalone: true,
})
export class LocalizedDatePipe implements PipeTransform {

  private translateService = inject(TranslateService);

  /**
   * @param value - ISO date OR unix timestamp in milliseconds since Epoch
   * @param ignoreTimezoneOffset - use for formatting wall datetime strings without timezone specifiers
   */
  transform(value: Nullable<string | number | Date>): string {
    if (!value) { // Branch used merely for performance optimization
      return '';
    }

    return iczFormatDate(this.translateService.currentLang, value);
  }

}
