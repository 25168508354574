import {add} from 'date-fns';

export interface ArrayDiff<T> {
  additions: T[];
  removals: T[];
}

export function getArrayDiff<T>(originalItems: T[], newItems: T[]): ArrayDiff<T> {
  return {
    additions: newItems.filter(i => !originalItems.includes(i)),
    removals: originalItems.filter(i => !newItems.includes(i)),
  };
}

/**
 * Uses Object#is internally for comparison.
 */
export function hasDuplicates<T>(arr: T[], comparator: (arg0: T) => any = (x: T) => x): boolean {
  if (!arr || arr.length === 0) return false;

  return (new Set(arr.map(comparator))).size !== arr.length;
}

/**
 * Moves an item of arr from index A to index B and returns a new array (shallow copy).
 */
export function arrayMove<T>(arr: Array<Nullable<T>>, fromIndex: number, toIndex: number): T[] {
  const arrCopy = [...arr];

  if (toIndex >= arrCopy.length) {
    let k = toIndex - arrCopy.length + 1;
    while (k) {
      arrCopy.push(null);
      --k;
    }
  }

  arrCopy.splice(toIndex, 0, arrCopy.splice(fromIndex, 1)[0]);

  return arrCopy as T[];
}

/**
 * Checks if a value if empty in terms of form field inputs.
 */
export function isNilOrEmptyString(value: any): value is Nil | '' {
  return isNil(value) || value === '';
}

/**
 * Checks is a given value is a string which contains a YYYY-MM-DD type date.
 * Does not validate logical correctness and consistency of month x date in the value.
 */
export function isIsoDateString(s: any) {
  return typeof s === 'string' && (/\d{4}-\d{2}-\d{2}/).test(s);
}

/**
 * Returns start (00:00:00.000) of a given day according to local
 * time (i.e. real hours/minutes might be different in GMT).
 */
export function getMidnightOfTheDay(date: Date): Date {
  const out = new Date(date);
  out.setHours(0);
  out.setMinutes(0);
  out.setSeconds(0);
  out.setMilliseconds(0);

  return out;
}

/**
 * Returns start of today.
 * @see getMidnightOfTheDay
 */
export function getTodayMidnight(): Date {
  return getMidnightOfTheDay(new Date());
}

/**
 * Returns start of the next day.
 * @see getMidnightOfTheDay
 */
export function getTomorrowMidnight(): Date {
  let out = new Date();
  out = add(out, {days: 1});
  out.setHours(0);
  out.setMinutes(0);
  out.setSeconds(0);
  out.setMilliseconds(0);

  return out;
}

/**
 * Computes number of days between date A and date B.
 * Mathematically rounds non-whole day reminders.
 */
export function daysBetween(start: string, end: string): number {
  const d1 = Number(new Date(start));
  const d2 = Number(new Date(end));
  return Math.round((d2 - d1) / (1000 * 3600 * 24)); // convert ms to days
}
