<div class="col grow overflow-hidden">
  @if (showHeader) {
    <div
      cdkDrag cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
      cdkDragBoundary=".cdk-overlay-container"
      class="row overflow-hidden header">
      <div class="row grow header-title">
        <icz-label class="whitespace-pre truncate" [label]="title || 'TODO Write Modal Title'" [labelContext]="titleContext"></icz-label>
      </div>
      <icz-button [class.invisible]="!canClose" cdkDragHandle cdkDragHandleDisabled svgIcon="close" size="small" (onAction)="dialog.close(null)" background="transparent" disableRipple></icz-button>
    </div>
  }

  <div class="col grow block overflow-hidden modal-content">
    <ng-content></ng-content>
  </div>

  @if (showFooter) {
    <div class="row footer">
      <ng-content select="[leftButtons]"></ng-content>
      <div class="grow"></div>
      <ng-content select="[rightButtons]"></ng-content>
    </div>
  }
</div>
