import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {of, pipe} from 'rxjs';
import {debounceTime, filter, finalize, map, switchMap, tap} from 'rxjs/operators';
import {CrossReferenceSpecializationType, DocumentForm, EntityType, ObjectClass} from '|api/commons';
import {
  ApiCrossReferenceService,
  ApiDocumentService,
  ApiReceivedDocumentService,
  DocumentDto,
  DocumentPrepareSettlementDto,
  ReceivedDocumentDto
} from '|api/document';
import {
  makeDefaultOptionsDefinition,
  OptionsDefinitionFactory
} from '@icz/angular-form-elements';
import {IczFormGroup} from '@icz/angular-form-elements';
import {enumToOptions} from '../../../../../../core/services/data-mapping.utils';
import {getObjectIcon} from '../../../../shared-document.utils';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {CodebookService} from '../../../../../../core/services/codebook.service';
import {SKIP_ERROR_DIALOG} from '../../../../../../core/error-handling/http-errors';
import {DocumentSearchService} from '../../../../../../services/document-search.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {VisibleClassifiersMode} from '../../../../abstract-classifier-selector.component';
import {SearchedDocumentOptionData} from '../document-settle-model';
import {locateOptionByValue, IczOption} from '@icz/angular-form-elements';


@Component({
  selector: 'icz-document-settle-by-document-form',
  templateUrl: './document-settle-by-document-form.component.html',
  styleUrls: ['./document-settle-by-document-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentSettleByDocumentFormComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private searchService = inject(DocumentSearchService);
  private apiDocumentService = inject(ApiDocumentService);
  private translateService = inject(TranslateService);
  private codebookService = inject(CodebookService);
  private apiReceivedDocumentService = inject(ApiReceivedDocumentService);
  private apiCrossReferenceService = inject(ApiCrossReferenceService);
  private destroyRef = inject(DestroyRef);

  @Input()
  document!: DocumentDto;
  @Input()
  form!: IczFormGroup;
  @Input()
  preselectedDocument: Nullable<DocumentDto>;
  @Output()
  resolutionByDocumentChanged = new EventEmitter<Nullable<SearchedDocumentOptionData>>();

  settlementDate!: Date;
  selectedResolutionDocument: Nullable<DocumentDto>;

  readonly minSearchTermLength = 3;

  searchedDocumentOptions!: IczOption<Nullable<number>, SearchedDocumentOptionData>[];
  previouslySearchedOptions: IczOption<Nullable<number>, SearchedDocumentOptionData>[] = [];
  documentFormOptions = enumToOptions('documentForm', DocumentForm) as IczOption<string>[];
  documentErrors: string[] = [];

  documentToOption: (d: DocumentDto) => IczOption<number, SearchedDocumentOptionData> = d => ({
    value: d.id,
    label: `${d.refNumber ?? this.translateService.instant('bez čísla jednacího')} - ${d.subject}`,
    icon: getObjectIcon(d.objectClass as unknown as ObjectClass)!,
    data: {
      document: d,
    }
  });

  get byDocumentForm() {
    return this.selectedResolutionDocument?.documentForm?.toString();
  }

  get byDocumentComponentsCount() {
    return String(this.form.get('relatedDocumentComponents')!.value?.length ?? 0);
  }

  get relatedDocumentControl() {
    return this.form.get('relatedDocumentId');
  }

  readonly VisibleClassifiersMode = VisibleClassifiersMode;

  makeDocumentsSearchOptionsDefinition: OptionsDefinitionFactory = (options$, strForSearch) => {
    const defaultDefinition = makeDefaultOptionsDefinition(options$, strForSearch);

    defaultDefinition.searchtermToOptionsOperator = pipe(
      debounceTime(300),
      switchMap(searchTerm => {
        if (searchTerm.length >= this.minSearchTermLength && !isNil(this.document.fileId)) {
          return this.findDocuments(searchTerm);
        }
        else {
          return of([]);
        }
      }),
      map((documents: DocumentDto[]) => documents.map(this.documentToOption)),
      tap(options => this.searchedDocumentOptions = options ?? []),
    );

    return defaultDefinition;
  };

  private findDocuments(searchTerm: string) {
    return this.apiDocumentService.documentFindSuitableDocumentsForSettlement({
      id: this.document.id,
      body: {
        searchedTerm: searchTerm,
      }
    });
  }

  private prepareByDocumentSettlement(val: string) {
    if (this.selectedResolutionDocument) {
      this.loadingService.startLoading(this);

      this.settlementDate = new Date(val);

      this.apiDocumentService.documentPrepareSettlement(
        {
          id: this.selectedResolutionDocument.id,
          dateTime: val,
        },
        SKIP_ERROR_DIALOG
      ).pipe(finalize(() => {
        this.loadingService.endLoading(this);
      })).subscribe({
        next: prepareDto => {
          this.prepareDocumentSettlement(prepareDto);
        },
        error: err => {
          this.documentErrors = [err.error.error.errorCode];
        }
      });
    }
  }


  private prepareDocumentSettlement(prepareDto: DocumentPrepareSettlementDto) {
    if (prepareDto.documentTypes) {
      this.form.get('relatedDocumentTypeId')?.setValue(this.selectedResolutionDocument!.documentTypeId);
    }
  }

  ngOnInit(): void {
    this.setUpResolutionDocumentChangeListener();
    this.preselectResolutionDocumentByCrossReferences();

    if (this.preselectedDocument) {
      this.previouslySearchedOptions = this.searchedDocumentOptions = [this.preselectedDocument].map(this.documentToOption);
      this.relatedDocumentChanged(this.preselectedDocument.id);
    }
  }

  private relatedDocumentChanged(documentId: number) {
    this.selectedResolutionDocument = this.searchedDocumentOptions.find(op => op.value === documentId)?.data?.document;
    if (this.selectedResolutionDocument) {
      this.prepareByDocumentSettlement((this.form.get('settlementDate')?.value));
    }
  }

  private setUpResolutionDocumentChangeListener() {
    this.relatedDocumentControl?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(val => {
      this.relatedDocumentChanged(val);
    });
  }

  onResolutionDocumentSelected(selectedDocumentId: Nullable<number>) {
    if (selectedDocumentId) {
      this.resolutionByDocumentChanged.emit(locateOptionByValue(this.searchedDocumentOptions, selectedDocumentId)!.data);
    } else {
      this.resolutionByDocumentChanged.emit(null);
    }
  }

  private preselectResolutionDocumentByCrossReferences() {
    if (this.document?.entityType === EntityType.OWN_DOCUMENT) {
      this.loadingService.startLoading(this);
      this.apiCrossReferenceService.crossReferenceFindAll({
        entityType: EntityType.DOCUMENT,
        entityId: this.document.id,
      }).pipe(
        switchMap(documentCrossReferences => {
          const settlementByResponseReference = documentCrossReferences.find(
            cr => cr.valid && cr.specialization === CrossReferenceSpecializationType.DOCUMENT_SETTLEMENT_BY_RESPONSE
          );

          if (settlementByResponseReference) {
            let receivedDocumentId: Nullable<number>;

            if (settlementByResponseReference.entityReferringToId === this.document!.id) {
              receivedDocumentId = settlementByResponseReference.entityReferringFromId;
            }
            else if (settlementByResponseReference.entityReferringFromId === this.document!.id) {
              receivedDocumentId = settlementByResponseReference.entityReferringToId;
            }

            if (receivedDocumentId) {
              return this.apiReceivedDocumentService.receivedDocumentFindById({id: receivedDocumentId}).pipe(
                filter(Boolean),
              );
            }
            else {
              this.loadingService.endLoading(this);
              return of(null);
            }
          }
          else {
            this.loadingService.endLoading(this);
            return of(null);
          }
        }),
        tap((document: Nullable<ReceivedDocumentDto>) => {
          if (document) {
            this.searchedDocumentOptions = [this.documentToOption(document)];
            this.relatedDocumentControl?.setValue(document.id);
          }
        }),
      ).subscribe({
        error: _ => {
          this.loadingService.endLoading(this);
        }
      });
    }
  }

}
