import {Observable} from 'rxjs';
import {OwnOfficeDeskConsignmentDto, OwnOfficeDeskConsignmentElasticDto} from '|api/sad';

export enum ManualPostDialogResultType {
  POSTED = 'POSTED',
}
export interface ManualPostDialogResult {
  resultType: ManualPostDialogResultType;
  consignment: OwnOfficeDeskConsignmentDto;
}

/**
 * Necessary abstract class for avoiding cyclic dependency between shared and other modules,
 * dependency direction being reversed by providing services implementing this shared abstract
 */
export abstract class AbstractManualPostDialogService {
  abstract openManualPostDialog(documentId: number, consignment: OwnOfficeDeskConsignmentElasticDto): Observable<Nullable<ManualPostDialogResult>>;
}
