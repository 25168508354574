import {ChangeDetectionStrategy, Component, HostListener} from '@angular/core';
import {DialogMode, DialogSeverity, SimpleDialogData} from '../dialog.models';
import {ButtonComponent, FormButtonsComponent, IconComponent, InterpolatePipe} from '@icz/angular-essentials';
import {NgClass} from '@angular/common';
import {injectModalData, injectModalRef} from '../modals.utils';
import {TranslateModule} from '@ngx-translate/core';

/**
 * @internal
 */
@Component({
  selector: 'icz-simple-dialog',
  templateUrl: './simple-dialog.component.html',
  styleUrls: ['./simple-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    IconComponent,
    NgClass,
    FormButtonsComponent,
    ButtonComponent,
    TranslateModule,
    InterpolatePipe,
  ],
})
export class SimpleDialogComponent {

  protected modalRef = injectModalRef<boolean>();
  protected data = injectModalData<SimpleDialogData>();

  @HostListener('window:keydown.enter', ['$event'])
  protected enterPressed(event: Event) {
    event.stopPropagation();
    this.close(true);
  }

  @HostListener('window:keydown.escape', ['$event'])
  protected escapePressed(event: Event) {
    event.stopPropagation();
    this.close(false);
  }

  protected readonly DialogMode = DialogMode;

  protected get icon(): string {
    switch (this.data?.severity) {
      case DialogSeverity.ERROR:
        return 'modal_error';
      case DialogSeverity.WARNING:
        return 'warning_color';
      case DialogSeverity.INFO:
      default:
        return 'modal_info';
    }
  }

  protected get mode(): DialogMode {
    return this.data.mode ?? DialogMode.SIMPLE;
  }

  protected get leftButtonTitle() {
    return this.data.leftButtonTitle ?? 'Potvrdit';
  }

  protected get rightButtonTitle() {
    return this.data.rightButtonTitle ?? 'Storno';
  }

  protected close(result: boolean) {
    this.modalRef.close(result);
  }

}
