import {TransactionEventDto} from '|api/transactionlog';
import {FilterOperator, FilterParam, FilterParamTree, FilterTreeOperator, IczTableDataSource} from '@icz/angular-table';
import {TransactionLogSearchService} from '../../../services/transaction-log-search.service';

export interface TransactionsDataSourceParams {
  global: boolean;
  objectLookupParams?: {esslObjectType: string, entityId: number}
  uidLookupParams?: {idSource: string, identifier: string}
}

export class TransactionsDataSource extends IczTableDataSource<TransactionEventDto> {
  constructor(searchService: TransactionLogSearchService, params: TransactionsDataSourceParams) {
    super(searchParams => {
      if (!params.global) {
        const isUidFilterUsed = searchParams.filter.find(
          filterParam => filterParam.fieldName === 'entityIdentifier.uid'
        ) !== undefined;

        const entityIdAndObjectTypeFilterValues: FilterParam[] = [
          {
            fieldName: 'entityId',
            operator: FilterOperator.equals,
            value: String(params.objectLookupParams?.entityId),
          },
          {
            fieldName: 'esslObjectType',
            operator: FilterOperator.equals,
            value: String(params.objectLookupParams?.esslObjectType),
          },
        ];

        const complexUidFilter: FilterParamTree = {
          operator: FilterTreeOperator.OR,
          values: [
            {
              operator: FilterTreeOperator.AND,
              values: [
                {
                  fieldName: 'entityIdentifier.identifier',
                  operator: FilterOperator.equals,
                  value: String(params.uidLookupParams?.identifier),
                },
                {
                  fieldName: 'entityIdentifier.idSource',
                  operator: FilterOperator.equals,
                  value: String(params.uidLookupParams?.idSource),
                }
              ]
            },
            {
              operator: FilterTreeOperator.AND,
              values: [
                {
                  fieldName: 'parentIdentifier.identifier',
                  operator: FilterOperator.equals,
                  value: String(params.uidLookupParams?.identifier),
                },
                {
                  fieldName: 'parentIdentifier.idSource',
                  operator: FilterOperator.equals,
                  value: String(params.uidLookupParams?.idSource),
                }
              ]
            },
          ]
        };

        if (params.uidLookupParams && !isUidFilterUsed) {
          if (!searchParams.complexFilter) {
            // if all of idSource, uid, entityId and esslObjectType are provided, construct OR between entityUID, parentUID and entityID filter groups
            if (params.objectLookupParams) {
              searchParams.complexFilter = {
                operator: FilterTreeOperator.OR,
                values: [
                  {
                    operator: FilterTreeOperator.AND,
                    values: entityIdAndObjectTypeFilterValues
                  },
                  complexUidFilter
                ]
              };
            }
            // otherwise if only idSource and uid, construct just UID filter
            else {
              searchParams.complexFilter = complexUidFilter;
            }
          }
        }
        // if only entityId and esslObjectType, use simple filter
        else if (params.objectLookupParams) {
          searchParams.filter = [...searchParams.filter, ...entityIdAndObjectTypeFilterValues];
        }
      }

      searchParams.sort ??= [];

      if (!searchParams.sort.find(s => s.fieldName === 'id')) {
        searchParams.sort.push({
          fieldName: 'id',
          descending: true,
        });
      }

      return searchService.findTransactions(searchParams);
    });
  }
}
