/* tslint:disable */
/* eslint-disable */

/**
 * Storage unit filler state
 */
export enum StorageUnitFillerState {
  PLANNED = 'PLANNED',
  RUNNING = 'RUNNING',
  FINISHED = 'FINISHED',
  TESTED = 'TESTED'
}
