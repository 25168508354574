import {Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {AnalogCompositionType, AnalogProtectiveElement, EntityType, EsslComponentRelation} from '|api/commons';
import {
  ApiDigitalComponentService,
  ApiPaperComponentService,
  ConversionRegisterDto,
  DigitalComponentCompleteDto,
  DigitalComponentVersionCompleteDto,
  PaperComponentDto
} from '|api/component';
import {
  setDynamicControlsByAnalogComposition
} from '../../../analog-component-create-form/analog-component-create-form.component';
import {EsslComponentDto} from '../../../../../services/essl-component-search.service';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {isAnalogCompositionSheets} from '../../essl-components-utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';


@Component({
  selector: 'icz-advanced-essl-component-metadata',
  templateUrl: './advanced-essl-component-metadata.component.html',
  styleUrls: ['./advanced-essl-component-metadata.component.scss'],
})
export class AdvancedEsslComponentMetadataComponent implements OnInit {

  private apiDigitalComponentService = inject(ApiDigitalComponentService);
  private apiPaperComponentService = inject(ApiPaperComponentService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  esslComponent!: EsslComponentDto;
  @Input()
  digitalComponentVersion?: DigitalComponentVersionCompleteDto;
  @Input()
  conversionRegisters: Nullable<Array<ConversionRegisterDto>>;

  @Input({required: true})
  set form(newForm: IczFormGroup) {
    this._formGroup = newForm;
    if (this.isPaperComponent) {
      this._formGroup.get('numberOfSheets')?.addValidators([IczValidators.required(), IczValidators.isStringifiedInteger()]);
      this._formGroup.get('count')?.addValidators([IczValidators.required()]);
      this._formGroup.get('compositionType')?.addValidators([IczValidators.required()]);

      setDynamicControlsByAnalogComposition(newForm, this._formGroup.get('compositionType')!.value);
      this._formGroup.get('compositionType')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(compositionType => {
        setDynamicControlsByAnalogComposition(newForm, compositionType);
      });
    }
  }
  get form() {
    return this._formGroup;
  }

  _formGroup!: IczFormGroup;

  analogCompositionTypeOptions: IczOption[] = [];
  protectiveElementOptions: IczOption[] = [];
  originEsslComponent: Nullable<PaperComponentDto | DigitalComponentCompleteDto>;

  sourceContainerComponent: Nullable<EsslComponentDto>;
  sourceAnonymizationComponent: Nullable<EsslComponentDto>;

  get digitalComponent() {
    return this.esslComponent as DigitalComponentCompleteDto;
  }

  get paperComponent() {
    return this.esslComponent as PaperComponentDto;
  }

  get isDigitalComponent() {
    return this.esslComponent.entityType === EntityType.DIGITAL_COMPONENT;
  }

  get isPaperComponent() {
    return this.esslComponent.entityType === EntityType.PAPER_COMPONENT;
  }

  get isAnalogCompositionSheets() {
    return isAnalogCompositionSheets(this.form.get('compositionType')?.value);
  }

  get isComponentMainComponent(): boolean {
    return this.form.get('relationType')!.value === EsslComponentRelation.MAIN;
  }

  // todo 12160 expand text, can be not only authorized but also other types of conversion
  get authConversionInfoText(): string {
    return this.isDigitalComponent ? 'Tato komponenta vznikla autorizovanou konverzí z moci úřední z listinné do elektronické podoby z následující komponenty:' :
      'Tato komponenta vznikla autorizovanou konverzí z moci úřední z elektronické do listinné podoby z následující komponenty:';
  }

  get authorizedConversionExists(): boolean {
    return false;
    // return Boolean(this.digitalComponent.authConversionClause?.componentIdConverted);
  }

  get clauseExists(): boolean {
    return false;
    // return Boolean(this.digitalComponent.authConversionClause?.clauseId);
  }

  get conversionRegistersExists() {
    return Boolean(this.conversionRegisters?.length);
  }

  get esslComponentOrVersionDto() {
    if (this.isDigitalComponent) {
      return this.digitalComponentVersion!;
    } else {
      return this.esslComponent as EsslComponentDto;
    }
  }

  get hasDigitalComponentVersionMark() {
    return (this.digitalComponentVersion?.digitalComponentVersionSignatureFlags ?? []).find(flag => (flag.flag ?? '').includes('MARK'));
  }

  get hasDigitalComponentVersionTimestamp() {
    return (this.digitalComponentVersion?.digitalComponentVersionSignatureFlags ?? []).find(flag => (flag.flag ?? '').includes('TIMESTAMP'));
  }

  get isComponentRenditionByAnonymization() {
    return this.isDigitalComponent && this.digitalComponent?.parentAnonymizationDigitalComponentId;
  }

  ngOnInit() {
    this.analogCompositionTypeOptions = enumToOptions('analogCompositionType', AnalogCompositionType);
    this.protectiveElementOptions = enumToOptions('analogProtectiveElement', AnalogProtectiveElement);

    if (this.digitalComponent?.parentContainerDigitalComponentId) {
      this.apiDigitalComponentService.digitalComponentFindById({
        id: this.digitalComponent?.parentContainerDigitalComponentId!,
      }).subscribe(digitalComponent => {
        this.sourceContainerComponent = digitalComponent;
      });
    }
    // todo 12160 fetch source component from conversionRegistersAsTarget.sourceComponentId
    // if (this.digitalComponent?.authConversionClause?.componentIdConverted) {
    //   if (this.isDigitalComponent) {
    //     this.apiPaperComponentService.paperComponentFindById({id: this.digitalComponent?.authConversionClause?.componentIdConverted}).subscribe(paper => {
    //       this.originEsslComponent = paper;
    //     });
    //   } else if (this.isPaperComponent) {
    //     this.apiDigitalComponentService.digitalComponentFindById({id: this.paperComponent.authConversionClause?.componentIdConverted!}).subscribe(digital => {
    //       this.originEsslComponent = digital;
    //     });
    //   }
    // }

    if (this.digitalComponent?.parentAnonymizationDigitalComponentId) {
      this.apiDigitalComponentService.digitalComponentFindById({
        id: this.digitalComponent?.parentAnonymizationDigitalComponentId!,
      }).subscribe(digitalComponent => {
        this.sourceAnonymizationComponent = digitalComponent;
      });
    }
  }

}
