<div [waiting]="loadingIndicatorService.isLoading(this, loaderIdIsdsFind)">
  @if (loadingIndicatorService.isLoading(this, loaderIdIsdsFind)) {
    <icz-alert class="m-16"
      heading="Probíhá ověření všech dat o subjektu ze systému Datových schránek" severity="info">
    </icz-alert>
  }
  @if (loading) {
    <icz-spinner class="block text-center mt-64"></icz-spinner>
  }
  @else {
    <div>
      <div class="px-16 pt-16">
        <icz-radio-group>
          <div class="row gap-24 icz-body-1">
            @if (createNewConsignmentDialogType === CreateNewConsignmentDialogType.OFFICE_DESK_ONLY) {
              <icz-radio-button
                (change)="consigneeSelectionModeChanged(ConsigneeSelectionMode.OFFICE_DESK_PUBLIC_POSTING)"
                [checked]="consigneeSelectionMode === ConsigneeSelectionMode.OFFICE_DESK_PUBLIC_POSTING"
                [value]="ConsigneeSelectionMode.OFFICE_DESK_PUBLIC_POSTING"
                label="Veřejné vyvěšení"
              ></icz-radio-button>
            }
            <icz-radio-button
              (change)="consigneeSelectionModeChanged(ConsigneeSelectionMode.CONSIGNEE_SEARCH)"
              [checked]="consigneeSelectionMode === ConsigneeSelectionMode.CONSIGNEE_SEARCH"
              [value]="ConsigneeSelectionMode.CONSIGNEE_SEARCH"
              label="Vyhledat adresáta"
            ></icz-radio-button>
            <icz-radio-button
              (change)="consigneeSelectionModeChanged(ConsigneeSelectionMode.DOCUMENT_SUBJECTS)"
              [checked]="consigneeSelectionMode === ConsigneeSelectionMode.DOCUMENT_SUBJECTS"
              [label]="documentSubjectsCheckboxTitle"
              [value]="ConsigneeSelectionMode.DOCUMENT_SUBJECTS"
            ></icz-radio-button>
            <icz-radio-button
              (change)="consigneeSelectionModeChanged(ConsigneeSelectionMode.FILE_SUBJECTS)"
              [checked]="consigneeSelectionMode === ConsigneeSelectionMode.FILE_SUBJECTS"
              [label]="fileSubjectsCheckboxTitle"
              [value]="ConsigneeSelectionMode.FILE_SUBJECTS"
              [fieldDisabled]="!documentData.parentFileId"
            ></icz-radio-button>
            <icz-radio-button
              (change)="consigneeSelectionModeChanged(ConsigneeSelectionMode.DISTRIBUTION_LIST)"
              [checked]="consigneeSelectionMode === ConsigneeSelectionMode.DISTRIBUTION_LIST"
              [value]="ConsigneeSelectionMode.DISTRIBUTION_LIST"
              label="Vybrat adresáty z distribučního seznamu"
              testingFeature
            ></icz-radio-button>
          </div>
        </icz-radio-group>
      </div>
      @if (consigneeSelectionMode === ConsigneeSelectionMode.CONSIGNEE_SEARCH) {
        <icz-subject-search-with-results
          #subjectSearch
          (advanceToCreate)="consigneeCreationRequested.emit($event)"
          (subjectAddressChanged)="consigneeAddressChanged.emit($event)"
          (tableActionExecuted)="tableActionExecuted($event)"
          [allowCreateOnNotFound]="true"
          [allowShowLinkToStepCreate]="true"
          [searchOnlyIdentified]="true"
          [subjectToolbarContext]="SubjectToolbarContext.OVERVIEW"
          [createNewConsignmentDialogType]="createNewConsignmentDialogType"
          [objectId]="documentData.id"
          [relationType]="SubjectObjectRelationType.ADDRESSEE"
          [relatedObjectType]="RelatedObjectType.DOCUMENT"
          [resultTableColumnSet]="SubjectTableColumnSet.RESULT_SET_FOR_CONSIGNMENTS_SEARCH"
          [showInlineSearchButton]="false"
          [showSearchAppendix]="true"
          class="mx--24"
          tableViewId="consignee-selection-search"
        ></icz-subject-search-with-results>
      }
      @else if (consigneeSelectionMode === ConsigneeSelectionMode.DOCUMENT_SUBJECTS) {
        <icz-subjects-table
          (actionExecuted)="tableActionExecuted($event)"
          (reloadDataSource)="dataSourceReloaded()"
          (subjectAddressChanged)="consigneeAddressChanged.emit($event)"
          [columnSet]="SubjectTableColumnSet.RESULT_SET_FOR_CONSIGNMENTS_DOCUMENT_RELATED"
          [createNewConsignmentDialogType]="createNewConsignmentDialogType"
          [dataSource]="documentSubjectsDataSource!"
          class="w-full mt-16"
          tableId="consignee-selection-parent-document"
          tableTitle="Seznam subjektů přiřazených k dokumentu"
        ></icz-subjects-table>
      }
      @else if (consigneeSelectionMode === ConsigneeSelectionMode.FILE_SUBJECTS) {
        <icz-subjects-table
          (actionExecuted)="tableActionExecuted($event)"
          (reloadDataSource)="dataSourceReloaded()"
          (subjectAddressChanged)="consigneeAddressChanged.emit($event)"
          [columnSet]="SubjectTableColumnSet.RESULT_SET_FOR_CONSIGNMENTS_DOCUMENT_RELATED"
          [createNewConsignmentDialogType]="createNewConsignmentDialogType"
          [dataSource]="fileSubjectsDataSource!"
          class="w-full mt-16"
          tableId="consignee-selection-parent-document"
          tableTitle="Seznam subjektů přiřazených ke spisu"
        ></icz-subjects-table>
      }
      @else if (consigneeSelectionMode === ConsigneeSelectionMode.OFFICE_DESK_PUBLIC_POSTING) {
        <icz-alert
          class="mt-24"
          heading="Dokument bude vyvěšen na úřední desku bez přiřazení konkrétního adresáta.">
        </icz-alert>
      }
    </div>
  }
</div>
