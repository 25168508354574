import {Component, DestroyRef, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidators,
  ValidDateFnWithMessage
} from '@icz/angular-form-elements';
import {DeliveryResultConfirmationForm, MessageStatusAfterDelivery} from '|api/commons';
import {DeliveryResultDto} from '|api/codebook';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';

export function getDeliveryResultSpecificationFormGroup(isNoteDisabled = true) {
  return new IczFormGroup({
    deliveryResultId: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
    note: new IczFormControl<Nullable<string>>({value: null, disabled: isNoteDisabled}),
    acknowledgementDate: new IczFormControl<Nullable<string>>(new Date().toISOString(), [IczValidators.required()]),
    deliveryConfirmationForm: new IczFormControl<Nullable<string>>(null),
  });
}

@Component({
  selector: 'icz-manual-delivery-result-form',
  templateUrl: './manual-delivery-result-form.component.html',
  styleUrls: ['./manual-delivery-result-form.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class ManualDeliveryResultFormComponent implements OnInit, IczOnChanges {
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  deliveryResultOptions: IczOption[] = [];
  @Input({required: true})
  deliveryResults: DeliveryResultDto[] = [];
  @Input({required: true})
  isValidAcknowledgementDate!: ValidDateFnWithMessage;
  @Output()
  selectedDeliveryResultChanged = new EventEmitter<Nullable<DeliveryResultDto>>();

  selectedDeliveryResult: Nullable<DeliveryResultDto>;
  readonly DeliveryResultConfirmationForm = DeliveryResultConfirmationForm;

  getStatusAfterDeliveryTranslationKey(statusAfterDelivery: Nullable<MessageStatusAfterDelivery>) {
    return statusAfterDelivery ? ('en.messageStatusAfterDelivery.' + statusAfterDelivery) : '';
  }

  ngOnInit(): void {
    this.form.get('deliveryResultId')!.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(deliveryResultId => {
      this.processSelectedDeliveryResult(deliveryResultId);
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.form?.currentValue) {
      this.processSelectedDeliveryResult(this.form.get('deliveryResultId')!.value);
    }
  }

  processSelectedDeliveryResult(deliveryResultId: Nullable<number>) {
    if (this.deliveryResults && deliveryResultId) {
      this.selectedDeliveryResult = this.deliveryResults.find(dr => dr.id === deliveryResultId);
      this.form.get('note')!.setValue(this.selectedDeliveryResult!.name);
      this.selectedDeliveryResultChanged.emit(this.selectedDeliveryResult);
    }
  }
}
