import {DeliveryTypeDto} from '|api/codebook';
import {ConsignmentType, DistributionClass, NodeType, SubjectRecordWithRelationsDto} from '|api/commons';
import {
  OwnDigitalConsignmentDto,
  OwnDigitalConsignmentElasticDto,
  OwnDigitalConsignmentUpdateDto,
  OwnInternalDigitalConsignmentDto,
  OwnInternalDigitalConsignmentElasticDto,
  OwnInternalDigitalConsignmentUpdateDto,
  OwnInternalPaperConsignmentDto,
  OwnInternalPaperConsignmentElasticDto,
  OwnInternalPaperConsignmentUpdateDto,
  OwnOfficeDeskConsignmentDto,
  OwnOfficeDeskConsignmentElasticDto,
  OwnOfficeDeskConsignmentUpdateDto,
  OwnPaperConsignmentDto,
  OwnPaperConsignmentElasticDto,
  OwnPaperConsignmentUpdateDto, OwnPaperMultiConsignmentDto, OwnPaperMultiConsignmentElasticDto,
  OwnTelefaxConsignmentDto,
  OwnTelefaxConsignmentElasticDto
} from '|api/sad';
import {isValidNow} from '../../../../core/services/data-mapping.utils';
import {OwnConsignmentWorkflowAction} from '../../consignment-dialog/consignment-dialog/consignment.model';

export type GenericOwnConsignment = OwnPaperConsignmentDto | OwnPaperMultiConsignmentDto | OwnDigitalConsignmentDto | OwnOfficeDeskConsignmentDto | OwnInternalPaperConsignmentDto | OwnInternalDigitalConsignmentDto | OwnTelefaxConsignmentDto;

export type GenericOwnElasticConsignment = OwnPaperConsignmentElasticDto | OwnPaperMultiConsignmentElasticDto | OwnDigitalConsignmentElasticDto | OwnOfficeDeskConsignmentElasticDto | OwnInternalPaperConsignmentElasticDto | OwnInternalDigitalConsignmentElasticDto | OwnTelefaxConsignmentElasticDto;
export type GenericOwnElasticConsignmentWithConsignee = GenericOwnElasticConsignment & {consigneeDetail?: Nullable<SubjectRecordWithRelationsDto>};

export type GenericUpdateOwnConsignment = OwnPaperConsignmentUpdateDto | OwnDigitalConsignmentUpdateDto | OwnOfficeDeskConsignmentUpdateDto |
  OwnInternalPaperConsignmentUpdateDto | OwnInternalDigitalConsignmentUpdateDto;

export function isOfficeDeskConsignment(object: Nullable<GenericOwnConsignment>): object is OwnOfficeDeskConsignmentDto {
  return (object as GenericOwnConsignment)?.consignmentType === ConsignmentType.OFFICE_DESK;
}

export function getConsignmentPostingNumber(row: OwnPaperConsignmentDto) {
  if (!row.consignmentPostingNumber) return '';
  else {
    const cpn = row.consignmentPostingNumber;
    return `${cpn.prefix}${cpn.code}${cpn.suffix}`;
  }
}

export enum OwnConsignmentTableView {
  OFFICER_CREATE_CONSIGNMENTS = 'OFFICER_CREATE_CONSIGNMENTS',
  OFFICER_VIEW_IN_PROGRESS = 'OFFICER_VIEW_IN_PROGRESS',
  OFFICER_VIEW_ALL = 'OFFICER_VIEW_ALL',
  OFFICER_VIEW_REJECTED = 'OFFICER_VIEW_REJECTED',
  OFFICER_RECORD_DELIVERY_RESULT = 'OFFICER_RECORD_DELIVERY_RESULT',
  DISPATCH_OFFICER_VIEW_GENERIC = 'DISPATCH_OFFICER_VIEW_GENERIC',
  DISPATCH_OFFICER_VIEW_TO_TAKEOVER = 'DISPATCH_OFFICER_VIEW_TO_TAKEOVER',
  DISPATCH_OFFICER_VIEW_TO_DISPATCH = 'DISPATCH_OFFICER_VIEW_TO_DISPATCH',
  DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION = 'DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION',
  DISPATCH_OFFICER_VIEW_DISPATCHED = 'DISPATCH_OFFICER_VIEW_DISPATCHED',
  DISPATCH_OFFICER_RECORD_DELIVERY_RESULT = 'DISPATCH_OFFICER_RECORD_DELIVERY_RESULT',
  MULTI_CONSIGNMENT_CONTENT = 'MULTI_CONSIGNMENT_CONTENT',
}

// prefixed with OD = Office Desk to be unique from OwnConsignmentDispatchTableView when comparing values
export enum OwnConsignmentOfficeDeskTableView {
  OD_OFFICER_CREATE_OFFICE_DESK_CONSIGNMENTS = 'OD_OFFICER_CREATE_OFFICE_DESK_CONSIGNMENTS',
  OD_OFFICER_VIEW_IN_PROGRESS= 'OD_OFFICER_VIEW_IN_PROGRESS',
  OD_OFFICER_VIEW_ALL_POSTED = 'OD_OFFICER_VIEW_ALL_POSTED',
  OD_OFFICER_VIEW_REJECTED = 'OD_OFFICER_VIEW_REJECTED',
  OD_OFFICER_VIEW_ALL = 'OD_OFFICER_VIEW_ALL',
  OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER = 'OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER',
  OD_DISPATCH_OFFICER_VIEW_TO_POST = 'OD_DISPATCH_OFFICER_VIEW_TO_POST',
  OD_DISPATCH_OFFICER_VIEW_POSTED = 'OD_DISPATCH_OFFICER_VIEW_POSTED',
  OD_DISPATCH_OFFICER_VIEW_UNPOSTED = 'OD_DISPATCH_OFFICER_VIEW_UNPOSTED',
}

export function isForDispatchOfficerView(tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView) {
  if (!tableView) return false;
  return [
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_GENERIC,
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_TAKEOVER,
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_DISPATCHED,
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_TO_DISPATCH,
    OwnConsignmentTableView.DISPATCH_OFFICER_VIEW_IN_DISTRIBUTION,
    OwnConsignmentTableView.DISPATCH_OFFICER_RECORD_DELIVERY_RESULT,
    OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER,
    OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_POST,
    OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_POSTED,
    OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_UNPOSTED,
  ].includes(tableView);
}

export function isForOfficerView(tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView) {
  if (!tableView) return false;
  return [
    OwnConsignmentTableView.OFFICER_VIEW_ALL,
    OwnConsignmentTableView.OFFICER_VIEW_REJECTED,
    OwnConsignmentTableView.OFFICER_VIEW_IN_PROGRESS,
    OwnConsignmentTableView.OFFICER_RECORD_DELIVERY_RESULT,
    OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL,
    OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS,
    OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL_POSTED,
    OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED,
  ].includes(tableView);
}

export function isOfficeDeskView(tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView): tableView is OwnConsignmentOfficeDeskTableView {
  if (!tableView) return false;
  return [
    OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_TAKEOVER,
    OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_TO_POST,
    OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_POSTED,
    OwnConsignmentOfficeDeskTableView.OD_DISPATCH_OFFICER_VIEW_UNPOSTED,
    OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL,
    OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_IN_PROGRESS,
    OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_ALL_POSTED,
    OwnConsignmentOfficeDeskTableView.OD_OFFICER_VIEW_REJECTED,
  ].includes(tableView as OwnConsignmentOfficeDeskTableView);
}

export function isMultiConsignmentContentView(tableView: OwnConsignmentTableView | OwnConsignmentOfficeDeskTableView) {
  return tableView === OwnConsignmentTableView.MULTI_CONSIGNMENT_CONTENT;
}

export enum CreateNewConsignmentDialogType {
  ALLOW_ALL_DISPATCH_METHODS = 'ALLOW_ALL_DISPATCH_METHODS',
  OFFICE_DESK_ONLY = 'OFFICE_DESK_ONLY'
}

export enum ManualDeliveryResultRecordMode {
  BEFORE_CREATE = 'BEFORE_CREATE', // for dispatch outside filing office
  AFTER_CREATE = 'AFTER_CREATE', // for explicitly selected menu option in dispatch office/doc detail consignment table
}

export enum ManualDeliveryResultRecordAction {
  RECORD_DELIVERY_RESULT = 'RECORD_DELIVERY_RESULT',
  ADD_PROOF_OF_DELIVERY = 'ADD_PROOF_OF_DELIVERY',
}

export interface OwnConsignmentWorkflowActionResult {
  action: OwnConsignmentWorkflowAction;
  success: boolean;
}

export interface OwnConsignmentWorkflowActionOpts {
  validate?: boolean;
  saveFirst?: boolean;
}

export function convertOwnConsignmentToOwnElasticConsignment(consignment: GenericOwnConsignment): GenericOwnElasticConsignmentWithConsignee {
  return {
    ...consignment,
    document: {
      objectClass: isOwnOfficeDeskConsignment(consignment) ? (consignment as OwnOfficeDeskConsignmentDto).objectClass : null,
      ownerFunctionalPositionId: consignment.ownerFunctionalPositionId,
      ownerOrgUnitId: null,
      refNumber: consignment.refNumber,
      subject: consignment.documentSubject,
    },
    _entity_type: ''
  } as GenericOwnElasticConsignmentWithConsignee;
}

export function filterDeliveryTypesByDistributionClass(dt: DeliveryTypeDto[], distributionClass: DistributionClass ): DeliveryTypeDto[] {
  return dt.filter(dt => (dt.parentId))
    .filter(dt => dt.nodeType === NodeType.LEAF)
    .filter(dt => dt.distributionClass.distributionClassValue === distributionClass)
    .filter(dt => isValidNow(dt));
}

export function isOwnPaperConsignment(consignment: GenericOwnConsignment): consignment is OwnPaperConsignmentDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS ||
    consignment.consignmentType === ConsignmentType.OWN_PAPER_ON_PLACE
  );
}

export function isOwnMultiPaperConsignment(consignment: GenericOwnConsignment): consignment is OwnPaperConsignmentDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_PAPER_MULTI_ADDRESS);
}

export function isOwnInternalConsignment(consignment: GenericOwnConsignment): consignment is OwnInternalPaperConsignmentDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_PAPER_INTERNAL ||
    consignment.consignmentType === ConsignmentType.OWN_DIGITAL_INTERNAL
  );
}

export function isOwnDigitalConsignment(consignment: GenericOwnConsignment): consignment is OwnDigitalConsignmentDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX ||
    consignment.consignmentType === ConsignmentType.OWN_DIGITAL_EMAIL
  );
}

export function isOwnFaxConsignment(consignment: GenericOwnConsignment): consignment is OwnTelefaxConsignmentDto {
  return consignment.consignmentType === ConsignmentType.TELEFAX;
}

export function isOwnOfficeDeskConsignment(consignment: GenericOwnConsignment): consignment is OwnTelefaxConsignmentDto {
  return consignment.consignmentType === ConsignmentType.OFFICE_DESK;
}

export function getOutboundConsignmentIdentifier(consignment: Nullable<GenericOwnConsignment | GenericOwnElasticConsignmentWithConsignee>): Nullable<string> {
  return consignment?.outboundConsignmentIdentifier ?? '';
}

export function isOwnPaperElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnPaperConsignmentElasticDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS
  ) && !isNil(consignment.document);
}

export function isOwnPaperOrMultiPaperElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnPaperConsignmentElasticDto {
  return (
    (consignment.consignmentType === ConsignmentType.OWN_PAPER_ADDRESS && !isNil(consignment.document)) || consignment.consignmentType === ConsignmentType.OWN_PAPER_MULTI_ADDRESS);
}

export function isOwnMultiPaperElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnPaperMultiConsignmentElasticDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_PAPER_MULTI_ADDRESS
  );
}

export function isOwnPersonalElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnPaperConsignmentElasticDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_PAPER_ON_PLACE
  ) && !isNil(consignment.document);
}

export function isOwnInternalElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnInternalPaperConsignmentElasticDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_PAPER_INTERNAL ||
    consignment.consignmentType === ConsignmentType.OWN_DIGITAL_INTERNAL
  ) && !isNil(consignment.document);
}

export function isPartOfOwnMultiPaperElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnInternalPaperConsignmentElasticDto {
  return !isNil((consignment as Nullable<OwnPaperConsignmentElasticDto>)?.ownPaperMultiConsignmentId);
}

export function isOwnInternalPaperElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnInternalPaperConsignmentElasticDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_PAPER_INTERNAL
  ) && !isNil(consignment.document);
}

export function isOwnInternalDigitalElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnInternalPaperConsignmentElasticDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_DIGITAL_INTERNAL
  ) && !isNil(consignment.document);
}

export function isOwnPortalElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnDigitalConsignmentElasticDto {
  return (
    consignment.consignmentType === ConsignmentType.PORTAL
  ) && !isNil(consignment.document);
}

export function isOwnDigitalElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnDigitalConsignmentElasticDto {
  return (
    consignment.consignmentType === ConsignmentType.OWN_DIGITAL_DATABOX ||
    consignment.consignmentType === ConsignmentType.OWN_DIGITAL_EMAIL
  ) && !isNil(consignment.document);
}

export function isOwnFaxElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnTelefaxConsignmentElasticDto {
  return consignment.consignmentType === ConsignmentType.TELEFAX && !isNil(consignment.document);
}

export function isOwnOfficeDeskElasticConsignment(consignment: GenericOwnElasticConsignmentWithConsignee): consignment is OwnOfficeDeskConsignmentElasticDto {
  return consignment.consignmentType === ConsignmentType.OFFICE_DESK && !isNil(consignment.document);
}
