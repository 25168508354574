import {DateAdapter} from '@angular/material/core';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';


export function initDatepickerLocale(translateService: TranslateService, adapter: DateAdapter<Date>) {
  function changeFirstDayOfWeekBasedOnLanguage(lang: string) {
    adapter.getFirstDayOfWeek = () => (lang === 'en' ? 0 : 1);
  }

  const currentLanguage = translateService.currentLang;
  adapter.setLocale(currentLanguage);
  changeFirstDayOfWeekBasedOnLanguage(currentLanguage);

  translateService.onLangChange.pipe(
    map(event => event?.lang),
  ).subscribe((newLanguage: string) => {
    adapter.setLocale(newLanguage);
    changeFirstDayOfWeekBasedOnLanguage(newLanguage);
  });
}
