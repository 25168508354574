<icz-icon
  class="w-16"
  size="small"
  svgIcon="error"
  [hidden]="!errorDetails?.length"
  iczTooltip="Chyby zpracování"
  cdkOverlayOrigin
  #errorsPopupOrigin="cdkOverlayOrigin"
  (click.stop)="openErrorsPopup()"
></icz-icon>

<icz-popover
  [isOpen]="isErrorsPopupOpen"
  [overlayOrigin]="errorsPopupOrigin"
  (onClose)="closeErrorsPopup()"
>
  @for (errorDetail of errorDetails; track errorDetail.id) {
    <div class="icz-body-1 issue-item">
      <div class="icz-body-strong">{{ 'Identifikátor chyby' | translate }}:</div>
      <div>{{ errorDetail.eventId }}</div>
      <div class="icz-body-strong mt-4">{{ 'Čas chyby' | translate }}:</div>
      <div>{{ errorDetail.eventTime | localizedDatetime }}</div>
      <div class="icz-body-strong mt-4">{{ 'Popis' | translate }}:</div>
      <div>{{ errorDetail.errorMessage }}</div>
      <div class="icz-body-strong mt-4">{{ 'Identifikátor komponenty' | translate }}:</div>
      <div>{{ errorDetail.componentId }}</div>
    </div>
  }
</icz-popover>
