import {Directive, forwardRef, inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {PermissionSelectorComponent} from './permissions/permission-selector/permission-selector.component';
import {
  EpdzAttachmentSubsetSelectorComponent
} from './epdz-attachment-subset-selector/epdz-attachment-subset-selector.component';
import {SubjectAddressSelectorComponent} from './subjects/subject-address-selector/subject-address-selector.component';
import {FormMoneyInputComponent} from './form-money-input/form-money-input.component';
import {LegacyGenericValueAccessor} from '../../legacy-generic-value-accessor';


@Directive({
  // the + ',' + are intentional because the commas are hard
  // to read and thus the selector is prone to hard-to-spot mistakes

  selector: 'icz-subject-address-selector[formControlName]' + ',' +
    'icz-form-money-input[formControlName]' + ',' +
    'icz-epdz-attachment-subset-selector[formControlName]' + ',' +
    'icz-permission-selector[formControlName]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SharedBusinessFieldValueAccessorDirective),
    multi: true
  }],
})
export class SharedBusinessFieldValueAccessorDirective extends LegacyGenericValueAccessor {

  private subjectAddressSelectorComponent = inject(SubjectAddressSelectorComponent, {optional: true});
  private formMoneyInputComponent = inject(FormMoneyInputComponent, {optional: true});
  private componentSubsetSelectorComponent = inject(EpdzAttachmentSubsetSelectorComponent, {optional: true});
  private permissionSelectorComponent = inject(PermissionSelectorComponent, {optional: true});

  @Input()
  override formControlName!: string;

  protected override component = (this.subjectAddressSelectorComponent ||
    this.formMoneyInputComponent || this.componentSubsetSelectorComponent || this.permissionSelectorComponent)!;

}
