import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {EntityType} from '|api/commons';
import {InternalNotificationKey} from '|api/notification';
import {
  getToastHeaderTemplateTranslateKey,
  MessageType,
  SErrorParameters,
  SImplicitlyStringifiable,
  ToastService
} from '../../../components/notifications/toast.service';
import {CirculationBulkTaskData, SCirculationBulkTaskData} from './circulation-toast.service';


export enum EsslComponentCirculationToastType {
  ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR_ERROR = 'ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR_ERROR',
  ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT = 'ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT',
  ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR = 'ESSL_COMPONENT_CIRCULATION_SIGN_TARGET_PARTICIPANT_ERROR',
  ESSL_COMPONENT_CIRCULATION_SIGN_REVOKE_TARGET_PARTICIPANT = 'ESSL_COMPONENT_CIRCULATION_SIGN_REVOKE_TARGET_PARTICIPANT',
  ESSL_COMPONENT_CIRCULATION_SIGN_REJECT_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_SIGN_REJECT_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_TASK_REJECT_TARGET_PARTICIPANT = 'ESSL_COMPONENT_CIRCULATION_TASK_REJECT_TARGET_PARTICIPANT',
  ESSL_COMPONENT_CIRCULATION_SIGN_ERROR = 'ESSL_COMPONENT_CIRCULATION_SIGN_ERROR',
  ESSL_COMPONENT_CIRCULATION_SIGN_INVALID = 'ESSL_COMPONENT_CIRCULATION_SIGN_INVALID',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR_ERROR = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR_ERROR',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_TARGET_PARTICIPANT = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_TARGET_PARTICIPANT',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_REVOKE_TARGET_PARTICIPANT = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_REVOKE_TARGET_PARTICIPANT',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_TARGET_PARTICIPANT = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_TARGET_PARTICIPANT',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_ERROR = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_ERROR',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_REJECTED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_REJECTED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_PASSED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_PASSED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_TASK_SUCCESS_TARGET_PARTICIPANT = 'ESSL_COMPONENT_CIRCULATION_TASK_SUCCESS_TARGET_PARTICIPANT',
  ESSL_COMPONENT_CIRCULATION_TASK_PASSED_TARGET_PARTICIPANT = 'ESSL_COMPONENT_CIRCULATION_TASK_PASSED_TARGET_PARTICIPANT',
  ESSL_COMPONENT_CIRCULATION_TASK_REVOKED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_TASK_REVOKED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_BULK_SIGN_ASSIGNED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_BULK_SIGN_ASSIGNED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_BULK_APPROVAL_ASSIGNED_INITIATOR = 'ESSL_COMPONENT_CIRCULATION_BULK_APPROVAL_ASSIGNED_INITIATOR',
  ESSL_COMPONENT_CIRCULATION_APPROVAL_BY_TARGET_ERROR = 'ESSL_COMPONENT_CIRCULATION_APPROVAL_BY_TARGET_ERROR',
  ESSL_COMPONENT_CIRCULATION_DISAPPROVAL_BY_TARGET_ERROR = 'ESSL_COMPONENT_CIRCULATION_DISAPPROVAL_BY_TARGET_ERROR',
  ESSL_COMPONENT_BULK_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT = 'ESSL_COMPONENT_BULK_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT',
}

export const SCirculatedEsslComponentData = z.object({
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
  [InternalNotificationKey.ESSL_COMPONENT_LABEL]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ESSL_COMPONENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: z.nullable(z.optional(SImplicitlyStringifiable)),
  [InternalNotificationKey.ACTIVITY_ID]: SImplicitlyStringifiable,
});
export type CirculatedEsslComponentData = z.infer<typeof SCirculatedEsslComponentData>;

export const SCirculatedEsslComponentErrorData = z.object({
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
  [InternalNotificationKey.ESSL_COMPONENT_LABEL]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ESSL_COMPONENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: z.nullable(z.optional(SImplicitlyStringifiable)),
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type CirculatedEsslComponentErrorData = z.infer<typeof SCirculatedEsslComponentErrorData>;

export const SEsslComponentSignSuccessInitiatorData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  }),
);
export type EsslComponentSignSuccessInitiatorData = z.infer<typeof SEsslComponentSignSuccessInitiatorData>;

export const SEsslComponentSignRevokeTargetParticipantData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.INITIATOR_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.INITIATOR_FP_CODE]: SImplicitlyStringifiable,
  })
);
export type EsslComponentSignRevokeTargetParticipantData = z.infer<typeof SEsslComponentSignRevokeTargetParticipantData>;

export const SEsslComponentSignRejectInitiatorData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  })
);
export type EsslComponentSignRejectInitiatorData = z.infer<typeof SEsslComponentSignRejectInitiatorData>;

export const SEsslComponentSignErrorData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  })
);
export type EsslComponentSignErrorData = z.infer<typeof SEsslComponentSignErrorData>;

export const SEsslComponentSignInvalidData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  })
);
export type EsslComponentSignInvalidData = z.infer<typeof SEsslComponentSignInvalidData>;

export const SEsslComponentApprovalApprovedInitiatorData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  })
);
export type EsslComponentApprovalApprovedInitiatorData = z.infer<typeof SEsslComponentApprovalApprovedInitiatorData>;

export const SEsslComponentApprovalRevokeTargetParticipantData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.INITIATOR_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.INITIATOR_FP_CODE]: SImplicitlyStringifiable,
    [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  })
);
export type EsslComponentApprovalRevokeTargetParticipantData = z.infer<typeof SEsslComponentApprovalRevokeTargetParticipantData>;

export const SEsslComponentApprovalDisapprovedInitiatorData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  })
);
export type EsslComponentApprovalDisapprovedInitiatorData = z.infer<typeof SEsslComponentApprovalDisapprovedInitiatorData>;

export const SEsslComponentApprovalRejectInitiatorData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  })
);
export type EsslComponentApprovalRejectInitiatorData = z.infer<typeof SEsslComponentApprovalRejectInitiatorData>;

export const SEsslComponentApprovalPassInitiatorData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
    [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  })
);
export type EsslComponentApprovalPassInitiatorData = z.infer<typeof SEsslComponentApprovalPassInitiatorData>;

export const SEsslComponentApprovalErrorData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  })
);
export type EsslComponentApprovalErrorData = z.infer<typeof SEsslComponentApprovalErrorData>;

export const SEsslComponentTaskSuccessData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  })
);
export type EsslComponentTaskSuccessData = z.infer<typeof SEsslComponentTaskSuccessData>;

export const SEsslComponentTaskRejectData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  })
);
export type EsslComponentTaskRejectData = z.infer<typeof SEsslComponentTaskRejectData>;

export const SEsslComponentTaskPassedData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  })
);
export type EsslComponentTaskPassedData = z.infer<typeof SEsslComponentTaskPassedData>;

export const SEsslComponentTaskRevokedData = z.intersection(
  SCirculatedEsslComponentData,
  z.object({
    [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  })
);
export type EsslComponentTaskRevokedData = z.infer<typeof SEsslComponentTaskRevokedData>;

export const SEsslActivityErrorData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});

export const SBulkCirculatedEsslComponentData = z.object({
  [InternalNotificationKey.ESSL_COMPONENT_LABEL]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ESSL_COMPONENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DIGITAL_COMPONENT_VERSION_ID]: z.optional(SImplicitlyStringifiable),
});
export type BulkCirculatedEsslComponentData = z.infer<typeof SBulkCirculatedEsslComponentData>;

export function getEsslComponentToastTemplateType(toastType: EsslComponentCirculationToastType, entityType: string): string {
  if (entityType === EntityType.DIGITAL_COMPONENT) {
    return `${toastType}_DIGITAL`;
  }
  else {
    return `${toastType}_NONDIGITAL`;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EsslComponentCirculationToastService {

  private toastService = inject(ToastService);

  dispatchEsslComponentSignAssignedInitiator(templateData: CirculatedEsslComponentData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_ASSIGNED_INITIATOR, templateData),
      templateData,
      SCirculatedEsslComponentData
    );
  }

  dispatchEsslComponentSignSuccessInitiator(templateData: EsslComponentSignSuccessInitiatorData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_INITIATOR, templateData),
      templateData,
      SEsslComponentSignSuccessInitiatorData
    );
  }

  dispatchEsslComponentSignSuccessTargetParticipant(templateData: CirculatedEsslComponentData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_SUCCESS_TARGET_PARTICIPANT, templateData),
      templateData,
      SCirculatedEsslComponentData
    );
  }

  dispatchEsslComponentSignRevokeTargetParticipant(templateData: EsslComponentSignRevokeTargetParticipantData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_REVOKE_TARGET_PARTICIPANT, templateData),
      templateData,
      SEsslComponentSignRevokeTargetParticipantData
    );
  }

  dispatchEsslComponentSignRejectInitiator(templateData: EsslComponentSignRejectInitiatorData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_REJECT_INITIATOR, templateData),
      templateData,
      SEsslComponentSignRejectInitiatorData
    );
  }

  dispatchEsslComponentTaskRejectTargetParticipant(templateData: CirculatedEsslComponentData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_TASK_REJECT_TARGET_PARTICIPANT, templateData),
      templateData,
      SCirculatedEsslComponentData
    );
  }

  dispatchEsslComponentSignError(templateData: EsslComponentSignErrorData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_ERROR, templateData),
      templateData,
      SEsslComponentSignErrorData
    );
  }

  dispatchEsslComponentSignInvalid(templateData: EsslComponentSignInvalidData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_SIGN_INVALID, templateData),
      templateData,
      SEsslComponentSignInvalidData
    );
  }

  dispatchEsslComponentApprovalAssignedInitiator(templateData: CirculatedEsslComponentData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_ASSIGNED_INITIATOR, templateData),
      templateData,
      SCirculatedEsslComponentData
    );
  }

  dispatchEsslComponentApprovalApprovedInitiator(templateData: EsslComponentApprovalApprovedInitiatorData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_INITIATOR, templateData),
      templateData,
      SEsslComponentApprovalApprovedInitiatorData
    );
  }

  dispatchEsslComponentApprovalApprovedTargetParticipant(templateData: CirculatedEsslComponentData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_APPROVED_TARGET_PARTICIPANT, templateData),
      templateData,
      SCirculatedEsslComponentData
    );
  }

  dispatchEsslComponentApprovalRejectedInitiator(templateData: EsslComponentApprovalRejectInitiatorData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_REJECTED_INITIATOR, templateData),
      templateData,
      SEsslComponentApprovalRejectInitiatorData
    );
  }

  dispatchEsslComponentApprovalPassedInitiator(templateData: EsslComponentApprovalPassInitiatorData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_PASSED_INITIATOR, templateData),
      templateData,
      SEsslComponentApprovalPassInitiatorData
    );
  }

  dispatchEsslComponentTaskPassedTargetParticipant(templateData: EsslComponentTaskPassedData) {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_TASK_PASSED_TARGET_PARTICIPANT, templateData),
      templateData,
      SEsslComponentTaskPassedData
    );
  }

  dispatchEsslComponentApprovalRevokeTargetParticipant(templateData: EsslComponentApprovalRevokeTargetParticipantData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_REVOKE_TARGET_PARTICIPANT, templateData),
      templateData,
      SEsslComponentApprovalRevokeTargetParticipantData
    );
  }

  dispatchEsslComponentApprovalDisapprovedInitiator(templateData: EsslComponentApprovalDisapprovedInitiatorData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_INITIATOR, templateData),
      templateData,
      SEsslComponentApprovalDisapprovedInitiatorData
    );
  }

  dispatchEsslComponentApprovalDisapprovedTargetParticipant(templateData: CirculatedEsslComponentData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_DISAPPROVED_TARGET_PARTICIPANT, templateData),
      templateData,
      SCirculatedEsslComponentData
    );
  }

  dispatchEsslComponentApprovalError(templateData: EsslComponentApprovalErrorData): void {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_APPROVAL_ERROR, templateData),
      templateData,
      SEsslComponentApprovalErrorData
    );
  }

  dispatchTaskSuccessTargetParticipant(templateData: EsslComponentTaskRejectData) {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_TASK_SUCCESS_TARGET_PARTICIPANT, templateData),
      templateData,
      SEsslComponentTaskRejectData
    );
  }

  dispatchEsslComponentRevokeInitiator(templateData: EsslComponentTaskRevokedData) {
    this.toastService.dispatchSimpleToast(
      MessageType.INFO,
      this.getToastTemplateType(EsslComponentCirculationToastType.ESSL_COMPONENT_CIRCULATION_TASK_REVOKED_INITIATOR, templateData),
      templateData,
      SEsslComponentTaskRevokedData
    );
  }

  private getToastTemplateType(toastType: EsslComponentCirculationToastType, componentData: CirculatedEsslComponentData): string {
    return getEsslComponentToastTemplateType(toastType, String(componentData[InternalNotificationKey.ENTITY_TYPE]));
  }

  dispatchBulkTaskToast(templateData: CirculationBulkTaskData, toastType: EsslComponentCirculationToastType) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(toastType),
          templateData,
          templateDataSchema: SCirculationBulkTaskData,
        },
      },
    });
  }
}
