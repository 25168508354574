/* eslint-disable @angular-eslint/directive-class-suffix */
import {Directive, EventEmitter, Input, Output, TemplateRef} from '@angular/core';
import {PrimitiveValueFormField} from './abstract-form-field';
import {IAutocompleteListItemContext, IImplicitTemplateContext} from '../form-autocomplete/form-autocomplete.model';
import {isRequired} from '../validators/icz-validators/icz-validators';
import {getOptionsByValuesList, IczOption, locateOptionByValue} from '../form-elements.model';
import {hasDuplicates} from '../form-elements.utils';

/**
 * An abstract class used as a base for implementing <select>-like form elements.
 * Supports singleselect and multiselect and is resistant to various race
 * conditions such as when options load later than value and vice versa.
 *
 * Responsibilities:
 * - Declaring a basic set of @Inputs and @Outputs for particular subclasses
 * - Converting the internal representation to FormGroup data
 * - Implementing the interface needed by FormFieldValue accessor by defining _valueChange
 */
@Directive()
export abstract class AbstractSelectField<T extends string|number> extends PrimitiveValueFormField<T[]|T> {

  /**
   * Options to be chosen from.
   * @see IczOption
   */
  @Input({required: true})
  set options(newOptions: Nullable<IczOption<Nullable<T>, any>[]>) {
    newOptions ??= [];

    if (hasDuplicates(newOptions, o => o.value)) {
      console.warn(
        `[formControlName=${this.formControlName}] Options array contains duplicate option values. ` +
        `Please pass in an array without such duplicates otherwise Autocomplete might not work reliably.`,
        newOptions
      );
    }

    this._options = newOptions;
    this._visibleOptions = newOptions.filter(o => !o.isHidden);

    if (this.isMultiselect) {
      this.__value = this.searchForMultiselectOptions(this._value as T[]);
    }
    else {
      this.__value = this.searchForSingleselectOptions(this._value as T);
    }
    if (this.allowSingleOptionPreselection) {
      this._valueChanged(this.__value);
    }
  }
  get options(): IczOption<Nullable<T>, any>[] {
    return this._options;
  }
  /**
   * If true, displays a trash icon used for clearing field value.
   */
  @Input()
  clearable = true;
  /**
   * If true, will render selection interface of the field (e.g. a list or a tree) inside an openable popover.
   * Else will render the selection interface permanently visible below search field.
   */
  @Input()
  asPopover = true;
  /**
   * If true, the field will allow multiple values to be selected. Multivalue lists will be rendered as chips.
   *
   * IMPORTANT:
   * - Multivalues are always arrays (Array<T>),
   * - Singlevalues are always primitive (T),
   *
   * so if the field gets an array value in singleselect mode or vice versa, the field will emit a warning and might not render anything.
   */
  @Input()
  isMultiselect: Nullable<boolean> = false;
  /**
   * If the options which arrive to this component have length = 1 and this input
   * is true, the sole option item will automatically get preselected.
   */
  @Input()
  allowSingleOptionPreselection = false;
  /**
   * Prefix of the internal search field.
   */
  @Input()
  searchTermInputPrefix = '';
  /**
   * Origin ID. If defined, the selector will work only with options with the given origin ID.
   * @see IczOption.originId
   */
  @Input()
  originId: Nullable<string>;
  /**
   * Custom option template, rendered in options list.
   */
  @Input()
  customOptionTemplate: Nullable<TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>>;
  /**
   * Custom option template, rendered in field value field or inside chips if isMultiselect = true.
   */
  @Input()
  customSelectedOptionTemplate: Nullable<TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>>;
  /**
   * @deprecated - we might remove this in the future.
   */
  @Output()
  valueChangeWithOriginIds = new EventEmitter<Nullable<IczOption<Nullable<T>, any>[] | IczOption<Nullable<T>, any>>>();

  /**
   * Field value.
   */
  @Input()
  override set value(newValues: Nullable<T[]|T>) {
    this._value = newValues;

    if (this.options) {
      if (Array.isArray(newValues) && this.isMultiselect) {
        this.__value = this.searchForMultiselectOptions(newValues);
      }
      else if (!Array.isArray(newValues) && newValues === null && this.isMultiselect) {
        this.__value = [];
      }
      else if (!Array.isArray(newValues) && !this.isMultiselect) {
        this.__value = this.searchForSingleselectOptions(newValues);
      }
      else {
        throw new Error(`ComposableFormAutocomplete received an unexpected` +
          `value: ${newValues} for multiselect=${this.isMultiselect}.`);
      }
    }
  }
  override get value(): Nullable<T[]|T> {
    return this._value;
  }

  protected get required(): boolean {
    return isRequired(this.control);
  }

  protected _options!: IczOption<Nullable<T>, any>[];
  /**
   * User is able to select/search thru visible options only.
   */
  protected _visibleOptions!: IczOption<Nullable<T>, any>[];
  /**
   * This value is intrinsic array of option objects that get passed to inner components of the autocomplete.
   */
  protected __value: IczOption<Nullable<T>, any>[] = [];
  /**
   * This value is synchronized with the value of associated FormControl object.
   */
  override _value: Nullable<T[]|T> = null;
  private _valueWithOriginIds: Nullable<IczOption<Nullable<T>, any>[] | IczOption<Nullable<T>, any>> = null;

  protected override _valueChanged(selectedOptions: IczOption<Nullable<T>, any>[]) {
    let selectedOptionIds;

    // if originId is present, every option should have its 'id' property.
    if (this.originId) {
      selectedOptions = selectedOptions.filter(o => {
        if (!o.originId) {
          console.warn(
            `[formControlName=${this.formControlName}] You have passed originId to the component but originIds of your Options are undefined. ` +
            `This is an inconsistent state, if passing @Input originId, every Option should have id AND originId.`
          );

          return false;
        }

        return o.originId === this.originId;
      });
      selectedOptionIds = selectedOptions.map(o => {
        if (!o.id) {
          console.warn(
            `[formControlName=${this.formControlName}] You have passed originId to the component but ids of your Options are undefined. ` +
            `This is an inconsistent state, if passing @Input originId, every Option should have id AND originId.`
          );
        }

        return o.id!;
      });
    }
    else {
      selectedOptionIds = selectedOptions.map(o => o.value!);
    }

    if (!selectedOptionIds.length) {
      this.__value = [];
      this._value = null;
      this._valueWithOriginIds = null;
    }
    else if (selectedOptionIds.length && !this.isMultiselect) {
      this.__value = [selectedOptions[0]];
      this._value = selectedOptionIds[0] as Nullable<T>;
      this._valueWithOriginIds = selectedOptions[0];
    }
    else {
      this.__value = selectedOptions;
      this._value = selectedOptionIds as T[];
      this._valueWithOriginIds = selectedOptions;
    }

    this.emitFieldValue();
  }

  protected emitFieldValue() {
    this.valueChange.emit(this._value);
    this.valueChangeWithOriginIds.emit(this._valueWithOriginIds);
  }

  protected searchForMultiselectOptions(newValues: Nullable<T[]>): IczOption<Nullable<T>, any>[] {
    if (this.options && newValues) {
      return getOptionsByValuesList(this.options, newValues, this.originId);
    }
    else {
      return [];
    }
  }

  protected searchForSingleselectOptions(newValue: Nullable<T>): IczOption<Nullable<T>, any>[] {
    if (this.options) {
      if (this.options.length === 1 && isNil(newValue) && isRequired(this.control) && this.allowSingleOptionPreselection) {
        return this.options;
      } else {
        const option = locateOptionByValue(this.options, newValue, this.originId);

        if (!option) {
          if (!isNil(newValue)) {
            // tslint:disable-next-line:no-console
            console.warn(`[formControlName=${this.formControlName}] Model value "${newValue}" is not present in options of the autocomplete.`);
          }

          return [];
        }
        else {
          return [option];
        }
      }
    }
    else {
      return [];
    }
  }

  protected deselectOption(so: IczOption<Nullable<T>>) {
    const elementToRemove = this.__value.find(option => option.value === so.value);

    if (elementToRemove) {
      this.optionDeselector(elementToRemove);
      this.__value = [...this.__value];

      this._valueChanged(this.__value);
    }
  }

  /**
   * Template method used for customizing element deselection process.
   * To override when removing a multiselect badge of inner tree node for example.
   */
  protected optionDeselector(optionToDeselect: IczOption<Nullable<T>, any>) {
    this.__value = AbstractSelectField.deselectOption(this.__value, optionToDeselect);
  }

  protected static deselectOption<T>(options: IczOption<Nullable<T>, any>[], optionToDeselect: IczOption<Nullable<T>, any>, ...extraOptions: any[]): IczOption<Nullable<T>, any>[] {
    return options.filter(o => o.value !== optionToDeselect.value);
  }
}
