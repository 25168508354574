import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DeliveryServiceBasicDto, DistributionClassDto} from '|api/codebook';
import {DistributionClass} from '|api/commons';
import {CodebookService} from '../../../core/services/codebook.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {ApplicationConfigService} from '../../../core/services/config/application-config.service';
import {IczDateValidators, IczFormGroup, IczOption, locateOptionByValue} from '@icz/angular-form-elements';
import {DeliveryTypeOption, deliveryTypesToOption} from '../../../core/services/data-mapping.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {ReceivedDocumentUsage} from '../received-consignments.model';
import {ReceivedPaperConsignmentCreateDto} from '|api/sad';

@Component({
  selector: 'icz-receive-paper-consignment-set-dispatch',
  templateUrl: './receive-paper-consignment-set-dispatch.component.html',
  styleUrls: ['./receive-paper-consignment-set-dispatch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReceivePaperConsignmentSetDispatchComponent implements OnInit {

  protected codebookService = inject(CodebookService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  applicationConfigService = inject(ApplicationConfigService);
  private destroyRef = inject(DestroyRef);
  private cd = inject(ChangeDetectorRef);

  @Input({required: true}) form!: IczFormGroup; // is expected to be ReceivePaperConsignmentFormGroup
  @Input({required: true}) receivedDocumentUsage!: ReceivedDocumentUsage;
  @Output() distributionClassDtoChanged = new EventEmitter<DistributionClassDto>();

  get isConsignmentRecommended() {
    return Boolean(this.form.get('recommended')?.value);
  }

  get isCreateFilingOfficePaper() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_PAPER_CONSIGNMENT;
  }

  get isRegisterContentFilingOffice() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_FILING_OFFICE;
  }

  get isRegisterContentOfficer() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.REGISTER_CONTENT_OFFICER;
  }

  get isCreateFilingOfficeInternal() {
    return this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_INTERNAL_CONSIGNMENT;
  }

  get isDistributionClassPostOffice() {
    return this.selectedDistributionClass?.distributionClassValue === DistributionClass.ADDRESS_PLACE;
  }

  get isDistributionClassPostOfficeOrPersonal() {
    return this.selectedDistributionClass?.distributionClassValue === DistributionClass.ADDRESS_PLACE ||
      this.selectedDistributionClass?.distributionClassValue === DistributionClass.ON_PLACE;
  }

  get showExternalId() {
    return (this.isCreateFilingOfficePaper && this.applicationConfigService.consignmentExternalIdEnabled) ||
      this.isRegisterAnyContent;
  }
  get isRegisterAnyContent() {
    return this.isRegisterContentFilingOffice || this.isRegisterContentOfficer;
  }

  deliveryTypeOptions$!: Observable<DeliveryTypeOption[]>;
  deliveryOptions!: DeliveryTypeOption[];
  selectedDistributionClass!: DistributionClassDto;

  basicServiceWithDistributorOptions: IczOption[] = [];
  basicServices: DeliveryServiceBasicDto[] = [];

  isValidDeliveryDate = IczDateValidators.pastOrPresentDateValidator;

  ngOnInit(): void {
    const allDeliveryTypesForDelivery$ = this.codebookService.deliveryTypes().pipe(deliveryTypesToOption);

    if (this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_OFFICER_FULL_DOCUMENT || this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_PERSONAL_CONSIGNMENT) {
      this.deliveryTypeOptions$ = allDeliveryTypesForDelivery$.pipe(map(types => types.filter(t => t.data!.distributionClass.distributionClassValue === DistributionClass.ON_PLACE)));
    } else if (this.receivedDocumentUsage === ReceivedDocumentUsage.CREATE_FILING_OFFICE_PAPER_CONSIGNMENT) {
      this.deliveryTypeOptions$ = allDeliveryTypesForDelivery$.pipe(map(types => types.filter(t => t.data!.distributionClass.distributionClassValue === DistributionClass.ADDRESS_PLACE)));
    } else {
      this.deliveryTypeOptions$ = allDeliveryTypesForDelivery$;
    }
    if (this.isRegisterAnyContent) {
      const toDisable: (keyof ReceivedPaperConsignmentCreateDto)[] = ['deliveryTypeId', 'basicServiceId', 'postingNumber', 'senderPostOffice', 'dispatchDate',
      'deliveryDate', 'deliveryTime', 'externalId'];
      toDisable.forEach(field => {
        this.form.get(field)!.disable();
      });

    }
    this.deliveryTypeOptions$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      options => {
        this.deliveryOptions = options;
        this.selectDistributionClass(this.form.get('deliveryTypeId')?.value);
        this.selectBasicServiceOptions(this.form.get('deliveryTypeId')?.value);
      }
    );
    this.form.get('deliveryTypeId')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((change: number) => {
      this.selectDistributionClass(change);
      this.selectBasicServiceOptions(change);
    });

    this.codebookService.deliveryServiceBasics().subscribe(services => {
      this.basicServices = services;
      this.selectBasicServiceOptions(this.form.get('deliveryTypeId')?.value);
    });

    this.form.get('basicServiceId')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(basicServiceId => {
      const selectedService = this.basicServices.find(service => service.id === basicServiceId);
      if (selectedService) {
        this.form.get('recommended')?.setValue(selectedService.onlyDeliversAgainstConfirmation);
      }
    });
  }

  selectBasicServiceOptions(deliveryTypeId: Nullable<number>) {
    if (!this.isCreateFilingOfficeInternal) {
      this.form.get('basicServiceId')?.setValue(null);
    }
    if (deliveryTypeId) {
      this.basicServiceWithDistributorOptions =
        this.basicServices.filter(s => s.deliveryTypeId === deliveryTypeId)
          .map(s => ({value: s.id, label: `${s.name} - ${s.distributor.description}`}));
      this.cd.detectChanges();
    }
  }

  selectDistributionClass(id: number) {
    const selectedOption = locateOptionByValue(this.deliveryOptions, id);
    if (selectedOption) {
      this.selectedDistributionClass = selectedOption.data!.distributionClass as DistributionClassDto;
      this.distributionClassDtoChanged.emit(this.selectedDistributionClass);
    }
  }

}
