import {Pipe, PipeTransform} from '@angular/core';
import {IczOption, locateOptionByValue} from '../form-elements.model';

/**
 * A pipe which looks up an option in options list using locateOptionByValue
 * algorithm and renders the label of the option.
 *
 * @see locateOptionByValue
 *
 * Note: This pipe assumes that its input array is not mutated, thus is
 * suitable for example in tables where a value is looked up in list.
 */
@Pipe({
  name: 'findInList',
  pure: true, // for each pair (immutable array, value) it will produce the same result
  standalone: true,
})
export class FindInListPipe implements PipeTransform {

  transform(optionValue: Nullable<string|number|Array<string|number>>, array: Array<IczOption>, originId?: string): string {
    if (isNil(optionValue)) {
      return '';
    }
    else {
      if (!Array.isArray(optionValue)) return locateOptionByValue(array, optionValue, originId)?.label ?? '';
      else return optionValue.map(o => locateOptionByValue(array, o, originId)?.label ?? '').join(', ');
    }
  }

}
