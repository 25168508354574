import {forkJoin, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {IczFormGroup} from '@icz/angular-form-elements';
import {CodebookService} from '../core/services/codebook.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DestroyRef} from '@angular/core';


export function initSecurityCategoryPreselectionByEntityClass(this: {form: IczFormGroup, codebookService: CodebookService, destroyRef: DestroyRef}) {
  this.form.get('entityClassId')!.valueChanges.pipe(
    switchMap(entityClassId => forkJoin([
      of(entityClassId),
      this.codebookService.entityClasses(),
    ])),
    takeUntilDestroyed(this.destroyRef),
  ).subscribe(([entityClassId, entityClasses]) => {
    const securityCategoryControl = this.form.get('securityCategoryId')!;

    const selectedEntityClass = entityClasses.find(ec => ec.id === entityClassId);

    if (selectedEntityClass) {
      const relatedSecurityCategoryId = selectedEntityClass.securityCategoryId;

      if (relatedSecurityCategoryId) {
        securityCategoryControl.setValue(relatedSecurityCategoryId);
      }
    }
  });
}

export function initEntityClassPreselectionByDocumentType(this: {form: IczFormGroup, codebookService: CodebookService, destroyRef: DestroyRef, enableEntityClassPrefill: boolean}) {
  this.form.get('documentTypeId')!.valueChanges.pipe(
    switchMap(documentTypeId => forkJoin([
      of(documentTypeId),
      this.codebookService.documentTypes(),
    ])),
    takeUntilDestroyed(this.destroyRef),
  ).subscribe(([documentTypeId, documentTypes]) => {
    const entityClassControl = this.form.get('entityClassId')!;

    const selectedDocumentType = documentTypes.find(dt => dt.id === documentTypeId);

    if (selectedDocumentType && this.enableEntityClassPrefill) {
      const relatedEntityClassId = selectedDocumentType.entityClassId;

      if (relatedEntityClassId) {
        entityClassControl.setValue(relatedEntityClassId);
      }
    }
  });
}
