import {Directive, ElementRef, inject, OnInit} from '@angular/core';

/**
 * A utility directive which can be placed on the <a> element without href
 * attribute to enable rendering of text underline and pointer cursor.
 * Handy if you intend to use the <a> element with (click) event handler.
 */
@Directive({
  selector: '[iczLinkWithoutHref]',
  standalone: true,
})
export class LinkWithoutHrefDirective implements OnInit {

  private elementRef = inject(ElementRef);

  /**
   * @internal
   */
  ngOnInit() {
    (this.elementRef.nativeElement as HTMLLinkElement).tabIndex = 0;
  }

}
