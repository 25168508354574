import {
  AddressDto,
  CzechAddressDto,
  GenericLineAddressDto,
  PostalBoxAddressDto,
  SlovakAddressDto,
  SubjectAttributeAddressDto,
  SubjectAttributesDto,
  SubjectAttributeSource,
  SubjectAttributeState,
  SubjectRecordDto
} from '|api/commons';
import {IczFormGroup} from '@icz/angular-form-elements';
import {flatten} from 'lodash';
import {SubjectAttributeType} from './subject-attribute-type';
import {hashed} from '@icz/angular-essentials';

export enum AddressFormat {
  CzechAddressDto = 'CzechAddressDto',
  SlovakAddressDto = 'SlovakAddressDto',
  GenericLineAddressDto = 'GenericLineAddressDto',
  PostalBoxAddressDto = 'PostalBoxAddressDto',
}

export type AddressCompleteDto = (CzechAddressDto | GenericLineAddressDto | PostalBoxAddressDto | SlovakAddressDto) & {id?: Nullable<string>};

export type AddressForm = {
  search?: string;
  id?: Nullable<number>;
  source?: Nullable<SubjectAttributeSource>;
  state?: Nullable<SubjectAttributeState>;
  validFrom?: Nullable<string>
  value: {
    _Class?: AddressFormat;
    country?: string;
    [AddressFormat.CzechAddressDto]?: CzechAddressDto;
    [AddressFormat.SlovakAddressDto]?: SlovakAddressDto;
    [AddressFormat.GenericLineAddressDto]?: GenericLineAddressFormDto;
    [AddressFormat.PostalBoxAddressDto]?: PostalBoxAddressDto;
  }
};

export enum Countries {
  CZE = 'CZE',
  SVK = 'SVK',
}

export type AddressFormsWithAddressPurpose = Array<{form: IczFormGroup, addressType: SubjectAttributeType, id?: string}>;

export interface AddressWithTypeDto {
  type?: Nullable<SubjectAttributeType>,
  format: AddressFormat,
  address: AddressCompleteDto
}

export interface AddressWithTypeAndValidityDto extends AddressWithTypeDto {
  isCurrentlyValid: boolean;
}

export interface CzechAddressDtoExtended extends CzechAddressDto {
  registrationNumber?: string;
}

/**
 * Generic postal address composed of one or more unstructured lines in format used in form
 */
export interface GenericLineAddressFormDto extends AddressDto {

  /**
   * Unstructured address lines. At least one address line must always be present.
   */
  addressLines?: Array<{
    line: string
  }>;
}

export const SUBJECT_ADDRESS_ATTRIBUTE_TYPES = [
  SubjectAttributeType.MAILING_ADDRESS,
  SubjectAttributeType.RESIDENTIAL_ADDRESS,
  SubjectAttributeType.ADDITIONAL_ADDRESS,
  SubjectAttributeType.POSTAL_BOX,
];

export function addressToAddressWithType(consigneeAddress: AddressCompleteDto, subject?: SubjectRecordDto): AddressWithTypeDto {
  let type: Nullable<SubjectAttributeType> = null;
  if (subject) {
    const subjectAddresses: Nullable<SubjectAttributeAddressDto>[] = flatten(Object.keys(subject.attributes)
      .filter(a => SUBJECT_ADDRESS_ATTRIBUTE_TYPES.includes(a as SubjectAttributeType))
      .map(a => subject.attributes[a as keyof SubjectAttributesDto]))
      .filter(a => Boolean(a?.value));

    const addressToFind: Nullable<SubjectAttributeAddressDto> = subjectAddresses.find(sa => hashed(sa!.value!) === hashed(consigneeAddress));
    if (addressToFind) {
      Object.entries(subject.attributes).forEach(([k, v]) => {
        if (Array.isArray(v)) {
          (v as SubjectAttributeAddressDto[])?.forEach(additional => {
            const attrToFind = (additional as SubjectAttributeAddressDto)?.id === addressToFind.id;
            if (attrToFind) type = k as SubjectAttributeType;
          });
        } else {
          const attrToFind = (v as SubjectAttributeAddressDto)?.id === addressToFind.id;
          if (attrToFind) type = k as SubjectAttributeType;
        }
      });
    }
  }

  return {
    address: consigneeAddress,
    format: consigneeAddress._Class as AddressFormat,
    type
  };
}

export function isCompleteAddressDto(value: any): value is AddressCompleteDto {
  return value && value._Class && (
    AddressFormat.CzechAddressDto in value ||
    AddressFormat.SlovakAddressDto in value ||
    AddressFormat.GenericLineAddressDto in value ||
    AddressFormat.PostalBoxAddressDto in value
  );
}

export function isAddressDto(value: any): value is CzechAddressDto | SlovakAddressDto | GenericLineAddressDto | PostalBoxAddressDto {
  return value && value._Class && (
    value._Class === AddressFormat.CzechAddressDto ||
    value._Class === AddressFormat.SlovakAddressDto ||
    value._Class === AddressFormat.GenericLineAddressDto ||
    value._Class === AddressFormat.PostalBoxAddressDto
  );
}

export function isCzechAddress(addressFormat: AddressFormat) {
  return addressFormat === AddressFormat.CzechAddressDto;
}

export function isSlovakAddress(addressFormat: AddressFormat) {
  return addressFormat === AddressFormat.SlovakAddressDto;
}

export function isGenericLineAddress(addressFormat: AddressFormat) {
  return addressFormat === AddressFormat.GenericLineAddressDto;
}

export function isPostalBoxAddress(addressFormat: AddressFormat) {
  return addressFormat === AddressFormat.PostalBoxAddressDto;
}

export function isPOBoxWithAttributeId(value: any): value is PostalBoxAddressDto | SubjectAttributeAddressDto {
  return isPostalBoxAddress(value?.value?._Class) && 'id' in value;
}
