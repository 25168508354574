import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TransactionSidebarComponent} from '../transaction-sidebar/transaction-sidebar.component';
import {TableModule} from '@icz/angular-table';
import {EssentialsModule} from '@icz/angular-essentials';
import {TransactionsComponent} from './transactions.component';
import {ButtonCollectionModule} from '../../button-collection/button-collection.module';
import {LibraryImportModule} from '../../.module-library-import/library-import.module';

@NgModule({
  declarations: [
    TransactionSidebarComponent,
    TransactionsComponent,
  ],
  imports: [CommonModule, EssentialsModule, TableModule, ButtonCollectionModule, LibraryImportModule],
  exports: [
    TransactionSidebarComponent,
    TransactionsComponent,
  ],
  providers: [],
})
export class TransactionsModule {
}
