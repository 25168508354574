import {Component, inject} from '@angular/core';
import {FileDto, RenditionOutputType} from '|api/document';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {
  AbstractPrintObjectInfoDialogComponent
} from '../print-document-rendition-dialog/abstract-print-object-info-dialog.component';
import {injectModalData} from '@icz/angular-modal';


@Component({
  selector: 'icz-print-file-info-dialog',
  templateUrl: './print-file-info-dialog.component.html',
  styleUrls: ['./print-file-info-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class PrintFileInfoDialogComponent extends AbstractPrintObjectInfoDialogComponent<FileDto> {

  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected objects = injectModalData<FileDto[]>();

  form = new IczFormGroup({
    content: new IczFormControl(true),
    envelope: new IczFormControl(true),
    metadata: new IczFormControl(true),
    transactionLog: new IczFormControl(true),
  });

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  protected getPrintRequest(formValue: typeof this.form['value']) {
    return this.apiRenditionService.renditionCreateBulkFilePrintRendition$Response({
      body: {
        fileIds: this.objects.map(d => d.id),
        content: formValue.content,
        envelope: formValue.envelope,
        metadata: formValue.metadata,
        transactionLog: formValue.transactionLog,
        renditionOutputType: RenditionOutputType.PDF,
      }
    });
  }

}
