import {Injectable, InjectionToken} from '@angular/core';

/**
 * A custom service calling backend healthcheck endpoint should implement this interface
 */
export interface FireAndForgetHealthcheckProvider {
  /**
   * Will check the backend for heartheat.
   * If backend is healthy, it should not do anything.
   * If backend is unhealthy, it should redirect the user to a dedicated page informing him that backend is down etc.
   */
  checkBackend(): void;
}

/**
 * Dependency injection token used for providing optional backend heartbeat check on each modal open
 */
export const FIRE_AND_FORGET_HEALTHCHECK_PROVIDER = new InjectionToken<FireAndForgetHealthcheckProvider>('FireAndForgetHealthcheckProvider');

/**
 * An empty implementation of FireAndForgetHealthcheckProvider which does not check for backend health
 */
@Injectable()
export class NoopFireAndForgetHealthcheckProvider implements FireAndForgetHealthcheckProvider {
  checkBackend(): void {}
}
