<div class="flex main-content grow" [formGroup]="form">
  <div class="row items-center grow">
    <icz-history-bits class="overflow-hidden"></icz-history-bits>
    <icz-breadcrumbs class="grow main-bar-breadcrumbs" [currentPage]="currentPage$ | async"></icz-breadcrumbs>
  </div>

  @if (activeModuleService.activeModule === ApplicationModule.MAIN) {
    <div class="flex items-center">
      <icz-form-field
        #searchTermInput
        (keydown.enter)="doSearch()"
        class="search-input"
        placeholder="Hledej všude"
        formControlName="searchTerm"
        (focus)="searchInputFocusChanged(true)"
        (blur)="searchInputFocusChanged(false)"
        [style.width.px]="searchInputExpanded ? 300 : 120"
      >
        <icz-icon prefix svgIcon="search" size="small"></icz-icon>
      </icz-form-field>
      <icz-button
        class="ml-4 mr-2"
        size="large"
        svgIcon="advanced_filter"
        (onAction)="openAdvancedSearch()"
        iczTooltip="Pokročilé"
      ></icz-button>
    </div>
    <div class="flex relative" [@shakeit]="state">
      <icz-button
        class="my-auto ringbell-button"
        svgIcon="ringbell"
        size="large"
        [label]="ringbellCount"
        iczTooltip="Upozornění"
        (onAction)="toggleNotificationsSidebar()"
      ></icz-button>
      @if (hasUnreadNotifications) {
        <div class="ringbell-red-dot"></div>
      }
    </div>
  }
  <icz-button
    [hidden]="environment.environmentType !== EnvironmentType.DEVELOPMENT"
    class="mr-1 my-auto"
    svgIcon="info"
    size="large"
    #info="cdkOverlayOrigin"
    cdkOverlayOrigin
    iczTooltip="Changelog"
    (onAction)="infoOpen = !infoOpen"
  ></icz-button>
  <icz-button
    class="mx-1 my-auto"
    svgIcon="settings"
    size="large"
    #settings="cdkOverlayOrigin"
    cdkOverlayOrigin
    iczTooltip="Nastavení aplikace"
    (onAction)="settingsOpen = !settingsOpen"
  ></icz-button>
  @if (applicationConfigService.isErrorReportingEnabled) {
    <icz-button
      class="mx-1 my-auto"
      svgIcon="config_warning"
      iczTooltip="Chybové hlášení"
      size="large"
      (onAction)="generateBugReport()"
    ></icz-button>
  }

  <hr class="mx-8">

  <div class="flex items-center main-bar-settings-opener" (click)="userSettingsOpen = !userSettingsOpen">
    @if (functionalPositionSubstituted$ | async) {
      @if (substitutedFPHolder$ | async; as user) {
        <div>
          <icz-user-initials
            class="ml-8"
            [initials]="getUserInitials(user)"
            [iczTooltip]="getUserFullName(user)"
          ></icz-user-initials>
        </div>
      }
      @else {
        <icz-user-initials
          class="ml-8"
          [initials]="''"
          [iczTooltip]="'Bez vykonávajícího'"
        ></icz-user-initials>
        <div></div>
      }
    }
    @else {
      <icz-user-initials
        class="ml-8"
        [initials]="userInitials$ | async"
        [iczTooltip]="userFullName$ | async"
      ></icz-user-initials>
    }


    <div class="username">
      {{ functionalPositionName$ | async }}<br />
      {{ organizationName$ | async }}
    </div>

    <icz-button
      svgIcon="expand_more"
      class="mt-4"
      background="transparent"
      #userSettings="cdkOverlayOrigin"
      cdkOverlayOrigin
    ></icz-button>
  </div>
</div>

<icz-popover [overlayOrigin]="info" [isOpen]="infoOpen" (onClose)="infoOpen = false">
  @if (infoOpen) {
    <icz-app-info-popup (onClose)="infoOpen = false"></icz-app-info-popup>
  }
</icz-popover>

<icz-popover [overlayOrigin]="settings" [isOpen]="settingsOpen" (onClose)="settingsOpen = false">
  @if (settingsOpen) {
    <icz-settings-popup (onClose)="settingsOpen = false"></icz-settings-popup>
  }
</icz-popover>

<icz-popover [overlayOrigin]="userSettings" [isOpen]="userSettingsOpen" (onClose)="userSettingsOpen = false">
  @if (userSettingsOpen) {
    <icz-user-settings-popup></icz-user-settings-popup>
  }
</icz-popover>
