/* eslint-disable @angular-eslint/directive-class-suffix */
import {DestroyRef, Directive, EventEmitter, inject, Input, Output} from '@angular/core';
import {IczFormControl} from '../icz-form-controls';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {USER_INTERACTION_LOGGER} from '@icz/angular-essentials';

/**
 * Base form field class for usage with GenericValueAccessor.
 * Every single element form control should extend from this class if you plan to use it with reactive forms.
 * T is value type.
 */
@Directive()
export abstract class AbstractFormField<T> {

  private _debugLogger = inject(USER_INTERACTION_LOGGER);
  private _destroyRef = inject(DestroyRef);

  //////// these common properties should be implemented by every subclass of AbstractFormField ////////

  /**
   * Input field label.
   */
  @Input()
  label!: string;
  /**
   * Input placeholder in case no value is present.
   */
  @Input()
  placeholder!: string;

  //////// these @Inputs and @Outputs are required by GenericValueAccessor to function properly ////////

  /**
   * External field value which is used in reactive form models.
   *
   * Value setter can be overriden in subclasses to implement various behaviors,
   *   such as sanitization, validation with corrections or additional filter state changes.
   */
  @Input()
  set value(newValue: Nullable<T>) {
    this._value = newValue;
  }
  /**
   * Value getter can be overridden to implement additional transformations made to the item.
   */
  get value(): Nullable<T> {
    return this._value;
  }
  /**
   * If true, makes the field disabled both in view and in model.
   * If the control is plugged into reactive form controls, disabling via this input has higher priority than disabling via AbstractControl.disable().
   * Not just [disabled] cause it clashes with another directive from ReactiveFormsModule.
   */
  @Input()
  set fieldDisabled(newValue: Nullable<boolean>) {
    this._fieldDisabledFromTemplate = newValue;
  }
  get fieldDisabled(): Nullable<boolean> {
    return this._fieldDisabledFromTemplate ?? this._fieldDisabledFromReactiveModel;
  }
  /**
   * Fired when external field value changes.
   */
  @Output()
  valueChange = new EventEmitter<Nullable<T>>();
  /**
   * Fired when the user exits the field.
   */
  @Output()
  blur = new EventEmitter<void>();

  /**
   * Used mainly for debug logging.
   */
  @Input()
  formControlName!: string;

  /**
   * Associated reactive form control instance.
   */
  control!: IczFormControl;
  /**
   * A non-mandatory hook used for adding various behaviors to the form control when it is plugged
   * into reactive control system, such as implicit validations. Runs at ngOnInit time.
   */
  onControlAssigned: Nullable<() => void>;

  /**
   * @internal
   */
  _fieldDisabledFromReactiveModel: Nullable<boolean>;

  /**
   * Internal value of the form field. Internal value can be for example
   * used for data binding to child components thru template.
   */
  _value: Nullable<T> = null;

  protected _fieldDisabledFromTemplate: Nullable<boolean>;
  protected _isPasswordField: Nullable<boolean>;

  constructor() {
    this.blur.pipe(takeUntilDestroyed(this._destroyRef)).subscribe(
      _ => {
        if (!this._isPasswordField) {
          this._debugLogger.logUserInteraction({description: `Input '${this.label}', hodnota: ${String(this._value)}`});
        }
      }
    );
  }

  protected abstract _valueChanged($event: any): void;

}

/**
 * Most usual form model value type of form controls.
 */
export type PrimitiveControlValueType = Nullable<string|number|boolean|Array<string|number|boolean>>;

/**
 * An alias for fiem fields with value of primitive type or array of primitive values.
 */
@Directive()
export abstract class PrimitiveValueFormField<T extends PrimitiveControlValueType = PrimitiveControlValueType> extends AbstractFormField<T> {}
