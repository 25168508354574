/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { CirculationTaskDto } from '../models/circulation-task-dto';
import { circulationTaskHandoverToOwnHandsPass } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-pass';
import { CirculationTaskHandoverToOwnHandsPass$Params } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-pass';
import { circulationTaskHandoverToOwnHandsReject } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-reject';
import { CirculationTaskHandoverToOwnHandsReject$Params } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-reject';
import { circulationTaskHandoverToOwnHandsReturnByTarget } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-return-by-target';
import { CirculationTaskHandoverToOwnHandsReturnByTarget$Params } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-return-by-target';
import { circulationTaskHandoverToOwnHandsRevoke } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-revoke';
import { CirculationTaskHandoverToOwnHandsRevoke$Params } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-revoke';
import { circulationTaskHandoverToOwnHandsTakeover } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-takeover';
import { CirculationTaskHandoverToOwnHandsTakeover$Params } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-takeover';
import { circulationTaskHandoverToOwnHandsTakeoverAndPassByInitiator } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-takeover-and-pass-by-initiator';
import { CirculationTaskHandoverToOwnHandsTakeoverAndPassByInitiator$Params } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-takeover-and-pass-by-initiator';
import { circulationTaskHandoverToOwnHandsTakeoverByInitiator } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-takeover-by-initiator';
import { CirculationTaskHandoverToOwnHandsTakeoverByInitiator$Params } from '../fn/circulation-task-handover-to-own-hands/circulation-task-handover-to-own-hands-takeover-by-initiator';


/**
 * Controller for executing actions on handover-to-own-hands document circulation tasks
 */
@Injectable({ providedIn: 'root' })
export class ApiCirculationTaskHandoverToOwnHandsService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `circulationTaskHandoverToOwnHandsTakeoverByInitiator()` */
  static readonly CirculationTaskHandoverToOwnHandsTakeoverByInitiatorPath = '/flow/handover-to-own-hands/{id}/takeover-rejected';

  /**
   * Flow initiator's takeover
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverToOwnHandsTakeoverByInitiator()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskHandoverToOwnHandsTakeoverByInitiator$Response(params: CirculationTaskHandoverToOwnHandsTakeoverByInitiator$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverToOwnHandsTakeoverByInitiator(this.http, this.rootUrl, params, context);
  }

  /**
   * Flow initiator's takeover
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverToOwnHandsTakeoverByInitiator$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskHandoverToOwnHandsTakeoverByInitiator(params: CirculationTaskHandoverToOwnHandsTakeoverByInitiator$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverToOwnHandsTakeoverByInitiator$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverToOwnHandsTakeoverAndPassByInitiator()` */
  static readonly CirculationTaskHandoverToOwnHandsTakeoverAndPassByInitiatorPath = '/flow/handover-to-own-hands/{id}/takeover-rejected-and-pass';

  /**
   * Flow initiator's takeover and pass
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverToOwnHandsTakeoverAndPassByInitiator()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsTakeoverAndPassByInitiator$Response(params: CirculationTaskHandoverToOwnHandsTakeoverAndPassByInitiator$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverToOwnHandsTakeoverAndPassByInitiator(this.http, this.rootUrl, params, context);
  }

  /**
   * Flow initiator's takeover and pass
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverToOwnHandsTakeoverAndPassByInitiator$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsTakeoverAndPassByInitiator(params: CirculationTaskHandoverToOwnHandsTakeoverAndPassByInitiator$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverToOwnHandsTakeoverAndPassByInitiator$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverToOwnHandsTakeover()` */
  static readonly CirculationTaskHandoverToOwnHandsTakeoverPath = '/flow/handover-to-own-hands/{id}/takeover-by-target';

  /**
   * Target participant's takeover
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverToOwnHandsTakeover()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskHandoverToOwnHandsTakeover$Response(params: CirculationTaskHandoverToOwnHandsTakeover$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverToOwnHandsTakeover(this.http, this.rootUrl, params, context);
  }

  /**
   * Target participant's takeover
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverToOwnHandsTakeover$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  circulationTaskHandoverToOwnHandsTakeover(params: CirculationTaskHandoverToOwnHandsTakeover$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverToOwnHandsTakeover$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverToOwnHandsRevoke()` */
  static readonly CirculationTaskHandoverToOwnHandsRevokePath = '/flow/handover-to-own-hands/{id}/revoke';

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverToOwnHandsRevoke()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsRevoke$Response(params: CirculationTaskHandoverToOwnHandsRevoke$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverToOwnHandsRevoke(this.http, this.rootUrl, params, context);
  }

  /**
   * Revoke all tasks by revoking management task by initiator
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverToOwnHandsRevoke$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsRevoke(params: CirculationTaskHandoverToOwnHandsRevoke$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverToOwnHandsRevoke$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverToOwnHandsReturnByTarget()` */
  static readonly CirculationTaskHandoverToOwnHandsReturnByTargetPath = '/flow/handover-to-own-hands/{id}/return';

  /**
   * Finish task by reject task by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverToOwnHandsReturnByTarget()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsReturnByTarget$Response(params: CirculationTaskHandoverToOwnHandsReturnByTarget$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverToOwnHandsReturnByTarget(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by reject task by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverToOwnHandsReturnByTarget$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsReturnByTarget(params: CirculationTaskHandoverToOwnHandsReturnByTarget$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverToOwnHandsReturnByTarget$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverToOwnHandsReject()` */
  static readonly CirculationTaskHandoverToOwnHandsRejectPath = '/flow/handover-to-own-hands/{id}/reject';

  /**
   * Finish task by reject task by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverToOwnHandsReject()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsReject$Response(params: CirculationTaskHandoverToOwnHandsReject$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverToOwnHandsReject(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by reject task by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverToOwnHandsReject$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsReject(params: CirculationTaskHandoverToOwnHandsReject$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverToOwnHandsReject$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

  /** Path part for operation `circulationTaskHandoverToOwnHandsPass()` */
  static readonly CirculationTaskHandoverToOwnHandsPassPath = '/flow/handover-to-own-hands/{id}/pass';

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `circulationTaskHandoverToOwnHandsPass()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsPass$Response(params: CirculationTaskHandoverToOwnHandsPass$Params, context?: HttpContext): Observable<StrictHttpResponse<CirculationTaskDto>> {
    return circulationTaskHandoverToOwnHandsPass(this.http, this.rootUrl, params, context);
  }

  /**
   * Finish task by pass to next target by target
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `circulationTaskHandoverToOwnHandsPass$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  circulationTaskHandoverToOwnHandsPass(params: CirculationTaskHandoverToOwnHandsPass$Params, context?: HttpContext): Observable<CirculationTaskDto> {
    return this.circulationTaskHandoverToOwnHandsPass$Response(params, context).pipe(
      map((r: StrictHttpResponse<CirculationTaskDto>): CirculationTaskDto => r.body)
    );
  }

}
