import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  ElementRef,
  forwardRef,
  inject,
  InjectionToken,
  Input,
  OnInit
} from '@angular/core';

import {ControlContainer, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {IczFormContainerDirective} from '../form-container/icz-form.directive';
import {IczFormControl} from '../icz-form-controls';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AbstractFormField} from './abstract-form-field';

/**
 * A token used for linking GenericValueAccessor host directive to its associated field component.
 * Use it like this:
 * ```
 * @Component({
 *   ...
 *   standalone: true,
 *   hostDirectives: [{
 *     directive: GenericValueAccessor,
 *     inputs: ['formControlName'],
 *   }],
 *   providers: [{
 *     provide: VALUE_ACCESSIBLE_COMPONENT,
 *     useExisting: forwardRef(() => DatePickerComponent),
 *   }],
 *   ...
 * })
 * export class DatePickerComponent ... {
 * ```
 */
export const VALUE_ACCESSIBLE_COMPONENT = new InjectionToken<AbstractFormField<any>>('Value Accessible Component');

/**
 * A value accessor which can be used to easily implement custom form controls using AbstractFormField.
 */
@Directive({
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => GenericValueAccessor),
    multi: true,
  }],
  standalone: true,
})
export class GenericValueAccessor implements ControlValueAccessor, OnInit {

  protected element = inject(ElementRef);
  protected controlContainer = inject(ControlContainer, {optional: true});
  protected cd = inject(ChangeDetectorRef);
  protected iczFormContainer = inject(IczFormContainerDirective, {optional: true});
  protected destroyRef = inject(DestroyRef);
  protected component = inject(VALUE_ACCESSIBLE_COMPONENT);

  /**
   * Reactive form control key.
   */
  @Input()
  formControlName!: string;

  /**
   * @internal
   */
  ngOnInit(): void {
    if (this.formControlName) {
      if (!this.controlContainer) {
        throw new Error(`GenericValueAccessorDirective can't find control for formControlName=${this.formControlName}. Have you provided included the form element inside a [formGroup]?`);
      }
      else if (!this.component) {
        throw new Error(`GenericValueAccessorDirective can't find component for formControlName=${this.formControlName}. Have you provided VALUE_ACCESSIBLE_COMPONENT correctly?`);
      }
      else {
        this.initFormControl();

        this.iczFormContainer?.formGroupChanged$.pipe(
          takeUntilDestroyed(this.destroyRef)
        ).subscribe(_ => this.initFormControl());

        this.component.valueChange.pipe(
          takeUntilDestroyed(this.destroyRef)
        ).subscribe(newValue => this.onChange(newValue as string));

        this.component.blur.pipe(
          takeUntilDestroyed(this.destroyRef)
        ).subscribe(_ => this.onTouched());
      }
    }
  }

  /**
   * @internal
   */
  registerOnChange(fn: any): void {
    this.onChange = () => {
      fn(this.component.value);
    };
  }

  /**
   * @internal
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * @internal
   */
  setDisabledState(isDisabled: boolean): void {
    this.component._fieldDisabledFromReactiveModel = isDisabled;
    this.cd.detectChanges();
  }

  /**
   * @internal
   */
  writeValue(newValue: string): void {
    this.component.value = newValue;
    this.cd.detectChanges();
  }

  private onChange = (_: string) => {};
  private onTouched = () => {};

  private initFormControl() {
    // such a typecast is fine because formControlName is
    // always placed on a field associated to a FormControl
    this.component.control = this.controlContainer!.control!.get(this.formControlName) as IczFormControl;
    if (this.component.onControlAssigned) this.component.onControlAssigned();
  }

}
