import {ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';

import {
  FormAutocompleteComponent,
  FormInlineSelectComponent,
  FormTreeSelectorComponent,
  getOptionsByValuesList,
  IczFormControl,
  IczFormGroup,
  IczOption,
  isOptionSelectable,
  PrimitiveControlValueType,
  TreeItemSelectionStrategy
} from '@icz/angular-form-elements';
import {IczTableFilterWithList} from '../../../abstract-table-filter.component';
import {CodebookFilterItem, FilterItemValue, NonemptyFilterItem, SubfilterInitialValues} from '../../../filter.types';
import {
  ButtonComponent,
  ChipComponent,
  InaccessibleDirective,
  TooltipDirective,
  TRUNCATED_TEXT_TOOLTIP_DELAY, WINDOW
} from '@icz/angular-essentials';
import {allToolbarOperators} from '../../../table.models';
import {convertViewFiltersToDataFilters, filterAndSortArray} from '../../../filter.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {forkJoin, fromEvent} from 'rxjs';
import {NgTemplateOutlet} from '@angular/common';
import {CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {ReactiveFormsModule} from '@angular/forms';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {isNoValueOperator} from '../../../table.utils';
import {FormFilterComponent} from '../../form-filter/form-filter.component';
import {TableComponent} from '../../../table.component';
import {debounceTime, map, startWith} from 'rxjs/operators';

/**
 * @internal
 */
@Component({
  selector: 'icz-codebook-filter',
  templateUrl: './codebook-filter.component.html',
  styleUrls: ['./codebook-filter.component.scss'],
  standalone: true,
  imports: [
    InaccessibleDirective,
    FormInlineSelectComponent,
    FormTreeSelectorComponent,
    NgTemplateOutlet,
    FormAutocompleteComponent,
    ButtonComponent,
    CdkFixedSizeVirtualScroll,
    CdkVirtualScrollViewport,
    ChipComponent,
    TooltipDirective,
    CdkVirtualForOf,
    ReactiveFormsModule,
    TranslateModule,
    FormFilterComponent,
  ],
})
export class CodebookFilterComponent extends IczTableFilterWithList implements OnInit {
  private window = inject(WINDOW);
  private tableComponent = inject(TableComponent, {optional: true});
  private cd = inject(ChangeDetectorRef);
  tableHeight = null;

  private translateService = inject(TranslateService);

  override set item(newItem: CodebookFilterItem) {
    this._item = newItem;
  }
  override get item(): CodebookFilterItem {
    return this._item as CodebookFilterItem;
  }

  protected readonly TRUNCATED_TEXT_TOOLTIP_DELAY = TRUNCATED_TEXT_TOOLTIP_DELAY;

  protected subfilterForm: Nullable<IczFormGroup<Record<string, IczFormControl<FilterItemValue<unknown>>>>>;

  private filteredOptions: Nullable<IczOption[]>;

  private isEverythingSelected = false;

  protected chosenOptions: IczOption[] = [];

  private selectableListItems: IczOption<Nullable<string | number>, any>[] = [];

  protected get options() {
    return this.filteredOptions ?? this.item.list ?? [];
  }

  protected get filterValueControl() {
    return this.form.get('value')!;
  }

  protected readonly TreeItemSelectionStrategy = TreeItemSelectionStrategy;

  private windowResized$ = fromEvent(this.window, 'resize').pipe(
    startWith(null),
    map(_ => this.window.innerWidth),
    debounceTime(500),
  );

  ngOnInit() {
    this.selectableListItems = (this.item.list ?? []).filter(o => isOptionSelectable(o));

    this.initForm();
    this.initSubfilterForm();
    this.computeChosenOptions();

    this.filterValueControl.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.checkEverythingSelected();
      this.computeChosenOptions();
    });

    if (this.tableComponent) {
      this.tableHeight = this.tableComponent.tableContainerEl.nativeElement.clientHeight;
    }

    this.windowResized$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      if (this.tableComponent) {
        this.tableHeight = this.tableComponent.tableContainerEl.nativeElement.clientHeight;
        this.cd.detectChanges();
      }
    });
  }

  private initSubfilterForm() {
    if (this.item.subfilters?.length) {
      const subfilterControls: Record<string, IczFormControl<FilterItemValue<unknown>>> = {};

      for (const subfilter of this.item.subfilters) {
        subfilterControls[subfilter.id!] = new IczFormControl<FilterItemValue<unknown>>();
      }

      this.subfilterForm = new IczFormGroup(subfilterControls);

      if (this.item.subfilterInitialValues) {
        this.applySubfilterInitialValues(this.item.subfilterInitialValues);
      }

      if (this.item.subfilterInitialValues$) {
        forkJoin(this.item.subfilterInitialValues$).pipe(
          takeUntilDestroyed(this.destroyRef),
        ).subscribe(resolvedInitialValues => {
          this.applySubfilterInitialValues(resolvedInitialValues);
        });
      }

      this.subfilterForm.valueChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(subfiltersValue => {
        this.filterOptionsBySubfilters(subfiltersValue);
      });
    }
  }

  protected applyFilter(): void {
    this.applyFilterOperator();

    if (!this.hasNoValueFilterOperator) {
      this.emitFilterValue();
    }

    this.closeFilterPopup();
  }

  protected removeSelectedItem(item: IczOption): void {
    const selectedItemValue = item.originId ? item.id : item.value;
    const filterValueCopy = [...this.filterValueControl.value];
    const itemIndex = filterValueCopy.indexOf(selectedItemValue);

    if (itemIndex !== -1) {
      filterValueCopy.splice(itemIndex, 1);
      this.form.get('value')!.setValue(filterValueCopy);
    }
  }

  protected selectAllValueChanged(newCheckboxValue: Nullable<PrimitiveControlValueType>) {
    if (newCheckboxValue === true) {
      this.isEverythingSelected = true;
      this.filterValueControl.setValue(this.selectableListItems.map(o => o.value));
    }
    else {
      this.isEverythingSelected = false;
      this.filterValueControl.setValue(null);
    }
  }

  private applySubfilterInitialValues(subfilterInitialValues: SubfilterInitialValues) {
    for (const [subfilterKey, initialValue] of Object.entries(subfilterInitialValues)) {
      this.subfilterForm!.get(subfilterKey)!.setValue(initialValue);
    }
    this.filterOptionsBySubfilters(this.subfilterForm!.value);
  }

  private checkEverythingSelected() {
    const filterValue = this.filterValueControl.value;

    if (filterValue && this.options && filterValue.length >= this.selectableListItems.length) {
      this.isEverythingSelected = true;
    }
    else {
      this.isEverythingSelected = false;
    }
  }

  private computeChosenOptions() {
    const list = this.item?.list as IczOption<unknown>[];
    const filterValue = this.form.get('value')!.value;

    if (Array.isArray(filterValue)) {
      this.chosenOptions = getOptionsByValuesList(list, filterValue, this.item?.originId);
    }
    else {
      this.chosenOptions = [];
    }
  }

  private filterOptionsBySubfilters(subfiltersValue: Partial<Record<string, FilterItemValue<unknown>>>) {
    const viewFilters = Object.values(subfiltersValue)
      .filter(Boolean)
      .filter(filterItemValue => filterItemValue!.id && filterItemValue!.operator)
      .filter(filterItemValue => (
        isNoValueOperator(filterItemValue!.operator) ||
        !isNoValueOperator(filterItemValue!.operator) && filterItemValue!.value
      ))
      .map(filterItemValue => ({
        ...this.item.subfilters!.find(filterItem => filterItem.id === filterItemValue!.id),
        ...filterItemValue,
        filterOption: allToolbarOperators.find(o => o.value === filterItemValue!.operator),
      })) as NonemptyFilterItem[];

    const filteredData = filterAndSortArray(
      [...((this.item.list ?? []).map(o => o.data))],
      convertViewFiltersToDataFilters(viewFilters, this.translateService.currentLang),
    );

    this.filteredOptions = (this.item.list ?? []).filter(o => filteredData.includes(o.data));
    this.selectableListItems = this.filteredOptions.filter(o => isOptionSelectable(o));
    this.checkEverythingSelected();
  }

}
