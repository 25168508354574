import {Injectable} from '@angular/core';
import {FreeDaysPredicate} from '@icz/angular-form-elements';

@Injectable()
export class FreeDaysService implements FreeDaysPredicate {
  // after having implemented a list of bank holidays to
  // G2 config, connect this service to it
  isFreeDay(d: Date) {
    return d.getDay() === 0; // every sunday
  }
}
