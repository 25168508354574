@if (dataSource) {
  <icz-table
    class="grow h-full"
    [id]="'subjectUsageReport'"
    [dataSource]="dataSource"
    [columnsData]="columnsData"
    [config]="tableConfig"
  >
    <icz-label tableTitle label="Přehled použití"></icz-label>
    <icz-label noDataLabel label="Žádné použití subjektu"></icz-label>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'relatedObjectType'">
      <span>{{ row.relatedObjectType | translate }}</span>
    </ng-container>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'refNumber'">
      @if (row.refNumber; as refNumber) {
        <div class="ref-number" [iczTooltip]="refNumber"
          iczTooltipClass="ref-number-multiline">{{ refNumber }}
        </div>
      }
      @if (row.uid; as uid) {
        <div>{{ uid }}
        </div>
      }
    </ng-container>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'state'; let column = column">
      @if (row.relatedObjectType === RelatedObjectType.DOCUMENT) {
        <span> {{ row.state | findInList:column.list! | translate }} </span>
      }
      @if (row.relatedObjectType === RelatedObjectType.FILE) {
        <span> {{ row.state | findInList:column.list! | translate }} </span>
      }
    </ng-container>

  </icz-table>
}
