import {FilterParam, IczTableDataSource} from '@icz/angular-table';
import {EsslComponentDto, EsslComponentSearchService} from '../../../../../services/essl-component-search.service';

export class ConsignmentComponentsDatasource extends IczTableDataSource<EsslComponentDto> {
  constructor(
    searchService: EsslComponentSearchService,
    consignmentId: number,
    documentId: number,
    additionalFilters?: FilterParam[]
  ) {
    super(searchParams => searchService.findEsslComponentsForConsignment(searchParams, consignmentId, documentId, additionalFilters));
  }
}
