/*
  PERMISSION UTILS
  1. Used for checking if user has certain permissions
  2. getInsufficientPermissionTooltip is used to show tooltip for insufficient permissions, translation is ready
*/

import {TranslateService} from '@ngx-translate/core';
import {
  AuthorizedEntityType,
  ComponentAuthorizationRequest,
  ComponentAuthorizationResult,
  ComponentAuthorizedOperation,
  ComponentAuthorizedOperationPermitStatus,
  DocumentAuthorizationRequest,
  DocumentAuthorizationResult,
  DocumentAuthorizedOperation,
  DocumentAuthorizedOperationPermitStatus,
  FileAuthorizationRequest,
  FileAuthorizationResult,
  FileAuthorizedOperation,
  FileAuthorizedOperationPermitStatus,
  OwnConsignmentAuthorizationRequest,
  OwnConsignmentAuthorizationResult,
  OwnConsignmentAuthorizedOperation,
  OwnConsignmentAuthorizedOperationPermitStatus,
  ReceivedConsignmentAuthorizationRequest,
  ReceivedConsignmentAuthorizationResult,
  ReceivedConsignmentAuthorizedOperation,
  ReceivedConsignmentAuthorizedOperationPermitStatus,
  SharedFolderAuthorizationRequest,
  SharedFolderAuthorizationResult,
  SharedFolderAuthorizedOperation,
  SharedFolderAuthorizedOperationPermitStatus,
  StorageUnitAuthorizationRequest,
  StorageUnitAuthorizationResult,
  StorageUnitAuthorizedOperation,
  StorageUnitAuthorizedOperationPermitStatus
} from '|api/core';

export type GeneralAuthorizedOperation = DocumentAuthorizedOperation |
  FileAuthorizedOperation |
  OwnConsignmentAuthorizedOperation |
  ReceivedConsignmentAuthorizedOperation |
  ComponentAuthorizedOperation |
  StorageUnitAuthorizedOperation |
  SharedFolderAuthorizedOperation;

export type GeneralAuthorizationRequest = Omit<
  DocumentAuthorizationRequest |
  FileAuthorizationRequest |
  OwnConsignmentAuthorizationRequest |
  ReceivedConsignmentAuthorizationRequest |
  ComponentAuthorizationRequest |
  StorageUnitAuthorizationRequest |
  SharedFolderAuthorizationRequest, 'operationsToAuthorize'
> & {
  operationsToAuthorize: GeneralAuthorizedOperation[],
};

export type GeneralAuthorizationResult = DocumentAuthorizationResult |
  FileAuthorizationResult |
  OwnConsignmentAuthorizationResult |
  ReceivedConsignmentAuthorizationResult |
  ComponentAuthorizationResult |
  StorageUnitAuthorizationResult |
  SharedFolderAuthorizationResult;

export type GeneralAuthorizedOperationPermitStatus = DocumentAuthorizedOperationPermitStatus |
  FileAuthorizedOperationPermitStatus |
  OwnConsignmentAuthorizedOperationPermitStatus |
  ReceivedConsignmentAuthorizedOperationPermitStatus |
  ComponentAuthorizedOperationPermitStatus |
  StorageUnitAuthorizedOperationPermitStatus |
  SharedFolderAuthorizedOperationPermitStatus;

export function isAuthorizedOperationGranted(authorizationResult: Nullable<GeneralAuthorizationResult>, authorizedOperation: GeneralAuthorizedOperation) {
  const operation = ((authorizationResult?.authorizedOperations ?? []) as GeneralAuthorizedOperationPermitStatus[]).find(ao => ao.operation === authorizedOperation);
  return operation?.userAuthorized ?? false;
}

export function getInsufficientPermissionsTooltip(
  authorizationResult: Nullable<GeneralAuthorizationResult>,
  authorizedOperation: GeneralAuthorizedOperation,
  translateService: TranslateService,
): Nullable<{tooltip: string, tooltipContext?: {[k: string]: string}}> {
  if (!authorizationResult) {
    return null;
  }
  else {
    const operation = (authorizationResult.authorizedOperations as GeneralAuthorizedOperationPermitStatus[]).find(ao => ao.operation === authorizedOperation);
    if (operation && !operation.userAuthorized) {
      const permissionNotGrantedReason = operation.permissionsNotGranted[0]?.reason;

      if (permissionNotGrantedReason) {
        return {
          tooltip: translateService.instant(`en.authorizationBusinessReason.${permissionNotGrantedReason}`)
        };
      }
      else {
        return null;
      }
    }
    else {
      return null;
    }
  }
}

export function getAuthorizedOperationsByEntityTypes(authorizedOperationsToFetch: GeneralAuthorizedOperation[]) {
  const out: Record<AuthorizedEntityType, GeneralAuthorizedOperation[]> = {
    [AuthorizedEntityType.DOCUMENT]: [],
    [AuthorizedEntityType.FILE]: [],
    [AuthorizedEntityType.OWN_CONSIGNMENT]: [],
    [AuthorizedEntityType.RECEIVED_CONSIGNMENT]: [],
    [AuthorizedEntityType.COMPONENT]: [],
    [AuthorizedEntityType.STORAGE_UNIT]: [],
    [AuthorizedEntityType.SHARED_FOLDER]: [],
    [AuthorizedEntityType.RECEIVED_INTERNAL_MESSAGE]: [],
    [AuthorizedEntityType.AGENDA_TRANSFER]: [],
  };

  for (const authorizedOperation of authorizedOperationsToFetch) {
    if (authorizedOperation.startsWith(AuthorizedEntityType.DOCUMENT)) {
      out[AuthorizedEntityType.DOCUMENT].push(authorizedOperation);
    }
    else if (authorizedOperation.startsWith(AuthorizedEntityType.FILE)) {
      out[AuthorizedEntityType.FILE].push(authorizedOperation);
    }
    else if (authorizedOperation.startsWith(AuthorizedEntityType.OWN_CONSIGNMENT)) {
      out[AuthorizedEntityType.OWN_CONSIGNMENT].push(authorizedOperation);
    }
    else if (authorizedOperation.startsWith(AuthorizedEntityType.RECEIVED_CONSIGNMENT)) {
      out[AuthorizedEntityType.RECEIVED_CONSIGNMENT].push(authorizedOperation);
    }
    else if (authorizedOperation.startsWith(AuthorizedEntityType.COMPONENT)) {
      out[AuthorizedEntityType.COMPONENT].push(authorizedOperation);
    }
  }

  return out;
}
