import {FireAndForgetHealthcheckProvider} from '@icz/angular-modal';
import {DestroyRef, inject, Injectable} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {createAbsoluteRoute} from '../routing/routing.helpers';
import {Router} from '@angular/router';
import {HealthcheckService} from '../guards/healthcheck.service';

@Injectable()
export class FireAndForgetHealthcheckService implements FireAndForgetHealthcheckProvider {

  private router = inject(Router);
  private destroyRef = inject(DestroyRef);
  private healthcheckService = inject(HealthcheckService);

  checkBackend() {
    this.healthcheckService.getBackendHealthcheckResult().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(result => {
      if (!result.isUp) {
        this.router.navigateByUrl(createAbsoluteRoute(result.redirectTo!));
      }
    });
  }

}
