import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FilterItem} from '../../filter.types';
import {FilterValueFormatter} from '../../table.providers';

/**
 * @internal
 */
@Injectable()
export class BooleanFilterValueFormatterService implements FilterValueFormatter {

  format(filterItem: FilterItem): Observable<string> {
    if (filterItem.value) {
      return of('Ano');
    }
    else {
      return of('Ne');
    }
  }

}
