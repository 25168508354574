import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import {PrimitiveValueFormField} from '../common/abstract-form-field';
import {MatCheckbox, MatCheckboxChange} from '@angular/material/checkbox';
import {TranslateModule} from '@ngx-translate/core';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

/**
 * A checkbox.
 */
@Component({
  selector: 'icz-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TranslateModule,
    MatCheckbox,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => CheckboxComponent),
  }],
})
export class CheckboxComponent extends PrimitiveValueFormField {

  /**
   * Secondary radio label, placed directly under the normal label, written in muted color, used for descriptive/help texts.
   */
  @Input()
  secondaryLabel: string = '';
  /**
   * A dedicated flag for indeterminate checkbox state.
   */
  @Input()
  indeterminate!: boolean;
  /**
   * Expands the clickable area which triggers checkbox value change.
   */
  @HostBinding('class.with-generous-click-area')
  @Input()
  generousClickArea = false;
  /**
   * @deprecated - please use CSS instead. We might remove this.
   */
  @Input()
  verticalAlignmentWithFormField = false;
  /**
   * Sets check status (TRUE = checked; FALSE = unchecked).
   */
  @Input()
  set checked(value: boolean) {
    this.value = value;
  }
  get checked(): boolean {
    return this.value as boolean;
  }
  /**
   * Emits check status (TRUE/FALSE) on check state change.
   */
  @Output()
  changed = new EventEmitter<boolean>();

  protected _valueChanged($event: MatCheckboxChange) {
    const newValue = $event.checked;

    this.value = newValue;
    this.changed.emit(newValue);
    this.valueChange.emit(newValue);
    this.blur.emit();
  }

}
