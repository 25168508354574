@if ((loadingService.isLoading$(this) | async)) {
  <icz-spinner class="block mx-auto text-center"></icz-spinner>
}
@else {
  <div>
    <h3 class="mt-8">{{ 'Doručenka' | translate }}</h3>
    @if (fullConsignment && fullConsignment.deliveryEvents) {
      <div class="row">
        <div class="grow icz-body-1">
          <div class="pt-16">
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Identifikátor datové zprávy' | translate }}:</div>
              {{ fullConsignment.outboundConsignmentIdentifier }}
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Předmět' | translate }}:</div>
              {{ fullConsignment.subject }}
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Adresát' | translate }}:</div>
              <span>{{fullConsignment.consigneeDetail | subjectName:true}}</span>
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Dat. schránka adresáta' | translate }}:</div>
              {{ fullConsignment.consigneeDataBox ?? (nullText | translate) }}
            </div>
          </div>
          <div class="pt-16">
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Datum a čas dodání' | translate }}:</div>
              {{ dispatchedAt ? (dispatchedAt | localizedDatetime) : (nullText | translate) }}
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Datum a čas doručení' | translate }}:</div>
              {{ fullConsignment.proofOfDelivery!.acknowledgementDate ? (fullConsignment.proofOfDelivery!.acknowledgementDate | localizedDatetime) : (nullText | translate) }}
            </div>
          </div>
        </div>
        <div class="grow">
          <h4>{{ 'Rozšířené informace' | translate }}</h4>
          <div class="icz-body-1">
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Zmocnění' | translate }}:</div>
              {{ fullConsignment.empowerment ? (fullConsignment.empowerment | empowerment) : (nullText | translate) }}
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'K rukám' | translate }}:</div>
              {{ fullConsignment.toBeDeliveredTo ?? (nullText | translate) }}
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Naše číslo jednací' | translate }}:</div>
              {{ fullConsignment.refNumber ?? (nullText | translate) }}
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Naše spisová značka' | translate }}:</div>
              {{ fullConsignment.fileRefNumber ?? (nullText | translate) }}
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Vaše číslo jednací' | translate }}:</div>
              {{ fullConsignment.consignorRefNumber ?? (nullText | translate) }}
            </div>
            <div class="mb-8">
              <div class="icz-body-strong">{{ 'Vaše spisová značka' | translate }}:</div>
              {{ fullConsignment.consignorFileRefNumber ?? (nullText | translate) }}
            </div>
          </div>
        </div>
        <div class="grow">
          <h4>{{ 'Události' | translate }}</h4>
          <div class="message-events icz-body-1">
            @for (event of deliveryEvents; track event) {
              <div
                class="mb-8 p-8" [class.latest-event]="$first"
                >
                <div class="icz-body-strong">{{ event.eventDate | localizedDatetime }}</div>
                {{ ('fe.ui.dataBoxEvent.description.' + event.eventCode) | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    }
  </div>
}
