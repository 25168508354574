import {ChangeDetectionStrategy, Component, DestroyRef, EventEmitter, inject, Input, Output} from '@angular/core';
import {BehaviorSubject, map, Observable, of} from 'rxjs';
import {OwnConsignmentStatus} from '|api/commons';
import {AuthorizedEntityType, OwnConsignmentAuthorizedOperation} from '|api/core';
import {InternalNotificationKey} from '|api/notification';
import {ConsignmentValidationDialogResult} from '../consignment-validation/consignment-validation.component';
import {OwnConsignmentWorkflowService} from '../../../own-consignment-table/own-consignment-workflow.service';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {
  GenericOwnElasticConsignmentWithConsignee,
  GenericUpdateOwnConsignment,
  isOwnDigitalElasticConsignment,
  isOwnInternalElasticConsignment,
  isOwnInternalPaperElasticConsignment,
  isOwnOfficeDeskElasticConsignment,
  isOwnPaperOrMultiPaperElasticConsignment,
  isOwnPersonalElasticConsignment,
  isPartOfOwnMultiPaperElasticConsignment,
  OwnConsignmentWorkflowActionOpts,
  OwnConsignmentWorkflowActionResult
} from '../../../own-consignment-table/model/own-consignment-model';
import {ConsignmentsToastService, ConsignmentToastData} from '../../consignments-toast.service';
import {CurrentSessionService} from '../../../../../services/current-session.service';
import {AuthorizedButton, AuthorizedButtonService} from '../../../authorized-button.service';
import {Button} from '../../../../button-collection/button-collection.component';
import {ConsignmentDialogButtonsDisablers} from './consignment-dialog-buttons-disablers';
import {
  ConsignmentToolbarButtonsDisablers
} from '../../../own-consignment-toolbar/consignment-toolbar-buttons/consignment-toolbar-buttons-disablers';
import {isDispatchOfficer} from '../../../../../core/model/user-roles.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {OwnConsignmentWorkflowAction} from '../consignment.model';
import {CodebookService} from '../../../../../core';
import {
  ConsignmentOperationValidators
} from '../../../own-consignment-toolbar/consignment-toolbar-buttons/consignment-operation-validators';


@Component({
  selector: 'icz-consignment-dialog-buttons',
  templateUrl: './consignment-dialog-buttons.component.html',
  styleUrls: ['./consignment-dialog-buttons.component.scss'],
  providers: [
    OwnConsignmentWorkflowService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentDialogButtonsComponent implements IczOnChanges {

  private ownConsignmentWorkflowService = inject(OwnConsignmentWorkflowService);
  private consignmentsToastService = inject(ConsignmentsToastService);
  private currentSessionService = inject(CurrentSessionService);
  private authorizedButtonService = inject(AuthorizedButtonService);
  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) consignment!: GenericOwnElasticConsignmentWithConsignee;
  @Input({required: true}) consignmentUpdateReq$: Nullable<Observable<GenericUpdateOwnConsignment>>;
  @Input({required: true}) consignmentValidationResult: Nullable<ConsignmentValidationDialogResult>;
  @Output() actionStarted = new EventEmitter<void>();
  @Output() result = new EventEmitter<OwnConsignmentWorkflowActionResult>();

  buttons: Button[] = [];

  buttonClicked(button: Button) {
    button.action?.(button);
  }

  getActionButtons() {
    if (!this.consignment) return;
    const currentUserInfo = this.currentSessionService.currentUserFunctionalPosition;
    const isOwner = this.consignment.document!.ownerFunctionalPositionId === currentUserInfo?.id;
    const dispatchOfficer = isDispatchOfficer(currentUserInfo!);
    const isPaperOrMultiPaperConsignment = isOwnPaperOrMultiPaperElasticConsignment(this.consignment);
    const isDigitalConsignment = isOwnDigitalElasticConsignment(this.consignment);
    const isPersonalConsignment = isOwnPersonalElasticConsignment(this.consignment);
    const isOfficeDeskConsignment = isOwnOfficeDeskElasticConsignment(this.consignment);
    const isInternalPaperConsignment = isOwnInternalPaperElasticConsignment(this.consignment);
    const isInternalConsignment = isOwnInternalElasticConsignment(this.consignment);
    const isPartOfMultiPaperConsignment = isPartOfOwnMultiPaperElasticConsignment(this.consignment);

    let buttons$: Observable<AuthorizedButton[]> = new BehaviorSubject([]);

    if (this.consignment.status === OwnConsignmentStatus.TO_HANDOVER) {
      buttons$ = this.codebookService.deliveryTypes().pipe(
        map(deliveryTypes => ([
          {
            show: isOwner && !isPersonalConsignment && !isPartOfMultiPaperConsignment,
            disable: this.consignmentValidationResult?.loading,
            buttonDisablers: [
              ConsignmentDialogButtonsDisablers.isConsignmentInvalid(this.consignmentValidationResult),
              ...ConsignmentToolbarButtonsDisablers.generateDisablers([this.consignment!], [
                ConsignmentOperationValidators.isEmailOutsideFilingOffice(deliveryTypes),
              ]),
            ],
            label: 'Předat výpravně',
            authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_HANDOVER],
            action: _ => this.workflowAction(OwnConsignmentWorkflowAction.HANDOVER, [this.consignment], {validate: true, saveFirst: true}, this.consignmentUpdateReq$!),
          },
          {
            show: isDigitalConsignment && !isPartOfMultiPaperConsignment,
            buttonDisablers: [
              ConsignmentDialogButtonsDisablers.isConsignmentInvalid(this.consignmentValidationResult),
              ...ConsignmentToolbarButtonsDisablers.generateDisablers([this.consignment!], [
                ConsignmentOperationValidators.isNotEmailOutsideFilingOffice(deliveryTypes),
              ]),
            ],
            label: 'Potvrdit vypravení',
            authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_MARK_AS_DISPATCHED],
            action: _ => this.workflowAction(OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE, [this.consignment]),
          },
          {
            label: 'Smazat',
            show: !isPartOfMultiPaperConsignment,
            authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_DELETE],
            action: _ => this.workflowAction(OwnConsignmentWorkflowAction.DELETE, [this.consignment]),
          },
        ]))
      );
    }
    else if (this.consignment.status === OwnConsignmentStatus.TO_TAKEOVER) {
      buttons$ = of([
        {
          show: dispatchOfficer && (isPaperOrMultiPaperConsignment || isOfficeDeskConsignment || isInternalPaperConsignment) && !isPartOfMultiPaperConsignment,
          label: 'Převzít',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_TAKEOVER],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.TAKEOVER, [this.consignment], {saveFirst: true}, this.consignmentUpdateReq$!),
        },
        {
          show: !isOfficeDeskConsignment && dispatchOfficer && !isPartOfMultiPaperConsignment,
          label: 'Převzít a předat do distribuce',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_DISTRIBUTE],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.TAKEOVER_AND_PREPARE_FOR_DISTRIBUTION, [this.consignment], {saveFirst: true}, this.consignmentUpdateReq$!),
        },
        {
          show: (isPaperOrMultiPaperConsignment || isOfficeDeskConsignment || isInternalConsignment || isDigitalConsignment) && dispatchOfficer && !isPartOfMultiPaperConsignment,
          label: 'Vrátit',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_REJECT],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.REJECT, [this.consignment]),
        },
        {
          show: isOwner && !isPartOfMultiPaperConsignment,
          label: 'Smazat',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_DELETE],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.DELETE, [this.consignment]),
        },
      ]);
    }
    else if (this.consignment.status === OwnConsignmentStatus.TAKEN_OVER) {
      buttons$ = of([
        {
          show: (isPaperOrMultiPaperConsignment || isInternalConsignment) && dispatchOfficer && !isPartOfMultiPaperConsignment,
          label: 'Předat do distribuce',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_DISTRIBUTE],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.PREPARE_FOR_DISTRIBUTION, [this.consignment], {saveFirst: true}, this.consignmentUpdateReq$!),
        },
        {
          show: (isPaperOrMultiPaperConsignment || isOfficeDeskConsignment || isInternalConsignment) && dispatchOfficer && !isPartOfMultiPaperConsignment,
          label: 'Vrátit',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_REJECT],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.REJECT, [this.consignment]),
        },
        {
          show: isOfficeDeskConsignment && dispatchOfficer,
          label: 'Vyvěsit',
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.POST, [this.consignment]),
        },
      ]);
    }
    else if (this.consignment.status === OwnConsignmentStatus.IN_DISTRIBUTION) {
      buttons$ = of([
        {
          show: isPaperOrMultiPaperConsignment && dispatchOfficer && !isPartOfMultiPaperConsignment,
          label: 'Potvrdit vypravení',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_MARK_AS_DISPATCHED],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.DISPATCH, [this.consignment]),
        },
        {
          show: isPaperOrMultiPaperConsignment && dispatchOfficer && !isPartOfMultiPaperConsignment,
          label: 'Stáhnout z distribuce',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_WITHDRAW_FROM_DISTRIBUTION],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.WITHDRAW_FROM_DISTRIBUTION, [this.consignment]),
        },
        {
          show: isPaperOrMultiPaperConsignment && dispatchOfficer && !isPartOfMultiPaperConsignment,
          label: 'Vrátit',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_REJECT],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.REJECT, [this.consignment]),
        },
      ]);
    }
    else if (this.consignment.status === OwnConsignmentStatus.POSTED) {
      buttons$ = of([
        {
          show: isOfficeDeskConsignment && dispatchOfficer,
          label: 'Svěsit',
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.UNPOST, [this.consignment]),
        },
      ]);
    }
    else if (this.consignment.status === OwnConsignmentStatus.REJECTED) {
      buttons$ = of([
        {
          show: (isPaperOrMultiPaperConsignment || isOfficeDeskConsignment || isInternalConsignment) && isOwner && !isPartOfMultiPaperConsignment,
          disable: this.consignmentValidationResult?.loading,
          label: 'Znovupředat výpravně',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_HANDOVER],
          buttonDisablers: [
            ConsignmentDialogButtonsDisablers.isConsignmentInvalid(this.consignmentValidationResult),
          ],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.HANDOVER, [this.consignment]),
        },
        {
          show: (isPaperOrMultiPaperConsignment || isDigitalConsignment || isOfficeDeskConsignment || isInternalConsignment) && isOwner && !isPartOfMultiPaperConsignment,
          label: 'Stáhnout k nápravě',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_WITHDRAW],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.WITHDRAW_FOR_CORRECTION, [this.consignment]),
        },
        {
          show: isOwner && !isPartOfMultiPaperConsignment,
          label: 'Zrušit',
          authorizedOperations: [OwnConsignmentAuthorizedOperation.OWN_CONSIGNMENT_CANCEL],
          action: _ => this.workflowAction(OwnConsignmentWorkflowAction.CANCEL, [this.consignment]),
        },
      ]);
    }

    this.authorizedButtonService.fetchAuthorizedButtonPermissions(
      {
        [AuthorizedEntityType.OWN_CONSIGNMENT]: this.consignment.id,
      },
      buttons$,
    ).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(buttons => this.buttons = buttons);
  }

  private dispatchToastForActionsWithoutModal(action: OwnConsignmentWorkflowAction, selection: GenericOwnElasticConsignmentWithConsignee[]) {
    const toastData: ConsignmentToastData = {
      [InternalNotificationKey.CONSIGNMENT_ID]: selection[0].id,
      [InternalNotificationKey.CONSIGNMENT_UID]: selection[0].uid!.uid!,
    };

    if (action === OwnConsignmentWorkflowAction.HANDOVER) {
      this.consignmentsToastService.dispatchConsignmentHandedOver(toastData);
    } else if (action === OwnConsignmentWorkflowAction.TAKEOVER) {
      this.consignmentsToastService.dispatchConsignmentTakenOver(toastData);
    } else if (action === OwnConsignmentWorkflowAction.DISPATCH_OUTSIDE_FILING_OFFICE) {
      this.consignmentsToastService.dispatchConsignmentOutsideFilingOfficeDispatched(toastData);
    }
  }

  private workflowAction(action: OwnConsignmentWorkflowAction,
                         selection: GenericOwnElasticConsignmentWithConsignee[],
                         opts: OwnConsignmentWorkflowActionOpts = {validate: true, saveFirst: false},
                         updateReq$?: Observable<GenericUpdateOwnConsignment>
  ) {
    this.actionStarted.emit();
    this.ownConsignmentWorkflowService.workflowAction[action as OwnConsignmentWorkflowAction](selection, opts, updateReq$)
      .subscribe(result => {
        if (result) {
          this.dispatchToastForActionsWithoutModal(action, selection);
          this.result.emit({action, success: true});
        }
        else {
          this.result.emit({action, success: false});
        }
      });
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.consignment || changes.consignmentValidationResult) {
      const previousValidity = changes.consignmentValidationResult?.previousValue?.valid;
      const currentValidity = changes.consignmentValidationResult?.currentValue?.valid;

      if ((previousValidity ?? false) !== (currentValidity ?? false)) {
        this.getActionButtons();
      }
    }
  }

}
