import {CdkDrag, CdkDragDrop, CdkDropList} from '@angular/cdk/drag-drop';
import {ChangeDetectionStrategy, Component, forwardRef, Input} from '@angular/core';
import {PrimitiveValueFormField} from '../common/abstract-form-field';
import {ItemSorterItemComponent} from './item-sorter-item/item-sorter-item.component';
import {TranslateModule} from '@ngx-translate/core';
import {IczOption, locateOptionByValue} from '../form-elements.model';
import {arrayMove} from '../form-elements.utils';
import {GenericValueAccessor, VALUE_ACCESSIBLE_COMPONENT} from '../common/generic.value-accessor';

/**
 * A component which allows the user to sort options according to his liking.
 * The sorting can be performed either using drag-and-drop or using UP/DOWN buttons on the sorter items.
 */
@Component({
  selector: 'icz-form-item-sorter',
  templateUrl: './item-sorter.component.html',
  styleUrls: ['./item-sorter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ItemSorterItemComponent,
    TranslateModule,
    CdkDropList,
    CdkDrag,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => ItemSorterComponent),
  }],
})
export class ItemSorterComponent extends PrimitiveValueFormField {

  /**
   * Options to be sorted.
   */
  @Input({required: true})
  set options(newValue: IczOption[]) {
    this._options = newValue;
    this._value = newValue.map(o => o.value!);
  }
  get options(): IczOption[] {
    return this._options;
  }

  /**
   * Value is an array of option values respecting the sorting order.
   * null = unsorted = keep input option array order.
   */
  override set value(newValue: Array<string|number>) {
    if (!newValue) {
      this._value = this._options.map(o => o.value!);
    }
    else {
      this._value = newValue;
    }
  }
  override get value(): Array<string|number> {
    return this._value;
  }

  override _value!: Array<string | number>;

  protected _options: IczOption[] = [];

  protected get optionsSortedByValue(): IczOption[] {
    return (this._value ?? []).map(v => locateOptionByValue(this._options, v)!);
  }

  protected override _valueChanged() {
    this.blur.emit();
    this.valueChange.emit(this._value);
  }

  protected moveUp(index: number) {
    if (index > 0) {
      this.moveItem(index, index - 1);
    }
  }

  protected moveDown(index: number) {
    if (index < this._value?.length - 1) {
      this.moveItem(index, index + 1);
    }
  }

  protected itemDropped($event: CdkDragDrop<any, any>) {
    const {previousIndex, currentIndex} = $event;
    this.moveItem(previousIndex, currentIndex);
  }

  private moveItem(fromIndex: number, toIndex: number) {
    this._value = arrayMove(this._value, fromIndex, toIndex);
    this._valueChanged();
  }

}
