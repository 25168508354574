import {ChangeDetectionStrategy, Component, DestroyRef, inject, OnInit} from '@angular/core';
import {
  CzechAddressDto,
  SubjectAttributeAddressDto,
  SubjectAttributeDataBoxDto,
  SubjectAttributesDto,
  SubjectAttributeStringDto,
  SubjectRecordClassification,
  SubjectRecordDto
} from '|api/commons';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  IczDateValidators,
  IczFormControl,
  IczFormGroup,
  IczOption,
  IczValidators,
  locateOptionByValue
} from '@icz/angular-form-elements';
import {ApiSubjectRecordService} from '|api/subject-register';
import {
  DocumentDetailService,
  FileDetailService,
  formatAddressForDto,
  isAddressPartiallyFilled,
} from 'libs/shared/src/lib/services';
import {enumToOptions, enumValuesToArray} from '../../../../core/services/data-mapping.utils';
import {subjectGetAttribute, subjectHasAttribute} from '../../model/subjects.model';
import {CodebookService} from '../../../../core/services/codebook.service';
import {IszrRppUserRelationFilteredDto} from '|api/codebook';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AddressForm} from '../../model/addresses.model';
import {AddressIdentificationService, AddressIdentificationStatus} from '../address/address-identification.service';
import {catchError, map, Observable, of, switchMap} from 'rxjs';
import {DialogService, injectModalData, injectModalRef} from '@icz/angular-modal';
import {addressDtoToForm} from '../address/address-utils';
import {startWith} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {SubjectAttributeType} from '../../model/subject-attribute-type';
import {WITHOUT_FILE_REF_NUMBER, WITHOUT_REF_NUMBER} from '../../shared-business-components.model';
import {getSearchSubjectFormGroup, SubjectSearchFormData} from '../subject-search-form/subject-search-form.model';
import {SearchOnlyAttribute, SubjectIdentifierType} from '../subject-search.model';

export type IszrQueryDialogData = SubjectRecordDto | SubjectSearchFormData;
type AttributeCombinationsWithPriorities = Array<Array<SubjectAttributeType|SearchOnlyAttribute>>;

export interface IszrQueryDialogResult {
  subject: SubjectRecordDto,
  valueCorrectionMode?: boolean,
}

const ATTRIBUTE_COMBINATIONS_FO: AttributeCombinationsWithPriorities = [
  [SubjectAttributeType.DATA_BOX],
  [SubjectAttributeType.IDENTITY_CARD_ID],
  [SubjectAttributeType.DRIVING_LICENCE_ID],
  [SubjectAttributeType.PASSPORT_ID],
  [SubjectAttributeType.CLIENT_ID],
  [SubjectAttributeType.FIRST_NAME, SubjectAttributeType.SURNAME, SubjectAttributeType.BIRTH_DATE],
  [SubjectAttributeType.FIRST_NAME, SubjectAttributeType.SURNAME, SearchOnlyAttribute.ADDRESS],
  [SubjectAttributeType.FIRST_NAME, SubjectAttributeType.SURNAME, SubjectAttributeType.BIRTH_DATE, SearchOnlyAttribute.ADDRESS],
];

const ATTRIBUTE_COMBINATIONS_PFO_PO: AttributeCombinationsWithPriorities = [
  [SubjectAttributeType.BUSINESS_NAME],
  [SubjectAttributeType.CID],
  [SubjectAttributeType.DATA_BOX],
];

const REASON_CJ_PLACEHOLDER = '{CJ}';
const REASON_SZ_PLACEHOLDER = '{SZ}';
const REASON_UID_PLACEHOLDER = '{UID}';

function isSubjectSearchMode(data: Nullable<IszrQueryDialogData>) {
  return data && typeof data === 'object' && 'identityType' in data;
}

@Component({
  selector: 'icz-iszr-query-dialog',
  templateUrl: './iszr-query-dialog.component.html',
  styleUrls: ['./iszr-query-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IszrQueryDialogComponent implements OnInit {

  protected modalRef = injectModalRef<Nullable<IszrQueryDialogResult>>();
  private addressIdentificationService = inject(AddressIdentificationService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private apiSubjectRecordService = inject(ApiSubjectRecordService);
  private codebookService = inject(CodebookService);
  private dialogService = inject(DialogService);
  private destroyRef = inject(DestroyRef);
  private documentDetailService = inject(DocumentDetailService, {optional: true});
  private fileDetailService = inject(FileDetailService, {optional: true});
  protected data = injectModalData<IszrQueryDialogData>();

  form = new IczFormGroup({
    iszrRequestMetadata: new IczFormGroup({
      iszrRppId: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
      requestReason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
    }),
    iszrSubjectIdentificationCriteria: getSearchSubjectFormGroup(undefined, false),
  });

  searchCombinationForm = new IczFormGroup({
    searchCombinationIndex: new IczFormControl<Nullable<number>>(null, [IczValidators.required()]),
  });

  initialFormValue!: SubjectSearchFormData;

  isSubjectSearchMode = false;

  allowableSearchCombinations: SubjectSearchFormData[] = [];

  agendaActivityRoleOptions: IczOption<string, IszrRppUserRelationFilteredDto>[] = [];

  showValueCorrection = false;
  valueCorrectionDisabled = false;
  valueCorrectionMode = false;

  readonly classificationOptions = enumToOptions('subjectRecordClassification', SubjectRecordClassification);
  readonly SubjectRecordClassification = SubjectRecordClassification;
  readonly SubjectAttributeType = SubjectAttributeType;
  readonly SearchOnlyAttribute = SearchOnlyAttribute;

  readonly birthDateValidator = IczDateValidators.birthDateValidator;

  get iszrSubjectIdentificationCriteriaForm() {
    return this.form.get('iszrSubjectIdentificationCriteria') as IczFormGroup;
  }

  get iszrRequestMetadataForm() {
    return this.form.get('iszrRequestMetadata') as IczFormGroup;
  }

  get addressForm() {
    return this.iszrSubjectIdentificationCriteriaForm.get(SearchOnlyAttribute.ADDRESS) as IczFormGroup;
  }

  get selectedClassification() {
    return this.iszrSubjectIdentificationCriteriaForm.get('classification')!.value as SubjectRecordClassification;
  }

  get submitButtonTooltip() {
    if (!this.iszrRequestMetadataForm.valid) {
      return 'Není vyplněna agenda a důvod dotazu';
    }
    else {
      if (!this.form.valid || !this.isQueryCombinationValid) {
        return 'Není vyplněna vhodná kombinace údajů pro ztotožnění';
      }
      else {
        return null;
      }
    }
  }

  get isQueryCombinationValid() {
    if (isSubjectSearchMode(this.data)) {
      let allowableAttributeCombinations: Array<Array<SubjectAttributeType | SearchOnlyAttribute>>;

      if (this.selectedClassification === SubjectRecordClassification.FO) {
        allowableAttributeCombinations = ATTRIBUTE_COMBINATIONS_FO;
      }
      else {
        allowableAttributeCombinations = ATTRIBUTE_COMBINATIONS_PFO_PO;
      }

      for (const attributeCombination of allowableAttributeCombinations) {
        const isCombinationFulfilled = attributeCombination.every(combinationElement => {
          if (combinationElement === SearchOnlyAttribute.ADDRESS) {
            return isAddressPartiallyFilled(this.iszrSubjectIdentificationCriteriaForm.get(SearchOnlyAttribute.ADDRESS) as IczFormGroup);
          }
          else {
            if (combinationElement === SubjectAttributeType.DATA_BOX) {
              combinationElement = SearchOnlyAttribute.DATA_BOX;
            }

            return !isNil(this.iszrSubjectIdentificationCriteriaForm.get(combinationElement)!.getRawValue());
          }
        });

        if (isCombinationFulfilled) {
          return true;
        }
      }

      return false;
    }
    else {
      return this.searchCombinationForm.valid;
    }
  }

  private handleValueCorrection(subject: SubjectRecordDto) {
    this.showValueCorrection = Boolean(subject.id);
    this.valueCorrectionDisabled = Boolean(subject.iszrIdentifier && subject.iszrMetadata);
  }

  ngOnInit(): void {
    this.initialFormValue = this.iszrSubjectIdentificationCriteriaForm.getRawValue();

    const classificationControl = this.iszrSubjectIdentificationCriteriaForm.get('classification')!;

    classificationControl.enable();
    classificationControl.setValue(SubjectRecordClassification.FO);
    classificationControl.addValidators([IczValidators.required()]);

    if (this.data) {
      if (isSubjectSearchMode(this.data)) {
        this.isSubjectSearchMode = true;
        this.iszrSubjectIdentificationCriteriaForm.patchValue(this.data as SubjectSearchFormData);
      }
      else {
        const subject = this.data as SubjectRecordDto;

        if (subject.classification === SubjectRecordClassification.FO) {
          this.generateSearchCombinationsBySubject(subject, ATTRIBUTE_COMBINATIONS_FO);
        }
        else {
          this.generateSearchCombinationsBySubject(subject, ATTRIBUTE_COMBINATIONS_PFO_PO);
        }

        if (!this.allowableSearchCombinations.length) {
          this.dialogService.showError(
            'Subjekt obsahuje nedostatečné množství informací pro ztotožnění, nelze ho tudíž ztotožnit. Zkontrolujte údaje u subjektu a zkuste to znovu.'
          );
        }

        if (this.allowableSearchCombinations.length === 1) {
          this.searchCombinationForm.get('searchCombinationIndex')!.setValue(0);
          this.patchCriteriaFormWithSearchCombination(this.allowableSearchCombinations[0]);
        }
        else {
          this.searchCombinationForm.valueChanges.pipe(
            takeUntilDestroyed(this.destroyRef),
          ).subscribe(formValue => {
            this.iszrSubjectIdentificationCriteriaForm.reset(this.initialFormValue);

            const selectedCombination = this.allowableSearchCombinations[formValue.searchCombinationIndex!];

            this.patchCriteriaFormWithSearchCombination(selectedCombination);
          });
        }
        this.handleValueCorrection(subject);
      }
    }

    this.codebookService.iszrAgendasWithActivityRolesForCurrentFunctionalPosition().subscribe(userRelations => {
      this.agendaActivityRoleOptions = userRelations.map(ur => ({
        value: ur.iszrRppDto.id,
        label: `${ur.iszrRppDto.agendaCode}/${ur.iszrRppDto.activityRoleCode} ${ur.iszrRppDto.activityRoleName}`,
        data: ur,
      }));

      const iszrRppIdControl = this.iszrRequestMetadataForm.get('iszrRppId')!;

      if (this.agendaActivityRoleOptions.length === 1) {
        iszrRppIdControl.setValue(this.agendaActivityRoleOptions[0]!.value);
      }

      iszrRppIdControl.valueChanges.pipe(
        startWith(iszrRppIdControl.value),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(iszrRppId => {
        if (iszrRppId) {
          const iszrRppOption = locateOptionByValue(this.agendaActivityRoleOptions, iszrRppId)!;
          this.iszrRequestMetadataForm.get('requestReason')!.setValue(this.interpolateRequestReason(iszrRppOption.data!));
        }
      });
    });
  }

  submit(): void {
    const formValue = this.form.getRawValue();
    const iszrRequestMetadata = formValue.iszrRequestMetadata;
    const iszrSubjectIdentificationCriteria = formValue.iszrSubjectIdentificationCriteria;

    const addressDto = formatAddressForDto(iszrSubjectIdentificationCriteria.address as AddressForm) as CzechAddressDto;

    let identificationResult$: Observable<Nullable<CzechAddressDto>>;

    if (addressDto.city && !addressDto.id) {
      identificationResult$ = this.addressIdentificationService.identifyAddress(iszrSubjectIdentificationCriteria.address as AddressForm).pipe(
        map(addressIdentificationResult => {
          if (addressIdentificationResult.status === AddressIdentificationStatus.IDENTIFIED) {
            return addressIdentificationResult.address;
          }
          else {
            throw new Error('Nepodařilo se identifikovat adresu pro dotaz do ISZR. Zkontrolujte adresu subjektu, popřípadě ji upravte, a zkuste to znovu.');
          }
        }),
      );
    }
    else {
      identificationResult$ = of(addressDto.city ? addressDto : null);
    }

    this.loadingIndicatorService.doLoading(
      identificationResult$.pipe(
        switchMap(address => this.apiSubjectRecordService.subjectRecordIdentifySubject({
          body: {
            iszrRequestMetadata: {
              iszrRppId: iszrRequestMetadata.iszrRppId!,
              requestReason: iszrRequestMetadata.requestReason,
            },
            iszrSubjectIdentificationCriteria: {
              address,
              birthDate: iszrSubjectIdentificationCriteria.birthDate,
              businessName: iszrSubjectIdentificationCriteria.businessName,
              cid: iszrSubjectIdentificationCriteria.cid,
              classification: iszrSubjectIdentificationCriteria.classification as SubjectRecordClassification,
              clientId: iszrSubjectIdentificationCriteria.clientId,
              dataBoxId: iszrSubjectIdentificationCriteria.dataBoxId,
              drivingLicenceId: iszrSubjectIdentificationCriteria.drivingLicenceId,
              email: iszrSubjectIdentificationCriteria.email,
              firstName: iszrSubjectIdentificationCriteria.firstName,
              identityCardId: iszrSubjectIdentificationCriteria.identityCardId,
              passportId: iszrSubjectIdentificationCriteria.passportId,
              surname: iszrSubjectIdentificationCriteria.surname,
            },
            subjectRecordId: isSubjectSearchMode(this.data) ? undefined : (this.data as SubjectRecordDto).id,
          }
        })),
        catchError(error => {
          if (!(error instanceof HttpErrorResponse)) {
            this.dialogService.showError(error.message);
          }
          return of(null);
        }),
      ),
      this
    ).subscribe(subject => {
      if (subject) {
        this.modalRef.close({subject, valueCorrectionMode: this.valueCorrectionMode});
      }
    });
  }

  cancel(): void {
    this.modalRef.close();
  }

  private patchCriteriaFormWithSearchCombination(selectedCombination: SubjectSearchFormData) {
    this.iszrSubjectIdentificationCriteriaForm.patchValue({
      ...selectedCombination,
      [SearchOnlyAttribute.ADDRESS]: {
        value: selectedCombination[SearchOnlyAttribute.ADDRESS],
      }
    });
  }

  private generateSearchCombinationsBySubject(subject: SubjectRecordDto, attributeCombinations: AttributeCombinationsWithPriorities) {
    attributeCombinationLoop: for (const attributeCombination of attributeCombinations) {
      let searchCombination: SubjectSearchFormData = {
        classification: subject.classification,
      };

      for (const attribute of attributeCombination) {
        if (attribute === SearchOnlyAttribute.ADDRESS) {
          const addressesToCheck: Array<[SubjectAttributeType, Nullable<number>]> = [];
          const hasMailingAddress = subjectHasAttribute(subject, SubjectAttributeType.MAILING_ADDRESS);
          const hasAdditionalAddresses = subjectHasAttribute(subject, SubjectAttributeType.ADDITIONAL_ADDRESS);

          if (subjectHasAttribute(subject, SubjectAttributeType.RESIDENTIAL_ADDRESS)) {
            addressesToCheck.push([SubjectAttributeType.RESIDENTIAL_ADDRESS, null]);
          }
          else if (hasMailingAddress || hasAdditionalAddresses) {
            let additionalAddresses: SubjectAttributeAddressDto[] = [];
            let mailingAddresses: SubjectAttributeAddressDto[] = [];

            if (hasAdditionalAddresses) {
              additionalAddresses = subjectGetAttribute(subject, SubjectAttributeType.ADDITIONAL_ADDRESS) as SubjectAttributeAddressDto[];
            }
            if (hasMailingAddress) {
              mailingAddresses = [subjectGetAttribute(subject, SubjectAttributeType.MAILING_ADDRESS) as SubjectAttributeAddressDto];
            }

            const additionalAndMailingAddressesSorted = ([...additionalAddresses, ...mailingAddresses])
              .sort((a1, a2) => a1.validFrom < a2.validFrom ? 1 : -1);

            for (const additionalAddress of additionalAndMailingAddressesSorted) {
              const additionalAddressIndex = additionalAndMailingAddressesSorted.indexOf(additionalAddress);

              addressesToCheck.push([
                SubjectAttributeType.MAILING_ADDRESS,
                additionalAddressIndex === -1 ? null : additionalAddressIndex
              ]);
            }
          }

          if (!addressesToCheck.length) {
            continue attributeCombinationLoop;
          }
          else {
            for (const addressToCheck of addressesToCheck) {
              searchCombination = {...searchCombination};

              let address: CzechAddressDto;

              if (addressToCheck[0] === SubjectAttributeType.ADDITIONAL_ADDRESS) {
                if (!isNil(addressToCheck[1])) {
                  address = (subjectGetAttribute(subject, addressToCheck[0]) as SubjectAttributeAddressDto[])[addressToCheck[1]];
                }
                else {
                  address = subjectGetAttribute(subject, addressToCheck[0]);
                }
              }
              else if (addressToCheck[0] === SubjectAttributeType.RESIDENTIAL_ADDRESS || addressToCheck[0] === SubjectAttributeType.MAILING_ADDRESS) {
                address = subjectGetAttribute(subject, addressToCheck[0]);
              }

              searchCombination[SearchOnlyAttribute.ADDRESS] = addressDtoToForm(address.value)!.value;

              this.allowableSearchCombinations.push(searchCombination);
              continue attributeCombinationLoop;
            }
          }
        }
        else {
          if (subjectHasAttribute(subject, attribute as keyof SubjectAttributesDto)) {
            const attributeDto = subjectGetAttribute(subject, attribute as keyof SubjectAttributesDto);

            if (Array.isArray(attributeDto)) { // it's a databox
              for (const databox of attributeDto as SubjectAttributeDataBoxDto[]) {
                searchCombination = {...searchCombination};
                searchCombination[SearchOnlyAttribute.DATA_BOX] = databox.value!.id;
                this.allowableSearchCombinations.push(searchCombination);
              }
              continue attributeCombinationLoop;
            }
            else {
              if (enumValuesToArray(SubjectIdentifierType).includes(attribute)) {
                searchCombination.identityType = attribute as unknown as SubjectIdentifierType;
              }

              searchCombination[attribute as keyof SubjectSearchFormData] = (attributeDto as SubjectAttributeStringDto)!.value;
            }
          }
          else {
            continue attributeCombinationLoop;
          }
        }
      }

      this.allowableSearchCombinations.push(searchCombination);
    }
  }

  private interpolateRequestReason(iszrRppRelation: IszrRppUserRelationFilteredDto) {
    let defaultReason = iszrRppRelation.defaultRequestReason ?? '';

    if (defaultReason.includes(REASON_CJ_PLACEHOLDER) || defaultReason.includes(REASON_SZ_PLACEHOLDER) || defaultReason.includes(REASON_UID_PLACEHOLDER)) {
      if (defaultReason.includes(REASON_CJ_PLACEHOLDER)) {
        defaultReason = defaultReason.replaceAll(
          REASON_CJ_PLACEHOLDER,
          this.documentDetailService ?
            (this.documentDetailService?.object?.refNumber ?? WITHOUT_REF_NUMBER) :
            ''
        );
      }

      if (defaultReason.includes(REASON_SZ_PLACEHOLDER)) {
        if (this.fileDetailService) {
          defaultReason = defaultReason.replaceAll(REASON_SZ_PLACEHOLDER, this.fileDetailService.object.refNumber ?? WITHOUT_FILE_REF_NUMBER);
        }
        else if (this.documentDetailService) {
          defaultReason = defaultReason.replaceAll(
            REASON_SZ_PLACEHOLDER,
            this.documentDetailService.fileForDocument ?
              (this.documentDetailService.fileForDocument.refNumber ?? WITHOUT_FILE_REF_NUMBER) :
              ''
          );
        }
        else {
          defaultReason = defaultReason.replaceAll(REASON_SZ_PLACEHOLDER, '');
        }
      }

      defaultReason = defaultReason.replaceAll(
        REASON_UID_PLACEHOLDER,
        this.documentDetailService?.object?.uid?.uid ?? this.fileDetailService?.object?.uid?.uid ?? ''
      );

      return defaultReason;
    }
    else {
      return defaultReason;
    }
  }

}
