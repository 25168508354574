import {inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FilterItem} from '../../filter.types';
import {FilterValueFormatter} from '../../table.providers';
import {NumberToLocaleStringPipe} from '@icz/angular-essentials';

/**
 * @internal
 */
@Injectable()
export class NumberFilterValueFormatterService implements FilterValueFormatter {

  private numberToLocaleStringPipe = inject(NumberToLocaleStringPipe);

  format(filterItem: FilterItem): Observable<string> {
    if (filterItem.subValues?.length === 2) {
      const intervalBounds = [...filterItem.subValues];
      intervalBounds.sort();

      const lowerBoundValue = this.numberToLocaleStringPipe.transform(Number(intervalBounds[0].value));
      const upperBoundValue = this.numberToLocaleStringPipe.transform(Number(intervalBounds[1].value));

      return of(`${lowerBoundValue} - ${upperBoundValue}`);
    }
    else {
      return of(this.numberToLocaleStringPipe.transform(Number(filterItem.value)));
    }
  }

}
