import {InjectionToken, Type} from '@angular/core';
import {FilterItemTree, FilterItemValueTree} from './filter-trees.utils';
import {Sort, SortDirection} from '@angular/material/sort';
import {FilterItem, FilterType} from './filter.types';
import {AbstractTableFilter} from './abstract-table-filter.component';
import {Observable} from 'rxjs';
import {TableToolbarService} from './table-toolbar/table-toolbar.service';
import {Params} from '@angular/router';
import {TablePage} from './table.models';

/**
 * All saved filters of a certain table.
 */
export type TableSavedFilters = Record<string, TableSavedFilterWithDefault>;

/**
 * A tree representation of logical formula of a filter, with additional flag signifying whether
 *   the filter is default, i.e. is automatically applied after the table gets initialized.
 */
export type TableSavedFilterWithDefault = FilterItemValueTree & {isDefault?: boolean};

/**
 * An associative map from table ID to its saved filters.
 * table ID is set using @Input TableComponent.id and should be unique for each business data view.
 */
export type AllSavedFilters = Record<string, TableSavedFilters>;

/**
 * An interface used for implementing your persistence strategy for saved filters.
 */
export interface SavedFiltersPersistor {
  /**
   * Gets saved filters of all tables.
   */
  getSavedFilters(): Nullable<AllSavedFilters>;
  /**
   * Saves saved filters of all tables.
   */
  saveSavedFilters(savedFilters: AllSavedFilters): void;
}

/**
 * Use this token to enable persistence of saved table filters.
 */
export const SAVED_FILTERS_PERSISTOR = new InjectionToken<SavedFiltersPersistor>('Saved Filters Persistor');

/**
 * A persistor which does not persist anything.
 */
export class NoopSavedFiltersPersistor implements SavedFiltersPersistor {
  getSavedFilters(): Nullable<AllSavedFilters> {
    return null;
  }
  saveSavedFilters(savedFilters: AllSavedFilters): void {}
}

/**
 * Table sort to be persisted.
 */
export interface PersistedSortproperties {
  /**
   * Table column ID.
   */
  sortColumnId: string;
  /**
   * ASC/DESC/unset direction.
   */
  sortDirection: SortDirection;
}

/**
 * Table column user set properties to be persisted.
 */
export interface PersistedColumnProperties {
  /**
   * Table colum ID.
   */
  id: string;
  /**
   * Whether the column is visible.
   */
  isVisible: boolean;
  /**
   * Column width in px.
   */
  columnWidth: number;
}

export interface PersistedUserTableProperties {
  /**
   * Hash of concat(...TableColumnsData.columnDefinitions.id). If table schema changes (i.e. the hash changes),
   * the table will remove user's table properties for the table that change and apply default table configuration in all regards.
   */
  tableSchemaVersionHash: string;
  /**
   * A list of user-set column properties if tampered with by the user.
   */
  columns?: Nullable<PersistedColumnProperties[]>;
  /**
   * User-set sort if any.
   */
  sort?: Nullable<PersistedSortproperties>;
}

/**
 * An interface used for implementing your persistence strategy for table properties
 */
export interface TablePropertiesPersistor {
  /**
   * Gets globally set table row count.
   */
  getTablePageRowCount(): number;
  /**
   * Saves globally set table row count.
   */
  setTablePageRowCount(pageRowCount: number): void;
  /**
   * Gets all user properties currently set by the user.
   */
  loadAllTablePropertiesColumns(): Nullable<{ [key: string]: PersistedUserTableProperties }>;
  /**
   * Gets table user properties by tableId.
   * @param tableId tableId is set by @Input TableComponent.id
   *   and should be unique for each business data view.
   */
  getTableProperties(tableId: string): Nullable<PersistedUserTableProperties>;
  /**
   * Saves table user properties by tableId.
   */
  saveTableProperties(tableId: string, columns: PersistedUserTableProperties): void;
  /**
   * Deletes table user properties by tableId.
   */
  deleteCustomPropertiesForTable(tableId: string): void;
}

/**
 * Use this token to enable persistence of user-set behavior of a given table.
 * Implement your own TablePropertiesPersistor and add it to DI system under this token.
 */
export const TABLE_PROPERTIES_PERSISTOR = new InjectionToken<TablePropertiesPersistor>('Table Properties Persistor');

/**
 * A persistor which does not persist anything.
 */
export class NoopTablePropertiesPersistor implements TablePropertiesPersistor {
  getTablePageRowCount(): number {
    return 20;
  }
  setTablePageRowCount(pageRowCount: number): void {}
  loadAllTablePropertiesColumns(): Nullable<{ [key: string]: PersistedUserTableProperties; }> {
    return null;
  }
  getTableProperties(tableId: string): Nullable<PersistedUserTableProperties> {
    return null;
  }
  saveTableProperties(tableId: string, columns: PersistedUserTableProperties): void {}
  deleteCustomPropertiesForTable(tableId: string): void {}
}

/**
 * A transfer object bearing query params deserialization results, internally used
 * for applying state to table component after deserialization.
 */
export interface TableQueryParamsDeserializationResult {
  /**
   * Because TableQueryParamsSerializer.deserialize calls TableToolbarService to set up filtering state,
   * setting this boolean to TRUE is important after messing in any way with TableToolbarService.
   */
  hasSomeFilters: boolean;
  /**
   * Deserialized fulltext search term if any.
   */
  searchTerm: Nullable<string>;
  /**
   * Deserialized table sort command object if any.
   */
  sort: Nullable<Sort>;
  /**
   * Deserialized table page size and page number command object if any.
   */
  page: Nullable<TablePage>
  /**
   * Deserialized focused row key if any.
   */
  focusedRowKey: Nullable<string|number>;
}

/**
 * Operation mode of TableQueryParamsSerializer.serialize
 */
export enum EsslTableQueryParametersSerializationMode {
  /**
   * Serializes all params - filter, sort, fulltext and pagination
   */
  ALL_PARAMS = 'ALL_PARAMS',
  /**
   * Serializes only sorting and nothing else
   */
  SORT_PARAM_ONLY = 'SORT_PARAM_ONLY',
  /**
   * Serializes only page number, page size and nothing else
   */
  PAGE_PARAM_ONLY = 'PAGE_PARAM_ONLY',
  /**
   * Serializes only focused row and nothing else
   */
  FOCUSED_ROW_ONLY = 'FOCUSED_ROW_ONLY',
}

/**
 * Provides serialization and deserialization of current table filtering/sorting/pagination state.
 */
export interface TableQueryParamsSerializer {
  /**
   * Serializes table state into query parameters and calls router or any other methods for altering browser page path.
   * Note that each mode should by implemented separately:
   * - One mode should only serialize sorting (SORT_PARAM_ONLY),
   * - Another should only serialize page parameters (PAGE_PARAM_ONLY)
   * - And another should only serialize focused row kry (FOCUSED_ROW_ONLY)
   * - .... and other should serialize everything including the sort, page and focused row (ALL_PARAMS).
   */
  serialize(mode: EsslTableQueryParametersSerializationMode, searchTerm: string, activeFilters: FilterItemTree, currentSort: Nullable<Sort>, tablePage: Nullable<TablePage>, focusedRow: Nullable<string|number>): void;
  /**
   * Deserializes current page's query parameters and sets up table state which corresponds to the query parameters.
   */
  deserialize(tableToolbarService: TableToolbarService, queryParams: Params): TableQueryParamsDeserializationResult;
}

/**
 * Use this token to apply your own strategy of table query params serialization and deserialization.
 */
export const TABLE_QUERY_PARAMS_SERIALIZER = new InjectionToken<TableQueryParamsSerializer>('Table Query Params Serializer');

/**
 * A serializer which does not serialize/deserialize anything.
 */
export class NoopTableQueryParamsSerializer implements TableQueryParamsSerializer {
  deserialize(tableToolbarService: TableToolbarService, queryParams: Params): TableQueryParamsDeserializationResult {
    return {
      sort: null,
      page: null,
      searchTerm: null,
      hasSomeFilters: false,
      focusedRowKey: null,
    };
  }
  serialize(mode: EsslTableQueryParametersSerializationMode, searchTerm: string, activeFilters: FilterItemTree, currentSort: Nullable<Sort>): void {}
}

/**
 * An interface used for implementing formatters for custom filter types
 * passed to the table via the FILTER_PLUGINS DI token.
 */
export interface FilterValueFormatter {
  /**
   * Converts a given filterItem with values or subvalues to a string representation.
   */
  format(filterItem: FilterItem): Observable<string>;
}

/**
 * Filter component plugin definitions. Registers a custom
 * filtering widget and a custom filter value formatter.
 */
export interface FilterPluginDefinition {
  /**
   * Component class which is a specialization of AbstractTableFilter.
   */
  filterComponent: Type<AbstractTableFilter>;
  /**
   * A service implementing filter value formatting for the given filter type.
   */
  filterValueFormatter: Nullable<FilterValueFormatter>;
  /**
   * DI deps of filterValueFormatter declared as tokens (like in StaticProvider.deps).
   */
  filterValueFormatterDeps?: Nullable<Array<any>>;
}

/**
 * A map associating filter types to filter component plugin definitions.
 */
export type FilterPlugins = Record<FilterType, Nullable<FilterPluginDefinition>>;

/**
 * Register your own filter types using this token.
 */
export const FILTER_PLUGINS = new InjectionToken<FilterPlugins>('Filter Plugins');

/**
 * @internal
 */
export const DEFAULT_FILTERS = new InjectionToken<FilterPlugins>('Default Filters');
