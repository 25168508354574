<icz-resizable-sidebar
  [initialWidth]="340"
  (widthChanged)="sideWidth = $event"
>
  <div class="title">{{treeviewTitle | translate}}</div>

  <icz-table-tree-menu
    [showItemMenu]="showItemMenu"
    [treeControl]="treeControl"
    [dataSource]="dataSource"
    [selectedMenuItem]="selected"
    [maxWidth]="sideWidth"
    (treeItemSelected)="onTreeNodeClick($event)">
    <icz-button background="transparent" (onAction)="onAction.emit({action: 'edit', node: selected})" label="Nastavit seskupení typů"></icz-button>
    <icz-button background="transparent" (onAction)="onAction.emit({action: 'group', node: selected})" label="Založit nové seskupení typů"></icz-button>
    <icz-button background="transparent" (onAction)="onAction.emit({action: 'new', node: selected})" label="Založit typ"></icz-button>
  </icz-table-tree-menu>
</icz-resizable-sidebar>
