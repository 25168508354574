import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject} from 'rxjs';
import {AnalogComponentTypeDto} from '|api/codebook';
import {
  AnalogComponentForm,
  AnalogComponentOrigin,
  AnalogCompositionType,
  AnalogProtectiveElement,
  DocumentForm,
  EsslComponentRelation
} from '|api/commons';
import {
  AnalogComponentToDocumentReferenceCreateDto,
  ApiDigitalComponentVersionService,
  DigitalComponentVersionDto,
  MediumComponentCreateDto,
  PaperComponentCreateDto,
  PhysicalItemComponentCreateDto
} from '|api/component';
import {OwnDocumentCreateDto} from '|api/document';
import {ReceivedDocumentWithComponentsCreateBaseDto} from '|api/sad';
import {CheckUnsavedFormDialogService} from '../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IczFormArray, IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {CodebookService} from '../../../core/services/codebook.service';
import {analogComponentTypesToOptions, enumToOptions} from '../../../core/services/data-mapping.utils';
import {EsslAnalogComponentCreateDto} from '../../../services/essl-component-search.service';
import {
  ComponentUploadStatus,
  defaultDigitalFormConfig,
  isAnalogCompositionSheets
} from '../essl-components/essl-components-utils';
import {AnalogComponentSubmitDataService} from '../essl-components/services/analog-component-submit-data.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  prepareCustomFieldValuesForBackend
} from '../document-file-custom-fields-section/document-file-custom-fields-section.component';

export enum EsslAnalogComponentType {
  PAPER_COMPONENT = 'PAPER_COMPONENT',
  MEDIUM_COMPONENT = 'MEDIUM_COMPONENT',
  PHYSICAL_ITEM_COMPONENT = 'PHYSICAL_ITEM_COMPONENT',
}

export enum CreateAnalogsUsage {
  STANDALONE_CREATE = 'STANDALONE_CREATE',
  PART_OF_DOCUMENT_CREATE = 'PART_OF_DOCUMENT_CREATE',
  PART_OF_DOCUMENT_CREATE_FILING_OFFICE = 'PART_OF_DOCUMENT_CREATE_FILING_OFFICE',
}

export interface CreateAnalogFormConfig {
  analogCreateUsage: CreateAnalogsUsage,
  isDetailedInput: 'true' | 'false',
  showFieldOrigin: boolean,
  showFieldIsFinal: boolean,
  setFieldIsFinalAsTrue: boolean,
  allowDeleteFirst?: boolean,
  setFirstComponentAsMain?: boolean,
}

export const defaultCreateAnalogFormConfig: CreateAnalogFormConfig = {
  analogCreateUsage: CreateAnalogsUsage.PART_OF_DOCUMENT_CREATE,
  isDetailedInput: 'true',
  showFieldIsFinal: false,
  showFieldOrigin: false,
  setFieldIsFinalAsTrue: false,
  allowDeleteFirst: true,
  setFirstComponentAsMain: false,
};

export enum EsslAnalogComponentTypeAsFields {
  PAPER = 'paperComponents',
  MEDIUM = 'mediumComponents',
  PHYSICAL = 'physicalItemComponents',
}

// Note: these are default component name (labels) values, don't translate
export enum DefaultPaperComponentName {
  MAIN = 'Hlavní listina',
  ENCLOSURE = 'Listinná příloha'
}

const defaultNumberOfSheets = 1;

export type EsslAnalogComponentCreateDtoWithDigitalContent = EsslAnalogComponentCreateDto &
  { digitalComponentVersionDto?: DigitalComponentVersionDto, status?: ComponentUploadStatus };

export interface AnalogComponentCreateOptions {
  type: EsslAnalogComponentType;
  initialSize?: number;
}

const createNumberOfSheetsControl = () => {
  return new IczFormControl<Nullable<number>>(defaultNumberOfSheets, [IczValidators.required(), IczValidators.isInteger()]);
};

export function setDynamicControlsByAnalogComposition(paperControl: IczFormGroup, compositionType: AnalogCompositionType) {
  if (compositionType === AnalogCompositionType.VOLUMES) {
    paperControl.removeControl('numberOfSheets');
  } else if (compositionType === AnalogCompositionType.SHEETS) {
    if (!paperControl.get('numberOfSheets')) {
      paperControl.addControl('numberOfSheets', createNumberOfSheetsControl());
    } else if (paperControl.get('numberOfSheets') && !paperControl.get('numberOfSheets')?.value) {
      paperControl.get('numberOfSheets')?.setValue(defaultNumberOfSheets);
    }
  }
}

type OwnReceivedDocumentCreateDto = OwnDocumentCreateDto | ReceivedDocumentWithComponentsCreateBaseDto;
type DocumentCreateDtoAnalogComponentProperties = Pick<OwnReceivedDocumentCreateDto, 'paperComponents'|'mediumComponents'|'physicalItemComponents'>;

export function transformDocumentCreateDtoNondigitalComponents(document: OwnReceivedDocumentCreateDto): DocumentCreateDtoAnalogComponentProperties {
  return {
    paperComponents: (document.paperComponents as any)?.paperComponents?.map((pc: any) => {
      const renditionDigitalComponent = (pc as EsslAnalogComponentCreateDtoWithDigitalContent).digitalComponentVersionDto;
      return {
        compositionType: pc.compositionType,
        count: pc.count,
        description: pc.description,
        digitalRenditionId: renditionDigitalComponent?.id,
        isFinal: pc.isFinal,
        label: pc.label,
        numberOfSheets: pc.numberOfSheets,
        originType: pc.originType,
        protectiveElementSet: pc.protectiveElementSet,
        relationType: pc.relationType,
      };
    }),
    mediumComponents: (document.mediumComponents as any).mediumComponents,
    physicalItemComponents: (document.physicalItemComponents as any).physicalItemComponents,
  };
}

export function transformDocumentCreateDtoWithComponents(documentFormValue: OwnReceivedDocumentCreateDto, isCreateUnopenedDocument: boolean): OwnReceivedDocumentCreateDto {
  const documentCreateDto: OwnReceivedDocumentCreateDto = {
    subject: documentFormValue.subject,
    assignRefNr: documentFormValue.assignRefNr,
    description: documentFormValue.description,
    note: documentFormValue.note,
    relatedEvidence: documentFormValue.relatedEvidence,
    label: documentFormValue.label,
    documentForm: documentFormValue.documentForm,
    externalId: documentFormValue.externalId,
    documentTypeId: documentFormValue.documentTypeId,
    empowerment: documentFormValue.empowerment,
    entityClassId: documentFormValue.entityClassId,
    executionDate: documentFormValue.executionDate,
    keywordIds: documentFormValue.keywordIds,
    resolutionDate: documentFormValue.resolutionDate,
    securityCategoryId: documentFormValue.securityCategoryId,
    triggerEventCheckYear: documentFormValue.triggerEventCheckYear,
    yearOfRetentionPeriodStart: documentFormValue.yearOfRetentionPeriodStart,
    senderRefNr: (documentFormValue as ReceivedDocumentWithComponentsCreateBaseDto).senderRefNr,
    componentPhysicalLocation: documentFormValue.componentPhysicalLocation,
    objectClass: documentFormValue.objectClass,
    customFieldValues: prepareCustomFieldValuesForBackend(documentFormValue.customFieldValues ?? []),
  };

  // Unopened documentFormValue does not have any components
  if (isCreateUnopenedDocument) {
    return documentCreateDto;
  }
  else {
    if (documentFormValue.documentForm === DocumentForm.DIGITAL) {
      return {
        ...documentCreateDto,
        digitalComponents: documentFormValue.digitalComponents,
      };
    } else if (documentFormValue.documentForm === DocumentForm.HYBRID) {
      return {
        ...documentCreateDto,
        digitalComponents: documentFormValue.digitalComponents,
        ...transformDocumentCreateDtoNondigitalComponents(documentFormValue),
      };
    }
    else {
      return {
        ...documentCreateDto,
        ...transformDocumentCreateDtoNondigitalComponents(documentFormValue),
      };
    }
  }
}

export function createAnalogComponentsFormGroup(options: AnalogComponentCreateOptions): IczFormGroup {
  const form = new IczFormGroup({
    [EsslAnalogComponentTypeAsFields.PAPER]: new IczFormArray(() => new IczFormGroup({
      label: new IczFormControl<Nullable<DefaultPaperComponentName>>(DefaultPaperComponentName.ENCLOSURE, [IczValidators.required()]),
      relationType: new IczFormControl<Nullable<EsslComponentRelation>>(EsslComponentRelation.ENCLOSURE, [IczValidators.required()]),
      count: new IczFormControl<Nullable<number>>(1, [IczValidators.required(), IczValidators.isInteger()]),
      compositionType: new IczFormControl<Nullable<AnalogCompositionType>>(AnalogCompositionType.SHEETS, [IczValidators.required()]),
      numberOfSheets: createNumberOfSheetsControl(),
      originType: new IczFormControl<Nullable<AnalogComponentOrigin>>(AnalogComponentOrigin.ANALOG_BORN, [IczValidators.required()]),
      protectiveElementSet: new IczFormControl<Nullable<AnalogProtectiveElement[]>>([], []),
      isFinal: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
      description: new IczFormControl<Nullable<string>>(null, []),
      // not in dto
      digitalComponentVersionDto: new IczFormControl<Nullable<string>>(null, []),
      status: new IczFormControl<Nullable<string>>(ComponentUploadStatus.IDLE, []),
    }), []),
    [EsslAnalogComponentTypeAsFields.MEDIUM]: new IczFormArray(() => new IczFormGroup({
      label: new IczFormControl<Nullable<string>>('Nosič', [IczValidators.required()]),
      relationType: new IczFormControl<Nullable<EsslComponentRelation>>(EsslComponentRelation.ENCLOSURE, [IczValidators.required()]),
      type: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
      isFinal: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
      description: new IczFormControl<Nullable<string>>(null, []),
    }), []),
    [EsslAnalogComponentTypeAsFields.PHYSICAL]: new IczFormArray(() => new IczFormGroup({
      label: new IczFormControl<Nullable<string>>('Předmět', [IczValidators.required()]),
      relationType: new IczFormControl<Nullable<EsslComponentRelation>>(EsslComponentRelation.ENCLOSURE, [IczValidators.required()]),
      type: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
      isFinal: new IczFormControl<Nullable<boolean>>(false, [IczValidators.required()]),
      description: new IczFormControl<Nullable<string>>(null, []),
    }), []),
  });

  const initialSize = options?.initialSize ?? 1;

  if (options.type === EsslAnalogComponentType.PAPER_COMPONENT) {
    (form.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormArray).setSize(initialSize);
    form.removeControl(EsslAnalogComponentTypeAsFields.MEDIUM);
    form.removeControl(EsslAnalogComponentTypeAsFields.PHYSICAL);
  } else if (options.type === EsslAnalogComponentType.MEDIUM_COMPONENT) {
    (form.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormArray).setSize(initialSize);
    form.removeControl(EsslAnalogComponentTypeAsFields.PAPER);
    form.removeControl(EsslAnalogComponentTypeAsFields.PHYSICAL);
  } else if (options.type === EsslAnalogComponentType.PHYSICAL_ITEM_COMPONENT) {
    (form.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormArray).setSize(initialSize);
    form.removeControl(EsslAnalogComponentTypeAsFields.PAPER);
    form.removeControl(EsslAnalogComponentTypeAsFields.MEDIUM);
  }

  return form;
}


@Component({
  selector: 'icz-analog-component-create-form',
  templateUrl: './analog-component-create-form.component.html',
  styleUrls: ['./analog-component-create-form.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class AnalogComponentCreateFormComponent implements OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  private analogComponentSubmitDataService = inject(AnalogComponentSubmitDataService);
  private apiDigitalComponentVersionService = inject(ApiDigitalComponentVersionService);
  private codebookService = inject(CodebookService);
  private translateService = inject(TranslateService);
  private changeDetectorRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  @ViewChild('fileUpload', {static: false})
  fileUpload!: ElementRef;

  @Input() analogComponentType = EsslAnalogComponentType.PAPER_COMPONENT;
  @Input() resetForm!: Nullable<EventEmitter<void>>;
  @Output() submitted = new EventEmitter<AnalogComponentToDocumentReferenceCreateDto[]>();

  private formSet$ = new BehaviorSubject(false);
  private _formGroup!: IczFormGroup;

  @Input() set form(form: IczFormGroup) {
    if (!Boolean(this.formSet$.getValue())) {
      this._formGroup = form;
      this.formSet$.next(true);
    }
  }
  get form(): IczFormGroup {
    return this._formGroup;
  }

  _config!: CreateAnalogFormConfig;
  @Input() set config(config: CreateAnalogFormConfig) {
    this._config = config;

    if (config?.setFirstComponentAsMain) {
      this.formSet$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(set => {
        if (set && this.paperComponentsControl) {
          this.paperComponentsControl.setSize(1);
          this.paperComponentsControl.controls[0].get('relationType')!.setValue(EsslComponentRelation.MAIN);
        }
      });
    }
  }

  get config(): CreateAnalogFormConfig {
    return this._config;
  }

  readonly ComponentUploadStatus = ComponentUploadStatus;

  selectFilesForAnalogRendition = new EventEmitter();
  selectFilesForDigitalComponents = new EventEmitter();
  digitalComponentsConfig = defaultDigitalFormConfig;
  indexOfDigitalContent = 0;
  esslComponentRelationOptions = enumToOptions('esslComponentRelation', EsslComponentRelation)
    .filter(o => o.value === EsslComponentRelation.MAIN || o.value === EsslComponentRelation.ENCLOSURE);
  protectiveElementOptions = enumToOptions('analogProtectiveElement', AnalogProtectiveElement);
  analogOriginTypeOptions = enumToOptions('analogComponentOrigin', AnalogComponentOrigin);

  analogComponentTypesOptions!: IczOption[];

  get paperComponents(): PaperComponentCreateDto[] {
    return this.paperComponentsControl.value;
  }

  get paperComponentsControl(): IczFormArray {
    return this.form.get(EsslAnalogComponentTypeAsFields.PAPER) as IczFormArray;
  }

  get mediumComponents(): MediumComponentCreateDto[] {
    return this.mediumComponentsControl.value;
  }

  get mediumComponentsControl(): IczFormArray {
    return this.form.get(EsslAnalogComponentTypeAsFields.MEDIUM) as IczFormArray;
  }

  get physicalItemComponents(): PhysicalItemComponentCreateDto[] {
    return this.physicalItemComponentsControl.value;
  }

  get physicalItemComponentsControl(): IczFormArray {
    return this.form.get(EsslAnalogComponentTypeAsFields.PHYSICAL) as IczFormArray;
  }

  get isPaperComponentsForm(): boolean {
    return this.analogComponentType === EsslAnalogComponentType.PAPER_COMPONENT;
  }

  get isMediumComponentsForm(): boolean {
    return this.analogComponentType === EsslAnalogComponentType.MEDIUM_COMPONENT;
  }

  get isPhysicalItemComponentsForm(): boolean {
    return this.analogComponentType === EsslAnalogComponentType.PHYSICAL_ITEM_COMPONENT;
  }

  get isStandaloneCreate(): boolean {
    return this.config?.analogCreateUsage === CreateAnalogsUsage.STANDALONE_CREATE;
  }

  get isDetailedInput(): boolean {
    return this.config?.isDetailedInput === 'true';
  }

  get formControlsBasedOnDialogType(): IczFormArray {
    if (this.isPaperComponentsForm) return this.paperComponentsControl;
    if (this.isMediumComponentsForm) return this.mediumComponentsControl;
    else return this.physicalItemComponentsControl;
  }

  get mediumOrPhysicalItemsControl(): IczFormArray {
    return this.isMediumComponentsForm ? this.mediumComponentsControl : this.physicalItemComponentsControl;
  }

  ngOnInit() {
    this.selectFilesForAnalogRendition.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => this.openSystemDialog());

    this.resetForm?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(_ => {
      if (this.isPaperComponentsForm) {
        this.paperComponentsControl.setSize(0);
      } else if (this.isMediumComponentsForm) {
        this.mediumComponentsControl.setSize(0);
      } else if (this.isPhysicalItemComponentsForm) {
        this.physicalItemComponentsControl.setSize(0);
      }
      this.formSet$.next(true);
    });

    if (this.isPaperComponentsForm) {
      this.paperComponentsControl?.controls?.forEach(paperControl => {
        this.setValuesByFormValue(paperControl as IczFormGroup);
      });
    }

    this.codebookService.analogComponentTypes().pipe(takeUntilDestroyed(this.destroyRef)).subscribe((types:AnalogComponentTypeDto[]) => {
      const form = this.isPhysicalItemComponentsForm ? AnalogComponentForm.ITEM : AnalogComponentForm.MEDIUM;
      this.analogComponentTypesOptions = analogComponentTypesToOptions(types, form, this.translateService);
    });
    this.setComponentFinality();
  }

  openSystemDialog() {
    this.fileUpload.nativeElement.click();
  }

  getPaperComponentLabelControl(paper: IczFormGroup): IczFormControl {
    return paper.get('label') as IczFormControl;
  }

  getAutofillName(index: number): Nullable<string> {
    return this.mediumComponentsControl?.controls[index].get('label')?.value;
  }

  isComponentMainComponent(i: number): boolean {
    return this.formControlsBasedOnDialogType?.controls[i]!.get('relationType')!.value === EsslComponentRelation.MAIN;
  }

  emitSelectFiles(index: number) {
    this.indexOfDigitalContent = index;
    this.selectFilesForAnalogRendition.emit();
  }

  resetHiddenFileInput() {
    this.fileUpload.nativeElement.value = null;
  }

  setDigitalContentForComponent() {
    this.analogComponentSubmitDataService.fileBlob = this.fileUpload.nativeElement.files![0];
    if (!this.analogComponentSubmitDataService.fileBlob) return;

    this.resetHiddenFileInput();
    this.analogComponentSubmitDataService.startUpload(this.formControlsBasedOnDialogType, this.indexOfDigitalContent, this.changeDetectorRef);
  }

  addAnotherComponent() {
    this.formControlsBasedOnDialogType.incrementSize();
    this.changeDetectorRef.detectChanges();
    if (this.isPaperComponentsForm) {
      const lastlyAddedPaperControl = this.paperComponentsControl.controls[this.paperComponentsControl.length - 1];
      this.setValuesByFormValue(lastlyAddedPaperControl as IczFormGroup);
      if (this.paperComponentsControl.length === 1) {
        lastlyAddedPaperControl.get('relationType')!.setValue(EsslComponentRelation.MAIN);
      }
    }
    this.setComponentFinality();
  }

  getIcon(form: IczFormGroup): Nullable<string> {
    const status = form.get('status')!.value;
    if (status === ComponentUploadStatus.UPLOADING) return null;
    else if (status === ComponentUploadStatus.ERROR) return 'error';
    else if (status === ComponentUploadStatus.SUCCESS) return 'success';
    else return null;
  }

  showSpinner(formControl: IczFormGroup) {
    const status = formControl.get('status')!.value;
    return status === ComponentUploadStatus.UPLOADING;
  }

  submit() {
    this.analogComponentSubmitDataService.submit(
      this.form,
      this,
      this.analogComponentType,
      this.loadingIndicatorService,
      this.formControlsBasedOnDialogType,
      this.submitted,
    );
  }

  analogCompositionTypeOptions: IczOption[] = enumToOptions('analogCompositionType', AnalogCompositionType);

  isAnalogCompositionSheets(index: number) {
    return isAnalogCompositionSheets(this.paperComponents[index].compositionType);
  }

  deleteDigitalComponentDraft(index: number) {
    const draftControl = this.formControlsBasedOnDialogType.controls[index];
    const versionId = draftControl.get('digitalComponentVersionDto')?.value?.id;
    if (!versionId) return;
    const status = draftControl.get('status')!;
    status.setValue(ComponentUploadStatus.UPLOADING);

    this.apiDigitalComponentVersionService.digitalComponentVersionDeleteDraftsByDigitalComponentVersionIds({
      body: [versionId]
    }).subscribe(() => {
      status.setValue(ComponentUploadStatus.IDLE);
      draftControl.get('digitalComponentVersionDto')?.setValue(null);
      draftControl.get('originType')!.setValue(AnalogComponentOrigin.ANALOG_BORN);
    });
  }

  deleteAnalogComponentDraft(index: number) {
    const minIndex = this.config.allowDeleteFirst ? 0 : 1;
    if (index >= minIndex) this.formControlsBasedOnDialogType.removeAt(index);
  }

  setValuesByFormValue(paperControl: IczFormGroup): void {
    paperControl.get('relationType')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(relationType => {
      if (relationType === EsslComponentRelation.MAIN) {
        paperControl.get('compositionType')?.setValue(AnalogCompositionType.SHEETS);
        paperControl.get('count')?.setValue(1);
        if (this.getPaperComponentLabelControl(paperControl).value === DefaultPaperComponentName.ENCLOSURE) {
          this.getPaperComponentLabelControl(paperControl).setValue(DefaultPaperComponentName.MAIN);
        }
      } else if (relationType === EsslComponentRelation.ENCLOSURE) {
        if (this.getPaperComponentLabelControl(paperControl).value === DefaultPaperComponentName.MAIN) {
          this.getPaperComponentLabelControl(paperControl).setValue(DefaultPaperComponentName.ENCLOSURE);
        }
      }
    });
    paperControl.get('compositionType')?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(compositionType => {
      setDynamicControlsByAnalogComposition(paperControl, compositionType);
    });
  }

  private setComponentFinality() {
    if (this.config?.setFieldIsFinalAsTrue === true) {
      this.formControlsBasedOnDialogType.controls.forEach(control => {
        control.get('isFinal')?.setValue(true);
      });
    }
  }

  onRelationTypeChange(value: string, changedControlIndex: number) {
    if (value === EsslComponentRelation.MAIN) {
      this.paperComponentsControl?.controls?.forEach((paperControl, index) => {
        if (index !== changedControlIndex) {
          paperControl.get('relationType')!.setValue(EsslComponentRelation.ENCLOSURE);
        }
      });
    }
  }

}
