import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatCard} from '@angular/material/card';

/**
 * A component representing a card with any rich content passed through a content slot.
 */
@Component({
  selector: 'icz-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatCard],
})
export class CardComponent {}
