import {inject} from '@angular/core';
import {UrlSerializer} from '@angular/router';
import {filter, map, take} from 'rxjs/operators';
import {CurrentSessionService} from '../../services/current-session.service';
import {ApplicationRoute} from '../../enums/shared-routes.enum';
import {isAdmin} from '../model/user-roles.model';


export function guardAdministratorRole() {
  return () => {
    const currentSessionService = inject(CurrentSessionService);
    const urlSerializer = inject(UrlSerializer);

    return currentSessionService.currentUserFunctionalPosition$.pipe(filter(Boolean), take(1), map(currentUserInfo => {
      if (isAdmin(currentUserInfo)) {
        return true;
      } else {
        return urlSerializer.parse(ApplicationRoute.ROOT);
      }
    }));
  };
}
