import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  IczFormArray,
  IczFormControl,
  IczFormGroup,
  IczOption,
  locateOptionByValue,
  TreeItemSelectionStrategy
} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {
  OrganizationalStructureOption,
  OrganizationalStructureService
} from '../../../core/services/organizational-structure.service';
import {CurrentSessionService} from '../../../services';
import {EsslApplicationRole} from '|api/commons';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {map, Observable} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

export interface MemberWithPermission {
  functionalPositionCode: string;
  permission: string;
}

function createFunctionalPositionForm() {
  return new IczFormGroup({
    id: new IczFormControl<Nullable<number>>(),
    code: new IczFormControl<Nullable<string>>(),
    name: new IczFormControl<Nullable<string>>(),
    permission: new IczFormControl<Nullable<string>>(null),
    isReadonly: new IczFormControl<Nullable<boolean>>(null),
  });
}

export function getMembersWithPermissionsFormControls() {
  return {
    permissions: new IczFormControl<Nullable<string>>(),
    functionalPlacesToAssign: new IczFormControl<Nullable<string[]>>(),
    functionalPositions: new IczFormArray<ReturnType<typeof createFunctionalPositionForm>>(() => {
      return createFunctionalPositionForm();
    }, [])
  };
}

export type MemberWithPermissionForm = ReturnType<typeof createFunctionalPositionForm>;
export type MembersWithPermissionsForm = IczFormGroup<ReturnType<typeof getMembersWithPermissionsFormControls>>;

@Component({
  selector: 'icz-members-with-permissions-table',
  templateUrl: './members-with-permissions-table.component.html',
  styleUrls: ['./members-with-permissions-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MembersWithPermissionsTableComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private organizationalStructureService = inject(OrganizationalStructureService);
  private currentSessionService = inject(CurrentSessionService);
  private destroyRef = inject(DestroyRef);

  // a form with controls: permissions, functionalPlacesToAssign, functionalPositions
  @Input({required: true})
  form!: IczFormGroup;

  @Input({required: true})
  permissionOptions: IczOption[] = [];

  @Input({required: true})
  updateModeMemberPermissions: Nullable<MemberWithPermission[]>;

  @Input()
  showPermissionTooltip = true;

  @Input()
  disableSelfPermissionAssignment = false;

  @Input()
  restrictToApplicationRole: Nullable<EsslApplicationRole>;

  @Input()
  permissionTooltipTranslationPrefix = '';

  @Output()
  membersCountChanged = new EventEmitter<number>();

  allFunctionalPlacesOptions: OrganizationalStructureOption[] = [];
  functionalPlacesOptions: OrganizationalStructureOption[] = this.allFunctionalPlacesOptions;
  alreadySelectedPlaces: Array<number> = [];

  get functionalPositionsForm() {
    return this.form.get('functionalPositions') as IczFormArray;
  }

  get isAddPositionButtonDisabled() {
    return isNil(this.form.get('functionalPlacesToAssign')?.value) || (this.permissionOptions.length > 0 && isNil(this.form.get('permissions')?.value));
  }

  get isSomePermissionReadOnly() {
    return (this.functionalPositionsForm.getRawValue() ?? []).some(fpForm => fpForm.isReadonly);
  }

  ngOnInit() {
    this.functionalPositionsForm.valueChanges.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(val => val.length),
      distinctUntilChanged(),
    ).subscribe(functionalPositionsCount => {
      this.membersCountChanged.emit(functionalPositionsCount);
    });

    let optionsSource$: Observable<OrganizationalStructureOption[]>;

    if (this.restrictToApplicationRole) {
      optionsSource$ = this.organizationalStructureService.functionalPositionsOptions().pipe(
        map(options => options.filter(
          o => this.restrictToApplicationRole ? o.data!.applicationRoles!.includes(this.restrictToApplicationRole) : true
        ))
      );
    }
    else {
      optionsSource$ = this.organizationalStructureService.functionalPositionTreeOptions();
    }

    this.loadingService.doLoading(
      optionsSource$,
      this
    ).subscribe(options => {
      this.allFunctionalPlacesOptions = options.map(o => {
        if (this.disableSelfPermissionAssignment) {
          const isUnassignable = o.originId === 'fp' && this.currentSessionService.currentUserFunctionalPosition!.id === o.value;

          return {
            ...o,
            disabled: isUnassignable,
            disableReason: isUnassignable ? 'Nemůžete přiřadit oprávnění sami sobě.' : undefined,
          };
        }
        else {
          return o;
        }
      });

      if (this.updateModeMemberPermissions) {
        const wasFormInitiallyEmpty = this.functionalPositionsForm.length === 0;

        for (const memberPermission of this.updateModeMemberPermissions) {
          const fp = this.allFunctionalPlacesOptions.find(p => p.data!.code === memberPermission.functionalPositionCode);
          if (fp) {
            if (wasFormInitiallyEmpty) {
              const group = this.functionalPositionsForm.incrementSize();
              group.patchValue({
                name: fp.label,
                id: fp.id,
                permission: memberPermission.permission,
                code: memberPermission.functionalPositionCode
              });
            }
            this.alreadySelectedPlaces.push(fp.id!);
          }
        }

        this.form.markAsTouched();
      }

      this.updatePlaceOptions();
    });
  }

  getFunctionalPositionNameValue(form: IczFormGroup) {
    return form.get('name')!.value;
  }

  addWorkerWithPermissions() {
    const selectedPositions = this.form.get('functionalPlacesToAssign')!.value;
    const selectedPermission = this.form.get('permissions')!.value;
    selectedPositions?.forEach((posValue: number) => {
      this.functionalPositionsForm.incrementSize();
      const group = this.functionalPositionsForm.controls[this.functionalPositionsForm.length - 1];
      const selectedOption = locateOptionByValue(this.functionalPlacesOptions, posValue, 'fp')!;
      group.patchValue({
        name: selectedOption.label,
        id: selectedOption.id,
        permission: selectedPermission,
        code: selectedOption.data!.code
      });
      this.alreadySelectedPlaces.push(posValue);
    });
    this.updatePlaceOptions();
    this.form.get('functionalPlacesToAssign')!.setValue(null);
    this.functionalPositionsForm!.markAsTouched();
    this.form.get('permissions')!.setValue(null);
  }

  deleteWorker(index: number) {
    const deletedWorkerValue = this.functionalPositionsForm.controls[index].get('id')?.value;
    const toBeDeletedIndex = this.alreadySelectedPlaces.findIndex(placeValue => placeValue === deletedWorkerValue);
    if (toBeDeletedIndex > -1) this.alreadySelectedPlaces.splice(toBeDeletedIndex, 1);
    this.updatePlaceOptions();
    this.functionalPositionsForm.removeAt(index);
    this.functionalPositionsForm!.markAsTouched();
  }

  updatePlaceOptions() {
    this.functionalPlacesOptions = this.allFunctionalPlacesOptions.map(posOption => {
      const isAlreadySelected = this.alreadySelectedPlaces.includes(posOption.id!);

      if (isAlreadySelected) {
        return {
          ...posOption,
          disabled: true,
          disableReason: 'Funkční místo bylo již vybráno.',
        };
      }
      else {
        return posOption;
      }
    });
  }

  protected readonly TreeItemSelectionStrategy = TreeItemSelectionStrategy;
}
