import {Directive, inject, Injector} from '@angular/core';

/**
 * A utility directive used for extracting node injectors from Angular template nodes.
 * Useful in very specific technological scenarios, usually not needed.
 */
@Directive({
  selector: '[iczGetNodeInjector]',
  exportAs: 'iczGetNodeInjector',
  standalone: true,
})
export class GetNodeInjectorDirective {

  injector = inject(Injector);

}
