import {Component, ElementRef, EventEmitter, HostListener, inject, Input, Output, TemplateRef} from '@angular/core';
import {IAutocompleteListItemContext} from '../form-autocomplete.model';
import {OptionItemComponent} from './option-item/option-item.component';
import {
  FormAutocompleteListTextItemComponent
} from '../form-autocomplete-list-text-item/form-autocomplete-list-text-item.component';
import {IczOption} from '../../form-elements.model';

/**
 * A reusable list of options, rendering all the aspects of option lists.
 */
@Component({
  selector: 'icz-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss'],
  standalone: true,
  imports: [
    OptionItemComponent,
    FormAutocompleteListTextItemComponent,
  ]
})
export class OptionsListComponent<T> {

  private elementRef = inject(ElementRef);

  /**
   * Option list.
   */
  @Input({required: true})
  options: IczOption<T, any>[] = [];
  /**
   * List of selected options. The inner objects must be reference-equal to the objects in the options array.
   */
  @Input()
  selectedOptions: IczOption<T, any>[] = [];
  /**
   * If true, the list items will render checkboxes.
   */
  @Input()
  isMultiselect: Nullable<boolean> = false;
  /**
   * Makes all options and their checkboxes greyed out regardless of their disabled properties.
   */
  @Input()
  readonlyMode = false;
  /**
   * Custom item template for rendering custmization.
   */
  @Input()
  listItemTemplate!: TemplateRef<IAutocompleteListItemContext>;
  /**
   * Height cap in px. Fine in most scenarios.
   */
  @Input()
  maxListHeight = 280;
  /**
   * @see OptionItemComponent.hideDefaultTooltip
   */
  @Input()
  hideDefaultTooltips = false;
  /**
   * Emits a subset of passed options which corresponds to the actual selection in the option list.
   */
  @Output()
  selectionChanged = new EventEmitter<IczOption<T, any>[]>();

  private activeOption: Nullable<IczOption<T, any>> = null;

  @HostListener('document:click', ['$event'])
  protected clickout(event: MouseEvent) {
    if(!this.elementRef.nativeElement.contains(event.target)) {
      this.resetActiveOption();
    }
  }

  /**
   * Chooses previous option above current option for operation.s
   * Can be bound to keyboard shortcuts.
   */
  selectPrevOption() {
    if (this.activeOption) {
      const optionIndex = this.options.indexOf(this.activeOption);

      if (optionIndex > 0) {
        this.activeOption = this.options[optionIndex - 1];
      }
    }
  }

  /**
   * Chooses next option below current option for operations.
   * Can be bound to keyboard shortcuts.
   */
  selectNextOption() {
    if (this.activeOption) {
      const optionIndex = this.options.indexOf(this.activeOption);

      if (optionIndex < this.options.length - 1) {
        this.activeOption = this.options[optionIndex + 1];
      }
    } else {
      this.selectFirstOption();
    }
  }

  /**
   * Marks the current option as selected.
   * Can be bound to keyboard shortcuts.
   */
  selectCurrentOption() {
    if (this.activeOption) {
      this.optionSelected(this.activeOption);
    }
  }

  protected optionSelected(option: IczOption<T, any>) {
    if (this.readonlyMode || option.disabled || option.isSeparator) {
      return;
    }

    if (this.isMultiselect) {
      if (!this.isSelected(option)) {
        this.selectedOptions!.push(option);
      }
      else {
        const index = this.selectedOptions.findIndex(op => op.value === option.value);
        this.selectedOptions!.splice(index,1);
      }
    }
    else {
      this.selectedOptions = [option];
    }

    this.selectionChanged.emit(this.selectedOptions!);
  }

  protected selectFirstOption() {
    if (this.activeOption === null && this.options.length) {
      this.activeOption = this.options[0];
    }
  }

  protected resetActiveOption() {
    this.activeOption = null;
  }

  protected mouseEnteredOption(option: IczOption<T>) {
    this.activeOption = option;
  }

  protected isSelected(option: IczOption<T, any>) {
    return !this.readonlyMode && this.selectedOptions?.some(op => op.value === option.value);
  }

  protected isActive(option: IczOption<T, any>) {
    return !this.readonlyMode && this.activeOption === option && !this.activeOption.isSeparator;
  }

}
