import {inject, Injectable} from '@angular/core';
import {MessageType, ToastService} from '../../../components/notifications/toast.service';
import {
  WebSocketNotification,
  WebSocketNotificationsService
} from '../../../components/notifications/web-socket-notifications.service';
import {
  InternalNotificationBulkComponentActionMessageCode,
  InternalNotificationMessageCode
} from './internal-notification.enum';
import {
  EsslComponentToastType,
  SComponnentAnonymizationTemplateData,
  SDigitalComponentSimpleErrorTemplateData,
  SDigitalComponentTemplateData
} from './essl-component-toast.service';
import {forIn} from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class EsslComponentNotificationsService {

  private toastService = inject(ToastService);
  private wsNotificationsService = inject(WebSocketNotificationsService);

  messageCodeToToastMapping: Record<InternalNotificationBulkComponentActionMessageCode, EsslComponentToastType> = {
    [InternalNotificationBulkComponentActionMessageCode.CONVERSION_BULK_SUCCESS]: EsslComponentToastType.CONVERSION_SUCCESS,
    [InternalNotificationBulkComponentActionMessageCode.CONVERSION_BULK_ERROR]: EsslComponentToastType.CONVERSION_TO_OUTPUT_ERROR,
    [InternalNotificationBulkComponentActionMessageCode.EXTRACTION_BULK_SUCCESS]: EsslComponentToastType.COMPONENT_BULK_CONTAINER_EXTRACT_SUCCESS,
    [InternalNotificationBulkComponentActionMessageCode.EXTRACTION_BULK_ERROR]: EsslComponentToastType.COMPONENT_BULK_CONTAINER_EXTRACT_ERROR,
    [InternalNotificationBulkComponentActionMessageCode.CHANGE_WITH_CLAUSE_BULK_SUCCESS]: EsslComponentToastType.CONVERSION_WITH_CLAUSE_SUCCESS,
    [InternalNotificationBulkComponentActionMessageCode.CHANGE_WITH_CLAUSE_BULK_ERROR]: EsslComponentToastType.CONVERSION_TO_OUTPUT_WITH_CLAUSE_ERROR,
    [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_LOCK_SUCCESS]: EsslComponentToastType.COMPONENT_LOCKED,
    [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_LOCK_ERROR]: EsslComponentToastType.COMPONENT_LOCKED_ERROR,
    [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_UNLOCK_SUCCESS]: EsslComponentToastType.COMPONENT_UNLOCKED,
    [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_UNLOCK_ERROR]: EsslComponentToastType.COMPONENT_UNLOCKED_ERROR,
    [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_DELETE_SUCCESS]: EsslComponentToastType.COMPONENT_DELETION_SUCCESS,
    [InternalNotificationBulkComponentActionMessageCode.BULK_COMPONENT_DELETE_ERROR]: EsslComponentToastType.COMPONENT_DELETION_ERROR,
  };

  initialize() {

    forIn(InternalNotificationBulkComponentActionMessageCode, (value, key) => {
      let messageType = MessageType.INFO;

      if (key.endsWith('SUCCESS')) {
        messageType = MessageType.INFO;
      } else if (key.endsWith('ERROR')) {
        messageType = MessageType.ERROR;
      }

      this.wsNotificationsService.registerMessageHandler(
        value,
        (parsedBody: WebSocketNotification) => {
          this.toastService.dispatchBulkToast(
            messageType,
            this.messageCodeToToastMapping[value],
            parsedBody.parameters,
            SDigitalComponentTemplateData,
            false,
          );
        }
      );
    });

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.EXECUTION_DATE_CLAUSE_CREATE_SUCCESS,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.EXECUTION_CLAUSE_CREATION_SUCCESS,
          parsedBody.parameters,
          SDigitalComponentTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.EXECUTION_DATE_CLAUSE_CREATE_ERROR,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.ERROR,
          EsslComponentToastType.EXECUTION_CLAUSE_CREATION_ERROR,
          parsedBody.parameters,
          SDigitalComponentSimpleErrorTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.COMPONENT_ANONYMIZATION_COMPLETED,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.COMPONENT_ANONYMIZATION_COMPLETED,
          parsedBody.parameters,
          SComponnentAnonymizationTemplateData,
        );
      }
    );

    this.wsNotificationsService.registerMessageHandler(
      InternalNotificationMessageCode.COMPONENT_ANONYMIZATION_CANCELED,
      (parsedBody: WebSocketNotification) => {
        this.toastService.dispatchBulkToast(
          MessageType.INFO,
          EsslComponentToastType.COMPONENT_ANONYMIZATION_CANCELED,
          parsedBody.parameters,
          SComponnentAnonymizationTemplateData,
        );
      }
    );
  }

}
