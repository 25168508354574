import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {
  ColumnDefinition,
  extendDefaultTableConfig,
  FilterType,
  TableColumnsData,
  TableReservedColumns
} from '@icz/angular-table';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczSimpleTableDataSource} from '../../../services';
import {enumToOptions, RegistryOfficeToastService} from '../../../core';
import {
  RegistryOfficeTransferIncidentDialogComponent
} from './registry-office-transfer-incident-dialog/registry-office-transfer-incident-dialog.component';
import {DocumentFiltersDataService} from '../document-table/components/document-filters/document-filters-data.service';
import {delay, of} from 'rxjs';
import {RegistryOfficeTransferIncidentView} from '../ref-number-link/ref-number-link.component';
import {RegistryOfficeTransferIncidentDto} from '|api/document';
import {RegistryOfficeTransferErrorSource, RegistryOfficeTransferIncidentState} from '|api/commons';
import {SearchRecordSourceRegistryOfficeTransferIncidentDto} from '|api/elastic';
import {ELASTIC_RELOAD_DELAY} from '../document-toolbar/services/toolbar-common.utils';
import {InternalNotificationKey} from '|api/notification';
import {IczModalService} from '@icz/angular-modal';

type RegistryOfficeTransferIncidentsTableColumnId = (
  keyof RegistryOfficeTransferIncidentDto |
  'auditInfo.createdAt' |
  TableReservedColumns
);

export enum RegistryOfficeTransferIncidentsTableView {
  PROBLEM_INCIDENTS_LIST = 'PROBLEM_INCIDENTS_LIST',
  OBJECT_INCIDENTS = 'OBJECT_INCIDENTS',
}

enum RegistryOfficeTransferIncidentsTableColumn {
  SOURCE = 'source',
  ERROR_ID = 'errorId',
  NAME = 'name',
  STATE = 'state',
  CREATED_AT = 'auditInfo.createdAt',
  OBJECT_CLASS = 'objectClass',
  REF_NUMBER = 'refNumber',
  ENTITY_NAME = 'entityName',
  REGISTRY_OFFICE_TRANSFER_PROBLEM_ID = 'registryOfficeTransferProblemId'
}

const REGISTRY_OFFICE_TRANSFER_INCIDENTS_COLUMNSETS: Record<RegistryOfficeTransferIncidentsTableView, RegistryOfficeTransferIncidentsTableColumnId[]> = {
  [RegistryOfficeTransferIncidentsTableView.PROBLEM_INCIDENTS_LIST]: [
    RegistryOfficeTransferIncidentsTableColumn.SOURCE,
    RegistryOfficeTransferIncidentsTableColumn.ERROR_ID,
    RegistryOfficeTransferIncidentsTableColumn.NAME,
    RegistryOfficeTransferIncidentsTableColumn.STATE,
    RegistryOfficeTransferIncidentsTableColumn.CREATED_AT,
  ],
  [RegistryOfficeTransferIncidentsTableView.OBJECT_INCIDENTS]: [
    TableReservedColumns.SELECTION,
    RegistryOfficeTransferIncidentsTableColumn.OBJECT_CLASS,
    RegistryOfficeTransferIncidentsTableColumn.REF_NUMBER,
    RegistryOfficeTransferIncidentsTableColumn.ENTITY_NAME,
    RegistryOfficeTransferIncidentsTableColumn.SOURCE,
    RegistryOfficeTransferIncidentsTableColumn.ERROR_ID,
    RegistryOfficeTransferIncidentsTableColumn.NAME,
    RegistryOfficeTransferIncidentsTableColumn.STATE,
    RegistryOfficeTransferIncidentsTableColumn.REGISTRY_OFFICE_TRANSFER_PROBLEM_ID,
    RegistryOfficeTransferIncidentsTableColumn.CREATED_AT,
  ],
};

@Component({
  selector: 'icz-registry-office-transfer-incidents-table',
  templateUrl: './registry-office-transfer-incidents-table.component.html',
  styleUrls: ['./registry-office-transfer-incidents-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RegistryOfficeTransferIncidentsTableComponent implements OnInit {

  private modalService = inject(IczModalService);
  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private registryOfficeToastService = inject(RegistryOfficeToastService);
  loadingIndicatorService = inject(LoadingIndicatorService);

  @Input({ required: true })
  tableId!: string;
  @Input({ required: true })
  label!: string;
  @Input({ required: true })
  noDataLabel!: string;
  @Input({ required: true })
  dataSource!: IczSimpleTableDataSource<SearchRecordSourceRegistryOfficeTransferIncidentDto>;
  @Input({ required: true })
  view!: RegistryOfficeTransferIncidentsTableView;

  @Output()
  selectedRowsChanged = new EventEmitter<SearchRecordSourceRegistryOfficeTransferIncidentDto[]>();

  transferIncidentStateEnumPrefix = 'en.registryOfficeTransferIncidentState.';

  columnsData!: TableColumnsData<RegistryOfficeTransferIncidentsTableColumnId>;

  tableConfig = extendDefaultTableConfig<RegistryOfficeTransferIncidentsTableColumn>({
    defaultSort: {
      fieldName: RegistryOfficeTransferIncidentsTableColumn.CREATED_AT,
      descending: true,
    }
  });

  readonly RegistryOfficeTransferIncidentState = RegistryOfficeTransferIncidentState;
  readonly RegistryOfficeTransferIncidentView = RegistryOfficeTransferIncidentView;
  readonly RegistryOfficeTransferIncidentsTableColumn = RegistryOfficeTransferIncidentsTableColumn;

  ngOnInit() {
    this.initColumnsData();
  }

  rowClicked(incident: RegistryOfficeTransferIncidentDto) {
    this.modalService.openComponentInModal<RegistryOfficeTransferIncidentDto, RegistryOfficeTransferIncidentDto>({
      component: RegistryOfficeTransferIncidentDialogComponent,
      modalOptions: {
        width: 900,
        height: 700,
        titleTemplate: 'Detail chyby při předání do spisovny',
      },
      data: incident,
    }).pipe(
      delay(ELASTIC_RELOAD_DELAY),
    ).subscribe(result => {
      if (result) {
        this.dataSource.reload(true);
        this.registryOfficeToastService.dispatchIncidentUpdated({
          [InternalNotificationKey.NAME]: result.name ?? '',
        });
      }
    });
  }

  private initColumnsData() {
    const applicableColumnIds = REGISTRY_OFFICE_TRANSFER_INCIDENTS_COLUMNSETS[this.view];
    const columnDefs: ColumnDefinition<RegistryOfficeTransferIncidentsTableColumnId>[] = [];

    for (const columnId of applicableColumnIds) {
      if (columnId === TableReservedColumns.SELECTION) {
        columnDefs.push({
          id: TableReservedColumns.SELECTION,
          label: 'Výběr',
          disableSort: true,
          filterType: FilterType.NONE,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.SOURCE) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.SOURCE,
          label: 'Zdroj chyby',
          disableSort: true,
          filterType: FilterType.ENUM,
          list: enumToOptions('registryOfficeTransferErrorSource', RegistryOfficeTransferErrorSource),
          allowTranslation: true,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.ERROR_ID) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.ERROR_ID,
          label: 'ID chyby',
          filterType: FilterType.TEXT,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.NAME) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.NAME,
          label: 'Název',
          filterType: FilterType.TEXT,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.STATE) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.STATE,
          label: 'Stav',
          icon: 'checked',
          disableSort: true,
          fixedWidth: 35,
          filterType: FilterType.ENUM,
          list: enumToOptions('registryOfficeTransferIncidentState', RegistryOfficeTransferIncidentState),
          allowTranslation: true,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.CREATED_AT) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.CREATED_AT,
          label: 'Datum založení',
          filterType: FilterType.DATE,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.OBJECT_CLASS) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.OBJECT_CLASS,
          label: 'Druh',
          fixedWidth: 40,
          filterType: FilterType.ENUM,
          disableSort: true,
          list: this.documentFiltersDataService.objectClassOptions,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.REF_NUMBER) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.REF_NUMBER,
          label: 'ČJ/SZ',
          filterType: FilterType.TEXT,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.ENTITY_NAME) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.ENTITY_NAME,
          label: 'Věc/Název',
          filterType: FilterType.TEXT,
        });
      }
      else if (columnId === RegistryOfficeTransferIncidentsTableColumn.REGISTRY_OFFICE_TRANSFER_PROBLEM_ID) {
        columnDefs.push({
          id: RegistryOfficeTransferIncidentsTableColumn.REGISTRY_OFFICE_TRANSFER_PROBLEM_ID,
          label: 'Navázaný problém',
          disableSort: true,
          filterType: FilterType.CODEBOOK,
          list$: of([
            {
              value: 1,
              label: 'DESA ERR_SPIS_001',
            },
            {
              value: 2,
              label: 'DESA ERR_FORMAT_001',
            }
          ])
        });
      }
      // else nothing
    }

    this.columnsData = new TableColumnsData<RegistryOfficeTransferIncidentsTableColumnId>(columnDefs);
  }

}
