import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Observable} from 'rxjs';
import {ApiDocumentService} from '|api/document';
import {ApiOwnConsignmentService, OwnConsignmentBulkHandleOperationsDto, OwnConsignmentWithdrawalDto} from '|api/sad';
import {
  WithdrawForCorrectionDialogData,
  WithdrawForCorrectionDialogResult,
  WithdrawForCorrectionDialogResultType
} from './withdraw-for-correction-dialog.service';
import {
  GenericOwnConsignment,
  isOwnDigitalElasticConsignment,
  isOwnInternalDigitalElasticConsignment,
  isOwnInternalPaperElasticConsignment, isOwnMultiPaperElasticConsignment,
  isOwnOfficeDeskElasticConsignment,
  isOwnPaperElasticConsignment
} from '../../../own-consignment-table/model/own-consignment-model';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {constructBulkModalTitle} from '../../../../../lib/utils';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {WITHOUT_REF_NUMBER} from '../../../shared-business-components.model';


@Component({
  selector: 'icz-withdraw-for-correction',
  templateUrl: './withdraw-for-correction-dialog.component.html',
  styleUrls: ['./withdraw-for-correction-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WithdrawForCorrectionDialogComponent implements OnInit {

  private modalRef = injectModalRef<Nullable<WithdrawForCorrectionDialogResult>>();
  private apiOwnConsignmentService = inject(ApiOwnConsignmentService);
  private apiDocumentService = inject(ApiDocumentService);
  private translateService = inject(TranslateService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected modalData = injectModalData<WithdrawForCorrectionDialogData>();

  form = new IczFormGroup({
    withdrawReason: new IczFormControl<Nullable<string>>(null, [IczValidators.required()]),
  });

  ngOnInit() {
    const defaultTitle = constructBulkModalTitle('Stažení zásilky k nápravě');
    if (this.modalData.consignments) {
      const isBulk = this.modalData.consignments.length > 1;
      if (isBulk) {
        this.modalRef.setModalTitle({
          title: defaultTitle,
          titleContext: {count: this.modalData.consignments.length.toString()},
        });
      }
      else {
        if (this.modalData.documentId) {
          this.apiDocumentService.documentFindById({id: this.modalData.documentId}).subscribe(doc => {
            this.modalRef.setModalTitle({
              title: 'Stažení zásilky k nápravě {{refNumber}}: {{subject}}',
              titleContext: {
                refNumber: doc.refNumber ?? this.translateService.instant(WITHOUT_REF_NUMBER),
                subject: doc.subject
              },
            });
          });
        }
        else {
          this.modalRef.setModalTitle({
            title: defaultTitle,
          });
        }
      }
    }
  }

  withdrawForCorrection() {
    const formValue = this.form.getRawValue() as OwnConsignmentWithdrawalDto;

    const isBulk = this.modalData.consignments.length > 1;
    let req$: Observable<GenericOwnConsignment | void>;

    if (isBulk) {
      const reqData: OwnConsignmentBulkHandleOperationsDto = {
        reason: formValue.withdrawReason,
        bulkDataDto: this.modalData.consignments.map(c => {
          return {id: c.id, consignmentType: c.consignmentType};
        }),
      };

      req$ = this.apiOwnConsignmentService.ownConsignmentBulkWithdraw({body: reqData});
    }
    else {
      const reqData = {id: this.modalData.consignments[0].id, body: formValue};
      const c = this.modalData.consignments[0];

      if (isOwnPaperElasticConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawPaper(reqData);
      }
      else if (isOwnMultiPaperElasticConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawMultiPaper(reqData);
      }
      else if (isOwnOfficeDeskElasticConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawOfficeDesk(reqData);
      }
      else if (isOwnInternalDigitalElasticConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawInternalDigital(reqData);
      }
      else if (isOwnInternalPaperElasticConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawInternalPaper(reqData);
      }
      else if (isOwnDigitalElasticConsignment(c)) {
        req$ = this.apiOwnConsignmentService.ownConsignmentWithdrawDigital(reqData);
      }
      else {
        throw new Error('Wrong type of own consignment for withdraw!');
      }
    }

    this.loadingIndicatorService.doLoading(
      req$,
      this,
    ).subscribe(ownConsignment => {
      this.modalRef.close({
        resultType: WithdrawForCorrectionDialogResultType.WithdrawnForCorrection,
        consignment: ownConsignment,
      } as WithdrawForCorrectionDialogResult);
    });
  }

  close() {
    this.modalRef.close(null);
  }

}
