import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ConsignmentType} from '|api/commons';
import {IconSize} from '@icz/angular-essentials';
import {getConsignmentIcon} from '../shared-document.utils';

@Component({
  selector: 'icz-own-consignment-type-icon',
  templateUrl: './own-consignment-type-icon.component.html',
  styleUrls: ['./own-consignment-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OwnConsignmentTypeIconComponent {

  @Input({required: true}) consignmentType!: ConsignmentType;
  @Input({required: true}) iconSize!: IconSize;

  readonly getConsignmentIcon = getConsignmentIcon;
  readonly ConsignmentType = ConsignmentType;

  getConsignmentTypeTooltip(consignmentType: ConsignmentType) {
    return `en.consignmentType.${consignmentType}`;
  }

}
