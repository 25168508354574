import {ChangeDetectorRef, Component, DestroyRef, forwardRef, inject, Input} from '@angular/core';
import {TranslateParser} from '@ngx-translate/core';
import {
  FormFieldComponent,
  GenericValueAccessor,
  IczOption,
  isRequired,
  PrimitiveControlValueType,
  PrimitiveValueFormField,
  ValidationErrorsListComponent,
  VALUE_ACCESSIBLE_COMPONENT
} from '@icz/angular-form-elements';
import {TableColumnsData} from '../table-columns-data';
import {
  PopupSelectorTableDialogComponent,
  PopupSelectorTableDialogData
} from '../popup-selector-table-dialog/popup-selector-table-dialog.component';
import {IczInMemoryDatasource} from '../icz-in-memory.datasource';
import {defaultPageSize, IczTableDataSource} from '../table.datasource';
import {IczModalService} from '@icz/angular-modal';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {IconComponent, TooltipDirective} from '@icz/angular-essentials';
import {FilterOperator} from '../table.utils';

/**
 * @internal
 */
type TableSelectorOption = IczOption|Record<string, any>;

/**
 * @internal
 */
interface SelectedValue {
  modelValue: PrimitiveControlValueType;
  viewValue: string;
  completeValue: any;
}


@Component({
  selector: 'icz-form-popup-table-selector',
  templateUrl: './form-popup-table-selector.component.html',
  styleUrls: ['./form-popup-table-selector.component.scss'],
  standalone: true,
  imports: [
    ValidationErrorsListComponent,
    FormFieldComponent,
    IconComponent,
    TooltipDirective,
  ],
  hostDirectives: [{
    directive: GenericValueAccessor,
    inputs: ['formControlName'],
  }],
  providers: [{
    provide: VALUE_ACCESSIBLE_COMPONENT,
    useExisting: forwardRef(() => FormPopupTableSelectorComponent),
  }],
})
export class FormPopupTableSelectorComponent<TColumnId extends string> extends PrimitiveValueFormField {

  private modalService = inject(IczModalService);
  private translateParser = inject(TranslateParser);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  /**
   * If the control is clearable, a trash icon is shown after hovering
   * the control to allow quickly clearing control value using that.
   */
  @Input()
  clearable = true;
  /**
   * Title of the popup window.
   */
  @Input({required: true})
  popupTitle = '';
  /**
   * Table title rendered directly above the table element.
   */
  @Input({required: true})
  tableTitle = '';
  /**
   * Table ID used for table user options storage.
   */
  @Input({required: true})
  tableId = '';
  /**
   * Orange table tag to show in the popup table.
   */
  @Input()
  tableTag = '';
  /**
   * A flag controlling if to show fulltext search box in the popup table.
   */
  @Input()
  showFulltextSearch = false;
  /**
   * Shows a help placeholder in fulltext search field.
   * Effective only if showFulltextSearch is TRUE.
   */
  @Input()
  fulltextSearchFieldPlaceholder = '';
  /**
   * Default filters to initially show in the popup table.
   */
  @Input()
  defaultFilterColumns: TColumnId[] = [];
  /**
   * Table options. Ideal for small eager-loaded and cached datasets.
   * If the dataset is bigger, consider using dataSource.
   */
  @Input()
  set options(newValue: TableSelectorOption[]) {
    this._options = newValue;
    this.renderSelectedValueFromOptions();
  }
  get options(): TableSelectorOption[] {
    return this._options;
  }
  /**
   * DataSource can be specified in case you prefer lazyloading inner
   * table data or need richer objects than just plain options.
   * If dataSource gets passed to the component along with options,
   * dataSource gets precedence as the source of the data for the table.
   */
  @Input()
  set dataSource(newDataSource: IczTableDataSource<any>) {
    this.allValuesDataSource = newDataSource;
    this.renderSelectedValueFromDataSource();
  }
  /**
   * In datasource mode, there's a possilibity to pass a separate datasource used
   * for inner table, effectively used as an analogy for Option#isHidden.
   */
  @Input()
  selectableValuesDataSource: Nullable<IczTableDataSource<any>>;
  /**
   * Schema of the popup table
   */
  @Input({required: true})
  tableColumnsData = new TableColumnsData<TColumnId>([]);
  /**
   * Controls what gets selected from table rows as view value (value in the control box).
   * Usually needs a reconfiguration in case we use a dataSourceFactory. Default should be fine if we use options.
   * ViewValueTemplate can use simple interpolation using { ... } block
   * (single curly braces, not Mustache cause it collides with Angular interpolation).
   */
  @Input()
  viewValueTemplate: string = '{label}';
  /**
   * Controls what gets selected from table rows as model value (value of FormControl).
   * Usually needs a reconfiguration in case we use a dataSourceFactory. Default should be fine if we use options.
   */
  @Input()
  modelValueKey: string = 'value';
  /**
   * Model value, can be used for template data binding
   */
  @Input()
  override set value(newValue: PrimitiveControlValueType) {
    super.value = newValue;

    if (this.allValuesDataSource) {
      this.renderSelectedValueFromDataSource();
    }
    else if (this._options?.length) {
      this.renderSelectedValueFromOptions();
    }
  }
  override get value(): PrimitiveControlValueType {
    return this._value as PrimitiveControlValueType;
  }
  /**
   * A special visually distinguished label of the field to the right of the ordinary label.
   */
  @Input()
  rightLabel: Nullable<string>;
  /**
   * Tooltip of the right label.
   * Applicable only if rightLabel is non-null.
   */
  @Input()
  rightLabelTooltip: Nullable<string>;
  /**
   * A flag determining if clicking the right label will open a popover.
   * Applicable only if rightLabel is non-null.
   */
  @Input()
  showRightLabelPopupOnClick = false;

  /**
   * Whole selected object along with its view value and model value
   */
  selectedValue: Nullable<SelectedValue> = null;

  protected get viewValue(): string {
    return isNil(this.selectedValue) ? '' : this.selectedValue!.viewValue;
  }

  protected get required(): boolean {
    return isRequired(this.control);
  }

  protected get isClearButtonVisible() {
    return (this.clearable && !this.required && !this.fieldDisabled && this.value);
  }

  private get dataSourceForInnerTable() {
    return this.selectableValuesDataSource ?? this.allValuesDataSource;
  }

  private _options: TableSelectorOption[] = [];
  private allValuesDataSource!: IczTableDataSource<any>;

  protected openTable() {
    if (!this.fieldDisabled) {
      if (!this.tableColumnsData?.columnDefinitions?.length) {
        console.warn('Table schema for icz-form-popup-table-selector is missing.');
      }

      this.modalService.openComponentInModal({
        component: PopupSelectorTableDialogComponent,
        modalOptions: {
          width: 1000,
          height: 600,
          titleTemplate: this.popupTitle || this.label,
          disableAutoMargin: true,
        },
        data: {
          tableId: this.tableId,
          tableTitle: this.tableTitle,
          tableTitleTag: this.tableTag,
          schema: this.tableColumnsData,
          tableConfig: {
            defaultFilterColumns: this.defaultFilterColumns as string[],
            toolbarConfig: {
              showFulltextSearch: this.showFulltextSearch,
              fulltextSearchFieldPlaceholder: this.fulltextSearchFieldPlaceholder,
            }
          },
          dataSource: (
            this.dataSourceForInnerTable ??
            new IczInMemoryDatasource(() => this._options.filter(o => !o.isHidden))
          ),
        } as PopupSelectorTableDialogData<TColumnId>,
      }).subscribe((resultValue: any) => {
        if (resultValue) {
          if (resultValue[this.modelValueKey] === undefined) {
            console.warn(`FormPopupTableSelectorComponent#modelValueKey
            might be set incorrectly because it produced value: ${resultValue[this.modelValueKey]}.`);
          }

          this.selectedValue = {
            viewValue: this.renderViewValueTemplateData(resultValue),
            modelValue: resultValue[this.modelValueKey],
            completeValue: resultValue,
          };
          this._valueChanged(resultValue[this.modelValueKey]);
        }

        this.blur.emit();
      });
    }
  }

  protected clearClicked($event: Event) {
    $event.stopPropagation();

    this.selectedValue = null;
    this._valueChanged(null);
  }

  protected _valueChanged(newValue: Nullable<PrimitiveControlValueType>) {
    this.blur.emit();

    // super.value and not this.value because it will bypass
    // SearchApi value lookup which is redundant at this point
    super.value = newValue;
    this.valueChange.emit(super.value);
  }

  private renderSelectedValueFromOptions() {
    if (this._value) {
      const selectedOption = this._options.find(o => o[this.modelValueKey as keyof TableSelectorOption] === this._value);

      if (selectedOption) {
        this.selectedValue = {
          modelValue: this._value,
          viewValue: this.renderViewValueTemplateData(selectedOption),
          completeValue: selectedOption,
        };
      }
      else {
        console.warn(`Value "${this._value}" does not correspond to any option of popup table selector.
            Please ensure that FormPopupTableSelectorComponent#modelValueKey is set
            properly to a unique option property.`);
      }
    }
  }

  private renderSelectedValueFromDataSource() {
    const value = String(this._value);

    if (this._value) {
      this.allValuesDataSource.items$.pipe(
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(valueItems => {
        if (valueItems.length === 1) {
          this.selectedValue = {
            modelValue: value,
            viewValue: this.renderViewValueTemplateData(valueItems[0]),
            completeValue: valueItems[0],
          };
          this.cd.detectChanges();
        }
        else {
          console.warn(`[formControlName=${this.formControlName}] Current value corresponds to ${valueItems.length} options from API. Please ensure that FormPopupTableSelectorComponent#modelValueKey is set properly to a valid option property.`);
        }
      });

      this.allValuesDataSource.loadPage({
        page: 0,
        size: defaultPageSize,
        filter: [{
          fieldName: this.modelValueKey,
          value,
          operator: FilterOperator.equals,
        }],
        sort: [],
      });
    }
    else {
      this.selectedValue = null;
    }
  }

  private renderViewValueTemplateData(data: any) {
    return this.translateParser.interpolate(
      this.viewValueTemplate
        .replaceAll('{', '{{')
        .replaceAll('}', '}}'),
      data
    ) ?? '';
  }

}
