import {EpdzErrorDto} from '|api/sad';
import {FilterOperator, IczTableDataSource} from '@icz/angular-table';
import {FilingOfficeSearchService} from '../../services/filing-office-search.service';

export class EpdzErrorsDatasource extends IczTableDataSource<EpdzErrorDto> {

  constructor(
    searchService: FilingOfficeSearchService,
    distributionNodeId: number
  ) {
    super(searchParams => {
      searchParams.filter ??= [];

      if (!searchParams.filter.find(f => f.fieldName === 'active')) {
        searchParams.filter.push({
          fieldName: 'active',
          operator:  FilterOperator.equals,
          value: String(true),
        });
      }

      return searchService.findEpdzErrors(searchParams, distributionNodeId);
    });
  }

}
