import {IczTableDataSource, mergeSearchParams, SearchRequestWithResult} from '../table.datasource';
import {FilterOperator, SearchParams} from '../table.utils';

/**
 * @internal
 */
export const NODE_LEVEL_SYMBOL = Symbol('symbol.treeNodeLevel');

/**
 * Tree node type, must be supplied by data producer in every object for the datasource.
 */
export enum NodeType {
  /**
   * Nodes are inner tree items which can be expanded.
   * Nodes have descendants.
   */
  NODE = 'NODE',
  /**
   * Leaves do not have descendants and should not be expanded.
   */
  LEAF = 'LEAF'
}

/**
 * An interface defining mandatory data attributes of objects which are supplied to tree table.
 */
interface HierarchicalEntity {
  /**
   * Database identifier.
   */
  id?: Nullable<number>;
  /**
   * Link to parent HierarchicalEntity's id.
   */
  parentId?: Nullable<number>;
  /**
   * @see NodeType
   */
  nodeType: NodeType;
}

/**
 * Tree data source also provides functionalities specific to tree branch expansion states.
 */
export class IczTreeTableDataSource<T extends HierarchicalEntity> extends IczTableDataSource<T> {

  /**
   * Map Node ID -> Node Level. Only nodes which are expanded are present here.
   */
  private nodeExpansionLevels!: Map<number, number>;

  /**
   * @see IczTableDataSource.loadPage
   */
  override loadPage(searchParams: SearchParams, deselectAll = false) {
    searchParams = this.enhanceWithWithAdditionalSearchParams(searchParams);

    let rootLevelParams: Partial<SearchParams>;

    if (!this.areFiltersApplied(searchParams)) {
      rootLevelParams = {
        filter: [
          {
            fieldName: 'parentId',
            operator: FilterOperator.empty,
            value: null,
          }
        ]
      };
    }
    else {
      // filtering will flatten the resulting data visible to the user
      rootLevelParams = {};
    }

    // base request for totalCount
    this._loadPage$.next({
      lastSearchParams: searchParams,
      currentSearchParams: mergeSearchParams(
        searchParams,
        rootLevelParams
      ),
      deselectAll
    });
  }

  /**
   * Requests data producer to provide tree branch contents to the datasource.
   */
  expandTreeBranch(branchRootNode: T) {
    let branchNodeLevel = 1;

    if (branchRootNode.parentId && this.nodeExpansionLevels.has(branchRootNode.parentId)) {
      branchNodeLevel += this.nodeExpansionLevels.get(branchRootNode.parentId)!;
    }

    this.nodeExpansionLevels.set(branchRootNode.id!, branchNodeLevel);

    // Branch-level params enforce always page=0 and size=Max because branch contents should not be paginated
    const branchLevelParams: Partial<SearchParams> = {
      filter: [
        {
          fieldName: 'parentId',
          operator: FilterOperator.equals,
          value: String(branchRootNode.id),
        }
      ],
      page: 0,
      size: 2000, // max
    };

    this._loadPage$.next({
      lastSearchParams: this.lastSearchParams,
      currentSearchParams: mergeSearchParams(
        this.lastSearchParams,
        branchLevelParams,
      ),
      deselectAll: false,
    });
  }

  /**
   * Removes records from the given tree branch from the datasource.
   */
  collapseTreeBranch(branchRootNode: T) {
    const branchRootIdsToCollapse = this.findAllDescendantBranchRootNodes(branchRootNode).map(n => n.id!);

    for (const idToCollapse of branchRootIdsToCollapse) {
      this.nodeExpansionLevels.delete(idToCollapse);
    }

    this.setData(this.data.filter(d => !branchRootIdsToCollapse.includes(d.parentId!)));
  }

  /**
   * Checks if a given tree node is expandable.
   */
  isNodeExpandable(node: T) {
    return !this.areFiltersApplied(this.lastSearchParams) && node.nodeType === NodeType.NODE;
  }

  /**
   * Checks if a given tree node has already been expanded.
   */
  isNodeExpanded(node: T) {
    return !isNil(this.nodeExpansionLevels.get(node.id!));
  }

  /**
   * @see IczTableDataSource.reload
   */
  override reload(deselectAll = false) {
    this.resetNodeExpansionStatus();
    super.reload(deselectAll);
  }

  protected override hasSortOrFilterChanged(searchParams: SearchParams) {
    return super.hasSortOrFilterChanged({
      ...searchParams,
      filter: searchParams.filter.filter(f => f.fieldName !== 'parentId'),
    });
  }

  protected override initialize() {
    this.resetNodeExpansionStatus();
    super.initialize();
  }

  protected override handleLoadDataResult(loadPageRequestWithResult: SearchRequestWithResult<T>) {
    if (this.hasSortOrFilterChanged(loadPageRequestWithResult.request.currentSearchParams)) {
      this.resetNodeExpansionStatus();
    }

    const loadedBranchId = this.getLoadedBranchId(loadPageRequestWithResult.request.currentSearchParams);

    if (loadedBranchId) {
      const branchRootIndex = this.data.findIndex(d => d.id === loadedBranchId);
      const branchContents = loadPageRequestWithResult.result.content.map(d => ({
        ...d,
        [NODE_LEVEL_SYMBOL]: this.nodeExpansionLevels.get(loadedBranchId),
      }));

      if (branchRootIndex !== -1) {
        this.setData(this.data.toSpliced(branchRootIndex + 1, 0, ...branchContents));
      }
    }
    else {
      if (this.hasPageOrSizeChanged(loadPageRequestWithResult.request.currentSearchParams)) {
        this.resetNodeExpansionStatus();
      }

      this.setData(loadPageRequestWithResult.result.content ?? []);
      if (this.paginator) this.paginator.length = loadPageRequestWithResult.result.totalElements ?? 0;
    }

    this._error$.next(null);
    this._loading$.next(false);
  }

  private hasPageOrSizeChanged(searchParams: SearchParams) {
    if (this.lastSearchParams) {
      return (
        searchParams.page !== this.lastSearchParams.page ||
        searchParams.size !== this.lastSearchParams.size
      );
    }
    else {
      return false;
    }
  }

  private areFiltersApplied(searchParams: Nullable<SearchParams>) {
    if (searchParams) {
      return (
        searchParams.filter.length > 1 ||
        (searchParams.filter.length === 1 && searchParams.filter[0].fieldName !== 'parentId')
      );
    }
    else {
      return false;
    }
  }

  private resetNodeExpansionStatus() {
    this.nodeExpansionLevels = new Map();
  }

  private getLoadedBranchId(searchParams: SearchParams): Nullable<number> {
    const parentIdFilter = searchParams.filter.find(
      f => f.fieldName === 'parentId' && f.operator === FilterOperator.equals
    );

    if (parentIdFilter) {
      return Number(parentIdFilter.value);
    }
    else {
      return null;
    }
  }

  private findAllDescendantBranchRootNodes(branchRootNode: T): T[] {
    const children = this.data.filter(d => d.nodeType === NodeType.NODE && d.parentId === branchRootNode.id);

    if (children) {
      return [
        branchRootNode,
        ...children.flatMap(c => this.findAllDescendantBranchRootNodes(c)),
      ];
    }
    else {
      return [branchRootNode];
    }
  }

}
