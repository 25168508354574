import {ChangeDetectorRef, Component, EventEmitter, HostBinding, inject, Input, OnInit, Output} from '@angular/core';
import {ButtonComponent, DetachingService} from '@icz/angular-essentials';

/**
 * A component used for rendering a sliding table preview panel with a "close" button.
 * The content of the preview itself is expected to be passed using ng-content.
 */
@Component({
  selector: 'icz-table-preview',
  templateUrl: './table-preview.component.html',
  styleUrls: ['./table-preview.component.scss'],
  standalone: true,
  imports: [
    ButtonComponent,
  ],
})
export class TablePreviewComponent implements OnInit {

  private detachingService = inject(DetachingService);
  private cd = inject(ChangeDetectorRef);

  /**
   * A flag controlling open/close state of the panel.
   */
  @HostBinding('class.opened')
  @Input({required: true})
  opened: Nullable<boolean> = false;
  /**
   * Disabled built-in panel header styling to allow for custom heading
   * content pass using ng-content slot "previewHeader".
   */
  @HostBinding('class.header-custom')
  @Input()
  withCustomPreviewHeader = false;
  /**
   * Signalizes when the table preview has been closed by the user.
   */
  @Output()
  closed = new EventEmitter<void>();

  /**
   * @internal
   */
  ngOnInit() {
    this.detachingService.registerDetach('columnResize', this.cd);
  }

}
