import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DocumentState} from '|api/commons';
import {AbstractObjectSelectorComponent} from '../file-selector/abstract-object-selector.component';
import {DocumentAllowableObjectClass, DocumentDto} from '|api/document';
import {OWN_DOCUMENT_OBJECT_CLASSES, RECEIVED_DOCUMENT_OBJECT_CLASSES} from '../shared-document.utils';
import {FilterOperator, SearchParams} from '@icz/angular-table';
import {WITHOUT_REF_NUMBER} from '../shared-business-components.model';

@Component({
  selector: 'icz-document-selector',
  templateUrl: './document-selector.component.html',
  styleUrls: ['./document-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentSelectorComponent extends AbstractObjectSelectorComponent<DocumentDto> {

  @Input()
  objectClasses: DocumentAllowableObjectClass[] = [...OWN_DOCUMENT_OBJECT_CLASSES, ...RECEIVED_DOCUMENT_OBJECT_CLASSES];

  readonly recentlyVisitedObjectsHeading = 'Naposledy navštívené dokumenty:';
  protected additionalFilteringCriteriaFactory = () => [
    {
      fieldName: 'documentState',
      operator: FilterOperator.equals,
      value: String([DocumentState.IN_PROGRESS]),
    },
  ];
  readonly withoutRefNumberText = WITHOUT_REF_NUMBER;

  protected findObjects(searchParams: SearchParams) {
    return this.searchService.findDocumentsAll(searchParams);
  }

}
