import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ButtonComponent, IconComponent, TooltipDirective} from '@icz/angular-essentials';
import {IczOption} from '../../form-elements.model';

/**
 * @internal
 */
@Component({
  selector: 'icz-item-sorter-item',
  templateUrl: './item-sorter-item.component.html',
  styleUrls: ['./item-sorter-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    IconComponent,
    TooltipDirective,
  ]
})
export class ItemSorterItemComponent {
  @Input({required: true})
  index = 0;
  @Input({required: true})
  itemCount = 0;
  @Input({required: true})
  option!: IczOption;
  @Output()
  upPressed = new EventEmitter<void>();
  @Output()
  downPressed = new EventEmitter<void>();
}
