import {Observable} from 'rxjs';
import {StorageUnitDto} from '|api/document';

export interface NewStorageUnitDialogResult {
  storageUnit: StorageUnitDto;
  redirectToPermissionSettings: boolean;
}

/**
 * Necessary abstract class for avoiding cyclic dependency between shared and other modules,
 * dependency direction being reversed by providing services implementing this shared abstract
 */
export abstract class AbstractStorageUnitDialogsManagerService {
  abstract openStorageUnitCreateDialog(): Observable<Nullable<NewStorageUnitDialogResult>>;
  abstract openStorageUnitInsertDialog(storageUnit: StorageUnitDto): Observable<Nullable<boolean>>;
}
