import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IczFormGroup} from '@icz/angular-form-elements';

import {SubjectAttributeType} from '../../../model/subject-attribute-type';

@Component({
  selector: 'icz-subject-create-contacts',
  templateUrl: './subject-create-contacts.component.html',
  styleUrls: ['./subject-create-contacts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectCreateContactsComponent {

  @Input({required: true}) form!: IczFormGroup;
  @Input() boxed = false;
  @Input() readonly = false;

  readonly SubjectAttributeType = SubjectAttributeType;

}
