<div class="col grow" [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="permission-selection">
    <div class="row-group grow">
      <icz-form-tree-selector
        class="grow-2"
        [isMultiselect]="true"
        formControlName="functionalPlacesToAssign"
        [options]="functionalPlacesOptions"
        [autoSizeMax]="4"
        label="Vybrat funkční místo s oprávněním"
        placeholder="Vyberte funkční místo"
        originId="fp"
        [selectionStrategy]="TreeItemSelectionStrategy.HYBRID"
      ></icz-form-tree-selector>
      @if (permissionOptions.length) {
        <icz-form-autocomplete
          class="grow-1 align-with-field-label"
          formControlName="permissions"
          [options]="permissionOptions"
          [clearable]="false"
          placeholder="Zvolte oprávnění"
          [customOptionTemplate]="permissionOptionTemplate"
          [hideDefaultTooltips]="true"
        ></icz-form-autocomplete>
      }
      <icz-button
        class="h-32 align-with-field-label"
        [primary]="true"
        [disabled]="isAddPositionButtonDisabled"
        (onAction)="addWorkerWithPermissions()" label="Přidat"
      ></icz-button>
    </div>
  </div>
  <div class="row grow header-row mat-header-cell">
    <span class="grow-2 header-row-cell pl-16">{{'Funkční místo' | translate}}</span>
    @if (permissionOptions.length || isSomePermissionReadOnly) {
      <span class="grow-3 header-row-cell">{{'Oprávnění' | translate}}</span>
    }
  </div>
  @for (nodeGroup of functionalPositionsForm.controls; track nodeGroup; let i = $index) {
    <div class="row grow px-16 py-8 worker-definition-row">
      <form [formGroup]="$any(nodeGroup)" class="row grow items-center">
        <div class="row grow-2">
          <icz-icon size="small" svgIcon="funkcni_misto"></icz-icon>
          <span class="icz-body-1">{{getFunctionalPositionNameValue($any(nodeGroup))}}</span>
        </div>
        @if (permissionOptions.length || !nodeGroup.value.isReadonly) {
          <div class="row grow-3 items-center">
            @if (nodeGroup.value.isReadonly) {
              <span class="icz-body-1">{{ nodeGroup.value.permission | findInList:permissionOptions | translate }}</span>
            }
            @else if (permissionOptions.length) {
              <icz-form-autocomplete
                formControlName="permission"
                [options]="permissionOptions"
                class="pl-12" [clearable]="false"
                [customOptionTemplate]="permissionOptionTemplate"
                [hideDefaultTooltips]="true"
                [showValidationStatus]="false"
              ></icz-form-autocomplete>
            }
            @if (!nodeGroup.value.isReadonly) {
              <div class="row ml-auto items-center">
                <icz-button svgIcon="delete" (onAction)="deleteWorker(i)" class="h-24"></icz-button>
              </div>
            }
          </div>
        }
      </form>
    </div>
  }
</div>

<ng-template #permissionOptionTemplate let-context>
  <icz-form-autocomplete-list-text-item
    class="w-full"
    [option]="context.option"
    >
    @if (showPermissionTooltip) {
      <icz-icon
        afterItemLabel
        svgIcon="info"
        size="small"
        [iczTooltip]="'fe.ui.tooltip.permission.' + permissionTooltipTranslationPrefix + '.' + context.option.value"
      ></icz-icon>
    }
  </icz-form-autocomplete-list-text-item>
</ng-template>
