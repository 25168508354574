<icz-transaction-sidebar
  (closed)="onPreviewClose()"
  [opened]="isAttributePreviewOpened$ | async"
  [event]="currentTransactionEvent$ | async"
></icz-transaction-sidebar>

@if (dataSource) {
  <icz-table
    id="transactions-table"
    #iczTable
    class="grow"
    [dataSource]="dataSource"
    [columnsData]="columnsData"
    (pageLoadStarted)="pageLoadStarted($event)"
    (activeRowChanged)="onRowClick($event)"
    [config]="config"
  >
    <icz-label tableTitle label="Transakční protokol"></icz-label>
    <icz-label noDataLabel label="Žádné záznamy v transakčním protokolu"></icz-label>
    <icz-label notFoundLabel label="V transakčním protokolu nebyly nalezeny žádné záznamy"></icz-label>

    <div class="row-group" tools>
      <icz-button testingFeature testingFeatureLabel="NYI" svgIcon="printer" (onAction)="onClickPrint($event)" label="Tisk"></icz-button>
    </div>

    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: 'creationDate'">
      @if (row.creationDate | localizedDatetime:false:true; as creationDate) {
        <span
          [iczTooltip]="creationDate">
        {{ creationDate }}
      </span>
      }
    </ng-container>

  </icz-table>
}
