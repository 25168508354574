import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DeliveryTypeDto} from '|api/codebook';
import {DistributionClass, NodeType, SubjectRecordDto} from '|api/commons';
import {IczFormGroup, IczOption, locateOptionByValue} from '@icz/angular-form-elements';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {isValidNow} from '../../../../../core/services/data-mapping.utils';

import {SubjectAttributeType} from '../../../model/subject-attribute-type';

const IMPLEMENTED_DISTRIBUTION_CLASSES: DistributionClass[] = [
  DistributionClass.ADDRESS_PLACE,
  DistributionClass.ISDS_BOX,
  DistributionClass.EMAIL_BOX,
  DistributionClass.ON_PLACE,
  DistributionClass.OFFICIAL_BOARD,
  DistributionClass.INTERNAL,
  DistributionClass.PHONE_CONTACT,
  DistributionClass.PORTAL_BOX,
];

export interface ApplicableDeliveryType {
  distributionClass: DistributionClass;
  deliveryType: DeliveryTypeDto;
}

@Component({
  selector: 'icz-applicable-delivery-type-selector',
  templateUrl: './applicable-delivery-type-selector.component.html',
  styleUrls: ['./applicable-delivery-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicableDeliveryTypeSelectorComponent implements OnInit {

  private codebookService = inject(CodebookService);
  private translateService = inject(TranslateService);

  @Input({required: true})
  form!: IczFormGroup;
  @Input({required: true})
  controlName!: string;
  @Input({required: true})
  subject!: SubjectRecordDto;
  @Input({required: true})
  constrainToDistributionClass: Nullable<DistributionClass>;
  @Input()
  disabled = false;
  @Input()
  disableFilingOfficeProcessingChange = false;
  @Output()
  applicableDeliveryTypeChanged = new EventEmitter<Nullable<ApplicableDeliveryType>>();
  @Output()
  loadingStatusChanged = new EventEmitter<boolean>(true);

  deliveryTypeOptions: IczOption<number, ApplicableDeliveryType>[] = [];

  ngOnInit(): void {
    this.fetchDeliveryTypeOptions();
    if (this.disabled) this.form.disable();
  }

  private fetchDeliveryTypeOptions() {
    this.loadingStatusChanged.emit(true);

    const selectorControl = this.form.get(this.controlName)!;

    this.codebookService.deliveryTypes().subscribe(deliveryTypes => {
      let applicableDistributionClasses: DistributionClass[];

      if (this.constrainToDistributionClass) {
        applicableDistributionClasses = IMPLEMENTED_DISTRIBUTION_CLASSES
          .filter(dg => dg === this.constrainToDistributionClass);
      }
      else {
        // ISDS box must be defined at subject level prior to opening consignment specification form
        if ((this.subject.attributes ?? {}).hasOwnProperty(SubjectAttributeType.DATA_BOX)) {
          applicableDistributionClasses = IMPLEMENTED_DISTRIBUTION_CLASSES.filter(dg => dg !== DistributionClass.INTERNAL && dg !== DistributionClass.PHONE_CONTACT);
        }
        else {
          applicableDistributionClasses = IMPLEMENTED_DISTRIBUTION_CLASSES
            .filter(dg => (dg !== DistributionClass.ISDS_BOX && dg !== DistributionClass.INTERNAL && dg !== DistributionClass.PHONE_CONTACT));
        }
      }

      const applicableDeliveryGroupNodeIds = deliveryTypes
        .filter(dt => applicableDistributionClasses.includes(dt.distributionClass.distributionClassValue!))
        .map(dt => dt.id!);

      this.deliveryTypeOptions = deliveryTypes
        .filter(dt => applicableDeliveryGroupNodeIds.includes(dt.parentId!))
        .filter(dt => dt.nodeType === NodeType.LEAF)
        .filter(c => isValidNow(c))
        .filter(dt => dt.forDispatch)
        .map(dt => ({
          value: dt.id!,
          label: `${dt.name ?? ''}`,
          data: {
            distributionClass: deliveryTypes.find(
              parentDeliveryType => parentDeliveryType.id === dt.parentId
            )!.distributionClass?.distributionClassValue,
            processedByFilingOffice: dt.processedByFilingOffice,
            deliveryType: dt
          }
        }));

      if (this.disableFilingOfficeProcessingChange && selectorControl.value) {
        const selectedOption = locateOptionByValue(this.deliveryTypeOptions, selectorControl.value);

        if (selectedOption) {
          const currentDeliveryTypeProcessedByFilingOffice = selectedOption.data!.deliveryType.processedByFilingOffice;

          this.deliveryTypeOptions = this.deliveryTypeOptions.map(dtOption => {
            const isDeliveryTypeProcessedByFilingOffice = dtOption.data!.deliveryType.processedByFilingOffice;

            if (currentDeliveryTypeProcessedByFilingOffice !== isDeliveryTypeProcessedByFilingOffice) {
              dtOption.disabled = true;
              dtOption.disableReason = this.translateService.instant('Nelze změnit na hodnotu s jiným způsobem zpracování podatelnou');
            }

            return dtOption;
          });
        }
      }

      this.loadingStatusChanged.emit(false);

      if (this.deliveryTypeOptions.length === 1) {
        selectorControl.setValue(this.deliveryTypeOptions[0].value);
      }

      const currentValue = selectorControl.value;

      if (currentValue) {
        this.emitDistributionClass(currentValue);
      }
    });
  }

  emitDistributionClass(selectedOptionValue: Nullable<(string | number)[] | string | number>) {
    if (!selectedOptionValue) {
      this.applicableDeliveryTypeChanged.emit(null);
    }
    else {
      const option = this.deliveryTypeOptions.find(o => o.value === selectedOptionValue);

      if (!option) {
        this.applicableDeliveryTypeChanged.emit(null);
      }
      else {
        this.applicableDeliveryTypeChanged.emit(option.data);
      }
    }
  }

}
