import {ExtendedCirculationTaskDto} from '../../model/elastic-extended-entities.interface';
import {ButtonDisablerFn} from '../../../button-collection/button-collection.component';
import {CommonToolbarDisablers} from './toolbar-common.disablers';
import {TaskOperationValidators} from './task-operation-validators';

export class TaskToolbarButtonsDisablers {

  static selectedItemIsNotDocumentCirculation(selection: ExtendedCirculationTaskDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, TaskOperationValidators.selectedItemIsNotDocumentCirculation());
    };
  }

  static selectedItemIsNotFileCirculation(selection: ExtendedCirculationTaskDto[]): ButtonDisablerFn {
    return () => {
      return CommonToolbarDisablers.evalSelectionWithValidator(selection, TaskOperationValidators.selectedItemIsNotFileCirculation());
    };
  }
}
