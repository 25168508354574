<icz-tabs [tabs]="modeTabs" [activeTab]="activeModeTab" [shouldNavigate]="false" (tabClick)="changeSelectionMode($event.id)">
  <ng-template [iczTab]="DateFilterSelectionMode.DATE">
    <div class="calendar-row">
      <div class="calendar-area">
        <form class="px-8 pt-16 row-group" [formGroup]="calendarDateForm">
          <icz-form-field
            class="date-input grow" formControlName="date"
            iczAutoFocus (keydown.enter)="applyDateInputValue()"
          >
            <span prefix>{{ 'Datum' | translate }}:</span>
          </icz-form-field>
          <icz-button
            primary label="Použít" class="mb-16"
            (onAction)="applyDateInputValue()"
          ></icz-button>
        </form>
        <icz-calendar
          [selected]="selected"
          (selectedChange)="onDateChange($event)"
          (daySelected)="onDayClick()"
        ></icz-calendar>
      </div>
      <icz-date-picker-shortcuts
        class="grow pt-4"
        [selectionMode]="selectionMode"
        [activeIntervalShortcut]="activeShortcutType"
        (shortcutClicked)="handleDateShortcutChange($event)"
      ></icz-date-picker-shortcuts>
    </div>
  </ng-template>
  <ng-template [iczTab]="DateFilterSelectionMode.ABSOLUTE_INTERVAL">
    <div class="calendar-row">
      <div class="calendar-area">
        <form class="px-8 pt-16 row-group" [formGroup]="calendarRangeForm">
          <icz-form-field iczAutoFocus class="grow" formControlName="start" (keydown.enter)="applyDateRangeInputValue()">
            <span prefix>{{ 'Od' | translate }}:</span>
          </icz-form-field>
          <icz-form-field class="grow" formControlName="end" (keydown.enter)="applyDateRangeInputValue()">
            <span prefix>{{ 'Do' | translate }}:</span>
          </icz-form-field>
          <icz-button
            primary label="Použít" class="mb-16"
            (onAction)="applyDateRangeInputValue()"
          ></icz-button>
        </form>
        <icz-calendar-range
          [selectedDateRange]="$any(selected)"
          (selectedDateRangeChange)="onDateChange($event)"
        ></icz-calendar-range>
      </div>
      <icz-date-picker-shortcuts
        class="grow pt-4"
        [selectionMode]="selectionMode"
        [activeIntervalShortcut]="activeShortcutType"
        (shortcutClicked)="handleDateShortcutChange($event)"
      ></icz-date-picker-shortcuts>
    </div>
  </ng-template>
  <ng-template [iczTab]="DateFilterSelectionMode.RELATIVE_INTERVAL">
    <div class="p-16">
      <icz-alert
        severity="info" class="mb-16"
        heading="Flexibilně zadané období je výhodné, pokud budete chtít nastavení filtru uložit pro opětovné použití. Nastavené období se Vám při opakovaném použití filtru vždy přepočítá k aktuálnímu datu."
      ></icz-alert>
      <icz-date-picker-shortcuts
        [isColumnView]="true"
        [selectionMode]="selectionMode"
        [activeIntervalShortcut]="activeShortcutType"
        [selectedDaysAgo]="selectedDaysAgo"
        (shortcutClicked)="handleDateShortcutChange($event)"
      ></icz-date-picker-shortcuts>
    </div>
  </ng-template>
</icz-tabs>
