import {Component, inject, Input, OnInit} from '@angular/core';
import {OwnPortalConsignmentForm} from '../consignment-dialog.form';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {IczOption} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';

@Component({
  selector: 'icz-portal-consignment-specification',
  templateUrl: './portal-consignment-specification.component.html',
  styleUrls: ['./portal-consignment-specification.component.scss']
})
export class PortalConsignmentSpecificationComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);

  @Input({required: true})
  specificFieldsForm!: OwnPortalConsignmentForm;

  portalDistributionNodeOptions: IczOption[] = [];

  ngOnInit() {
    this.loadingService.doLoading(
      this.codebookService.portalNodes(),
      this
    ).subscribe(portalNodes => {
      this.portalDistributionNodeOptions = portalNodes.map(node => ({value: node.id, label: node.nodeName}));
    });
  }

}
