import {Component, inject, OnInit} from '@angular/core';
import {ReceivedEpdzMessageRejectionDataDto} from '|api/sad';
import {CheckUnsavedFormDialogService} from '../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup, IczValidators, TextLength} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczModalRef} from '@icz/angular-modal';

@Component({
  selector: 'icz-reject-email-message-dialog',
  templateUrl: './reject-email-message-dialog.component.html',
  styleUrls: ['./reject-email-message-dialog.component.scss'],
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class RejectEmailMessageDialogComponent implements OnInit, IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  protected modalRef = inject(IczModalRef<Nullable<ReceivedEpdzMessageRejectionDataDto>>);
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);

  form = new IczFormGroup({
    rejectionReasonText: new IczFormControl<Nullable<string>>(null, [IczValidators.required()], undefined, TextLength.LONG),
    rejectionReasonAlternativeText: new IczFormControl<Nullable<string>>(null, undefined, undefined, TextLength.LONG),
  });

  formGroupsToCheck!: string[];

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
  }

  submit() {
    const formValue = this.form.value;

    this.modalRef.close({
      rejectionReasonText: formValue.rejectionReasonText,
      rejectionReasonAlternativeText: formValue.rejectionReasonAlternativeText,
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

}
