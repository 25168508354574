import {Component, inject, Input, OnInit} from '@angular/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {BulkPostingFormDialogData} from '../bulk-posting-form-dialog.service';
import {ApiBulkPostingFormService, BulkPostingFormRecordStatus} from '|api/sad';
import {extendDefaultTableConfig, FilterType, IczInMemoryDatasource, TableColumnsData} from '@icz/angular-table';
import {GenericSearchService} from '../../../../../../services/search-api.service';
import {getConsignmentPostingNumber} from '../../../../own-consignment-table/model/own-consignment-model';
import {enumToOptions} from '../../../../../../core/services/data-mapping.utils';
import {injectModalData} from '@icz/angular-modal';


@Component({
  selector: 'icz-bulk-posting-form-dialog-consignments',
  templateUrl: './bulk-posting-form-dialog-consignments.component.html',
  styleUrls: ['./bulk-posting-form-dialog-consignments.component.scss'],
})
export class BulkPostingFormDialogConsignmentsComponent implements OnInit {

  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected apiBulkPostingFormService = inject(ApiBulkPostingFormService);
  protected genericSearchService = inject(GenericSearchService);
  protected modalData = injectModalData<BulkPostingFormDialogData>();

  @Input({required: true}) id!: number;
  dataSource = new IczInMemoryDatasource(() => []);

  tableConfig = extendDefaultTableConfig({
    toolbarConfig: {
      showToolbar: false,
    },
  });

  columnsData = new TableColumnsData([
    {id: 'ownPaperConsignmentDto.uid', label: 'UID zásilky', filterType: FilterType.NONE, disableSort: true},
    {id: 'ownPaperConsignmentDto.consignmentType', label: 'Typ zásilky', filterType: FilterType.NONE, disableSort: true, displayed: false},
    {id: 'ownPaperConsignmentDto.refNumber', label: 'Číslo jednací', filterType: FilterType.NONE, disableSort: true, allowTranslation: true},
    {
      id: 'status',
      label: 'Stav záznamu PPA',
      filterType: FilterType.ENUM,
      list: enumToOptions('bulkPostingFormRecordStatus', BulkPostingFormRecordStatus),
      disableSort: true,
      allowTranslation: true,
    },
    {
      id: 'ownPaperConsignmentDto.distributionDate',
      label: 'Převzato do distribuce',
      filterType: FilterType.DATE,
      disableSort: true,
      allowTranslation: true,
    },
    {id: 'ownPaperConsignmentDto.consignee', label: 'Komu se posílá', filterType: FilterType.NONE, disableSort: true, allowTranslation: true},
    {
      id: 'ownPaperConsignmentDto.consigneeAddress',
      label: 'Kam se posílá',
      filterType: FilterType.NONE,
      disableSort: true,
      allowTranslation: true,
    },
    {id: 'ownPaperConsignmentDto.codAmount', label: 'Dobírka', filterType: FilterType.NONE, disableSort: true, allowTranslation: true},
    {id: 'ownPaperConsignmentDto.declaredValue', label: 'Udaná cena', filterType: FilterType.NONE, disableSort: true, allowTranslation: true},
    {id: 'ownPaperConsignmentDto.payoutAmount', label: 'Výplatné', filterType: FilterType.NONE, disableSort: true, allowTranslation: true},
    {id: 'ownPaperConsignmentDto.weight', label: 'Hmotnost', filterType: FilterType.NONE, disableSort: true, allowTranslation: true},
    {
      id: 'ownPaperConsignmentDto.consignmentPostingNumber',
      label: 'ČDZ',
      filterType: FilterType.NONE,
      disableSort: true,
      allowTranslation: true,
    },
  ]);

  readonly getConsignmentPostingNumber = getConsignmentPostingNumber;

  ngOnInit() {
    this.apiBulkPostingFormService.bulkPostingFormFindDetailById({id: this.id!}).subscribe(consigs => {
      this.dataSource!.setDataFactory(() => consigs);
    });
  }

}
