import {Directive, forwardRef, inject, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {EmpowermentComponent} from '../empowerment/empowerment/empowerment.component';
import {LegacyGenericValueAccessor} from '../../legacy-generic-value-accessor';


@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'icz-empowerment[formControlName]',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EmpowermentValueAccessorDirective),
    multi: true
  }],
})
export class EmpowermentValueAccessorDirective extends LegacyGenericValueAccessor {

  private empowermentComponent = inject(EmpowermentComponent, {optional: true});

  @Input()
  override formControlName!: string;

  protected override component = this.empowermentComponent!;

}
