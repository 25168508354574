import {Component, Input} from '@angular/core';
import {IczFormGroup} from '@icz/angular-form-elements';
import {AbstractSubjectVerifiableAttributeComponent} from '../../subject-single-attribute/abstract-subject-verifiable-attribute.component';
import {AddressFormat} from '../../../model/addresses.model';
import {enumToOptions} from '../../../../../core/services/data-mapping.utils';
import {AddressComponentFormatType} from '../address.component';


@Component({
  selector: 'icz-czech-or-slovak-address',
  templateUrl: './czech-or-slovak-address.component.html',
  styleUrls: ['./czech-or-slovak-address.component.scss']
})
export class CzechOrSlovakAddressComponent extends AbstractSubjectVerifiableAttributeComponent {

  @Input({required: true}) addressFormat!: AddressFormat;
  @Input() isReadonly = false;

  addressFormatTypeOptions = enumToOptions('addressComponentFormatType', AddressComponentFormatType);

  get czechAddressControl(): IczFormGroup {
    return this.valueForm.get(AddressFormat.CzechAddressDto) as IczFormGroup;
  }

  get slovakAddressControl(): IczFormGroup {
    return this.valueForm.get(AddressFormat.SlovakAddressDto) as IczFormGroup;
  }

  get czechOrSlovakAddressForm(): IczFormGroup {
    return this.addressFormat === AddressFormat.CzechAddressDto ? this.czechAddressControl : this.slovakAddressControl;
  }

  get readonlyAddressFormatType(): string {
    if (this.addressFormat === AddressFormat.CzechAddressDto) {
      return this.addressFormatTypeOptions?.find(a => a.value === AddressComponentFormatType.CZ)!.label;
    } else if (this.addressFormat === AddressFormat.SlovakAddressDto) {
      return this.addressFormatTypeOptions?.find(a => a.value === AddressComponentFormatType.SK)!.label;
    }
    return '';
  }

}
