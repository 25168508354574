import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {SubjectRecordDto} from '|api/commons';
import {SubjectTemplateUtils} from '../../../../utils/subject-template-utils';


@Component({
  selector: 'icz-subject-header-info',
  templateUrl: './subject-header-info.component.html',
  styleUrls: ['./subject-header-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubjectHeaderInfoComponent {

  @Input({required: true})
  subject!: SubjectRecordDto;
  @Input()
  withSplitBorder = true;
  @Input()
  showIdentifiedAsTag = false;
  @Input()
  isSubjectRepresenting = false;

  get isIszrVerified() {
    return SubjectTemplateUtils.isIszrVerified(this.subject);
  }

}
