import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {EntityWithRefNumber} from '../ref-number-link/ref-number-link.component';

export type KeyValueType = 'string' | 'functionalPosition' | 'refNumber';

@Component({
  selector: 'icz-key-value-pair',
  templateUrl: './key-value-pair.component.html',
  styleUrls: ['./key-value-pair.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyValuePairComponent {

  @Input({required: true}) key: Nullable<string>;
  @Input({required: true}) value: Nullable<string | number>;
  @Input() type: KeyValueType = 'string';
  // entity is only needed if type === 'refNumber'
  @Input() entity?: Nullable<EntityWithRefNumber>;

}
