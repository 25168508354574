<div class="overflow-y-auto overflow-x-hidden" [style.maxHeight.px]="maxListHeight">

  @for (option of options; track option.value) {
    @if (!option.isHidden) {
      <icz-option-item
        [class.cursor-default]="readonlyMode"
        [option]="option"
        [listItemTemplate]="listItemTemplate"
        [isSelected]="isSelected(option)"
        [isMultiselect]="isMultiselect"
        [class.active]="isActive(option)"
        [hideDefaultTooltip]="hideDefaultTooltips"
        (mouseleave)="resetActiveOption()"
        (mouseenter)="mouseEnteredOption(option)"
        (click)="optionSelected(option);"
      ></icz-option-item>
    }
  }
</div>
