import {TemplateRef} from '@angular/core';
import {Observable} from 'rxjs';
import {
  IAutocompleteListItemContext,
  IczOption,
  IImplicitTemplateContext,
  TreeItemSelectionStrategy
} from '@icz/angular-form-elements';
import {FilterOperator, isNoValueOperator} from './table.utils';
import {TableReservedColumns} from './table.component';
import {IczTableDataSource} from './table.datasource';
import {TableColumnsData} from './table-columns-data';

/**
 * Available filter types.
 * filterType NONE is used for unfilterable columns.
 */
export enum FilterType {
  TEXT = 'text',
  DATE = 'date',
  DATE_STATISTICS = 'dateStatistics',
  DATETIME = 'datetime',
  NUMBER = 'number',
  ENUM = 'enum',
  CODEBOOK = 'codebook',
  BOOLEAN = 'boolean',
  FILE_SIZE = 'fileSize',
  EMPOWERMENT = 'empowerment',
  SUBJECT_RECORD = 'subjectRecord',
  PAGE_SELECTOR = 'pageSelector',
  ADDRESS = 'consigneeAddress',
  NONE = 'none',
}

/**
 * List of filters which use BasicFilterDefinition.
 */
export const BASIC_FILTER_TYPES = [
  FilterType.TEXT,
  FilterType.NUMBER,
  FilterType.DATE,
  FilterType.DATE_STATISTICS,
  FilterType.DATETIME,
  FilterType.BOOLEAN,
  FilterType.FILE_SIZE,
  FilterType.EMPOWERMENT,
  FilterType.SUBJECT_RECORD,
  FilterType.ADDRESS,
] as const;

/**
 * @internal
 */
type BasicFilterType = typeof BASIC_FILTER_TYPES[number];

/**
 * Base definition of filter and table column behavior.
 */
interface CommonFilterDefinitionFields<T extends string = never> {
  /**
   * Column ID. Also used for API calls related to filtering.
   * Usually uses property name of table row object property it is used to filter.
   */
  id: T | TableReservedColumns;
  /**
   * Column label. Also used for filter label.
   */
  label?: Nullable<string>;
  /**
   * If value of a given object property is null, this text will be
   * used when rendering associated table cell.
   */
  nullValueText?: string;
  /**
   * If true, translate pipe will be applied to table cell contents.
   * Useful for rendering enum members which typically have human-unreadable hierarchical translation keys.
   */
  allowTranslation?: boolean;
  /**
   * If allowTranslation is true, this prefix is used before passing the value to translate pipe.
   * as a prefix for translation key of given table item cell.
   */
  translateWithPrefix?: string;
  /**
   * Alternative filter label if we need to distinguish column label and filter label.
   */
  filterLabel?: string;
}

/**
 * Generic filter option type.
 */
export type FilterListOption = IczOption<any, any>;

/**
 * Used for filters where the user selects an item from a collection of available options.
 * Note that the list is also bound to rendering of item labels in table cells.
 */
export interface FilterWithList {
  /**
   * A list which should be available (i.e. completely filled) at table initialization time.
   */
  list?: FilterListOption[];
  /**
   * A list which will be loaded in a deferred manner at table initialization time.
   */
  list$?: Observable<FilterListOption[]>;
}

export interface FilterWithDatasource {
  filterDataSource?: IczTableDataSource<any>;
}

/**
 * @internal
 */
export interface BasicFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T> {
  filterType: BasicFilterType;
  filterConfig?: CommonFilterConfig;
}

/**
 * @internal
 */
export interface EnumFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T>, FilterWithList {
  filterType: FilterType.ENUM;
  filterConfig?: EnumFilterConfig;
}

/**
 * @internal
 */
export interface CodebookFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T>, FilterWithList {
  filterType: FilterType.CODEBOOK;
  filterConfig?: CodebookFilterConfig;
}

export interface PageSelectorFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T>, FilterWithDatasource {
  filterType: FilterType.PAGE_SELECTOR;
  filterConfig?: PageSelectorFilterConfig;
}

/**
 * @internal
 */
export interface NoneFilterDefinition<T extends string = never> extends CommonFilterDefinitionFields<T> {
  filterType: FilterType.NONE;
  filterConfig?: FilterConfigBase;
}

/**
 * @internal
 */
export type CombinedFilterDefinition<T extends string = never> = (
  Omit<BasicFilterDefinition<T>, 'filterType'> &
  Omit<EnumFilterDefinition<T>, 'filterType'> &
  Omit<CodebookFilterDefinition<T>, 'filterType'> &
  Omit<PageSelectorFilterDefinition<T>, 'filterType'> &
  Omit<NoneFilterDefinition<T>, 'filterType'> &
  { filterType: FilterType }
);

/**
 * @internal
 */
export type NonemptyFilterDefinition<T extends string = never> = (
  BasicFilterDefinition<T> |
  EnumFilterDefinition<T> |
  CodebookFilterDefinition<T> |
  PageSelectorFilterDefinition<T>
);

/**
 * @internal
 */
export type BaseFilterDefinition<T extends string = never> = (
  NonemptyFilterDefinition<T> |
  NoneFilterDefinition<T>
);

/**
 * @internal
 */
export function isBasicFilterDefinition(fd: BaseFilterDefinition<string>): fd is BasicFilterDefinition<string> {
  return BASIC_FILTER_TYPES.includes(fd.filterType as BasicFilterType);
}

/**
 * @internal
 */
export function isEnumFilterDefinition(fd: BaseFilterDefinition<string>): fd is EnumFilterDefinition<string> {
  return fd.filterType === FilterType.ENUM;
}

/**
 * @internal
 */
export function isCodebookFilterDefinition(fd: BaseFilterDefinition<string>): fd is CodebookFilterDefinition<string> {
  return fd.filterType === FilterType.CODEBOOK;
}

/**
 * @internal
 */
export function isPageSelectorFilterDefinition(fd: BaseFilterDefinition<string>): fd is PageSelectorFilterDefinition<string> {
  return fd.filterType === FilterType.PAGE_SELECTOR;
}

/**
 * @internal
 */
export function isListFilterDefinition(fd: BaseFilterDefinition<string>): fd is EnumFilterDefinition<string> | CodebookFilterDefinition<string> {
  return isEnumFilterDefinition(fd) || isCodebookFilterDefinition(fd);
}

/**
 * @internal
 */
export function isNonemptyFilterDefinition(fd: BaseFilterDefinition<string>): fd is NoneFilterDefinition<string> {
  return isBasicFilterDefinition(fd) || isEnumFilterDefinition(fd) || isCodebookFilterDefinition(fd) || isPageSelectorFilterDefinition(fd);
}

export interface FilterConfigBase {
  /**
   * If backend exposes a dedicated field used for complex filtering which
   * does not correspond to any backend output data property, use this.
   */
  customFilterId?: string;
}

export interface CommonFilterConfig extends FilterConfigBase {
  /**
   * Distinctive filters are permanently visible filters left to full-text search field.
   */
  isDistinctive?: boolean;
  /**
   * If TRUE, the number will not be formatted according to user's locale. Handy mainly for years.
   */
  disableLocalizedNumberFormatting?: boolean;
}

/**
 * Filter config for enum filter type.
 */
export interface EnumFilterConfig extends CommonFilterConfig {
  /**
   * Bound to rudimentary deprecated hard to remove code.
   * @deprecated
   */
  findListCb?: (searchTerm: string) => Observable<Array<{id: number; name: string}>>;
  /**
   * Minimal search term length
   */
  minSearchTermLength?: number;
  /**
   * A source of IDs in FilterItem#id, if need to be distinguished. Used in cases when a filter is sourcing
   * its options from two database tables with potentially non-empty intersection of their IDs.
   */
  originId?: string;
  /**
   * If filterType is "list" and the filter is multiselect, this option set to true
   * will allow the user to define custom chips and sort them using usage rate.
   */
  useCustomChips?: boolean;
  /**
   * Namespace for custom chip data, effective only if if useCustomChips is true.
   */
  chipNamespace?: string;
  /**
   * Used for custom option templates.
   */
  customListItemTemplate?: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>;
}

/**
 * This data structure is used for transfering initial subfilter values to the subfilters.
 * Record from subfilter ID to its intrinsic value.
 */
export type SubfilterInitialValues = Record<string, FilterItemValue>;

export interface CodebookFilterConfig extends CommonFilterConfig {
  /**
   * Will render the codebook for filtering as hierarchical tree.
   */
  isTree?: boolean;
  /**
   * Applicable only is isTreee is set to true.
   * @see TreeItemSelectionStrategy
   */
  treeSelectionStrategy?: TreeItemSelectionStrategy;
  /**
   * Minimal search term length
   */
  minSearchTermLength?: number;
  /**
   * A source of IDs in FilterItem#id, if need to be distinguished. Used in cases when a filter is sourcing
   * its options from two database tables with potentially non-empty intersection of their IDs.
   */
  originId?: string;
  /**
   * Used for custom option templates.
   */
  customListItemTemplate?: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>;
  /**
   * Used for custom template of selected codebook items list.
   */
  customSelectedChipTemplate?: TemplateRef<IImplicitTemplateContext<IAutocompleteListItemContext>>;
  /**
   * Subfilters of codebook filters are able to further filter codebook filter options based on user criteria.
   * (CodebookFilterItems are disallowed on purpose to break recursive rendering.)
   */
  subfilters?: Array<Partial<BasicFilterItem | EnumFilterItem>>;
  /**
   * Effective only if subFilters are defined.
   * @see SubfilterInitialValues
   */
  subfilterInitialValues?: SubfilterInitialValues;
  /**
   * Effective only if subFilters are defined.
   * Works in a similar way to subfilterInitialValues but is able to fetch data asynchronously
   * in a fashion similar to FilterWithList.list vs. FilterWithList.list$.
   * @see SubfilterInitialValues
   */
  subfilterInitialValues$?: Record<string, Observable<FilterItemValue>>;
  /**
   * Placeholder for fulltext search field in codebook items list/tree.
   */
  searchFieldPlaceholder?: string;
}

export interface PageSelectorFilterConfig extends CommonFilterConfig {
  /**
   * Id of table component inside the filter, used for storing settings of the table
   */
  filterTableId?: string;
  /**
   * Title shown in header of table filter
   */
  tableFilterTitle?: string;
  /**
   * Tag shown in header of table filter
   */
  tableTag?: string;
  /**
   * Table schema for displaying table columns in filter
   */
  tableSchema?: TableColumnsData<string>;
  /**
   * Key of model of table datasource used in formater of selected filter value
   */
  modelValueKey?: string;
  /**
   * Key of model of table datasource used in formater of selected filter label
   */
  modelLabelKey?: string;
  /**
   * List of default filters in filter table, list must correspond to table columns defined in tableSchema
   */
  defaultFilterColumns?: string[];
  /**
   * Placeholder in fulltext serach filed in table filter
   */
  filterFulltextSearchFieldPlaceholder?: string;
}

/**
 * This interface represents a single filter value subvalue.
 * Subvalues of the filter are used when the value comprises of more
 * individual values which are in a specific relations (a date or number interval for example).
 */
export interface FilterSubValue {
  /**
   * Subvalue relational operator. Applies in the scope of all subvalues of a given filter value.
   */
  operator: FilterOperator;
  /**
   * Subvalue value.
   */
  value: Nullable<string>;
  /**
   * Whether the subvalues are in logical conjunction to each other.
   * FALSE by default.
   */
  and?: boolean;
  /**
   * If there are generally more subvalues which are not easily mappable to filter data properties,
   * each subvalue should have its subvalue ID for query param serialization and deserialization purposes.
   */
  subValueId?: string;
}

/**
 * Properties used for defining behavior of all filters.
 * This object also carries value, subValues and viewValue because data passing in the filters works based on object mutation.
 */
interface CommonFilterItemFields extends FilterWithList, FilterWithDatasource {
  /**
   * Filter key, matches to an associated column in table.
   */
  id: string;
  /**
   * Human-readable filter name.
   */
  label: Nullable<string>;
  /**
   * Main filter value.
   */
  value: Nullable<string | string[]>;
  /**
   * Either value or subvalues, rendered into a hunam-readable string.
   * @deprecated - we plan to remove this in the future. Use FilterNameService.getFilterItemValue instead.
   */
  viewValue?: Nullable<string>;
  /**
   * Filtering subvalues.
   * @see FilterValue.subvalues
   * @see FilterSubValue
   */
  subValues?: Nullable<FilterSubValue[]>;
  /**
   * Implements a specific alternative filter label behavior connected to table column labels.
   * @deprecated
   */
  columnLabel: string;
  /**
   * A list of all filter options if applicable.
   */
  filterOption?: IczOption<FilterOperator>;
}

/**
 * @internal
 */
export interface BasicFilterItem extends CommonFilterItemFields, CommonFilterConfig {
  filterType: BasicFilterType;
}

/**
 * @internal
 */
export interface EnumFilterItem extends CommonFilterItemFields, EnumFilterConfig {
  filterType: FilterType.ENUM;
}

/**
 * @internal
 */
export interface CodebookFilterItem extends CommonFilterItemFields, CodebookFilterConfig {
  filterType: FilterType.CODEBOOK;
}

/**
 * @internal
 */
export interface PageSelectorFilterItem extends CommonFilterItemFields, PageSelectorFilterConfig {
  filterType: FilterType.PAGE_SELECTOR;
}

/**
 * @internal
 */
export interface NoneFilterItem extends CommonFilterItemFields, FilterConfigBase {
  filterType: FilterType.NONE;
}

/**
 * Filter item definition, used mostly in internal filter code.
 * The union constituents are used for increasing typecheck strength.
 */
export type NonemptyFilterItem = BasicFilterItem | EnumFilterItem | CodebookFilterItem | PageSelectorFilterItem;

/**
 * Filter item definition used in public table and filter interfaces.
 */
export type FilterItem = NonemptyFilterItem | NoneFilterItem;

/**
 * @internal
 */
export function isBasicFilterItem(fi: Nullable<FilterItem>): fi is BasicFilterItem {
  return BASIC_FILTER_TYPES.includes(fi?.filterType as BasicFilterType);
}

/**
 * @internal
 */
export function isEnumFilterItem(fi: Nullable<FilterItem>): fi is EnumFilterItem {
  return fi?.filterType === FilterType.ENUM;
}

/**
 * @internal
 */
export function isCodebookFilterItem(fi: Nullable<FilterItem>): fi is CodebookFilterItem {
  return fi?.filterType === FilterType.CODEBOOK;
}

/**
 * @internal
 */
export function isPageSelectorFilterItem(fi: Nullable<FilterItem>): fi is PageSelectorFilterItem {
  return fi?.filterType === FilterType.PAGE_SELECTOR;
}

/**
 * @internal
 */
export function isListFilterItem(fi: Nullable<FilterItem>): fi is EnumFilterItem | CodebookFilterItem {
  return isEnumFilterItem(fi) || isCodebookFilterItem(fi);
}

/**
 * @internal
 */
export function isNonemptyFilterItem(fi: Nullable<FilterItem>): fi is NonemptyFilterItem {
  return isBasicFilterItem(fi) || isEnumFilterItem(fi) || isCodebookFilterItem(fi) || isPageSelectorFilterItem(fi);
}

/**
 * Data values used after serialization/deserialization of filter item values in
 * table query parameters before they get hydrated into FilterItemValue class.
 */
export interface DehydratedFilterItemValue<T = string | string[]> {
  /**
   * Filter key, matches this filter value to filter of column with this ID after deserialization.
   */
  id: string;
  /**
   * Filtering operator.
   */
  operator: Nullable<FilterOperator>;
  /**
   * Main filter value.
   */
  value: Nullable<T>;
  /**
   * Filtering subvalues.
   * @see FilterValue.subvalues
   * @see FilterSubValue
   */
  subValues?: Nullable<FilterSubValue[]>;
  /**
   * Ehen matching with corresponding options after deserialization, filters
   * with lists will look up only Options with corresponding originId.
   */
  originId?: string;
  /**
   * A list of all filter options if applicable which also contains
   * an option associated to the value property.
   * @deprecated
   */
  list?: Nullable<FilterListOption[]>;
}

/**
 * Data transfer object class with additional validation and conversion capabilities.
 * Works together with FilterValue and FilterItem.
 */
export class FilterItemValue<T = string | string[]> implements DehydratedFilterItemValue<T> {

  /**
   * Converts a FilterItem to this
   */
  static fromFilterItem(item: FilterItem): FilterItemValue {
    return new this(
      item.id,
      item!.filterOption?.value,
      item!.value,
      item!.subValues,
      isListFilterItem(item) ? item.originId : undefined,
      isListFilterItem(item) ? item!.list : undefined,
    );
  }

  constructor(
    public id: string,
    public operator: Nullable<FilterOperator>,
    public value: Nullable<T>,
    public subValues?: Nullable<FilterSubValue[]>,
    public originId?: string,
    public list?: Nullable<FilterListOption[]>,
  ) {}

  /**
   * Checks if the internal value is consistent with the selected operator.
   */
  hasValidValue(): boolean {
    return (!isNoValueOperator(this.operator) && !!this.value) || isNoValueOperator(this.operator);
  }

}

/**
 * @internal
 */
export function isFilterWithOptions(filterType: Nullable<FilterType>) {
  return filterType === FilterType.ENUM || filterType === FilterType.CODEBOOK;
}
