import {DocumentDto, DocumentProfileDto, FileDto, FileProfileDto, StorageUnitDto} from '|api/document';
import {GeneralAuthorizationResult} from '../permissions/permissions.utils';


export interface EntityWithAuthorization {
  authorization?: Nullable<GeneralAuthorizationResult>;
}

export type DocumentDtoWithAuthorization = DocumentDto & EntityWithAuthorization;

export type DocumentOrProfileDtoWithAuthorization = (DocumentDto | DocumentProfileDto) & EntityWithAuthorization;

export type FileDtoWithAuthorization = FileDto & EntityWithAuthorization;

export type FileOrProfileDtoWithAuthorization = (FileDto | FileProfileDto) & EntityWithAuthorization;

export type StorageUnitDtoWithAuthorization = StorageUnitDto & EntityWithAuthorization;

export function isDocumentOrProfileDto(entity: any): entity is DocumentDto | DocumentProfileDto {
  return (
    entity &&
    'id' in entity &&
    'subject' in entity &&
    'objectClass' in entity
  );
}

export function isCompleteDocumentDto(entity: any): entity is DocumentDto {
  return (
    isDocumentOrProfileDto(entity) &&
    'empowerment' in entity &&
    'documentForm' in entity
  );
}

export function isDocumentProfileDto(entity: any): entity is DocumentProfileDto {
  return (
    isDocumentOrProfileDto(entity) &&
    !('empowerment' in entity) &&
    !('documentForm' in entity)
  );
}
