import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ApiSignatureService, SignatureValidationItemDto} from '|api/component';
import {SignatureValidationDataDto} from '|api/sad';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {injectModalData, injectModalRef} from '@icz/angular-modal';

export interface DigitalComponentSignatureDetailData {
  digitalComponentVersionId: Nullable<number>;
  signatureValidationData: Nullable<SignatureValidationDataDto>;
  lastClauseDate?: Nullable<string>;
}

function sortByDateDesc(d1: SignatureValidationItemDto, d2: SignatureValidationItemDto) {
  if (d2.signatureTime === d1.signatureTime) {
    return 0;
  }
  return d2.signatureTime! > d1.signatureTime! ? 1 : -1;
}

@Component({
  selector: 'icz-digital-component-signature-detail-dialog',
  templateUrl: './digital-component-signature-detail-dialog.component.html',
  styleUrls: ['./digital-component-signature-detail-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitalComponentSignatureDetailDialogComponent implements OnInit {

  protected modalRef = injectModalRef<DigitalComponentSignatureDetailDialogComponent>();
  private apiSignatureService = inject(ApiSignatureService);
  protected loadingIndicatorService = inject(LoadingIndicatorService);
  protected modalData = injectModalData<DigitalComponentSignatureDetailData>();

  private _signaturesDetail$ = new BehaviorSubject<Nullable<SignatureValidationDataDto>>(null);
  private _signaturesAndSeals$ = this._signaturesDetail$.pipe(
    filter(Boolean),
    map(signatureDetail => signatureDetail?.signatures),
  );

  signatures$ = this._signaturesAndSeals$.pipe(
    map(list => list!.filter(({qcType}) => qcType !== 'SEAL')),
    map(list => list.sort(sortByDateDesc)),
  );

  seals$ = this._signaturesAndSeals$.pipe(
    map(list => list!.filter(({qcType}) => qcType === 'SEAL')),
    map(list => list.sort(sortByDateDesc)),
  );

  showTwoColGrid$ = combineLatest([this.signatures$, this.seals$]).pipe(
    map(([signatures, seals]) => (signatures.length && !seals.length) || (!signatures.length && seals.length))
  );

  ngOnInit() {
    if (this.modalData.digitalComponentVersionId) {
      this.loadingIndicatorService.doLoading(
        this.apiSignatureService.signatureSignaturesDetail({
          digitalComponentVersionId: this.modalData.digitalComponentVersionId!
        }),
        this
      ).subscribe(this._signaturesDetail$);
    }
    else if (this.modalData.signatureValidationData) {
      this._signaturesDetail$.next(this.modalData.signatureValidationData);
    }
  }

  closeDialog() {
    this.modalRef.close();
  }

}
