import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {PopoverComponent} from '../popover/popover.component';
import {NgClass} from '@angular/common';

/**
 * A popover with styling adjusted for a bunch of icz-buttons next to each other.
 * Will render the buttons in top-to-bottom fashion, looking like a context menu.
 */
@Component({
  selector: 'icz-popover-menu',
  templateUrl: './popover-menu.component.html',
  styleUrls: ['./popover-menu.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PopoverComponent, NgClass],
})
export class PopoverMenuComponent {

  /**
   * If TRUE, the popover will open.
   */
  @Input({required: true})
  isOpen = false;
  /**
   * An overlay origin. Host element of the overlay origin
   * will serve as an anchor point of the menu.
   */
  @Input({required: true})
  overlayOrigin!: CdkOverlayOrigin;
  /**
   * If TRUE, menu buttons in popover contents will have their
   *   labels and icons aligned to the left. Else centering will apply.
   */
  @Input()
  buttonLeftAlign = true;
  /**
   * Emits after the popover menu gets closed either by clicking outside or programmatically.
   */
  @Output()
  onClose = new EventEmitter();

}
