<form [formGroup]="form" [waiting]="loadingService.isLoading$(this) | async">
  <div class="col grow h-full">
    <icz-alert heading="fe.ui.textHelp.agnedaTransferConfirmationInfo" severity="info"></icz-alert>
    <icz-validation-errors-list [array]="filesControl" [wrapInAlert]="true" [compactAlert]="true"></icz-validation-errors-list>
    <icz-file-upload-list
      class="grow"
      [form]="form"
      [showFileSize]="true"
      [isMultiUpload]="true"
      [allowedFileExtensions]="['.zip']"
      [showFileDragAndDrop]="true"
      [showFileUploadButton]="true"
      (fileSelected)="filesChanged()"
      fileFormArrayName="confirmFiles"
    >
    </icz-file-upload-list>
  </div>
</form>
<icz-form-buttons>
  <icz-button leftButtons iczFormSubmit
              [disabled]="loadingService.isLoading(this) || !isAllUploaded()"
              (onAction)="submit()"
              primary label="Potvrdit"
  ></icz-button>
  <icz-button rightButtons
              [disabled]="loadingService.isLoading(this)"
              (onAction)="cancel()" label="Storno"
  ></icz-button>
</icz-form-buttons>
