import {Directive, inject, Input, TemplateRef} from '@angular/core';
import {TableColumnsData} from './table-columns-data';
import {CombinedColumnDefinition} from './table.models';
import {IczTableDataSource} from './table.datasource';

/**
 * A special structural directive used for capturing TemplateRefs connected to custom table cell templates.
 */
@Directive({
  selector: '[iczColumnTemplate]',
  standalone: true,
})
export class ColumnTemplateDirective<TData, TColId extends string> {

  /**
   * Captured HTML template, used further in table rendering process.
   */
  content = inject(TemplateRef);

  /**
   * DataSource associated to the given template, used for data type checking inside the template.
   */
  @Input({alias: 'iczColumnTemplateFrom', required: true})
  set from(stuff: [IczTableDataSource<TData>, TableColumnsData<TColId>]) {}

  /**
   * ID of table column associated to this template.
   */
  @Input({alias: 'iczColumnTemplateId', required: true})
  id!: TColId;

  /**
   * If using withEllipsis: true for *iczColumnTemplate, inner
   *   ng-container contents must be wrapped inside a <span> element.
   */
  @Input('iczColumnTemplateWithEllipsis')
  set withEllipsis(newValue: boolean) {
    this._withEllipsis = newValue;
  }
  get withEllipsis(): boolean {
    return this._withEllipsis;
  }

  private _withEllipsis = false;

  /**
   * This makes it possible to know the type of row and column variables inside the inner ng-container and perform type checks against it:
   * - TData is bound to IczTableDataSource<TData>,
   * - ColumnDefinition<TColId> is bound to TableColumnsData<TColId>.
   */
  static ngTemplateContextGuard<TData, TColId extends string>(
    dir: ColumnTemplateDirective<TData, TColId>,
    ctx: unknown
  ): ctx is {$implicit: TData; column: CombinedColumnDefinition<TColId>} {
    return true;
  }

}
