import {Component, inject, OnInit} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup, IczOption, IczValidators} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {ApiAgendaTransferEnvelopeService, ApiAgendaTransferService, DocumentDto, FileDto} from '|api/document';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {Observable} from 'rxjs';
import {namedDtosToOptions} from '../../../../../core/services/data-mapping.utils';
import {
  DocumentToastService,
  DocumentToastType
} from '../../../../../core/services/notifications/document-toast.service';
import {InternalNotificationKey} from '|api/notification';
import {isDocumentEntity} from '../../../shared-document.utils';
import {FileToastService, FileToastType} from '../../../../../core/services/notifications/file-toast.service';
import {esslErrorDtoToToastParameters} from '../../../../notifications/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {EntityType} from '|api/commons';

export interface MoveToTransferEnvelopeDialogData {
  selectedObjects: (DocumentDto | FileDto)[],
}

@Component({
  selector: 'icz-move-to-transfer-envelope-dialog',
  templateUrl: './move-to-transfer-envelope-dialog.component.html',
  styleUrls: ['./move-to-transfer-envelope-dialog.component.scss'],
  providers: [CheckUnsavedFormDialogService],
})
export class MoveToTransferEnvelopeDialogComponent implements OnInit, IFormGroupCheckable {
  private checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected loadingService = inject(LoadingIndicatorService);
  private modalData = injectModalData<MoveToTransferEnvelopeDialogData>();
  protected modalRef = injectModalRef<Nullable<boolean>>();
  protected apiAgendaTransferEnvelopeService = inject(ApiAgendaTransferEnvelopeService);
  protected apiAgendaTransferService = inject(ApiAgendaTransferService);
  private documentToastService = inject(DocumentToastService);
  private fileToastService = inject(FileToastService);
  private translateService = inject(TranslateService);
  formGroupsToCheck!: string[];

  availableEnvelopes$: Observable<IczOption[]> = this.apiAgendaTransferEnvelopeService.agendaTransferEnvelopeFindAvailable()
    .pipe(namedDtosToOptions);

  form = new IczFormGroup({
    envelopeId: new IczFormControl(null, [IczValidators.required()]),
  });

  isBulkOperation = false;

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    this.isBulkOperation = this.modalData.selectedObjects.length > 1;
  }

  submit() {
    const formValue = this.form.getRawValue();

    this.loadingService.doLoading(
      this.apiAgendaTransferService.agendaTransferMarkForTransfer({
        body: {
          envelopeId: formValue.envelopeId,
          operationEntityDtoList: this.modalData.selectedObjects.map(obj => ({id: obj.id!, entityType: isDocumentEntity(obj.entityType!) ? EntityType.DOCUMENT : EntityType.FILE})),
        }
      }),
      this
    ).subscribe({
      next: _ => {
        if (this.isBulkOperation) {
          this.documentToastService.dispatchBulkOperationToast(DocumentToastType.BULK_AGENDA_TRANSFER_STARTED, {
            [InternalNotificationKey.COUNT]: this.modalData.selectedObjects.length
          });
        } else {
          const selectedObject = this.modalData.selectedObjects[0];
          if (isDocumentEntity(this.modalData.selectedObjects[0].entityType)) {
            this.documentToastService.dispatchDocumentInfoToast(DocumentToastType.AGENDA_TRANSFER_STARTED, {
              [InternalNotificationKey.DOCUMENT_ID]: selectedObject.id!,
              [InternalNotificationKey.DOCUMENT_SUBJECT]: selectedObject.subject
            });
          } else {
            this.fileToastService.dispatchFileInfoToast(FileToastType.FILE_AGENDA_TRANSFER_STARTED, {
              [InternalNotificationKey.FILE_ID]: selectedObject.id!,
              [InternalNotificationKey.FILE_SUBJECT]: selectedObject.subject
            });
          }
        }
        this.form.markAsPristine();
        this.modalRef.close(true);
      },
      error: err => {
        const errorData = esslErrorDtoToToastParameters(this.translateService, err.error);

        if (this.isBulkOperation) {
          this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.BULK_AGENDA_TRANSFER_ERROR, errorData);
        } else {
          if (isDocumentEntity(this.modalData.selectedObjects[0].entityType)) {
            this.documentToastService.dispatchDocumentErrorToast(DocumentToastType.AGENDA_TRANSFER_ERROR, errorData);
          } else {
            this.fileToastService.dispatchFileErrorToast(FileToastType.FILE_AGENDA_TRANSFER_ERROR, errorData);
          }
        }
      }
    });
  }

  cancel() {
    this.modalRef.close(false);
  }
}
