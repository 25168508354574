import {booleanAttribute, ChangeDetectionStrategy, Component, inject, Input, ViewChild} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, switchMap} from 'rxjs/operators';
import {EsslObjectType, TransactionLogEventType} from '|api/commons';
import {ApiTransactionEventAttributeService, TransactionEventDto} from '|api/transactionlog';
import {TransactionsDataSource} from './transactions.datasource';
import {
  ColumnDefinition,
  extendDefaultTableConfig,
  FilterType,
  SearchParams,
  TableColumnsData,
  TableComponent
} from '@icz/angular-table';
import {TransactionSidebarEvent} from '../transaction-sidebar/transaction-sidebar.component';
import {TransactionLogSearchService} from '../../../services/transaction-log-search.service';
import {CodebookService} from '../../../core/services/codebook.service';
import {OrganizationalStructureService} from '../../../core/services/organizational-structure.service';
import {enumToOptions, namedDtoToOption} from '../../../core/services/data-mapping.utils';
import {getUserFullName} from '../../../model';
import {Button} from '../../button-collection/button-collection.component';
import {IczOption} from '@icz/angular-form-elements';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';

type TransactionHistoryType = 'entity' | 'transaction-protocol';

type TransactionsTableColumnKey = keyof TransactionEventDto | 'entityIdentifier.uid' | 'parentIdentifier.uid';

const SYSTEM_OPTION: IczOption = {
  value: -1,
  label: 'Systém',
};

@Component({
  selector: 'icz-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsComponent implements IczOnChanges {

  private searchService = inject(TransactionLogSearchService);
  private codebookService = inject(CodebookService);
  private orgStructureService = inject(OrganizationalStructureService);
  private apiTransactionEventAttributeService = inject(ApiTransactionEventAttributeService);

  @ViewChild('iczTable') iczTable!: TableComponent<TransactionsTableColumnKey>;

  @Input({required: true}) type!: TransactionHistoryType;
  @Input() identifier: Nullable<string> = null;
  @Input() idSource: Nullable<string>;
  @Input() entityId: Nullable<number>;
  @Input() esslObjectType: Nullable<EsslObjectType>;
  @Input({transform: booleanAttribute}) global = false;

  dataSource!: TransactionsDataSource;

  config = extendDefaultTableConfig({
    hasActiveRow: true,
    disableLocalStorageSortPersistence: true,
  });

  functionalPositionsOptions$: Observable<IczOption[]> = this.orgStructureService.functionalPositions().pipe(
    map(entries => ([
      SYSTEM_OPTION,
      ...entries.map(namedDtoToOption()),
    ]))
  );

  userOptions$: Observable<IczOption[]> = this.codebookService.users().pipe(
    map(users => ([
      SYSTEM_OPTION,
      ...users.map(u => ({
        label: getUserFullName(u),
        value: u.id,
      })),
    ]))
  );

  get isTransactionProtocolTable() {
    return this.type === 'transaction-protocol';
  }

  private getTableColumns(): ColumnDefinition<TransactionsTableColumnKey>[] {
    const columns: ColumnDefinition<TransactionsTableColumnKey>[] = [
      {id: 'creationDate', label: 'Datum operace', filterType: FilterType.DATETIME},
      {id: 'userId', label: 'Uživatel', list$: this.userOptions$, filterType: FilterType.ENUM},
      {id: 'functionalPositionId', label: 'Funkční místo', filterType: FilterType.ENUM, list$: this.functionalPositionsOptions$},
      {
        id: 'esslObjectType',
        label: 'Druh objektu',
        list: enumToOptions('esslObjectType', EsslObjectType),
        filterType: FilterType.ENUM,
        allowTranslation: true,
      },
      {
        id: 'transactionLogEventType',
        label: 'Typ operace',
        list: enumToOptions('transactionLogEventType', TransactionLogEventType),
        filterType: FilterType.ENUM,
        allowTranslation: true,
      },
      {
        id: 'entityIdentifier.uid',
        label: 'Identifikátor',
        filterType: FilterType.TEXT,
      },
      {
        id: 'origin',
        label: 'Zdroj',
        filterType: FilterType.TEXT,
        allowTranslation: true,
      },
      // todo(rb) add Ztvárnění when BE ready
      // todo(rb) backend does not fill those data, however it might do so in the future
      // {id: 'description', label: 'Popis akce', filterType: FilterType.text},
    ];

    if (this.isTransactionProtocolTable) {
      return [...columns, {id: 'parentIdentifier.uid', label: 'Kontext', filterType: FilterType.TEXT}];
    }
    return columns;
  }

  columnsData = new TableColumnsData<TransactionsTableColumnKey>(this.getTableColumns());

  private _currentTransactionEvent$ = new BehaviorSubject<Nullable<TransactionEventDto>>(null);

  currentTransactionEvent$: Observable<Nullable<TransactionSidebarEvent>> = this._currentTransactionEvent$.pipe(
    filter(Boolean),
    switchMap(basicTransactionEvent =>
      this.apiTransactionEventAttributeService.transactionEventAttributeFindAllChangedByTransactionEventId({
        id: basicTransactionEvent!.id!,
      }).pipe(
        map(attributes => ({
          eventData: basicTransactionEvent,
          attributes,
        })),
      )
    )
  );

  isAttributePreviewOpened$ = this._currentTransactionEvent$.pipe(
    map(Boolean),
  );

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.global || changes.idSource || changes.identifier || changes.esslObjectType || changes.entityId) {
      this.dataSource = new TransactionsDataSource(
        this.searchService,
        {
          global: this.global,
          objectLookupParams: (
            this.esslObjectType && this.entityId ?
              {
                esslObjectType: this.esslObjectType,
                entityId: this.entityId,
              } :
              undefined
          ),
          uidLookupParams: (
            this.idSource && this.identifier ?
              {
                idSource: this.idSource,
                identifier: this.identifier,
              } :
              undefined
          ),
        }
      );
    }
  }

  setCurrentTransactionEvent(transactionEvent: Nullable<TransactionEventDto>) {
    this._currentTransactionEvent$.next(transactionEvent);
  }

  pageLoadStarted(searchParams: SearchParams) {
    this._currentTransactionEvent$.next(null);
  }

  onRowClick(transactionEvent: TransactionEventDto) {
    this.setCurrentTransactionEvent(transactionEvent);
  }

  // NYI
  onClickPrint(event: Event) {}

  onPreviewClose() {
    this.setCurrentTransactionEvent(null);
    this.iczTable.unsetActiveRow();
  }

  toolbarButtonClicked(button: Button) {
    button.action?.(button);
  }

}
