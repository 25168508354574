import {inject, Injectable} from '@angular/core';
import {Observable, pipe} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {
  ApiPropertyValueOauthService,
  PropertyValueCreateDto,
  PropertyValueDto,
  PropertyValueType,
  PropertyValueWithIdUpdateDto
} from '|api/config-server';
import {parseBoolean} from '@icz/angular-essentials';
import {DialogService} from '@icz/angular-modal';
import {ConfigSearchService} from './config-search.service';
import {CurrentSessionService} from './current-session.service';
import {FilterOperator, Page} from '@icz/angular-table';

export function configPropValuesToTypedValues() {
  return map((propValues: PropertyValueDto[]) => {
    return propValues.map((propValue: PropertyValueDto) => {
      let typedValue: Nullable<TypedConfigPropValue> = null;
      if (propValue.value && propValue.valueType) {
        switch (propValue.valueType) {
          case PropertyValueType.BOOLEAN:
            typedValue = parseBoolean(propValue.value.toString());
            break;
          case PropertyValueType.DATE:
          case PropertyValueType.TIME:
          case PropertyValueType.DATETIME:
            typedValue = propValue.value.toString();
            break;
          case PropertyValueType.INTEGER:
            typedValue = parseInt(propValue.value.toString(), 10);
            break;
          case PropertyValueType.DECIMAL:
            typedValue = Number(propValue.value.toString());
            break;
          case PropertyValueType.LIST:
            typedValue = JSON.parse(JSON.stringify(propValue.value));
            break;
          case PropertyValueType.STRING:
          default:
            typedValue = propValue.value.toString();
        }
      }
      return {
        key: propValue.key,
        value: typedValue,
        id: propValue.id,
        organizationId: propValue.organizationId,
        applicationName: propValue.applicationName
      } as TypedPropertyValue;
    });
  });
}

function validateConfigKeysResponse(configKeys: string[], dialogService: DialogService, showErrorDialog: boolean) {
  return tap((values: Record<string, TypedPropertyValue>) => {
    const missingPropertyKeys: string[] = [];
    configKeys.forEach(key => {
      if (!values.hasOwnProperty(key)) {
        missingPropertyKeys.push(key);
      }
    });
    if (missingPropertyKeys.length) {
      if (showErrorDialog) {
        dialogService.showError(`Nebyly definovány hodnoty kritických konfiguračních klíčů: ${missingPropertyKeys.toString()}`);
      }
      // eslint-disable-next-line no-console
      console.error(`Nebyly definovány hodnoty kritických konfiguračních klíčů: ${missingPropertyKeys.toString()}`);
    }
  });
}

export interface TypedPropertyValue {
  key: string;
  value: Nullable<TypedConfigPropValue>;
  id: number;
  organizationId: Nullable<number>;
  applicationName: Nullable<string>;
}

export type TypedConfigPropValue = string | number | boolean | string[];

@Injectable({
  providedIn: 'root',
})
export class ConfigPropValueService {

  private searchService = inject(ConfigSearchService);
  private configApiService = inject(ApiPropertyValueOauthService);
  private currentSessionService = inject(CurrentSessionService);
  private dialogService = inject(DialogService);

  private transformAndValidateValues = (keys: string[], showErrorDialog: boolean) => {
    return pipe(
      map((page: Page<PropertyValueDto>) => page.content),
      configPropValuesToTypedValues(),
      map(values => Object.fromEntries(values.map(v => [v.key, v]))),
      validateConfigKeysResponse(keys, this.dialogService, showErrorDialog),
    );
  };

  getManyPropValuesForConfigApp(keys: string[], showErrorDialog = false,  pageSize = 100, page = 0): Observable<Record<string, TypedPropertyValue>> {
    const searchParams = this.getSearchParamsForConfigKeyGet(pageSize, page, keys);

    return this.searchService.findConfigPropertyValues(searchParams).pipe(
      this.transformAndValidateValues(keys, showErrorDialog),
    );
  }

  getManyPropValues(keys: string[], showErrorDialog = false,  pageSize = 100, page = 0): Observable<Record<string, TypedPropertyValue>> {
    const searchParams = this.getSearchParamsForConfigKeyGet(pageSize, page, keys);

    return this.searchService.findConfigPropertyValuesOauth(searchParams).pipe(
      this.transformAndValidateValues(keys, showErrorDialog),
    );
  }

  getManyPropValuesByOrganization(keys: string[], showErrorDialog: boolean, returnUndefinedProperties: boolean) {
    return this.propertyValueOauthGetOrganization(keys, returnUndefinedProperties).pipe(
      configPropValuesToTypedValues(),
      map(values => Object.fromEntries(values.map(v => [v.key, v]))),
      validateConfigKeysResponse(keys, this.dialogService, showErrorDialog),
    );
  }

  propertyValueOauthGetOrganization(keys: string[], returnUndefinedProperties: boolean) {
    return this.configApiService.propertyValueOauthGetOrganizationProperties({
      body: {
        keys,
        returnUndefinedProperties,
      }
    });
  }

  propertyValueOauthUpdateOrCreate(typedPropertyValues: TypedPropertyValue[]) {
    const organizationalPropertyValuesToUpdate: PropertyValueWithIdUpdateDto[] = [];
    const organizationalPropertyValuesToCreate: PropertyValueCreateDto[] = [];

    typedPropertyValues.forEach(typedValue => {
      if (typedValue.id && typedValue.organizationId) {
        organizationalPropertyValuesToUpdate.push(typedValue as PropertyValueWithIdUpdateDto);
      } else {
        organizationalPropertyValuesToCreate.push({
          applicationName: typedValue.applicationName,
          key: typedValue.key,
          organizationId: this.currentSessionService.currentOrganization!.id,
          value: typedValue.value
        });
      }
    });

    return this.configApiService.propertyValueOauthUpdateOrCreateOrganizationalPropertyValues({
      body: {
        organizationalPropertyValuesToUpdate,
        organizationalPropertyValuesToCreate
      }});
  }

  private getSearchParamsForConfigKeyGet(pageSize: number, page: number, keys: string[]) {
    return {
      size: pageSize,
      page,
      filter: [
        {fieldName: 'propertyDefinition.key', operator: FilterOperator.inSet, value: String(keys ?? [])}
      ]
    };
  }
}
