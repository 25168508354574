import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

import {InterpolationContext} from '../essentials.utils';
import {IconComponent} from '../icon/icon.component';
import {TranslateModule} from '@ngx-translate/core';

/**
 * Tag color.
 */
export type IczTagColor = 'orange' | 'green' | 'grey' | 'red' | 'blue' | 'purple';

/**
 * A tag used for rendering and differentiating business entity states.
 */
@Component({
  selector: 'icz-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IconComponent, TranslateModule],
})
export class TagComponent {

  /**
   * Tag label. Can contain mustache-like expressions.
   */
  @Input({required: true})
  label: Nullable<string>;
  /**
   * Tag label interpolation context for mustache-like expressions.
   */
  @Input()
  labelContext: Nullable<InterpolationContext>;
  /**
   * Additional icon to accompany tag label.
   * Rendered to the left of tag label.
   */
  @Input()
  svgIcon!: string;
  /**
   * Tag color.
   */
  @Input()
  color: IczTagColor = 'orange';
  /**
   * If TRUE, makes the tag render with the flow of its surrounding text.
   */
  @Input()
  inline = false;

  @HostBinding('class.inline-flex')
  protected get isInlineFlex() {
    return this.inline;
  }

  @HostBinding('class.flex')
  protected get isFlex() {
    return !this.inline;
  }

  @HostBinding('class.red')
  protected get isRed() {
    return this.color === 'red';
  }

  @HostBinding('class.green')
  protected get isGreen() {
    return this.color === 'green';
  }

  @HostBinding('class.grey')
  protected get isGrey() {
    return this.color === 'grey';
  }

  @HostBinding('class.orange')
  protected get isOrange() {
    return this.color === 'orange';
  }

  @HostBinding('class.blue')
  protected get isBlue() {
    return this.color === 'blue';
  }

  @HostBinding('class.purple')
  protected get isPurple() {
    return this.color === 'purple';
  }

}
