import {inject, Injectable} from '@angular/core';
import {z} from 'zod';
import {EntityType} from '|api/commons';
import {InternalNotificationKey} from '|api/notification';
import {
  getToastBodyTemplateTranslateKey,
  getToastHeaderTemplateTranslateKey,
  MessageType,
  SErrorParameters,
  SImplicitlyStringifiable,
  ToastService
} from '../../../components/notifications/toast.service';
import {isDocumentEntity} from '../../../components/shared-business-components/shared-document.utils';

export enum CirculationToastType {
  FLOW_TASK_ASSIGNED_INITIATOR = 'FLOW_TASK_ASSIGNED_INITIATOR',
  FLOW_TASK_ASSIGNED_INITIATOR_ERROR = 'FLOW_TASK_ASSIGNED_INITIATOR_ERROR',
  FLOW_TASK_ASSIGNED_TARGET_PARTICIPANT = 'FLOW_TASK_ASSIGNED_TARGET_PARTICIPANT',
  FLOW_TASK_SUCCESS_INITIATOR = 'FLOW_TASK_SUCCESS_INITIATOR',
  FLOW_TASK_SUCCESS_TARGET_PARTICIPANT = 'FLOW_TASK_SUCCESS_TARGET_PARTICIPANT',
  FLOW_TASK_ERROR_TARGET_PARTICIPANT = 'FLOW_TASK_ERROR_TARGET_PARTICIPANT',
  FLOW_TASK_REJECTED_INITIATOR = 'FLOW_TASK_REJECTED_INITIATOR',
  FLOW_TASK_REJECTED_TARGET_PARTICIPANT = 'FLOW_TASK_REJECTED_TARGET_PARTICIPANT',
  FLOW_TASK_REJECTED_TARGET_PARTICIPANT_ERROR = 'FLOW_TASK_REJECTED_TARGET_PARTICIPANT_ERROR',
  FLOW_TASK_REVOKED_INITIATOR = 'FLOW_TASK_REVOKED_INITIATOR',
  FLOW_TASK_REVOKED_TARGET_PARTICIPANT = 'FLOW_TASK_REVOKED_TARGET_PARTICIPANT',
  FLOW_TASK_REVOKED_TARGET_PARTICIPANT_ERROR = 'FLOW_TASK_REVOKED_TARGET_PARTICIPANT_ERROR',
  FLOW_TASK_PASSED_INITIATOR = 'FLOW_TASK_PASSED_INITIATOR',
  FLOW_TASK_PASSED_TARGET_PARTICIPANT = 'FLOW_TASK_PASSED_TARGET_PARTICIPANT',
  FLOW_TASK_PASSED_TARGET_PARTICIPANT_ERROR = 'FLOW_TASK_PASSED_TARGET_PARTICIPANT_ERROR',
  FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR = 'FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR',
  FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR = 'FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR_ERROR',
  FLOW_HANDOVER_TASK_ASSIGNED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TASK_ASSIGNED_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TASK_SUCCESS_INITIATOR = 'FLOW_HANDOVER_TASK_SUCCESS_INITIATOR',
  FLOW_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT',
  FLOW_FILE_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT = 'FLOW_FILE_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TASK_TARGET_PARTICIPANT_ERROR = 'FLOW_HANDOVER_TASK_TARGET_PARTICIPANT_ERROR',
  FLOW_HANDOVER_TASK_REJECTED_INITIATOR = 'FLOW_HANDOVER_TASK_REJECTED_INITIATOR',
  FLOW_HANDOVER_TASK_REJECTED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TASK_REJECTED_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TASK_REVOKED_INITIATOR = 'FLOW_HANDOVER_TASK_REVOKED_INITIATOR',
  FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TASK_PASSED_INITIATOR = 'FLOW_HANDOVER_TASK_PASSED_INITIATOR',
  FLOW_HANDOVER_TASK_PASSED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TASK_PASSED_TARGET_PARTICIPANT',
  FLOW_HANDOVER_UNOPENED_TASK_RETURNED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_UNOPENED_TASK_RETURNED_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_INITIATOR = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_INITIATOR',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_RETURNED_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_INITIATOR = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_INITIATOR',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_REJECTED_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_INITIATOR = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_INITIATOR',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_REVOKED_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_INITIATOR = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_INITIATOR',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_SUCCESS_TARGET_PARTICIPANT',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_INITIATOR = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_INITIATOR',
  FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_TARGET_PARTICIPANT = 'FLOW_HANDOVER_TO_OWN_HANDS_TASK_PASSED_TARGET_PARTICIPANT',
  FLOW_DOCUMENT_HANDOVER_TO_EXTERNAL_APP_STARTED = 'FLOW_DOCUMENT_HANDOVER_TO_EXTERNAL_APP_STARTED',
  FLOW_FILE_HANDOVER_TO_EXTERNAL_APP_STARTED = 'FLOW_FILE_HANDOVER_TO_EXTERNAL_APP_STARTED',
  FLOW_BULK_HANDOVER_TO_EXTERNAL_APP_STARTED = 'FLOW_BULK_HANDOVER_TO_EXTERNAL_APP_STARTED',
  FLOW_BULK_TAKEOVER_FROM_EXTERNAL_APP = 'FLOW_BULK_TAKEOVER_FROM_EXTERNAL_APP',
  FLOW_TAKEOVER_FILE_FROM_EXTERNAL_APP = 'FLOW_TAKEOVER_FILE_FROM_EXTERNAL_APP',
  FLOW_TAKEOVER_DOCUMENT_FROM_EXTERNAL_APP = 'FLOW_TAKEOVER_DOCUMENT_FROM_EXTERNAL_APP',
  FLOW_BULK_HANDOVER = 'FLOW_BULK_HANDOVER',
  FLOW_BULK_HANDOVER_UNOPENED_RETURN_FOR_REGISTER_CONTENT = 'FLOW_BULK_HANDOVER_UNOPENED_RETURN_FOR_REGISTER_CONTENT',
  FLOW_BULK_TASK_ASSIGNED_INITIATOR = 'FLOW_BULK_TASK_ASSIGNED_INITIATOR',
  FLOW_BULK_APPROVE_ERROR = 'FLOW_BULK_APPROVE_ERROR',
  FLOW_BULK_STATEMENT_ERROR = 'FLOW_BULK_STATEMENT_ERROR',
  FLOW_BULK_STATEMENT_BY_TARGET_ERROR = 'FLOW_BULK_STATEMENT_BY_TARGET_ERROR',
  FLOW_BULK_STATEMENT_BY_TARGET = 'FLOW_BULK_STATEMENT_BY_TARGET',
  FLOW_BULK_ACKNOWLEDGEMENT_ERROR = 'FLOW_BULK_ACKNOWLEDGEMENT_ERROR',
  FLOW_BULK_ACKNOWLEDGEMENT_BY_TARGET = 'FLOW_BULK_ACKNOWLEDGEMENT_BY_TARGET',
  FLOW_BULK_ACKNOWLEDGEMENT_BY_TARGET_ERROR = 'FLOW_BULK_ACKNOWLEDGEMENT_BY_TARGET_ERROR',
  FLOW_BULK_HANDOVER_TAKEOVER = 'FLOW_BULK_HANDOVER_TAKEOVER',
  FLOW_BULK_REVOKE = 'FLOW_BULK_REVOKE',
  FLOW_BULK_DISAPPROVE = 'FLOW_BULK_DISAPPROVE',
  FLOW_BULK_REJECT = 'FLOW_BULK_REJECT',
  FLOW_BULK_PASS_HANDOVER = 'FLOW_BULK_PASS_HANDOVER',
  FLOW_BULK_PASS_HANDOVER_ERROR = 'FLOW_BULK_PASS_HANDOVER_ERROR',
  FLOW_BULK_COMPONENT_APPROVE = 'FLOW_BULK_COMPONENT_APPROVE',
  FLOW_BULK_DOCUMENT_APPROVE = 'FLOW_BULK_DOCUMENT_APPROVE',
  FLOW_BULK_COMPONENT_DISAPPROVE = 'FLOW_BULK_COMPONENT_DISAPPROVE',
  FLOW_BULK_ANALOG_COMPONENT_SIGN_STARTED = 'FLOW_BULK_ANALOG_COMPONENT_SIGN_STARTED',
  FLOW_BULK_COMPONENT_HAND_FOR_SIGN_ERROR = 'FLOW_BULK_COMPONENT_HAND_FOR_SIGN_ERROR',
  FLOW_BULK_TASK_BY_TARGET_ERROR = 'FLOW_BULK_TASK_BY_TARGET_ERROR',
  FLOW_BULK_COMPONENT_DISAPPROVE_ERROR = 'FLOW_BULK_COMPONENT_DISAPPROVE_ERROR',
  FLOW_BULK_DISAPPROVE_ERROR = 'FLOW_BULK_DISAPPROVE_ERROR',
  FLOW_BULK_HANDOVER_TASK_ASSIGNED_INITIATOR = 'FLOW_BULK_HANDOVER_TASK_ASSIGNED_INITIATOR',
  FLOW_BULK_HAND_FOR_APPROVAL_DOCUMENT_INITIATOR = 'FLOW_BULK_HAND_FOR_APPROVAL_DOCUMENT_INITIATOR',
  FLOW_BULK_HAND_FOR_APPROVAL_COMPONENT_INITIATOR = 'FLOW_BULK_HAND_FOR_APPROVAL_COMPONENT_INITIATOR',
  FLOW_BULK_HAND_FOR_STATEMENT_INITIATOR = 'FLOW_BULK_HAND_FOR_STATEMENT_INITIATOR',
  FLOW_BULK_HAND_FOR_ACKNOWLEDGMENT_INITIATOR = 'FLOW_BULK_HAND_FOR_ACKNOWLEDGMENT_INITIATOR',
}

export const SCirculationTaskAssignedInitiatorData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskAssignedInitiatorData = z.infer<typeof SCirculationTaskAssignedInitiatorData>;

export const SCirculationTaskAssignedTargetParticipantData = z.object({
  [InternalNotificationKey.INITIATOR_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.INITIATOR_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskAssignedTargetParticipantData = z.infer<typeof SCirculationTaskAssignedTargetParticipantData>;

export const SCirculationTaskSuccessInitiatorData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskSuccessInitiatorData = z.infer<typeof SCirculationTaskSuccessInitiatorData>;

export const SCirculationTaskSuccessTargetParticipantData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskSuccessTargetParticipantData = z.infer<typeof SCirculationTaskSuccessTargetParticipantData>;

export const SCirculationTaskRejectedInitiatorData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskRejectedInitiatorData = z.infer<typeof SCirculationTaskRejectedInitiatorData>;

export const SCirculationTaskRejectedTargetParticipantData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskRejectedTargetParticipantData = z.infer<typeof SCirculationTaskRejectedTargetParticipantData>;

export const SCirculationTaskRevokedInitiatorData = z.object({
  [InternalNotificationKey.ACTIVITY_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskRevokedInitiatorData = z.infer<typeof SCirculationTaskRevokedInitiatorData>;

export const SCirculationTaskRevokedTargetParticipantData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.INITIATOR_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.INITIATOR_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskRevokedTargetParticipantData = z.infer<typeof SCirculationTaskRevokedTargetParticipantData>;

export const SCirculationTaskPassedInitiatorData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskPassedInitiatorData = z.infer<typeof SCirculationTaskPassedInitiatorData>;

export const SCirculationTaskPassedTargetParticipantData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationTaskPassedTargetParticipantData = z.infer<typeof SCirculationTaskPassedTargetParticipantData>;

export const SCirculationHandoverTaskAssignedInitiatorData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskAssignedInitiatorData = z.infer<typeof SCirculationHandoverTaskAssignedInitiatorData>;

export const SCirculationHandoverTaskAssignedTargetParticipantData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskAssignedTargetParticipantData = z.infer<typeof SCirculationHandoverTaskAssignedTargetParticipantData>;

export const SCirculationHandoverTaskSuccessInitiatorData = z.object({
  [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskSuccessInitiatorData = z.infer<typeof SCirculationHandoverTaskSuccessInitiatorData>;

export const SCirculationHandoverTaskSuccessTargetParticipantData = z.object({
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskSuccessTargetParticipantData = z.infer<typeof SCirculationHandoverTaskSuccessTargetParticipantData>;

export const SCirculationHandoverTaskRejectedInitiatorData = z.object({
  [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskRejectedInitiatorData = z.infer<typeof SCirculationHandoverTaskRejectedInitiatorData>;

export const SCirculationHandoverTaskRejectedTargetParticipantData = z.object({
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskRejectedTargetParticipantData = z.infer<typeof SCirculationHandoverTaskRejectedTargetParticipantData>;

export const SCirculationHandoverTaskRevokedInitiatorData = z.object({
  [InternalNotificationKey.ACTIVITY_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskRevokedInitiatorData = z.infer<typeof SCirculationHandoverTaskRevokedInitiatorData>;

export const SCirculationHandoverToOwnHandsTaskReturnedInitiatorData = z.object({
  [InternalNotificationKey.ACTIVITY_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverToOwnHandsReturnedInitiatorData = z.infer<typeof SCirculationHandoverToOwnHandsTaskReturnedInitiatorData>;

export const SCirculationHandoverTaskRevokedTargetParticipantData = z.object({
  [InternalNotificationKey.INITIATOR_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.INITIATOR_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REASON]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskRevokedTargetParticipantData = z.infer<typeof SCirculationHandoverTaskRevokedTargetParticipantData>;

export const SCirculationHandoverTaskPassedInitiatorData = z.object({
  [InternalNotificationKey.TARGET_PARTICIPANT_USERNAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.TARGET_PARTICIPANT_FP_CODE]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskPassedInitiatorData = z.infer<typeof SCirculationHandoverTaskPassedInitiatorData>;

export const SCirculationHandoverTaskPassedTargetParticipantData = z.object({
  [InternalNotificationKey.DOCUMENT_SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.DOCUMENT_ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE_CLASS]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskPassedTargetParticipantData = z.infer<typeof SCirculationHandoverTaskPassedTargetParticipantData>;

export const SCirculationHandoverTaskToExternalAppData = z.object({
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: z.optional(SImplicitlyStringifiable),
  [InternalNotificationKey.SUBJECT]: z.optional(SImplicitlyStringifiable),
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
});
export type CirculationHandoverTaskToExternalAppData = z.infer<typeof SCirculationHandoverTaskToExternalAppData>;

export const SCirculationBulkHandoverTaskToExternalAppData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type CirculationBulkHandoverTaskToExternalAppData = z.infer<typeof SCirculationBulkHandoverTaskToExternalAppData>;

export const SCirculationBulkTaskData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: z.optional(SImplicitlyStringifiable),
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});

export const SCirculationBulkTaskAssignedInitiatorData = z.object({
  [InternalNotificationKey.SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
});
export type CirculationBulkHandoverTaskAssignedInitiatorData = z.infer<typeof SCirculationBulkTaskAssignedInitiatorData>;

export const SCirculationBulkTaskAssignedInitiatorErrorData = z.object({
  [InternalNotificationKey.SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type CirculationBulkTaskAssignedInitiatorErrorData = z.infer<typeof SCirculationBulkTaskAssignedInitiatorErrorData>;


export const SCirculationBulkTaskSuccessByTargetData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
});
export type CirculationBulkTaskSuccessByTargetData = z.infer<typeof SCirculationBulkTaskSuccessByTargetData>;

export const SCirculationBulkTaskErrorByTargetData = z.object({
  [InternalNotificationKey.ACTIVITY_TYPE_KEY]: SImplicitlyStringifiable,
  [InternalNotificationKey.SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_DESCRIPTION]: SImplicitlyStringifiable,
  [InternalNotificationKey.ERROR_PARAMETERS]: SErrorParameters,
});
export type CirculationBulkTaskErrorByTargetData = z.infer<typeof SCirculationBulkTaskErrorByTargetData>;


export type CirculationBulkTaskData = z.infer<typeof SCirculationBulkTaskData>;

@Injectable({
  providedIn: 'root'
})
export class CirculationToastService {

  private toastService = inject(ToastService);

  dispatchTaskAssignedInitiator(templateData: CirculationTaskAssignedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_ASSIGNED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationTaskAssignedInitiatorData,
        },
      },
    });
  }

  dispatchTaskAssignedTargetParticipant(templateData: CirculationTaskAssignedTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_ASSIGNED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationTaskAssignedTargetParticipantData,
        },
      },
    });
  }

  dispatchTaskSuccessInitiator(templateData: CirculationTaskSuccessInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_SUCCESS_INITIATOR),
          templateData,
          templateDataSchema: SCirculationTaskSuccessInitiatorData,
        },
      },
    });
  }

  dispatchTaskSuccessTargetParticipant(templateData: CirculationTaskSuccessTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_SUCCESS_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationTaskSuccessTargetParticipantData,
        },
      },
    });
  }

  dispatchTaskRejectedInitiator(templateData: CirculationTaskRejectedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_REJECTED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationTaskRejectedInitiatorData,
        },
      },
    });
  }

  dispatchTaskRejectedTargetParticipant(templateData: CirculationTaskRejectedTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_REJECTED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationTaskRejectedTargetParticipantData,
        },
      },
    });
  }

  // Return for register content is considered POSITIVE action
  dispatchTaskReturnedForRegisterContentTargetParticipant(templateData: CirculationTaskSuccessTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_UNOPENED_TASK_RETURNED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationTaskSuccessTargetParticipantData,
        },
      },
    });
  }

  dispatchTaskRevokedInitiator(templateData: CirculationTaskRevokedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_REVOKED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationTaskRevokedInitiatorData,
        },
      },
    });
  }

  dispatchTaskRevokedTargetParticipant(templateData: CirculationTaskRevokedTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_REVOKED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationTaskRevokedTargetParticipantData,
        },
      },
    });
  }

  dispatchTaskPassedInitiator(templateData: CirculationTaskPassedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_PASSED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationTaskPassedInitiatorData,
        },
      },
    });
  }

  dispatchTaskPassedTargetParticipant(templateData: CirculationTaskPassedTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TASK_PASSED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationTaskPassedTargetParticipantData,
        },
      },
    });
  }

  // todo missing ERROR toast in BE implementation!!

  // todo not fully specified in FS
  dispatchHandoverTaskAssignedInitiator(templateData: CirculationHandoverTaskAssignedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_ASSIGNED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationHandoverTaskAssignedInitiatorData,
        },
      },
    });
  }

  // todo not fully specified in FS
  dispatchHandoverTaskAssignedTargetParticipant(templateData: CirculationHandoverTaskAssignedTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_ASSIGNED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationHandoverTaskAssignedTargetParticipantData,
        },
      },
    });
  }

  dispatchHandoverTaskSuccessInitiator(templateData: CirculationHandoverTaskSuccessInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_SUCCESS_INITIATOR),
          templateData,
          templateDataSchema: SCirculationHandoverTaskSuccessInitiatorData,
        },
      },
    });
  }

  dispatchHandoverTaskSuccessTargetParticipant(templateData: CirculationHandoverTaskSuccessTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_SUCCESS_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationHandoverTaskSuccessTargetParticipantData,
        },
      },
    });
  }

  dispatchHandoverTaskRejectedInitiator(templateData: CirculationHandoverTaskRejectedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_REJECTED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationHandoverTaskRejectedInitiatorData,
        },
      },
    });
  }

  dispatchHandoverTaskRejectedTargetParticipant(templateData: CirculationHandoverTaskRejectedTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_REJECTED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationHandoverTaskRejectedTargetParticipantData,
        },
      },
    });
  }

  dispatchHandoverTaskRevokedInitiator(templateData: CirculationHandoverTaskRevokedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_REVOKED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationHandoverTaskRevokedInitiatorData,
        },
      },
    });
  }

  dispatchHandoverTaskRevokedTargetParticipant(templateData: CirculationHandoverTaskRevokedTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_REVOKED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationHandoverTaskRevokedTargetParticipantData,
        },
      },
    });
  }

  dispatchHandoverTaskPassedInitiator(templateData: CirculationHandoverTaskPassedInitiatorData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      isBackendNotification: true,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_PASSED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationHandoverTaskPassedInitiatorData,
        },
      },
    });
  }

  dispatchHandoverTaskPassedTargetParticipant(templateData: CirculationHandoverTaskPassedTargetParticipantData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_HANDOVER_TASK_PASSED_TARGET_PARTICIPANT),
          templateData,
          templateDataSchema: SCirculationHandoverTaskPassedTargetParticipantData,
        },
      },
    });
  }

  // todo missing ERROR toast in BE implementation!!

  dispatchHandoverTaskToExternalAppStarted(templateData: CirculationHandoverTaskToExternalAppData) {
    let template = '';
    if (isDocumentEntity(templateData[InternalNotificationKey.ENTITY_TYPE] as EntityType)) {
      template = getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_DOCUMENT_HANDOVER_TO_EXTERNAL_APP_STARTED);
    } else {
      template = getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_FILE_HANDOVER_TO_EXTERNAL_APP_STARTED);
    }

    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template,
          templateData,
          templateDataSchema: SCirculationHandoverTaskToExternalAppData,
        },
      },
    });
  }

  dispatchBulkHandoverTaskToExternalAppStarted(templateData: CirculationBulkHandoverTaskToExternalAppData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_BULK_HANDOVER_TO_EXTERNAL_APP_STARTED),
          templateData,
          templateDataSchema: SCirculationBulkHandoverTaskToExternalAppData,
        },
      },
    });
  }

  dispatchBulkTakeoverReturnedFromExternalApp(templateData: CirculationBulkHandoverTaskToExternalAppData) {
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template: getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_BULK_TAKEOVER_FROM_EXTERNAL_APP),
          templateData,
          templateDataSchema: SCirculationBulkHandoverTaskToExternalAppData,
        },
      },
    });
  }

  dispatchTakeoverReturnedFromExternalApp(templateData: CirculationHandoverTaskToExternalAppData) {
    let template = '';
    if (isDocumentEntity(templateData[InternalNotificationKey.ENTITY_TYPE] as EntityType)) {
      template = getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TAKEOVER_DOCUMENT_FROM_EXTERNAL_APP);
    } else {
      template = getToastBodyTemplateTranslateKey(CirculationToastType.FLOW_TAKEOVER_FILE_FROM_EXTERNAL_APP);
    }
    this.toastService.dispatchToast({
      type: MessageType.INFO,
      data: {
        header: {
          template,
          templateData,
          templateDataSchema: SCirculationHandoverTaskToExternalAppData,
        },
      },
    });
  }

  dispatchBulkTaskToast(templateData: CirculationBulkTaskData, toastType: CirculationToastType) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(toastType),
          templateData,
          templateDataSchema: SCirculationBulkTaskData,
        },
      },
    });
  }

  dispatchBulkHandoverTaskToast(templateData: CirculationBulkTaskData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(CirculationToastType.FLOW_BULK_HANDOVER),
          templateData,
          templateDataSchema: SCirculationBulkTaskData,
        },
      },
    });
  }

  dispatchBulkTaskAssignedInitiator(templateData: CirculationBulkTaskData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(CirculationToastType.FLOW_BULK_TASK_ASSIGNED_INITIATOR),
          templateData,
          templateDataSchema: SCirculationBulkTaskData,
        },
      },
    });
  }

  dispatchBulkHandoverTakeoverTaskToast(templateData: CirculationBulkTaskData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(CirculationToastType.FLOW_BULK_HANDOVER_TAKEOVER),
          templateData,
          templateDataSchema: SCirculationBulkTaskData,
        },
      },
    });
  }

  dispatchBulkPassToNextTaskToast(templateData: CirculationBulkTaskData) {
    this.toastService.dispatchToast({
      type: MessageType.WARNING,
      data: {
        header: {
          template: getToastHeaderTemplateTranslateKey(CirculationToastType.FLOW_BULK_PASS_HANDOVER),
          templateData,
          templateDataSchema: SCirculationBulkTaskData,
        },
      },
    });
  }

}
