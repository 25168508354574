import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {OrganizationalStructureEntityType} from '|api/commons';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczFormArray, IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {
  OrganizationalStructureOption,
  OrganizationalStructureService
} from '../../../../core/services/organizational-structure.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczOption} from '@icz/angular-form-elements';

@Component({
  selector: 'icz-permission-selector-form',
  templateUrl: './permission-selector-form.component.html',
  styleUrls: ['./permission-selector-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionSelectorFormComponent implements OnInit, IczOnChanges {

  protected loadingService = inject(LoadingIndicatorService);
  private organizationalStructureService = inject(OrganizationalStructureService);

  @Input({required: true})
  permissionOptions!: IczOption[];
  @Input({required: true})
  permissionsListForm!: IczFormArray;
  @Input()
  useFunctionalPositions: Nullable<boolean>;
  @Input()
  disableCurrentFunctionalPosition = true;
  @Output()
  permissionListChanged = new EventEmitter<void>();

  permissionsToAssignForm = new IczFormGroup({
    organizationalStructureEntityId: new IczFormControl<Nullable<number>>(),
    organizationalStructureEntityType: new IczFormControl<Nullable<OrganizationalStructureEntityType>>(),
    permissionType: new IczFormControl<Nullable<string>>(),
  });

  orgStructureOptions: OrganizationalStructureOption[] = [];
  disabledOptionValues: (number | string)[] = [];

  get isAddPermissionButtonDisabled() {
    return Boolean(
      !this.permissionsToAssignForm.value.organizationalStructureEntityId ||
      !this.permissionsToAssignForm.value.organizationalStructureEntityType ||
      !this.permissionsToAssignForm.value.permissionType
    );
  }

  ngOnInit() {
    this.organizationalStructureService.orgStructureOptions().subscribe(oso => {
      this.orgStructureOptions = oso;
    });
  }

  ngOnChanges(changes: IczSimpleChanges<this>): void {
    if (changes.permissionsListForm && changes.permissionsListForm.currentValue) {
      this.resolveDisabledOptions(changes.permissionsListForm.currentValue);
    }
  }

  getOrgEntityNameValue(form: IczFormGroup) {
    const originId = form.get('organizationalStructureEntityType')!.value === OrganizationalStructureEntityType.FUNCTIONAL_POSITION ? 'fp' : 'ou';
    const value = form.get('organizationalStructureEntityId')!.value;
    const opt = this.orgStructureOptions.find(o => o.id === value && o.originId === originId);
    return opt?.label;
  }

  isFM(form: IczFormGroup) {
    return form.get('organizationalStructureEntityType')!.value === OrganizationalStructureEntityType.FUNCTIONAL_POSITION;
  }

  deletePermission(index: number) {
    if (index >= 0) {
      this.permissionsListForm?.removeAt(index);
      this.resolveDisabledOptions(this.permissionsListForm);
      this.permissionListChanged.next();
    }
  }

  addToPermissionsList() {
    const newPermissionToAssign = this.permissionsToAssignForm.value;
    if (newPermissionToAssign.organizationalStructureEntityType === OrganizationalStructureEntityType.FUNCTIONAL_POSITION) {
      const fmAsId = newPermissionToAssign.organizationalStructureEntityId;
      newPermissionToAssign.organizationalStructureEntityId = this.orgStructureOptions.find(o => o.originId === 'fp' && o.value === fmAsId)!.id;
    }

    this.permissionsListForm.incrementSize().patchValue(newPermissionToAssign);
    this.resolveDisabledOptions(this.permissionsListForm);
    this.permissionListChanged.next();
    this.permissionsToAssignForm.reset();
  }

  valueChangeWithOriginIds(v: Nullable<IczOption[] | IczOption>) {
    const value = v as Nullable<IczOption>;
    if (value) {
      let entityType: Nullable<OrganizationalStructureEntityType>;
      if (value.originId === 'fp') {
        entityType = OrganizationalStructureEntityType.FUNCTIONAL_POSITION;
      } else if (value.originId === 'ou') {
        entityType = OrganizationalStructureEntityType.ORGANIZATIONAL_UNIT;
      } else {
        entityType = null;
      }
      this.permissionsToAssignForm.get('organizationalStructureEntityType')!.setValue(entityType);
    }
  }

  resolveDisabledOptions(formArray: Nullable<IczFormArray>) {
    const valuesToDisable: (number | string)[] = [];

    if (this.orgStructureOptions.length && formArray) {
      formArray.controls.forEach(ctrlGroup => {
        const orgEntityId = ctrlGroup.get('organizationalStructureEntityId')!.value;
        const orgEntityType = ctrlGroup.get('organizationalStructureEntityType')!.value;

        if (orgEntityType === OrganizationalStructureEntityType.FUNCTIONAL_POSITION) {
          const fmValue = this.orgStructureOptions.find(o => o.originId === 'fp' && o.id === orgEntityId)!.value;
          if (fmValue) valuesToDisable.push(fmValue);
        } else {
          valuesToDisable.push(orgEntityId);
        }
      });
    }

    this.disabledOptionValues = valuesToDisable;
  }

}
