<div class="gap-2 row grow" [class.reserved]="option.isReserved" [class.separator]="option.isSeparator">
  @if (option.icon) {
    <icz-icon class="mr-8" size="small" [svgIcon]="option.icon"></icz-icon>
  }
  @if (option.disableTranslate) {
    <div class="option-label grow">{{ option.label }}</div>
  } @else {
    <div class="option-label grow">{{ option.label | translate }}</div>
  }
  <ng-content select="[afterItemLabel]"></ng-content>
</div>
