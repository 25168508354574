@if (isSelectedClassifierExpired) {
  <icz-alert
    severity="warning"
    class="mb-12" heading="Neplatný typ spisu"
  ></icz-alert>
}
<ng-container [formGroup]="form">
  <icz-form-autocomplete
    [label]="label"
    [isMultiselect]="isMultiselect"
    [formControlName]="controlName"
    [fieldDisabled]="fieldDisabled"
    [options]="classifierOptions"
  ></icz-form-autocomplete>
</ng-container>
