import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, combineLatest, isObservable, Observable, of, Subject} from 'rxjs';
import {filter, map, tap} from 'rxjs/operators';
import {
  FormOptions,
  FormOptionsDefinition,
  IImplicitTemplateContext,
  IPopoverContext,
  ISelectedOptionContext,
  makeDefaultOptionsDefinition,
  OptionsDefinitionFactory,
} from '../form-autocomplete.model';
import {FormFieldComponent} from '../../form-field/form-field.component';
import {IconComponent, LoadingIndicatorService, PopoverComponent, TooltipDirective} from '@icz/angular-essentials';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AsyncPipe, NgStyle, NgTemplateOutlet} from '@angular/common';
import {CdkOverlayOrigin} from '@angular/cdk/overlay';
import {IczOption} from '../../form-elements.model';

/**
 * Implements full-text search in translated Option label.
 */
function defaultTranslatedStrForSearch(translateService: TranslateService) {
  return (option: IczOption) => option.disableTranslate ? option.label : translateService.instant(option.label ?? '');
}

/**
 * @internal
 *
 * Composable Form Autocomplete allows the developer to create numerous variations
 * of a component which searches for a value in a list and serves as a select/multiselect.
 *
 * Responsibilities:
 * - Rendering a small form field
 * - Passing synchronous data made from asynchronous data sources to child components
 *   using template contexts and async pipe
 * - Handling data flows for fulltext search and value selection (model->view / view->model)
 * - Handling focus event, blur event, tab-chain and escape keypresses
 *
 * Child components are:
 * - Popover Content component - e.g. FormAutocompleteList - must accept IPopoverContext
 *   Responsibilities:
 *   - Handling keyboard shortcuts for value browsing+selection (i.e. ArrowUp/ArrowDown/Enter)
 *     cause those might change based on popover content!
 *   - Adding/removing items to/from selected Options by sending a complete array of currently selected options
 *   - Having a text input for and passing search term changes to THIS component if desired
 *   - Rendering the options
 *
 * - Selected Option component - e.g. FormAutocompleteListTextItem inside an ngFor
 *   must accept ISelectedOptionContext
 *   Responsibilities:
 *   - Rendering a selected Option in user-friendly way - additional data
 *     for rendering can be passed to Option#data
 */
@Component({
  selector: 'icz-composable-form-autocomplete',
  templateUrl: './composable-form-autocomplete.component.html',
  styleUrls: ['./composable-form-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgTemplateOutlet,
    CdkOverlayOrigin,
    PopoverComponent,
    IconComponent,
    TranslateModule,
    NgStyle,
    FormFieldComponent,
    TooltipDirective,
  ],
})
export class ComposableFormAutocompleteComponent implements OnInit {

  private cd = inject(ChangeDetectorRef);
  private translateService = inject(TranslateService);
  private loadingService = inject(LoadingIndicatorService);
  private destroyRef = inject(DestroyRef);

  readonly MULTISELECT_VALUES_LIST_MAX_LINES = 5;
  private readonly VALUES_LIST_MIN_WIDTH_PX = 150;

  @ViewChild('selectedValueField', {read: FormFieldComponent})
  selectedValueField!: FormFieldComponent;
  @ViewChild('selectedValueField', {read: ElementRef})
  selectedValueFieldEl!: ElementRef;

  @Input({required: true})
  set options(options: FormOptions) {
    this._options = options;
    this.setUpOptionsDefinition(options, defaultTranslatedStrForSearch(this.translateService));
  }

  @Input({required: true})
  set value(newValues: IczOption[]) {
    if (newValues === null) {
      this.selectedOptions$.next([]);
    }
    else {
      this.selectedOptions$.next(newValues);
    }
  }
  @Input({required: true})
  label!: string;
  @Input({required: true})
  placeholder!: string;
  @Input({required: true})
  disabled: Nullable<boolean> = false;
  @Input({required: true})
  clearable = false;
  @Input({required: true})
  isMultiselect: Nullable<boolean> = false;
  @Input({required: true})
  asPopover = true;
  @Input({required: true})
  required = false;
  @Input()
  autoSizeMax = 1;
  // minimal length of search term when to start effectively searching and displaying options
  @Input()
  minSearchTermLength = 0;
  @Input({required: true})
  popoverContentTemplate!: TemplateRef<IImplicitTemplateContext<IPopoverContext>>;
  @Input({required: true})
  selectedOptionTemplate!: TemplateRef<IImplicitTemplateContext<ISelectedOptionContext>>;
  @Input()
  optionsDefinitionFactory: OptionsDefinitionFactory = makeDefaultOptionsDefinition;
  @Input()
  set searchIndexer(newIndexer: Nullable<(o: IczOption) => string>) {
    if (newIndexer) {
      this.setUpOptionsDefinition(this._options, newIndexer);
    }
  }
  @Input()
  allowLoadingIndicator: Nullable<boolean> = true;
  @Input()
  rightLabel: Nullable<string>;
  @Input()
  rightLabelTooltip: Nullable<string>;
  @Input()
  showRightLabelPopupOnClick = false;

  @Output()
  valueChange = new EventEmitter<IczOption[]>();
  @Output()
  blur = new EventEmitter<void>();
  @Output()
  cleared = new EventEmitter<void>();
  @Output()
  optionsListClosed = new EventEmitter<boolean>();

  private searchTerm$ = new BehaviorSubject<string>('');
  private options$ = new BehaviorSubject<IczOption[]>([]);
  protected selectedOptions$ = new BehaviorSubject<IczOption[]>([]);

  private optionsDefinition$ = new Subject<FormOptionsDefinition>();

  protected optionsOpen = false;

  private _options: FormOptions = [];

  private selectionChangedHandler = (sel: IczOption[]) => {
    this.selectedOptionsChanged(sel);

    if (!this.isMultiselect) {
      this.closeOptions(false);
      this.selectedValueField?.focusField();
    }
  };

  private searchTermChangedHandler = (term: Nullable<string>) => {
    this.searchTerm$.next(term ?? '');
  };

  protected popoverContentContext$: Observable<IImplicitTemplateContext<IPopoverContext>> = combineLatest([
    this.options$,
    this.selectedOptions$,
  ]).pipe(
    map(([options, selectedOptions]) => {
      return {
        $implicit: {
          options,
          selectedOptions,
          selectionChanged: this.selectionChangedHandler,
          searchTermChanged: this.searchTermChangedHandler,
        }
      };
    }),
  );

  protected selectedOptionContext$: Observable<IImplicitTemplateContext<ISelectedOptionContext>> = this.selectedOptions$.pipe(
    map(selectedOptions => ({
      $implicit: {selectedOptions}
    })),
  );

  protected get showClearButton() {
    return (
      this.clearable &&
      this.selectedOptions$.value.length && (
        (!this.isMultiselect && !this.required) ||
        this.isMultiselect
      )
    );
  }

  protected get selectedValueFieldWidthPx(): number {
    return Math.max(this.selectedValueFieldEl.nativeElement.offsetWidth, this.VALUES_LIST_MIN_WIDTH_PX);
  }

  ngOnInit(): void {
    this.optionsDefinition$.pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(formOptionsDefinition => {
      this.searchTerm$.pipe(
        filter(searchTerm => searchTerm.length >= this.minSearchTermLength),
        tap(_ => {
          if(this.allowLoadingIndicator) {
            this.loadingService.startLoading(this);
          }
        }),
        formOptionsDefinition.searchtermToOptionsOperator,
        tap(_ => {
          if(this.allowLoadingIndicator) {
            this.loadingService.endLoading(this);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(this.options$);

      this.searchTerm$.pipe(
        filter(searchTerm => searchTerm.length < this.minSearchTermLength),
        map(_ => []),
        tap(_ => this.loadingService.endLoading(this)),
        takeUntilDestroyed(this.destroyRef),
      ).subscribe(this.options$);
    });
  }

  closeOptions(isSynthetic: boolean) {
    if (this.optionsOpen) {
      this.optionsOpen = false;

      this.searchTerm$.next('');
      this.cd.detectChanges();
      this.optionsListClosed.emit(isSynthetic);
      this.loadingService.endLoading(this);
      this.blur.emit();
    }
  }

  protected clearClicked($event: Event) {
    $event.stopPropagation();

    if (!this.disabled) {
      this.selectedOptionsChanged([]);
      this.cleared.emit();
      this.blur.emit();
    }
  }

  protected selectedOptionsChanged(selectedOptions: IczOption[]) {
    this.selectedOptions$.next(selectedOptions);
    this.valueChange.emit(selectedOptions);

    this.blur.emit();
  }

  protected inputFieldClicked($event: Event) {
    this.openClicked($event);
  }

  protected openClicked($event: Event) {
    $event.stopPropagation();

    this.openPopover();
  }

  protected openPopover() {
    if (!this.disabled) {
      this.optionsOpen = true;
    }
  }

  protected keyPressed($event: KeyboardEvent) {
    switch ($event.key) {
      case 'Tab':
        this.tabPressed();
        break;
      case 'Escape':
        this.escapePressed($event);
        break;
    }
  }

  protected getInternalContentStyle(maxLines: number, isEmpty: boolean): Record<string, string> {
    const paddingCompensationValuePxEmpty = 7.5;
    const paddingCompensationValuePxNonempty = 4.5;
    const oneLineHeightPx = 18;

    let paddingCompensations: Record<string, string> = {};

    if (this.isMultiselect) {
      if (isEmpty) {
        paddingCompensations = {
          paddingTop: `${paddingCompensationValuePxEmpty}px`,
          paddingBottom: `${paddingCompensationValuePxEmpty}px`,
        };
      }
      else {
        paddingCompensations = {
          paddingTop: `${paddingCompensationValuePxNonempty}px`,
          paddingBottom: `${paddingCompensationValuePxNonempty}px`,
        };
      }
    }

    return {
      overflowY: 'auto',
      overflowX: 'hidden',
      maxHeight: `${oneLineHeightPx * maxLines}px`,
      ...paddingCompensations,
    };
  }

  private setUpOptionsDefinition(
    options: IczOption[] | Observable<IczOption[]>,
    searchIndexer: (option: IczOption) => string
  ) {
    setTimeout(() => {
      if (!options) {
        this.optionsDefinition$.next(this.optionsDefinitionFactory(of([]), searchIndexer));
      }
      else if (Array.isArray(options)) {
        this.optionsDefinition$.next(this.optionsDefinitionFactory(of(options), searchIndexer));
      }
      else if (isObservable(options)) {
        this.optionsDefinition$.next(this.optionsDefinitionFactory(options, searchIndexer));
      }
      // else nothing
    }, 0);
  }

  private tabPressed() {
    this.closeOptions(false);
    this.selectedValueField?.focusField();
  }

  private escapePressed($event: KeyboardEvent) {
    this.closeOptions(false);
    this.selectedValueField?.focusField();

    $event.preventDefault();
    $event.stopPropagation();
  }

}
