<form [waiting]="loadingService.isLoading(this)" [formGroup]="form">
  <icz-org-structure-selection-loader
    [parentForm]="form"
    (groupSelected)="applySavedGroup($event)"
  ></icz-org-structure-selection-loader>
  <icz-section label="Komu předat originál">
    <icz-org-structure-selector
      [form]="form"
      [disableCurrentFunctionalPosition]="true"
      [disableOptionsWithEmptyOrgEntity]="true"
      [disabledOptionValues]="disabledOriginalOptionValues$ | async"
      disabledOptionValuesReason="Organizační jednotka nebo její reprezentující funkční místo již byly vybrány pro předání duplikátu"
      controlName="originalOrgStructureElementId"
      label="Funkční místo nebo organizační jednotka - předání originálu"
      reservedValueLabel="Ponechat si originál"
    ></icz-org-structure-selector>
  </icz-section>
  <icz-section label="Komu předat duplikát">
    <icz-org-structure-selector
      [form]="form"
      [isMultiselect]="true"
      [disableCurrentFunctionalPosition]="true"
      [disableOptionsWithEmptyOrgEntity]="true"
      [disabledOptionValues]="disabledCopyOptionValues$ | async"
      disabledOptionValuesReason="Organizační jednotka nebo její reprezentující funkční místo již byly vybrány pro předání originálu"
      controlName="copyOrgStructureElementIds"
      label="Funkční místa nebo organizační jednotky - předání duplikátu"
    ></icz-org-structure-selector>
    <icz-org-structure-selection-saver
      [parentForm]="form"
      [group]="groupForSaving"
    ></icz-org-structure-selection-saver>
  </icz-section>
  @if (isTakeoverAndPassMode) {
    <icz-section label="Důvod předání">
      <icz-form-field formControlName="settlementNote"
                      label="Důvod předání"
                      [autoSizeMax]="3">
      </icz-form-field>
    </icz-section>
  }
  <icz-section label="Instrukce a termín">
    <div class="row">
      <icz-form-field class="grow-5"
                      formControlName="targetTaskInstruction"
                      label="Instrukce"
                      [autoSizeMax]="3">
      </icz-form-field>
      <icz-date-picker class="grow-3"
                       formControlName="deadline"
                       label="Termín úkolu"
                       [selectableDates]="deadlineValidator"
      ></icz-date-picker>
    </div>
  </icz-section>
  <!-- todo(mh) define behavior for printing of handoverProtocol -->
<!--  <icz-section *ngIf="!isTaskEntity(initEntity) && $any(initEntity)?.documentForm === DocumentForm.ANALOG" label="Tisk">
    <icz-checkbox testingFeature
                  formControlName="printHandoverProtocol"
                  label="Tisknout předávací protokol">
    </icz-checkbox>
  </icz-section>-->
</form>

<icz-form-buttons>
  <icz-button iczFormSubmit [disabled]="loadingService.isLoading(this)" leftButtons (onAction)="submit()" primary label="Předat"></icz-button>
  <icz-button rightButtons [disabled]="loadingService.isLoading(this)" (onAction)="closeModal()" label="Storno"></icz-button>
</icz-form-buttons>
