/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiRegistryOfficeTransferService } from './services/api-registry-office-transfer.service';
import { ApiStorageUnitService } from './services/api-storage-unit.service';
import { ApiStorageUnitFillerService } from './services/api-storage-unit-filler.service';
import { ApiSharedFolderService } from './services/api-shared-folder.service';
import { ApiSettlementService } from './services/api-settlement.service';
import { ApiReceivedDocumentService } from './services/api-received-document.service';
import { ApiOwnDocumentService } from './services/api-own-document.service';
import { ApiFileService } from './services/api-file.service';
import { ApiDocumentService } from './services/api-document.service';
import { ApiAgendaTransferService } from './services/api-agenda-transfer.service';
import { ApiAgendaTransferEnvelopeService } from './services/api-agenda-transfer-envelope.service';
import { ApiDocumentFileSubjectsService } from './services/api-document-file-subjects.service';
import { ApiSignatureService } from './services/api-signature.service';
import { ApiRenditionService } from './services/api-rendition.service';
import { ApiDocumentDuplicateService } from './services/api-document-duplicate.service';
import { ApiCrossReferenceService } from './services/api-cross-reference.service';
import { ApiAgendaTransferExportService } from './services/api-agenda-transfer-export.service';
import { ApiStorageUnitElasticService } from './services/api-storage-unit-elastic.service';
import { ApiAgendaTransferExportManifestService } from './services/api-agenda-transfer-export-manifest.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiRegistryOfficeTransferService,
    ApiStorageUnitService,
    ApiStorageUnitFillerService,
    ApiSharedFolderService,
    ApiSettlementService,
    ApiReceivedDocumentService,
    ApiOwnDocumentService,
    ApiFileService,
    ApiDocumentService,
    ApiAgendaTransferService,
    ApiAgendaTransferEnvelopeService,
    ApiDocumentFileSubjectsService,
    ApiSignatureService,
    ApiRenditionService,
    ApiDocumentDuplicateService,
    ApiCrossReferenceService,
    ApiAgendaTransferExportService,
    ApiStorageUnitElasticService,
    ApiAgendaTransferExportManifestService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
