/* eslint-disable no-restricted-imports */
import {AbstractControl, UntypedFormControl, ValidationErrors, Validators} from '@angular/forms';
import {isNil, isNumber} from 'lodash';
import {IczFormControl} from '../../icz-form-controls';
import {IczValidatorFn, ValidationErrorMessage} from './validator-decorators';
import {parseISO} from 'date-fns';
import {maskDigitValidators, neverValidator} from '../../input-mask/digit_validators';
import {formatNumberByNumericMask} from '../../input-mask/input-mask.directive';

/**
 * @internal
 */
// eslint-disable-next-line -- definition coherent with Angular docs
export type ValidatorResult = ValidationErrors | null;

/**
 * A collection of validators to be used in our forms.
 * Conventions:
 * - Every validator here should either reuse a built-in Angular validator by returning it
 *     or define and return a custom ValidatorFn for Angular Reactive forms
 *     (see https://dzone.com/articles/how-to-create-custom-validators-in-angular)
 * - Every validator should define a template of its validation error message
 *     using the @ValidationErrorMessage decorator. Further reference
 *     for the decorator to be found in validator-decorators.ts.
 */
export class IczValidators {
  @ValidationErrorMessage('Nesmí být prázdné.')
  static required(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (typeof control.value === 'string') {
        const isNonEmptyString = control.value.trim().length > 0;

        if (isNonEmptyString) {
          return null;
        }
        else {
          return {
            required: false,
          };
        }
      }
      else {
        return Validators.required(control);
      }
    };
  }

  @ValidationErrorMessage('Musí obsahovat alespoň {{requiredLength}} znaků.')
  static minLength(length: number): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (typeof control.value === 'string') {
        return Validators.minLength(length)(control);
      }
      else {
        return null;
      }
    };
  }

  @ValidationErrorMessage('Nesmí přesáhnout {{requiredLength}} znaků.')
  static maxLength(length: number): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (typeof control.value === 'string') {
        return Validators.maxLength(length)(control);
      }
      else {
        return null;
      }
    };
  }

  @ValidationErrorMessage('Musí být e-mail ve tvaru "xxx@yyy.zz".')
  static email(): IczValidatorFn {
    return Validators.email;
  }

  @ValidationErrorMessage('Musí být telefonní číslo ve tvaru "+123 456 789 012".')
  static phoneNumber(): IczValidatorFn {
    return Validators.pattern(/\+\d{1,3} \d{3} \d{3} \d{3}/);
  }

  @ValidationErrorMessage('Musí být URL ve tvaru "https://base.application.url/" nebo "http://base.application.url/".')
  static simpleUrl(): IczValidatorFn {
    return Validators.pattern(/^(https?:\/\/)[^\/]+\/$/);
  }

  @ValidationErrorMessage('Musí mít minimální číselnou hodnotu {{min}}.')
  static min(value: number): IczValidatorFn {
    return Validators.min(value);
  }

  @ValidationErrorMessage('Musí mít maximální číselnou hodnotu {{max}}.')
  static max(value: number): IczValidatorFn {
    return Validators.max(value);
  }

  @ValidationErrorMessage('Musí být nejméně {{minFormattedDateTime}}.')
  static minDateTime(minIsoStringDateTimeValue: string, dateMode = true): IczValidatorFn {
    return control => {
      const controlValue = control.value as Nullable<string>;

      if (isNil(controlValue)) {
        return null;
      }
      else {
        if (controlValue >= minIsoStringDateTimeValue) {
          return null;
        }
        else {
          const minDateTime = parseISO(minIsoStringDateTimeValue);
          return {
            minDateTime: {
              minFormattedDateTime: dateMode ? minDateTime.toLocaleDateString() : minDateTime.toLocaleString(),
            }
          };
        }
      }
    };
  }

  @ValidationErrorMessage('Musí být nejvýše {{maxFormattedDateTime}}.')
  static maxDateTime(maxIsoStringDateTimeValue: string, dateMode = true): IczValidatorFn {
    return control => {
      const controlValue = control.value as Nullable<string>;

      if (isNil(controlValue)) {
        return null;
      }
      else {
        if (controlValue <= maxIsoStringDateTimeValue) {
          return null;
        }
        else {
          const maxDateTime = parseISO(maxIsoStringDateTimeValue);
          return {
            maxDateTime: {
              maxFormattedDateTime: dateMode ? maxDateTime.toLocaleDateString() : maxDateTime.toLocaleString(),
            }
          };
        }
      }
    };
  }

  @ValidationErrorMessage('Musí být násobkem čísla {{value}}.')
  static isMultipleOfValue(roundingFactor: number): IczValidatorFn {
    return control => {
      const controlValue = control.value as Nullable<number>;

      if (isNil(controlValue)) {
        return null;
      }
      else {
        const quotient = controlValue / roundingFactor;
        const roundedQuotient = Math.round(quotient);
        const expectedA = roundedQuotient * roundingFactor;
        const epsilon = 1e-10;

        if (Math.abs(controlValue - expectedA) < epsilon) {
          return null;
        }
        else {
          return {
            isMultipleOfValue: {
              value: roundingFactor,
            }
          };
        }
      }
    };
  }

  /**
   * Validator for requiring rounding of numbers.
   *
   * Parameter roundingOrder:
   * - if positive, determines maximal number of decimal places (1 - 0.1, 2 - 0.01, 0.1, ...),
   * - if negative, determines, minimal decimal order for rounding (-1 = 10/20/30..., -2 = 100/200/...).
   */
  @ValidationErrorMessage('Zadaná hodnota není dostatečně zaokrouhlena.')
  static isRoundedToNumericOrder(roundingOrder: number): IczValidatorFn {
    return control => {
      const multipleValidatorResult = IczValidators.isMultipleOfValue(10 ** (-roundingOrder))(control);

      if (multipleValidatorResult) {
        return {
          isRoundedToNumericOrder: false,
        };
      }
      else {
        return null;
      }
    };
  }

  @ValidationErrorMessage('Zadaná hodnota neodpovídá číselné masce {{mask}}.')
  static isValidByNumericMask(mask: string): IczValidatorFn {
    return control => {
      const controlValue = control.value;

      if (isNil(controlValue)) {
        return null;
      }
      else {
        const sanitizedValue = (typeof controlValue) === 'string' ? parseFloat(controlValue) : controlValue as number;
        const controlValueAsString = formatNumberByNumericMask(mask, sanitizedValue);

        if (controlValueAsString) {
          for (let i = 0; i < controlValueAsString.length; ++i) {
            const char = controlValueAsString[i];
            const maskDigit = mask.charAt(i);
            const digitValidator = maskDigitValidators[maskDigit] || neverValidator;

            if (!digitValidator(char)) {
              return {
                isValidByMask: {mask},
              };
            }
          }

          return null;
        }
        else {
          return null;
        }
      }
    };
  }

  @ValidationErrorMessage('Zadejte platné datum ve formátu "D.M.RRRR", "D/M/RRRR", nebo "DDMMRRRR".')
  static localDate() {
    return Validators.pattern(/(^\d{1,2}\. ?\d{1,2}\. ?\d{4}$)|(^\d{1,2}\/ ?\d{1,2}\/ ?\d{4}$)|(^\d{2}\d{2}\d{4}$)/);
  }

  @ValidationErrorMessage('Zadejte platný čas ve formátu "HH:MM:SS"')
  static isTime() {
    return Validators.pattern(/^(?:2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/);
  }

  @ValidationErrorMessage('Zadejte platný čas ve formátu "HH:MM"')
  static isSimpleTime() {
    return Validators.pattern(/^(2[0-3]|[01]?[0-9]):([0-5]?[0-9])$/);
  }

  @ValidationErrorMessage('Zadejte platný datum s časem ve formátu "DD.MM.RRRR HH:MM:SS"')
  static isLocalDateTime() {
    return Validators.pattern(/^([1-9]|([012][0-9])|(3[01]))\.(0{0,1}[1-9]|1[012])\.\d\d\d\d (2[0-3]|[0-1]?\d):[0-5]\d:[0-5]\d$/);
  }

  @ValidationErrorMessage('Musí být platné desetinné číslo, Např.: "2,5", "2,25", "2,0434223"')
  static isDecimal() {
    return (control: AbstractControl): ValidatorResult => {
      if (!isNil(control.value) && !isNumber(control.value)) {
        return {isDecimal: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Musí být celé číslo, Např.: "2", "15", "0"')
  static isInteger(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      if (!isNil(control.value) && !Number.isInteger(control.value)) {
        return {isInteger: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Musí být celé číslo, Např.: "2", "15", "0"')
  static isStringifiedInteger(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      // eslint-disable-next-line eqeqeq -- coercive behavior is on point here - `string|number == string` where string|number might contain nonconversible values
      if (control.value && control.value != `${parseInt(control.value, 10)}`) {
        return {isInteger: false};
      }
      return null;
    };
  }

  @ValidationErrorMessage('Hesla se musí shodovat')
  static matchPasswords(): IczValidatorFn {
    return (control: AbstractControl) => {
      const password = control.get('password');
      const confirmPassword = control.get('confirmPassword');

      return (password?.touched || confirmPassword?.touched) && password!.value !== confirmPassword!.value ? {notMatchingPassword: true} : null;
    };
  }

  // used under very specific circumstances - when we want to
  // use an autocomplete option with value equal to null.
  // note that when using this validator, the initial value of
  // given form control must be equal to undefined or some other
  // falsy value that is not null.
  // ---
  // why is it like this? because historically the code relied on
  // "null" meaning "unfilled" but in fact it is sometimes a
  // special value for backend services.
  @ValidationErrorMessage('Nesmí být prázdné.')
  static requiredButAllowNull(): IczValidatorFn {
    return (control: AbstractControl) => {
      return (control.value === null || Validators.required(control) === null) ? null : {requiredButAllowNull: true};
    };
  }

  @ValidationErrorMessage('Musí být validní pole formátu JSON.')
  static isJsonArray(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      const value = control.value;

      if (!value) {
        return null;
      }
      else {
        const jsonValueValidationResult = IczValidators.isJsonValue()(control);

        if (jsonValueValidationResult === null) {
          const parsedValue = JSON.parse(value);

          if (!parsedValue || Array.isArray(parsedValue)) {
            return null;
          }
          else {
            return {isJsonArray: true};
          }
        }
        else {
          return {isJsonArray: true};
        }
      }
    };
  }

  @ValidationErrorMessage('Musí být validní objekt formátu JSON.')
  static isJsonObject(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      const value = control.value;

      if (!value) {
        return null;
      }
      else {
        const jsonValueValidationResult = IczValidators.isJsonValue()(control);

        if (jsonValueValidationResult === null) {
          const parsedValue = JSON.parse(value);

          // typeof(parsedValue) === 'object' => null literal included on purpose
          if (typeof(parsedValue) === 'object' && !Array.isArray(parsedValue)) {
            return null;
          }
          else {
            return {isJsonArray: true};
          }
        }
        else {
          return {isJsonObject: true};
        }
      }
    };
  }

  @ValidationErrorMessage('Musí být validní hodnota formátu JSON.')
  static isJsonValue(): IczValidatorFn {
    return (control: AbstractControl): ValidatorResult => {
      const value = control.value;

      if (!value) {
        return null;
      }
      else {
        try {
          JSON.parse(value);
          return null;
        }
        catch (ex) {
          return {isJsonValue: true};
        }
      }
    };
  }

  static _dummyValidator = () => null;
}

/**
 * A predicate checking if the control has IczValidators.required() or IczValidators.requiredButAllowNull on it.
 */
export function isRequired(control: Nullable<AbstractControl>) {
  const validationErrors = control?.validator?.(new UntypedFormControl('')) ?? {};

  return 'required' in validationErrors || 'requiredButAllowNull' in validationErrors;
}

/**
 * A predicate checking if the control has an "important" flag.
 */
export function isImportant(control: Nullable<IczFormControl>) {
  return control?.important!;
}
