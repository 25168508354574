import {DestroyRef, inject, Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {FilterWithDatasource, PageSelectorFilterItem} from '../../filter.types';
import {FilterValueFormatter} from '../../table.providers';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import { FilterOperator } from '../../table.utils';
import { getDefaultSearchParams } from '../../table.datasource';
import {map} from 'rxjs/operators';

/**
 * @internal
 */
@Injectable()
export class PageSelectorFilterFormatterService implements FilterValueFormatter {
  private destroyRef = inject(DestroyRef);


  format(filterItem: PageSelectorFilterItem): Observable<string> {
    if (!filterItem?.filterDataSource) {
      console.warn(`FilterItem with filterType PAGE-SELECTOR requires to have FilterItem#filterDataSource ` +
        `specified in order to render human-friendly labels. Displaying list of raw values as fallback.`);

      return of((filterItem.value as string[])!.join(', '));
    }

    if (!filterItem?.modelValueKey) {
      console.warn(`FilterItem with filterType PAGE-SELECTOR requires to have FilterItem#modelValueKey ` +
        `specified in order to render human-friendly labels. Displaying list of raw values as fallback.`);

      return of((filterItem.value as string[])!.join(', '));
    }

    if (!filterItem?.modelLabelKey) {
      console.warn(`FilterItem with filterType PAGE-SELECTOR requires to have FilterItem#modelValueKey ` +
        `specified in order to render human-friendly labels. Displaying list of raw values as fallback.`);

      return of((filterItem.value as string[])!.join(', '));
    }

    const filterDatasource = (filterItem as FilterWithDatasource).filterDataSource!;
    filterDatasource.loadPage({
      ...getDefaultSearchParams(),
      filter: [{
        fieldName: filterItem.modelValueKey!,
        value: String(filterItem.value),
        operator: FilterOperator.inSet,
      }],
    });

    return filterDatasource.items$.pipe(
      takeUntilDestroyed(this.destroyRef),
      map(page => {
        return page.map(item => item[filterItem.modelLabelKey!]).join(', ');
      })
    );
  }

}
