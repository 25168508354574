import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';

export interface TemplateCustomFieldDefinition {
  key: string;
  label: string;
}

interface CustomFieldsFormDiff {
  additions: string[]; // additions in definitions with respect to form
  removals: string[]; // removals in definitions with respect to form
}

function getCustomFieldsAndFormDifference(
  definitions: TemplateCustomFieldDefinition[],
  customTextForm: IczFormGroup
): CustomFieldsFormDiff {
  const definitionKeys = definitions.map(d => d.key).sort();
  const formKeys = Object.keys(customTextForm.controls).sort();

  const additions = definitionKeys.filter(definitionKey => !formKeys.includes(definitionKey));
  const removals = formKeys.filter(definitionKey => !definitionKeys.includes(definitionKey));

  return {
    additions,
    removals,
  };
}

export function isCustomFieldsDefinitionConsistentWithForm(
  definitions: TemplateCustomFieldDefinition[],
  customTextForm: IczFormGroup
) {
  const diff = getCustomFieldsAndFormDifference(definitions, customTextForm);
  return diff.additions.length === 0 && diff.removals.length === 0;
}

export function initializeCustomFieldsForm(
  definitions: TemplateCustomFieldDefinition[],
  customTextForm: IczFormGroup
) {
  const diff = getCustomFieldsAndFormDifference(definitions, customTextForm);

  for (const keyToAdd of diff.additions) {
    customTextForm.addControl(keyToAdd, new IczFormControl(null), {emitEvent: false});
  }

  for (const keyToRemove of diff.removals) {
    customTextForm.removeControl(keyToRemove, {emitEvent: false});
  }

  // setting this flag explicitly to let the user immediatelly know that his input is invalid
  customTextForm.markAllAsTouched();
  customTextForm.recursivelyUpdateValueAndValidity();
}

export function initializeCustomFieldsFormByValuesMap(
  valuesMap: Record<string, string>,
  customTextForm: IczFormGroup
) {
  initializeCustomFieldsForm(
    Object.keys(valuesMap).map(k => ({key: k, label: ''})),
    customTextForm,
  );
}

export function clearCustomTextForm(customTextForm: IczFormGroup) {
  const customTextControlNames = Object.keys(customTextForm.controls);

  for (const controlName of customTextControlNames) {
    customTextForm.removeControl(controlName);
  }

  customTextForm.reset();
  customTextForm.recursivelyUpdateValueAndValidity();
}

export function filterEmptyCustomTextValues(customTextValues: Record<string, Nullable<string>>) {
  const entries = Object.entries(customTextValues);
  let out: Nullable<Record<string, string>> = null;

  for (const [k, v] of entries) {
    if (!out) {
      out = {};
    }

    if (v) {
      out[k] = v;
    }
  }

  return out;
}

@Component({
  selector: 'icz-envelope-or-label-custom-fields-form',
  templateUrl: './envelope-or-label-custom-fields-form.component.html',
  styleUrls: ['./envelope-or-label-custom-fields-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnvelopeOrLabelCustomFieldsFormComponent implements IczOnChanges {

  @Input({required: true})
  form: Nullable<IczFormGroup<Record<string, IczFormControl<Nullable<string>>>>>;
  @Input()
  customFields: TemplateCustomFieldDefinition[] = [];
  @Input()
  wrapInSection = true;
  @Input()
  disabled = false;

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if ((changes.customFields || changes.form) && this.customFields && this.form) {
      if (!isCustomFieldsDefinitionConsistentWithForm(this.customFields, this.form)) {
        initializeCustomFieldsForm(this.customFields, this.form);
      }
    }
  }
}
