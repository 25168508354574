/* eslint-disable @typescript-eslint/quotes */

/**
 * Icons aggregated from libs/icz-angular-form-elements/assets/icons/.
 *
 * Used as a method of resource inlining to decrease HTTP GET overhead
 * for fetching lots of small icons at application startup.
 */
export const ICONS = {
  "after": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"20\" height=\"20\" fill=\"none\" viewBox=\"0 0 20 20\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M13.253 9.889l-5.657 5.657c-.39.39-1.024.39-1.414 0-.39-.39-.39-1.024 0-1.414l4.243-4.243-4.243-4.243c-.39-.39-.39-1.023 0-1.414.39-.39 1.024-.39 1.414 0l5.657 5.657z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "calendar": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"18\" fill=\"none\" viewBox=\"0 0 18 18\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M5 1a1 1 0 0 1 2 0v1h4V1a1 1 0 1 1 2 0v1h3a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h3V1zm6 3v1a1 1 0 1 0 2 0V4h3v3H2V4h3v1a1 1 0 0 0 2 0V4h4zM2 9v7h14V9H2z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "close_bold": "<svg width=\"12\" height=\"12\" viewBox=\"0 0 12 12\" fill=\"none\" xmlns=\"http://www.w3.org/2000/svg\">\n<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.5 6L0.707106 9.79289C0.316582 10.1834 0.316582 10.8166 0.707107 11.2071L0.792893 11.2929C1.18342 11.6834 1.81658 11.6834 2.20711 11.2929L6 7.5L9.79289 11.2929C10.1834 11.6834 10.8166 11.6834 11.2071 11.2929L11.2929 11.2071C11.6834 10.8166 11.6834 10.1834 11.2929 9.79289L7.5 6L11.2929 2.20711C11.6834 1.81658 11.6834 1.18342 11.2929 0.792893L11.2071 0.707107C10.8166 0.316583 10.1834 0.316583 9.79289 0.707107L6 4.5L2.20711 0.707106C1.81658 0.316582 1.18342 0.316582 0.792893 0.707107L0.707107 0.792893C0.316583 1.18342 0.316583 1.81658 0.707107 2.20711L4.5 6Z\" fill=\"#6C6F75\"/>\n</svg>\n",
  "delete": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"14\" height=\"16\" fill=\"none\" viewBox=\"0 0 14 16\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M5 0a1 1 0 0 0-1 1H1.618a1 1 0 0 0-.894.553L0 3h14l-.724-1.447A1 1 0 0 0 12.382 1H10a1 1 0 0 0-1-1H5zm8 4H1l1.86 11.164a1 1 0 0 0 .987.836h6.306a1 1 0 0 0 .986-.836L13 4zM4.694 14L3.361 6h7.278l-1.333 8H4.694z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "eye": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"12\" fill=\"none\" viewBox=\"0 0 18 12\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M9 12c-3.314 0-6.314-2-9-6 2.686-4 5.686-6 9-6s6.314 2 9 6c-2.686 4-5.686 6-9 6zM9 2C6.728 2 4.56 3.275 2.46 6c2.1 2.725 4.268 4 6.54 4 2.272 0 4.44-1.275 6.54-4-2.1-2.725-4.268-4-6.54-4zm3 4a3 3 0 1 1-6 0 3 3 0 0 1 6 0z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "eye_hidden": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"18\" height=\"14\" fill=\"none\" viewBox=\"0 0 18 14\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M15.364 1.707A1 1 0 0 0 13.95.293l-1.256 1.256A8.132 8.132 0 0 0 9 .657c-3.314 0-6.314 2-9 6 1.137 1.693 2.33 3.028 3.58 4.005l-.944.945A1 1 0 1 0 4.05 13.02l1.256-1.256A8.132 8.132 0 0 0 9 12.657c3.314 0 6.314-2 9-6-1.137-1.694-2.33-3.029-3.58-4.005l.944-.945zM9 2.657c-2.272 0-4.44 1.275-6.54 4 .837 1.087 1.685 1.943 2.547 2.58L6.292 7.95a3 3 0 0 1 4.001-4.001l.885-.884A6.047 6.047 0 0 0 9 2.657zm3.945 1.385l.048.036-1.285 1.285a3 3 0 0 1-4.001 4.001l-.885.885A6.046 6.046 0 0 0 9 10.657c2.272 0 4.44-1.275 6.54-4-.853-1.107-1.717-1.975-2.595-2.615z\" clip-rule=\"evenodd\"/>\n</svg>\n",
  "time": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"17\" height=\"17\" fill=\"none\" viewBox=\"0 0 17 17\">\n    <path fill=\"#6C6F75\" fill-rule=\"evenodd\" d=\"M0 8.1c0 4.473 3.626 8.1 8.1 8.1 4.473 0 8.1-3.627 8.1-8.1 0-4.474-3.627-8.1-8.1-8.1C3.626 0 0 3.626 0 8.1zm8.1 6.3c3.48 0 6.3-2.82 6.3-6.3s-2.82-6.3-6.3-6.3-6.3 2.82-6.3 6.3 2.82 6.3 6.3 6.3zm0-10.8c-.497 0-.9.403-.9.9V9h4.5c.497 0 .9-.403.9-.9s-.403-.9-.9-.9H9V4.5c0-.497-.403-.9-.9-.9z\" clip-rule=\"evenodd\"/>\n</svg>\n"
};
