/* tslint:disable */
/* eslint-disable */

/**
 * Document state
 */
export enum DocumentState {
  IN_PROGRESS = 'IN_PROGRESS',
  UNOPENED = 'UNOPENED',
  SETTLED = 'SETTLED',
  DEACTIVATED = 'DEACTIVATED',
  SUSPENDED = 'SUSPENDED',
  DELETED = 'DELETED',
  EXPORTED = 'EXPORTED'
}
