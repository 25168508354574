import {TranslateParser, TranslateService} from '@ngx-translate/core';
import {combineLatest} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {
  ConsignmentType,
  ContainerExtractionState,
  DocumentForm,
  DocumentState,
  EntityType,
  EpdzMessageType,
  FileState,
  ObjectClass,
  RegistryOfficeTransferState,
  RelatedObjectType,
  RetentionTriggerTypeCode,
  StorageUnitAllowableObjectClass
} from '|api/commons';
import {DigitalComponentCompleteDto, DigitalComponentVersionCompleteDto, PaperComponentDto} from '|api/component';
import {
  DocumentAllowableObjectClass,
  DocumentDto,
  FileAllowableObjectClass,
  FileDto,
  OwnDocumentDto,
  ReceivedDocumentDto,
  SimplifiedDisposalScheduleDto,
  StorageUnitDto
} from '|api/document';
import {DocumentsRoute} from '../../enums/documents-routes.enum';
import {getTodayMidnight, IczFormGroup} from '@icz/angular-form-elements';
import {EsslAnalogComponentTypeAsFields} from './analog-component-create-form/analog-component-create-form.component';
import {IczTagColor} from '@icz/angular-essentials';
import {EsslEntity, EsslObject} from './model/entity.model';
import {EsslComponentDto} from '../../services/essl-component-search.service';
import {ExtendedCirculationTaskDto} from './model/elastic-extended-entities.interface';
import {DocumentOrFileWithGenericState} from './model/document-file-sidebar.model';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DestroyRef} from '@angular/core';
import {DeliveryTypeDto, DisposalScheduleDto} from '|api/codebook';
import {WITHOUT_REF_NUMBER} from './shared-business-components.model';
import {CodebookService} from '../../core/services/codebook.service';

type EntityDetailUriSegment = DocumentsRoute.DOCUMENT | DocumentsRoute.FILE | DocumentsRoute.STORAGE_UNIT;

export type DocumentOrFileDto = DocumentDto | FileDto;

export const DOCUMENT_ENTITY_TYPES: Nullable<EntityType>[] = [
  EntityType.DOCUMENT,
  EntityType.OWN_DOCUMENT,
  EntityType.RECEIVED_DOCUMENT,
];

export const COMPONENT_ENTITY_TYPES: Nullable<EntityType>[] = [
  EntityType.COMPONENT,
  EntityType.DIGITAL_COMPONENT,
  EntityType.ANALOG_COMPONENT,
  EntityType.PAPER_COMPONENT,
  EntityType.PHYSICAL_ITEM_COMPONENT,
  EntityType.MEDIUM_COMPONENT
];

export const OWN_DOCUMENT_OBJECT_CLASSES: DocumentAllowableObjectClass[] = [
  DocumentAllowableObjectClass.OWN_DOCUMENT,
  DocumentAllowableObjectClass.OWN_DOCUMENT_DIGITAL
];

export const RECEIVED_DOCUMENT_OBJECT_CLASSES: DocumentAllowableObjectClass[] = [
  DocumentAllowableObjectClass.RECEIVED_DOCUMENT,
  DocumentAllowableObjectClass.RECEIVED_DOCUMENT_DIGITAL,
];

export const FILE_OBJECT_CLASSES: Nullable<FileAllowableObjectClass>[] = [
  FileAllowableObjectClass.FILE,
  FileAllowableObjectClass.FILE_DIGITAL
];

export const STORAGE_UNIT_OBJECT_CLASSES: Nullable<StorageUnitAllowableObjectClass>[] = [
  StorageUnitAllowableObjectClass.STORAGE_UNIT,
  StorageUnitAllowableObjectClass.STORAGE_UNIT_DIGITAL
];

const DOCUMENT_TAG_COLORS: Record<DocumentState, IczTagColor> = {
  [DocumentState.IN_PROGRESS]: 'blue',
  [DocumentState.UNOPENED]: 'blue',
  [DocumentState.SETTLED]: 'green',
  [DocumentState.DEACTIVATED]: 'grey',
  [DocumentState.SUSPENDED]: 'grey',
  [DocumentState.DELETED]: 'grey',
  [DocumentState.EXPORTED]: 'grey',
};

const FILE_TAG_COLORS: Record<FileState, IczTagColor> = {
  [FileState.OPEN]: 'blue',
  [FileState.SETTLED]: 'green',
  [FileState.CLOSED]: 'green',
  [FileState.DEACTIVATED]: 'grey',
  [FileState.DELETED]: 'grey',
  [FileState.SUSPENDED]: 'grey',
  [FileState.EXPORTED]: 'grey',
};

export const ALLOWED_DIGITAL_COMPONENT_TEMPLATE_TYPES = ['.xsl', '.xml', '.odt', '.docx', '.xslx'];

export enum RetentionTriggerWidgetMode {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
  ENABLED_START_YEAR = 'ENABLED_START_YEAR',
  VALIDATED = 'VALIDATED'
}

export function isEsslComponent(entity: Nullable<EsslEntity>): entity is EsslComponentDto {
  return COMPONENT_ENTITY_TYPES.includes(entity?.entityType);
}

export function isDigitalComponent(entity: Nullable<EsslEntity>): entity is DigitalComponentCompleteDto {
  return entity?.entityType === EntityType.DIGITAL_COMPONENT;
}

export function getLatestDigitalComponentVersion(digitalComponent: Nullable<DigitalComponentCompleteDto>): Nullable<DigitalComponentVersionCompleteDto> {
  return digitalComponent?.digitalComponentVersions?.[0];
}

export function getExtractionStateTagColor(extractionState: ContainerExtractionState) {
  if (extractionState === ContainerExtractionState.EXTRACTED || extractionState === ContainerExtractionState.EXTRACTED_WITHOUT_CONTENT) {
    return 'blue';
  }
  else {
    return 'orange';
  }
}

export function getLastDigitalComponentVersionId(digitalComponent: Nullable<DigitalComponentCompleteDto>) {
  return getLatestDigitalComponentVersion(digitalComponent)!.id;
}

/**
 * Returns a value in Bytes.
 */
export function sumDigitalComponentsSize(selectedComponents: DigitalComponentCompleteDto[]) {
  if (!selectedComponents?.length) {
    return 0;
  }
  else {
    return selectedComponents
      .map(c => getLatestDigitalComponentVersion(c)?.fileSize ?? 0)
      .reduce((prev, curr) => (prev ?? 0) + curr);
  }
}

/**
 * Returns a value in Bytes.
 */
export function sumPaperComponentsRenditionSize(selectedComponents: PaperComponentDto[]) {
  if (!selectedComponents?.length) {
    return 0;
  }
  else {
    return selectedComponents
      .map(c => c?.digitalComponentLastFileSize ?? 0)
      .reduce((prev, curr) => (prev ?? 0) + curr);
  }
}

export function getDigitalComponentFileName(digitalComponent: DigitalComponentCompleteDto) {
  return getLatestDigitalComponentVersion(digitalComponent)!.fileName;
}

export function isOwnDocumentObject(object: Nullable<EsslObject>) {
  return OWN_DOCUMENT_OBJECT_CLASSES.includes(object?.objectClass as any);
}

export function isReceivedDocumentObject(object: Nullable<EsslObject>) {
  return RECEIVED_DOCUMENT_OBJECT_CLASSES.includes(object?.objectClass as any);
}

export function receivedConsignmentTypeDisallowsAdditionalAnalogComponents(document: DocumentDto, deliveryType: DeliveryTypeDto) {
  if (isOwnDocumentObject(document)) {
    return false;
  }
  else if (!deliveryType) {
    return true;
  }

  const disallowedDeliveryTypeCodes = [
    DELIVERY_TYPE_EMAIL_CODE,
    DELIVERY_TYPE_DATABOX_CODE,
    DELIVERY_TYPE_WEB_CODE,
    DELIVERY_TYPE_INTERNAL_DIGITAL_CODE,
  ];
  return disallowedDeliveryTypeCodes.includes(deliveryType.code);
}

export function receivedConsignmentTypeDisallowsAdditionalDigitalComponents(document: DocumentDto, deliveryType: DeliveryTypeDto) {
  if (isOwnDocumentObject(document)) {
    return false;
  }
  else if (!deliveryType) {
    return true;
  }

  const disallowedDeliveryTypeCodes = [
    DELIVERY_TYPE_EMAIL_CODE,
    DELIVERY_TYPE_DATABOX_CODE,
    DELIVERY_TYPE_WEB_CODE,
    DELIVERY_TYPE_INTERNAL_DIGITAL_CODE,
    DELIVERY_TYPE_INTERNAL_ANALOG_CODE,
  ];
  return disallowedDeliveryTypeCodes.includes(deliveryType.code);
}

export function isUnopenedDocument(object: Nullable<EsslObject>) {
    return (object as ReceivedDocumentDto)?.documentState === DocumentState.UNOPENED;
}

export function getDetailUriByObject(object: Nullable<EsslObject>) {
  let entityDetailUriSegment: EntityDetailUriSegment;

  if (isDocumentObject(object)) {
    entityDetailUriSegment = DocumentsRoute.DOCUMENT;
  }
  else if (isFileObject(object)) {
    entityDetailUriSegment = DocumentsRoute.FILE;
  }
  else if (isStorageUnitObject(object)) {
    entityDetailUriSegment = DocumentsRoute.STORAGE_UNIT;
  }

  return getDetailUri(entityDetailUriSegment!, object!.id);
}

export function getDetailUri(entityType: EntityDetailUriSegment, entityId: Nullable<number>) {
  return entityId ? `documents/${entityType}/${entityId}` : '';
}

export function isFileObject(object: Nullable<EsslObject>): object is EsslEntity & FileDto {
  return FILE_OBJECT_CLASSES.includes(object?.objectClass as any);
}

export function isDocumentObject(object: Nullable<EsslObject>): object is EsslEntity & (OwnDocumentDto | ReceivedDocumentDto) {
  return isOwnDocumentObject(object) || isReceivedDocumentObject(object);
}

export function isStorageUnitObject(object: Nullable<EsslObject>): object is EsslEntity & (StorageUnitDto) {
  return STORAGE_UNIT_OBJECT_CLASSES.includes(object?.objectClass as any);
}

export function isDocumentEntity(entityType: Nullable<EntityType>) {
  return DOCUMENT_ENTITY_TYPES.includes(entityType);
}

export function getSuperclassEntityType(entityType: EntityType) {
  if (DOCUMENT_ENTITY_TYPES.includes(entityType)) {
    return EntityType.DOCUMENT;
  }
  else if (COMPONENT_ENTITY_TYPES.includes(entityType)) {
    return EntityType.FILE;
  }
  else {
    return entityType;
  }
}

export function entityTypeAsDocumentOrFile(entityType: EntityType): Nullable<EntityType> {
  if (isDocumentEntity(entityType)) {
    return EntityType.DOCUMENT;
  }
  else if (isFileEntity(entityType)) {
    return EntityType.FILE;
  }
  return null;
}

export function entityTypeToRelatedObjectType(entityType: EntityType): Nullable<RelatedObjectType> {
  if (entityType === EntityType.DOCUMENT || entityType === EntityType.OWN_DOCUMENT || entityType === EntityType.RECEIVED_DOCUMENT) return RelatedObjectType.DOCUMENT;
  else if (entityType === EntityType.FILE) return RelatedObjectType.FILE;
  else if (entityType === EntityType.CONSIGNMENT) return RelatedObjectType.CONSIGNMENT;
  else return null;
}

export function relatedObjectClassToRelatedObjectType(objectClass: ObjectClass): Nullable<RelatedObjectType> {
  if (objectClass === ObjectClass.RECEIVED_DOCUMENT || objectClass === ObjectClass.RECEIVED_DOCUMENT_DIGITAL ||
    objectClass === ObjectClass.OWN_DOCUMENT || objectClass === ObjectClass.OWN_DOCUMENT_DIGITAL) return RelatedObjectType.DOCUMENT;
  else if (objectClass === ObjectClass.FILE || objectClass === ObjectClass.FILE_DIGITAL) return RelatedObjectType.FILE;
  // missing mapping for ObjectClass.CONSIGNMENT on purpose because related object should only be DOCUMENT or FILE
  else return null;
}

export function isFileEntity(entityType: Nullable<EntityType>) {
  if (entityType) {
    return (entityType === EntityType.FILE);
  } else {
    return false;
  }
}

export function isStorageUnitEntity(entityType: Nullable<EntityType>) {
  if (entityType) {
    return (entityType === EntityType.STORAGE_UNIT);
  } else {
    return false;
  }
}

export function getObjectClassByDocumentForm(documentForm: DocumentForm, isReceivedDocument: boolean = false): Nullable<DocumentAllowableObjectClass> {
  switch (documentForm) {
    case DocumentForm.ANALOG:
      return isReceivedDocument ? DocumentAllowableObjectClass.RECEIVED_DOCUMENT : DocumentAllowableObjectClass.OWN_DOCUMENT;
    case DocumentForm.DIGITAL:
    case DocumentForm.HYBRID:
      return isReceivedDocument ? DocumentAllowableObjectClass.RECEIVED_DOCUMENT_DIGITAL : DocumentAllowableObjectClass.OWN_DOCUMENT_DIGITAL;
    default:
      return null;
  }
}

export function calcDocumentFormFromComponents(form: IczFormGroup, ctx: DestroyRef) {
  const documentFormControl = form.get('documentForm')!;

  const digital$ = form.get('digitalComponents')!.valueChanges.pipe(takeUntilDestroyed(ctx), startWith(null));
  const paper$ = form.get(EsslAnalogComponentTypeAsFields.PAPER)!.get([EsslAnalogComponentTypeAsFields.PAPER])!
    .valueChanges.pipe(takeUntilDestroyed(ctx), startWith(null));
  const medium$ = form.get(EsslAnalogComponentTypeAsFields.MEDIUM)!.get([EsslAnalogComponentTypeAsFields.MEDIUM])!
    .valueChanges.pipe(takeUntilDestroyed(ctx), startWith(null));
  const physical$ = form.get(EsslAnalogComponentTypeAsFields.PHYSICAL)!.get([EsslAnalogComponentTypeAsFields.PHYSICAL])!
    .valueChanges.pipe(takeUntilDestroyed(ctx), startWith(null));

  combineLatest([digital$, paper$, medium$, physical$])
    .pipe(
      takeUntilDestroyed(ctx),
    )
    .subscribe(([digital, paper, medium, physical]) => {
      const digitalCount: number = digital?.length ?? 0;
      const analogCount: number = (paper ? paper.length : 0) + (medium ? medium.length : 0) + (physical ? physical.length : 0);

      if (digitalCount && analogCount) {
        documentFormControl.setValue(DocumentForm.HYBRID);
      } else if (digitalCount) {
        documentFormControl.setValue(DocumentForm.DIGITAL);
      } else if (analogCount) {
        documentFormControl.setValue(DocumentForm.ANALOG);
      } else {
        documentFormControl.setValue(null);
      }
    });
}

export function getObjectIcon(objectClass: ObjectClass) {
  switch (objectClass) {
    case ObjectClass.RECEIVED_DOCUMENT_DIGITAL:
      return 'doruceny_dokument_digi';
    case ObjectClass.RECEIVED_DOCUMENT:
      return 'doruceny_dokument';
    case ObjectClass.OWN_DOCUMENT_DIGITAL:
      return 'vlastni_dokument_digi';
    case ObjectClass.OWN_DOCUMENT:
      return 'vlastni_dokument';
    case ObjectClass.FILE_DIGITAL:
      return 'spis_digi';
    case ObjectClass.FILE:
      return 'spis';
    case ObjectClass.CASE_FILE_DIGITAL:
      return 'typovy_spis_digi';
    case ObjectClass.CASE_FILE:
      return 'typovy_spis';
    case ObjectClass.CASE_VOLUME:
      return 'dil_typoveno_spisu';
    case ObjectClass.CONSIGNMENT:
      return 'zasilka';
    case ObjectClass.DISPOSAL_SCHEDULE:
      return 'skartacni_navrh';
    case ObjectClass.STORAGE_UNIT:
      return 'storage_unit';
    case ObjectClass.STORAGE_UNIT_DIGITAL:
      return 'storage_unit_digital';
    default:
      console.warn(`Small icon for objectClass=${objectClass} is missing.`);
      return null;
  }
}

export function getConsignmentIcon(consignmentType: ConsignmentType) {
  switch (consignmentType) {
    case ConsignmentType.OWN_PAPER_ADDRESS:
      return 'zasilka';
    case ConsignmentType.OWN_PAPER_MULTI_ADDRESS:
      return 'multi_consig';
    case ConsignmentType.OWN_DIGITAL_DATABOX:
      return 'datova_zprava';
    case ConsignmentType.OWN_DIGITAL_EMAIL:
      return 'email';
    case ConsignmentType.OWN_DIGITAL_INTERNAL:
      return 'internal';
    case ConsignmentType.OWN_PAPER_INTERNAL:
      return 'internal';
    case ConsignmentType.OWN_PAPER_ON_PLACE:
      return 'take_over';
    case ConsignmentType.RECEIVED_PAPER_INTERNAL:
      return 'internal';
    case ConsignmentType.RECEIVED_PAPER_ADDRESS:
      return 'zasilka';
    case ConsignmentType.RECEIVED_DIGITAL_INTERNAL:
      return 'internal';
    case ConsignmentType.RECEIVED_DIGITAL_DATABOX:
      return 'datova_zprava';
    case ConsignmentType.RECEIVED_DIGITAL_EMAIL:
      return 'email';
    case ConsignmentType.OFFICE_DESK:
      return 'uredni_deska';
    case ConsignmentType.TELEFAX:
      return 'printer';
    case ConsignmentType.PORTAL:
      return 'portal';
    default:
      return 'empty';
  }
}

export function getEpdzMessageIcon(epdzMessageType: EpdzMessageType) {
  switch (epdzMessageType) {
    case EpdzMessageType.EMAIL:
      return 'email';
    case EpdzMessageType.DATA_BOX:
    case EpdzMessageType.DATA_BOX_PERSONAL_DELIVERY:
      return 'datova_zprava';
    default:
      return 'empty';
  }
}

export function getObjectLabel(translateService: TranslateService, object: {subject: string, refNumber?: Nullable<string>}) {
  return `${object.refNumber ? object.refNumber : translateService.instant(WITHOUT_REF_NUMBER)}: ${object.subject}`;
}

export function getStorageUnitLabel(storageUnit: StorageUnitDto) {
  return `${storageUnit.storageUnitNumber}: ${storageUnit.name}`;
}

export function getBigObjectIcon(object: Nullable<EsslObject>) {
  switch (object?.objectClass) {
    case DocumentAllowableObjectClass.RECEIVED_DOCUMENT:
      return 'big_received_document';
    case DocumentAllowableObjectClass.RECEIVED_DOCUMENT_DIGITAL:
      return 'big_received_document_digital';
    case DocumentAllowableObjectClass.OWN_DOCUMENT:
      return 'big_own_document';
    case DocumentAllowableObjectClass.OWN_DOCUMENT_DIGITAL:
      return 'big_own_document_digital';
    case FileAllowableObjectClass.FILE:
      return 'big_file';
    case FileAllowableObjectClass.FILE_DIGITAL:
      return 'big_file_digital';
    case StorageUnitAllowableObjectClass.STORAGE_UNIT:
      return 'storage_unit_big';
    case StorageUnitAllowableObjectClass.STORAGE_UNIT_DIGITAL:
      return 'storage_unit_digital_big';
    default:
      console.warn(`Big icon for objectClass=${object?.objectClass} is missing.`);
      return null;
  }
}

export function isEsslObjectEntity(entity: Nullable<EsslEntity>): entity is EsslEntity & (OwnDocumentDto | ReceivedDocumentDto | FileDto) {
  return [
    EntityType.DOCUMENT,
    EntityType.OWN_DOCUMENT,
    EntityType.RECEIVED_DOCUMENT,
    EntityType.FILE,
    EntityType.STORAGE_UNIT,
  ].includes(entity?.entityType!);
}

export function isTaskEntity(entity: Nullable<EsslEntity>): entity is EsslEntity & ExtendedCirculationTaskDto {
  return entity?.entityType === EntityType.CIRCULATION_TASK || 'isManagementTask' in ((entity ?? {}) as (EsslEntity & ExtendedCirculationTaskDto));
}

export function isActivityEntity(entity: Nullable<EsslEntity>): entity is EsslEntity & ExtendedCirculationTaskDto {
  return entity?.entityType === EntityType.CIRCULATION_ACTIVITY || 'activityType' in ((entity ?? {}) as EsslEntity & ExtendedCirculationTaskDto);
}

export function getNumberedLabel(i: number, labelToTranslate: string, translateService: TranslateService) {
  return `${i + 1}. ${translateService.instant(labelToTranslate)}`;
}

export function memorySizeToBytes(memorySizeString: Nullable<string>): number {
  if (!memorySizeString) {
    return 0;
  }
  else {
    let unitBase = 0;

    if (memorySizeString.endsWith('kB')) {
      unitBase = 1024;
    }
    else if (memorySizeString.endsWith('MB')) {
      unitBase = 1024 ** 2;
    }

    memorySizeString.replace(/[kM]B/g, '');

    const quantity = parseInt(memorySizeString, 10);

    return quantity * unitBase;
  }
}

export function getObjectTagColor(object: Nullable<DocumentOrFileWithGenericState | OwnDocumentDto | ReceivedDocumentDto | FileDto | StorageUnitDto>) {
  if (isDocumentObject(object)) {
    const state = object.documentState ?? (object as any).entityState as DocumentState;
    return DOCUMENT_TAG_COLORS[state];
  }
  else if (isFileObject(object)) {
    const state = object.fileState ?? (object as any).entityState as FileState;
    return FILE_TAG_COLORS[state];
  }
  else {
    return 'blue';
  }
}

export function getRegistryTransferTaskTagColor(transferActivityState: Nullable<RegistryOfficeTransferState>) {
  switch (transferActivityState) {
    case RegistryOfficeTransferState.HANDED_OVER:
    case RegistryOfficeTransferState.TO_RESOLVE_ISSUES:
      return 'orange';
    case RegistryOfficeTransferState.TAKEN_OVER_FOR_SAVING:
    case RegistryOfficeTransferState.SAVED_TO_DESA:
    case RegistryOfficeTransferState.TAKEN_OVER:
      return 'green';
    case RegistryOfficeTransferState.TO_RESOLVE_ISSUES_BY_OWNER:
    case RegistryOfficeTransferState.TO_RESOLVE_ISSUES_BY_REGISTRY_OFFICE:
      return 'red';
    default:
      return 'blue';
  }
}

export function getRegistryTransferLabel(transferActivityState: Nullable<RegistryOfficeTransferState>) {
  return `en.registryOfficeTransferState.${transferActivityState}` ;
}

export const presentOrFutureDateValidator = {
  validationFn: (d: Date) => {
    return d >= getTodayMidnight();
  },
  invalidDateMessage : { errorMessageTemplate: 'Datum nesmí být v minulosti'}
};

export function getLockedStateText(object: Nullable<EsslObject>, translateService: TranslateService, translateParser: TranslateParser): string {
  if (object) {
    const lockedState = {
      lockedState: translateService.instant('en.lockedState.' + String(object.lockedState)),
    };
    if (isDocumentObject(object)) {
      return translateParser.interpolate(translateService.instant('Dokument je zablokován: {{lockedState}}'), lockedState)!;
    } else if (isFileObject(object)) {
      return translateParser.interpolate(translateService.instant('Spis je zablokován: {{lockedState}}'), lockedState)!;
    } else if (isStorageUnitObject(object)) {
      return translateParser.interpolate(translateService.instant('Ukládací jednotka je zablokována: {{lockedState}}'), lockedState)!;
    } else {
      return '';
    }
  } else {
    return '';
  }
}

export const DISTRIBUTOR_POST_CODE = 'posta';

export const ADDITIONAL_SERVICE_ISDS_FICTIONAL_DELIVERY_CODE = 'DorucitFikci';

export const DELIVERY_TYPE_PERSONAL_CODE = 'Osobne';
export const DELIVERY_TYPE_POST_CODE = 'Posta';
export const DELIVERY_TYPE_COURIER_CODE = 'Kuryr';
export const DELIVERY_TYPE_DELIVERY_SERVICE_CODE = 'DorucovaciSluzba';
export const DELIVERY_TYPE_EMAIL_CODE = 'EPodatelnaMail';
export const DELIVERY_TYPE_WEB_CODE = 'EPodatelnaWeb';
export const DELIVERY_TYPE_INTERNAL_DIGITAL_CODE = 'InterniE';
export const DELIVERY_TYPE_INTERNAL_ANALOG_CODE = 'Interni';
export const DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE = 'ElektronickaPosta';
export const DELIVERY_TYPE_DATABOX_CODE = 'DatovaSchranka';
export const DELIVERY_TYPE_REGISTERED_EPOST_CODE = 'Rep';

export function isRetentionTriggerTypeWithPeriod(retentionTriggerType: Nullable<RetentionTriggerTypeCode>) {
  return retentionTriggerType === RetentionTriggerTypeCode.AFTER_EXTERNAL_EVENT || retentionTriggerType === RetentionTriggerTypeCode.AFTER_SUBJECT_CREATE;
}

export function isRetentionTriggerTypeWithUnknownYear(retentionTriggerType: Nullable<RetentionTriggerTypeCode>) {
  return retentionTriggerType === RetentionTriggerTypeCode.AFTER_EXTERNAL_EVENT || retentionTriggerType === RetentionTriggerTypeCode.AFTER_SUBJECT_CREATE || retentionTriggerType === RetentionTriggerTypeCode.AFTER_ENTITY_CLOSE;
}

export function isExternalRetentionTriggerType(retentionTriggerType: Nullable<RetentionTriggerTypeCode>) {
  return retentionTriggerType === RetentionTriggerTypeCode.AFTER_EXTERNAL_EVENT;
}

export function formatDisposalSchedule(disposalSchedule: Nullable<DisposalScheduleDto | SimplifiedDisposalScheduleDto>) {
  if (disposalSchedule) {
    return disposalSchedule.disposalOperationCode! +
      (disposalSchedule.retentionPeriod ?? disposalSchedule.disposalYear ?? '') +
      ' - ' + disposalSchedule.name;
  } else {
    return '';
  }
}

export function getExternalRetentionTriggersOptions(codebookService: CodebookService) {
  return codebookService.externalRetentionTriggers().pipe(
    map(retentionTriggers => (retentionTriggers ?? []).map(rt => ({
      value: rt.id,
      label: `${rt.code} ${rt.description}`,
    }))),
  );
}
