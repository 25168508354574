/**
 * Represents a single tabset item.
 */
export interface TabItem<I = string | number> {
  /**
   * Tab identifier. Should be unique in each tabset.
   */
  id: I;
  /**
   * Tab label.
   */
  label: string;
  /**
   * Tab tooltip.
   */
  tooltip?: Nullable<string>;
  /**
   * If TRUE, the contents of count property will be shown in the tab next to tab label.
   */
  showCount?: Nullable<boolean>;
  /**
   * Represents a count assigned to the tab. Rendered to the right of tab label only if showCount is TRUE.
   */
  count?: Nullable<number>;
  /**
   * If TRUE, the tab will be greyed out and not available for clicking
   */
  disabled?: Nullable<boolean>;
  /**
   * Tab icon rendered to the left of tab label.
   */
  icon?: Nullable<string>;
  /**
   * If TRUE, will render tab validity icon from valid property.
   */
  showTabValidity?: Nullable<boolean>;
  /**
   * Tab validity flag. TRUE - valid; FALSE - invalid.
   * Can be used in multistep scenarios where each step has validity state.
   */
  valid?: Nullable<boolean>;
  /**
   * If TRUE, the tab will be excluded from rendering.
   */
  isHidden?: Nullable<boolean>;
  /**
   * If TRUE, will show a loading spinner to the left of tab label.
   */
  loading?: Nullable<boolean>;
}

/**
 * A tab with additional rendering priority defined.
 *
 * Rendering priorities are useful for responsive designs when certain tabs are
 * fine to be rendered off-secren while certain tabs should always be in the view.
 */
export interface TabItemWithPriority<I = string | number> extends TabItem<I> {

  /**
   * Tab priority amongst other tabs.
   * Tab priority is closely tied to responsive breakpoints.
   * If tab priority is too low for a given active breakpoint, the tab will
   *   be moved to a special dropdown tab called "More".
   * Active tabs with low priority will always be visible outside of the "More" dropdown.
   * Tabs without priority will be rendered always at their place.
   */
  priority: TabPriority;

}

/**
 * Tab size. Small tabs have reduced inner paddings.
 * Default tab size is strongly preferred.
 */
export type TabsSize = 'small' | 'default';

/**
 * Tab priority.
 */
export enum TabPriority {
  /**
   * Always visible even on a large desktop.
   */
  LOW = 'LOW',
  /**
   * Always visible on medium and large desktop.
   */
  MEDIUM = 'MEDIUM',
  /**
   * Always visible on small, medium and large desktop.
   */
  HIGH = 'HIGH',
  /**
   * Always visible for every breakpoint.
   */
  HIGHEST = 'HIGHEST',
}
