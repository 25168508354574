import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {combineLatest, map, Observable, of} from 'rxjs';
import {TransactionEventAttributeDto, TransactionLogAttributeType} from '|api/commons';
import {ConfigTransactionEventDto} from '|api/config-server';
import {TransactionEventDto,} from '|api/transactionlog';
import {FindFunctionalPositionByIdPipe} from '../find-functional-position-by-id.pipe';
import {formatBoolean, iczFormatDate, iczFormatDatetime, iczFormatTime, parseBoolean} from '@icz/angular-essentials';
import {CodebookService} from '../../../core/services/codebook.service';
import {ApplicationLanguage} from '../../../core/services/environment.models';

export interface TransactionSidebarEvent {
  eventData: TransactionEventDto | ConfigTransactionEventDto;
  attributes: TransactionEventAttributeDto[];
}

enum TransactionEventAttributeValueKey {
  ORIGINAL_VALUE = 'originalValue',
  CURRENT_VALUE = 'currentValue',
}

@Component({
  selector: 'icz-transaction-sidebar',
  templateUrl: './transaction-sidebar.component.html',
  styleUrls: ['./transaction-sidebar.component.scss'],
  providers: [
    FindFunctionalPositionByIdPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TransactionSidebarComponent {

  private codebookService = inject(CodebookService);
  private translateService = inject(TranslateService);
  private functionalPositionPipe = inject(FindFunctionalPositionByIdPipe);

  @Input() opened: Nullable<boolean> = false;

  @Input() event: Nullable<TransactionSidebarEvent>;

  @Output() closed = new EventEmitter<void>();

  get attributes(): Nullable<TransactionEventAttributeDto[]> {
    return this.event?.attributes;
  }

  get eventTypeLocalizationString(): string {
    if ((this.event?.eventData as Nullable<TransactionEventDto>)?.transactionLogEventType) {
      return `en.transactionLogEventType.${(this.event?.eventData as TransactionEventDto)?.transactionLogEventType}`;
    }
    else if ((this.event?.eventData as Nullable<ConfigTransactionEventDto>)?.configTransactionEventType) {
      return `en.configTransactionEventType.${(this.event?.eventData as ConfigTransactionEventDto)?.configTransactionEventType}`;
    }
    else {
      return '';
    }
  }

  get entityType(): Nullable<string> {
    return (
      (this.event?.eventData as Nullable<TransactionEventDto>)?.esslObjectType ??
      (this.event?.eventData as Nullable<ConfigTransactionEventDto>)?.entityType
    );
  }

  readonly TransactionEventAttributeValueKey = TransactionEventAttributeValueKey;

  getAttributeLabel(attribute: TransactionEventAttributeDto) {
    if (attribute.disableAttributeNameTranslation) {
      return attribute.attributeName;
    }
    else {
      const attrParts = ['attr'];
      const eventType = (
        (this.event?.eventData as Nullable<TransactionEventDto>)?.transactionLogEventType ??
        (this.event?.eventData as Nullable<ConfigTransactionEventDto>)?.configTransactionEventType
      );

      if (eventType) {
        attrParts.push(eventType);
      }
      if (this.entityType) {
        attrParts.push(this.entityType);
      }

      attrParts.push(attribute.attributeName);

      return attrParts.join('.');
    }
  }

  onPreviewClose() {
    this.opened = false;
    this.closed.emit();
  }

  formatAttributeValue(attribute: TransactionEventAttributeDto, valueKey: TransactionEventAttributeValueKey): Observable<string> {
    const value: Nullable<string | Array<string>> = attribute[valueKey];
    const attributeType = attribute.attributeType;

    if (
      !attribute.attributeCardinality ||
      attribute.attributeCardinality === 'SINGLE_VALUE'
    ) {
      return this.formatSingleValue(
        value as Nullable<string>,
        attributeType
      );
    }
    else if (
      attribute.attributeCardinality === 'MULTI_VALUE' ||
      attribute.attributeCardinality === 'MULTI_VALUE_WITH_KEYS'
    ) {
      const valuePart$ = ((value ?? []) as Array<string>).map(
        valuePart => this.formatSingleValue(valuePart, attributeType)
      );

      return combineLatest(valuePart$).pipe(
        map(valueParts => valueParts.join(', ')),
      );
    }
    else {
      return of('');
    }
  }

  private formatSingleValue(value: Nullable<string>, attributeType: TransactionLogAttributeType): Observable<string> {
    if (isNil(value)) {
      return of('');
    }
    else {
      const currentLocale = this.translateService.currentLang as ApplicationLanguage;

      switch (attributeType) {
        case TransactionLogAttributeType.DATETIME:
          return of(iczFormatDatetime(currentLocale, value as string));
        case TransactionLogAttributeType.LOCALDATE:
          return of(iczFormatDate(currentLocale, value as string, true));
        case TransactionLogAttributeType.LOCALTIME:
          return of(iczFormatTime(currentLocale, Number(value), true));
        case TransactionLogAttributeType.L_10_N_STRING:
          return of(this.translateService.instant(value as string));
        case TransactionLogAttributeType.BOOLEAN:
          return of(formatBoolean(
            this.translateService,
            parseBoolean(value as string),
          ));
        case TransactionLogAttributeType.FUNCTIONAL_POSITION:
          return this.functionalPositionPipe.transform(value ? Number(value) : null);
        case TransactionLogAttributeType.DECIMAL:
        case TransactionLogAttributeType.INTEGER:
        case TransactionLogAttributeType.STRING:
        case TransactionLogAttributeType.ENUM:
        default:
          return of(value as string);
      }
    }
  }

}
