import {Component, DestroyRef, EventEmitter, HostListener, inject, Input, OnInit, Output,} from '@angular/core';
import {merge} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  AnalogComponentOrigin,
  AnalogCompositionType,
  CirculationTaskState,
  ComponentProcessingState,
  ContainerExtractionState,
  DigitalComponentOrigin,
  DigitalComponentRenditionType,
  DigitalComponentVersionSignatureFlagType,
  DocumentForm,
  DocumentState,
  EntityType,
  EsslComponentAvailability,
  EsslComponentRelation
} from '|api/commons';
import {
  DigitalComponentCompleteDto,
  DigitalComponentVersionCompleteDto,
  DigitalComponentVersionSignatureFlagDto
} from '|api/component';
import {DocumentDto} from '|api/document';
import {InternalNotificationKey} from '|api/notification';
import {EsslComponentDto, EsslComponentDtoWithActivity} from '../../../services/essl-component-search.service';
import {getExtractionStateTagColor, getLatestDigitalComponentVersion} from '../shared-document.utils';
import {enumArrayToOptions, enumToArray, enumToOptions} from '../../../core/services/data-mapping.utils';
import {SignMarkTimestampService} from '../../../services/sign-mark-timestamp.service';
import {WebSocketNotificationsService} from '../../notifications/web-socket-notifications.service';
import {DocumentFiltersDataService} from '../document-table/components/document-filters/document-filters-data.service';
import {RemoteBinaryFileDownloadService} from '../../../services/remote-binary-file-download.service';
import {InternalNotificationMessageCode} from '../../../core/services/notifications/internal-notification.enum';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {
  ColumnDefinition,
  extendDefaultTableConfig,
  FilterType,
  IczTableDataSource,
  RowDisableFn,
  TableColumnsData,
  TableConfig,
  TableReservedColumns
} from '@icz/angular-table';
import {IczOnChanges, IczSimpleChanges} from '@icz/angular-essentials';
import {IczOption} from '@icz/angular-form-elements';
import {PaperComponentDto} from '|api/sad';

export enum EsslComponentsTableColumnSet {
  ESSL_COMPONENTS_OVERVIEW = 'ESSL_COMPONENTS_OVERVIEW', // All components can be overviewed
  DIGITAL_COMPONENTS_SELECTOR_SIMPLE = 'DIGITAL_COMPONENTS_SELECTOR_SIMPLE', // Only DIGITAL components may be selected for doc resoluton etc.
  DIGITAL_COMPONENTS_SELECTOR_EXTENDED = 'DIGITAL_COMPONENTS_SELECTOR_EXTENDED', // Only DIGITAL components may be selected for doc resoluton etc.
  DIGITAL_COMPONENTS_SELECTOR_CONSIGNMENTS = 'DIGITAL_COMPONENTS_SELECTOR_CONSIGNMENTS',
  ESSL_COMPONENTS_SETTLEMENT_SELECTOR = 'ESSL_COMPONENTS_SETTLEMENT_SELECTOR',
  OWN_CONSIGNMENT_COMPONENT_SELECTOR = 'OWN_CONSIGNMENT_COMPONENT_SELECTOR',
  CONTAINER_CONTENTS = 'CONTAINER_CONTENTS',
}


export enum EsslComponentsTableColumn {
  ORDER_IN_DOCUMENT = 'orderInDocument',
  IS_FINAL = 'isFinal',
  LABEL_AND_DESCRIPTION = 'labelAndDescription',
  DESCRIPTION = 'description',
  LABEL = 'label',
  VERSION = 'version',
  AVAILABILITY = 'availability',
  SIGNATURES = 'signatures',
  RELATION_TYPE = 'relationType',
  ORIGIN_TYPE = 'originType',
  CREATED_AT = 'auditInfo.createdAt',
  CREATED_BY = 'auditInfo.createdBy',
  MODIFIED_AT = 'auditInfo.modifiedAt',
  MODIFIED_BY = 'auditInfo.modifiedBy',
  FILE_SIZE = 'fileSize',
  IDENTIFIER = 'identifier',
  UNACCEPTABLE_OUTPUT_FORMAT = 'unacceptableOutputFormat',
  COMPONENT_STATE = 'componentState',
  NUMBER_OF_PAGES = 'numberOfPages',
  COUNT = 'count',
  NUMBER_OF_SHEETS = 'numberOfSheets',
  PATH_IN_CONTAINER = 'pathInContainer',
  RENDITION_TYPE = 'renditionType',
  LABEL_SMALL = 'labelSmall',
  CIRCULATION_ACTIVITY = 'circulationActivity',
}

const COLUMN_NAMES: Record<EsslComponentsTableColumn|TableReservedColumns, string> = {
  [TableReservedColumns.SELECTION]: 'Výběr',
  [EsslComponentsTableColumn.ORDER_IN_DOCUMENT]: 'Index',
  [EsslComponentsTableColumn.IS_FINAL]: 'Finální verze',
  [EsslComponentsTableColumn.CIRCULATION_ACTIVITY]: 'Úkol komponenty',
  [EsslComponentsTableColumn.LABEL_AND_DESCRIPTION]: 'Název a popis komponenty',
  [EsslComponentsTableColumn.DESCRIPTION]: 'Popis komponenty',
  [EsslComponentsTableColumn.LABEL]: 'Název komponenty',
  [EsslComponentsTableColumn.LABEL_SMALL]: 'Název komponenty',
  [EsslComponentsTableColumn.AVAILABILITY]: 'Dostupnost',
  [EsslComponentsTableColumn.VERSION]: 'Verze',
  [EsslComponentsTableColumn.SIGNATURES]: 'Podpis/Pečeť',
  [EsslComponentsTableColumn.RELATION_TYPE]: 'Vazba',
  [EsslComponentsTableColumn.ORIGIN_TYPE]: 'Původ',
  [EsslComponentsTableColumn.CREATED_AT]: 'Datum vytvoření',
  [EsslComponentsTableColumn.FILE_SIZE]: 'Velikost',
  [EsslComponentsTableColumn.CREATED_BY]: 'Založil',
  [EsslComponentsTableColumn.MODIFIED_AT]: 'Datum úpravy',
  [EsslComponentsTableColumn.MODIFIED_BY]: 'Změnil',
  [EsslComponentsTableColumn.IDENTIFIER]: 'UID',
  [EsslComponentsTableColumn.UNACCEPTABLE_OUTPUT_FORMAT]: 'Výstupní formát',
  [EsslComponentsTableColumn.COMPONENT_STATE]: 'Stav',
  [EsslComponentsTableColumn.COUNT]: 'Počet příloh/svazků',
  [EsslComponentsTableColumn.NUMBER_OF_SHEETS]: 'Počet listů',
  [EsslComponentsTableColumn.NUMBER_OF_PAGES]: 'Počet stran',
  [EsslComponentsTableColumn.PATH_IN_CONTAINER]: 'Umístění v kontejneru',
  [EsslComponentsTableColumn.RENDITION_TYPE]: 'Ztvárnění',
};

const COLUMN_SETS: Record<EsslComponentsTableColumnSet, Array<TableReservedColumns|EsslComponentsTableColumn>> = {
  [EsslComponentsTableColumnSet.ESSL_COMPONENTS_OVERVIEW]: [
    TableReservedColumns.SELECTION,
    EsslComponentsTableColumn.ORDER_IN_DOCUMENT,
    EsslComponentsTableColumn.IS_FINAL,
    EsslComponentsTableColumn.AVAILABILITY,
    EsslComponentsTableColumn.CIRCULATION_ACTIVITY,
    EsslComponentsTableColumn.LABEL_AND_DESCRIPTION,
    EsslComponentsTableColumn.LABEL,
    EsslComponentsTableColumn.RENDITION_TYPE,
    EsslComponentsTableColumn.DESCRIPTION,
    EsslComponentsTableColumn.VERSION,
    EsslComponentsTableColumn.COMPONENT_STATE,
    EsslComponentsTableColumn.SIGNATURES,
    EsslComponentsTableColumn.RELATION_TYPE,
    EsslComponentsTableColumn.ORIGIN_TYPE,
    EsslComponentsTableColumn.CREATED_AT,
    EsslComponentsTableColumn.FILE_SIZE,
    EsslComponentsTableColumn.CREATED_BY,
    EsslComponentsTableColumn.MODIFIED_AT,
    EsslComponentsTableColumn.MODIFIED_BY,
    EsslComponentsTableColumn.IDENTIFIER,
    EsslComponentsTableColumn.COUNT,
    EsslComponentsTableColumn.NUMBER_OF_SHEETS,
    EsslComponentsTableColumn.NUMBER_OF_PAGES,
  ],
  [EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_SIMPLE]: [
    TableReservedColumns.SELECTION,
    EsslComponentsTableColumn.IS_FINAL,
    EsslComponentsTableColumn.LABEL_AND_DESCRIPTION,
    EsslComponentsTableColumn.LABEL,
    EsslComponentsTableColumn.DESCRIPTION,
    EsslComponentsTableColumn.CREATED_AT,
  ],
  [EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_EXTENDED]: [
    TableReservedColumns.SELECTION,
    EsslComponentsTableColumn.ORDER_IN_DOCUMENT,
    EsslComponentsTableColumn.IS_FINAL,
    EsslComponentsTableColumn.LABEL_AND_DESCRIPTION,
    EsslComponentsTableColumn.LABEL,
    EsslComponentsTableColumn.RENDITION_TYPE,
    EsslComponentsTableColumn.DESCRIPTION,
    EsslComponentsTableColumn.SIGNATURES,
    EsslComponentsTableColumn.RELATION_TYPE,
    EsslComponentsTableColumn.FILE_SIZE,
    EsslComponentsTableColumn.CREATED_AT,
    EsslComponentsTableColumn.CREATED_BY,
    EsslComponentsTableColumn.MODIFIED_AT,
    EsslComponentsTableColumn.MODIFIED_BY,
    EsslComponentsTableColumn.IDENTIFIER,
    EsslComponentsTableColumn.COUNT,
    EsslComponentsTableColumn.NUMBER_OF_SHEETS,
    EsslComponentsTableColumn.NUMBER_OF_PAGES,
  ],
  [EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_CONSIGNMENTS]: [
    TableReservedColumns.SELECTION,
    EsslComponentsTableColumn.ORDER_IN_DOCUMENT,
    EsslComponentsTableColumn.IS_FINAL,
    EsslComponentsTableColumn.LABEL_AND_DESCRIPTION,
    EsslComponentsTableColumn.LABEL,
    EsslComponentsTableColumn.DESCRIPTION,
    EsslComponentsTableColumn.FILE_SIZE,
    EsslComponentsTableColumn.SIGNATURES,
    EsslComponentsTableColumn.RELATION_TYPE,
    EsslComponentsTableColumn.CREATED_AT,
  ],
  [EsslComponentsTableColumnSet.ESSL_COMPONENTS_SETTLEMENT_SELECTOR]: [
    TableReservedColumns.SELECTION,
    EsslComponentsTableColumn.ORDER_IN_DOCUMENT,
    EsslComponentsTableColumn.UNACCEPTABLE_OUTPUT_FORMAT,
    EsslComponentsTableColumn.IS_FINAL,
    EsslComponentsTableColumn.LABEL_AND_DESCRIPTION,
    EsslComponentsTableColumn.LABEL,
    EsslComponentsTableColumn.DESCRIPTION,
    EsslComponentsTableColumn.SIGNATURES,
    EsslComponentsTableColumn.CREATED_AT,
    EsslComponentsTableColumn.RELATION_TYPE,
  ],
  [EsslComponentsTableColumnSet.OWN_CONSIGNMENT_COMPONENT_SELECTOR]: [
    TableReservedColumns.SELECTION,
    EsslComponentsTableColumn.ORDER_IN_DOCUMENT,
    EsslComponentsTableColumn.IS_FINAL,
    EsslComponentsTableColumn.LABEL_AND_DESCRIPTION,
    EsslComponentsTableColumn.LABEL,
    EsslComponentsTableColumn.DESCRIPTION,
    EsslComponentsTableColumn.FILE_SIZE,
    EsslComponentsTableColumn.SIGNATURES,
    EsslComponentsTableColumn.RELATION_TYPE,
    EsslComponentsTableColumn.CREATED_AT,
  ],
  [EsslComponentsTableColumnSet.CONTAINER_CONTENTS]: [
    EsslComponentsTableColumn.LABEL_SMALL,
    EsslComponentsTableColumn.PATH_IN_CONTAINER,
    EsslComponentsTableColumn.FILE_SIZE,
  ],
};

function isDigitalComponent(esslComponent: Nullable<EsslComponentDto>): esslComponent is DigitalComponentCompleteDto {
  return esslComponent?.entityType === EntityType.DIGITAL_COMPONENT;
}

function isPaperComponent(esslComponent: Nullable<EsslComponentDto>): esslComponent is PaperComponentDto {
  return esslComponent?.entityType === EntityType.PAPER_COMPONENT;
}


@Component({
  selector: 'icz-essl-components-table',
  templateUrl: './essl-components-table.component.html',
  styleUrls: ['./essl-components-table.component.scss'],
})
export class EsslComponentsTableComponent implements OnInit, IczOnChanges {

  private signMarkTimestampService = inject(SignMarkTimestampService);
  private wsNotificationService = inject(WebSocketNotificationsService);
  private documentFiltersDataService = inject(DocumentFiltersDataService);
  private remoteBinaryFileDownloadService = inject(RemoteBinaryFileDownloadService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true})
  id!: string;
  @Input({required: true})
  dataSource!: IczTableDataSource<EsslComponentDtoWithActivity>;
  @Input()
  isNonBlockingLoading = false;
  @Input()
  columnSet = EsslComponentsTableColumnSet.ESSL_COMPONENTS_OVERVIEW;
  @Input()
  preselectedRows: Nullable<EsslComponentDto[]>;
  @Input()
  rowSelectionDisabler: Nullable<RowDisableFn<any>>;
  @Input()
  disabledRowTooltip: Nullable<string>;
  @Output()
  rowsSelected = new EventEmitter<EsslComponentDto[]>();
  @Input()
  rowCount: Nullable<number>;
  @Input()
  acceptableDigitalDataFormats: Nullable<string[]>;
  @Input()
  document: Nullable<DocumentDto>;
  @Input()
  canUserDisplayComponentsContent = true;
  @Input()
  consignmentId: Nullable<number>;
  @Input()
  hideTableToolbar = false;

  readonly CirculationTaskState = CirculationTaskState;
  readonly TableReservedColumns = TableReservedColumns;
  readonly EsslComponentsTableColumn = EsslComponentsTableColumn;
  readonly EsslComponentAvailability = EsslComponentAvailability;
  readonly EsslComponentsTableColumnSet = EsslComponentsTableColumnSet;

  readonly isDigitalComponent = isDigitalComponent;
  readonly isPaperComponent = isPaperComponent;
  readonly getLatestDigitalComponentVersion = getLatestDigitalComponentVersion;
  readonly getExtractionStateTagColor = getExtractionStateTagColor;

  config!: TableConfig<EsslComponentsTableColumn>;

  esslComponentRelationOptions: IczOption[] = enumToOptions('esslComponentRelation', EsslComponentRelation);
  esslComponentAvailabilityOptions: IczOption[] = enumToOptions('esslComponentAvailability', EsslComponentAvailability);
  extractionStateOptions = enumToOptions('containerExtractionState', ContainerExtractionState);
  originTypeOptions: IczOption[] = [];

  columnsData!: TableColumnsData<EsslComponentsTableColumn>;

  columnsDisplayed: {id: string; width: number}[] = [];
  expandedRow: Nullable<EsslComponentDto>;

  addingTimestampToDigitalComponents$ = this.signMarkTimestampService.addingTimestampToDigitalComponents$;

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (!this.expandedRow) return;
    this.setColumnsDisplayedForExpanded();
  }

  ngOnInit() {
    merge(
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.CONVERSION_SUCCESS),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.CONVERSION_ERROR),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.CONVERSION_WITH_CLAUSE_SUCCESS),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.CONVERSION_WITH_CLAUSE_ERROR),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.AUTHORIZED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.AUTHORIZED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.VERIFIED_CONVERSION_PAPER_TO_DIGITAL_SUCCESS),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.VERIFIED_CONVERSION_DIGITAL_TO_PAPER_SUCCESS),
      this.wsNotificationService.getMessageListener$(InternalNotificationMessageCode.FORM_FILLER_CLAUSE_SUCCESS),
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(message => {
        const documentIds = message.parameters.map(parameterSet => Number(parameterSet[InternalNotificationKey.DOCUMENT_ID]));

        if (documentIds.includes(this.document?.id ?? 0)) {
          this.dataSource?.reload(false);
        }
      });

    const digitalComponentOriginValues = enumToArray(DigitalComponentOrigin);
    const analogComponentOriginValues = enumToArray(AnalogComponentOrigin).filter(analogEnumValueTuple => {
      return !digitalComponentOriginValues.find(digitalEnumValueTuple => analogEnumValueTuple[1] === digitalEnumValueTuple[1]);
    });

    this.originTypeOptions = [
      ...enumArrayToOptions('digitalComponentOrigin', digitalComponentOriginValues),
      ...enumArrayToOptions('analogComponentOrigin', analogComponentOriginValues)
    ];

    this.initTableConfig();
    this.setUpColumnSet();
  }

  get isDocumentDeactivated() {
    return this.document?.documentState === DocumentState.DEACTIVATED;
  }

  ngOnChanges(changes: IczSimpleChanges<this>) {
    if (changes.document && this.document) {
      this.setUpColumnSet();
    }
  }

  setColumnsDisplayedForExpanded(unsetExpandedRow = false) {
    this.columnsDisplayed = [];
    const thElems = document.querySelectorAll('th.mat-mdc-header-cell');
    thElems.forEach(th => {
      const idPrefixLength = 'matheader'.length;
      this.columnsDisplayed.push({id: th.id.substring(idPrefixLength), width: th.clientWidth});
    });
    if (unsetExpandedRow) this.expandedRow = null;
  }

  toggleVersions(row: EsslComponentDto) {
    this.setColumnsDisplayedForExpanded();
    this.expandedRow = this.expandedRow === row ? null : row;
  }

  download(digitalComponentVersion: DigitalComponentVersionCompleteDto) {
    this.remoteBinaryFileDownloadService.fetchDigitalComponentVersion(digitalComponentVersion.id!);
  }

  isAddingTimestamp(id: number) {
    return this.signMarkTimestampService.digitalComponentsWithAddingTimestampInProgress$.pipe(
      map(digitalComponents => Boolean(digitalComponents.find(digitalComponent => digitalComponent.id === id)))
    );
  }

  isMarking(id: number) {
    return this.signMarkTimestampService.digitalComponentsWithMarkInProgress$.pipe(
      map(digitalComponents => Boolean(digitalComponents.find(digitalComponent => digitalComponent.id === id)))
    );
  }

  isInManualValidation(id: number) {
    return this.signMarkTimestampService.digitalComponentsWithManualValidationProgress$.pipe(
      map(digitalComponents => Boolean(digitalComponents.find(digitalComponent => digitalComponent.id === id)))
    );
  }

  isUnacceptableOutputFormat(row: EsslComponentDto) {
    if (row.entityType === EntityType.DIGITAL_COMPONENT) {
      const latestComponentVersionPuid = getLatestDigitalComponentVersion(row as DigitalComponentCompleteDto)?.puid!;
      const isAcceptable = (this.acceptableDigitalDataFormats ?? []).includes(latestComponentVersionPuid);
      return !isAcceptable;
    } else {
      return false;
    }
  }

  hasValidatedFlags(digitalFlags: Nullable<DigitalComponentVersionSignatureFlagDto[]>) {
    if (digitalFlags) {
      return digitalFlags.every(flagData => flagData.flag !== DigitalComponentVersionSignatureFlagType.VALIDATION_FAILED);
    } else {
      return false;
    }
  }

  private setUpColumnSet() {
    const columnDefinitions: ColumnDefinition<EsslComponentsTableColumn>[] = [];

    for (const columnId of COLUMN_SETS[this.columnSet]) {
      let columnDef: ColumnDefinition<EsslComponentsTableColumn>;

      switch (columnId) {
        case TableReservedColumns.SELECTION:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NONE
          };
          break;
        case EsslComponentsTableColumn.ORDER_IN_DOCUMENT:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NONE,
            fixedWidth: 30,
            hideLabelInHeader: true,
          };
          break;
        case EsslComponentsTableColumn.IS_FINAL:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            icon: 'checked',
            fixedWidth: 40,
            filterType: FilterType.BOOLEAN,
            disableSort: false,
          };
          break;
        case EsslComponentsTableColumn.AVAILABILITY:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            icon: 'icon_lock',
            fixedWidth: 30,
            filterType: FilterType.ENUM,
            disableSort: true,
            list: this.esslComponentAvailabilityOptions,
          };
          break;
        case EsslComponentsTableColumn.LABEL_AND_DESCRIPTION:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NONE,
            maxWidth: 300,
            filterConfig: {
              customFilterId: 'label',
            }
          };
          break;
        case EsslComponentsTableColumn.LABEL_SMALL:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NONE,
            maxWidth: 300,
            filterConfig: {
              customFilterId: 'label',
            }
          };
          break;
        case EsslComponentsTableColumn.LABEL:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.TEXT,
            displayed: false,
            allowSettingInContextMenu: false,
          };
          break;
        case EsslComponentsTableColumn.DESCRIPTION:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.TEXT,
            displayed: false,
            allowSettingInContextMenu: false,
          };
          break;
        case EsslComponentsTableColumn.CIRCULATION_ACTIVITY:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            fixedWidth: 32,
            icon: 'task_faded',
            filterType: FilterType.NONE,
            disableSort: true
          };
          break;
        case EsslComponentsTableColumn.NUMBER_OF_PAGES:
        case EsslComponentsTableColumn.NUMBER_OF_SHEETS:
        case EsslComponentsTableColumn.COUNT:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NUMBER,
            displayed: false,
            disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
          };
          break;
        case EsslComponentsTableColumn.VERSION:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NUMBER,
            displayed: this.document?.documentForm !== DocumentForm.ANALOG,
            filterConfig: {
              customFilterId: 'version',
            }
          };
          break;
        case EsslComponentsTableColumn.COMPONENT_STATE:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NONE,
            disableSort: true
          };
          break;
        case EsslComponentsTableColumn.SIGNATURES:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NONE,
            disableSort: true,
          };
          break;
        case EsslComponentsTableColumn.RELATION_TYPE:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.ENUM,
            list: this.esslComponentRelationOptions,
            disableSort: true,
          };
          break;
        case EsslComponentsTableColumn.ORIGIN_TYPE:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.ENUM,
            list: this.originTypeOptions,
          };
          break;
        case EsslComponentsTableColumn.CREATED_AT:
        case EsslComponentsTableColumn.MODIFIED_AT:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            displayed: columnId === EsslComponentsTableColumn.CREATED_AT,
            filterType: FilterType.DATE,
          };
          break;
        case EsslComponentsTableColumn.CREATED_BY:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.ENUM,
            list$: this.documentFiltersDataService.userOptions(),
            displayed: false,
            disableSort: true,
            filterConfig: {
              customFilterId: 'auditInfo.createdBy', // auditInfo.createdBy actually contains string username, but we send user ID.
            }
          };
          break;
        case EsslComponentsTableColumn.MODIFIED_BY:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.ENUM,
            list$: this.documentFiltersDataService.userOptions(),
            displayed: false,
            disableSort: true,
            filterConfig: {
              customFilterId: 'auditInfo.modifiedBy', // auditInfo.modifiedBy actually contains string username, but we send user ID.
            }
          };
          break;
        case EsslComponentsTableColumn.IDENTIFIER:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.TEXT,
            displayed: false,
            filterConfig: {
              customFilterId: 'uid.uid',
            }
          };
          break;
        case EsslComponentsTableColumn.FILE_SIZE:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NONE,
            fixedWidth: 100,
            filterConfig: {
              customFilterId: 'fileSize',
            }
          };
          break;
        case EsslComponentsTableColumn.UNACCEPTABLE_OUTPUT_FORMAT:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            icon: 'inline_error_grey',
            fixedWidth: 30,
            filterType: FilterType.BOOLEAN,
            disableSort: true,
          };
          break;
        case EsslComponentsTableColumn.PATH_IN_CONTAINER:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.NONE,
            disableSort: true,
          };
          break;
        case EsslComponentsTableColumn.RENDITION_TYPE:
          columnDef = {
            id: columnId,
            label: COLUMN_NAMES[columnId],
            filterType: FilterType.ENUM,
            list: enumToOptions('digitalComponentRenditionType', DigitalComponentRenditionType),
            disableSort: true,
          };
          break;
      }

      columnDefinitions.push(columnDef);
    }

    this.columnsData = new TableColumnsData<EsslComponentsTableColumn>(columnDefinitions);
  }

  private initTableConfig() {
    this.config = extendDefaultTableConfig<EsslComponentsTableColumn>({
      rowHeight: this.columnSet === EsslComponentsTableColumnSet.CONTAINER_CONTENTS ? undefined : 70,
      defaultFilterColumns: [EsslComponentsTableColumn.LABEL, EsslComponentsTableColumn.DESCRIPTION],
      toolbarConfig: {
        autoOpenFilter: (
          this.columnSet !== EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_CONSIGNMENTS &&
          this.columnSet !== EsslComponentsTableColumnSet.OWN_CONSIGNMENT_COMPONENT_SELECTOR &&
          this.columnSet !== EsslComponentsTableColumnSet.ESSL_COMPONENTS_SETTLEMENT_SELECTOR
        )
      },
      defaultSort: {
        fieldName: EsslComponentsTableColumn.ORDER_IN_DOCUMENT,
        descending: false
      },
    });

    if (
      this.columnSet === EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_SIMPLE ||
      this.columnSet === EsslComponentsTableColumnSet.DIGITAL_COMPONENTS_SELECTOR_EXTENDED ||
      this.columnSet === EsslComponentsTableColumnSet.ESSL_COMPONENTS_SETTLEMENT_SELECTOR
    ) {
      this.config.toolbarConfig.showFilter = false;
      this.config.toolbarConfig.showReload = false;
    }
  }

  protected readonly EsslComponentRelation = EsslComponentRelation;
  protected readonly AnalogCompositionType = AnalogCompositionType;
  protected readonly DigitalComponentRenditionType = DigitalComponentRenditionType;
  protected readonly ComponentProcessingState = ComponentProcessingState;
}
