import {ChangeDetectionStrategy, Component, DestroyRef, inject} from '@angular/core';
import {CheckUnsavedFormDialogService} from '../../../../../services/check-unsaved/check-unsaved-form-dialog.service';
import {IFormGroupCheckable} from '../../../../../lib/form-group-checks';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {IczValidators} from '@icz/angular-form-elements';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {EsslComponentsTableColumnSet} from '../../../essl-components-table/essl-components-table.component';
import {EsslComponentDto} from '../../../../../services/essl-component-search.service';
import {ApiDigitalComponentService} from '|api/component';
import {injectModalData, injectModalRef} from '@icz/angular-modal';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

export interface EsslComponentsTakeoverFromDocumentDialogData {
  duplicate: boolean;
  documentId: Nullable<number>;
  componentIds?: Nullable<number[]>;
}

@Component({
  selector: 'icz-essl-components-takeover-from-document-dialog',
  templateUrl: './essl-components-takeover-from-document-dialog.component.html',
  styleUrls: ['./essl-components-takeover-from-document-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    CheckUnsavedFormDialogService
  ]
})
export class EsslComponentsTakeoverFromDocumentDialogComponent implements IFormGroupCheckable {

  protected loadingService = inject(LoadingIndicatorService);
  private modalRef = injectModalRef<Nullable<number>>();
  protected apiDigitalComponentService = inject(ApiDigitalComponentService);
  protected checkUnsavedService = inject(CheckUnsavedFormDialogService);
  protected data = injectModalData<EsslComponentsTakeoverFromDocumentDialogData>();
  private destroyRef = inject(DestroyRef);

  form = new IczFormGroup({
    documentId: new IczFormControl<Nullable<number>>(null),
    dupilcateToCurrentDocument: new IczFormControl<boolean>(true),
  });

  formGroupsToCheck!: string[];
  selectedComponentIds: number[] = [];

  get selectedDocumentControl() {
    return this.form.get('documentId')! as IczFormControl;
  }

  get dupilcateToCurrentDocumentControl() {
    return this.form.get('dupilcateToCurrentDocument')! as IczFormControl;
  }

  ngOnInit() {
    this.checkUnsavedService.addUnsavedFormCheck(this, ['form']);
    if (this.data.duplicate) {
      this.selectedComponentIds = this.data.componentIds!;
    }

    this.dupilcateToCurrentDocumentControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      if (value) {
        this.selectedDocumentControl.clearValidators();
      } else {
        this.selectedDocumentControl.setValidators([IczValidators.required()]);
      }
    });
  }

  submit() {
    const formValue = this.form.value;
    let documentId;

    if (formValue.dupilcateToCurrentDocument || !this.data.duplicate) {
      documentId = this.data.documentId;
    } else {
      documentId = formValue.documentId;
    }

    this.loadingService.doLoading(
      this.apiDigitalComponentService.digitalComponentCopyDigitalComponents({
        digitalComponentIds: this.selectedComponentIds,
        documentId: documentId!,
      }),
      this,
    ).subscribe(_ => {
      this.form.markAsPristine();
      this.modalRef.close(this.selectedComponentIds.length);
    });
  }

  cancel() {
    this.modalRef.close(null);
  }

  setEsslComponents(components: EsslComponentDto[]) {
    this.selectedComponentIds = components.map(components => components.id!);
  }

  protected readonly EsslComponentsTableColumnSet = EsslComponentsTableColumnSet;
}
