import {Component, inject} from '@angular/core';
import {SubstitutionsCounterMode} from '../dashboard-substitutions-counter/dashboard-substitutions-counter.component';
import {Router} from '@angular/router';
import {createAbsoluteRoute} from '../../../core/routing/routing.helpers';
import {ApplicationRoute} from '../../../enums/shared-routes.enum';
import {RegistryOfficeRoute} from '../../../enums/documents-routes.enum';
import {findMenuItemByViewId, MainMenuCountsService} from '../../../core/services/main-menu-counts.service';
import {map} from 'rxjs/operators';
import {MenuItemCountViewIds} from '../../../core/routing/menu-item-count-view-ids';
import {AbstractDashboardConnectedToSettingsComponent} from '../abstract-dashboard-connected-to-settings.component';
import {RegistryOfficeSearchService} from '../../../services/registry-office-search.service';

import {FilterOperator} from '@icz/angular-table';
import {CodebookService} from '../../../core/services/codebook.service';


@Component({
  selector: 'icz-dashboard-registry',
  templateUrl: './dashboard-registry.component.html',
  styleUrls: ['./dashboard-registry.component.scss']
})
export class DashboardRegistryComponent extends AbstractDashboardConnectedToSettingsComponent {

  private codebookService = inject(CodebookService);
  private registryOfficeSearchService = inject(RegistryOfficeSearchService);
  private mainMenuCountsService = inject(MainMenuCountsService);
  private router = inject(Router);

  readonly registryOfficeToTakeOverRoute = createAbsoluteRoute(ApplicationRoute.REGISTRY_OFFICE, RegistryOfficeRoute.TO_TAKE_OVER);
  readonly toResolveIssuesRoute = createAbsoluteRoute(ApplicationRoute.REGISTRY_OFFICE, RegistryOfficeRoute.TO_RESOLVE_ISSUES);
  readonly problemsRoute = createAbsoluteRoute(ApplicationRoute.REGISTRY_OFFICE, RegistryOfficeRoute.PROBLEMS);

  readonly SubstitutionsCounterMode = SubstitutionsCounterMode;

  showRegistryOfficeGauges$ = this.codebookService.accessibleRegistryOfficesForCurrentFunctionalPosition().pipe(
    map(registryOffices => registryOffices.length > 0),
  );
  registryOfficeToTakeOver$ = this.mainMenuCountsService.menuItemsWithCounts$.pipe(
    map(menuItems => ((menuItems && findMenuItemByViewId(menuItems, MenuItemCountViewIds.REGISTRY_OFFICE_TO_TAKE_OVER)?.count) ?? 0))
  );
  toResolveIssuesCount$ = this.mainMenuCountsService.menuItemsWithCounts$.pipe(
    map(menuItems => ((menuItems && findMenuItemByViewId(menuItems, MenuItemCountViewIds.REGISTRY_OFFICE_TO_RESOLVE_ISSUES)?.count) ?? 0))
  );
  problemsWithUnresolvedIncidentsCount$ = this.registryOfficeSearchService.findRegistryOfficeProblems({
    page: 0,
    size: 0,
    filter: [
      {
        fieldName: 'unresolvedIncidentsCount',
        operator: FilterOperator.greater,
        value: String(0),
      }
    ],
    sort: [],
  }).pipe(
    map(page => page.totalElements)
  );

  goToPage(route: string) {
    this.router.navigateByUrl(route);
  }

}
