import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  DoCheck,
  ElementRef,
  inject,
  Input,
  OnInit
} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject, throttleTime} from 'rxjs';
import {map} from 'rxjs/operators';
import {Breadcrumb, BreadcrumbsService} from './breadcrumbs.service';
import {HistoryBit} from '../../../services/history.model';
import {getObjectIcon} from '../../shared-business-components/shared-document.utils';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {TranslateService} from '@ngx-translate/core';
import {IczOption} from '@icz/angular-form-elements';

const BREADCRUMB_VISIBLE_ITEMS_COUNT = 3;

interface BreadcrumbOptionData {
  breadcrumb: Breadcrumb;
}

type BreadcrumbOption = IczOption<Nullable<number>, BreadcrumbOptionData>;


@Component({
  selector: 'icz-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BreadcrumbsComponent implements OnInit, DoCheck {

  private router = inject(Router);
  private breadcrumbsService = inject(BreadcrumbsService);
  private elementRef = inject(ElementRef);
  private cd = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);
  private translateService = inject(TranslateService);

  @Input({required: true})
  currentPage!: Nullable<HistoryBit>;

  isOpen = false;

  private _breadcrumbs$ = this.breadcrumbsService.breadcrumbs$.pipe(
    map(breadcrumbs => {
      if (breadcrumbs.length && breadcrumbs[0].isRerouteFromHistory) {
        breadcrumbs.unshift({
          uri: null,
          name: 'Historie',
          isRerouteFromHistory: false,
          isAwaitingAlias: false,
        });
      }
      return breadcrumbs;
    }),
  );

  breadcrumbLinks$: Observable<Breadcrumb[]> = this._breadcrumbs$.pipe(
    map(breadcrumbs => {
      const startIndex = breadcrumbs.length - BREADCRUMB_VISIBLE_ITEMS_COUNT;
      return breadcrumbs.length > 1 ? breadcrumbs.slice(startIndex < 0 ? 0 : startIndex, breadcrumbs.length - 1) : [];
    }),
  );

  currentBreadcrumb$: Observable<Nullable<Breadcrumb>> = this._breadcrumbs$.pipe(
    map(breadcrumbs => breadcrumbs.length ? breadcrumbs[breadcrumbs.length - 1] : null),
  );

  restBreadcrumbs$: Observable<BreadcrumbOption[]> = this._breadcrumbs$.pipe(
    map(breadcrumbs => {
      const endIndex = breadcrumbs.length - BREADCRUMB_VISIBLE_ITEMS_COUNT;
      return breadcrumbs.slice(0, endIndex < 0 ? 0 : endIndex);
    }),
    map(breadcrumbs => [...breadcrumbs].reverse()),
    map(breadcrumbs => breadcrumbs.map((b, i) => ({
      value: i as Nullable<number>,
      label: b.name,
      disabled: isNil(b.uri),
      icon: b.objectClass ? getObjectIcon(b.objectClass)! : undefined,
      data: {
        breadcrumb: b,
      }
    }))),
  );

  breadcrumbName(breadcrumb: Breadcrumb): string {
    if (breadcrumb.nameParts && breadcrumb.nameParts.length > 1) {
      const translatedParts: string[] = breadcrumb.nameParts ? breadcrumb.nameParts.filter(Boolean).map(part => this.translateService.instant(part)) : [];
      return translatedParts.join(' ');
    } else {
      return this.translateService.instant(breadcrumb.name);
    }
  }

  get maxBreadcrumbWidth(): number {
    // 45 is width + padding of restBreadcrumbs and favorite page buttons
    return (this.elementRef.nativeElement.offsetWidth - (2 * 45)) / BREADCRUMB_VISIBLE_ITEMS_COUNT;
  }

  private _elementWidth$ = new Subject<number>();

  readonly getObjectIcon = getObjectIcon;

  ngOnInit() {
    this._elementWidth$.pipe(
      throttleTime(100),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(_ => {
      this.cd.detectChanges();
    });
  }

  ngDoCheck() {
    this._elementWidth$.next(this.elementRef.nativeElement.offsetWidth);
  }

  breadcrumbOptionSelected(breadcrumbOptionSelection: BreadcrumbOption[]) {
    this.navigateToBreadcrumb(breadcrumbOptionSelection[0].data!.breadcrumb);
  }

  navigateToBreadcrumb(breadcrumb: Breadcrumb) {
    this.router.navigateByUrl(breadcrumb.uri!);
  }
}
