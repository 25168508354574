import {inject, Injectable} from '@angular/core';
import {SubjectObjectRelationCreateDto, SubjectRecordCreateOrUpdateDto, SubjectRecordDto, UpdateRelationWithSubjectDto} from '|api/commons';
import {ApiRelatedObjectService, ApiSubjectRecordService} from '|api/subject-register';
import {Observable, of, switchMap} from 'rxjs';
import {SKIP_ERROR_DIALOG} from '../../../core/error-handling/http-errors';
import {ConsignmentWithDuplicateResolveService} from '../received-document-form/consignment-with-duplicate-resolve.service';
import {SubjectDuplicateResolveDialogResult, SubjectReplacementConfig} from '../model/subjects.model';

export enum CreateOrUpdateSubjectAction {
  CREATE_OR_UPDATE_SUBJECT = 'CREATE_OR_UPDATE_SUBJECT_ONLY',
  UPDATE_SUBJECT_OBJECT_RELATION = 'UPDATE_SUBJECT_OBJECT_RELATION',
}

export interface CreateOrUpdateSubjectServiceDto {
  subject: SubjectRecordCreateOrUpdateDto,
  createRelation?: SubjectObjectRelationCreateDto,
  updateRelation?: UpdateRelationWithSubjectDto,
}

@Injectable({
  providedIn: 'root',
})
export class SubjectCreateOrUpdateWithDuplicateResolveService {

  private apiSubjectRecordService = inject(ApiSubjectRecordService);
  private apiRelatedObjectService = inject(ApiRelatedObjectService);
  private consignmentWithDuplicateResolveService = inject(ConsignmentWithDuplicateResolveService);

  private getSubjectRecordCreateOrUpdateRecord(body: SubjectRecordCreateOrUpdateDto): Observable<SubjectRecordDto> {
    body.enrichMode = true;
    return this.apiSubjectRecordService.subjectRecordCreateOrUpdateRecord({body}, SKIP_ERROR_DIALOG);
  }

  private getSubjectRecordUpdateRelationWithSubject(body: UpdateRelationWithSubjectDto): Observable<SubjectRecordDto> {
    return this.apiSubjectRecordService.subjectRecordUpdateRelationWithSubject({body}, SKIP_ERROR_DIALOG);
  }

  private subjectActionEnumToReq$(action: CreateOrUpdateSubjectAction, callParams: {
    body: CreateOrUpdateSubjectServiceDto
  }): Observable<SubjectRecordDto | void> {
    switch (action) {
      case CreateOrUpdateSubjectAction.CREATE_OR_UPDATE_SUBJECT:
        return this.getSubjectRecordCreateOrUpdateRecord(callParams.body.subject);
      case CreateOrUpdateSubjectAction.UPDATE_SUBJECT_OBJECT_RELATION:
        return this.getSubjectRecordUpdateRelationWithSubject(callParams.body.updateRelation!);
    }
  }

  createOrUpdateSubjectWithDuplicateResolve(action: CreateOrUpdateSubjectAction,
                                            initialCallParams: CreateOrUpdateSubjectServiceDto,
                                            objectDetailId?: Nullable<number>,
                                            subjectReplacementConfig?: SubjectReplacementConfig,
  ): Observable<Nullable<SubjectRecordDto | SubjectRecordCreateOrUpdateDto>> {
    return this.consignmentWithDuplicateResolveService.createOrUpdateWithDuplicateResolve(
      this.subjectActionEnumToReq$(action, {body: initialCallParams}),
      initialCallParams.subject,
      subjectReplacementConfig
    )
      .pipe(switchMap(createOrUpdateAttemptResult => {
        if (!createOrUpdateAttemptResult) {
          return of(null); // no result means closing the duplicate dialog without any action
        }
        if (createOrUpdateAttemptResult instanceof SubjectDuplicateResolveDialogResult) {
          if (createOrUpdateAttemptResult.forceCreateOrUpdate) {
            if (objectDetailId) {
              const callParamsWithForceMode = {...initialCallParams};
              callParamsWithForceMode.subject.forceMode = true;

              return this.subjectActionEnumToReq$(action, {body: initialCallParams});
            }
            else {
              return of(initialCallParams.subject);
            }
          }
          else {
            if (createOrUpdateAttemptResult.selectedSubject!.id) {
              return this.apiRelatedObjectService.relatedObjectLinkObjectsToSubject(
                {
                  subjectId: createOrUpdateAttemptResult.selectedSubject!.id!,
                  body: [initialCallParams.createRelation!],
                },
                SKIP_ERROR_DIALOG
              );
            }
            else {
              const callParamsForRecursion = {...initialCallParams};
              callParamsForRecursion.subject = createOrUpdateAttemptResult.selectedSubject!;
              if (initialCallParams.createRelation) {
                callParamsForRecursion.subject.objectRelations = [initialCallParams.createRelation];
              }
              return this.createOrUpdateSubjectWithDuplicateResolve(action, callParamsForRecursion, objectDetailId, subjectReplacementConfig);
            }
          }
        }
        else {
          return of(createOrUpdateAttemptResult);
        }
      }));
  }
}
