/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';

import { CustomBoolFieldDto } from '../../models/custom-bool-field-dto';
import { CustomDateFieldDto } from '../../models/custom-date-field-dto';
import { CustomDateTimeFieldDto } from '../../models/custom-date-time-field-dto';
import { CustomEnumFieldDto } from '../../models/custom-enum-field-dto';
import { CustomFieldDto } from '../../models/custom-field-dto';
import { CustomNumericFieldDto } from '../../models/custom-numeric-field-dto';
import { CustomTextFieldDto } from '../../models/custom-text-field-dto';

export interface CustomFieldOauthFindAll$Params {
}

export function customFieldOauthFindAll(http: HttpClient, rootUrl: string, params?: CustomFieldOauthFindAll$Params|null|undefined, context?: HttpContext): Observable<StrictHttpResponse<Array<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>>> {
  const rb = new RequestBuilder(rootUrl, customFieldOauthFindAll.PATH, 'get');
  if (params) {
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<Array<(CustomFieldDto | CustomBoolFieldDto | CustomDateFieldDto | CustomDateTimeFieldDto | CustomEnumFieldDto | CustomNumericFieldDto | CustomTextFieldDto)>>;
    })
  );
}

customFieldOauthFindAll.PATH = '/oauth/custom-field/find-all';