export enum DemoRoute {
  TEMPLATE = 'template',
  BLANK_PAGE_TEMPLATE = 'blank-page-template',
  FORM_PAGE_TEMPLATE = 'form-page-template',
  TABLE_PAGE_TEMPLATE = 'table-page-template',
  TREE_TABLE_PAGE_TEMPLATE = 'tree-table-page-template',
  MODAL_PAGE_TEMPLATE = 'modal-page-template',
  GALLERY = 'gallery',
  IDT = 'idt',
  HIGHLIGHTS = 'highlights',
  ICONS = 'icons',
  NOTIFICATIONS = 'notifications',
}
