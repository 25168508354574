import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczModalRef} from '@icz/angular-modal';


@Component({
  selector: 'icz-no-official-content-dialog',
  templateUrl: './no-official-content-dialog.component.html',
  styleUrls: ['./no-official-content-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoOfficialContentDialogComponent {

  loadingService = inject(LoadingIndicatorService);
  modalRef = inject(IczModalRef);

  submit() {
    this.modalRef.close(true);
  }

  cancel() {
    this.modalRef.close(null);
  }

}
