@if (statisticsConfig) {
  <icz-trend-counter
    [trendData]="trendWidgetData"
    [statisticsConfig]="statisticsConfig"
    [isTrendLoading]="(loadingIndicatorService.isLoading$(this) | async)!"
    class="block"
    (goToDetailClicked)="goToDetailClicked.emit($event)"
    (periodChanged)="periodChanged($event)">
  </icz-trend-counter>
}
