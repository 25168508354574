import {inject, Injectable} from '@angular/core';
import {MessageType, SImplicitlyStringifiable, ToastService} from '../../../components/notifications/toast.service';
import {z} from 'zod';
import {InternalNotificationKey} from '|api/notification';

export const SStorageUnitTemplateData = z.object({
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.NAME]: SImplicitlyStringifiable,
  [InternalNotificationKey.STORAGE_UNIT_NUMBER]: SImplicitlyStringifiable,
});
export type StorageUnitTemplateData = z.infer<typeof SStorageUnitTemplateData>;

export const SStorageUnitEntityTemplateData = z.object({
  [InternalNotificationKey.ENTITY_TYPE]: SImplicitlyStringifiable,
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.SUBJECT]: SImplicitlyStringifiable,
  [InternalNotificationKey.REF_NUMBER]: SImplicitlyStringifiable,
});
export type StorageUnitEntityTemplateData = z.infer<typeof SStorageUnitEntityTemplateData>;

export const SStorageUnitBulkAactionTemplateData = z.object({
  [InternalNotificationKey.COUNT]: SImplicitlyStringifiable,
});
export type StorageUnitBulkAactionTemplateData = z.infer<typeof SStorageUnitBulkAactionTemplateData>;

export const SStorageUnitFillerTemplateData = z.object({
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
});
export type StorageUnitFillerTemplateData = z.infer<typeof SStorageUnitFillerTemplateData>;

export enum StorageUnitToastType {
  STORAGE_UNIT_CREATE_SUCCESS = 'STORAGE_UNIT_CREATE_SUCCESS',
  STORAGE_UNIT_INSERT_STARTED = 'STORAGE_UNIT_INSERT_STARTED',
  STORAGE_UNIT_INSERT_SUCCESS = 'STORAGE_UNIT_INSERT_SUCCESS',
  STORAGE_UNIT_INSERT_ERROR = 'STORAGE_UNIT_INSERT_ERROR',
  STORAGE_UNIT_MOVE_STARTED = 'STORAGE_UNIT_MOVE_STARTED',
  STORAGE_UNIT_MOVE_SUCCESS = 'STORAGE_UNIT_MOVE_SUCCESS',
  STORAGE_UNIT_MOVE_ERROR = 'STORAGE_UNIT_MOVE_ERROR',
  STORAGE_UNIT_WITHDRAW_STARTED = 'STORAGE_UNIT_WITHDRAW_STARTED',
  STORAGE_UNIT_WITHDRAW_SUCCESS = 'STORAGE_UNIT_WITHDRAW_SUCCESS',
  STORAGE_UNIT_WITHDRAW_ERROR = 'STORAGE_UNIT_WITHDRAW_ERROR',
  STORAGE_UNIT_BLOCK_STARTED = 'STORAGE_UNIT_BLOCK_STARTED',
  STORAGE_UNIT_UNBLOCK_STARTED = 'STORAGE_UNIT_UNBLOCK_STARTED',
  STORAGE_UNIT_DELETE_STARTED = 'STORAGE_UNIT_DELETE_STARTED',
  STORAGE_UNIT_BLOCK_SUCCESS = 'STORAGE_UNIT_BLOCK_SUCCESS',
  STORAGE_UNIT_BLOCK_FAILED = 'STORAGE_UNIT_BLOCK_FAILED',
  STORAGE_UNIT_UNBLOCK_SUCCESS = 'STORAGE_UNIT_UNBLOCK_SUCCESS',
  STORAGE_UNIT_UNBLOCK_FAILED = 'STORAGE_UNIT_UNBLOCK_FAILED',
  STORAGE_UNIT_DELETE_SUCCESS = 'STORAGE_UNIT_DELETE_SUCCESS',
  STORAGE_UNIT_DELETE_FAILED = 'STORAGE_UNIT_DELETE_FAILED',
  STORAGE_UNIT_FILLER_CREATED = 'STORAGE_UNIT_FILLER_CREATED',
  STORAGE_UNIT_FILLER_CREATE_FAILED = 'STORAGE_UNIT_FILLER_CREATE_FAILED',
  STORAGE_UNIT_FILLER_UPDATED = 'STORAGE_UNIT_FILLER_UPDATED',
  STORAGE_UNIT_FILLER_UPDATE_ERROR = 'STORAGE_UNIT_FILLER_UPDATE_ERROR',
  STORAGE_UNIT_FILLER_DELETE_SUCCESS = 'STORAGE_UNIT_FILLER_DELETE_SUCCESS',
  STORAGE_UNIT_FILLER_DELETE_ERROR = 'STORAGE_UNIT_FILLER_DELETE_ERROR',
  STORAGE_UNIT_FILLER_SUCCESS = 'STORAGE_UNIT_FILLER_SUCCESS',
  STORAGE_UNIT_FILLER_ERROR = 'STORAGE_UNIT_FILLER_ERROR',
}

export const SStorageUnitToastData = z.object({
  [InternalNotificationKey.ID]: SImplicitlyStringifiable,
  [InternalNotificationKey.STORAGE_UNIT_NUMBER]: SImplicitlyStringifiable,
});

export type StorageUnitToastData = z.infer<typeof SStorageUnitToastData>;

@Injectable({
  providedIn: 'root',
})
export class StorageUnitToastService {

  private toastService = inject(ToastService);

  dispatchSimpleInfoToast(storageToastType: StorageUnitToastType) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, storageToastType);
  }

  dispatchSimpleWarningToast(storageToastType: StorageUnitToastType) {
    this.toastService.dispatchSimpleToast(MessageType.WARNING, storageToastType);
  }

  dispatchSimpleErrorToast(storageToastType: StorageUnitToastType) {
    this.toastService.dispatchSimpleToast(MessageType.ERROR, storageToastType);
  }

  dispatchStorageUnitCreated(toastData: StorageUnitToastData) {
    this.toastService.dispatchSimpleToast(MessageType.INFO, StorageUnitToastType.STORAGE_UNIT_CREATE_SUCCESS, toastData, SStorageUnitToastData);
  }
}
