/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { ApiUserService } from './services/api-user.service';
import { ApiPropertyValueService } from './services/api-property-value.service';
import { ApiOrganizationService } from './services/api-organization.service';
import { ApiTeleFaxNodesOauthService } from './services/api-tele-fax-nodes-oauth.service';
import { ApiStorageUnitFillerConfigurationOauthService } from './services/api-storage-unit-filler-configuration-oauth.service';
import { ApiSheetNodesOauthService } from './services/api-sheet-nodes-oauth.service';
import { ApiPropertyValueOauthService } from './services/api-property-value-oauth.service';
import { ApiPortalNodesOauthService } from './services/api-portal-nodes-oauth.service';
import { ApiOfficeDeskNodesOauthService } from './services/api-office-desk-nodes-oauth.service';
import { ApiLabelTemplateService } from './services/api-label-template.service';
import { ApiIszrCertificateService } from './services/api-iszr-certificate.service';
import { ApiIssdOauthService } from './services/api-issd-oauth.service';
import { ApiIssdImportConfigurationOauthService } from './services/api-issd-import-configuration-oauth.service';
import { ApiEpdzPropertiesOauthService } from './services/api-epdz-properties-oauth.service';
import { ApiEmailUploadNodesOauthService } from './services/api-email-upload-nodes-oauth.service';
import { ApiEmailNotificationRulesOauthService } from './services/api-email-notification-rules-oauth.service';
import { ApiEmailNotificationByUserOauthService } from './services/api-email-notification-by-user-oauth.service';
import { ApiEmailNodesOauthService } from './services/api-email-nodes-oauth.service';
import { ApiDataboxNodesOauthService } from './services/api-databox-nodes-oauth.service';
import { ApiCzechPointConfigurationOauthService } from './services/api-czech-point-configuration-oauth.service';
import { ApiCustomFieldOauthService } from './services/api-custom-field-oauth.service';
import { ApiBasicSettingsOauthService } from './services/api-basic-settings-oauth.service';
import { ApiPropertyDefinitionService } from './services/api-property-definition.service';
import { ApiIssdTypeService } from './services/api-issd-type.service';
import { ApiDictionaryService } from './services/api-dictionary.service';
import { ApiPasswordService } from './services/api-password.service';
import { ApiDistributionNodeOauthService } from './services/api-distribution-node-oauth.service';
import { ApiTransactionEventService } from './services/api-transaction-event.service';
import { ApiEnvironmentService } from './services/api-environment.service';
import { ApiPropertyDefinitonOauthService } from './services/api-property-definiton-oauth.service';
import { ApiEpdzLinkedNodeOauthService } from './services/api-epdz-linked-node-oauth.service';
import { ApiDictionaryOauthService } from './services/api-dictionary-oauth.service';
import { ApiIssdService } from './services/api-issd.service';
import { ApiIssdImportConfigurationService } from './services/api-issd-import-configuration.service';
import { ApiEpdzPropertiesService } from './services/api-epdz-properties.service';
import { ApiApplicationService } from './services/api-application.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    ApiUserService,
    ApiPropertyValueService,
    ApiOrganizationService,
    ApiTeleFaxNodesOauthService,
    ApiStorageUnitFillerConfigurationOauthService,
    ApiSheetNodesOauthService,
    ApiPropertyValueOauthService,
    ApiPortalNodesOauthService,
    ApiOfficeDeskNodesOauthService,
    ApiLabelTemplateService,
    ApiIszrCertificateService,
    ApiIssdOauthService,
    ApiIssdImportConfigurationOauthService,
    ApiEpdzPropertiesOauthService,
    ApiEmailUploadNodesOauthService,
    ApiEmailNotificationRulesOauthService,
    ApiEmailNotificationByUserOauthService,
    ApiEmailNodesOauthService,
    ApiDataboxNodesOauthService,
    ApiCzechPointConfigurationOauthService,
    ApiCustomFieldOauthService,
    ApiBasicSettingsOauthService,
    ApiPropertyDefinitionService,
    ApiIssdTypeService,
    ApiDictionaryService,
    ApiPasswordService,
    ApiDistributionNodeOauthService,
    ApiTransactionEventService,
    ApiEnvironmentService,
    ApiPropertyDefinitonOauthService,
    ApiEpdzLinkedNodeOauthService,
    ApiDictionaryOauthService,
    ApiIssdService,
    ApiIssdImportConfigurationService,
    ApiEpdzPropertiesService,
    ApiApplicationService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
