import {
  OwnConsignmentStatus,
  RegistryOfficeTransferState,
  SettlementType,
  StorageUnitFillerClassificationAttribute,
  StorageUnitFillerState,
  StorageUnitForm
} from '|api/commons';
import {TemplatePoolService} from '@icz/angular-essentials';
import {SharedTemplateCollectionComponent} from '../shared-template-collection/shared-template-collection.component';
import {CodebookFilterDefinition, ColumnDefinition, FilterType} from '@icz/angular-table';
import {TreeItemSelectionStrategy} from '@icz/angular-form-elements';
import {DocumentFiltersDataService} from './components/document-filters/document-filters-data.service';
import {
  getDisposalScheduleFilterConfig,
  getEntityClassFilterConfig,
  setupEntityClassFilterSubfilters
} from './components/document-filters/document-filter-selectors';
import {
  DocumentView,
  RegistryOfficeTransferView,
  StorageUnitView
} from '../document-toolbar/services/toolbar-common.utils';
import {enumToOptions} from '../../../core/services/data-mapping.utils';
import {shareReplay} from 'rxjs';
import {
  Essl9972RegistryOfficeTransferActivityState
} from '|api-draft/core/models/essl-9972-registry-office-transfer-activity-state';
import {
  RegistryOfficeTransferIncidentOrigin
} from '|api-draft/core/models/essl-9972-search-record-source-registry-office-transfer-activity-dto';


export type DocumentsTableView = DocumentView | StorageUnitView | RegistryOfficeTransferView;

export enum DocumentsTableColumn {
  SELECTION = 'selection',
  OBJECT_CLASS = 'objectClass',
  OBJECT_CLASS_FULL = 'objectClassFull',
  REF_NUMBER = 'refNumber',
  REF_NUMBER_ALL = 'refNumberAll',
  SUBJECT = 'subject',
  DIGITAL_COMPONENT_COUNT = 'digitalComponentCount',
  HOLDERS_FUNCTIONAL_POSITION_IDS = 'holderFunctionalPositionIds',
  ENTITY_STATE = 'entityState',
  REPRESENTING_SUBJECT = 'representingSubject',
  DESCRIPTION = 'description',
  CREATED_AT = 'auditInfo.createdAt',
  MODIFIED_AT = 'auditInfo.modifiedAt',
  CREATION_DATE = 'creationDate',
  IS_CONSTRAINED_VIEW = 'isConstrainedView',
  IDENTIFIER = 'uid.uid',
  RELEVANCE = 'relevance',
  RESOLUTION_DATE = 'resolutionDate',
  OWNER_FUNCTIONAL_POSITION_ID = 'ownerFunctionalPositionId',
  OWNER_ORG_UNIT_ID = 'ownerOrgUnitId',
  NOTE = 'note',
  OBJECT_FORM = 'objectForm',
  DISPOSAL_SCHEDULE_ID = 'disposalScheduleId',
  SECURITY_CATEGORY_ID = 'securityCategoryId',
  DOCUMENT_TYPE_ID = 'documentTypeId',
  FILE_TYPE_ID = 'fileTypeId',
  ENTITY_CLASS_ID = 'entityClassId',
  FILE_INSERTION_DATE = 'documentPutInGivenFileAt',
  SETTLEMENT_DATE = 'settlement.settlementDate',
  KEYWORD_IDS = 'keywordIds',
  LABEL = 'label',
  EXECUTION_DATE = 'executionDate',
  CREATED_BY = 'auditInfo.createdByFunctionalPositionId',
  MODIFIED_BY = 'auditInfo.modifiedByFunctionalPositionId',
  DIGITAL_COMPONENT_LAST_TYPE_FLAG = 'digitalComponentLastTypeFlag',
  REF_NUMBER_HISTORY = 'refNumberHistory',
  COMPONENT_TYPE = 'components.componentType',
  COMPONENT_LABEL = 'components.label',
  COMPONENT_LABEL_FULLTEXT = 'components.label_fulltext',
  COMPONENT_DESCRIPTION = 'components.description',
  COMPONENT_PUID = 'components.digitalComponentLastPuid',
  COMPONENT_CONTENT = 'components.content_fulltext',
  SENDER_REF_NR = 'senderRefNr',
  COMPONENT_PHYSICAL_LOCATION = 'componentPhysicalLocation',
  FILING_REGISTER_ID = 'filingRegisterId',
  FILING_REGISTER_REG_DATE = 'filingRegisterRegDate',
  SETTLEMENT_TYPE = 'settlement.type',
  EMPOWERMENT = 'empowerment',
  RELATED_SUBJECTS_IDS = 'relatedSubjects',
  CONSIGNMENT_UID = 'consignments.uid.uid',
  CONSIGNMENT_DELIVERY_TYPE_ID = 'consignments.deliveryTypeId',
  CONSIGNMENT_SUBJECT = 'consignments.subject',
  CONSIGNMENT_DISPATCH_DATE = 'consignments.dispatchDate',
  CONSIGNMENT_DELIVERY_DATE = 'consignments.deliveryDate',
  CONSIGNMENT_EXTERNAL_ID = 'consignments.externalId',
  CONSIGNMENT_STATUS = 'consignments.status',
  CONSIGNMENT_SUBJECT_RECORD = 'subjectSenderAddressee',
  CONSIGNMENT_DELIVERY_SERVICE_COMBINATION_ID = 'consignments.deliveryServiceCombinationId',
  CONSIGNMENT_DISTRIBUTOR_ID = 'consignments.distributorId',
  CONSIGNMENT_POSTING_NUMBER = 'consignments.postingNumber',
  CONSIGNMENT_SUBJECT_ADDRESS = 'consignments.subjectAddress',
  CONSIGNMENT_SUBJECT_EMAIL = 'consignments.subjectEmail',
  CONSIGNMENT_SUBJECT_DATABOX = 'consignments.subjectDatabox',
  CONSIGNMENT_OUTBOUND_IDENTIFIER = 'consignments.outboundConsignmentIdentifier',
  CONSIGNMENT_ORIGINAL_SENDER = 'consignments.consignmentOriginalSender',
  FILE_INSERTED_DOCUMENT = 'fileId',
  SHARE_PERMISSIONS = 'shareinfo.permissionSetLevel',
  SHARE_DATE = 'shareinfo.shareDate',
  ISSD_ID = 'issdId',
  ISSD_TASK = 'issdTask',
  HANDOVER_ACTIVITY_ID = 'handoverActivityId',
  HANDOVER_ACTIVITY_TARGET_FP_ID = 'handoverActivityTargetFpId',
  HANDOVER_ACTIVITY_REJECT_REASON = 'handoverActivityRejectReason',
  RELATED_EVIDENCE = 'relatedEvidence',
  ORDER_NUMBER_IN_GIVEN_FILE = 'orderNumberInGivenFile',
  CLOSURE_DATE = 'closureDate',
  DISPOSAL_YEAR = 'disposalYear',
  STORAGE_UNIT_NAME = 'name',
  STORAGE_UNIT_NUMBER = 'storageUnitNumber',
  STORAGE_UNIT_CONTENT_BLOCKED = 'contentBlocked',
  PHYSICAL_LOCATION = 'physicalLocation',
  EXPECTED_DISPOSAL_YEAR = 'expectedDisposalYear',
  EXPECTED_CONTENT_DISPOSAL_YEAR = 'expectedContentDisposalYear',
  CONTENT_COUNT = 'contentCount',
  REGISTRY_OFFICE_ID = 'registryOfficeId',
  REGISTRY_OFFICE_TRANSFER_STATE = 'registryOfficeTransferState',
  HANDED_OVER_AT = 'handedOverAt',
  REGISTRY_OFFICE_TRANSFER_ACTIVITY_STATE = 'registryOfficeTransferActivityState',
  TAKEN_OVER_AT = 'takenOverAt',
  HANDED_OVER_BY_FUNCTIONAL_POSITION_ID = 'handedOverByFunctionalPositionId',
  ENTITY_NAME = 'entityName',
  UNRESOLVED_INCIDENTS_COUNT = 'unresolvedIncidentsCount',
  INCIDENTS_ORIGIN = 'incidentsOrigin',
  HAS_INCIDENTS = 'hasIncidents',
  YEAR_OF_RETENTION_PERIOD_START = 'yearOfRetentionPeriodStart',
  TRIGGER_EVENT_CHECK_YEAR = 'triggerEventCheckYear',
  EXTERNAL_RETENTION_TRIGGER_IDS = 'externalRetentionTriggerIds',
  STORAGE_UNIT_FORM = 'storageUnitForm',
  STORAGE_UNIT_FILLER_ID = 'id',
  STORAGE_UNIT_FILLER_REJECTED_ENTITIES = 'statistics.entitiesRejectedCount',
  STORAGE_UNIT_FILLER_INSERTED_ENTITIES = 'statistics.entitiesInsertedCount',
  STORAGE_UNIT_FILLER_STORAGE_UNITS_EXPECTED = 'statistics.storageUnitsExpectedCount',
  STORAGE_UNIT_FILLER_STORAGE_UNITS_INSERTED = 'statistics.storageUnitsInsertedCount',
  STORAGE_UNIT_FILLER_STATE = 'storageUnitFillerState',
  STORAGE_UNIT_FILLER_FINAL_RUN = 'finalRun',
  STORAGE_UNIT_FILLER_SCHEDULED_RUN_AT = 'scheduledRunAt',
  STORAGE_UNIT_FILLER_LAST_RUN_AT = 'lastRunAt',
  STORAGE_UNIT_FILLER_CLASSIFICATION_ATTRIBUTES = 'classificationAttributes',
  STORAGE_UNIT_FILLER_ENFORCE_DISPOSAL_SCHEDULE = 'enforceStrictDisposalSchedule',
  STORAGE_UNIT_FILLER_ENFORCE_DISPOSAL_YEAR = 'enforceStrictDisposalYear',
  STORAGE_UNIT_FILLER_ENFORCE_ENTITY_CLASS = 'enforceStrictEntityClass',
  STORAGE_UNIT_FILLER_HUMAN_READABLE_FILTER = 'humanReadableFilter',
}

const defaultColumnsSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.IS_CONSTRAINED_VIEW,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.OBJECT_CLASS_FULL,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.ENTITY_STATE,
  DocumentsTableColumn.REPRESENTING_SUBJECT,
  DocumentsTableColumn.DESCRIPTION,
  DocumentsTableColumn.RESOLUTION_DATE,
  DocumentsTableColumn.CREATED_AT,
  DocumentsTableColumn.MODIFIED_AT,
  DocumentsTableColumn.LABEL,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.NOTE,
  DocumentsTableColumn.OBJECT_FORM,
  DocumentsTableColumn.DOCUMENT_TYPE_ID,
  DocumentsTableColumn.FILE_TYPE_ID,
  DocumentsTableColumn.ENTITY_CLASS_ID,
  DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
  DocumentsTableColumn.SECURITY_CATEGORY_ID,
  DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS,
  DocumentsTableColumn.EXECUTION_DATE,
  DocumentsTableColumn.CREATED_BY,
  DocumentsTableColumn.MODIFIED_BY,
  DocumentsTableColumn.KEYWORD_IDS,
  DocumentsTableColumn.EMPOWERMENT,
  DocumentsTableColumn.FILING_REGISTER_REG_DATE,
  DocumentsTableColumn.RELATED_EVIDENCE,
  DocumentsTableColumn.SETTLEMENT_DATE,
  DocumentsTableColumn.SETTLEMENT_TYPE,
  DocumentsTableColumn.CLOSURE_DATE,
  DocumentsTableColumn.OWNER_ORG_UNIT_ID,
  DocumentsTableColumn.TRIGGER_EVENT_CHECK_YEAR,
  DocumentsTableColumn.YEAR_OF_RETENTION_PERIOD_START,
  DocumentsTableColumn.COMPONENT_PHYSICAL_LOCATION,
];

const documentSelectorColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.IS_CONSTRAINED_VIEW,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.DESCRIPTION,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.FILING_REGISTER_REG_DATE,
  DocumentsTableColumn.RELATED_EVIDENCE,
];

const fileContentsColumnsSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.ORDER_NUMBER_IN_GIVEN_FILE,
  DocumentsTableColumn.IS_CONSTRAINED_VIEW,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.HAS_INCIDENTS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.REPRESENTING_SUBJECT,
  DocumentsTableColumn.FILE_INSERTION_DATE,
  DocumentsTableColumn.ENTITY_STATE,
  DocumentsTableColumn.DOCUMENT_TYPE_ID,
  DocumentsTableColumn.SETTLEMENT_DATE,
  DocumentsTableColumn.FILE_INSERTED_DOCUMENT,
  DocumentsTableColumn.FILING_REGISTER_REG_DATE,
  DocumentsTableColumn.RELATED_EVIDENCE,
  DocumentsTableColumn.IDENTIFIER,
];

const storageUnitContentsColumnsSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.HAS_INCIDENTS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.ENTITY_CLASS_ID,
  DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
  DocumentsTableColumn.DISPOSAL_YEAR,
  DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_STATE,
];

const manualSharedColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.ENTITY_STATE,
  // DocumentsTableColumn.SHARE_PERMISSIONS, // likely no support in near future
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  // DocumentsTableColumn.SHARE_DATE, // likely no support in near future
  DocumentsTableColumn.RESOLUTION_DATE,
];

const issdDocumentsColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.OBJECT_CLASS_FULL,
  DocumentsTableColumn.ISSD_TASK,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.ISSD_ID,
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.ENTITY_STATE,
  DocumentsTableColumn.REPRESENTING_SUBJECT,
  DocumentsTableColumn.DESCRIPTION,
  DocumentsTableColumn.RESOLUTION_DATE,
  DocumentsTableColumn.CREATED_AT,
  DocumentsTableColumn.MODIFIED_AT,
  DocumentsTableColumn.LABEL,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.NOTE,
  DocumentsTableColumn.OBJECT_FORM,
  DocumentsTableColumn.DOCUMENT_TYPE_ID,
  DocumentsTableColumn.FILE_TYPE_ID,
  DocumentsTableColumn.ENTITY_CLASS_ID,
  DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
  DocumentsTableColumn.SECURITY_CATEGORY_ID,
  DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS,
  DocumentsTableColumn.EXECUTION_DATE,
  DocumentsTableColumn.CREATED_BY,
  DocumentsTableColumn.MODIFIED_BY,
  DocumentsTableColumn.KEYWORD_IDS,
  DocumentsTableColumn.EMPOWERMENT,
  DocumentsTableColumn.FILING_REGISTER_REG_DATE,
  DocumentsTableColumn.RELATED_EVIDENCE,
];

const filingOfficeReceivedColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.DESCRIPTION,
  DocumentsTableColumn.CREATED_AT, // todo make it visible here
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.MODIFIED_AT,
  DocumentsTableColumn.FILING_REGISTER_REG_DATE,
  DocumentsTableColumn.RELATED_EVIDENCE,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_RECORD,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_DATE,
  DocumentsTableColumn.CREATED_BY,
  DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER,
  DocumentsTableColumn.SENDER_REF_NR,
  DocumentsTableColumn.CONSIGNMENT_EXTERNAL_ID,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_EMAIL,
];

const filingOfficeHandedOverColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.HANDOVER_ACTIVITY_ID,
  DocumentsTableColumn.HANDOVER_ACTIVITY_TARGET_FP_ID,
  DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS,
  DocumentsTableColumn.CREATED_AT,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.DESCRIPTION,
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.MODIFIED_AT,
  DocumentsTableColumn.FILING_REGISTER_REG_DATE,
  DocumentsTableColumn.RELATED_EVIDENCE,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_RECORD,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_DATE,
  DocumentsTableColumn.CREATED_BY,
  DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER,
  DocumentsTableColumn.SENDER_REF_NR,
  DocumentsTableColumn.CONSIGNMENT_EXTERNAL_ID,
];

const filingOfficeRejectedColumnSet = [
  ...filingOfficeHandedOverColumnSet,
  DocumentsTableColumn.ENTITY_STATE,
  DocumentsTableColumn.HANDOVER_ACTIVITY_REJECT_REASON,
];

const ownershipTransferDisplayedColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.ENTITY_STATE,
  DocumentsTableColumn.CREATED_AT,
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS,
];

export const globalSearchCommonFilters = [
  DocumentsTableColumn.IS_CONSTRAINED_VIEW, // "eye" column
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.DESCRIPTION,
  DocumentsTableColumn.NOTE,
  DocumentsTableColumn.OBJECT_FORM,
  DocumentsTableColumn.LABEL,
  DocumentsTableColumn.COMPONENT_PHYSICAL_LOCATION,
  DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
  DocumentsTableColumn.ENTITY_CLASS_ID,
  DocumentsTableColumn.SECURITY_CATEGORY_ID,
  DocumentsTableColumn.KEYWORD_IDS,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.CREATED_AT,
  DocumentsTableColumn.MODIFIED_AT,
  DocumentsTableColumn.ENTITY_STATE,
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS,
  DocumentsTableColumn.CREATED_BY,
  DocumentsTableColumn.MODIFIED_BY,
  DocumentsTableColumn.RESOLUTION_DATE,
  DocumentsTableColumn.SETTLEMENT_DATE,
  DocumentsTableColumn.SETTLEMENT_TYPE,
  DocumentsTableColumn.EMPOWERMENT,
  DocumentsTableColumn.RELATED_SUBJECTS_IDS,
  DocumentsTableColumn.RELATED_EVIDENCE,
];

export const globalSearchDocumentOnlyFilters = [
  DocumentsTableColumn.SENDER_REF_NR,
  DocumentsTableColumn.DOCUMENT_TYPE_ID,
  DocumentsTableColumn.FILING_REGISTER_ID,
  DocumentsTableColumn.FILING_REGISTER_REG_DATE,
  DocumentsTableColumn.EXECUTION_DATE,
  DocumentsTableColumn.COMPONENT_TYPE,
  DocumentsTableColumn.COMPONENT_LABEL,
  DocumentsTableColumn.COMPONENT_DESCRIPTION,
  DocumentsTableColumn.COMPONENT_PUID,
  DocumentsTableColumn.CONSIGNMENT_UID,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT,
  DocumentsTableColumn.CONSIGNMENT_DISPATCH_DATE,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_DATE,
  DocumentsTableColumn.CONSIGNMENT_STATUS,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_RECORD,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_SERVICE_COMBINATION_ID,
  DocumentsTableColumn.CONSIGNMENT_DISTRIBUTOR_ID,
  DocumentsTableColumn.CONSIGNMENT_POSTING_NUMBER,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_ADDRESS,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_EMAIL,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_DATABOX,
  DocumentsTableColumn.CONSIGNMENT_OUTBOUND_IDENTIFIER,
  DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER,
];

export const globalSearchFileOnlyFilters = [
  DocumentsTableColumn.FILE_TYPE_ID,
];

export const globalSearchAllFilters = [...new Set([
  ...globalSearchCommonFilters,
  ...globalSearchDocumentOnlyFilters,
  ...globalSearchFileOnlyFilters
])];

// globalSearchColumnsSet should contain at least globalSearchAllFilters
export const globalSearchColumnsSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.IS_CONSTRAINED_VIEW, // "eye" column
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.REF_NUMBER_ALL,
  DocumentsTableColumn.SUBJECT,
  DocumentsTableColumn.REPRESENTING_SUBJECT,
  DocumentsTableColumn.ENTITY_STATE,
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS,
  DocumentsTableColumn.CREATED_BY,
  DocumentsTableColumn.MODIFIED_BY,
  DocumentsTableColumn.CREATED_AT,
  DocumentsTableColumn.RESOLUTION_DATE,
  DocumentsTableColumn.MODIFIED_AT,
  DocumentsTableColumn.OBJECT_FORM,
  DocumentsTableColumn.DESCRIPTION,
  DocumentsTableColumn.NOTE,
  DocumentsTableColumn.LABEL,
  DocumentsTableColumn.KEYWORD_IDS,
  DocumentsTableColumn.ENTITY_CLASS_ID,
  DocumentsTableColumn.DOCUMENT_TYPE_ID,
  DocumentsTableColumn.FILE_TYPE_ID,
  DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
  DocumentsTableColumn.SECURITY_CATEGORY_ID,
  DocumentsTableColumn.RELEVANCE,

  // hidden columns, used for passing in parameters from advanced criteria page
  DocumentsTableColumn.EXECUTION_DATE,
  DocumentsTableColumn.DIGITAL_COMPONENT_LAST_TYPE_FLAG,
  DocumentsTableColumn.REF_NUMBER_HISTORY,
  DocumentsTableColumn.COMPONENT_TYPE,
  DocumentsTableColumn.COMPONENT_LABEL,
  DocumentsTableColumn.COMPONENT_DESCRIPTION,
  DocumentsTableColumn.COMPONENT_PUID,
  DocumentsTableColumn.COMPONENT_CONTENT,
  DocumentsTableColumn.SENDER_REF_NR,
  DocumentsTableColumn.COMPONENT_PHYSICAL_LOCATION,
  DocumentsTableColumn.FILING_REGISTER_ID,
  DocumentsTableColumn.FILING_REGISTER_REG_DATE,
  DocumentsTableColumn.SETTLEMENT_DATE,
  DocumentsTableColumn.SETTLEMENT_TYPE,
  DocumentsTableColumn.EMPOWERMENT,
  DocumentsTableColumn.RELATED_SUBJECTS_IDS,
  DocumentsTableColumn.CONSIGNMENT_UID,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT,
  DocumentsTableColumn.CONSIGNMENT_DISPATCH_DATE,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_DATE,
  DocumentsTableColumn.CONSIGNMENT_STATUS,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_RECORD,
  DocumentsTableColumn.CONSIGNMENT_DELIVERY_SERVICE_COMBINATION_ID,
  DocumentsTableColumn.CONSIGNMENT_DISTRIBUTOR_ID,
  DocumentsTableColumn.CONSIGNMENT_POSTING_NUMBER,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_ADDRESS,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_EMAIL,
  DocumentsTableColumn.CONSIGNMENT_SUBJECT_DATABOX,
  DocumentsTableColumn.CONSIGNMENT_OUTBOUND_IDENTIFIER,
  DocumentsTableColumn.ISSD_ID,
  DocumentsTableColumn.RELATED_EVIDENCE,
  DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER,
  DocumentsTableColumn.TRIGGER_EVENT_CHECK_YEAR,
  DocumentsTableColumn.YEAR_OF_RETENTION_PERIOD_START,
];

const storageUnitInsertColumnSet = [
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.STORAGE_UNIT_CONTENT_BLOCKED,
  DocumentsTableColumn.STORAGE_UNIT_NUMBER,
  DocumentsTableColumn.STORAGE_UNIT_NAME,
  DocumentsTableColumn.CREATED_AT,
  DocumentsTableColumn.CONTENT_COUNT,
  DocumentsTableColumn.EXPECTED_DISPOSAL_YEAR,
  DocumentsTableColumn.DISPOSAL_SCHEDULE_ID,
  DocumentsTableColumn.MODIFIED_AT,
  DocumentsTableColumn.ENTITY_CLASS_ID,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.PHYSICAL_LOCATION,
  DocumentsTableColumn.DISPOSAL_YEAR,
  DocumentsTableColumn.EXPECTED_CONTENT_DISPOSAL_YEAR,
];

const registryOfficeTransfersColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.ENTITY_NAME,
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.OWNER_ORG_UNIT_ID,
  DocumentsTableColumn.ENTITY_CLASS_ID,
  DocumentsTableColumn.REGISTRY_OFFICE_ID,
  DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_STATE,
  DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_ACTIVITY_STATE,
  DocumentsTableColumn.HANDED_OVER_AT,
  DocumentsTableColumn.TAKEN_OVER_AT,
  DocumentsTableColumn.HANDED_OVER_BY_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.UNRESOLVED_INCIDENTS_COUNT,
  DocumentsTableColumn.INCIDENTS_ORIGIN,
];

const registryOfficeTransfersWithIncidentsListingColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.OBJECT_CLASS,
  DocumentsTableColumn.HAS_INCIDENTS,
  DocumentsTableColumn.REF_NUMBER,
  DocumentsTableColumn.IDENTIFIER,
  DocumentsTableColumn.ENTITY_NAME,
  DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.OWNER_ORG_UNIT_ID,
  DocumentsTableColumn.ENTITY_CLASS_ID,
  DocumentsTableColumn.REGISTRY_OFFICE_ID,
  DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_STATE,
  DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_ACTIVITY_STATE,
  DocumentsTableColumn.HANDED_OVER_AT,
  DocumentsTableColumn.TAKEN_OVER_AT,
  DocumentsTableColumn.HANDED_OVER_BY_FUNCTIONAL_POSITION_ID,
  DocumentsTableColumn.UNRESOLVED_INCIDENTS_COUNT,
  DocumentsTableColumn.INCIDENTS_ORIGIN,
];

const storageUnitColumnSet = [
  DocumentsTableColumn.SELECTION,
  ...storageUnitInsertColumnSet,
  DocumentsTableColumn.STORAGE_UNIT_FORM,
];

const storageUnitFillerContentStorageUnitsColumnSet = [
  ...storageUnitInsertColumnSet,
  DocumentsTableColumn.STORAGE_UNIT_FORM,
];

const storageUnitFillerColumnSet = [
  DocumentsTableColumn.SELECTION,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_ID,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_STATE,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_REJECTED_ENTITIES,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_INSERTED_ENTITIES,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_STORAGE_UNITS_EXPECTED,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_STORAGE_UNITS_INSERTED,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_CLASSIFICATION_ATTRIBUTES,
  DocumentsTableColumn.CREATED_BY,
  DocumentsTableColumn.REGISTRY_OFFICE_ID,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_SCHEDULED_RUN_AT,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_LAST_RUN_AT,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_DISPOSAL_SCHEDULE,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_DISPOSAL_YEAR,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_ENTITY_CLASS,
  DocumentsTableColumn.STORAGE_UNIT_FILLER_HUMAN_READABLE_FILTER,
];

export const documentColumnNames: Record<DocumentsTableColumn, string> = {
  [DocumentsTableColumn.SELECTION]: 'Výběr',
  [DocumentsTableColumn.OBJECT_CLASS]: 'Druh',
  [DocumentsTableColumn.OBJECT_CLASS_FULL]: 'Druh objektu',
  [DocumentsTableColumn.REF_NUMBER]: 'ČJ/SZ', // Field both in database and Elastic
  [DocumentsTableColumn.REF_NUMBER_ALL]: 'ČJ/SZ', // Elastic specific field, used for filtering
  [DocumentsTableColumn.SUBJECT]: 'Věc',
  [DocumentsTableColumn.DIGITAL_COMPONENT_COUNT]: 'Počet komponent',
  [DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS]: 'Držitelé',
  [DocumentsTableColumn.ENTITY_STATE]: 'Stav',
  [DocumentsTableColumn.REPRESENTING_SUBJECT]: 'Reprezentující subjekt',
  [DocumentsTableColumn.DESCRIPTION]: 'Popis',
  [DocumentsTableColumn.CREATED_AT]: 'Datum založení',
  [DocumentsTableColumn.MODIFIED_AT]: 'Datum úpravy',
  [DocumentsTableColumn.IS_CONSTRAINED_VIEW]: 'Omezený detail',
  [DocumentsTableColumn.IDENTIFIER]: 'UID - Unikátní identifikátor',
  [DocumentsTableColumn.RELEVANCE]: 'Relevance',
  [DocumentsTableColumn.RESOLUTION_DATE]: 'Termín vyřízení',
  [DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID]: 'Zpracovatel',
  [DocumentsTableColumn.OWNER_ORG_UNIT_ID]: 'Útvar zpracovatele',
  [DocumentsTableColumn.NOTE]: 'Poznámka',
  [DocumentsTableColumn.OBJECT_FORM]: 'Forma',
  [DocumentsTableColumn.DISPOSAL_SCHEDULE_ID]: 'Skartační režim',
  [DocumentsTableColumn.DISPOSAL_YEAR]: 'Rok vyřazení',
  [DocumentsTableColumn.SECURITY_CATEGORY_ID]: 'Bezpečnostní kategorie',
  [DocumentsTableColumn.DOCUMENT_TYPE_ID]: 'Typ dokumentu',
  [DocumentsTableColumn.FILE_TYPE_ID]: 'Typ spisu',
  [DocumentsTableColumn.ENTITY_CLASS_ID]: 'Věcná skupina',
  [DocumentsTableColumn.FILE_INSERTION_DATE]: 'Zařazeno',
  [DocumentsTableColumn.SETTLEMENT_DATE]: 'Datum vyřízení',
  [DocumentsTableColumn.CLOSURE_DATE]: 'Datum uzavření',
  [DocumentsTableColumn.LABEL]: 'Značka',
  [DocumentsTableColumn.CREATION_DATE]: 'Založen',
  [DocumentsTableColumn.EXECUTION_DATE]: 'Právní moc',
  [DocumentsTableColumn.CREATED_BY]: 'Založil',
  [DocumentsTableColumn.MODIFIED_BY]: 'Změnil',
  [DocumentsTableColumn.DIGITAL_COMPONENT_LAST_TYPE_FLAG]: 'Formát souboru',
  [DocumentsTableColumn.REF_NUMBER_HISTORY]: 'Původní číslo jednací',
  [DocumentsTableColumn.KEYWORD_IDS]: 'Klíčová slova',
  [DocumentsTableColumn.COMPONENT_TYPE]: 'Druh předmětu/nosiče',
  [DocumentsTableColumn.COMPONENT_LABEL]: 'Název obsahu komponenty',
  [DocumentsTableColumn.COMPONENT_LABEL_FULLTEXT]: 'Název obsahu komponenty',
  [DocumentsTableColumn.COMPONENT_DESCRIPTION]: 'Popis komponenty',
  [DocumentsTableColumn.COMPONENT_CONTENT]: 'Obsah komponenty',
  [DocumentsTableColumn.COMPONENT_PUID]: 'Formát obsahu komponenty',
  [DocumentsTableColumn.SENDER_REF_NR]: 'ČJ odesílatele',
  [DocumentsTableColumn.CONSIGNMENT_EXTERNAL_ID]: 'Externí identifikátor zásilky',
  [DocumentsTableColumn.COMPONENT_PHYSICAL_LOCATION]: 'Uložení',
  [DocumentsTableColumn.FILING_REGISTER_ID]: 'Evidenční pomůcka',
  [DocumentsTableColumn.FILING_REGISTER_REG_DATE]: 'Poslední datum evidence',
  [DocumentsTableColumn.SETTLEMENT_TYPE]: 'Způsob vyřízení',
  [DocumentsTableColumn.EMPOWERMENT]: 'Zmocnění',
  [DocumentsTableColumn.RELATED_SUBJECTS_IDS]: 'Subjekt dokumentu/spisu',
  [DocumentsTableColumn.CONSIGNMENT_UID]: 'Identifikátor zásilky',
  [DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID]: 'Způsob doručení zásilky',
  [DocumentsTableColumn.CONSIGNMENT_SUBJECT]: 'Předmět datové zprávy',
  [DocumentsTableColumn.CONSIGNMENT_DISPATCH_DATE]: 'Datum vypravení zásilky',
  [DocumentsTableColumn.CONSIGNMENT_DELIVERY_DATE]: 'Datum doručení adresátovi zásilky',
  [DocumentsTableColumn.CONSIGNMENT_STATUS]: 'Stav zásilky',
  [DocumentsTableColumn.CONSIGNMENT_SUBJECT_RECORD]: 'Odesílatel/adresát zásilky',
  [DocumentsTableColumn.CONSIGNMENT_DELIVERY_SERVICE_COMBINATION_ID]: 'Doručovací služba',
  [DocumentsTableColumn.CONSIGNMENT_DISTRIBUTOR_ID]: 'Distributor zásilky',
  [DocumentsTableColumn.CONSIGNMENT_POSTING_NUMBER]: 'ČDZ zásilky',
  [DocumentsTableColumn.CONSIGNMENT_SUBJECT_ADDRESS]: 'Poštovní adresa zásilky',
  [DocumentsTableColumn.CONSIGNMENT_SUBJECT_EMAIL]: 'E-mail odesílatele/adresáta zásilky',
  [DocumentsTableColumn.CONSIGNMENT_SUBJECT_DATABOX]: 'ISDS schránka odesílatele/adresáta zásilky',
  [DocumentsTableColumn.CONSIGNMENT_OUTBOUND_IDENTIFIER]: 'Označení příchozí nebo odchozí zásilky',
  [DocumentsTableColumn.FILE_INSERTED_DOCUMENT]: 'Aktuální dokumenty',
  [DocumentsTableColumn.SHARE_PERMISSIONS]: 'Oprávnění',
  [DocumentsTableColumn.SHARE_DATE]: 'Datum nasdílení',
  [DocumentsTableColumn.ISSD_ID]: 'Externí aplikace',
  [DocumentsTableColumn.ISSD_TASK]: 'Předání externí aplikaci',
  [DocumentsTableColumn.HANDOVER_ACTIVITY_ID]: 'Aktivita oběhu',
  [DocumentsTableColumn.HANDOVER_ACTIVITY_TARGET_FP_ID]: 'Komu předáno',
  [DocumentsTableColumn.HANDOVER_ACTIVITY_REJECT_REASON]: 'Důvod odmítnutí',
  [DocumentsTableColumn.RELATED_EVIDENCE]: 'Související evidence',
  [DocumentsTableColumn.ORDER_NUMBER_IN_GIVEN_FILE]: 'Pořadí ve spisu',
  [DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER]: 'Původní odesílatel',
  [DocumentsTableColumn.STORAGE_UNIT_NUMBER]: 'Číslo ukládací jednotky',
  [DocumentsTableColumn.STORAGE_UNIT_NAME]: 'Název ukládací jednotky',
  [DocumentsTableColumn.STORAGE_UNIT_CONTENT_BLOCKED]: 'Blokován obsah',
  [DocumentsTableColumn.PHYSICAL_LOCATION]: 'Uložení',
  [DocumentsTableColumn.EXPECTED_DISPOSAL_YEAR]: 'Předpokládaný rok vyřazení jednotky',
  [DocumentsTableColumn.EXPECTED_CONTENT_DISPOSAL_YEAR]: 'Předpokládaný rok vyřazení obsahu',
  [DocumentsTableColumn.CONTENT_COUNT]: 'Položek obsahu',
  [DocumentsTableColumn.REGISTRY_OFFICE_ID]: 'Spisovna',
  [DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_STATE]: 'Stav uložení',
  [DocumentsTableColumn.HANDED_OVER_AT]: 'Předáno na spisovnu',
  [DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_ACTIVITY_STATE]: 'Úkol',
  [DocumentsTableColumn.TAKEN_OVER_AT]: 'Převzato spisovnou',
  [DocumentsTableColumn.HANDED_OVER_BY_FUNCTIONAL_POSITION_ID]: 'Předal',
  [DocumentsTableColumn.ENTITY_NAME]: 'Název entity',
  [DocumentsTableColumn.HAS_INCIDENTS]: 'Chyby předávání do spisovny',
  [DocumentsTableColumn.UNRESOLVED_INCIDENTS_COUNT]: 'Nevyřešených incidentů',
  [DocumentsTableColumn.INCIDENTS_ORIGIN]: 'Původ incidentů',
  [DocumentsTableColumn.YEAR_OF_RETENTION_PERIOD_START]: 'Rok spouštěcí události',
  [DocumentsTableColumn.TRIGGER_EVENT_CHECK_YEAR]: 'Rok kontroly spouštěcí události',
  [DocumentsTableColumn.EXTERNAL_RETENTION_TRIGGER_IDS]: 'Původ incidentů',
  [DocumentsTableColumn.STORAGE_UNIT_FORM]: 'Forma',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_STATE]: 'Stav',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_FINAL_RUN]: 'Typ',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_INSERTED_ENTITIES]: 'Založených entit',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_REJECTED_ENTITIES]: 'Selhaných entit',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_STORAGE_UNITS_INSERTED]: 'Založených UJ',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_STORAGE_UNITS_EXPECTED]: 'Očekávaných UJ',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_SCHEDULED_RUN_AT]: 'Datum příštího běhu',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_LAST_RUN_AT]: 'Datum posledního běhu',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_CLASSIFICATION_ATTRIBUTES]: 'Klíče',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_ID]: 'ID dávky',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_DISPOSAL_SCHEDULE]: 'Jen pro shodné skartační režimy',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_DISPOSAL_YEAR]: 'Jen pro shodný rok vyřazení',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_ENTITY_CLASS]: 'Jen pro shodnou věcnou skupinu',
  [DocumentsTableColumn.STORAGE_UNIT_FILLER_HUMAN_READABLE_FILTER]: 'Kritéria výběru objektů',
};

export function getStaticDocumentsColumnsForView(viewType: DocumentsTableView): DocumentsTableColumn[] {
  switch (viewType) {
    case DocumentView.GLOBAL_SEARCH:
      return globalSearchColumnsSet;
    case DocumentView.FILE_CONTENTS:
      return fileContentsColumnsSet;
    case DocumentView.STORAGE_UNIT_CONTENTS:
      return storageUnitContentsColumnsSet;
    case DocumentView.FILING_OFFICE_RECEIVED:
      return filingOfficeReceivedColumnSet;
    case DocumentView.FILING_OFFICE_HANDED_OVER:
      return filingOfficeHandedOverColumnSet;
    case DocumentView.FILING_OFFICE_REJECTED:
      return filingOfficeRejectedColumnSet;
    case DocumentView.DOCUMENT_SELECTOR:
      return documentSelectorColumnSet;
    case DocumentView.MANUAL_SHARED:
      return manualSharedColumnSet;
    case DocumentView.ISSD_DOCUMENTS:
      return issdDocumentsColumnSet;
    case StorageUnitView.STORAGE_UNITS_TABLE:
      return storageUnitColumnSet;
    case StorageUnitView.STORAGE_UNIT_FILLER_CONTENT_STORAGE_UNITS:
      return storageUnitFillerContentStorageUnitsColumnSet;
    case StorageUnitView.STORAGE_UNIT_FILLERS_TABLE:
      return storageUnitFillerColumnSet;
    case StorageUnitView.STORAGE_UNIT_INSERT_DETAIL:
      return storageUnitInsertColumnSet;
    case RegistryOfficeTransferView.REGISTRY_OFFICE_TO_TAKE_OVER:
    case RegistryOfficeTransferView.HANDED_OVER_AND_STORED:
      return registryOfficeTransfersColumnSet;
    case RegistryOfficeTransferView.REGISTRY_OFFICE_TO_RESOLVE_ISSUES:
    case RegistryOfficeTransferView.OFFICER_TO_RESOLVE_ISSUES:
      return registryOfficeTransfersWithIncidentsListingColumnSet;
    default:
      return defaultColumnsSet;
  }
}

export function getDocumentsColumnsData(
  viewType: DocumentsTableView,
  documentFiltersDataService: DocumentFiltersDataService,
  templatePool: TemplatePoolService<SharedTemplateCollectionComponent>,
  isUnitView: boolean,
  columns: DocumentsTableColumn[],
  registryOfficesCount?: number,
) {
  const entityClassOptionsTree$ = documentFiltersDataService.entityClassOptionsTree().pipe(shareReplay(1));
  const classificationSchemeOptions$ = documentFiltersDataService.classificationSchemeOptions().pipe(shareReplay(1));
  const isOutsideGlobalSearch = viewType !== DocumentView.GLOBAL_SEARCH;
  const isFilingOfficeView = [
    DocumentView.FILING_OFFICE_REJECTED, DocumentView.FILING_OFFICE_HANDED_OVER, DocumentView.FILING_OFFICE_RECEIVED
  ].includes(viewType as DocumentView);

  const columnDefinitions: ColumnDefinition<DocumentsTableColumn>[] = [];
  columns.forEach(column => {
    let columnDefinition: ColumnDefinition<DocumentsTableColumn>;
    switch (column) {
      case DocumentsTableColumn.SELECTION:
        columnDefinition = {
          id: column,
          displayed: viewType !== DocumentView.STORAGE_UNIT_FILLER_SELECTION_FILTERS,
          allowSettingInContextMenu: viewType !== DocumentView.STORAGE_UNIT_FILLER_SELECTION_FILTERS,
          label: documentColumnNames[column],
          filterType: FilterType.NONE
        };
        break;

      case DocumentsTableColumn.OBJECT_CLASS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          fixedWidth: 60,
          disableSort: true,
          filterType: FilterType.ENUM,
          displayed: viewType !== StorageUnitView.STORAGE_UNIT_FILLER_CONTENT_STORAGE_UNITS,
          filterLabel: 'Druh objektu',
          list: documentFiltersDataService.objectClassOptions,
          filterConfig: {
            isDistinctive: viewType === DocumentView.GLOBAL_SEARCH,
          }
        };
        break;

      case DocumentsTableColumn.OBJECT_CLASS_FULL:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          disableSort: true,
          filterType: FilterType.NONE,
          displayed: false,
          allowSettingInContextMenu: true,
        };
        break;

      case DocumentsTableColumn.REF_NUMBER:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NONE, // if ref number filtering is desired, add REF_NUMBER_ALL to columnset
          toggleable: false,
        };
        break;

      case DocumentsTableColumn.REF_NUMBER_ALL:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          // global search only filter
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.SUBJECT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
        };
        break;

      case DocumentsTableColumn.DIGITAL_COMPONENT_COUNT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          fixedWidth: 80,
          filterType: FilterType.NUMBER
        };
        break;

      case DocumentsTableColumn.HOLDERS_FUNCTIONAL_POSITION_IDS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          disableSort: true,
          displayed: false,
          list$: documentFiltersDataService.functionalPositionTreeOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'fp',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID,
          },
        };
        break;

      case DocumentsTableColumn.ENTITY_STATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          disableSort: true,
          allowTranslation: true,
          list: documentFiltersDataService.entityStateOptions,
          filterType: FilterType.ENUM,
          displayed: viewType !== DocumentView.GLOBAL_SEARCH
        };
        break;

      case DocumentsTableColumn.REPRESENTING_SUBJECT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          disableSort: true,
          filterType: FilterType.SUBJECT_RECORD,
          displayed: viewType !== DocumentView.FILE_CONTENTS,
        };
        break;

      case DocumentsTableColumn.DESCRIPTION:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: viewType !== DocumentView.GLOBAL_SEARCH,
        };
        break;

      case DocumentsTableColumn.CREATED_AT:
        columnDefinition = {
          id: column,
          label: isFilingOfficeView ? 'Založen' : documentColumnNames[column],
          filterType: isFilingOfficeView ? FilterType.DATETIME : FilterType.DATE,
          displayed: column === DocumentsTableColumn.CREATED_AT && viewType !== DocumentView.GLOBAL_SEARCH,
        };
        break;

      case DocumentsTableColumn.MODIFIED_AT:
        columnDefinition = {
          id: column,
          label: isFilingOfficeView ? 'Upraveno' : documentColumnNames[column],
          filterType: isFilingOfficeView ? FilterType.DATETIME : FilterType.DATE,
          displayed: false,
        };
        break;

      case DocumentsTableColumn.RESOLUTION_DATE:
        columnDefinition = {
          id: column,
          displayed: viewType === DocumentView.EXPIRING_DOCUMENTS || (isUnitView && viewType === DocumentView.SETTLED_DOCUMENTS) || viewType === DocumentView.MANUAL_SHARED,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
        };
        break;

      case DocumentsTableColumn.CLOSURE_DATE:
        columnDefinition = {
          id: column,
          displayed: viewType === DocumentView.SETTLED_DOCUMENTS,
          allowSettingInContextMenu: viewType === DocumentView.SETTLED_DOCUMENTS,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
        };
        break;

      case DocumentsTableColumn.YEAR_OF_RETENTION_PERIOD_START:
        columnDefinition = {
          id: column,
          displayed: viewType === DocumentView.SETTLED_DOCUMENTS || viewType === DocumentView.SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR,
          allowSettingInContextMenu: viewType === DocumentView.SETTLED_DOCUMENTS || viewType === DocumentView.SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
          filterConfig: {
            disableLocalizedNumberFormatting: true,
          }
        };
        break;
      case DocumentsTableColumn.EXTERNAL_RETENTION_TRIGGER_IDS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.externalRetentionTriggers(),
          disableSort: true,
        };
        break;
      case DocumentsTableColumn.TRIGGER_EVENT_CHECK_YEAR:
        columnDefinition = {
          id: column,
          displayed: viewType === DocumentView.SETTLED_DOCUMENTS || viewType === DocumentView.SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR,
          allowSettingInContextMenu: viewType === DocumentView.SETTLED_DOCUMENTS || viewType === DocumentView.SETTLED_DOCUMENTS_RETENTION_CHECK_YEAR,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
          filterConfig: {
            disableLocalizedNumberFormatting: true,
          }
        };
        break;

      case DocumentsTableColumn.EXECUTION_DATE:
        columnDefinition = {
          id: column,
          displayed: false,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
          allowSettingInContextMenu: (
            (viewType === DocumentView.DOCUMENT_LISTING && isUnitView) ||
            (viewType === DocumentView.EXPIRING_DOCUMENTS && isUnitView) ||
            viewType === DocumentView.SETTLED_DOCUMENTS
          ),
        };
        break;

      case DocumentsTableColumn.IDENTIFIER:
        columnDefinition = {
          id: column,
          displayed: viewType === DocumentView.FILING_OFFICE_RECEIVED || viewType === DocumentView.STORAGE_UNIT_CONTENTS,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
        };
        break;

      case DocumentsTableColumn.RELEVANCE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
        };
        break;

      case DocumentsTableColumn.IS_CONSTRAINED_VIEW:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: viewType === DocumentView.GLOBAL_SEARCH,
          icon: 'eye',
          fixedWidth: 24,
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case DocumentsTableColumn.OWNER_FUNCTIONAL_POSITION_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: viewType === DocumentView.GLOBAL_SEARCH || viewType === DocumentView.MANUAL_SHARED,
          list$: documentFiltersDataService.functionalPositionTreeOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'fp',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID,
          },
          disableSort: true,
        };
        break;

      case DocumentsTableColumn.OWNER_ORG_UNIT_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: false,
          allowSettingInContextMenu: viewType === DocumentView.SETTLED_DOCUMENTS,
          list$: documentFiltersDataService.organizationalUnitTreeOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'ou',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID,
          },
          disableSort: true,
        };
        break;

      case DocumentsTableColumn.CREATED_BY:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: viewType === DocumentView.GLOBAL_SEARCH,
          disableSort: true,
          list$: documentFiltersDataService.functionalPositionTreeOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'fp',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID,
          }
        };
        break;

      case DocumentsTableColumn.MODIFIED_BY:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: viewType === DocumentView.GLOBAL_SEARCH,
          disableSort: true,
          list$: documentFiltersDataService.functionalPositionTreeOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'fp',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID,
          }
        };
        break;

      case DocumentsTableColumn.SHARE_PERMISSIONS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: viewType === DocumentView.MANUAL_SHARED,
          list: documentFiltersDataService.permissionSetLevelOptions,
          filterType: FilterType.ENUM,
        };
        break;

      case DocumentsTableColumn.SHARE_DATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: viewType === DocumentView.MANUAL_SHARED,
          filterType: FilterType.DATE,
        };
        break;

      case DocumentsTableColumn.NOTE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: false,
          filterType: FilterType.TEXT,
        };
        break;

      case DocumentsTableColumn.OBJECT_FORM:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: false,
          filterType: FilterType.ENUM,
          list: documentFiltersDataService.documentFormOptions,
          disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
        };
        break;

      case DocumentsTableColumn.DISPOSAL_SCHEDULE_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: viewType === DocumentView.STORAGE_UNIT_CONTENTS,
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.disposalScheduleOptions(),
          filterConfig: getDisposalScheduleFilterConfig(),
          disableSort: true,
        };
        break;

      case DocumentsTableColumn.DISPOSAL_YEAR:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
          disableSort: true,
          displayed: false,
          filterConfig: {
            disableLocalizedNumberFormatting: true,
          }
        };
        break;

      case DocumentsTableColumn.SECURITY_CATEGORY_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: false,
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.securityCategoryOptions(),
          filterConfig: {
            isTree: true,
          },
          disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
        };
        break;

      case DocumentsTableColumn.DOCUMENT_TYPE_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: false,
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.documentTypeOptions(),
          filterConfig: {
            isTree: true,
          },
          disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
        };
        break;

      case DocumentsTableColumn.FILE_TYPE_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: false,
          disableSort: true,
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.fileTypeOptions(),
          filterConfig: {
            isTree: true,
          },
        };
        break;

      case DocumentsTableColumn.ENTITY_CLASS_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.CODEBOOK,
          list$: entityClassOptionsTree$,
          filterConfig: getEntityClassFilterConfig(templatePool),
          disableSort: true, //todo(mh) remove when ESSL-7936 is fixed on BE
        };
        break;

      case DocumentsTableColumn.SETTLEMENT_DATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
          displayed: viewType === DocumentView.SETTLED_DOCUMENTS,
          allowSettingInContextMenu: viewType === DocumentView.SETTLED_DOCUMENTS,
        };
        break;

      case DocumentsTableColumn.FILE_INSERTION_DATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
        };
        break;

      case DocumentsTableColumn.KEYWORD_IDS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.keywordOptions(),
          disableSort: true,
          displayed: false,
          allowSettingInContextMenu: viewType !== DocumentView.GLOBAL_SEARCH,
        };
        break;

      case DocumentsTableColumn.LABEL:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
        };
        break;

      case DocumentsTableColumn.DIGITAL_COMPONENT_LAST_TYPE_FLAG:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list: documentFiltersDataService.digitalComponentTypeOptions,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.REF_NUMBER_HISTORY:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.COMPONENT_TYPE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.COMPONENT_LABEL:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.COMPONENT_LABEL_FULLTEXT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NONE,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.COMPONENT_DESCRIPTION:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.COMPONENT_CONTENT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NONE,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.COMPONENT_PUID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.formatOptionTree(),
          displayed: false,
          allowSettingInContextMenu: false,
          filterConfig: {
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID,
          }
        };
        break;

      case DocumentsTableColumn.FILE_INSERTED_DOCUMENT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.BOOLEAN,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.SENDER_REF_NR:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: viewType === DocumentView.FILING_OFFICE_RECEIVED ||
            viewType === DocumentView.FILING_OFFICE_HANDED_OVER ||
            viewType === DocumentView.FILING_OFFICE_REJECTED,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_EXTERNAL_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
        };
        break;

      case DocumentsTableColumn.COMPONENT_PHYSICAL_LOCATION:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: viewType === DocumentView.SETTLED_DOCUMENTS || viewType === DocumentView.STORAGE_UNIT_FILLER_SELECTION_FILTERS,
        };
        break;

      case DocumentsTableColumn.FILING_REGISTER_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list$: documentFiltersDataService.basicRegisterOptions(),
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.FILING_REGISTER_REG_DATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
          displayed: false,
          allowSettingInContextMenu: (
            viewType === DocumentView.DOCUMENT_LISTING ||
            viewType === DocumentView.EXPIRING_DOCUMENTS ||
            viewType === DocumentView.SETTLED_DOCUMENTS
          ) && isUnitView,
        };
        break;

      case DocumentsTableColumn.SETTLEMENT_TYPE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list: enumToOptions('settlementType', SettlementType),
          allowTranslation: true,
          displayed: viewType === DocumentView.SETTLED_DOCUMENTS,
          allowSettingInContextMenu: viewType === DocumentView.SETTLED_DOCUMENTS,
        };
        break;

      case DocumentsTableColumn.RELATED_SUBJECTS_IDS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.SUBJECT_RECORD,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_UID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_DELIVERY_TYPE_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list$: documentFiltersDataService.deliveryTypeOptions(),
          disableSort: true,
          displayed: isOutsideGlobalSearch,
          allowSettingInContextMenu: isOutsideGlobalSearch,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_SUBJECT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_DISPATCH_DATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_DELIVERY_DATE:
        columnDefinition = {
          id: column,
          label: isFilingOfficeView ? 'Doručen' : documentColumnNames[column],
          filterType: isFilingOfficeView ? FilterType.DATETIME : FilterType.DATE,
          displayed: isOutsideGlobalSearch,
          allowSettingInContextMenu: isOutsideGlobalSearch,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_STATUS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list: enumToOptions('ownConsignmentStatus', OwnConsignmentStatus),
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_SUBJECT_RECORD:
        columnDefinition = {
          id: column,
          label: isFilingOfficeView ? 'Odesílatel' : documentColumnNames[column],
          filterType: FilterType.SUBJECT_RECORD,
          displayed: isOutsideGlobalSearch,
          allowSettingInContextMenu: isOutsideGlobalSearch,
          disableSort: true,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_DELIVERY_SERVICE_COMBINATION_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list$: documentFiltersDataService.deliveryServiceOptions(),
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_DISTRIBUTOR_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list$: documentFiltersDataService.distributorOptions(),
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_POSTING_NUMBER:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_SUBJECT_ADDRESS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ADDRESS,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_SUBJECT_EMAIL:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_SUBJECT_DATABOX:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_OUTBOUND_IDENTIFIER:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: false,
        };
        break;

      case DocumentsTableColumn.EMPOWERMENT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          displayed: false,
          disableSort: true,
          filterType: FilterType.EMPOWERMENT,
        };
        break;

      case DocumentsTableColumn.CREATION_DATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
        };
        break;

      case DocumentsTableColumn.ISSD_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          filterLabel: 'Externí aplikace',
          list$: documentFiltersDataService.issdAppsOptions(),
          disableSort: true,
          allowTranslation: false,
          displayed: viewType !== DocumentView.GLOBAL_SEARCH,
        };
        break;

      case DocumentsTableColumn.ISSD_TASK:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          fixedWidth: 32,
          icon: 'task_faded',
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case DocumentsTableColumn.HANDOVER_ACTIVITY_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          fixedWidth: 32,
          icon: 'task_faded',
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;

      case DocumentsTableColumn.ORDER_NUMBER_IN_GIVEN_FILE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          hideLabelInHeader: true,
          filterType: FilterType.NUMBER,
          toggleable: false,
          fixedWidth: 60,
        };
        break;

      case DocumentsTableColumn.HANDOVER_ACTIVITY_TARGET_FP_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          disableSort: true,
          list$: documentFiltersDataService.functionalPositionTreeOptions,
          filterType: FilterType.CODEBOOK,
          filterConfig: {
            originId: 'fp',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID,
          }
        };
        break;

      case DocumentsTableColumn.RELATED_EVIDENCE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
        };
        break;

      case DocumentsTableColumn.HANDOVER_ACTIVITY_REJECT_REASON:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NONE,
        };
        break;

      case DocumentsTableColumn.CONSIGNMENT_ORIGINAL_SENDER:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
          allowSettingInContextMenu: viewType !== DocumentView.GLOBAL_SEARCH,
        };
        break;

      case DocumentsTableColumn.STORAGE_UNIT_NUMBER:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: viewType === StorageUnitView.STORAGE_UNITS_TABLE || viewType === StorageUnitView.STORAGE_UNIT_INSERT_DETAIL,
          toggleable: false,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_NAME:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: [
            StorageUnitView.STORAGE_UNITS_TABLE,
            StorageUnitView.STORAGE_UNIT_INSERT_DETAIL,
            StorageUnitView.STORAGE_UNIT_FILLERS_TABLE,
          ].includes(viewType as StorageUnitView),
          toggleable: viewType === StorageUnitView.STORAGE_UNIT_FILLERS_TABLE,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FORM:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list: enumToOptions('storageUnitForm', StorageUnitForm),
          allowTranslation: true,
          displayed: false,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_CONTENT_BLOCKED:
        columnDefinition = {
          id: column,
          icon: 'icon_lock',
          fixedWidth: 60,
          label: documentColumnNames[column],
          filterType: FilterType.BOOLEAN,
          disableSort: true,
        };
        break;
      case DocumentsTableColumn.PHYSICAL_LOCATION:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
          displayed: false,
        };
        break;
      case DocumentsTableColumn.EXPECTED_DISPOSAL_YEAR:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
          filterConfig: {
            disableLocalizedNumberFormatting: true,
          }
        };
        break;
      case DocumentsTableColumn.EXPECTED_CONTENT_DISPOSAL_YEAR:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
          displayed: false,
          filterConfig: {
            disableLocalizedNumberFormatting: true,
          }
        };
        break;
      case DocumentsTableColumn.CONTENT_COUNT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
        };
        break;
      case DocumentsTableColumn.REGISTRY_OFFICE_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.registryOfficeOptions(),
          disableSort: true,
          displayed: (!isNil(registryOfficesCount) && registryOfficesCount > 1)
        };
        break;
      case DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_STATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list: enumToOptions('registryOfficeTransferState', RegistryOfficeTransferState),
          allowTranslation: true,
          disableSort: true,
        };
        break;
      case DocumentsTableColumn.HANDED_OVER_AT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
        };
        break;
      case DocumentsTableColumn.REGISTRY_OFFICE_TRANSFER_ACTIVITY_STATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.ENUM,
          list: enumToOptions('registryOfficeTransferActivityState', Essl9972RegistryOfficeTransferActivityState),
          displayed: viewType === RegistryOfficeTransferView.HANDED_OVER_AND_STORED,
          allowSettingInContextMenu: viewType === RegistryOfficeTransferView.HANDED_OVER_AND_STORED,
          disableSort: true,
        };
        break;
      case DocumentsTableColumn.TAKEN_OVER_AT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATE,
        };
        break;
      case DocumentsTableColumn.HANDED_OVER_BY_FUNCTIONAL_POSITION_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.CODEBOOK,
          list$: documentFiltersDataService.functionalPositionTreeOptions,
          filterConfig: {
            originId: 'fp',
            isTree: true,
            treeSelectionStrategy: TreeItemSelectionStrategy.HYBRID,
          },
          disableSort: true,
        };
        break;
      case DocumentsTableColumn.ENTITY_NAME:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
        };
        break;
      case DocumentsTableColumn.UNRESOLVED_INCIDENTS_COUNT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_STATE:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          list: enumToOptions('storageUnitFillerState', StorageUnitFillerState),
          allowTranslation: true,
          filterType: FilterType.ENUM,
          disableSort: true,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_FINAL_RUN:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.BOOLEAN,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_ID:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.TEXT,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_INSERTED_ENTITIES:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
          displayed: false,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_REJECTED_ENTITIES:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
          displayed: false,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_STORAGE_UNITS_EXPECTED:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_STORAGE_UNITS_INSERTED:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NUMBER,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_SCHEDULED_RUN_AT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATETIME,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_LAST_RUN_AT:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.DATETIME,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_DISPOSAL_SCHEDULE:
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_DISPOSAL_YEAR:
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_ENFORCE_ENTITY_CLASS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.BOOLEAN,
          disableSort: true,
          displayed: false,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_HUMAN_READABLE_FILTER:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          filterType: FilterType.NONE,
          disableSort: true,
        };
        break;
      case DocumentsTableColumn.STORAGE_UNIT_FILLER_CLASSIFICATION_ATTRIBUTES:
        columnDefinition = {
          id: column,
          disableSort: true,
          label: documentColumnNames[column],
          allowTranslation: true,
          list: enumToOptions('storageUnitFillerClassificationAttribute', StorageUnitFillerClassificationAttribute),
          filterType: FilterType.ENUM,
        };
        break;
      case DocumentsTableColumn.INCIDENTS_ORIGIN:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          disableSort: true,
          filterType: FilterType.ENUM,
          list: enumToOptions('registryOfficeTransferIncidentOrigin', RegistryOfficeTransferIncidentOrigin),
          allowTranslation: true,
        };
        break;
      case DocumentsTableColumn.HAS_INCIDENTS:
        columnDefinition = {
          id: column,
          label: documentColumnNames[column],
          disableSort: true,
          icon: 'warning',
          iconSize: 'default',
          fixedWidth: 60,
          filterType: FilterType.NONE,
        };
        break;
      default:
        return;
    }
    columnDefinitions.push(columnDefinition);
  });

  const entityClassColumnDef = columnDefinitions.find(cd => cd.id === DocumentsTableColumn.ENTITY_CLASS_ID);

  if (viewType === DocumentView.OWNERSHIP_TRANSFER || viewType === DocumentView.STORAGE_UNIT_INSERT) {
    columnDefinitions.forEach(column => {
      column.displayed = ownershipTransferDisplayedColumnSet.some(displayedColumn => displayedColumn === column.id);
    });
  }

  if (entityClassColumnDef) {
    setupEntityClassFilterSubfilters(
      (entityClassColumnDef as CodebookFilterDefinition).filterConfig!,
      classificationSchemeOptions$,
    );
  }

  return columnDefinitions;
}
