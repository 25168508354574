import {OperationValidator, ValidatorGuard} from '../../../../services/bulk-operation-validation.service';
import {
  GenericOwnElasticConsignmentWithConsignee,
  isOwnDigitalElasticConsignment,
  isOwnInternalElasticConsignment,
  isOwnInternalPaperElasticConsignment,
  isOwnMultiPaperElasticConsignment,
  isOwnOfficeDeskElasticConsignment,
  isOwnPaperElasticConsignment,
  isOwnPersonalElasticConsignment,
  isOwnPortalElasticConsignment,
} from '../../own-consignment-table/model/own-consignment-model';
import {OwnConsignmentStatus} from '|api/commons';
import {TranslateService} from '@ngx-translate/core';
import {DeliveryTypeDto} from '|api/codebook';
import {DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE} from '../../shared-document.utils';
import {OwnPaperConsignmentElasticDto} from '|api/sad';

export class ConsignmentOperationValidators {

  @ValidatorGuard()
  static isConsignmentInvalid(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return !c.valid ? {validationMessage: 'Zásílka není validní'} : null;
    };
  }

  static notInAllowedStatus(allowedStatus: OwnConsignmentStatus, translateService: TranslateService): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      const isIncorrectStatus = c.status !== allowedStatus;

      if (isIncorrectStatus) {
        return {
          validationMessage: 'Zásilka není ve stavu {{state}}.',
          validationMessageContext: {state: translateService.instant(`en.ownConsignmentStatus.${allowedStatus}`)}
        };
      }
      else {
        return null;
      }
    };
  }

  static isNotPaperOrMultiPaperOrInternalPaperConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return (!isOwnPaperElasticConsignment(c) && !isOwnInternalPaperElasticConsignment(c) && !isOwnMultiPaperElasticConsignment(c)) ?
        {validationMessage: 'Zásilka není listinná ani interní listinná.'} :
        null;
    };
  }

  static notInOneOfAllowedStatus(allowedStatuses: OwnConsignmentStatus[], translateService: TranslateService): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      const isIncorrectStatus = allowedStatuses.every(s => c.status !== s);

      if (isIncorrectStatus) {
        const joinedStatuses = allowedStatuses.map(s => translateService.instant(`en.ownConsignmentStatus.${s}`)).toString().replaceAll(',', ', ');

        return {
          validationMessage: 'Zásilka není v nekterém ze stavů: {{statuses}}.',
          validationMessageContext: {statuses: joinedStatuses}
        };
      }
      else {
        return null;
      }
    };
  }

  static isPaperConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return isOwnPaperElasticConsignment(c) || isOwnMultiPaperElasticConsignment(c) ? {validationMessage: 'Zásilka je listinná.'} : null;
    };
  }

  static isNotPaperConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return !ConsignmentOperationValidators.isPaperConsignment()(c) ? {validationMessage: 'Zásilka není listinná.'} : null;
    };
  }

  static isNotPaperOrInternalAnalogConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return !isOwnPaperElasticConsignment(c) && !isOwnInternalPaperElasticConsignment(c)  ? {validationMessage: 'Zásilka není listinná nebo interní analogová.'} : null;
    };
  }

  static isPersonalConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return isOwnPersonalElasticConsignment(c) ? {validationMessage: 'Zásilka je osobní.'} : null;
    };
  }

  static isPortalConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return isOwnPortalElasticConsignment(c) ? {validationMessage: 'Zásilka je portálová.'} : null;
    };
  }

  static isDigitalConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return isOwnDigitalElasticConsignment(c) ? {validationMessage: 'Zásilka je do datové schránky nebo přes e-mail.'} : null;
    };
  }

  static isNotDigitalConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return !isOwnDigitalElasticConsignment(c) ? {validationMessage: 'Zásilka není do datové schránky nebo přes e-mail.'} : null;
    };
  }

  static isInternalConsignment(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return isOwnInternalElasticConsignment(c) ? {validationMessage: 'Zásilka je interní analogová.'} : null;
    };
  }

  static isInternalConsignmentInDistribution(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return (isOwnInternalElasticConsignment(c) && c.status === OwnConsignmentStatus.IN_DISTRIBUTION) ? {validationMessage: 'Interní zásilka je v distribuci.'} : null;
    };
  }

  static isEmailOutsideFilingOffice(deliveryTypes: DeliveryTypeDto[]): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      const emailOutsideFilingOfficeDeliveryTypeId = deliveryTypes.find(dt => dt.code === DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE)!.id;
      return (c.deliveryTypeId === emailOutsideFilingOfficeDeliveryTypeId) ? {validationMessage: 'Zásilka je mimo EPO.'} : null;
    };
  }

  static isNotEmailOutsideFilingOffice(deliveryTypes: DeliveryTypeDto[]): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      const emailOutsideFilingOfficeDeliveryTypeId = deliveryTypes.find(dt => dt.code === DELIVERY_TYPE_EMAIL_OUTSIDE_FILING_OFFICE_CODE)!.id;
      return (c.deliveryTypeId !== emailOutsideFilingOfficeDeliveryTypeId) ? {validationMessage: 'Zásilka není mimo EPO.'} : null;
    };
  }

  static consignmentHasNoProofOfDelivery(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return !c.proofOfDelivery ?
        {validationMessage: 'Doručenku lze připojit pouze k zásilce, které již bylo zapsáno doručení v minulosti.'} :
        null;
    };
  }

  static isNotSupportedForLabelPrinting(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return (!isOwnPaperElasticConsignment(c) &&
        !isOwnPersonalElasticConsignment(c) &&
        !isOwnInternalPaperElasticConsignment(c) &&
        !isOwnOfficeDeskElasticConsignment(c)) &&
        !isOwnMultiPaperElasticConsignment(c) ?
        {validationMessage: 'Tato akce podporuje pouze listinná, osobní, interní listinná vypravení a vyvěšení na úřední desku.'} :
        null;
    };
  }

  static isNotSupportedForEnvelopePrinting(): OperationValidator<GenericOwnElasticConsignmentWithConsignee> {
    return (c: GenericOwnElasticConsignmentWithConsignee | GenericOwnElasticConsignmentWithConsignee) => {
      return (!isOwnPaperElasticConsignment(c) && !isOwnPersonalElasticConsignment(c) && !isOwnInternalPaperElasticConsignment(c) && !isOwnMultiPaperElasticConsignment(c)) ?
        {validationMessage: 'Tato akce podporuje pouze listinná, osobní a interní listinná vypravení.'} :
        null;
    };
  }

  static isPartOfOwnMultiPaperConsignment() {
    return (c: GenericOwnElasticConsignmentWithConsignee) => {
      return !isNil((c as Nullable<OwnPaperConsignmentElasticDto>)?.ownPaperMultiConsignmentId) ?
        {validationMessage: 'Zásilka je součástí hromadné zásilky. Individuálně ji nelze upravovat'} :
        null;
    };
  }

}
