import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {OwnConsignmentDistributionDto, OwnPaperConsignmentDto} from '|api/sad';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup} from '@icz/angular-form-elements';
import {debounceTime, map} from 'rxjs/operators';
import {combineLatest} from 'rxjs';
import {namedDtoToOption, nameDtoToOptionWithId} from '../../../../../../core/services/data-mapping.utils';
import {EsslMoney, EsslMoneyDto} from '|api/commons';
import {CodebookService} from '../../../../../../core/services/codebook.service';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {PrepareOrDispatchFormData} from '../../../dispatch-dialogs.model';
import {IczValidators} from '@icz/angular-form-elements';
import {LocalizedDatePipe} from '@icz/angular-essentials';
import {DISTRIBUTOR_POST_CODE} from '../../../../shared-document.utils';
import {IczOption} from '@icz/angular-form-elements';


@Component({
  selector: 'icz-prepare-or-dispatch-form',
  templateUrl: './prepare-or-dispatch-form.component.html',
  styleUrls: ['./prepare-or-dispatch-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    LocalizedDatePipe,
  ]
})
export class PrepareOrDispatchFormComponent implements OnInit {

  loadingIndicatorService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) formData!: PrepareOrDispatchFormData;
  @Input({required: true}) isDefaultConsignment = false;
  @Input({required: true}) consignment!: OwnPaperConsignmentDto;
  @Output() formValueChanged = new EventEmitter<OwnConsignmentDistributionDto>();

  private patchDistributionValues(c: OwnPaperConsignmentDto, v: OwnConsignmentDistributionDto): OwnPaperConsignmentDto {
    return {
      ...c,
      payoutAmount: v.payoutAmount as EsslMoneyDto,
      weight: v.weight,
      consignmentPostingNumber: v.consignmentPostingNumber,
    };
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(
      debounceTime(100),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(value => {
      this.consignment = this.patchDistributionValues(this.consignment!, value as OwnConsignmentDistributionDto);
      this.formValueChanged.emit(value as OwnConsignmentDistributionDto);
    });

    this.patchForm();
    if (this.formData.showMetadata) {
      this.codebookService.deliveryTypes().subscribe(deliveryTypes => {
        this.deliveryTypeOptions = deliveryTypes.map(nameDtoToOptionWithId(true));
      });
    }
  }

  deliveryTypeOptions: IczOption[] = [];

  form = new IczFormGroup({
    distributorId: new IczFormControl<Nullable<number>>({value: null, disabled: true}, []),
    consignmentPostingNumber: new IczFormGroup({ // ConsignmentPostingNumberDto
      code: new IczFormControl<Nullable<string>>(null, []),
      prefix: new IczFormControl<Nullable<string>>(null, []),
      suffix: new IczFormControl<Nullable<string>>(null, []),
    }),
    bulkPostingFormId: new IczFormControl<Nullable<number>>(null, []), // waiting for bulkPostingForm next iteration
    weight: new IczFormControl<Nullable<number>>(null, [IczValidators.isInteger(), IczValidators.min(0)]),
    payoutAmount: new IczFormControl<Nullable<EsslMoney>>(null, []),
  });

  distributorOptions$ = this.codebookService.distributors().pipe(map(dists => dists.map(namedDtoToOption())));

  postalDistributorId!: number;

  get isDistributorPost() {
    return this.form.get('distributorId')?.value === this.postalDistributorId;
  }

  patchForm() {
    if (this.consignment) {
      this.loadingIndicatorService.doLoading(
        combineLatest([
          this.codebookService.deliveryServiceCombinations(),
          this.codebookService.distributors(),
        ]),
        this
      ).subscribe(([combinations, distributors]) => {
        this.postalDistributorId = distributors.find(d => d.name === DISTRIBUTOR_POST_CODE)!.id!; // name has unique constraint
        const distributorId = combinations.find(c => c.id === this.consignment!.deliveryServiceCombinationId)!.basicService.distributorId;
        this.form.get('distributorId')!.setValue(distributorId);
      });

      const attrsToPatch = ['payoutAmount', 'weight', 'consignmentPostingNumber'];
      attrsToPatch.forEach((attr: string) => {
        if (this.consignment[attr as keyof OwnPaperConsignmentDto]) {
          this.form.get(attr)?.setValue(this.consignment[attr as keyof OwnPaperConsignmentDto]);
        }
      });
    }
  }
}
