@if (columnsData && tableConfig) {
  <icz-table
    #iczTable
    [id]="tableId"
    [config]="tableConfig"
    [columnsData]="columnsData"
    [dataSource]="dataSource"
    (rowClicked)="onRowClick($event)"
    [rowDisabler]="rowDisabler"
    [rowSelectionDisabler]="rowDisabler"
    (pageLoadStarted)="pageLoadStarted.emit($event)"
    (selectedRowsChanged)="selectedRows.emit($event)"
  >
    <ng-content tableTitle select="[tableTitle]"></ng-content>
    <ng-content tableTabs select="[tableTabs]"></ng-content>
    <ng-content notFoundLabel select="[notFoundLabel]"></ng-content>
    <ng-content noDataLabel select="[noDataLabel]"></ng-content>
    <ng-content tools select="[tools]"></ng-content>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: ReceivedDigitalMessagesTableColumn.MESSAGE_TYPE">
      <div
        cdkOverlayOrigin
        #attachmentsPopupOrigin="cdkOverlayOrigin"
        (click.stop)="openAttachmentsPopup(row)"
      >
        <div class="row gap-0" [iczTooltip]="'en.epdzMessageType.' + row.messageType">
          <icz-icon
            [iczTooltip]="getConsignmentTypeTooltip(row) | async"
            [svgIcon]="getEpdzMessageIcon(row.messageType!)"
            size="small"
          ></icz-icon>
          @if (row.haveAttachments || row.originalDigitalComponentVersionId) {
            <icz-icon svgIcon="attachment" size="small"></icz-icon>
          }
        </div>
      </div>
      <icz-popover
        [isOpen]="isAttachmentsPopupOpen && row.id === currentEpdzMessageId"
        [overlayOrigin]="attachmentsPopupOrigin"
        (onClose)="closeAttachmentsPopup()"
      >
        <div class="gap-8 p-8 col" [waiting]="loadingIndicatorService.isLoading$(this) | async">
          <div class="gap-8 overflow-hidden col digital-components-list">
            @for (attachment of epdzMessageAttachments; track attachment; let i = $index) {
              <icz-epdz-attachment-link
                [epdzAttachment]="attachment"
                (click)="openCurrentMessageEpdzAttachmentAtIndex(row, i)"
                class="cursor-pointer"
              ></icz-epdz-attachment-link>
            }
          </div>
        </div>
      </icz-popover>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS; withEllipsis: true">
      @if (view === ReceivedDigitalMessagesTableView.DATABOX_CONSIGNMENTS) {
        @if (row.consigneeAddress; as consigneeAddress) {
          <span [iczTooltip]="consigneeAddress">{{ consigneeAddress }}</span>
        }
      }
      @else {
        <!-- todo(rb) remove after multi email boxes support added on BE -->
        @if (row.consigneeAddress + ' (' + ('Výchozí schránka' | translate) + ')'; as consigneeAddress) {
          <span [iczTooltip]="consigneeAddress">{{ consigneeAddress }}</span>
        }
      }
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: ReceivedDigitalMessagesTableColumn.MESSAGE_ISSUES; withEllipsis: true">
      <icz-digital-message-issues
        [defects]="row.defects"
      ></icz-digital-message-issues>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: ReceivedDigitalMessagesTableColumn.PROCESSING_ERRORS; withEllipsis: true">
      <icz-digital-message-processing-errors
        [errorDetails]="row.details"
      ></icz-digital-message-processing-errors>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: ReceivedDigitalMessagesTableColumn.CONSIGNEE_ADDRESS_ALIAS; withEllipsis: true">
      <span>{{row.distributionNodeId | findInList:databoxNodeNames}}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: ReceivedDigitalMessagesTableColumn.EXTERNAL_ID; withEllipsis: true">
      <span>{{row.externalId}}</span>
    </ng-container>
    <ng-container *iczColumnTemplate="let row from [dataSource, columnsData]; id: ReceivedDigitalMessagesTableColumn.SIGNATURE_FLAG">
      @if (row.signatureFlag !== DigitalComponentVersionSignatureFlagType.NONE) {
        @if ('en.digitalComponentVersionSignatureFlagType.' + row.signatureFlag; as signatureFlag) {
          <span
            [iczTooltip]="signatureFlag"
            >
            {{ signatureFlag | translate }}
          </span>
        }
      }
    </ng-container>
  </icz-table>
}
