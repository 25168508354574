import {ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {PrimitiveControlValueType} from '../../../common/abstract-form-field';
import {TimeSelectorUsage} from '../time-selector.component';
import {FormFieldComponent} from '../../../form-field/form-field.component';
import {ButtonComponent} from '@icz/angular-essentials';

/**
 * A simple number counter with value, increment and decrement buttons.
 */
@Component({
  selector: 'icz-number-counter-field',
  templateUrl: './number-counter-field.component.html',
  styleUrls: ['./number-counter-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ButtonComponent,
    FormFieldComponent,
  ]
})
export class NumberCounterFieldComponent {

  /**
   * Minimal counter value.
   */
  @Input({required: true})
  minValue = 0;
  /**
   * Maximal counter value.
   */
  @Input({required: true})
  maxValue = Infinity;
  /**
   * Expected count of numerals. Numbers with numeral count lower than this will get zero-padded from the left.
   */
  @Input()
  numeralCount = 2;
  /**
   * Counter value.
   */
  @Input({required: true})
  value = 0;
  /**
   * Emits when the counter changes its value. Can be used in two-way data binding.
   */
  @Output()
  valueChange = new EventEmitter<number>();
  /**
   * @see TimeSelectorUsage
   */
  @Input({required: true})
  timeSelectorUsage = TimeSelectorUsage.FILTER;
  /**
   * If true, the counter will become visually inaccessible.
   */
  @Input()
  fieldDisabled = false;

  private hasNonNumericValue = false;

  @HostBinding('class.icz-invalid')
  protected get isInvalid(): boolean {
    return (this.value < this.minValue || this.value > this.maxValue) || this.hasNonNumericValue;
  }

  protected get viewValue(): string {
    return String(this.value).padStart(this.numeralCount, '0');
  }
  protected set viewValue(newValue: PrimitiveControlValueType) {
    const parsedValue = parseInt(newValue as string, 10);

    if (isNaN(parsedValue)) {
      this.hasNonNumericValue = true;
    }
    else {
      this.hasNonNumericValue = false;

      this.value = parsedValue;

      if (!this.isInvalid) {
        this.valueChange.emit(this.value);
      }
    }
  }

  protected increment() {
    if (this.value >= this.maxValue || this.value < this.minValue) {
      this.value = this.minValue;
    }
    else {
      ++this.value;
    }

    this.valueChange.emit(this.value);
  }

  protected decrement() {
    if (this.value <= this.minValue || this.value > this.maxValue) {
      this.value = this.maxValue;
    }
    else {
      --this.value;
    }

    this.valueChange.emit(this.value);
  }

  protected readonly TimepickerUsage = TimeSelectorUsage;

}
