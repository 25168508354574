import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {map} from 'rxjs/operators';
import {OrganizationalUnitDto} from '|api/auth-server';
import {ApiBasicSettingsOauthService, DistributionNodeType} from '|api/config-server';
import {SheetNodeDto} from '|api/sad';
import {CodebookService} from '../../../../../core/services/codebook.service';
import {namedDtoToOption} from '../../../../../core/services/data-mapping.utils';
import {OrganizationalStructureService} from '../../../../../core/services/organizational-structure.service';
import {LoadingIndicatorService} from '@icz/angular-essentials';
import {OwnInternalConsignmentForm} from '../consignment-dialog.form';
import {
  SubjectAttributeSource,
  SubjectAttributeState,
  SubjectRecordClassification,
  SubjectRecordDto
} from '|api/commons';

import {sheetNodeToOption} from '../consignment.model';
import {forkJoin} from 'rxjs';
import {IczOption, locateOptionByValue} from '@icz/angular-form-elements';

export interface OrganizationConsignee {
  subject: SubjectRecordDto,
  sheetNode: Nullable<SheetNodeDto>,
}

type SheetNodeOption = IczOption<number, SheetNodeDto>;
type OrganizationalUnitOption = IczOption<number, OrganizationalUnitDto>;

@Component({
  selector: 'icz-consignment-org-unit-selection',
  templateUrl: './consignment-org-unit-selection.component.html',
  styleUrls: ['./consignment-org-unit-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsignmentOrgUnitSelectionComponent implements OnInit {

  protected loadingService = inject(LoadingIndicatorService);
  private codebookService = inject(CodebookService);
  private orgStructureService = inject(OrganizationalStructureService);
  private apiBasicSettingsOauthService = inject(ApiBasicSettingsOauthService);
  private translateService = inject(TranslateService);

  @Input({required: true}) form!: OwnInternalConsignmentForm;

  @Output() organizationConsigneeChanged: EventEmitter<OrganizationConsignee> = new EventEmitter<OrganizationConsignee>();

  organizationalUnitsOptions$ = this.orgStructureService.organizationalUnits().pipe(
    map(units => units.map(u => {
      return {
        ...namedDtoToOption(true)(u),
        data: u,
      };
    }))
  );

  sheetNodesOptions$ = this.codebookService.sheetNodes().pipe(
    map(sheetNodes => {
      if (sheetNodes) {
        return sheetNodes
          .filter(sheetNode => sheetNode.isActive)
          .filter(node => node.purpose === DistributionNodeType.SEND_RECEIVE || node.purpose === DistributionNodeType.RECEIVE);
      } else {
        return [];
      }
    }),
    map(sheetNodes => sheetNodes.map(sn => ({
      ...sheetNodeToOption(this.translateService)(sn),
      data: sn,
    }))),
  );

  sheetNodesOptions: SheetNodeOption[] = [];
  organizationalUnitsOptions: OrganizationalUnitOption[] = [];

  organizationDesc = '';

  organizationDummyConsignee!: OrganizationConsignee;

  ngOnInit(): void {
    const organizationalUnitControl = this.form.get('consigneeOrganizationalUnitId')!;
    const sheetNodeControl = this.form.get('filingOfficeSheetNodeId')!;

    organizationalUnitControl.valueChanges.subscribe(ouId => {
      if (ouId) {
        const orgUnit = locateOptionByValue(this.organizationalUnitsOptions, ouId)!.data;
        this.form.get('consigneeAdditionalName')!.setValue(orgUnit ? orgUnit.name : null);
      }

    });
    sheetNodeControl.valueChanges.subscribe(nodeId => {
      if (nodeId) {
        const baseAttr = {
          source: SubjectAttributeSource.INTERNAL,
          state: SubjectAttributeState.CORRECT,
          validFrom: new Date().toISOString(),
        };
        this.organizationDummyConsignee.sheetNode = locateOptionByValue(this.sheetNodesOptions, nodeId)!.data;
        this.organizationDummyConsignee.subject.attributes =
          { mailingAddress: {
            ...baseAttr,
              value: this.organizationDummyConsignee.sheetNode!.address
            }
          };
        this.form.get('consigneeAddress')!.setValue(this.organizationDummyConsignee.sheetNode!.address);
        this.organizationConsigneeChanged.emit(this.organizationDummyConsignee);
      }
    });

    this.loadingService.doLoading(
      forkJoin([
        this.sheetNodesOptions$,
        this.organizationalUnitsOptions$,
        this.apiBasicSettingsOauthService.basicSettingsOauthFind(),
      ]),
      this
    ).subscribe(([sheetNodesOptions, organizationalUnitsOptions, settings]) => {
      this.organizationDesc = `${settings.organizationSubjectType} - ${settings.organizationName}, ${settings.organizationCid}`;
      this.organizationDummyConsignee = {
        sheetNode: null,
        subject: {
          identifiable: false,
          classification: settings.organizationSubjectType as unknown as SubjectRecordClassification,
          attributes: {}
        }
      };
      this.organizationConsigneeChanged.emit(this.organizationDummyConsignee);

      this.sheetNodesOptions = sheetNodesOptions;

      if (sheetNodesOptions.length === 1) {
        sheetNodeControl.setValue(sheetNodesOptions[0].value);
      }

      this.organizationalUnitsOptions = organizationalUnitsOptions;

      if (organizationalUnitsOptions.length === 1) {
        organizationalUnitControl.setValue(organizationalUnitsOptions[0].value);
      }
    });
  }

}
