import {ChangeDetectorRef, DestroyRef, Directive, inject, OnInit} from '@angular/core';
import {DocumentDto, FileDto} from '|api/document';
import {ObjectClass, SettlementType} from '|api/commons';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {DisposalScheduleDto, EntityClassDto} from '|api/codebook';
import {TabItem} from '@icz/angular-essentials';
import {IczFormControl, IczFormGroup, IczValidators} from '@icz/angular-form-elements';
import {SettlementTabState} from './document-settle-model';
import {getObjectIcon} from '../../../shared-document.utils';
import {CodebookService} from '../../../../../core';
import {DialogService, injectModalData} from '@icz/angular-modal';

@Directive()
export abstract class AbstractObjectSettleDialogComponent<TObject extends DocumentDto | FileDto> implements OnInit {

  protected codebookService = inject(CodebookService);
  protected dialogService = inject(DialogService);
  protected changeDetectorRef = inject(ChangeDetectorRef);
  protected destroyRef = inject(DestroyRef);
  protected objects = injectModalData<TObject[]>();

  entityClasses: EntityClassDto[] = [];
  disposalSchedules: DisposalScheduleDto[] = [];

  readonly GENERAL_TAB = 'general';

  tabs: TabItem[] = [
    {
      id: this.GENERAL_TAB,
      label: 'Specifikace vyřízení'
    }
  ];

  protected generalTabForm!: IczFormGroup;
  activeTabId = this.GENERAL_TAB;
  overrideValueByGeneralTab = true;
  isBulk = false;
  overrideQuestionModalOpened = false;
  forms: Record<string, IczFormGroup> = {};
  tabsCache: Record<string, SettlementTabState> = {};

  ngOnInit() {
    this.codebookService.entityClasses().subscribe(ec => this.entityClasses = ec);
    this.codebookService.disposalSchedules().subscribe(ds => this.disposalSchedules = ds);
  }

  tabChange(activatedTab: TabItem<string>) {
    if (activatedTab.id === this.GENERAL_TAB && this.activeTabId !== this.GENERAL_TAB) {
      this.overrideValueByGeneralTab = false;
    }

    this.activeTabId = activatedTab.id;
  }

  settlementTabStateChange(fileId: string, tabState: SettlementTabState) {
    this.tabsCache[fileId] = tabState;
  }

  tabValidityChanged(fileId: string, valid: boolean) {
    const tab = this.tabs.find(t => t.id === fileId);
    if (tab) tab.valid = valid;
  }

  protected abstract overrideValueInTabs(field: string, value: any): void;

  protected syncFormsValidityWithTabValidity() {
    this.tabs.forEach(tab => {
      if (tab.id !== this.GENERAL_TAB) {
        const form = this.forms[tab.id.toString()];
        if (form) tab.valid = form.valid;
      }
    });
    this.changeDetectorRef.detectChanges();
  }

  protected setupGeneralTabValueOverride(overrideFields: string[]) {
    overrideFields.forEach(field => {
      this.generalTabForm.get(field)!.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
        if (!this.overrideValueByGeneralTab) {
          if (this.overrideQuestionModalOpened) return;

          this.dialogService.openQuestionDialogWithAnswer({
            title: 'Přepsat vlastní hodnoty',
            description: 'Chystáte se přepsat vlastní hodnoty jednotlivých objektů hodnotami z první záložky.',
            question: 'Přejete si přepsat vlastní hodnoty výchozími hodnotami?',
            leftButtonTitle: 'Přepsat výchozími hodnotami',
            rightButtonTitle: 'Storno',
          }).subscribe(result => {
            this.overrideValueByGeneralTab = true;
            this.overrideQuestionModalOpened = false;

            if (result) {
              this.overrideValueInTabs(field, value);
            }
          });
          this.overrideQuestionModalOpened = true;
        }
        else {
          this.overrideValueInTabs(field, value);
        }
      });
    });
  }

  protected processReasonAndContentFields(fileSettlementType: Nullable<SettlementType>, form: IczFormGroup) {
    AbstractObjectSettleDialogComponent.processReasonAndContentFields(
      fileSettlementType,
      form.get('reason') as IczFormControl,
      form.get('content') as IczFormControl
    );
  }

  static processReasonAndContentFields(
    fileSettlementType: Nullable<SettlementType>,
    reasonControl: IczFormControl,
    contentControl: IczFormControl
  ) {
    if (fileSettlementType === SettlementType.BY_RECORD) {
      contentControl.setValidators([IczValidators.required()]);
      reasonControl.setValidators([IczValidators.required()]);
    }
    else {
      contentControl.setValidators([]);
      reasonControl.setValidators([]);
    }
    contentControl.updateValueAndValidity();
    reasonControl.updateValueAndValidity();
  }

  protected initTabs() {
    this.tabs = this.tabs.concat(this.objects.map(f => ({
      id: f.id.toString(),
      label: f.refNumber!,
      icon: getObjectIcon(f.objectClass as unknown as ObjectClass),
      showTabValidity: false,
      valid: false
    })));
  }

}
