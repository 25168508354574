/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { agendaTransferExportManifestGenerate } from '../fn/agenda-transfer-export-manifest/agenda-transfer-export-manifest-generate';
import { AgendaTransferExportManifestGenerate$Params } from '../fn/agenda-transfer-export-manifest/agenda-transfer-export-manifest-generate';


/**
 * API for managing export descriptive xml (manifest) files for agenda transfer
 */
@Injectable({ providedIn: 'root' })
export class ApiAgendaTransferExportManifestService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `agendaTransferExportManifestGenerate()` */
  static readonly AgendaTransferExportManifestGeneratePath = '/agenda-transfer-envelope/manifest/{entityType}/{entityId}/generate';

  /**
   * Generates export descriptive xml (manifest) files for agenda transfer
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `agendaTransferExportManifestGenerate()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportManifestGenerate$Response(params: AgendaTransferExportManifestGenerate$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return agendaTransferExportManifestGenerate(this.http, this.rootUrl, params, context);
  }

  /**
   * Generates export descriptive xml (manifest) files for agenda transfer
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `agendaTransferExportManifestGenerate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  agendaTransferExportManifestGenerate(params: AgendaTransferExportManifestGenerate$Params, context?: HttpContext): Observable<void> {
    return this.agendaTransferExportManifestGenerate$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
